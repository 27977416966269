import React from "react";
import R14, { StyleSheet, View, Text, AsyncStorage, Touchable, Image } from "../../core";
import SepaBillsComponent from "../../components/SEPA/SepaBillsComponent";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import JsonToCSV from "../../utils/JsonToCSV";
import HtmlDecode from "../../utils/HtmlDecode";
import DateUtils from "../../utils/DateUtils";
import { analyticsLogs } from "../../utils/AnalyticLogs";
import { CommonString } from "../../constant/appCommonText";

const attachedFileIcon = require("../../assets/images/attachedFileIcon.png");

export default R14.connect(
    class SEPABillsScreen extends React.Component {
        constructor(props) {
            super(props);
            AsyncStorage.setItem("homeScreen", this.props.r14.navigation.activeRouteName);
        }

        convertToCSV = async (items, screenName = "SEPABills") => {
            analyticsLogs( CommonString.SEPA_ALL_BILLS, `${CommonString.DOWNLOAD_CSV}`, this.props.app);
            if (items.length) {
                items = items.map(item => {
                    return {
                        "Biller": item.billerName,
                        "Receive Date": DateUtils.formatDate(item.createdOn),
                        "Amount": item.billAmount,
                        "Status": item.billStatus,
                        "Payment Type": item.paymentType,
                        "Expiry Date": DateUtils.formatDate(item.expiryDate)
                    }
                })

                const csvContent = JsonToCSV.convertJsonToCSV(items);
                HtmlDecode.downloadUTF8file({
                    content: csvContent,
                    filename: `${screenName}_${DateUtils.formatYYYYMMDDHHMMSS()}.csv`
                })
            }
            return false;
        }

        render() {
            const { isUberDocs } = this.props.app.dm.userSession.state;
            let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
            const items = this.props.app.dm.sepa.state.sepaBills;
            return (
                <>
                    <View style={[commonStyles.paddingLeftRight16, styles.pageTitle]}>
                        <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500]}>{this.props.r14.navigation.currRoute._config.label}</Text>
                        <View style={[commonStyles.marginTop5]}>
                            <Touchable onPress={() => this.convertToCSV(items, "SEPA Bills")}>
                                <Image source={attachedFileIcon} style={commonStyles.icon18} />
                            </Touchable>
                        </View>
                    </View>
                    <SepaBillsComponent customHeights={styles.maxHeight400} items={items} />
                </>

            )
        }
    });

    
const styles = StyleSheet.create({
    pageTitle: {
        marginTop: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        minHeight: 30,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 10,
                    marginBottom: 10,
                };
            }
        },
    },
    
    maxHeight400: {
        maxHeight: 400,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    maxHeight: "auto",
                };
            }
        }
    },
})