import React, { Component } from "react";
import R14, { View, StyleSheet, Button } from "../core";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import CommonUtility from "../utils/CommonUtility";
import ModalPopUp from "../components/ModalPopUp";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import DeviceUtils from "../utils/DeviceUtils";
import { CommonString } from "../constant/appCommonText";


export default R14.connect(
  class InAppCameraComponent extends Component {
    
    handleTakePhoto = (dataUri) => {
      const { onValueChange, onCamClose } = this.props;
      let randomString = CommonUtility.randomPassword();
      let base64Split = dataUri.split(",");
      let fileObj = {
        filename: "Document_" + randomString.toUpperCase() + ".png",
        contentType: "PNG",
        content: base64Split[1],
        size: this.convertBytes(base64Split[1].length),
        actualsize: base64Split[1].length,
        contentUri: base64Split[0],
      };
      onValueChange(fileObj, "done");
      onCamClose();
      return false;
    };

    convertBytes(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0;
      let n = parseInt(x, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      //include a decimal point and a tenths-place digit if presenting
      //less than ten of KB or greater units
      // return n.toFixed(n < 10 && l > 0 ? 2 : 0) + " " + units[l];
      return n.toFixed(2) + " " + units[l];
    }

    handleCameraError = (error) => {
    //  console.log("camera error", error.toString()); 
      const { onCamClose } = this.props;
      if (error.message.includes("Permission denied"))
      {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.CameraAccessDenied);
        onCamClose();
        return false;
      }
      else  if (error.message.includes("device not found"))
      {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.CameraNotFound);
        onCamClose();
        return false;
      } 
      else
        this.props.app.dm.commonDomain.showToastr("error", error.message);
    };

    
    getDevice = () => {

      return new Promise((resolve, reject) => {

        DeviceUtils.getDeviceStatus("camera")
        .then((deviceResponse) => {
          if (deviceResponse === "granted")
            return  resolve(true);
          else {
            if (deviceResponse === "allowDevie") {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.CameraAccessDenied);
             // return  resolve(false);
            }
            else if (deviceResponse === "deviceNA") {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.CameraNotFound);
             // return false;
            }
          }
          return  resolve(false);
        });
      });
    }

    render() {
      const { isCamVisible, onCamClose } = this.props;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      // if (isCamVisible){
      //   visible = this.getDevice().then(
      //     (res) =>{
      //       return res;
      //     }
      //   )
      // }

      return (
        <ModalPopUp
          visibility={isCamVisible} 
          isFooter={false}
          bodyWrapper={styles.captureScreenShotWrapper}
          modalContent={styles.captureScreenShotContent}
          heading={" "}
          handleCancel={onCamClose}
          closeButton={!!isMobileDevice ? commonStyles.displayNone : null}
          modalHeading={[]}
          animationType="fade"
          footerClass={commonStyles.padding0}
          style={[
            styles.filePassowrdModalWrapper,
            commonStyles.flex1,
            commonStyles.flexDirectionRow,
            commonStyles.justifyContentCenter,
            commonStyles.alignItemsCenter,
            commonStyles.flexWrap,
          ]}
          contentInner={[commonStyles.dFlex, commonStyles.flexDirectionColumn]}>
          <View style={[styles.attachmentFieldOuter]}>
            <Camera
              onTakePhoto={(dataUri) => {
                this.handleTakePhoto(dataUri);
              }}
              onCameraError={(error) => {
                this.handleCameraError(error);
              }}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              isDisplayStartCameraError={false}
              // isFullscreen={false}
              //idealResolution={{ width: 640, height: 480 }}
              //isMaxResolution={true}
              //sizeFactor={1}
              imageType={IMAGE_TYPES.PNG}
              //onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
              //onCameraStart={(stream) => { handleCameraStart(stream); }}
              //onCameraStop={() => { handleCameraStop(); }}
              //imageCompression={0.97}
              isImageMirror={false}
              //isSilentMode={false}
            />
          </View>
          {!!isMobileDevice ? (
            <Button
              title={"Cancel"}
              style={[commonStyles.blueBorderButton, styles.formBtns, commonStyles.marTAuto]}
              titleTextStyle={[commonStyles.blueBorderButtonColor, commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading6]}
              onPress={onCamClose}
            />
          ) : null}
        </ModalPopUp>
      );
    }
  }
);
const styles = StyleSheet.create({
  attachmentFieldOuter: {
    width: "100%",
    padding: 0,
    //  borderTopLeftRadius: 52,
    borderTopRightRadius: 52,
    borderBottomLeftRadius: 52,
    borderBottomRightRadius: 52,
    // backgroundColor: "#cff2ff",
    overFlow: "hidden",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
  },
  attachmentField: {
    zIndex: 99,
    height: 52,
    width: 52,
    padding: 0,
    borderTopLeftRadius: 52,
    borderTopRightRadius: 52,
    borderBottomLeftRadius: 52,
    borderBottomRightRadius: 52,
    borderColor: "rgba(255,255,255, 0.0)",
    backgroundColor: "rgba(255,255,255, 0.0)",
  },
  captureScreenShotWrapper: {
    width: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
          // marginTop: "auto",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          height: "100%",
        };
      }
    },
  },
  captureScreenShotContent: {},
  displayNone: {
    display: "none",
  },

  filePassowrdModalWrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  formBtns: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
});
