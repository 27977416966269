import React from "react";
import R14, { StyleSheet, View, Text, Touchable, Image, Platform } from "../../core"; 
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import ExternalLinkComponent from "../../components/PCH/Linking";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";

const xbpLogo = require("../../assets/images/NewXbpLogo150.png");
const back_icon = require("../../assets/images/back_icon.png");

export default R14.connect(
  class TermsAndConditionsScreen extends React.Component {
    navPchLogin = () => {
      this.props.app.nav.back();
      return false;       
    };
    accessServicesAgreementRoute = async () =>  {
      this.props.app.nav.to("accessServicesAgreementRoute");
      return false;
    }
    redirectTopchusertermsRoute = async () => {
      this.props.app.nav.to("pchusertermsRoute");
      return false;
    }
    render() {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <View scrollEnabled style={[styles.pchPrivacyPolicy]}>
          {isMobileDevice ?
            (<View>
              <View style={[customCommonStyles.customLogoImageContainer, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <Touchable onPress={this.navPchLogin}>
                  <View>
                    <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                  </View>
                </Touchable>
                <View style={[styles.logoStyle, commonStyles.justifyContentCenter]}>
                  <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
                </View>
                {/* <View>
                    <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500]}>Registration</Text>
                  </View> */}
              </View>
            </View>) :
            (<View style={[styles.loginHeader]}>
              <Touchable onPress={this.navPchLogin}>
                <View>
                  <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                </View>
              </Touchable>
              <View style={commonStyles.marginLeft16}>
                <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
              </View>
            </View>)}
            <View style={[commonStyles.marginBottom35, commonStyles.marginTop20, commonStyles.alignItemsCenter]}>
              <View style={[commonStyles.textAlignCenter]}>
                <Text style={[styles.mainHeading, commonFontStyles.workSans, commonStyles.textAlignCenter]}>Terms and Conditions</Text>
              </View>
              <View style={[commonStyles.textAlignCenter]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading4, commonStyles.fullWidth, commonStyles.textAlignCenter]}>(Last updated on September 13, 2022)
              </Text>
              </View>
            </View>
            <View style={styles.pchPrivacyPolicyWrapper}>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder, commonStyles.flex1, commonStyles.alignItemsCenter]}> 
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>These Terms and Conditions including the 
                  {Platform.OS === "web" ? 
                  <Touchable onPress={this.accessServicesAgreementRoute}>
                    <View>
                      <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle,commonStyles.blueLinkColor]}>
                      {" "}Access Service Agreement {" "}
                      </Text>
                    </View>
                  </Touchable>
                  : <Text onPress={this.accessServicesAgreementRoute} style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle,commonStyles.blueLinkColor]}>
                     {" "}Access Service Agreement{" "}
                  </Text>
                  }
                  and the 
                  {Platform.OS === "web" ? 
                  <Touchable onPress={async () => {
                    this.props.app.nav.to("pchusertermsRoute");
                  }}>
                    <View>
                      <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle,commonStyles.blueLinkColor]}>
                      {" "}Authorized User Terms{" "}
                      </Text>
                    </View>
                  </Touchable>
                  : <Text onPress={async () => {
                    this.props.app.nav.to("pchusertermsRoute");
                  }} style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle,commonStyles.blueLinkColor]}>
                     {" "}Authorized User Terms{" "}
                  </Text>
                  }
                  
                    (collectively, “Terms”) are a legal agreement between the Customer and Exela Enterprise Solutions, Inc. (“Exela”) which govern the access to and use of the Xchange of Bills and Payment software and service (“XBP”), this site, and other related sites sponsored by Exela, any related services, and if applicable, applications, including integrations provided by Exela (collectively, the "Sites"). XBP was created by and is a brand owned by subsidiaries of Exela Technologies, Inc. (together with its subsidiaries, “Exela”).    If the party that is accessing or using XBP or related Sites (the “Customer” or “User”), then these Terms apply to Customer’s use.</Text> 
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  By accessing, using, or downloading any materials from the Sites, registering for the services, or downloading or using any of the apps, Customer agrees to be bound by these terms. <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>IF CUSTOMER DOES NOT AGREE TO THESE TERMS, THE CUSTOMER IS NOT AUTHORIZED AND MUST CEASE USING THE SITES IMMEDIATELY.</Text>  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Recognizing the global nature of the Internet, the Customer must comply with all local rules regarding online conduct and acceptable use of "Information." Specifically, the Customer agrees to comply with all applicable laws regarding the transmission of technical data exported from the United States or the country in which Customer resides.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION AND WAIVER OF JURY TRIALS AND CLASS ACTIONS GOVERNING DISPUTES ARISING FROM THE USE OF THE SERVICES WHICH AFFECT CUSTOMER’S LEGAL RIGHTS AS DETAILED HEREIN. PLEASE READ CAREFULLY.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  CUSTOMER MUST BE OF LEGAL AGE (18 YEARS OF AGE OR OLDER, OR OTHERWISE OF LEGAL AGE IN CUSTOMER RESIDENT JURISDICTION) AND COMPETENT TO AGREE TO THESE TERMS TO USE THE SITES OR SERVICES. IF THE CUSTOMER AGREES TO THESE TERMS ON BEHALF OF AN ORGANIZATION OR ENTITY, THE CUSTOMER REPRESENTS THAT IT HAS THE AUTHORITY TO AGREE TO THESE TERMS ON THAT ORGANIZATION OR ENTITY’S BEHALF AND BIND THEM TO THESE TERMS, IN WHICH CASE THE REFERENCES TO “CUSTOMER” OR “USER” IN THESE TERMS, EXCEPT FOR IN THIS SENTENCE, WILL REFER TO THAT ORGANIZATION OR ENTITY.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  CUSTOMER, THE USER, SHALL TAKE ALL REASONABLE STEPS TO ENSURE THAT NO UNAUTHORIZED PERSONS HAVE ACCESS TO THE SITES, AND TO ENSURE THAT NO PERSONS AUTHORIZED TO HAVE SUCH ACCESS SHALL TAKE ANY ACTION THAT WOULD BE IN VIOLATION OF THESE TERMS.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  BY USING THE SERVICES (AS DEFINED BELOW), THE CUSTOMER CONSENTS TO RECEIVE ELECTRONIC COMMUNICATIONS FROM EXELA AND/OR ITS PARTNERS AND AFFILIATES. CUSTOMER AGREES THAT ANY NOTICES, AGREEMENTS, DISCLOSURES OR OTHER COMMUNICATIONS THAT ARE SENT TO THE CUSTOMER ELECTRONICALLY WILL SATISFY ANY LEGAL COMMUNICATION REQUIREMENTS, INCLUDING THAT SUCH COMMUNICATIONS BE IN WRITING, PROVIDED THAT ANY COMMUNICATION TO THE CUSTOMER IS SENT TO THE EMAIL ADDRESS PROVIDED ON CUSTOMER’S ACCOUNT. CUSTOMER AGREES THAT ANY COMMUNICATION TO EXELA WILL BE SENT TO <ExternalLinkComponent linkURL={"mailto:" + CommonString.XBPClaimSupportEmail} linkText={CommonString.XBPClaimSupportEmail} /> FOR FURTHER HANDLING.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                  A.	USE/ACCESS  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Customer use of XBP through the Sites and the access to relevant support from Exela (collectively, the "Services") is available on registration basis available on the Site.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                  B.	OWNERSHIP OF PROPRIETARY INFORMATION; CONTENT  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                The Services contain materials that are proprietary and are protected by copyright laws, international treaty provisions, trademarks, service marks, and other intellectual property rights, laws, and treaties. Exela, and its licensors own and will retain ownership of all right, title, and interest in the Services, including intellectual property rights therein (but excluding Customer’s Content (as defined below). Customer owns and will retain ownership of all right, title, and interest in Customer’s Content. Except as otherwise required by applicable law, any reproduction, distribution, modification, retransmission, or publication of any copyrighted material is strictly prohibited without the express written consent of the copyright owner. Neither party will do anything inconsistent with such a title. Customer’s use of any Exela or XBP proprietary information other than as permitted in accordance with these Terms is strictly prohibited.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                The Services consist of web based digital exchange platform for transmission of medical claim processing and any data contained therein (collectively "Content"). Customer owns and will retain ownership of all right, title, and interest in the Customer’s Content uploaded to the Services, including intellectual property rights therein. Except as described in these Terms, Exela claims no ownership rights in Customer Content. Exela may provide links to publicly available Content to use with the Services but is not responsible in any manner for such publicly available Content. However, the Customer is responsible for ensuring that any Content (as well as any publicly available Content) that is used in Connection with the Services complies with the United States copyright laws, other intellectual property laws, and treaties, as well as applicable privacy laws and export laws. Exela does not have the ability to screen all Content, but Exela shall have the right (but not the obligation) in its sole discretion to remove all or part of Customer’s Content from the Services. If the Customer is an administrator for its account, then the Customer is responsible for the Content and activities of its
                {Platform.OS === "web" ?
                 <Touchable onPress={this.redirectTopchusertermsRoute}>
                  <View>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.blueLinkColor]}>
                    {" "}Authorized Users
                    </Text>
                  </View>
                  </Touchable> : 
                    <Text onPress={this.redirectTopchusertermsRoute} style={[commonStyles.blueLinkColor]}>
                      {" "}Authorized Users
                    </Text>}.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Although the Customer is in no way obligated to do so, if the Customer does choose to share an idea, suggestions, information, or feedback relating to the Services ("Suggestion"), then the Customer hereby assigns, transfers, and conveys to Exela/ or XBP, all worldwide right, title, and interest in and to all intellectual property rights in all of the Customer’s Suggestions.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Exela and/or its licensors owns and will retain ownership of all right, title, and interest in its Content, including all intellectual property rights therein. Customer claims no ownership of Exela and/or its licensors’ Content.   
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                If the Customer is a user of Content posted by a third-party on the Services ("Third-Party Content"), the Customer acknowledges that Exela does not approve, endorse, monitor, verify, or take responsibility for any such Third-Party Content. Customer agrees that the third party posting the Third-Party Content is solely responsible for it and that Exela is not liable for any Third-Party Content. EXELA HEREBY EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY WITH RESPECT TO CLAIMS ARISING OUT OF OR RELATED TO THIRD -PARTY CONTENT REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL OR EQUITABLE THEORY.   
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                  C.	LIMITED LICENSE  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Upon Customer’s acceptance of these Terms, Exela hereby grants a limited, revocable, non-exclusive, non-transferrable license to access and use the Services (including Exela and/or its licensors’ Content provided in connection with the Services) solely for Customer’s personal or internal business purpose and only as expressly permitted herein.  Customer acknowledges that Exela reserves all rights relating to the Services not expressly granted to Customer herein. The license, Service or software is considered delivered and accepted when made available for access.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Customer will not nor permit anyone else to: (a) share its login ID for the Services with any other person (each user must have their own login ID) or use or launch any automated system to access the Services; (b) access another users account, circumvent standard access to the Services, or attempt to gain unauthorized access to the Services; (c) use the Services in a manner that is illegal, defames, abuses, tortious, harasses, stalks, threatens, or violates the legal rights of others; (d) use the Services to impersonate another person or entity or misrepresenting Customer or affiliation with an entity, including sending deceptive source-identifying information such as spoofing or phishing, or to upload, post, host, or transmit unsolicited email or spam, or use the Services to transmit any malware, Trojan horses, worms or viruses or any destructive or malicious code; (e) rent, lease, distribute, sell, resell, assign, or otherwise transfer rights to the Services or Exela Content and/or its licensors’ Content; (f) reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code or underlying intellectual property relevant to the Services; or (g) use the Services or Exela and/or its licensors’ for the benefit of a competitive offering to the Services or to intentionally harm or discredit Exela.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Any violation by the Customer of the license restrictions contained in this Section may result in the immediate termination of the Customer’s right to use the Services, as well as potential liability for copyright infringement or other claims.  Exela reserves the right to refuse service to anyone for any reason at any time.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                D.	CONFIDENTIAL INFORMATION  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                In connection with the Services, Customer and Exela may share proprietary or non-public information (“Confidential Information”) which may or may not be designated as “Confidential,” “Proprietary” or some similar designation. "Confidential Information" will not include any information which: (a) was previously known to the other party without restriction on use or disclosure; (b) is or becomes publicly available, through no fault or breach of these terms by the receiving party; (c) is rightfully obtained by the receiving party from a third-party not under a duty of confidentiality and without restriction on use or disclosure; (d) is independently developed by such other party without reference to the Confidential Information.   
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                The receiving party of Confidential Information agrees: (a) not to disclose Confidential Information to a third-party, with the exception that Confidential Information may be disclosed to such party’s employees, and/or its affiliates, consultants, attorneys, accountants or other professional advisors who have a need to have the information that are under confidentiality obligations that are at least as restrictive as the terms contained herein or subject to direction of the disclosing party; (b) to protect the secrecy of and to avoid disclosure and unauthorized use of the disclosing party's Confidential Information to the same degree that it takes to protect its own Confidential Information, and in no event less than reasonable care; and (c) to use Confidential Information only as necessary to fulfill its obligations and exercise its rights under these Terms.   
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Upon termination, Exela will have no further obligations in respect of Customer’s Confidential Information.   
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                E.	HANDLING OF EU PERSONAL DATA  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                The following terms have the meanings given in the General Data Protection Regulation (EU) 2016/679: "personal data", "data subject" and "process". To the extent Customer is an individual, Customer  hereby expressly grants consent to Exela to: (a) process its personal data (including sensitive personal data) in accordance with the Privacy Policy and to collect, use, and disclose such personal data in order deliver Services and otherwise in accordance with the terms herein; (b) disclose personal data (including sensitive personal data) to the categories of recipients described in the Privacy Policy; (c) transfer personal data (including sensitive personal data) throughout the world, including to the United States and other countries that do not ensure adequate protection for personal data (as determined by the European Commission); and (d) disclose personal data (including sensitive personal data) to comply with lawful requests by public authorities, including to meet national security or law enforcement requirements.   
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                F.	DEVICES AND/OR INTERNET
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Services depend on third-party network and Internet providers and device manufacturers that are outside of Exela’s control. Customer acknowledges that Exela will not be responsible or liable for performance or non-performance as a result of such devices or networks. Customer acknowledges that the processing and transmission of the Services, including the Content, may involve transmissions over various networks and unencrypted transfer to a network or device. Customer acknowledges that third-party networks or devices may change their technical requirements interfering with the operation of the Services.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                G.	WARRANTIES AND DISCLAIMERS
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Customer hereby represents and warrants to Exela that: (a) Customer has all requisite rights and authority to use the Services under these terms and to grant all applicable rights herein; (b) is responsible for use of the Services; (c) solely responsible for maintaining the confidentiality of password(s); (d) will promptly notify Exela of any unauthorized use of the Services; (e) agrees that Exela will not be liable for any losses incurred as a result of a third-party's use of Customer’s account, regardless of whether such use is with or without Customer’s knowledge and consent; (f) will use the Services for lawful purposes only and subject to these terms; (g) any information Customer submits to Exela   is true, accurate, and correct; and (h) will not attempt to gain unauthorized access to the system or the Services, other accounts, computer systems, or networks under the control or responsibility of Exela.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Customer acknowledges that the Sites and/or Services and any related products, information, documentation, software, technology, technical data, and any derivatives thereof, that Exela makes available (collectively “Excluded Data”) is subject to export control laws and regulations of the United States (including, the U.S. Export Administration Act and the sanctions regulations administered by the U.S. Department of the Treasury Office of Foreign Assets Control (“OFAC”)) and other jurisdictions (collectively “Export Laws”). Customer represent and warrant that Customer will not access, download, use, export, or re-export, directly or indirectly, the Excluded Data to any location, entity, government or person prohibited by Export Laws (including to any end-user in a U.S. embargoed country or territory or an end-user included on OFAC’s listing of Specially Designated Nationals or the U.S. Commerce Department’s Entity List or Denied Persons List) without first complying with all Export Laws that may be imposed by the U.S. Government and any country or organization of nations within whose jurisdiction Customer operates or does business. Customer is solely responsible for complying with Export Laws for all Excluded Data and any of its content transmitted through the Sites. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE SERVICES AND SITES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” CUSTOMER’S USE OF THE SERVICES AND SITES SHALL BE AT CUSTOMER’S SOLE RISK. EXELA  AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES, SUBSIDIARIES, AND LICENSORS (“EXELA  PARTIES”) (i) MAKES NO ADDITIONAL REPRESENTATION OR WARRANTY OF ANY KIND WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER; (ii) EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, ACCURACY, AND TITLE; AND (iii) DOES NOT WARRANT THAT THE SERVICES OR SITES ARE OR WILL BE ERROR-FREE, WILL MEET CUSTOMER’S REQUIREMENTS, OR BE TIMELY OR SECURE. CUSTOMER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE RESULTING FROM USE OF THE SERVICES OR SITES. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                EXELA MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES AND SITES CONTENT AND ASSUMES NO RESPONSIBILITY FOR ANY: (i) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (ii) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY NATURE WHATSOEVER RESULTING FROM THE ACCESS TO AND USE OF SERVICES AND SITES; (iii) ANY UNAUTHORIZED ACCESS TO OR USE OF EXELA SERVERS; (iv) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITES; (v) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE SITES; (vi) ANY LOSS OF CUSTOMER DATA OR CONTENT FROM THE SITES; AND/OR (vii) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES AND SITES. CUSTOMER WILL NOT HAVE THE RIGHT TO MAKE OR PASS ON ANY REPRESENTATION OR WARRANTY ON BEHALF OF EXELA TO ANY THIRD-PARTY.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Because some jurisdictions do not allow limitations on implied warranties, the above limitation may not apply to Customer. In that event, such warranties are limited to the maximum extent permitted by and for the minimum warranty period allowed by the mandatory applicable law.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Exela’s sole obligation and Customer’s sole and exclusive remedy for any breach of these terms is limited to Exela’s reasonable commercial efforts to correct the non-conforming services at no additional charge to Customer. In the event that Exela is unable to correct material deficiencies in the Services after using commercially reasonable efforts to do so, Customer shall be entitled to terminate the Services.   
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                H.	LIMITITATION OF LIABILITY
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL EXELA  BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY: (a) USE OF THE SITES OR THE SERVICES; (b) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (c) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY NATURE WHATSOEVER RESULTING FROM CUSTOMER’S ACCESS TO AND USE OF THE SITES OR THE SERVICES; (d) ANY UNAUTHORIZED ACCESS TO OR USE OF EXELA SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; (e) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM EXELA SERVERS; (f) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE SITES OR THE SERVICES BY ANY THIRD-PARTY; (g) ANY LOSS OF DATA OR CONTENT FROM THE SITES OR THE SERVICES; (h) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF CUSTOMER’S USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITES OR THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT THE EXELA PARTIES ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND/OR (i) THE DISCLOSURE OF INFORMATION PURSUANT TO THESE TERMS OR THE EXELA PRIVACY POLICY.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Each provision of these Terms that provides for a limitation of liability, disclaimer of warranties, or exclusion of damages represents an agreed allocation of the risks of these Terms between Customer and Exela. This allocation is an essential element of the basis of the bargain between the parties. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                I.	INDEMNIFICATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Customer will defend, indemnify, and hold Exela harmless from any and all third-party claims, damages, liabilities, and costs (including, but not limited to attorneys' fees) arising from or related to: (a) allegations that Customer’s Content infringes any intellectual property or other rights of any person or entity, (b) Customer’s failure to comply with these Terms, or (c) Customer’s willful misconduct or negligence. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Exela retains the exclusive right to settle, compromise, and pay, without Customer’s consent, any and all claims or causes of action that are brought against Exela. Exela will (a) promptly provide notice to Customer of any claim, suit or proceeding for which indemnity is claimed and Customer agrees to cooperate with Exela defense of these claim(s). Customer will not enter into any settlement that imposes liability or obligations on Exela without Exela’s express prior consent.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                J.	PAYMENT; FEES 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Customer shall pay Exela all fees associated with the Services in advance whether or not you use any or all of the Services. Customer must provide Exela with a valid card, or other forms of payment acceptable to Exela. Additionally, Customer is responsible for payment of all taxes, including sales, use, personal property, value-added, excise, customs fees, import duties, stamp duties and any other similar taxes and duties, including  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Customer acknowledges that Exela may change its fees and/or fee structures for Services from time to time in its discretion Exela will post notice of such changes on the Sites. Customer agrees that continued use of all or part of the Services will be subject to Customer’s payment of any applicable fees. Exela shall not be liable to the Customer or to any third-party for any modification, price change, suspension, or discontinuance of the Services.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                If the Customer in good faith disputes of any payment, the Customer should contact Exela support team with details of the dispute, any supporting documentation, and its contact information within thirty (30) days of the Customer becoming aware of the dispute. Customer will continue to pay all amounts when due (even disputed amounts) while the parties work diligently and promptly to resolve the dispute. Upon resolution, Exela will promptly credit any amount owed to the Customer or Customer will promptly pay all amounts owed to Exela. In order to receive a credit or dispute an invoice, please contact Exela support at <ExternalLinkComponent linkURL={"mailto:" + CommonString.XBPClaimSupportEmail} linkText={CommonString.XBPClaimSupportEmail} />
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                K.	TERMINATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Exela may terminate the arrangement contemplated by these Terms or suspend the use of Services if the Customer (a) violate the license restrictions contained in these Terms, or (b) is past due on payment of a fees owed and have not responded to request for payment, (c) billing or contact information is false, fraudulent or invalid, (d) issued bad checks or reversed credit/debit card transactions, (e) breach or otherwise to fail to comply with these Terms and have not cured such breach within five (5) days of or Exela written notice specifying the alleged breach, or (f)  enter receivership, general assignment for the benefit of  creditors, any bankruptcy or insolvency proceedings that are not dismissed within sixty (60) days, liquidation, dissolution or termination of Customer’s business operations.   
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                L.	EFFECT OF TERMINATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                If these terms expire or are terminated for any reason: (a) Customer will pay to Exela any amounts that have accrued before, and remain unpaid as of, the date of the termination or expiration, including those for the billing cycle in which termination occurs; (b) any and all of the liabilities to or  Exela that have accrued before the effective date of the expiration or termination will survive; (c) licenses and use rights granted to Customer with respect to the Services and Sites and intellectual property will immediately terminate; (d) Exela obligation to provide any further services under these Terms will immediately terminate. Exela will not be liable to the Customer or any third-party for termination of the arrangement contemplated by these Terms or any termination or suspension of the Customer’s use of the Services.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                CUSTOMER ACKNOWLEDGES THAT THE CONTENT WILL BE DELETED FROM THE SERVICES UPON THE TERMINATION OR UPON THE EXPIRATION DATE OF THE CANCELLED SERVICES. THIS INFORMATION CANNOT BE RECOVERED ONCE DELETED. IF CUSTOMER IS NOT A CURRENT SUBSCRIBER, EXELA WILL NOT STORE CONTENT ON CUSTOMER’S BEHALF.  
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                M.	GOVERNING LAW
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                The arrangement contemplated by these Terms will be governed by and construed in accordance with the substantive laws in the State of New York.   These Terms will not be governed by the conflict of law rules of any jurisdiction that would result in the application of any laws other than those of the State of New York or the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                N.	BINDING ARBITRATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                IN THE EVENT OF A DISPUTE, CONTROVERSY OR CLAIM ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OR TO Exela , THE COMPLAINING PARTY SHALL NOTIFY THE OTHER PARTY IN WRITING THEREOF. WITHIN THIRTY (30) DAYS OF SUCH NOTICE, BOTH PARTIES SHALL ATTEMPT TO RESOLVE THE DISPUTE IN GOOD FAITH. IF THE DISPUTE IS NOT RESOLVED BY NEGOTIATION OR MEDIATION, AT ANY TIME AFTER EXPIRATION OF THIRTY (30) CALENDAR DAYS FROM THE DATE OF THE WRITTEN REQUEST FOR INFORMAL SETTLEMENT. INSTEAD OF SUING IN COURT, Exela AND CUSTOMER EACH AGREE TO SETTLE DISPUTES ONLY BY ARBITRATION, ACCORDINGLY. THE DISPUTE SHALL BE SUBMITTED TO BINDING ARBITRATION IN ACCORDANCE WITH THE AAA COMMERCIAL ARBITRATION RULES BY A SOLE INDEPENDENT ARBITRATOR CHOSEN BY THE AAA OFFICE IN NEW YORK CITY. THE ARBITRATOR SHALL ISSUE A WRITTEN AWARD STATING THE REASONS THEREFOR. THE RULES IN ARBITRATION ARE DIFFERENT. THERE IS NO JUDGE OR JURY, AND REVIEW IS LIMITED, BUT AN ARBITRATOR CAN AWARD THE SAME DAMAGES AND RELIEF, AND MUST HONOR THE SAME LIMITATIONS STATED IN THESE TERMS AS A COURT WOULD. JUDGEMENT UPON THE AWARD RENDERED BY THE ARBITRATOR SHALL BE BINDING ON BOTH PARTIES AND MAY BE ENTERED IN ANY COURT OF COMPETENT JURISDICTION. THE ARBITRATION SHALL BE CONDUCTED IN THE ENGLISH LANGUAGE. EITHER PARTY SHALL RETAIN THE RIGHT TO OBTAIN INTERIM EQUITABLE RELIEF IN ANY COURT OF COMPETENT JURISDICTION. IN NO EVENT SHALL THE ARBITRATOR BE EMPOWERED TO AWARD PUNITIVE DAMAGES. EACH PARTY SHALL BEAR ITS OWN EXPENSE OF ARBITRATION AND SHALL SHARE EQUALLY THE COST OF THE ARBITRATOR AND THE ARBITRATION PROCEEDINGS. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                O.	WAIVER OF JURY TRIAL; CLASS ACTION WAIVER
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                SUBJECT TO THE FOREGOING AGREEMENT TO ARBITRATE, Exela AND THE CUSTOMER EACH KNOWINGLY WAIVE ANY RIGHT TO JURY TRIAL UNDER THESE TERMS, AND THE CUSTOMER FURTHER AGREES THAT ARBITRATION SHALL PROCEED SOLELY ON AN INDIVIDUAL BASIS WITHOUT THE RIGHT FOR ANY CLAIMS TO BE ARBITRATED ON A COLLECTIVE OR CLASS ACTION BASIS OR ON A BASIS INVOLVING CLAIMS BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF OTHERS ("CLASS ACTION WAIVER"). CLAIMS MAY NOT BE JOINED OR CONSOLIDATED UNLESS AGREED TO IN WRITING BY US. THE WAIVER OF JURY TRIAL AND AGREEMENT TO ARBITRATE SHALL REMAIN IN EFFECT EVEN IF THE CLASS ACTION WAIVER IS LIMITED, VOIDED, OR FOUND UNENFORCEABLE. THE CUSTOMER AND Exela AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN CUTOMER’S OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                P.	THIRD-PARTY CONTENT
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                The Services may provide, links to other third-party websites, services, or resources that are beyond Exela control. Exela makes no representations as to the quality, suitability, functionality, or legality of any third-party content to which links may be provided, and Customer hereby waives any claim that Customer might have against Exela with respect to such services. Exela <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>IS NOT RESPONSIBLE FOR THE CONTENT ON THE INTERNET OR WEB PAGES THAT ARE LOCATED OUTSIDE THE SITE OR POSTS OF USER CONTENT.</Text> Customer’s usage through the Site, including any payment gateway, and any other terms, conditions, warranties, or representations associated with such dealings are solely between Customer and such provider. Customer agrees that Exela shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such links to third-party websites or resources on the Site. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                Q.	MARKETING
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Customer grants Exela the right to list, present or utilize their name, data and logo as a PCH customer on Exela’s service website and/or marketing materials. If the Customer wishes to withdraw its consent, the Customer can send its request on <ExternalLinkComponent linkURL={"mailto:" + CommonString.XBPClaimSupportEmail} linkText={CommonString.XBPClaimSupportEmail} />. Exela shall within seven (7) days of receipt of Customer’s request remove Customers name, data and logo from Exela’s service website and/or marketing material. 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom10, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle, commonStyles.fontWeight700]}>
                R.	MISCELLANEOUS
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                The Services provided hereunder are "commercial items" as that term is defined at 48 C.F.R. 2.101 (October 1995) consisting of "commercial computer software" and "commercial computer software documentation" as such terms are used in 48 C.F.R. 12.212 (Sept 1995) and are provided to the U.S. Government only as a commercial end item. All U.S. Government End User's rights to access and use the Services are set forth in 48 C.F.R. 12.212 and 48 C.F.R. 227.7202 (June 1995).
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                These Terms may be agreed to online, by use of the Services and/or executed by electronic signature and in one or more counterparts.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                These Terms do not establish the parties as business partners or agents of the other, and neither party has the right to bind the other on any third-party agreement.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Customer may not assign any of its rights or obligations hereunder, except in connection with a merger or acquisition. Subject to the foregoing, these Terms shall be binding upon, and inure to the benefit of, the successors and assigns of the parties thereto.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Except for any payment obligations, neither Customer nor Exela will be liable for failure to perform any obligation under these Terms to the extent such failure is caused by a force majeure event (including acts of God, natural disasters, war, civil disturbance, action by a governmental entity, strike, crime, and other causes beyond the party’s reasonable control). The party affected by the force majeure event will provide notice to the other party within a commercially reasonable time and will use commercially reasonable efforts to resume performance as soon as practicable. Obligations not performed due to a force majeure event will be performed promptly after the force majeure event concludes.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                These Terms together with the "Consent To Proceed Electronically," described at Consent To Proceed Electronically and "Privacy Policy," which governs Exela’s use of Customer’s information, described at Privacy Policy,  each of which are incorporated herein, constitute the entire agreement between the parties with respect to Customer’s access and use of the Services. These Terms supersede and replace all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter and prevails over any conflicting terms contained in any documents, communications, or discussions.  All waivers must be in writing and signed by the party waiving its rights. If any part of these Terms is found unenforceable by an arbitrator or court of competent jurisdiction, the rest of these Terms will nonetheless continue in effect, and both parties agree that the unenforceable provisions will be modified so as to best accomplish the objectives of these Terms within the limits of applicable law.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Exela may revise these Terms or any additional terms and conditions that are relevant to the Services from time to time to reflect changes in the law or to the Services.  Please review the site on a regular basis to obtain timely notice of any revisions. Exela will post the revised terms on the Site with a “last updated” date.  Customer agrees that Exela shall not be liable to Customer or to any third-party for any modification of the Terms.  IF CUSTOMER CONTINUES TO USE THE SERVICES AFTER THE REVISIONS TAKE EFFECT, CUSTOMER AGREES TO BE BOUND BY THE REVISED TERMS. 
                </Text>
              </View>
            </View>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  mainHeading: {
    fontSize: 26,
  },
  pchPrivacyPolicy: {
    backgroundColor: "#fff",
  },
  pchPrivacyPolicyWrapper: {
    marginLeft:"auto", 
    marginRight: "auto", 
    padding: 16, 
    width: "80%",
    screen: ({ width }) => {
      if (width <= 991)
      return {
          width: "100%",
        };
    },
  },
  ppListing: {
    fontSize: 14,
    marginRight: 5,
    lineHeight: 28,
  },
  textStyleHolder: {
    textAlign: "justify",
  },
  textStyle: {
    lineHeight: 28,
    color: "#18191b",
  },
  ppListingSquare: {
    fontSize: 10,
    marginRight: 9,
  },
  linkColor: {
    color: "#5ccaf3",
    fontSize: 14,
  },
  logoImageContainer: {
    width: "100%",
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    boxShadow: "0px 0px 6px #00000033",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "flex",
          width: "auto",
          alignItems: "center",
          marginBottom: 16,
          paddingTop: 10,
          paddingRight: 16,
          paddingBottom: 10,
          paddingLeft: 16,
        };
    },
  },
  logoImage: {
    height: 55,
    width: 150,
    marginTop: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    display: "block",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          //	height: 48,
          //	width: 144,
        };
    },
  },
  logoIcon: {
    width: 160,
    height: 64,
    display: "block",
    backgroundSize: "contain",
  },
  icon55150: {
    height: 55,
    width: 150,
    display: "block",
    backgroundSize: "contain",
  },
  loginHeader: {
    display: "flex",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    textAlign: "center",
    alignItems: "center",
    position: "relaive",
    boxShadow: "0px 0px 6px #00000033",
  },
  mainContent: {
    marginTop: 75,
  },
  logoStyle: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});
