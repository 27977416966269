import { AsyncStorage, AsyncSessionStorage, Platform } from "../core";
import { BILL_STATUS, LOGIN_TYPE, MSG_TYPE, PLATFORM_NAME, USER_TYPE } from '../constant/app.data.constant';
import HtmlDeCode from "./HtmlDecode";
import { CLAIM_CLIENT_ID, KC_CLIENT_ID } from "../constant/app.constant";
import moment from 'moment';
import { CommonString } from "../constant/appCommonText";

const unAuthorizedMessage = CommonString.InvalidAccess;

export default class CommonUtility {
  static ascDescOrder(property, order) {
    let sort_order = 1;
    if (order === "DESC") {
      sort_order = -1;
    }
    return (a, b) => {
      let c = a[property];
      let d = b[property];
      if (c < d) {
        return -1 * sort_order;
      } else if (c > d) {
        return 1 * sort_order;
      } else {
        return 0 * sort_order;
      }
    };
  }

  static filterStatus(items, sts, appName = "XBP") {
    let arr = [...items];
    if (appName === "XBP") {
      let isBookmark = sts.includes("Priority");

      let newArr = arr.map((num) => {
        if (num.isExtentionRequested === true && sts.includes("Requested"))
          num.tempBillStatus = "Requested";
        else if (num.isExtentionApproved === true && sts.includes("Approved"))
          num.tempBillStatus = "Approved";
        else if (num.isExtentionDeclined === true && sts.includes("Rejected"))
          num.tempBillStatus = "Rejected";
        else
          num.tempBillStatus = num.billStatus;
        num.tempBillStatus = [MSG_TYPE.EXT_GRANTED, MSG_TYPE.EXT_DECLINED, MSG_TYPE.REQ_PAY_EXT].includes(num.rtpMessageType) ?
          num.tempBillStatus : !!num.paymentStatus ? num.paymentStatus : num.tempBillStatus;
        // num.tempBillStatus = num.isExtentionRequested === true && sts.includes("Requested") ? "Requested" :
        //   num.isExtentionApproved === true && sts.includes("Approved") ? "Approved" :
        //     num.isExtentionDeclined === true && sts.includes("Rejected") ? "Rejected" : num.billStatus;
        // num.tempBillStatus = !!num.paymentStatus ? num.paymentStatus : num.tempBillStatus;

        for (let i = 0; i < sts.length; i++) {
          if (!!isBookmark) {
            if (num.tempBillStatus === sts[i] || (!!num.bookmark && !BILL_STATUS[num.billStatus] && (!num.biller || !num.biller.isBlocked))) {
              return num;
            }
          } else {
            if (num.tempBillStatus === sts[i]) {
              return num;
            }
          }
        }
        return null;
      });
      const updatedArr = newArr.filter((el) => {
        return el != null;
      });
      return updatedArr;
    }
    else {
      let newArr = arr.filter((num) => {
        for (let i = 0; i < sts.length; i++) {
          if (num.billStatus === sts[i]) {
            return num;
          }
        }
        return null;
      });
      const updatedArr = newArr.filter((el) => {
        return el != null;
      });
      return updatedArr;
    }
  }

  static generate20Character() {
    let minm = 1000000;
    let maxm = 9999999;
    let chr = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let str = [...Array(13)].map((_) => chr[~~(Math.random() * chr.length)]).join("");
    let sevenD = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    return `${Date.now()}${sevenD}-${str}=`;
    // return `${Date.now()}-${str}=`;
  }

  static shortName(str) {
    if (!!str) {
      str = str.trim();
      let b = str.split(" ");
      b = b.filter(x => x);
      return b.length > 1
        ? `${b[0].charAt(0).toUpperCase()}${b[1].charAt(0).toUpperCase()}`
        : str.length > 0
          ? `${str.charAt(0).toUpperCase()}${str[str.length - 1].toUpperCase()}`
          : `${str.charAt(0).toUpperCase()}`;
    }
    else return str;
  }

  static displayBillsFilter(filterBills, selectedChk = "billDate", sortOrder = "DESC", appName = "XBP", isOpenFilter = false, selectedBU, selectedDateChk, fromDate, toDate) {
    let finalBillArray = [];
    try {

      if (appName === "XBP") {
        // parent object
        let newfilterBills = filterBills.filter(x => x !== undefined);
        let uniqueBillerName = [...new Set(newfilterBills.map(el => {
          return !!el && !!el.biller && !!el.biller.billerName ? el.biller.billerName : ""
        }))];

        uniqueBillerName.forEach(
          (billername, ind) => {
            let billsOfBiller = newfilterBills.filter((el) => {
              return el.biller.billerName === billername
            });
            // go through biller
            let billsCounter = 0;
            billsOfBiller.forEach(
              (bill) => {
                //  24 hr = 24 * 60 = 1440 min 
                let delayMin = 15 //1440; //15; //5; //1440;
                let existBillerBlockedD = new Date(bill.biller.blockedDate);
                let billerBlockedDAfter24Hr = new Date(existBillerBlockedD.setMinutes(existBillerBlockedD.getMinutes() + delayMin));

                let existPDdDate = new Date(bill.fullPaidAndDeclined);
                let PDAfter24Hr = new Date(existPDdDate.setMinutes(existPDdDate.getMinutes() + delayMin));

                // bill within 24 hr after block biller
                if (!!bill.biller && !!bill.biller.isBlocked === true && billerBlockedDAfter24Hr >= new Date() && bill.rtpMessageType !== "DeclineBlock") {

                  // paidAndDeclined date less then blocked date 
                  if (!!bill.biller.blockedDate && !!bill.fullPaidAndDeclined) {
                    if (new Date(bill.biller.blockedDate) < new Date(bill.fullPaidAndDeclined)) {
                      finalBillArray.push(bill);
                      billsCounter++
                    }
                    else if (billerBlockedDAfter24Hr >= new Date()) {
                      finalBillArray.push(bill);
                      billsCounter++
                    }
                  }
                  else {
                    finalBillArray.push(bill);
                    billsCounter++
                  }
                }
                // bills within 24 hr after paid and declined if biller not blocked
                else if (!!bill.biller && !!bill.biller.isBlocked === false && ((bill.billStatus === "Paid" && bill.paymentStatus === "Paid") || bill.billStatus === "Declined") && !!isOpenFilter ? new Date() : PDAfter24Hr >= new Date()) {
                  finalBillArray.push(bill);
                  billsCounter++
                }
                //biller not blocked and status not paid & decline
                else if (!!bill.biller && !!bill.biller.isBlocked === false && !(bill.billStatus === "Paid" && bill.paymentStatus === "Paid") && bill.billStatus !== "Declined") {
                  finalBillArray.push(bill);
                  billsCounter++
                }

              }
            );
            if (billsCounter === 0) {
              let sortedBills = billsOfBiller.sort((a, b) => new Date(b.billDate) - new Date(a.billDate))
              finalBillArray.push(sortedBills[0]);
            }
          }
        );

        if (selectedBU && selectedBU.value) {
          finalBillArray = finalBillArray.filter(bill => {
            // return bill.biller.uid === selectedBU.value ? true : false
            return `${bill.biller.billerName} (${bill.biller.buName || bill.biller.buId})` === selectedBU.label ? true : false
          })
        };

        if (!!selectedDateChk && !!fromDate && !!toDate) {
          const startDate = moment(fromDate, "MM/DD/YYYY");
          const endDate = moment(toDate, "MM/DD/YYYY");
          if (selectedDateChk === "billDate") {
            finalBillArray = finalBillArray.filter(bill => {
              const compareDate = moment(new Date(bill.billDate));
              return compareDate.isBetween(startDate, endDate, 'days', '[]') // [] = all inclusive, () = default exclusive, [) left inclusive, (] right inclusive
            })
          } else if (selectedDateChk === "dueDate") {
            finalBillArray = finalBillArray.filter(bill => {
              const compareDate = moment(bill.dueDate, "MM/DD/YYYY");
              return compareDate.isBetween(startDate, endDate, 'days', '[]') // all inclusive
            })
          }
        } else if (!!selectedDateChk && !!fromDate) {
          const startDate = moment(fromDate, "MM/DD/YYYY");
          if (selectedDateChk === "billDate") {
            finalBillArray = finalBillArray.filter(bill => {
              const compareDate = moment(new Date(bill.billDate));
              return compareDate.isSameOrAfter(startDate) // [] = all inclusive, () = default exclusive, [) left inclusive, (] right inclusive
            })
          } else if (selectedDateChk === "dueDate") {
            finalBillArray = finalBillArray.filter(bill => {
              const compareDate = moment(bill.dueDate, "MM/DD/YYYY");
              return compareDate.isSameOrAfter(startDate,) // all inclusive
            })
          }
        } else if (!!selectedDateChk && !!toDate) {
          const endDate = moment(toDate, "MM/DD/YYYY");
          if (selectedDateChk === "billDate") {
            finalBillArray = finalBillArray.filter(bill => {
              const compareDate = moment(new Date(bill.billDate));
              return compareDate.isSameOrBefore(endDate) // [] = all inclusive, () = default exclusive, [) left inclusive, (] right inclusive
            })
          } else if (selectedDateChk === "dueDate") {
            finalBillArray = finalBillArray.filter(bill => {
              const compareDate = moment(bill.dueDate, "MM/DD/YYYY");
              return compareDate.isSameOrBefore(endDate,) // all inclusive
            })
          }
        }

        return finalBillArray.sort((a, b) =>
          selectedChk === "dueDate" && sortOrder === "DESC" ?
            new Date(b.dueDate) - new Date(a.dueDate) :
            selectedChk === "dueDate" && sortOrder === "ASC" ?
              new Date(a.dueDate) - new Date(b.dueDate) :
              selectedChk === "billDate" && sortOrder === "ASC" ?
                new Date(a.billDate) - new Date(b.billDate) :
                new Date(b.billDate) - new Date(a.billDate)
        );
      }
      else {
        filterBills.forEach(
          (bill) => {
            //  24 hr = 24 * 60 = 1440 min 
            let delayMin = 15 //1440; //15; //5; //1440;
            let submittedDate = new Date(bill.submittedDate);
            submittedDate = new Date(submittedDate.setMinutes(submittedDate.getMinutes() + delayMin));

            // bill within 24 hr after block biller
            if (submittedDate >= new Date()) {
              finalBillArray.push(bill);
            }
            else {
              finalBillArray.push(bill);
            }
          }
        );

        return finalBillArray.sort((a, b) =>
          selectedChk === "submittedDate" && sortOrder === "DESC" ?
            new Date(b.submittedDate) - new Date(a.submittedDate) :
            selectedChk === "submittedDate" && sortOrder === "ASC" ?
              new Date(a.submittedDate) - new Date(b.submittedDate) :
              selectedChk === "uploadedDate" && sortOrder === "ASC" ?
                new Date(a.createdAt) - new Date(b.createdAt) :
                new Date(b.createdAt) - new Date(a.createdAt)
        );

      }
    }
    catch (err) {
      return finalBillArray;
    }
  }

  static displayHistoryBills(historyBills, currentBillDetails) {
    let finalBillArray = [];
    let delayMin = 15 //1440; // 15; // 5; //1440;
    for (let i = 0; i < historyBills.length; i++) {
      let bill = historyBills[i];
      if (bill.threadId !== currentBillDetails.threadId) {
        //  24 hr = 24 * 60  = 1440 min 
        if (!!bill.fullPaidAndDeclined) {
          let existPDdDate = new Date(bill.fullPaidAndDeclined);
          let PDAfter24Hr = new Date(existPDdDate.setMinutes(existPDdDate.getMinutes() + delayMin));

          if (bill.rtpMessageType === "DeclineBlock" || bill.rtpMessageType === "Decline" || bill.billStatus === "Declined") {
            bill.historyDate = bill.fullPaidAndDeclined;
            finalBillArray.push(bill);
          }
          else if ((bill.billStatus === "Paid" && bill.paymentStatus === "Paid") && PDAfter24Hr < new Date()) {
            bill.historyDate = bill.fullPaidAndDeclined;
            finalBillArray.push(bill);
          }
        }
      }
    }
    return finalBillArray.sort((a, b) => new Date(b.historyDate) - new Date(a.historyDate));
  }

  static displayBillsHisotryDate(bill) {
    let finalDate = new Date();
    if ((bill.billStatus === "Paid" && bill.paymentStatus === "Paid") || bill.billStatus === "Declined") {
      finalDate = !!bill.fullPaidAndDeclined ? new Date(bill.fullPaidAndDeclined) : new Date();
    }
    return finalDate;
  }

  static getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) return PLATFORM_NAME.Window;
    if (/android/i.test(userAgent)) return PLATFORM_NAME.Android;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return PLATFORM_NAME.IOS;
    return PLATFORM_NAME.UNKNOWN;
  }

  static mobileNumberFormatting(value) {
    if (value) {
      let splitPhoneNumber = value.split(" ");
      if (splitPhoneNumber[1]) {
        let match = splitPhoneNumber[1].match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          match[1] = splitPhoneNumber[0] + " ";
          return [match[1], "(", match[2], ") ", match[3], "-", match[4]].join("");
        }
      } else {
        let match = splitPhoneNumber[0].match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          match[1] = "+1 ";
          return [match[1], "(", match[2], ") ", match[3], "-", match[4]].join("");
        }
      }
    }
    return value;
  }
  static randomPassword() {
    return Math.random()
      .toString(36)
      .slice(-8);
  }

  static async setWithExpiry(key, value, ttl, scode) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
      scode: scode,
      isEncrypt: true
    }
    await AsyncStorage.setItem(key, JSON.stringify(item))
  }

  static async getWithExpiry(key) {
    const itemStr = await AsyncStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      await AsyncStorage.removeItem(key)
      return null
    }
    return item;
  }

  static async htmlDecode(input) {
    return await HtmlDeCode.htmlDecode(input);
  }

  static htmlDecodeSanitize(input) {
    return HtmlDeCode.htmlDecodeSanitize(input);
  }

  static getUTCDate(currentDate) {
    if (!currentDate)
      currentDate = new Date();

    let cDate = currentDate.getUTCDate();
    let cMonth = currentDate.getUTCMonth();
    let cYear = currentDate.getUTCFullYear();
    return new Date(cYear, cMonth, cDate);
  }

  static compareDueDate(date) {
    let currentDate = this.getUTCDate();

    let dueDate = new Date(date);
    let dDate = dueDate.getDate();
    let dMonth = dueDate.getMonth();
    let dYear = dueDate.getFullYear();

    if (new Date(dYear, dMonth, dDate) < currentDate)
      return true;
    else
      return false;
  }

  static async updateSubRole(app, userLoginDetail) {
    app.dm.userSession.setState({
      pchRegistrationTypeDoctor: false,
      pchRegistrationTypePatient: true,
    });
    const userType = USER_TYPE.PCH_PATIENT;
    const updateUserReqBody = {
      uid: userLoginDetail.uid,
      subRole: [{ appName: "CLAIMS", appRole: userType }],
    };
    let res = await app.dm.userRegistration.updateUserDetails(updateUserReqBody, true);
    if (res) {
      userLoginDetail.userType = userType;
      await app.dm.userSession.updateSMBOrganization(userLoginDetail);
      app.dm.userSession.setState({
        userLoginDetail: userLoginDetail
      })
      app.dm.userLoginDomain.setState({
        socialLoginProfile: null,
        socialLoginType: "",
      });
      app.nav.to("patientDashboard");
      return false;
    }
  };

  static async getKCToken(app, payload) {
    let isClaims = !!app.route._path ? app.route._path.indexOf('claims') > - 1 : false;
    try {
      let response = await app.dm.kcAPI.authToken(payload, isClaims);
      const { audience } = app.dm.kcAPI.state;
      if (!!response && typeof response === "boolean") {
        if (!!audience && audience.includes(isClaims ? CLAIM_CLIENT_ID : KC_CLIENT_ID)) {
          const userLoginDetail = await app.dm.rest.getUser();
          if (!!isClaims) {
            const { subRoleUpdate } = app.dm.userSession.state;
            if (userLoginDetail.userType === "" && subRoleUpdate === true)
              this.updateSubRole(app, userLoginDetail);
            else {
              if (!userLoginDetail.isActive || userLoginDetail.isActive === "false" || userLoginDetail.isActive === false) {
                app.dm.commonDomain.showToastr("error", CommonString.InActiveAccount);
                app.nav.to(!!isClaims ? "pchLogin" : "login", { message: CommonString.InActiveAccount });
                return false;
              }
              app.dm.userLoginDomain.setState({
                socialLoginProfile: null,
                socialLoginType: "",
              });
              let returnUrl = (app.route._data && app.route._data.query && app.route._data.query.ReturnUrl) ? app.route._data.query.ReturnUrl : "";
              let directUrl = app.route._location.search;
              if (directUrl.indexOf("?ReturnUrl=") > -1) {
                returnUrl = directUrl.split('&').find(x => x.indexOf('ReturnUrl='))
                if (returnUrl.indexOf("medical-bills") > -1) {
                  let uid = returnUrl.split("/");
                  uid = uid[uid.length - 1];
                  app.nav.to("uploadedBillsRoute", { uid: uid });
                  return false;
                }
              }
              else
                app.nav.to(userLoginDetail.userType === USER_TYPE.PCH_PROVIDER ? "uploadBillsRoute" : "patientDashboard");
            }
          }
          else {
            let returnUrl = (app.route._data && app.route._data.query && app.route._data.query.ReturnUrl) ? app.route._data.query.ReturnUrl : "";
            let directUrl = app.route._location.search;
            if (directUrl.indexOf("?ReturnUrl=") > -1) {
              returnUrl = directUrl.split('&').find(x => x.indexOf('ReturnUrl='))
              if (returnUrl.indexOf("bill-details") > -1) {
                let threadId = returnUrl.split("/");
                threadId = threadId[threadId.length - 1];
                await app.dm.dashboard.setCompiledMsgList();
                const { compiledMsgList } = app.dm.dashboard.state;
                if (!!compiledMsgList.length) {
                  let item = compiledMsgList.filter((message) => message.threadId === threadId);
                  if (!!item.length) {
                    app.dm.billDetails.setState({
                      billDetails: item[0]
                    });
                    AsyncStorage.setItem("homeScreen", "dashboard");
                    app.nav.to("billDetailsRoute", { threadId: threadId });
                    return false;
                  } else {
                    app.nav.to("unauthorize", { content: CommonString.InvalidBill });
                    return false;
                  }
                } else {
                  app.nav.to("unauthorize", { content: CommonString.InvalidBill });
                  return false;
                }
              } else if (returnUrl.indexOf("t2p") > -1) {
                let msgMetaIdentifier = returnUrl.split("/");
                msgMetaIdentifier = msgMetaIdentifier[msgMetaIdentifier.length - 1]
                app.nav.to("textToPay", { messageMetaIdentifier: msgMetaIdentifier })
              } else {
                app.nav.to("unauthorize", { content: CommonString.InvalidBill });
                return false;
              }
            } else {
              app.nav.to("dashboard");
            }
          }
          return false;
        }
        else {
          await app.dm.userSession.logout();
          if (Platform.OS === "web")
            app.nav.to("login", { message: unAuthorizedMessage });
          else
            app.nav.to("unauthorize");
          return false;
        }
      }
      else if (!!response && 'success' in response && !response.success) {
        if (response.message === "NOT-IN-XBP") {
          await AsyncSessionStorage.setItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN, response.access_token);
          await AsyncSessionStorage.setItem(CommonString.LOCAL_STORAGE_REFRESHTOKEN, response.refresh_token);
          let userLoginDetail = response.userLoginDetail;
          if (!!response.userBUDetails && response.userBUDetails.length > 0)
            userLoginDetail = { ...response.userLoginDetail, ...response.userBUDetails[0] };
          app.dm.userLoginDomain.setState({
            access_token: response.access_token,
            refresh_token: response.refresh_token,
            socialLoginProfile: userLoginDetail,
            socialLoginType: LOGIN_TYPE.KEYCLOAK
          })
          if (!!audience && audience.includes(isClaims ? CLAIM_CLIENT_ID : KC_CLIENT_ID)) {
            // app.nav.to(!!isClaims ? "pchSignup" : "registration", { type: "new" });
            //below 2 lines.
            await app.dm.userSession.logout(response.access_token, response.refresh_token);
            app.nav.to(!!isClaims ? "pchLogin" : "login");
            return false;
          }
          else {
            await app.dm.userSession.logout(response.access_token, response.refresh_token);
            if (Platform.OS === "web")
              app.nav.to(!!isClaims ? "pchLogin" : "login", { message: unAuthorizedMessage });
            else
              app.nav.to("unauthorize");
            return false;
          }
        }
        else {
          await app.dm.userSession.logout(response.access_token, response.refresh_token);
          if (response.message.indexOf('invalid_grant') > -1) {
            app.nav.to(!!isClaims ? "pchLogin" : "login");
            return false;
          }
          else {
            app.dm.commonDomain.showToastr("error", response.message);
            app.nav.to(!!isClaims ? "pchLogin" : "login", { message: response.message });
            return false;
          }
        }
      }
      else {
        await app.dm.userSession.logout(response.access_token, response.refresh_token);
        if (response.message.indexOf('invalid_grant') > -1) {
          app.nav.to(!!isClaims ? "pchLogin" : "login");
          return false;
        }
        else {
          app.dm.commonDomain.showToastr("error", response.message);
          app.nav.to(!!isClaims ? "pchLogin" : "login", { message: response.message });
          return false;
        }
      }
    } catch (error) {
      console.log("Token Error  ", error)
      await app.dm.userSession.logout();
      app.nav.to(!!isClaims ? "pchLogin" : "login", { message: error.message });
      return false;
    }
  }

  static async zipCodeFormater(form, key, text) {
    // trim exceeding char
    let val = text.replace(/[^0-9]/g, "").substring(0, 10);
    if (val.length > 5) {
      form.elmts[key].value = val.substring(0, 5) + "-" + val.substring(5, 9);
    } else {
      form.elmts[key].value = val;
    }
  };

  static async isValidUrl(urlString) {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
  }

  static groupBySum(items, sumProp, groupByProp) {
    var result = [];
    if (!!groupByProp) {
      items.reduce(function (res, value) {
        if (!res[value[groupByProp]]) {
          res[value[groupByProp]] = { groupByProp: value[groupByProp], sumProp: 0 };
          result.push(res[value[groupByProp]])
        }
        res[value[groupByProp]].sumProp += parseFloat(value[sumProp] || 0 );
        return res;
      }, {});
      return result;
    }
    else {
      return items.reduce(function (a, b) {
        return a + parseFloat(b[sumProp] || 0);
      }, 0);
    }
  }

  static formatAmount(number, minimumFractionDigits = 2, maximumFractionDigits = 2) {
    // Check if number is not defined or is NaN
		if (typeof number === 'undefined' || isNaN(number)) {
			return 'N/A';
		}

		// Convert number to string and handle non-decimal numbers
		let numberString = isNaN(parseFloat(number)) ? number.toString() : parseFloat(number).toFixed(maximumFractionDigits);

		// Split the integer and fractional parts
		let parts = numberString.split('.');
		let integerPart = parts[0];
		let fractionalPart = parts.length > 1 ? '.' + parts[1] : '';

		// Add commas for thousands separator
		integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

		// Adjust fractional part length
		if (fractionalPart.length < minimumFractionDigits + 1) {
			fractionalPart += '0'.repeat(minimumFractionDigits - fractionalPart.length + 1);
		} else if (fractionalPart.length > maximumFractionDigits + 1) {
			fractionalPart = fractionalPart.slice(0, maximumFractionDigits + 1);
		}

		return integerPart + fractionalPart;
	}

  static convertNumberToWords(number) {
    var special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];
    if (number < 20) return special[number];
    if (number % 10 === 0) return deca[Math.floor(number / 10) - 2] + 'ieth';
    return deca[Math.floor(number / 10) - 2] + 'y-' + special[number % 10];
  }

  static textSubstring(text = "", maxDigit = 25, platform = "all") {   // platform =  all/web/native
    // for web only
    if(platform === "web" && !!text && Platform.OS === "web"){
        return text.length >= maxDigit ?  `${text.substr(0, maxDigit - 3)}...` : text;
    // for native only
    } else if(platform === "native" && !!text && Platform.OS !== "web"){
        return text.length >= maxDigit ?  `${text.substr(0, maxDigit - 3)}...` : text;
    // for all (web+native)
    } else if(platform === "all" && !!text){
        return text.length >= maxDigit ?  `${text.substr(0, maxDigit - 3)}...` : text;
    } else {
        return text;
    };
  };

  static MobileFormatText(number) {
    if (number) {
      let splitPhoneNumber = number.split(" ");
      if (splitPhoneNumber[1]) {
        let match = splitPhoneNumber[1].match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          let formattedString = "";
          match[1] = splitPhoneNumber[0] + " ";
          formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
          return formattedString;
        }
      } else {
        let match = splitPhoneNumber[0].match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          let formattedString = "";
          formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
          return formattedString;
        }
      }
    }
    return number;
  }
}
