import React from "react";
import R14, {
  View,
  StyleSheet,
  Colors,
  Touchable,
  Image,
  Text,
  Form,
  TextInputField,
  SafeAreaView,
  AsyncStorage,
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { fontFamilyStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import IdleTimer from "react-idle-timer";
import CommonUtility from "../utils/CommonUtility";
import InAppScanner from "./InAppScanner";
import { BILL_OPTS, LOGIN_TYPE, MESSAGE_TYPE, USER_TYPE } from "../constant/app.data.constant";
import UserAgentValidator from "../utils/UserAgentValidator";
import { uberDocThemeStyle } from "../assets/css/clients/uberDocThemeStyle";
import Linking from "../utils/Linking";
import { CommonString } from "../constant/appCommonText";
import PopupModalComponent from "./PopupModalComponent";
import { analyticsLogs } from "../utils/AnalyticLogs";
import PCHLoaderComponent from "./PCH/PCHLoaderComponent";

const notificationIcon = require("../assets/images/notification.png");
const qrCodeScan = require("../assets/images/qrCodeScan.png");
const switchOrg = require("../assets/images/icManageInsuranceCoB.png");
const icLogoutB = require("../assets/images/icLogoutB.png");
const icPractitionerProfileB = require("../assets/images/icPractitionerProfileB.png");
const back_icon = require("../assets/images/back_icon.png");
const historyIcon = require("../assets/images/historyIcon.png");
const grayHistoryIcon = require("../assets/images/grayHistoryIcon.png");
const defaultSec = 10;

export default R14.connect(
  class HeaderControlsRight extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isSearchHistoryOpen: false,
        selectedHistoryString: "",
        isInactive: true,
        bShowConcurrentLoginPopUp: false,
        lastReadDateTime: new Date(),
        lastReadNotificationTime: null,
        isLoaderVisible: false
      };
      this.currentScreen = "";
      this.oldScreen = "";
      this.searchHistory = [];
      this.localSearchHistory = [];
      //For Idle Timer.
      this.idleTimer = null;
      this.handleOnAction = this.handleOnAction.bind(this);
      this.handleOnActive = this.handleOnActive.bind(this);
      this.handleOnIdle = this.handleOnIdle.bind(this);
      this.handleLogoutPress = this.handleLogoutPress.bind(this);
      this.generateNewSessionString = this.generateNewSessionString.bind(this);
      // this.handleOnBeforeUnload = this.handleOnBeforeUnload.bind(this);
      // For concurrent login.
      // this.handleConcurrentUserLoginEvent = this.handleConcurrentUserLoginEvent.bind(this);
      // this.handleOnload = this.handleOnload.bind(this);
      this.userLoginDetail = {};
      this.userBUDetail = {};
      this.loginType = "";

    }

    async initialize() {
      const { userLoginDetail } = this.props.app.dm.userSession.state;
      this.userLoginDetail = await this.props.app.dm.rest.getUser();
      if (!!!this.userLoginDetail)
        this.userLoginDetail = userLoginDetail;
      this.loginType = await AsyncStorage.getItem("loginType");
      const { userBUDetails } = this.props.app.dm.userSession.state;
      let userBUs = await this.props.app.dm.rest.getuserBUDetails();
      this.userBUDetail = !!userBUs && userBUs.length > 0 ? userBUs[0] : {};
      if (!!!this.userBUDetail)
        this.userBUDetail = userBUDetails[0];

      this.setState({ initialized: true });
    }

    componentDidMount = async () => {
      this.initialize();
      this.props.app.dm.notificationDomain.setState({
        isMobileDevice: UserAgentValidator.isMobileDevice(),
      });
      setInterval(async () => {
        this.initialize();
        let currentDate = new Date()
        let lastCallTime = this.state.lastReadNotificationTime;
        if (this.state.lastReadNotificationTime === null || lastCallTime <= currentDate) {
          await this.props.app.dm.notificationDomain.notificationDetail(true);
        }
      }, 1000 * defaultSec);
    }

    // handleConcurrentUserLoginEvent = () => {
    //   let isLoggedIn = this.props.app.dm.userSession.isLoggedIn;
    //   if (isLoggedIn) {
    //     // DOM Load Event
    //     window.addEventListener("load", (ev) => {
    //       if (!this.state.bShowConcurrentLoginPopUp) {
    //         this.sessionChecker();
    //       }
    //     });
    //     // Mouse Click Event
    //     document.addEventListener("mouseup", (ev) => {
    //       if (!this.state.bShowConcurrentLoginPopUp) {
    //         this.sessionChecker();
    //       }
    //     });
    //   }
    // };

    navToSubscription = async () => {
      this.props.app.dm.dashboard.setState({ isSubscriptionValid: true });
      this.props.app.nav.to("subscriptionRoute");
      return false;
    }

    handleLogoutPress = async () => {
      let appointment = await AsyncStorage.getItem("appointment");
      let loginType = this.loginType;
      let redirect_url = await AsyncStorage.getItem("REDIRECT_URI");
      let _sign = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION);
      analyticsLogs(CommonString.LOG_OUT, `${CommonString.User_Logged_Out}`, this.props.app);
      if (!!redirect_url && (!!this.props.app.dm.userSession.state.isUberDocs || appointment || loginType === LOGIN_TYPE.UBERDOC)) {
        let access_token = await this.props.app.dm.userSession.getAccessToken();
        Linking.LinkingURL(`${redirect_url}&appointment=${appointment}&errorMessage=${encodeURIComponent("Session has been expired")}&access_token=${access_token}`, "_self")
        await this.props.app.dm.dashboard.resetDashboardDomain();
      }
      else if (!!redirect_url && (!!this.props.app.dm.userSession.state.isExternalLogin || loginType === LOGIN_TYPE.PCHGLOBAL)) {
        let access_token = await this.props.app.dm.userSession.getAccessToken();
        Linking.LinkingURL(`${redirect_url}?access_token=${access_token}`, "_self")
        await this.props.app.dm.dashboard.resetDashboardDomain();
      }
      else {
        this.props.app.dm.userSession.setState({ isLoggedOut: false, });
        await this.props.app.dm.userSession.logout();
        //Handle xbp/pch logout
          this.props.app.nav.to(_sign === USER_TYPE.CLAIMS ? "pchLogin": "login");
      }
      return false;
    };

    closeLogoutScreen = async () => {
      this.props.app.dm.userSession.setState({ isLoggedOut: false, });
      this.props.app.nav.back();
    }

    sessionChecker = () => {
      if (!!this.props.app.dm.userSession.isLoggedIn) {
        let lastCallTime = new Date(this.state.lastReadDateTime);
        lastCallTime = new Date(lastCallTime.setMinutes(lastCallTime.getMinutes() + 2)); //5
        if (lastCallTime <= new Date()) {
          this.setState({
            lastReadDateTime: new Date(),
          });
          this.props.app.dm.userSession.isSessionStringValid().then((result) => {
            if (!!result.tokenIsValid && result.isConcurrentLogin === true) {
              this.setState({
                bShowConcurrentLoginPopUp: true,
              });
            }
            else if (!!!result.tokenIsValid) {
              this.props.app.dm.dashboard.setState({
                sessionInActive: true
              });
            }
          });
        }
      }
    };

    generateNewSessionString = async () => {

      if (this.userLoginDetail !== null && this.userLoginDetail !== undefined) {
        let res = await this.props.app.dm.userSession.createSessionString(this.userLoginDetail);
        if (res) {
          this.props.app.dm.commonDomain.showToastr("success", CommonString.SessionUpdated);
          this.setState({ bShowConcurrentLoginPopUp: false });
        } else {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
      }
    };

    // Get all History Data
    async searchHistoryData() {
      if (this.userLoginDetail !== null && this.userLoginDetail !== undefined && !!this.userLoginDetail.pid && this.userLoginDetail.userType === USER_TYPE.XBP) {
        const searchHistory = await this.props.app.dm.userSearchDomain.getLastSearchHistory(
          this.userLoginDetail.pid,
          3,
          this.currentScreen.name,
          "",
          "DESC",
          false
        );
        this.props.app.dm.userSearchDomain.setState({ localSearchHistory: searchHistory || [], searchHistory: searchHistory || [] });
      }
    }

    searchToggal = async () => {
      let form = this.props.app.ui.form("searchBill");
      if (!!form && !!form.elmts && !!form.elmts.searchText) {
        form.elmts.searchText.setValue("");
        this.closeSearchHistory();
        this.props.app.dm.userSearchDomain.setState({
          isSearchOpen: !this.props.app.dm.userSearchDomain.state.isSearchOpen,
          textInSearchBox: "",
          currentOpenScreen: this.currentScreen.name,
        });
        this.props.app.dm.userSearchDomain.keyTypeSearch("", this.currentScreen.name);
        if (!!this.props.app.dm.userSearchDomain.state.isSearchOpen)
          await this.searchHistoryData();
        if (!!this.props.app.dm.userSession.state.leftMenuOverlay && this.props.app.dm.notificationDomain.state.isMobileDevice)
          this.props.app.dm.userSession.setState({
            leftMenuToggle: !this.props.app.dm.userSession.state.leftMenuToggle,
            leftMenuOverlay: !this.props.app.dm.userSession.state.leftMenuOverlay,
          });
      }
    };

    searchTextClear = () => {
      let form = this.props.app.ui.form("searchBill");
      form.elmts.searchText.setValue("");
      this.props.app.dm.userSearchDomain.setState({
        textInSearchBox: "",
        currentOpenScreen: this.currentScreen.name,
      });
      this.props.app.dm.userSearchDomain.keyTypeSearch("", this.currentScreen.name);
    };

    searchCloseToggal = () => {
      let currentRoute = this.props.r14.navigation.activeRouteName;
      if (currentRoute === "dashboard") {
        if (!!!this.state.isSearchHistoryOpen || this.props.app.dm.userSearchDomain.state.searchHistory.length === 0) {
          let form = this.props.app.ui.form("searchBill");
          form.elmts.searchText.setValue("");
          this.props.app.dm.userSearchDomain.setState({
            isSearchOpen: false,
            textInSearchBox: "",
            currentOpenScreen: this.currentScreen.name,
          });
          this.props.app.dm.userSearchDomain.keyTypeSearch("", this.currentScreen.name);
        }
        else {
          this.setState({
            isSearchHistoryOpen: false,
          });
        }
      }
      else {
        let form = this.props.app.ui.form("searchBill");
        form.elmts.searchText.setValue("");
        this.props.app.dm.userSearchDomain.setState({
          isSearchOpen: false,
          textInSearchBox: "",
          currentOpenScreen: this.currentScreen.name,
        });
        this.props.app.dm.userSearchDomain.keyTypeSearch("", this.currentScreen.name);
      }
      // Changes done because issue has been reported for search section is overlapping on bulkpay link
    }

    openSearchHistory = () => {
      this.setState({
        isSearchHistoryOpen: true,
      });
    };

    closeSearchHistory = () => {
      this.setState({
        isSearchHistoryOpen: false,
      });
    };

    saveSearchString = (val) => {
      if (!!val && !!val.trim())
        this.props.app.dm.userSearchDomain.CreateUserSearchHistory(this.userLoginDetail.pid, this.currentScreen.name, val.trim());
      // this.closeSearchHistory();
    }

    onSearch = (val) => {
      const searchHistory = this.props.app.dm.userSearchDomain.state.localSearchHistory.filter(x => x.searchedString.indexOf(val) > -1);
      // this.searchHistory = this.localSearchHistory;
      this.props.app.dm.userSearchDomain.keyTypeSearch(val, this.currentScreen.name);
      this.props.app.dm.userSearchDomain.setState({
        isSearchOpen: true,
        textInSearchBox: val,
        currentOpenScreen: this.currentScreen.name,
        searchHistory,
      });
    };

    onHistoryString = (historyKeyword) => {
      let form = this.props.app.ui.form("searchBill");
      form.elmts.searchText.setValue(historyKeyword);
      this.props.app.dm.userSearchDomain.setState({
        textInSearchBox: historyKeyword,
        currentOpenScreen: this.currentScreen.name,
      });
      this.props.app.dm.userSearchDomain.keyTypeSearch(historyKeyword, this.currentScreen.name);
      this.saveSearchString(historyKeyword);
      this.closeSearchHistory();
    }

    goToNotification = () => {
      analyticsLogs(CommonString.HEADER_RIGHT, `${CommonString.CLICKED_NOTIFICATION}`, this.props.app);
      this.props.app.nav.to("notificationScreenRoute");
      if (!!this.props.app.dm.userSession.state.leftMenuOverlay && this.props.app.dm.notificationDomain.state.isMobileDevice)
        this.props.app.dm.userSession.setState({
          leftMenuToggle: !this.props.app.dm.userSession.state.leftMenuToggle,
          leftMenuOverlay: !this.props.app.dm.userSession.state.leftMenuOverlay,
        });
    };
    goToCreateMessageScreen = () => {
      this.props.app.nav.to("addMessageScreenRoute");
      if (!!this.props.app.dm.userSession.state.leftMenuOverlay && this.props.app.dm.notificationDomain.state.isMobileDevice)
        this.props.app.dm.userSession.setState({
          leftMenuToggle: !this.props.app.dm.userSession.state.leftMenuToggle,
          leftMenuOverlay: !this.props.app.dm.userSession.state.leftMenuOverlay,
        });
    };
    
    renderNotification = (commonFontStyles) => {
      const { selectedScreen } = this.props.app.dm.dashboard.state;
      if (selectedScreen === BILL_OPTS[0].value) {
        const { notificationCount } = this.props.app.dm.notificationDomain.state;
        const notifilen = notificationCount > 0 ? notificationCount.toFixed().length : 0
        return (
          <Touchable style={[commonStyles.marginLeft10]} onPress={this.goToNotification}>
            <View style={[commonStyles.notificationHolder]}>
              <View>
                <Image source={notificationIcon} style={commonStyles.icon18} />
              </View>
              {notificationCount > 0 &&
                <View style={[
                  commonStyles.notificationSection,
                  notifilen === 3 ? styles.threeDigiNotification : null,
                  notifilen === 4 ? styles.fourDigiNotification : null,
                ]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.whiteColor]}>{notificationCount}</Text>
                </View>
              }
            </View>
          </Touchable>
        );
      }
    };

    renderQRCodeSection = () => {
      const { selectedScreen } = this.props.app.dm.dashboard.state;
      if (selectedScreen === BILL_OPTS[0].value)
        return (
          <Touchable onPress={this.scanningQRCode}>
            <View>
              <Image source={qrCodeScan} style={commonStyles.icon18} />
            </View>
          </Touchable>
        );
    };

    switchOrganization = () => {
      analyticsLogs(CommonString.Switch_Organization, `${CommonString.CLICKED_SWITCH_ORG}`, this.props.app);
      this.props.app.nav.to("organizationRoute");
      return false;
    }

    renderOrganization = (commonFontStyles) => {
      let org = this.props.app.dm.userSession.state.defaultOrg;
      if (!org)
        org = this.userLoginDetail.defaultOrg;
      return (
        <>
          {!!org ?
            <View style={[commonStyles.dFlex, commonStyles.flexDirectionColumn]}>
              <Text style={[commonStyles.smallHeading, commonStyles.fontWeight400, commonFontStyles.workSans]}>
                Org Name:
              </Text>
              <Text style={[commonStyles.exSmallHeading, commonStyles.fontWeight600, commonFontStyles.workSans]}>
                {org.orgName}
              </Text>
            </View>
            : null}
          <Touchable style={[styles.marginLeft24]} onPress={this.switchOrganization}>
            <View>
              <Image source={switchOrg} style={commonStyles.icon18} />
            </View>
          </Touchable>
        </>
      );
    };

    onPressOpenProfileDetails = () => {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      if (isMobileDevice)
        this.props.app.dm.userSession.setState({
          leftMenuToggle: false,
          leftMenuOverlay: false
        })
      this.props.app.dm.userSession.setState({
        isProfileDetailsOpen: !this.props.app.dm.userSession.state.isProfileDetailsOpen,
      })
    }
    onPressCloseProfileDetails = () => {
      this.props.app.dm.userSession.setState({
        isProfileDetailsOpen: false,
      });
    }

    navToProfile = () => {
      const { userType } = this.userLoginDetail;
      if (userType === USER_TYPE.XBP) {
        this.props.app.nav.to("profileViewRoute");
      } else {
        this.props.app.nav.to("pchProfileViewRoute");
      }
      this.props.app.dm.userSession.setState({
        isProfileDetailsOpen: false,
      });
    }
    navToLogout = () => {
      this.props.app.nav.to("loginOut");
      this.props.app.dm.userSession.setState({
        isProfileDetailsOpen: false,
      });
    }

    renderPCHLogoutSection = (commonFontStyles) => {
      const { isProfileDetailsOpen } = this.props.app.dm.userSession.state;
      const { userType } = this.userLoginDetail;
      let { firstName, lastName } = this.userBUDetail;
      firstName = !!firstName ? firstName : "";
      lastName = !!lastName ? lastName : "";
      let displayName = CommonUtility.shortName(`${firstName} ${lastName}`)
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <>
          <View style={[styles.profileDetailSectionWrap, commonStyles.justifyContentFlexEnd]}>
            <Touchable style={[styles.marginLeft24]} onPress={this.onPressOpenProfileDetails}>
              <View style={[styles.profileSection]}>
                <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight500]}>
                  {displayName}
                </Text>
              </View>
            </Touchable>
            {isProfileDetailsOpen ? (
              <View style={[styles.profileDetailSection, isMobileDevice ? userType === USER_TYPE.PCH_PROVIDER ? styles.styleForMobileProvider : styles.styleForMobilePatient : null]}>
                {isMobileDevice ? null : (
                  <>
                    <View style={[styles.blueBrackground]}></View>
                    <View style={[styles.profileSectionInner]}>
                      <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight500]}>
                        {displayName}
                      </Text>
                    </View>
                    <View style={[styles.profileSectionName]}>
                      <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight500]}>
                        {`${firstName} ${lastName}`}
                      </Text>
                    </View>
                  </>
                )}
                {userType !== USER_TYPE.XBP_ADMIN ?
                  <Touchable style={[styles.profileSectionLinks]} onPress={this.navToProfile}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                      <Image source={icPractitionerProfileB} style={[styles.menuIconsInprofilesection, commonStyles.marginRight10]} />
                      <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, styles.linkColor, commonStyles.fontWeight500]}>
                        {userType === USER_TYPE.PCH_PROVIDER ? "Practice Group Profile" : userType === USER_TYPE.PCH_PATIENT ? "Patient Profile" : "Profile"}
                      </Text>
                    </View>
                  </Touchable> : null}
                <Touchable style={[styles.profileSectionLinks]} onPress={this.navToLogout}>
                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                    <Image source={icLogoutB} style={[styles.menuIconsInprofilesection, commonStyles.marginRight10]} />
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, styles.linkColor, commonStyles.fontWeight500]}>Logout</Text>
                  </View>
                </Touchable>
              </View>
            ) : null}
          </View>
          {isProfileDetailsOpen ? (
            <Touchable onPress={this.onPressCloseProfileDetails} style={[styles.profileOverlaySection]}>
              <View></View>
            </Touchable>
          ) : null}
        </>
      );
    };

    renderSearchSection = (commonFontStyles) => {
      const { isSearchOpen, searchHistory } = this.props.app.dm.userSearchDomain.state;
      return (
        <View style={[commonStyles.marginLeftRight5, commonStyles.marginLeftRight5TopBottom10, !isSearchOpen && commonStyles.displayNone]}>
          <Form name="searchBill" style={[commonStyles.padding0, commonStyles.flexGrow1]}>
            <View style={[styles.searchBillInnerWrap]}>
              <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.flexDirectionRow]}>
                <Touchable style={[commonStyles.marginLeftRight10]} onPress={this.searchCloseToggal}>
                  <View>
                    <Image source={back_icon} style={[commonStyles.icon18, commonStyles.marginTop6]} />
                  </View>
                </Touchable>
                <View style={[styles.textFieldOuter, commonStyles.flexGrow1]}>
                  <TextInputField
                    name="searchText"
                    value={""}
                    style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.margin0, customCommonStyles.inputHeight]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    autoFocus
                    onFocus={this.openSearchHistory}
                    iconRight={!!this.props.app.dm.userSearchDomain.state.textInSearchBox ? "close" : ""}
                    onIconRightPress={this.searchTextClear}
                    onChangeText={(val) => {
                      this.onSearch(val);
                    }}
                    onBlur={() => {
                      let form = this.props.app.ui.form("searchBill");
                      if (!!form) {
                        this.saveSearchString(form.elmts.searchText.value);
                      }
                    }}
                  />
                </View>
                {/* <Touchable style={[commonStyles.marginLeftRight20]} onPress={this.searchTextClear}>
                  <View>
                  <Image source={closeIcon} style={commonStyles.icon14} />
                  </View>
                </Touchable> */}
              </View>
            </View>
            {
              !!this.state.isSearchHistoryOpen && !!searchHistory ?
                searchHistory.map((el, index) => {
                  return (
                    <View key={"search_" + index} style={[commonStyles.whiteBackgroundColor, styles.autoCompleteBox]}>
                      <Touchable
                        onPress={() => {
                          this.onHistoryString(el.searchedString);
                        }}>
                        <View
                          style={[
                            commonStyles.flex1,
                            commonStyles.flexDirectionRow,
                            commonStyles.paddingTopBottom5,
                            commonStyles.alignItemsCenter,
                            styles.noStyle
                          ]}
                        >
                          <View>
                            <Image source={grayHistoryIcon} style={[commonStyles.icon16, commonStyles.marginRight25, styles.marginLeft24]} />
                          </View>
                          <View>
                            <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>
                              {el.searchedString}
                            </Text>
                          </View>
                        </View>
                      </Touchable>
                    </View>
                  )
                })
                : null
            }
          </Form>
        </View>
      );
    };

    onFilterOpen = () => {
      this.props.app.dm.dashboard.setState({
        isBlockNavigation: false,
      });
      this.props.app.dm.userSearchDomain.setState({
        isSearchOpen: false,
        textInSearchBox: "",
        currentOpenScreen: this.currentScreen.name,
      });
      this.props.app.dm.filterDomain.setState({ isFilterOpen: true });
    };
    // onRestoreFilter = () => {
    //   const { compiledMsgList } = this.props.app.dm.dashboard.state;
    //   this.props.app.dm.dashboard.setState({ filterBills: compiledMsgList });
    //   this.props.app.dm.filterDomain.resetFilter();
    // };

    gotDevices = (mediaDevices) =>
      new Promise((resolve, reject) => {
        const availableVideoInputs = [];
        mediaDevices.forEach((mediaDevice) => {
          if (mediaDevice.kind === "videoinput") {
            // audioinput //videoinput
            availableVideoInputs.push({
              deviceId: mediaDevice.deviceId,
              label: mediaDevice.label,
            });
          }
        });

        if (availableVideoInputs.length > 0) {
          resolve(availableVideoInputs);
        } else {
          reject(new Error("ERR::NO_MEDIA_TO_STREAM"));
        }
      });

    getMedia = async (constraints) => {
      try {
        await navigator.mediaDevices.getUserMedia(constraints);
        /* use the stream */
      } catch (err) {
        console.log(err);
      }
    };

    resolvePermission = (permission) =>
      new Promise((resolve, reject) => {
        permission.onchange = function () {
          console.log("Permission changed to " + permission.state);
        };

        const permissions = [permission.state];
        if (permissions.length > 0) {
          resolve(permissions);
        } else {
          reject(new Error("ERR::NO_MEDIA_TO_STREAM_PERMISSION"));
        }
      });

    getPermission = async (PermissionDescriptor) => {
      try {
        navigator.permissions
          .query(PermissionDescriptor)
          .then(this.resolvePermission)
          .then((permission) => {
            if (permission.includes("granted")) {
              this.props.app.dm.dashboard.setState({
                IsScannerVisible: true,
                isAlertBox: false,
                alertMessage: "",
                alertMessageHeading: "Scanning",
                retryCount: 0,
              });
            } else if (permission.includes("prompt") || permission.includes("denied")) {
              this.props.app.dm.dashboard.setState({
                IsScannerVisible: true,
                isAlertBox: false,
                alertMessage: "",
                alertMessageHeading: "Scanning",
                retryCount: 0,
              });
              this.props.app.dm.dashboard.setState({
                IsScannerVisible: true,
                isAlertBox: true, //true
                alertMessage: "Allow XBP to take pictures and record video",
                alertMessageHeading: "Allow XBP to use camera",
                retryCount: 0,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) { }
    };

    scanningQRCode = () => {
      analyticsLogs(CommonString.Scan_QR_Code, `${CommonString.CLICKED_QR_SCAN}`, this.props.app);
      navigator.mediaDevices
        .enumerateDevices()
        .then(this.gotDevices)
        .then((availableVideoInputs) => {
          if (availableVideoInputs.length > 0) {
            //  this.getPermission({name:"microphone"})
            this.getPermission({ name: "camera" });
          }
        })
        .catch((err) => {
          this.props.app.dm.dashboard.setState({
            IsScannerVisible: true,
            isAlertBox: true, //true
            alertMessage:
              "Check your system settings to make sure that a camera is available. If not, plug one in. You might then need to restart your browser.",
            alertMessageHeading: "Can not find the camera",
            retryCount: 0,
          });
        });
      if (!!this.props.app.dm.userSession.state.leftMenuOverlay && this.props.app.dm.notificationDomain.state.isMobileDevice)
        this.props.app.dm.userSession.setState({
          leftMenuToggle: !this.props.app.dm.userSession.state.leftMenuToggle,
          leftMenuOverlay: !this.props.app.dm.userSession.state.leftMenuOverlay,
        });
    };

    renderIcon = (commonFontStyles) => {
      const name = this.props.r14.navigation.activeRouteName;
      const { billDetails } = this.props.app.dm.billDetails.state;
      let billerId = null;
      if (!!billDetails) {
        billerId = billDetails.billerId
      }

      switch (name) {
        case "billDetailsRoute":
          if (!!billerId) {
            return (
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd]}>
                <Touchable
                  onPress={() =>{
                    analyticsLogs(CommonString.BILL_HISTORY_PAGE, `${CommonString.Clicked_on_Bill_History_Icon}`, this.props.app);
                    this.props.app.nav.to("billHistoryScreenRoute", {
                      billerPid: billerId,
                    })
                  }
                  }>
                  <View>
                    <Image source={historyIcon} style={commonStyles.icon18} />
                  </View>
                </Touchable>
                {this.renderNotification(commonFontStyles)}
                {this.renderPCHLogoutSection(commonFontStyles)}
              </View>
            );
          }
          break;
        case "makePaymentRoutes":
        case "billersDirectoryRoute":
        case "simpleMessageRoute":
        case "dashboard":
        case "patientDashboard":
        case "pracDashboard":
        case "profileViewRoute":
        case "billRequestScreenRoute":
        case "bulkPaymentRoutes":
        case "myBanksRoute":
        case "linkPayerRoute":
          return (
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd]}>
              {this.renderQRCodeSection(commonFontStyles)}
              {this.renderNotification(commonFontStyles)}
              {this.renderPCHLogoutSection(commonFontStyles)}
            </View>
          );
        case "addMessageScreenRoute":
        case "profileEditRoute":
        case "simpleMessageDetailRoute":
        case "pamMessageRoute":
        case "notificationScreenRoute":
          return <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd]}>
            {this.renderNotification(commonFontStyles)}
            {this.renderPCHLogoutSection(commonFontStyles)}
          </View>;
        case "uploadBillRoute":
        case "scanQRcodeRoute":
        case "approveBillRoute":
        case "makePaymentsRoute":
        case "vendorStatementsRoute":
        case "receivePaymentsRoute":
        case "organizationRoute":
          return <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd]}>
            {this.renderOrganization(commonFontStyles)}
            {this.renderPCHLogoutSection(commonFontStyles)}
          </View>;
        default:
          return (
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd]}>
              {this.renderPCHLogoutSection(commonFontStyles)}
            </View>
          );;
      }
    };

    handleOnAction(event) { }

    handleOnActive(event) {
      if (this.state.isInactive === false) {
        this.idleTimer.reset();
      }
    }
    // warning modal for session timeout will pop up.
    handleOnIdle = async () => {
      this.setState({
        bShowConcurrentLoginPopUp: false,
      });
      this.props.app.dm.dashboard.setState({
        sessionInActive: true
      })
      this.setState({ isInactive: true });
      setTimeout(async () => {
        if (this.state.isInactive && !!this.idleTimer) {
          if (this.idleTimer) {
            this.idleTimer.pause();
          }
          await this.handleLogoutPress();
        }
      }, 1000 * 60 * 15);
    };

    handleCloseModel = () => {
      this.props.app.dm.billDetails.setState({
        isShowErrorMsg: false
      })
    }

    renderPopUpMenu(clientStyle, commonFontStyles) {
      return (
        <SafeAreaView>
          <View>
            <IdleTimer
              startOnMount={true}
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              timeout={1000 * 60 * 15}
              onActive={this.handleOnActive}
              onIdle={this.handleOnIdle}
              onAction={this.handleOnAction}
              debounce={250}
              backdropStyle={styles.backdrop}
            />
            <View>
              <PopupModalComponent
                visibility={this.props.app.dm.dashboard.state.sessionInActive
                  || this.props.app.dm.userSession.state.isLoggedOut
                  || this.props.app.dm.billDetails.state.isShowErrorMsg
                  || this.state.bShowConcurrentLoginPopUp
                  || !this.props.app.dm.dashboard.state.isSubscriptionValid}
                bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor, !this.props.app.dm.dashboard.state.isSubscriptionValid ? styles.width430 : null]}
                messageType={MESSAGE_TYPE.info}
                headingIcon={true}
                heading={
                  this.props.app.dm.dashboard.state.sessionInActive ? "Session Expired!"
                    : this.state.bShowConcurrentLoginPopUp ? "Concurrent Login Detected!"
                      : this.props.app.dm.userSession.state.isLoggedOut ? "Logout"
                        : !this.props.app.dm.dashboard.state.isSubscriptionValid ? "Subscription Upgrade/Renew!"
                          : "Info"}
                modalContent={[]}
                bodyContent={
                  this.props.app.dm.dashboard.state.sessionInActive ? "Your session has expired. You will be redirected to the login screen."
                    : this.state.bShowConcurrentLoginPopUp ? "Do you want to continue the session?"
                      : this.props.app.dm.userSession.state.isLoggedOut ? (this.loginType === LOGIN_TYPE.PCHGLOBAL ? "Are you sure want to redirect PCH Global?" : "Are you sure you want to logout?")
                        : this.props.app.dm.billDetails.state.isShowErrorMsg ? "Please get the payment accounts and mode confirmed with your Biller."
                          : ""}
                childContent={!this.props.app.dm.dashboard.state.isSubscriptionValid ?
                  <View>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.contentColor, commonStyles.textAlignCenter]}>Your Subscription has been expired. Please
                      <Touchable onPress={() => this.navToSubscription()}>
                        <View style={[styles.width55]}>
                          <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight400, commonStyles.contentColor, commonStyles.textAlignCenter, styles.subscriptionLinkColor]}> Click here</Text>
                        </View>
                      </Touchable> to kindly Renew/Upgrade your plan to avail the services.. </Text>
                  </View> : null}
                okLabel={
                  this.props.app.dm.dashboard.state.sessionInActive ? "Ok"
                    : this.state.bShowConcurrentLoginPopUp ? "No"
                      : this.props.app.dm.userSession.state.isLoggedOut ? "No"
                        : this.props.app.dm.billDetails.state.isShowErrorMsg ? "Ok"
                          : "Yes"}
                handleOk={
                  this.props.app.dm.dashboard.state.sessionInActive ? this.handleLogoutPress
                    : this.state.bShowConcurrentLoginPopUp ? this.handleLogoutPress
                      : this.props.app.dm.userSession.state.isLoggedOut ? this.closeLogoutScreen
                        : this.props.app.dm.billDetails.state.isShowErrorMsg ? this.handleCloseModel
                          : null}
                closeLabel={
                  this.props.app.dm.dashboard.state.sessionInActive ? ""
                    : this.state.bShowConcurrentLoginPopUp ? "Yes"
                      : this.props.app.dm.userSession.state.isLoggedOut ? "Yes"
                        : ""}
                handleClose={
                  this.props.app.dm.dashboard.state.sessionInActive ? null
                    : this.state.bShowConcurrentLoginPopUp ? this.generateNewSessionString
                      : this.props.app.dm.userSession.state.isLoggedOut ? this.handleLogoutPress
                        : null}
              />
            </View>
          </View>
        </SafeAreaView>
      );
    }

    handlePageFaviconAndTitle() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      document.title = "XBP - UBERDOC"; // replace with uberDocs page title
      link.href = `${window.location.origin}/uberdocs-favicon-32x32.png`;  // replace with uberDocs favicon.ico url
    }

    render() {
      let clientStyle = !!this.props.app.dm.userSession.state.isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!this.props.app.dm.userSession.state.isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
      const { isUberDocs, isExternalLogin, isLoading } = this.props.app.dm.userSession.state;
      if (!!isUberDocs) this.handlePageFaviconAndTitle();
      this.currentScreen = this.props.app.nav.route;
      let userType = !!this.userLoginDetail ? this.userLoginDetail.userType : null;
      let isLoggedIn = this.props.app.dm.userSession.isLoggedIn;
      if (isLoggedIn) this.sessionChecker();
      if (!!this.props.app.dm.userSession.state.isUberDocs)
        return (<View style={[clientStyle.header]}>
          {isLoggedIn ? this.renderPopUpMenu(clientStyle, commonFontStyles) : null}
        </View>);

      return (
        <View style={[styles.headerControlsRight, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, clientStyle.header]}>
          {isLoggedIn ? this.renderPopUpMenu(clientStyle, commonFontStyles) : null}
          {userType === USER_TYPE.XBP ? (
            <>
              {this.renderIcon(commonFontStyles)}
              <View style={[styles.searchBill, this.props.app.dm.userSearchDomain.state.isSearchOpen ? styles.block : null]}>
                {this.renderSearchSection(commonFontStyles)}
              </View>
              <InAppScanner />
            </>
          ) : <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd]}>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd, commonStyles.alignItemsCenter]}>
              <Image source={historyIcon} style={commonStyles.icon18} />
              <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight500]}>: UTC</Text>
            </View>
            {!!isExternalLogin ? null : this.renderPCHLogoutSection(commonFontStyles)}
          </View>}
          <PCHLoaderComponent isVisiable={isLoading} labelText={"Please wait"} />
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  headerControlsRight: {
    flex: 1,
    flexDirection: "row",
    paddingRight: 15,
    paddingLeft: 15,
    position: "relative",
  },
  searchBill: {
    position: "absolute",
    width: 400,
    right: 5,
    top: 35,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100vw",
          right: 0,
          top: 45,
          // right: 40,
        };
    },
  },
  textFieldOuter: {
    color: "#fff",
    backgroundColor: "#fff"
  },
  messageTextField: {
    // height: 48,
  },
  accountMenu: {
    width: 256,
  },
  header: {
    flex: 1,
    flexDirection: "column",
    borderBottomWidth: 1,
    minWidth: "auto",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.1),
    borderStyle: "solid",
    boxShadow: "none",
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 12,
  },
  headerClientText: {
    fontSize: 14,
    fontWeight: "300",
  },
  menuIconsInprofilesection: {
    height: 18,
    width: 18,
    display: "block",
    backgroundSize: "contain",
  },
  makePayment: {},
  notificationCount: {
    lineHeight: 8,
  },
  block: { display: "block" },
  none: { display: "none" },
  noStyle: {},
  sessionExpirPopupBodyWrapper: {
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          fontWeight: 600,
        };
    },
  },
  modalOuterWrapper: {
    width: "auto",
    backgroundColor: "#0000005c",
  },
  closeBtn: {
    position: "absolute",
    right: 0,
    top: 10,
  },
  sessionModelHeaderStyle: {
    color: "#4DC5F2",
    paddingTop: 8,
  },
  sessionModelOkBtn: {
    width: 100,
  },
  btnTexClr: {
    color: "#fff",
  },
  autoCompleteBox: {
    boxShadow: "0px 3px 10px -5px #000",
    border: "1px solid #9ca6ad",
    marginTop: -10,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 18,
    borderTop: 0,
  },
  addProfilePopupMenu: {
    // marginRight: -15,
  },
  profileRightSection: {
    justifyContent: "center",
  },
  profileIconWrapper: {
    width: 40,
    height: 40,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    backgroundColor: "#e2e2e2",
  }, 
  titleColor: {
    color: "#38c2ef",
  },
  profileSection: {
    borderRadius: 60,
    backgroundColor: "#e0e0e0",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: 30,
    minWidth: 30,
    height: 30,
  },
  profileSectionInner: {
    marginTop: -20,
    backgroundColor: "#e0e0e0",
    borderWidth: 1,
    borderRadius: 40,
    borderStyle: "solid",
    borderColor: "#fff",
    width: 30,
    minWidth: 30,
    height: 30,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
  },
  profileDetailSectionWrap: {
    position: "relative"
  },
  profileDetailSection: {
    backgroundColor: "#fff",
    position: "absolute",
    width: 200,
    right: 2,
    boxShadow: "0px 3px 6px rgb(8 8 8 / 21%)",
    // box- shadow: rgb(8 8 8 / 21 %) 0px 3px 6px;
    borderRadius: 4,
    top: 41,
    flex: 1,
    flexDirection: "column",
    zIndex: 999,
  },
  blueBrackground: {
    background: "#1277D9",
    height: 30,
    width: "100%",
    borderRadius: "4px 4px 0 0",
  },
  profileSectionLinks: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingBottom: 5,
    width: "100%",
    color: "#182832",
  },
  linkColor: {
    color: "#182832",
  },
  profileSectionName: {
    marginTop: 8,
    textAlign: "center",
    borderBottom: "1px solid #dfdfdf",
    paddingBottom: 5,
    wordBreak: "break-all",
    paddingLeft: 10,
    paddingRight: 10,
  },
  styleForMobileProvider: {
    width: 215,
  },
  styleForMobilePatient: {
    width: 160,
  },
  profileOverlaySection: {
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 99,
    left: 0,
    top: 56,
  },
  searchBillInnerWrap: {
    minHeight: 48,
  },
  marginLeft24: {
    marginLeft: 22,
  },
  threeDigiNotification: {
    minWidth: 22,
    left: 8,
  },
  fourDigiNotification: {
    minWidth: 26,
    left: 4,
  },
  width55: {
    width: 55
  },
  subscriptionLinkColor: {
    color: "rgb(27,99,181)",
    fontWeight: 600,
    fontSize: 12
  },
  width430: {
    width: 430
  }
});
