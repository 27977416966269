import React from "react";
import R14, { StyleSheet, View, Text } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import DateUtils from "../../utils/DateUtils";
import DownloadComponent from "./DownloadComponent";
import CommonUtility from "../../utils/CommonUtility";

export default R14.connect(
  class ReceiverComponent extends React.Component {
    render() {
      const { leaf, billerName } = this.props;
      return (
        <View style={[commonStyles.marRAuto]}>
          <View style={[styles.chatBoxWidth, commonStyles.minWidth150]}>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
              <View
                style={[
                  styles.userInitialOuter,
                  styles.billerInitial,
                  commonStyles.flex1,
                  commonStyles.alignItemsCenter,
                  commonStyles.justifyContentCenter,
                ]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fileSize, commonStyles.fontWeight500]}>
                  {CommonUtility.shortName(billerName)}
                </Text>
              </View>
              <View>
                <Text style={[commonStyles.guestName, commonFontStyles.workSans, styles.guestNameStyle]}>
                  {billerName}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, commonStyles.hostChat, commonStyles.marginBottom5, commonStyles.flexDirectionColumn]}>
              <View style={[commonStyles.minWidth150, commonStyles.guestText, commonStyles.wordBreakWord]}>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans, commonStyles.flexWrap, commonStyles.flex1]}>{leaf.envelope.messageBody.messageBody}</Text>
                </View>
                {!!leaf.attachments && !!leaf.attachments.length ? <DownloadComponent item={leaf} /> : null}
                <View style={[commonStyles.guestTextBorder]}></View>
              </View>
              <View style={commonStyles.textAlignRight}>
                <Text style={[
                  commonStyles.exSmallHeading, 
                  // commonStyles.chatTiming, 
                  commonStyles.textAlignRight, 
                  commonFontStyles.workSans
                  ]}>
                  {DateUtils.dateTodayYesterday(leaf.envelope.messageHeader.messageDateTime)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  chatBoxWidth: {
    paddingLeft: 5,
    marginBottom: 15,
    // maxWidth: "85%",
  },
  userInitialOuter: {
    height: 20,
    width: 20,
    maxWidth: 20,
    minWidth: 20,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    paddingTop: 2,
    paddingRight: 2,
    paddingBottom: 2,
    paddingLeft: 2,
  },
  billerInitial: {
    backgroundColor: "#e8e5e5",
  },
  guestNameStyle: {
    paddingRight: 10,
  }
});
