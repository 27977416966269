import React from "react";
import R14, { View, Text, StyleSheet, Button } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { PAY_ENVIRONMENT, PAYMENT_MODE, STRIPE_KEY, PAGE_PATH } from "../../constant/app.data.constant";
import GooglePayButton from "@google-pay/button-react";
import ApplePayment from "./ApplePayment";
import ACHModelPopUp from "./ACHModelPopUp"
import Touchable from "../../components/TouchableComponent"

export default R14.connect(
    class PaymentOptions extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                paymentDataRequest: {},
                stripeDetails: {},
                showACHModalPopUp: false,
            };
        }

        componentDidMount = async () => {
            const { amtPayable, subject, billerName, paymentOptions, pchClaim, currencyCode, bill } = this.props;
            let stripeDetails = !!pchClaim? STRIPE_KEY : { ...STRIPE_KEY, ...paymentOptions}; // Ravi_XBP
            stripeDetails.pchClaim = pchClaim;
            let lineItems = [];
            if(!!bill && !!bill.lineItems)
                lineItems = bill.lineItems;
            // 1) Request a payment token for your payment provider
            const tokenizationSpecification = {
                type: "PAYMENT_GATEWAY",
                // stripe payment gateway
                parameters: {
                    gateway: "stripe",
                    "stripe:version": stripeDetails.version,
                    "stripe:publishableKey": stripeDetails.publishableKey,
                },
            };

            // 2) Describe your allowed payment methods
            const baseCardPaymentMethod = {
                type: "CARD",
                parameters: {
                    allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                    allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"],
                },
            };

            const cardPaymentMethod = {
                ...baseCardPaymentMethod,
                tokenizationSpecification: tokenizationSpecification,
            };

            // 3) Transaction info
            const transactionInfo = {
                totalPriceStatus: "FINAL",
                totalPriceLabel: "Total",
                totalPrice: amtPayable.toFixed(2),
                currencyCode: currencyCode,
                countryCode: "US",
                displayItems: (!!lineItems && lineItems.length > 0) ? lineItems :[
                    {
                        label: subject,
                        type: "LINE_ITEM",
                        price: amtPayable.toFixed(2),
                    },
                ],
            };

            // 4) merchant info
            const merchantInfo = {
                // A Google merchant identifier issued after your website is approved by Google
                merchantId: "16857882227307232005",
                merchantName: billerName || bill.payee, // 'Example Merchant',
            };

            // 5) Address is pending

            //Create a PaymentDataRequest object
            const paymentDataRequest = {
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [cardPaymentMethod],
                merchantInfo: merchantInfo,
                transactionInfo: transactionInfo,
                emailRequired: true,
                callbackIntents: ["SHIPPING_ADDRESS", "PAYMENT_AUTHORIZATION"], //"SHIPPING_OPTION", "SHIPPING_ADDRESS",
                shippingAddressRequired: true,
                // // shippingOptionRequired : true,
                shippingAddressParameters: {
                    phoneNumberRequired: true,
                },
            };

            this.setState({
                paymentDataRequest: paymentDataRequest,
                stripeDetails: stripeDetails,
            });
        };

        isReadyToPay = async (isReady) => {
            console.warn('Is Pay Ready ', isReady);
            return isReady;
        };

        onPaymentDataChanged = async (data) => {
            console.warn(data)
            return {};
        };

        onPaymentAuthorized = async (paymentRequest) => {
            console.warn(paymentRequest)
            // TODO: implement funds check
            if (true) {
                return { transactionState: "SUCCESS" };
            } else {
                return {
                    transactionState: "ERROR",
                    error: {
                        reason: "PAYMENT_DATA_INVALID",
                        message: "Insufficient funds (shouldSucceed is false), click Pay again.",
                        intent: "PAYMENT_AUTHORIZATION",
                    },
                };
            }
        };

        processPayment = async (paymentDataResponse) => {
            // console.log('payment Data Response', paymentDataResponse);
            const { onPaymentDone, user, onModelClose, displayLoader, paymentOptions, pchClaim, bill} = this.props;
            if(!!onModelClose)
                onModelClose();
            if(!!displayLoader)    
                displayLoader();
                
            let token = paymentDataResponse.paymentMethodData.tokenizationData.token;
            // console.log('Token: ', token);
            if (!!token) {
                let payload = {
                    tokendetails: {
                        cardtoken: JSON.parse(token).id,
                        payment_intent: 0,
                        merchantAccountNumber: !!paymentOptions ? paymentOptions.merchantAccountNumber : "",
                        isPchClaim: pchClaim
                    },
                    transactiondetails: {
                        total_amount: parseFloat(this.state.paymentDataRequest.transactionInfo.totalPrice),
                        currency: this.state.paymentDataRequest.transactionInfo.currencyCode,
                        purchase_level: 1,
                        transaction_date: new Date(),
                        transaction_description: `bill payment ${this.state.paymentDataRequest.merchantInfo.merchantName}`,
                    },
                    billingaddress: {
                        billing_username: user.pid,
                        user_email: paymentDataResponse.email,
                        phone_Number: paymentDataResponse.shippingAddress.phoneNumber,
                        user_address1: paymentDataResponse.shippingAddress.address1,
                        user_address2: paymentDataResponse.shippingAddress.address2 + " " + paymentDataResponse.shippingAddress.locality,
                        user_zip: paymentDataResponse.shippingAddress.postalCode,
                        user_city: paymentDataResponse.shippingAddress.administrativeArea,
                        user_state: paymentDataResponse.shippingAddress.countryCode,
                    },
                };
                if(!!bill && !!bill.customerId)
                    payload.billingaddress.customerId = bill.customerId;
                
                let response = {};
                response = await this.props.app.dm.billDetails.stripeCheckout(payload);
                response.paymentMode = PAYMENT_MODE.ANDROID_PAY;
                response.totalAmount = payload.transactiondetails.total_amount;
                response.achBankAccountId = "Google Wallet";
                onPaymentDone(response);
            }
        };

        loadPaymentError = async (error) => {
            console.error("On Payment Error", error);
            let response = {
                success: false,
                error: error.statusMessage,
                isCancelByUser: false
            };
            const { onPaymentDone } = this.props;
            onPaymentDone(response);
        };

        loadPaymentCancel = async (cancel) => {
            console.warn("On Payment Cancel ", cancel);
            let response = {
                success: false,
                error: `${cancel.statusCode}: Payment cancelled by user.`,
                isCancelByUser: true
            };
            const { onPaymentDone } = this.props;
            onPaymentDone(response);
        };

        updateOnPartPayment = async (payType, updatedAmt, amtPayable) => {
            // const { payType, updatedAmt, amtPayable } = this.props;
            const { paymentDataRequest } = this.state;
            if (!!paymentDataRequest.transactionInfo) {
                if (payType === PAGE_PATH.PAYPART && !!updatedAmt && parseFloat(updatedAmt) > 0) {
                    paymentDataRequest.transactionInfo.totalPrice = parseFloat(updatedAmt).toFixed(2);
                    paymentDataRequest.transactionInfo.displayItems[0].price = parseFloat(updatedAmt).toFixed(2);
                }
                else {
                    paymentDataRequest.transactionInfo.totalPrice = amtPayable.toFixed(2);
                    paymentDataRequest.transactionInfo.displayItems[0].price = amtPayable.toFixed(2);
                }
                this.setState({
                    paymentDataRequest: paymentDataRequest
                });
            }
        }

        onPaymentIntent = (paymentRes) => {
            const { onPaymentDone } = this.props;
            const { paymentDataRequest } = this.state;
            let response = {};
            response.paymentMode = PAYMENT_MODE.ACH_PAY;
            response.totalAmount = paymentDataRequest.transactionInfo.totalPrice;
            response.success = paymentRes.success;
            response.transactionId = !!paymentRes.paymentIntent ? paymentRes.paymentIntent : paymentRes.achBankAccountId;
            response.achBankAccountId = paymentRes.achBankAccountId;
            response.achBankAccountNumber = paymentRes.achBankAccountNumber;
            response.achPaymentIntentId = paymentRes.paymentIntent;
            this.gotoACHPayment();
            onPaymentDone(response);
        }

        gotoACHPayment = (cancel) => {
            if(cancel && cancel.statusCode){
                let response = {
                    success: false,
                    error: `${cancel.statusCode}: Payment cancelled by user.`,
                    isCancelByUser: true
                };
                const { onPaymentDone } = this.props;
                onPaymentDone(response);
                this.setState({
                    showACHModalPopUp: !this.state.showACHModalPopUp
                })
            } else {
                this.setState({
                    showACHModalPopUp: !this.state.showACHModalPopUp
                })
            }
        }

        render() {
            const { isLoading, externalClient, rtpPay = false, pchClaim, googleBtnStyle, bulkPay, showGooglePay = false, cancelBtnStyle, cancelText = "Cancel", cancelTextStyle, onCancel, gpayContainer, buttonColor } = this.props;
            const { paymentDataRequest } = this.state;
            const isSafariBrowser = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") <= -1;
            return (
                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexWrap, !!bulkPay && styles.customJustifyContentFlexEnd, commonStyles.flexGrow0, commonStyles.alignItemsCenter, !!pchClaim && commonStyles.justifyContentCenter, gpayContainer]}>
                  {!!cancelBtnStyle ? <Button
                                title={cancelText}
                                key="cancel"
                                style={cancelBtnStyle}
                                titleTextStyle={cancelTextStyle}
                                onPress={onCancel}
                            /> : null }
                    {this.state.showACHModalPopUp ? (
                        <ACHModelPopUp 
                            visibility={this.state.showACHModalPopUp}
                            handleCloseModel={(message) => this.gotoACHPayment(message)}
                            billerName={this.props.billerName}
                            payerId={this.props.user.pid}
                            payerName={this.props.user.firstName}
                            billDetailId={this.props.billDetailId}
                            email={this.props.user.email}
                            billerId={this.props.billerId}
                            buId={this.props.buId}
                            buName={this.props.buName}
                            merchantAccountNumber={this.props.paymentOptions.merchantAccountNumber}
                            amount={paymentDataRequest.transactionInfo.totalPrice}
                            subject={this.props.subject}
                            currencyCode={paymentDataRequest.transactionInfo.currencyCode}
                            onPaymentIntent={this.onPaymentIntent}
                        />
                    ): null }
                    {!isSafariBrowser ? (
                    <View style={[googleBtnStyle, !!isLoading ? commonStyles.disable : null]}>
                        <GooglePayButton
                            buttonSizeMode="fill"
                            buttonColor= {!!buttonColor ?  buttonColor : "white" }
                            buttonType="short"
                            className= {[!!showGooglePay ? "" : !!externalClient ? "btnpchclaims external-client": !!pchClaim ? "btnpchclaims" : !!bulkPay ? "btnBulkPay" : !!rtpPay ? `btnRTPPay ${isLoading ? "btnDisabled" :""}` :""]}
                            environment={PAY_ENVIRONMENT.ENVIRONMENT}
                            paymentRequest={this.state.paymentDataRequest}
                            onLoadPaymentData={this.processPayment}
                            onError={this.loadPaymentError}
                            onCancel={this.loadPaymentCancel}
                            onReadyToPayChange={this.isReadyToPay}
                            onPaymentAuthorized={this.onPaymentAuthorized}
                            style={
                                !!showGooglePay ? null :
                                    {
                                        flexBasis: "100%",
                                        border: "0px solid rgba(21, 27, 38, 0.4)",
                                        height: 24,
                                        minHeight: 24,
                                        borderTopLeftRadius: 5,
                                        borderTopRightRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        borderBottomRightRadius: 5,
                                        padding: 0,
                                    }
                            }
                            onPaymentDataChanged={(data) => this.onPaymentDataChanged(data)}
                        />
                    </View>
                    ): ( 
                        <ApplePayment
                            props={this.props}
                            paymentDataRequest={this.state.paymentDataRequest}
                            stripeDetails={this.state.stripeDetails}
                            proceedToApplePayStyle={styles.minWidth200}
                        />    
                    )}
                    {(!!pchClaim) ? null :
                        <Touchable disabled={!!isLoading}  style={[!!isLoading ? commonStyles.marginLeft10: null]} onPress={!!isLoading ? null : this.gotoACHPayment}>
                            <View style={[styles.achPaymentBtn, !!isLoading ? commonStyles.disable : null]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight500, commonStyles.whiteColor, !!isLoading ? commonStyles.disableText: null]}>
                                    {!!pchClaim ? "Yes" : "ACH"}
                                </Text>
                            </View>
                        </Touchable>
                    }
                </View>                
            )
        }
    }
);

const styles = StyleSheet.create({
    achPaymentBtn: {
        width: 110,
        marginRight: 10,
        marginLeft: 10,
        height: 24,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "none",
        borderRadius: 5,
        padding: 0,
        backgroundColor: "#d71996",
        screen: ({width}) =>{
            if(width <= 991){
                return {
                    width: 110
                }
            }
        }
    },       
    minWidth200: {
        minWidth: 100,
        screen: ({width}) => {
            if(width <= 991){
                return {
                    minWidth: 95
                }
            }
        }
    },
    customJustifyContentFlexEnd: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    justifyContent: "flex-end",
              }
            }
          }  
    },
    
    
});
