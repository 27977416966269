import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Image } from '../../core';
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { PAYMENT_MODE } from "../../constant/app.data.constant";
import { useStripe } from "@stripe/react-stripe-js";
import Touchable from "../../components/TouchableComponent"
const applepay = require("../../assets/images/applepay.png");

const SafariPayment = ({ props, paymentDataRequest }) => {
  let { amtPayable, subject, pchClaim, isLoading, showGooglePay = false } = props;
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  useEffect(() => {
    if (stripe) {
      try {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        subject = (!!paymentDataRequest.transactionInfo && !!paymentDataRequest.transactionInfo.displayItems && paymentDataRequest.transactionInfo.displayItems.length > 0) ? paymentDataRequest.transactionInfo.displayItems[0].label : subject;
      } catch (error) {
      }
      const payload = {
        country: "US",
        currency: "usd",
        total: {
          label: subject,
          amount: Math.round(amtPayable * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      }
      const pr = stripe.paymentRequest(payload);

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, amtPayable, subject]);

  if (paymentRequest && !!!isLoading) {
    paymentRequest.on("paymentmethod", async (ev) => {
      const { onPaymentDone } = props;
      let payload = {
        tokendetails: {
          cardtoken: ev.paymentMethod.id,
          payment_intent: 0,
        },
        transactiondetails: {
          total_amount: parseFloat(
            paymentDataRequest.transactionInfo.totalPrice
          ),
          currency: paymentDataRequest.transactionInfo.currencyCode,
          purchase_level: 1,
          transaction_date: new Date(),
          transaction_description: `bill payment ${paymentDataRequest.merchantInfo.merchantName}`,
        },
        billingaddress: {
          billing_username: ev.paymentMethod.billing_details.name,
          user_email: ev.paymentMethod.billing_details.email,
          phone_Number: ev.paymentMethod.billing_details.phone,
          user_address1: ev.paymentMethod.billing_details.address.line1,
          user_address2: ev.paymentMethod.billing_details.address.line2,
          user_zip: ev.paymentMethod.billing_details.address.postal_code,
          user_city: ev.paymentMethod.billing_details.address.city,
          user_state: ev.paymentMethod.billing_details.address.state,
        },
      };
      let response = {};
      response = await props.app.dm.billDetails.stripePaymentCheckout(payload);
      response.paymentMode = PAYMENT_MODE.APPLE_PAY;
      response.totalAmount = parseFloat(
        paymentDataRequest.transactionInfo.totalPrice
      );
      response.achBankAccountId = "Apple Wallet";
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          response.transactionId,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        ev.complete("fail");
        let response = {
          success: false,
          error: confirmError,
        };
        onPaymentDone(response);
      } else {
        ev.complete("success");
        onPaymentDone(response);
        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(
            response.transactionId
          );
          if (error) {
            ev.complete("fail");
            let response = {
              success: false,
              error: error,
            };
            onPaymentDone(response);
          } else {
            ev.complete("success");
            onPaymentDone(response);
          }
        } else {
          ev.complete("success");
          onPaymentDone(response);
          // The payment has succeeded.
        }
      }
    });
    return (
      <Touchable onPress={async () => paymentRequest.show()} style={[!!showGooglePay ? commonStyles.fullFlexContainer : null]}>
        <View style={[!!showGooglePay ? styles.showApplyIcon : styles.paymentButtonWrapper]}>
          {!!showGooglePay ? <Image source={applepay} style={[styles.applyPayIconImage]} /> :
            <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight500, commonStyles.whiteColort]}>{!!pchClaim ? "YES" : "APPLE PAY"}</Text>}
        </View>
      </Touchable>
    );
  }

  return (
    <Touchable onPress={async () => console.log('disabled')} disabled={!paymentRequest} style={[!!showGooglePay ? commonStyles.fullFlexContainer : commonStyles.marginLeft10]}>
      <View style={[!!showGooglePay ? styles.showApplyIcon : styles.paymentButtonWrapper, commonStyles.disable]}>
        {!!showGooglePay ? <Image source={applepay} style={[styles.applyPayIconImage]} /> :
          <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight500, commonStyles.whiteColor, commonStyles.disableText]}>{!!pchClaim ? "YES" : "APPLE PAY"}</Text>}
      </View>
    </Touchable>
  );
};

export default SafariPayment;

const styles = StyleSheet.create({
  paymentButtonWrapper: {
    marginLeft: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#d71996',
    backgroundColor: "#d71996",
    borderRadius: 5,
    padding: 5,
    width: 100,
    height: 24,
    flex: 1,
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "auto",
          minWidth: 105,
          marginRight: 0,
          marginLeft: 10,
          flexBasis: "48%"
        }
      }
    }
  },
  showApplyIcon: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 5,
    backgroundColor: "#fff",
    width: "100%",
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: "100%"
  },
  applyPayIconImage: {
    width: 38,
    height: 19,
    display: "block",
    backgroundSize: "contain",
  },
});
