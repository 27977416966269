import R14 from "../core";
import { SEARCH_TYPE  } from "../constant/app.data.constant";

export default class MiscellaneousDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      countries: [],
      countriesCollection: [],
      stateCollection: [],
      cityCollection: []
    }
  }

  async countryCollections() {
    try {
      const { initialized, countries } = this.state;
      if (!!!initialized || countries.length === 0) {
        let res = await this.api.qry(
          `query Countrys{
            countrys{
                totalCount
                nodes {  
                    uid city_ascii country admin_name
                }
            }
        }`);
        return this.setStateCountry(res.data.countrys.nodes);
      }
      return [];
    }
    catch (err) {
      return [];
    }
  }

  async qryCountryStateCity(limit = 300, countryFilter = "", stateFilter = "", cityFilter = "", fields = "uid city city_ascii country cityid admin_name") {
    const options = {
      countryFilter,
      stateFilter,
      cityFilter,
      limit: limit,
    };
    try {
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetCountryStateCity($countryFilter: String, $stateFilter: String,  $cityFilter: String!, $limit: Int){
          getCountryStateCity( countryFilter: $countryFilter, stateFilter: $stateFilter, cityFilter: $cityFilter, limit: $limit) {
            nodes {
              ${fields}
            }
          }
        }`,
        options
      ));
      return this.setStateCountry(!!result && result.data && result.data.getCountryStateCity ? result.data.getCountryStateCity.nodes : []);
    } catch (err) {
      return err;
    }
  }

  setStateCountry = (inputCompany) => {
    // 1. copy existing state & update it
    let filterItems = !!inputCompany ? inputCompany.map((el, i) => {
      return {
        uid: el.uid,
        // cityid: el.cityid,
        // city: el.city.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        city_ascii: el.city_ascii.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        country: el.country.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        admin_name: el.admin_name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      }
    }) : [];

    const countries = [...new Set([...this.state.countries, ...filterItems])];
    // 2. set state
    this.setState({ initialized: true, countries });
    return countries;
  };

  async getCountries(limit = 300, countryInput = "", stateInput = "", cityInput = "") {
    try {
      countryInput = !!countryInput ? countryInput : "";
      stateInput = !!stateInput ? stateInput : "";
      cityInput = !!cityInput ? cityInput : "";
      let filter = this.state.countries.filter((c) => c.country.toLowerCase().startsWith(!!countryInput ? countryInput.toLowerCase() : c.country.toLowerCase())
        && c.admin_name.toLowerCase().startsWith(!!stateInput ? stateInput.toLowerCase() : c.admin_name.toLowerCase())
        && c.city_ascii.toLowerCase().startsWith(!!cityInput ? cityInput.toLowerCase() : c.city_ascii.toLowerCase()));
      if (!filter || filter.length === 0) {
         filter = await this.qryCountryStateCity(limit, countryInput, stateInput, cityInput);
       // filter = await this.countryCollections();
        return !!filter ? filter.filter((c) => c.country.toLowerCase().startsWith(!!countryInput ? countryInput.toLowerCase() : c.country.toLowerCase())
          && c.admin_name.toLowerCase().startsWith(!!stateInput ? stateInput.toLowerCase() : c.admin_name.toLowerCase())
          && c.city_ascii.toLowerCase().startsWith(!!cityInput ? cityInput.toLowerCase() : c.city_ascii.toLowerCase())) : [];
      }
      else
        return filter;
    } catch (error) {
        return [];
    }
  }

  async getCountryStateCity(searchType = SEARCH_TYPE.COUNTRY, searchText = "", countryId = "", stateId = "", limit = 10) {
    countryId = !!countryId ? countryId : "";
    stateId = !!stateId ? stateId : "";
    searchText = !!searchText ? searchText : "";
    if (searchType === SEARCH_TYPE.COUNTRY) {
      const options = {
        filter: {
          country_name: { like: "%" + searchText + "%" },  
        },
       // page: 1,
       // resultsPerPage: limit,
        sort: [{
          field: "country_name",
          order: "ASC"
        }]
      }
      return await this.getCountryList(options);
    }
    else if (searchType === SEARCH_TYPE.STATE) {

      let options = {
        filter: {
          state_name: { like: "%" + searchText + "%" },
          country_id: { eq: countryId }
        },
       // page: 1,
      //  resultsPerPage: limit,
        sort: [{
          field: "state_name",
          order: "ASC"
        }]
      }
      if (!!!countryId)
        delete options.filter.country_id;
      return await this.getStateList(options);
    }
    else if (searchType === SEARCH_TYPE.CITY) {
      const options = {
        filter: {
          city_name: { like: "%" + searchText + "%" },
          state_id: { eq: stateId },
        },
       // page: 1,
       // resultsPerPage: limit,
        sort: [{
          field: "city_name",
          order: "ASC"
        }]
      }
      if (!!!stateId)
        delete options.filter.state_id;
      return await this.getCityList(options);
    }
  }

  async getCountryList(option) {
    let countryData = [];
    let searchText = option.filter.country_name.like.replace("%", "") || "";
    searchText = searchText === "%" ? "" :  searchText.replace("%", "") || "";
    searchText = searchText.toLowerCase();
    let searchKey = "countrykey";

    if (!!this.state.countriesCollection && this.state.countriesCollection.length > 0) {
      countryData = this.state.countriesCollection.filter((item) => item.key === searchKey)[0];
    }
    
    if (!!countryData && 'value' in countryData && countryData.value.length > 0)
    {
      let finalData = !!searchText ? countryData.value.filter(item=> item.country_name.toLowerCase().includes(searchText)).slice(0,10): countryData.value.slice(0,10);
      if(!!finalData && finalData.length > 0)
        return finalData;
      else
        return await this.getCountryItems(option, searchKey, searchText);
    }
    else {
    return await this.getCountryItems(option, searchKey, searchText);
    }
  }

  async getCountryItems(option, searchKey, searchText)
  {
    try {
      let res = await this.api.qry(
        `query MstCountrys($filter: MstCountryFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
        mstCountrys(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort ){
            totalCount
            nodes {  
                uid country_name  
            }
        }
    }`,
        option);

      let result = res.data.mstCountrys.nodes || [];
      if (!!result && result.length > 0) {
        let countryData = this.state.countriesCollection.filter((item) => item.key === searchKey)[0]
        let keyValuePair = [{ key: searchKey, value: !!countryData && !!countryData.value ? [...countryData.value, ...result] : [...[], ...result] }];
        this.setState({ countriesCollection: keyValuePair });
      }
      let countryData = this.state.countriesCollection.filter((item) => item.key === searchKey)[0]

      if (!!countryData && 'value' in countryData && countryData.value.length > 0) {
        let finalData = !!searchText ? countryData.value.filter(item => item.country_name.toLowerCase().includes(searchText)).slice(0, 10) : countryData.value.slice(0, 10);
        return finalData;
      }
      else
        return [];
    }
    catch (err) {
      return [];
    }
  }

  async getStateList(options) {
    let stateData = [];
    let searchText = options.filter.state_name.like === "%" ? "" :  options.filter.state_name.like.replace("%", "") || "";
    searchText = searchText === "%" ? "" :  searchText.replace("%", "") || "";
    searchText = searchText.toLowerCase();
    let searchKey =  'country_id' in options.filter ? options.filter.country_id.eq : "";
    if (!!this.state.stateCollection && this.state.stateCollection.length > 0) {
      stateData = this.state.stateCollection.filter((item) => item.key === searchKey)[0];
    }
    if (!!stateData && 'value' in stateData && stateData.value.length > 0  ) {
      let finalData = !!searchText ? stateData.value.filter(item=> item.state_name.toLowerCase().includes(searchText)).slice(0,10): stateData.value.slice(0,10);
      if(!!finalData && finalData.length > 0)
        return finalData;
      else
        return await this.getStateItems(options, searchKey, searchText);
    }
    else {
      return await this.getStateItems(options, searchKey, searchText);
    }
  }

  async getStateItems(options, searchKey,searchText)
  {
    try {
      let res = await this.api.qry(
        `query MstStates($filter: MstStateFilter,$page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          mstStates(filter: $filter,page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
              nodes {  
                  uid state_name country_id
              }
          }
      }`,
        options
      );
      let result = res.data.mstStates.nodes || [];
      if (!!result && result.length > 0) {
        let oldStates = this.state.stateCollection.filter((item) => item.key === searchKey)[0]
        let newStateValue = oldStates && oldStates.value.length > 0 ? oldStates.value : []
        if(newStateValue.length !== result.length){
          let keyValuePair = [{ key: searchKey, value: [...result, ...newStateValue] }];
          this.setState({ stateCollection: keyValuePair });
        }
      }
     let stateData = this.state.stateCollection.filter((item) => item.key === searchKey)[0]
      if (!!stateData && 'value' in stateData && stateData.value.length > 0  ) {
        let finalData = !!searchText ? stateData.value.filter(item=>item.state_name.toLowerCase().includes(searchText)).slice(0,10): stateData.value.slice(0,10);
        return finalData;
      }
      else
        return [];
    }
    catch (err) {
      return [];
    }
  }
  
  async getCityList(options) {
    let cityData = [];
    let searchText = options.filter.city_name.like === "%" ? "": options.filter.city_name.like.replace("%", "") || "";
    searchText = searchText === "%" ? "" :  searchText.replace("%", "") || "";
    searchText = searchText.toLowerCase();
    let searchKey =  'state_id' in options.filter ? options.filter.state_id.eq : "";

    if (!!this.state.cityCollection && this.state.cityCollection.length > 0) {
      cityData = this.state.cityCollection.filter((item) => item.key === searchKey)[0];
    }
    if (!!cityData && 'value' in cityData && cityData.value.length > 0) {
      let finalData = !!searchText ? cityData.value.filter(item => item.city_name.toLowerCase().startsWith(searchText)).slice(0, 10) : cityData.value.slice(0, 10);
      if (finalData && finalData.length > 0) {
        return finalData
      } else {
        try {
          let res = await this.api.qry(
            `query MstCity($filter: MstCityFilter,  $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
              mstCitys(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
                  nodes {
                      uid city_name state_id
                  }
              }
          }`,
            options);
  
          let result = res.data.mstCitys.nodes || [];
          if (!!result && result.length > 0) {
            let oldCity = this.state.cityCollection.filter((item) => item.key === searchKey)[0]
            let newCityValue = oldCity && oldCity.value.length > 0 ? oldCity.value : []
            let keyValuePair = [{ key: searchKey, value: [...result, ...newCityValue] }];
            this.setState({ cityCollection: keyValuePair });
          }
          cityData = this.state.cityCollection.filter((item) => item.key === searchKey)[0]
          if (!!cityData && 'value' in cityData && cityData.value.length > 0) {
            let finalData = !!searchText ? cityData.value.filter(item => item.city_name.toLowerCase().startsWith(searchText)).slice(0, 10) : cityData.value.slice(0, 10);
            return finalData
          }        
          else
            return [];
        }
        catch (err) {
          return [];
        }
      }
    }
    else {
      try {
        let res = await this.api.qry(
          `query MstCity($filter: MstCityFilter,  $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
            mstCitys(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
                nodes {
                    uid city_name state_id
                }
            }
        }`,
          options);

        let result = res.data.mstCitys.nodes || [];
        if (!!result && result.length > 0) {
          let oldCity = this.state.cityCollection.filter((item) => item.key === searchKey)[0]
          let newCityValue = oldCity && oldCity.value.length > 0 ? oldCity.value : []
          let keyValuePair = [{ key: searchKey, value: [...result, ...newCityValue] }];
          this.setState({ cityCollection: keyValuePair });
        }
        cityData = this.state.cityCollection.filter((item) => item.key === searchKey)[0]
        if (!!cityData && 'value' in cityData && cityData.value.length > 0) {
          let finalData = !!searchText ? cityData.value.filter(item => item.city_name.toLowerCase().includes(searchText)).slice(0, 10) : cityData.value.slice(0, 10);
          return finalData
        }        
        else
          return [];
      }
      catch (err) {
        return [];
      }
    }
  }

  async countryCodes(search = "", fields = "mobile_code") {
    try {
      let res = await this.api.qry(
        `query getCountryCode($search: String,  $limit: Int){
            getCountryCode(search: $search, limit: $limit){
                nodes {  
                    ${fields}
                }
            }
        }`, { search: search });
      res = res.data.getCountryCode.nodes;
      return res;
    }
    catch (err) {
    }
    return [];
  }

}
