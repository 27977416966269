import React from "react";
import R14, {
    TextInputField,
    SubmitButton,
    StyleSheet,
    Surface,
    View,
    Text,
    Form,
    SelectMenuField,
    Image,
    Dimensions,
    Button
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
import CommonValidators from "../utils/CommonValidators";
import { CommonString } from "../constant/appCommonText";
import RadioButtons from "../components/RadioButtons";
import DatePicker from "../components/PCH/DatePicker";
import DateUtils from "../utils/DateUtils";
import Linking from "../utils/Linking";
import CopyRight from "../components/CopyRight";

const mainImg = Dimensions.get("window").width === 1024 && Dimensions.get("window").height === 1292 ? require("../assets/images/mainImg-ipad.png") : require("../assets/images/mainImg.png");
const XBPlogo = require("../assets/images/uberdoc_logo_1.png");
const BILL_OPTS = [
    { label: "In-Person", value: "In-Person" },
    { label: "Telemedicine ", value: "telemedicine" },
];

export default R14.connect(
    class ClientPOCScreen extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                selectedValue: "In-Person",
                showFromDatePicker: false,
                isValidated: false,
                appointmentData: {
                    "appointmentId": "RQHQTQAgrq",
                    "patientId": "fjtKhgW49k",
                    "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJDQXV2akZ0R2FZQ3VSZHRIVlR0aEFhTXl3cW1BRXdiU1Z3azVmZU8wam5NIn0.eyJleHAiOjE3MTI3NTIwMDAsImlhdCI6MTcxMjc1MDIwMCwianRpIjoiNzMwZWFkMTUtNjBiOS00NTlkLWEyNzUtMWVhZWI3YTZjNzg0IiwiaXNzIjoiaHR0cHM6Ly9hdXRoLWRldi5leGVsYXRlY2guY29tL3JlYWxtcy9Db25zdW1lclJlYWxtIiwiYXVkIjpbIlVCRVJET0NXRUIiLCJ4YnBzbWIiLCJhY2NvdW50Il0sInN1YiI6IjVjMjQyZGJkLWEyNTQtNGUyNy1iMzcxLTRlNGQ0YzY1NzJmZSIsInR5cCI6IkJlYXJlciIsImF6cCI6IlVCRVJET0NXRUIiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHBzOi8vd2ViLlVCRVJET0NXRUIuY29tIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLWNvbnN1bWVyIHJlYWxtIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7InhicHNtYiI6eyJyb2xlcyI6WyJleGVsYV9hZG1pbiJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIFVCRVJET0NXRUIgZW1haWwiLCJjbGllbnRJZCI6IlVCRVJET0NXRUIiLCJjbGllbnRIb3N0IjoiMTA2LjIxNS4yNDguMTIyIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzZXJ2aWNlLWFjY291bnQtdWJlcmRvY3dlYiIsImNsaWVudEFkZHJlc3MiOiIxMDYuMjE1LjI0OC4xMjIifQ.bhUusdQMHvSXBYxHcJu-OLEPV-iho2HAujaDDAt5qdxvQ9i53PUbGbwj6PBg0DRk1sIwr_m4h68T_tnEEoiljHd_BV9e0fI-kOeMhdbcjtz-PJBy-IA2HHEig0ufLT15W4batZxp7GiOwIPiK5yGE4p6qXqX0aSzoMpCZx7x_j5vto1bnEHQFH7g5jlQrYAN8_v2qRQ1FQYQbwyCJVgj8uQOvC6QazpTHZsCKzpgCXKc2t4lcv8gLbfvUw2UuLH9z-5mEefHfLnH_5HTnvgqObK57WZIvIw0D5hAvFYaaRynPd97sZygHYtOLPhz1nLtxmVEq7VW4kdbuCpaDyFL0Q"
                }
            };
        }
        componentDidMount() {
            try {
                this.form = this.props.app.ui.form("clientForm");
                this.form.elmts.countryCode.setValue({ label: "+1", value: "+1" });
            } catch (error) {

            }
        }

        onPress = async (val) => {
            let url = ``;
            let redirect_url = encodeURIComponent(`${window.location.origin}/uber-docs-xbp-claims?type=xbp`);
            if (val === "submit") {
                url = `${window.location.origin}/claims/external-upload-bill/${this.state.appointmentData.appointmentId}/${redirect_url}/${this.state.appointmentData.access_token}`;
            }
            else if (val === "view") {
                url = `${window.location.origin}/claims/external-view-bill/${this.state.appointmentData.appointmentId}/${redirect_url}/${this.state.appointmentData.access_token}`;
            }
            else if (val === "history") {
                url = `${window.location.origin}/claims/external-bill-history/${this.state.appointmentData.patientId}/${redirect_url}/${this.state.appointmentData.access_token}`;
            }
            if (!!url)
                Linking.LinkingURL(url, "_self");
            return false;
        }

        amountText = async (val) => {
            try {
                let amtText = val;
                if (!!val) {
                    amtText = amtText.replace(/[^\d.]/g, "").substring(0, 10);
                    amtText = amtText.indexOf('.') > -1 && amtText.split('.')[1].length > 2 > 0 ? parseFloat(amtText).toFixed(2) : amtText;
                    this.form.elmts.appointmentCost.setValue(amtText);
                }
                let isValid = /^\+?(\d*[1-9]\d*\.?|\d*\.\d*[0-9]\d*)$/gi.test(amtText);

                if (isValid && (amtText < 0.5 || amtText.length > 9))
                    isValid = false;
                
                if (isValid) {
                    this.form.elmts.amount.setValue(amtText);
                    this.form.elmts.appointmentCost.setError(null);
                } else if (amtText === "") {
                    this.form.elmts.appointmentCost.setError(null);
                } else {
                    this.form.elmts.appointmentCost.setError("Enter valid amount");
                }
            } catch (error) {

            }
        }

        handleFromShowHideDatePicker = (val) => {
            if (val === "open" && this.state.showFromDatePicker) {
                return;
            } else if (val === "close") {
                this.setState({ showFromDatePicker: false });
                return;
            } else {
                this.setState({ showFromDatePicker: !this.state.showFromDatePicker })
            }
        };

        onClearFromDate = () => {
            const form = this.props.app.ui.form("clientForm");
            form.elmts.treatmentDate.setValue(null);
            form.elmts.treatmentDate.setError(null);
            this.setState({
                showFromDatePicker: false,
            })
        }

        onFromDateChange = async (val, autoformat = true) => {
            const form = this.props.app.ui.form("clientForm");
            this.handleFromShowHideDatePicker();
            form.elmts.treatmentDate.setError(null);
            if (!!val) {
                if (!!autoformat) {
                    let date = DateUtils.formatDateInMMDDYYYY(val);
                    if (date) {
                        form.elmts.treatmentDate.setValue(date);
                        form.elmts.treatmentDate.setError("");
                    } else form.elmts.treatmentDate.setValue(null);
                } else {
                    if (val) {
                        form.elmts.treatmentDate.setValue(val);
                        form.elmts.treatmentDate.setError("");
                    } else form.elmts.treatmentDate.setValue(null);
                }
            }
        };

        onFromDateBlur = async () => {
            const form = this.props.app.ui.form("clientForm");
            const { treatmentDate } = form.values;
            this.handleFromShowHideDatePicker();
            if (!!treatmentDate) {
                let dat = await this.validateDate(treatmentDate);
                if (!!dat) {
                    let formatedDate = DateUtils.formatDateInMMDDYYYY(treatmentDate);
                    dat = await this.validateDate(formatedDate);
                    if (!!dat) {
                        form.elmts.treatmentDate.setValue(formatedDate);
                        form.elmts.treatmentDate.setError(null);
                        this.onFromDateChange(treatmentDate, false);
                    } else {
                        form.elmts.treatmentDate.setValue(treatmentDate);
                        form.elmts.treatmentDate.setError(CommonString.DateFormatRequired);
                    }
                } else {
                    form.elmts.treatmentDate.setValue(null);
                    form.elmts.treatmentDate.setError(CommonString.DateFormatRequired);
                }
            } else {
                form.elmts.treatmentDate.setError(null);
            }
        };

        validateDate = async (testdate) => {
            let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
            return date_regex.test(testdate);
        };

        onValueChange = async (value) => {
            this.setState({
                selectedValue: value
            })
            return false;
        }

        handleSubmit = async (form) => {
            this.props.app.ui.progressIndicator.show();
            let payerPhoneNumber = form.elmts.mobileNumber.value.replace(/[^\w\d\s]/g, "").replace(" ", "");
            try {
                let { firstName, lastName, userEmail, providerName, npi, treatmentDate, address1, appointmentCost, } = form.values;
                let payload = {
                    Treatment: {
                        TreatmentDate: treatmentDate,
                        Address1: address1,
                        AppointmentCost: appointmentCost,
                        VisitType: this.state.selectedValue
                    },
                    Provider: {
                        Name: providerName,
                        NPI: npi
                    },
                    Consumer: {
                        "FirstName": firstName,
                        "LastName": lastName,
                        "Email": userEmail,
                        "PhoneNumber": payerPhoneNumber,
                    }
                }
                let response = await this.props.app.dm.queryDomain.clientResponse(JSON.stringify(payload));
                console.log(response)
                if (!!response) {
                    this.props.app.dm.commonDomain.showToastr("success", "Data Validated Successfully. Please proceed...");

                    this.setState({
                        appointmentData: {
                            "appointmentId": response.AuthorizeResult,
                            "patientId": response.bancHubUserName,
                            "access_token": response.error
                        },
                        isValidated: true
                    })
                }
                else
                    this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);

            } catch (error) {
                this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            return false;

        };

        trimWhiteSpacesFirstName = (val) => {
            try {
                this.form.elmts.firstName.setValue(val.trimStart());
            } catch (error) {

            }
        };

        trimWhiteSpacesLastName = (val) => {
            try {
                this.form.elmts.lastName.setValue(val.trimStart());
            } catch (error) {

            }
        };

        // Mobile number validation.
        mobileNumberText(val) {
            let mobNumText = val;
            if (!!val) {
                let cleaned = ("" + mobNumText).replace(/\D/g, "").substring(0, 10);
                this.form.elmts.mobileNumber.setValue(cleaned);
                let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    let formattedString = "";
                    if (match[1] !== undefined) {
                        match[1] = this.form.elmts.countryCode.value.value;
                    } else {
                        match[1] = "+1 ";
                    }
                    formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
                    this.form.elmts.mobileNumber.setValue(formattedString);
                }
            }
        }


        render() {
            this.enlistedCountryCode = [
                {
                    label: "+1",
                    value: "+1",
                },
            ];
            let data = this.state.appointmentData;
            return (
                <View style={styles.mainLogin}>
                    <View style={[styles.bgContainer, styles.leftSection, customCommonStyles.customXBPRegibgContainer]}>
                        <Image source={mainImg} style={[styles.backgroundImage]} />
                    </View>
                    <Surface style={[styles.rightSection, customCommonStyles.customXBPRegiRightSection]}>
                        <View>
                            <View style={[styles.loginHeader, commonStyles.justifyContentCenter, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexGrow0, commonStyles.alignItemsCenter, styles.logoImageContainer,]}>
                                <View style={commonStyles.margin0Auto}>
                                    <Image resizeMode="contain" style={[commonStyles.logoImage]} source={XBPlogo} />
                                </View>
                            </View>
                            <Form
                                name="clientForm"
                                style={[styles.form, commonStyles.flexGrow0, commonStyles.paddingTop0]}
                                onSubmit={this.handleSubmit}
                                validateBeforeSubmit={true}
                                validators={CommonValidators.getFormValidator()}>
                                <View style={[this.state.isValidated ? commonStyles.displayNone : null]}>
                                    <TextInputField
                                        name="firstName"
                                        label="First Name*"
                                        required={CommonString.EnterFirstNameRequired}
                                        style={[styles.fNameField, commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                        validator="min2Max50"
                                        onChangeText={this.trimWhiteSpacesFirstName}
                                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                    />
                                    <TextInputField
                                        name="lastName"
                                        label="Last Name"
                                        style={[styles.lNameField, commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                        validator="min2Max50"
                                        onChangeText={this.trimWhiteSpacesLastName}
                                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                    />
                                    {/* NOTE: An approach to bunk browser autofill. */}
                                    <TextInputField name="uEmail" label="Email Address" style={styles.hideField} />
                                    <TextInputField
                                        name="userEmail"
                                        label="Email*"
                                        required={CommonString.EmailRequired}
                                        style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                        validator="validateEmail"
                                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                    />
                                    <TextInputField name="mobileNumber" label="Mobile Number" style={styles.hideField} value="checked" />
                                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.mobileNumberHolder]}>
                                        <TextInputField
                                            name="+1"
                                            value="+1"
                                            style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight, styles.countryCode]}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                            readOnly={true}
                                            helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                            disabled={true}
                                        />
                                        <SelectMenuField
                                            style={[commonFontStyles.workSans, commonMetricsStyle.heading6, styles.countryCode, customCommonStyles.inputHeight, commonStyles.invisibleBlock]}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                                            name="countryCode"
                                            required="Select Country Code"
                                            items={this.enlistedCountryCode}
                                            onValueChange={this.onDomainExtensionChange}
                                            searchable
                                            disabled={true}
                                            itemLoader={async ({ search }) => {
                                                let items = this.enlistedCountryCode;
                                                return items
                                                    .filter((item) => {
                                                        return item.label.toLowerCase().includes(search.toLowerCase());
                                                    })
                                                    .sort((a, b) => b.label - a.label);
                                            }}
                                            helperTextStyle={[commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                        />
                                        <TextInputField
                                            name="mobileNumber"
                                            label="Mobile Number*"
                                            required={CommonString.EnterMobileNoRequired}
                                            style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                            validator="max10phoneNoValidator"
                                            onChangeText={this.mobileNumberText}
                                            valueParser={(val) => parseInt(val)}
                                            helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0, styles.mobileNumErrorTest]}
                                        />

                                    </View>
                                    <View
                                        style={[
                                            commonStyles.justifyContentCenter,
                                            commonStyles.alignItemsCenter,
                                            styles.orSection,
                                        ]}
                                    >
                                        <Text style={[commonFontStyles.workSans, styles.or]}>
                                            Provider Section
                                        </Text>
                                    </View>
                                    <TextInputField
                                        name="providerName"
                                        label="Provider Name"
                                        style={[styles.fNameField, commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                        validator="min2Max50"
                                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                    />
                                    <TextInputField
                                        name="npi"
                                        label="NPI"
                                        style={[styles.lNameField, commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                        validator="min10Max10DigitNPI"
                                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                    />
                                    <View
                                        style={[
                                            commonStyles.justifyContentCenter,
                                            commonStyles.alignItemsCenter,
                                            styles.orSection,
                                            commonStyles.marginBottom25
                                        ]}
                                    >
                                        <Text style={[commonFontStyles.workSans, styles.or]}>
                                            Treatment Section
                                        </Text>
                                    </View>

                                    <View style={[styles.customDatePickerWidth, commonStyles.marginBottom30]}>
                                        <DatePicker
                                            name={"treatmentDate"}
                                            formName="clientForm"
                                            label={"Treatment Date*"}
                                            required={"Treatment Date Required"}
                                            props={this.props}
                                            style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                            helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                        />
                                    </View>
                                    <TextInputField
                                        name="address1"
                                        label="Treatment Address1*"
                                        required={"Treatment Address1 Required"}
                                        style={[styles.fNameField, commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                        validator="min2Max50"
                                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                    />
                                    <TextInputField
                                        name="appointmentCost"
                                        label="Appointment Cost*"
                                        required={"Appointment Cost Required"}
                                        style={[styles.lNameField, commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                        validator="max10WithDecimal"
                                        onChangeText={async (val) => await this.amountText(val)}
                                        valueParser={(val) => parseFloat(val)}
                                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                    />
                                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.marginBottom10, styles.marginTop20]}>
                                        {BILL_OPTS.map((el, key) => (
                                            <View key={"tabItems_" + key} style={[commonStyles.marginRight16]}>
                                                <RadioButtons radioButtonsClass={styles.minHeight27} textOnlyStyles={styles.height11} key={key} options={el} onValueChange={this.onValueChange} isSelected={this.state.selectedValue} />
                                            </View>
                                        ))}
                                    </View>
                                    <View style={[commonStyles.marTAuto, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                                        <View style={[commonStyles.fullWidth, commonStyles.marginRight8]}>
                                            <SubmitButton
                                                key="validate"
                                                title="Validate"
                                                style={[commonStyles.blueButton, commonStyles.fullWidth, customCommonStyles.noBoxShadow,]}
                                                titleTextStyle={[commonStyles.blueButtonColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.whiteColor]}
                                            />
                                        </View>
                                    </View>
                                </View>
                                <View style={[!!this.state.isValidated ? null : commonStyles.displayNone]}>
                                    <View style={[commonStyles.marTAuto, commonStyles.flex1, commonStyles.flexDirectionColumn, commonStyles.marginTop16, commonStyles.marginBottom16,]}>
                                        {!!data && Object.keys(data).map((key, index) => {
                                            if (key !== "access_token") {
                                                return <Text
                                                    key={index}
                                                    style={[
                                                        commonStyles.dBlock,
                                                        commonStyles.fontWeight500,
                                                        commonStyles.heading6,
                                                        commonFontStyles.workSans,
                                                        commonStyles.labelDetails,
                                                    ]}> {key.toLocaleUpperCase()} : {data[key]} </Text>
                                            } return null;
                                        })}

                                    </View>
                                    <View style={[commonStyles.marTAuto, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                                        <View style={[commonStyles.flexBasis48, commonStyles.marginRight8]}>
                                            <Button
                                                key="submit"
                                                title="Submit"
                                                onPress={() => this.onPress("submit")}
                                                style={[commonStyles.blueBorderButton, commonStyles.fullWidth]}
                                                titleTextStyle={[
                                                    commonStyles.blueBorderButtonColor,
                                                    commonStyles.fontWeight500,
                                                    commonFontStyles.workSans,
                                                    commonMetricsStyle.heading6,
                                                ]}
                                            />
                                        </View>
                                        <View style={[commonStyles.flexBasis48]}>
                                            <Button
                                                key="history"
                                                title="History"
                                                onPress={() => this.onPress("history")}
                                                style={[commonStyles.blueBorderButton, commonStyles.fullWidth]}
                                                titleTextStyle={[
                                                    commonStyles.blueBorderButtonColor,
                                                    commonStyles.fontWeight500,
                                                    commonFontStyles.workSans,
                                                    commonMetricsStyle.heading6,
                                                ]}
                                            />
                                        </View>
                                        <View style={[commonStyles.flexBasis48, commonStyles.marginLeft8]}>
                                            <Button
                                                key="view"
                                                title="View"
                                                onPress={() => this.onPress("view")}
                                                style={[commonStyles.blueBorderButton, commonStyles.fullWidth]}
                                                titleTextStyle={[
                                                    commonStyles.blueBorderButtonColor,
                                                    commonStyles.fontWeight500,
                                                    commonFontStyles.workSans,
                                                    commonMetricsStyle.heading6,
                                                ]}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </Form>
                        </View>
                        <View style={[styles.mrgTopAuto, commonStyles.marginBottom10, styles.copyRight]}>
                            <CopyRight />
                        </View>
                    </Surface>
                </View>
            );
        }
    }
);

const styles = StyleSheet.create({
    loginHeader: {
        paddingTop: 16,
        paddingRight: 16,
        paddingBottom: 8,
        paddingLeft: 16,
        // marginTop: 8,
        screen: ({ width, height }) => {
            if (width <= 991) {
                return {
                    paddingRight: 0,
                    paddingLeft: 0,
                    display: "none",
                };
            }
        },
    },
    mainLogin: {
        flex: 1,
        backgroundColor: "#fff",
        flexDirection: "row",
        screen: ({ width, height }) => {
            if (width <= 991) {
                return {
                    flexDirection: "column",
                };
            }
        },
    },
    leftSection: {
        flex: 2,
        screen: ({ width }) => {
            if (width < 1024) {
                return {
                    display: "none",
                };
            }
        },
    },
    rightSection: {
        // flex: 1,
        width: 550,
        maxWidth: 550,
        // justifyContent: "center",
        position: "relative",
        padding: 0,
        screen: ({ width, height }) => {
            if (width === 1024) {
                return {
                    width: 485,
                    maxWidth: 550
                }
            }
            if (width < 1024) {
                return {
                    marginTop: 0,
                    paddingLeft: 16,
                    paddingRight: 16,
                    marginRight: "auto",
                    marginBottom: 0,
                    marginLeft: "auto",
                    maxWidth: "100%",
                    minWidth: "100%",
                    boxShadow: "none",
                    // height: "100vh",
                    alignItems: "stretch",
                    alignSelf: "stretch",
                    paddingBottom: 25,
                    flex: 1,
                    justifyContent: "space-between"
                };
            }
        },
    },
    backgroundImage: {
        width: "144%",
        height: "100%",
        display: "block",
        backgroundSize: "cover",
        backgroundPosition: "left center",
        screen: ({ width, height }) => {
            if ((width === 1280) && (height >= 881 && height <= 1024))
                return {
                    // backgroundPosition: "30%",
                    backgroundPosition: "-90px center",
                };
            if (width === 1024)
                return {
                    backgroundPosition: "30% center",
                };
        },
    },
    bgContainer: {
        width: "100%",
        display: "block",
        height: "100vh",
        screen: ({ width }) => {
            if (width <= 768)
                return {
                    display: "none",
                    width: 0,
                };
        },
    },
    registerButton: {
        width: "100%",
    },
    fNameField: {
        width: "100%",
    },
    lNameField: {
        width: "100%",
    },
    uNameField: {
        width: "100%",
    },
    mNumberField: {
        width: "100%",
    },
    emailField: {
        width: "100%",
    },
    passwordField: {
        width: "100%",
    },
    cPasswordField: {
        width: "100%",
    },
    loginLogo: {
        width: "100%",
        height: 90,
        marginBottom: 90,
    },
    rememberMeText: {
        position: "relative",
        top: -28,
        left: 30,
    },
    addBank: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: 30,
        marginBottom: 30,
        color: "#37c2ef",
        fontSize: "14",
    },
    addBankSection: {
        textAlign: "center",
        color: "#0B4A87",
        width: "100%",
    },
    loginControls: {
        width: "100%",
    },
    createAccount: {
        color: "#0B4A87",
        fontSize: "14",
        textTransform: "none",
        marginLeft: 5,
    },
    twoCol: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    hideField: {
        display: "none",
    },
    redHelperText: {
        color: "#B00020",
        position: "static",
    },
    greenHelperText: {
        color: "green",
    },
    logoImageContainer: {
        width: "100%",
        textAlign: "center",
        marginTop: 0,
        marginRight: "auto",
        marginBottom: 4,
        marginLeft: "auto",
    },

    form: {
        padding: 16,
        screen: ({ width, height }) => {
            if (width <= 1024)
                return {
                    marginTop: "15%"
                }
            if (width <= 991)
                return {
                    paddingTop: 5,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 20,
                    marginLeft: 0,
                };
        },
    },
    countryCode: {
        maxWidth: 50,
    },
    customHelperTextStyle: {
        position: "static",
        // marginLeft:12,
        // marginTop:0,
        // marginBottom:0,
        // marginRight:0,
        ...StyleSheet.margin(0, 0, 0, 12),
        display: "block",
    },
    customHelperTextStyleMobile: {
        position: "static",
        display: "block",
        ...StyleSheet.margin(0, 0, 0, -58),
    },
    copyRight: {
        textAlign: "center",
        marginTop: "auto",
        screen: ({ width, }) => {
            if (width <= 1024)
                return {
                    paddingTop: 5,
                    paddingRight: "2%",
                    paddingBottom: 0,
                    paddingLeft: "2%",
                };
        },
    },
    helpIcon: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    registrationHelpTooltipHolder: {
        position: "relative",
    },
    registrationHelpTooltip: {
        position: "absolute",
        backgroundColor: "#333",
        color: "#fff",
        top: 45,
        zIndex: 999,
        padding: 10,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    registrationHelpTooltipArrow: {
        width: 10,
        height: 10,
        display: "block",
        position: "absolute",
        backgroundColor: "#333",
        top: -4,
        right: 17,
        transform: [{ rotate: "45deg" }],
    },
    logoIcon: {
        width: 150,
        height: 55,
        display: "block",
        backgroundSize: "contain",
    },
    logoHolder: {
        minWidth: 160,
        maxWidth: 160,
    },
    mViewlogoIcon: {
        maxWidth: 40,
        marginRight: 16,
    },
    mobileNumErrorTest: {
        display: "block",
        marginLeft: -50,
        width: "130%",
    },
    loginWithSocialWrap: {
        marginTop: 0,
        marginRight: 16,
        marginBottom: 0,
        marginLeft: 16,
        position: "relative",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    marginRight: 0,
                    marginLeft: 0,
                };
        },
    },
    loginWithSocial: {
        // marginBottom: 23,
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
        flexDirection: "column",
    },
    socialButtons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        minHeight: 35,
        marginBottom: 15,
    },
    orSection: {
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderBottomColor: "rgba(186, 186, 186, 1)",
        position: "relative",
        textAlign: "center",
        marginBottom: 14,
        marginTop: -25,
    },
    or: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        // border: "1px solid #bababa",
        borderTopLeftRadius: 50,
        borderTopRightRadius: 50,
        borderBottomLeftRadius: 50,
        borderBottomRightRadius: 50,
        backgroundColor: "#fff",
        position: "relative",
        top: 10,
        paddingTop: 1,
        paddingRight: 5,
        paddingBottom: 1,
        paddingLeft: 5,
        letterSpacing: 0.5,
        fontSize: 14,
    },
    mobileNumberHolder: {
        minHeight: 52,
    },
    minHeight27: {
        minHeight: 27,
        paddingBottom: 8,
    },
    height11: {
        height: 11,
        width: "auto"
    },
    customDatePickerWidth: {
        marginBottom: 16
    },
    marginTop20: {
        marginTop: -15
    }
});
