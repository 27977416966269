import React from "react";
import R14 from "../core";
import ResponsiveTableComponent from "../components/ResponsiveTableComponent";
import DateUtils from "../utils/DateUtils";
import { BILLER_STATUS } from "../constant/app.data.constant";
import JsonToCSV from "../utils/JsonToCSV";
import HtmlDecode from "../utils/HtmlDecode";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

const defaultSec = 10; //60

export default R14.connect(
  class BillersDirectoryScreen extends React.Component {
    constructor(props) {
      super(props);
      this.timerInstance = null;
    }

    async componentDidMount() {
      this.props.app.dm.dashboard.setState({selectedScreen: "RTP"});
      this.timerInstance = setInterval(async () => {
        const { isSearchOpen } = this.props.app.dm.userSearchDomain.state;
        if(!!isSearchOpen) return false;
        else await this.props.app.dm.billerDirectoryDomain.displayBillerDirectoryData();
      }, 1000 * defaultSec);
    }

    componentWillUnmount() {
      if (this.timerInstance) {
        clearInterval(this.timerInstance);
      }
    }

    onNavigate = (item) => {
        analyticsLogs(CommonString.PAM_LIST, `${CommonString.PAM_DETAILS}`, this.props.app);
        this.props.app.nav.to("pamMessageRoute", { pamId: item.pamId, pid: item.payerId });
        return false;
    };

    convertToCSV = async(items, screenName = "data") => {
      analyticsLogs(CommonString.PAM_LIST, `${CommonString.DOWNLOAD_CSV}`, this.props.app);
      if (items.length){
          items = items.map(item => {
            return {
              "Biller Name": item.billerName || item.biller.billerName,
              "Business Unit Name": item.buName || item.biller.buName,
              "Account Number": item.accountNumber,
              "Status": BILLER_STATUS[item.status],
            }
          })
        const csvContent = JsonToCSV.convertJsonToCSV(items);
        HtmlDecode.downloadUTF8file({
          content: csvContent,
          filename: `${screenName}_${DateUtils.formatYYYYMMDDHHMMSS()}.csv`
        })
      }
      return false;
    }

    render() {
      const { pamList, searchPamList } = this.props.app.dm.billerDirectoryDomain.state;
      const { isSearchOpen } = this.props.app.dm.userSearchDomain.state;
      let leftMenuToggle = this.props.app.dm.userSession.state.leftMenuToggle;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      let currentRoute =this.props.r14.navigation.activeRouteName;

      let displayPamList = [];
      if (isSearchOpen) displayPamList = searchPamList;
      else displayPamList = pamList ? pamList : [];

      const { notificationThreadIds } = this.props.app.dm.notificationDomain.state;
      let messages = displayPamList.map((data, i) => {
        return data;
      });
      if (notificationThreadIds.length > 0) {
        let d = notificationThreadIds.map((data, i) => {
          return data.threadId;
        });
        messages = messages.filter((message) => d.includes(message.pamId));
      }

      return (
          <ResponsiveTableComponent
            currentRoute={currentRoute}
            displayPamList={messages}
            leftMenuToggle={leftMenuToggle}
            onNavigate={this.onNavigate}
            convertToCSV={this.convertToCSV}
            isSimpleMessageScreen={false}
            isBillerDirectory={true}
            isMakePayment={false}
            isDashboard={false}
            isMobileDevice={isMobileDevice}
            message = {"No billers found."}
            headerText = {this.props.r14.navigation.currRoute._config.label}
          /> 
      );
    }
  }
); 
