import React from "react";
import R14, {
  TextInputField,
  SubmitButton,
  StyleSheet,
  Surface,
  View,
  Form,
  Touchable,
  Image,
  Button,
  Dimensions
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
import CommonValidators from "../utils/CommonValidators";
import RSAencryption from "../utils/RSAencryption";
import { LOGIN_TYPE, USER_TYPE } from "../constant/app.data.constant";
import { KC_CLIENT_ID } from "../constant/app.constant";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";
import DropDown from "../components/DropDown";
const mainImg = Dimensions.get("window").width === 1024 && Dimensions.get("window").height === 1292 ? require("../assets/images/mainImg-ipad.png") : require("../assets/images/mainImg.png");
const backIcon = require("../assets/images/back_icon.png");
const XBPlogo = require("../assets/images/NewXbpLogo150.png");
const defaultCode = {
  label: "+1",
  value: "+1",
};

export default R14.connect(
  class RegistrationScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        enableErrorStyle: false,
        defaultDomain: "#xbp.exela.global",
        socialMediaRegistrationEnabled: this.props.registrationType.type !== "new" ? true : false,
        currentContextProfileId: "",
        isOpenRegistrationHelpTooltip: false,
        showPassword: true,
        showConfirmPassword: true,
      };
      this.countryCodes = [defaultCode];
    }

    componentDidMount = async () => {
      try {
        this.form = this.props.app.ui.form("registrationForm");
        this.form.elmts.countryCode.setValue(defaultCode);
        if (!!this.form && !!this.form.elmts && !!this.form.elmts.countryCode) {
          let result = await this.props.app.dm.miscellaneousDomain.countryCodes();
          result.forEach((code) => {
            this.countryCodes.push({
              label: `+${code.mobile_code}`,
              value: `+${code.mobile_code}`,
            });
          });
          this.form.elmts.countryCode.setItems(this.countryCodes);
          this.form.elmts.countryCode.setValue(defaultCode);
        }

        // Fill data received from google auth
        const { socialLoginProfile, socialLoginType } = this.props.app.dm.userLoginDomain.state;
        if (this.state.socialMediaRegistrationEnabled && socialLoginType === LOGIN_TYPE.GOOGLE) {
          let form = this.props.app.ui.form("registrationForm");
          form.elmts.firstName.setValue(socialLoginProfile.givenName);
          form.elmts.lastName.setValue(socialLoginProfile.familyName);
          form.elmts.userEmail.setValue(socialLoginProfile.email);
          this.setState({ currentContextProfileId: socialLoginProfile.googleId });
        } else if (this.state.socialMediaRegistrationEnabled && socialLoginType === LOGIN_TYPE.APPLE) {
          let form = this.props.app.ui.form("registrationForm");
          const { authorization, user = "" } = socialLoginProfile;
          const payload = RSAencryption.AppleTokenDecode(authorization.id_token);
          this.setState({ currentContextProfileId: payload.sub });
          if (user !== "") {
            form.elmts.firstName.setValue(user.name.firstName);
            form.elmts.lastName.setValue(user.name.lastName);
            form.elmts.userEmail.setValue(user.email);
          } else form.elmts.userEmail.setValue(payload.email);
        }
        else if (this.state.socialMediaRegistrationEnabled && socialLoginType === LOGIN_TYPE.AMAZON) {
          let form = this.props.app.ui.form("registrationForm");
          form.elmts.firstName.setValue(socialLoginProfile.firstName);
          form.elmts.lastName.setValue(socialLoginProfile.lastName);
          form.elmts.userEmail.setValue(socialLoginProfile.email);
          this.setState({ currentContextProfileId: socialLoginProfile.id });
        }
        else if (socialLoginType === LOGIN_TYPE.KEYCLOAK) {
          let form = this.props.app.ui.form("registrationForm");
          form.elmts.firstName.setValue(socialLoginProfile.firstName);
          form.elmts.lastName.setValue(socialLoginProfile.lastName);
          form.elmts.userEmail.setValue(socialLoginProfile.email);
          this.setState({ currentContextProfileId: socialLoginProfile.kcId });
        }

      } catch (error) {

      }
    }

    
    readTextConfirmPassword = (val) => {
      this.confirmPassword = val;
    }

    redirectToReturnUrl = async () => {
      let returnUrl = this.props.app.route._location.search;
      if (returnUrl.indexOf("?ReturnUrl=") > -1) {
        if (returnUrl.indexOf("medical-bills") > -1) {
          let uid = returnUrl.split("/");
          uid = uid[uid.length - 1];
          this.props.app.nav.to("uploadedBillsRoute", { uid: uid });
          return false;
        } else {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.InvalidBill);
          this.props.app.nav.to("profileViewRoute");
        }
      } else {
        this.props.app.nav.to("profileViewRoute");
      }
    };

    navPchLogin = () => {
      let returnUrl = this.props.app.route._location.search;
      if (!!returnUrl && returnUrl.length > 0) document.location = `${window.location.origin}/claims/login${returnUrl}`;
      else this.props.app.nav.to("login");
    };

    handleSubmit = async (form) => {
      this.props.app.ui.progressIndicator.show();
      const { socialLoginType } = this.props.app.dm.userLoginDomain.state;
      let payerPhoneNumber = form.elmts.countryCode.value.value + " " + form.elmts.mobileNumber.value.replace(/[^\w\d\s]/g, "").replace(" ", "");
      this.clearAllValidationMessages();
      let validateNameText = this.nameFieldsValidation();
      let validatePassword = false;
      let validateBothPasswords = false;
      let validateAppleMail = false;
      if (!!socialLoginType) {
        validateAppleMail = await this.applePrivateEmailValidation();
        validatePassword = true;
        validateBothPasswords = true;
      } else {
        validateAppleMail = true;
        validatePassword = true;
        validateBothPasswords = true;
        // validatePassword = this.passwordValidation();
        // validateBothPasswords = this.matchPasswordValidation();
      }

      if (validateNameText && validateAppleMail && validatePassword && validateBothPasswords) {
        let reqBody = {
          firstName: form.elmts.firstName.value,
          lastName: !!form.elmts.lastName.value ? form.elmts.lastName.value : "",
          email: form.elmts.userEmail.value.trim(),
          mobileNumber: payerPhoneNumber,
          // password: this.confirmPassword,
          socialProfileId: this.state.currentContextProfileId,
          userType: USER_TYPE.XBP,
          loginType: !!socialLoginType ? socialLoginType : LOGIN_TYPE.NORMAL,
          signInLocation: USER_TYPE.XBP,
          clientId: KC_CLIENT_ID
        };
        try {
          let res = await this.props.app.dm.userRegistration.signup(reqBody);
          if (!!res && !!res.success) {
            const {access_token, refresh_token } = this.props.app.dm.userLoginDomain.state;
            analyticsLogs(CommonString.Payer_Registration, CommonString.Registered_New_Payer, this.props.app);
            this.props.app.dm.commonDomain.showToastr("success", res.message);
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            if (!!access_token && !!reqBody.socialProfileId && !!res.userLoginDetail && !!res.userLoginDetail.uid) {
              this.props.app.dm.userLoginDomain.setState({
                socialLoginProfile: null,
                socialLoginType: "",
              });
              res.access_token = access_token;
              res.refresh_token = refresh_token;
              await this.props.app.dm.userSession._processAuthResult(res);
              await this.redirectToReturnUrl();
              return false;
            } else {
              this.navPchLogin();
              return false;
            }
          } else {
            this.props.app.dm.commonDomain.showToastr("error", !!res.message ? res.message : res.error);
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            return false;
          }
        } catch (err) {
          this.props.app.dm.commonDomain.showToastr("error", err.message);
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          return false;
        }
      } else {
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        return false;
      }
    };

    // Clear Validation Message
    clearAllValidationMessages = () => {
      let regForm = this.props.app.ui.form("registrationForm");
      regForm.elmts.firstName.setError("");
      regForm.elmts.lastName.setError("");
      regForm.elmts.userEmail.setError("");
      regForm.elmts.mobileNumber.setError("");
      // regForm.elmts.userName.setError("");
      // regForm.elmts.domainExtensions.setError("");
      // regForm.elmts.password.setError("");
      // if (!this.state.socialMediaRegistrationEnabled) {
      //   regForm.elmts.newPassword.setError("");
      //   regForm.elmts.confirmPassword.setError("");
      // }
      // else {
      //  regForm.elmts.password.setError("");
      // }
    };

    // Validate names fields.
    nameFieldsValidation = () => {
      let fName = this.props.app.ui.form("registrationForm").elmts.firstName;
      let lName = this.props.app.ui.form("registrationForm").elmts.lastName;
      let isValidFirstName = false;
      let isValidLastName = false;
      // --- First Name
      if (fName.value !== "") {
        if (/^[a-zA-Z0-9\s]{2,50}$/gi.test(fName.value.trim()) === false) {
          fName.setError("Min 2 - Max 50 alphanumeric characters allowed");
        } else {
          fName.setError("");
          isValidFirstName = true;
        }
      }
      // --- Last Name
      if (lName.value !== "") {
        if (/^[a-zA-Z0-9\s]{2,50}$/gi.test(lName.value) === false) {
          lName.setError("Min 2 - Max 50 alphanumeric characters allowed");
        } else {
          lName.setError("");
          isValidLastName = true;
        }
      }
      else
        isValidLastName = true;

      if (isValidFirstName && isValidLastName) {
        return true;
      } else {
        return false;
      }
    };

    trimWhiteSpacesFirstName = (val) => {
      this.form.elmts.firstName.setValue(val.trimStart());
    };

    trimWhiteSpacesLastName = (val) => {
      this.form.elmts.lastName.setValue(val.trimStart());
    };

    // Validate password field.
    passwordValidation = () => {
      let formElement = this.props.app.ui.form("registrationForm").elmts.newPassword;
      let passwordTxt = formElement.value;
      let isUpper = false;
      let isNumeric = false;
      let isSpecial = false;
      let minMaxLength = false;
      let bContainsSpace = false;
      let bContainsLowerCase = false;

      // Upper Case check
      if (passwordTxt.replace(/[^A-Z]/g, "").length > 0) {
        isUpper = true;
      }
      // Numeric Character check
      if (passwordTxt.replace(/[^0-9]/g, "").length > 0) {
        isNumeric = true;
      }
      // Special Character check
      if (passwordTxt.replace(/[^#@$!%*?]/g, "").length > 0) {
        isSpecial = true;
      }
      // Lower case check
      if (passwordTxt.replace(/[^a-z]/g, "").length > 0) {
        bContainsLowerCase = true;
      }
      // Check if any whitespace.
      if (/\s/.test(passwordTxt)) {
        bContainsSpace = true;
      }
      // Length Check
      if (passwordTxt.length >= 8 && passwordTxt.length <= 15) {
        minMaxLength = true;
      }
      if (isUpper && isNumeric && isSpecial && minMaxLength && bContainsLowerCase && !bContainsSpace) {
        formElement.setError("");
        return true;
      } else {
        formElement.setError("Enter Min 8, Max 15 Characters at least 1 Uppercase and 1 lower case, 1 numeric with Special Char #@$!%*?");
        return false;
      }
    };

    // Validate both password fields.
    matchPasswordValidation = () => {
      let newPasswordTxt = this.props.app.ui.form("registrationForm").elmts.newPassword;
      let confirmPasswordTxt = this.props.app.ui.form("registrationForm").elmts.confirmPassword;

      let isValid = false;
      if (newPasswordTxt.value !== "") {
        if (confirmPasswordTxt.value !== "") {
          if (newPasswordTxt.value !== confirmPasswordTxt.value) {
            confirmPasswordTxt.setError("Passwords do not match");
            isValid = false;
          } else {
            confirmPasswordTxt.setError("");
            isValid = true;
          }
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
      return isValid;
    };

    // Mobile number validation.
    mobileNumberText(val) {
      let mobNumText = val;
      if (!!val) {
        let cleaned = ("" + mobNumText).replace(/\D/g, "").substring(0, 10);
        this.form.elmts.mobileNumber.setValue(cleaned);
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          let formattedString = "";
          if (match[1] !== undefined) {
            match[1] = this.form.elmts.countryCode.value.value;
          } else {
            match[1] = "+1 ";
          }
          formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
          this.form.elmts.mobileNumber.setValue(formattedString);
        }
      }
    }

    //Apple Private validation.
    applePrivateEmailValidation = async () => {
      // --- mg6bydhtm5@privaterelay.appleid.com
      let frmElmt = this.props.app.ui.form("registrationForm").elmts.userEmail;
      let privateEmail = frmElmt.value;
      let res = privateEmail.includes("privaterelay.appleid.com");
      if (res) {
        // frmElmt.setError("Please Enter Vaild Email Address.");
        // return false;
        frmElmt.setError("");
        return true;
      } else {
        frmElmt.setError("");
        return true;
      }
    };

    navToLogin = () => {
      analyticsLogs(CommonString.Payer_Registration, CommonString.Clicked_Cancel, this.props.app);
      this.props.app.dm.userLoginDomain.setState(
        {
          socialLoginType: "",
          socialLoginProfile: null
        })
      this.props.app.nav.to("login");
      return false;
    };

    toggleShowPassword() {
      this.setState({ showPassword: !this.state.showPassword });
    }
    toggleShowConfirmPassword() {
      this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }

    onSearchCountryCode = async (val) => {
      this.countryCodes = []
      if (!!val && !!this.form && !!this.form.elmts && !!this.form.elmts.countryCode) {
        let result = await this.props.app.dm.miscellaneousDomain.countryCodes(val);
        result.forEach((code) => {
          this.countryCodes.push({
            label: `+${code.mobile_code}`,
            value: `+${code.mobile_code}`,
          });
        });
        this.form.elmts.countryCode.setItems(this.countryCodes);
      }
      else {
        let result = await this.props.app.dm.miscellaneousDomain.countryCodes(val);
        this.countryCodes = [defaultCode];
        result.forEach((code) => {
          this.countryCodes.push({
            label: `+${code.mobile_code}`,
            value: `+${code.mobile_code}`,
          });
        });
        this.form.elmts.countryCode.setItems(this.countryCodes);
      }
      return false;
    }

    onCodeChange = async (val) => {
      if (!val || val.label === "No Results Found" || val.label.includes("Type in a search value")) {
        this.form.elmts.countryCode.setValue(defaultCode);
      } else if (val && val.value) {
        this.form.elmts.countryCode.setValue(val);
      }
    };

    render() {
      const { socialLoginProfile } = this.props.app.dm.userLoginDomain.state;
      return (
          <View style={styles.mainLogin}>
            <View style={[styles.bgContainer, styles.leftSection, customCommonStyles.customXBPRegibgContainer]}>
              <Image source={mainImg} style={[styles.backgroundImage]} />
            </View>
            <Surface style={[styles.rightSection, customCommonStyles.customXBPRegiRightSection]}> 
              <View>
                <View style={[styles.loginHeader, commonStyles.justifyContentCenter, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexGrow0, commonStyles.alignItemsCenter, styles.logoImageContainer,]}>
                  <Touchable onPress={this.navToLogin}>
                    <View>
                      <Image resizeMode="contain" style={[commonStyles.icon20]} source={backIcon} />
                    </View>
                  </Touchable>
                  <View style={commonStyles.margin0Auto}>
                      <Image resizeMode="contain" style={[commonStyles.logoImage]} source={XBPlogo} />
                  </View>
                </View>
              <Form
                name="registrationForm"
                style={[styles.form, commonStyles.flexGrow0, commonStyles.paddingTop0]}
                onSubmit={this.handleSubmit}
                validateBeforeSubmit={true}
                validators={CommonValidators.getFormValidator()}>
                <TextInputField
                  name="firstName"
                  label="First Name*"
                  required= {CommonString.EnterFirstNameRequired}
                  style={[styles.fNameField, commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  validator="min2Max50"
                  onChangeText={this.trimWhiteSpacesFirstName}
                  helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  disabled={!!socialLoginProfile && !!socialLoginProfile.firstName}
                  readOnly={!!socialLoginProfile && !!socialLoginProfile.firstName}
                />
                <TextInputField
                  name="lastName"
                  label="Last Name"
                  style={[styles.lNameField, commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  validator="min2Max50"
                  onChangeText={this.trimWhiteSpacesLastName}
                  helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  disabled={!!socialLoginProfile && !!socialLoginProfile.lastName}
                  readOnly={!!socialLoginProfile && !!socialLoginProfile.lastName}

                />
                <TextInputField
                  name="userEmail"
                  label="Email*"
                  required={CommonString.EmailRequired}
                  style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  validator="validateEmail"
                  helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  disabled={!!socialLoginProfile && !!socialLoginProfile.email}
                  readOnly={!!socialLoginProfile && !!socialLoginProfile.email}
                />
                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.mobileNumberHolder]}>
                  <DropDown
                    name="countryCode"
                    label={"Code*"}
                    onValueChange={this.onCodeChange}
                    searchable={true}
                    items={this.countryCodes}
                    itemLoader={async ({ search }) => {
                        return await this.onSearchCountryCode(search);
                    }}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    style={[customCommonStyles.inputHeight, commonFontStyles.workSans, commonStyles.heading6, commonStyles.marginBottom13, styles.countryCode]}
                    onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "orgName")}/>
                   
                  <TextInputField
                    name="mobileNumber"
                    label="Mobile Number*"
                    required= {CommonString.EnterMobileNoRequired}
                    style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    validator="max10phoneNoValidator"
                    onChangeText={this.mobileNumberText}
                    valueParser={(val) => parseInt(val)}
                    helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0, styles.mobileNumErrorTest]}
                  />
                  
                </View>
                <View style={[commonStyles.marTAuto, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                  <View style={[commonStyles.flexBasis48, commonStyles.marginRight8]}>
                    <Button
                      key="cancel"
                      title="Cancel"
                      onPress={this.navToLogin}
                      style={[commonStyles.blueBorderButton, commonStyles.fullWidth]}
                      titleTextStyle={[
                        commonStyles.blueBorderButtonColor,
                        commonStyles.fontWeight500,
                        commonFontStyles.workSans,
                        commonMetricsStyle.exSmallHeading,
                      ]}
                    />
                  </View>
                  <View style={[commonStyles.flexBasis48, commonStyles.marginLeft8]}>
                    <SubmitButton
                      key="register"
                      title="Register"
                      style={[commonStyles.blueButton, commonStyles.fullWidth, customCommonStyles.noBoxShadow,]}
                      titleTextStyle={[commonStyles.blueButtonColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.exSmallHeading, commonStyles.whiteColor]}
                    />
                  </View>
                </View>
              </Form>
              </View>
            </Surface>
          </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  loginHeader: {
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    // marginTop: 8,
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          paddingRight: 0,
          paddingLeft: 0,
          display: "none",
        };
      }
    },
  },
  mainLogin: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "row",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  leftSection: {
    flex: 2,
    screen: ({ width }) => {
      if (width < 1024) {
        return {
           display: "none",
        };
      }
    },
  },
  // rightSection: {
  //   flex: 1,
  //   width: 550,
  //   maxWidth: 550,
  //   // justifyContent: "center",
  //   position: "relative",
  //   paddingTop: 0,
  //   screen: ({ width }) => {
  //     if (width <= 991) {
  //       return {
  //         width: "100%",
  //         maxWidth: "100%",
  //       };
  //     }
  //   },

  //   screen: ({ width, height }) => {
  //     if (width === 540 && height === 720) {
  //       return {
  //         height: "auto",
  //       };
  //     }

  //     if (width >= 320 && width <= 359) {
  //       return {
  //         width: 320,
  //         height: "auto",
  //       };
  //     }
  //     //  only for iPhone X start
  //     if (width <= 375 && height === 812) {
  //       return {
  //         // height: "100vh",
  //       };
  //     } else if (width <= 375) {
  //       return {
  //         height: "auto",
  //       };
  //     }
  //     //  only for iPhone X end
  //     if (width <= 410) {
  //       return {
  //         height: "auto",
  //       };
  //     }
  //     //  only for iPhone 6/7/8 plus start
  //     if (width <= 414 && height === 736) {
  //       return {
  //         maxWidth: 415,
  //         paddingTop: 0,
  //         paddingRight: 16,
  //         paddingBottom: 0,
  //         paddingLeft: 16,
  //       };
  //     }
  //     //  only for iPhone 6/7/8 plus End

  //     if (width >= 410 && width <= 411) {
  //       return {
  //         paddingTop: 0,
  //         paddingRight: 16,
  //         paddingBottom: 16,
  //         paddingLeft: 16,
  //       };
  //     }
  //     if (width <= 991) {
  //       return {
  //         // paddingTop: 80,
  //         marginTop: 0,
  //         marginRight: "auto",
  //         marginBottom: 0,
  //         marginLeft: "auto",
  //         maxWidth: "100%",
  //         minWidth: "100%",
  //         boxShadow: "none",
  //       };
  //     }
  //   },
  // },
  rightSection: {
    // flex: 1,
    width: 550,
    maxWidth: 550,
    // justifyContent: "center",
    position: "relative",
    padding: 0,
    screen: ({ width, height }) => {
      if (width === 1024) {
        return {
          width:485,
          maxWidth:550
        }
      }
      if (width < 1024) {
        return {
          marginTop: 0,
          paddingLeft: 16,
          paddingRight: 16,
          marginRight: "auto",
          marginBottom: 0,
          marginLeft: "auto",
          maxWidth: "100%",
          minWidth: "100%",
          boxShadow: "none",
          // height: "100vh",
          alignItems: "stretch",
          alignSelf: "stretch",
          paddingBottom: 25,
          flex: 1,
          justifyContent: "space-between"
        };
      }      
    },
  },
  backgroundImage: {
    width: "144%",
    height: "100%",
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "left center",
    screen: ({ width, height }) => {
      if ((width === 1280) && (height >= 881 && height <= 1024))
        return {
          // backgroundPosition: "30%",
          backgroundPosition: "-90px center",
        };
     if (width === 1024)
        return {
          backgroundPosition: "30% center",
        };
    },
  },
  bgContainer: {
    width: "100%",
    display: "block",
    height: "100vh",
    screen: ({ width }) => {
      if (width <= 768)
        return {
          display: "none",
          width: 0,
        };
    },
  },
  registerButton: {
    width: "100%",
  },
  fNameField: {
    width: "100%",
  },
  lNameField: {
    width: "100%",
  },
  uNameField: {
    width: "100%",
  },
  mNumberField: {
    width: "100%",
  },
  emailField: {
    width: "100%",
  },
  passwordField: {
    width: "100%",
  },
  cPasswordField: {
    width: "100%",
  },
  loginLogo: {
    width: "100%",
    height: 90,
    marginBottom: 90,
  },
  rememberMeText: {
    position: "relative",
    top: -28,
    left: 30,
  },
  addBank: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 30,
    marginBottom: 30,
    color: "#37c2ef",
    fontSize: "14",
  },
  addBankSection: {
    textAlign: "center",
    color: "#0B4A87",
    width: "100%",
  },
  loginControls: {
    width: "100%",
  },
  createAccount: {
    color: "#0B4A87",
    fontSize: "14",
    textTransform: "none",
    marginLeft: 5,
  },
  twoCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hideField: {
    display: "none",
  },
  redHelperText: {
    color: "#B00020",
    position: "static",
  },
  greenHelperText: {
    color: "green",
  },
  logoImageContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 4,
    marginLeft: "auto",
  },
  
  form: {
    padding: 16,
    screen: ({ width, height }) => {
      if (width <= 1024)
        return {
          marginTop:"15%"
        }
      if (width <= 991)
        return {
          paddingTop: 5,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 20,
          marginLeft: 0,
        };
    },
  },
  countryCode: {
    maxWidth: 100,
  },
  customHelperTextStyle: {
    position: "static",
    // marginLeft:12,
    // marginTop:0,
    // marginBottom:0,
    // marginRight:0,
    ...StyleSheet.margin(0, 0, 0, 12),
    display: "block",
  },
  customHelperTextStyleMobile: {
    position: "static",
    display: "block",
    ...StyleSheet.margin(0, 0, 0, -58),
  },
  copyRight: {
    textAlign: "center",
    marginTop: "auto",
    screen: ({ width,  }) => {
      if (width <= 1024)
        return {
          paddingTop: 5,
          paddingRight: "2%",
          paddingBottom: 0,
          paddingLeft: "2%",
        };
    },
  },
  helpIcon: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  registrationHelpTooltipHolder: {
    position: "relative",
  },
  registrationHelpTooltip: {
    position: "absolute",
    backgroundColor: "#333",
    color: "#fff",
    top: 45,
    zIndex: 999,
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  registrationHelpTooltipArrow: {
    width: 10,
    height: 10,
    display: "block",
    position: "absolute",
    backgroundColor: "#333",
    top: -4,
    right: 17,
    transform: [{ rotate: "45deg" }],
  },
  logoIcon: {
    width: 150,
    height: 55,
    display: "block",
    backgroundSize: "contain",
  },
  logoHolder: {
    minWidth:160,
    maxWidth:160,
  },
  mViewlogoIcon: {
    maxWidth: 40,
    marginRight: 16,
  },
  mobileNumErrorTest: {
    display: "block",
    marginLeft: -100,
    width: "130%",
  },
  loginWithSocialWrap: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991)
      return {
        marginRight: 0,
        marginLeft: 0,
        };
    },
  },
  loginWithSocial: {
    // marginBottom: 23,
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    flexDirection: "column",
  },
  socialButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    minHeight: 35,
    marginBottom: 15,
  },
  orSection: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderBottomColor: "rgba(186, 186, 186, 1)",
    position: "relative",
    textAlign: "center",
    marginBottom: 24,
  },
  or: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    // border: "1px solid #bababa",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    backgroundColor: "#fff",
    position: "relative",
    top: 10,
    paddingTop: 1,
    paddingRight: 5,
    paddingBottom: 1,
    paddingLeft: 5,
    letterSpacing: 0.5,
    fontSize: 14,
  },
  mobileNumberHolder: {
    minHeight: 72,
  },
  arrowHolderStyle: {
    position: "absolute",
    left: -5,
  },
  toolTipMessageSignupStyle: {
    right: 30,
  },
});
