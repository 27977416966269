import React from "react";
import R14, { StyleSheet, Image, Text, View, Link, Touchable, AsyncStorage } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { BILL_OPTS, SRTP_RESOURES, USER_TYPE } from "../constant/app.data.constant";
import { CommonString } from "../constant/appCommonText";

export default R14.connect(
  class LeftMenuComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        initialized: false,
      };
      this.userLoginDetail = null;
      this.initialize();
    }

    componentDidMount() {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      if (!!isMobileDevice && !(window.innerWidth === 1024)) {
        this.props.app.dm.userSession.setState({
          leftMenuToggle: !isMobileDevice,
          leftMenuOverlay: false,
        });
      }
    }

    closeMenu =  async() => {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      if (isMobileDevice) {
        this.props.app.dm.userSession.setState({
          // leftMenuToggle: !this.props.app.dm.userSession.state.leftMenuToggle,
          leftMenuOverlay: !this.props.app.dm.userSession.state.leftMenuOverlay,
        });
      }
    };
    closeOverlay = async () => {
        this.props.app.dm.userSession.setState({
          leftMenuToggle: false,
          leftMenuOverlay: false,
        });
    };

    async initialize() {
      this.userLoginDetail = await this.props.app.dm.rest.getUser();
      if (!!!this.userLoginDetail) {
        let _sign = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION);
        this.props.app.nav.to(_sign === USER_TYPE.CLAIMS ? "pchLogin" : "login");
        return false;
      }
      this.setState({ initialized: true });
    }

    validateRoute = (data) => {
      if (!!data) {
        try {
          const { selectedScreen } = this.props.app.dm.dashboard.state;
          let routes = new Set(Object.keys(data));
          if (selectedScreen === BILL_OPTS[1].value) {
            let items = SRTP_RESOURES.filter(item => routes.has(item));
            if (!!items && items.length > 0)
              return true;
            else return false;
          }
        } catch (error) {
        }
      }
      return true;
    }

    render() {
      if (!this.state.initialized || !!this.props.app.dm.userSession.state.isExternalLogin) return null;
      let userType = USER_TYPE.XBP;
      const bottomVisibility = false;
      const { leftMenuToggle, leftMenuOverlay } = this.props.app.dm.userSession.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const { leftMenuStyle } = this.props;
      const userRoles = this.props.app.dm.userSession.getUserRoles();
      if (!!this.userLoginDetail && !!this.userLoginDetail.userType) {
        userType = this.userLoginDetail.userType;
      }

      const routesProp = this.props.r14.navigation._navigators.privateNavigator.routes.homeNavigator.routes;
      const asArray = Object.entries(routesProp);
      let filterdArray = asArray.filter(([key, value]) => {
        return !!value.userType ? ((!!value.userType.includes(userType) || ( userType === USER_TYPE.XBP && value.userType.includes(userRoles))) ? true : false) : true;
      });

      // Remove from left menus & move at top right corner
      filterdArray = filterdArray.filter(x=> !!x[1].label && !["Practice Group Profile", "Patient Profile"].includes(x[1].label) && !(x[1].label === "Manage User" && !!this.userLoginDetail.parentUid))
      // select only XBP OR SMB OR XBP_SMB menu items based on resource access
      if(userType === USER_TYPE.XBP){
        filterdArray = filterdArray.filter(x=> !!x[1].userType && x[1].userType.includes(userRoles));
      }

      let currentRoute = this.props.r14.navigation.activeRouteName;
      return (
        <>
          <View
            style={
              !!bottomVisibility && !!isMobileDevice && userType !== USER_TYPE.XBP
                ? [styles.pchLeftMenu, commonStyles.flex1, commonStyles.flexDirectionRow, leftMenuToggle ? styles.pchOpenMenu : styles.pchCloseMenu]
                : [styles.leftMenu, commonStyles.flex1, leftMenuToggle ? [customCommonStyles.leftMenuWrap, styles.openMenu] : styles.closeMenu, leftMenuStyle]
            }>
            {filterdArray.map((ele, key) =>
              !!ele[1].label && this.validateRoute(ele[1].routes) ? (
                <View
                  key={key}
                  style={[
                    !!bottomVisibility && !!isMobileDevice && userType !== USER_TYPE.XBP ? styles.leftMenuItemsHolder : null,
                    (!!ele[1].routes && Object.keys(ele[1].routes).includes(currentRoute)) || ele[0] === currentRoute
                      ? !!bottomVisibility && !!isMobileDevice && userType !== USER_TYPE.XBP
                        ? styles.activeMenuHolder
                        : null
                      : null,
                  ]}>
                  <Link
                    to={!!ele[1].routes ? Object.keys(ele[1].routes)[0] : ele[0]}
                    onPress={this.closeMenu}
                    style={[
                      styles.leftMenuItems,
                      commonStyles.flex1,
                      commonStyles.flexDirectionRow,
                      commonStyles.alignItemsCenter,
                      (!!ele[1].routes && Object.keys(ele[1].routes).includes(currentRoute)) || ele[0] === currentRoute
                        ? !!bottomVisibility && !!isMobileDevice && userType !== USER_TYPE.XBP
                          ? leftMenuToggle
                            ? styles.pchActive
                            : styles.pchToggleActive
                          : leftMenuToggle
                          ? styles.active
                          : styles.toggleActive
                        : null,
                    ]}>
                    {!!ele[1].whiteIconPath ? (
                      !!bottomVisibility && !!isMobileDevice && userType !== USER_TYPE.XBP ? (
                        (!!ele[1].routes && Object.keys(ele[1].routes).includes(currentRoute)) || ele[0] === currentRoute ? (
                          <Image source={ele[1].whiteIconPath} style={[commonStyles.icon18, styles.menuIcon]} />
                        ) : (
                          <Image source={ele[1].whiteIconPath} style={[commonStyles.icon18, styles.menuIcon]} />
                        )
                      ) : leftMenuToggle ? (
                        <Image source={ele[1].whiteIconPath} style={[commonStyles.icon18, styles.menuIcon]} />
                      ) : (
                        <Image source={ele[1].whiteIconPath} style={[commonStyles.icon18, styles.menuIcon]} />
                      )
                    ) : (
                      <View style={styles.emptyIcon}></View>
                    )}
                    {leftMenuToggle ? (
                      !!bottomVisibility && !!isMobileDevice && userType !== USER_TYPE.XBP ? null : (
                        <Text style={[styles.menuText, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>{ele[1].label}</Text>
                      )
                    ) : null}
                  </Link>
                </View>
              ) : null
            )}
          </View>
          {leftMenuOverlay && !(window.innerWidth === 1024) ? (<Touchable onPress={this.closeOverlay} style={[styles.leftMenuOverlayOpen]}><View><Text>&nbsp;</Text></View></Touchable>) : null}
        </>
        );
    }
  }
);

const styles = StyleSheet.create({
  leftMenuWrapper: {
    zIndex: 999999,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          position: "relative",
        };
    },
  },
  leftMenu: {
    // height: "calc(100vh - 64px)",
    height: "100%",
    backgroundColor: "rgb(27,99,181)",
    boxShadow: "3px 0px 2px #0000001A",
    transition: "all 0.3s ease",
    zIndex: 5,
    overflowX: "auto",
    flex: 1,
    top: 40,
    screen: ({ width }) => {
      if (width <= 991){
        return {
          height: "100%",
          zIndex: 999999,
        };
      }
      // if (width === 753){
      //   return {
      //     height: "calc(100vh - 162px)",
      //   };
      // }
      // if (width === 414){
      //   return {
      //     height: "calc(100vh - 170px)",
      //   };
      // }
    },
  },
  openMenu: {
    maxWidth: 180,
    minWidth: 180,
    backgroundColor:"rgb(27,99,181)",
    transition: "all 0.3s ease",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          transition: "all 0.3s ease",
          maxWidth: 215,
          position: "absolute",
          width: 215,
          marginLeft: 0,
        };
    },
  },
  closeMenu: {
    backgroundColor: "rgb(27,99,181)",
    maxWidth: 45,
    // minWidth: 64,
    transition: "all 0.3s ease",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          transition: "all 0.3s ease",
          maxWidth: 0,
          marginLeft: -350,
        };
    },
  },
  leftMenuItemsHolder: {
    flex: 1,
    alignItems: "center",
    borderTop: "1px solid #38c2ef",
  },
  activeMenuHolder: {
    borderTop: "1px solid #016788",
  },
  leftMenuItems: {
    height: 30,
  },
  menuIcon: {
    marginTop: 0,
    marginRight: 10,
    marginBottom: 0,
    marginLeft: 10,
  },
  whiteColorIcon: {
    ColorFill: "#fff",
  },
  menuText: {
    color: "#fff",
    width: "100%",
  },
  leftMenuOverlayOpen: {
    screen: ({ width }) => {
      if (width >= 1024){
        return {
          display: "none",
        };
      }
      if (width <= 991){
        return {
          position: "absolute",
          height: "100%",
          width: "100vw",
          transition: "all 0.3s ease",
          backgroundColor: "#00000052",
          zIndex: 998,
        };
      }
      // if (width === 753){
      //   return {
      //     height: "calc(100vh - 162px)",
      //   };
      // }
      // if (width === 414){
      //   return {
      //     height: "calc(100vh - 170px)",
      //   };
      // }
    },
  },
  leftMenuOverlayClose: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // position: "absolute",
          // height: "calc(100vh - 56px)",
          // width: "0vw",
          // transition: "all 0.3s ease",
          // backgroundColor: "#00000052",
          display: "none",
        };
    },
  },
  active: {
    // backgroundColor: "#58c8f2",
    backgroundColor: "rgb(11,74,135)",
    borderLeftWidth: 2,
    borderStyle: "solid",
    borderColor: "#4DC5F2",
  },
  toggleActive: {
    backgroundColor: "rgb(11,74,135)",
    borderLeftWidth: 2,
    borderStyle: "solid",
    borderColor: "#4DC5F2",
    transition: "all 0.3s ease",
  },
  emptyIcon: {
    minWidth: 64,
  },
  pchLeftMenuWrapper: {
    width: "100%",
    height: "auto",
    position: "unset",
  },
  pchLeftMenu: {
    maxWidth: "100%",
    width: "100%",
    bottom: 0,
    position: "absolute",
    height: 56,
    backgroundColor: "#fff",
  },
  pchOpen: {},
  pchCloseMenu: {},
  pchActive: {
    color: "red",
  },
  pchToggleActive: {
    color: "green",
  },
  copyRight: {
    ...StyleSheet.margin("auto", 16, 16, 16),
  },
  copyrightTooltipHolder: {
    position: "relative",
  },
  copyrightTooltip: {
    position: "absolute",
    backgroundColor: "#37c2ef",
    color: "#fff",
    top: -65,
    zIndex: 999999,
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    minWidth: 220,
    left: 0,
    flex: 1,
    screen: ({ width }) => {
      if (width === 768 || width === 810)
      return {
          // left: -90,
        };
    },
  },
  copyrightTooltipArrow: {
    width: 0,
    height: 0,
    borderLeft: "5px solid #37c2ef",
    borderRight: "5px solid transparent",
    borderBottom: "5px solid transparent",
    position: "absolute",
    bottom: -5,
    left: 5,
    screen: ({ width }) => {
      if (width === 768 || width === 810)
      return {
          // left: 110,
        };
    }
  },
});
