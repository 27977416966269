import React from "react";
import R14, {
  Form,
  View,
  Text,
  StyleSheet,
  Touchable,
  Image,
  Button,
  SubmitButton,
  TextInputField,
  SelectMenuField,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";

import { CommonString } from "../../constant/appCommonText";

import CommonValidators from "../../utils/CommonValidators";

import FileUploadComponent from "../../components/FileUploadComponent";
import InAppCameraComponent from "../../components/InAppCameraComponent";
import DeviceUtils from "../../utils/DeviceUtils";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import DatePicker from "../../components/PCH/DatePicker";
import DateUtils from "../../utils/DateUtils";
import { MESSAGE_TYPE } from "../../constant/app.data.constant";
import PopupModalComponent from "../../components/PopupModalComponent";
import { analyticsLogs } from "../../utils/AnalyticLogs";

const deleteIcon = require("../../assets/images/delete.png");

export default R14.connect(
  class UploadBillScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAddAttachmentsVisible: false,
        attachments: [],
        isCamVisible: false,
      };
      this.form = null;
      this.child = React.createRef();
      this.vendorList = [];
      this.currencies = [];
    }

    componentDidMount = async () => {
      try {
        this.form = this.props.app.ui.form("uploadBill");
        let date = DateUtils.formatDateInMMDDYYYY(new Date());
        this.form.elmts.invoiceDate.setValue(date);
        this.getCurrency();
        await this.onSearchVender();
      }
      catch (err) {
        console.log(err)
      }
    };

    onDiscard = async () => {
      analyticsLogs(CommonString.SMB_UPLOAD_BILL, `${CommonString.DISCARD_FORM}`, this.props.app);
      this.form.reset();
      this.onClear();
      this.props.app.nav.back();
    };

    getCurrency = async () => {
      const currencies = await this.props.app.dm.smbApi.getSMBCurrency({pid: this.props.user.pid});
      let sortedCurrencies = currencies.sort((first, second) => {
        let firstCurrency = first.currencyCode.toLowerCase();
        let secondCurrency = second.currencyCode.toLowerCase();

        if (firstCurrency < secondCurrency) return -1;
        if (firstCurrency > secondCurrency) return 1;
        return 0;
      });
      sortedCurrencies = sortedCurrencies.map(curr => {
        return {
          ...curr,
          label: curr.currencyCode,
          value: curr.currencyCode
        }
      });
      const form = this.props.app.ui.form("uploadBill");
      if(form && form.elmts && form.elmts.currency) form.elmts.currency.setItems(sortedCurrencies);
      this.currencies = sortedCurrencies;
      return sortedCurrencies;
    }


    onClear = () => {
      const form = this.props.app.ui.form("uploadBill");
      form.elmts.venderId.setValue("");
      form.elmts.extNo.setValue("");
      form.elmts.invoiceNumber.setValue("");
      form.elmts.invoiceDate.setValue("");
      form.elmts.invoiceAmount.setValue("");
      form.elmts.discountAmount.setValue("");
      form.elmts.currency.setValue(null);

      form.elmts.venderId.setError(null);
      form.elmts.extNo.setError(null);
      form.elmts.invoiceNumber.setError(null);
      form.elmts.invoiceDate.setError(null);
      form.elmts.invoiceAmount.setError(null);
      form.elmts.discountAmount.setError(null);
      form.elmts.currency.setError(null);

      let date = DateUtils.formatDateInMMDDYYYY(new Date());
      this.form.elmts.invoiceDate.setValue(date);
      this.onSearchVender();
      this.child.current.wrappedInstance.pullErrorText("");
      this.setState({
        attachments: [],
        isAlertVisible: false,
        alertMessage: ""
      });
    };

    onVenderChange = (item) => {
      const {value} = item;
      const form = this.props.app.ui.form("uploadBill");
      const venderDetails = this.vendorList.find(vendor => vendor.value === value);
      form.elmts.extNo.setValue(venderDetails.extRefNumber1);
      if(!!venderDetails.currency) form.elmts.currency.setValue({label: venderDetails.currency, value: venderDetails.currency});
      form.elmts.venderId.setError(null);
    }

    onSearchVender = async (search = "", limit = 10) => {
      const txt = !!search && search.label ? search.label : search;
      const form = this.props.app.ui.form("uploadBill");
      try {
        // graphql API
        let venders = await this.props.app.dm.smbApi.getVenderList({
          search: txt,
          pid: this.props.user.pid,
          resultsPerPage: limit,
        });
        venders = !!venders && venders.length ? venders : [];
        venders = venders.map(vendor => {
          return {
            ...vendor,
            label: vendor.businessName,
            value: vendor.vendorID
          }
        });
        this.vendorList = venders;
        form.elmts.venderId.setItems(venders);
        return venders;
      } catch (error) {
        console.log('error while fetching venders list', error);
        if(!!form && form.elmts && form.elmts.venderId)form.elmts.venderId.setItems([]);
        return [];
      }
    };

    handleSubmit = async (form) => {
      try {
        // const { attachments } = this.state;
        // if (!attachments || attachments.length === 0) {
        //   let error = "Please upload the bill.";
        //   if (!!!isMobileDevice) {
        //     await this.child.current.wrappedInstance.pullErrorText(error);
        //   } else {
        //     this.setState({
        //       isAlertVisible: true,
        //       alertMessage: "Please upload the bill."
        //     })
        //   }
        //   return false;
        // }
        const { venderId, invoiceNumber, invoiceDate, invoiceAmount, discountAmount, currency } = form.values;
        if(!invoiceAmount || (!!discountAmount && (+discountAmount >= invoiceAmount)) ) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.InvoiceAmountMustBeGreater);
          return false;
        };
        const payload = {
          pid: this.props.user ? this.props.user.pid : "",
          type: "bills",
          vendorId: venderId ? venderId.value : "",
          invoiceNo: !!invoiceNumber ? `${invoiceNumber}` : "",
          invoiceAmount: !!invoiceAmount ? `${invoiceAmount}` : "",
          invoiceDate: invoiceDate,
          discountAmount: !!discountAmount ? `${discountAmount}` : "",
          currency: currency ? currency.value : "",
          from: "Mobile App"
        }
        if( !(venderId && venderId.value) || !invoiceNumber || !invoiceAmount) return false;
        this.setState({ loaderVisible: true });
        await this.props.app.dm.smbApi.saveSmbCreateBill(payload);
        this.onClear()
        analyticsLogs(CommonString.SMB_UPLOAD_BILL, `${CommonString.BILL_SUBMITTED_ACTION}`, this.props.app);
        this.props.app.dm.commonDomain.showToastr("success", CommonString.BillCreatedMessage);
        return false;
      } catch (error) {
        this.props.app.dm.commonDomain.showToastr("error", error);
      } finally {
        this.setState({
          loaderVisible: false //false //true
        });
      }
    };

    shortenVendorName = (txt = "", limit = 40) => {
      if (txt.length > limit) {
        return txt.substring(0, limit) + '...';
      } else {
        return txt;
      }
    }

    onFileInputChange = async (val, status) => {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      if (!!!isMobileDevice)
        await this.child.current.wrappedInstance.pullErrorText("");

      if (status === "done") {
        let x = [val];
        const { attachments } = this.state;
        let existingFiles = attachments.filter((value) => value.filename === val.filename && value.actualsize === val.actualsize);
        if (existingFiles.length > 0) {
          let error = "File has been already added."
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "File has been already added."
            })
          }
          return false;
        }

        let filesSize = attachments.reduce((prevVal, val) => prevVal + val.actualsize, 0);
        filesSize = filesSize + val.actualsize;
        if (filesSize / 1024 / 1024 > 10) {
          let error = "Maximum 10MB file size allowed.";
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Maximum 10MB file size allowed."
            })
          }
          return false;
        }

        if (attachments.length >= 1) {
          let error = "Maximum 1 attachment allowed.";
          if (!!!isMobileDevice) {
            await this.child.current.wrappedInstance.pullErrorText(error);
          } else {
            this.setState({
              isAlertVisible: true,
              alertMessage: "Maximum 1 attachment allowed."
            })
          }
          this.setState({ isAddAttachmentsVisible: false });
          return false;
        }
        this.setState({
          loaderVisible: true //false //true
        });
        this.onClear();
        analyticsLogs(CommonString.SMB_UPLOAD_BILL, `${CommonString.ATTACHMENT_ADDED}`, this.props.app);
        attachments.push(x[0]);
        // call API 
        if(x[0] && (x[0].contentType === 'png' || x[0].contentType === 'PNG' || x[0].contentType === 'jpg' || x[0].contentType === 'JPG' || x[0].contentType === 'jpeg' || x[0].contentType === 'JPEG') ){
          let uploadDocContent = await this.props.app.dm.queryDomain.analyzeUploadDocument({
            content: x[0].content,
            documentId: x[0].filename,
          });
          // set values in fields
          const form = this.props.app.ui.form("uploadBill");
          form.reset();
          if(uploadDocContent.InvoiceId) form.elmts.invoiceNumber.setValue(uploadDocContent.InvoiceId);
          if(uploadDocContent.VendorName) {
            const vendorDetails = this.vendorList.find(vendor => vendor.label.toLowerCase() === uploadDocContent.VendorName.toLowerCase());
            if(!!vendorDetails && vendorDetails.value) {
                form.elmts.venderId.setValue(vendorDetails);
                this.onVenderChange(vendorDetails);
            } else {
              const searchVendor = await this.onSearchVender(uploadDocContent.VendorName);
              if(searchVendor && searchVendor.length){
                form.elmts.venderId.setValue(searchVendor[0]);
                this.onVenderChange(searchVendor[0])
              } else {
                const vendorName = this.shortenVendorName(uploadDocContent.VendorName);
                form.elmts.venderId.setError(`"${vendorName}" invalid vendor`);
              }
            }
          }
          if(uploadDocContent.ExtNo) form.elmts.extNo.setValue(uploadDocContent.ExtNo);
          if(uploadDocContent.InvoiceDate) form.elmts.invoiceDate.setValue(DateUtils.formatDateInMMDDYYYY(uploadDocContent.InvoiceDate));
          if(uploadDocContent.InvoiceTotal && uploadDocContent.InvoiceTotal.amount) form.elmts.invoiceAmount.setValue(uploadDocContent.InvoiceTotal.amount);
          if(uploadDocContent.InvoiceTotal && uploadDocContent.InvoiceTotal.currencyCode) form.elmts.currency.setValue({label: uploadDocContent.InvoiceTotal.currencyCode, value: uploadDocContent.InvoiceTotal.currencyCode});
          if(uploadDocContent.DiscountAmount) form.elmts.discountAmount.setValue(uploadDocContent.DiscountAmount.amount);
          if(uploadDocContent.TotalDiscount) form.elmts.discountAmount.setValue(uploadDocContent.TotalDiscount.amount);
        }
        this.setState({ isAddAttachmentsVisible: false, loaderVisible: false, attachments: attachments });
      } else if (status === "removed") {
        const { attachments } = this.state;
        analyticsLogs(CommonString.SMB_UPLOAD_BILL, `${CommonString.ATTACHMENT_REMOVED}`, this.props.app);
        this.setState({
          attachments: attachments.filter((value) => value.filename !== val.filename && value.actualsize !== val.actualsize),
        });
      }
    };

    onDeleteFile = async (ind) => {
      analyticsLogs(CommonString.SMB_UPLOAD_BILL, `${CommonString.FILE_DELETED}`, this.props.app);
      this.form.reset();
      this.onClear();
    };

    onCamClose = async () => {
      this.setState({ isCamVisible: false });
    };

    openCamera = async () => {
      const { osName } = this.state;
      if (osName !== "web") {
        let resPermission = DeviceUtils.getPermission();
        resPermission.then(
          (status) => {
            if (status === "granted")
              this.setState({ isCamVisible: true, hasPermission: status === "granted" });
          })
      }
      else {
        let deviceRes = DeviceUtils.getDevice()
        try {
          deviceRes.then(
            (res) => {
              if (res.includes("granted"))
                this.setState({ isCamVisible: true });
              else if (osName === 'web' && (window.innerWidth <= 800) && (window.innerHeight <= 720)) {
                this.setState({ isCamVisible: true });
              } else
                this.props.app.dm.commonDomain.showToastr("error", res);
            }
          )
        } catch (e) {
          this.props.app.dm.commonDomain.showToastr("error", e);
        }
      }
    }

    renderCamViewer() {
      const { isCamVisible, hasPermission } = this.state;
      return <InAppCameraComponent isCamVisible={isCamVisible} onValueChange={this.onFileInputChange} onCamClose={this.onCamClose} hasPermission={hasPermission} />;
    }

    render() {
      const { attachments,
      } = this.state;
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
      return (
        <>
          {this.renderCamViewer()}
            <View style={[styles.uploadBillSection, clientStyle.secondaryBgColor]}>
              <Form
                name="uploadBill"
                style={[styles.uploadBillsForm, clientStyle.form]}
                validateBeforeSubmit={true}
                validators={CommonValidators.getFormValidator()}
                onSubmit={this.handleSubmit}>
                <View style={[commonStyles.flex1, styles.customflexDirection, styles.inputHolder, styles.attachFileScetion,]}>
                  <View
                    style={[
                      commonStyles.flex1,
                      commonStyles.flexBasis48,
                      commonStyles.flexDirectionRow,
                      commonStyles.alignItemsCenter,
                      commonStyles.justifyContentCenter,
                      styles.attachOpt,
                      styles.leftInputHolder,
                    ]}>
                    <View style={[styles.fileUploadWrapper]}>
                      <FileUploadComponent
                        ref={this.child}
                        maxFiles={1}
                        // fileTypes={"image/jpeg,image/jpg,image/png,application/pdf"} //,application/pdf
                        fileTypes={"image/jpeg,image/jpg,image/png"} //,application/pdf
                        // fileTypeLabel={"jpeg,jpg,png, pdf"}
                        fileTypeLabel={"jpeg,jpg,png"}
                        onValueChange={this.onFileInputChange}
                        // labelText="Drag & Drop Bill (*.jpg, *.jpeg, *.png, *.pdf)"
                        labelText="Drag & Drop Bill (*.jpg, *.jpeg, *.png)"
                        fileInputStyle={[commonStyles.margin0Auto]}
                        attachmentFieldOuterCustomHeight={styles.attachmentFieldOuter}
                      />
                    </View>
                  </View>
                  <View style={[
                    styles.attachmentsHolder,
                    customCommonStyles.customAttachmentsHolder,
                    commonStyles.flex1,
                    commonStyles.flexBasis48,
                    styles.rightInputHolder,
                    attachments && !!attachments.length ? null : [commonStyles.dFlex, commonStyles.alignItemsCenter]]}>
                    {attachments && !!attachments.length ? (
                      attachments.map((el, i) => (
                        <View style={[commonStyles.flex1, styles.attachmentCard, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween]} key={`atta_${i}`}>
                          <View>
                            <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight500, commonStyles.dBlock, customCommonStyles.wordBreakWord]}>
                              {el.filename}
                            </Text>
                            <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.lightGray]}>
                              {el.size}
                            </Text>
                          </View>
                          <Touchable onPress={() => this.onDeleteFile(i)}>
                            <View>
                              <Image source={deleteIcon} style={commonStyles.icon20} />
                            </View>
                          </Touchable>
                        </View>
                      ))
                    ) : null}
                  </View>
                </View>

                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap, this.state.isEditOrAddCpt && styles.displayNone]}>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.leftInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.customFlexDirection,
                      styles.customAlignItemsStart,
                      styles.textAlign
                    ]}>
                    <SelectMenuField
                      name="venderId"
                      label={"Vendor*"}
                      required={CommonString.VendorRequired}
                      onValueChange={this.onVenderChange}
                      searchable
                      itemLoader={async ({ search }) => {
                        return await this.onSearchVender(search);
                      }}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      style={[customCommonStyles.inputHeight, styles.width100]}
                    />
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis48, styles.incNameDropDown]}>
                      <TextInputField
                      name="extNo"
                      label="Ext. Ref"
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      disabled={true}
                      readOnly={true}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    />
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap, this.state.isEditOrAddCpt && styles.displayNone]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown]}>
                    <TextInputField
                      name="invoiceNumber"
                      label="Invoice Number*"
                      required={CommonString.InvoiceNumberRequired}
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />

                  </View>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.rightInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.flexDirectionColumn,
                      styles.customAlignItemsStart,
                      styles.textAlign,
                      commonStyles.alignItemsStart,
                      styles.customMarginBottom30
                    ]}> 
                      <DatePicker
                        name={"invoiceDate"}
                        formName={"uploadBill"}
                        label={"Invoice Date"}
                        props={this.props}
                        style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100, commonStyles.maxWidth100, commonStyles.fullFlexContainer]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                        helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                      />
                    </View>
                </View>

                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap, this.state.isEditOrAddCpt && styles.displayNone]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown]}>
                    <TextInputField
                      name="invoiceAmount"
                      label="Invoice Amount*"
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      required={CommonString.InvoiceAmountRequired}
                      validator={"max8WithDecimal"}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />

                  </View>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.rightInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.customFlexDirection,
                      styles.customAlignItemsStart,
                      styles.textAlign,
                      styles.customMarginBottom30
                    ]}>
                    <TextInputField
                      name="discountAmount"
                      label="Discount Amount"
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight, styles.width100]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap, this.state.isEditOrAddCpt && styles.displayNone]}>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.leftInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.customFlexDirection,
                      styles.customAlignItemsStart,
                      styles.textAlign
                    ]}>
                    <SelectMenuField
                      name="currency"
                      label={"Currency"}
                      items={this.currencies}
                      searchable
                      itemLoader={async ({ search }) => {
                        let items = this.currencies;
                        return items
                          .filter((item) => {
                            return item.label.toLowerCase().includes(search.toLowerCase());
                          })
                          .sort((a, b) => b.label - a.label);
                      }}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      style={[customCommonStyles.inputHeight, styles.width100]}
                    />
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, styles.displayNone]}>
                      <TextInputField
                      name="other"
                      label="Notes"
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    />
                    {/* <SelectMenuField
                      name="currency"
                      label={"Curreny"}
                      // required={"Select Insurance Co."}
                      items={[{ label: "USD", value: "usd" }, { label: "Ruppee", value: "ruppee" }]}
                      onValueChange={this.onDoctorSearch}
                      // searchable
                      // itemLoader={async ({ search }) => {
                      //   return await this.onSearchInsurance(search);
                      // }}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      style={[customCommonStyles.inputHeight, styles.width100]}
                    /> */}
                    
                  </View>
                </View>
                <View style={[styles.customMarTAuto, commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow]}>
                  <View style={[styles.formBtnsHolder]}>
                    <Button
                      title={"Discard"}
                      style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.btnWidth108]}
                      titleTextStyle={[commonStyles.secondayWhiteBtnText, commonFontStyles.workSans]}
                      onPress={() => this.onDiscard()}
                    />
                    <SubmitButton
                      title={"Submit"}
                      style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, commonStyles.marginLeft16]}
                      titleTextStyle={[commonStyles.primaryBtnText, commonFontStyles.workSans, clientStyle.submitButtonText]}
                    />
                  </View>
                </View>
              </Form>
            </View>
            <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} clientStyle={clientStyle} />
          <PopupModalComponent
            visibility={this.state.isConfirmationVisible}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
            messageType={MESSAGE_TYPE.delete}
            headingIcon={true}
            heading={"Delete CPT"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={"Are you sure you want to delete?"}
            okLabel={"No"}
            handleOk={async () => this.closePopScreen()}
            closeLabel={"Yes"}
            handleClose={async () => this.removeCptItem()}
          />
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  attachOpt: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 15,
        };
      }
    },
  },
  leftInputHolder: {
    marginRight: 8,
    maxWidth: '49%',
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: '100%',
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMarTAuto: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: -15,
        };
      }
    },
  },
  customFlexProperties: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
          marginBottom: 30
        };
      }
      if (width >= 991) {
        return {
          justifyContent: "flex-end",
        };
      }
    },
  },
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  formBtnsHolder: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginBottom: 60
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  uploadBillSection: {
    backgroundColor: "#fff",
    marginTop: 0,
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    // flex: 1,
    // alignItems: "flex-start",
    // flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 16,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  customAlignItemsStart: {
    alignItems: "baseline",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: null,
        };
      }
    },
  },
  attachmentCard: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#a1a4a8",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
          minHeight: 65,
        };
      }
    },
  },
  fileUploadWrapper: {
    flexBasis: "100%",
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#a1a4a8",
    borderRadius: 5,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginRight: 0,
        };
      }
    },
  },
  uploadBillsForm: {
    width: '100%',
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flex: 1,
          width: '100%'
        };
      }
      if (width <= 991) {
        return {
          paddingTop: 0,
          //   width: "90%"
        };
      }
    },
  },
  attachmentFieldOuter: {
    width: "100%",
    position: "relative",
    height: 60,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: 50,
        };
      }
    },
  },

  attachFileScetion: {
    marginBottom: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  attachmentsHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 16,
        };
      }
    },
  },
  bodyWrapper: {
    width: 300,
    borderRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          fontWeight: 600,
        };
    },
  },
  modalContent: {
    paddingLeft: 26,
    paddingRight: 26,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingLeft: 26,
          paddingRight: 26,
        };
    },
  },
  incNameDropDown: {
    marginBottom: 30,
    screen: ({ width }) => {
      if (width >= 991)
        return {
          maxWidth: "49%",
        };
    },
  },
  textAlign: {
    alignItems: "center",
    height: "55%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginBottom: 0,
          alignItems: "flex-start",
        };
      }
    },
  },
  width100: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%"
        };
      }
    },
  },
  customMarginBottom30: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 30,
        };
      }
    },
  },
  btnWidth108: {
    width: 108
  },
  marginTop10: {
    top: 5,
    width: 14,
    height: 14,
    backgroundColor: "red",
    color: "blue"
  }
});
