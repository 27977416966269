import React from "react";
import { View, StyleSheet, Text } from "../../core";
import ModalPopUp from "../../components/ModalPopUp";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import PaymentOptions from "../../components/payments/PaymentOptions";

export default class ServiceChargePaymentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: this.props.isShowPaymentService,
    };
    this.child = React.createRef();
  }

  onPaymentDone = (res) => {
    const { onPaymentProcess, hideServiceChargePayment } = this.props;
    hideServiceChargePayment();
    onPaymentProcess(res)
  }

  onModelClose = () => {
    const { hideServiceChargePayment, hideLoader } = this.props;
    hideServiceChargePayment();
    hideLoader();
  }

  displayLoader = () => {
    const { displayLoader } = this.props;
    displayLoader();
  }

  render() {
    let { isShowPaymentService, isUberDocs } = this.props;
    let billName = "Exela XBP Claim";
    let billerId = "ExelaXBPClaim#";
    let subject = "Service Charge Fee";
    let amtPayable = 0.99;
    let user = {
      pid: billerId,
    };
    isShowPaymentService = !!isShowPaymentService ? isShowPaymentService : false;
    let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
    let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

    return (
      <ModalPopUp
        visibility={isShowPaymentService}
        isFooter={true}
        bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
        animationType="fade"
        footerClass={commonStyles.padding0}
        headerStyle={commonStyles.positionRelative}
        modalContent={commonStyles.marginBottom0}>
        <View>
          <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, styles.areYou]}>
            <Text
              style={[
                commonFontStyles.workSans,
                commonStyles.heading6,
                commonStyles.dBlock,
              ]}
            >
              Are you sure you want to submit the claim?
            </Text>
            <Text
              style={[
                commonFontStyles.workSans,
                commonStyles.heading6,
                commonStyles.dBlock,
                commonStyles.fullWidth,
              ]}
            >
              You will be charged ${amtPayable}.
            </Text>
          </View>
          <View style={[commonStyles.smallModalBtnWrapper]}>
            <PaymentOptions
              ref={this.child}
              billerName={billName}
              billerId={billerId}
              subject={subject}
              amtPayable={amtPayable}
              user={user}
              currencyCode={"USD"}
              onPaymentDone={(res) => this.onPaymentDone(res)}
              onModelClose={this.onModelClose}
              displayLoader={this.displayLoader}
              isLoading={false}
              width={1}
              pchClaim={true}
              externalClient={isUberDocs}
              googleBtnStyle={[commonStyles.marB0, commonStyles.marT0]}
              cancelBtnStyle={[commonStyles.blueBorderButton, commonStyles.marginRight5, styles.buttons, commonStyles.flexBasis48, clientStyle.backAndCancelButton]}
              cancelTextStyle={[commonStyles.blueBorderButtonColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, clientStyle.backAndCancelButtonText]}
              onCancel={async () => this.onModelClose()}
              cancelText="No"
            />
          </View>
        </View>
      </ModalPopUp>
    )
  }
}

const styles = StyleSheet.create({
  PDFViewBodyWrapper: {
    width: 330,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#a7a7a7",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flex: 1,
          width: 300,
          justifyContent: "center",
          alignItems: "center",
        };
      }
    },
  },
  PDFModalContent: {
    marginTop: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // width: "85%",
        };
    },
  },
  areYou: {
    alignItems: "center",
    textAlign: "center",
  },
  buttons: {
    maxWidth: 100,
    height: 24
  },
});