import React, { Component } from "react";
import R14, { StyleSheet, View } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { uberDocThemeStyle } from "../assets/css/clients/uberDocThemeStyle";

import BillerDetails from "../components/BillerDetails";
import PaidSuccessfullyComponent from "../components/PaidSuccessfullyComponent";
import DeclineMessageComponent from "../components/DeclineMessageComponent"; 

export default R14.connect(
  class BillReceiptScreen extends Component {
    render() {
      const { billDetails } = this.props;
      const { isTooltipOpen, isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;

      return (
        <View style={[styles.billDetailsScreen, isTooltipOpen ? styles.billContainerWithTooltip : null, clientStyle.secondaryBgColor]}>
          <View style={[styles.container, commonStyles.borderBottomSpace10]}>
            <BillerDetails billData={billDetails} customFlexDirection={[styles.customFlexDirection]} />
          </View>
          {billDetails.billStatus === "Declined" ? (
            <DeclineMessageComponent />
          ) : (
            <PaidSuccessfullyComponent
              message={`${!!billDetails.lastPaidAmount ? billDetails.lastPaidAmount.toFixed(2) : "0.00"} Successfully paid to ${billDetails.biller.billerName}`}
              paymentMode={billDetails.paymentMode}
              isUberDocs={isUberDocs}
            />
          )}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  billDetailsScreen: {
    flex: 1,
    backgroundColor: "#fff",
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 16,
    marginTop: 16,
    paddingTop: 16,
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    overflowX: "auto",
    screen: ({ width }) => {
      if (width <= 991){
        return {
          marginLeft: 0,
          marginRight: 0,
          paddingRight: 16,
          paddingLeft: 16,
          paddingBottom: 16,
        };
      }
    },
  },
  pageTitleHolder: {
    // backgroundColor: "#fff",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991)
      return {
        flexDirection: "column",
        };
    },
  },
  billContainerWithTooltip: {
    overflow: "unset",
  },
});
