import React, { Component } from "react";
import R14, { StyleSheet, View, Button, Image, Text } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
const closeCircleIcon = require("../assets/images/close-circle-icon.png");

export default R14.connect(
  class DeclineMessageComponent extends Component {
    render() {
      return (
        <View style={styles.declineMessageComponent}>
          <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.justifyContentCenter, styles.crossIcon]}>
            <Image source={closeCircleIcon} style={commonStyles.icon36} />
          </View>
          <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.justifyContentCenter]}>
            <Text style={[commonFontStyles.workSans, commonStyles.heading4, commonStyles.fontWeight500, commonStyles.redColor]}>
              You have declined the bill.
            </Text>
          </View>
          <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.justifyContentCenter, commonStyles.marginTopBottom20]}>
            <Button
              key="gotohome"
              title={"Go to Dashboard"}
              style={[commonStyles.blueBorderButton, customCommonStyles.noBoxShadow,]}
              titleTextStyle={[
                commonStyles.blueBorderButtonFontStyle,
                commonStyles.heading6,
                commonStyles.blueBorderButtonColor,
                commonFontStyles.workSans,
                commonStyles.fontWeight500
              ]}
              to="dashboard"
            />
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  declineMessageComponent: {
    // height: "calc(100vh - 410px)",
    screen: ({ width }) => {
      if (width <= 360)
        return {
          // height: "calc(100vh - 415px)",
        };
    },
  },
  crossIcon: {
    marginTop: 16,
    marginRight: 0,
    marginBottom: 15,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 360)
        return {
          marginTop: 15,
          marginRight: 0,
          marginBottom: 15,
          marginLeft: 0,
        };
    },
  },
});
