import { FILE_EXTENSION } from "../constant/app.data.constant";

export default class HtmlDeCode {

    static async htmlDecode(input) {
        if (!input) return input;
        return Object.keys(input).forEach((key) => {
            if (typeof input[key] === 'string' || input[key] instanceof String)
                input[key] = new DOMParser().parseFromString(input[key], "text/html").childNodes[0].innerText.trim();
        });
    }

    static htmlDecodeSanitize(input) {
        if (!input) return input;
        return Object.keys(input).forEach((key) => {
            if (typeof input[key] === 'string' || input[key] instanceof String)
                input[key] = new DOMParser().parseFromString(input[key], "text/html").childNodes[0].innerText.trim();
        });
    }

    static downloadUTF8file = async (item) => {
        let ext = item.filename.split('.').pop();
        if (!!ext && FILE_EXTENSION.includes(ext.toUpperCase())) {
            const downloadLink = document.createElement("a");
            downloadLink.href = 'data:application/octet-stream;base64,' + btoa(item.content);
            downloadLink.download = item.filename;
            downloadLink.click();
            downloadLink.remove();
        }
    }

    static downloadFile = async (item) => {
        let ext = item.filename.split('.').pop();
        if (!!ext && FILE_EXTENSION.includes(ext.toUpperCase())) {
            const downloadLink = document.createElement("a");
            downloadLink.href = item.content;
            downloadLink.download = item.filename;
            downloadLink.click();
            downloadLink.remove();
        }
    }
}
