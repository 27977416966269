
/* AKS QA API URLs*/
// const BASE_URL = "http://52.190.40.168:5011";
const BASE_URL = "https://qarepository-xbp.exela.global";
const USER_API = `${BASE_URL}/apis/requesttopay/v1.0/app/user`;
const NATIVE_APP_URL = "http://localhost:3000";
// const NATIVE_APP_URL = "https://web-6b734b48594d37546a.am.os.inventr.ai";
// const NATIVE_APP_URL = "https://web-696e4f475f306d6378.am.os.inventr.ai";
// const NATIVE_APP_URL = "http://3.21.92.106:32869";
const MIXPANEL_TOKEN = '7e518a89c7a82cf730e5647be319c2da';
const GOOGLE_ANALYTICS_TOKEN = 'G-QS3G6MWWWW';

const KC_BASE_URL = 'https://auth-dev.exelatech.com';

const REALM_NAME = "ConsumerRealm";
const KC_CLIENT_ID = "xbppayer"; 
const CLAIM_CLIENT_ID = "xbpclaim";

export {
    BASE_URL, USER_API, NATIVE_APP_URL, KC_BASE_URL, REALM_NAME, KC_CLIENT_ID, CLAIM_CLIENT_ID, MIXPANEL_TOKEN, GOOGLE_ANALYTICS_TOKEN
}