import { USER_TYPE } from "../constant/app.data.constant";

const routes = {
  initialRoute: "main",
  routes: {
    main: {
      initialRoute: "/login",  // TEST PR
      type: "stack",
      routes: {
        landing: {
          path: "/",
          label: "Login",
          metadata: {
            public: true,
          },
        },
        kcsession: {
          path: '/kcsession/:application',
          label: "Landing",
          metadata: {
            public: true,
          },
        },
        login: {
          path: "/login",
          label: "Login",
          metadata: {
            public: true,
          },
        },
        unauthorize: {
          path: "/unauthorize/:content",
          label: "Unauthorize",
          metadata: {
            public: true,
          },
        },
        pchlanding: {
          path: "/claims",
          label: "Claims Login",
          metadata: {
            public: true,
          },
        },
        pchLogin: {
          path: "/claims/login",
          label: "Claims Login",
          metadata: {
            public: true,
          },
        },
        changePassword: {
          path: "/changePassword",
          label: "Change Password",
          metadata: {
            public: true,
          },
        },
        pchChangePassword: {
          path: "/claims/changePassword",
          label: "Claims Change Password",
          metadata: {
            public: true,
          },
        },
        forgotPassword: {
          path: "/forgotPassword",
          label: "Forgot Password",
          metadata: {
            public: true,
          },
        },
        pchForgotPassword: {
          path: "/claims/forgotPassword",
          label: "Claims Forgot Password",
          metadata: {
            public: true,
          },
        },
        forgotUsername: {
          path: "/forgotUsername",
          label: "Forgot User Name",
          metadata: {
            public: true,
          },
        },
        registration: {
          path: "/registration/:type",
          label: "Registration",
          metadata: {
            public: true,
          },
        },
        pchSignup: {
          path: "/claims/registration/:type",
          label: "Claims Registration",
          metadata: {
            public: true,
          },
        },
        pchSignupConfirmation: {
          path: "/claims/registration/confirm",
          label: "Claims Registration Confirmation",
          metadata: {
            public: true,
          },
        },
        helps: {
          path: `/claims/helps`,
          label: "Claims Helps",
          metadata: {
            public: true,
          },
        },
        external: {
          path: "/scanBill/:threadId",
          label: "Scanner",
          action: "externalScannerAction",
          metadata: {
            public: true,
          },
        },
        externalBills: {
          path: "/claims/uploaded-bills/:type/:uid",
          label: "Claims Upload Bills",
          action: "uploadedBillAction",
          metadata: {
            public: true,
          },
        },
        textToPay: {
          path: "/t2p/:messageMetaIdentifier",
          label: "T2P",
          action: "textToPayAction",
          metadata: {
            public: true,
          },
        },
        pchTermsAndconditionsRoute: {
          path: `/claims/termsandconditions`,
          label: "Terms And Conditions",
          action: "pchTermsAndconditionsAction",
          metadata: {
            public: true,
          },
        },
        pchPrivacyPolicyRoute: {
          path: `/claims/privacypolicy`,
          label: "Privacy Policy",
          action: "pchPrivacyPolicy",
          metadata: {
            public: true,
          },
        },
        pchusertermsRoute: {
          path: `/claims/userterms`,
          label: "User Terms",
          action: "pchuserterms",
          metadata: {
            public: true,
          },
        },
        accessServicesAgreementRoute: {
          path: `/claims/accessServicesAgreement`,
          label: "Claims Access Services Agreement",
          action: "accessServicesAgreement",
          metadata: {
            public: true,
          },
        },
        termsAndConditionsScreenRoute: {
          path: `/claims/termsAndConditions`,
          label: "Terms And Conditions",
          action: "termsAndConditionsScreen",
          metadata: {
            public: true,
          },
        },
        termsAndconditionsRoute: {
          path: `/termsandconditions`,
          label: "Terms And Conditions",
          action: "termsAndconditionsAction",
          metadata: {
            public: true,
          },
        },
        privacyPolicyRoute: {
          path: `/privacypolicy`,
          label: "Privacy Policy",
          action: "pchPrivacyPolicy",
          metadata: {
            public: true,
          },
        },
        clientpoc: {
          path: `/uber-docs-xbp-claims`,
          label: "Uber Docs Claims",
          action: "clientPOC",
          metadata: {
            public: true,
          }
        },
        privateNavigator: {
          type: "stack",
          routes: {
            homeNavigator: {
              type: "stack",
              initialRoute: "dashboardNavigator",
              routes: {
                dashboardNavigator: {
                  type: "stack",
                  label: "Dashboard",
                  icon: "moniter",
                  iconPath: require("../assets/images/icDashboardB.png"),
                  whiteIconPath: require("../assets/images/icDashboardW.png"),
                  blueIconPath: require("../assets/images/icDashboardBlue.png"),
                  userType: [USER_TYPE.XBP, USER_TYPE.SMB, USER_TYPE.XBPSMB],
                  routes: {
                    dashboard: {
                      path: `/dashboard`,
                      label: "Dashboard",
                      action: "dashboard",
                    },
                    makePaymentRoutes: {
                      path: `/make-payment`,
                      label: "All Bills",
                      action: "makePaymentAction",
                    },
                    bulkPaymentRoutes: {
                      path: `/bulk-payment`,
                      label: "Bulk Payment",
                      action: "bulkPaymentAction",
                    },
                    achPaymentRoutes: {
                      path: `/pay/:types`,
                      label: "ACH Payment",
                      action: "achPaymentAction",
                    },
                    // added space in label, so that iphone8 doesn't have two lines issue
                    sepaBillsRoute: {
                      path: `/sepa-bills`,
                      label: "SEPA Bills ",
                      action: "sepaBillAction",
                    },
                    sepaBillDetailsRoute: {
                      path: `/sepa-bill-details/:resourceId`,
                      label: "Bill Details",
                      action: "sepaBillDetail",
                    },
                    paymentReceiptRoute: {
                      path: `/payment-receipts`,
                      label: "Payment Receipts",
                      action: "paymentReceipt",
                    },
                    deleteAccountRoute: {
                      path: `/delete-account`,
                      label: "Delete Account",
                      action: "deleteAccountAction",
                    },
                  },
                },
                paymentOptionsNavigator: {
                  type: "stack",
                  // label: "Payment Options",
                  // icon: "cloudSync",
                  userType: [USER_TYPE.XBP],
                  routes: {
                    paymentOptionsRoute: {
                      path: `/payment-options`,
                      label: "Payment Options",
                      action: "paymentOptionsAction",
                    },
                  },
                },
                billNavigator: {
                  type: "stack",
                  userType: [USER_TYPE.XBP, USER_TYPE.XBPSMB],
                  routes: {
                    billDetailsRoute: {
                      path: `/bill-details/:threadId`,
                      label: `Bill Details`,
                      action: "billDetailsAction",
                    },
                    billHistoryDetailsRoute: {
                      path: `/bill-history-details/:threadId`,
                      label: `History Details`,
                      action: "billDetailsAction",
                    },
                  },
                },
                billersDirectoryNavigator: {
                  type: "stack",
                  label: "Payer Authorization",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icBillerDirectoryB.png"),
                  whiteIconPath: require("../assets/images/icBillerDirectoryW.png"),
                  userType: [USER_TYPE.XBP, USER_TYPE.XBPSMB],
                  routes: {
                    billersDirectoryRoute: {
                      path: `/directory`,
                      label: "Payer Authorization",
                      action: "billersDirectoryScreenAction",
                    },
                    pamMessageRoute: {
                      path: `/pam-message/:pamId/:pid`,
                      label: "PAM Message",
                      action: "pamMessageAction",
                    },
                  },
                },
                adminScreenNavigator: {
                  type: "stack",
                  label: "Manage Client",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icManageUserB.png"),
                  whiteIconPath: require("../assets/images/icManageUserW.png"),
                  blueIconPath: require("../assets/images/icPractitionerProfileBlue.png"),
                  userType: [USER_TYPE.XBP_ADMIN],
                  routes: {
                    clientListRoute: {
                      path: `/claims/clients`,
                      action: "manageClientAction",
                      label: "Manage Client",
                    },
                    addClientRoute: {
                      path: `/claims/add-client`,
                      action: "addClientAction",
                      label: "Add Client"
                    },
                    editClientRoute: {
                      path: `/claims/edit-client`,
                      action: "editClientAction",
                      label: "Update Client"
                    },
                    viewClientRoute: {
                      path: `/claims/view-client`,
                      action: "viewClientAction",
                      label: "Client Details"
                    }
                  },
                },
                simpleMessageNavigator: {
                  type: "stack",
                  label: "Message Biller",
                  icon: "message",
                  iconPath: require("../assets/images/messageBillerBlack.png"),
                  whiteIconPath: require("../assets/images/messageBillerWhite.png"),
                  userType: [USER_TYPE.XBP, USER_TYPE.XBPSMB],
                  routes: {
                    simpleMessageRoute: {
                      path: `/simple-message`,
                      label: "Message Biller",
                      action: "simpleMessageScreenAction",
                    },
                    simpleMessageDetailRoute: {
                      path: `/simple-message/:threadId`,
                      label: "Message",
                      action: "simpleMessagesDetailsAction",
                    },
                    addMessageScreenRoute: {
                      path: `/simple-message/createmessage`,
                      action: "createMessageAction",
                      label: "Add Message",
                    },
                  },
                },
                messageScreenNavigator: {
                  type: "stack",
                  routes: {
                    messageScreenRoute: {
                      path: `/messages/:threadId`,
                      action: "messageScreenAction",
                      label: "Messages",
                    },
                  },
                },
                billHistoryScreenNavigator: {
                  type: "stack",
                  routes: {
                    billHistoryScreenRoute: {
                      path: `/bill-history/:billerPid`,
                      action: "billHistoryScreenAction",
                      label: "History",
                    },
                  },
                },
                notificationScreenNavigator: {
                  type: "stack",
                  routes: {
                    notificationScreenRoute: {
                      path: `/notification`,
                      action: "notificationScreenAction",
                      label: "Notifications",
                    },
                  },
                },
                billRequestScreenNavigator: {
                  type: "stack",
                  // label: "Find/Link Biller",
                  // icon: "powerPlug",
                  iconPath: require("../assets/images/ic-Bill-Request-b.png"),
                  whiteIconPath: require("../assets/images/ic-Bill-Request-w.png"),
                  blueIconPath: require("../assets/images/ic-Bill-Request-b.png"),
                  userType: [USER_TYPE.XBP, USER_TYPE.XBPSMB],
                  routes: {
                    billRequestScreenRoute: {
                      path: `/bill-request`,
                      action: "billRequestScreenAction",
                      label: "Find/Link Biller",
                    },
                  },
                },
                patientDashboardNavigator: {
                  type: "stack",
                  label: "Dashboard",
                  icon: "moniter",
                  iconPath: require("../assets/images/icDashboardB.png"),
                  whiteIconPath: require("../assets/images/icDashboardW.png"),
                  blueIconPath: require("../assets/images/icDashboardBlue.png"),
                  userType: [USER_TYPE.PCH_PATIENT],
                  routes: {
                    patientDashboard: {
                      path: `/claims/dashboard`,
                      label: "Dashboard",
                      action: "dashboard",
                    },
                    uploadedBillsRoute: {
                      path: `/claims/medical-bills/:uid`,
                      action: "viewBillDetailsAction",
                      label: "Uploaded Bill",
                    },
                    subscriptionRoute: {
                      path: `/app/subscription`,
                      label: "Subscribe Plan",
                      action: "appsubscription",
                    },
                    subscribedRoute: {
                      path: `/app/subscribe`,
                      label: "Subscribe Plan",
                      action: "appsubscription",
                    },
                    serviceSubscriptionRoute: {
                      path: `/app/services-subscription`,
                      label: "Application Services",
                      action: "serviceSubscription",
                    },
                    serviceSubscribedRoute: {
                      path: `/app/services-subscribe`,
                      label: "Application Services",
                      action: "serviceSubscription",
                    },
                    patientCheckout: {
                      path: `/claims/checkout/c/pay/:data`,
                      action: "checkoutPayment",
                      label: "Checkout",
                    },
                  },
                },
                uploadBillScreenNavigator: {
                  type: "stack",
                  label: "Upload Bill",
                  icon: "arrowUp",
                  iconPath: require("../assets/images/icUploadBillB.png"),
                  whiteIconPath: require("../assets/images/icUploadBillW.png"),
                  blueIconPath: require("../assets/images/icUploadBillBlue.png"),
                  userType: [USER_TYPE.PCH_PROVIDER, USER_TYPE.PCH_PATIENT],
                  routes: {
                    uploadBillsRoute: {
                      path: `/claims/upload-bills`,
                      action: "uploadBillScreenAction",
                      label: "Upload Bill",
                    },
                    directSubmission: {
                      path: `/claims/submit-claim/guest`,
                      action: "nonRegisterAction",
                      label: "Submit Claim",
                      metadata: {
                        public: true,
                      }
                    },
                    nonRegisterSubmission: {
                      path: `/claims/submit-claim`,
                      label: "Submit Claim",
                      action: "nonRegisterAction"
                    },
                    nonRegisterReceipt: {
                      path: `/claims/submission/:action`,
                      action: "nonregisterReceipt",
                      label: "Claim Submission",
                      metadata: {
                        public: true,
                      }
                    },
                    submittedReceiptRoute: {
                      path: `/claims/submitted-receipt/:uid`,
                      label: "Claim Details",
                      action: "submittedReceipt",
                    },
                    externalClientUploadBillRoute: {
                      path: "/claims/external-upload-bill/:appointment/:redirect_url/:access_token",
                      action: "externalUploadBillAction",
                      label: "Uploaded Bill",
                      metadata: {
                        public: true,
                      }
                    },
                    externalClientbillViewRoute: {
                      path: "/claims/external-view-bill/:appointment/:redirect_url/:access_token",
                      action: "externalViewBillAction",
                      label: "Submitted Bill",
                      metadata: {
                        public: true,
                      }
                    },
                    externalClientbillHistoryRoute: {
                      path: "/claims/external-bill-history/:appointment/:redirect_url/:access_token",
                      label: "Bill History",
                      action: "externalHistroyBillAction",
                      metadata: {
                        public: true,
                      }
                    },
                    pchGlobalAccessRoute: {
                      path: "/pch-global/upload-bill/:redirect_url/:access_token",
                      action: "pCHGlobalUploadBillAction",
                      label: "Uploaded Bill",
                      metadata: {
                        public: true,
                      }
                    },
                  },
                },
                managePaymentScreenNavigator: {
                  type: "stack",
                  label: "Manage Payment",
                  icon: "arrowUp",
                  iconPath: require("../assets/images/icUploadBillB.png"),
                  whiteIconPath: require("../assets/images/ic-Bill-Request-w.png"),
                  blueIconPath: require("../assets/images/ic-Bill-Request-b.png"),
                  userType: [USER_TYPE.PCH_PATIENT],
                  routes: {
                    managePaymentsRoute: {
                      path: `/claims/manage-payment`,
                      action: "managePaymentScreenAction",
                      label: "Manage Payment",
                    },
                    viewPaymentRoute: {
                      path: `/claims/manage-payment/view/:uid`,
                      label: "View Payment",
                      action: "viewManagePaymentAction",
                    },
                    applyPaymentRoute: {
                      path: `/claims/manage-payment/apply/:uid`,
                      label: "Apply Payment",
                      action: "applyManagePaymentAction",
                    },
                  },
                },
                providerListScreenNavigator: {
                  type: "stack",
                  label: "Manage Provider",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icManageProviderB.png"),
                  whiteIconPath: require("../assets/images/icManageProviderW.png"),
                  blueIconPath: require("../assets/images/icManageProviderBlue.png"),
                  userType: [USER_TYPE.PCH_PROVIDER, USER_TYPE.PCH_PATIENT],
                  routes: {
                    providerListRoute: {
                      path: `/claims/manage-provider`,
                      action: "manageProviderAction",
                      label: "Manage Provider",
                    },
                    addProviderRoute: {
                      path: `/claims/manage-provider/:mode`,
                      action: "manageProviderAction",
                      label: "Manage Provider",
                    },
                  },
                },
                manageUsersNavigator: {
                  type: "stack",
                  // label: "Manage User",
                  icon: "accountCircle",
                  iconPath: require("../assets/images/icManageUserB.png"),
                  whiteIconPath: require("../assets/images/icManageUserW.png"),
                  blueIconPath: require("../assets/images/icPractitionerProfileBlue.png"),
                  userType: [USER_TYPE.PCH_PROVIDER],
                  routes: {
                    //PCH profileview
                    addUsersRoute: {
                      path: `/claims/add-user`,
                      label: "Manage User",
                      action: "manageUsersAction",
                    },
                    editUsersRoute: {
                      path: `/claims/edit-user/:uid`,
                      label: "Edit User",
                      action: "userEditAction",
                    },
                  },
                },
                insuranceListScreenNavigator: {
                  type: "stack",
                  label: "Manage Insurance Co",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icManageInsuranceCoB.png"),
                  whiteIconPath: require("../assets/images/icManageInsuranceCoW.png"),
                  blueIconPath: require("../assets/images/icManageInsuranceCoB.png"),
                  userType: [USER_TYPE.PCH_PATIENT],
                  routes: {
                    insuranceListRoute: {
                      path: `/claims/manage-insurance`,
                      action: "insuranceListAction",
                      label: "Insurance Co.",
                    },
                    addInsuranceRoute: {
                      path: `/claims/manage-insurance/:mode`,
                      label: "Insurance Co.",
                      action: "insuranceListAction",
                    },
                  },
                },
                pchdashboardNavigator: {
                  type: "stack",
                  label: "Bill History",
                  icon: "moniter",
                  iconPath: require("../assets/images/icDashboardB.png"),
                  whiteIconPath: require("../assets/images/icDashboardW.png"),
                  blueIconPath: require("../assets/images/icDashboardBlue.png"),
                  userType: [USER_TYPE.PCH_PROVIDER],
                  routes: {
                    pracDashboard: {
                      path: `/claims/dashboard`,
                      label: "Dashboard",
                      action: "dashboard",
                    },
                    uploadedBillsRoute: {
                      path: `/claims/medical-bills/:uid`,
                      action: "viewBillDetailsAction",
                      label: "Uploaded Bill",
                    }
                  },
                },
                providerDetailNavigator: {
                  type: "stack",
                  label: "Practice Group Profile",
                  icon: "accountCircle",
                  iconPath: require("../assets/images/icPractitionerProfileB.png"),
                  whiteIconPath: require("../assets/images/icPractitionerProfileW.png"),
                  blueIconPath: require("../assets/images/icPractitionerProfileBlue.png"),
                  userType: [USER_TYPE.PCH_PROVIDER],
                  routes: {
                    //PCH profileview
                    pchProfileViewRoute: {
                      path: `/claims/profile-view`,
                      label: "Profile",
                      action: "pchProfileViewAction",
                    },
                    pchProfileEditRoute: {
                      path: `/claims/profile-edit/:mode`,
                      label: "Edit Profile",
                      action: "pchProfileEditAction",
                    },
                  },
                },
                patientDetailNavigator: {
                  type: "stack",
                  label: "Patient Profile",
                  icon: "accountCircle",
                  iconPath: require("../assets/images/icPractitionerProfileB.png"),
                  whiteIconPath: require("../assets/images/icPractitionerProfileW.png"),
                  blueIconPath: require("../assets/images/icPractitionerProfileBlue.png"),
                  userType: [USER_TYPE.PCH_PATIENT],
                  routes: {
                    //PCH profileview
                    pchProfileViewRoute: {
                      path: `/claims/profile-view`,
                      label: "Profile",
                      action: "pchProfileViewAction",
                    },
                    pchProfileEditRoute: {
                      path: `/claims/profile-edit`,
                      label: "Edit Profile",
                      action: "pchProfileEditAction",
                    },
                  },
                },
                pchGlobalNavigator: {
                  type: "stack",
                  label: "PCH Global",
                  icon: "accountCircle",
                  iconPath: require("../assets/images/icManageUserB.png"),
                  whiteIconPath: require("../assets/images/icManageUserW.png"),
                  blueIconPath: require("../assets/images/icPractitionerProfileBlue.png"),
                  userType: [USER_TYPE.PCH_PROVIDER],
                  routes: {
                    pchGlobal: {
                      path: `/claims/pch-global`,
                      label: "PCH Global",
                      action: "navPCHGlobalAction",
                    },
                  },
                },
                moopNavigator: {
                  type: "stack",
                  label: "MOOP",
                  icon: "moniter",
                  iconPath: require("../assets/images/icDashboardB.png"),
                  whiteIconPath: require("../assets/images/icDashboardW.png"),
                  blueIconPath: require("../assets/images/icDashboardBlue.png"),
                  userType: [USER_TYPE.PCH_PATIENT],
                  routes: {
                    moopRoute: {
                      path: `/claims/moop`,
                      label: "MOOP (Member Out of Pocket)",
                      action: "moopAction",
                    },
                  },
                },
                helpNavigator: {
                  type: "stack",
                  label: "Help",
                  icon: "accountCircle",
                  iconPath: require("../assets/images/ichelpb.png"),
                  whiteIconPath: require("../assets/images/ichelpw.png"),
                  userType: [USER_TYPE.PCH_PROVIDER, USER_TYPE.PCH_PATIENT],
                  routes: {
                    //PCH profileview
                    helpRoute: {
                      path: `/claims/help`,
                      label: "Help Videos & Documents",
                      action: "helpScreenAction",
                    },
                  },
                },
                payNowNavigator: {
                  type: "stack",
                  routes: {
                    payTypeRoute: {
                      path: `/payment/:paytype`,
                      label: "Bill Payment",
                      action: "payTypeAction",
                    },
                    payPartialRoute: {
                      path: `/paypart/:paytype`,
                      label: "Bill Pay Part",
                      action: "payTypeAction",
                    },
                    declinedRoute: {
                      path: `/declined/:paytype`,
                      label: "Decline Bill",
                      action: "declinedAction",
                    },
                    extensionRoute: {
                      path: `/request/:paytype`,
                      label: "Extension Request",
                      action: "extensionAction",
                    },
                    billReceiptRoute: {
                      path: `/bill-receipt/:threadId`,
                      label: "Bill Details",
                      action: "billReceiptAction",
                    },
                    addNewCardRoute: {
                      path: `/add-new-card`,
                      label: "Add New Card",
                      action: "addNewCardAction",
                    },
                    addNewAccountRoute: {
                      path: `/add-new-account`,
                      label: "Add New Account",
                      action: "addNewAccountAction",
                    },
                    editAccountRoute: {
                      path: `/edit-account/:uid`,
                      label: "Edit Account",
                      action: "editAccountAction",
                    },
                  },
                },
                myBanksNavigator: {
                  type: "stack",
                  label: "My Banks",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icManageInsuranceCoB.png"),
                  whiteIconPath: require("../assets/images/icManageInsuranceCoW.png"),
                  blueIconPath: require("../assets/images/icManageInsuranceCoB.png"),
                  userType: [USER_TYPE.XBP, USER_TYPE.XBPSMB],
                  routes: {
                    myBanksRoute: {
                      path: `/my-banks`,
                      label: "My Banks",
                      action: "bankAccountAction",
                    },
                  },
                },
                uploadBillNavigator: {
                  type: "stack",
                  label: "Upload Bill",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icUploadBillB.png"),
                  whiteIconPath: require("../assets/images/icUploadBillW.png"),
                  blueIconPath: require("../assets/images/icUploadBillBlue.png"),
                  userType: [USER_TYPE.SMB, USER_TYPE.XBPSMB],
                  routes: {
                    uploadBillRoute: {
                      path: `/smb/upload-bill`,
                      label: "Upload Bill",
                      action: "uploadBillAction",
                    },
                  },
                },
                scanQRcodeNavigator: {
                  type: "stack",
                  label: "Scan QR Code",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icManageInsuranceCoB.png"),
                  whiteIconPath: require("../assets/images/icManageInsuranceCoW.png"),
                  blueIconPath: require("../assets/images/icManageInsuranceCoB.png"),
                  userType: [USER_TYPE.SMB, USER_TYPE.XBPSMB],
                  routes: {
                    scanQRcodeRoute: {
                      path: `/smb/scan-qr-code`,
                      label: "Scan QR Code",
                      action: "scanQRcodeAction",
                    },
                    organizationRoute: {
                      path: `/smb/switch-organizations`,
                      action: "switchOrganizations",
                      label: "Organizations",
                    }
                  }
                },
                approveBillNavigator: {
                  type: "stack",
                  label: "Approve Bill",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/ic-Bill-Request-b.png"),
                  whiteIconPath: require("../assets/images/ic-Bill-Request-w.png"),
                  blueIconPath: require("../assets/images/ic-Bill-Request-b.png"),
                  userType: [USER_TYPE.SMB, USER_TYPE.XBPSMB],
                  routes: {
                    approveBillRoute: {
                      path: `/smb/approve-bill`,
                      label: "Approve Bill",
                      action: "approveBillAction",
                    },
                  },
                },
                makePaymentsNavigator: {
                  type: "stack",
                  label: "Make Payments",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icManageProviderB.png"),
                  whiteIconPath: require("../assets/images/icManageProviderW.png"),
                  blueIconPath: require("../assets/images/icManageProviderBlue.png"),
                  userType: [USER_TYPE.SMB, USER_TYPE.XBPSMB],
                  routes: {
                    makePaymentsRoute: {
                      path: `/smb/make-payments`,
                      label: "Make Payments",
                      action: "makePaymentsAction",
                    },
                    submittedSmbReceiptRoute: {
                      path: `/smb/submitted-receipt`,
                      label: "Bill Details",
                      action: "submittedSmbReceipt",
                    },
                  },
                },
                vendorStatementsNavigator: {
                  type: "stack",
                  label: "Vendor Statements",
                  icon: "moniter",
                  iconPath: require("../assets/images/icDashboardB.png"),
                  whiteIconPath: require("../assets/images/icDashboardW.png"),
                  blueIconPath: require("../assets/images/icDashboardBlue.png"),
                  userType: [USER_TYPE.SMB, USER_TYPE.XBPSMB],
                  routes: {
                    vendorStatementsRoute: {
                      path: `/smb/vendor-statements`,
                      label: "Vendor Statements",
                      action: "vendorStatementsAction",
                    },
                  },
                },
                receivePaymentsNavigator: {
                  type: "stack",
                  label: "Receive Payments",
                  icon: "cloudSync",
                  iconPath: require("../assets/images/icManageProviderB.png"),
                  whiteIconPath: require("../assets/images/icManageProviderW.png"),
                  blueIconPath: require("../assets/images/icManageProviderBlue.png"),
                  userType: [USER_TYPE.SMB, USER_TYPE.XBPSMB],
                  routes: {
                    receivePaymentsRoute: {
                      path: `/smb/receive-payments`,
                      label: "Receive Payments",
                      action: "receivePaymentsAction",
                    },
                  },
                },
                linkPayerNavigator: {
                  type: "stack",
                  userType: [USER_TYPE.XBP],
                  routes: {
                    linkPayerRoute: {
                      path: "/link-payer",
                      label: "Link Payer",
                      action: "linkPayerAction",
                    },
                  },
                },
                profileViewNavigator: {
                  type: "stack",
                  label: "Profile",
                  icon: "accountCircle",
                  iconPath: require("../assets/images/icPractitionerProfileB.png"),
                  whiteIconPath: require("../assets/images/icPractitionerProfileW.png"),
                  blueIconPath: require("../assets/images/icPractitionerProfileBlue.png"),
                  userType: [USER_TYPE.XBP, USER_TYPE.SMB, USER_TYPE.XBPSMB],
                  routes: {
                    profileViewRoute: {
                      path: `/profile-view`,
                      label: "Profile",
                      action: "profileViewAction",
                    },

                    profileEditRoute: {
                      path: `/profile-edit`,
                      label: "Edit Profile",
                      action: "profileEditAction",
                    },
                  },
                },
                myPreferencesNavigator: {
                  type: "stack",
                  // label: "My Preferences",
                  // icon: "settings",
                  iconPath: "icMyPreferencesB.png",
                  whiteIconPath: "icMyPreferencesW.png",
                  userType: [USER_TYPE.XBP],
                  routes: {
                    myPreferencesViewRoute: {
                      path: `/my-preferences`,
                      label: "My Preferences",
                      action: "myPreferencesAction",
                    },
                  },
                },
                loginOut: {
                  path: "/logout",
                  label: "Logout",
                  icon: "lock",
                  iconPath: require("../assets/images/icLogoutB.png"),
                  whiteIconPath: require("../assets/images/icLogoutW.png"),
                  userType: [USER_TYPE.XBP, USER_TYPE.SMB, USER_TYPE.XBPSMB, USER_TYPE.PCH_PATIENT, USER_TYPE.PCH_PROVIDER],
                  action: "logout",
                  metadata: {
                    public: true,
                  },
                  state: {
                    isLogout: true,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  modal: {
    type: "modal",
    initialRoute: "dashboard",
    modal: {},
    header: {},
    routes: {
      payNowRoutes: {
        title: "Pay Now",
        path: "/paynow",
        initialParentRoute: "dashboard",
        action: "payNowAction",
      },
      clientEdit: {
        title: "Edit Client",
        path: "/client/edit/:uid",
        initialParentRoute: "clients",
      },
      userCreate: {
        title: "Create User",
        path: "/user/create",
        initialParentRoute: "users",
        action: "userEdit",
      },
      userEdit: {
        title: "Edit User",
        path: "/user/edit/:uid",
        initialParentRoute: "users",
      },
      clientAdd: {
        title: "Client add",
        path: "/user/edit/:uid",
        initialParentRoute: "payNowNavigator",
      },
      declinedBlockModalRoute: {
        title: "Declined Block",
        path: "/declined/:paytype",
        initialParentRoute: "declinedRoute",
      },
    },
  },
};

export default routes;
