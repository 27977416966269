import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SafariPayment from "./SafariPayment";

const ApplePayment = ({ props, paymentDataRequest, stripeDetails }) => {
  const {proceedToApplePayStyle} = props; 
  if (stripeDetails && stripeDetails.publishableKey) {
    const stripePromise = loadStripe(stripeDetails.publishableKey);
    return (
      <Elements stripe={stripePromise}>
        <SafariPayment
          props={props}
          paymentDataRequest={paymentDataRequest}
          proceedToApplePayStyle={proceedToApplePayStyle}
        />
      </Elements>
    );
  }
  return null;
};

export default ApplePayment;
