import R14 from "../../core";
import CommonUtility from "../../utils/CommonUtility";

export default class ClaimPaymentDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      totalBills: 0,
      currentPage: 1,
      totalPages: 1,
      resultsPerPage: 9,
    };
  }

  async getClaimPayments(filter, fields = "uid createdBy createdAt checkNo paidDate paidAmount deductible copay coinsurance comments fromDateOfService toDateOfService memberClaimsAmount uploadBillUid documentId", resultsPerPage = 10) {
    try {
      let result = await this.api.qry(
        `query ClaimPayments($filter: ClaimPaymentFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          claimPayments(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            totalCount @include(if: true)
            nodes {
              ${fields}
              
            }
          }
        }`,
        filter
      );
      if (!!result.data && !!result.data.claimPayments) return result.data.claimPayments;
      else return [];
    } catch (error) {
      console.log(error)
      return [];
    }
  }

  async createClaimPayment(input, fields = "uid") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
          mutation CreateClaimPayment($input: CreateClaimPaymentInput){
            createClaimPayment(input: $input){              
              success 
              claimPayment {
                ${fields}
              }
            }
          }`,
        { input }
      );
      return res.data.createClaimPayment.claimPayment;
    } catch (error) {
      return error;
    }
  }

  async updateClaimPayment(
    input,
    fields = "uid"
  ) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
      mutation UpdateClaimPaymentDetail($input: UpdateClaimPaymentInput) {
        updateClaimPayment(input: $input){
          claimPayment {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
      return res.data.updateClaimPayment.claimPayment;
    } catch (error) {
      return error;
    }
  }

}
