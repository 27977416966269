
import React from "react";
import R14, {
  SelectMenuField
} from "../core";

export default R14.connect(
  class DropDown extends React.Component {
    render() {
      const {
        name,
        label,
        required,
        onValueChange,
        searchable,
        itemLoader,
        labelTextStyle,
        labelStyle,
        helperTextStyle,
        style,
        items,
        disabled,
        readOnly,
        multiple,
        value
      } = this.props;

      return (
        <SelectMenuField
          name={name}
          label={label}
          required={required}
          value={value}
          onValueChange={onValueChange}
          searchable={searchable}
          itemLoader={itemLoader}
          labelTextStyle={labelTextStyle}
          labelStyle={labelStyle}
          helperTextStyle={helperTextStyle}
          style={style}
          items={items}
          disabled={disabled}
          readOnly={readOnly}
          multiple={multiple}
        />
      );
    }
  }
);
