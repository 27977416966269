import React from "react";
import R14, { StyleSheet, View, Text, Touchable, Image } from "../../core"; 
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import ExternalLinkComponent from "../../components/PCH/Linking";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";

const xbpLogo = require("../../assets/images/NewXbpLogo150.png");
const back_icon = require("../../assets/images/back_icon.png");

export default R14.connect(
  class PCHPrivacyPolicy extends React.Component {
    navPchLogin = () => {
      let returnUrl = this.props.app.route._location.search;
      if (!!returnUrl && returnUrl.length > 0) {
        document.location = `${window.location.origin}/claims/login${returnUrl}`;
      }
      else {
        if (this.props.app.route._path.indexOf("claims") > -1) {
          this.props.app.nav.to("pchLogin");
          return false;
        }
        else {
          this.props.app.nav.to("login");
          return false;
        }
      }

      
    };
    render() {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <View style={[styles.pchPrivacyPolicy]}>
          {isMobileDevice ?
            (<View>
              <View style={[customCommonStyles.customLogoImageContainer, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <Touchable onPress={this.navPchLogin}>
                  <View>
                    <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                  </View>
                </Touchable>
                <View style={[styles.logoStyle, commonStyles.justifyContentCenter]}>
                  <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
                </View>
                {/* <View>
                    <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500]}>Registration</Text>
                  </View> */}
              </View>
            </View>) :
            (<View style={[styles.loginHeader]}>
              <Touchable onPress={this.navPchLogin}>
                <View>
                  <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                </View>
              </Touchable>
              <View style={commonStyles.marginLeft16}>
                <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
              </View>
            </View>)}
            <View style={[commonStyles.marginBottom35, commonStyles.marginTop20, commonStyles.alignItemsCenter]}>
              <View style={[commonStyles.textAlignCenter]}>
                <Text style={[styles.mainHeading, commonFontStyles.workSans, commonStyles.textAlignCenter]}>PRIVACY POLICY </Text>
              </View>
              <View style={[commonStyles.textAlignCenter]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading4, commonStyles.fullWidth, commonStyles.textAlignCenter]}>Last updated July 19, 2019
              </Text>
              </View>
            </View>
            <View style={styles.pchPrivacyPolicyWrapper}>
            {/* <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>Please click here, if you are an individual located in Canada, for Exela Technologies, Inc.’s Canada PIPEDA Statement.</Text>
            </View> */}
            <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>XBP® was created by and is a brand owned by subsidiaries of Exela Technologies, Inc. (together with its subsidiaries, the "Company", "We", "Us" or "Our" ). If you are using these services or related website, then this Privacy Policy applies.</Text> 
            </View>
            <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  This Privacy Policy defines Company objectives for securing and protecting personally identifiable information and other information.
              </Text>
            </View>
            <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The types of personal data includes names, addresses, phone numbers, birthdates, social security numbers, tax identification numbers, national insurance numbers and financial account numbers.
              </Text>
            </View>
            {/* <View style={commonStyles.marginBottom20}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Please click here for Exela Technologies, Inc.'s EU-U.S. Privacy Shield Framework Policy
              </Text>
              </View>
            <View style={commonStyles.marginBottom20}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  This Privacy Policy tells you about our policies and procedures for collecting, using, and disclosing your personal data and other information.
              </Text>
            </View>
            <View style={commonStyles.marginBottom20}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  It also tells you about your privacy rights and how the law protects you.
              </Text>
            </View> */}
            <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Users can access the service (the "Service") through our websites and other affiliated sites (the "Sites"), applications on devices. A "Device" is any computer used to access the Service, including without limitation a desktop, laptop, mobile phone, tablet, or other Internet-enabled electronic device. This Privacy Policy governs your access of the Service, regardless of how you access it, and by using our Service you consent to the collection, transfer, processing, storage, disclosure and other uses described in this Privacy Policy.
              </Text>
               </View>
            <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  This Privacy Policy refers to different categories of users, specifically: "Visitor" refers to a user who accesses the Sites but does not register; "Registered User" refers to a user who signs up for an account with the Service; and "Subscriber" refers to a Registered User who purchases a subscription plan for the Service. All of the different forms of data, content, and information described below, including without limitation Personal Data, are collectively referred to as "information."
              </Text>
            </View>
            <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
              <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                POLICY/PROCEDURE
              </Text>
            </View>
            <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
              <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  I. OBJECTIVES
              </Text>
            </View>
            <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company adheres to Federal, State and Local regulatory and customer privacy requirements. The Company collects personally identifiable information when voluntarily submitted by users described above. The information provided is used to fulfill specific requests, unless given permission to use it in another manner. In connection with services we provide, the Company may collect the following types of information:
                </Text>
            </View>
            <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>1. </Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  <Text style={[commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading6]}>Personally Identifiable Information.</Text> Names, addresses, email addresses, phone numbers, birthdates, social security, tax identification, financial account, national insurance numbers and company information.
              </Text>
            </View>
            <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                  <Text style={[styles.ppListing, commonFontStyles.workSans,]}>2. </Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  <Text style={[commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading6]}>Cookies.</Text> A cookie is sent out to the user's computer that will identify the user's browser. These cookies enable certain features of the Services and will be used exclusively to collect information concerning the use of the Services. Cookies contain no personally identifiable data, so the user's personally identifiable information is not collected or retained.
              </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>3. </Text>
                
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  <Text style={[commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading6]}>User Communications. </Text>When a user sends an email or other communication to the Company, these communications may be retained in order to process inquiries, respond to requests, and improve overall services.
              </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>4. </Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  <Text style={[commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading6]}>Affiliated Websites.</Text> Personal information that a user may provide to websites affiliated with the Company may be sent to the Company in order to deliver services to the Company or other entities affiliated which the Company provides. The Company processes such information in accordance with this Policy.
              </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company reserves the right to collect and process personal information in the course of providing services to our clients without the knowledge of individuals involved. Where the Company collects personal information from individuals within the European Union, upon request, the Company will inform them about the types of personal information collected from them, the purposes for which it was collected, and uses of the information, and the types of non-agent third parties to which the Company discloses that information.
                 </Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  As a general rule, the Company will not disclose personally identifiable information except when the Company is required or permitted per customer agreement, law (including pursuant to national security of law enforcement requirements) or otherwise, such as when the Company believes in good faith that the law requires disclosure or other circumstances outlined in this Privacy Policy require or permit disclosure.
                     </Text>
                </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>1. </Text>
                      <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>                
                  The Company may share information with governmental agencies or other companies assisting in fraud prevention or investigation. The Company may do so when: (1) permitted or required by law; (2) trying to protect against or prevent actual or potential fraud or unauthorized transactions, or, (3) investigating fraud which has already taken place. This information however, is not provided to these companies for marketing purposes.
                         </Text>
                </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>2. </Text>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Permitted transfers of information, either to third parties or within the Company, include the transfer of information from one jurisdiction to another, including transfers to the United States from other countries, including the European Union, and from the United States to other countries, including the European Union. Subject to the applicable legal requirements, including in the European Union, personal information may be transferred to a jurisdiction where the laws provide less or different protection than the jurisdiction in which the personal information is collected or accessed.
                         </Text>
                </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company takes reasonable steps to protect personally identifiable information. To prevent unauthorized access or disclosure of personally identifiable information, maintain data accuracy, and support the appropriate use and confidentiality of personally identifiable information, either for its own purposes or on behalf of our clients, the Company has put in place appropriate physical, technical, and managerial procedures to safeguard and secure the personally identifiable information and data the Company possesses.
                     </Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company collects and maintains personally identifiable information in a manner that is compatible with the purpose for which it was collected and maintained, or as subsequently authorized by an individual or client. To the extent necessary for such purposes, the Company takes reasonable steps to confirm that personal information is accurate and complete with regard to its intended use.
                  </Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union to the United States, respectively. The Company has certified to the Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this Privacy Policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit <ExternalLinkComponent linkURL={"https://www.privacyshield.gov/"} linkText={"https://www.privacyshield.gov/"} /> 
                  </Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                      <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company utilizes a self-assessment approach support compliance with this Privacy Policy.
                  </Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>1. </Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company periodically verifies that related policies are accurate, comprehensive for the information intended to be covered, prominently displayed, implemented, and are in conformity with the principles of this Privacy Policy.
                  </Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>2. </Text>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company encourages interested persons to raise any concerns with the Company. The Company will investigate and attempt to resolve complaints and disputes regarding use and disclosure of personal information in accordance with the principles contained in this Privacy Policy.
                     </Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>3. </Text>
                      <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  With respect to any complaints relating to this Privacy Policy that cannot be resolved through our internal processes, the Company has agreed to participate in the dispute resolution procedures of the panel established by the European Union Data Protection Authorities to resolve disputes pursuant to the Privacy Shield Principles.
                         </Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>4. </Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If the Company, the European Data Protection Authorities, or other qualified government agencies determines that the Company has not complied with this Privacy Policy, the Company shall take appropriate steps to address any adverse effects related to non-compliance and to promote future compliance.
      </Text>
            </View>         
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,]}>5. </Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If the Company determines an employee is in violation of this Privacy Policy, that employee will be subject to the Company's disciplinary process.
                    </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  In the event that the Company merges, is acquired by or sells its assets to a third party, the Company may disclose personally identifiable information as is reasonably necessary in connection with any such merger, acquisition or sale. Any such party with whom the Company merges or whom acquires some of all of the assets of the Company may not have the same or similar privacy guidelines as set forth in this Privacy Policy and may use personally identifiable information in a manner other than as set forth herein.
</Text>
            </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  This Privacy Policy shall be reviewed annually and updated as necessary to comply with applicable regulations.
            </Text>
          </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company will post any revised Privacy Policy on its website, or a similar website that replaces that website.
                </Text>
          </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Information obtained from or relating to clients or former clients is further subject to the terms of any privacy notice provided to the client, any contract or other agreement with the client, and application enforcement laws.
                </Text>
          </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                            <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Company will cooperate with the appropriate regulatory authorities, including local data protection regulatory authorities, to resolve any complaints regarding the transfer of personal data that cannot be resolved between the Company and an individual.
                </Text>
          </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
                                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  It is important that the personal identifiable information we hold about you is accurate and current. Please keep us informed if your personal identifiable information changes during your relationship with us.
                </Text>
          </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans,styles.ppListingSquare]}>●</Text>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If you are a Registered User, you may review, update, correct or delete the personal identifiable information provided in your registration or account profile by changing information in your profile or account page. In some cases we may retain copies of your information if required by law. For questions about your personal identifiable information on our Service, please contact Company's support at <ExternalLinkComponent linkURL={"mailto:" + CommonString.XBPClaimSupportEmail } linkText={CommonString.XBPClaimSupportEmail} /> We will respond to your inquiry within 30 days.
                  </Text>
            </View>
            
            </View>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  mainHeading: {
    fontSize: 26,
  },
  pchPrivacyPolicy: {
    backgroundColor: "#fff",
  },
  pchPrivacyPolicyWrapper: {
    marginLeft:"auto", 
    marginRight: "auto", 
    padding: 16, 
    width: "80%",
    screen: ({ width }) => {
      if (width <= 991)
      return {
          width: "100%",
        };
    },
  },
  ppListing: {
    fontSize: 14,
    marginRight: 5,
    lineHeight: 28,
  },
  textStyleHolder: {
    textAlign: "justify",
    wordBreak: "break-word",
    flexBasis: "100%",
    maxWidth: "100%",
    paddingRight: 10,
  },
  textStyle: {
    lineHeight: 28,
    color: "#18191b",
    textAlign: "justify",
    wordBreak: "break-word",
    flexGrow: 1,
    flexBasis: "100%",
  },
  ppListingSquare: {
    fontSize: 10,
    marginRight: 9,
    minWidth: 6,
  },
  linkColor: {
    color: "#5ccaf3",
    fontSize: 14,
  },
  logoImageContainer: {
    width: "100%",
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    boxShadow: "0px 0px 6px #00000033",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "flex",
          width: "auto",
          alignItems: "center",
          marginBottom: 16,
          paddingTop: 10,
          paddingRight: 16,
          paddingBottom: 10,
          paddingLeft: 16,
        };
    },
  },
  logoImage: {
    height: 55,
    width: 150,
    marginTop: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    display: "block",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          //	height: 48,
          //	width: 144,
        };
    },
  },
  logoIcon: {
    width: 160,
    height: 64,
    display: "block",
    backgroundSize: "contain",
  },
  icon55150: {
    height: 55,
    width: 150,
    display: "block",
    backgroundSize: "contain",
  },
  loginHeader: {
    display: "flex",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    textAlign: "center",
    alignItems: "center",
    position: "relaive",
    boxShadow: "0px 0px 6px #00000033",
  },
  mainContent: {
    marginTop: 75,
  },
  logoStyle: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  copyRightWrapper: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 16, 
  },
});
