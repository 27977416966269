import React from "react";
import { View, Text, StyleSheet, Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
export default class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    // this.sortType = null;
    this.state = {
      ASC: false,
      DESC: false,
      NA: false,
    };
  }

  componentDidMount() {
    const { seletedOpt } = this.props;
    this.setState({
      [seletedOpt]: true,
    });
  }

  toggleSwitch = async (st) => {
    // this.sortType = ORD[st];
    const { onSwiwtch } = this.props;
    await this.setState({
      ASC: false,
      DESC: false,
      NA: false,
    });
    this.setState({
      ...this.state,
      [st]: !this.state[st],
    });
    onSwiwtch(st);
  };

  render() {
    const { ASC, DESC } = this.state;
    const { toggleSwitchBtnouterStyle } = this.props;
    return (
      <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
        <View style={[styles.atoz]}>
          <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans,]}>Asc</Text>
        </View>
        <View style={[commonStyles.flex1, styles.toggleSwitchBtnouter, commonStyles.justifyContentSpaceBetween, commonStyles.flexDirectionRow, toggleSwitchBtnouterStyle]}>
          <Touchable
            style={[
              DESC ? styles.descendingActive : null,
            ]}
            onPress={() => this.toggleSwitch("ASC")}
          >
            <View>
              <Text></Text>
            </View>
          </Touchable>
          {/*}    <Touchable
            style={[
         
                DESC ? styles.descendingActive : styles.ascendingActive,
              DESC || ASC ? styles.switchBtn : styles.normal,
            ]}
            onPress={() => DESC ? this.toggleSwitch("ASC") : this.toggleSwitch("DESC")}>
            <View >
              <Text>B</Text>
            </View>
        </Touchable>  */}
          <Touchable
            style={[
              ASC ? styles.ascendingActive : null,

            ]}
            onPress={() => this.toggleSwitch("DESC")}
          >
            <View >
              <Text></Text>
            </View>
          </Touchable>
        </View>
        <View style={[styles.atoz]}>
          <Text style={[commonStyles.heading6, commonStyles.fontWeight500, commonFontStyles.workSans,]}>Desc</Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  toggleSwitch: {
    display: "block",
  },
  toggleSwitchBtnouter: {
    width: 50,
    maxWidth: 50,
    minWidth: 50,
    height: 20,
    minHeight: 20,
    marginTop: 0,
    marginRight: 15,
    marginBottom: 0,
    marginLeft: 15,
    backgroundColor: "#e6e6e6",
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
  },
  switchBtn: {
    width: 20,
    maxWidth: 20,
    height: 20,
    minHeight: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    border: "1px solid #d71996",
    backgroundColor: "#d71996",
    transition: "all 0.5s ease",
    flex: 1,
  },
  deactivate: {
    display: "none",
  },
  ascendingActive: {
    marginRight: "40px",
    backgroundColor: "#d71996",
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    minWidth: "30px"
  },
  descendingActive: {
    marginLeft: 30,
    backgroundColor: "#d71996",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    minWidth: 30,
  },
  normal: {
    minWidth: 20,
    maxWidth: 20,
    height: 20,
    minHeight: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    border: "1px solid #37c2ef",
    backgroundColor: "#fff",
  },
  visibilityHidden: {
    opacity: 0,
  },
  atoz: {
    minHeight: 20,
  }
});
