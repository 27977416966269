import React from "react";
import R14, {
  StyleSheet,
  Text,
  View,
  Platform,
  Form,
  SelectMenuField,
  FlatList,
  Touchable,
  Checkbox,
  SubmitButton,
  Button,
  TextInputField,
  Image,
} from "../../core";

import NoRecordsFound from "../../components/NoRecordsFound";
import { CommonString } from "../../constant/appCommonText";
import ModalPopUp from "../../components/ModalPopUp";
import CommonValidators from "../../utils/CommonValidators";
import DatePicker from "../../components/PCH/DatePicker";
import DateUtils from "../../utils/DateUtils";
import CommonUtility from "../../utils/CommonUtility";
import PaymentCheckoutComponent from "../../components/payments/PaymentCheckoutComponent";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";

import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { responsiveTableStyle } from "../../assets/css/responsiveTableStyle";
import { commonMetricsStyle } from "../../assets/css/commonMetricsStyle";
import { STRIPE_KEY } from "../../constant/app.data.constant";
import { analyticsLogs } from "../../utils/AnalyticLogs";

const arrowLeft = require("../../assets/images/icPrevious.png");
const arrowRight = require("../../assets/images/icNext.png");
const arrowFirst = require("../../assets/images/icFirst.png");
const arrowLast = require("../../assets/images/icLast.png");
const type = "Bills";
const approvalStatus = "approved";
const unpaidPayemntStatus = "unpaid";
const partialPaidPayemntStatus = "Partially Paid";
export default R14.connect(
  class MakePaymentsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAllBillsSelected: false,
        selectedBills: [],
        isMarkAsPaid: false,
        bills: [],
        currentPage: 1,
        resultsPerPage: 9,
        totalBills: 0,
        isLoading: false,
        isPaymentCheckout: false,
        paymentStatus: unpaidPayemntStatus,
      };
    }

    async componentDidMount() {
      try {
        const approvalForm = this.props.app.ui.form("approvalStatusForm");
        if (approvalForm) approvalForm.elmts.paymentStatus.setValue({ label: "Unpaid", value: unpaidPayemntStatus });
        this.setState({isLoading: true});
        const payload = {
          pid: this.props.user.pid,
          type,
          approvalStatus,
          paymentStatus: unpaidPayemntStatus,
          page: this.state.currentPage,
          resultsPerPage: this.state.resultsPerPage
        };
        const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
        if(bill.success === false){
          this.props.app.dm.commonDomain.showToastr( "error", CommonString.SomethingWentWrong);
          this.setState({isLoading: false})
          return false;
        }
        this.setState({
          bills: bill.data,
          totalBills: bill.totalRecords,
          isLoading: false,
        });
      } catch (error) {
        this.setState({
          isLoading: false,
        });
      }
    }

    onSelectAllBills = () => {
      analyticsLogs(CommonString.SMB_MAKE_PAYMENT, this.state.isAllBillsSelected ? `${CommonString.UNSELECTED_ALL_BILLS}` : CommonString.SELECTED_ALL_BILLS, this.props.app);
      this.setState({ isAllBillsSelected: !this.state.isAllBillsSelected });
      return false;
    }

    onSelectBill = (bill) => {
      const selectedBills = this.state.selectedBills;
      const isBillAlreadySelected = selectedBills.map(e => e.invoiceID).includes(bill.invoiceID);

      if (!!isBillAlreadySelected) {
        analyticsLogs(CommonString.SMB_MAKE_PAYMENT, `${CommonString.UNSELECT_BILL}`, this.props.app);
        this.setState({ selectedBills: selectedBills.filter(e => e.invoiceID !== bill.invoiceID) });
      } else {
        analyticsLogs(CommonString.SMB_MAKE_PAYMENT, `${CommonString.SELECT_BILL}`, this.props.app);
        // selectedBills.push(bill);
        this.setState({ selectedBills: [bill] });
      }
      return false;
    }

    onApprovalStatusFilter = async (data) => {
      const { value } = data;
      analyticsLogs(CommonString.SMB_MAKE_PAYMENT, `${data.label} ${CommonString.PAYMENT_STATUS_CHANGED}`, this.props.app);
      this.setState({isLoading: true, currentPage: 1, approvalStatus: value});
      const payload = {
        pid: this.props.user.pid,
        type,
        paymentStatus: value,
        approvalStatus,
        page: 1,
        resultsPerPage: 9
      };
      const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
      if(bill.success === false){
          this.props.app.dm.commonDomain.showToastr( "error", CommonString.SomethingWentWrong);
          this.setState({isLoading: false})
          return false;
        }
      this.setState({
        bills: bill.data,
        totalBills: bill.totalRecords,
        isLoading: false,
        currentPage: 1,
        resultsPerPage: 9,
        paymentStatus: value,
      });
      return false;
    };


    getNext = async () => {
      let { currentPage, totalBills, resultsPerPage, paymentStatus } = this.state;
      const totalPages = Math.ceil(totalBills / resultsPerPage);
      if (currentPage <= totalPages) {
        currentPage = currentPage + 1;
        if (currentPage <= totalPages) {
          this.setState({isLoading: true});
          const payload = {
            pid: this.props.user.pid,
            type,
            approvalStatus,
            paymentStatus,
            page: currentPage,
            resultsPerPage: currentPage * resultsPerPage,
          };
          const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
        bill.data = bill.data.slice((currentPage - 1) * resultsPerPage, currentPage * (resultsPerPage + 1));
          this.setState({
            bills: bill.data,
            totalBills: bill.totalRecords,
            currentPage: currentPage,
            isLoading: false,
          });
        }
      }
    }

    getPrevious = async () => {
      let { currentPage, resultsPerPage, paymentStatus } = this.state;
      if (currentPage >= 1) {
        currentPage = currentPage - 1;
        if (currentPage > 0) {
          this.setState({isLoading: true});
          const payload = {
            pid: this.props.user.pid,
            type,
            approvalStatus,
            paymentStatus,
            page: currentPage,
            resultsPerPage: currentPage * resultsPerPage
          };
          const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
          bill.data = bill.data.slice((currentPage - 1) * resultsPerPage, currentPage * (resultsPerPage + 1));
          this.setState({
            bills: bill.data,
            totalBills: bill.totalRecords,
            currentPage: currentPage,
            isLoading: false,
          });
        }
      }
    }


    getFirst = async () => {
      this.setState({isLoading: true});
      const payload = {
        pid: this.props.user.pid,
        type,
        approvalStatus,
        paymentStatus: this.state.paymentStatus,
        page:  1,
        resultsPerPage: this.state.resultsPerPage
      };
      const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
      bill.data = bill.data.slice(0, this.state.resultsPerPage + 1);
      this.setState({
        bills: bill.data,
        totalBills: bill.totalRecords,
        currentPage: 1,
        isLoading: false,
      });
    }

    getLast = async () => {
      let { totalBills, resultsPerPage, paymentStatus } = this.state;
      const totalPages = Math.ceil(totalBills / resultsPerPage);
      this.setState({isLoading: true});
      const payload = {
        pid: this.props.user.pid,
        type,
        approvalStatus,
        paymentStatus,
        page:  totalPages,
        resultsPerPage: totalPages * resultsPerPage
      };
      const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
      bill.data = bill.data.slice((totalPages - 1) * resultsPerPage, totalPages * (resultsPerPage + 1));
      this.setState({
        bills: bill.data,
        totalBills: bill.totalRecords,
        currentPage: totalPages,
        isLoading: false,
      });
    }

    handleMarkAsPaid = () => {
      this.setState({isMarkAsPaid: !this.state.isMarkAsPaid});
      return false;
    }

    handleCheckout = (data) => {
      analyticsLogs(CommonString.SMB_MAKE_PAYMENT,  !!data === "close" || !this.state.isPaymentCheckout ? `${CommonString.CLICKED_ON_MAKE_PAYMENT}` : CommonString.CANCEL_MAKE_PAYMENT, this.props.app);
      this.setState({
        isPaymentCheckout: !!data === "close" ? false : !this.state.isPaymentCheckout
      })
    }

    onDateChange = (val) => {
      const form = this.props.app.ui.form("markAsPaidForm");
      if (!!val) {
        let paidDate = new Date(val);
        let currentDate = CommonUtility.getUTCDate();
        if (currentDate < paidDate) {
          form.elmts.paymentDate.setError("Date shouldn't be in future.");
          return false;
        }
      }
      return true;
    };

    onMarkAsPaidSubmit = async (form) => {
      const {selectedBills, bills} = this.state;
      const { paymentAmount, paymentDate, referenceNo} = form.elmts;
      if(+selectedBills[0].dueAmount < +paymentAmount.value ) {
        form.elmts.paymentAmount.setError(CommonString.PaymentExceedError);
        this.props.app.dm.commonDomain.showToastr( "error", CommonString.PaymentExceedError);
        return false;
      }
      const payload = {
        "pid":this.props.user.pid,
        "type":"bills",
        "invoiceId": `${selectedBills[0].invoiceID}`,
        "paymentAmount": paymentAmount.value,
        "bankCharges":"0.00",
        "paymentDate": DateUtils.formatDateInYYYYMMDD(paymentDate.value), //"2023-07-20"
        "paymentMode":"Cash",
        "referenceNo": referenceNo.value,
        "notes":"",
        "paymentMethod": "Cash",
        "paymentType": "Net",
        "transactionId": `${CommonUtility.generate20Character()}`,
        "transcationRemarks": "Success",
        "transcationStatus": "Success",
        "from": "Mobile App"
      }
      const markAsPaid = await this.props.app.dm.smbApi.saveSmbMarkAsPaidPayment(payload);
      if(markAsPaid === false){
          this.props.app.dm.commonDomain.showToastr( "error", CommonString.SomethingWentWrong);
          this.setState({isLoading: false})
          return false;
        }
      analyticsLogs(CommonString.SMB_MAKE_PAYMENT, `${CommonString.SUBMITTED_MARK_AS_PAID}`, this.props.app);
      if(!!markAsPaid){
        form.elmts.paymentAmount.setValue(null);
        form.elmts.paymentDate.setValue(DateUtils.formatDateInMMDDYYYY(new Date()));
        form.elmts.referenceNo.setValue(null);
        form.elmts.paymentAmount.setError(null);
        form.elmts.paymentDate.setError(null);
        form.elmts.referenceNo.setError(null);
  
        this.props.app.dm.commonDomain.showToastr( "success", `Invoice No. ${selectedBills[0].invoiceID}, successfully mark as paid.`);
        this.getFirst();
      }

      this.setState({
        selectedBills: [],
        isMarkAsPaid: false,
        currentPage: 1,
        resultsPerPage: 9,
        totalBills: bills.length,
        isLoading: false,
      });
      return false;
    }

    onPaymentDone = async (data) => {
      const {selectedBills} = this.state;
      const payload = {
        "pid":this.props.user.pid,
        "type":"bills",
        "invoiceId": `${selectedBills[0].invoiceID}`,
        "paymentAmount": selectedBills[0].dueAmount,
        "bankCharges":"0.00",
        "paymentDate": DateUtils.formatDateInYYYYMMDD(new Date()), //"2023-07-20"
        "paymentMode":"Cash",
        "referenceNo": data.referenceNo || "",
        "notes":"",
        "paymentMethod": "Stripe",
        "paymentType": "Net",
        "transactionId": `${data.transactionId}`,
        "transcationRemarks": "Success",
        "transcationStatus": "Success",
        "from": "Mobile App"
      }
      const markAsPaid = await this.props.app.dm.smbApi.saveSmbMarkAsPaidPayment(payload);
      if(markAsPaid === false){
          this.props.app.dm.commonDomain.showToastr( "error", CommonString.SomethingWentWrong);
          this.setState({isLoading: false})
          return false;
        }
      analyticsLogs(CommonString.SMB_MAKE_PAYMENT, `${CommonString.PAYMENT_DONE}`, this.props.app);
      if(!!markAsPaid){
        this.props.app.dm.commonDomain.showToastr( "success", `Invoice No. ${selectedBills[0].invoiceID}, successfully mark as paid.`);
      }

      this.setState({
        selectedBills: [],
      });
      return false;
    }

    handleModelRender = () => {
      setTimeout(() => {
        const { selectedBills } = this.state;
        const form = this.props.app.ui.form("markAsPaidForm");
        if(form && selectedBills && selectedBills.length){
          if(!form.elmts.paymentAmount.value) form.elmts.paymentAmount.setValue(selectedBills[0].dueAmount);
          if(!form.elmts.paymentDate.value) form.elmts.paymentDate.setValue(DateUtils.formatDateInMMDDYYYY(new Date()));
          form.elmts.paymentAmount.setError(null);
          form.elmts.paymentDate.setError(null);
        }
      }, 0)
    }

    renderPageTitleSection() {
      return (
        <View style={[styles.pageTitleHolder]}>
          <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, styles.pageTitle]}>
            {this.props.r14.navigation.currRoute._config.label}
          </Text>
        </View>
      );
    }

    renderMarkAsPaidSection() {
      this.handleModelRender();
      return (
        <ModalPopUp
          visibility={this.state.isMarkAsPaid}
          isFooter={false}
          bodyWrapper={[commonStyles.mediumModalBodyWrapper, styles.wrapperHeight, ]}
          contentInner={[styles.contentInner]}
        >
          <View style={[commonStyles.flex1, this.state.isMarkAsPaid ? null : commonStyles.displayNone]}>
            <View style={commonStyles.textAlignCenter}>
              <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.textAlignCenter]}>Mark as Paid</Text>
            </View>
            <Form
              name="markAsPaidForm"
              style={[styles.markAsReadForm, commonStyles.justifyContentFlexStart]}
              validators={CommonValidators.getFormValidator()}
              validateBeforeSubmit={true}
              onSubmit={this.onMarkAsPaidSubmit}>
              <View style={[styles.leftInputField]}>
                <TextInputField
                  name="paymentAmount"
                  label={`${CommonString.PaymentAmountLabel}*($)`}
                  validator="max10WithDecimal"
                  required={CommonString.PaymentAmountRequired}
                  style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonMetricsStyle.smallHeading, styles.left0]}
                />
              </View>
              <View style={[styles.leftInputField]}>
                <DatePicker
                    name={"paymentDate"}
                    formName="markAsPaidForm"
                    label={CommonString.PaymentDateLabel+ "*"}
                    required={CommonString.PaymentDateRequired}
                    props={this.props}
                    onValueChange ={this.onDateChange}
                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                  />
              </View>
              <View style={[commonStyles.flex1,]}>
                <View style={[styles.leftInputField]}>
                  <TextInputField
                    name="referenceNo"
                    label={`${CommonString.ReferenceNoLabel}*`}
                    required={CommonString.ReferenceNoRequired}
                    validator="min2Max50"
                    style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonMetricsStyle.smallHeading, styles.left0]}
                  />
                </View>
              </View>
              {/* Back & Add button */}
              <View style={[styles.markAsPaidBtnContainter, Platform.OS === "web" ? styles.mViewMarkAsPaidBtnContainter : null]} >
                  <Button
                    title={"Back"}
                    key="markAsPaidBackBtn"
                    style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, commonStyles.marginRight15, commonStyles.flexBasis48]}
                    titleTextStyle={[commonStyles.secondayWhiteBtnText, commonFontStyles.workSans]}
                    onPress={this.handleMarkAsPaid}
                  />
                  <SubmitButton
                    title={"Submit"}
                    key="markAsPaidBtn"
                    style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24,commonStyles.flexBasis48]}
                    titleTextStyle={[commonStyles.primaryBtnText, commonFontStyles.workSans]}
                  />
              </View>
            </Form>
          </View>

        </ModalPopUp>
      )
    }

    render() {
      const approvalBills = this.state.bills;
      const {currentPage, resultsPerPage, totalBills, isPaymentCheckout, isLoading, selectedBills} = this.state;
      const totalPages = Math.ceil(totalBills / resultsPerPage);
      let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
      let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
      let rowItems = endIndex - startIndex + 1;
      return (
        <>
          <PCHLoaderComponent isVisiable={!!isLoading} labelText={"Please wait"} />
            <View style={[styles.container, commonStyles.dashboardScrollView, commonStyles.flex1]}>
              {this.renderMarkAsPaidSection()}
              <View style={[styles.table]}>
                <View style={[styles.approvalHeader,]}>
                  <Form
                    name="approvalStatusForm"
                    validateBeforeSubmit={false}
                    style={[styles.optFormStyles, commonStyles.flexBasis50, !isPaymentCheckout ? null : commonStyles.displayNone, !selectedBills.length ? styles.minWidth100 : styles.minWidth50, selectedBills.length ? commonStyles.paddingRight0 : null]}
                  >
                    <View style={[styles.statusFieldOuter, commonStyles.flex1, commonStyles.customFlexDirection]}>
                      <View style={[commonStyles.flexBasis50, commonStyles.fullFlexContainer, selectedBills.length ? styles.minWidth100 : null]}>
                        <SelectMenuField
                          name="paymentStatus"
                          label={"Payment Status"}
                          style={[styles.statusField,]}
                          items={[{ label: "Unpaid", value: unpaidPayemntStatus }, { label: "Partially Paid", value: partialPaidPayemntStatus }]}
                          onValueChange={(e) => this.onApprovalStatusFilter(e)}
                          labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                          labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        />
                      </View>
                    </View>
                  </Form>
                  <View style={[
                      commonStyles.flexBasis50,
                      commonStyles.flex1,
                      commonStyles.justifyContentFlexEnd,
                      commonStyles.alignItemsEnd,
                      commonStyles.flexDirectionRow,
                      commonStyles.marginRight16,
                      commonStyles.marginTop16,
                      commonStyles.marginBottom16,
                      styles.customApproveBtns,
                      !isPaymentCheckout ? null : ([
                        styles.paymentCheckout
                      ]),
                      selectedBills.length ? null : commonStyles.displayNone
                    ]}>
                    <PaymentCheckoutComponent
                        amtPayable={selectedBills.length ? selectedBills[0].dueAmount :11}
                        paymentOptions={{merchantAccountNumber: STRIPE_KEY.merchantAccountNumber}}
                        billerName={selectedBills.length ? selectedBills[0].businessName: "Merchant 1"}
                        bulkPay={false}
                        isLoading={false}
                        onPaymentDone={(res) => this.onPaymentDone(res)}
                        handleCheckout={this.handleCheckout}
                        isPaymentCheckout={!!this.state.isPaymentCheckout}
                        currencyCode={"USD"}
                        user={this.props.user}
                        bill={selectedBills}
                    />
                    <View style={[!isPaymentCheckout ? null : commonStyles.displayNone, styles.width50]}>
                      <Touchable
                        style={[responsiveTableStyle.paynowBtn, styles.actionBtnWidth, commonStyles.bgTransparent]}
                        onPress={() => this.handleMarkAsPaid()}>
                        <View style={[responsiveTableStyle.paynowBtnInnerWrap, customCommonStyles.customPaynowBtnInnerWrap, styles.btnWidth]}>
                          <Text style={[commonStyles.primaryBtnText, commonFontStyles.workSans, commonStyles.textAlignCenter, commonStyles.fullWidth,]}>Mark As Paid</Text>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, styles.selectAllChk]}>
                    {/* <View style={[styles.th, styles.selectBillTh, !isPaymentCheckout ? null : commonStyles.displayNone]}>
                      <Checkbox
                        name="selectAllBills"
                        label={`Select All Bills`}
                        value={this.state.isAllBillsSelected}
                        onPress={() => this.onSelectAllBills()}
                        colorBlank="#585858"
                        colorMarked="#4dc5f2"
                        disabled={false}
                      />
                    </View>
                    <View style={[commonStyles.paddingLeftRight16]}>
                      <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading6,]}>Select All</Text>
                    </View> */}
                  </View>
                </View>
                {!!approvalBills.length && !isPaymentCheckout > 0 ? (
                  <View style={[styles.table, commonStyles.margin0, commonStyles.padding0, styles.borderWidth0]}>
                    <View style={styles.theadOuter}>
                      <View
                        style={[
                          styles.thead,
                          commonStyles.flex1,
                          commonStyles.justifyContentSpaceBetween,
                          commonStyles.flexDirectionRow,
                        ]}
                      >
                        <View style={[styles.th, styles.selectAllBillTh]}>
                          {/* <Checkbox
                            name="selectAllBills"
                            label={`Select All Bills`}
                            value={this.state.isAllBillsSelected}
                            onPress={() => this.onSelectAllBills()}
                            colorBlank="#585858"
                            colorMarked="#4dc5f2"
                            disabled={false}
                          /> */}
                        </View>
                        <View style={[styles.th, commonStyles.paddingRight16]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                        Vendor Name
                      </Text>
                        </View>
                        <View style={styles.th}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Invoice#
                      </Text>
                        </View>
                        <View style={[styles.th]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Due Date
                      </Text>
                        </View>
                        <View style={[styles.th]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Due Amount
                      </Text>
                        </View>
                        <View style={[styles.th]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Payment Status
                      </Text>
                        </View>
                        {/* <View
                          style={[
                            styles.th,
                            styles.action,
                            styles.thAction,
                            leftMenuToggle ? null : styles.thActionLeftMenuToggle,
                          ]}
                        >
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Action
                      </Text>
                        </View> */}
                      </View>
                    </View>
                  </View>
                ) : null}
                <FlatList
                  style={[
                    styles.flatlistEle,
                    approvalBills.length > 0
                      ? styles.billListComponent
                      : styles.noItemsComponent,
                    this.props.app.dm.addInsuranceDomain.state.totalPages > 1 ? null : styles.billListComponentWithNoPagi,
                    styles.borderWidth0,
                    commonStyles.marT0,
                    commonStyles.marRT0,
                    commonStyles.marLT0,
                    commonStyles.positionRelative,
                    !isPaymentCheckout ? null : commonStyles.displayNone,
                  ]}
                  data={approvalBills || []}
                  ListEmptyComponent={
                    <View style={[styles.padd10, commonStyles.flex1]}>
                      <NoRecordsFound
                        noRecords={[commonStyles.marginTop15, commonStyles.marT0, commonStyles.marRT0, commonStyles.marLT0, styles.customMargin16]}
                        message={CommonString.NoInvoiceFound}
                      />
                    </View>
                  }
                  keyExtractor={(el, i) => i}
                  renderItem={({ item, index }) => (
                    <View key={"insuranceRow_" + index} style={[styles.customFlex]}>
                      <View style={[styles.th, styles.selectBillTh]}>
                        <Checkbox
                          name="selectBill"
                          label={`Select Bill`}
                          value={selectedBills.map(e => e.invoiceID).includes(item.invoiceID)}
                          onPress={() => this.onSelectBill(item)}
                          colorBlank="#585858"
                          colorMarked="#4dc5f2"
                          disabled={false}
                        />
                      </View>
                      <View
                        style={[
                          styles.tr,
                          commonStyles.flex1,
                          commonStyles.justifyContentSpaceBetween,
                          styles.customFlexDirection,
                          commonStyles.marLT0,
                          commonStyles.marRT0
                        ]}
                      >
                        <View style={[styles.td, commonStyles.paddingRight16]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Vendor Name:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileBreakWord]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.businessName}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.fullMaxWidthTd]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Invoice#:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileBreakWord, styles.fullMaxWidthTd]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.invoiceNo}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.memberName]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Due Date:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileMemberName]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {DateUtils.formatDateInMMDDYYYY(item.dueDate)}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.memberName]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Due Amount:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileMemberName]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.dueAmount}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.memberName]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Payment Status:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileMemberName]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.paymentStatus}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                />
                { approvalBills.length ?
            <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.paginationWrap, commonStyles.flexGrow0, customCommonStyles.customPaginationWrap, styles.paginationWrap, !isPaymentCheckout ? null : commonStyles.displayNone]}>
              <View style={[commonStyles.rowPerPage]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans]}>Rows per page: {rowItems}</Text>
              </View>
              <View style={[commonStyles.displayingItem]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans]}>Displaying item {startIndex}-{endIndex} of {totalBills}</Text>
              </View>
              <View style={[commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, commonStyles.paginationInnerWrap, commonStyles.flexGrow0]}>
                <View>
                  <Touchable
                    style={[currentPage === 1 ? commonStyles.disabledInput : null]}
                    onPress={() => currentPage === 1 ? null : this.getFirst()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === 1 ? commonStyles.disabledInput : null]}>
                      <Image source={arrowFirst} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View>
                  <Touchable
                    style={[currentPage === 1 ? commonStyles.disabledInput : null]}
                    onPress={() => currentPage === 1 ? null : this.getPrevious()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === 1 ? commonStyles.disabledInput : null]}>
                      <Image source={arrowLeft} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View>
                  <Touchable
                    style={[currentPage === totalPages ? commonStyles.disabledInput : null]}
                    onPress={() => currentPage === totalPages ? null : this.getNext()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === totalPages ? commonStyles.disabledInput : null]}>
                      <Image source={arrowRight} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View>
                  <Touchable
                    style={[currentPage === totalPages ? commonStyles.disabledInput : null]}
                    onPress={() => currentPage === totalPages ? null : this.getLast()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === totalPages ? commonStyles.disabledInput : null]}>
                      <Image source={arrowLast} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
            : null}
              </View>

            </View>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  approveBillScreen: {
    padding: 15,
    ...StyleSheet.margin(0, 0, 0, 0),
    height: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
        };
      }
      if (width <= 640) {
        return {
          margin: 0,
          paddingBottom: 20
        };
      }
    },
  },
  pageTitleHolder: {
    // backgroundColor: "#fff",
    zIndex: 999,
    paddingTop: 7,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    background: "#f4f4f4",
  },
  pageTitle: {
    color: "#151b26",
  },
  container: {
    marginBottom: 16
  },
  table: {
    marginRight: 16,
    marginLeft: 16,
    backgroundColor: "#fff",
    padding: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderBottom: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderWidth: 0,
          padding: 0,
          marginTop: 2,
          marginRight: 0,
          marginLeft: 0,
        };
    },
  },
  approvalHeader: {
    marginTop: -16,
    marginRight: -16,
    marginBottom: 0,
    marginLeft: -16,
    position: "relative",
    zIndex: 99,
    flex: 1,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          flexDirection: "column"
        };
      }
    },
  },
  optFormStyles: {
    paddingBottom: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingBottom: 10,
        };
      }
    },
  },
  statusFieldOuter: {
    marginBottom: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        }
      }
    },
  },
  statusField: {
    marginBottom: 0,
  },
  borderWidth0: {
    borderWidth: 0
  },
  theadOuter: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  thead: {
    paddingTop: 3,
    paddingRight: 16,
    paddingBottom: 3,
    paddingLeft: 16,
    position: "relative",
    zIndex: 999,
    backgroundColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    flexDirection: "row",
  },
  tr: {
    flexDirection: "row",
    paddingTop: 5,
    paddingRight: 16,
    paddingBottom: 5,
    paddingLeft: 16,
    position: "relative",
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 10,
          paddingRight: 16,
          paddingBottom: 10,
          paddingLeft: 16,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          borderRightWidth: 0,
          // borderBottomWidth: 1,
          borderLeftWidth: 0,
          flexDirection: "column",
        };
    },
  },
  th: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
  },
  incNameTh: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
    paddingRight: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "75%",
          flexDirection: "row",
        };
    },
  },
  selectAllBillTh: {
    flex: 1,
    flexBasis: 58,
    maxWidth: 58,
    paddingRight: 16,
    marginTop: -8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "75%",
          flexDirection: "row",
        };
    },
  },
  thAction: {
    maxWidth: 45,
    flexBasis: 45,
    paddingLeft: 8,
    minWidth: 103,
  },
  action: {
    maxWidth: 45,
    flexBasis: 45,
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          minWidth: 100,
        };
      }
      if (width <= 991) {
        return {
          // position: "absolute",
          zIndex: 999999,
          top: 10,
          justifyContent: "space-between",
        };
      }
    },
  },
  thActionLeftMenuToggle: {
    minWidth: 113,
  },
  td: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "100%",
          flexDirection: "row",
        };
    },
  },
  flatlistEle: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "block",
        }
      }
    },
  },
  billListComponent: {
    marginTop: 0,
    marginRight: -16,
    marginBottom: 30,
    marginLeft: -16,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    position: 'relative',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // borderTopWidth: 1,
          marginTop: 150,
          marginRight: 0,
          marginBottom: 100,
          marginLeft: 0,
          paddingBottom: 0,
        };
    },
  },
  noItemsComponent: {
    marginTop: 88,
    marginBottom: 50,
    marginLeft: 16,
    marginRight: 16,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 145,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        };
    },
  },
  billListComponentWithNoPagi: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 35,
        };
    },
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customFontWeight600: {
    fontWeight: 400,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontWeight: "bold",
        };
      }
    },
  },
  mobileLabels: {
    marginRight: 10,
    minWidth: 120,
  },
  fullMaxWidthTd: {
    wordBreak: "break-word",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
        }
      }
    },
  },
  memberName: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          flexBasis: "100%",
          maxWidth: "100%",
        };
      }
    },
  },
  customFlex: {
    flex: 1,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          border: "solid",
          borderTop: "none",
          borderWidth: 1,
          borderColor: "#dadada",
        };
      }
      if (width <= 992) {
        return {
          borderBottomWidth: 1,
          borderBottom: "solid",
          borderColor: "#dadada",
        };
      }
    },
  },
  selectBillTh: {
    paddingRight: 0,
    paddingLeft: 16,
    marginTop: 5,
    maxWidth: 45,
    flexBasis: 45,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingLeft: 10,
        };
      }
    },
  },
  selectAllChk: {
    borderBottomWidth: 1,
    borderBottom: "solid",
    borderColor: "#dadada",
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          borderWidth: 0,
          display: "none"
        };
      }
    },
  },
  customApproveBtns: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 24,
          alignItems: "flex-start"
        };
      } else {
        return {
          marginLeft: 16
        }
      }
    },
  },
  actionBtnWidth: {
    width: 125,
    height: 24,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  contentInner: {
    minHeight: "100%",
    height: "100%",
  },
  markAsReadForm: {
    paddingTop: 24,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  leftInputField: {
    maxWidth: "100%",
    // marginRight: 20,
    maxHeight: 58,
    minHeight: 58,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // maxWidth: "100%",
          // flexBasis: "100%",
          marginRight: 0,
          marginBottom: 5,
        };
    },
  },
  left0: {
    left: 0
  },
  markAsPaidBtnContainter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: "100%",
    maxHeight: 34,
  },
  mViewMarkAsPaidBtnContainter: {
    screen: ({ width, height }) => {
      if (width === 375 && height === 553)
        return {
          marginBottom: 56,
        };
    },
  },
  activeButton: {
    backgroundColor: "#37c2ef",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#37c2ef",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
    justifyContent: 'center',
    height: 24,
    maxHeight: 24,
    // paddingTop: 15,
    // paddingBottom: 15,
    width: 100,
    textAlign: 'center'
  },
  
  blueButton: {
    backgroundColor: "#d71996",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#d71996",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
    justifyContent: 'center',
    height: 24,
    // paddingTop: 15,
    // paddingBottom: 15,
    width: 100,
    textAlign: 'center'
  },
  activeButtonPadding: {
    paddingLeft: 15,
    paddingRight: 15
  },
  paymentDateField: {
    // width: "88%",
    flex: 1,
  },
  paginationWrap: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          marginTop: 16,
          marginBottom: 28,
        };
      }
    },
  },
  btnWidth: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: 125,
          maxWidth: 125
        };
      }
    },
  },
  wrapperHeight: {
    width: 300,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minHeight: 390,
          height: 390,
        };
      }
    },
  },
  paymentCheckout: {
    marginTop: 28,
    width: '100%',
    flexBasis: '100%',
    justifyContent: 'center'
  },
  customMargin16: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 16,
          marginRight: 16,
          marginTop: 16,
        };
      }
    },
  },
  width50: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "50%",
        };
      }
    },
  },
  minWidth100: {
    minWidth: "100%",
  },
  minWidth50: {
    minWidth: "50%"
  }
});
