import React from "react";
import R14, { StyleSheet, View, AsyncStorage, Text, Image, Touchable } from "../core";
import BillAggregationComponent from "../components/BillAggregationComponent";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import JsonToCSV from "../utils/JsonToCSV";
import HtmlDecode from "../utils/HtmlDecode";
import DateUtils from "../utils/DateUtils";
import { CURRENCY } from "../constant/app.data.constant";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

const attachedFileIcon = require("../assets/images/attachedFileIcon.png");

export default R14.connect(
    class BulkPaymentScreen extends React.Component {

        async componentDidMount() {
            const previousScreen = await AsyncStorage.getItem("homeScreen");
            // If user reload then naviagte to last screen or dashboard
            if (!this.props.app.dm.dashboard.state.bulkPayBills || !this.props.app.dm.dashboard.state.bulkPayBills.length) {
                previousScreen ? this.props.app.nav.to(previousScreen) : this.props.app.nav.to("dashboard");
                return;
            }
        };

        convertToCSV = async (items, screenName = "data") => {
            if (items.length) {
                analyticsLogs(CommonString.BULK_PAYMENT, `${CommonString.DOWNLOAD_CSV}`, this.props.app);
                items = items.map(item => {
                    return {
                        "Biller Name": item.billerName || item.biller.billerName,
                        "Business Unit Name": item.buName || item.biller.buName,
                        "Bill Subject": item.subject,
                        "Account Number": item.accountNumber,
                        "Creation Date": DateUtils.formatDate(item.billDate),
                        "Due On": DateUtils.formatDate(item.dueDate),
                        "Status": item.billStatus,
                        "Initial Amount": `${CURRENCY.USD}${item.billAmount}`,
                        "Paid Amount": `${CURRENCY.USD}${item.amountPaid}`,
                        "Amount Payable": `${CURRENCY.USD}${item.amountDue.toFixed(2)}`,
                        "Reference ID": item.endToEndIdentification,
                    }
                })
                const csvContent = JsonToCSV.convertJsonToCSV(items);
                HtmlDecode.downloadUTF8file({
                    content: csvContent,
                    filename: `${screenName}_${DateUtils.formatYYYYMMDDHHMMSS()}.csv`
                })
            }
            return false;
        }

        render() {
            const uniqueBillers = [];
            if (this.props.app.dm.dashboard.state.bulkPayBills && this.props.app.dm.dashboard.state.bulkPayBills.length) {
                const billerNameAndId = this.props.app.dm.dashboard.state.bulkPayBills.map(bill => {
                    return { value: bill.billerId, label: bill.biller.billerName }
                });
                billerNameAndId.forEach((biller) => {
                    // biller = {label: 'ABC', value: 123}
                    // uniqueBillers = [ {label: 'ABCD', value: 1234} ]
                    if (uniqueBillers && uniqueBillers.length) {
                        // loop thorugh uniqueBillers, check current biller is not in list
                        let isAlreadyExistsInState = false;
                        uniqueBillers.forEach((stateBiller) => {
                            if (stateBiller.value === biller.value) isAlreadyExistsInState = true;
                        })

                        if (!isAlreadyExistsInState) uniqueBillers.push(biller);

                    } else {
                        uniqueBillers.push(biller);
                    }
                });
            }

            return (
                <>
                    <View style={[commonStyles.marginTop16, commonStyles.marginLeft16, commonStyles.marginBottom16, styles.headerTitle]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading4, commonStyles.fontWeight500]}>{this.props.r14.navigation.currRoute._config.label}{" "}</Text>
                        <View style={[]}>
                            <Touchable onPress={() => this.convertToCSV(this.props.app.dm.dashboard.state.bulkPayBills, "Bulk Pay")}>
                                <Image source={attachedFileIcon} style={commonStyles.icon18} />
                            </Touchable>
                        </View>
                    </View>
                    <BillAggregationComponent bills={this.props.app.dm.dashboard.state.bulkPayBills} billerNameList={uniqueBillers}
                        userDetail={this.props.userDetail} />
                </>
            );
        }
    }
);

const styles = StyleSheet.create({
    billRequestInnerScreen: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginRight: 0,
                    marginLeft: 0,
                };
            }
        },
    },
    headerTitle: {
        maxHeight: 45,
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-between",
        marginRight: 16,
        alignItems: "center",
    }
});
