import React from "react";
import R14, { AsyncStorage } from "../../core";
import BillItemsComponent from "../../components/PCH/BillItemsComponent";

export default R14.connect(
  class PCHDashboardScreen extends React.Component {
    constructor(props) {
      super(props);
      AsyncStorage.setItem("homeScreen", this.props.r14.navigation.activeRouteName);
    }

    render() {
      // added code for responsiveness
      const { filterBills,selectedBlock, userType, isUberDoc, userLoginDetail = {} } = this.props;
      return (
            <BillItemsComponent
              userType={userType}
              filterBills={filterBills}
              screenName="DashboardScreen"
              selectedBlock={selectedBlock}
              isUberDoc={isUberDoc}
              userLoginDetail={userLoginDetail}
            /> 
      );
    }
  }
);