import React from "react";
import {
  View,
  StyleSheet,
  Button,
  TextInputField,
} from "../../core";
import ReactDatePickerField from "react-datepicker";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../utils/DateUtils";
import Moment from 'moment';
import { CommonString } from "../../constant/appCommonText";

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    this.setState({visible: false});
  }

  componentWillUnmount() {
    this.setState({visible: false});
  }

  onClearFromDate = () => {
    try {
      const { name, props, formName, onClear = null } = this.props
      const form = props.app.ui.form(formName);
      form.elmts[name].setValue(null);
      form.elmts[name].setError(null);
      this.setState({
        visible: false,
      })
      if(!!onClear) onClear()
    } catch (error) { }
  }

  closeDatePicker = (val) => {
    try {
      if (val === "open" && this.state.visible) {
        return;
      } else if (val === "close") {
        this.setState({ visible: false });
        return;
      } else {
        this.setState({ visible: !this.state.visible })
      }
    } catch (error) { }
  };

  validateDate = (date) => {
    try {
      let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
      return date_regex.test(date);
    } catch (error) { }
  };

  onBlur = async () => {
    try {
      const { props, formName, name } = this.props;
      const form = props.app.ui.form(formName);
      const value = form.values[name];
      this.closeDatePicker();
      if (!!value) {
        let dat = this.validateDate(value);
        if (!!dat) {
          let formattedDate = DateUtils.formatDateInMMDDYYYY(value);
          dat = this.validateDate(formattedDate);
          if (!!dat) {
            form.elmts[name].setValue(formattedDate);
            form.elmts[name].setError(null);
            this.onFromDateChange(value, false);
          } else {
            form.elmts[name].setValue(value);
            form.elmts[name].setError(CommonString.DateFormatRequired);
          }
        } else {
          form.elmts[name].setValue(null);
          form.elmts[name].setError(CommonString.DateFormatRequired);
        }
      } else {
        form.elmts[name].setError(null);
      }
    } catch (error) { }
  };


  onValueChange = async (val, autoformat = true) => {
    try {
      const { props, name, formName, onValueChange } = this.props;
      const form = props.app.ui.form(formName);
      this.closeDatePicker();
      form.elmts[name].setError(null);
      if (!!val) {
        if (!!autoformat) {
          let date = DateUtils.formatDateInMMDDYYYY(val);
          if (date) {
            form.elmts[name].setValue(date);
            form.elmts[name].setError("");
          } else form.elmts[name].setValue(null);
        } else {
          if (val) {
            form.elmts[name].setValue(val);
            form.elmts[name].setError("");
          } else form.elmts[name].setValue(null);
        }
      }
      if(!!onValueChange) onValueChange(val);
    } catch (error) { 
      console.error(error)
    }
    return false;
  };

  render() {
    const { 
      isUberDocs,
      formName,
      name,
      label,
      props,
      style,
      labelTextStyle,
      labelStyle,
      helperTextStyle,
      required
    } = this.props;

    const value = props.app.ui.form(formName) && props.app.ui.form(formName).values ? props.app.ui.form(formName).values[name] : null;
    const { visible } = this.state;
    let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
    let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
    return <>
      {visible ? (
        <ReactDatePickerField
          selected={!!value ? Moment(value, "MM/DD/YYYY").toDate() : Moment().toDate()}
          onChange={async (date) => await this.onValueChange(date)}
          showMonthDropdown
          isClearable
          showIcon
          inline
          form={formName}
          name={name}
          showYearDropdown
          dropdownMode="select"
          onBlur={async () => await this.onBlur()}
          closeOnScroll={true}
        >
          <View>
            <Button
              onPress={() => this.closeDatePicker ? this.closeDatePicker("close") : null}
              title={'Close'}
              style={[styles.closeBtn, commonStyles.blueButton, commonStyles.flexBasis48, customCommonStyles.noBoxShadow, styles.buttons, commonStyles.secondaryBtn, clientStyle.submitButton]}
              titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, clientStyle.submitButtonText]}
            />
          </View>
        </ReactDatePickerField>) : null}
      <TextInputField
        name={name}
        label={label}
        secure={false}
        readOnly={true}
        required={!!required ? required : false}
        onFocus={() => this.closeDatePicker('open')}
        icon="calendar"
        onIconPress={() => this.closeDatePicker()}
        iconRight={props.app.ui.form(formName) && props.app.ui.form(formName).values[name] ? "close" : ""}
        onIconRightPress={this.onClearFromDate}
        style={style}
        labelTextStyle={labelTextStyle}
        labelStyle={labelStyle}
        helperTextStyle={helperTextStyle}
      />
    </>
  }
}

const styles = StyleSheet.create({
  closeBtn: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  buttons: {
    width: 100,
    minWidth: 100,
    height: 24,
  }
});
