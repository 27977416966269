import React from "react";
import R14, { StyleSheet, View, Text, Touchable, Image, FlatList } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import PCHSignupComponent from "../../components/PCH/PCHSignupComponent";
import NoRecordsFound from "../../components/NoRecordsFound";
import ToggleRadioBtn from "../../components/ToggleRadioBtn";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import { CommonString } from "../../constant/appCommonText";
import PopupModalComponent from "../../components/PopupModalComponent";
import { MESSAGE_TYPE } from "../../constant/app.data.constant";

const editIcon = require("../../assets/images/edit.png");
const arrowLeft = require("../../assets/images/icPrevious.png");
const arrowRight = require("../../assets/images/icNext.png");
const arrowFirst = require("../../assets/images/icFirst.png");
const arrowLast = require("../../assets/images/icLast.png");

export default R14.connect(
  class AddUsers extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        actionMode: "Listing",
        isConfirmationVisible: false,
        rowUid: "",
        activeDeactieFlag: "",
      };
    }

    addUser = async () => {
      this.setState({ actionMode: "Add" });
    };

    editUser = (item) => {
      this.props.app.nav.to("editUsersRoute", { uid: item.uid });
    };

    handleCancel = async () => {
      this.setState({ actionMode: "Listing" });
    };

    openPopScreen = (uid, flag) => {
      this.setState({ rowUid: uid, activeDeactieFlag: flag });
      this.setState({ isConfirmationVisible: true });
    };

    closePopScreen = () => {
      this.setState({ isConfirmationVisible: false });
    };

    refreshList = async () => {
      this.setState({ actionMode: "Listing" });
    };

    activeDeactive = async () => {
      try {
        let apiBody = {
          isActive: this.state.activeDeactieFlag,
          uid: this.state.rowUid,
          updatedDate: new Date().toUTCString(),
          updatedBy: this.props.parentUID,
        };
        this.props.app.dm.userRegistration.updateUserCustomDetails(apiBody, false, "uid");
        const { subuserItems } = this.props.app.dm.user.state;
        if (!!subuserItems && !!subuserItems.length) {
          let index = subuserItems.findIndex((user) => user.uid === this.state.rowUid);
          subuserItems[index].isActive = this.state.activeDeactieFlag;
        }
        this.props.app.dm.user.setState({ subuserItems: subuserItems });
        this.props.app.dm.commonDomain.showToastr(
          "success",
          "User " + (this.state.activeDeactieFlag === "false" ? "Deactivated " : "Activated ") + "successfully."
        );
        this.setState({ isConfirmationVisible: false, rowUid: "", activeDeactieFlag: "" });
      }
      catch (err) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
    };

    getNext = async () => {
      this.setState({
        loaderVisible: true
      })
      let { currentPage, totalPages } = this.props.app.dm.user.state;
      if (currentPage <= totalPages) {
        currentPage = currentPage + 1;
        if (currentPage <= totalPages) {

          this.props.app.dm.user.setState({
            currentPage: currentPage
          });
          await this.props.app.dm.user.getUserDetailsListWithPage(this.props.parentUID);
        }
      }
      this.setState({
        loaderVisible: false
      })
    }

    getPrevious = async () => {
      this.setState({
        loaderVisible: true
      })
      let { currentPage } = this.props.app.dm.user.state;
      if (currentPage >= 1) {
        currentPage = currentPage - 1;
        if (currentPage > 0) {

          this.props.app.dm.user.setState({
            currentPage: currentPage
          })

          await this.props.app.dm.user.getUserDetailsListWithPage(this.props.parentUID);
        }
      }
      this.setState({
        loaderVisible: false
      })
    }


    getFirst = async () => {
      this.setState({
        loaderVisible: true
      })
      this.props.app.dm.user.setState({
        currentPage: 1
      })

      await this.props.app.dm.user.getUserDetailsListWithPage(this.props.parentUID);
      this.setState({
        loaderVisible: false
      })
    }

    getLast = async () => {
      this.setState({
        loaderVisible: true
      })
      let { totalPages } = this.props.app.dm.user.state;
      this.props.app.dm.user.setState({
        currentPage: totalPages
      })

      await this.props.app.dm.user.getUserDetailsListWithPage(this.props.parentUID);
      this.setState({
        loaderVisible: false
      })
    }

    renderUserListing() {
      const { subuserItems, currentPage, resultsPerPage, totalBills } = this.props.app.dm.user.state;
      let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
      let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
      let rowItems = endIndex - startIndex + 1;
      return (
        <View style={[]}>
          {subuserItems.length > 0 ? (
            <View style={styles.table}>
              <View style={[styles.thead]}>
                {/* !!dashboarScroll ? styles.customPaddingRight16 : null */}
                <View style={styles.th}>
                  <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Name</Text>
                </View>
                <View style={styles.th}>
                  <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Email</Text>
                </View>
                <View style={styles.th}>
                  <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Mobile No.</Text>
                </View>
                <View style={[styles.th]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Status</Text>
                </View>
                <View
                  style={[
                    styles.th,
                    styles.action,
                    // !!dashboarScroll ? styles.customMarginRight16 : null
                  ]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Action</Text>
                </View>
              </View>
            </View>
          ) : null}
          <FlatList
            style={[styles.userListComponentWrap, customCommonStyles.customAddUserRow, subuserItems.length > 0 ? customCommonStyles.userListComponent : styles.noItemsComponent]}
            data={subuserItems}
            keyExtractor={(el, i) => i}
            // onLayout={(event) => this.getHeightOfElement(event)}
            ListEmptyComponent={
              <View style={styles.padd10}>
                <NoRecordsFound noRecords={[commonStyles.marginTop15, styles.noUserRecords]} message={"No users added."} />
              </View>
            }
            renderItem={({ item, index }) => (
              <View style={[styles.tr, customCommonStyles.customAddUserRow, (index === item.length - 1) ? customCommonStyles.customTr : null]} key={"userRow_" + index}>
                <View style={styles.td}>
                  <View
                    style={[
                      commonStyles.mView,
                      commonStyles.marginBottom5,
                      styles.mobileLabels,
                    ]}
                  >
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight700,
                        commonStyles.heading6,
                      ]}
                    >
                      Name :
                    </Text>
                  </View>
                  <View style={[customCommonStyles.valueInMbile, customCommonStyles.customPaddingRight]}>
                    <Text
                      style={[commonFontStyles.workSans, commonStyles.heading6]}
                    >
                      {item.firstName +
                        (!!item.lastName ? " " + item.lastName : "")}
                    </Text>
                  </View>
                </View>
                <View style={[styles.td, customCommonStyles.customPaddingRight]}>
                  <View
                    style={[
                      commonStyles.mView,
                      commonStyles.marginBottom5,
                      styles.mobileLabels,
                    ]}
                  >
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight700,
                        commonStyles.heading6,
                      ]}
                    >
                      Email :
                    </Text>
                  </View>
                  <View style={[customCommonStyles.valueInMbile]}>
                    <Text
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.textOverflowEllipsis, customCommonStyles.customLongEmailWidth]}
                    >
                      {item.email}
                    </Text>
                  </View>
                </View>
                <View style={styles.td}>
                  <View
                    style={[
                      commonStyles.mView,
                      commonStyles.marginBottom5,
                      styles.mobileLabels,
                    ]}
                  >
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight700,
                        commonStyles.heading6,
                      ]}
                    >
                      Mobile No :
                    </Text>
                  </View>
                  <View style={[customCommonStyles.valueInMbile]}>
                    <Text
                      style={[commonFontStyles.workSans, commonStyles.heading6]}
                    >
                      {item.mobileNumber}
                    </Text>
                  </View>
                </View>
                <View style={[styles.td, styles.memberName]}>
                  <View
                    style={[
                      commonStyles.mView,
                      commonStyles.marginBottom5,
                      styles.mobileLabels,
                    ]}
                  >
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight700,
                        commonStyles.heading6,
                      ]}
                    >
                      Status :
                    </Text>
                  </View>
                  <View
                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.valueInMbile]}
                  >
                    {/* {(item.isActive === "true" || !!!item.isActive) ?
                      (
                      <Touchable onPress={() => this.openPopScreen(item.uid, "false")} style={commonStyles.marginLeft15}>
                        <Text style={[commonFontStyles.workSans, styles.statusLink, commonStyles.heading6]}>Active </Text>
                      </Touchable>)
                      : (<Touchable onPress={() => this.openPopScreen(item.uid, "true")} style={commonStyles.marginLeft15}>
                        <Text style={[commonFontStyles.workSans, styles.statusLink, commonStyles.heading6]}>Deactive</Text>
                      </Touchable>)
                    } */}
                    <ToggleRadioBtn
                      toggleSwitch={() =>
                        this.openPopScreen(
                          item.uid,
                          item.isActive === "true" || !!!item.isActive
                            ? "false"
                            : "true"
                        )
                      }
                      on={item.isActive === "true" ? true : false}
                      toggleRadioBtnSize={styles.toggleRadioBtnSize}
                    />
                  </View>
                </View>

                {/* <View style={styles.td}>
                  <View style={[commonStyles.mView, commonStyles.marginBottom5, styles.mobileLabels]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading6]}>Status </Text>
                  </View>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>
                    test
                  </Text>
                </View> */}

                <View style={[styles.td, styles.action, customCommonStyles.customAction]}>
                  <View
                    style={[
                      commonStyles.dFlex,
                      commonStyles.alignItemsCenter,
                      // this.state.displayType == "Edit" ? commonStyles.dFlex : styles.none,
                    ]}
                  >
                    <Touchable onPress={() => this.editUser(item)}>
                      <View style={customCommonStyles.editIcon}>
                        <Image source={editIcon} style={commonStyles.icon18} />
                      </View>
                    </Touchable>
                    {/* {(item.isActive === "true" || !!!item.isActive) ?
                      (<Touchable onPress={() => this.openPopScreen(item.uid, "false")} style={commonStyles.marginLeft15}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>  Active </Text>
                      </Touchable>)
                      : (<Touchable onPress={() => this.openPopScreen(item.uid, "true")} style={commonStyles.marginLeft15}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>  Deactive </Text>
                      </Touchable>)
                    } */}
                  </View>
                </View>
              </View>
            )}
          />
          {this.props.app.dm.user.state.totalPages > 1 ?
            <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.paginationWrap, commonStyles.flexGrow0, customCommonStyles.customPaginationWrap]}>
              <View style={[commonStyles.rowPerPage]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans]}>Rows per page: {rowItems}</Text>
              </View>
              <View style={[commonStyles.displayingItem]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans]}>Displaying item {startIndex}-{endIndex} of {totalBills}</Text>
              </View>
              <View style={[commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, commonStyles.paginationInnerWrap, commonStyles.flexGrow0]}>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.user.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.user.state.currentPage === 1 ? null : this.getFirst()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.user.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowFirst} style={commonStyles.icon20} />
                      {/* <Text style={[
                      styles.paginationBtnTextSize,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.whiteColor,
                      commonStyles.transformUpperCase,
                    ]}>First</Text> */}
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.user.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.user.state.currentPage === 1 ? null : this.getPrevious()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.user.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowLeft} style={commonStyles.icon20} />
                      {/* <Ionicons name="page-next-outline" size={32} color="green" /> */}
                      {/* <Text style={[
                      styles.paginationBtnTextSize,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.whiteColor,
                      commonStyles.transformUpperCase,
                    ]}>Prev</Text> */}
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.user.state.currentPage === this.props.app.dm.user.state.totalPages ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.user.state.currentPage === this.props.app.dm.user.state.totalPages ? null : this.getNext()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.user.state.currentPage === this.props.app.dm.user.state.totalPages ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowRight} style={commonStyles.icon20} />
                      {/* <Text style={[
                      styles.paginationBtnTextSize,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.whiteColor,
                      commonStyles.transformUpperCase,
                    ]}>Next</Text> */}
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.user.state.currentPage === this.props.app.dm.user.state.totalPages ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.user.state.currentPage === this.props.app.dm.user.state.totalPages ? null : this.getLast()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.user.state.currentPage === this.props.app.dm.user.state.totalPages ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowLast} style={commonStyles.icon20} />
                      {/* <Text style={[
                      styles.paginationBtnTextSize,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.whiteColor,
                      commonStyles.transformUpperCase,
                    ]}>Last</Text> */}
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
            : null}
          <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} />
        </View>
      );
    }

    render() {
      const { actionMode } = this.state;
      return (
        <>
            <View
              style={[
                styles.pageTitleHolder,
                commonStyles.flex1,
                commonStyles.flexGrow0,
                commonStyles.alignItemsCenter,
                commonStyles.justifyContentSpaceBetween,
                commonStyles.flexDirectionRow,
              ]}>
              <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.titleTextColor]}>
                {actionMode === "Add" ? "Add User" : this.props.r14.navigation.currRoute._config.label}
              </Text>
              <View style={commonStyles.marLAuto}>
                {actionMode === "Listing" ? (
                  <Touchable onPress={() => this.addUser()} style={[commonStyles.primaryButton]}>
                    <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.flexDirectionRow, commonStyles.flexGrow0]}>
                      {/* <View style={styles.plusIcon}>
                        <Image source={plusSolidIcon} style={[commonStyles.icon20]} />
                      </View> */}
                      <View>
                        <Text style={[commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.heading6, styles.custompaddingLeftRight, commonStyles.whiteColor]}>
                          Add User
                        </Text>
                      </View>
                    </View>
                  </Touchable>
                ) : null}
              </View>
            </View>
          {actionMode === "Add" ? (
            <PCHSignupComponent
              formFlexDirection={[styles.formFlexDirection, commonStyles.marT0, styles.addUserForm, customCommonStyles.customAddUserForm]}
              addUsersFormAlignment={styles.addUsersFormAlignment}
              leftInput={styles.leftInput}
              rightInput={styles.rightInput}
              addUserBtnHolder={styles.addUserBtnHolder}
              adduserBtns={styles.adduserBtns}
              addBtn={styles.addBtn}
              registrationType={"new"}
              screen={"addUser"}
              parentUID={this.props.parentUID}
              userLoginDetail={this.props.userLoginDetail}
              refreshList={this.refreshList}
              handleCancel={this.handleCancel}
            />
          ) : null}
          {actionMode === "Listing" ? this.renderUserListing() : null}
          <PopupModalComponent
            visibility={this.state.isConfirmationVisible}
            bodyWrapper={[styles.sessionExpirPopupBodyWrapper]}
            messageType={MESSAGE_TYPE.info}
            headingIcon={true}
            heading={this.state.activeDeactieFlag === "false" ? "Deactivate Account" : "Activate Account"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={`Are you sure you want to ${this.state.activeDeactieFlag === "false" ? "Deactivate" : "Activate"}?`}
            okLabel={this.state.activeDeactieFlag === "false" ? "No" : "Yes"}
            handleOk={this.state.activeDeactieFlag === "false" ? this.closePopScreen : this.activeDeactive}
            closeLabel={this.state.activeDeactieFlag === "false" ? "Yes" : "No"}
            handleClose={this.state.activeDeactieFlag === "false" ? this.activeDeactive : this.closePopScreen }
            modalHeading={[commonStyles.nativeModalHeading]}
          />
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  uploadBillsScreen: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // backgroundColor: "#fff",
        };
    },
  },
  btnWrapper: {
    flex: 1,
    justifyContent: "center",
    marginTop: 24,
    // marginBottom: 15,
  },
  modalBackdrop: {
    backgroundColor: "#00000052",
    overflowX: "auto",
  },
  bodyWrapper: {
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          fontWeight: 600,
        };
    },
  },
  addAttachmentBodyWrapper: {
    marginTop: "auto",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: "100vw",
    padding: 15,
  },
  commentsField: {
    minHeight: 128,
    resize: "vertical",
  },
  closePopup: {
    width: "20%",
    height: 8,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: "#e1e1e1",
    marginBottom: 25,
  },
  attachOpt: {
    textAlign: "center",
    borderWidth: 1,
    borderStyle: "dotted",
    borderColor: "#dadada",
    padding: 10,
  },
  attachments: {
    paddingLeft: 50,
  },
  ucountIconDiv: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
    backgroundColor: "#cff2ff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    marginTop: 5,
    marginLeft: 5,
  },

  filePassowrdModalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  modalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  filePassowrdModalBodyWrapper: {
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#a7a7a7",
    padding: 10,
    width: 350,
    display: "flex",
    flexDirection: "column",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          marginTop: "auto",
        };
      }
    },
  },
  inputDisable: {
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: 0.5,
  },

  table: {
    marginRight: 16,
    marginLeft: 16,
    backgroundColor: "#fff",
    paddingTop: 16,
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    borderBottomWidth: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          marginLeft: 0,
        };
      }
    },
  },

  thead: {
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    marginRight: 16,
    marginLeft: 16,
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    zIndex: 999,
    backgroundColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  tr: {
    justifyContent: "space-between",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTop: 0,
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    position: "relative",
    zIndex: 99,
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flex: 1,
          flexDirection: "row",
        };
      }
      if (width <= 991) {
        return {
          marginLeft: 0,
          marginRight: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 0,
        };
      }
    },
  },
  th: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
  },
  td: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "75%",
          flexDirection: "row",
        };
      }
    },
  },
  action: {
    maxWidth: "10% ",
    flexBasis: "10%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          top: 12,
          position: "absolute",
          right: 26,
          zIndex: 99999999,
          maxWidth: "21%",
          flexBasis: "20%",
        };
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userListComponentWrap: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTopWidth: 0,
    minHeight: 300,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderTopWidth: 1,
        };
    },
  },
  noItemsComponent: {
    marginTop: 100,
    marginRight: 16,
    marginBottom: 50,
    marginLeft: 16,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 60,
          marginRight: 0,
          marginBottom: 100,
          marginLeft: 0,
        };
    },
  },

  loaderimg: {
    width: 300,
    height: 190,
    display: "block",
    backgroundSize: "contain",
  },
  profileEditForm: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginLeft: 16,
    paddingTop: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 150,
        };
    },
  },
  pageTitleHolder: {
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
  },
  customMargin: {
    marginTop: 15,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 15,
          marginRight: 16,
          marginBottom: 0,
          marginLeft: 16,
        };
    },
  },
  formFlexDirection: {
    backgroundColor: "#fff",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    marginTop: 16,
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 16,
          paddingRight: 16,
          paddingBottom: 60,
          paddingLeft: 16,
          flexDirection: "column",
        };
    },
  },
  addUsersFormAlignment: {
    flexBasis: "48%",
    flex: 1,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: null,
        };
      }
    },
  },
  rightInput: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginLeft: 0,
        };
    },
  },
  leftInput: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 0,
        };
    },
  },
  addUserBtnHolder: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // flexBasis: "48%",
          justifyContent: "flex-start",
          marginBottom: 150,
        };
    },
  },
  adduserBtns: {
    flexBasis: "15%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexBasis: "48%",
        };
    },
  },
  addBtn: {
    minWidth: 108,
  },
  mobileLabels: {
    marginRight: 10,
    minWidth: 85,
  },
  statusLink: {
    color: "#38c2ef",
  },
  custompaddingLeftRight: {
    ...StyleSheet.padding(0, 10, 0, 5),
  },
  toggleRadioBtnSize: {
    width: 50,
  },
  buttons: {
    height: 38,
    maxWidth: 100,
  },
  // emailText: {
  //   paddingRight: 20,
  //   textOverflow: "ellipsis",
  //   overflow: "hidden",
  // },
  addUserBtn: {
    minWidth: 115,
  },
  noUserRecords: {
    marginBottom: 350,
  },
  paginationWrap: {
    marginTop: "auto",
    marginRight: 50,
    marginBottom: 30,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 17,
          paddingTop: 20,
          marginBottom: 80,
        }
      }
    }
  },
  paginationBtn: {
    borderWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  addUserForm: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dfdfdf",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexWrap: "wrap",
        }
      }
    }
  },
  closeBtn: {
    position: "absolute",
    right: 0,
    top: 4,
  },
  confirmationModal: {
    minHeight: 150,
  },
});
