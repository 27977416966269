import React from "react";
import R14, { StyleSheet, View, Text, ExternalLink, Touchable, Image } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";
const xbpLogo = require("../../assets/images/NewXbpLogo150.png");
const back_icon = require("../../assets/images/back_icon.png");

export default R14.connect(
  class PCHTermsAndconditions extends React.Component {
    navPchLogin = () => {
      let returnUrl = this.props.app.route._location.search;
      if (!!returnUrl && returnUrl.length > 0) {
        document.location = `${window.location.origin}/claims/login${returnUrl}`;
      }
      else {
        if (this.props.app.route._path.indexOf("claims") > -1) {
          this.props.app.nav.to("pchLogin");
          return false;
        }
        else {
          this.props.app.nav.to("login");
          return false;
        }
      }


    };
    render() {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <View scrollEnabled style={[styles.pchTermsAndconditions]}>
          {isMobileDevice ?
            (
              <View style={[customCommonStyles.customLogoImageContainer, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.flexGrow0]}>
                <Touchable onPress={this.navPchLogin}>
                  <View>
                    <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                  </View>
                </Touchable>
                <View style={[styles.logoStyle, commonStyles.justifyContentCenter]}>
                  <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
                </View>
                {/* <View>
                    <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500]}>Registration</Text>
                  </View> */}
              </View>
            ) :
            (<View style={[styles.loginHeader]}>
              <Touchable onPress={this.navPchLogin}>
                <View>
                  <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                </View>
              </Touchable>
              <View style={commonStyles.marginLeft16}>
                <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
              </View>
            </View>)}
            <View style={[commonStyles.textAlignCenter, commonStyles.marginBottom35, commonStyles.marginTop20]}>
              <View>
                <Text style={[styles.mainHeading, commonFontStyles.workSans, commonStyles.textAlignCenter]}>Terms and Conditions </Text>
              </View>
              <View>
                <Text style={[commonFontStyles.workSans, commonStyles.heading4, commonStyles.fullWidth, commonStyles.textAlignCenter]}>(Last updated July 22, 2021)
                </Text>
              </View>
            </View>
            <View style={styles.pchTermsAndconditionsWrapper}>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>These terms and conditions (collectively, “Terms”) are a legal agreement between you and us which govern your access to and use of the XBP software and service (“XBP”), this site, and other related sites sponsored by us, any related services, and if applicable, applications, including integrations provided by us (collectively, the "Sites"). XBP was created by and is a brand owned by subsidiaries of Exela Technologies, Inc. (together with its subsidiaries, “Exela”, “we” or “us”). If you are using XBP or related Sites, then these Terms apply to your use.</Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  By accessing, using or downloading any materials from the Sites, registering for our services, or downloading or using any of our apps, you agree to be bound by these terms. If you do not agree to these terms, you are not authorized and must cease using XBP and the sites immediately.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  These terms contain a binding arbitration provision and waiver of jury trials and class actions governing disputes arising from the use of the XBP services which affect your legal rights as detailed herein. Please read carefully.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom30, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You must be of legal age (18 years of age or older, or otherwise of legal age in your resident jurisdiction) and competent to agree to these terms to use the sites or services. If you are agreeing to these terms on behalf of an organization or entity, you represent that you have the authority to agree to these terms on that organization or entity’s behalf and bind them to these terms, in which case the references to "You" or "Your" in these terms, except for in this sentence, will refer to that organization or entity. You, the user, shall take all reasonable steps to ensure that no unauthorized persons have access to the XBP or sites, and to ensure that no persons authorized to have such access shall take any action that would be in violation of these terms. Your responses to all questions throughout the electronic process may be recorded and made part of your electronically signed documents record. By using the services (as defined below), you consent to receive electronic communications from Exela and/or its partners and affiliates. You agree that any notices, agreements, disclosures or other communications that are sent to you electronically will satisfy any legal communication requirements, including that such communications be in writing, provided that any communication to you is sent to the email address provided on your account. You agree that any communication to Exela will be sent to <ExternalLink style={[styles.linkColor, commonFontStyles.workSans,]} url={"mailto:" + CommonString.XBPClaimSupportEmail}>{CommonString.XBPClaimSupportEmail}</ExternalLink> for further handling.
                </Text>
              </View>
              <View style={commonStyles.marginBottom20}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  A. USER TYPES
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Your use of XBP through the Sites and your access to relevant support from us (collectively, the "Services") is available as follows:
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  UNLIMITED FREE VERSION. Unlimited Free Version (“Free Version”) refers to the use of Services for unlimited period of time. You acknowledge that the version of the Services that are available pursuant to a Free Version may not include or allow access to all features or functions of the Services. The Free Version is available until such time as Exela elects to end the Free Version and/or add restrictions or conditions to the Free Version, at its sole discretion. Notwithstanding anything to the contrary, during a Free Version the Services are provided solely “AS IS” and “as available” without any warranty that may be set forth in these Terms, and Exela expressly disclaims any and all warranties, including implied warranties and specifically any warranties of merchantability or fitness for a particular purpose; and to the fullest extent permitted by applicable law, Exela hereby disclaims any and all liability arising out of or relating to your use of the free version.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  SUBSCRIPTION SERVICES. If you purchase a subscription plan, you will have access to additional Services ("Subscription Services") based on the plan that you select ("Subscription Level") and time commitment ("Subscription Period"). In accordance with our subscription plan descriptions, Exela will limit your access to certain features, volume and/or time-based on the plan that you select. Any unused volume associated with a per-use Subscription Level will be carried over to subsequent Subscription Periods. Exela reserves the right at any time to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  B. OWNERSHIP OF PROPRIETARY INFORMATION; CONTENT
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Services contain materials that are proprietary and are protected by copyright laws, international treaty provisions, trademarks, service marks, and other intellectual property rights, laws, and treaties. Exela and its licensors own and will retain ownership of all right, title, and interest in the Services, including intellectual property rights therein (but excluding your Content (as defined below)). You own and will retain ownership of all right, title, and interest in your Content. Except as otherwise required by applicable law, any reproduction, distribution, modification, retransmission, or publication of any copyrighted material is strictly prohibited without the express written consent of the copyright owner. Neither party will do anything inconsistent with such a title. Your use of any Exela proprietary information other than as permitted in accordance with these Terms is strictly prohibited.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Services consist of online tools for managing and manipulating documents, obtaining signatures, and other text on documents, communications thereof, and any data contained therein (collectively "Content"). You own and will retain ownership of all right, title, and interest in your Content uploaded to the Services, including intellectual property rights therein. Except as described in these Terms, Exela claims no ownership rights in your Content. Exela may provide links to publicly available Content to use with the Services but is not responsible in any manner for such publicly available Content. However, you are responsible for ensuring that any Content (as well as any publicly available Content) that you use in Connection with the Services complies with the United States copyright laws, other intellectual property laws, and treaties, as well as applicable privacy laws and export laws. Exela does not have the ability to screen all Content, but Exela shall have the right (but not the obligation) in its sole discretion to remove all or part of your Content from the Services. If you are an administrator for your account, then you are responsible for the Content and activities of your users.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Although you are in no way obligated to do so, if you do choose to share an idea, suggestions, information or feedback relating to the Services ("Suggestion"), then you hereby assign, transfer and convey to Exela, all worldwide right, title, and interest in and to all intellectual property rights in all of your Suggestions.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela may provide Content, such as sample documents, templates, and forms, for you to use solely with the Services. Exela owns and will retain ownership of all right, title, and interest in its Content, including all intellectual property rights therein. You claim no ownership of Exela’s Content.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If you are a user of Content posted by a third party on the Services ("Third Party Content"), you acknowledge that Exela does not approve, endorse, monitor, verify, or take responsibility for any such Third Party Content. You agree that the third party posting the Third Party Content is solely responsible for it and that Exela is not liable for any Third Party Content. EXELA HEREBY EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY WITH RESPECT TO CLAIMS ARISING OUT OF OR RELATED TO THIRD PARTY CONTENT REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL OR EQUITABLE THEORY.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>

                  C. LIMITED LICENSE
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Upon your acceptance of these Terms, Exela hereby grants you a limited, revocable, non-exclusive, non-transferrable license to access and use the Services (including Exela Content provided in connection with the Services) solely for your personal or internal business purpose and only as expressly permitted herein. You acknowledge that Exela reserves all rights relating to the Services not expressly granted to you herein. The license, Service or software is considered delivered and accepted when made available for access.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You will not nor permit anyone else to: (a) share your login ID for the Services with any other person (each user must have their own login ID) or use or launch any automated system to access the Services; (b) access another users account, circumvent standard access to the Services, or attempt to gain unauthorized access to the Services; (c) use the Services in a manner that is illegal, defames, abuses, tortious, harasses, stalks, threatens, or violates the legal rights of others; (d) use the Services to impersonate another person or entity or misrepresenting yourself or affiliation with an entity, including sending deceptive source-identifying information such as spoofing or phishing, or to upload, post, host, or transmit unsolicited email or spam, or use the Services to transmit any malware, Trojan horses, worms or viruses or any destructive or malicious code; (e) rent, lease, distribute, sell, resell, assign, or otherwise transfer rights to the Services or Exela Content; (f) reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code or underlying intellectual property relevant to the Services; or (g) use the Services or Exela Content for the benefit of a competitive offering to the Services or to intentionally harm or discredit Exela.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Any violation by you of the license restrictions contained in this Section may result in the immediate termination of your right to use the Services, as well as potential liability for copyright infringement or other claims. Exela reserves the right to refuse service to anyone for any reason at any time.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  D. CONFIDENTIAL INFORMATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  In connection with the Services, you and Exela may share proprietary or non-public information (“Confidential Information”) which may or may not be designated as “Confidential,” “Proprietary” or some similar designation. "Confidential Information" will not include any information which: (a) was previously known to the other party without restriction on use or disclosure; (b) is or becomes publicly available, through no fault or breach of these terms by the receiving party; (c) is rightfully obtained by the receiving party from a third party not under a duty of confidentiality and without restriction on use or disclosure; (d) is independently developed by such other party without reference to the Confidential Information.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The receiving party of Confidential Information agrees: (a) not to disclose Confidential Information to a third party, with the exception that Confidential Information may be disclosed to such party’s employees, and/or its affiliates, consultants, attorneys, accountants or other professional advisors who have a need to have the information that are under confidentiality obligations that are at least as restrictive as the terms contained herein or subject to direction of the disclosing party; (b) to protect the secrecy of and to avoid disclosure and unauthorized use of the disclosing party's Confidential Information to the same degree that it takes to protect its own Confidential Information, and in no event less than reasonable care; and (c) to use Confidential Information only as necessary to fulfill its obligations and exercise its rights under these Terms.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Upon termination or expiration of your Free Version or Subscription Period, Exela will have no further obligations in respect of your Confidential Information.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  E. HANDLING OF EU PERSONAL DATA
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The following terms have the meanings given in the General Data Protection Regulation (EU) 2016/679: "personal data", "data subject" and "process". To the extent you are an individual, you hereby expressly grant consent to Exela to: (a) process your personal data (including sensitive personal data) in accordance with the Privacy Policy and to collect, use, and disclose such personal data in order deliver Services and otherwise in accordance with the terms herein; (b) disclose your personal data (including sensitive personal data) to the categories of recipients described in the Privacy Policy; (c) transfer your personal data (including sensitive personal data) throughout the world, including to the United States and other countries that do not ensure adequate protection for personal data (as determined by the European Commission); and (d) disclose your personal data (including sensitive personal data) to comply with lawful requests by public authorities, including to meet national security or law enforcement requirements.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  F. DEVICES AND/OR INTERNET
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Services depend on third party network and Internet providers and device manufacturers that are outside of Exela's control. You acknowledge that Exela will not be responsible or liable for performance or non-performance as a result of such devices or networks. You acknowledge that the processing and transmission of the Services, including your Content, may involve transmissions over various networks and unencrypted transfer to a network or device. You acknowledge that third party networks or devices may change their technical requirements interfering with the operation of the Services.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  G. WARRANTIES AND DISCLAIMERS
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You hereby represent and warrant to Exela that: (a) you have all requisite rights and authority to use the Services under these terms and to grant all applicable rights herein; (b) you are responsible for your use of the Services; (c) you are solely responsible for maintaining the confidentiality of your password(s); (d) you will promptly notify Exela any unauthorized use of the Services; (e) you agree that Exela will not be liable for any losses incurred as a result of a third party's use of your account, regardless of whether such use is with or without your knowledge and consent; (f) you will use the Services for lawful purposes only and subject to these terms; (g) any information you submit to Exela is true, accurate, and correct; and (h) you will not attempt to gain unauthorized access to the system or the Services, other accounts, computer systems, or networks under the control or responsibility of Exela.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You acknowledge that the Sites and/or Services and any related products, information, documentation, software, technology, technical data, and any derivatives thereof, that Exela makes available (collectively “Excluded Data”) is subject to export control laws and regulations of the United States (including, the U.S. Export Administration Act and the sanctions regulations administered by the U.S. Department of the Treasury Office of Foreign Assets Control (“OFAC”)) and other jurisdictions (collectively “Export Laws”). You represent and warrant that you will not access, download, use, export, or re-export, directly or indirectly, the Excluded Data to any location, entity, government or person prohibited by Export Laws (including to any end-user in a U.S. embargoed country or territory or an end-user included on OFAC’s listing of Specially Designated Nationals or the U.S. Commerce Department’s entity List or Denied Persons List) without first complying with all Export Laws that may be imposed by the U.S. Government and any country or organization of nations within whose jurisdiction you operate or do business. You are solely responsible for complying with Export Laws for all Excluded Data and any of its content transmitted through the Sites.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Except as expressly set forth herein, the services and sites are provided “as is” and “as available.” your use of the services and sites shall be at your sole risk. Exela and its respective officers, directors, employees, agents, affiliates, subsidiaries, and licensors (“Exela parties”) (I) makes no additional representation or warranty of any kind whether express, implied (either in fact or by operation of law), or statutory, as to any matter whatsoever; (ii) expressly disclaims all implied warranties of merchantability, fitness for a particular purpose, quality, accuracy, and title; and (iii) does not warrant that the services or sites are or will be error-free, will meet your requirements, or be timely or secure. You will be solely responsible for any damage resulting from use of the services or sites.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Exela parties make no warranties or representations about the accuracy or completeness of the services and sites content and assume no responsibility for any: (I) errors, mistakes, or inaccuracies of content; (ii) personal injury or property damage of any nature whatsoever resulting from your access to and use of services and sites; (iii) any unauthorized access to or use of our servers; (iv) any interruption or cessation of transmission to or from the sites; (v) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the sites; (vi) any loss of your data or content from the sites; and/or (vii) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the services and sites. You will not have the right to make or pass on any representation or warranty on behalf of Exela to any third party.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Because some jurisdictions do not allow limitations on implied warranties, the above limitation may not apply to you. In that event, such warranties are limited to the maximum extent permitted by and for the minimum warranty period allowed by the mandatory applicable law.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela's sole obligation and your sole and exclusive remedy for any breach of these terms is limited to Exela's reasonable commercial efforts to correct the non-conforming services at no additional charge to you. In the event that Exela is unable to correct material deficiencies in the services after using commercially reasonable efforts to do so, you shall be entitled to terminate the then remaining term of your subscription as your sole and exclusive remedy and you shall be entitled to a prorated refund of any unused prepaid fees from the date of termination, and/or relief from any subsequent payments due with respect to such services. Exela's obligations hereunder are conditioned upon you notifying Exela of the breach in writing and providing Exela with evidence of such non-conformity to assist in Exela's efforts to verify the same.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  H. LIMITITATION OF LIABILITY
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Not with standing anything to the contrary, the Exela parties will not, under any circumstances, be liable to you for any consequential, incidental, special, cover, punitive, or exemplary damages arising out of or related to the transactions contemplated under these terms, including, but not limited to, goodwill, work stoppage, lost profits, or loss of business, even if apprised of the likelihood of such losses, and whether such claims are made based on contract, tort (including negligence), or any other legal theory.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  To the fullest extent permitted by applicable law, in no event shall the Exela parties be liable to you for any direct, indirect, incidental, special, punitive, or consequential damages whatsoever resulting from any: (a) use of the sites or the services; (b) errors, mistakes, or inaccuracies of content; (c) personal injury or property damage of any nature whatsoever resulting from your access to and use of the sites or the services; (d) any unauthorized access to or use of our servers and/or any and all personal information and/or financial information stored therein; (e) any interruption or cessation of transmission to or from our servers; (f) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the sites or the services by any third party; (g) any loss of your data or content from the sites or the services; (h) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, transmitted, or otherwise made available via the sites or the services, whether based on warranty, contract, tort (including negligence), or any other legal or equitable theory, and whether or not the Exela parties are advised of the possibility of such damages; and/or (I) the disclosure of information pursuant to these terms or our privacy policy.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela’s total liability to you for any cause of action arising out of or related to these terms or to your use of the services or sites (including warranty claims), regardless of the forum and regardless of whether any action or claim is based on contract, tort (including negligence), or any other legal or equitable theory, will not exceed the total amount paid by you to Exela for the applicable service(s) giving rise to the claim under these terms during the six (6) month period preceding the date of the first event giving rise to liability. The existence of more than one claim shall not enlarge this limit.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Each provision of these Terms that provides for a limitation of liability, disclaimer of warranties, or exclusion of damages represents an agreed allocation of the risks of these Terms between you and us. This allocation is an essential element of the basis of the bargain between the parties.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Your jurisdiction may not allow the exclusion of warranties or limitation of liability above, so the limitations or exclusions herein may not apply to you. In such jurisdictions, the liability of Exela will be limited to the greatest extent permitted by law.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  I. INDEMNIFICATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You will defend, indemnify, and hold the Exela Parties harmless from any and all third party claims, damages, liabilities, and costs (including, but not limited to attorneys' fees) arising from or related to: (a) allegations that your Content infringes any intellectual property or other rights of any person or entity, (b) your failure to comply with these Terms, or (c) your willful misconduct or negligence.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela retains the exclusive right to settle, compromise, and pay, without your consent, any and all claims or causes of action that are brought against Exela. Exela will (a) promptly provide notice to you of any claim, suit or proceeding for which indemnity is claimed and you agree to cooperate with our defense of these claim(s). You will not enter into any settlement that imposes liability or obligations on Exela without Exela's express prior consent.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  J. PAYMENT; FEES
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You shall pay Exela all fees associated with your Subscription in advance of each Subscription Period (monthly or annually), whether or not you use any or all of the Subscription Services for which you subscribe. Prior to accessing your Subscription Services, you must provide Exela with a valid credit card, or other forms of payment acceptable to Exela. Additionally, you are responsible for payment of all taxes, including sales, use, personal property, value-added, excise, customs fees, import duties, stamp duties and any other similar taxes and duties, including penalties and interest, imposed by any federal, state, provincial or local government entity on the transactions contemplated by these Terms, excluding taxes based upon Exela's net income.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  YOUR PAYMENTS TO US ARE NON-REFUNDABLE EXCEPT AS EXPRESSLY PROVIDED IN THE TERMS OF THIS SECTION J AND SECTION L. If you provided a credit card you agree, authorize and consent that Exela may charge your account for all fees for your Subscription Services as they are incurred, including renewal payments on the last day of each Subscription Period. The authorization to a charge continues through the applicable Subscription Period and automatic renewal terms unless you CANCEL your subscription as set forth in SECTION K of these Terms. If you are invoiced by Exela, you agree to pay all such invoices within thirty (30) days of the date of the invoice. As a subscriber, you agree to provide Exela with complete and accurate billing and contact information and keep it up to date throughout your Subscription Period.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If Exela fails to receive payment from you by any due date, Exela may, in its sole discretion, suspend your use of Subscription Services and delete your account. You further agree to reimburse Exela for all reasonable attorneys' fees and costs incurred to collect past due amounts.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You acknowledge that Exela may change its fees and/or fee structures for Services from time to time in its discretion, including instituting fees for use of Services that were formerly included in the Free Version Services or other free functionality on the Sites without liability to you. Exela will post notice of such changes on the Sites. Any pricing changes to your paid Subscription Services will be effective as of your next Subscription Period and not impact your current Subscription Period. You agree that your continued use of all or part of the Services will be subject to your payment of any applicable fees. Exela shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the services.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If you are a subscriber with a good faith dispute of any payment, you should contact Exela's support team with details of the dispute, any supporting documentation, and your contact information within thirty (30) days of your becoming aware of the dispute. You will continue to pay all amounts when due (even disputed amounts) while we work diligently and promptly to resolve the dispute. Upon resolution, Exela will promptly credit any amount owed to you or you will promptly pay all amounts owed to Exela.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  In order to receive a credit or dispute an invoice, please contact Exela support at <ExternalLink style={[styles.linkColor, commonFontStyles.workSans,]} url={"mailto:" + CommonString.XBPClaimSupportEmail } >{CommonString.XBPClaimSupportEmail}</ExternalLink>
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  K. TERM; TERMINATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You can cancel your subscription by clicking on ‘cancel subscription’ tab in your account. If you are a user of the free version, these terms will remain in full force and effect during the free version, except that Exela or you may terminate the arrangement contemplated by these terms at any time for convenience without liability or notice.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If you are a subscriber, the arrangement contemplated by these terms will be effective as of the earlier of either (i) the date that you accept these click-thru terms or (ii) the date that you signed a sales order with the end of the subscription period specified in your applicable order form, or if no term is specified, until the arrangement contemplated by these terms is terminated by either party in accordance with these terms. These terms will, upon the expiration of your initial subscription period, automatically renew for successive subscription periods equal in duration to your initial term, unless you cancel your subscription prior to the end of your then-current subscription period. If you are an individual subscriber or an administrator for the account, you can cancel your subscription by clicking on the ‘cancel subscription’ tab in your account. If you or Exela elects not to renew the services before the end of your current subscription period, you will not be charged for subsequent periods.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela may terminate the arrangement contemplated by these Terms or suspend your use of Subscription Services if (a) you violate the license restrictions contained in these Terms, or (b) you are past due on payment of a fees owed and have not responded to request for payment, (c) your billing or contact information is false, fraudulent or invalid, (d) you issued bad checks or reversed credit/debit card transactions, (e) you breach or otherwise to fail to comply with these Terms and have not cured such breach within five (5) days of Exela's written notice specifying the alleged breach, or (f) you enter receivership, general assignment for the benefit of creditors, any bankruptcy or insolvency proceedings that are not dismissed within sixty (60) days, liquidation, dissolution or termination of your business operations. Exela may also elect to cancel your subscription at the end of your then current Subscription Period by providing you at least ten (10) days prior written notice.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  L. EFFECT OF TERMINATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If these terms expire or are terminated for any reason: (a) you will pay to Exela any amounts that have accrued before, and remain unpaid as of, the date of the termination or expiration, including those for the billing cycle in which termination occurs; (b) any and all of your liabilities to Exela that have accrued before the effective date of the expiration or termination will survive; (c) licenses and use rights granted to you with respect to the Services and Sites and intellectual property will immediately terminate; (d) Exela’s obligation to provide any further services under these terms will immediately terminate, (e) your account and your Content contained therein will no longer be accessible by you. Exela will not be liable to you or any third party for termination of the arrangement contemplated by these Terms or any termination or suspension of your use of the Services.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If you are a subscriber, termination by Exela will not result in any refund of fees for the current Subscription Period and you are still obligated to pay any outstanding, unpaid fees. Section J above notwithstanding, if you or we terminate an ‘annual’ Subscription Level within the first thirty (30) days of the initial Subscription Period, you may submit a request (HERE) to Exela for a refund of the fees paid (less any applicable cost or transaction fees to facilitate such refund) to Exela for the initial Subscription Period, which Exela will reasonably consider, without obligation, in good faith. Exela will have no obligation to consider refund requests related to a terminated Subscription plan, if the termination does not occur in the first thirty (30) days of the initial Subscription Period, or if there has been a violation of other terms herein.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You acknowledge that your content will be deleted from the services upon the termination of the free period, termination or upon the expiration date of your cancelled subscription. This information cannot be recovered once deleted. If you are not a current subscriber, Exela will not store content on your behalf.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  M. GOVERNING LAW
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The arrangement contemplated by these Terms will be governed by and construed in accordance with the substantive laws in the State of New York. These Terms will not be governed by the conflict of law rules of any jurisdiction that would result in the application of any laws other than those of the State of New York or the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  N. BINDING ARBITRATION
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  In the event of a dispute, controversy or claim arising out of or relating in any way to these terms or to Exela, the complaining party shall notify the other party in writing thereof. Within thirty (30) days of such notice, both parties shall attempt to resolve the dispute in good faith. If the dispute is not resolved by negotiation or mediation, at any time after expiration of thirty (30) calendar days from the date of the written request for informal settlement. Instead of suing in court, we each agree to settle disputes only by arbitration, accordingly. The dispute shall be submitted to binding arbitration in accordance with the aaa commercial arbitration rules by a sole independent arbitrator chosen by the aaa office in new york city. The arbitrator shall issue a written award stating the reasons therefor. The rules in arbitration are different. There’s no judge or jury, and review is limited, but an arbitrator can award the same damages and relief, and must honour the same limitations stated in these terms as a court would. Judgment upon the award rendered by the arbitrator shall be binding on both parties and may be entered in any court of competent jurisdiction. The arbitration shall be conducted in the english language. Either party shall retain the right to obtain interim equitable relief in any court of competent jurisdiction. In no event shall the arbitrator be empowered to award punitive damages. Each party shall bear its own expense of arbitration and shall share equally the cost of the arbitrator and the arbitration proceedings.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  O. WAIVER OF JURY TRIAL; CLASS ACTION WAIVER
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Subject to the foregoing agreement to arbitrate, Exela and you each knowingly waive any right to jury trial under these terms, and you further agree that arbitration shall proceed solely on an individual basis without the right for any claims to be arbitrated on a collective or class action basis or on a basis involving claims brought in a purported representative capacity on behalf of others ("Class action waiver"). Claims may not be joined or consolidated unless agreed to in writing by us. The waiver of jury trial and agreement to arbitrate shall remain in effect even if the class action waiver is limited, voided, or found unenforceable. You and Exela agree that each may bring claims against the other only in your or its individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  P. THIRD-PARTY CONTENT
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Services may provide, links to other third-party websites, services, or resources that are beyond Exela’s control. We make no representations as to the quality, suitability, functionality, or legality of any third-party content to which links may be provided, and you hereby waive any claim you might have against us with respect to such services. Exela is not responsible for the content on the internet or web pages that are located outside the site or posts of user content. Your usage through the Site, including any payment gateway, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and such provider. You agree that Exela shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such links to third-party websites or resources on the Site.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  Q. MISCELLANEOUS
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The Services provided to you hereunder are "commercial items" as that term is defined at 48 C.F.R. 2.101 (October 1995) consisting of "commercial computer software" and "commercial computer software documentation" as such terms are used in 48 C.F.R. 12.212 (Sept 1995) and are provided to the U.S. Government only as a commercial end item. All U.S. Government End User's rights to access and use the Services are set forth in 48 C.F.R. 12.212 and 48 C.F.R. 227.7202 (June 1995).
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  These Terms may be agreed to online, by use of the Services and/or executed by electronic signature and in one or more counterparts.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  These Terms do not establish the parties as business partners or agents of the other, and neither party has the right to bind the other on any third-party agreement.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  You may not assign any of your rights or obligations hereunder, except in connection with a merger or acquisition. Subject to the foregoing, these Terms shall be binding upon, and inure to the benefit of, the successors and assigns of the parties thereto.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Except for any payment obligations, neither you nor Exela will be liable for failure to perform any obligation under these Terms to the extent such failure is caused by a force majeure event (including acts of God, natural disasters, war, civil disturbance, action by a governmental entity, strike, crime and other causes beyond the party’s reasonable control). The party affected by the force majeure event will provide notice to the other party within a commercially reasonable time and will use commercially reasonable efforts to resume performance as soon as practicable. Obligations not performed due to a force majeure event will be performed promptly after the force majeure event concludes.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  These Terms together with our "Consent To Proceed Electronically," described at Consent To Proceed Electronically and "Privacy Policy," which governs Exela's use of your information, described at Privacy Policy, each of which are incorporated herein, constitute the entire agreement between the parties with respect to your access and use of the Services. These Terms supersede and replace all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter and prevails over any conflicting terms contained in any documents, communications, or discussions. All waivers must be in writing and signed by the party waiving its rights. If any part of these Terms is found unenforceable by an arbitrator or court of competent jurisdiction, the rest of these Terms will nonetheless continue in effect, and both parties agree that the unenforceable provisions will be modified so as to best accomplish the objectives of these Terms within the limits of applicable law.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  We may revise these Terms or any additional terms and conditions that are relevant to the Services from time to time to reflect changes in the law or to the Services. Please review the site on a regular basis to obtain timely notice of any revisions. We will post the revised terms on the Site with a “last updated” date. You agree that we shall not be liable to you or to any third party for any modification of the Terms. If you continue to use the services after the revisions take effect, you agree to be bound by the revised terms.
                </Text>
              </View>
            </View>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  pchTermsAndconditions: {
    backgroundColor: "#fff",
  },
  mainHeading: {
    fontSize: 26,
  },
  pchTermsAndconditionsWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: 16,
    width: "80%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
        };
    },
  },
  textStyleHolder: {
    textAlign: "justify",
  },
  textStyle: {
    lineHeight: 28,
    color: "#18191b",
  },
  linkColor: {
    color: "#5ccaf3",
    // textDecoration: "none",
    fontSize: 14,
  },
  logoImageContainer: {
    width: "100%",
    paddingTop: 10,
    paddingRight: 30,
    paddingBottom: 10,
    paddingLeft: 30,
    boxShadow: "0px 0px 6px #00000033",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "auto",
          alignItems: "center",
          marginBottom: 16,
          paddingTop: 10,
          paddingRight: 30,
          paddingBottom: 10,
          paddingLeft: 30,
        };
    },
  },
  logoImage: {
    height: 55,
    width: 150,
    marginTop: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    display: "block",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          //	height: 48,
          //	width: 144,
        };
    },
  },
  logoIcon: {
    width: 160,
    height: 64,
    display: "block",
    backgroundSize: "contain",
  },
  icon55150: {
    height: 55,
    width: 150,
    display: "block",
    backgroundSize: "contain",
  },
  loginHeader: {
    display: "flex",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    textAlign: "center",
    alignItems: "center",
    position: "relaive",
    boxShadow: "0px 0px 6px #00000033",
  },
  logoStyle: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  copyRightWrapper: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 16, 
  },
});
