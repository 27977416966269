import R14, { } from "../core";
import { THREAD_LIST, GET_MSG_FROM_THREAD } from "../constant/app.constant";
import DateUtils from "../utils/DateUtils";
import CommonUtility from "../utils/CommonUtility";

export default class SimpleMessageDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      simpleMessage: [],
      searchSimpleMessage: [],
      messageList: [],
    };
  }

  async getThreadList(hardReload = false) {
    const { pid } = await this.dm.rest.getUser();
    let res = await this.dm.dashboard.getAllDateTimeHistory();
    let currentDate = new Date();

    let x = new Date(
      !!res.length && !!res[0].simpleMessageLastDateTime
        ? res[0].simpleMessageLastDateTime
        : `01/01/${currentDate.getFullYear()}`
    );
    // x.setDate(x.getDate() - 1);
    x.setHours(x.getHours() - 5);
    x.toUTCString();
    let fromDate = parseInt((x.getTime() / 1000).toFixed());
    let nowDt = parseInt((currentDate.getTime() / 1000).toFixed());
    let threadMsgList = null;
    try {
      let threadsRes = await this.dm.rest.get(`${THREAD_LIST}?pagesize=1000&from=${fromDate}&to=${nowDt}`);
      if (!!threadsRes && threadsRes.threads) {
        threadMsgList = threadsRes.threads;
        await this.simpleMsgThreadIdCollection(threadsRes);
        await this.getSimpleMessages();
      }
      else if (!!hardReload) {
        await this.getSimpleMessages();
      }
    } catch (error) {
      console.log("Thread List ::: ", error);
    } finally {
      if (!!res.length && !!threadMsgList && threadMsgList.length > 0) {
        let payload = { payerId: pid };
        currentDate.toUTCString();
        payload.simpleMessageLastDateTime = currentDate;
        payload.uid = res[0].uid;
        const { dateTimeHistorys } = this.dm.dashboard.state;
        if (!!dateTimeHistorys && dateTimeHistorys.length > 0) {
          dateTimeHistorys[0].simpleMessageLastDateTime = currentDate
          this.dm.dashboard.setState({
            dateTimeHistorys: dateTimeHistorys
          });
        }
        this.dm.dashboard.updateMsgDateHistory(payload);
      }
    }
  }

  async getMessages(threadId) {
    try {
      let pid = await this.dm.dashboard.findCorrectPId(threadId)
      let res = await this.dm.queryDomain.middleware(this.api.qry(`query getMessages($threadId: String!, $payer: String!) {
        getMessages(threadId: $threadId, payer: $payer){
          success message error data
        }
      }`, { threadId: threadId, payer: pid }));
      let data = res.data.getMessages;
      if (!!data) return JSON.parse(data.data);
    } catch (error) {
    }
    return null;
  }

  async getBillerFromThread(threadId) {
    try {
      let msgDetails = await this.getMessages(threadId);
      if (!!msgDetails) {
        let msg = msgDetails.messages.sort((a, b) =>
          new Date(a.envelope.messageHeader.messageDateTime) - new Date(b.envelope.messageHeader.messageDateTime)
        )
        msgDetails.messages = msg
      }
      return msgDetails;
    } catch (error) {
      console.log("Messages  ::: ", error);
    }
  }

  async simpleMsgThreadIdCollection(res) {
    const { threads } = res;
    let calls = []
    const pids = await this.dm.rest.getLinkedPID();
    let linkedPIds = pids.split(',');
    for (let el of threads) {
      calls.push(this.syncMessages(el["thread-id"], linkedPIds));
    }
    await Promise.all(calls);
  }

  async syncMessages(threadId, linkedPIds) {
    try {
      let ele = await this.getBillerFromThread(threadId);
      if (!!ele && ele.messages[0].envelope.messageBody && ele.messages[0].envelope.threadHeader.profile.toLowerCase() === "simplemessage") {
        const msgCount = ele.messages.filter((el) => !!el.envelope.messageBody &&
          linkedPIds.find(x => x.pid === el.envelope.messageHeader.recipientPid));
        await this.addUpdateDB({ ...ele.messages[ele.messages.length - 1], currentMsgCount: msgCount.length }, "listing");
      }
    } catch (error) {
      console.log("Thread message ::: ", error);
    }
  }

  async compiledMessages(arrayOfThreads) {
    const pids = await this.dm.rest.getLinkedPID();
    let linkedPIds = pids.split(',');
    try {
      for (const ele of arrayOfThreads) {
        const { messageBody } = ele.messages[0].envelope;
        if (!!messageBody) {
          const msgCount = ele.messages.filter((el) => !!el.envelope.messageBody && linkedPIds.find(x => x.pid === el.envelope.messageHeader.recipientPid));
          await this.addUpdateDB({ ...ele.messages[ele.messages.length - 1], currentMsgCount: msgCount.length }, "listing");
        }
      }
    } catch (error) {
      console.error("Compiled Message::: ", error);
    }
  }

  async compiledMessagesNotification(threads) {
    let messagesDetails = [];
    if (threads && threads.length > 0) {
      let newThreads = [];
      let complieList = this.state.simpleMessage;
      if (!!complieList && complieList.length > 0) {
        let bills = complieList.map(x => { return x.threadId });
        newThreads = threads.filter(x => !bills.includes(x.threadId));
      }
      else
        newThreads = threads;

      if (!!newThreads && newThreads.length > 0) {
        try {
          for (let thread of newThreads) {
            let res = await this.getBillerFromThread(thread.threadId);
            if (!!res && res.messages[0].envelope.messageBody && res.messages[0].envelope.threadHeader.profile.toLowerCase() === "simplemessage") {
              messagesDetails.push(res);
            }
          }
        } catch (error) {
          console.log("Thread message ::: ", error);
        }
      }
    }
    if (!!messagesDetails && messagesDetails.length > 0) {
      await this.compiledMessages(messagesDetails);
      await this.getSimpleMessages();
    }
  }

  async displaySimpleMessage(hardReload = false) {
    await this.getThreadList(hardReload);
    const { isSearchOpen, textInSearchBox, currentOpenScreen } = this.dm.userSearchDomain.state;
    if (isSearchOpen) this.dm.userSearchDomain.keyTypeSearch(textInSearchBox, currentOpenScreen);
  }

  async getMessagePayload(data) {
    const { subject, messageBody, attachments } = data;
    let datetime = DateUtils.isoFormateDate();
    let senderName = await this.dm.billDetails.getSenderName({
      buId: data.buId,
      billerRef: data.billerRef,
      payerRef: data.payerRef,
      payerId: data.payerId
    });

    let uniqueId = CommonUtility.generate20Character();
    return {
      envelope: {
        threadHeader: {
          subject,
          profile: "simpleMessage",
          threadId: `${data.payerId}-${data.billerId}-${uniqueId}`,
          originatorPid: data.payerId,
          respondentPid: data.billerId,
          threadDateTime: datetime,
          threadPriority: "high",
          accountNumber: "0",
          isExternalCustomer: false,
        },
        messageHeader: {
          senderName: senderName,
          senderPid: data.payerId,
          recipientPid: data.billerId,
          buId: data.buId,
          senderPIDRef: data.payerRef,
          recipientPIDRef: data.billerRef,
          messageId: uniqueId,
          messageDateTime: datetime,
        },
        messageBody: {
          messageBody,
          lastMessage: messageBody,
          lastMessageDate: datetime
        },
      },
      attachments,
      messageMeta: {
        numMessages: 1,
        deliveryPath: {
          fromID: datetime,
          timestamp: datetime,
          toID: datetime,
          signature: "",
        },
      },
    };
  }

  async postSimpleMessage(payload) {
    try {
      let pid = await this.dm.dashboard.findCorrectPId(payload.envelope.threadHeader.threadId);
      return await this.dm.rest.post(`${GET_MSG_FROM_THREAD}`, payload, { pid: pid });
    } catch (err) {
      console.log("Simple Message :::: ", err);
    }
  }

  async replySimpleMessage(payload) {
    const { messageHeader, threadHeader } = payload.envelope;
    let newDatetime = DateUtils.isoFormateDate();
    const pids = await this.dm.rest.getLinkedPID();
    let linkedPids = pids.split(',');

    let senderName = await this.dm.billDetails.getSenderName({
      buId: messageHeader.buId,
      payerRef: linkedPids.find(x => x === threadHeader.respondentPid) ? messageHeader.recipientPIDRef : messageHeader.senderPIDRef,
      billerRef: linkedPids.find(x => x === threadHeader.respondentPid) ? messageHeader.senderPIDRef : messageHeader.recipientPIDRef,
      payerId: linkedPids.find(x => x === threadHeader.respondentPid) ? messageHeader.recipientPid : messageHeader.senderPid

    });
    let newPayload = {
      envelope: {
        ...payload.envelope,
        messageHeader: {
          ...messageHeader,
          messageId: CommonUtility.generate20Character(),
          messageDateTime: newDatetime,
          senderName: senderName,
          senderPid: linkedPids.find(x => x === threadHeader.respondentPid) ? messageHeader.recipientPid : messageHeader.senderPid,
          recipientPid: linkedPids.find(x => x === threadHeader.respondentPid) ? messageHeader.senderPid : messageHeader.recipientPid,
          senderPIDRef: linkedPids.find(x => x === threadHeader.respondentPid) ? messageHeader.recipientPIDRef : messageHeader.senderPIDRef,
          recipientPIDRef: linkedPids.find(x => x === threadHeader.respondentPid) ? messageHeader.senderPIDRef : messageHeader.recipientPIDRef,
        }
      },
      messageMeta: payload.messageMeta,
      attachments: payload.attachments
    };
    return newPayload;
  }

  async getMsgPayloadr14(data, currentMsgCount) {
    try {
      const { pid } = await this.dm.rest.getUser();
      const pids = await this.dm.rest.getLinkedPID();
      let linkedPIds = pids.split(',');
      const { envelope } = data;
      const biller = await this.dm.rest.getBillers({
        buId: envelope.messageHeader.buId,
        payerRef: linkedPIds.find(x => x === envelope.threadHeader.respondentPid) ? envelope.messageHeader.recipientPIDRef : envelope.messageHeader.senderPIDRef,
        billerRef: linkedPIds.find(x => x === envelope.threadHeader.respondentPid) ? envelope.messageHeader.senderPIDRef : envelope.messageHeader.recipientPIDRef
      });

      return {
        subject: envelope.threadHeader.subject,
        threadId: envelope.threadHeader.threadId,
        originator: envelope.threadHeader.originatorPid,
        billerId: biller.billerId,
        billerName: biller.billerName,
        currentMsgCount: !!currentMsgCount ? currentMsgCount : 0,
        readMsgCount: 0,
        lastMessage: envelope.messageBody.messageBody,
        lastMessageDate: envelope.messageHeader.messageDateTime,
        payerId: biller.payerId,
        actionBy: pid,
        billerUid: biller.uid
      };

    } catch (error) {

    }
  }

  async saveMessage(
    input,
    fields = "uid threadId originator subject billerId billerName billerUid currentMsgCount readMsgCount lastMessage lastMessageDate payerId"
  ) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
      mutation CreateSimpleMessage($input: CreateSimpleMessageInput) {
        createSimpleMessage(input: $input){
          simpleMessage {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
      let { simpleMessage } = this.state;
      let message = res.data.createSimpleMessage.simpleMessage;
      if (!simpleMessage || simpleMessage.length === 0) {
        await this.dm.simpleMessage.getSimpleMessages();
      }
      else {
        let simpleMessage = this.state.simpleMessage;

        if (!!simpleMessage) {
          simpleMessage.unshift(message);
          this.setState({
            simpleMessage: simpleMessage
          })
        }
      }
      return message;
    } catch (error) {
      return error;
    }
  }

  async updateMessage(
    input,
    fields = "uid threadId originator subject billerId billerName billerUid currentMsgCount readMsgCount lastMessage lastMessageDate payerId"
  ) {
    delete input.biller;
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
      mutation UpdateSimpleMessage($input: UpdateSimpleMessageInput) {
        updateSimpleMessage(input: $input){
          simpleMessage {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
      return res.data.updateSimpleMessage.simpleMessage;
    } catch (error) {
      return error;
    }
  }

  hasOnlyMessageBody(arrayOfThreads) {
    try {
      return arrayOfThreads.filter((ele) => ele.envelope.messageBody);
    } catch (error) {
      console.error("hasOnlyMessageBody Message::: ", error);
    }
  }

  async updateReadUnreadMsgCount(input) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `
      mutation UpdateMessageCount($input: SimpleMsgInput) {
        updateMessageCount(input: $input){
          success,
          threadId
        }
      }`,
        { input }
      ));
      return res.data.updateMessageCount.simpleMessage;
    } catch (error) {
      return error;
    }
  }

  async getSimpleMessages(
    fields = "uid threadId payerId originator billerId billerName billerUid subject lastMessage lastMessageDate currentMsgCount readMsgCount"
  ) {
    const pids = await this.dm.rest.getLinkedPID();
    let pidBUs = await this.dm.rest.getLinkedPIDBU();
    let linkedPids = pids.split(',');
    let options = {
      filter: { payerId: { in: linkedPids } },
      sort: [{ field: "lastMessageDate", order: "DESC" }]
    };
    try {
      let res = await this.api.qry(
        `query SimpleMessages($filter: SimpleMessageFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
        simpleMessages(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
        options
      );
      let results = res.data.simpleMessages.nodes;
      let list = await this.dm.rest.getBillers();
      for (let i = 0; i < results.length; i++) {
        // eslint-disable-next-line no-loop-func
        let biller = list.find(x => x.uid === results[i].billerUid);
        results[i].biller = biller;
      }
      results = results.filter((el) => {
        return pidBUs.some((f) => {
          return ((!!el.biller && f.buId === el.biller.buId && f.pid === el.payerId && f.billerId === el.biller.billerId) || !f.buId);
        });
      });
            
      this.setState({ simpleMessage: results, searchSimpleMessage: results });
    } catch (error) {
      return error;
    }
  }

  async getMessageById(
    threadId,
    fields = "uid threadId originator billerId billerName billerUid subject lastMessage lastMessageDate currentMsgCount readMsgCount"
  ) {
    const { simpleMessage } = this.state;

    if (!!simpleMessage && !!simpleMessage.length > 0) {
      let result = simpleMessage.filter(x => x.threadId === threadId);
      let list = await this.dm.rest.getBillers();
      for (let i = 0; i < result.length; i++) {
        let biller = list.find(x => x.uid === result[i].billerUid);
        result[i].biller = biller;
      }
      return result;
    }
    else {
      let options = {
        filter: { threadId: { eq: threadId } },
      };
      try {
        let res = await this.api.qry(
          `query SimpleMessages($filter: SimpleMessageFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
        simpleMessages(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
          options
        );
        return res.data.simpleMessages.nodes;
      } catch (error) {
        return error;
      }
    }
  }

  async getLinkPIDSuggestions() {
    const profile = await this.dm.rest.getUser();

    let options = {
      email: profile.email
    };
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetLinkPIDSuggestions($email: String!){
          getLinkPIDSuggestions(email: $email ) 
        }`,
        options
      ));

      if (!!res.data.getLinkPIDSuggestions) return JSON.parse(res.data.getLinkPIDSuggestions);
      return res.data.getLinkPIDSuggestions;
    } catch (error) {
      return error;
    }
  }

  async postLinkPayerPID(payerPID = [], primaryPID) {
    const profile = await this.dm.rest.getUser();
    const payerPIDList = payerPID.map(pid => {
      return {buid: pid.buId, billerPID: pid.billerPID, payerPID: pid.payerPID, otp: pid.otp}
    });
    let input = {
      emailID: primaryPID.email,
      mobileNumber: CommonUtility.MobileFormatText(profile.mobileNumber),
      payerPIDList: payerPIDList
    };
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `mutation LinkPayerPID($input: LinkPayerInput!)  {
            linkPayerPID(input: $input)
            {
             success message
            }
         }`,
        { input }
      ));

      return res.data.linkPayerPID;
    } catch (error) {
      return error;
    }
  }

  async linkPayerOTP(payerPID = [], primaryPID) {
      const profile = await this.dm.rest.getUser();
      const payerPIDList = payerPID.map(pid => {
        return {buid: pid.buId, billerPID: pid.billerPID, payerPID: pid.payerPID}
      });
      let input = {
        emailID: primaryPID.email,
        mobileNumber: profile.mobileNumber,
        payerPIDList: payerPIDList
      };
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `mutation linkPayerOTP($input: LinkPayerInput!)  {
            linkPayerOTP(input: $input){
              success message
            }
         }`,
        { input }
      ));

      return res.data.linkPayerOTP;
    } catch (error) {
      return error;
    }
  }

  async addUpdateDB(data, source) {
    try {
      const { threadHeader, messageHeader, messageBody } = data.envelope;
      let hasThread = await this.getMessageById(threadHeader.threadId);
      if (!!hasThread.length) {
        const { pid } = await this.dm.rest.getUser();
        const pids = await this.dm.rest.getLinkedPID();
        let linkedPIds = pids.split(',');
        const biller = await this.dm.rest.getBillers({
          buId: messageHeader.buId,
          payerRef: linkedPIds.find(x => x === threadHeader.respondentPid) ? messageHeader.recipientPIDRef : messageHeader.senderPIDRef,
          billerRef: linkedPIds.find(x => x === threadHeader.respondentPid) ? messageHeader.senderPIDRef : messageHeader.recipientPIDRef
        });

        let updatePayload = {
          lastMessage: messageBody.messageBody,
          lastMessageDate: messageHeader.messageDateTime,
          uid: hasThread[0].uid,
          billerName: biller.billerName,
          billerId: biller.billerId,
          billerUid: biller.uid,
          currentMsgCount: hasThread[0].currentMsgCount,
          readMsgCount: hasThread[0].readMsgCount,
          actionBy: pid,
          payerId: biller.payerId
        };
        if (source === "listing") {
          updatePayload.currentMsgCount = data.currentMsgCount;
        } else {
          updatePayload.readMsgCount = data.readMsgCount;
        }
        return await this.updateMessage(updatePayload);
      } else {
        let payload = await this.getMsgPayloadr14(data, data.currentMsgCount);
        if(!!payload)
          await this.saveMessage(payload);

      }
    } catch (error) {

    }
  }

}
