import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from "../constant/app.constant";

const debugMode = process.env.NODE_ENV === "development" ||  !process.env.NODE_ENV;
mixpanel.init(MIXPANEL_TOKEN, {debug: !!debugMode, track_pageview: false, persistence: 'localStorage'});

/**
 * groupName = String
 * trackingEvent = {} // any
 */
const mixPanelEvent = (groupName, trackingEvent = {}) => {
    // Set this to a unique identifier for the user performing the event.
    mixpanel.identify(!!trackingEvent.pid ? trackingEvent.pid : `${Math.floor(Math.random() * 100000000)}`)
    // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
    mixpanel.track(groupName, trackingEvent);
    return true;
};

export {
  mixPanelEvent
};
