import React from "react";
import R14 from "../core"; 
import LinkPayerComponent from "../components/LinkPayerComponent";

export default R14.connect(
  class LinkPayerScreen extends React.Component {
    render() {
      return (
          <LinkPayerComponent />
      );
    }
  }
); 
