import React from "react";
import R14, {
  StyleSheet,
  View,
  Form,
  SubmitButton,
  TextInputField,
  Text,
  Platform,
  Button
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../../assets/css/commonMetricsStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import getIpAddress from "../../utils/GetIPAddress";
import CommonValidators from "../../utils/CommonValidators";
import RadioButtons from "../RadioButtons";
import { CommonString } from "../../constant/appCommonText";

export default R14.connect(
  class ACHCreateForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedAccountHolder: "individual",
        selectedAccountType: "savings",
        accountHolderError: false,
        accountTypeError: false,
      }
    }

    handleCloseModel = () => {
      this.props.handleCreateForm();
    }

    createSetupIntent = async (cpForm) => {
      try {
        if (!this.state.selectedAccountHolder && !this.state.selectedAccountType) {
          this.setState({
            accountTypeError: true,
            accountHolderError: true,
          });
          return;
        } else if (!this.state.selectedAccountHolder) {
          this.setState({
            accountTypeError: false,
            accountHolderError: true,
          });
          return;
        } else if (!this.state.selectedAccountType) {
          this.setState({
            accountTypeError: true,
            accountHolderError: false,
          });
          return;
        } else {
          this.setState({
            accountTypeError: false,
            accountHolderError: false,
          })
        }
        this.props.showLoading(true);
        const ipAddress = await getIpAddress();
        const payload = {
          //   email: 'amit.rathee@exelaonline.com',
          email: this.props.email,
          name: this.props.payerName,

          accountHolderType: this.state.selectedAccountHolder,
          accountType: this.state.selectedAccountType,
          routingNumber: cpForm.elmts.routingNumber.value,
          bankName: cpForm.elmts.bankName.value,
          accountNumber: cpForm.elmts.accountNumber.value,

          ipAddress: ipAddress,
          userAgent: Platform.OS,

          metaData: {
            pid: this.props.payerId,
            billerPid: this.props.billerId,
            billerName: this.props.billerName,
            buId: this.props.buId,
            buName: !!this.props.buName? this.props.buName: this.props.buId,
          },
        };
        const response = await this.props.app.dm.achPaymentDomain.createSetupIntent(payload);
        // Toster message to check your mail to verify account
        if (response && response.createSetupIntent && response.createSetupIntent.payerBankAccount) {
          this.props.handleCreateForm({
            intent: response.createSetupIntent.payerBankAccount,
          })
        } else {
            this.props.showLoading(false);
            this.props.app.dm.commonDomain.showToastr("error", response.createSetupIntent && response.createSetupIntent.message ? response.createSetupIntent.message : CommonString.SomethingWentWrong);
        }
        return false;
      } catch (error) {
        this.props.showLoading(false);
        this.props.app.dm.commonDomain.showToastr("error", error);
      }
    }

    onAccountHolderChange = (val) => {
      this.setState({
        selectedAccountHolder: val,
      });
    };

    onAccountTypeChange = (val) => {
      this.setState({
        selectedAccountType: val,
      });
    };

    render() {
      const accountHolderTypes = [{ label: "Company", value: "company" }, { label: "Individual", value: "individual" }];
      const accountTypes = [{ label: "Checking", value: "checking" }, { label: "Savings", value: "savings" }];
      return (
        <View style={[styles.mainContainer, this.props.isDisplayNone && commonStyles.displayNone]}>
          <View style={commonStyles.textAlignCenter}>
          <Text style={[commonStyles.marginLeft15, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.textAlignCenter]}>Add Bank</Text>
          </View>
            <Form
              name="ACHCreateForm"
              style={[styles.createMessageForm, commonStyles.justifyContentFlexStart]}
              validators={CommonValidators.getFormValidator()}
              validateBeforeSubmit={true}
              onSubmit={this.createSetupIntent}>
              <View style={[styles.leftInputField]}>
                <TextInputField
                  name="bankName"
                  label={`${CommonString.BankNameLabel}*`}
                  validator="min2Max50"
                  required={CommonString.EnterBankNameRequired}
                  style={[commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.smallHeading, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.smallHeading]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonMetricsStyle.smallHeading,commonStyles.exSmallHeading,styles.left0]}
                />
              </View>
              <View style={[styles.leftInputField, commonStyles.flexDirectionColumn]}>
                <View style={[styles.accountTypeContainer, customCommonStyles.accountTypeContainerHeight]}>
                  <View style={[styles.accountTypeLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans]} >Account Holder Type*</Text>
                  </View>
                  {accountHolderTypes && accountHolderTypes.map((el, key) => (
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                      <RadioButtons
                        radioButtonsClass={styles.radioButtonsOuter}
                        touchableHolder={[commonStyles.flexBasis50]}
                        key={"actHolderType_" + key}
                        options={el}
                        onValueChange={this.onAccountHolderChange}
                        isSelected={this.state.selectedAccountHolder}
                      />
                    </View>
                  ))}
                </View>
              </View>
              {this.state.accountHolderError ? (
                <View style={[styles.accountTypeErrorLabel]}>
                  <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >Select Account Holder Type</Text>
                </View>
              ) : null}
              <View style={[styles.leftInputField]}>
                <View style={[styles.accountTypeContainer, customCommonStyles.accountTypeContainerHeight]}>
                  <View style={[styles.accountTypeLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans]} >Account Type*</Text>
                  </View>
                  {accountTypes && accountTypes.map((el, key) => (
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                      <RadioButtons
                        radioButtonsClass={styles.radioButtonsOuter}
                        touchableHolder ={commonStyles.flexBasis50}
                        key={"actType_" + key}
                        options={el}
                        onValueChange={this.onAccountTypeChange}
                        isSelected={this.state.selectedAccountType}
                      />
                    </View>
                  ))}
                </View>
                {this.state.accountTypeError ? (
                  <View style={[styles.accountTypeErrorLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >Select Account Type</Text>
                  </View>
                ) : null}
              </View>
              <View style={[commonStyles.flex1,]}>
                <View style={[styles.leftInputField]}>
                  <TextInputField
                    name="routingNumber"
                    label={`${CommonString.RoutingNoLabel}*`}
                    validator="min6Max50Digits"
                    required={CommonString.EnterRoutingNoRequired}
                    style={[commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.smallHeading, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.smallHeading]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonMetricsStyle.smallHeading,commonStyles.exSmallHeading, styles.left0]}
                  />
                </View>
                <View style={[styles.leftInputField]}>
                  <TextInputField
                    name="accountNumber"
                    label={`${CommonString.AccountNoLabel}*`}
                    validator="min6Max50Digits"
                    required={CommonString.EnterAccountNoRequired}
                    style={[commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.smallHeading, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.smallHeading]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonMetricsStyle.smallHeading,commonStyles.exSmallHeading, styles.left0]}
                  />
                </View>
              </View>
              {/* Back & Add button */}
              <View style={[styles.buttonContainter, Platform.OS === "web" ? styles.mViewButtonContainter : null]} >
                <View style={[commonStyles.marginRight15]}>
                  <Button
                    title={"Back"}
                    key="ACHBackFormButton"
                    style={[styles.activeButton, styles.border2, commonStyles.whiteBackgroundColor, customCommonStyles.noBoxShadow]}
                    titleTextStyle={[commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6, commonStyles.blueBorderButtonColor]}
                    onPress={this.handleCloseModel}
                  />
                </View>
                <View>
                  <SubmitButton
                    title={"Add"}
                    key="ACHCreateFormButton"
                    style={[styles.activeButton, styles.border1, styles.activeButtonPadding, customCommonStyles.noBoxShadow]}
                    titleTextStyle={[commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6, commonStyles.whiteColor]}
                  />
                </View>
              </View>
          </Form>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  mediumModalBodyWrapper: {
    width: 800
  },
  headerText: {
    marginLeft: 20
  },
  creatingMessageScreenInnerWrap: {
    backgroundColor: "#fff",
    // marginTop: 16,
    // marginRight: 16,
    marginBottom: 16,
    // marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  },
  createMessageForm: {
    paddingTop: 24,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  leftInputField: {
    maxWidth: "100%",
    // marginRight: 20,
    maxHeight: 60, 
    minHeight: 60,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // maxWidth: "100%",
          // flexBasis: "100%",
          marginRight: 0,
          marginBottom: 0,
        };
    },
  },
  modelPopupButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flexBasis: "50%",
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }
      } else {
        return {
          marginRight: 16
        }
      }
    }
  },
  activeButton: {
    backgroundColor: "#d71996",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
    justifyContent: 'center',
    height: 24,
    // paddingTop: 15,
    // paddingBottom: 15,
    width: 100,
    textAlign: 'center'
  },
  border1:{
    borderWidth: 1,
    borderStyle: "solid",
    borderColor:"d71996"
  },
  border2:{
    borderWidth: 2,
    borderStyle: "solid",
    borderColor:"rgb(18, 119, 217)"
  },
  buttonContainter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: "100%",
    maxHeight: 34, 
  },
  mViewButtonContainter:{
      screen: ({ width, height }) => {
      if (width === 375 && height === 553)
        return {
          marginBottom: 56,
        };
    },
  },
  activeButtonPadding: {
    paddingLeft: 15,
    paddingRight: 15
  },
  backAndAddContainter: {
    flex: 1,
    flexDirection: 'row'
  },
  accountTypeContainer: {
    flex: 1,
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'rgba(21, 27, 38, 0.4)',
    borderStyle: 'solid',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 16,
    paddingRight: 16,
    position: 'relative',
    maxHeight: 35,
    minHeight: 35,
  },
  radioButtonsOuter: {
    marginRight: 5
  },
  maxWidth50: {
    maxWidth: "50%",
  },
  accountTypeLabel: {
    position: 'absolute',
    top: -11,
    left: 8,
    backgroundColor: 'white',
    paddingLeft: 5,
    paddingRight: 5
  },
  accountTypeErrorLabel: {
    marginLeft: 10,
    marginTop: 2
  },
  left0: {
    left: 0
  }
});