import R14, { } from "../core";
import CommonUtility from "../utils/CommonUtility";
import { UPDATE_TEXT_TO_PAY_PREFERENCE } from "../constant/app.constant";

export default class MyPreferenceDomain extends R14.Domain {
  async savePreference(preferenceList) {
    let user = await this.dm.rest.getUser();
    let data = JSON.stringify(preferenceList);
    let payload = {
      payerId: user.pid,
      setPreferenceList: data,
    };
    await CommonUtility.htmlDecode(payload);

    try {
      let res = await this.api.mutate(
        `mutation CreateMyPreference($input: CreateMyPreferenceInput) {
                createMyPreference(input: $input){       
                    myPreference{
                        payerId setPreferenceList
                    }              
                }            
            }`,
        {
          input: payload,
        }
      );
      return res.data.createMyPreference.myPreference;
    } catch (error) {
      return error;
    }
  }
  async getMyPreference() {
    let user = await this.dm.rest.getUser();

    try {
      let res = await this.api.query(
        `query myPreferences($filter: MyPreferenceFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
                myPreferences(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){       
                    nodes{
                        uid payerId setPreferenceList
                    }              
                }            
            }`,
        {
          filter: {
            payerId: { eq: user.pid },
          },
        }
      );
      return res.data.myPreferences.nodes;
    } catch (error) {
      return error;
    }
  }
  async updatePreference(preferenceList, uid, payerId) {
    let data = JSON.stringify(preferenceList);
    let payload = {
      payerId: payerId,
      setPreferenceList: data,
      uid: uid,
    };
    await CommonUtility.htmlDecode(payload);
    try {
      let res = await this.api.mutate(
        `mutation UpdateMyPreference($input: UpdateMyPreferenceInput) {
                updateMyPreference(input: $input){       
                    myPreference{
                        uid payerId setPreferenceList
                    }              
                }            
            }`,
        {
          input: payload,
        }
      );
      return res.data.updateMyPreference.myPreference;
    } catch (error) {
      return error;
    }
  }

  /**
   * Update Payer notification preference (text-to-pay) 
   * @param {"textToPayPreference": "Both/None/Email/SMS"} preferenceData 
   * @param {"123456789"} pamId
   * @param {"ghjk"} uid
   */
  async updateTextToPayPreference(pam, preferenceData) {
    try {
      await CommonUtility.htmlDecode(preferenceData);
      await this.dm.rest.patch(`${UPDATE_TEXT_TO_PAY_PREFERENCE}/${pam.pamId}`, preferenceData, { pid: !!pam.payerId ? pam.payerId : pam.biller.payerId });
      await this.dm.billerDirectoryDomain.updatePamMessages({ textToPayPreference: preferenceData.textToPayPreference, uid: pam.uid });
      return true;
    } catch (error) {
      return error;
    }
  }

}
