import React, { Component } from "react";
import R14, { StyleSheet, View, Text, Platform, ActivityIndicator, Image, Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { CURRENCY, BILL_STATUS } from "../constant/app.data.constant";
import Bookmarked from "./Bookmared";
import StatusComponent from "./StatusComponent";
import CommonUtility from "../utils/CommonUtility";
import DateUtils from "../utils/DateUtils";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
// import { BILL_STATUS, RTP_STATUS } from "../constant/app.data.constant";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import HtmlDeCode from "../utils/HtmlDecode";
import TooltipComponent from "./TooltipComponent";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";

const delinedIcon = require("../assets/images/delined.png");
const downloadIcon = require("../assets/images/downloadBlueIcon.png");

export default R14.connect(
  class BillerDetails extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        billDetails: this.props.billDetails,
      };
    }
    fullMessageWindow = () => {
      let newThread = this.props.billDetails.threadId; //.replaceAll("#", ">>");
      this.props.app.nav.to("messageScreenRoute", { threadId: newThread });
    };

    toggleBookmark = async (el) => {
      el.bookmark = !el.bookmark;
      let res = await this.props.app.dm.dashboard.addUpdateBookmark(el);
      if (res.uid && this.props.onBkmkToggle) {
        this.props.onBkmkToggle(res.bookmark);
      }
    };

    downloadFile = async (billData) => {
      const { threadId, biller, endToEndIdentification } = billData;
      let fileName = `${biller.billerName}_${threadId}.pdf`;
      this.setState({
        isLoading: true,
      });
      try {
        let base64Format =  await this.props.app.dm.billDetails.getInvoiceData(endToEndIdentification);
        if (!!base64Format && 'success' in base64Format && base64Format.success ===true) {
          const fileObj = {
            content: `${Platform.OS === 'web' ? 'data:application/octet-stream;base64,' : ''}${(base64Format && base64Format.data) ? base64Format.data : base64Format}`,
            contentType: fileName.split('.')[1] || "pdf",
            filename: fileName
          };
          HtmlDeCode.downloadFile(fileObj);
          analyticsLogs(CommonString.RTP_BILL_DETAILS, CommonString.ATTACHMENT_DOWNLOADED, this.props.app);
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.NoBillDocument);
        }
      } catch (err) {
        this.setState({
          isLoading: false,
        });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
    };

    webView = (payableAmt, maxlimit, paidAmount) => {
      const { billData, customStyles, customFlexDirection } = this.props;
      let currentRoute = this.props.r14.navigation.activeRouteName;
      return (
        <View style={[styles.billerDetails, customStyles]}>
          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.billerDetailsRow, commonStyles.marB0, styles.payPageBillerDetailsRow]}>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart, commonStyles.marginBottom16,]}>
              <View style={[styles.webViewUserIconDiv, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
                <Text
                  style={[
                    commonStyles.heading6,
                    commonStyles.fontWeight700,
                    commonFontStyles.workSans,
                    commonStyles.userInitial,
                    styles.userInitial,
                  ]}>
                  {CommonUtility.shortName(billData.biller.billerName)}
                </Text>
              </View>
              <View>
                {/* <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight500, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Biller Name
                </Text> */}
                <View style={[commonStyles.flex1]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.transformCapitalize, commonStyles.wordBreakWord]}>
                    {billData.biller.billerName}
                  </Text>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.transformCapitalize, commonStyles.wordBreakWord]}>
                    {billData.biller.buName ? billData.biller.buName : billData.biller.buId }
                  </Text>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.transformCapitalize, commonStyles.wordBreakWord]}>
                    {billData.subject}
                  </Text>
                  <View style={[styles.fullWidth, commonStyles.MarginTop10]}>
                    <Text style={styles.cardSmlHeadingText}>{DateUtils.dateAndTimeWithAmPm(billData.threadDateTime)}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={[
                commonStyles.flex1,
                // commonStyles.flexBasis50,
                // commonStyles.alignItemsEnd,
                commonStyles.flexDirectionRow,
                // commonStyles.alignItemsCenter,
                commonStyles.justifyContentFlexEnd,
                styles.minWidth115
              ]}>
              {/* <StatusComponent
                // marginRight={styles.customeMargin}
                marginRight={commonStyles.margin0}
                label={billData.billStatus}
                isSelected={false}
                paymentStatusLabel={billData.paymentStatus}
                style={[styles.noBackground, styles.customeMargin]}
              /> */}
              {!BILL_STATUS[billData.billStatus] && !billData.biller.isBlocked ? (
                <View>
                  <Bookmarked onToggle={() => this.toggleBookmark(billData)} isBookmarked={billData.bookmark} bookedMarkStyle={commonStyles.flexGrow0} />
                </View>
              ) : null}
              <View style={styles.statusSection}>
                {billData.billStatus === 'Failed' ? (
                  <TooltipComponent
                    verificationTip={true}
                    verificationTipCss={[styles.tooltipStyles]}
                    verificationStatus={'FAILED'}
                    toolTipMessage={billData.failReason}
                    priorityInfoTooltipStyle={styles.priorityInfoTooltip}
                    smallPriorityInfoTooltipStyle={styles.smallPriorityInfoTooltip}
                    bigPriorityInfoTooltipStyle={styles.bigPriorityInfoTooltip}
                    toolTipMessageCss={[customCommonStyles.toolTipMessageSignupCss, styles.toolTipMessageStyle]}
                    // priorityInfoTooltipStyle={styles.toolTipMessageSignupStyle}
                    arrowHolderStyle={styles.arrowHolderStyle}
                  />
                ) : (
                    <StatusComponent
                      marginRight={[commonStyles.margin0, commonStyles.paddingTop0, commonStyles.marginLeft15]}
                      label={billData.billStatus}
                      isSelected={false}
                      paymentStatusLabel={billData.paymentStatus}
                    />
                  )}
              </View>
            </View>
          </View>
          <View style={[commonStyles.flex1, styles.billerDetailsRow, currentRoute === "billDetailsRoute" ? null : styles.payPageBillerDetailsRow,]}>
            <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
              <View style={[styles.paymentPageBillDetailsLabels]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Account Number
                </Text>
              </View>
              <View style={[styles.paymentPageBillDetailsValue]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.transformCapitalize]}>
                  {!!billData.accountNumber ? billData.accountNumber : "N/A"}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
              <View style={[styles.paymentPageBillDetailsLabels]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Reference ID
                </Text>
              </View>
              <View style={[styles.paymentPageBillDetailsValue]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.transformCapitalize]}>
                  {(!!billData.endToEndIdentification && billData.endToEndIdentification.length > maxlimit) ?
                    (billData.endToEndIdentification.substring(0, maxlimit - 3)) + '...' : billData.endToEndIdentification}
                </Text>
              </View>
            </View>
          </View>
          <View style={[commonStyles.flex1, styles.billerDetailsRow, currentRoute === "billDetailsRoute" ? null : styles.payPageBillerDetailsRow,]}>
            <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
              <View style={[styles.paymentPageBillDetailsLabels]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Bill Period
                </Text>
              </View>
              <View style={[styles.paymentPageBillDetailsValue]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                  {billData.billingPeriod}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
              <View style={[styles.paymentPageBillDetailsLabels]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Received Date
                </Text>
              </View>
              <View style={[styles.paymentPageBillDetailsValue]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                  {DateUtils.dateAndTimeWithAmPm(billData.threadDateTime)}
                </Text>
              </View>
            </View>
          </View>
          <View style={[commonStyles.flex1, styles.billerDetailsRow, currentRoute === "billDetailsRoute" ? null : styles.payPageBillerDetailsRow,]}>
            <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
              <View style={[styles.paymentPageBillDetailsLabels]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Due On
                </Text>
              </View>
              <View style={[styles.paymentPageBillDetailsValue]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                  {DateUtils.formatDate(billData.dueDate)}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
              <View style={[styles.paymentPageBillDetailsLabels]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Amount Payable
                </Text>
              </View>
              <View style={[styles.paymentPageBillDetailsValue]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.transformCapitalize]}>
                  {/* {CURRENCY[billData.currency]} */}
                  {CURRENCY[billData.currency]}{billData.billStatus === "Paid" ? "0.00" : payableAmt.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
          <View style={[commonStyles.flex1, styles.billerDetailsRow, currentRoute === "billDetailsRoute" ? null : styles.payPageBillerDetailsRow,]}>
            <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
              <View style={[styles.paymentPageBillDetailsLabels]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Initial Amount
                </Text>
              </View>
              <View style={[styles.paymentPageBillDetailsValue]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.transformCapitalize]}>
                  {CURRENCY[billData.currency]}{billData.amount}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
              <View style={[styles.paymentPageBillDetailsLabels]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails]}>
                  Paid Amount
                </Text>
              </View>
              <View style={[styles.paymentPageBillDetailsValue]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.transformCapitalize]}>
                  {CURRENCY.USD}{paidAmount}
                </Text>
              </View>
            </View>
          </View>
          {!billData.biller.isBlocked && !!billData.threadHeader && !!billData.threadHeader.isExternalCustomer &&
            <View style={[commonStyles.flex1, styles.billerDetailsRow, currentRoute === "billDetailsRoute" ? null : styles.payPageBillerDetailsRow,]}>
              <View style={[commonStyles.flex1, commonStyles.paddingRight1, customFlexDirection, styles.customWidthWebView]}>
                <Touchable onPress={() => this.downloadFile(billData)} style={[styles.downloadSectionTouchable,]}>
                  <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.flexDirectionRow, styles.downloadSection]}>
                    <Image source={downloadIcon} style={[commonStyles.icon20, commonStyles.marginRight10]} />
                    <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight600, commonStyles.transformCapitalize, commonStyles.labelDetails, styles.downloadBillText]}>
                      Download Bill
                  </Text>
                  </View>
                </Touchable>
              </View>
              <View style={[commonStyles.flex1, customFlexDirection]}>
              
              </View>
            </View>}
        </View>
      );
    };

    render() {
      const { billData, padding2} = this.props;
      let payableAmt = parseFloat(billData.amountDue);
      let paidAmount = parseFloat(billData.amountPaid).toFixed(2);
      let maxlimit = 21;
      return (
        <>
          <View style={styles.container}>
            <View style={styles.mView}>
              <View style={[commonStyles.flex1, commonStyles.marginBottom5]}>
                <View style={[Platform.OS === 'ios' ? commonStyles.dFlex : commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween]}>
                  <View style={[styles.fullWidth]}>
                  <View style={[styles.billerNameSection]}>
                    <Text
                      style={[
                        styles.cardHeadingText,
                        commonFontStyles.workSans,
                        commonStyles.heading5,
                        commonStyles.fontWeight500,
                        commonStyles.transformCapitalize,
                        commonStyles.wordBreakWord,
                      ]}
                    >
                      {billData.biller.billerName}
                    </Text>
                  </View>
                  <View>
                    <Text style={[styles.cardSubHeadingText,
                        commonStyles.fontWeight500,
                        commonFontStyles.workSans,
                        commonStyles.margin0,
                        commonStyles.wordBreakWord,
                        commonStyles.marginRight10,]}>
                        {billData.biller.buName ? billData.biller.buName : billData.biller.buId }
                    </Text>
                  </View>
                  <View style={[commonStyles.marginBottom10]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonStyles.fontWeight500,
                        commonFontStyles.workSans,
                        commonStyles.margin0,
                        commonStyles.wordBreakWord,
                        commonStyles.marginRight10,
                      ]}>
                    {billData.subject}
                    </Text>
                  </View>
                  </View>
                  </View>
                <View>
                  {/* <View> */}
                    <View style={[Platform.OS === 'ios' ?
                      [commonStyles.dFlex, commonStyles.flexDirectionRow] : [commonStyles.flex1, commonStyles.flexDirectionRow,],
                      commonStyles.justifyContentSpaceBetween, styles.dateRowSpacing
                    ]}>
                      <View style={[styles.billDetailsHeaderLeftSection]}>
                        <Text style={styles.cardSmlHeadingText}>{DateUtils.dateAndTimeWithAmPm(billData.threadDateTime)}</Text>
                      </View>
                      <View style={[Platform.OS === 'ios' ?
                        [commonStyles.dFlex, commonStyles.flexDirectionRow] : [commonStyles.flex1, commonStyles.flexDirectionRow,],
                        commonStyles.alignItemsStart, commonStyles.justifyContentFlexEnd,
                        Platform.OS === 'ios' ? styles.minWidth40 : null,
                      ]}>
                        {!BILL_STATUS[billData.billStatus] && !billData.biller.isBlocked ? (
                          <View style={[Platform.OS !== "web" && billData.billStatus === 'Failed' ? commonStyles.marginRight15 : null]}>
                            <Bookmarked onToggle={() => this.toggleBookmark(billData)} isBookmarked={billData.bookmark} bookedMarkStyle={commonStyles.flexGrow0} />
                          </View>
                        ) : null}
                        {billData.biller.isBlocked ? <Image source={delinedIcon} resizeMode="contain" style={[styles.decline]} /> : null}

                        {billData.billStatus === 'Failed' ? (
                          <TooltipComponent
                            verificationTip={true}
                            verificationTipCss={[styles.tooltipStyles]}
                            verificationStatus={'FAILED'}
                            toolTipMessage={billData.failReason}
                            priorityInfoTooltipStyle={styles.priorityInfoTooltip}
                            smallPriorityInfoTooltipStyle={styles.smallPriorityInfoTooltip}
                            bigPriorityInfoTooltipStyle={styles.bigPriorityInfoTooltip}
                            justifyContentEnd={commonStyles.paddingTop0}
                          />
                        ) : (
                            <StatusComponent
                              marginRight={[commonStyles.margin0, commonStyles.paddingTop0, commonStyles.marginLeft15]}
                              label={billData.billStatus}
                              isSelected={false}
                              paymentStatusLabel={billData.paymentStatus}
                            />
                          )}
                        {/* <StatusComponent
                          marginRight={[commonStyles.marRT0, commonStyles.marB0, commonStyles.paddingTop0]}
                          label={billData.billStatus}
                          isSelected={false}
                          paymentStatusLabel={billData.paymentStatus}
                        /> */}
                      </View>
                      {/* </View> */}
                    </View>
                  {/* </View> */}
                </View>
              </View>
              <View style={[padding2]}>
                <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
                  <View style={[commonStyles.flexBasis50]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        commonStyles.fontWeight700,
                        styles.label,
                      ]}>
                      Account Number
                    </Text>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        styles.labelValue,
                      ]}>
                      {!!billData.accountNumber ? billData.accountNumber : "N/A"}
                    </Text>
                  </View>
                    <View style={[commonStyles.flexBasis50]}>
                      <Text
                        style={[
                          styles.cardSubHeadingText,
                          commonMetricsStyle.heading6,
                          commonFontStyles.workSans,
                          commonStyles.darkGrayColor,
                          styles.label,
                          commonStyles.fontWeight700,
                        ]}>
                        Reference ID
                      </Text>
                      <Text
                        style={[
                          styles.cardSubHeadingText,
                          commonMetricsStyle.heading6,
                          commonFontStyles.workSans,
                          commonStyles.darkGrayColor,
                          styles.labelValue,
                          customCommonStyles.textOverflowEllipsis,
                        ]}>
                        {(!!billData.endToEndIdentification && billData.endToEndIdentification.length > maxlimit) ?
                          (billData.endToEndIdentification.substring(0, maxlimit - 3)) + '...' : billData.endToEndIdentification}
                      </Text>
                    </View>
                </View>
                <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
                  <View style={[commonStyles.flexBasis50]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        commonStyles.fontWeight700,
                        styles.label,
                      ]}>
                      Bill Period
                    </Text>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        styles.labelValue,
                      ]}>
                      {billData.billingPeriod}
                    </Text>
                  </View>
                  <View style={[commonStyles.flexBasis50]}>
                    <Text style={[styles.cardSubHeadingText, commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight700, commonStyles.transformCapitalize, commonStyles.labelDetails, ]}>
                      Received Date
                    </Text>
                    <Text style={[styles.cardSubHeadingText, commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400]}>
                      {DateUtils.dateAndTimeWithAmPm(billData.threadDateTime)}
                    </Text>
                  </View>
                </View>
                <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
                  <View style={[commonStyles.flexBasis50]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        commonStyles.fontWeight700,
                        styles.label,
                      ]}>
                      Due On
                    </Text>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        styles.labelValue,
                      ]}>
                      {DateUtils.formatDate(billData.dueDate)}
                    </Text>
                  </View>
                  <View style={[commonStyles.flexBasis50]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        commonStyles.fontWeight700,
                        styles.label,
                      ]}>
                      Amount Payable
                    </Text>
                    <Text
                      style={[styles.cardSubHeadingText, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.darkGrayColor, styles.label]}>
                      {CURRENCY[billData.currency]}{billData.billStatus === "Paid" ? "0.00" : payableAmt.toFixed(2)}
                    </Text>
                  </View>
                </View>
                <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
                  <View style={[commonStyles.flexBasis50]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        commonStyles.fontWeight700,
                        styles.label,
                      ]}>
                      Initial Amount
                    </Text>
                    <Text
                      style={[styles.cardSubHeadingText, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.darkGrayColor, styles.label]}>
                      {CURRENCY[billData.currency]}{billData.amount}
                    </Text>
                  </View>
                  <View style={[commonStyles.flexBasis50]}>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        commonStyles.fontWeight700,
                        styles.label,
                      ]}>
                      Paid Amount
                    </Text>
                    <Text
                      style={[
                        styles.cardSubHeadingText,
                        commonMetricsStyle.heading6,
                        commonFontStyles.workSans,
                        commonStyles.darkGrayColor,
                        styles.labelValue,
                      ]}>
                      {CURRENCY.USD}{paidAmount}
                    </Text>
                  </View>
                </View>
                {!billData.biller.isBlocked && !!billData.threadHeader && !!billData.threadHeader.isExternalCustomer &&
                  <View style={[styles.mViewDownloadSectionTouchable]}>
                    <Touchable onPress={() => this.downloadFile(billData)} style={[styles.downloadSectionTouchable,]}>
                      <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.flexDirectionRow, styles.downloadSection]}>
                        <Image source={downloadIcon} style={[commonStyles.icon20, commonStyles.marginRight10]} />
                        <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.fontWeight500, commonStyles.transformCapitalize, commonStyles.labelDetails, styles.downloadBillText]}>
                          Download Bill
                      </Text>
                      </View>
                    </Touchable>
                  </View>}
              </View>
            </View>
            {this.state.isLoading && 
            <View style={styles.loading}>
              <ActivityIndicator size="large" color="#337aff" />
              </View>
            }
            <View style={styles.webView}>{this.webView(payableAmt, maxlimit, paidAmount)}</View>
          </View>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  container: {
    // paddingTop: 10,
  },
  layout: {
    position: "absolute",
    width: "100%",
    height: "100%", 
    opacity: 0.4
  },
  loading: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 300,
    bottom: 0,
    // minHeight: "100%",
    // minWidth: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  cardRightDiv: {},
  cardHeadingText: {
    color: "#182832",
    display: "block",
    fontSize: 16,
  },
  cardSubHeadingText: {
    color: "#444444",
    display: "block",
    fontSize: 14,
    marginTop: 4,
    marginRight: 0,
    // marginBottom: 8,
    marginLeft: 0,
  },
  cardSmlHeadingText: {
    color: "#888888",
    display: "block",
    fontSize: 12,
    margin: 0,
  },
  bokMark: {
    display: "block",
    width: 17,
    height: 22,
    backgroundSize: "contain",
  },
  payOptImg: {
    width: 76,
    height: 76,
    display: "block",
    backgroundSize: "contain",
  },
  userIconDiv: {
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    backgroundColor: "#e7e7e7",
  },
  minMax40: {minWidth: '40%', maxWidth: '40%'},
  decline: {
    height: 13,
    width: 15,
    marginTop: 0,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
  },
  customWidthWebView: {
    screen: ({ width }) => {
      if (width >= 640 )
        return {      
          maxWidth: "20%",
          minWidth: "20%"
        };
    },
  },
  marginTop3: {
    marginTop: 3,
  },
  leftSection: {
    // minWidth: 160,
    maxWidth: "50%",
  },
  rightSection: {
    // minWidth: 160,
    maxWidth: "50%",
    marginLeft: 10,
  },
  labelValue: {
    fontWeight: 400,
  },
  billerDetails: {
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991 )
        return {
          paddingTop: 2,
        };
    },
  },
  billerDetailsRow: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 16,
    marginLeft: 0,
    flexDirection: "row",
    screen: ({ width, height }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
        };
    },
  },
  webView: {
    display: "none",
    screen: ({ width }) => {
      if (width >= 640)
        return {
          display: "block",
        };
    },
  },
  mView: {
    display: "none",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          display: "block",
        };
    },
  },
  noBackground: {
    backgroundColor: "rgba(255,255,255, 0.0)",
    border: 0,
  },
  customeMargin: {
    marginTop: -10,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  statusSection: {
    screen: ({ width }) => {
      if (width <= 640)
        return {
          // order: 1,
          minWidth: "30%"
        };
      if (width >= 640)
        return {
          // order: 1,
        //   marginTop: 10,
        //   marginBottom: 10,
        };
    },
  },
  paymentPageBillDetailsLabels: {
    minWidth: 175,
    marginRight: 16,
  },
  paymentPageBillDetailsValue: {
    flexBasis: "100%",
  },
  webViewUserIconDiv: {
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    backgroundColor: "#e7e7e7",
    position: "relative",
    marginRight: 16,
    screen: ({ width }) => {
      if (width >= 640 && width <= 1024)
        return {
          display: "none",
        };
    },
  },
  billDetailsHeaderLeftSection: {
    width: "50%",
  },
  billerNameSection: {
    // maxWidth: "90%",
    // minWidth: "90%",
    paddingTop: 7,
    // paddingBottom: 5,
  },
  minWidth40: {
    width: "50%",
  },
  popupMenuStyle: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  dateRowSpacing: {
    // paddingTop: ,
    minWidth: "100%",
    height: 30,
  },
  payPageBillerDetailsRow: {
    marginBottom: 5,
  },
  downloadSectionTouchable: {
    maxWidth: 145,
    minWidth: 145,
  },
  downloadSection: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#4dc5f2",
    padding: 5,
    borderRadius: 5,
  },
  downloadBillText: {
    color: "#4dc5f2"
  },
  mViewDownloadSectionTouchable: {
    marginTop: 16,
    maxWidth: 135,
    minWidth: 135,
  },
  fullWidth: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%"
        }
      }
    }
  },
  minWidth115: {
    minWidth: 115
  },
  tooltipStyles: {
    // marginTop: -10,
    top: 0,
    position: "relative",
    minHeight: 50,
    left: 0,
  },
  priorityInfoTooltip: {
    right: "110%",
    minHeight: 40,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    screen: ({ width, height }) => {
      if (width === 810 && (height === 1010 || height === 1080) ){
        return  {
          right: Platform.OS !== "web" ? "110%" : "110%",
        }
      }
      if (width <= 991) {
        return {
          right: Platform.OS !== "web" ? "110%" : "100%",
        }
      }
    }
  },
  bigPriorityInfoTooltip: {
    bottom: 0,
  },
  smallPriorityInfoTooltip: {
     bottom: 10, 
  },
  arrowHolderStyle: {
    position: "absolute",
    left: -5,
  },
  toolTipMessageSignupStyle: {
    right: 30,
  },
  toolTipMessageStyle: {
    maxHeight: 20,
    left: 15,
    marginLeft: 7
  },
  billDetailsRowInner: {
      maxWidth: "50%",
  }
});
