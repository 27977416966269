import React from "react";
import { StyleSheet, Touchable, Image, View } from "../core";
import { commonStyles } from "../assets/css/commonStyle";

export default class Bookmarked extends React.Component {
  render() {
    const { isBookmarked, onToggle, bookedMarkStyle, bookedMarkImgStyle, source } = this.props;
    let imgpath = isBookmarked ? require("../assets/images/bookmared_active.png") : require("../assets/images/bookmared_unactive.png");
    if (!!source && source === "bA") {
      return (
        <View style={[commonStyles.flex1, bookedMarkStyle]}>
          <View>
            <Image resizeMode="contain" style={[styles.bookedMark, bookedMarkImgStyle]} source={imgpath} />
          </View>
        </View>
      );
    }
    else {
      return (
        <Touchable style={[commonStyles.flex1, bookedMarkStyle]} onPress={onToggle}>
          <View>
            <Image resizeMode="contain" style={[styles.bookedMark, bookedMarkImgStyle]} source={imgpath} />
          </View>
        </Touchable>
      );
    }
  }
}

const styles = StyleSheet.create({
  bookedMark: {
    height: 22,
    width: 15,
    marginLeft: 16,
    display: "block",
  },
});
