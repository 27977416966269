import { USER_TYPE } from "../constant/app.data.constant";
import R14, { } from "../core";
import CommonUtility from "../utils/CommonUtility";
export default class NotificationDomain extends R14.Domain {
    constructor(props) {
        super(props);   
        this.state={
          notifications:[],
          notificationCount: 0,
          notificationThreadIds:[],
          reCalled: false
        }   
    }

  async notificationDetail(onlyCount = false) {
    let userLoginDetail = await this.dm.rest.getUser();
    let userType = !!userLoginDetail ? userLoginDetail.userType : null;
    if (userType === USER_TYPE.XBP && this.dm.dashboard.state.selectedScreen === "RTP") {
      let notificationResult = await this.insertNotification(onlyCount);
      if (!!onlyCount) {
        this.setState({
          notificationCount: !!notificationResult ? notificationResult.totalCount : 0,
          reCalled: true
        })
        return false;
      }
      else {
        this.setState({
          notifications: !!notificationResult ? notificationResult.nodes : [], // finalNotifications
          notificationCount: !!notificationResult ? notificationResult.totalCount: 0
        })
      }
    }
  }

    async updateNotification(input, fields = "uid payerId isRead") {
    await CommonUtility.htmlDecode(input);
      try {
        let res = await this.dm.queryDomain.middleware(this.api.mutate(
          `
          mutation updateNotificationData($input: NotificationData!){
            updateNotificationData(input: $input){              
              totalCount
            }
          }`,
          { input }
        ));
        let notification =  res.data.updateNotificationData;
        this.setState({
          notificationCount: notification.totalCount
        });        
      } catch (error) {
        return error;
      }
    }

    async insertNotification(onlyCount = false, fields = "uid buId billerId payerId billerRef payerRef threadId originatorPid respondentPid messageType profile subject note subMessageType messageDateTime isRead readCount readDateTime") {
      let { pid } = await this.dm.rest.getUser();
      let pidBUs = await this.dm.rest.getLinkedPIDBU();
      let input = {
          payerId: pid,
          onlyCount: onlyCount,
          currentCount: this.state.notificationCount,
          reCalled: this.state.reCalled,
          pids: pidBUs.map((item) => { return item['pid'] }).toString(),
          buIds: pidBUs.map((item) => { return item['buId'] }).toString(),
          billerIds: pidBUs.map((item) => { return item['billerId'] }).toString(),
        };

        try {
          let res = await this.dm.queryDomain.middleware(this.api.mutate(
            ` mutation insertNotification($input: InsertNotificationInput!){
              insertNotification(input: $input){       
                  ${!!onlyCount ? "totalCount" :
                   `totalCount
                    nodes
                     {
                       ${fields}
                      }` 
                  }
              }
            }`,
            { input }
          ));
          return res.data.insertNotification;
        } catch (error) {
          return error;
        }
    }
}