import React from "react";
import R14, { View, Text, Button, TextInputField, Form, Touchable, StyleSheet, SubmitButton } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
import { CommonString } from "../constant/appCommonText";
import CommonValidators from "../utils/CommonValidators";
import ModalPopUp from "./ModalPopUp";

export default R14.connect(class OTPConfirmComponent extends React.Component {

  onTextChange = (text) => {
    try {
      // Ensure the text contains only digits and is at most 6 characters long
      const sanitizedText = text.replace(/[^0-9]/g, '').slice(0, 6);
      let otpForm = this.props.app.ui.form("optLinkPayer");
      otpForm.elmts.oneTimeCode.setValue(sanitizedText);
      return false;
    } catch (error) {

    }
  };

  handleSubmit = (form) => {
    const { oneTimeCode } = form.values;
    this.props.handleSubmit(oneTimeCode);
    return false;
  }

  render() {
    const { mobileNo = "", sendOTP, handleOtpPopUp, visibility, timer = 30, totalResentOTP } = this.props;
    return (
      <ModalPopUp
        visibility={visibility}
        isFooter={true}
        bodyWrapper={[commonStyles.smallModalBodyWrapper]}
        handleCancel={this.onCancel}
        closeButton={customCommonStyles.customDisplayNone}
        modalOuterWrapper={styles.overFlowAuto}
        modalContent={[commonStyles.marB0]}>
        <View style={[commonStyles.flex1, commonStyles.flexGrow0, customCommonStyles.customFilePassowrdModalBodyWrapper]}>
          <Form
            name="optLinkPayer"
            style={[commonStyles.padding0]}
            onSubmit={this.handleSubmit}
            validateBeforeSubmit={true}
            validators={CommonValidators.getFormValidator()}>
            <View style={[commonStyles.marginBottom20]}>
              <Text
                style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight400, commonStyles.contentColor, commonStyles.textAlignCenter]}
              >{"We have sent you a 6 digit one-time code on your registered"}</Text>
              <Text
                style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight500, commonStyles.contentColor, commonStyles.textAlignCenter]}
              >{` mobile number ending in ${mobileNo}. `} </Text>
              <Text
                style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight400, commonStyles.contentColor, commonStyles.textAlignCenter]}
              >{"To continue, please enter the code below."}</Text>
            </View>
            <View style={[commonStyles.flex1,]}>
              <TextInputField
                name="oneTimeCode"
                label={`One-time code*`}
                required={CommonString.EnterOneTimeCodeRequired}
                validator={"minMax6Digit"}
                onChangeText={(val) => this.onTextChange(val)}
                style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.smallHeading, customCommonStyles.inputHeight,]}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.smallHeading]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
            </View>
            <View style={[styles.marginTopMins5, totalResentOTP >= 3 ? commonStyles.displayNone : null ]}>
              {timer > 0 ? <Text
                style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight400, commonStyles.contentColor, commonStyles.textAlignCenter]}
              >{`You can request another code in ${timer} seconds.`}</Text> :
                <Touchable onPress={() => sendOTP("resend")}>
                  <View>
                    <Text style={[commonFontStyles.workSans, commonStyles.secondayWhiteBtnText, commonStyles.smallHeading, commonStyles.textAlignCenter]}>{"Resend Code"}</Text>
                  </View>
                </Touchable>}
            </View>
            <View style={[commonStyles.flex1,
                    commonStyles.flexDirectionRow,
                    commonStyles.alignItemsCenter,
                    commonStyles.justifyContentCenter,
                    commonStyles.marginTop15]}>
              <Button
                title={"Cancel"}
                style={[commonStyles.secondayWhiteBtn, commonStyles.btnHeight24, customCommonStyles.noBoxShadow]}
                onPress={handleOtpPopUp}
                key="billCancel"
                titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.secondayWhiteBtnText, commonStyles.exSmallHeading,]}
              />
              <SubmitButton
                title="Ok"
                key="billOk"
                style={[
                  commonStyles.blueButton,
                  commonStyles.btnHeight24, 
                  styles.modalPopupOkBtn,
                  customCommonStyles.noBoxShadow,
                  commonStyles.marginLeft8,
                ]}
                titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading]}
              />
            </View>
          </Form>
        </View>
      </ModalPopUp>
    );
  }
});


const styles = StyleSheet.create({
  oneTimeFormStyle: {
    paddingTop: 16,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  accountTypeErrorLabel: {
    marginTop: -10,
  },
  modalPopupOkBtn: {
    width: "auto",
  },
  customHelperTextStyle: {
    left: 0,
  },

  bodyWrapper: {
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          maxHeight: 280,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
        };
    },
  },
  modalOuterWrapper: {
    backgroundColor: "#00000059",
  },
  marginTopMins5: {
    marginTop: -5,
  }
})