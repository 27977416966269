
/* AKS QA API URLs*/
const BASE_URL = "https://qarepository-xbp.exela.global";
const USER_API = `${BASE_URL}/apis/requesttopay/v1.0/app/user`;
const NATIVE_APP_URL = "https://web-6b734b48594d37546a.am.os.inventr.ai";
const MIXPANEL_TOKEN = 'b96f4ed802f9046ce82eb0f7c64cc2f5';
const GOOGLE_ANALYTICS_TOKEN = 'G-4LJL5QKMX4';

const KC_BASE_URL = 'https://auth-dev.exelatech.com';

const REALM_NAME = "ConsumerRealm";
const KC_CLIENT_ID = "xbppayer"; 
const CLAIM_CLIENT_ID = "xbpclaim";

export {
    BASE_URL, USER_API, NATIVE_APP_URL, KC_BASE_URL, REALM_NAME, KC_CLIENT_ID, CLAIM_CLIENT_ID, MIXPANEL_TOKEN, GOOGLE_ANALYTICS_TOKEN
}