import React, { PureComponent } from "react";
import { StyleSheet, View, Text, Image, Button } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { fontFamilyStyles } from "../assets/css/commonFontStyles";
import { CURRENCY } from "../constant/app.data.constant";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { uberDocThemeStyle } from "../assets/css/clients/uberDocThemeStyle";
const verifiedDoneIcon = require("../assets/images/verified_done.png");

export default class PaidSuccessfullyComponent extends PureComponent {
  render() {
    const { message, paymentMode, isUberDocs } = this.props;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
    
    return (
      <View style={[styles.paidSuccessfullyComponent, commonStyles.flex1, commonStyles.alignItemsCenter]}>
        <View>
          <Image source={verifiedDoneIcon} style={styles.verified_done} />
        </View>
        <View style={[styles.successTextHolder, commonStyles.textAlignCenter]}>
          <Text style={[commonFontStyles.workSans, commonStyles.heading4, commonStyles.greenColor, styles.successText, commonStyles.textAlignCenter]}>
            {CURRENCY.USD}
            {message}
          </Text>
        </View>

        {!!paymentMode ? (
          <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.flexDirectionColumn, commonStyles.justifyContentCenter]}>
            <View>
            </View>
            <View style={[commonStyles.marginBottom15]}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.successText]}>Payment made with {paymentMode}</Text>
            </View>
          </View>
        ) : null}

        <View style={styles.buttonHolder}>
          <Button
            key="gotohome"
            title="Go to Dashboard"
            style={[commonStyles.blueBorderButton, commonStyles.flexBasis50, commonStyles.marginLeftRight15, styles.gtohButton, customCommonStyles.noBoxShadow, clientStyle.backAndCancelButton]}
            titleTextStyle={[commonStyles.fontWeight500, commonStyles.blueBorderButtonColor, clientStyle.backAndCancelButtonText]}
            to="dashboard"
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  paidSuccessfullyComponent: {
    position: "relative",
    minHeight: 210,
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 10,
    paddingLeft: 15,
  },
  verified_done: {
    height: 36,
    width: 36,
    display: "block",
    backgroundSize: "contain",
  },
  successImage: {
    marginTop: 22,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
  },
  successText: {
    lineHeight: 29,
  },
  successTextHolder: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 16,
    marginLeft: 0,
  },
  buttonHolder: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 25,
    marginLeft: 0,
    width: "100%",
  },
  gtohButton: {
    width: "auto",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
    // screen: ({ width }) => {
    //   if (width <= 991)
    //     return {
    //       width: "100%",
    //     };
    // },
  },
  payMathodIcon: {
    height: 24,
    width: 54,
    display: "block",
    backgroundSize: "contain",
  },
});
