import React from "react";
import R14, { StyleSheet, Text, View, Platform } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { BILL_STATUS, PAGE_PATH } from "../../constant/app.data.constant";
import BottomControlComponent from "../../components/SEPA/BottomControlComponent";
import BillDetailComponent from "../../components/SEPA/BillDetailComponent";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent"; 
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import AccordionComponent from "../../components/SEPA/AccordionComponent";
import ReasonPopupComponent from "../../components/SEPA/ReasonPopupComponent";
import { CommonString } from "../../constant/appCommonText";
import { SEPA_ACCEPT_TRANS, SEPA_REJCT_RSNS, SEPA_REJCT_TRANS } from "../../constant/sepa.constant";
const defaultSec= 10;

export default R14.connect(
  class SEPABillDetailsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        billDetails: this.props.billDetails,
        installmentDetails: this.props.installmentDetails,
        action: "",
        visibility: false,
        loaderVisible: false
      };
      this.timerInstance = null;
      this.refectching = true;
    }

    async componentDidMount() {
      this.timerInstance = setInterval(async () => {
        if (!!this.refectching && this.props.billDetails.billStatus === "ACCEPTED") {
          let bill = await this.props.app.dm.sepa.getBill(this.props.billDetails.resourceId);
          let billDetails = bill && bill.sepaBill;
          let installmentDetails = this.state.installmentDetails;
          for await (let item of installmentDetails) {
            item.billStatus = billDetails.billStatus;
            item.messages = await this.props.app.dm.sepa.getSEPABillMessage(billDetails.uid);
          }
          this.setState({
            billDetails: billDetails,
            installmentDetails: installmentDetails
          })
        }
      }, 1000 * defaultSec);
    }

    initiatePayment = async (e) => {
      this.refectching = e;
    }

    componentWillUnmount() {
      if (this.timerInstance) {
        clearInterval(this.timerInstance);
      }
    }

    paymentNavigation = async (action) => {
      try {
        this.setState({action: action});
        if (action === PAGE_PATH.REJECT) {
          this.setState({
            action: action,
            visibility: true
          })
        }
        else
          await this.onConfirm({ status: "ACCP", statusInfo: "" }, action);

        return false;
      } catch (error) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
    }

    onConfirm = async (payload, action) => {
      try {
        payload.bId = this.state.billDetails.uid;
        this.setState({ loaderVisible: true });
        action = this.state.action || action;
        let res = await this.props.app.dm.sepa.acceptRejectSRTP(payload, this.state.action || action);
        if (!!res && res.success) {
          this.handleClose();
          let installmentDetails = this.state.installmentDetails;
          for await (let item of installmentDetails) {
            item.billStatus = res.sepaBill.billStatus;
            if (action !== PAGE_PATH.REJECT)
              item.isExpanded = false;
            item.messages = await this.props.app.dm.sepa.getSEPABillMessage(payload.bId);
          }

          if (action !== PAGE_PATH.REJECT)
            installmentDetails[0].isExpanded = true;

          this.setState({ loaderVisible: false, billDetails: res.sepaBill, installmentDetails: installmentDetails });
          this.props.app.dm.commonDomain.showToastr("success", res.message);
        }
        else {
          this.setState({ loaderVisible: false });
          this.props.app.dm.commonDomain.showToastr("error", res.message);
        }
      } catch (error) {
        this.setState({ loaderVisible: false });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
    }

    handleClose = () => {
      this.setState({ visibility: false, action: "" })
    }

    onStatusInfoValueChange = (val) => {
      const form = this.props.app.ui.form("frmAcptRjct");
      form.elmts.statusInfo.setError(null);
      if(Platform.OS !== "web") {
        form.elmts.statusInfo.setValue(val.label)
      } else form.elmts.statusInfo.setValue(val);
      return;
    }

    onRejectValueChange = (val) => {
      const form = this.props.app.ui.form("frmAcptRjct");
      form.elmts.status.setError(null);
      if(Platform.OS !== "web") {
        form.elmts.status.setValue(val.label)
      } else form.elmts.status.setValue(val);
      return;
    }

    render() {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <>
          <View style={[commonStyles.marginLeftRight16, commonStyles.marginTop15, commonStyles.flex1, styles.billDetailsScreenInnerWrap]}>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.billerDetailsWrap, customCommonStyles.customBillerDetailsWrap]}>
              <View
                style={[
                  styles.container,
                  commonStyles.flex1,
                  commonStyles.flexBasis50,
                  commonStyles.flex1,
                  commonStyles.flexDirectionColumn,
                  commonStyles.padding0,
                  customCommonStyles.customBillerDetailsContainer,
                ]}>
                <BillDetailComponent
                  padding1={styles.padding1}
                  padding2={[styles.padding2]}
                  padding3={styles.padding3}
                  userIconDiv={[styles.userIconDiv, commonStyles.flex1]}
                  billData={this.state.billDetails}
                  installmentDetails={this.state.installmentDetails}
                  customFlexDirection={commonStyles.flexDirectionColumn}
                  isBookmarkRequire={true}
                />
                {isMobileDevice ? (
                  <>
                    <View style={styles.bottomPaySection}>
                      {this.state.billDetails.billStatus === "VOID" ?
                        <View style={[commonStyles.marginTopBottom10]}>
                          <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.wordBreakWord, commonStyles.helperTextFontStyle, commonStyles.fontWeight500]}>*Bill request expired. Please connect with Biller.</Text>
                        </View> : null
                      }
                      <BottomControlComponent billDetails={this.state.billDetails} extraOpt={this.state.billDetails.rtpMessageType} isDisabled={BILL_STATUS[this.state.billDetails.billStatus]} paymentNavigation={this.paymentNavigation} />
                    </View>
                    <View style={[styles.mView]}>
                      <View style={[styles.customPaddingLeftRight16]}>
                        <AccordionComponent billDetails={this.state.billDetails} userDetails={this.props.userDetails} installmentDetails={this.state.installmentDetails} initiatePayment={this.initiatePayment} />
                      </View>
                    </View>
                  </>
                ) : null}

                {!isMobileDevice ? (
                  <View style={[commonStyles.marTAuto, styles.webView, commonStyles.flexDirectionColumn, styles.bottomPaySectionWebView]}>
                    {this.state.billDetails.billStatus === "VOID" ?
                      <View style={[commonStyles.paddingLeftRight16]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.wordBreakWord, commonStyles.helperTextFontStyle, commonStyles.fontWeight500]}>*Bill request expired. Please connect with Biller.</Text>
                      </View> : null
                    }
                    <View style={[styles.bottomPaySection, commonStyles.marTAuto, commonStyles.webView]}>
                      <BottomControlComponent
                        isDisabled={BILL_STATUS[this.state.billDetails.billStatus]}
                        billDetails={this.state.billDetails}
                        paymentNavigation={this.paymentNavigation}
                        extraOpt={this.state.billDetails.rtpMessageType} />
                    </View>
                  </View>
                ) : null}
              </View>
              {(window.innerWidth >= 1024) ? (
                <View
                  style={[
                    commonStyles.flex1,
                    commonStyles.flexBasis50,
                    commonStyles.flexDirectionColumn,
                    styles.container,
                    commonStyles.padding0,
                    styles.messageSection,
                    customCommonStyles.customMessageSection,
                    styles.webView
                  ]}>
                  <View style={[customCommonStyles.sepaCustomMessageContainer]}>
                    <AccordionComponent billDetails={this.state.billDetails} userDetails={this.props.userDetails} installmentDetails={this.state.installmentDetails} initiatePayment={this.initiatePayment} />
                  </View>
                </View>
              ) : null}
            </View>
          </View>
          <ReasonPopupComponent handleClose={this.handleClose} onConfirm={this.onConfirm} formName="frmAcptRjct" bill={this.state.billDetails} action={this.state.action} visibility={this.state.visibility} transactionStatusInfo={SEPA_REJCT_RSNS} transactionStatus={this.state.action === PAGE_PATH.REJECT ? SEPA_REJCT_TRANS : SEPA_ACCEPT_TRANS} onRejectValueChange={this.onRejectValueChange} onStatusInfoValueChange={this.onStatusInfoValueChange} />
          <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} />
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  bottomPaySection: {
    border: 0,
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 0,
          paddingLeft: 0,
        };
    },
  },
  container: {
    padding: 10,
    maxWidth: "49%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    backgroundColor: "#fff",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          maxWidth: "100%",
          boxShadow: "none",
          borderWidth: 0,
        };
    },
  },
  billerDetailsWrap: {
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexWrap: "wrap",
          marginBottom: 0,
          backgroundColor: "#fff",
        };
    },
  },
  messageSection: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 15,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 15,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  },
  padding1: {
    paddingTop: 8,
    paddingBottom: 0,
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 0,
          paddingLeft: 0,
        };
    },
  },
  padding2: {
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 4,
  },
  padding3: {
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
  },
  webView: {
    display: "none",
    screen: ({ width }) => {
      if (width >= 1024)
        return {
          display: "flex",
        };
    },
  },
  mView: {
    display: "none",
    screen: ({ width }) => {
      if (width < 1024)
        return {
          display: "flex",
          flexDirection: "column",
        };
    },
  },
  customPaddingLeftRight16: {
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingRight: 0,
          paddingLeft: 0,
        };
      }
    },
  },
  billDetailsScreenInnerWrap: {
    marginTop: 0,
    marginBottom: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 16,
          paddingLeft: 16,
          marginLeft: 0,
          marginRight: 0,
          backgroundColor: "#fff",
          minHeight: "100%"
        };
    },
  },
  bottomPaySectionWebView: {
    marginBottom: 16,
  },
});
