import React from "react";
import R14, {
    View,
    StyleSheet,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import TableListComponent from "../../components/TableListComponent";
import VendorFilterComponent from "../../components/SMB/VendorFilterComponent";
import { analyticsLogs } from "../../utils/AnalyticLogs";
import { CommonString } from "../../constant/appCommonText";

export default R14.connect(
    class VendorStatementsScreen extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                filterData: [],
                resultsPerPage: 9,
                currentPage: 1,
                totalBills: 0,
                loaderVisible: false,
                payload: {}
            };
        }

        handleReset  = async () => {
          analyticsLogs(CommonString.VENDOR_STATEMENT, `${CommonString.Reset_Search}`, this.props.app);
            this.setState({
                filterData: [],
                resultsPerPage: 9,
                currentPage: 1,
                totalBills: 0,
                loaderVisible: false,
                payload: {}
            })
            return false;
        }

        handleSubmit = async (payload) => {
            try {
                if (!payload.vendorID || !payload.paymentStatus || !payload.orgList) return false;
                this.setState({loaderVisible: true, payload });
                const bill = await this.props.app.dm.smbApi.getAPVendorStatementDetail(payload);
                bill.data = bill.data.slice(0, this.state.resultsPerPage);
                this.setState({filterData: bill.data, totalBills: bill.totalRecords, currentPage: 1, loaderVisible: false});
                analyticsLogs(CommonString.VENDOR_STATEMENT, `${CommonString.Search}`, this.props.app);
                return false;
            } catch (error) {
                this.setState({filterData: [], loaderVisible: false});
                this.props.app.dm.commonDomain.showToastr("error", error);
                return false;
            }
        };

        getNext = async () => {
            let { currentPage, totalBills, resultsPerPage, payload } = this.state;
            const totalPages = Math.ceil(totalBills / resultsPerPage);
            if (currentPage <= totalPages) {
                currentPage = currentPage + 1;
                this.setState({ loaderVisible: true });
                payload.resultsPerPage = currentPage * resultsPerPage
                const bill = await this.props.app.dm.smbApi.getAPVendorStatementDetail(payload);
                bill.data = bill.data.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);

                this.setState({
                    filterData: bill.data,
                    totalBills: bill.totalRecords,
                    loaderVisible: false,
                    currentPage: currentPage,
                });
            }
        }

        getPrevious = async () => {
            let { currentPage, resultsPerPage, payload } = this.state;
            if (currentPage >= 1) {
                currentPage = currentPage - 1;
                if (currentPage > 0) {
                this.setState({ loaderVisible: true });
                payload.resultsPerPage = currentPage * resultsPerPage;
                const bill = await this.props.app.dm.smbApi.getAPVendorStatementDetail(payload);
                bill.data = bill.data.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);

                this.setState({
                    filterData: bill.data,
                    totalBills: bill.totalRecords,
                    loaderVisible: false,
                    currentPage: currentPage,
                });
                }
            }
        }

        getFirst = async () => {
            this.setState({ loaderVisible: true });
            const {payload, resultsPerPage} = this.state;
            payload.resultsPerPage = resultsPerPage;
            const bill = await this.props.app.dm.smbApi.getAPVendorStatementDetail(payload);
            bill.data = bill.data.slice(0, resultsPerPage);
            this.setState({
                filterData: bill.data,
                totalBills: bill.totalRecords,
                loaderVisible: false,
                currentPage: 1,
            });
        }

        getLast = async () => {
            let { totalBills, resultsPerPage, payload } = this.state;
            const totalPages = Math.ceil(totalBills / resultsPerPage);
            this.setState({ loaderVisible: true });
            payload.resultsPerPage = totalPages * resultsPerPage;

            const bill = await this.props.app.dm.smbApi.getAPVendorStatementDetail(payload);
            bill.data = bill.data.slice((totalPages - 1) * resultsPerPage, totalPages * (resultsPerPage + 1));

            this.setState({
                filterData: bill.data,
                totalBills: bill.totalRecords,
                loaderVisible: false,
                currentPage: totalPages,
            });
        }

        render() {
            const { isUberDocs } = this.props.app.dm.userSession.state;
            let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
            const {filterData, totalBills, resultsPerPage, currentPage, loaderVisible} = this.state; 

            const totalPages = Math.ceil(totalBills / resultsPerPage);
            let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
            let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
            let rowItems = endIndex - startIndex + 1;
            return (
                <>
                        <View style={[styles.uploadBillSection, clientStyle.secondaryBgColor]}>
                            <VendorFilterComponent user={this.props.user} handleSubmit={this.handleSubmit} handleReset={this.handleReset} />
                            <View style={[styles.padding16]}>
                            <TableListComponent
                                totalPages={totalPages}
                                startIndex={startIndex}
                                endIndex={endIndex}
                                rowItems={rowItems}
                                dataList={filterData}
                                currentPage={currentPage}
                                totalBills={totalBills}
                                pagination={true}
                                message="No record found."
                                getFirst={() => this.getFirst()}
                                getLast={() => this.getLast()}
                                getNext={() => this.getNext()}
                                getPrevious={() => this.getPrevious()}
                                labelKeys={["Invoice#", "Invoice Date", "Invoice Amount", "Due Date", "Due Amount", "Invoice Status", "Payment Status"]}
                                labelValues={["invoiceNo", "invoiceDate", "invoiceAmount", "dueDate", "dueAmount", "invoiceStatus", "paymentStatus"]}
                                dateTypeFields={["dueDate", "invoiceDate"]}
                                amountTypeFields={[]}
                            />
                            </View>
                        </View>
                        <PCHLoaderComponent isVisiable={loaderVisible} labelText={"Please wait"} clientStyle={clientStyle} />
                </>
            );
        }
    }
);
const styles = StyleSheet.create({
    uploadBillSection: {
        backgroundColor: "#fff",
        marginTop: 16,
        marginRight: 16,
        marginBottom: 16,
        marginLeft: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    paddingTop: 0,
                };
            }
        },
    },
    pageTitle: {
        marginTop: 15,
        display: "flex",
        justifyContent: "space-between",
        minHeight: 30,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 10,
                    marginBottom: 10,
                };
            }
        },
    },
    padding16: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    padding: 16,
                };
            }
        },
    }
});
