import React from "react";
import R14, {
    Form,
    View,
    Text,
    StyleSheet,
    Button,
    SubmitButton,
    TextInputField,
    Platform,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import CommonValidators from "../../utils/CommonValidators";
import DatePicker from "../../components/PCH/DatePicker";
import DropDown from "../../components/DropDown";

export default R14.connect(
    class ManagePaymentFilterComponent extends React.Component {
        constructor(props) {
            super(props);
            this.form = null;
            this.insuranceItems = [];
        }

        componentDidMount = async () => {
            try {
                this.form = this.props.app.ui.form("managePaymentfilters");
                const {insurances} = this.props;
                if (!!insurances && insurances.length > 0) {
                    insurances.forEach((insurance) => {
                        this.insuranceItems.push({
                            label: insurance.name,
                            value: insurance.uid,
                            poBoxNo: insurance.poBoxNo,
                            address1: insurance.address1,
                            city: insurance.city,
                            state: insurance.state,
                            groupNumber: insurance.groupNumber
                        });
                    });
                    this.form.elmts.insuranceName.setItems(this.insuranceItems);
                };
            }
            catch (err) {
                console.log(err)
            }
        };

        onDiscard = async () => {
            this.form.reset();
            this.props.handleReset();
        };

        onInvalidTypeText = (txt, key, form = this.props.app.ui.form("managePaymentfilters")) => {
            return form.elmts[key].setValue(txt.slice(0, -1))
        }

        onInsuranceChange = async (val) => {
            const { label, value } = val;
            const form = this.props.app.ui.form("managePaymentfilters")
            if (label === "No Results Found" || label.includes("Type in a search value")) {
                form.elmts.insuranceName.setValue(null);
                return false;
            }
            form.elmts.insuranceName.setValue({ label: label, value: value });
            form.elmts.insuranceName.setError(null);
        };

        onSearchInsurance = async (val) => {
            const { insurances, userLoginDetail } = this.props;
            this.insuranceItems = [];
            if (!!insurances && insurances.length > 0) {
                insurances.forEach((insurance) => {
                    this.insuranceItems.push({
                        label: insurance.name,
                        value: insurance.uid,
                        poBoxNo: insurance.poBoxNo,
                        address1: insurance.address1,
                        city: insurance.city,
                        state: insurance.state,
                        groupNumber: insurance.groupNumber
                    });
                });
            };
            if (!!val) {
                this.insuranceItems = this.insuranceItems
                    .filter((item) => {
                        return item.label.toLowerCase().includes(val.toLowerCase());
                    }).sort((a, b) => b.label - a.label);

                let ins = await this.props.app.dm.addInsuranceDomain.getInsuranceOnSearch(userLoginDetail.uid, val);
                if (!!ins && ins.length > 0) {
                    ins.forEach((insurance) => {
                        this.insuranceItems.push({
                            label: insurance.name,
                            value: insurance.uid,
                            poBoxNo: insurance.poBoxNo,
                            address1: insurance.address1,
                            city: insurance.city,
                            state: insurance.state,
                            groupNumber: insurance.groupNumber
                        });
                    });
                };
            };
            this.insuranceItems = await this.filterItems(this.insuranceItems);
            this.form.elmts.insuranceName.setItems(this.insuranceItems);
            return this.insuranceItems;
        }

        filterItems = async (list) => {
            let filterArray = list.reduce((acc, current) => {
                const x = acc.find(item => item.value === current.value);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            return filterArray;
        }

        handleSubmit = async(form) => {
            await this.props.handleSubmitFilter(form);
            return false;
        }

        render() {
            let clientStyle = commonStyles;
            let commonFontStyles = fontFamilyStyles;
            return (
                <View style={[styles.filterContainer]}>
                    <View style={[styles.filterLabel]}>
                        <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans]} >Filter</Text>
                    </View>
                    <Form
                        name="managePaymentfilters"
                        style={[styles.uploadBillsForm, clientStyle.form]}
                        validateBeforeSubmit={true}
                        validators={CommonValidators.getFormValidator()}
                        onSubmit={this.handleSubmit}>
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.leftInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.customFlexDirection,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    commonStyles.marginBottom10
                                ]}>
                                <DropDown
                                    name="insuranceName"
                                    label={"Insurance"}
                                    onValueChange={this.onInsuranceChange}
                                    searchable={true}
                                    items={this.insuranceItems}
                                    itemLoader={async ({ search }) => {
                                        return await this.onSearchInsurance(search);
                                    }}
                                    // multiple={true}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                    style={[customCommonStyles.inputHeight, styles.width100, styles.minWidth100, commonFontStyles.workSans, commonStyles.heading6, commonStyles.marginBottom13]}

                                    onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "orgName")}
                                />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    styles.customMarginBottom30
                                ]}>

                                <TextInputField
                                    name="checkNumber"
                                    label="Check Number "
                                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                        </View>
                        {/* Date for service from-to */}
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, Platform.OS === "web" ? null : commonStyles.flexDirectionRow,]}>
                                <DatePicker
                                    name={"fromDateOfService"}
                                    formName="managePaymentfilters"
                                    label={"Date of Service - From "}
                                    props={this.props}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                                />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.flexDirectionColumn,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    commonStyles.alignItemsStart,
                                    styles.customMarginBottom30,
                                    Platform.OS === "web" ? null : commonStyles.flexDirectionRow,
                                ]}>
                                <DatePicker
                                    name={"toDateOfService"}
                                    formName="managePaymentfilters"
                                    label={"Date of Service - To "}
                                    props={this.props}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100, commonStyles.maxWidth100, commonStyles.fullFlexContainer]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                                />

                            </View>
                        </View>
                        {/* Paid date from-to */}
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, Platform.OS === "web" ? null : commonStyles.flexDirectionRow,]}>
                                <DatePicker
                                    name={"fromPaidDate"}
                                    formName="managePaymentfilters"
                                    label={"Paid Date - From "}
                                    props={this.props}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                                />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.flexDirectionColumn,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                    commonStyles.alignItemsStart,
                                    styles.customMarginBottom30,
                                    Platform.OS === "web" ? null : commonStyles.flexDirectionRow,
                                ]}>
                                <DatePicker
                                    name={"toPaidDate"}
                                    formName="managePaymentfilters"
                                    label={"Paid Date - To "}
                                    props={this.props}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100, commonStyles.maxWidth100, commonStyles.fullFlexContainer]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                                />
                            </View>
                        </View>

                        <View style={[commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow]}>
                            <View style={[styles.formBtnsHolder]}>
                                <Button
                                    title={"Cancel"}
                                    style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.btnWidth108, styles.customRestMargin]}
                                    titleTextStyle={[commonStyles.secondayWhiteBtnText,commonStyles.exSmallHeading, commonFontStyles.workSans]}
                                    onPress={() => this.onDiscard()}
                                />
                                <SubmitButton
                                    title={"Search"}
                                    style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.customRestMargin, styles.customMarginLeft16]}
                                    titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, clientStyle.submitButtonText]}
                                />
                            </View>
                        </View>
                    </Form>
                </View>
            );
        }
    }
);
const styles = StyleSheet.create({
    leftInputHolder: {
        marginRight: 8,
        maxWidth: '49%',
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    maxWidth: '100%',
                    marginRight: 0,
                };
            }
        },
    },
    rightInputHolder: {
        marginLeft: 8,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 0,
                };
            }
        },
    },
    customFlexProperties: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: "auto",
                    // marginBottom: 30
                };
            }
            if (width >= 991) {
                return {
                    justifyContent: "flex-end",
                };
            }
        },
    },
    inputHolder: {
        marginBottom: 6,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexDirection: "column",
                };
            }
        },
    },
    formBtnsHolder: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexBasis: "100%",
                    marginBottom: 16,
                    marginTop: 16
                };
            }
        },
    },
    customAlignItemsStart: {
        alignItems: "baseline",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    alignItems: null,
                };
            }
        },
    },
    uploadBillsForm: {
        width: '100%',
        maxWidth: "100%",
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    flex: 1,
                    width: '100%',
                };
            }
            if (width <= 991) {
                return {
                    paddingTop: 10,
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingRight: 0,
                    //   width: "90%"
                };
            }
        },
    },
    incNameDropDown: {
        marginBottom: 24,
        screen: ({ width }) => {
            if (width >= 991)
                return {
                    maxWidth: "49%",
                };
        },
    },
    textAlign: {
        alignItems: "center",
        // height: "55%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginBottom: 0,
                    alignItems: "flex-start",
                };
            }
        },
    },
    width100: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    minWidth100: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minWidth: "100%",
                };
            }
        },
    },
    customMarginBottom30: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 15,
                };
            }
        },
    },

    filterContainer: {
        flex: 1,
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'rgba(21, 27, 38, 0.4)',
        borderStyle: 'solid',
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 16,
        paddingRight: 16,
        position: 'relative',
        marginBottom: 16,
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16,
        maxWidth: "100%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginBottom: 0,
                };
            }
        }
    },

    filterLabel: {
        position: 'absolute',
        top: -11,
        left: 8,
        backgroundColor: 'white',
        paddingLeft: 5,
        paddingRight: 5
    },
    marginBottom16: {
        marginBottom: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 32,
                };
            }
        },
    },
    marginTop10: {
        screen: ({ width }) => {
            if (width > 991) {
                return {
                    marginTop: 10,
                };
            }
        }
    },
    customMarginLeft16: {
        marginLeft: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 0,
                    marginRight: 0
                };
            }
        }
    },
    paymentStatusWrapper: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    paddingTop: 0,
                };
            }
        }
    },
    btnWidth108: {
        width: 108
    },
    customRestMargin: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginRight: 16,
                    flexBasis: "48%",
                };
            }
        }
    }
});
