import React from "react";
import { commonStyles } from "../assets/css/commonStyle";
import { fontFamilyStyles } from "../assets/css/commonFontStyles";
import { StyleSheet, View, Text } from "../core";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { uberDocThemeStyle } from "../assets/css/clients/uberDocThemeStyle";

export default class NoRecordsFound extends React.Component {
  render() {
    const { message, noRecords, isUberDocs } = this.props;
    let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
    let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
    return (
      <View style={[styles.nofoundmsg, commonStyles.flex1, commonStyles.flexGrow0, customCommonStyles.customNoRecordsFound, noRecords]}>
        <Text style={[commonFontStyles.workSans, commonStyles.whiteColor, commonStyles.fontWeight500, commonStyles.heading6, clientStyle.primaryTextColor]}>{message}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  nofoundmsg: {
    backgroundColor:"rgb(27, 99, 181)",
    padding: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    margin: 15,
  },
});
