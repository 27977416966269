import React from "react";
import R14, { View, Text, StyleSheet } from "../core";
import MobileStoreButton from "react-mobile-store-button";
import { getAndroidDeepLink, getIOSDeepLink } from "url-to-deep-link";
import { PLATFORM_NAME } from "../constant/app.data.constant";
import CommonUtility from "../utils/CommonUtility";

export default R14.connect(
  class ExternalScannerScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        osName: "",
        isShowButton: false,
      };
    }

    componentDidMount() {
     // if (Platform.OS === "web") {
        //this.downloadFile();
        this.setState({
          osName: CommonUtility.getMobileOperatingSystem(),
        });
        this.onRedirectDeepLinking();
      //}
    }

    onRedirectDeepLinking = async () => {
      const { osName } = this.state;
      const { threadId, uid, type } = this.props;
      let deepLinkUrl = `${window.location.origin}/login`;
      if (!!uid && !!type && this.props.app.route._location.pathname.toLowerCase().indexOf('claims/uploaded-bills/') > -1) {
        let bill = await this.props.app.dm.uploadBillDomain.fetchUploadedBill(uid, "email mobileNumber")
        if (!!bill && type === "N") {
          this.props.app.dm.userSession.setState({
            pchRegistrationTypePatient: true,
            pchRegistrationTypeProvider: false
          });
          this.props.app.dm.userLoginDomain.setState({
            socialLoginProfile: {
              mobileNumber: bill.mobileNumber.split(' ').pop(),
              email: bill.email,
            },
          });
          this.props.app.nav.to("pchSignup", { type: `new?ReturnUrl=claims/medical-bills/${uid}` });
        }
        else if (!!bill && (type === "E" || !type)) {
          this.props.app.dm.userLoginDomain.setState({
            socialLoginProfile: {
              mobileNumber: bill.mobileNumber.split(' ').pop(),
              email: bill.email,
            },
          });
          this.props.app.nav.to("pchLogin", { ReturnUrl: `claims/medical-bills/${uid}` });
        }
        else
          this.props.app.nav.to("pchLogin", { ReturnUrl: `claims/medical-bills/${uid}` });
        return false;
      }
      else if (!!threadId && this.props.app.route._location.pathname.toLowerCase().indexOf('scanbill/') > -1) {
        deepLinkUrl = `${window.location.origin}/login?ReturnUrl=bill-details/${threadId}`;
      }

      try {
        if (osName === PLATFORM_NAME.Android) document.location = getAndroidDeepLink(deepLinkUrl);
        else if (osName === PLATFORM_NAME.IOS) document.location = getIOSDeepLink(deepLinkUrl);
        else this.fallback(deepLinkUrl, true);
      } catch (err) {
        // Reload window to have correct document
        window.location.reload(true);
        this.fallback(deepLinkUrl, true);
      }
    };

    fallback = (deepLinkUrl, blank) => {
      // Open in same or different window the original URL
      if (blank) window.open(deepLinkUrl, "_self");
      else window.location = deepLinkUrl;
    };

    render() {
      const iosUrl = "https://apps.apple.com/ge/app/exela-smart-locker/id1531432794";
      const apkUrl = "https://play.google.com/store/apps/details?id=com.rule14.smartlocker";
      const { osName, isShowButton } = this.state;

      return (
        <View style={styles.camStyle}>
          {!!isShowButton ? (
            !!osName && osName !== PLATFORM_NAME.UNKNOWN ? (
              <MobileStoreButton
                store={osName.toLowerCase()}
                url={osName === PLATFORM_NAME.Android ? apkUrl : iosUrl}
                linkProps={{ title: `${osName} Store` }}
              />
            ) : (
              <View>
                <Text>{osName} Operating System</Text>
              </View>
            )
          ) : null}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  camStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },
});
