import React from "react";
import R14, { View, Text, StyleSheet, Button } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import SwitchToggleComponent from "./SwitchToggleComponent";
import ModalPopUp from "./ModalPopUp";
import PayerPerferenceBottomSection from "./PayerPerferenceBottomSection";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";

export default R14.connect(
    class PAMPerferencesComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                emailStatus: props.emailStatus,
                SMSstatus: props.SMSstatus,
                updatedPreference: {
                    emailStatus: props.emailStatus,
                    SMSstatus: props.SMSstatus
                },
            }
        }

        onEmailNotification = () => {
            this.setState({
                emailStatus: !this.state.emailStatus
            })
        }

        onSMSnotification = () => {
            this.setState({
                SMSstatus: !this.state.SMSstatus
            })
        }


        updateTextToPayPreference = () => {
            const { pam } = this.props;

            // if SMSstatus & emailStatus both are false then only textToPayPreference "None"
            let textToPayPreference = "None";
            // if SMSstatus & emailStatus both true then textToPayPreference "Both"
            if (this.state.SMSstatus && this.state.emailStatus) {
                textToPayPreference = "Both";
            } else if (this.state.emailStatus) {
                textToPayPreference = "Email";
            } else if (this.state.SMSstatus) {
                textToPayPreference = "SMS"
            }

            // update text to pay 
            if (this.state.updatedPreference.emailStatus !== this.state.emailStatus || this.state.updatedPreference.SMSstatus !== this.state.SMSstatus) {
                analyticsLogs(CommonString.PAM_DETAILS, `${CommonString.PAM_PREFERENCE_UPDATED}`, this.props.app);
                this.props.app.dm.myPreferenceDomain.updateTextToPayPreference(pam, { "textToPayPreference": textToPayPreference });
            }
            this.props.app.dm.commonDomain.showToastr("success", CommonString.PayerPreferences);
            this.setState({
                updatedPreference: {
                    emailStatus: this.state.emailStatus,
                    SMSstatus: this.state.SMSstatus
                }
            })
            this.handleCloseModel('onUpdate');
        }

        handleCloseModel = (status) => {
            if (status !== "onUpdate") {
                this.setState({
                    emailStatus: this.state.updatedPreference.emailStatus,
                    SMSstatus: this.state.updatedPreference.SMSstatus
                });
            }
            this.props.handleCloseModel();
        }

        render() {
            const { biller } = this.props;
            return (
                <ModalPopUp
                    visibility={!!this.props.visibility}
                    isFooter={true}
                    handleCancel={this.handleCloseModel}
                    bodyWrapper={[commonStyles.mediumModalBodyWrapper, this.props.moreInfo.status !== "accepted" || this.props.isBlocked ? customCommonStyles.prefrenceModalWrapper : customCommonStyles.prefrenceModalWrapperWithTextToPay, commonStyles.alignItemsFlexStart]}
                    handleOk={this.props.reSyncBiller}
                    headerStyle={[commonStyles.flex, commonStyles.justifyContentSpaceBetween]}
                    modalContent={[commonStyles.marginBottom0]}
                >
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentCenter, commonStyles.marginBottom10, commonStyles.alignItemsCenter, styles.modalTitleHolder]}>
                            <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight500, commonStyles.darkGrayColor]}>
                                Preferences
                            </Text>
                    </View>
                    <View style={[customCommonStyles.customMarginBottom]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.socialLoginText, commonStyles.fontWeight500, commonStyles.darkGrayColor]}>Biller Preferences</Text>
                        <SwitchToggleComponent
                            key={'Customer Service Engagement'}
                            containerStyle={styles.switchToggleBtnContainer}
                            paymentStatus={biller.messagingToggleValue}
                            textStyle={[styles.switchToggleText, commonFontStyles.workSans, commonStyles.heading6]}
                            paymentOption={'Customer Service Engagement'}
                        />
                    </View>
                    <View style={[customCommonStyles.payOptTitle]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight500, commonStyles.darkGrayColor]}>Payment Options:</Text>
                    </View>
                    <SwitchToggleComponent
                        key={'Payment - Full'}
                        containerStyle={[styles.switchToggleBtnContainer, commonStyles.flexBasis50]}
                        paymentStatus={biller.isPaymentsFull}
                        textStyle={[styles.switchToggleText, commonFontStyles.workSans, commonStyles.heading6]}
                        paymentOption={'Payment - Full'}
                    />
                    <SwitchToggleComponent
                        key={'Payment - Partial'}
                        containerStyle={[styles.switchToggleBtnContainer, commonStyles.flexBasis50]}
                        paymentStatus={biller.isPaymentsPartial}
                        textStyle={[styles.switchToggleText, commonFontStyles.workSans, commonStyles.heading6]}
                        paymentOption={'Payment - Partial'}
                    />
                    <SwitchToggleComponent
                        key={'Payment decline'}
                        containerStyle={[styles.switchToggleBtnContainer, commonStyles.flexBasis50]}
                        paymentStatus={biller.isPaymentDecline}
                        textStyle={[styles.switchToggleText, commonFontStyles.workSans, commonStyles.heading6]}
                        paymentOption={'Payment decline'}
                    />
                    <SwitchToggleComponent
                        key={'Payment due date extensions'}
                        containerStyle={[styles.switchToggleBtnContainer, commonStyles.flexBasis50]}
                        paymentStatus={biller.isPaymentDueDateExtensions}
                        textStyle={[styles.switchToggleText, commonFontStyles.workSans, commonStyles.heading6]}
                        paymentOption={'Payment due date extensions'}
                    />
                    <View style={[styles.reSyncmodelPopupButton]}>
                        <View style={[commonStyles.flex1, commonStyles.flexGrow0, commonStyles.marLAuto, commonStyles.alignItemsEnd]}>
                            <Button
                                title={"Re-Sync"}
                                key="reSyncBiller"
                                titleColor={commonStyles.sectionHeader.color}
                                style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.minwidth, commonStyles.marLAuto]}
                                titleTextStyle={[
                                    commonStyles.whiteColor,
                                    commonStyles.fontWeight500,
                                    commonFontStyles.workSans,
                                    commonStyles.exSmallHeading,
                                ]}
                                onPress={this.props.reSyncBiller}
                            />
                        </View>
                    </View>
                    {this.props.moreInfo.status !== "accepted" || this.props.isBlocked ? null : (
                        <>
                            <PayerPerferenceBottomSection
                                emailStatus={this.state.emailStatus}
                                SMSstatus={this.state.SMSstatus}
                                onEmailNotification={this.onEmailNotification}
                                onSMSnotification={this.onSMSnotification}
                                bottomControlsViewStyle={[commonStyles.marginTop20]}
                            />
                            <View style={[styles.reSyncmodelPopupButton]}>
                                <View style={[commonStyles.flex1, commonStyles.flexGrow0, commonStyles.marLAuto, commonStyles.alignItemsEnd]}>
                                    <Button
                                        title={"Update"}
                                        key="updatePayerPreference"
                                        titleColor={commonStyles.sectionHeader.color}
                                        style={[commonStyles.blueButton, commonStyles.paddingLeftRight16, styles.minwidth, customCommonStyles.noBoxShadow]}
                                        pointerEvents={this.state.updatedPreference.emailStatus !== this.state.emailStatus || this.state.updatedPreference.SMSstatus !== this.state.SMSstatus ? "none" : null}
                                        titleTextStyle={[
                                            commonStyles.whiteColor,
                                            commonStyles.fontWeight500,
                                            commonFontStyles.workSans,
                                            commonStyles.exSmallHeading
                                        ]}
                                        onPress={this.state.updatedPreference.emailStatus !== this.state.emailStatus || this.state.updatedPreference.SMSstatus !== this.state.SMSstatus ? this.updateTextToPayPreference : null}
                                        disabled={this.state.updatedPreference.emailStatus !== this.state.emailStatus || this.state.updatedPreference.SMSstatus !== this.state.SMSstatus ? false : true}
                                    />
                                </View>
                            </View>
                        </>
                    )}
                </ModalPopUp>
            );
        }
    });

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: "#fff",
        // marginLeft: 15,
        // marginRight: 15,
        // marginBottom: 15,
        // paddingTop: 15,
        // paddingBottom: 15,
        screen: ({ width, height }) => {
            if (width <= 991) {
                return {
                    // display: "none",
                    // marginTop: 0,
                    // marginRight: 0,
                    // marginBottom: 0,
                    // marginLeft: 0,
                };
            }
            if (width === 1366 && height === 657) {
                return {
                    // marginBottom: 7,
                };
            }
        },
    },
    switchToggleText: {
        marginTop: -2.5,
        marginLeft: 10
    },
    switchToggleBtnContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 15,
        alignItems: "center",
        minHeight: 20,
        maxHeight: 20,
    },
    activeButton: {
        backgroundColor: "rgba(255,255,255, 1.0)",
        // borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#37c2ef",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        boxShadow: "none",
        // marginRight: 15,
        justifyContent: 'center',
        height: 38,
        // width: 105,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    // width: '40vw',
                    height: 38,
                    marginTop: 15,
                    alignItems: "flex-end"

                }
            }
        }
    },

    modelPopupButton: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    flexBasis: "20%",
                    flexWrap: 'wrap',
                    marginLeft: 45,
                    justifyContent: 'flex-end'
                }
            }
        }
    },
    reSyncmodelPopupButton: {
        marginTop: 20,
        minHeight: 38,
    },
    buttonContainers: {
        flexDirection: "column",
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    flex: 1,
                    flexDirection: 'row',
                }
            }
        }
    },
    minwidth: {
        minWidth: 105,
        width: 105
    },
    closeButtonWrapper: {
        width: 20,
        height: 20,
        maxHeight: 20,
    },
    closeButton: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalTitleHolder: {
        flexGrow: 0,
        maxHeight: 23,
        minHeight: 23,
    },
});