import R14 from "../../core";

export default class MOOPDomain extends R14.Domain {
    
    async getMoopData(insurance) {
        let userdetail = await this.dm.rest.getUser();
        let fields = "uid createdBy uploadBillUid memberClaimsAmount toDateOfService fromDateOfService checkNo deductible copay coinsurance paidDate paidAmount";
        let user = {
            "user": userdetail.uid,
            "insurance": insurance
        }
        try {
            let result = await this.dm.queryDomain.middleware(this.api.qry(`query GetMOOPdata($user: String!, $insurance: String!){
            getMOOPdata(user: $user, insurance: $insurance){
                totalCount @include(if: true)
                nodes {
                  ${fields}
                }
              }
            }`,
                user));
            if (!!result.data.getMOOPdata && !!result.data.getMOOPdata.nodes) {
                return result.data.getMOOPdata.nodes;
            }
            else return [];
        } catch (error) {
            console.log(error)
            return [];
        }
    }
}