import React from "react";
import R14, {
  Form,
  View,
  Text,
  StyleSheet,
  Button,
  SubmitButton,
  TextInputField,
  Platform,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { commonMetricsStyle } from "../../assets/css/commonMetricsStyle";
import { CommonString } from "../../constant/appCommonText";
import CommonValidators from "../../utils/CommonValidators";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import ModalPopUp from "../../components/ModalPopUp";
import DatePicker from "../../components/PCH/DatePicker"

import DateUtils from "../../utils/DateUtils";
import TableListComponent from "../../components/TableListComponent";
import DropDown from "../../components/DropDown";
import PopupModalComponent from "../../components/PopupModalComponent";
import { MESSAGE_TYPE } from "../../constant/app.data.constant";
import { analyticsLogs } from "../../utils/AnalyticLogs";
const editIcon = require("../../assets/images/edit.png");

const PaymentModes = [{ label: "Cash", value: "Cash" }, { label: "Cheque", value: "Cheque" }, { label: "ACH", value: "ACH" }, { label: "Credit Card", value: "Credit Card" }, { label: "Wire Transfer", value: "Wire Transfer" }];

export default R14.connect(
  class ReceivePaymentsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        totalDueAmount: 0,
        totalAppliedAmount: 0,
        resultsPerPage: 9,
        currentPage: 1,
        totalBills: 0,
        loaderVisible: false,
        confirmationPopUp: false,
        editAmountItem: {},
      };
      this.form = null;
      this.businessList = [];
      this.billList = [];
      this.selectedBusiness = {};
      this.selectedPaymentMode = {};
      this.selectedPaymentStatus = {};
    }

    componentDidMount = async () => {
      try {
        this.setState({loaderVisible: true})
        this.form = this.props.app.ui.form("receivepayments");
        let date = DateUtils.formatDateInMMDDYYYY(new Date());
        this.form.elmts.paymentDate.setValue(date);
        this.onSearchBusiness();
        this.setState({loaderVisible: false })
      }
      catch (err) {
        console.log(err)
      }
    };

    onDiscard = async (reset) => {
      if(!!reset) analyticsLogs(CommonString.SMB_RECEIVE_PAYMENT, `${CommonString.CLICKED_ON} ${reset}`, this.props.app);
      this.form.reset();
      this.onClear();
    };


    onClear = () => {
      const form = this.props.app.ui.form("receivepayments");
      form.elmts.businessName.setValue("");
      form.elmts.paymentRefNo.setValue("");
      form.elmts.referenceNumber.setValue("");
      form.elmts.paymentDate.setValue("");
      form.elmts.paymentAmount.setValue("");
      form.elmts.notes.setValue("");
      form.elmts.paymentMode.setValue("");
      form.elmts.paymentStatus.setValue("");

      form.elmts.businessName.setError(null);
      form.elmts.paymentRefNo.setError(null);
      form.elmts.referenceNumber.setError(null);
      form.elmts.paymentDate.setError(null);
      form.elmts.paymentAmount.setError(null);
      form.elmts.notes.setError(null);
      form.elmts.paymentMode.setError(null);
      form.elmts.paymentStatus.setError(null);

      let date = DateUtils.formatDateInMMDDYYYY(new Date());
      this.form.elmts.paymentDate.setValue(date);
      // after clear, reset applied amount and update due amount if required
      this.billList = [];
      this.selectedPaymentStatus = {};
      this.selectedBusiness = {};
      this.selectedPaymentMode = {};
      this.onSearchBusiness();
      this.setState({ attachments: [], totalDueAmount: 0, totalAppliedAmount: 0, currentPage: 1, totalBills: 0, confirmationPopUp: false, loaderVisible: false });
    };

    onPaymentModeChange = (item) => {
      const { label } = item;
      const form = this.props.app.ui.form("receivepayments");
      if(label === "No Results Found" || label.includes("Type in a search value")){
        form.elmts.paymentMode.setValue(null);
        return false;
      }
      if(Platform.OS !== "web") form.elmts.paymentMode.setValue(label);
      form.elmts.paymentMode.setError(null);
      this.selectedPaymentMode = item;
    }

    onPaymentStatusChange =(item) => {
      const { label } = item;
      const form = this.props.app.ui.form("receivepayments");
      if(label === "No Results Found" || label.includes("Type in a search value")){
        form.elmts.paymentStatus.setValue(null);
        return false;
      }
      if(Platform.OS !== "web") form.elmts.paymentStatus.setValue(label);
      form.elmts.paymentStatus.setError(null);
      this.selectedPaymentStatus = item;
    }

    onBusinessChange = async (item) => {
      const { label, value } = item;
      const form = this.props.app.ui.form("receivepayments");
      this.onDiscard();
      if(label === "No Results Found" || label.includes("Type in a search value")){
        form.elmts.paymentRefNo.setValue(null);
        form.elmts.businessName.setValue(null);
        this.billList = [];
        this.setState({totalBills: 0, totalDueAmount: 0, totalAppliedAmount: 0, currentPage: 1})
        return false;
      }
      const businessDetails = this.businessList.find(business => business.value === value);
      if(Platform.OS !== "web") form.elmts.businessName.setValue(label)
      else form.elmts.businessName.setValue(item);
      form.elmts.businessName.setError(null);
      this.selectedBusiness = businessDetails;

      this.setState({loaderVisible: true});
      if(businessDetails.value){
        const invoiceList = await this.props.app.dm.smbApi.getSMBCustomerInvoiceList({
          customerID: `${businessDetails.value}`,
          pid: this.props.user.pid,
        });
        if(invoiceList.paymentRefID) form.elmts.paymentRefNo.setValue(invoiceList.paymentRefID + 1);
        else form.elmts.paymentRefNo.setValue(1);
        this.billList = invoiceList.data;
        this.onTotalDueAmount(invoiceList.data);
        this.setState({ totalBills: invoiceList.data.length, loaderVisible: false });
        return false;
      }
      this.setState({loaderVisible: false});
      return false;
    }

    onTotalAppliedAmount = (txt) => {
      const form = this.props.app.ui.form("receivepayments");
      if (isNaN(txt)) {
        form.elmts.paymentAmount.setValue(!!txt ? txt.substr(0, txt.length - 1) : null);
        return;
      };

      if(!this.selectedBusiness || !this.selectedBusiness.businessName) {
        form.elmts.paymentAmount.setError(CommonString.BusinessNameRequired)
        form.elmts.paymentAmount.setValue(!!txt ? txt.substr(0, txt.length - 1) : null);
        return;
      }

      if(this.billList && !this.billList.length) {
        form.elmts.paymentAmount.setError(CommonString.NoPendingInvoice)
        form.elmts.paymentAmount.setValue(!!txt ? txt.substr(0, txt.length - 1) : null);
        return;
      }

      let text = `${txt}`;
      if (txt[txt.length - 1] !== "." && txt[txt.length - 1] !== "0") text = parseFloat(txt.toString().match(/^-?\d+(?:\.\d{0,2})?/)) || 0;

      form.elmts.paymentAmount.setError("");
      let payableAmount = text;
      const appliedBills = this.billList.map(bill => {
        if (+bill.dueAmount <= payableAmount) {
          payableAmount = (payableAmount - +bill.dueAmount).toFixed(2);
          return { ...bill, appliedAmount: (+bill.dueAmount).toFixed(2) }
        } else {
          const result = { ...bill, appliedAmount: payableAmount }
          payableAmount = 0;
          return result;
        }
      })
      this.billList = appliedBills;
      this.onTotalDueAmount(appliedBills);
      form.elmts.paymentAmount.setValue(text);
      this.setState({ totalAppliedAmount: Math.min(+this.state.totalDueAmount, +text) });
    }

    onTotalDueAmount = (bills = []) => {
      let totalDue = bills.reduce((sum, currentBill) => sum + +currentBill.dueAmount, 0);
      totalDue = totalDue.toFixed(2);
      this.setState({ totalDueAmount: +totalDue });
    }

    onSearchBusiness = async (search = "", limit = 10) => {
      const form = this.props.app.ui.form("receivepayments");
      try {
        const txt = !!search && search.label ? search.label : search;
        // graphql API
        let businesses = await this.props.app.dm.smbApi.getSMBCustomer({
          search: txt,
          pid: this.props.user.pid,
          resultsPerPage: limit,
        });
        businesses = !!businesses && businesses.length ? businesses : [];
        businesses = businesses.map(business => {
          return {
            ...business,
            label: business.businessName,
            value: business.custID
          }
        });
        this.businessList = businesses;
        form.elmts.businessName.setItems(businesses);
        return businesses;
      } catch (error) {
        console.log('error while fetching venders list', error);
        if (!!form && form.elmts && form.elmts.businessName) form.elmts.businessName.setItems([]);
        return [];
      }
    };

    onConfirmationPopUp = (form) => {
        const { paymentAmount } = form.values;
        if(+paymentAmount < this.state.totalAppliedAmount ) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.AppliedAmountMoreThanAmount);
          return false;
        } else if(+paymentAmount > this.state.totalAppliedAmount ) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.AppliedAmountLessThanAmount);
          return false;
        };
        if(!this.selectedBusiness || !this.selectedBusiness.value || !this.selectedPaymentMode || !this.selectedPaymentMode.value || !this.selectedPaymentStatus || !this.selectedPaymentStatus.value  ) {
          if(!this.selectedBusiness || !this.selectedBusiness.value) form.elmts.businessName.setError(`Select Business Name`);
          if (!this.selectedPaymentMode || !this.selectedPaymentMode.value) form.elmts.paymentMode.setError(`Select Payment Mode`);
          if (!this.selectedPaymentStatus || !this.selectedPaymentStatus.value ) form.elmts.paymentStatus.setError(`Select Payment Status`);
          return false;
        }
        this.setState({confirmationPopUp: true});
        return false;
    }

    handleSubmit = async (form) => {
      try {
        const { paymentRefNo, referenceNumber, paymentDate, paymentAmount, notes } = form.values;
        if(+paymentAmount !== this.state.totalAppliedAmount ) {
          this.props.app.dm.commonDomain.showToastr("error", `Amount $${paymentAmount} & Total Applied $${this.state.totalAppliedAmount} amount are not same.`);
          return false;
        }

        const payload = {
          "pid": this.props.user.pid,
          "type": "invoices",
          "customerID": `${this.selectedBusiness.value}`,
          "paymentRefId": `${paymentRefNo}`,
          "bankCharges": `0`,
          "paymentDate": paymentDate,
          "paymentType": this.selectedPaymentMode.value,
          "referenceNo": `${!!referenceNumber ? referenceNumber : ""}`,
          "paymentStatus": this.selectedPaymentStatus.value,
          "paymentMode": this.selectedPaymentMode.value,
          "notes": `${notes}`,
        }
        let invoices = this.billList.map(bill => {
          if(bill.appliedAmount){
            return {
              "invoiceID": `${bill.invoiceID}`,
              "paymentAmount": `${bill.appliedAmount}`
            }
          };
          return false;
        });
        invoices = invoices.filter(Boolean);
        payload.invoices = invoices;
        if (!payload.customerID || !paymentAmount) return false;
        this.setState({loaderVisible: true});
        await this.props.app.dm.smbApi.saveSmbReceivePayment(payload);
        this.onClear()
        analyticsLogs(CommonString.SMB_RECEIVE_PAYMENT, `${CommonString.RecordPaymentMessage}`, this.props.app);
        this.props.app.dm.commonDomain.showToastr("success", CommonString.RecordPaymentMessage);
        return false;
      } catch (error) {
        this.setState({loaderVisible: false});
        this.props.app.dm.commonDomain.showToastr("error", error);
      }
    };

    onDateChange = async (val, autoformat = true) => {
      if (!!val) {
        if (!!autoformat) {
          let date = DateUtils.formatDateInMMDDYYYY(val);
          if (!!date && new Date(date) > new Date()) {
            this.form.elmts.paymentDate.setValue(null);
            this.form.elmts.paymentDate.setError(CommonString.DateCanNotFuture);
            return false;
          }
        }
        else if (new Date(val) > new Date()) {
          this.form.elmts.paymentDate.setValue(null);
          this.form.elmts.paymentDate.setError(CommonString.DateCanNotFuture);
          return false;
        }
      }
    };

    getNext = async () => {
      let { currentPage, totalBills, resultsPerPage } = this.state;
      const totalPages = Math.ceil(totalBills / resultsPerPage);
      if (currentPage <= totalPages) {
        currentPage = currentPage + 1;
        this.setState({ loaderVisible: true });
        // const payload = {
        //   "pid": this.props.user.pid,
        //   type,
        //   approvalStatus,
        //   paymentStatus,
        //   "page": currentPage,
        //   resultsPerPage: currentPage * resultsPerPage,
        // };
        // const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
        let bill = [];
        bill = bill.slice((currentPage - 1) * resultsPerPage, currentPage * (resultsPerPage + 1));
        this.billList = bill;
        this.onTotalDueAmount(bill);
        this.setState({
          totalBills: bill.length,
          loaderVisible: false,
          currentPage: currentPage,
        });
      }
    }

    getPrevious = async () => {
      let { currentPage, resultsPerPage } = this.state;
      if (currentPage >= 1) {
        currentPage = currentPage - 1;
        if (currentPage > 0) {
          this.setState({ loaderVisible: true });
          // const payload = {
          //   "pid": this.props.user.pid,
          //   type,
          //   approvalStatus,
          //   paymentStatus,
          //   "page": currentPage,
          //   resultsPerPage: currentPage * resultsPerPage
          // };
          // const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);

          let bill = [];
          bill = bill.slice((currentPage - 1) * resultsPerPage, currentPage * (resultsPerPage + 1));
          this.billList = bill;
          this.onTotalDueAmount(bill);
          this.setState({
            totalBills: bill.length,
            loaderVisible: false,
            currentPage: currentPage,
          });
        }
      }
    }


    getFirst = async () => {
      this.setState({ loaderVisible: true });
      // const payload = {
      //   "pid": this.props.user.pid,
      //   type,
      //   approvalStatus: this.state.approvalStatus,
      //   paymentStatus,
      //   "page": 1,
      //   "resultsPerPage": this.state.resultsPerPage
      // };
      // const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
      let bill = [];
      bill = bill.slice(0, this.state.resultsPerPage + 1);
      this.billList = bill;
      this.onTotalDueAmount(bill);
      this.setState({
        totalBills: bill.length,
        loaderVisible: false,
        currentPage: 1,
      });
    }

    getLast = async () => {
      let { totalBills, resultsPerPage } = this.state;
      const totalPages = Math.ceil(totalBills / resultsPerPage);

      this.setState({ loaderVisible: true });
      // const payload = {
      //   "pid": this.props.user.pid,
      //   type,
      //   approvalStatus,
      //   paymentStatus,
      //   "page": totalPages,
      //   resultsPerPage: totalPages * resultsPerPage
      // };
      // const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
      let bill = [];
      bill = bill.slice((totalPages - 1) * resultsPerPage, totalPages * (resultsPerPage + 1));
      this.billList = bill;
      this.onTotalDueAmount(bill);
      this.setState({
        totalBills: bill.length,
        loaderVisible: false,
        currentPage: totalPages,
      });
    }

    onActionClick = (item) => {
      this.setState({editAmountItem: item});
      analyticsLogs(CommonString.SMB_RECEIVE_PAYMENT, `${CommonString.EDIT_BILL_ICON}`, this.props.app);

      // render editAmountForm takes some times that's why added setTimeout
      setTimeout(() => {
        const form = this.props.app.ui.form("editAmountForm");
        form.elmts.invoiceNumber.setValue(item.invoiceNumber);
        form.elmts.invoiceAmount.setValue((+item.invoiceAmount).toFixed(2));
        form.elmts.dueAmount.setValue((+item.dueAmount).toFixed(2));
        form.elmts.appliedAmount.setValue(item.appliedAmount);
      }, 0)
    }

    onEditAppliedAmt = (txt) => {
      const form = this.props.app.ui.form("editAmountForm");
      // const form = this.props.app.ui.form("receivepayments");
      if (isNaN(txt)) {
        form.elmts.appliedAmount.setValue(!!txt ? txt.substr(0, txt.length - 1) : null);
        return;
      };
      const {totalDueAmount, editAmountItem} = this.state;

      if (editAmountItem.dueAmount < +txt) {
        form.elmts.appliedAmount.setError("Amount can't be more than due amount")
        form.elmts.appliedAmount.setValue(!!txt ? txt.substr(0, txt.length - 1) : null);
        return;
      };
      if (totalDueAmount < +txt) {
        form.elmts.appliedAmount.setError("Total applied amount can't be more than total due amount")
        form.elmts.appliedAmount.setValue(!!txt ? txt.substr(0, txt.length - 1) : null);
        return;
      };
      let text = txt;
      if (txt[txt.length - 1] === ".") text = txt;
      else text = parseFloat(txt.toString().match(/^-?\d+(?:\.\d{0,2})?/)) || 0;

      form.elmts.appliedAmount.setError("");
      let appliedAmount = text;
      const appliedBills = this.billList.map(bill => {
        if (+bill.dueAmount <= appliedAmount) {
          appliedAmount = (appliedAmount - +bill.dueAmount).toFixed(2);
          return { ...bill, appliedAmount: this.state.editAmountItem.invoiceNumber === bill.invoiceNumber ? (+bill.dueAmount).toFixed(2) : bill.appliedAmount }
        } else {
          const result = { ...bill, appliedAmount: this.state.editAmountItem.invoiceNumber === bill.invoiceNumber ? appliedAmount : bill.appliedAmount }
          appliedAmount = 0;
          return result;
        }
      })
      this.billList = appliedBills;
      form.elmts.appliedAmount.setValue(text);
      return false;
    }

    onEditAmountSubmit = (form) => {
      const {appliedAmount, invoiceNumber} = form.values;
      const appliedBills = this.billList.map(bill => {
        return {
          ...bill,
          appliedAmount: bill.invoiceNumber === invoiceNumber ? appliedAmount : (bill.appliedAmount || 0)
        }
      });

      let totalPaymentAmt = appliedBills.reduce((sum, currentBill) => sum + +currentBill.appliedAmount, 0);
      totalPaymentAmt = totalPaymentAmt.toFixed(2);

      this.billList = appliedBills;
      analyticsLogs(CommonString.SMB_RECEIVE_PAYMENT, `${CommonString.Applied_Amount_Updated}`, this.props.app);
      // this.props.app.ui.form("receivepayments").elmts.paymentAmount.setValue(totalPaymentAmt);
      this.setState({ totalAppliedAmount: totalPaymentAmt, editAmountItem: {} });
      return false;
    }

    onInvalidTypeText = (txt, key, form = this.props.app.ui.form("receivepayments")) => {
        return form.elmts[key].setValue(txt.slice(0, -1))
    }

    onTrimText = (txt, key, max, form = this.props.app.ui.form("receivepayments")) => {
      return form.elmts[key].setValue(txt.slice(0, max))
    }

    renderEditAmountModalPopUp = (item) => {
      const commonFontStyles = fontFamilyStyles;
      return (
        <ModalPopUp
          visibility={!!item && !!item.invoiceNumber}
          isFooter={false}
          bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.editAppliedAmtPopUp]}>
        <View style={[styles.mainContainer]}>
          <View style={commonStyles.textAlignCenter}>
            <Text style={[commonStyles.marginLeft15, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.textAlignCenter]}>Edit Applied Amount</Text>
          </View>
            <Form
              name="editAmountForm"
              style={[styles.editAmountForm, commonStyles.justifyContentFlexStart]}
              validators={CommonValidators.getFormValidator()}
              validateBeforeSubmit={true}
              onSubmit={this.onEditAmountSubmit}>
              <View style={[commonStyles.flex1,]}>
                <View style={[styles.leftInputField]}>
                  <TextInputField
                    name="invoiceNumber"
                    label={`Invoice#`}
                    disabled={true}
                    style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
                <View style={[styles.leftInputField]}>
                  <TextInputField
                    name="invoiceAmount"
                    label={`Invoice Amount`}
                    disabled={true}
                    style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
                <View style={[styles.leftInputField]}>
                  <TextInputField
                    name="dueAmount"
                    label={`Due Amount`}
                    disabled={true}
                    style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
                <View style={[styles.leftInputField]}>
                  <TextInputField
                    name="appliedAmount"
                    label={`Applied Amount*`}
                    required={CommonString.AppliedAmountRequired}
                    onChangeText={(txt) => this.onEditAppliedAmt(txt)}
                    style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  />
                </View>
              </View>
              {/* Back & Add button */}
              <View style={[styles.buttonContainter, Platform.OS === "web" ? styles.mViewButtonContainter : null]} >
                  <Button
                    title={"Back"}
                    key="BackEditFormButton"
                    style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, commonStyles.marginRight15, commonStyles.flexBasis48, styles.customBtnWidth,]}
                    titleTextStyle={[commonStyles.secondayWhiteBtnText, commonFontStyles.workSans]}
                    onPress={() => {
                      this.setState({editAmountItem: {}})}
                    }
                  />
                  <SubmitButton
                    title={"Update"}
                    key="editAmountFormButton"
                    style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, commonStyles.flexBasis48, styles.customBtnWidth,]}
                    titleTextStyle={[commonStyles.primaryBtnText, commonFontStyles.workSans]}
                  />
              </View>
          </Form>
        </View>
        </ModalPopUp>
      )
    }


    render() {
      const clientStyle = commonStyles;
      const commonFontStyles = fontFamilyStyles;
      const {
        totalAppliedAmount,
        totalDueAmount,
        resultsPerPage,
        currentPage,
        totalBills,
        loaderVisible,
        confirmationPopUp,
        editAmountItem
      } = this.state;

      const totalPages = Math.ceil(totalBills / resultsPerPage);
      let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
      let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
      let rowItems = endIndex - startIndex + 1;
      const paymentAmount = this.props.app.ui.form("receivepayments") && this.props.app.ui.form("receivepayments").values && this.props.app.ui.form("receivepayments").values.paymentAmount ? this.props.app.ui.form("receivepayments").values.paymentAmount : 0;
      return (
        <>
          {this.renderEditAmountModalPopUp(editAmountItem)}
            <View style={[styles.uploadBillSection, clientStyle.secondaryBgColor]}>
              <Form
                name="receivepayments"
                style={[styles.uploadBillsForm, clientStyle.form]}
                validateBeforeSubmit={true}
                validators={CommonValidators.getFormValidator()}
                onSubmit={this.onConfirmationPopUp}>
                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.leftInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.customFlexDirection,
                      styles.customAlignItemsStart,
                      styles.textAlign,
                      Platform.OS === "web" ? commonStyles.marginBottom30 : styles.customMarginBottom16
                    ]}>
                    <DropDown
                      name="businessName"
                      label={"Business Name*"}
                      required={CommonString.BusinessNameRequired}
                      // for web - onValueChange
                      onValueChange={this.onBusinessChange}
                      searchable={true}
                      itemLoader={async ({ search }) => await this.onSearchBusiness(search)}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100, styles.minWidth100]}
                      // for native - onItemSelect
                      onItemSelect={this.onBusinessChange}
                      // for native - onDataProvider must return array [{label, value}]
                      onDataProvider={(search) => this.onSearchBusiness(search)}
                      setExistingValue={""}
                      defaultValue={()=> ""}
                      searchableTextBoxStyle={[commonStyles.marB0]}
                      searchableTextBoxStyleHolder={[commonStyles.marginBottom16, commonStyles.fullFlexContainer, commonStyles.maxWidth100,]}
                      readOnly={false}
                      disabled={false}
                      onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "businessName")}
                    />
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis48, styles.customMarginBottom30,]}>
                    <TextInputField
                      name="paymentRefNo"
                      label="Payment Ref No#"
                      disabled={true}
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      readOnly={true}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                    />
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown, styles.customMarginBottom30,]}>
                    <TextInputField
                      name={"paymentAmount"}
                      label={"Amount* ($)"}
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                      onChangeText={txt => this.onTotalAppliedAmount(txt)}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      required={CommonString.AmountRequired}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                  </View>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.rightInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.flexDirectionColumn,
                      styles.customAlignItemsStart,
                      styles.textAlign,
                      commonStyles.alignItemsStart,
                      styles.customMarginBottom30,
                      Platform.OS === "web" ? null : commonStyles.flexDirectionRow,
                    ]}>
                      <DatePicker
                        name={"paymentDate"}
                        label={"Payment Date*"}
                        formName={"receivepayments"}
                        props={this.props}
                        onValueChange ={this.onDateChange}
                        required={CommonString.SelectPaymentDate}
                        style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.customWidth49, commonStyles.maxWidth100, ]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                        helperTextStyle={[commonStyles.left0, commonFontStyles.workSans]}
                      />
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.leftInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.customFlexDirection,
                      styles.customAlignItemsStart,
                      styles.textAlign,
                      styles.customWidth,
                      Platform.OS === "web" ? null : styles.customMarginBottom16
                    ]}>
                    <DropDown
                      name="paymentMode"
                      label={"Payment Mode*"}
                      required={CommonString.SelectPaymentModeRequired}
                      items={PaymentModes}
                      searchable={false}
                      itemLoader={() => PaymentModes}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      style={[customCommonStyles.inputHeight, styles.width100, styles.minWidth100]}
                      // for web
                      onValueChange={this.onPaymentModeChange}
                      // for native 
                      onItemSelect={this.onPaymentModeChange}
                      // for native - onDataProvider must return array [{label, value}]
                      onDataProvider={() => PaymentModes}
                      setExistingValue={() => ""}
                      defaultValue={() => ""}
                      searchableTextBoxStyle={[commonStyles.marB0]}
                      searchableTextBoxStyleHolder={[commonStyles.marginBottom16, commonStyles.fullFlexContainer, commonStyles.maxWidth100,]}
                      readOnly={false}
                      disabled={false}
                      onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "paymentMode")}
                    />
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis48, Platform.OS === "web" ? null : styles.customMarginBottom16]}>
                    <DropDown
                      name="paymentStatus"
                      label={"Payment Status*"}
                      required={CommonString.SelectPaymentStatusRequired}
                      items={[{ label: "Paid", value: "Paid" }]}
                      searchable={false}
                      itemLoader={async () => [{ label: "Paid", value: "Paid" }]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      style={[customCommonStyles.inputHeight, styles.width100, styles.minWidth100]}
                      // for web
                      onValueChange={this.onPaymentStatusChange}
                      // for native 
                      onItemSelect={this.onPaymentStatusChange}
                      // for native - onDataProvider must return array [{label, value}]
                      onDataProvider={() => [{ label: "Paid", value: "Paid" }]}
                      setExistingValue={() => ""}
                      // to show all items [don't pass default search value]. --> defaultValue={() => ""}
                      defaultValue={() => ""}
                      searchableTextBoxStyle={[commonStyles.marB0]}
                      searchableTextBoxStyleHolder={[commonStyles.marginBottom16, commonStyles.fullFlexContainer, commonStyles.maxWidth100,]}
                      readOnly={false}
                      disabled={false}
                      onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "paymentStatus")}
                    />
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.addIncProTextWrap]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, styles.incNameDropDown]}>
                    <TextInputField
                      name="referenceNumber"
                      label="Reference#"
                      onChangeText={(txt) => this.onTrimText(txt, "referenceNumber", 30)}
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />

                  </View>
                  <View
                    style={[
                      commonStyles.flex1,
                      styles.rightInputHolder,
                      commonStyles.flexBasis48,
                      commonStyles.customFlexDirection,
                      styles.customAlignItemsStart,
                      styles.textAlign,
                      styles.customMarginBottom30
                    ]}>
                    <TextInputField
                      name="notes"
                      label="Note"
                      style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight, styles.width100, commonStyles.fullFlexContainer]}
                      labelTextStyle={[
                        commonStyles.labelText,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        clientStyle.secondaryBgColor
                      ]}
                      onChangeText={(txt) => this.onTrimText(txt, "notes", 75)}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                  </View>
                </View>
                <View style={[styles.customMarTAuto, commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow]}>
                  <View style={[styles.formBtnsHolder]}>
                    <Button
                      title={"Reset"}
                      style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.submitBtn, commonStyles.marginRight10, styles.customBtnWidth,]}
                      titleTextStyle={[commonStyles.secondayWhiteBtnText, commonFontStyles.workSans]}
                      onPress={() => this.onDiscard('Reset')}
                    />
                    <SubmitButton
                      title={"Record Payment"}
                      style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, commonStyles.marginLeft16, styles.customSubmitBtn, styles.submitBtn, styles.customBtnWidth, ]}
                      titleTextStyle={[commonStyles.primaryBtnText, commonFontStyles.workSans, clientStyle.submitButtonText]}
                    />
                  </View>
                </View>
              </Form>
              <View style={[commonStyles.padding16, commonStyles.paddingBottom10, commonStyles.paddingTop0, styles.customBorderTop, this.selectedBusiness && this.selectedBusiness.value ? null : commonStyles.displayNone]}>
                <Text style={[
                  commonFontStyles.workSans,
                  commonStyles.heading4,
                  commonStyles.dBlock,
                  commonStyles.fontWeight500
                ]}>List of Pending Invoice for the Customer</Text>
              </View>
              <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.padding16, commonStyles.justifyContentSpaceBetween, commonStyles.paddingTop0, commonStyles.paddingBottom10, styles.customBorderBottom, this.selectedBusiness && this.selectedBusiness.value ? null : commonStyles.displayNone]}>
                <View>
                  <Text style={[
                    commonFontStyles.workSans,
                    commonStyles.heading5,
                    commonStyles.dBlock,
                    commonStyles.fontWeight500
                  ]}>Total Due Amount: ${totalDueAmount}</Text>
                </View>
                <View style={[styles.customPaddingTop5]}>
                  <Text style={[
                    commonFontStyles.workSans,
                    commonStyles.heading5,
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.blueBorderButtonColor
                  ]}>Balance Amount: ${ ( +paymentAmount - +totalAppliedAmount > 0 ? +paymentAmount - +totalAppliedAmount : 0).toFixed(2) }</Text>
                </View>
                <View style={[styles.customPaddingTop5]}>
                  <Text style={[
                    commonFontStyles.workSans,
                    commonStyles.heading5,
                    commonStyles.dBlock,
                    commonStyles.fontWeight500
                  ]}>Total Applied Amount: ${totalAppliedAmount}</Text>
                </View>
              </View>
              {this.selectedBusiness && this.selectedBusiness.value ? (
                <View style={[styles.padding16]}>
                <TableListComponent
                totalPages={totalPages}
                startIndex={startIndex}
                endIndex={endIndex}
                rowItems={rowItems}
                dataList={this.billList}
                currentPage={currentPage}
                totalBills={totalBills}
                pagination={false}
                getFirst={() => this.getFirst()}
                getLast={() => this.getLast()}
                getNext={() => this.getNext()}
                getPrevious={() => this.getPrevious()}
                labelKeys={["Invoice", "Business Name", "Currency", "Invoice Amount", "Invoice Date",  "Due Amount", "Due Date", "Applied Amount", "Action"]}
                labelValues={["invoiceNumber", "businessName", "currency", "invoiceAmount", "invoiceDate", "dueAmount", "dueDate", "appliedAmount", "edit"]}
                visibility={!loaderVisible}
                icon={editIcon}
                onActionClick={(item) => this.onActionClick(item)}
                message={CommonString.NoPendingInvoice}
                dateTypeFields={["dueDate", "invoiceDate"]}
                amountTypeFields={[]}
                />
                </View>
              ) : null}
            </View>
            <PCHLoaderComponent isVisiable={loaderVisible} labelText={"Please wait"} clientStyle={clientStyle} />
          <PopupModalComponent
            visibility={!!confirmationPopUp}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.confirmationPopUpStyle]}
            messageType={MESSAGE_TYPE.info}
            headingIcon={true}
            heading={"Record Payment"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={"Are you sure, you want to record payment?"}
            okLabel={"Yes"}
            handleOk={async () => this.handleSubmit(this.props.app.ui.form("receivepayments"))}
            closeLabel={"No"}
            handleClose={async () => {
              this.setState({confirmationPopUp: false})}
            }
            modalHeading={[commonStyles.nativeModalHeading]}
          />
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  uploadBillsScreen: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          backgroundColor: "#fff",
          marginBottom: 45,
        };
    },
  },
  modalBackdrop: {
    backgroundColor: "#00000052",
    overflowX: "auto",
  },
  closePopup: {
    width: "20%",
    height: 8,
    borderRadius: 25,
    backgroundColor: "#e1e1e1",
    marginBottom: 25,
  },
  attachOpt: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 15,
        };
      }
    },
  },
  attachments: {
    // paddingLeft: 50,
  },
  leftInputHolder: {
    marginRight: 8,
    maxWidth: '49%',
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: '100%',
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMarTAuto: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: -15,
        };
      }
    },
  },
  customFlexProperties: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
          // marginBottom: 30
        };
      }
      if (width >= 991) {
        return {
          justifyContent: "flex-end",
        };
      }
    },
  },
  submitBtn: {
    minWidth: 150,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: 108,
          flexBasis: "48%"
        };
      }
    },
  },
  inputHolder: {
    marginBottom: 6,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customPaddingTop15: {
    paddingTop: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          marginTop: 15,
          marginLeft: 10,
        };
      }
    },
  },
  formBtnsHolder: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginBottom: 0,
          justifyContent: "space-around",
        };
      }
    },
  },
  formBtns: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
          marginBottom: 30
        };
      }
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailRow: {
    borderBottom: "1px solid rgb(218, 218, 218)",
    paddingBottom: 16,
    marginBottom: 15,
    flexBasis: "48%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingBottom: 0,
          flexBasis: "100%",
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  addIncProText: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  countryCode: {
    maxWidth: 70,
  },
  customHelperTextStyleMobile: {
    marginLeft: 58,
  },
  btnHolder: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  uploadBillSection: {
    backgroundColor: "#fff",
    marginTop: 16,
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    // flex: 1,
    // alignItems: "flex-start",
    // flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 16,
        };
      }
    },
  },
  mobView: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "flex",
          marginTop: -10,
          flexDirection: "row",
        };
      }
    },
  },
  dobInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
        };
      }
    },
  },
  emailInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -11,
        };
      }
    },
  },
  cameraOpt: {
    paddingTop: 8,
    paddingBottom: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          paddingBottom: 0,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  marginTopMin20: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -20,
        };
      }
    },
  },
  marginTopMin10: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -10,
        };
      }
    },
  },
  pageTitle: {
    marginTop: 15,
    display: "flex",
    justifyContent: "space-between",
    minHeight: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
          marginBottom: 10,
        };
      }
    },
  },
  mobileNumberInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 15,
        };
      }
    },
  },
  cameraOptHolder: {
    flex: 1,
    flexGrow: 1,
  },
  uploadedBillAttachFile: {
    // marginBottom: 10,
  },
  helperTextStyleDob: {
    position: "static",
  },
  leftMinus12: {
    left: -12,
  },
  autoFillModelBodyWrapper: {
    width: 500,
    maxHeight: "80vh",
    overflowY: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          height: "100vh",
        };
      }
    },
  },
  autoFillModel: {
    height: "100%",
    overflowY: "auto",
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: "100vh",
        };
      }
    },
  },
  fillWidth: {
    width: "33%",
  },
  tr: {
    display: "flex",
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    cursor: "pointer",
    borderBottom: "1px solid rgb(161 164 168)",
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 5,
    flexDirection: "column",
  },
  addInsuranceProvider: {
    // backgroundColor: "#e2e2e2",
    alignItems: "center",
    // borderRadius: 20,
    // paddingTop: 2,
    // paddingRight: 4,
    // paddingBottom: 2,
    // paddingLeft: 4,
    // border: "1px solid #333",
    // marginTop: 14,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "inline-block"
        };
      }
    },
  },
  customAlignItemsStart: {
    alignItems: "baseline",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: null,
        };
      }
    },
  },
  mViewFontStyle: {
    fontSize: 16,
    fontWeight: 600,
  },
  attachmentCard: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#a1a4a8",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
          minHeight: 65,
        };
      }
    },
  },
  updatedonForMView: {
    flexDirection: "row",
  },
  PDFViewBodyWrapper: {
    width: "80vw",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          // height: "75vh",
          marginTop: "auto",
          borderRadius: "10px 10px 0 0",
        };
      }
      if (width === 411 && height === 731) {
        return {
          height: "64vh",
        };
      }
    },
  },
  PDFModalContent: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          overflowY: "scroll",
          marginTop: 0,
        };
      }
    },
  },
  custompaddingLeftRight: {
    paddingTop: 0,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 5,
  },
  disabledPOBox: {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "default",
  },
  fileUploadWrapper: {
    // flexBasis: "48%",
    flexBasis: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginRight: 0,
        };
      }
    },
  },
  buttons: {
    height: 24,
  },
  uploadBillsForm: {
    width: '100%',
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flex: 1,
          width: '100%'
        };
      }
      if (width <= 991) {
        return {
          paddingTop: 0,
          //   width: "90%"
        };
      }
    },
  },
  attachmentFieldOuter: {
    width: "100%",
    position: "relative",
    height: 60,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: 50,
        };
      }
    },
  },

  attachFileScetion: {
    marginBottom: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  attachmentsHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 16,
        };
      }
    },
  },
  emptyElement: {
    height: 25,
    width: 25,
    backgroundColor: "#fff",
    position: "absolute",
    top: 10,
    right: 5,
  },
  poBoxHolder: {
    width: "73%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "63%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  bodyWrapper: {
    width: 300,
    borderRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          fontWeight: 600,
        };
    },
  },
  modalContent: {
    paddingLeft: 26,
    paddingRight: 26,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingLeft: 26,
          paddingRight: 26,
        };
    },
  },
  closeBtn: {
    position: "absolute",
    right: -14,
    top: -10,
  },
  btnWrapper: {
    flex: 1,
    justifyContent: "center",
    marginTop: 15,
  },
  incNameDropDown: {
    marginBottom: 30,
    screen: ({ width }) => {
      if (width >= 991)
        return {
          maxWidth: "49%",
        };
    },
  },
  textAlign: {
    alignItems: "center",
    height: "55%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginBottom: 0,
          alignItems: "flex-start",
        };
      }
    },
  },
  orTextSection: {
    marginTop: 12
  },
  maxWidth48: {
    screen: ({ width }) => {
      if (width > 991) {
        return {
          maxWidth: "48%"
        };
      }
    },
  },
  width100: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
        };
      }
    },
  },
  customMarginBottom30: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 30,
        };
      }
    },
  },
  customMarginBottom16: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 16,
        };
      }
    },
  },
  minWidth100: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: "100%",
        };
      }
    },
  },
  customSubmitBtn: {
    marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0
        };
      }
    },
  },
  customPaddingTop5: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 5
        };
      }
    },
  },
  customBorderBottom: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "#dadada",
        };
      }
    },
  },
  customBorderTop: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          borderTopWidth: 1,
          borderStyle: "solid",
          borderColor: "#dadada",
        };
      }
    },
  },
  activeButton: {
    backgroundColor: "#37c2ef",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#37c2ef",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
    justifyContent: 'center',
    height: 24,
    maxHeight: 24,
    // paddingTop: 15,
    // paddingBottom: 15,
    width: 100,
    textAlign: 'center'
  },
  activeButtonPadding: {
    paddingLeft: 15,
    paddingRight: 15,
    height: 24,
    maxHeight: 24
  },
  buttonContainter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    width: "100%",
    maxHeight: 34, 
  },
  mViewButtonContainter:{
      screen: ({ width, height }) => {
      if (width === 375 && height === 553)
        return {
          marginBottom: 56,
        };
    },
  },
  editAmountForm: {
    paddingTop: 24,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  leftInputField: {
    maxWidth: "100%",
    // marginRight: 20,
    maxHeight: 55,
    minHeight: 55,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // maxWidth: "100%",
          // flexBasis: "100%",
          marginRight: 0,
          marginBottom: 0,
        };
    },
  },
  confirmationPopUpStyle: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          height: 145,
          minHeight: 145,
          maxHeight: 150,
        };
    },
  },
  editAppliedAmtPopUp: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          height: 380,
          minHeight: 380,
          maxHeight: 380,
        };
    },
  },
  customWidth49: {
    width: "100%",
    screen: ({ width }) => {
        if (width <= 991) {
            return {
                width: Platform.OS !== "web" ? "88%" : "100%",
            };
        }
    }
  },
  customBtnWidth: {
    screen: ({ width }) => {
      if (width <= 991 && Platform.OS !== "web" ) {
          return {
            minWidth: "48%",
            width: "98%",
          };
      }
  }
  },
  padding16: {
      screen: ({ width }) => {
          if (width <= 991) {
              return {
                  padding: 16,
              };
          }
      },
  }
});
