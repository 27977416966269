import React from "react";
import R14, {
  View,
  StyleSheet,
  Touchable,
  Image,
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";
const crateMessageIcon = require("../assets/images/NewCreateMessage.png");

export default R14.connect(
  class AddMessageIconComponent extends React.Component {

    goToCreateMessageScreen = () => {
      analyticsLogs(CommonString.SIMPLE_MESSAGE_LIST, `${CommonString.CREATE_NEW_MESSAGE_ACTION}`, this.props.app);
      this.props.app.nav.to("addMessageScreenRoute");
    };

    render() {
      if(!!this.props.app.dm.userSession.state.isUberDocs) return null;
      return (
        <View style={[styles.headerControlsRight]}>
          <Touchable onPress={this.goToCreateMessageScreen}>
            <View>
                <Image source={crateMessageIcon} style={commonStyles.icon18} />
            </View>
          </Touchable>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  headerControlsRight: {
    paddingRight: 16,
    position: "relative",
  }
});
