import React from "react";
import { App as R14App } from "./core";
import theme from "./config/theme.config";
import routes from "./config/routes.config";
import icons from "./config/icons.config";
import config from "./config/app.config";

import Domains from "./config/domains.config";
import Actions from "./Actions";

export default class App extends React.Component {
  render() {
    return (
      <R14App
        name="rtpMobileApp"
        routes={routes}
        scrollBehavior={(to, from, savedPosition) => {
          if (savedPosition) return savedPosition;
          else return { x: 0, y: 0 };
          // If there's no history, or we didn't name the route, use standard scroll behavoir
        }}
        domains={Domains}
        actions={Actions}
        config={config}
        theme={theme}
        icons={icons}
      />
    );
  }
}
