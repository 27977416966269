import React from "react";
import R14, {
  View,
  Touchable,
  Image,
  Platform,
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { USER_TYPE } from "../constant/app.data.constant";
import UserAgentValidator from "../utils/UserAgentValidator";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

const searchIcon = require("../assets/images/NewSearch.png");

export default R14.connect(
  class SearchIconComponent extends React.Component {
    constructor(props) {
      super(props);
      this.currentScreen = "";
      this.userLoginDetail = {};
    }

    async initialize() {
      const { userLoginDetail } = this.props.app.dm.userSession.state;
      this.userLoginDetail = await this.props.app.dm.rest.getUser();
      if (!!!this.userLoginDetail) this.userLoginDetail = userLoginDetail;
    }

    componentDidMount = async () => {
      this.initialize();
      this.props.app.dm.notificationDomain.setState({
        isMobileDevice: UserAgentValidator.isMobileDevice(),
      });
    }

    searchToggal = async () => {
      let form = this.props.app.ui.form("searchBill");
      if(form && form.elmts && form.elmts.searchText) form.elmts.searchText.setValue("");
      analyticsLogs(this.props.screenName === 'makePaymentRoutes' ? CommonString.RTP_ALL_BILLS : 
        this.props.screenName === 'billersDirectoryRoute' ? CommonString.PAM_LIST : 
        this.props.screenName === 'simpleMessageRoute' ? CommonString.SIMPLE_MESSAGE_LIST : 
        CommonString.RTP_DASHBOARD, 
        CommonString.SEARCH_ICON_ACTION, this.props.app
      );
      this.props.app.dm.userSearchDomain.setState({
        isSearchOpen: !this.props.app.dm.userSearchDomain.state.isSearchOpen,
        textInSearchBox: "",
        currentOpenScreen: this.currentScreen.name,
      });
      this.props.app.dm.userSearchDomain.keyTypeSearch("", this.currentScreen.name);
      if (!!this.props.app.dm.userSearchDomain.state.isSearchOpen)
        await this.searchHistoryData();
      if(!!this.props.app.dm.userSession.state.leftMenuOverlay && this.props.app.dm.notificationDomain.state.isMobileDevice)
        this.props.app.dm.userSession.setState({
          leftMenuToggle: Platform.OS === "web" ? !this.props.app.dm.userSession.state.leftMenuToggle : false,
          leftMenuOverlay: Platform.OS === "web" ? !this.props.app.dm.userSession.state.leftMenuOverlay : false,
        });
    };

    // Get all History Data
    async searchHistoryData() {
      if (this.userLoginDetail !== null && this.userLoginDetail !== undefined && !!this.userLoginDetail.pid && this.userLoginDetail.userType === USER_TYPE.XBP) {
        const searchHistory = await this.props.app.dm.userSearchDomain.getLastSearchHistory(
          this.userLoginDetail.pid,
          3,
          this.currentScreen.name,
          "",
          "DESC",
          false
        );
        this.props.app.dm.userSearchDomain.setState({localSearchHistory: searchHistory || [], searchHistory: searchHistory || []});
      }
    }

    render() {
      if(!!this.props.app.dm.userSession.state.isUberDocs) return null;
      this.currentScreen = this.props.app.nav.route;
      const {selectedScreen } = this.props.app.dm.dashboard.state;

      return (
        selectedScreen === "RTP" ?
          <Touchable onPress={this.searchToggal} style={[this.props.screenName === "makePaymentRoutes" ? commonStyles.marginRight15 : null]}>
            <View>
              <Image source={searchIcon} style={commonStyles.icon18} />
            </View>
          </Touchable> : null
      );
    }
  }
);
