import React from "react";
import R14 from "../core";
import ResponsiveTableComponent from "../components/ResponsiveTableComponent";
import DateUtils from "../utils/DateUtils";
import JsonToCSV from "../utils/JsonToCSV";
import HtmlDecode from "../utils/HtmlDecode";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

const defaultSec = 10;

export default R14.connect(
  class SimpleMessageScreen extends React.Component {
    constructor(props) {
      super(props);
      this.timerInstance = null;
    }

    componentDidMount() {
      this.props.app.dm.dashboard.setState({selectedScreen: "RTP"});
      this.timerInstance = setInterval(async () => {
      const { isSearchOpen } = this.props.app.dm.userSearchDomain.state;
        if(!!isSearchOpen) return false;
        else await this.props.app.dm.simpleMessage.displaySimpleMessage();
      }, 1000 * defaultSec);
    }

    componentWillUnmount() {
      if (this.timerInstance) {
        clearInterval(this.timerInstance);
      }
    }

    onNavigate = (item) => {
        analyticsLogs(CommonString.SIMPLE_MESSAGE_LIST, `${CommonString.SIMPLE_MESSAGE_DETAILS_ACTION}`, this.props.app);
        let newThreadId = item.threadId; //.replaceAll("#", ">>");
        this.props.app.nav.to("simpleMessageDetailRoute", { threadId: newThreadId });
    };
    
    convertToCSV = async(items, screenName = "data") => {
      analyticsLogs(CommonString.SIMPLE_MESSAGE_LIST, `${CommonString.DOWNLOAD_CSV}`, this.props.app);
      if (items.length){
          items = items.map(item => {
            return {
              "Biller Name": item.billerName || item.biller.billerName,
              "Business Unit Name": item.buName || item.biller.buName,
              "Subject": item.subject,
              "Last Message": item.lastMessage,
              "Date": DateUtils.formatDate(item.lastMessageDate, "MM/DD/YYYY")
            }
          })
        const csvContent = JsonToCSV.convertJsonToCSV(items);
        HtmlDecode.downloadUTF8file({
          content: csvContent,
          filename: `${screenName}_${DateUtils.formatYYYYMMDDHHMMSS()}.csv`
        })
      }
      return false;
    }

    render() {
      const { simpleMessage, searchSimpleMessage } = this.props.app.dm.simpleMessage.state;
      const { notificationThreadIds } = this.props.app.dm.notificationDomain.state;
      const { isSearchOpen } = this.props.app.dm.userSearchDomain.state;
      const { leftMenuToggle } = this.props.app.dm.userSession.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      let currentRoute =this.props.r14.navigation.activeRouteName;

      let displayMessages = [];
      if (isSearchOpen) displayMessages = searchSimpleMessage;
      else displayMessages = simpleMessage ? simpleMessage : [];

      let messages = displayMessages.map((data, i) => {
        return data;
      });

      if (notificationThreadIds.length > 0) {
        let d = notificationThreadIds.map((data, i) => {
          return data.threadId;
        });
        messages = messages.filter((message) => d.includes(message.threadId));
      }

      return (
            <ResponsiveTableComponent
              simpleMessage={messages}
              currentRoute={currentRoute}
              leftMenuToggle={leftMenuToggle}
              onNavigate={this.onNavigate}
              convertToCSV={this.convertToCSV}
              isSimpleMessageScreen={true}
              isBillerDirectory={false}
              isMakePayment={false}
              isDashboard={false}
              isMobileDevice={isMobileDevice}
              message = {"No messages to display."}
              headerText = {this.props.r14.navigation.currRoute._config.label}
            /> 
      );
    }
  }
); 