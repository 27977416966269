import R14 from "../core";
export default class UserSearchHistoryDomain extends R14.Domain {

  constructor(props) {
    super(props);
    this.initialSearchValues = this.getInitialStates();
    this.state = {
      ...this.initialSearchValues,
    };
  }

  getInitialStates() {
    return {
      isSearchOpen: false,
      textInSearchBox: "",
      currentOpenScreen: "",
      localSearchHistory: [],
      searchHistory: []
    };
  }

  // create search history method added by Azad
  async CreateUserSearchHistory(payerId, screenType, searchString)
  {
    try
    {
      let checkExist = await  this.getLastSearchHistory(payerId, 1, screenType, searchString.toLowerCase() , "DESC", false);
      if (checkExist.length > 0) await this.deleteUserHistory(checkExist[0].uid);
    }
    
    catch(error){
     // console.log(error);
    }
   
    let userSearchHistory= {
        payerId: payerId,
        searchedString : searchString.toLowerCase(),
        screenType : screenType
    }

    try
    {
      let res = await this.api.mutate(  `
      mutation createUserSearchHistory($input: CreateUserSearchHistoryInput!) {
        createUserSearchHistory(input: $input){
          userSearchHistory {
            uid payerId screenType searchedString
          }
        }
      }`,
        { input: userSearchHistory }
      );

      // delete if history more than 3
      let checkGt3 = await this.getLastSearchHistory(payerId, 1, screenType, "", "ASC", true);
      if( checkGt3.totalCount > 3)
      {
        await this.deleteUserHistory(checkGt3.nodes[0].uid);
      }
     
      return res.data.createUserSearchHistory.userSearchHistory;
    }
    catch(error){
      return error;
    }
  }

  

  async getLastSearchHistory(payerid, recordCount, screenName, stringSearch, sort, returnNode) {
    let options = {};
    if (stringSearch === "") {
      options = {
        filter: {
          payerId: { eq: payerid },
          screenType: { eq: screenName }
        },
        sort: [
          {
            field: 'createdAt',
            order: sort,
          },
        ],
        resultsPerPage: recordCount,
        page: 1,

      }
    }
    else {
      options = {
        filter: {
          payerId: { eq: payerid },
          screenType: { eq: screenName },
          searchedString: { eq: stringSearch },
        },
        sort: [
          {
            field: 'createdAt',
            order: sort,
          },
        ],
        resultsPerPage: recordCount,
        page: 1,

      }
    }

    try {
      let res = await this.api.qry(
        `
      query UserSearchHistorys($filter: UserSearchHistoryFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        userSearchHistorys(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            uid searchedString, payerId
          }
        }
      }`,
        options
      )
      return returnNode ?  res.data.userSearchHistorys : (res.data.userSearchHistorys.nodes || {}) 
    } catch (err) {
      return err
    }
  }


  async deleteUserHistory(uid) {
    await this.api.mutate(`
      mutation DeleteUserSearchHistory($uid: ID!) {
        deleteUserSearchHistory(uid: $uid){
          userSearchHistory {
            uid
          }
        }
      }`, {
        uid: uid
      }
    );
    return true;
  }

  async keyTypeSearch (searchText, currentScreen){   
    if (currentScreen === "dashboard") {
      const { compiledMsgList } = this.dm.dashboard.state;
      const tempResult = compiledMsgList.filter(
        (el) => el.biller && el.bookmark === true && el.billStatus !== "Paid" && el.billStatus !== "Declined"
      )
      const searchDashboardResult = tempResult.filter(
        (el) => el.subject.toUpperCase().includes(searchText.toUpperCase())
       // || el.billStatus.toUpperCase().includes(searchText.toUpperCase()) 
        || el.biller.billerName.toUpperCase().includes(searchText.toUpperCase())
      )
      this.dm.dashboard.setState({ headerSearchList: searchText ==="" ? tempResult : searchDashboardResult });
    }
    else if (currentScreen === "makePaymentRoutes") {
      const { filterBills } = this.dm.dashboard.state;
      const searchMakePaymentResult = filterBills.filter(
        (el) => el.subject.toUpperCase().includes(searchText.toUpperCase())
         // || el.billStatus.toUpperCase().includes(searchText.toUpperCase()) 
          || el.biller.billerName.toUpperCase().includes(searchText.toUpperCase())
      )
      this.dm.dashboard.setState({ headerSearchList: searchText ==="" ? filterBills : searchMakePaymentResult });
    }
    else if (currentScreen === "simpleMessageRoute") {
      const { simpleMessage } = this.dm.simpleMessage.state;
      const simpleMessageResult = simpleMessage.filter(
        (el) => el.subject.toUpperCase().includes(searchText.toUpperCase())
          || el.billerName.toUpperCase().includes(searchText.toUpperCase())
      )
      this.dm.simpleMessage.setState({ searchSimpleMessage:  searchText ==="" ? simpleMessage : simpleMessageResult });
    }
    else if (currentScreen === "billersDirectoryRoute") {
      const { pamList } = this.dm.billerDirectoryDomain.state;
      const pamListResult =pamList.filter(
        (el) => el.biller.billerName.toUpperCase().includes(searchText.toUpperCase())
      )
      this.dm.billerDirectoryDomain.setState({ searchPamList:  searchText ==="" ? pamList : pamListResult });
    }
  }

}