import R14 from "../core";

export default class ClientDomain extends R14.Domain {
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            totalBills: 0,
            currentPage: 1,
            totalPages: 1,
            resultsPerPage: 10,
            isFetched: false
        }
    }

    async findMemberClients(opt, fields = "uid name isDefault") {
        let options = { ...opt };
        try {
            let res = await this.dm.queryDomain.middleware(this.api.qry(
                `query GetClient($filter: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
                    getMemberClients(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){  
                        nodes {
                            ${fields}
                        }
                }
            }`,
                options
            ));

            return !!res && !!res.data && res.data.getMemberClients ? res.data.getMemberClients : [];
        } catch (error) {
            return error;
        }
    }

    async find(opt, fields = "uid name description email phoneNo serviceAmount isActive url isDefault createdOn") {
        let options = { ...opt };
        try {
            let res = await this.dm.queryDomain.middleware(this.api.qry(
                `query FindClient($filter: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
                    findClient(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
                        totalCount    
                        nodes {
                        ${fields}
                        }
          }
        }`,
                options
            ));

            return !!res && !!res.data && res.data.findClient ? res.data.findClient : [];
        } catch (error) {
            return error;
        }
    }

    async addUpdateClient(input, fields = "uid name description email phoneNo serviceAmount isActive url isDefault") {
        try {
            let res = await this.dm.queryDomain.middleware(
                this.api.mutate(`mutation AddUpdateClient($input: UpdateClientInput!){
                addUpdateClient(input: $input){  
                   client {
                    ${fields}
                   }             
                   success
                }
              }`, { input }
                ));

            return !!res && !!res.data && res.data.addUpdateClient ? res.data.addUpdateClient : { success: false, client: null };
        } catch (error) {
            return { success: false, client: null };
        }
    }

    async findClientAPI(opt, fields = "uid clientUid client { uid name } apiType methodType contentType isActive url createdOn headers { name value dataType actionType } bodyParameters { name value dataType actionType } queryStrings { name value dataType actionType } dataBody responseProp") {
        let options = { ...opt };
        try {
            let res = await this.dm.queryDomain.middleware(this.api.qry(
                `query FindClientAPI($filter: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
                    findClientAPI(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
                        totalCount    
                        nodes {
                        ${fields}
                        }
                    }
                }`,
                options
            ));

            return !!res && !!res.data && res.data.findClientAPI ? res.data.findClientAPI : [];
        } catch (error) {
            return error;
        }
    }


    async addUpdateClientAPI(input, fields = "uid clientUid client { uid name } apiType methodType contentType isActive url createdOn headers { name value dataType actionType } bodyParameters { name value dataType actionType } queryStrings { name value dataType actionType } dataBody responseProp") {
        try {
            let res = await this.dm.queryDomain.middleware(
                this.api.mutate(`mutation AddUpdateClientAPI($input: [UpdateClientAPIModelInput!]){
                addUpdateClientAPI(input: $input){  
                    clientAPIModels
                    {
                         clientAPIModel {
                                ${fields}
                            }  
                    }           
                   success
                }
              }`, { input }
                ));

            return !!res && !!res.data && res.data.addUpdateClientAPI ? res.data.addUpdateClientAPI : { success: false, client: null };
        } catch (error) {
            return { success: false, client: null };
        }
    }

}