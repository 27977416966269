import React, { Component } from "react";
import R14, { StyleSheet, View, Text, Platform } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { CURRENCY } from "../../constant/app.data.constant";
import Bookmarked from "../Bookmared";
import StatusComponent from "../StatusComponent";
import CommonUtility from "../../utils/CommonUtility";
import DateUtils from "../../utils/DateUtils";
import { commonMetricsStyle } from "../../assets/css/commonMetricsStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import TooltipComponent from "../TooltipComponent";
import { analyticsLogs } from "../../utils/AnalyticLogs";
import { CommonString } from "../../constant/appCommonText";


export default R14.connect(
  class BillDetailComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        billData: this.props.billData,
      };
    }

    toggleBookmark = async (el) => {
      analyticsLogs(CommonString.SEPA_BILL_DETAILS, !el.bookmark ? CommonString.Prioritize_Bill : CommonString.Removed_Priority, this.props.app);
      el.bookmark = !el.bookmark;
      let bill = await this.props.app.dm.sepa.toggleBookmark(el.uid);
      if (bill) {
        let { sepaBills, totalBills } = this.props.app.dm.sepa.state;
        if (!!sepaBills.length) {
          let ind = sepaBills.findIndex((el1) => el1.uid === el.uid);
          if (ind >= 0) {
            sepaBills[ind].bookmark = !sepaBills[ind].bookmark;
            let data = sepaBills;
            await this.props.app.dm.sepa.setState({ sepaBills: data, totalBills: totalBills });
          }
        }
        this.setState({
          billData: el
        })
      }
    };

    webView = (payableAmt, maxlimit, paidAmount) => {
      const { billData, customStyles, customFlexDirection, installmentDetails, isBookmarkRequire } = this.props;
      let isOneTime = !!billData.selectedPaymentType && billData.selectedPaymentType.indexOf('OT/') > -1 ? true : false;
      let installmentDetail = installmentDetails.find(x => x.billStatus !== "PAID" && x.billStatus !== "Paid");
      let idx = 0;
      let totalItems = installmentDetails.length;
      if (!!installmentDetail) {
        idx = installmentDetails.findIndex((x) => x.uid === installmentDetail.uid);
        idx = idx + 1;
      }
      else
      {
        idx = totalItems;
        installmentDetail = installmentDetails.slice(-1)[0];
      }
      return (
        <View style={styles.webView}>
          <View style={[styles.billerDetails, customStyles]}>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.billerDetailsRow, commonStyles.marB0, styles.payPageBillerDetailsRow]}>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart, commonStyles.marginBottom16,]}>
                <View style={[styles.webViewUserIconDiv, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
                  <Text
                    style={[
                      commonStyles.heading6,
                      commonStyles.fontWeight700,
                      commonFontStyles.workSans,
                      commonStyles.userInitial,
                      styles.userInitial,
                    ]}>
                    {CommonUtility.shortName(billData.billerName)}
                  </Text>
                </View>
                <View>
                  <View style={[commonStyles.flex1]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, commonStyles.wordBreakWord]}>
                      {billData.billerName}
                    </Text>
                    <View style={[styles.fullWidth, commonStyles.MarginTop10]}>
                      <Text style={styles.cardSmlHeadingText}>{DateUtils.dateAndTimeWithFormat(billData.billDate, "DD/MM/YYYY")}</Text>
                    </View>
                  </View>
                </View>
              </View>

              {!!isBookmarkRequire && <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexDirectionRow,
                  commonStyles.justifyContentFlexEnd,
                  styles.minWidth115
                ]}>
                {!!["VOID", "CANCELLED", "REJECTED", "PAID"].includes(billData.billStatus) ? null :
                  <View>
                    <Bookmarked onToggle={() => this.toggleBookmark(billData)} isBookmarked={billData.bookmark} bookedMarkStyle={commonStyles.flexGrow0} />
                  </View>}

                <View style={styles.statusSection}>
                  <StatusComponent
                    marginRight={[commonStyles.margin0, commonStyles.paddingTop0, commonStyles.marginLeft15]}
                    label={billData.billStatus}
                    isSelected={false}
                    paymentStatusLabel={billData.paymentStatus}
                  />
                </View>
              </View>}
            </View>
            <View style={[commonStyles.flex1, styles.billerDetailsRow, styles.payPageBillerDetailsRow,]}>
              <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Account Number
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {!!billData.billerIBAN ? billData.billerIBAN : "N/A"}
                  </Text>
                </View>
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Reference Id
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {!!installmentDetail && installmentDetail.endToEndIdentification
                      ? (installmentDetail.endToEndIdentification.length > maxlimit
                        ? installmentDetail.endToEndIdentification.substring(0, maxlimit - 3) + '...'
                        : installmentDetail.endToEndIdentification) : "N/A"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.billerDetailsRow, styles.payPageBillerDetailsRow,]}>
              <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Payment Type
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {!!isOneTime ? "OneTime" : "Instalment"}
                  </Text>
                </View>
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Expiry Date
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {DateUtils.formatDate(billData.expiryDate, "DD/MM/YYYY")}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.billerDetailsRow, styles.payPageBillerDetailsRow,]}>
              <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Execution Date
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {!!installmentDetail ? DateUtils.formatDate(installmentDetail.executionDate, "DD/MM/YYYY") : "N/A"}
                  </Text>
                </View>
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Amount Payable
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {CURRENCY[billData.currency]}{billData.billStatus === "Paid" ? "0.00" : CommonUtility.formatAmount(payableAmt.toFixed(2))}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.billerDetailsRow, styles.payPageBillerDetailsRow,]}>
              <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Initial Amount
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {CURRENCY[billData.currency]}{CommonUtility.formatAmount(billData.billAmount.toFixed(2))}
                  </Text>
                </View>
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Paid Amount
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {CURRENCY[billData.currency]}{CommonUtility.formatAmount(paidAmount)}
                  </Text>
                </View>
              </View>
            </View>
            {!!!isOneTime && !!installmentDetail && billData.billStatus !== "PAID" && <View style={[commonStyles.flex1, styles.billerDetailsRow, styles.payPageBillerDetailsRow,]}>
              <View style={[commonStyles.flex1, commonStyles.paddingRight15, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Instalment Amount
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {CURRENCY[billData.currency]}{CommonUtility.formatAmount(installmentDetail.billAmount.toFixed(2))}
                  </Text>
                </View>
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexBasis50, customFlexDirection, styles.billDetailsRowInner]}>
                <View style={[styles.paymentPageBillDetailsLabels]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600, commonStyles.labelDetails]}>
                    Current Instalment
                  </Text>
                </View>
                <View style={[styles.paymentPageBillDetailsValue]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>
                    {idx} out of {totalItems}
                  </Text>
                </View>
              </View>
            </View>}
          </View>
        </View>
      );
    };

    responsiveView(payableAmt, maxlimit, paidAmount) {
      const { billData, padding2, installmentDetails, isBookmarkRequire } = this.props;
      let isOneTime = !!billData.selectedPaymentType && billData.selectedPaymentType.indexOf('OT/') > -1 ? true : false;
      let installmentDetail = installmentDetails.find(x => x.billStatus !== "PAID" && x.billStatus !== "Paid");
      let idx = 0;
      let totalItems = installmentDetails.length;
      if (!!installmentDetail) {
        idx = installmentDetails.findIndex((x) => x.uid === installmentDetail.uid);
        idx = idx + 1;
      }
      else
      {
        idx = totalItems;
        installmentDetail = installmentDetails.slice(-1)[0];
      }
      return (
        <View style={styles.mView}>
          <View style={[commonStyles.flex1, commonStyles.marginBottom5]}>
            <View style={[Platform.OS === 'ios' ? commonStyles.dFlex : commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween]}>
              <View style={[styles.fullWidth]}>
                <View style={[styles.billerNameSection]}>
                  <Text
                    style={[
                      styles.cardHeadingText,
                      commonFontStyles.workSans,
                      commonStyles.heading5,
                      commonStyles.fontWeight500,
                      commonStyles.wordBreakWord
                    ]}
                  >
                    {billData.billerName}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <View style={[Platform.OS === 'ios' ?
                [commonStyles.dFlex, commonStyles.flexDirectionRow] : [commonStyles.flex1, commonStyles.flexDirectionRow,],
              commonStyles.justifyContentSpaceBetween, styles.dateRowSpacing
              ]}>
                <View style={[styles.billDetailsHeaderLeftSection]}>
                  <Text style={styles.cardSmlHeadingText}>{DateUtils.dateAndTimeWithFormat(billData.billDate, "DD/MM/YYYY")}</Text>
                </View>
                {!!isBookmarkRequire ?
                  <View style={[Platform.OS === 'ios' ?
                    [commonStyles.dFlex, commonStyles.flexDirectionRow] : [commonStyles.flex1, commonStyles.flexDirectionRow,],
                  commonStyles.alignItemsStart, commonStyles.justifyContentFlexEnd,
                  Platform.OS === 'ios' ? styles.minWidth40 : null,]}>
                    {!!["VOID", "CANCELLED", "REJECTED", "PAID"].includes(billData.billStatus) ? null :
                      <View style={[Platform.OS !== "web" && billData.billStatus === 'Failed' ? commonStyles.marginRight15 : null]}>
                        <Bookmarked onToggle={() => this.toggleBookmark(billData)} isBookmarked={billData.bookmark} bookedMarkStyle={commonStyles.flexGrow0} />
                      </View>}
                    {billData.billStatus === 'Failed' ? (
                      <TooltipComponent
                        verificationTip={true}
                        verificationTipCss={[styles.tooltipStyles]}
                        verificationStatus={'FAILED'}
                        toolTipMessage={billData.failReason}
                        priorityInfoTooltipStyle={styles.priorityInfoTooltip}
                        smallPriorityInfoTooltipStyle={styles.smallPriorityInfoTooltip}
                        bigPriorityInfoTooltipStyle={styles.bigPriorityInfoTooltip}
                        justifyContentEnd={commonStyles.paddingTop0}
                      />
                    ) : (
                      <StatusComponent
                        marginRight={[commonStyles.margin0, commonStyles.paddingTop0, commonStyles.marginLeft15]}
                        label={billData.billStatus}
                        isSelected={false}
                        paymentStatusLabel={billData.paymentStatus}
                      />
                    )}
                  </View> : null}
              </View>
            </View>
          </View>
          <View style={[padding2]}>
            <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700
                  ]}>
                  Account Number
                </Text>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    styles.labelValue,
                  ]}>
                  {!!billData.billerIBAN ? billData.billerIBAN.length > 20 ? `${billData.billerIBAN.substring(0, 4)}XXXXXXXXXXXX${billData.billerIBAN.substring(billData.billerIBAN.length - 4, billData.billerIBAN.length)}` : billData.billerIBAN : "N/A"}
                </Text>
              </View>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700,
                  ]}>
                  Reference Id
                </Text>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    styles.labelValue,
                    customCommonStyles.textOverflowEllipsis,
                  ]}>
                  {!!installmentDetail && installmentDetail.endToEndIdentification
                    ? (installmentDetail.endToEndIdentification.length > maxlimit
                      ? installmentDetail.endToEndIdentification.substring(0, maxlimit - 3) + '...'
                      : installmentDetail.endToEndIdentification) : "N/A"}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700
                  ]}>
                  Payment Type
                </Text>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    styles.labelValue,
                  ]}>
                  {!!isOneTime ? "OneTime" : "Instalment"}
                </Text>
              </View>
              <View style={[commonStyles.flexBasis50]}>
                <Text style={[styles.cardSubHeadingText, commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight700, commonStyles.labelDetails,]}>
                  Expiry Date
                </Text>
                <Text style={[styles.cardSubHeadingText, commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400]}>
                  {DateUtils.formatDate(billData.expiryDate, "DD/MM/YYYY")}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700
                  ]}>
                  Execution Date
                </Text>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    styles.labelValue,
                  ]}>
                  {!!installmentDetail ? DateUtils.formatDate(installmentDetail.executionDate, "DD/MM/YYYY") : "N/A"}
                </Text>
              </View>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700
                  ]}>
                  Amount Payable
                </Text>
                <Text
                  style={[styles.cardSubHeadingText, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.darkGrayColor]}>
                  {CURRENCY[billData.currency]}{billData.billStatus === "Paid" ? "0.00" : CommonUtility.formatAmount(payableAmt.toFixed(2))}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700
                  ]}>
                  Initial Amount
                </Text>
                <Text
                  style={[styles.cardSubHeadingText, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.darkGrayColor]}>
                  {CURRENCY[billData.currency]}{CommonUtility.formatAmount(billData.billAmount.toFixed(2))}
                </Text>
              </View>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700
                  ]}>
                  Paid Amount
                </Text>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    styles.labelValue,
                  ]}>
                  {CURRENCY[billData.currency]}{CommonUtility.formatAmount(paidAmount)}
                </Text>
              </View>
            </View>

            {!!!isOneTime && installmentDetail && billData.billStatus !== "PAID" && <View style={[commonStyles.flex1, styles.billerDetailsRow]}>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700
                  ]}>
                  Instalment Amount
                </Text>
                <Text
                  style={[styles.cardSubHeadingText, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.darkGrayColor]}>
                  {CURRENCY[billData.currency]}{CommonUtility.formatAmount(installmentDetail.billAmount.toFixed(2))}
                </Text>
              </View>
              <View style={[commonStyles.flexBasis50]}>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    commonStyles.fontWeight700
                  ]}>
                  Current Instalment
                </Text>
                <Text
                  style={[
                    styles.cardSubHeadingText,
                    commonMetricsStyle.heading6,
                    commonFontStyles.workSans,
                    commonStyles.darkGrayColor,
                    styles.labelValue,
                  ]}>
                  {idx} out of {totalItems}
                </Text>
              </View>
            </View>}
          </View>
        </View>
      )
    }

    render() {
      const { billData, installmentDetails } = this.props;
      let paidAmount = installmentDetails.reduce((t, n) => t + n.amountPaid, 0)
      let payableAmt = parseFloat(billData.billAmount - paidAmount);
      paidAmount = parseFloat(paidAmount || 0.00).toFixed(2);
      let maxlimit = 21;
      return (
        <View>
          {this.responsiveView(payableAmt, maxlimit, paidAmount)}
          {this.webView(payableAmt, maxlimit, paidAmount)}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  cardHeadingText: {
    color: "#182832",
    display: "block",
    fontSize: 16,
  },
  cardSubHeadingText: {
    color: "#444444",
    display: "block",
    fontSize: 14,
    marginTop: 4,
    marginRight: 0,
    marginLeft: 0,
  },
  cardSmlHeadingText: {
    color: "#888888",
    display: "block",
    fontSize: 12,
    margin: 0,
  },
  labelValue: {
    fontWeight: 400,
  },
  billerDetails: {
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 2,
        };
    },
  },
  billerDetailsRow: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 16,
    marginLeft: 0,
    flexDirection: "row",
    screen: ({ width, height }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
        };
    },
  },
  webView: {
    display: "none",
    screen: ({ width }) => {
      if (width >= 640)
        return {
          display: "block",
        };
    },
  },
  mView: {
    display: "none",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          display: "block",
        };
    },
  },
  statusSection: {
    screen: ({ width }) => {
      if (width <= 640)
        return {
          minWidth: "30%"
        };
    },
  },
  paymentPageBillDetailsLabels: {
    minWidth: 175,
    marginRight: 16,
  },
  paymentPageBillDetailsValue: {
    flexBasis: "100%",
  },
  webViewUserIconDiv: {
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    backgroundColor: "#e7e7e7",
    position: "relative",
    marginRight: 16,
    screen: ({ width }) => {
      if (width >= 640 && width <= 1024)
        return {
          display: "none",
        };
    },
  },
  billDetailsHeaderLeftSection: {
    width: "50%",
  },
  billerNameSection: {
    paddingTop: 7,
  },
  minWidth40: {
    width: "50%",
  },
  dateRowSpacing: {
    // paddingTop: ,
    minWidth: "100%",
  },
  payPageBillerDetailsRow: {
    marginBottom: 5,
  },
  fullWidth: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%"
        }
      }
    }
  },
  minWidth115: {
    minWidth: 115
  },
  tooltipStyles: {
    // marginTop: -10,
    top: 0,
    position: "relative",
    minHeight: 50,
    left: 0,
  },
  priorityInfoTooltip: {
    right: "110%",
    minHeight: 40,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    screen: ({ width, height }) => {
      if (width === 810 && (height === 1010 || height === 1080)) {
        return {
          right: Platform.OS !== "web" ? "110%" : "110%",
        }
      }
      if (width <= 991) {
        return {
          right: Platform.OS !== "web" ? "110%" : "100%",
        }
      }
    }
  },
  bigPriorityInfoTooltip: {
    bottom: 0,
  },
  smallPriorityInfoTooltip: {
    bottom: 10,
  },
  billDetailsRowInner: {
    maxWidth: "50%",
  }
});
