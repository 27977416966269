import React from "react";
import R14, { Image, View, Text, Button, Modal, IconButton } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { MESSAGE_TYPE } from "../constant/app.data.constant";
import { fontFamilyStyles } from "../assets/css/commonFontStyles";
import { uberDocThemeStyle } from "../assets/css/clients/uberDocThemeStyle";

const errorIcon = require("../assets/images/error.png");
const infoIcon = require("../assets/images/info.png");
const successIcon = require("../assets/images/success.png");
const warningIcon = require("../assets/images/warning.png");
const deleteIcon = require("../assets/images/delete.png");

export default R14.connect( class PopupModalComponent extends React.Component {

  onCrossPress = () => {
    const { handleCancel } = this.props;
    if (handleCancel && typeof handleCancel === "function") {
      handleCancel();
    }
  };

  onClose = () => {
    const { handleClose } = this.props;
    if (handleClose && typeof handleClose === "function") {
      handleClose();
    }
  };

  onOkPress = () => {
    const { handleOk } = this.props;
    if (handleOk && typeof handleOk === "function") {
      handleOk();
    }
  };

  render() {
    const {
      visibility,
      transparent = false,
      heading,
      headerStyle,
      bodyWrapper,
      contentInner,
      okLabel,
      closeLabel,
      handleClose,
      footerClass,
      modalPopupOkBtn,
      modalPopupCancelBtn,
      closeButton,
      handleOk,
      modalOuterWrapper,
      modalContent,
      modalHeading,
      cancelBtnTextStyle,
      okBtnTextStyle,
      bodyContent,
      messageType,
      headingIcon,
      onCrossPress,
      disabledOk = false,
      disabledClose = false,
    } = this.props;

    let id = Math.random().toString().replace('.', '');
    const { isUberDocs } = this.props.app.dm.userSession.state;
    let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
    let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

    return (
      <Modal
        animationType="fade"
        transparent={transparent}
        visible={!!visibility}
        // key={`model_${id}`}
        style={[
          commonStyles.modalWrapper,
          commonStyles.flex1,
          commonStyles.justifyContentCenter,
          commonStyles.alignItemsCenter,
          modalOuterWrapper,
        ]}>
        <View id={`modelB_${id}`} key={`modelB_${id}`} style={[commonStyles.modalBodyWrapper, commonStyles.flex1, commonStyles.flexGrow0, clientStyle.secondaryBgColor, bodyWrapper]}>
          {(heading || onCrossPress || headingIcon) ? (
            <View style={[commonStyles.modalHeader, commonStyles.flexDirectionColumn, headerStyle]}>
              <View style={[commonStyles.flexDirectionRow]}>
              {headingIcon ?
                <Image
                  source={
                    messageType === MESSAGE_TYPE.error ? errorIcon :
                      messageType === MESSAGE_TYPE.warning ? warningIcon :
                        messageType === MESSAGE_TYPE.success ? successIcon :
                        messageType === MESSAGE_TYPE.delete ? deleteIcon :
                          infoIcon
                  }
                  style={[commonStyles.icon20, commonStyles.marginBottom5]}
                /> : null}
              {
                (!!onCrossPress && 1 === 2) ?
                  <IconButton icon="close" size="small" style={[closeButton]} onPress={this.onCrossPress} />
                  : null}
                  </View>
                <View style={[commonStyles.minHeight25]}>
                  <Text style={[commonStyles.heading, commonFontStyles.workSans, commonStyles.heading4, commonStyles.fontWeight500, commonStyles.contentColor, commonStyles.alignItemsCenter, modalHeading]}>{heading}</Text>
                </View>
            </View>
          ) : null}
          <View style={[commonStyles.modalContent, modalContent]}>
            <View style={[commonStyles.innerContent, commonStyles.flex1, commonStyles.justifyContentCenter, contentInner]}>
              <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight400, commonStyles.contentColor, commonStyles.textAlignCenter]}>{bodyContent}</Text></View>
          </View>
          {(!!handleClose || handleOk) ? (
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexDirectionRow,
                commonStyles.alignItemsCenter,
                commonStyles.justifyContentCenter,
                footerClass,
              ]}>
              {handleClose ? (
                <Button
                  title={closeLabel || "Close"}
                  style={[commonStyles.secondayWhiteBtn, commonStyles.btnHeight24, customCommonStyles.noBoxShadow, clientStyle.backAndCancelButton, modalPopupCancelBtn]}
                  onPress={this.onClose}
                  disabled={disabledClose || false}
                  titleTextStyle={[
                    commonFontStyles.workSans,
                    commonStyles.fontWeight500,
                    commonStyles.secondayWhiteBtnText,
                    commonStyles.exSmallHeading,
                    cancelBtnTextStyle,
                    clientStyle.backAndCancelButtonText
                  ]}
                />
              ) : null}
              {handleOk ? (
                <Button
                  title={okLabel || "Ok"}
                  style={[commonStyles.blueButton, commonStyles.btnHeight24, modalPopupOkBtn, customCommonStyles.noBoxShadow, commonStyles.marginLeft10,clientStyle.submitButton ]}
                  onPress={this.onOkPress}
                  disabled={disabledOk || false}
                  titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading, clientStyle.submitButtonText, okBtnTextStyle]}
                />
              ) : null}
            </View>
          ) : null}
        </View>
      </Modal>
    );
  }
});