import React from "react";
import { View, StyleSheet, Button, Modal, IconButton, SectionHeader } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";

export default class ModalPopUp extends React.Component {
  onCrossPress = () => {
    const { handleCancel } = this.props;
    if (handleCancel && typeof handleCancel === "function") {
      handleCancel();
    }
  };

  onClose = () => {
    const { handleClose } = this.props;
    if (handleClose && typeof handleClose === "function") {
      handleClose();
    }
  };

  onOkPress = () => {
    const { handleOk } = this.props;
    if (handleOk && typeof handleOk === "function") {
      handleOk();
    }
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (prevState.visibility !== nextProps.visibility) {
  //     return { visibility: nextProps.visibility };
  //   }

  //   return null;
  // }

  render() {
    const {
      visibility,
      transparent = false,
      heading,
      headerStyle,
      bodyWrapper,
      contentInner,
      isFooter,
      okLabel,
      closeLabel,
      handleClose,
      footerClass,
      modalPopupOkBtn,
      modalPopupCancelBtn,
      closeButton,
      handleOk,
      modalOuterWrapper,
      modalContent,
      modalHeading,
      // onBackdropPress,
      cancelBtnTextStyle,
      okBtnTextStyle,
    } = this.props;
    return (
      <Modal
        animationType="fade"
        transparent={transparent}
        visible={visibility}
        style={[
          commonStyles.modalWrapper,
          commonStyles.flex1,
          commonStyles.justifyContentCenter,
          commonStyles.alignItemsCenter,
          // commonStyles.flexWrap,
          modalOuterWrapper,
        ]}>
        <View style={[commonStyles.modalBodyWrapper, commonStyles.flex1, commonStyles.flexGrow0, bodyWrapper]}>
          {heading ? (
            <View style={[commonStyles.modalHeader, headerStyle]}>
              <SectionHeader
                style={[commonStyles.heading, commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight500, commonStyles.darkGrayColor, modalHeading]}
                level={2}>
                {heading}
              </SectionHeader>

              <IconButton icon="close" size="small" style={[styles.crossIcon, closeButton]} onPress={this.onCrossPress} />
            </View>
          ) : null}
          <View style={[commonStyles.modalContent, modalContent]}>
            <View style={[commonStyles.innerContent, contentInner]}>{this.props.children}</View>
          </View>
          {!isFooter ? (
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexDirectionRow,
                commonStyles.alignItemsEnd,
                commonStyles.justifyContentFlexEnd,
                footerClass,
              ]}>
              {handleClose ? (
                <Button
                  title={closeLabel || "Close"}
                  style={[commonStyles.transperentBtn, modalPopupCancelBtn]}
                  onPress={this.onClose}
                  titleTextStyle={[
                    commonFontStyles.workSans,
                    commonStyles.fontWeight500,
                    commonStyles.heading6,
                    commonStyles.transperentBtnColor,
                    cancelBtnTextStyle,
                  ]}
                />
              ) : null}
              {handleOk ? (
                <Button
                  title={okLabel || "Ok"}
                  style={[commonStyles.blueButton, modalPopupOkBtn, customCommonStyles.noBoxShadow,]}
                  onPress={this.onOkPress}
                  titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, okBtnTextStyle]}
                />
              ) : null}
            </View>
          ) : null}
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  // crossIcon: {
  //   height: 38,
  // },
});
