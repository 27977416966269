import { StyleSheet } from "../../core";
// import "../../assets/fonts/fontLibrary.css";
/**
 * Font weight matrix
 * regular = 400
 * medium = 500
 * bold = 600
 */
const commonFontStyles = StyleSheet.create({
  workSans: {
    fontFamily: "Work Sans",
    // fontFamily: "WorkSansRegular",
  },
  bodyText: {
    fontFamily: "Work Sans",
    fontSize: 13,
    color: "#182832",
    fontWeight: "400"
  },
  leftMenuText: {
    fontFamily: "Work Sans",
    fontSize: 13,
    color: "#000000",
    fontWeight: "500"
  },
  userPopDownMenuText: {
    fontFamily: "Work Sans",
    fontSize: 13,
    color: "#182832",
    fontWeight: "500"
  },
  header: {
    fontFamily: "Work Sans",
    fontSize: 20,
    color: "#182832",
    fontWeight: "500"
  },
  subHeader: {
    fontFamily: "Work Sans",
    fontSize: 18,
    color: "#182832",
    fontWeight: "500"
  },
  subSubHeader: {
    fontFamily: "Work Sans",
    fontSize: 16,
    color: "#182832",
    fontWeight: "400"
  },
  filterFrameHeader: {
    fontFamily: "Work Sans",
    fontSize: 12,
    color: "#182832",
    fontWeight: "600"
  },
  buttonText: {
    fontFamily: "Work Sans",
    fontSize: 11,
    fontWeight: "500"
  },
  formObjectText: {
    fontFamily: "Work Sans",
    fontSize: 12,
    fontWeight: "500"
  },
  dataTableHeadText: {
    fontFamily: "Work Sans",
    fontSize: 12,
    fontWeight: "500"
  },
  dataTableBodyText: {
    fontFamily: "Work Sans",
    fontSize: 11,
    fontWeight: "400"
  },
  dataTablePaginationText: {
    fontFamily: "Work Sans",
    fontSize: 12,
    fontWeight: "400"
  },
});

const fontFamilyStyles = StyleSheet.create({
  workSans: {
    fontFamily: "Work Sans",
    // fontFamily: "WorkSansRegular",
  },
});
export { commonFontStyles, fontFamilyStyles };
