import React from "react";
import R14, {
  View,
  Platform
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import InAppScanner from "../../components/InAppScanner";
import InAppCameraComponent from "../../components/InAppCameraComponent";
import { CommonString } from "../../constant/appCommonText";
import Linking from "../../utils/Linking";

export default R14.connect(
  class ScanQRcodeScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showScanner: true,
        IsScannerVisible: false,
        isAlertBox: false,
        alertMessage: "",
        alertMessageHeading: "Scanning",
        retryCount: 0,
      }
    }

    onCamClose = async () => {
      this.setState({
          showScanner: !this.state.showScanner
      })
    };

    handleSubmit = async (data) => {
      if (data) {
        Linking.LinkingURL(data);
      }
    }

    onCancel = async() => {
      this.setState({
        IsScannerVisible: false,
        isAlertBox: false,
        alertMessage: "",
        alertMessageHeading: "Scanning",
        retryCount: 0,
        delay: 5000,
        result: "",
        showScanner: false,
      });
      this.props.app.nav.back();
    }

    gotDevices = (mediaDevices) =>
      new Promise((resolve, reject) => {
        const availableVideoInputs = [];
        mediaDevices.forEach((mediaDevice) => {
          if (mediaDevice.kind === "videoinput") {
            // audioinput //videoinput
            availableVideoInputs.push({
              deviceId: mediaDevice.deviceId,
              label: mediaDevice.label,
            });
          }
        });

        if (availableVideoInputs.length > 0) {
          resolve(availableVideoInputs);
        } else {
          reject(new Error("ERR::NO_MEDIA_TO_STREAM"));
        }
      });

    getMedia = async (constraints) => {
      try {
        await navigator.mediaDevices.getUserMedia(constraints);
        /* use the stream */
      } catch (err) {
        console.log(err);
      }
    };

    resolvePermission = (permission) =>
      new Promise((resolve, reject) => {
        permission.onchange = function () {
          console.log("Permission changed to " + permission.state);
        };

        const permissions = [permission.state];
        if (permissions.length > 0) {
          resolve(permissions);
        } else {
          reject(new Error("ERR::NO_MEDIA_TO_STREAM_PERMISSION"));
        }
      });

    getPermission = async (PermissionDescriptor) => {
      try {
        navigator.permissions
          .query(PermissionDescriptor)
          .then(this.resolvePermission)
          .then((permission) => {
            if (permission.includes("granted")) {
              this.setState({
                IsScannerVisible: true,
                isAlertBox: false,
                alertMessage: "",
                alertMessageHeading: "Scanning",
                retryCount: 0,
              });
            } else if (permission.includes("prompt") || permission.includes("denied")) {
              this.setState({
                IsScannerVisible: true,
                isAlertBox: false,
                alertMessage: "",
                alertMessageHeading: "Scanning",
                retryCount: 0,
              });
              this.setState({
                IsScannerVisible: true,
                isAlertBox: true, //true
                alertMessage: "Allow XBP to take pictures and record video",
                alertMessageHeading: "Allow XBP to use camera",
                retryCount: 0,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) { }
    };

    scanningQRCode = () => {
      navigator.mediaDevices
        .enumerateDevices()
        .then(this.gotDevices)
        .then((availableVideoInputs) => {
          if (availableVideoInputs.length > 0) {
            this.getPermission({ name: "camera" });
          }
        })
        .catch((err) => {
          this.setState({
            IsScannerVisible: true,
            isAlertBox: true, //true
            alertMessage:
              "Check your system settings to make sure that a camera is available. If not, plug one in. You might then need to restart your browser.",
            alertMessageHeading: "Can not find the camera",
            retryCount: 0,
          });
        });
      if(!!this.props.app.dm.userSession.state.leftMenuOverlay && this.props.app.dm.notificationDomain.state.isMobileDevice)
        this.props.app.dm.userSession.setState({
          leftMenuToggle: !this.props.app.dm.userSession.state.leftMenuToggle,
          leftMenuOverlay: !this.props.app.dm.userSession.state.leftMenuOverlay,
        });
    };

    onScan = async (data) => {
      if (data) {
        data = data.text || "";
        this.setState({
          result: data
        })
        Linking.LinkingURL(data);
      };
    };

    onRetry = (message = "Invalid QR Code", isOnError = false) => {
      let retryCount = this.state.retryCount;
      this.props.app.dm.commonDomain.showToastr("error", message);
      this.setState({
        retryCount: retryCount + 1,
      });

      if (!!isOnError) {
        if (retryCount > 3)
          setTimeout(() => {
            this.onCancel();
          }, 5000);
        else
          setTimeout(() => {
            this.onRetry(message, isOnError);
          }, 5000);
      }
    };

    onError = (err) => {
      console.log(err)
      this.onRetry(CommonString.SomethingWentWrong, true);
    };


    render() {
      Platform.OS === "web" && this.scanningQRCode();
      const {
        IsScannerVisible,
        isAlertBox,
        alertMessage,
        alertMessageHeading,
        retryCount} = this.state;
      return (
        <>
            <View style={[commonStyles.marginBottom16, commonStyles.dashboardScrollView, commonStyles.flex1]}>
              {!!IsScannerVisible && Platform.OS === "web" ? <InAppScanner
                IsScannerVisible={IsScannerVisible}
                isAlertBox={isAlertBox}
                alertMessage={alertMessage}
                alertMessageHeading={alertMessageHeading}
                retryCount={retryCount}
                onScan={this.onScan}
                onCancel={this.onCancel}
              /> : null}
              {Platform.OS !== "web" ? <InAppCameraComponent
                isCamVisible={this.state.showScanner}
                onValueChange={null}
                onCancel={this.onCancel}
                hasPermission={true}
                closeCam={this.onCancel}
                handleSubmit={this.handleSubmit}
              /> : null}
            </View>
        </>
      );
    }
  }
);

