import React from "react";
import R14, {
  StyleSheet, 
  Image,
  Text,
  View, 
  Form,
  Button,
  TextInputField, 
  SubmitButton, 
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import CommonValidators from "../utils/CommonValidators";
import LightBlueRadioButtons from "../components/LightBlueRadioButtons";
import DateUtils from "../utils/DateUtils";
import { CommonString } from "../constant/appCommonText";

const visaIcon = require("../assets/images/Visa.png");
const americanExpressIcon = require("../assets/images/american-express.png");
const mastercardIcon = require("../assets/images/Mastercard.png");

export default R14.connect(
  class AddNewCardScreen extends React.Component {
    constructor(props) {
      super(props);
      this.cardOption = [
        { label: "Credit Card", value: "Credit Card" },
        { label: "Debit Card", value: "Debit Card" },
      ];
      this.state = {
        monthExp: null,
        yearExp: null,
        selectedOpt: null,
        cardHolderName: null,
        cardNumber: null,
        expiryDate: null,
      };
      this.initialValues = { cardHolderName: null, cardNumber: null, expiryDate: null };
      this.form = null;
      this.user = this.props.userDetails;
      this.monthList = DateUtils.monthList();
      this.yearList = DateUtils.yearList();
    }

    onDateSelect(val) {
      if (!val) {
        this.form.elmts.expiryDate.setError(CommonString.ExpiryRequired);
      } else if (new Date(val) > new Date()) {
        this.form.elmts.expiryDate.setValue(DateUtils.formatDate(val));
        this.form.elmts.expiryDate.setError(null);
        let month = new Date(val).getMonth();
        let year = new Date(val).getFullYear();
        this.setState({
          monthExp: month + 1,
          yearExp: year,
          expiryDate: val,
        });
      } else {
        this.form.elmts.expiryDate.setValue(DateUtils.formatDate(val));
        this.form.elmts.expiryDate.setError(CommonString.CardExpiryError);
      }
    }

    componentDidMount() {
      this.form = this.props.app.ui.form("creditCard");
    }

    addCardHolderName(val) {
      this.setState({
        cardHolderName: val,
      });
    }

    addMask(val) {
      let form = this.props.app.ui.form("creditCard");
      if (!!val) {
        let cardDisplay = val.split("-").join(""); // remove hyphens
        cardDisplay = cardDisplay
          .match(new RegExp(".{1,4}", "g"))
          .join("-")
          .replace(/[^\d-]/g, "")
          .substring(0, 19);
        form.elmts.cardNumber.setValue(cardDisplay);
        this.setState({ cardNumber: val });
      }
    }

    onValueChange = (val) => {
      this.setState({ selectedOpt: val });
    };

    navigateToBack = async () => {
      this.props.app.nav.back();
    };
    onMonthSelect = (val) => {
      let { label, value } = val;
      this.setState({ monthExp: value });
      let form = this.props.app.ui.form("creditCard");
      form.elmts.month.setValue({ label: label, value: value });
    };
    onYearSelect = (val) => {
      let { label, value } = val;
      this.setState({
        yearExp: value,
      });
      let form = this.props.app.ui.form("creditCard");
      form.elmts.year.setValue({ label: label, value: value });
    };

    onSubmit = async (form) => {
      let monthExpDate = parseInt(this.state.monthExp) - 1;
      let yearExpDate = parseInt(this.state.yearExp);
      let days = new Date(yearExpDate, monthExpDate + 1, 0).getDate();
      const expiryCardDate = new Date(yearExpDate, monthExpDate, days);
      let month = new Date(expiryCardDate).getMonth();
      if (new Date(expiryCardDate) < new Date()) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.CardExpiryError);
        return false;
      }

      let payload = {
        cardHolderName: form.values.cardHolderName,
        cardNumber: form.values.cardNumber ? form.values.cardNumber.replace(/"-"/g, "") : null,
        monthExpDate: month + 1, //this.state.monthExp,
        yearExpDate: yearExpDate,
        // validCVV: form.values.cvv,
      };

      // let cardTokenPayload = await this.props.app.dm.billDetails.createGetCardTokenPayload(token.AuthorizeResult, payload);
      // let resultCardToken = await this.props.app.dm.billDetails.getCardToken_Old(cardTokenPayload);
      let resultCardToken = await this.props.app.dm.billDetails.getCardToken(payload);
      if (!this.state.selectedOpt) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.CardTypeError);
        return false;
      }
      let cardType;
      if (resultCardToken.GetCardTokenResult.display_number) {
        cardType = resultCardToken.GetCardTokenResult.display_number.split(" ");
      }

      if (resultCardToken.GetCardTokenResult.status === "SUCCESS" || resultCardToken.GetCardTokenResult.status === "SUBMITTED") {
        let payerCardDetails = {
          payerID: this.user.payerId,
          paymentType: this.state.selectedOpt,
          cardType: cardType[0],
          token: resultCardToken.GetCardTokenResult.token_id,
          displayNumber: resultCardToken.GetCardTokenResult.display_number,
          status: "Enabled",
          cardHolderName: form.values.cardHolderName,
        };
        let cardList = await this.props.app.dm.billDetails.getPayerCardDetailsList();
        if (cardList.length !== 0) {
          let data = cardList.filter(
            (element) =>
              element.displayNumber === payerCardDetails.displayNumber &&
              element.payerID === payerCardDetails.payerID &&
              element.paymentType === payerCardDetails.paymentType
          );

          if (!!data.length) {
            try {
              payerCardDetails.uid = data[0].uid;
              payerCardDetails.isSetDefault = data[0].isSetDefault;
              let update = await this.props.app.dm.billDetails.updatePayerCardDetails(payerCardDetails);

              if (update) {
                this.props.app.dm.commonDomain.showToastr("success", `${this.state.selectedOpt} updated successfully.`);
              } else {
                this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
              }
            } catch (error) {
              console.log("error", error);
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
          } else {
            try {
              let create = await this.props.app.dm.billDetails.savePayerCardDetails(payerCardDetails);

              if (create) {
                this.props.app.dm.commonDomain.showToastr("success", `${this.state.selectedOpt} added successfully.`);
              } else {
                this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
              }
            } catch (error) {
              console.log("error", error);
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
          }
        } else {
          try {
            let create = await this.props.app.dm.billDetails.savePayerCardDetails(payerCardDetails);

            if (create) {
              this.props.app.dm.commonDomain.showToastr("success", `${this.state.selectedOpt} added successfully.`);
            } else {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
          } catch (error) {
            console.log("error", error);
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }
        }
        setTimeout(() => {
          this.props.app.nav.back();
        }, 1000);
      } else {
        this.props.app.dm.commonDomain.showToastr("error", `${resultCardToken.GetCardTokenResult.error_desc}`);
        return;
      }
    };

    render() {
      const { selectedOpt } = this.state;

      const { cardHolderName, cardNumber, monthExp, yearExp } = this.state;

      return (
        <View style={styles.addNewCardScreen}>
          <View style={[commonStyles.marginTop10, commonStyles.marginBottom5]}>
            <Text style={[commonStyles.exSmallHeading, commonStyles.darkGrayColor, commonStyles.fontWeight300, commonStyles.lightGray]}>
              We support
            </Text>
          </View>
          <View style={commonStyles.dFlex}>
            <Image source={visaIcon} style={[commonStyles.icon36x22]} />
            <Image source={americanExpressIcon} style={[commonStyles.icon36x22, commonStyles.marginLeftRight15]} />
            <Image source={mastercardIcon} style={[commonStyles.icon36x22]} />
          </View>
          <View style={[commonStyles.dFlex, commonStyles.marTop30, commonStyles.alignItemsCenter]}>
            {this.cardOption.map((el, key) => (
              <LightBlueRadioButtons
                radioButtonsClass={[
                  commonStyles.margin0,
                  commonStyles.padding0,
                  commonStyles.flexBasis50,
                  el.label === "Debit Card" ? commonStyles.marginLeft12 : null,
                ]}
                imgClass={commonStyles.marginRight15}
                options={el}
                key={key}
                onValueChange={this.onValueChange}
                isSelected={selectedOpt}
              />
            ))}
          </View>

          <Form
            name="creditCard"
            validators={CommonValidators.getFormValidator()}
            validateBeforeSubmit={true}
            onSubmit={this.onSubmit}
            initialValues={this.initialValues}
            style={[commonStyles.padding0, commonStyles.marTop30, commonStyles.flexGrow1]}>
            <View style={[commonStyles.dFlex, commonStyles.flexWrap]}>
              <View
                style={[commonStyles.flex1, commonStyles.flexBasis48, commonStyles.marginRight8, styles.inputFields, commonStyles.marginBottom20]}>
                <TextInputField
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}
                  labelStyle={[commonStyles.formLabelStyle]}
                  name="cardHolderName"
                  label={CommonString.CardHolderNameLabel}
                  validator="min2Max26"
                  required={CommonString.CardHolderNameRequired}
                  onChangeText={(val) => this.addCardHolderName(val)}
                  style={[
                    commonStyles.margin0,
                    styles.messageTextField,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight500,
                  ]}
                />
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexBasis48, commonStyles.marginLeft8, styles.inputFields, commonStyles.marginBottom20]}>
                <TextInputField
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}
                  labelStyle={[commonStyles.formLabelStyle]}
                  name="cardNumber"
                  validator="cardValidator"
                  label={CommonString.CardNumberLabel + "*"}
                  required={CommonString.EnterCardNoRequired}
                  onChangeText={(val) => this.addMask(val)}
                  style={[
                    commonStyles.margin0,
                    styles.messageTextField,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight500,
                  ]}
                />
              </View>
            </View>
            <Text style={[commonStyles.heading5, commonStyles.darkGrayColor, commonFontStyles.workSans]}>Expiry Date</Text>
            <View style={[styles.expiryDateHolder, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween]}>
              <View style={[commonStyles.flexGrow1, styles.month]}>
                {/* <SelectMenuField
                  name="month"
                  label="Month*"
                  required="Select the Month"
                  items={this.monthList}
                  onValueChange={this.onMonthSelect}
                  labelTextStyle={[commonStyles.labelText,commonFontStyles.workSans, commonStyles.heading6]}
                /> */}
              </View>
              <View style={[commonStyles.flexGrow1, styles.year]}>
                {/* <SelectMenuField
                  name="year"
                  label="Year*"
                  required="Select the Year"
                  items={this.yearList}
                  onValueChange={this.onYearSelect}
                  labelTextStyle={[commonStyles.labelText,commonFontStyles.workSans, commonStyles.heading6]}
                /> */}
              </View>
            </View>
            <View style={[commonStyles.dFlex, commonStyles.marTAuto, commonStyles.marginBottom30, styles.buttonWrapper]}>
              <Button
                key="cancel"
                title="Cancel"
                style={[commonStyles.blueBorderButton, commonStyles.flexBasis50, commonStyles.marginRight15, styles.buttons]}
                titleTextStyle={[commonStyles.fontWeight500, commonStyles.blueBorderButtonColor, commonFontStyles.workSans]}
                onPress={this.navigateToBack}
              />
              <SubmitButton
                title="ADD"
                key="add"
                //disabled={!cardHolderName || !cardNumber || !expiryDate || !selectedOpt}
                disabled={!cardHolderName || !cardNumber || !selectedOpt || !monthExp || !yearExp}
                style={[commonStyles.flexBasis50, commonStyles.blueButton, styles.buttons]}
                titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonStyles.blueButtonColor, commonFontStyles.workSans]}
              />
            </View>
          </Form>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  addNewCardScreen: {
    flex: 0,
    height: "100%",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    paddingRight: 15,
    paddingLeft: 15,
    marginLeft: 16,
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          alignItems: "center",
          margin: 0,
        };
    },
  },
  expiryDateHolder: {
    marginTop: 10,
  },
  month: {
    marginRight: 10,
    flexGrow: 1,
    flexBasis: "50%",
  },
  year: {
    marginLeft: 10,
    flexGrow: 1,
    flexBasis: "50%",
  },
  inputFields: {
    screen: ({ width }) => {
      if (width >= 991 && width <= 1083) {
        return {
          flexBasis: "48%",
        };
      }
      if (width <= 991) {
        return {
          flexBasis: "100%",
          margin: 0,
        };
      }
    },
  },
  buttonWrapper: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          justifyContent: "flex-end",
        };
      }
    },
  },
  buttons: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          flexBasis: "auto",
          flexGrow: 0,
          minWidth: "108px",
        };
      }
    },
  },
});
