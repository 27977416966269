import R14, { } from "../../core";
import CommonUtility from "../../utils/CommonUtility";
export default class AddInsuranceDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      insuranceItems: [],
      totalBills: 0,
      currentPage: 1,
      totalPages: 1,
      resultsPerPage: 9,
      isFetched: false
    };
  }

  async getPchInsuranceDetails(filter, fields = "uid isPrimary name groupName groupNumber poBoxNo address1 city state zipCode userLoginDetailUid poBoxNo isActive isParticipant") {
    filter.search = filter.search || "";
    let result = await this.dm.queryDomain.middleware(this.api.qry(
      `query GetPchInsuranceDetails($userId: String!, $search: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        getPchInsuranceDetails(userId: $userId, search: $search, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
      filter
    ));
    if (!!result.data.getPchInsuranceDetails && !!result.data.getPchInsuranceDetails.nodes) {
      let totalCount = result.data.getPchInsuranceDetails.totalCount;
    const { currentPage, resultsPerPage} = this.state;
    this.setState({
        totalBills: totalCount,
        totalPages: Math.ceil(totalCount / resultsPerPage),
        isFetched:  currentPage === 1 ? true: false,
        insuranceItems: result.data.getPchInsuranceDetails.nodes 
      });
      return this.state.insuranceItems;
    }
    else return [];
  }

  async pchInsuranceDetails(filter, fields = "isPrimary uid name isActive") {
    filter.search = filter.search || "";
    let result = await this.api.qry(
      `query PchInsuranceDetails($filter: PchInsuranceDetailFilter,  $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        pchInsuranceDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          nodes {  ${fields} }
        }
      }`,
      filter
    );
    if (!!result.data.pchInsuranceDetails && !!result.data.pchInsuranceDetails.nodes) {
      return result.data.pchInsuranceDetails.nodes;
    }
    else return [];
  }

  async getSharedLists(filter, fields = "uid isPrimary name groupName groupNumber poBoxNo address1 city state zipCode isActive isParticipant") {
    let result = await this.api.qry(
      `query MstInsuranceDetail($filter: MstInsuranceDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        mstInsuranceDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
      filter
    );
    if (!!result.data.mstInsuranceDetails && !!result.data.mstInsuranceDetails.nodes) {
      let totalCount = result.data.mstInsuranceDetails.totalCount;
    const { currentPage, resultsPerPage} = this.state;
    this.setState({
        totalBills: totalCount,
        totalPages: Math.ceil(totalCount / resultsPerPage),
        isFetched:  currentPage === 1 ? true: false,
        insuranceItems: result.data.mstInsuranceDetails.nodes 
      });
      return this.state.insuranceItems;
    }
    else return [];
  }

  async getMstInsuranceDetails(filter, fields = "uid isPrimary name groupName groupNumber poBoxNo address1 city state zipCode isActive isParticipant") {
    filter.search = filter.search || "";
    let result = await this.dm.queryDomain.middleware(this.api.qry(
      `query GetMstInsuranceDetails($search: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        getMstInsuranceDetails(search: $search, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
      filter
    ));
    if (!!result.data.getMstInsuranceDetails && !!result.data.getMstInsuranceDetails.nodes) {
      let totalCount = result.data.getMstInsuranceDetails.totalCount;
    const { currentPage, resultsPerPage} = this.state;
    this.setState({
        totalBills: totalCount,
        totalPages: Math.ceil(totalCount / resultsPerPage),
        isFetched:  currentPage === 1 ? true: false,
        insuranceItems: result.data.getMstInsuranceDetails.nodes 
      });
      return this.state.insuranceItems;
    }
    else return [];
  }

  async getInsuranceDetailsWithPage(fieldsStr = "uid isPrimary name insuranceCompanyID planName groupName groupNumber email phoneNumber address1 address2 city state country zipCode employerName memberID memberFirstName memberLastName memberMiddleInitial userLoginDetailUid poBoxNo isActive documents { filename actualsize } isParticipant", totalRecords = 0) {
    const { isFetched, currentPage, resultsPerPage, insuranceItems, totalBills } = this.state;
    if (!!isFetched  && totalBills > 0) return insuranceItems;
    else {
      fieldsStr = "uid isPrimary name groupName groupNumber poBoxNo address1 city state zipCode userLoginDetailUid poBoxNo isActive isParticipant";
      const userLoginDetail = await this.dm.rest.getUser();
      let filter = {
        userId: userLoginDetail.uid,
        sort: { field: "createdAt", order: "DESC" },
        page: currentPage,
        resultsPerPage: totalRecords || resultsPerPage
      };

      return await this.getPchInsuranceDetails(filter, fieldsStr, resultsPerPage);
    }
  }

  async getSharedInsuranceDetailsWithPage(fieldsStr = "uid isPrimary name insuranceCompanyID planName groupName groupNumber email phoneNumber address1 address2 city state country zipCode employerName memberID memberFirstName memberLastName memberMiddleInitial userLoginDetailUid poBoxNo isActive documents { filename actualsize } isParticipant") {
    const { isFetched, currentPage, resultsPerPage, insuranceItems, totalBills } = this.state;
    if (!!isFetched  && totalBills > 0) return insuranceItems;
    else {
      fieldsStr = "uid isPrimary name groupName groupNumber poBoxNo address1 city state zipCode isActive isParticipant";
      let filter = {
        sort: { field: "createdAt", order: "DESC" },
        page: currentPage,
        resultsPerPage: resultsPerPage
      };

      return await this.getMstInsuranceDetails(filter, fieldsStr, resultsPerPage);
    }
  }

  // NOTE: insert Insurance Detail.
  async insertInsuranceDetail(input, fields = "uid isPrimary name insuranceCompanyID planName groupName groupNumber email phoneNumber address1 address2 city state country zipCode employerName memberID memberFirstName memberLastName memberMiddleInitial userLoginDetailUid poBoxNo isActive documents { filename }") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `mutation CreatePchInsuranceDetail($input: CreatePchInsuranceDetailInput){
            createPchInsuranceDetail(input: $input){              
              success pchInsuranceDetail {
                ${fields}
              }
            }
          }`,
        { input }
      );
      return res.data.createPchInsuranceDetail.pchInsuranceDetail;
    } catch (error) {
      return error;
    }
  }

  async getInsuranceOnSearch(uid, searchable, fields = "uid isPrimary name groupNumber poBoxNo isActive isParticipant address1 state city") {
    try {
      let filter = {
        filter: {
          userLoginDetailUid: { eq: uid },
          name: { like: `%${searchable}%` },
          isActive: { eq: "true" },
        },
        sort: { field: "name", order: "ASC" },
        page: 1,
        resultsPerPage: 10
      };
      let res = await this.api.qry(
        `query PchInsuranceDetail($filter: PchInsuranceDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
              pchInsuranceDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
                totalCount @include(if: true)
                nodes {
                  ${fields}
                }
              }
            }`,
        filter
      );

      return res.data.pchInsuranceDetails.nodes;
    } catch (err) {
      return [];
    }
  }

  async getInsurance(uid, fields = "uid isPrimary name insuranceCompanyID planName groupName groupNumber email phoneNumber address1 address2 city state country zipCode employerName memberID memberFirstName memberLastName memberMiddleInitial userLoginDetailUid poBoxNo isActive countryObject{label value}  stateObject{label value} cityObject{label value} documents { filename actualsize uid contentType seqNo } isParticipant") {
    try {
      let res = await this.api.qry(
        `query PchInsuranceDetail($uid: ID!){
          pchInsuranceDetail(uid: $uid){
                ${fields}
            }
          }`,
        {
          uid: uid
        }
      );
      return res.data.pchInsuranceDetail;
    }
    catch (err) {
      return err;
    }
  }

  async getMstInsurance(uid, fields = "uid isPrimary name insuranceCompanyID planName groupName groupNumber email phoneNumber address1 address2 city state country zipCode employerName poBoxNo isActive serialNo countryObject {label value}  stateObject {label value} cityObject {label value} documents { filename actualsize uid contentType seqNo } isParticipant") {
    try {
      let res = await this.api.qry(
        `query MstInsuranceDetail($uid: ID!){
          mstInsuranceDetail(uid: $uid){
                ${fields}
            }
          }`,
        {
          uid: uid
        }
      );
      return res.data.mstInsuranceDetail;
    }
    catch (err) {
      return err;
    }
  }

  async updateInsuranceDetail(input, fields = "uid") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
          mutation UpdatePchInsuranceDetail($input: UpdatePchInsuranceDetailInput){
            updatePchInsuranceDetail(input: $input){              
              success pchInsuranceDetail {
                ${fields}
              }
            }
          }`,
        { input }
      );
      return res.data.updatePchInsuranceDetail.pchInsuranceDetail;
    } catch (error) {
      return error;
    }
  }

  //NOTE:check referacne in bill table
  async checkExistinUploadBill(filter, fields = "uid") {
    try {
      let res = await this.api.qry(
        `
          query UploadBill($filter: UploadBillFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
            uploadBills(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
              totalCount @include(if: true)
              nodes {
                ${fields}
              }
            }
          }`,
        {
          filter: {
            ...filter,
            
          },
          page: 1,
          resultsPerPage: 1
        }
      );
      return res.data.uploadBills.nodes ? res.data.uploadBills.nodes : {};
    } catch (err) {
      return err;
    }
  }

  
  async fetchInsuranceDocuments(
    uid,
    fields = "uid documents { filename content contentType size  actualsize}",
  ) {
    let options = {
      uid: uid
    };
    try {
      let res = await this.api.qry(
        `query PchInsuranceDetail($uid: ID!){
            pchInsuranceDetail(uid: $uid){
                ${fields}
            }
        }`,
        options
      );
      return res.data.pchInsuranceDetail;
    } catch (error) {
      return error;
    }
  }

  // NOTE: insert Member Detail.
  async insertMembereDetail(input, fields = "uid memberId insuranceId firstName lastName dob gender isActive isPrimaryPolicyHolder relationToInsured") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `mutation CreateInsuranceMemberDetail($input: CreateInsuranceMemberDetailInput){
            createInsuranceMemberDetail(input: $input){              
              success insuranceMemberDetail {
                ${fields}
              }
            }
          }`,
        { input }
      );
      return res.data.createInsuranceMemberDetail.insuranceMemberDetail;
    } catch (error) {
      return error;
    }
  }

  //  get memberList
  async getInsuranceMembersList(filter, fields = "uid memberId insuranceId firstName lastName dob gender isPrimaryPolicyHolder isActive updatedBy deletedBy relationToInsured"){
    try {
      let result = await this.api.qry(
      `query InsuranceMemberDetail($filter: InsuranceMemberDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        insuranceMemberDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
      filter
    );
      if (!!result.data.insuranceMemberDetails && !!result.data.insuranceMemberDetails.nodes){
        return result.data.insuranceMemberDetails.nodes
      } else {
        return []
      }
    }
    catch (err) {
      return err;
    }
  }

  // Update member details
  async updateMemberDetail(input, fields = "uid memberId insuranceId firstName lastName dob gender isPrimaryPolicyHolder isActive updatedBy deletedBy relationToInsured") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
          mutation UpdateInsuranceMemberDetail($input: UpdateInsuranceMemberDetailInput){
            updateInsuranceMemberDetail(input: $input){              
              success insuranceMemberDetail {
                ${fields}
              }
            }
          }`,
        { input }
      );
      return res.data.updateInsuranceMemberDetail.insuranceMemberDetail;
    } catch (error) {
      return error;
    }
  }

  // get mst cpt list
  async getMstCptDetails(filter, fields = "uid label value effectiveDate expiryDate") {
    try {
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetMstCPTDetails($search: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
          getMstCPTDetails(search: $search, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            totalCount @include(if: true)
            nodes {
              ${fields}
            }
          }
        }`,
        filter
      ));
      if (!!result.data.getMstCPTDetails && !!result.data.getMstCPTDetails.nodes) return result.data.getMstCPTDetails.nodes || [];
      return []; 
    } catch (error) {
      return [];
    }
  }

  // get mst diagnosis list
  async getMstDiagnosisDetails(filter, fields = "uid label value effectiveDate expiryDate") {
    try {
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetMstDiagnosisDetails( $search: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        getMstDiagnosisDetails(search: $search, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
        filter
      ));
      if (!!result.data.getMstDiagnosisDetails && !!result.data.getMstDiagnosisDetails.nodes) return result.data.getMstDiagnosisDetails.nodes || [];
      return []; 
    } catch (error) {
      return [];
    }
  }

  // get mst modifer list
  async getMstModifiersDetails(filter, fields = "uid label value") {
    try {
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetMstModifiersDetails( $search: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        getModifiers(search: $search, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
        filter
      ));
      if (!!result.data.getModifiers && !!result.data.getModifiers.nodes) return result.data.getModifiers.nodes || [];
      return []; 
    } catch (error) {
      return [];
    }
  }

  // get mst visit type list
  async getVisitTypesDetails(filter, fields = "uid label value serialNo") {
    try {
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetVisitTypes($search: String, $page: Int, $resultPerPage: Int, $sort: [SortOption!]) {
          getVisitTypes(search: $search, page: $page, resultsPerPage: $resultPerPage, sort: $sort){
            nodes { ${fields}  }
          }
        }`,
        filter
      ));
      if (!!result.data.getVisitTypes && !!result.data.getVisitTypes.nodes) return result.data.getVisitTypes.nodes || [];
      return []; 
    } catch (error) {
      return [];
    }
  }

  // get insurance member details
  async insuranceMemberDetail(uid, fields = "uid firstName lastName dob memberId insuranceId isPrimaryPolicyHolder") {
    try {
      let result = await this.api.qry(
        `query InsuranceMemberDetail($uid: ID!) {
          insuranceMemberDetail(uid: $uid){
            ${fields}
          }
        }`,
        {uid}
      );
      if (!!result.data.insuranceMemberDetail) return result.data.insuranceMemberDetail;
      return {}; 
    } catch (error) {
      return {};
    }
  }

  async getEligibilityString(uid, mode) {
    try {
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetEligibilityString($insUid: String!, $mode:String!) {
          getEligibilityString(insUid: $insUid, mode: $mode){
            success data message
          }
        }`,
        { insUid: uid, mode }
      ));
      if (!!result.data.getEligibilityString) return result.data.getEligibilityString;
    } catch (error) {
    }
    return null;
  }

  async updateEligibilityLog(uid, paymentMode, transRef) {
    try {
     await this.dm.queryDomain.middleware(this.api.mutate(
        `mutation UpdateEligibilityLog($uid: String!, $paymentMode: String!, $transRef: String){
            updateEligibilityLog(uid: $uid, paymentMode:$paymentMode, transRef: $transRef){              
              success
            }
          }`,
        { uid: uid, paymentMode: paymentMode, transRef: transRef }
      ));
    } catch (error) {
    }
  }
}
