import React from "react";
import R14, { StyleSheet, Surface, View, Touchable, Button, Image, Text } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
const xbpLogo = require("../../assets/images/NewXbpLogo150.png");
const back_icon = require("../../assets/images/back_icon.png");
const mainImage = require("../../assets/images/mainImage.png");
const verifiedDoneIcon = require("../../assets/images/verified_done.png");

export default R14.connect(
  class SignupConfirmationScreen extends React.Component {
    navPchLogin = () => {
      this.props.app.nav.to("pchLogin");
    };

    render() {
      const { pchRegistrationTypeProvider, pchRegistrationTypePatient } = this.props.app.dm.userSession.state;
      return (
        <>
          <View style={[styles.mainLogin, commonStyles.flex1, commonStyles.flexDirectionRow]}>
            <View style={[styles.bgContainer, styles.leftSection]}>
              <Image source={mainImage} style={[styles.backgroundImage]} />
            </View>
            <Surface style={[styles.rightSection, commonStyles.flex1, customCommonStyles.customRightSection]}>
              <View style={[customCommonStyles.customRightSectionInnerWrap, pchRegistrationTypeProvider || pchRegistrationTypePatient ? null : customCommonStyles.welcomeRightSection]}>
                <View style={[commonStyles.mView]}>
                  <View style={[styles.logoImageContainer, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                    {!!pchRegistrationTypeProvider || !!pchRegistrationTypePatient ? (
                      <Touchable onPress={this.navPchLogin}>
                        <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                      </Touchable>
                    ) : (
                      <Touchable onPress={this.navPchLogin}>
                        <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                      </Touchable>
                    )}
                    <View style={[commonStyles.margin0Auto]}>
                      <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
                    </View>
                  </View>
                </View>
                <View style={[styles.loginHeader, commonStyles.justifyContentCenter]}>
                  {!!pchRegistrationTypeProvider || !!pchRegistrationTypePatient ? (
                    <Touchable onPress={this.navPchLogin}>
                      <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                    </Touchable>
                  ) : (
                    <Touchable onPress={this.navPchLogin}>
                      <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                    </Touchable>
                  )}
                  <View style={commonStyles.margin0Auto}>
                    <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.justifyContentSpaceBetween, commonStyles.flexGrow0]}>
                  <View>
                    <View style={[commonStyles.marginTop16, commonStyles.marginBottom16, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
                      <Text style={[commonStyles.heading5, commonFontStyles.workSans, commonStyles.fontWeight500]}>Welcome to Claims!</Text>
                    </View>
                    <View
                      style={[
                        commonStyles.flex1,
                        commonStyles.justifyContentCenter,
                        commonStyles.marginBottom10,
                        commonStyles.alignItemsCenter,
                        commonStyles.marginBottom45,
                        styles.welcomeToPCH,
                      ]}>
                      <Image source={verifiedDoneIcon} style={[styles.verified_done]} />
                      <Text style={[commonStyles.marginBottom45, commonFontStyles.workSans, commonStyles.fullWidth, commonStyles.textAlignCenter, commonStyles.heading5]}>Congratulations!</Text>
                      <Text style={[commonFontStyles.workSans, commonStyles.fullWidth, commonStyles.textAlignCenter, commonStyles.smallHeading]}>You have successfully signed up with Claims.</Text>
                      {/* <Text style={[commonFontStyles.workSans, commonStyles.fullWidth, commonStyles.textAlignCenter, commonStyles.smallHeading]}>You will receive an email shortly with further instructions.</Text>
                       */}
                      <Text style={[commonFontStyles.workSans, commonStyles.fullWidth, commonStyles.textAlignCenter, commonStyles.smallHeading]}>You will receive a mail in your registered email with login credentials.</Text>
                    </View>
                  </View>

                  <View style={[commonStyles.fullWidth, styles.padding]}>
                    <Button
                      key="gotohome"
                      title="Continue"
                      style={[commonStyles.loginButton, customCommonStyles.noBoxShadow,]}
                      titleTextStyle={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.transformNone, styles.loginButtonTextStyle, commonStyles.whiteColor]}
                      to="pchLogin"
                    />
                  </View>
                </View>
              </View>
            </Surface>
          </View>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  loginHeader: {
    display: "flex",
    paddingTop: 8,
    paddingBottom: 12,
    textAlign: "center",
    alignItems: "center",
    position: "relaive",
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          display: "none",
        };
      }
      if (width === 1366) { }
      return {
        paddingTop: 0,
      };
    },
  },
  mainLogin: {
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "row",
    height: "100%"
  },
  leftSection: {
    flex: 2,
    display: "flex",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "none",
        };
      }
    },
  },
  rightSection: {
    flex: 1,
    width: 550,
    maxWidth: 550,
    // justifyContent: "center",
    position: "relative",
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 16,
    height: "100%",
    screen: ({ width, height }) => {
      if (width <= 1024) {
        return {
          // paddingTop: 20, 
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: 0,
          marginRight: "auto",
          marginBottom: 0,
          marginLeft: "auto",
          maxWidth: "100%",
          minWidth: "100%",
          boxShadow: "none",
          // height: "100vh",
          alignItems: "stretch",
          alignSelf: "stretch",
        };
      }
    },
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center left",
    screen: ({ width, height }) => {
      if (width === 1024 && height === 768)
        return {
          backgroundPosition: "-65px",
        };
    },
  },
  bgContainer: {
    width: "100%",
    display: "block",
    height: "100vh",
    screen: ({ width }) => {
      if (width <= 1024)
        return {
          display: "none",
          width: 0,
        };
    },
  },
  greenHelperText: {
    color: "green",
  },
  logoImageContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 16,
    marginLeft: "auto",
  },
  copyRight: {
    textAlign: "center",
    marginTop: "auto",
    marginBottom: 10,
    screen: ({ width }) => {
      if (width === 1366) {
        return {
          marginBottom: 0,
        }
      };
    },
  },

  padding: {
    padding: "0 30px"
  },
  welcomeToPCH: {
    marginTop: 100
  },
  createAccHolder: {
    screen: ({ width }) => {
      if (width <= 359) {
        return {
          marginRight: -16,
          marginLeft: -16,
        };
      }
    },
  },

  verified_done: {
    height: 36,
    width: 36,
    display: "block",
    backgroundSize: "contain",
  },
});
