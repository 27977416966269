import React from "react";
import R14, { View, TextInputField, StyleSheet, Text, Touchable, Image, Platform } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import FileUploadComponent from "../components/FileUploadComponent";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";

const attachments = require("../assets/images/icAttachment.png");
const send = require("../assets/images/send.png");
const send_active = require("../assets/images/send_active.png");
const delete_icon = require("../assets/images/delete_bank.png");

export default R14.connect(
    class ChatboxInputField extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                fileName: "",
                hasContent: false,
                onFocus: false,
            }
            this.fileToSave = null;
            this.child = React.createRef()
        }

        onChangeText = (val) => {
            const { onChangeText } = this.props;
            if (!!val && val.length > 0) {
                this.setState({ hasContent: true });
            }
            else
                this.setState({ hasContent: false });

            if (!!onChangeText) onChangeText(val);
            return false;
        };

        onFileInputChange = async (val, status) => {
            if (status === "done") {
                const { fileName } = this.state;
                let fizeSize = val.actualsize / 1024 / 1024;
                if (fizeSize > 2) {
                    await this.child.current.wrappedInstance.pullErrorText(CommonString.MaxTwoMBAllowed);
                    return false;
                }
                if (fileName === val.filename) {
                    await this.child.current.wrappedInstance.pullErrorText(CommonString.DuplicateFile);
                    return false;
                } 
                analyticsLogs(this.props.eventName || CommonString.MESSAGE_SECTION, `${CommonString.ATTACHMENT_ADDED}`, this.props.app);
                this.fileToSave = val;
                this.setState({ fileName: val.filename });
                const { onFileInputChange, onValueChange } = this.props;
                if (!!onFileInputChange) onFileInputChange(val, status);
                if (!!onValueChange) {
                    onValueChange({
                        filename: val.filename,
                        contentType: val.contentType,
                        content: val.content,
                        size: val.size,
                    });
                }
            }
        };


        deleteFile = () => {
            const { deleteFile, onValueChange } = this.props;
            analyticsLogs(this.props.eventName || CommonString.MESSAGE_SECTION, `${CommonString.ATTACHMENT_REMOVED}`, this.props.app);
            this.fileToSave = null;
            this.setState({ fileName: "" });
            if (!!deleteFile) deleteFile(0);
            else if (!!onValueChange) onValueChange(null, true);
            return false;
        }

        sendMessage = async () => {
            const { onSend } = this.props;
            let response = false;
            if (!!onSend) response = await onSend();
            if (!!response) {
                this.fileToSave = null;
                this.setState({ fileName: "", hasContent: false });
            }
            return false;
        }

        onFocus = () => {
            this.setState({ onFocus: true });
        }
        onBlur = () => {
            this.setState({ onFocus: false });
        }

        render() {
            const {
                name,
                label,
                required = true,
                multiple = true,
                validator,
                readOnly = false,
                disable,
                labelTextStyle,
                labelStyle,
                helperTextStyle,
                autoFocus = false,
                commentsProps,
                onSend,
                onFileInputChange,
                onValueChange,
                textBoxStyle,
                attachmentBoxStyle,
                sendBtnBoxStyle
            } = this.props;

            return <>
                {!!this.state.fileName ?
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd, !!onSend ?styles.paddingRight25 : null]}>
                        <View style={[customCommonStyles.textOverflowEllipsis, customCommonStyles.attachedFileName]}>
                            <Text style={[commonStyles.exSmallHeading, commonStyles.fontWeight400, commonFontStyles.workSans]}>{this.state.fileName}</Text>
                        </View>
                        <Touchable onPress={() => this.deleteFile('removed')}>
                            <View style={[styles.closeIcon]}>
                                <Image style={commonStyles.icon14} source={delete_icon} />
                            </View>
                        </Touchable>
                    </View>
                    : null
                }
                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, customCommonStyles.chatBoxAlignItemsFlexEnd ]}>
                  <View style={[commonStyles.fullFlexContainer, !!onFileInputChange || !!onValueChange || !!onSend  ? customCommonStyles.txtBoxWithOnlyAttachment : null, ( !!(!!onFileInputChange || !!onValueChange) && !!onSend ) ? customCommonStyles.txtBoxWithAttachmentAndSend : null, ]}>
                    <TextInputField
                        name={!!name ? name : "enterMessage"}
                        label={!!label ? label : `${CommonString.ChatInputLabel}*`}
                        onChangeText={(txt) => this.onChangeText(txt)}
                        disabled={disable || readOnly}
                        required={required}
                        autoFocus={autoFocus}
                        multiline={multiple}
                        onFocus={() => this.onFocus()}
                        onBlur={() => this.onBlur()}
                        style={[styles.chatFieldStyle, customCommonStyles.chatFieldStyle, commonFontStyles.workSans, commonStyles.smallHeading,
                        textBoxStyle,
                        !!multiple ? styles.commentsField : null]}
                        validator={!!validator ? validator : "cmtsVali"}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.smallHeading, labelTextStyle]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, labelStyle]}
                        helperTextStyle={[commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0, helperTextStyle]} 
                        {...commentsProps} />
                        </View>
                    {!!onFileInputChange || !!onValueChange ?
                    <View style={[styles.iconContainerStyle, customCommonStyles.attachmentContainerStyle, styles.colWidth30, attachmentBoxStyle, this.state.onFocus ? [styles.onFocusStyle, customCommonStyles.onFocusStyle] : null, this.state.onFocus && !!attachmentBoxStyle ? customCommonStyles.onSmallFocusStyle : null]}>
                        <FileUploadComponent
                            ref={this.child}
                            maxFiles={1}
                            source={"XBP"}
                            labelText={<View style={styles.attachIcon}><Image source={attachments} resizeMode="contain" style={commonStyles.icon14} /></View>}
                            fileTypes={"application/pdf,text/xml,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                            fileTypeLabel={"pdf,xml,excel,word"}
                            onValueChange={this.onFileInputChange}
                            showErrorInModal={true}
                            attachmentFieldOuterCustomHeight={[customCommonStyles.attachmentFieldOuter, styles.attachmentContainer, !!attachmentBoxStyle ? customCommonStyles.smallAttachmentTop : null ]}
                        />
                    </View> : null}
                    {!!onSend ?
                        <View style={[styles.colWidth30, customCommonStyles.sendBtnChatBoxStyle, sendBtnBoxStyle]}>
                            {this.state.hasContent ?
                                <Touchable onPress={() => this.sendMessage()} style={[commonStyles.icon20, commonStyles.marginBottom20]}>
                                    <View style={[styles.sendIconContainer]}>
                                        <Image source={send_active} style={[commonStyles.icon20]} />
                                    </View>
                                </Touchable> :
                                <View style={[commonStyles.icon20, commonStyles.marginBottom20]}>
                                    <View style={[styles.sendIconContainer]}>
                                        <Image source={send} style={[commonStyles.icon20]} />
                                    </View>
                                </View>} 
                        </View> : null}
                </View>
            </>
        }
    }
);

const styles = StyleSheet.create({
    closeIcon: {
        minHeight: 20,
        minWidth: 20,
        maxWidth: 20,
        flex: 1,
        flexGrow: 0,
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 5,
        marginTop: Platform.OS !== "web" ? -5 : 0,
    },
    chatFieldStyle: {
        ...commonFontStyles.workSans,
        ...commonStyles.heading6,
        ...customCommonStyles.inputHeight,
        paddingTop: 5,
        paddingBottom: 11,
        marginBottom: 0,
        lineHeight: 15,
        borderWidth: 0,
        width: "100%"
    },
    iconContainerStyle: {
        position: "relative",
        borderTopWidth:1,
        borderBottomWidth:1,
        borderRightWidth:1,
        borderStyle: "solid",
        borderColor: "#151b2666",
        height: 55.45,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        marginLeft: -3,
        backgroundColor: "#fff",
        marginBottom: 11,
        paddingTop: 5,
        screen: ({ width }) => {
            if (width <= 1280)
                return {
                    height: 56,
                };
        }
    }, 
    sendIconContainer: {
        width: "10%",
        minWidth: 20,
        height: 20,
        marginBottom: 20,
        marginLeft: 5,
    },
    commentsField: {
        resize: "none",
    }, 
    colWidth30:{
        width: 30
    },
    onFocusStyle: {
        borderColor: "#397ea0",
        borderStartColor: "#fff",
        borderTopWidth: 2,
        borderRightWidth: 2,
        borderBottomWidth: 2,
    },
    attachmentContainer: {
        top: 0,
        position: "relative",
        minHeight: "100%",
        screen: ({ width }) => {
            if (width <= 991 && Platform.OS !== "web") {
              return {
                top: Platform.OS !== "android" ? 10 : 22,
              }
            }
          }
    },
    paddingRight25:{
        paddingRight: 25
    },
    attachIcon:{
        bottom: 10,
        position: "absolute",
    }
});
