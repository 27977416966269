import React, { Component } from "react";
import R14, { View, StyleSheet, Text } from "../core";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import PopupModalComponent from "./PopupModalComponent";
import { MESSAGE_TYPE } from "../constant/app.data.constant";

export default R14.connect(
  class FileUploadComponent extends Component {
    constructor(props) {
      super(props);
      this.child = React.createRef();
      this.state = {
        isAlertVisible: false,
        errorMessage: "",
        showErrorInModal: props.showErrorInModal
      }
    }

    getExtension(fname) {
      const { fileTypes, module, fileTypeLabel, source } = this.props;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      if (!!!source && !!fileTypes && fileTypes.includes(fname.toLowerCase())) {
        switch (fname.toUpperCase()) {
          case "PDF":
            return "PDF";
          case "JPEG":
            return "JPEG";
          case "JPG":
            return "JPG";
          case "PNG":
            return "PNG";
          case "TIFF":
            return "TIFF";
          case "TIF":
            return "TIFF";
          case "CSV":
          case "DIF":
          case "ODS":
          case "OTS":
          case "TSV":
          case "XLM":
          case "XLS":
          case "XLSB":
          case "XLSM":
          case "XLSX":
          case "XLT":
          case "XLTM":
          case "XLTX":
            return "XLS";
          case "XML":
            return "XML";
          case "DOC":
          case "DOCX":
          case "DOCM":
          case "DOTX":
          case "DOTM":
          case "DOCB":
            return "WORD";
          case "XSLT":
            return "XSLT";
          default:
            {
            let error = `Please upload ${!!module ? module : "bills"} in ${fileTypeLabel
              .split(",")
              // .map((item) => {
              //   return item.split("/")[1];
              // })
              .join(",")
              .toUpperCase()} format.`;
            if(!!!isMobileDevice){
              this.setState({
                errorMessage: error
              });
            } else {
              this.setState({
                isAlertVisible: true,
                errorMessage: error
              })
            }
          }
            return false;
        }
      } else {
        switch (fname.toUpperCase()) {
          case "CSV":
          case "DIF":
          case "ODS":
          case "OTS":
          case "TSV":
          case "XLM":
          case "XLS":
          case "XLSB":
          case "XLSM":
          case "XLSX":
          case "XLT":
          case "XLTM":
          case "XLTX":
            return "SDSH";
          case "XML":
            return "DXML";
          case "PDF":
            return "DPDF";
          case "DOC":
          case "DOCX":
          case "DOCM":
          case "DOTX":
          case "DOTM":
          case "DOCB":
            return "WORD";
          case "XSLT":
            return "XSLT";
          default:
            {
              let error = `Please upload ${!!module ? module : "bills"} in ${fileTypeLabel
                .split(",")
                // .map((item) => {
                //   return item.split("/")[1];
                // })
                .join(",")
                .toUpperCase()} format.`;
              if(!!!isMobileDevice){
                this.setState({
                  errorMessage: error
                });
              } else {
                this.setState({
                  isAlertVisible: true,
                  errorMessage: error
                })
              }
            }
            return false;
        }
      }
    }

    convertBytes(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0;
      let n = parseInt(x, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      //include a decimal point and a tenths-place digit if presenting
      //less than ten of KB or greater units
      // return n.toFixed(n < 10 && l > 0 ? 2 : 0) + " " + units[l];
      return n.toFixed(2) + " " + units[l];
    }

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let fileNameXt = this.getExtension(file.name.split(".").pop());
        const size = this.convertBytes(file.size);
        reader.onload = ((file1) => {
          let data = reader.result.split(",");
          if(fileNameXt === 'png' || fileNameXt === 'jpg' || fileNameXt === 'jpeg' || fileNameXt === 'PNG' || fileNameXt === 'JPG' || fileNameXt === 'JPEG'){
            var image = new Image();
            image.src = file1.target.result;
            image.onload = function(file2) { 
              if (!!fileNameXt) {
                resolve({
                  filename: file.name,
                  contentType: fileNameXt,
                  content: data[1],
                  size,
                  actualsize: file.size,
                  contentUri: data[0],
                  height: file2.target.height,
                  width: file2.target.width,
                });
              } else {
                return false;
              }
            }; 
          } else {
            if (!!fileNameXt) {
              resolve({
                filename: file.name,
                contentType: fileNameXt,
                content: data[1],
                size,
                actualsize: file.size,
                contentUri: data[0],
                // size: `${Math.round(file.size / 1024).toFixed(2)} ${["Bytes", "Kb", "Mb", "Gb", "Tb"][Math.floor(Math.log2(file.size) / 10)]}`,
              });
            } else {
              return false;
            }
          }
        });
        reader.onerror = (err) => {
          reject(err);
        };
        reader.readAsDataURL(file);
      });
    }

    fileInputChange = async ({ meta, file }, status) => {
      const { onValueChange, fileTypeLabel, maxFiles, module } = this.props;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      if (status === "done") {
        let fileName = file.name.split('.').slice(0, -1).join('.');
        if (fileName.length > 35) {
          let error = `[${fileName.length > 35 ? fileName.substr(0, 35) + "..." : fileName}] Maximum allowed filename is 35 characters.`;
          if(!!!isMobileDevice){
            this.setState({
              errorMessage: error
            });
          } else {
            this.setState({
              isAlertVisible: true,
              errorMessage: error
            })
          }
          this.handleSubmit();
          return false;
        }
        let fileBase64Data = await this.getBase64(file);
        onValueChange(fileBase64Data, status);
        this.handleSubmit();
        return false;
      }
      // else if (status === "removed") {
      //   let fileBase64Data = await this.getBase64(file);
      //   onValueChange(fileBase64Data, status);
      //   return false;
      // }
      else if (status === "rejected_max_files") {
        let error = `Maximum ${maxFiles} ${!!module ? module : "bills"} can be uploaded.`;
        if(!!!isMobileDevice){
          this.setState({
            errorMessage: error
          });
        } else {
          this.setState({
            isAlertVisible: true,
            errorMessage: error
          })
        }
        return false;
      } else if (status === "rejected_file_type") {
        let error = !!fileTypeLabel
        ? `Please upload ${!!module ? module : "bills"} in ${fileTypeLabel
          .split(",")
          // .map((item) => {
          //   return item.split("/")[1];
          // })
          .join(",")
          .toUpperCase()} format.`
        : "Invaild file format.";
      if(!!!isMobileDevice){
        this.setState({
          errorMessage: error
        });
      } else {
        this.setState({
          isAlertVisible: true,
          errorMessage: error
        })
      }
        return false;
      }
    };

    handleSubmit = () => {
      this.child.files.filter((f) => ["headers_received", "done"].includes(f.remove()));
    };

    pullErrorText = (error) =>{
        this.setState({
          errorMessage: error
        });
    }
    alertOk = async (msg) => {
      this.setState({
        isAlertVisible: false,
        errorMessage: ''
      })
    };

    renderAlertPopup() {
      const { isAlertVisible, errorMessage, showErrorInModal } = this.state;
      return (
        <PopupModalComponent
          visibility={(!!isAlertVisible || !!showErrorInModal) && !!errorMessage}
          bodyWrapper={[commonStyles.smallModalBodyWrapper]}
          messageType={MESSAGE_TYPE.info}
          headingIcon={true}
          heading={"Info"}
          modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
          bodyContent={errorMessage}
          okLabel={"Ok"}
          handleOk={() => this.alertOk(errorMessage)}
          closeLabel={""}
          handleClose={null}
        />
      );
    }

    render() {
      const { maxFiles, fileTypes, labelText, attachmentFieldOuterCustomHeight, errorMsgHolder, showErrorInModal, dropStyle } = this.props;
      const { errorMessage } = this.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <>
          {this.renderAlertPopup()}
          <View style={[styles.attachmentFieldOuter, attachmentFieldOuterCustomHeight]}>
            <Dropzone
              ref={(instance) => {
                this.child = instance;
              }}
              onChangeStatus={this.fileInputChange}
              accept={!!fileTypes ? fileTypes : "application/pdf"}
              inputContent={!!labelText ? labelText : ""}
              inputWithFilesContent={!!labelText ? labelText : ""}              
              canCancel={false}
              maxFiles={!!maxFiles ? maxFiles : 1}
              multiple={maxFiles > 1 ? true : false}
              styles={dropStyle}
            />
          </View>
          {!!errorMessage && !!!isMobileDevice && !!!showErrorInModal ?
            <View style={[commonStyles.textAlignLeft, errorMsgHolder]}>
              <Text style={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans, styles.errorText]}>
                {errorMessage}
              </Text>
            </View>
            : null}
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  attachmentFieldOuter: {
    width: "100%",
    padding: 0,
    // borderRadius: "100%",
    // backgroundColor: "#cff2ff",
    overFlow: "hidden",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
    flex: 2,
    flexGrow: 2,
    flexBasis: "100%",
    minWidth: 100,
  },
  errorText: {
    color: "rgb(176, 0, 32)"
  },
  bodyWrapper: {
    width: 300,
    borderRadius: 4,
  },
});
