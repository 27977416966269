import React from "react";
import R14, { StyleSheet, View, Text, Button, Checkbox, Form } from "../../core";
import DropDown from '../DropDown';
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import ModalPopUp from "../ModalPopUp";
import { analyticsLogs } from "../../utils/AnalyticLogs";
import { CommonString } from "../../constant/appCommonText";
import PCHSignupComponent from "./PCHSignupComponent";

export default R14.connect(
  class UploadBillConfirmationComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedLabels: [],
        labelError: false,
        isValid: false,
      };
      this.listOfLabels = ['All receipts attached', "Super Bill/Generic Bill attached", "Insurance card details, image attached", "Provider NPI Details"]
      this.clients = this.props.clients;
    }

    onClientChange = (val) => {
      const { label } = val;
      const form = this.props.app.ui.form("claimConfirmation")
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        form.elmts.ddlClient.setValue(null);
        return false;
      }
      form.elmts.ddlClient.setValue(val);
      form.elmts.ddlClient.setError(null);
    };

    onSearchClient = async (search) => {
      try {
        this.clients = await this.props.onSearchClient(search);
        this.form.elmts.ddlClient.setItems(this.clients);
      }
      catch (error) {

      }
      return this.clients;
    }

    handleSubmit = async () => {
      try {

        let hasError = false;
        let form = this.props.app.ui.form("claimConfirmation");
        const { ddlClient } = form.values;
        if (!!ddlClient && !!ddlClient.value)
          form.elmts.ddlClient.setError();
        else {
          form.elmts.ddlClient.setError("Select Client");
          hasError = true;
        }
        if (hasError) return false;

        if (!!this.state.labelError) {
          this.props.handleConfirmationPopUp(this.state.labelError, ddlClient.value);
          this.setState({
            labelError: false, selectedLabels: []
          });
          return false;
        }
        const isAllSelected = this.listOfLabels.every(label => this.state.selectedLabels.includes(label));
        if (!!!isAllSelected) {
          hasError = true;
          this.setState({
            labelError: true
          })
        }
        if (hasError) return false;
        this.setState({
          selectedLabels: [],
          labelError: false
        });
        // routed backed and set confirmationPopUpVisibility false
        analyticsLogs(CommonString.UPLOAD_BILL, CommonString.PAYMENT_PROCESSED_ACTION, this.props.app);
        this.props.handleConfirmationPopUp(false, ddlClient.value);
        return false;
      } catch (error) {
        return false;
      }
    };

    onCancel = (value) => {
      this.setState({ selectedLabels: [] });
      this.props.handleConfirmationPopUp(value, "");
      return false;
    }

    toggleCheckBox = async (item) => {
      const { selectedLabels } = this.state;
      const isSelected = selectedLabels.includes(item);
      if (isSelected) {
        const removedSelectedItem = selectedLabels.filter(label => label !== item);
        this.setState({
          selectedLabels: removedSelectedItem
        });
      } else {
        this.setState({
          selectedLabels: [...selectedLabels, item]
        })
      }
    };

    render() {
      const { confirmationPopUpVisibility, isUberDocs, nonRegisterUser } = this.props;
      const { selectedLabels, labelError, isValid } = this.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <ModalPopUp
          visibility={!!confirmationPopUpVisibility}
          isFooter={false}
          bodyWrapper={[styles.smallModalBodyWrapper, clientStyle.secondaryBgColor, !!nonRegisterUser ? commonStyles.padding0 : null]}
          modalContent={[commonStyles.marB0]}>
          <View style={[commonStyles.addInsuranceAlertText, !labelError && commonStyles.paddingBottom20]}>
            <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400, !labelError && commonStyles.heading5, !labelError && commonStyles.fontWeight500]}>{!!labelError ? "Claim submission to Insurer can proceed only on completion of all details. Please ensure completion" : (!!isValid && !!selectedLabels && selectedLabels.length === this.listOfLabels.length) ? "Thank you for your confirmation. You claim will now get routed to your Insurer." : !!nonRegisterUser ? "Member Information" : "Claim Confirmation"}</Text>
          </View>
          {(!!nonRegisterUser) ?
            <PCHSignupComponent onNonRegistrationCancel={() => this.props.onNonRegistrationCancel("cancelled")} onNonRegistrationSubmit={(payload) => this.props.onNonRegistrationSubmit(payload)} />
            :
            <Form name="claimConfirmation" style={[commonStyles.flex1, commonStyles.flexDirectionColumn, commonStyles.padding0]}>
              <View style={[commonStyles.flexBasis50]}>
                <DropDown
                  name="ddlClient"
                  label="Select Client*"
                  onValueChange={this.onClientChange}
                  searchable={true}
                  itemLoader={async ({ search }) => {
                    return await this.onSearchClient(search);
                  }}
                  items={this.clients}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  style={[commonStyles.marginBottom20, customCommonStyles.inputHeight, !!labelError ? commonStyles.displayNone : null]}
                />
              </View>

              {!labelError && this.listOfLabels.map((item, ind) => {
                return (<View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                  <Checkbox
                    name={item + ind}
                    label={item}
                    value={selectedLabels.includes(item) ? true : false}
                    onPress={() => this.toggleCheckBox(item)}
                    colorBlank="#585858"
                    colorMarked="#4dc5f2"
                    disabled={false}
                  />
                  <View style={[styles.labelAlertText]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}>{item}</Text>
                  </View>
                </View>)
              })}
              <View style={[commonStyles.smallModalBtnWrapper, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentCenter]}>
                <Button
                  title={"Cancel"}
                  style={[commonStyles.blueBorderButton, commonStyles.secondayWhiteBtn, styles.submitBtn, !!labelError ? commonStyles.displayNone : null]}
                  titleTextStyle={[commonStyles.secondayWhiteBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                  onPress={() => this.onCancel(true)}
                  key="handleCancel"
                />
                <Button
                  title={"Ok"}
                  style={[commonStyles.blueButton, commonStyles.marginRight8, commonStyles.marginLeft10, customCommonStyles.noBoxShadow, clientStyle.submitButton, styles.submitBtn]}
                  onPress={() => this.handleSubmit()}
                  key="handleSubmit"
                  titleTextStyle={[
                    commonFontStyles.workSans,
                    commonStyles.fontWeight500,
                    commonStyles.exSmallHeading,
                    commonStyles.transperentBtnColor,
                    commonStyles.whiteColor,
                    clientStyle.submitButtonText
                  ]}
                />
              </View>
            </Form>}
        </ModalPopUp>
      )
    }
  }
);

const styles = StyleSheet.create({
  labelAlertText: {
    marginTop: "auto",
    marginBottom: "auto",
    textAlign: "left",
  },
  smallModalBodyWrapper: {
    width: 320,
    screen: ({ width }) => {
      if (width >= 320 && width <= 359) {
        return {
          width: "100%",
          maxWidth: 300,
        };
      }
      if (width >= 360 && width <= 991) {
        return {
          width: "90%",
          maxWidth: 400,
          minWidth: 320,
        };
      }
    },
  },
  submitBtn: {
    minWidth: 108,
    maxWidth: 108,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: 99,
          maxWidth: 99,
        };
      }
    }
  },
})