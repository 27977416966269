import React from "react";
import R14, { StyleSheet, Form, TextInputField, SubmitButton, Text, View, Touchable, SelectMenuField, Button } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";

export default R14.connect(
  class BillRequestScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        toggleClass: false,
        searchedBiller: [],
        activeBillers: {},
        buttonType: "Search",
        isButtonDisabled: false,
        isShowGrid: false,
        selectedBiller: {}
      };
      this.industries = this.props.industries;
      this.form = null;
    }
    toogleStatus = (el) => {
      analyticsLogs(CommonString.FIND_AND_LINK_BILLER, CommonString.Biller_Selected_From_List, this.props.app)
      this.setState({ toggleClass: !this.state.toggleClass, buttonType: "Link", selectedBiller: el });
      this.form.elmts.billerName.setValue(el.billerName);
    };

    handleSubmit = async (form) => {
      //billrequestForm
      const { accountNo, billerName, industry } = form.values;
      if (this.state.buttonType === "Search") {
        this.setState({ searchedBiller: [], isShowGrid: false });
        if (!!!billerName) {
          form.elmts.billerName.setError("Enter Biller Name");
          return false;
        }

        if (!!billerName && billerName.length < 3) {
          form.elmts.billerName.setError("Enter at least 3 characters");
          return false;
        }
        this.setState({
          isButtonDisabled: true
        })
        if (!!!this.state.activeBillers[industry.label] || this.state.activeBillers[industry.label].length === 0) {
          let activeBiller = await this.props.app.dm.billRequest.getActiveBillersWithAPI([], false, industry.label);
          if (!!activeBiller && activeBiller.length > 0) {
            let obj = {}
            obj[industry.label] = activeBiller;

            const { activeBillers } = this.state;

            this.setState({
              activeBillers: { ...activeBillers, ...obj },
              isShowGrid: true,
            })
          }
        }
        if (!!this.state.activeBillers[industry.label]) {
          let res = await this.searchFromBillerList(this.state.activeBillers[industry.label], industry.label, billerName);
          if (res.length > 0) {
            this.setState({ searchedBiller: res });
          }
        }
        analyticsLogs(CommonString.FIND_AND_LINK_BILLER, `${CommonString.SEARCHED_LINK_BILLER}`, this.props.app);
        this.setState({
          isButtonDisabled: false,
          isShowGrid: true
        })
        return false;
      }
      else {
        if (!!!accountNo) {
          form.elmts.accountNo.setError("Please enter account no.");
          return false;
        }
        const { selectedBiller } = this.state;
        let payerPID = this.props.userLoginDetail.payerId;
        let inputPram = {
          payerPID: payerPID,
          billerPID: selectedBiller.billerId,
          accountNumber: accountNo,
          businessUnitId: selectedBiller.buId
        }
        this.setState({
          isButtonDisabled: true
        });
        let res = await this.props.app.dm.billRequest.linkBilltoBiller(inputPram);
        if (!!res) {
          let msg = "";
          let linkMessage = JSON.parse(res.message);
          if (!!linkMessage && !!linkMessage.msg)
            msg = linkMessage.msg;

          if (!!res.success) {
            analyticsLogs(CommonString.FIND_AND_LINK_BILLER, `${CommonString.LINK_BILLER}`, this.props.app);
            form.elmts.accountNo.setValue("");
            this.props.app.dm.commonDomain.showToastr("success", !!msg ? msg : CommonString.LinkedBiller);
          }
          else {
            this.props.app.dm.commonDomain.showToastr("error", !!msg && msg.indexOf('payerPID is invalid') === -1 ? msg : CommonString.AccountBillerMisMatch);
          }

          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        }
        this.setState({
          isButtonDisabled: false
        })
        return false;
      }
    }

    async searchFromBillerList(activeBiller, industryName, billerName) {
      if (!!activeBiller) {
        // let filteredList = activeBiller.filter(x => x.industry === industryName);

        let filteredList = activeBiller.filter((item) => {
          return (!!billerName ? item.billerName.toUpperCase().includes(billerName.toUpperCase()) : true)
        });
        filteredList = !!filteredList ? (filteredList.sort((a, b) => (a.billerName > b.billerName) ? 1 : ((b.billerName > a.billerName) ? -1 : 0))) : filteredList;
        return filteredList;
      }
      return null;
    }

    resetForm = () => {
      analyticsLogs(CommonString.FIND_AND_LINK_BILLER, `${CommonString.Reset_Info}`, this.props.app);
      this.form.elmts.billerName.value = "";
      this.form.elmts.accountNo.value = "";
      this.form.elmts.industry.setValue(null);
      this.setState({
        buttonType: "Search",
        searchedBiller: [],
        isShowGrid: false
      })
    }

    changeText = () => {
      this.setState({
        buttonType: "Search"
      })
    }

    componentDidMount() {
      // this.industries = this.props.industries;
      try {
        this.form = this.props.app.ui.form("billrequestForm");
        this.form.elmts.industry.setItems(this.industries)
      } catch (error) {

      }
    }

    onChangeIndustry = async (val) => {
      const { label } = val;
      this.form.elmts.billerName.value = "";
      this.setState({
        buttonType: "Search",
        searchedBiller: [],
        isShowGrid: false
      })
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.industry.setValue(null);
        return false;
      }
    }

    render() {
      const { buttonType, isButtonDisabled, searchedBiller, isShowGrid } = this.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <View style={[styles.billRequestInnerScreen,]}>
          <Form name="billrequestForm"
            style={[styles.billrequestForm]}
            onSubmit={this.handleSubmit}
            //validators={CommonValidators.getFormValidator()}
            validateBeforeSubmit={true}>
            <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection, commonStyles.justifyContentSpaceBetween]}>
              <View
                style={
                  [commonStyles.flexBasis49, styles.inputFields]
                }>
                <SelectMenuField
                  name="industry"
                  label={CommonString.IndustryLabel + "*"}
                  required={CommonString.SelectIndustryRequired}
                  items={this.industries}
                  onValueChange={this.onChangeIndustry}
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.marginBottom20]}
                  searchable
                  itemLoader={async ({ search }) => {
                    let items = this.industries;
                    return items
                      .filter((item) => {
                        return item.label.toLowerCase().includes(search.toLowerCase());
                      })
                      .sort((a, b) => b.label - a.label);
                  }}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle,]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                />
              </View>
              <View style={[commonStyles.flexBasis49, styles.inputFields, buttonType === "Link" && commonStyles.disabledInput]}>
                <TextInputField
                  name="billerName"
                  label={CommonString.BillerNameLabel + "*"}
                  readOnly={buttonType === "Link" ? true : false}
                  style={[styles.inputField, customCommonStyles.inputHeight, commonStyles.heading6, commonStyles.minHeight0]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  onChangeText={this.changeText}
                />
              </View>
              {buttonType === "Link" ? (
                <View style={[commonStyles.flexBasis49, styles.inputFields]}>
                  <TextInputField
                    name="accountNo"
                    label={CommonString.EnterAccountNoLabel + "*"}
                    style={[styles.inputField, customCommonStyles.inputHeight, commonStyles.heading6]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  />
                </View>
              ) : null}
            </View>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, buttonType === "Link" ? styles.customJustifyContent : commonStyles.justifyContentFlexEnd]}>
              {buttonType === "Link" ? (
                <Button
                  title={"Reset"}
                  key="reset"
                  titleColor="#000"
                  style={[commonStyles.blueBorderButton, styles.submitBtn, commonStyles.marginRight5]}
                  titleTextStyle={[
                    commonStyles.blueBorderButtonColor,
                    commonStyles.fontWeight500,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                  ]}
                  onPress={this.resetForm}
                />) : null}
              <SubmitButton
                title={!!isButtonDisabled ? "Processing" : buttonType === "Search" ? "Search Biller" : "Link Biller"}
                key="submit"
                titleColor="#000"
                disabled={isButtonDisabled}
                style={[commonStyles.blueButton, customCommonStyles.noBoxShadow, styles.submitBtn]}
                titleTextStyle={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.whiteColor, styles.submitBtnText]}
              />
            </View>

            {
              searchedBiller.length <= 0 && !!isShowGrid ?
                (<View style={[styles.noRecordsStyle]}><Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.whiteColor]}>No records found.</Text></View>)
                : null
            }
            {searchedBiller.length <= 0 || buttonType === "Link" ? null :
              isMobileDevice && !(window.innerWidth === 1024) ? null : (
                <View style={[styles.tHead, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                  <View style={[styles.th, styles.billerNameSection]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.tableKey]}>
                      Biller (Business unit)
                    </Text>
                  </View>
                  <View style={[styles.th, styles.billerIDSection, styles.billerIDSectionTh]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.tableKey]}>
                      Biller PID (Biller Ref)
                    </Text>
                  </View>
                </View>
              )}
            {buttonType === "Link" ? null : (
              searchedBiller.length <= 0 ? null :
                <View style={[styles.searchResult, customCommonStyles.customSearchResultWithAccountInput,]}>

                  {
                    searchedBiller.map((el, index) => {
                      return (
                        <View key={"link_" + index} style={[commonStyles.flex1, styles.resultRow, commonStyles.customFlexDirection, commonStyles.tableValue]}>
                          <View style={[commonStyles.marginLeft16, styles.billerNameSection]}>
                            <Touchable onPress={() => this.toogleStatus(el)} >
                              <View>
                                <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.darkGrayColor]}>
                                  {/* Biller Name (BuName || BuId) */}
                                  {el.billerName} ({!!el.buName ? el.buName : el.buId})
                                </Text>
                              </View>
                            </Touchable>
                          </View>

                          <View style={[commonStyles.marginLeft10, styles.billerIDSection]}>
                            <Touchable onPress={() => this.toogleStatus(el)} >
                              <View>
                                <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.darkGrayColor]}>
                                  {/* Biller Pid (Biller Ref) */}
                                  {el.billerId} ({el.billerRef})
                                </Text>
                              </View>
                            </Touchable>
                          </View>
                        </View>
                      )
                    })
                  }
                </View>
            )}
            <View style={[commonStyles.marTAuto]}>
            </View>
          </Form>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  // billRequestScreen: {
  //   marginRight: 16,
  //   marginLeft: 16,
  // },
  billRequestInnerScreen: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginLeft: 16,
    marginBottom: 15,
    flexGrow: 1,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          marginLeft: 0,
        };
      }
    },
  },
  inputField: {
    flexGrow: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexGrow: 1,
          width: "100%",
        };
      }
    },
  },
  resultRow: {
    // backgroundColor: "#FAFAFA",
    // minHeight: 72,
    paddingTop: 3,
    paddingRight: 16,
    paddingBottom: 3,
    paddingLeft: 16,
    // marginTop: 8,
    alignItems: "center",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    height: 25,
    maxHeight: 25,
    minHeight: 25,
    // borderTopWidth: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexWrap: "wrap",
          flex: 1,
          alignItems: "flex-start",
        };
      }
    },
  },
  inputFields: {
    screen: ({ width }) => {
      // if (width >= 991 && width <= 1083) {
      //   return {
      //     flexBasis: "48%",
      //   };
      // }
      if (width <= 991) {
        return {
          flexBasis: "100%",
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          flexGrow: 1,
        };
      }
    },
  },
  noRecordsStyle: {
    marginTop: 20,
    padding: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: "rgb(1, 86, 173)",
  },
  providerListComponent: {
    margin: "210px 16px 50px 16px",
    background: "#fff",
    paddingBottom: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          margin: 0, //"265px 16px 50px 16px",
        };
    },
  },
  searchResult: {
    // marginTop: 30,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTopWidth: 0,
    borderWidth: 1,
    overflow: "scroll",
    position: "relative",
    // overflowY: "hidden",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderTopWidth: 1,
          marginTop: 16,
        };
    },
  },
  noSearchResult: {
    borderWidth: 0,
    height: "calc(100vh - 400px)",
    screen: ({ height }) => {
      if (height <= 569) {
        return {
          height: "calc(100vh - 500px)",
        };
      }
    },
  },
  billerIcon: {
    width: 40,
  },
  billerIDSection: {
    // maxWidth: "30%",
    // flexBasis: "30%",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          maxWidth: "30%",
          flexBasis: "30%",
        };
      }
      if (width <= 991) {
        return {
          // maxWidth: "80%",
          // flexBasis: "80%",
          marginLeft: 15,
          marginRight: 15,
        };
      }
    },
  },
  billerNameSection: {
    wordBreak: "break-word",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          marginLeft: 16,
          maxWidth: "50%",
          flexBasis: "50%",
        };
      }
      if (width <= 991) {
        return {
          // maxWidth: "100%",
          // flexBasis: "100%",
          marginRight: 16,
        };
      }
    },
  },
  billRequestScreenWrap: {
    marginTop: 16,
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 16,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  },
  pageTitle: {
    color: "#151b26",
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  emptyField: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "none",
        };
      }
    },
  },
  billrequestForm: {
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#e2e2e2",
  },
  tHead: {
    backgroundColor: "#dadada",
    marginTop: 16,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  th: {
    paddingTop: 4.5,
    paddingRight: 16,
    paddingBottom: 4.5,
    paddingLeft: 16,
  },
  billerIDSectionTh: {
    paddingLeft: 8,
  },
  submitBtn: {
    minWidth: 150,
    paddingLeft: 0,
    paddingRight: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  submitBtnText: {
    minWidth: 150,
  },
  customJustifyContent: {
    justifyContent: "flex-end",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          justifyContent: "space-between"
        };
      }
    },
  }
});
