import React from "react";
import R14, {
    View,
    Text,
    StyleSheet,
    Touchable
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import CommonUtility from "../../utils/CommonUtility";

export default R14.connect(
    class ManagePaymentDetailsComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        render() {
            let commonFontStyles = fontFamilyStyles;
            const {
                icn = "",
                providerGroup = "",
                provider = "",
                dateOfService = "",
                totalClaimAmount = "",
                paid = "",
                patientResponsibility = "",
                balance = "",
                viewClaim = null,
                viewPayments = null,
            } = this.props;
            return (
                <>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.customDetailStyle]}>
                        <View style={[commonStyles.flex1, styles.customTextStyle]}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans]} >ICN: {icn}</Text>
                        </View>
                        <View style={[commonStyles.flex1, styles.customTextStyle, !!providerGroup ? null : commonStyles.displayNone]}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans]} >Provider Group: {providerGroup}</Text>
                        </View>
                        <View style={[commonStyles.flex1, styles.customTextStyle, !!provider ? null : commonStyles.displayNone]}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans]} >Provider: {provider}</Text>
                        </View>
                    </View>

                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.customDetailStyle]}>
                        <View style={[commonStyles.flex1, styles.customTextStyle, !!dateOfService ? null : commonStyles.displayNone]}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans]} >Date of Service: {dateOfService}</Text>
                        </View>
                        <View style={[commonStyles.flex1, styles.customTextStyle, !!totalClaimAmount ? null : commonStyles.displayNone]}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans]} >Total Claim Amount: {CommonUtility.formatAmount(totalClaimAmount)}</Text>
                        </View>
                        <View style={[commonStyles.flex1, styles.customTextStyle, !!paid ? null : commonStyles.displayNone]}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans]} >Paid: {CommonUtility.formatAmount(paid)}</Text>
                        </View>
                        <View style={[commonStyles.flex1, styles.customTextStyle, !!patientResponsibility ? null : commonStyles.displayNone]}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans]} >Patient Responsibility: {CommonUtility.formatAmount(patientResponsibility)}</Text>
                        </View>
                        <View style={[commonStyles.flex1, styles.customTextStyle, !!balance ? null : commonStyles.displayNone]}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans]} >Balance: {CommonUtility.formatAmount(balance)}</Text>
                        </View>
                        <Touchable style={[commonStyles.flex1, styles.customTextStyle, !!viewClaim ? null : commonStyles.displayNone]} onPress={() => viewClaim('view claim')}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans, styles.blueColor]} >View Claim</Text>
                        </Touchable>
                        <Touchable style={[commonStyles.flex1, styles.customTextStyle, !!viewPayments ? null : commonStyles.displayNone]} onPress={() => viewPayments('view payments')}>
                            <Text style={[commonStyles.heading6, commonFontStyles.workSans, styles.blueColor]} >View Payments</Text>
                        </Touchable>
                    </View>
                    
                </>
            );
        }
    }
);

const styles = StyleSheet.create({
    customDetailStyle: {
        padding: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    paddingTop: 0,
                    paddingBottom: 0,
                    flexDirection: "column"
                };
            }
        },

    },
    customTextStyle: {
        marginRight: 30,
        flexGrow: 0,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    paddingBottom: 8,
                };
            }
        },
    },
    blueColor: {
        color: "#d71996"
    }
});
