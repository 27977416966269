import React from "react";
import R14, { View, Text, StyleSheet, Touchable, Platform, ControlsView } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
import { PAGE_PATH, MSG_TYPE } from "../constant/app.data.constant";
import { customCommonStyles } from "../assets/css/customCommonStyle";
export default R14.connect(
  class BottomPaySection extends React.Component {
    async paymentNavigation(path, to) {
      this.props.app.nav.to(path, { paytype: to });
    }

    render() {
      const { isDisabled, activePage, extraOpt, billerDetails, billDetails } = this.props;
      let isPayDisable = [MSG_TYPE.PAY_ALL, MSG_TYPE.PAY_PART].includes(extraOpt) || billDetails.billStatus === "In Process";
      // let isPayDisable = false; // [MSG_TYPE.PAY_ALL].includes(extraOpt);
      return (
        <ControlsView
          type="buttons"
          style={[styles.bottomControlsView]}
          children={[
            <View key="controlViewBottom" style={[styles.bottomPaySection, commonStyles.flex1, commonStyles.justifyContentSpaceBetween, commonStyles.flexDirectionRow]}>
              {!!billerDetails && !!billerDetails.isPaymentDecline ?
                <View
                  style={[
                    customCommonStyles.payButtons,
                    commonStyles.flex1,
                    styles.bottomPayButtons,
                  ]}
                >
                  <Touchable
                    onPress={() => (!(isDisabled || isPayDisable) ? this.paymentNavigation("declinedRoute", PAGE_PATH.DECLINED) : null)}>
                    <View style={[
                      commonStyles.flex1,
                      commonStyles.justifyContentCenter,
                      commonStyles.alignItemsCenter,
                      commonStyles.textAlignCenter,
                      styles.declineButton,
                      !(isDisabled || isPayDisable) ? (activePage === PAGE_PATH.DECLINED ? styles.activeBtn : null) : styles.disable,
                    ]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonMetricsStyle.exSmallHeading,
                          commonStyles.fontWeight500,
                          commonStyles.transperentBtnColor,
                          commonStyles.transformUpperCase,
                          activePage === PAGE_PATH.DECLINED ? styles.active : null,
                        ]}>
                        Decline
                      </Text>
                    </View>
                  </Touchable>
                </View>
                : null}


              {!!billerDetails && !!billerDetails.isPaymentDueDateExtensions ?
                <View
                  style={[
                    customCommonStyles.payButtons,
                    commonStyles.flex1,
                    styles.bottomPayButtons,
                  ]}
                >
                  <Touchable
                    onPress={() => (!(isDisabled || isPayDisable) ? this.paymentNavigation("extensionRoute", PAGE_PATH.EXTENSION) : null)}>
                    <View style={[
                      commonStyles.flex1,
                      commonStyles.justifyContentCenter,
                      commonStyles.alignItemsCenter,
                      commonStyles.textAlignCenter,
                      styles.blueButton,
                      commonStyles.secondaryBtn,
                      !(isDisabled || isPayDisable) ? (activePage === PAGE_PATH.EXTENSION ? styles.activeBtn : null) : styles.disable,
                    ]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonMetricsStyle.exSmallHeading,
                          commonStyles.fontWeight500,
                          commonStyles.transformUpperCase,
                          commonStyles.whiteColor,
                          activePage === PAGE_PATH.EXTENSION ? styles.active : null,
                        ]}>
                        Extension
                      </Text>
                    </View>
                  </Touchable>
                </View>
                : null}

              {!!billerDetails && !!billerDetails.isPaymentsPartial ?
                <View
                  style={[
                    customCommonStyles.payButtons,
                    commonStyles.flex1,
                    styles.bottomPayButtons,
                  ]}
                >
                  <Touchable
                    onPress={() => (!(isDisabled || isPayDisable) ? this.paymentNavigation("payPartialRoute", PAGE_PATH.PAYPART) : null)}>
                    <View style={[
                      commonStyles.flex1,
                      commonStyles.justifyContentCenter,
                      commonStyles.alignItemsCenter,
                      commonStyles.textAlignCenter,
                      styles.blueButton,
                      commonStyles.secondaryBtn,
                      !(isDisabled || isPayDisable) ? (activePage === PAGE_PATH.PAYPART ? styles.activeBtn : null) : styles.disable,
                    ]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonMetricsStyle.exSmallHeading,
                          commonStyles.fontWeight500,
                          commonStyles.whiteColor,
                          commonStyles.transformUpperCase,
                          activePage === PAGE_PATH.PAYPART ? styles.active : null,
                          Platform.OS === 'web' && styles.fullWidth
                        ]}>
                        Pay Part
                      </Text>
                    </View>
                  </Touchable>
                </View>
                : null}


              {!!billerDetails && !!billerDetails.isPaymentsFull ?
                <View
                  style={[
                    customCommonStyles.payButtons,
                    commonStyles.flex1,
                    styles.bottomPayButtons,
                    commonStyles.marRT0
                  ]}
                >
                  <Touchable
                    onPress={() => (!(isDisabled || isPayDisable) ? this.paymentNavigation("payTypeRoute", PAGE_PATH.PAYFULL) : null)}>
                    <View style={[
                      commonStyles.flex1,
                      commonStyles.justifyContentCenter,
                      commonStyles.alignItemsCenter,
                      commonStyles.textAlignCenter,
                      styles.blueButton,
                      !(isDisabled || isPayDisable) ? (activePage === PAGE_PATH.PAYFULL ? styles.activeBtn : null) : styles.disable,
                    ]}>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonMetricsStyle.exSmallHeading,
                          commonStyles.fontWeight500,
                          commonStyles.whiteColor,
                          commonStyles.transformUpperCase,
                          activePage === PAGE_PATH.PAYFULL ? styles.active : null,
                          Platform.OS === 'web' && styles.fullWidth
                        ]}>
                        Pay All
                      </Text>
                    </View>
                  </Touchable>
                </View>
                : null}

            </View>]}
        />
      );
    }
  }
);

const styles = StyleSheet.create({
  bottomPaySection: {
    // borderTop: "1px solid #C8C8C8",
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
    width: "100%",
  },
  fullWidth: {
    width: "100%",
    // screen: ({ width }) => {
    //   if (width <= 420)
    //   return {
    //         width: "100%",
    //     };
    // }
  },
  payOptImages: {
    display: "block",
    width: 24,
    height: 24,
    backgroundSize: "contain",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
  },
  active: {
    color: "#888888",
  },
  activeBtn: {
    opacity: 1,
    pointerEvents: "none",
    backgroundColor: "#f4f4f4",
    cursor:"auto",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: "#f4f4f4"
  },
  disable: {
    opacity: 0.5,
    // cursor: "auto",
  },
  bottomControlsView: {
    // display: "block",
    // height: "auto",
    height: "auto",
  },
  blueButton: {
    flexGrow: 1,
    backgroundColor: "#d71996",
    minHeight: 24,
    height: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  bottomPayButtons: {
    flexBasis: "25%",
    width: "25%",
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 1024) {
        return {
          flexBasis: "24%",
          width: "24%",
          marginRight: 4,
        };
      }
    },
  },
  declineButton: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
    minHeight: 24,
    height: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderColor: "#1277d9",
    borderWidth: 1,
    borderStyle: "solid"
  },

});
