/* eslint-disable no-useless-escape */
import { CommonString } from "../constant/appCommonText";

export default class CommonValidators {
  static getFormValidator = () => {
    return {
      validateEmail: (value) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
        let isValid = re.test(value.trim());
        if (isValid)
          isValid = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,5})+$/.test(value.trim())
        return isValid
          ? true
          : {
            error: CommonString.InvalidEmail,
          };
      },
      phoneNoValidator: (value) => {
        let isValid = /^[0-9]{5,20}$/gi.test(value);
        return isValid
          ? true
          : {
            error: CommonString.MIN5MAX20DigitValidation,
          };
      },
      max10phoneNoValidator: (value) => {
        let isValid = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN10Digit,
          };
      },
      cardValidator: (value) => {
        let isValid = /^[0-9-]{19}$/gi.test(value);
        return isValid
          ? true
          : {
            error: CommonString.MIN16Digit,
          };
      },
      monthValidator: (value) => {
        let isValid = /^[1-9]$|^[1][0-2]$/gi.test(value);
        return isValid
          ? true
          : {
            error: CommonString.MIN1MAX12Digit,
          };
      },
      onlyNumbers: (value) => {
        let isValid = /^[0-9]*$/gi.test(value);
        return isValid
          ? true
          : {
            error: CommonString.OnlyDigit,
          };
      },
      checkSpclChar: async (value) => {
        let isValid = /^[\w\d\s&=_'+.,<>-]{2,60}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN2MAX60Validation,
          };
      },
      min2Max60: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{2,60}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN2MAX60WithoutSpecial,
          };
      },
      min2Max70: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{2,70}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.Min2Max70AlphaNumericChar,
          };
      },
      max20AlphaNumeric: async (value = "") => {
        let isValid = /^[a-zA-Z0-9\s]{0,20}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.Max20AlphNumericChar,
          };
      },
      max125AlphaNumeric: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{0,125}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.Max125AlphaNumericChar,
          };
      },
      min2Max26: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{2,26}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN2MAX26,
          };
      },
      max10Num: async (value) => {
        let isValid = /^[1-9][0-9]{0,9}$/gi.test(value.trim());
        return isValid ? true : { error: CommonString.MIN1MAX9999999999 };
      },
      max3Num: async (value) => {
        let isValid = /^[1-9][0-9]{0,2}$/gi.test(value.trim());
        return isValid ? true : { error: CommonString.MIN1MAX999 };
      },
      only4Num: async (value) => {
        let isValid = /^[0-9]{4}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.Only4Digit,
          };
      },
      min2Max300: async (value) => {
        let isValid = /^[\w\d\s&=_'+.,<>-]{2,300}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN2MAX300
          };
      },
      min2Max15: async (value) => {
        let isValid = /^([a-zA-Z0-9\s]{2,15})$/gi.test(value.trim());
        return isValid ? true : { error: CommonString.MIN2MAX15Validation };
      },
      min2Max15AlphaNumeric: async (value) => {
        let isValid = /^([a-zA-Z0-9\s]{0,15})$/gi.test(value.trim());
        return isValid ? true : { error: CommonString.MIN2MAX15Validation };
      },
      min0Max15: async (value) => {
        let isValid = /^([a-zA-Z0-9\s]{0,15})$/gi.test(value.trim());
        return isValid ? true : { error: CommonString.MAX15 };
      },
      poBoxNoWihSemiCol: async (value) => {
        // let isValid = /^([PO BOX 0-9]{0,15})$/gi.test(value.trim());
        let isValid = /^[\w\d\s&=_'+.,;#!@^*()<>-]{0,75}$/gi.test(value.trim());
        return isValid ? true : { error: CommonString.ContentExceed75, };
      },
      max15: async (value) => {
        let isValid = /^[\w\d\s]{0,15}$/gi.test(value.trim());
        return isValid ? true : { error: CommonString.MIN2MAX15Validation };
      },
      max10WithDecimal: async (value) => {
        let isValid = /^\+?(\d*[1-9]\d*\.?|\d*\.\d*[0-9]\d*)$/gi.test(value.trim());
        if (isValid && (value < 0.5 || value.length > 9)) {
          isValid = false;
        }
        return isValid ? true : { error: CommonString.MIN1MAX10Digit };
      },
      max10Decimal: async (value) => {
        let isValid = /^\+?(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/g.test(value);
        if (isValid && value.length > 0) {
          isValid = !(+value > 99999999.99 || value.length >= 12);
        }
        return isValid ? true : { error: CommonString.MIN1MAX10DigitAmount };
      },
      max140: async (value) => {
        let isValid = /^[\w\d\s&=_'+.,<>-]{0,140}$/gi.test(value.trim());
        return isValid ? true : { error: CommonString.ContentExceed140 };
      },
      max100: async (value) => {
        let isValid = /^.{0,100}$/gm.test(value.trim())
        return isValid ? true : { error: CommonString.ContentExceed100 };
      },
      cmtsVali: async (value) => {
        let isValid = /^.{0,140}$/gm.test(value.trim())
        return isValid ? true : { error: CommonString.ContentExceed140 };
      },
      min2Max30: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{2,30}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN2MAX30,
          };
      },
      min8Max15: async (value) => {
        let isValid = /^[a-zA-Z0-9!@#$%?*]{8,15}$/gi.test(value);
        isValid = !!isValid ? /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#@$!%*?]).{8,15}$/gi.test(value) : isValid;
        //let isValid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#@$!%*?]).{8,15}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.PasswordValidation,
          };
      },
      bankAccountValidator: (value) => {
        let isValid = /^[0-9X]{6,17}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN6MAX17,
          };
      },
      min10Max50: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{10,50}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN10MAX50,
          };
      },
      min9Max9Digit: async (value) => {
        let isValid = /^[0-9]{9}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.Only9Digit,
          };
      },
      min0Max5Digit: async (value) => {
        let isValid = /^\+?(\d{1,3}(,\d{3})*|\d+)$/g.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MAX5Digit,
          };
      },
      max8WithDecimal: async (value) => {
        let isValid = /^\+?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/g.test(value);
        if (isValid && (value < 0.5 || value.length > 9)) {
          isValid = false;
        }
        return isValid ? true : { error: CommonString.MIN1MAX8 };
      },
      min10Max10DigitNPI: async (value) => {
        let isValid = /^[0-9]{10}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.NPI10Digits,
          };
      },
      min8Max11: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{8,11}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN8MAX11,
          };
      },
      min2Max50: async (value) => {
        let isValid = /^[a-zA-Z0-9/-\s]{2,50}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN2MAX50Validation
          };
      },
      min0Max50: async (value = "") => {
        let isValid = /^[a-zA-Z0-9/-\s]{0,50}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MAX50,
          };
      },
      max75: async (value) => {
        let isValid = /^[\w\d\s&=_'+.,<>-]{0,75}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.ContentExceed75,
          };
      },
      // EIN number validation.
      EINValidation(value) {
        // let isValid = /^[a-zA-Z0-9\s]{0,10}$/gi.test(value);
        let isValid = /^(\d{2})(\-|\s)?(\d{7})$/.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.EINLength,
          };
      },

      min2Max150: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{2,150}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN2Max150Validation,
          };
      },
      min2Max6: async (value) => {
        let isValid = /^[0-9/-]{2,6}$/gi.test(value.trim())
        return isValid
          ? true
          : {
            error: CommonString.MIN2Max6Digit,
          };
      },
      minMax6Digit: async (value) => {
        let isValid = /^[0-9]{6}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.EnterValidOTPRequired,
          };
      },
      
      min5Max10: async (value) => {
        // valid zip code -> NNNNN or NNNNN-NNNN
        let isValid = /^\d{5}-\d{4}$|^\d{5}$|^\d{9}$/gi.test(value);
        return isValid
          ? true
          : {
            error: CommonString.ZipCode5,
          };
      },

      min16: async (value) => {
        let isValid = /^[a-zA-Z0-9\s]{16}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.PID16Length,
          };
      },
      min6Max50Digits: async (value) => {
        let isValid = /^[a-zA-Z0-9]{6,50}$/gi.test(value.trim());
        return isValid
          ? true
          : {
            error: CommonString.MIN6Max50Digit,
          };
      },
    };
  };
}
