import React, { Component } from "react";
import R14, { StyleSheet, View, Platform, Button, Form, SubmitButton } from "../core";
import { BILL_STATUS } from "../constant/app.data.constant";
import BillerDetails from "../components/BillerDetails";
import BottomPaySection from "../components/BottomPaySection";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import CommonValidators from "../utils/CommonValidators";
import { MSG_TYPE } from "../constant/app.data.constant";
import DateUtils from "../utils/DateUtils";
import DatePicker from "../components/PCH/DatePicker";
import CommonUtility from "../utils/CommonUtility";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";
import ChatboxInputField from "../components/ChatboxInputField";

export default R14.connect(
  class ExtensionScreen extends Component {
    constructor(props) {
      super(props);
      this.initialValues = {
        senderPid: props.billData.senderPid,
        senderName: props.billData.senderName,
      };
      this.state = {
        isLoading: false,
        showDatePicker: false,
      };
      this.fileData = null;
    }

    navigateToBack = async () => {
      analyticsLogs(CommonString.EXTENSION_BILL, `${CommonString.CLICKED_ON_CANCEL_BUTTON}`, this.props.app);
      let newThread = this.props.billData.threadId; //.replaceAll("#", ">>");
      this.props.app.nav.to("billDetailsRoute", { threadId: newThread });
    };

    componentDidMount() {
      this.form = this.props.app.ui.form("requestExt");
    }


    onAttachment = (val) => {
      if(!!val) analyticsLogs(CommonString.EXTENSION_BILL, `${CommonString.ATTACHMENT_ADDED}`, this.props.app);
      else analyticsLogs(CommonString.EXTENSION_BILL, `${CommonString.ATTACHMENT_REMOVED}`, this.props.app);
      this.fileData = val;
    };

    onSubmit = async (form) => {
      const { billData } = this.props;
      const { comments, requestedExecutionDate } = form.values;
      const extensionDate =  new Date(`${requestedExecutionDate} 14:00:00`)
      let reqDate = this.onDateChange(extensionDate);
      if (!!!reqDate)
        return false;

      let mergedData = {
        ...billData,
        additionalInformation: comments,
        requestedExecutionDate: extensionDate,
        messageType: MSG_TYPE.REQ_PAY_EXT,
        attachments: !!this.fileData ? [this.fileData] : null,
      };

      let payload = await this.props.app.dm.billDetails.getRequestExtPayload(mergedData);
      this.setState({ isLoading: true });
      try {
        let res = await this.props.app.dm.billDetails.postMessageForPayment(payload);
        if (res && res.threadId) {
          analyticsLogs(CommonString.EXTENSION_BILL, `${CommonString.BILL_EXTENSION_REQUESTED}`, this.props.app);
          this.props.app.dm.commonDomain.showToastr("success", CommonString.ExtensionSent); // given message by Hazel

          let decPayload = {
            uid: mergedData.uid,
            isExtentionRequested: true,
            extentionRequestedDate: mergedData.requestedExecutionDate,
            isExtentionApproved: false,
            extentionApprovedDate: null,
            isExtentionDeclined: false,
            extentionDeclinedDate: null,
            rtpMessageType: MSG_TYPE.REQ_PAY_EXT,
            isFailedPayment: false,
            failDate: null,
            billStatus: billData.billStatus,
            paymentStatus: billData.paymentStatus,
          };
          if (!["Paid", "Declined"].includes(billData.billStatus)) {
            let isOverDueDate = CommonUtility.compareDueDate(billData.dueDate);

            if (!!!isOverDueDate && billData.billStatus !== "Outstanding") {
              if (!!billData.amountPaid && billData.amountPaid > 0)
                decPayload.billStatus = "Outstanding";
              else
                decPayload.billStatus = "New";
            }
            else if (!!isOverDueDate) decPayload.billStatus = "Overdue";
          }
          decPayload.paymentStatus = decPayload.billStatus;
          await this.props.app.dm.dashboard.updateBill(decPayload);

          const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;
          if (compiledMsgList) {
            const ind = compiledMsgList.findIndex((el) => el.uid === decPayload.uid);
            compiledMsgList[ind].isExtentionRequested = decPayload.isExtentionRequested;
            compiledMsgList[ind].extentionRequestedDate = decPayload.extentionRequestedDate;
            compiledMsgList[ind].isExtentionApproved = decPayload.isExtentionApproved;
            compiledMsgList[ind].extentionApprovedDate = decPayload.extentionApprovedDate;
            compiledMsgList[ind].isExtentionDeclined = decPayload.isExtentionDeclined;
            compiledMsgList[ind].extentionDeclinedDate = decPayload.extentionDeclinedDate;
            compiledMsgList[ind].rtpMessageType = decPayload.rtpMessageType;
            compiledMsgList[ind].billStatus = decPayload.billStatus;
            compiledMsgList[ind].paymentStatus = decPayload.paymentStatus;
            compiledMsgList[ind].isFailedPayment = decPayload.isFailedPayment;
            const flIndx = filterBills.findIndex((el) => el.uid === decPayload.uid);
            filterBills[flIndx].isExtentionRequested = decPayload.isExtentionRequested;
            filterBills[flIndx].extentionRequestedDate = decPayload.extentionRequestedDate;
            filterBills[flIndx].isExtentionApproved = decPayload.isExtentionApproved;
            filterBills[flIndx].extentionApprovedDate = decPayload.extentionApprovedDate;
            filterBills[flIndx].isExtentionDeclined = decPayload.isExtentionDeclined;
            filterBills[flIndx].extentionDeclinedDate = decPayload.extentionDeclinedDate;
            filterBills[flIndx].rtpMessageType = decPayload.rtpMessageType;
            filterBills[flIndx].billStatus = decPayload.billStatus;
            filterBills[flIndx].paymentStatus = decPayload.paymentStatus;
            filterBills[flIndx].isFailedPayment = decPayload.isFailedPayment;
            await this.props.app.dm.dashboard.updateBillCount(compiledMsgList, filterBills);
          }
          this.props.app.nav.to("makePaymentRoutes");
        } else {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.ExtensionFail); // given message by Hazel
        }
      } catch (error) {
        console.log("Error: ", error);
      } finally {
        this.setState({ isLoading: false });
      }
      return false;
    };

    onDateChange = (val) => {
      this.form.elmts.requestedExecutionDate.setError(null);
      if (!val) {
        return false;
      }
      const { dueDate } = this.props.billData;
      val = `${val.toDateString()} 14:00:00`;
      this.form.elmts.requestedExecutionDate.setValue(DateUtils.formatDate(val));
      let duDate = new Date(dueDate);
      let reDate = new Date(new Date(val).toDateString());
      let currentDate = CommonUtility.getUTCDate();
      if (duDate >= reDate) {
        this.form.elmts.requestedExecutionDate.setError("Date should be greater than Bill due date.");
        return false;
      } else if (reDate < currentDate) {
        this.form.elmts.requestedExecutionDate.setError("Date should be greater than or equal to today.");
        return false;
      }
      return true;
    };


    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.comments.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.comments.setValue(val.substring(0, 140));
        this.form.elmts.comments.setError("Character Limit Exceeds 140 Characters");
      }
    };

    onSuccessCallback = async (data) => {
      //navigate to billDetails page.
      this.props.app.nav.to("makePaymentRoutes");
    };

    render() {
      const { billData, payType } = this.props;
      const { isLoading } = this.state;
      return (
          <View style={[styles.extensionInnerSection, commonStyles.flexGrow1]}>
            <BillerDetails customStyles={commonStyles.padding0} billData={billData} customFlexDirection={styles.customFlexDirection} />
            <View style={commonStyles.marginBottom15}>
              <BottomPaySection
                threadId={billData.threadId}
                activePage={payType}
                isDisabled={BILL_STATUS[billData.billStatus]}
                extraOpt={billData.rtpMessageType}
                billerDetails={billData.biller}
                billDetails={billData}
              />
            </View>
            <Form
              title="Request Extension"
              name="requestExt"
              style={[styles.customPadding15]}
              onSubmit={this.onSubmit}
              validateBeforeSubmit={true}
              validators={CommonValidators.getFormValidator()}
              initialValues={this.initialValues}>
              <View style={[Platform.OS !== "web" ? [commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.marginBottom30] : [commonStyles.marginBottom30, styles.minHeight52]]}>
                <DatePicker
                    name={"requestedExecutionDate"}
                    formName="requestExt"
                    props={this.props}
                    onValueChange ={this.onDateChange}
                    label={CommonString.IntendedPaymentLabel + "*"}
                    required={CommonString.IntendedPaymentRequired}
                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    helperTextStyle={[commonFontStyles.workSans, commonStyles.exSmallHeading, styles.dobLabelStyle]}
                  />
              </View>
              <View style={[customCommonStyles.extesionModalWrapperMsgComp]}>
                <ChatboxInputField
                  commentsProps={{
                    name:"comments",
                    required: CommonString.EnterNoteToBiller,
                    label: CommonString.NoteToBillerLabel,
                    validator: "cmtsVali",
                  }}
                  onChangeText = {this.readText}
                  onValueChange={this.onAttachment}
                  textBoxStyle={[commonStyles.smallTextBoxStyle]}
                  attachmentBoxStyle={[commonStyles.smallAttachmentBoxStyle]}
                  sendBtnBoxStyle={[commonStyles.smallSendBtnBoxStyle]}
                />
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.declinedBtnWrap]}>
                <Button
                  key="cancel"
                  title="Cancel"
                  style={[commonStyles.blueBorderButton, commonStyles.marginRight8, styles.cancelBtn, Platform.OS === 'ios' ? styles.fullWidth : styles.declinedBtn]}
                  titleTextStyle={[commonStyles.fontWeight500, commonStyles.blueBorderButtonColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}
                  onPress={this.navigateToBack}
                  disabled={isLoading}
                />
                <SubmitButton
                  title="Request"
                  key="request"
                  style={[commonStyles.blueButton, customCommonStyles.noBoxShadow, commonStyles.fullWidth, Platform.OS === 'ios' ? styles.fullWidth : styles.declinedBtn]}
                  titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonStyles.whiteColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}
                  disabled={isLoading}
                />
              </View>
            </Form>
          </View> 
      );
    }
  }
);

const styles = StyleSheet.create({
  declinedContainer: {
    // paddingTop: 16,
    // paddingRight: 16,
    // paddingBottom: 16,
    // paddingLeft: 16,
    position: "relative",
    margin: 16,
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          // backgroundColor: "#FFFFFF",
        };
      }
      if (width <= 991) {
        return {
          paddingRight: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          margin: 0,
          marginBottom: 30,
        };
      }
    },
  },
  commentsAttachment: {},
  commentsIcon: {
    height: 44,
    width: 44,
    display: "block",
    backgroundSize: "contain",
    borderTopLeftRadius: 44,
    borderTopRightRadius: 44,
    borderBottomLeftRadius: 44,
    borderBottomRightRadius: 44,
  },
  radioButtonsOuter: {
    color: "red",
  },
  customPadding15: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexDirection: "column",
        };
    },
  },
  declinedBtnWrap: {
    justifyContent: "flex-end",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          justifyContent: "space-between",
          marginTop: 20,
        };
      }
    },
  },
  cancelBtn: {
    borderWidth: 2,
    borderColor: "#1277D9",
  },
  declinedBtn: {
    paddingRight: 16,
    paddingLeft: 16,
    // marginLeft: 15,
    width: 100,
    height: 24,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: "48%",
          paddingRight: 0,
          paddingLeft: 0,
        };
      }
    },
  },
  fullWidth: {
    minWidth: "48%"
  },
  extensionInnerSection: {
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    marginLeft:16,
    marginRight: 16,
    overflowX: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: 16,
          paddingTop: 16,
        };
      }
    },
  },
  pageTitle: {
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 15,
          paddingRight: 16,
          paddingLeft: 16,
        };
      }
    },
  },
  intendedDateField: {
    width: "88%",
  },
  intendedPaymentDateLabel: {
    top: 13,
  },
  dobLabelStyle: {
    left: 0
  }
});
