import { PLATFORM_NAME } from '../constant/app.data.constant';

export default class UserAgentValidator {
    // methond return is mobile device or not as true/false
    static isMobileDevice()
    {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) 
        || ( window.innerWidth < 1024 ) ){
        // true for mobile device
        return true;
        }
        else{
        // false for not mobile device
        return false;
        }
    }
    
    static getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) return PLATFORM_NAME.Window;
        if (/android/i.test(userAgent)) return PLATFORM_NAME.Android;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return PLATFORM_NAME.IOS;
        return PLATFORM_NAME.UNKNOWN;
    }

    static redirectUrl(route) {
        const deepLinkUrl = `${window.location.origin}/${route}`
        this.fallback(deepLinkUrl, true);
    }

    static fallback(deepLinkUrl, blank) {
        // Open in same or different window the original URL
        if (blank) window.open(deepLinkUrl, "_self");
        else window.location = deepLinkUrl;
    };

}
