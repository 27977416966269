import React, { PureComponent } from "react";
import { StyleSheet, Image, View, Text, Platform,Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
export default class RadioButtons extends PureComponent {
  render() {
    const { options, onValueChange, isSelected, radioButtonsClass, touchableHolder, textOnly, textOnlyStyles } = this.props;
    let imgpath =
      isSelected === options.value ? require("../assets/images/selete_icon_active.png") : require("../assets/images/selete_icon_normal.png");
    return (
      <Touchable style={[touchableHolder]}
        onPress={() => onValueChange(options.value)}>
        <View style={[styles.buttonContainer, Platform.OS === "web" && commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexGrow0, !!textOnly && isSelected === options.value && styles.active, radioButtonsClass]}>
         {!!textOnly ? null : <Image resizeMode="contain" style={[styles.imgCls, commonStyles.icon15]} source={imgpath} /> }
          <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.singleFullFlexItem, commonStyles.height15, commonStyles.fontWeight400, commonStyles.smallHeading, commonStyles.contentColor, !!textOnly ? styles.height0 : null, !!textOnly && isSelected === options.value && commonStyles.fontWeight600, !!textOnlyStyles ? textOnlyStyles : null]}>{options.label}</Text>
        </View>
      </Touchable>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    paddingTop: 6,
    paddingBottom: 6,
    minHeight: 20,
  },
  imgCls: {
    marginRight: 10,
  },
  active:{    
    paddingBottom: 16,
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderColor: "#0b4a87",
    color: "#0b4a87"
  },
  height0:{
    height: 0
  }
});
