import React from "react";
import R14, {  StyleSheet, Form} from "../core";
import CommonValidators from "../utils/CommonValidators";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";
import ChatboxInputField from "./ChatboxInputField";
export default R14.connect(
  class ChatInputField extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        fileName: "",
      }
      this.message = this.props.messageType;
      this.fileToSave = null;
      this.form = null;
      this.child = React.createRef()
    }

    componentDidMount() {
      this.form = this.props.app.ui.form("chatBox");
    }

    onSubmit = async (form) => {
      const { onAfterSent } = this.props;
      form = this.props.app.ui.form("chatBox");
      if (!!this.fileToSave && 'content' in this.fileToSave && !!this.fileToSave.content) {
        var attac = {
          filename: this.fileToSave.filename,
          contentType: this.fileToSave.contentType,
          content: this.fileToSave.content,
        };
      }
      if (!!this.fileToSave && 'content' in this.fileToSave && !!this.fileToSave.content && !form.values.enterMessage) {
        form.elmts.enterMessage.setValue("Attachment");
      }
      if (await form.validate()) {
        const { billData, messageType } = this.props;

        let messages = this.props.msgList;
        messages = messages.filter((el) => el.envelope.messageBody);
        billData.note = form.values.enterMessage;
        billData.messageType = messageType;
        let lastLeaf;
        if (!!this.fileToSave && 'content' in this.fileToSave && !!this.fileToSave.content) {
          lastLeaf = { ...messages[0], billData, attac };
        } else {
          lastLeaf = { ...messages[0], billData };
        }
        let payload = await this.props.app.dm.billDetails.getNotePayload(lastLeaf);

        try {
          let res = await this.props.app.dm.billDetails.postMessageForPayment(payload);
          if (res.threadId) {
            analyticsLogs(this.props.eventName || CommonString.MESSAGE_SECTION, `${CommonString.MESSAGE_SENT}`, this.props.app);
            form.reset();
            onAfterSent(payload);
            this.deleteFile();
            this.props.app.dm.commonDomain.showToastr("success", CommonString.MessageSent);
            return true;
          }
          else
          {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }  
        } catch (err) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
      }
      return false;
    };

    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.enterMessage.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.enterMessage.setValue(val.substring(0, 140));
        this.form.elmts.enterMessage.setError(CommonString.ContentExceed140);
      }
    };

    onFileInputChange = async (val, status) => {
      if (status === "done") {
        const {fileName}  = this.state;
        let fizeSize = val.actualsize / 1024 / 1024;
        if (fizeSize > 2) {
          await this.child.current.wrappedInstance.pullErrorText(CommonString.MaxTwoMBAllowed);
          return false;
        }
        if (fileName === val.filename) {
          await this.child.current.wrappedInstance.pullErrorText(CommonString.DuplicateFile);
          return false;
        }
        analyticsLogs(this.props.eventName || CommonString.MESSAGE_SECTION, `${CommonString.ATTACHMENT_ADDED}`, this.props.app);
        this.fileToSave = val;
        this.setState({ fileName: val.filename });
      }
    };

    deleteFile = () => {
      this.fileToSave = null;
      this.setState({ fileName: "" });
    }

    render() {
      const { notAllowedChat, textBoxStyle, attachmentBoxStyle, sendBtnBoxStyle, activeSendBtnStyle} = this.props;

      return notAllowedChat === false ? null : (
          <Form
            name="chatBox"
            style={[styles.formContainer]}
            validators={CommonValidators.getFormValidator()}
            validateBeforeSubmit={true}
            onSubmit={this.onSubmit}>
              <ChatboxInputField
                name="enterMessage"
                label={`${CommonString.ChatInputLabel}*`}
                validator="cmtsVali"
                required={CommonString.MessageRequired}
                multiline
                onChangeText={this.readText}
                onFileInputChange={this.onFileInputChange}
                deleteFile={this.deleteFile}
                onSend={()=> this.onSubmit(this.props.app.ui.form("chatBox"))}
                textBoxStyle={textBoxStyle}
                attachmentBoxStyle={attachmentBoxStyle}
                sendBtnBoxStyle={sendBtnBoxStyle}
                activeSendBtnStyle={activeSendBtnStyle}
              />
            </Form>
      );
    }
  }
);

const styles = StyleSheet.create({
  formContainer: {
    paddingLeft: 0, 
    paddingRight: 0
  }
});
