import R14 from "../../core";

export default class SMBApiDomain extends R14.Domain {
  // Query APIs

  // payload = { pid: String, type: String, approvalStatus: String, paymentStatus: String, page: Int, resultsPerPage: Int }
  async getSMBInvoiceList(payload, fields = "businessName invoiceID invoiceNo invoiceDate dueDate dueAmount invoiceAmount paymentTerm extRefNo paymentStatus approverUserID approver entityId sourceSystem currency buLeader") {
    try {
      let userLoginDetail = await this.dm.rest.getUser();

      payload.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : payload.pid;

      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetSMBInvoiceList($pid: String, $type: String, $approvalStatus: String, $paymentStatus: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        getSMBInvoiceList(pid: $pid, type: $type, approvalStatus: $approvalStatus, paymentStatus: $paymentStatus, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
					totalRecords
          data { ${fields} }
          approverList { userID userName }
          success message
        }
      }`,
        payload,
      ));
      return res.data.getSMBInvoiceList;
    } catch (err) {
      console.log(err)
      return [];
    }
  };

  async getVenderList(payload, fields = "vendorID businessName extRefNumber1 currency") {
    try {
      let userLoginDetail = await this.dm.rest.getUser();
      payload.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : payload.pid;
                   
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetVenderList($pid: String, $search: String, $resultsPerPage: Int) {
        getVenderList(pid: $pid, search: $search, resultsPerPage: $resultsPerPage){
          data { ${fields} }
          success message
        }
      }`,
        payload,
      ));
      return res.data.getVenderList.data;
    } catch (err) {
      console.log(err)
      return [];
    }
  };

  async getSMBCustomer(payload, fields = "custID businessName extExtRefNumber") {
    try {
      let userLoginDetail = await this.dm.rest.getUser();
      payload.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : payload.pid;
      
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetSMBCustomer($pid: String, $search: String, $resultsPerPage: Int) {
        getSMBCustomer(pid: $pid, search: $search, resultsPerPage: $resultsPerPage ){
          data { ${fields} }
          success message
        }
      }`,
        payload,
      ));
      return res.data.getSMBCustomer.data;
    } catch (err) {
      console.log(err)
      return [];
    }
  };

  async getOrgList(payload, fields = "orgId orgName") {
    try {
      let userLoginDetail = await this.dm.rest.getUser();

      payload.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : payload.pid;
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetOrgList($pid: String) {
        getOrgList(pid: $pid){
          data { ${fields} }
          success message
        }
      }`,
        payload,
      ));
      return res.data.getOrgList.data;
    } catch (err) {
      console.log(err)
      return [];
    }
  };

  async getSMBCurrency(payload, fields = "currencyId currencyCode") {
    try {
      let userLoginDetail = await this.dm.rest.getUser();
      payload.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : payload.pid;
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetSMBCurrency($pid: String) {
        getSMBCurrency(pid: $pid){
          data { ${fields} }
          success message
        }
      }`,
        payload,
      ));
      return res.data.getSMBCurrency.data;
    } catch (err) {
      console.log(err)
      return [];
    }
  };

  async getSMBStates(payload, fields = "stateID stateName") {
    try {
      let userLoginDetail = await this.dm.rest.getUser();
      payload.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : payload.pid;

      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetSMBStates($pid: String) {
        getSMBStates(pid: $pid){
          data { ${fields} }
          success message
        }
      }`,
        payload,
      ));
      return res.data.getSMBStates.data;
    } catch (err) {
      console.log(err)
      return [];
    }
  };

  async getAPVendorStatementDetail(payload, fields = "invoiceID invoiceNo invoiceDate invoiceAmount vendorName extRef entityID dueDate dueAmount invoiceStatus paymentStatus paymentReference paidAmount paymentDate orgName") {
    try {
      let userLoginDetail = await this.dm.rest.getUser();
      payload.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : payload.pid;

      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetAPVendorStatementDetail($pid: String, $vendorID: String, $fromDate: String, $dueDate: String, $paymentStatus: String, $orgList: String, $entityID: String, $extRefNo: String) {
        getAPVendorStatementDetail(pid: $pid, vendorID: $vendorID, fromDate: $fromDate, dueDate: $dueDate,paymentStatus: $paymentStatus ,orgList: $orgList, entityID: $entityID, extRefNo: $extRefNo){
          data { ${fields} }
          totalRecords
          success message
        }
      }`,
        payload,
      ));
      return res.data.getAPVendorStatementDetail;
    } catch (err) {
      console.log(err)
      return [];
    }
  };
   async getSMBCustomerInvoiceList(payload, fields = "invoiceID invoiceNumber businessName currency invoiceDate invoiceAmount dueDate dueAmount") {
    try {
      let userLoginDetail = await this.dm.rest.getUser();
      payload.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : payload.pid;

       let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetSMBCustomerInvoiceList($pid: String, $customerID: String) {
        getSMBCustomerInvoiceList(pid: $pid, customerID: $customerID){
          data { ${fields} }
          paymentRefID
          success message
        }
      }`,
        payload,
      ));
      return res.data.getSMBCustomerInvoiceList;
    } catch (err) {
      console.log(err)
      return [];
    }
  };

  // Mutation APIs

  async saveSmbMarkAsPaidPayment(input) {
    try {
      let userLoginDetail = await this.dm.rest.getUser();

      input.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : input.pid;

      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `mutation SmbMarkAsPaidPayment($input: SMBMarkAsPaidPaymentInput!){
            smbMarkAsPaidPayment(input: $input)
        }`,
        { input }
      ));
      return res.data.smbMarkAsPaidPayment;
    } catch (error) {
      return false;
    }
  }

  async saveSmbApproveInvoiceStatus(input) {
    try {      
      let userLoginDetail = await this.dm.rest.getUser();

      input.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : input.pid;

      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `mutation SmbApproveInvoiceStatus($input: SMBApproveInvoiceStatusInput!){
            smbApproveInvoiceStatus(input: $input)
        }`,
        { input }
      ));
      return res.data.smbApproveInvoiceStatus;
    } catch (error) {
      return false;
    }
  }

  async saveSmbCreateBill(input) {
    try {      
      let userLoginDetail = await this.dm.rest.getUser();

      input.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : input.pid;

      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `mutation SmbCreateBill($input: SMBCreateBillInput!){
            smbCreateBill(input: $input)
        }`,
        { input }
      ));
      return res.data.smbCreateBill;
    } catch (error) {
      return false;
    }
  }

  async saveSmbReceivePayment(input) {
    try {  

      let userLoginDetail = await this.dm.rest.getUser();
      
      input.pid = (!!userLoginDetail && !!userLoginDetail.defaultOrg && !!userLoginDetail.defaultOrg.pid)
                   ? userLoginDetail.defaultOrg.pid : input.pid;

      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `mutation SmbReceivePayment($input: SMBReceivePayment!){
            smbReceivePayment(input: $input)
        }`,
        { input }
      ));
      return res.data.smbReceivePayment;
    } catch (error) {
      return false;
    }
  }
  async getUserOrgList(payload, fields = "orgId orgName pid parentOrgId") {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query getUserOrgs($email: String!) {
        getUserOrgs(email: $email){
          data { ${fields} }
          success message
        }
      }`,
        payload,
      ));
      return res.data.getUserOrgs.data;
    } catch (err) {
      console.log(err)
      return [];
    }
  };
}
