import React from "react";
import R14, { View, Touchable, Image, StyleSheet } from "../../core";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import HtmlDeCode from "../../utils/HtmlDecode";
import { commonStyles } from "../../assets/css/commonStyle";
import { CommonString } from "../../constant/appCommonText";

const attachedFileIcon = require("../../assets/images/attachedFileIcon.png");

export default R14.connect(
  class PDFViewerComponent extends React.Component {

    documentSource = (dataSourceType, dataSource) => {
      let source = "";
      if (dataSourceType === "pdf") {
        source = `data:application/pdf;base64,${dataSource}`;
      } else if (dataSourceType) {
          source = `data:image/${dataSourceType};base64,${dataSource}`;
      } else {
        source = dataSource;
      }
       return [{ uri: source }];
    };

    onDownloadFile = async (item, ext) => {
      const { bills, closePDF } = this.props;
      if (!!bills && !!bills.mergedFileName && !!item) {
        const paylod = {
          content: `data:application/octet-stream;base64,${item}`, //signedUrl, //`data:application/octet-stream;base64,${item}`,
          contentType: ext || 'PDF',
          filename: bills.mergedFileName //!!this.props.bills ? this.props.bills.mergedFileName : `xbpclaims.${ext}`
        }
       await HtmlDeCode.downloadFile(paylod);
       closePDF();
       return false;
      } else {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
      return false;
    };

    render() {
      try {
        const { dataSourceType, dataSource, disableFileName, isUberDocs, isMobileDevice, dataSourceUnsecured } = this.props;
        return (
          <View>
            <Touchable style={[styles.downloadFileIcon]} onPress={() => this.onDownloadFile(dataSource, dataSourceType)}>
              <View>
                <Image source={attachedFileIcon} style={commonStyles.icon20} />
              </View>
            </Touchable>
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={this.documentSource(dataSourceType, dataSourceUnsecured || dataSource)}
              className={!!isUberDocs ? "external-client" : null}
              style={
                isMobileDevice? 
                  { width: "70vw", height: 400, marginTop: 0, marginRight: "auto", marginBottom: 0, marginLeft: "auto" } : { width: "70vw", height: 500, marginTop: 0, marginRight: "auto", marginBottom: 0, marginLeft: "auto" }
              }
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: disableFileName,
                  retainURLParams: false,
                },
              }}>
            </DocViewer>
          </View>
        );
      } catch (error) {

      }
    }
  }
);
const styles = StyleSheet.create({
  downloadFileIcon: {
    display: "inline-flex",
    padding: 5,
  }
}); 