import Moment from 'moment';
import CommonUtility from './CommonUtility';
const DATE_MMDDYYYY = "MM/DD/YYYY";

export default class DateUtils {
  static formatDate(val, frm = "MM/DD/YYYY") {
    let dt;
    if (!!val && typeof val === "string" && val.includes('/')) {
      if (val.indexOf(':') <= -1) val = `${val} 14:00:00`;
      dt = Moment(val, 'MM/DD/YYYY hh:mm').toDate();
    }
    else dt = new Date(val);

    let m = dt.getUTCMonth() + 1;
    let d = dt.getUTCDate();
    let y = dt.getUTCFullYear();
    // date format 02/22/2021
    m = m.toString().length > 1 ? m : "0" + m;
    d = d.toString().length > 1 ? d : "0" + d;
    switch (frm) {
      case "MM/DD/YYYY":
        return `${m}/${d}/${y}`;
      case "DD/MM/YYYY":
        return `${d}/${m}/${y}`;
      default:
        let defaultDate = '';
        if (!!val && val.includes('/')) defaultDate = val;
        else defaultDate = Moment(new Date(val)).format('MM/DD/YYYY');
        return defaultDate;
    }
  }

  static formatDateInMMDDYYYY(val) {
    if (!!val) {
      let dt = new Date(val);
      let m = dt.getMonth() + 1;
      let d = dt.getDate();
      let y = dt.getFullYear();
      // date format 02/22/2021
      m = m.toString().length > 1 ? m : "0" + m;
      d = d.toString().length > 1 ? d : "0" + d;
      return `${m}/${d}/${y}`;
    }
    else return "";
  }

  static formatDateInYYYYMMDD(val) {
    if (!!val) {
      let dt = new Date(val);
      if(!!dt && !!dt.getMonth() ){
      let m = dt.getMonth() + 1;
      let d = dt.getDate();
      let y = dt.getFullYear();
      // date format 02/22/2021
      m = m.toString().length > 1 ? m : "0" + m;
      d = d.toString().length > 1 ? d : "0" + d;
      return `${y}-${m}-${d}`;
      } else {
        return Moment(val, "MM/DD/YYYY").format("YYYY-MM-DD");
      }
    }
    else return "";
  }

  static formatAMPM(dt) {
    // let time = new Date(dt).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
    // return time;
       return Moment(dt).utc().format('hh:mm A');
  }

  static formatYYYYMMDDHHMMSS(date) {
    if (!date) date = new Date();
    return `${date.getFullYear().toString()}${this.ConvertOnetoTwo(date.getMonth() + 1)}${this.ConvertOnetoTwo(date.getDate())}${this.ConvertOnetoTwo(date.getHours())}${this.ConvertOnetoTwo(date.getMinutes())}${this.ConvertOnetoTwo(date.getSeconds())}`;
  }

  static ConvertOnetoTwo(n) {
    return n < 10 ? '0' + n : n;
  }

  static dateAscDescSort(property, order) {
    let sort_order = 1;
    if (order === "DESC") {
      sort_order = -1;
    }
    return function (a, b) {
      a = new Date(a[property]);
      b = new Date(b[property]);
      if (a < b) {
        return -1 * sort_order;
      } else if (a > b) {
        return 1 * sort_order;
      } else {
        return 0 * sort_order;
      }
    };
  }

  static isoFormateDate() {
    return new Date().toISOString();
  }

  static formatInIsoFormat(val){
    return Moment(val, DATE_MMDDYYYY).toISOString();
  }

  static formatforServer(dt) {
    let date = new Date(dt);
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear() + 1;
    let acd = `${d}/${m}/${y}`;
    return new Date(acd).toISOString();
  }
  static formatforDisplay(dt) {
    let d = new Date(dt.split("T")[0]);
    let acd = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    return acd;
  }

  static dateTodayYesterday(dt, format) {
    let displayTime;
    let datemes = CommonUtility.getUTCDate(new Date(dt));
    let CDateTime = CommonUtility.getUTCDate();
    let today = CDateTime;
    today.setHours(0, 0, 0, 0);
    let yesterday = new Date(CDateTime - 864e5);
    yesterday.setHours(0, 0, 0, 0);
    if (datemes >= today) {
      displayTime = `Today ${DateUtils.formatAMPM(dt)}`;
    } else if (datemes >= yesterday) {
      displayTime = `Yesterday ${DateUtils.formatAMPM(dt)}`;
    } else {
      displayTime = `${DateUtils.formatDate(dt, !!format ? format : "MM/DD/YYYY")} ${DateUtils.formatAMPM(dt)}`;
    }
    return displayTime;
  }

  static dateAndTimeWithAmPm(dt, appName = "XBP") {
    return appName === "XBP" ?
      (DateUtils.formatDate(dt, "MM/DD/YYYY") + " " + DateUtils.formatAMPM(dt)) :
       (DateUtils.formatDate(dt, "MM/DD/YYYY") + " " + DateUtils.formatAMPM(dt));
  }

  static dateAndTimeWithFormat(dt, format) {
    return DateUtils.formatDate(dt, !!format ? format : "MM/DD/YYYY") + " " + DateUtils.formatAMPM(dt);
  }

  static monthList() {
    return [...Array(12).keys()].map((i) => ({ label: ("0" + (i + 1)).slice(-2), value: ("0" + (i + 1)).slice(-2) }));
    // let months=[{ label: "01", value: "01" },{ label: "02", value: "02" },
    //             { label: "03", value: "03" },{ label: "04", value: "04" },
    //             { label: "05", value: "05" },{ label: "06", value: "06" },
    //             { label: "07", value: "07" },{ label: "08", value: "08" },
    //             { label: "09", value: "09" },{ label: "10", value: "10" },
    //             { label: "11", value: "11" },{ label: "12", value: "12" }
    //             ];
    //return months
  }
  static yearList() {
    let startYear = new Date().getFullYear();
    return [...Array(12).keys()].map((i) => ({ label: ("0" + (i + startYear)).slice(-4), value: ("0" + (i + startYear)).slice(-4) }));
    // let years=[{ label: "2021", value: "2021" },{ label: "2022", value: "2022" },
    //             { label: "2023", value: "2023" },{ label: "2024", value: "2024" },
    //             { label: "2025", value: "2025" },{ label: "2026", value: "2026" },
    //             { label: "2027", value: "2027" },{ label: "2028", value: "2028" },
    //             { label: "2029", value: "2029" },{ label: "2030", value: "2030" },
    //             { label: "2031", value: "2031" }
    //             ];
    //return years
  }

  static inBetweenDateChecker(curr, startDate = "", endDate = "") {
    // Parse the inputs into Moment.js objects
    const currDate = Moment(curr);
    const startDateObj = startDate ? Moment(startDate) : null;
    const endDateObj = endDate ? Moment(endDate) : null;

    if (!currDate.isValid()) return false;
    // Check if curr is within the date range
    if (startDateObj && endDateObj) {
      return currDate.isBetween(startDateObj, endDateObj, null, '[]'); // '[]' includes the start and end dates
    } else if (startDateObj) {
      return currDate.isSameOrAfter(startDateObj);
    } else if (endDateObj) {
      return currDate.isSameOrBefore(endDateObj);
    }
    // If no startDate or endDate is provided, return true
    return true;
  }

  static isOlderThanMaxLimit(val, max = 125){
    // Convert val to a Date object
    const date = new Date(val);

    // Get the current date
    const currentDate = new Date();

    // Calculate the maximum allowed date (max = 125 years ago from the current date)
    const maxAllowedDate = new Date();
    maxAllowedDate.setFullYear(currentDate.getFullYear() - max);

    // Compare val with the maximum allowed date
    return date < maxAllowedDate;
  }

  static getShortestDate(dateStrings = []){

    const dateObjects = dateStrings.map((dateString) => new Date(dateString));
    const shortestDate = new Date(Math.min(...dateObjects));

    // Format the result as MM/DD/YYYY
    const formattedDate =
      (shortestDate.getMonth() + 1).toString().padStart(2, '0') +
      '/' +
      shortestDate.getDate().toString().padStart(2, '0') +
      '/' +
      shortestDate.getFullYear();
    return formattedDate;
  }

  static getGreatestDate(dateStrings = []){
    const dateObjects = dateStrings.map((dateString) => new Date(dateString));
    const highestDate = new Date(Math.max(...dateObjects));

    // Format the result as MM/DD/YYYY
    const formattedDate =
      (highestDate.getMonth() + 1).toString().padStart(2, '0') +
      '/' +
      highestDate.getDate().toString().padStart(2, '0') +
      '/' +
      highestDate.getFullYear();
    return formattedDate;
  }
}
