import React from "react";
import R14, {
  StyleSheet,
  Button,
  Image,
  Text,
  View,
  Touchable,
  ScrollView,
  TextInputField,
  Form,
  SubmitButton,
  SelectMenuField,
  PopUpMenu,
  PopUpMenuItem,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import CommonValidators from "../../utils/CommonValidators";
import { USER_TYPE, SEARCH_TYPE, MANAGE_PASSWORD, DEFAULT_COUNTRY, MESSAGE_TYPE } from "../../constant/app.data.constant";
import CommonUtility from "../../utils/CommonUtility";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";
import PopupModalComponent from "../../components/PopupModalComponent";

const editIcon = require("../../assets/images/iconEdit.png");

export default R14.connect(
  class PCHProfileViewScreen extends React.Component {
    constructor(props) {
      super(props);
      this.profileView = this.props.profile;
      this.validateBUDetails();
      this.scrollRef = React.createRef();
      this.state = {
        scrollCount: 0,
        isChangePasswordModalVisible: false,
        isUploadImageModalVisible: false,
        attachments: [],
        IsProfileChange: false,
        isDeletProfileImageModalVisible: false,
        showPassword: true,
        showNewPassword: true,
        showNewConfirmPassword: true,
        oldCountry: null,
        oldState: null,
        addressConfirmed: false,
        isAddressUpdation: "",
        addressConfirmationMessage: "",
      };
      
      this.props.app.dm.userLoginDomain.setState({
        userDetailUID: this.props.profile.uid,
        payerId: this.props.profile.payerId,
      });

      // User Detail Edit
      this.form = null;
      this.actionType = this.props.profileActionType;
      this.countriesList = [];
      this.statesList = [];
      this.citiesList = [];
      // User Change Password
      this.changePasswordForm = null;
    }

    
    validateBUDetails() {
      if (!!this.props.userBUDetails) { //&& this.props.userBUDetails.length < 2
        this.profileView ={...this.profileView,  ...this.props.userBUDetails[0]};
        const userBUDetails = this.props.userBUDetails.map((item)=>{
          if(!!item.billerName)
            return { label: `${item.billerName} (${item.buName})`, value: item.uid }
          else
            return { label: `NA`, value: item.uid }

        });
        this.setState({userBUDetails});
       }
    }

    toggleShowPassword() {
      this.setState({ showPassword: !this.state.showPassword });
    }
    toggleShowNewPassword() {
      this.setState({ showNewPassword: !this.state.showNewPassword });
    }
    toggleShowNewConfirmPassword() {
      this.setState({ showNewConfirmPassword: !this.state.showNewConfirmPassword });
    }


    componentDidMount = async () => {
      let selectedCountry = DEFAULT_COUNTRY;
      try {
        // If User Edit is enabled.
        if (this.actionType === "edit") {
          this.form = this.props.app.ui.form("profileEdit");
          if (!this.profileView.country || this.profileView.country === "US") {
            this.profileView.country = DEFAULT_COUNTRY.label;
          }
          
          // check city 
          if(!!this.profileView.countryObject && !!this.profileView.stateObject && !!this.profileView.cityObject)
          {
            
            this.countriesList = [this.profileView.countryObject];
            this.statesList = [this.profileView.stateObject];
            this.citiesList = [this.profileView.cityObject];
            this.form.elmts.country.setValue(this.profileView.countryObject);
            this.form.elmts.country.setItems(this.countriesList);
            
            this.form.elmts.states.setValue(this.profileView.stateObject);
            this.form.elmts.states.setItems(this.statesList);
            
            this.form.elmts.city.setValue(this.profileView.cityObject);
            this.form.elmts.city.setItems(this.citiesList);
          }
          else if(!!this.profileView.countryObject && !!this.profileView.stateObject)
          {
            
            this.countriesList = [this.profileView.countryObject];
            this.statesList = [this.profileView.stateObject];

            this.form.elmts.country.setValue(this.profileView.countryObject);
            this.form.elmts.country.setItems(this.countriesList);
            
            this.form.elmts.states.setValue(this.profileView.stateObject);
            this.form.elmts.states.setItems(this.statesList);

            let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.CITY, "", "", this.profileView.stateObject.value, 10);
            this.citiesList = data.map((item) => ({
              label: item.city_name,
              value: item.uid,
            }));

            this.form.elmts.city.setValue(null);
            this.form.elmts.city.setItems(this.citiesList);
          }
          else if(!!this.profileView.countryObject)
          {
            this.countriesList = [this.profileView.countryObject];

            this.form.elmts.country.setValue(this.profileView.countryObject);
            this.form.elmts.country.setItems(this.countriesList);
           
            
            let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, "", this.profileView.countryObject.value, "", 10);
            this.statesList = data.map((item) => ({
              label: item.state_name,
              value: item.uid,
            }));

            this.form.elmts.states.setValue(null);
            this.form.elmts.states.setItems(this.statesList);
          }
          else
          {
            this.countriesList = [selectedCountry];
            this.form.elmts.country.setValue(selectedCountry);
            this.form.elmts.country.setItems(this.countriesList);

            let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, "", selectedCountry.value, "", 10);
            this.statesList = data.map((item) => ({
              label: item.state_name,
              value: item.uid,
            }));

            this.form.elmts.states.setValue(null);
            this.form.elmts.states.setItems(this.statesList);
          }
        }
      }
      catch (err) { }
    };

    layoutChange = (event) => {
       let evtobj = event.nativeEvent.layout;
       return evtobj.height;
    };

    navigateToProfileEdit = () => {
      this.props.app.nav.to("pchProfileEditRoute");
    };

    navigateToAddInsuranceAddProvider = () => {
      const userLoginDetail = this.profileView;
      let userType = !!userLoginDetail ? userLoginDetail.userType : null;
      if (userType === USER_TYPE.PCH_PATIENT) { 
        this.props.app.nav.to("insuranceListRoute")
      } else if (userType === USER_TYPE.PCH_PROVIDER) {
        this.props.app.nav.to("addProviderRoute", { mode: "add" })
      }
    };
    onCancel = () => {
      // this.props.app.nav.to("pchProfileViewRoute");
      this.props.app.nav.back();
    };

    //--------Country Dropdown.
    onCountryChange = async (val, IsReturn) => {
      if(!!IsReturn) return false;

      let { label } = val;
      const { oldCountry } = this.state;
      if(!!oldCountry && oldCountry.label === val.label){}
      else{
        this.form.elmts.states.setValue(null);
        this.form.elmts.city.setValue(null);
        this.citiesList = [];
        this.form.elmts.city.setItems(this.citiesList);
      }
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.statesList = [];
        this.citiesList = [];
        this.form.elmts.country.setValue(null);
        this.form.elmts.city.setValue(null);
        this.form.elmts.states.setValue(null);
        this.form.elmts.states.setItems(this.statesList);
        this.form.elmts.city.setItems(this.citiesList);
        return false;
      }
      this.form.elmts.country.setValue(val);
      this.setState({
        oldCountry: val
      });
      this.statesList = await this.onSearchCountryStateCity(SEARCH_TYPE.STATE, true);
    };

    onStateChange = async (val) => {
      let { label } = val;
      const { oldState } = this.state;
      if(!!oldState && oldState.label === val.label){}
      else
        this.form.elmts.city.setValue(null);
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.citiesList = [];
        this.form.elmts.city.setValue(null);
        this.form.elmts.states.setValue(null);
        this.form.elmts.city.setItems(this.citiesList);
        return false;
      }
      this.form.elmts.states.setValue(val);
      this.setState({
        oldState: val
      });     
      this.citiesList = await this.onSearchCountryStateCity(SEARCH_TYPE.CITY, true);
    };

    onCityName = (val) => {
      const { label } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.city.setValue(null);
        return false;
      }
      this.form.elmts.city.setValue(val);
    };

    onSearchCountryStateCity = async (type, isBlankAllow = false, search = "") => {
      const { values, elmts } = this.props.app.ui.form("profileEdit");
      let items = [];
      if (search !== "" || !!isBlankAllow) {
        if (type === SEARCH_TYPE.STATE) {
          let country_name = !!values && !!values.country && !!values.country.value ? values.country.value :"";
         items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, search, country_name);
         items = !!items ? items.map((element) => ({
            label: element.state_name,
            value: element.uid,
          })) : [];
          items = [...new Map(items.map((item) => [item["label"], item])).values()];
          elmts.states.setItems(items);
          return items;
        } else if (type === SEARCH_TYPE.CITY) {
         let state_name = !!values && !!values.states && !!values.states.value ? values.states.value :"";
         if(!state_name) return [];
         items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.CITY, search, "", state_name);
         items = !!items ? items.map((element) => ({
            label: element.city_name,
            value: element.uid,
          })) : [];
          items = [...new Map(items.map((item) => [item["label"], item])).values()]
                 .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
          elmts.city.setItems(!!values.states && 'label' in values.states ? items : null);
          return !!values.states && 'label' in values.states ? items : [];
        } else {
          items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.COUNTRY, search);
          items = !!items ?items.map((element) => ({
            label: element.country_name,
            value: element.uid,
          })) : [];
          items = [...new Map(items.map((item) => [item["label"], item])).values()]
          elmts.country.setItems(items);
          return items;
        }
      } else {
        return type === SEARCH_TYPE.STATE ? this.statesList : type === SEARCH_TYPE.CITY ? this.citiesList : this.countriesList;
      }
    };

    onFileInputChange = (val, status) => {
      if (status === "done") {
        let filesSize = val.actualsize;
        if (filesSize / 1024 / 1024 > 2) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.MaxTwoMBAllowed);
          return false;
        }

        this.setState({ IsProfileChange: true, attachments: [val] });
      } 
    };

    //--------Update - User Edit Start.
    handleSubmit = async (form) => {
      this.props.app.ui.progressIndicator.show();
      this.setState({addressConfirmed: false});
      try {
        let userDetails = null;
        if (this.props.screen === "addUser") {
          userDetails = this.profileView;
        } else {
          userDetails = await this.props.app.dm.rest.getUser();
        }
          if (
            this.props.screen !== "addUser" &&
            !!!this.profileView.parentUid &&
            this.profileView.userType === USER_TYPE.PCH_PROVIDER &&
            this.profileView.EIN !== form.elmts.EIN.value
          ) {
            let loginDetails = await this.props.app.dm.userLoginDomain.checkEIN(form.elmts.EIN.value, userDetails.uid);
            if (loginDetails.isDuplicate) {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.DuplicateEIN);
              this.props.app.ui.progressIndicator.hide({ timeout: 750 });
              return false;
            }
          }
          let res = await this.saveUserDetails(form);
          if (res.message === "Invalid Address") {
            return false;
          } else if (res) {
            this.props.app.dm.commonDomain.showToastr("success", CommonString.UserUpdated);
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            if (this.props.screen === "addUser") this.props.app.nav.to("addUsersRoute");
            else if (!!this.props.defaultMode && this.props.defaultMode === "bill") this.props.app.nav.back();
            else this.props.app.nav.to("pchProfileViewRoute");
          } else {
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }
      } catch (error) {
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
      return false;
    };

    saveUserDetails = async (form) => {
      let userDetails = null;
      if (this.props.screen === "addUser") {
        userDetails = this.props.profile;
      } else {
        userDetails = await this.props.app.dm.rest.getUser();
      }
      const { IsProfileChange, attachments } = this.state;
      let profileImg = null;
      if (!!IsProfileChange && !!attachments && !!attachments.length > 0) {
        profileImg = attachments[0].content;
      }
      const updateUserReqBody = {
        uid: this.profileView.uid ? this.profileView.uid : userDetails.uid,
        pid: this.profileView.pid ? this.profileView.pid : userDetails.pid,
        firstName: form.values.payerFirstName ? form.values.payerFirstName : this.profileView.firstName,
        lastName: form.values.payerLastName ? form.values.payerLastName : this.profileView.lastName,
        addressLine1: form.values.addressLine1 !== null && form.values.addressLine1 !== undefined ? form.values.addressLine1 : "",
        addressLine2: form.values.addressLine2 !== null && form.values.addressLine2 !== undefined ? form.values.addressLine2 : "",
        town: form.values.city !== null && form.values.city !== undefined ? form.values.city.label : "",
        state: form.values.states !== null && form.values.states !== undefined ? form.values.states.label : "",
        country: form.values.country !== null && form.values.country !== undefined ? form.values.country.label : "",
        postCode: form.values.postCode !== null && form.values.postCode !== undefined ? form.values.postCode : "",
        // EIN: form.values.EIN ? form.values.EIN : this.profileView.EIN,
        countryObject: form.values.country !== null && form.values.country !== undefined ? form.values.country : null,
        stateObject:form.values.states !== null && form.values.states !== undefined ? form.values.states : null,
        cityObject: form.values.city !== null && form.values.city !== undefined ? form.values.city : null,
      };
      if (!!profileImg && profileImg.length > 0) {
        updateUserReqBody.profileImage = profileImg;
      }
      // get loggedin user detail
      const {uid:loggedUid} = await this.props.app.dm.rest.getUser();
      updateUserReqBody.updatedBy  = loggedUid;
      updateUserReqBody.updatedDate = new Date();
      // validate Address before updating details
      if(!this.state.addressConfirmed) {
        let isValidAddress = await this.props.app.dm.queryDomain.ValidateAddressDetails({
          address1: updateUserReqBody.addressLine1,
          address2: updateUserReqBody.addressLine2,
          city: updateUserReqBody.cityObject ? updateUserReqBody.cityObject.label : "",
          state: updateUserReqBody.stateObject ? updateUserReqBody.stateObject.label : "",
          country: updateUserReqBody.countryObject ? updateUserReqBody.countryObject.label : "",
          zipCode: updateUserReqBody.postCode
        });
  
        if(isValidAddress && isValidAddress.success === false){
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          this.props.app.dm.commonDomain.showToastr("error", isValidAddress.message);
          return {message: "Invalid Address"}
        };
  
        /** Start of Handle address confirmation popup */
        const updateAddressKeys = !!isValidAddress ? Object.keys(isValidAddress.updatedAddress) : [];
        if(isValidAddress && updateAddressKeys.length){
          let message = "";
          updateAddressKeys.forEach(key => {
            if(key === "address1") message = `Address1: ${isValidAddress.updatedAddress["address1"]}`;
            if(key === "address2") message = (!!message ? `${message}\n` : "") +  `Address2: ${isValidAddress.updatedAddress["address2"]}`;
            if(key === "city") message = (!!message ? `${message}\n` : "") + `City: ${isValidAddress.updatedAddress["city"]}`;
            if(key === "state") message = (!!message ? `${message}\n` : "") + `State: ${isValidAddress.updatedAddress["state"]}`;
            if(key === "zipCode") message = (!!message ? `${message}\n` : "") + `Zip Code: ${isValidAddress.updatedAddress["zipCode"]}`;
          });
  
          if(isValidAddress.alertMessage) message = (!!message ? `${message}\n\n` : "") + `Note* ${isValidAddress.alertMessage}`;
          this.setState({
            isAddressUpdation: "updateAddress",
            addressConfirmationMessage: message,
            addressConfirmed: true,
          });
          return {message: "Invalid Address"};
        } else if(isValidAddress && !!isValidAddress.alertMessage) {
          const message = `Note* ${isValidAddress.alertMessage}`;
          this.setState({
            isAddressUpdation: "alertMessage",
            addressConfirmationMessage: message,
            addressConfirmed: true
          });
          return {message: "Invalid Address"};
        };
        /** End of handle address confirmation popup */
      }

      let res = await this.props.app.dm.userRegistration.UpdatePIDDetails(updateUserReqBody, this.props.screen === "addUser" ? false : true);
      // let res = await this.props.app.dm.userRegistration.updateUserCustomDetails(updateUserReqBody, this.props.screen === "addUser" ? false : true);
      if (res) {
        this.profileView = { ...res };
        return true;
      } else {
        return false;
      }
      // }
    };

    onUpdateAddress = async (updateNewAddress) => {
      // update required fields
      const form = this.props.app.ui.form("profileEdit");
      if(!!updateNewAddress) {
        const splitMessage = this.state.addressConfirmationMessage.split("\n");
  
        splitMessage.forEach(msg => {
          if(!msg.startsWith("Note*")){
            const splitMsg = msg.split(": ");
            if(splitMsg[0] === "State"){
              form.elmts.states.setValue({label: splitMsg[1], value: splitMsg[1]});
              form.elmts.states.setItems([{label: splitMsg[1], value: splitMsg[1]}]);
            } else if(splitMsg[0] === "City"){
              form.elmts.city.setValue({label: splitMsg[1], value: splitMsg[1]});
              form.elmts.city.setItems([{label: splitMsg[1], value: splitMsg[1]}]);
            } else if(splitMsg[0] === "Zip Code") form.elmts.postCode.setValue(splitMsg[1]);
            else if(splitMsg[0] === "Address1") form.elmts.addressLine1.setValue(splitMsg[1]);
            else if(splitMsg[0] === "Address2")form.elmts.addressLine2.setValue(splitMsg[1]);
          }
        });
      }

      this.setState({ addressConfirmed: true, isAddressUpdation: "", addressConfirmationMessage: ""});
      let res = await this.saveUserDetails(form);
      if (res.message === "Invalid Address") {
        return false;
      } else if (res) {
        this.props.app.dm.commonDomain.showToastr("success", CommonString.UserUpdated);
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        if (this.props.screen === "addUser") this.props.app.nav.to("addUsersRoute");
        else if (!!this.props.defaultMode && this.props.defaultMode === "bill") this.props.app.nav.back();
        else this.props.app.nav.to("pchProfileViewRoute");
      } else {
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      };
      return false;
    }

    trimExceededCharZipCode = (val) => {
      CommonUtility.zipCodeFormater(this.form, "postCode", val );
    };

    //--------Update - User Edit End.
    mobileNumberText(val) {
      let mobNumText = val;
      if (!!val) {
        mobNumText = mobNumText.replace(/[^\d.]/g, "").substring(0, 15);
        this.form.elmts.payerMobileNumber.setValue(mobNumText);
      }
      let isValid = /^\+?(\d*[1-9]\d*\.?|\d*\.\d*[0-9]\d*)$/gi.test(mobNumText);

      if (isValid) {
        this.form.elmts.payerMobileNumber.setValue(mobNumText);
        this.form.elmts.payerMobileNumber.setError(null);
      } else {
        if (mobNumText === "") {
          this.form.elmts.payerMobileNumber.setError(null);
        } else {
          this.form.elmts.payerMobileNumber.setError("Enter the valid mobile number");
        }
      }
    }

    openChangePasswordModal = async() => {
      let res = await this.props.app.dm.userSession.isSessionStringValid(true);
      if(!!res.tokenIsValid)
        this.props.app.nav.to("pchChangePassword");
      else
      {
        this.props.app.dm.dashboard.setState({
          sessionInActive: true
        })
      }
      return false;
    };

    cancelChangePassword = () => {
      this.setState({
        isChangePasswordModalVisible: false,
        showPassword: true,
        showNewPassword: true,
        showNewConfirmPassword: true,
      });
    };

    //----------- Change Password Validations: Start.
    changePassword = async () => {
      let cpForm = this.props.app.ui.form("changePassword");
      this.clearAllValidationMessages(cpForm);
      if (cpForm.elmts.currentPassword.value === null || cpForm.elmts.currentPassword.value === "") {
        cpForm.elmts.currentPassword.setError("Enter the Password");
        return false;
      }
      else if (cpForm.elmts.newPassword.value === null || cpForm.elmts.newPassword.value === "") {
        cpForm.elmts.newPassword.setError("Enter New Password");
        return false;
      }
      else if (cpForm.elmts.confirmPassword.value === null || cpForm.elmts.confirmPassword.value === "") {
        cpForm.elmts.confirmPassword.setError("Enter Confirm Password");
        return false;
      }
      else if (cpForm.elmts.currentPassword.value === cpForm.elmts.newPassword.value) {
        cpForm.elmts.newPassword.setError("Can’t use password used in recent past.");
        return false;
      }
      else if (cpForm.elmts.confirmPassword.value !== cpForm.elmts.newPassword.value) {
        cpForm.elmts.confirmPassword.setError("Entered password not matched.");
        return false;
      }

      let isPasswordValid = this.isPasswordValid(cpForm);
      if (!isPasswordValid) {
        cpForm.elmts.newPassword.setError("Enter a valid password");
        return false;
      }
      else {
      this.props.app.ui.progressIndicator.show();
        let userInputBody = {
          handleType: MANAGE_PASSWORD.CHANGE_PASSWORD,
          pid: this.profileView.pid,
          oldPassword: cpForm.values.currentPassword,
          newPassword: cpForm.values.confirmPassword,
          signInLocation: USER_TYPE.CLAIMS,
        };

        let changePassword = await this.props.app.dm.userLoginDomain.managePassword(userInputBody);
        if (!!changePassword) {
          this.props.app.dm.commonDomain.showToastr(!!changePassword.success ? "success" : "error", changePassword.message);
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          if(!!changePassword.success)  
            this.setState({ isChangePasswordModalVisible: false });
        }
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      }
      this.setState({
        showPassword: true,
        showNewPassword: true,
        showNewConfirmPassword: true,
      })
    }

    clearAllValidationMessages = (form) => {
      form.elmts.confirmPassword.setError("");
      form.elmts.newPassword.setError("");
      form.elmts.currentPassword.setError("");
    };

    isPasswordValid = () => {
      let form = this.props.app.ui.form("changePassword");
      let passwordField = form.elmts.newPassword.value;
      let isUpper = false;
      let isNumeric = false;
      let isSpecial = false;
      let minMaxLength = false;
      // Upper Case check
      if (passwordField.replace(/[^A-Z]/g, "").length > 0) {
        isUpper = true;
      }
      // Numeric Character check
      if (passwordField.replace(/[^0-9]/g, "").length > 0) {
        isNumeric = true;
      }
      // Special Character check
      if (passwordField.replace(/[^#@$!%*?]/g, "").length > 0) {
        isSpecial = true;
      }
      // Length Check
      if (passwordField.length > 8 && passwordField.length < 30) {
        minMaxLength = true;
      }
      if (isUpper && isNumeric && isSpecial && minMaxLength) {
        // formElement.setError("");
        return true;
      } else {
        // formElement.setError("Min 8 and Max 30 character with 1 upper, 1 number with special characters @$!%*");
        return false;
      }

      // let form = this.props.app.ui.form("changePassword");
      // let isValid = true;
      // let passwordField = form.elmts.newPassword.value;
      // if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?])[A-Za-z\d@$!%*?]{8,30}$/gi.test(passwordField)) {
      //   isValid = false;
      // }
      // return isValid;
    };
    //----------- Change Password Validations: End.

    openUploadImageModal = () => {
      this.setState({
        isUploadImageModalVisible: true,
      });
    };
    onClose = async () => {
      this.setState({
        isUploadImageModalVisible: false,
        IsProfileChange: false,
      });
    };

    updateProfileImg = async () => {
      const { IsProfileChange, attachments } = this.state;
      if (!!!IsProfileChange) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.UploadProfile);
        return false;
      }
      let userDetails = null;
      if (this.props.screen === "addUser") userDetails = this.props.profile;
      else userDetails = await this.props.app.dm.rest.getUser();
      let profileImg = null;
      if (!!IsProfileChange && !!attachments && !!attachments.length > 0) {
        profileImg = attachments[0].content;
      }
      const updateUserReqBody = {
        uid: this.profileView.uid ? this.profileView.uid : userDetails.uid,
      };
      if (!!profileImg && profileImg.length > 0) {
        updateUserReqBody.profileImage = profileImg;
      }
      let res = await this.props.app.dm.userRegistration.updateUserCustomDetails(updateUserReqBody, this.props.screen === "addUser" ? false : true);
      if (res) {
        //TODO: update profile images.
        if (!!profileImg && profileImg.length > 0) {
          await this.props.app.dm.userRegistration.updateProfileImage(updateUserReqBody.uid);
        }

        this.profileView = { ...res };
        this.setState({ isUploadImageModalVisible: false, IsProfileChange: false });
        this.props.app.dm.commonDomain.showToastr("success", CommonString.UserUpdated);
      }
    };

    removeProfileImgOpenConfimationModal = async () => {
      this.setState({
        isDeletProfileImageModalVisible: true,
      })
    }
    closeDeleteModalPopup = async () => {
      this.setState({
        isDeletProfileImageModalVisible: false,
      })
    }
    removeProfileImg = async () => {
      let userDetails = null;
      if (this.props.screen === "addUser") userDetails = this.props.profile;
      else userDetails = await this.props.app.dm.rest.getUser();

      const updateUserReqBody = {
        uid: this.profileView.uid ? this.profileView.uid : userDetails.uid,
        profileImage: null
      }
      let res = await this.props.app.dm.userRegistration.updateUserCustomDetails(updateUserReqBody, this.props.screen === "addUser" ? false : true);
      if (res) {
        await this.props.app.dm.userRegistration.updateProfileImage(updateUserReqBody.uid);
        this.profileView = { ...res };
        this.setState({
          isUploadImageModalVisible: false,
          isDeletProfileImageModalVisible: false,
          IsProfileChange: false,
          attachments: []
        });
        this.props.app.dm.commonDomain.showToastr("success", CommonString.UserUpdated);
      }
    }
    //----------- Add insurance and change password Popup menu.
    renderPopUpMenu() {
      const userLoginDetail = this.profileView;
      let userType = !!userLoginDetail ? userLoginDetail.userType : null;
      let items = [];
      items.push(
        <PopUpMenuItem
          onPress={() =>
            userType === USER_TYPE.PCH_PATIENT
              ? this.props.app.nav.to("insuranceListRoute")
              : userType === USER_TYPE.PCH_PROVIDER
              ? this.props.app.nav.to("addProviderRoute", { mode: "add" })
              : null
          }
          label={userType === USER_TYPE.PCH_PROVIDER ? "Add Provider" : "Add Insurance"}
          key={userType === USER_TYPE.PCH_PROVIDER ? "Add Provider" : "Add Insurance"}
          value={userType === USER_TYPE.PCH_PROVIDER ? "Add Provider" : "Add Insurance"}
        />
      );
      if (userType === USER_TYPE.PCH_PATIENT) {
        items.push(<PopUpMenuItem onPress={this.navigateToProfileEdit} label="Edit Profile" key="Edit Profile" value="Edit Profile" />);
        if (!!!this.props.enableSocialLogin)
          items.push(<PopUpMenuItem onPress={this.openChangePasswordModal} label="Change Password" key="Change Password" value="Change Password" />);
      }
      return (
        <PopUpMenu
          controlIcon="dotsVertical"
          controlIconSize="small"
          key="trippleDot"
          direction="downLeft"
          style={[commonFontStyles.workSans, commonStyles.heading6, styles.width190]}>
          {items}
        </PopUpMenu>
      );
    }

    // EINValidation(val) {
    //   if (!!val) {
    //     let cleaned = val.replace(/\W\D/g, "").substring(0, 15);
    //     this.form.elmts.EIN.setValue(cleaned);
    //   }
    // }

    EINValidation(val) {
      if (!!val) {
        let cleaned = val.replace(/\D/g, "").substring(0, 9);
        let b = "-";
        let position = 2;
        let output = cleaned.length > 2 ? [cleaned.slice(0, position), b, cleaned.slice(position)].join('') : cleaned;
        this.form.elmts.EIN.setValue(output);
       // this.props.app.ui.form("registrationForm").elmts.EIN.setValue(cleaned);
      }
    }
    //----------- Render User Edit.
    renderUserEdit() {
      const userLoginDetail = this.profileView;
      let userType = !!userLoginDetail ? userLoginDetail.userType : null;
      let currentRoute = this.props.r14.navigation.activeRouteName;
      return (
        <View style={[commonStyles.flex1]}>
          <View style={[
              styles.pageTitleHolder,
              commonStyles.flex1,
              commonStyles.justifyContentSpaceBetween,
              commonStyles.alignItemsCenter,
              commonStyles.flexDirectionRow,]}>
            <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.titleTextColor]}>{this.props.r14.navigation.currRoute._config.label}</Text>
          </View> 
          <ScrollView
            key={this.state.scrollCount}
            ref={this.scrollRef}
            onLayout={this.layoutChange}
            height={this.layoutChange}
            style={[styles.centerScrollable, styles.profileDetailSection, currentRoute === "pchProfileEditRoute" ? styles.profileEditSection: null]}>
            {1 === 2 ? (
              <View style={styles.personalDetails}>
                <Text
                  style={[
                    commonStyles.heading6,
                    commonStyles.fontWeight700,
                    commonFontStyles.workSans,
                    commonStyles.userInitial,
                    styles.personalDetailsText,
                  ]}>
                  EDIT PERSONAL DETAILS
                </Text>
              </View>
            ) : null}
            <View style={[commonStyles.flex1, styles.customFlexDirection]}>
              <View style={[styles.profilePicSection, styles.editProfilePicSection]}>
                <View style={[commonStyles.flex1, styles.profileInfoSection, styles.editProfileInfoSection]}>
                  <View style={[styles.userPic, commonStyles.marginBottom10, userType === "PCH_PROVIDER" ? styles.userPicProvider : null]}>
                    {/* <Image source={defaultProfilePic} style={styles.usericon} /> */}
                    <View style={[styles.userIconDiv, userType === USER_TYPE.PCH_PATIENT ? styles.patientUserIconDiv: null]}>
                      <Text
                        style={[
                          commonStyles.heading4,
                          commonStyles.fontWeight700,
                          commonFontStyles.workSans,
                          commonStyles.userInitial,
                          styles.userInitial,
                        ]}>
                        {userType === USER_TYPE.PCH_PROVIDER && !!userLoginDetail.profileImage ? (
                          <Image source={{uri: `data:image/png;base64,${userLoginDetail.profileImage}`}} style={[commonStyles.icon60, styles.profileIcon]} />
                        ) : (
                          CommonUtility.shortName(`${this.profileView.firstName} ${this.profileView.lastName}`)
                        )}
                      </Text>
                      {userType === USER_TYPE.PCH_PROVIDER && !!!userLoginDetail.parentUid && 1 === 2 ? (
                        <View style={[styles.editProfileIcon]}>
                          <Touchable style={[styles.editProfileIcon, styles.editProfileIconBtn,  customCommonStyles.customEditProfileIconBtn]} onPress={userType === USER_TYPE.PCH_PROVIDER ? this.openUploadImageModal : null}>
                          <View>
                            <Image source={editIcon} style={[styles.icon10]} />
                          </View>
                        </Touchable>
                          </View>
                      ) : null}
                    </View>
                  </View>
                  <View style={[styles.profileDetails]}>
                    <View>
                      <Text style={[commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading4]}>
                        {this.profileView.firstName} {this.profileView.lastName}
                      </Text>
                    </View>
                    {this.props.r14.navigation.currRoute._config.label ? null : 
                    userType === USER_TYPE.PCH_PROVIDER ? (
                      <View style={[commonStyles.textAlignCenter, userType === USER_TYPE.PCH_PROVIDER ? null : commonStyles.displayNone]}>
                        <Text
                          style={[
                            commonStyles.fontWeight700,
                            commonFontStyles.workSans,
                            commonStyles.heading6,
                            commonStyles.wordBreakAll,
                            styles.profileUppercase,
                          ]}>
                          {this.props.screen === "addUser"
                            ? null
                            : !!this.profileView.parentUid
                                ? "Practice Group: " + this.profileView.parentUserDetail.firstName + " " + this.profileView.parentUserDetail.lastName
                            : "EIN#: " + (!!this.profileView.EIN ? this.profileView.EIN : "")}
                        </Text>
                      </View>
                    ) : null}
                    <View style={commonStyles.marginBottom10}>
                      <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.wordBreakAll]}>{this.profileView.email}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={commonStyles.flex1}>
                <Form
                  name="profileEdit"
                  style={[commonStyles.flex1, commonStyles.flexDirectionColumn, commonStyles.flexGrow0, styles.profileEditForm]}
                  onSubmit={this.handleSubmit}
                  validateBeforeSubmit={true}
                  validators={CommonValidators.getFormValidator()}>
                  <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection]}>
                    <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                      <TextInputField
                        name="payerMobileNumber"
                        label={CommonString.MobilerNumberLabel}
                        style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.mobileNumber}
                        disabled={true}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                        readOnly={true}
                      />
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginLeft8, styles.inputFields]}>
                      {userType === USER_TYPE.PCH_PATIENT || this.props.screen === "addUser" || !!this.profileView.parentUid ? null : (
                        <TextInputField
                          name="EIN"
                          label={CommonString.EINLabel + "*"}  
                          required={CommonString.EnterEINRequired}
                          style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                          labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                          labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                          value={this.profileView.EIN}
                          validator="EINValidation"
                          onChangeText={this.EINValidation}
                          helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                        />
                      )}
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection]}>
                    <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                      <TextInputField
                        name="addressLine1"
                        label={CommonString.Address1Label}
                        style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.addressLine1}
                        validator="max75"
                        disabled={false}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      />
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginLeft8, styles.inputFields]}>
                      <TextInputField
                        name="addressLine2"
                        label={CommonString.Address2Label}
                        style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.addressLine2}
                        validator="max75"
                        disabled={false}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      />
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection]}>
                    <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                      <SelectMenuField
                        style={[commonStyles.heading6]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        name="country"
                        label={CommonString.CountryLabel}
                        items={this.countriesList}
                        onValueChange={(val) => this.onCountryChange(val, true)}
                        searchable
                        itemLoader={async ({ search }) => {
                          return await this.onSearchCountryStateCity(SEARCH_TYPE.COUNTRY, false, search);
                        }}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle]}
                        disabled={true}
                      />
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.inputFields, styles.customMarginLeft8, styles.maxWidth49]}>
                      <SelectMenuField
                        style={[commonStyles.heading6,]}
                        labelTextStyle={[commonStyles.labelText, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        name="states"
                        label={CommonString.StatesLabel}
                        items={this.statesList}
                        onValueChange={this.onStateChange}
                        searchable
                        itemLoader={async ({ search }) => {
                          return await this.onSearchCountryStateCity(SEARCH_TYPE.STATE, false, search);
                        }}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle]}
                      />
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection]}>
                    <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                      <SelectMenuField
                        style={[commonStyles.heading6,]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, ]}
                        name="city"
                        label={CommonString.CityLabel}
                        items={this.citiesList}
                        onValueChange={this.onCityName}
                        searchable
                        itemLoader={async ({ search }) => {
                          return await this.onSearchCountryStateCity(SEARCH_TYPE.CITY, false, search);
                        }}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      />
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.inputFields, styles.customMarginLeft8]}>
                      <TextInputField
                        name="postCode"
                        label={CommonString.ZipCodeLabel}
                        validator="min5Max10"
                        style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.postCode}
                        disabled={false}
                        onChangeText={this.trimExceededCharZipCode}
                        helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                      />
                    </View>
                  </View>

                  <View style={[commonStyles.marTAuto, commonStyles.flex1, styles.buttonWrapper, commonStyles.flexDirectionRow]}>
                    <View style={[styles.buttonHolder, styles.cancelBtnHolder]}>
                      <Button
                        key="cancel"
                        title="Cancel"
                        onPress={this.onCancel}
                        style={[commonStyles.blueBorderButton, commonStyles.fullWidth, styles.cancelBtn]}
                        titleTextStyle={[
                          commonStyles.blueBorderButtonColor,
                          commonStyles.fontWeight500,
                          commonFontStyles.workSans,
                          commonStyles.exSmallHeading,
                        ]}
                      />
                    </View>
                    <View
                      style={[styles.buttonHolder, styles.updatebtnHolder]}>
                      <SubmitButton
                        key="update"
                        title="Update"
                        style={[commonStyles.blueButton, styles.updateBtn, customCommonStyles.noBoxShadow,]}
                        titleTextStyle={[commonStyles.blueButtonColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.whiteColor]}
                      />
                    </View>
                  </View>
                </Form>
              </View>
            </View>
          </ScrollView>
        </View>
      );
    }

    //----------- Render User Profile View.
    renderUserProfileView() {
      const userLoginDetail = this.profileView;
      let userType = !!userLoginDetail ? userLoginDetail.userType : null;

      if (!userLoginDetail.country || userLoginDetail.country === "US") {
        userLoginDetail.country = DEFAULT_COUNTRY.label;
      }
      return (
        <View style={[commonStyles.flex1]}>
          <View
            style={[
              styles.pageTitleHolder,
              commonStyles.flex1,
              commonStyles.justifyContentSpaceBetween,
              commonStyles.alignItemsCenter,
              commonStyles.flexDirectionRow,
              // commonStyles.flexGrow0,
            ]}>
            <View>
              <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.transformCapitalize, commonStyles.titleTextColor]}>
                {this.props.r14.navigation.currRoute._config.label}
              </Text>               
            </View>
            <View style={styles.profileSubMenu}>
              {this.renderPopUpMenu()}
            </View>
          </View>
          <ScrollView
            key={this.state.scrollCount}
            ref={this.scrollRef}
            onLayout={this.layoutChange}
            height={this.layoutChange}
            style={[styles.centerScrollable, styles.profileDetailSection]}>
            {1 === 2 ? (
              <View style={styles.personalDetails}>
                <Text
                  style={[
                    commonStyles.heading6,
                    commonStyles.fontWeight700,
                    commonFontStyles.workSans,
                    commonStyles.userInitial,
                    styles.personalDetailsText,
                  ]}>
                  PERSONAL DETAILS
                </Text>
              </View>
            ) : null}
            <View style={[commonStyles.flex1, styles.customFlexDirection, commonStyles.alignItemsStart]}>
              <View style={[commonStyles.marginTop10, styles.profilePicSection]}>
                <View style={[commonStyles.flex1, styles.profileInfoSection]}>
                  <View style={[styles.userPic, userType === "PCH_PROVIDER" ? styles.userPicProvider : null]}>
                    <View style={[styles.userIconDiv, userType === USER_TYPE.PCH_PATIENT ? styles.patientUserIconDiv : null]}>
                      <Text
                        style={[
                          commonStyles.heading4,
                          commonStyles.fontWeight700,
                          commonFontStyles.workSans,
                          commonStyles.userInitial,
                          styles.userInitial,
                        ]}>
                        {userType === USER_TYPE.PCH_PROVIDER && !!userLoginDetail.profileImage ? (
                          <Image source={{uri: `data:image/png;base64,${userLoginDetail.profileImage}`}} style={[commonStyles.icon60, styles.profileIcon]} />
                        ) : (
                          CommonUtility.shortName(`${this.profileView.firstName} ${this.profileView.lastName}`)
                        )}
                      </Text>
                      {this.actionType === "view" && userType === USER_TYPE.PCH_PROVIDER && !!!userLoginDetail.parentUid && 1 === 2 ? (
                        <View style={[styles.editProfileIcon]}>
                          {/* <Touchable onPress={this.navigateToProfileEdit}> */}
                          <Touchable
                            style={[styles.editProfileIcon]}
                            onPress={userType === USER_TYPE.PCH_PROVIDER ? this.openUploadImageModal : null}>
                            <Image source={editIcon} style={styles.icon10} />
                          </Touchable>
                        </View>
                      ) : null}
                    </View>
                  </View>
                  <View style={[styles.profileDetails]}>
                    <View>
                      <Text style={[commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading4]}>
                        {this.profileView.firstName} {this.profileView.lastName}
                      </Text>
                    </View>
                    {userType === USER_TYPE.PCH_PROVIDER ? (
                      <View>
                        <Text
                          style={[
                            commonStyles.fontWeight700,
                            commonFontStyles.workSans,
                            commonStyles.smallHeading,
                            commonStyles.wordBreakAll,
                            styles.profileUppercase,
                          ]}>
                          {/* {this.props.screen==="addUser" || !!this.profileView.parentUid  ? null : "EIN#:"} {this.profileView.EIN} */}
                          {this.props.screen === "addUser"
                            ? null
                            : !!this.profileView.parentUid
                              ? "Practice Group: " + this.profileView.parentUserDetail.firstName + " " + this.profileView.parentUserDetail.lastName
                            : "EIN#: " + (!!this.profileView.EIN ? this.profileView.EIN : "")}
                        </Text>
                      </View>
                    ) : null}
                    <View style={commonStyles.marginBottom10}>
                      <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.wordBreakAll]}>{this.profileView.email}</Text>
                    </View>
                  </View>
                </View>
                {/*{this.actionType === "view" ? (
                  <View style={[commonStyles.marLAuto, commonStyles.dFlex]}>
                   <Touchable onPress={this.navigateToProfileEdit}>
                  <Image source={editIcon} style={commonStyles.icon16} />
                </Touchable> 
                  </View>
                ) : null}*/}
              </View>
              <View style={[styles.customFlexGrow0, styles.customProfileSection]}>
                <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection]}>
                  <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                    <View style={[styles.detailRow]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.labelDetails,
                        ]}>
                        {CommonString.MobilerNumberLabel}
                      </Text>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {this.profileView.mobileNumber}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginLeft8, styles.inputFields]}>
                    <View style={[styles.detailRow]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.labelDetails,
                        ]}>
                        {CommonString.Address1Label}
                      </Text>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {this.profileView.addressLine1}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection]}>
                  <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                    <View style={[styles.detailRow]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.labelDetails,
                        ]}>
                        {CommonString.Address2Label}
                      </Text>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {this.profileView.addressLine2}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginLeft8, styles.inputFields]}>
                    <View style={[styles.detailRow]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.labelDetails,
                        ]}>
                        {CommonString.CountryLabel}
                      </Text>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {this.profileView.country}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection]}>
                  <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                    <View style={[styles.detailRow]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.labelDetails,
                        ]}>
                        {CommonString.StatesLabel}
                      </Text>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        { !!this.profileView.state ? this.profileView.state : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginLeft8, styles.inputFields]}>
                    <View style={[styles.detailRow]}>
                      <View>
                        <Text
                          style={[
                            commonStyles.dBlock,
                            commonStyles.fontWeight500,
                            commonStyles.heading6,
                            commonFontStyles.workSans,
                            commonStyles.marginBottom5,
                            commonStyles.labelDetails,
                          ]}>
                          {CommonString.CityLabel}
                        </Text>
                      </View>
                      <View>
                        <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                          {this.profileView.town}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.flexWrap, styles.customFlexDirection]}>
                  <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                    <View style={[styles.detailRow, commonStyles.margin0]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.labelDetails,
                        ]}>
                        {CommonString.ZipCodeLabel}
                      </Text>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {this.profileView.postCode}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexBasis48, commonStyles.marginLeft8, styles.inputFields]}>
                    {/*<Touchable
                  style={[commonStyles.marginTopBottom10, commonStyles.dFlex, commonStyles.alignItemsCenter]}
                  onPress={() =>
                    userType === USER_TYPE.PCH_PROVIDER
                      ? this.props.app.nav.to("providerListRoute")
                      : this.props.app.nav.to("addInsuranceRoute", { mode: "add" })
                  }>
                  <View style={styles.plusIcon}>
                    <Image source={plusSolidIcon} style={commonStyles.icon12} />
                  </View>
                  <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading5, commonStyles.paddingLeftRight10]}>
                    {userType === USER_TYPE.PCH_PROVIDER ? "Add Provider" : "Add Insurance"}
                  </Text>
                </Touchable> */}
                  </View>
                </View>
              </View>
            </View>
            {/* <View style={styles.changePasswordButtonWrap}>
              <Button
                style={[commonStyles.blueBorderButton, commonStyles.fullWidth, styles.changePasswordBtn]}
                onPress={this.openChangePasswordModal}
                title="Change Password"
                titleTextStyle={[commonStyles.blueBorderButtonFontStyle, commonFontStyles.workSans]}></Button>
            </View> */}
          </ScrollView>
        </View>
      );
    }

    render() {
      if (this.profileView.mobileNumber) {
        let splitPhoneNumber = this.profileView.mobileNumber.split(" ");
        if (splitPhoneNumber[1]) {
          let match = splitPhoneNumber[1].match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            let formattedString = "";
            match[1] = splitPhoneNumber[0] + " ";
            formattedString = [match[1], "(", match[2], ") ", match[3], "-", match[4]].join("");
            this.profileView.mobileNumber = "";
            this.profileView.mobileNumber = formattedString;
          }
        } else {
          let match = splitPhoneNumber[0].match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            let formattedString = "";
            match[1] = "+1 ";
            formattedString = [match[1], "(", match[2], ") ", match[3], "-", match[4]].join("");
            this.profileView.mobileNumber = "";
            this.profileView.mobileNumber = formattedString;
          }
        }
      }
      const { isDeletProfileImageModalVisible, isAddressUpdation, addressConfirmationMessage } = this.state;
      return (
        <>
          <View style={[styles.container, styles.profileContainer]}>
            {this.actionType === "view" ? this.renderUserProfileView() : null}
            {this.actionType === "edit" ? this.renderUserEdit() : null}
          </View>
          <PopupModalComponent
            visibility={isDeletProfileImageModalVisible || !!isAddressUpdation}
            bodyWrapper={[commonStyles.smallModalBodyWrapper]}
            messageType={!!isDeletProfileImageModalVisible ? MESSAGE_TYPE.delete : MESSAGE_TYPE.info}
            headingIcon={true}
            heading={!!isDeletProfileImageModalVisible ? "Delete Profile Image" : isAddressUpdation === "updateAddress" ? "Update Suggested Address" : "Update Address"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={!!isDeletProfileImageModalVisible ? "Are you sure you want to delete profile image?" : addressConfirmationMessage}
            okLabel={!!isDeletProfileImageModalVisible || (isAddressUpdation === "updateAddress") ? "No" : "Ok"}
            handleOk={() => !!isDeletProfileImageModalVisible ? this.closeDeleteModalPopup() : this.onUpdateAddress(false)}
            closeLabel={!!isDeletProfileImageModalVisible || (isAddressUpdation === "updateAddress") ? "Yes" : ""}
            handleClose={isAddressUpdation === "alertMessage" ? null : () => !!isDeletProfileImageModalVisible ? this.removeProfileImg() : this.onUpdateAddress(true)}
          />
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  profileViewScreen: {
    // flex: 0,
    // alignItems: "flex-start",
    padding: 15,
    margin: 0,
    height: "100%",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          // backgroundColor: "#fff",
          // alignItems: "center",
          margin: 0,
        };
    },
  },
  profileDetailSection: {
    backgroundColor: "#fff",
    padding: 16,
    marginRight: 16,
    marginLeft: 16,
    marginBottom: 20,
    height: "100%",
    // height: "calc(100vh - 160px)",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#00000029",
    screen: ({ width }) => {
      if (width >= 991){
        return {
          flex:1,
          flexGrow: 1,
        };
      }
      if (width <= 991){
      return {
        paddingTop: 0,
        paddingRight: 16,
        paddingBottom: 30,
        paddingLeft: 16,
        marginRight: 0,
        marginLeft: 0,
        minHeight: 800,
        top: 0,
        marginBottom: 0,
        };
      }
    },
  },
  profileEditSection: {
    screen: ({ width }) => {
      if (width <= 991)
      return {
        minHeight: 900,
      };
    },
  },
  BorderTop: {
    borderTop: "1px solid #D6D6D6",
  },
  camaricon: {
    width: 44,
    height: 44,
    display: "block",
    backgroundSize: "contain",
  },
  usericon: {
    width: 70,
    height: 70,
    display: "block",
    backgroundSize: "contain",
  },
  black: {
    color: "#333",
  },
  centerScrollable: {
    top: 70,
    bottom: 70,
    // overflowY: "auto",
    paddingRight: 15,
  },
  bodyWrapper: {
    width: 300,
    minHeight: 360,
    borderRadius: 6,
    boxShadow: "0px 0px 3px -1px #000",
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: 300,
          minHeight: 400,
        };
      }
    },
  },
  uploadImageModalBodyWrapper: {
    height: 400,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: "0px 0px 3px -1px #000",
    position: "relative",
  },
  modalOuterWrapper: {
    width: "auto",
    // backgroundColor: "#0000005c",
  },
  payNowWrapper: {
    width: "100%",
  },
  passwordField: {
    width: "100%",
  },
  passwordFieldContainer: {
    width: "100%",
    position: "relative",
  },
  closeBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 9999,
    // display: "none",
  },
  changePasswordCloseBtn: {
    position: "absolute",
    right: 7,
    zIndex: 9999,
    top: 5,
    // display: "none",
  },
  customHelperTextStyle: {
    // position: "static",
    left: 0,
  },
  changePasswordButtonWrap: {
    marginTop: 10,
    marginBottom: 40,
  },
  changePasswordBtn: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: "auto",
          marginLeft: "auto",
        };
      }
    },
  },
  changePhoto: {
    paddingRight: 16,
    paddingLeft: 16,
    marginTop: 25,
    marginBottom: 9,
  },
  profileEditForm: {
    paddingBottom: 10,
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: "auto",
          paddingLeft: 0,
          paddingRight: 0,
        };
      }
      if (width <= 992) {
        return {
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 75,
        };
      }
    },
  },
  synced: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    paddingTop: 2,
    paddingRight: 8,
    paddingBottom: 2,
    paddingLeft: 8,
    display: "flex",
  },
  inputFields: {
    screen: ({ width }) => {
      if (width >= 991 && width <= 1083) {
        return {
          flexBasis: "48%",
        };
      }
      if (width <= 991) {
        return {
          flexBasis: "100%",
          margin: 0,
          width: "100%",
        };
      }
    },
  },
  buttonWrapper: {
    justifyContent: "flex-end",
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          width: "100%",
        };
      }
    },
  },
  buttonHolder: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          flexBasis: "48%",
          // flexGrow: 1,
        };
      }
    },
  },
  updatebtnHolder: {
    minWidth: 104,
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  userDetailsVla: {
    minHeight: 18,
  },
  profileUppercase: {
    // textTransform: "uppercase",
  },
  patientUserIconDiv: {
    width: 50,
    minWidth: 50,
    height: 50,
  },
  userIconDiv: {
    width: 60,
    minWidth: 60,
    height: 60,
    borderTopLeftRadius: 60,
    borderTopRightRadius: 60,
    borderBottomLeftRadius: 60,
    borderBottomRightRadius: 60,
    backgroundColor: "#e7e7e7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    // screen: ({ width }) => {
    //   if (width <= 991 )
    //   return {
    //     width: 60,
    //     minWidth: 60,
    //     height: 60,
    //     };
    // },
  },
  pageTitleHolder: {
    // backgroundColor: "#fff",
    zIndex: 1,
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    minHeight: 54,
    maxHeight: 54,
  },
  detailRow: {
    borderBottomWidth: 1,
    borderBottomColor: "#dadada",
    borderStyle: "solid",
    paddingBottom: 5,
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 10,
        };
      }
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customMarginRight8: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  customMarginLeft8: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  profileSubMenu: {
    marginRight: -10,
  },
  editProfileIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: 24,
    width: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f4f4f4",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
    boxShadow: "0px 1px 3px #000",
  },
  editProfileIconBtn: {
    backgroundColor: "#f1f1f1",
  },
  profilePicSection: {
    minWidth: "25%",
    maxWidth: "25%",
    wordBreak: "break-all",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: "100%",
          maxWidth: "100%",
        };
      }
    },
  },
  editProfilePicSection: {
    marginTop: 15,
  },
  personalDetails: {
    marginTop: -16,
    marginRight: -16,
    marginBottom: -16,
    marginLeft: 13,
    paddingTop: 13,
    paddingRight: 16,
    paddingBottom: 13,
    paddingLeft: 16,
    borderBottom: "1px solid #DADADA",
  },
  personalDetailsText: {
    color: "#1277D9",
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  profileInfoSection: {
    marginRight: 16,
    flexDirection: "column",
    alignItems: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: "flex-start",
          marginRight: 0,
          marginBottom: 20,
          flexDirection: "row",
        };
      }
    },
  },
  editProfileInfoSection: {
    paddingLeft: 16,
    paddingRight: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        };
      }
    },
  },
  userPic: {
    marginBottom: 10,
    // flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexGrow: 0,
          justifyContent: "flex-start",
          marginBottom: 0,
          marginRight: 16,
        };
      }
    },
  },
  userPicProvider: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 4,
        };
      }
    },
  },
  profileDetails: {
    textAlign: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          textAlign: "left",
        };
      }
    },
  },
  updateBtn: {
    // height: 38,
    width: "100%",
  },
  profileImageShow: {
    height: 250,
    width: 250,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
    // backgroundColor: "#e0e0e0",
    // overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 16,
        };
      }
    },
  },
  viewProfileImg: {
    height: 250,
    width: 250,
    display: "block",
    backgroundSize: "contain",
    backgroundPosition: "center center",
  },
  contentInner: {
    position: "relative",
    paddingTop: 16,
    paddingBottom: 16,
    maxHeight: "80%",
    borderRadius: 5,
  },
  modalContent: {
    position: "relative",
  },
  modalPopupOkBtn: {
    height: 38,
    minWidth: 90,
    // marginLeft: 16,
    // backgroundColor: "#38c2ef",
  },
  modalPopupCancelBtn: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#317189",
  },
  cancelBtnTextStyle: {
    color: "#317189",
  },
  okBtnTextStyle: {
    color: "#343434",
  },
  buttons: {
    height: 38,
    padding: 0,
    minHeight: 38,
    width: 100,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  profileIcon: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#d6d6d6",
  },
  // editIconImg: {
  //   backgroundSize: "cover",
  // },
  icon10: {
    height: 10,
    minWidth: 10,
    width: 10,
    display: "block",
    backgroundSize: "contain",
  },
  inputHolder: {
    width: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginBottom: 10,
        };
      }
    },
  },
  pwdTextInput: {
    width: "100%",
    // minHeight: 52,
    // height: 52, 
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 10,
          paddingBottom: 10,
        };
      }
    },
  },
  btnsHolder: {
    width: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
        };
      }
    },
  },
  removeProfilePic: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 16,
    zIndex: 9999,
  },
   btnWrapper: {
    flex: 1,
    justifyContent: "flex-end",
    marginTop: 15,
    marginBottom: 15,
    alignItems: "flex-end",
    position: "relative",
    zIndex: 99999,
    screen: ({ width }) => {
      if (width <= 991)
      return {
          justifyContent: "center",
          alignItems: "center",
        };
     },
  },
  headerStyle: {
    maxHeight: 19,
  },
  btnTexClr: {
    color: "#317189"
  },
  changePasswordForm: {},
  cancelBtnHolder: {
    minWidth: 104,
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  cancelBtn: {
    minWidth: 104,
  },
  longErrorMsg: {
    width: "117%",
  },
  customProfileSection: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          flex: 1,
        };
      }
      if (width <= 992) {
        return {
          display: "block",
          width: "100%",
        };
      }
    },
  },
  customFlexGrow0: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          flexGrow: 1,
        };
      }
    },
  }, 
  profileContainer: {
    flex: 1,
  },
  customTitleMargin: {
    marginBottom: 8,
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          marginBottom: 18,
        };
      }
    },
  },
  width190:{
    width: 190
  },
  maxWidth49: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          maxWidth: "49%",
        }
      }
    }
  }
});

