import React from "react";
import R14, {
  StyleSheet,
  Button,
  Text,
  View,
  TextInputField,
  Form,
  SubmitButton,
  SelectMenuField,
  PopUpMenu,
  PopUpMenuItem,
  Platform
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import CommonValidators from "../utils/CommonValidators";
import { USER_TYPE, SEARCH_TYPE, DEFAULT_COUNTRY } from "../constant/app.data.constant";
import CustomPopupComponent from "../components/CustomPopupComponent";
import CommonUtility from "../utils/CommonUtility";
import GoogleLogin from "../components/SocialLogin/GoogleLogin";
import AppleLogin from "../components/SocialLogin/AppleLogin";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";

export default R14.connect(
  class ProfileViewScreen extends React.Component {
    constructor(props) {
      super(props);
      this.profileView = this.props.profile;
      this.validateBUDetails();
      this.scrollRef = React.createRef();
      this.state = {
        oldCountry: null,
        oldState: null,
        menuItems: this.props.profileActionType === "edit" ? [
          { label: "Link Payer", value: "linkPayer" },
          { label: "Change Password", value: "changePassword" },
        ] : [
          { label: "Edit Profile", value: "editProfile" },
          { label: "Link Payer", value: "linkPayer" },
          { label: "Change Password", value: "changePassword" },
        ],
        userBUDetails: []
      };
      // User Detail Edit
      this.form = null;
      this.actionType = this.props.profileActionType;
      this.countriesList = [];
      this.statesList = [];
      this.citiesList = [];
    }

    validateBUDetails() {
      if (!!this.props.userBUDetails) { //&& this.props.userBUDetails.length < 2
        this.profileView ={...this.profileView,  ...this.props.userBUDetails[0] };
        const userBUDetails = this.props.userBUDetails.map((item)=>{
          if(!!item.billerName)
            return { label: `${item.billerName} (${item.buName})`, value: item.uid }
          else
            return { label: `NA`, value: item.uid }

        });
        this.setState({userBUDetails});
       }
    }

    onChangeBUDetail = async (val) => {
      const { label } = val;
      analyticsLogs(CommonString.EDIT_PROFILE, `${CommonString.BILLER_SELECTED}`, this.props.app);
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.billerBU.setValue(null);
        return false;
      }
      else {
        this.form.elmts.billerBU.setValue(val);
        let buDetail = this.props.userBUDetails.find(x=> x.uid === val.value);
        this.profileView ={...this.profileView,  ...buDetail};
        this.form = this.props.app.ui.form("profileEdit");
        this.form.elmts.country.setValue(this.countriesList[0]);
        this.form.elmts.country.setItems(this.countriesList);

        const countryData = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.COUNTRY, this.profileView.country, "", "", 1);
        this.countriesList = countryData.map((item) => ({
          label: item.country_name,
          value: item.uid,
        }));
        const stateData = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, !!this.profileView.state ? this.profileView.state : "", !!this.profileView.state ? "" : countryData[0].uid, "", !!this.profileView.state ? 1 : 10);
        this.statesList = stateData.map((item) => ({
          label: item.state_name,
          value: item.uid,
        }));
        if (!!this.profileView.state) {
          const cityData = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.CITY, this.profileView.town, "", "", 1);
          this.citiesList = cityData.map((item) => ({
            label: item.city_name,
            value: item.uid,
          }));
        }

        this.form.elmts.country.setValue(this.countriesList[0]);
        this.form.elmts.country.setItems(this.countriesList);
        this.form.elmts.states.setValue(!!this.profileView.state ? this.statesList[0] : null);
        this.form.elmts.states.setItems(this.statesList);
        this.form.elmts.city.setValue(!!this.profileView.town ? this.citiesList[0] : null);
        this.form.elmts.city.setItems(this.citiesList);
        this.form.elmts.addressLine1.setValue(this.profileView.addressLine1)
        this.form.elmts.addressLine2.setValue(this.profileView.addressLine2)
        this.form.elmts.postCode.setValue(this.profileView.postCode)
        this.form.elmts.firstName.setValue(this.profileView.firstName)
        this.form.elmts.lastName.setValue(this.profileView.lastName)
      }
    };

    onChangeViewBUDetail = async (val) => {
      const { label } = val;
      analyticsLogs(CommonString.VIEW_PROFILE, `${CommonString.BILLER_SELECTED}`, this.props.app);
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.billerBU.setValue(null);
        return false;
      }
      else {
        this.form.elmts.billerBU.setValue(val);
        let buDetail = this.props.userBUDetails.find(x=> x.uid === val.value);
        this.profileView ={...this.profileView,  ...buDetail};
        this.setState({oldCountry: null})
      }
    };


    componentDidMount = async () => {
      try {

        let bus = this.props.userBUDetails.map((item) => {
          if (!!item.billerName)
            return { label: `${item.billerName} (${item.buName})`, value: item.uid }
          else
            return { label: `NA`, value: item.uid }
        });
        // If User Edit is enabled.
        if (this.actionType === "edit") {
          this.form = this.props.app.ui.form("profileEdit");
          this.form.elmts.billerBU.setValue(bus[0]);
          this.form.elmts.billerBU.setItems(bus);

          if (!this.profileView.country) {
            let mobile_code = this.profileView.mobileNumber.split(' ')[0];
            if (mobile_code.replace('+', '') === '1') {
              this.profileView.country = DEFAULT_COUNTRY.label;
            }
            else {
              let result = await this.props.app.dm.miscellaneousDomain.countryCodes(`eq:${mobile_code}`, 'country_name uid');
              if (!!result && result.length > 0)
                this.profileView.country = result[0].country_name;
              else
                this.profileView.country = DEFAULT_COUNTRY.label;
            }
          }
          else if (this.profileView.country === "US") this.profileView.country = DEFAULT_COUNTRY.label;
          if (this.profileView.country !== undefined && this.profileView.country !== "") {
            const countryData = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.COUNTRY, this.profileView.country, "", "", 1);
            this.countriesList = countryData.map((item) => ({
              label: item.country_name,
              value: item.uid,
            }));
            const stateData = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, !!this.profileView.state ? this.profileView.state : "", !!this.profileView.state ? "" : countryData[0].uid, "", !!this.profileView.state ? 1 : 10);
            this.statesList = stateData.map((item) => ({
              label: item.state_name,
              value: item.uid,
            }));
            if (!!this.profileView.state) {
              const cityData = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.CITY, this.profileView.town, "", "", 1);
              this.citiesList = cityData.map((item) => ({
                label: item.city_name,
                value: item.uid,
              }));
            }
            this.form.elmts.country.setValue(this.countriesList[0]);
            this.form.elmts.country.setItems(this.countriesList);
            this.form.elmts.states.setValue(!!this.profileView.state ? this.statesList[0] : null);
            this.form.elmts.states.setItems(this.statesList);
            this.form.elmts.city.setValue(!!this.profileView.town ? this.citiesList[0] : null);
            this.form.elmts.city.setItems(this.citiesList);
          } else {
            this.countriesList = await this.onSearchCountryStateCity(SEARCH_TYPE.COUNTRY, true, "");
          }
        } else if (this.actionType === "view") {
         this.form = this.props.app.ui.form("profileView");
          this.form.elmts.billerBU.setValue(bus[0]);
          this.form.elmts.billerBU.setItems(bus);
          if (!this.profileView.country) {
            let mobile_code = this.profileView.mobileNumber.split(' ')[0];
            if (mobile_code.replace('+', '') === '1') {
              this.profileView.country = DEFAULT_COUNTRY.label;
            }
            else {
              let result = await this.props.app.dm.miscellaneousDomain.countryCodes(mobile_code, 'country_name uid');
              if (!!result && result.length > 0)
                this.profileView.country = result[0].country_name;
              else
                this.profileView.country = DEFAULT_COUNTRY.label;
            }
          }
          else if (this.profileView.country === "US") {
            this.profileView.country = DEFAULT_COUNTRY.label;
          }
        }
      } catch (error) {

      }
    }

    navigateToProfileEdit = () => {
      analyticsLogs(CommonString.VIEW_PROFILE, `${CommonString.SELECTED_EDIT_PROFILE}`, this.props.app);
      this.props.app.nav.to("profileEditRoute");
    };

    onCancel = () => {
      analyticsLogs(CommonString.EDIT_PROFILE, `${CommonString.CLICKED_ON_CANCEL_BUTTON}`, this.props.app);
      this.props.app.nav.to("profileViewRoute");
    };

    //--------Country Dropdown.
    onCountryChange = async (val, IsReturn) => {
      if (!!IsReturn) {
        this.form.elmts.country.setValue(this.countriesList[0]);
        return false;
      }
      let { label } = val;
      const { oldCountry } = this.state;
      if (!!oldCountry && oldCountry.label === val.label) { }
      else {
        this.form.elmts.states.setValue(null);
        this.form.elmts.city.setValue(null);
      }
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.statesList = [];
        this.citiesList = [];
        this.form.elmts.country.setValue(this.countriesList[0]);
        this.form.elmts.city.setValue(null);
        this.form.elmts.states.setValue(null);
        this.form.elmts.states.setItems(this.statesList);
        this.form.elmts.city.setItems(this.citiesList);
        return false;
      }
      this.form.elmts.country.setValue(val);
      this.setState({
        oldCountry: val
      });
      this.statesList = await this.onSearchCountryStateCity(SEARCH_TYPE.STATE, true);
    };

    //--------State Dropdown.
    onStateChange = async (val) => {
      let { label } = val;
      const { oldState } = this.state;
      if (!!oldState && oldState.label === val.label) { }
      else
        this.form.elmts.city.setValue(null);
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.citiesList = [];
        this.form.elmts.city.setValue(null);
        this.form.elmts.states.setValue(null);
        this.form.elmts.city.setItems(this.citiesList);
        return false;
      }
      this.form.elmts.states.setValue(val);
      this.setState({
        oldState: val
      });
      this.citiesList = await this.onSearchCountryStateCity(SEARCH_TYPE.CITY, true);
    };

    //--------City Dropdown.
    onCityName = (val) => {
      const { label } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.form.elmts.city.setValue(null);

        return false;
      }
      this.form.elmts.city.setValue(val);
    };

    onSearchCountryStateCity = async (type, isBlankAllow = false, search = "") => {
      const { values, elmts } = this.props.app.ui.form("profileEdit");
      let items = [];
      if (search !== "" || !!isBlankAllow) {
        if (type === SEARCH_TYPE.STATE) {
          let country_name = !!values && !!values.country && !!values.country.value ? values.country.value :"";
          items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, search, country_name);
          items = items.map((element) => ({
            label: element.state_name,
            value: element.uid,
          }));
          items = [...new Map(items.map((item) => [item["label"], item])).values()];
          elmts.states.setItems(items);
          return items;
        } else if (type === SEARCH_TYPE.CITY) {
          let state_name = !!values && !!values.states && !!values.states.value ? values.states.value :"";
          if(!state_name) return [];
          items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.CITY, search, "", state_name);
          items = items.map((element) => ({
            label: element.city_name,
            value: element.uid,
          }));
          items = [...new Map(items.map((item) => [item["label"], item])).values()]
            .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
          elmts.city.setItems(!!values.states && 'label' in values.states ? items : null);
          return !!values.states && 'label' in values.states ? items : [];
        } else {
          items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.COUNTRY, search);
          items = items.map((element) => ({
            label: element.country_name,
            value: element.uid,
          }));
          items = [...new Map(items.map((item) => [item["label"], item])).values()]
          elmts.country.setItems(items);
          return items;
        }
      } else {
        return type === SEARCH_TYPE.STATE ? this.statesList : type === SEARCH_TYPE.CITY ? this.citiesList : this.countriesList;
      }
    };

    //--------Update - User Edit Start.
    handleSubmit = async (form) => {
      this.props.app.ui.progressIndicator.show();
      try {
        let res = await this.saveUserDetails(form);
        if (res) {
              analyticsLogs(CommonString.EDIT_PROFILE, `${CommonString.PROFILE_UPDATED}`, this.props.app);
              this.props.app.dm.commonDomain.showToastr("success", CommonString.UserUpdated);
              this.props.app.ui.progressIndicator.hide({ timeout: 750 });
              this.props.app.nav.to("profileViewRoute");
            } else {
              this.props.app.ui.progressIndicator.hide({ timeout: 750 });
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
        }
      catch (error) {
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
      return false;
    };

    saveUserDetails = async (form) => {
      const updateUserReqBody = {
        uid: this.profileView.uid,
        pid: this.profileView.pid,
        firstName: form.values.firstName ? form.values.firstName : this.profileView.firstName,
        lastName: form.values.lastName ? form.values.lastName : this.profileView.lastName,
        addressLine1: form.values.addressLine1 !== null && form.values.addressLine1 !== undefined ? form.values.addressLine1 : "",
        addressLine2: form.values.addressLine2 !== null && form.values.addressLine2 !== undefined ? form.values.addressLine2 : "",
        town: form.values.city !== null && form.values.city !== undefined ? form.values.city.label : "",
        state: form.values.states !== null && form.values.states !== undefined ? form.values.states.label : "",
        country: form.values.country !== null && form.values.country !== undefined ? form.values.country.label : "",
        postCode: form.values.postCode !== null && form.values.postCode !== undefined ? form.values.postCode : "",
      };
      let res = await this.props.app.dm.userRegistration.UpdatePIDDetails(updateUserReqBody);
      if (res) {
        this.profileView = { ...res };
        return true;
      } else {
        return false;
      }
    };

    trimExceededCharZipCode = (val) => {
      CommonUtility.zipCodeFormater(this.form, "postCode", val );
    };

    //--------Update - User Edit End.
    mobileNumberText(val) {
      let mobNumText = val;
      if (!!val) {
        mobNumText = mobNumText.replace(/[^\d.]/g, "").substring(0, 15);
        this.form.elmts.payerMobileNumber.setValue(mobNumText);
      }
      let isValid = /^\+?(\d*[1-9]\d*\.?|\d*\.\d*[0-9]\d*)$/gi.test(mobNumText);

      if (isValid) {
        this.form.elmts.payerMobileNumber.setValue(mobNumText);
        this.form.elmts.payerMobileNumber.setError(null);
      } else if (mobNumText === "") {
        this.form.elmts.payerMobileNumber.setError(null);
      } else {
        this.form.elmts.payerMobileNumber.setError("Enter the valid mobile number");
      }
    }

    onClickPopupMenu = async () => {
      if (!!this.props.app.dm.userSession.state.leftMenuOverlay && this.props.app.dm.notificationDomain.state.isMobileDevice)
        this.props.app.dm.userSession.setState({
          leftMenuToggle: !this.props.app.dm.userSession.state.leftMenuToggle,
          leftMenuOverlay: !this.props.app.dm.userSession.state.leftMenuOverlay,
        });
    }

    handlePopupClick = async (type) => {
      if (type === 'editProfile') {
        analyticsLogs(this.actionType === "edit" ? CommonString.EDIT_PROFILE : CommonString.VIEW_PROFILE, `${CommonString.SELECTED_EDIT_PROFILE}`, this.props.app);
        this.props.app.nav.to("profileEditRoute");
      } else if (type === 'linkPayer') {
        analyticsLogs(this.actionType === "edit" ? CommonString.EDIT_PROFILE : CommonString.VIEW_PROFILE, `${CommonString.SELECTED_LINK_PAYER}`, this.props.app);
        this.props.app.nav.to("linkPayerRoute");
      } else {
        let res = await this.props.app.dm.userSession.isSessionStringValid(true);
        if (!!res.tokenIsValid) {
          analyticsLogs(this.actionType === "edit" ? CommonString.EDIT_PROFILE : CommonString.VIEW_PROFILE, `${CommonString.SELECTED_CHANGE_PASSWORD}`, this.props.app);
          this.props.app.nav.to("changePassword");
        }
        else {
          this.props.app.dm.dashboard.setState({
            sessionInActive: true
          })
        }
        return false;
      }
    }

    renderPopUpMenu() {
      let items = [];
      if (this.actionType === "view")
        items.push(<PopUpMenuItem style={[commonStyles.fullWidth, commonStyles.height32]} onPress={() => this.handlePopupClick('editProfile')} label="Edit Profile              " key="Edit Profile" value="Edit Profile" />);
      items.push(<PopUpMenuItem style={[commonStyles.fullWidth, commonStyles.height32]} onPress={() => this.handlePopupClick('changePassword')} label="Change Password" key="Change Password" value="Change Password" />);
      items.push(<PopUpMenuItem style={[commonStyles.fullWidth, commonStyles.height32]} onPress={() => this.handlePopupClick('linkPayer')} label="Link Payer" key="Link Payer" value="Link Payer" />);
      return (
        <PopUpMenu
          controlIcon="dotsVertical"
          controlIconSize="small"
          key="trippleDot"
          direction="downLeft"
          style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.flex1, styles.width190]}>
          {items}
        </PopUpMenu>
      );
    }

    renderPageTitleSection() {
      return (
        <View style={[styles.pageTitleHolder, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, commonStyles.alignItemsCenter]}>
          <View>
            <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, styles.pageTitle]}>
              {this.props.r14.navigation.currRoute._config.label}{" "}
            </Text>
          </View>
          <View style={commonStyles.profileSubMenu}>
            {Platform.OS === "ios" ? (
              <CustomPopupComponent menuItem={this.state.menuItems} handleClick={this.handlePopupClick} onClickPopupMenu={this.onClickPopupMenu} leftMenuOverlay={this.props.app.dm.userSession.state.leftMenuOverlay} />
            ) : (
              this.renderPopUpMenu()
            )}
          </View>
        </View>
      );
    }

    renderUserIconSection() {
      return (
        <View style={[styles.userProfileDetails]}>
          <View style={[commonStyles.flex1, styles.userIconDivOuter]}>
            <View style={styles.userIconDiv}>
              <Text
                style={[commonStyles.heading4, commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.userInitial, styles.userInitial]}>
                {CommonUtility.shortName(`${this.profileView.firstName} ${this.profileView.lastName}`)}
              </Text>
              {/* {this.actionType === "view" ? (
              <View style={[styles.editProfileIcon]}>
                  <Touchable onPress={this.navigateToProfileEdit} style={[styles.editProfileIcon, styles.editProfileIconBtn,customCommonStyles.customEditProfileIconBtn]}>
                  <View>
                      <Image source={editIcon} resizeMode="contain" style={commonStyles.icon10} />
                  </View>
                </Touchable>
              </View>
            ) : null} */}
            </View>
            <View style={[styles.userDetailsWrap]}>
              <View style={[styles.userDetails]}>
                {this.profileView.email ? (
                  <View>
                    <Text
                      style={[
                        commonStyles.fontWeight500,
                        commonFontStyles.workSans,
                        commonStyles.heading6,
                        commonStyles.wordBreakAll
                      ]}>
                        {this.profileView.email}
                    </Text>
                  </View>
                ) : null}
                {this.profileView.mobileNumber ? (
                  <View>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.wordBreakAll]}>{this.profileView.mobileNumber}</Text>
                  </View>
                ) : null}
                {this.profileView.pid ? (
                  <View style={commonStyles.marginBottom10}>
                    <Text style={[commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading6, styles.profileUppercase]}>
                      PID: {this.profileView.pid}
                    </Text>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
          {this.actionType === "view" && 1 === 2 ? (
            <View style={[commonStyles.flex1, commonStyles.paddingLeftRight10, styles.socialMediaCustomMargin, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
              <View style={[styles.socialMediaOuterWrap, commonStyles.marginRight8, commonStyles.alignItemsCenter]}>
                {!this.profileView || this.profileView.googleProfileID === "" || this.profileView.googleProfileID === null ? (
                  <GoogleLogin buttonType={"Profile"} userType={USER_TYPE.XBP} profile={this.profileView}></GoogleLogin>
                ) : (
                  <GoogleLogin buttonType={"ProfileSynced"} userType={USER_TYPE.XBP} profile={this.profileView}></GoogleLogin>
                )}
              </View>
              <View style={[styles.socialMediaOuterWrap, commonStyles.marginLeft8, commonStyles.alignItemsCenter]}>
                {!this.profileView || this.profileView.appleProfileID === "" || this.profileView.appleProfileID === null ? (
                  <AppleLogin buttonType={"Profile"} userType={USER_TYPE.XBP} profile={this.profileView}></AppleLogin>
                ) : (
                  <AppleLogin buttonType={"ProfileSynced"} userType={USER_TYPE.XBP} profile={this.profileView}></AppleLogin>
                )}
              </View>
              {/* <View style={[styles.socialMediaOuterWrap]}>
                {!this.profileView || this.profileView.amazonProfileID === "" || this.profileView.amazonProfileID === null ? (
                  <AmazonLogin buttonType={"Profile"} userType={USER_TYPE.XBP}></AmazonLogin>
                ) : (
                  <AmazonLogin buttonType={"ProfileSynced"} userType={USER_TYPE.XBP}></AmazonLogin>
                )}
              </View> */}
            </View>
          ) : null}
        </View>
      );
    }

    //----------- Render User Edit.
    renderUserEdit() {
      const userBUDetails = this.props.userBUDetails.map((item)=>{
        if(!!item.billerName)
          return { label: `${item.billerName} (${item.buName})`, value: item.uid }
        else
          return { label: `NA`, value: item.uid }
      });
      return (
        <>
          {this.renderPageTitleSection()}
          <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.profileDetailSection, customCommonStyles.customXbpProfileDetailSection]}>
              <View style={[styles.userProfileDetailsLeft]}>{this.renderUserIconSection()}</View>
              <View style={[styles.userProfileDetailsRight, styles.maxWidth75]}>
                <Form
                  name="profileEdit"
                  style={[commonStyles.flex1, styles.profileEditForm]}
                  onSubmit={this.handleSubmit}
                  validateBeforeSubmit={true}
                  validators={CommonValidators.getFormValidator()}>
                  <View style={[styles.customFlecDirection, styles.inputFields]}>
                    <View style={[commonStyles.flexBasis48, styles.maxWidth48]}>
                      <SelectMenuField
                        name="billerBU"
                        label={CommonString.BillerBULabel}
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        items={userBUDetails}
                        onValueChange={this.onChangeBUDetail}
                        searchable
                        disabled={userBUDetails.length < 2}
                        readOnly={userBUDetails.length < 2}
                        // itemLoader={async ({ search }) => {
                        //   return await this.onSearchCountryStateCity(SEARCH_TYPE.CITY, false, search);
                        // }}
                      />
                    </View>
                    <View style={[commonStyles.flexBasis48, styles.maxWidth48]}>
                      <TextInputField
                        name="firstName"
                        label={CommonString.FirstNameLabel + "*"}
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.firstName}
                        disabled={true}
                        readOnly={true}
                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      />
                    </View>
                  </View>
                  <View style={[styles.customFlecDirection, styles.inputFields]}>
                    <View style={[commonStyles.flexBasis48,]}>
                      <TextInputField
                        name="lastName"
                        label={CommonString.LastNameLabel}
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.lastName}
                        disabled={true}
                        readOnly={true}
                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      />
                    </View>
                    <View style={[commonStyles.flexBasis48]}>
                      <TextInputField
                        name="addressLine1"
                        label={CommonString.Address1Label}
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.addressLine1}
                        validator="max75"
                        disabled={false}
                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      />
                    </View>
                  </View>
                  <View style={[styles.customFlecDirection, styles.inputFields]}>
                    <View style={[commonStyles.flexBasis48]}>
                      <TextInputField
                        name="addressLine2"
                        label={CommonString.Address2Label}
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.addressLine2}
                        validator="max75"
                        disabled={false}
                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      />
                    </View>
                    <View style={[commonStyles.flexBasis48]}>
                      <SelectMenuField
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        name="country"
                        label={CommonString.CountryLabel}
                        items={this.countriesList}
                        onValueChange={(val) => this.onCountryChange(val, false)}
                        searchable
                        // disabled={true}
                        itemLoader={async ({ search }) => {
                          return await this.onSearchCountryStateCity(SEARCH_TYPE.COUNTRY, false, search);
                        }}
                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      />
                    </View>
                  </View>
                  <View style={[styles.customFlecDirection, styles.inputFields]}>
                    <View style={[commonStyles.flexBasis48]}>
                      <SelectMenuField
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        name="states"
                        label={CommonString.StatesLabel}
                        items={this.statesList}
                        onValueChange={this.onStateChange}
                        searchable
                        itemLoader={async ({ search }) => {
                          return await this.onSearchCountryStateCity(SEARCH_TYPE.STATE, false, search);
                        }}
                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      />
                    </View>
                    <View style={[commonStyles.flexBasis48]}>
                      <SelectMenuField
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        name="city"
                        label={CommonString.CityLabel}
                        items={this.citiesList}
                        onValueChange={this.onCityName}
                        searchable
                        itemLoader={async ({ search }) => {
                          return await this.onSearchCountryStateCity(SEARCH_TYPE.CITY, false, search);
                        }}
                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      />
                    </View>
                  </View>
                  <View style={[styles.customFlecDirection, styles.inputFields]}>
                    <View style={[commonStyles.flexBasis48]}>
                      <TextInputField
                        name="postCode"
                        label={CommonString.ZipCodeLabel}
                        validator="min5Max10"
                        style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                        value={this.profileView.postCode}
                        disabled={false}
                        onChangeText={this.trimExceededCharZipCode}
                        helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                      />
                    </View>
                    <View style={[commonStyles.flexBasis48, styles.inputFields]}></View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd, styles.buttonWrapper]}>
                    <View style={[commonStyles.flexBasis48, commonStyles.marginRight8, styles.buttonHolder]}>
                      <Button
                        key="cancel"
                        title="Cancel"
                        onPress={this.onCancel}
                        style={[commonStyles.blueBorderButton, commonStyles.fullWidth, customCommonStyles.noBoxShadow]}
                        titleTextStyle={[
                          commonStyles.blueBorderButtonColor,
                          commonStyles.fontWeight500,
                          commonFontStyles.workSans,
                          commonStyles.exSmallHeading,
                        ]}
                      />
                    </View>
                    <View
                      style={[commonStyles.flexBasis48, commonStyles.marginLeft8, styles.buttonHolder, styles.updatebtnHolder, customCommonStyles.formUpdateBtnHolder]}>
                      <SubmitButton
                        key="update"
                        title="Update"
                        style={[commonStyles.blueButton, commonStyles.fullWidth, customCommonStyles.noBoxShadow]}
                        titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                      />
                    </View>
                  </View>
                </Form>
              </View>
          </View>
        </>
      );
    }

    //----------- Render User Profile View.
    renderUserProfileView() {
      const userBUDetails = this.props.userBUDetails.map((item)=>{
        if(!!item.billerName)
          return { label: `${item.billerName} (${item.buName})`, value: item.uid }
        else
          return { label: `NA`, value: item.uid }
      }).filter(bu => bu.label !== "NA");
      return (
        <>
          {this.renderPageTitleSection()}
          <View style={[commonStyles.flex1, commonStyles.customFlexDirection,styles.profileDetailSection, customCommonStyles.customXbpProfileDetailSection]}>
            <View style={[styles.userProfileDetailsLeft]}>{this.renderUserIconSection()}</View>
            <View style={[styles.userProfileDetailsRight, styles.maxWidth75]}>
              <View style={[commonStyles.flex1, styles.customFlecDirection, userBUDetails.length ? null : commonStyles.displayNone]}>
                {/* {!!this.props.userBUDetails && this.props.userBUDetails.length < 2 ? null : */}
                <Form
                  name="profileView"
                  style={[commonStyles.flex1, styles.profileViewForm]}
                  onSubmit={() => null}
                  validateBeforeSubmit={true}
                  validators={CommonValidators.getFormValidator()}>
                  <View>
                    <SelectMenuField
                      name="billerBU"
                      label={CommonString.BillerBULabel}
                      style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                      items={userBUDetails}
                      onValueChange={this.onChangeViewBUDetail}
                      searchable
                      disabled={userBUDetails.length < 2}
                      readOnly={userBUDetails.length < 2}
                    // itemLoader={async ({ search }) => {
                    //   return await this.onSearchCountryStateCity(SEARCH_TYPE.CITY, false, search);
                    // }}
                    />
                  </View>
                </Form>
                <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginLeft16, styles.inputFields, styles.customDisplay]}>
                  <View style={[commonStyles.marginTop5]}>
                    <Button
                      key="linkPayerButton"
                      title={CommonString.LinkPayer}
                      onPress={() => this.props.app.nav.to("linkPayerRoute")}
                      style={[commonStyles.blueButton, commonStyles.fullWidth, customCommonStyles.noBoxShadow, styles.linkPayerButton]}
                      titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6]}
                    />
                  </View>
                </View>
              </View>
              <View style={[commonStyles.flex1, styles.customFlecDirection]}>
                <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                  <View style={[styles.detailRow]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                        commonStyles.labelDetails,
                      ]}>
                      {CommonString.FirstNameLabel}
                    </Text>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {this.profileView.firstName}
                    </Text>
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginLeft8, styles.inputFields]}>
                  <View style={[styles.detailRow]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                        commonStyles.labelDetails,
                      ]}>
                      {CommonString.LastNameLabel}
                    </Text>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {this.profileView.lastName}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[commonStyles.flex1, styles.customFlecDirection]}>
                <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginRight8, styles.inputFields]}>
                  <View style={[styles.detailRow]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                        commonStyles.labelDetails,
                      ]}>
                      {CommonString.Address1Label}
                    </Text>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {this.profileView.addressLine1}
                    </Text>
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.customMarginLeft8, styles.inputFields]}>
                  <View style={[styles.detailRow]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                        commonStyles.labelDetails,
                      ]}>
                      {CommonString.Address2Label}
                    </Text>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {this.profileView.addressLine2}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[commonStyles.flex1, styles.customFlecDirection]}>
                <View style={[commonStyles.flexBasis50, styles.inputFields, styles.customMarginRight8]}>
                  <View style={[styles.detailRow]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                        commonStyles.labelDetails,
                      ]}>
                      {CommonString.CountryLabel}
                    </Text>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {this.profileView.country}
                    </Text>
                  </View>
                </View>
                <View style={[commonStyles.flexBasis50, styles.customMarginLeft8, styles.inputFields]}>
                  <View style={[styles.detailRow]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                        commonStyles.labelDetails,
                      ]}>
                      {CommonString.StatesLabel}
                    </Text>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {this.profileView.state ? this.profileView.state : ""}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[commonStyles.flex1, styles.customFlecDirection]}>
                <View style={[commonStyles.flex1, commonStyles.flexBasis50, styles.customMarginRight8, styles.inputFields]}>
                  <View style={[styles.detailRow]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                        commonStyles.labelDetails,
                      ]}>
                      {CommonString.CityLabel}
                    </Text>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {this.profileView.town}
                    </Text>
                  </View>
                </View>
                <View style={[commonStyles.flexBasis50, styles.customMarginLeft8, styles.inputFields]}>
                  <View style={[styles.detailRow]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                        commonStyles.labelDetails,
                      ]}>
                      {CommonString.ZipCodeLabel}
                    </Text>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {this.profileView.postCode}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </>
      );
    }

    render() {
      if (this.profileView.mobileNumber) {
        let splitPhoneNumber = this.profileView.mobileNumber.split(" ");
        if (splitPhoneNumber[1]) {
          let match = splitPhoneNumber[1].match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            let formattedString = "";
            match[1] = splitPhoneNumber[0] + " ";
            formattedString = [match[1], "(", match[2], ") ", match[3], "-", match[4]].join("");
            this.profileView.mobileNumber = "";
            this.profileView.mobileNumber = formattedString;
          }
        } else {
          let match = splitPhoneNumber[0].match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            let formattedString = "";
            match[1] = "+1 ";
            formattedString = [match[1], "(", match[2], ") ", match[3], "-", match[4]].join("");
            this.profileView.mobileNumber = "";
            this.profileView.mobileNumber = formattedString;
          }
        }
      }
      return (
        <>
          {this.actionType === "view" ? this.renderUserProfileView() : null}
          {this.actionType === "edit" ? this.renderUserEdit() : null}
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  inputBox: {
    lineHeight: 40,
    padding: 12
  },
  profileViewScreen: {
    padding: 15,
    ...StyleSheet.margin(0, 0, 0, 0),
    height: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
        };
      }
      if (width <= 640) {
        return {
          margin: 0,
          paddingBottom: 20
        };
      }
    },
  },
  profileDetailSection: {
    backgroundColor: "#fff",
    padding: 16,
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#d6d6d6",
    flexGrow: 0,
    height:"100%",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          flex: 1,
          marginRight: 0,
          marginLeft: 0,
          border: 0,
          paddingBottom: 20
        };
    },
  },
  BorderTop: {
    // borderTop: "1px solid #D6D6D6",
    borderTopWidth: 1,
    borderStyle: "solid",
    borderColor: "#d6d6d6",
  },
  camaricon: {
    width: 44,
    height: 44,
    display: "block",
    backgroundSize: "contain",
  },
  usericon: {
    width: 70,
    height: 70,
    display: "block",
    backgroundSize: "contain",
  },
  black: {
    color: "#333",
  }, 
  bodyWrapper: {
    width: 300,
    minHeight: 340,
    borderRadius: 6,
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: 300,
          minHeight: 350,
        };
      }
    },
  },
  modalOuterWrapper: {
    width: "auto",
    // backgroundColor: "#0000005c",
  },
  payNowWrapper: {
    flex: 1,
    justifyContent: "center",
    // alignItems: "center",
    flexDirection: "column",
  },
  passwordField: {
    width: "100%",
  },
  passwordFieldContainer: {
    width: "100%",
    position: "relative",
  },
  closeBtn: {
    // position: "relative",
    // zIndex: 9999,
    display: "none",
  },
  customHelperTextStyle: {
    // position: "static",
    left: 0,
  },
  changePasswordButtonWrap: {
    marginTop: 10,
    marginRight: 0,
    marginBottom: 40,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  changePasswordBtn: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          width: "auto",
          marginLeft: "auto",
        };
      }
    },
  },
  changePhoto: {
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    marginTop: 25,
    marginRight: 0,
    marginBottom: 9,
    marginLeft: 0,
  },
  profileEditForm: {
    paddingTop: 16,
    paddingRight: 0,
    paddingBottom: 16,
    paddingLeft: 0,
    minHeight: '100%',
    maxWidth: "100%"
  },
  profileViewForm: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    minHeight: '100%',
    maxWidth: "49%",
    minWidth: "49%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
        };
      }
    },
  },
  synced: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderRadius: 30,
    paddingTop: 2,
    paddingRight: 8,
    paddingBottom: 2,
    paddingLeft: 8,
    display: "flex",
  },
  inputFields: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    screen: ({ width }) => {
      if (width >= 991 && width <= 1083) {
        return {
          flexBasis: "48%",
        };
      }
      if (width <= 991) {
        return {
          flexBasis: "100%",
          width: "100%",
          marginRight: 0,
          marginLeft: 0,
        };
      }
    },
  },
  buttonWrapper: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          //   justifyContent: "flex-end",
        };
      }
    },
  },
  buttonHolder: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          flexBasis: "auto",
          flexGrow: 0,
        };
      }
    },
  },
  updatebtnHolder: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          minWidth: 104,
        };
      }
    },
  },
  userDetailsVla: {
    minHeight: 18,
    wordBreak: "break-all"
  },
  profileUppercase: {
    textTransform: "uppercase",
  },
  userIconDiv: {
    width: 60,
    maxWidth: 60,
    minWidth: 60,
    height: 60,
    borderTopLeftRadius: 60,
    borderTopRightRadius: 60,
    borderBottomLeftRadius: 60,
    borderBottomRightRadius: 60,
    backgroundColor: "#e7e7e7",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginBottom: 10,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginRight: 16,
          marginBottom: 0,
        };
      }
    },
  },
  pageTitleHolder: {
    // backgroundColor: "#fff",
    zIndex: 999,
    paddingTop: 7,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    background: "#f4f4f4",
    maxHeight: 45,
  },
  detailRow: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
    paddingBottom: 5,
    marginBottom: 15,
    flex: 1,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 10,
        };
      }
      if (width >= 992) {
        return {
          height: 47,
        };
      }
    },
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customMarginRight8: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  customMarginLeft8: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMarginLeft16: {
    marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  // profileSubMenu: {
  //   marginRight: -10,
  // },
  editProfileIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f4f4f4",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    boxShadow: "0px 1px 3px #000",
  },
  editProfileIconBtn: {
    backgroundColor: "#f1f1f1",
  },
  socialMediaCustomMargin: {
    marginTop: 20,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    flexWrap: "wrap",
    paddingTop: 0,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    justifyContent: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 20,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          padding: 0,
          justifyContent: "flex-start",
        };
      }
    },
  },
  profileSectionWrap: {
    display: "flex",
  },
  userProfileDetails: {
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
          marginRight: 0,
        };
      }
    },
  },
  userProfileDetailsLeft: {
    width: "30%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
      if (width === 1024) {
        return {
          width: "35%",
        };
      }
    },
  },
  pageTitle: {
    color: "#151b26",
  }, 
  userDetails: {
    textAlign: "left",
    minWidth: 170,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          textAlign: "left",
        };
      }
    },
  },
  socialMediaOuterWrap: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: 301,
    // width: 301,
    screen: ({ width }) => {
      if (width >= 320 && width <= 359) {
        return {
          maxWidth: 252,
          width: 252,
        };
      }
      if (width <= 991) {
        return {
          display: "block",
        };
      }
    },
  },
  userDetailsWrap: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "70%",
          maxWidth: "880%",
        };
      }
    },
  },
  customFlecDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  userIconDivOuter: {
    flexDirection: "column",
    alignItems: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
          flexDirection: "row",
        };
      }
    },
  },   
  userProfileDetailsRight: {
    flexGrow: 1,
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          flexGrow: 1,
        };
      } else if (width <= 992) {
        return {
          flexGrow: 0,
          width: "100%",
        };
      }
    },
  },
  longErrorMsg: {
    width: "120%"
  },
  arrowHolderStyle: {
    position: "absolute",
    left: -5,
  },
  toolTipMessageSignupStyle: {
    right: 30,
  },
  width190: {
    width: 190
  },
  linkPayerButton: {
    maxWidth: 130,
  },
  maxWidth48: {
    maxWidth: "48%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
          flexBasis: "100%",
        };
      }
    },
  },
  maxWidth75: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          maxWidth: "75%"
        };
      }
    },
  },
  customDisplay: {
    paddingTop: 5,
    display: "none",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "none"
        };
      }
    },
  }
});
