import React from "react";
import R14, {
  View,
  Text,
  StyleSheet,
  SelectMenuField,
  TextInputField,
  FlatList,
  Image,
  Touchable,
  Platform,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import NoRecordsFound from "../NoRecordsFound";
import DatePicker from "./DatePicker"
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";

const deleteIcon = require("../../assets/images/delete.png");
const editIcon = require("../../assets/images/edit.png");
const plusSolidIcon = require("../../assets/images/plus_solid.png");

export default R14.connect(
  class CptDetailsComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        alertMessage: ""
      };
    }
    totalAmount = (amount) => {
      if (+amount && amount.indexOf(".") !== -1) {
        amount = +amount;
        amount = amount.toFixed(2);
      };
      return amount;
    };

    render() {
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
      return (
        <View>
          {/* CPT Details & Add CPT Code */}
          <View style={[commonStyles.flex1, styles.inputHolder, styles.customAlignItemsStart, commonStyles.flexDirectionRow, this.props.isSuperBill && styles.marginBottom32, this.props.isSuperBill && styles.displayNone, this.props.isEditOrAddCpt && styles.displayNone]}>
            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis49,]}>
              <View>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.heading5,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}
                >CPT Details</Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.rightInputHolder, commonStyles.flexBasis49, commonStyles.alignItemsCenter, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow]}>
              <Touchable onPress={() => this.props.onEditCptDetails({})} style={styles.plusIcon}>
                <Image source={plusSolidIcon} style={[commonStyles.icon20]} />
              </Touchable>
              <Touchable onPress={() => this.props.onEditCptDetails({})} style={[commonStyles.marginLeft8]}>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight400,
                    commonStyles.heading6,
                    commonFontStyles.workSans,
                  ]}
                >Add CPT</Text>
              </Touchable>
            </View>
          </View>
          {/* CPT Table */}
          {this.props.cptItems.length > 0 ? (
            <View style={[styles.table, this.props.isSuperBill && styles.displayNone, this.props.isEditOrAddCpt && styles.displayNone]}>
              <View style={styles.theadOuter}>
                <View
                  style={[
                    styles.thead,
                    commonStyles.flex1,
                    commonStyles.justifyContentSpaceBetween,
                    commonStyles.flexDirectionRow,
                  ]}
                >
                  <View style={[styles.th, styles.incNameTh]}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonStyles.tableKey
                      ]}
                    >
                      CPT
                    </Text>
                  </View>
                  <View style={[styles.th, styles.incNameTh]}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonStyles.tableKey
                      ]}
                    >
                      Modifiers
                    </Text>
                  </View>
                  <View style={styles.th}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonStyles.tableKey
                      ]}
                    >
                      Units
                    </Text>
                  </View>
                  <View style={styles.th}>
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonStyles.tableKey
                      ]}
                    >
                      Total Fee
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.th,
                      styles.action,
                      styles.thAction,
                      this.props.leftMenuToggle ? null : styles.thActionLeftMenuToggle,
                    ]}
                  >
                    <Text
                      style={[
                        commonFontStyles.workSans,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonStyles.tableKey
                      ]}
                    >
                      Action
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          ) : null}
          <FlatList
            style={[
              styles.flatlistEle,
              this.props.cptItems.length > 0
                ? styles.insuranceListComponent
                : styles.noItemsComponent,
              commonStyles.marginBottom5,
              !!isUberDocs ? clientStyle.secondaryBgColor : null,
              this.props.isSuperBill && styles.displayNone,
              this.props.isEditOrAddCpt && styles.displayNone
            ]}
            data={this.props.cptItems}
            ListEmptyComponent={
              <View style={[commonStyles.flex1]}>
                <NoRecordsFound
                  noRecords={[styles.noIncRecords, clientStyle.primaryBgColor]}
                  message={CommonString.NoCptFound}
                  isUberDocs={!!isUberDocs}
                />
              </View>
            }
            keyExtractor={(el, i) => i}
            renderItem={({ item, index }) => (
              <View key={"insuranceRow_" + index}>
                <View
                  style={[
                    styles.tr,
                    commonStyles.flex1,
                    commonStyles.justifyContentSpaceBetween,
                    styles.customFlexDirection,
                  ]}
                >
                  <View style={[styles.td, styles.incNameTh]}>
                    <View
                      style={[
                        commonStyles.mView,
                        commonStyles.marginBottom5,
                        styles.mobileLabels,
                      ]}
                    >
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight700,
                          commonStyles.heading6,
                          commonStyles.tableKey
                        ]}
                      >
                        CPT:
                      </Text>
                    </View>
                    <View style={[customCommonStyles.valueInMbile, styles.fullMaxWidthTd]}>
                      <Text
                        style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.tableValue]}
                      >
                        {item.description}
                      </Text>
                    </View>
                  </View>



                  <View style={[styles.td, styles.incNameTh]}>
                    <View
                      style={[
                        commonStyles.mView,
                        commonStyles.marginBottom5,
                        styles.mobileLabels,
                      ]}
                    >
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight700,
                          commonStyles.heading6,
                          commonStyles.tableKey
                        ]}
                      >
                        Modifiers:
                      </Text>
                    </View>
                    <View style={[customCommonStyles.valueInMbile, styles.fullMaxWidthTd]}>
                      <Text
                        style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.tableValue]}
                      >
                        {item.modifiers && item.modifiers.length ? item.modifiers.map(e => e.label).join(", ") : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.td]}>
                    <View
                      style={[
                        commonStyles.mView,
                        commonStyles.marginBottom5,
                        styles.mobileLabels,
                      ]}
                    >
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight700,
                          commonStyles.heading6,
                          commonStyles.tableKey
                        ]}
                      >
                        Units:
                      </Text>
                    </View>
                    <View style={[customCommonStyles.valueInMbile]}>
                      <Text
                        style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.tableValue]}
                      >
                        {!!item.units ? `${item.units}` : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.td, styles.fullMaxWidthTd]}>
                    <View
                      style={[
                        commonStyles.mView,
                        commonStyles.marginBottom5,
                        styles.mobileLabels,
                      ]}
                    >
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight700,
                          commonStyles.heading6,
                          commonStyles.tableKey
                        ]}
                      >
                        Fee:
                      </Text>
                    </View>
                    <View style={[customCommonStyles.valueInMbile, styles.fullMaxWidthTd]}>
                      <Text
                        style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.tableValue]}
                      >
                        {!!item.fees ? `$${item.fees}` : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.td, styles.action, styles.customActionColumn,]}>
                    <View
                      style={[
                        commonStyles.flex1,
                        commonStyles.alignItemsCenter,
                        commonStyles.flexDirectionRow
                      ]}
                    >
                      <View>
                        <Touchable onPress={async () => this.props.onEditCptDetails(item)}
                        >
                          <View style={[customCommonStyles.customActionBtn]}>
                            <Image
                              source={editIcon}
                              style={commonStyles.icon18}
                            />
                          </View>
                        </Touchable>
                      </View>
                      {
                        <View style={styles.deleteActionWrap}>
                          <Touchable
                            onPress={async () => this.props.openPopScreen(item.cptRowId)}
                          >
                            <View style={customCommonStyles.customActionBtn}>
                              <Image
                                source={deleteIcon}
                                style={commonStyles.icon20}
                              />
                            </View>
                          </Touchable>
                        </View>
                      }
                    </View>
                  </View>
                </View>
              </View>
            )}
          />

          {/* Edit or Add CPT Details */}
          <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, !this.props.isEditOrAddCpt && styles.displayNone, commonStyles.marginTopBottom20]}>
            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis49, commonStyles.flexDirectionRow, styles.treatmentDetails]}>
              <Text
                style={[
                  // commonStyles.dBlock,
                  commonStyles.fontWeight500,
                  commonStyles.heading3,
                  commonFontStyles.workSans,
                  commonStyles.marginBottom5,
                  styles.cptAddTitle
                ]}
              >{`${this.props.cptCode ? "Edit" : "Add"} CPT Details `}</Text>
            </View>
          </View>
          {/* CPT code & units */}
          <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, !this.props.isEditOrAddCpt && styles.displayNone]}>
            <View
              style={[
                commonStyles.flex1,
                styles.leftInputHolder,
                commonStyles.flexBasis48,
                styles.addIncProText,
                commonStyles.customFlexDirection,
                styles.customMarginTop16,
                commonStyles.flexDirectionColumn,
                styles.maxWidth49
              ]}>
              <SelectMenuField
                name="editCptCode"
                label={"CPT*"}
                required={this.props.isSuperBill || !this.props.isEditOrAddCpt ? "" : CommonString.SelectCptRequired}
                searchable
                itemLoader={async ({ search }) => {
                  return await this.props.getCptList(search);
                }}
                onValueChange={(val) => this.props.onCptChange(val)}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
              {this.props.isCptCodeError ? (
                <View style={[styles.cptCodesErrorLabel]}>
                  <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >Select CPT</Text>
                </View>
              ) : null}
            </View>
            {/* Modifier list */}
            <View
              style={[
                commonStyles.flex1,
                styles.rightInputHolder,
                commonStyles.flexBasis48,
                styles.addIncProText,
                commonStyles.customFlexDirection,
                styles.customMaxWidth48to100,
                commonStyles.flexDirectionColumn
              ]}>
              <SelectMenuField
                name={"modifiers"}
                label={"Modifiers"}
                onValueChange={(val) => this.props.onModifierChange(val)}
                multiple={true}
                searchable
                itemLoader={async ({ search }) => {
                  return await this.props.getModifiersList(search);
                }}
                style={[commonStyles.marginBottom5]}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
              {this.props.isCptModifierError ? (
                <View style={[styles.totalAmountErrorLabel]}>
                  <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >{CommonString.Min1Max8FeeDigitsRequired}</Text>
                </View>
              ) : null}
            </View>
          </View>
          {/* CPT code & units */}
          <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, !this.props.isEditOrAddCpt && styles.displayNone]}>
            {/* Date of service */}
            <View style={[
              commonStyles.flex1,
              styles.leftInputHolder,
              commonStyles.flexBasis48,
              styles.addIncProText,
              commonStyles.customFlexDirection,
              commonStyles.marginBottom20, 
              styles.customMarginTop16,
              commonStyles.flexDirectionColumn,
              styles.maxWidth49
            ]}>
                <View style={[commonStyles.fullWidth]} >
                  <DatePicker
                    name={"dateOfService"}
                    label={`${CommonString.DateOfServiceLabel} `}
                    formName={"uploadBills"}
                    props={this.props}
                    onValueChange ={this.props.onDateOfServiceValueChange}                    
                    style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, Platform.OS === "web" ? commonStyles.marB0 : null]}
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                    helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                    isUberDocs={isUberDocs}
                  />
                </View>
              {!!this.props.isDateOfServiceError ? (
                <View style={[styles.cptCodesErrorLabel, commonStyles.marB0, commonStyles.marginTop5]}>
                  <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >{CommonString.EnterDateOfServiceRequired}</Text>
                </View>
              ) : null}
            </View>
            {/* Units */}
            <View style={[
              commonStyles.flex1,
              styles.rightInputHolder,
              commonStyles.flexBasis48,
              styles.addIncProText,
              commonStyles.customFlexDirection,
              styles.customMaxWidth48to100,
              commonStyles.flexDirectionColumn
            ]}>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, commonStyles.flexGrow0, commonStyles.flexDirectionColumn]}>
                <TextInputField
                  name={"editCptUnit"}
                  label={"Units"}
                  validator={"min0Max5Digit"}
                  secure={false}
                  disabled={false}
                  onChangeText={(unit) => this.props.onAmountChange({ value: unit, field: 'units' })}
                  style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                />
                {this.props.isCptUnitError ? (
                  <View style={[styles.totalAmountErrorLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >{CommonString.Min1Max5UnitsRequired}</Text>
                  </View>
                ) : null}
              </View>
            </View>
            
          </View>
          {/* Total Amount */}
          <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, !this.props.isEditOrAddCpt && styles.displayNone]}>
            {/* Fees */}
            <View
              style={[
                commonStyles.flex1,
                styles.leftInputHolder,
                commonStyles.flexBasis48,
                styles.addIncProText,
                commonStyles.customFlexDirection,
                styles.customMaxWidth48to100,
                commonStyles.flexDirectionColumn
              ]}>
              <TextInputField
                name={"editFee"}
                validator={"max8WithDecimal"}
                label={"Total Fee*($) "}
                secure={false}
                disabled={false}
                onChangeText={(fee) => this.props.onAmountChange({ value: fee, field: 'fees' })}
                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
              />
              {this.props.isCptFeeError ? (
                <View style={[styles.totalAmountErrorLabel]}>
                  <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >{CommonString.Min1Max8FeeDigitsRequired}</Text>
                </View>
              ) : null}
            </View>

            <View
              style={[
                commonStyles.flex1,
                styles.leftInputHolder,
                commonStyles.flexBasis48,
                styles.addIncProText,
                commonStyles.customFlexDirection,
                styles.customMaxWidth48to100,
                commonStyles.flexDirectionColumn,
                styles.inputDisable,
                styles.displayNone
              ]}>
              <TextInputField
                name={"totalFee"}
                label={CommonString.TotalAmountLabel}
                secure={false}
                disabled={false}
                readOnly={true}
                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
              />
            </View>
          </View>
        </View>
      )
    }
  }
);
const styles = StyleSheet.create({
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    marginBottom: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  customAlignItemsStart: {
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: null,
        };
      }
    },
  },
  marginBottom32: {
    marginBottom: 32
  },
  table: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderWidth: 0,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    borderBottomWidth: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 2,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  },
  theadOuter: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  thead: {
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    zIndex: 999,
    backgroundColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    flexDirection: "row",
    alignItems: 'center',
    minHeight: 25,
  },
  tr: {
    flexDirection: "row",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    alignItems: 'center',
    minHeight: 30,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 10,
          paddingRight: 0,
          paddingBottom: 10,
          paddingLeft: 0,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          flexDirection: "column",
          alignItems: 'flex-start',
        };
    },
  },
  th: {
    flex: 1,
    flexBasis: "18%",
    maxWidth: "18%",
  },
  incNameTh: {
    flex: 1,
    flexBasis: "25.5%",
    maxWidth: "25.5%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "75%",
          flexDirection: "row",
        };
    },
  },
  action: {
    maxWidth: "10%",
    flexBasis: "10%",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          minWidth: 100,
        };
      }
      if (width <= 991) {
        return {
          position: "absolute",
          zIndex: 999999,
          top: 10,
          justifyContent: "space-between",
        };
      }
    },
  },
  deleteActionWrap: {
    marginLeft: 10,
    position: "relative",
    zIndex: 9999999999,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  thAction: {
    maxWidth: "10%",
    flexBasis: "10%",
    paddingLeft: 8,
    minWidth: 103,
  },
  thActionLeftMenuToggle: {
    minWidth: 113,
  },
  flatlistEle: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "block",
        }
      }
    },
  },
  insuranceListComponent: {
    // marginTop: 144,
    marginRight: 0,
    marginBottom: 70,
    marginLeft: 0,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTop: 0,
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderTopWidth: 1,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 30,
          marginLeft: 0,
          paddingBottom: 0,
        };
    },
  },
  noItemsComponent: {
    marginTop: 0,
    marginBottom: 30,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#fff",
    padding: 0,
    position: "relative",
  },
  noIncRecords: {
    marginLeft: 0,
    marginRight: 0
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customFontWeight600: {
    fontWeight: 400,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontWeight: "bold",
        };
      }
    },
  },
  mobileLabels: {
    marginRight: 10,
    minWidth: 125,
  },
  fullMaxWidthTd: {
    wordBreak: "break-word",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
        }
      }
    },
  },
  td: {
    flex: 1,
    flexBasis: "18%",
    maxWidth: "18%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "75%",
          flexDirection: "row",
        };
    },
  },
  customActionColumn: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          right: 0,
          zIndex: 999999,
          maxWidth: "21%",
          flexBasis: "20%",
          justifyContent: "space-between",
        }
      }
    }
  },
  plusIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  treatmentDetails: {
    width: "100%",
    justifyContent: "space-between",
    flexGrow: 0,
  },
  customMarginTop16: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 16,
        };
      }
    }
  },
  cptCodesErrorLabel: {
    marginLeft: 0,
    marginTop: -32,
    marginBottom: 16
  },
  totalAmountErrorLabel: {
    marginLeft: 0,
    marginTop: -28,
    marginBottom: 10
  },
  addIncProText: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  customMaxWidth48to100: {
    maxWidth: "49%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          maxWidth: "100%",
        };
    },
  },
  inputDisable: {
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: 0.5,
  },
  customMarginTop30: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 30,
        };
    },
  },
  cptAddTitle: {
    minWidth: 150
  },
  intendedDateField: {
    width: "88%",
  },
  maxWidth49: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          maxWidth: "49%",
        };
    },
  }
});
