import { StyleSheet } from "../../../core";

const uberDocThemeStyle = StyleSheet.create({
    form: {
        backgroundColor: "#f4f4f4",
    },
    header: {
        backgroundColor: "#5d9fab",
        height:44,
        flex: 1
    },
    submitButton: {
        backgroundColor: "#f68c48"
    },
    submitButtonText: {
        color: "#fff",
        // fontFamily: "montserrat sans-serif",
        // fontWeight: "600",
        // fontSize: 17,
    },
    backAndCancelButton: {
        backgroundColor: "#f4f4f4",
        borderWidth: 2,
        borderColor: "#e5e5e5",
        borderStyle: "solid",
    },
    backAndCancelButtonText: {
        color: "#67696a",
        // fontFamily: "montserrat sans-serif",
        // fontWeight: "600",
        // fontSize: 17,
    },
    clientLogo:{
        height: 16,
        width: 100,
        screen: ({ width }) => {
        if (width <= 991)
            return {
            width: 100,
            };
        },
    },
    primaryBgColor: {
        backgroundColor: "#5d9fab"
    },
    primaryTextColor: {
        color: "#fff",
    },
    secondaryBgColor: {
        backgroundColor: "#f4f4f4",
    },
    bgTransparent: {
        backgroundColor: "transparent"
    },
    submitClaimButtonWidth: {
        width: 200
    },
    workSans: {
        fontFamily: "montserrat sans-serif",
    },
     circleGap:{
        borderLeftColor: "#f68c48",
     } 
});

export { uberDocThemeStyle };
