import React from "react";
import R14, {
    Form,
    View,
    Text,
    StyleSheet,
    Button,
    SubmitButton,
    TextInputField,
    Platform,
    Touchable,
    Image,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";
import CommonValidators from "../../utils/CommonValidators";
import DatePicker from "../PCH/DatePicker";
import DateUtils from "../../utils/DateUtils";
import FileUploadComponent from "../FileUploadComponent";
import CommonUtility from "../../utils/CommonUtility";
const deleteIcon = require("../../assets/images/delete.png");


export default R14.connect(
    class EditManagePaymentComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                attachments: [],
                alertMessage: "",
                isAlertVisible: false,
                isAddAttachmentsVisible: false,
                loaderVisible: false,
            };
            this.child = React.createRef();
            this.fileToSave = null;
        }

        validateDate = (date) => {
            const newDate = new Date(date);
            const formattedDate = DateUtils.formatDateInMMDDYYYY(newDate); 
            let date_regex = /^\d{2}\/\d{2}\/\d{4}$/;
            return date_regex.test(formattedDate);
        };

        onDateChange = async (val, autoformat = true) => {
            if (!!val && !!autoformat) {
                let date = DateUtils.formatDateInMMDDYYYY(val);
                if (!!date && new Date(date) > new Date()) {
                    const form = this.props.app.ui.form("editManagePayment");
                    form.elmts.paidDate.setValue(null);
                    form.elmts.paidDate.setValue(date);
                    form.elmts.paidDate.setError(CommonString.CheckFutureDateEntered);
                    this.props.showError(CommonString.CheckFutureDateEntered);
                }
            }
        };

        uploadDocumentatS3 = async (signedUrl, file) => {
            var options = {
                headers: {
                    "content-type": file.contentUri.replace("data:", "").replace(";base64", ""),
                }
            };
            return await this.props.app.dm.rest.putS3(signedUrl, Buffer.from(file.content, 'base64'), options);
        }

        handleSubmit = async (form) => {
            const { attachments } = this.state;
            let documentId = CommonUtility.generate20Character();
            if(this.props.item && this.props.item.documentId) documentId = this.props.item.documentId;
            if ((!attachments || attachments.length === 0) && !(this.props.item && this.props.item.documentId)) {
                let error = "Please upload EOB of the payment.";
                await this.child.current.wrappedInstance.pullErrorText(error);
                return false;
            };
            const {
                paidAmount = "",
                deductible = "",
                copay = "",
                coinsurance = "",
            } = form.values;
            if( !(paidAmount === "0" || +paidAmount) && !(+deductible) && !(+copay) && !(+coinsurance)) {
               const errorMessage = "Kindly enter Paid or Patient Responsibility Amount(deductible or copay or coinsurance)";
               this.props.showError(errorMessage)
               return false;
            }

            if(attachments && attachments.length) this.props.onLoaderHanlde(true);
            let uploadDocs = attachments.map(async (doc, i) => {
                doc.documentId = documentId;
                doc.seqNo = i + 1;
                let fileData = { ...doc };
                delete fileData.content;
                let response = await this.props.app.dm.uploadBillDomain.uploadDocumentOnServer(fileData);
                if (!!response && !!response.content)
                    await this.uploadDocumentatS3(response.content, doc)
                return response;
            });
            let docRes = await Promise.all(uploadDocs);
            if (docRes.filter(x => x.uid === null).length === 0) {
                this.props.handleSubmitPayment(form, documentId);
            } else {
                this.props.onLoaderHanlde(false);
                this.props.app.dm.commonDomain.showToastr("error", CommonString.ErrorOnFileUpload);
            }
            return false;
        }


        onCancel = async (item) => {
            this.props.onCancelPayment(item);
        }

        onUploadEOB = async (item) => {
            this.props.onUploadEOBPayment(item);
        }

        onChangeAmount = (txt, fm, key = "paidAmount") => {
            const form = this.props.app.ui.form("editManagePayment");
            let totalAmount = txt;
            if(key === "checkNumber") totalAmount = txt.replace(/[^a-zA-Z0-9-:\s]/g, '').substring(0, 70);
            else if(key === "comments") totalAmount = txt.substring(0, 150);
            else totalAmount = txt.replace(/[a-zA-Z\s!@#$%^&*()_\-+|~=`{}[\]:";'<>?,\\/]/g, "").substring(0, 70);
            form.elmts[key].setValue(totalAmount);
            form.elmts[key].setError(null);
        }

        onFileInputChange = async (val, status) => {
            const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
            if (!!!isMobileDevice) await this.child.current.wrappedInstance.pullErrorText("");
            const { attachments } = this.state;

            if (status === "done") {
                let x = [val];
                const { attachments } = this.state;
                let existingFiles = attachments.filter((value) => value.filename === val.filename && value.actualsize === val.actualsize);
                if (existingFiles.length > 0) {
                    let error = "File has been already added."
                    if (!!!isMobileDevice) {
                        await this.child.current.wrappedInstance.pullErrorText(error);
                    } else {
                        this.setState({
                            isAlertVisible: true,
                            alertMessage: "File has been already added."
                        })
                    }
                    return false;
                }

                let filesSize = attachments.reduce((prevVal, val) => prevVal + val.actualsize, 0);
                filesSize = filesSize + val.actualsize;
                if (filesSize / 1024 / 1024 > 10) {
                    let error = "Maximum 10MB file size allowed.";
                    if (!!!isMobileDevice) {
                        await this.child.current.wrappedInstance.pullErrorText(error);
                    } else {
                        this.setState({
                            isAlertVisible: true,
                            alertMessage: "Maximum 10MB file size allowed."
                        })
                    }
                    return false;
                }

                if (attachments.length > 20) {
                    let error = "Maximum 20 attachments allowed.";
                    if (!!!isMobileDevice) {
                        await this.child.current.wrappedInstance.pullErrorText(error);
                    } else {
                        this.setState({
                            isAlertVisible: true,
                            alertMessage: "Maximum 20 attachments allowed."
                        })
                    }
                    this.setState({ isAddAttachmentsVisible: false });
                    return false;
                }
                this.setState({
                    loaderVisible: true
                });
                attachments.push(x[0]);
                this.setState({ isAddAttachmentsVisible: false, loaderVisible: false, attachments: attachments });
            } else if (status === "removed") {
                this.setState({
                    attachments: attachments.filter((value) => value.filename !== val.filename && value.actualsize !== val.actualsize),
                });
            }
        };

        onDeleteFile = async (ind) => {
            const { attachments } = this.state;
            this.setState({
                attachments: attachments.filter((value, index, arr) => index !== ind),
            });
        };

        handleRender = () => {
            setTimeout(() => {
                const form = this.props.app.ui.form("editManagePayment");
                const { item, onEditAvailable, isEditAvailable } = this.props;
                if (item && item.checkNo && form && form.elmts && isEditAvailable) {
                    form.elmts.checkNumber.setValue(item.checkNo);
                    form.elmts.paidAmount.setValue(item.paidAmount || "0");
                    form.elmts.deductible.setValue(item.deductible || "0");
                    form.elmts.copay.setValue(item.copay || "0");
                    form.elmts.coinsurance.setValue(item.coinsurance || "0");
                    form.elmts.comments.setValue(item.comments);
                    if (item.paidDate) {
                        let dat = this.validateDate(item.paidDate);
                        if (!!dat) {
                            let formatedDate = DateUtils.formatDateInMMDDYYYY(item.paidDate);
                            form.elmts.paidDate.setValue(formatedDate);
                        }
                    };
                    this.setState({attachments: []});
                    onEditAvailable(false);
                }
            }, 0)
        }

        render() {
            let clientStyle = commonStyles;
            let commonFontStyles = fontFamilyStyles;
            const form = this.props.app.ui.form("editManagePayment");
            const { item, hideComponent } = this.props;
            this.handleRender();
            const { attachments } = this.state;
            return (
                <>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.customDetailStyle, !!hideComponent ? commonStyles.displayNone : null]}>
                        <View style={[commonStyles.flex1, styles.customTextStyle]}>
                            <Text style={[commonStyles.exSmallHeading, commonStyles.heading4, commonStyles.fontWeight500, commonFontStyles.workSans]} >{!!item && !!item.checkNo ? "Edit Payment" : "Enter a New Payment"}</Text>
                        </View>
                    </View>

                    <Form
                        name="editManagePayment"
                        style={[styles.uploadBillsForm, clientStyle.form, commonStyles.paddingTop0, !!hideComponent ? commonStyles.displayNone : null]}
                        validateBeforeSubmit={true}
                        validators={CommonValidators.getFormValidator()}
                        onSubmit={this.handleSubmit}>
                        {/* Check Number & Paid date */}
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, !!item && !!item.checkNo ? commonStyles.displayNone : null]}>
                            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis48, commonStyles.marginBottom5, Platform.OS === "web" ? null : commonStyles.flexDirectionRow,]}>

                                <TextInputField
                                    name="checkNumber"
                                    label="Check Number* "
                                    validator="min2Max15"
                                    required={"Enter Check Number"}
                                    onChangeText={(txt) => this.onChangeAmount(txt, form, "checkNumber")}
                                    style={[commonFontStyles.workSans, commonStyles.heading6,  customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.marginBottom35
                                ]}>
                                <DatePicker
                                    name={"paidDate"}
                                    formName="editManagePayment"
                                    label={"Paid Date* "}
                                    props={this.props}
                                    required={"Select Paid Date"}
                                    onValueChange ={this.onDateChange}
                                    style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.width100]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                />
                            </View>
                        </View>
                        {/* Paid Amount & Deductible */}
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder]}>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.leftInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.customFlexDirection,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                ]}>
                                <TextInputField
                                    name="paidAmount"
                                    label="Paid Amount "
                                    onChangeText={(txt) => this.onChangeAmount(txt, form, "paidAmount")}
                                    validator="max10Decimal"
                                    style={[commonFontStyles.workSans, commonStyles.heading6,  customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                ]}>

                                <TextInputField
                                    name="deductible"
                                    label="Deductible"
                                    onChangeText={(txt) => this.onChangeAmount(txt, form, "deductible")}
                                    validator="max10Decimal"
                                    style={[commonFontStyles.workSans, commonStyles.heading6,  customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                        </View>
                        {/* Copay & Coinsurance */}
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder]}>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.leftInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.customFlexDirection,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                ]}>
                                <TextInputField
                                    name="copay"
                                    label="Copay"
                                    onChangeText={(txt) => this.onChangeAmount(txt, form, "copay")}
                                    validator="max10Decimal"
                                    style={[commonFontStyles.workSans, commonStyles.heading6,  customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                ]}>

                                <TextInputField
                                    name="coinsurance"
                                    label="Coinsurance"
                                    onChangeText={(txt) => this.onChangeAmount(txt, form, "coinsurance")}
                                    validator="max10Decimal"
                                    style={[commonFontStyles.workSans, commonStyles.heading6,  customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                        </View>
                        {/* Comments */}
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder]}>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.leftInputHolder,
                                    commonStyles.flexBasis48,
                                    commonStyles.customFlexDirection,
                                    styles.customAlignItemsStart,
                                    styles.textAlign,
                                ]}>
                                <TextInputField
                                    name="comments"
                                    label="Comments"
                                    validator="max140"
                                    onChangeText={(txt) => this.onChangeAmount(txt, form, "comments")}
                                    style={[commonFontStyles.workSans, commonStyles.heading6,  customCommonStyles.inputHeight, styles.width100, styles.minWidth100,]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                            <View
                                style={[
                                    commonStyles.flex1,
                                    styles.rightInputHolder,
                                    commonStyles.flexBasis48,
                                ]}>

                                <TextInputField
                                    name="test"
                                    label="test"
                                    style={[commonFontStyles.workSans, commonStyles.heading6,  customCommonStyles.inputHeight, styles.width100, styles.minWidth100, commonStyles.displayNone]}
                                    labelTextStyle={[
                                        commonStyles.labelText,
                                        commonFontStyles.workSans,
                                        commonStyles.heading6,
                                        clientStyle.secondaryBgColor
                                    ]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]} />
                            </View>
                        </View>

                        <View style={[commonStyles.flex1, styles.customFlexProperties, commonStyles.marginBottom16]}>
                            {attachments.map((el, i) => (
                                // <View style={[styles.attachments]} >
                                <View style={[commonStyles.flex1, styles.attachmentCard, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween]} key={`atta_${i}`}>
                                    <View>
                                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight500, commonStyles.dBlock, customCommonStyles.wordBreakWord]}>
                                            {el.filename}
                                        </Text>
                                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.lightGray]}>
                                            {el.size}
                                        </Text>
                                    </View>
                                    <Touchable onPress={() => this.onDeleteFile(i)}>
                                        <View>
                                            <Image source={deleteIcon} style={commonStyles.icon20} />
                                        </View>
                                    </Touchable>
                                </View>
                            ))}
                        </View>
                        <View style={[commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow]}>
                            <View style={[styles.attachmentField, Platform.OS === 'web' ? styles.customMarTop8 : null]}>
                                <FileUploadComponent
                                    ref={this.child}
                                    maxFiles={20}
                                    labelText={<View style={[commonStyles.secondayWhiteBtn, commonStyles.btnHeight24,  styles.customRestMargin, styles.attachmentFieldOuterCustomHeight]}>
                                        <Text style={[commonStyles.secondayWhiteBtnText, commonFontStyles.workSans]}>{!!item && !!item.checkNo ? "Upload EOB(*.pdf, *.jpg, *.jpeg, *.png)" : "Upload EOB*(*.pdf, *.jpg, *.jpeg, *.png)" }</Text>
                                    </View>}
                                    fileTypes={"application/pdf,image/jpeg,image/jpg,image/png"}
                                    fileTypeLabel={"pdf,jpeg,jpg,png"}
                                    onValueChange={this.onFileInputChange}
                                    attachmentFieldOuterCustomHeight={[styles.attachmentFieldOuterCustomHeight]}
                                    showErrorInModal={true}
                                    dropStyle={{ dropzone: { border: 'none' } }}
                                />

                                <Text style={[styles.attachmentCover]}></Text>
                            </View>
                            <View style={[styles.formBtnsHolder]}>
                                <Button
                                    title={"Cancel"}
                                    style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, commonStyles.btnWidth108, styles.flexBasis48]}
                                    titleTextStyle={[commonStyles.secondayWhiteBtnText, commonStyles.exSmallHeading, commonFontStyles.workSans]}
                                    onPress={() => this.onCancel()}
                                />
                                <SubmitButton
                                    title={!!item && !!item.checkNo ? "Update" : "Submit"}
                                    style={[commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.flexBasis48,  styles.customMarginLeft16]}
                                    titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, clientStyle.submitButtonText]}
                                />
                            </View>
                        </View>
                    </Form>

                </>
            );
        }
    }
);

const styles = StyleSheet.create({
    customDetailStyle: {
        padding: 16,
        paddingTop: 0,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexDirection: "column"
                };
            }
        },

    },
    customTextStyle: {
        marginRight: 30,
        flexGrow: 0,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    paddingBottom: 8,
                };
            }
        },
    },
    blueColor: {
        color: "#d71996"
    },




    leftInputHolder: {
        marginRight: 8,
        maxWidth: '49%',
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    maxWidth: '100%',
                    marginRight: 0,
                };
            }
        },
    },
    rightInputHolder: {
        marginLeft: 8,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 0,
                    marginBottom: 0,
                };
            }
        },
    },
    customFlexProperties: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: "auto",
                    // marginBottom: 30,
                    display:"block"
                };
            }
            if (width >= 991) {
                return {
                    justifyContent: "flex-end",
                };
            }
        },
    },
    inputHolder: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexDirection: "column",
                };
            }
        },
    },
    formBtnsHolder: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexBasis: "100%",
                    marginBottom: 16,
                    marginTop: 16,
                    justifyContent: "space-between",
                };
            }
        },
    },
    customAlignItemsStart: {
        alignItems: "baseline",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    alignItems: null,
                };
            }
        },
    },
    uploadBillsForm: {
        width: '100%',
        maxWidth: "100%",
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    flex: 1,
                    width: '100%',
                };
            }
            if (width <= 991) {
                return {
                    paddingTop: 0,
                    paddingLeft: 16,
                    paddingBottom: 16,
                    paddingRight: 16,
                    //   width: "90%"
                };
            }
        },
    },
    textAlign: {
        alignItems: "center",
        // height: "55%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginBottom: 5,
                    alignItems: "flex-start",
                };
            }
        },
    },
    width100: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    minWidth100: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minWidth: "100%",
                };
            }
        },
    },
    customMarginBottom30: {
        marginBottom: 24,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 15,
                };
            }
        },
    },

    filterContainer: {
        flex: 1,
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'rgba(21, 27, 38, 0.4)',
        borderStyle: 'solid',
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 16,
        paddingRight: 16,
        position: 'relative',
        marginBottom: 16,
        marginTop: 16,
        marginLeft: 16,
        marginRight: 16,
        maxWidth: "100%"
    },

    filterLabel: {
        position: 'absolute',
        top: -11,
        left: 8,
        backgroundColor: 'white',
        paddingLeft: 5,
        paddingRight: 5
    },
    marginBottom16: {
        marginBottom: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 32,
                };
            }
        },
    },
    marginTop10: {
        screen: ({ width }) => {
            if (width > 991) {
                return {
                    marginTop: 10,
                };
            }
        }
    },
    customMarginLeft16: {
        marginLeft: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 0,
                    marginRight: 0
                };
            }
        }
    },
    paymentStatusWrapper: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    paddingTop: 0,
                };
            }
        }
    },
    customRestMargin: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexBasis: "98%",
                };
            }
        }
    },
    attachmentField: {
        position: "relative",
        // right: -1,
        zIndex: 99,
        height: 24,
        backgroundColor: "#fff",
    },

    attachmentCard: {
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: "#a1a4a8",
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        width: "49%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 0,
                    minHeight: 65,
                    width: "100%",

                };
            }
        },
    },
    attachmentFieldOuterCustomHeight: {
        width: 280
    },
    flexBasis48:{
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    // marginRight: 16,
                    flexBasis: "48%",
                };
            }
        }
    }
});
