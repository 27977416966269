import React from "react";
import R14, {
  StyleSheet,
  Image,
  Text,
  View,
  Touchable,
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import ModalPopUp from "../components/ModalPopUp";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import ReactPlayer from 'react-player'
import HtmlDeCode from "../utils/HtmlDecode";
import { USER_TYPE } from "../constant/app.data.constant";

const trainingVideosIcon = require("../assets/images/trainingVideosIcon.png");
const userManualIcon = require("../assets/images/userManualIcon.png");
const backIcon = require("../assets/images/back_icon.png");
const attachedFileIcon = require("../assets/images/attachedFileIcon.png");

export default R14.connect(
  class HelpScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isDocVisible: false,
        claimRegistration: false,
        fileName: "",
        label:""
      };
      this.helpItems =
      !!props.userType && props.userType === USER_TYPE.PCH_PATIENT ?
      [
        { label: "XBP Claims - How to do patient Registration", fileName: "Patien_Claim_Registration.mp4", fileType: "MP4" },
        { label: "XBP Claims - How to patient Upload Bill", fileName: "Patient_Upload_Bill.mp4", fileType: "MP4" },
        { label: "XBP Claims - Patient User Manuals", fileName: "Patient_Claim_Registration.pdf", fileType: "PDF" },
      ]:
      !!props.userType && props.userType === USER_TYPE.PCH_PROVIDER ?
      [
        // { label: "XBP Claims - How to do practitioner Registration", fileName: "Claim_Registration.mp4", fileType: "MP4" },
        { label: "XBP Claims - How to practitioner Upload Bill", fileName: "Practitioner_Upload_Bill.mp4", fileType: "MP4" },
        { label: "XBP Claims - Practitioner User Manuals", fileName: "Practitioner_Claim_Registration.pdf", fileType: "PDF" },
      ]:
      [
        { label: "XBP Claims - How to do patient Registration", fileName: "Patien_Claim_Registration.mp4", fileType: "MP4" },
        // { label: "XBP Claims - How to do practitioner Registration", fileName: "Claim_Registration.mp4", fileType: "MP4" },
        { label: "XBP Claims - User Manuals", fileName: "XBP_Claims_PDD_v2.pdf", fileType: "PDF" },
      ]
    }

    gotTomainSection= async () => {
      this.props.app.nav.back();
      return false;
    }

    openDoc = (fileName) => {
      this.setState({
        isDocVisible: !this.state.isDocVisible,
        fileName: fileName
      })
    }
    openVideo = (fileName, label) => {
      this.setState({
        claimRegistration: !this.state.claimRegistration,
        fileName: fileName,
        label: label
      })
    }

    onPDFClose = () => {
      this.setState({ isDocVisible: false, fileName:"",claimRegistration: false });
    };

    onDownloadFile = async () => {
      const { fileName } = this.state;
      const paylod = {
        content: `${window.location.origin}/documents/${fileName}`,
        contentType: 'PDF',
        filename: fileName
      }
      await HtmlDeCode.downloadFile(paylod);
      this.setState({ isDocVisible: false });
      return false;
    }

    rendermanualUserPdfViewer() {
      const { isDocVisible, fileName } = this.state;
      return (
        <ModalPopUp
          visibility={isDocVisible}
          isFooter={true}
          bodyWrapper={[commonStyles.largModalBodyWrapper]}
          heading={<View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
            <Text style={[commonStyles.heading5, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.transformCapitalize, commonStyles.titleTextColor]}>View Document</Text>
            <Touchable style={[styles.downloadFileIcon]} onPress={() => this.onDownloadFile()}>
              <View>
                <Image source={attachedFileIcon} style={commonStyles.icon16} />
              </View>
            </Touchable>
          </View>}
          handleCancel={this.onPDFClose}
          headerStyle={[commonStyles.paddingLeftRight16, commonStyles.paddingTop10]}
          modalHeading={[]}
          animationType="fade">
          <View>
            
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={[{ uri: `${window.location.origin}/documents/${fileName}` }]}
              style={{ width: "70vw", height: 400, marginTop: 0, marginRight: "auto", marginBottom: 0, marginLeft: "auto" }
              }
              config={{
                header: {
                  disableHeader: false,
                  retainURLParams: false,
                },
              }}>
            </DocViewer>
          </View>
        </ModalPopUp>
      );
    }

    renderClaimRegistrationVideo() {
      const { claimRegistration, fileName, label } = this.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <ModalPopUp
          visibility={claimRegistration}
          isFooter={true}
          bodyWrapper={[commonStyles.exLargModalBodyWrapper]}
          heading={label}
          handleCancel={this.onPDFClose}
          animationType="fade"
          modalContent= {styles.marginTopBottom0}
          headerStyle={commonStyles.marginBottom10}>
          <View>
            <ReactPlayer
              className="react-player"
              width="100%"
              height={isMobileDevice ? "auto" : "auto"}
              controls={true}
              autoPlay={true}
              playing
              url={`${window.location.origin}/documents/${fileName}`}
            />
          </View>
        </ModalPopUp>
      );
    }

    render() {
      const { userType } = this.props;
      return (

        <View style={[styles.helpDetailSection]}>
          <View>
            {!!userType ? null :
              <Touchable style={[styles.backBtn]} onPress={this.gotTomainSection}>
                <View>
                  <Image source={backIcon} style={[commonStyles.icon20]} />
                </View>
              </Touchable>}
            <View style={[commonStyles.marginTop20, commonStyles.flex1, styles.flexDirectionCustom, commonStyles.flexWrap]}>
              {this.helpItems.map((item, i) => {
                return (
                  item.fileType === "MP4" ?
                    <Touchable key={`helpitem_${i}`} style={[styles.videos, commonStyles.flexGrow0,]} onPress={() => this.openVideo(item.fileName, item.label)}>
                      <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.justifyContentCenter]}>
                        <Image source={trainingVideosIcon} style={[commonStyles.icon48, commonStyles.marginRight30, commonStyles.marginLeft30]} />
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight500, commonStyles.marTop40, commonStyles.textAlignCenter]}>{item.label}</Text>
                      </View>
                    </Touchable> :
                    <Touchable key={`helpitem_${i}`} style={[styles.videos, commonStyles.flexGrow0]} onPress={() => this.openDoc(item.fileName)}>
                      <View style={[commonStyles.flex1, commonStyles.alignItemsCenter, commonStyles.justifyContentCenter]}>
                        <Image source={userManualIcon} style={[commonStyles.icon48, commonStyles.marginRight30, commonStyles.marginLeft30]} />
                        <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight500, commonStyles.fullWidth, commonStyles.marTop40, commonStyles.textAlignCenter]}>{item.label}</Text>
                      </View>
                    </Touchable>
                )
              })}
            </View>
          </View>
          {this.rendermanualUserPdfViewer()}
          {this.renderClaimRegistrationVideo()}
        </View>

      );
    }
  }
);

const styles = StyleSheet.create({
  pageTitleHolder: {
    zIndex: 1,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
  },
  helpDetailSection: {
    backgroundColor: "#fff",
    paddingTop: 16,
    paddingLeft: 40,
    paddingBottom: 16,
    paddingRight: 16,
    marginRight: 16,
    marginLeft: 16,
    height: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#00000029",
    flex: 1,
    flexGrow: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingLeft: 25,
          marginRight: 0,
          marginLeft: 0,
          top: 0,
          height: "auto",
        };
    },
  },
  helpBox: {
    borderWidth: 1,
    borderColor: "#dadada",
    borderStyle: "solid",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    minHeight: 132,
    maxHeight: 132,
    alignItems: "center",
    flex: 1,
    paddingTop: 24,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    textAlign: "center",
  },
  videos: {
    borderWidth: 1,
    borderColor: "#dadada",
    borderStyle: "solid",
    borderRadius: 8,
    minHeight: 170,
    marginRight: 16,
    flex: 1,
    flexGrow: 0,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 16,
    paddingRight: 16,
    // minWidht: 192,
    flexBasis: "20%",
    minWidth: "30%",
    marginBottom: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 16,
          marginBottom: 16,
        };
    },
  },
  backBtn: {
    display: "inline-flex",
  },
  VideoViewBodyWrapper: {
    width: "98vw",
    borderRadius: 6,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: 300,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
        };
      }
    },
  },
  flexDirectionCustom: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 639)
        return {
          flexDirection: "column",
        };
    },
  },
  downloadFileIcon: {
    display: "inline-flex",
    padding: 5,
  },
  marginTopBottom0:{
    marginBottom:0,
    marginTop: 0
  }
});
