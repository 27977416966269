import React from "react";
import R14, { View, Text, StyleSheet, Touchable } from "../../core";
import { STRIPE_KEY } from "../../constant/app.data.constant";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentElementComponent from "./PaymentElementComponent";

import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { responsiveTableStyle } from "../../assets/css/responsiveTableStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";

export default R14.connect(
  class PaymentCheckoutComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        stripeDetails: {},
        clientSecret: ''
      };
    }

    componentDidMount = async () => {
      const { paymentOptions, pchClaim } = this.props;
      let stripeDetails = !!pchClaim ? STRIPE_KEY : { ...STRIPE_KEY, ...paymentOptions };
      stripeDetails.pchClaim = pchClaim;
      this.setState({ stripeDetails: stripeDetails });
    };

    onProcessIntent = async () => {
      const { user, amtPayable, currencyCode, pchClaim } = this.props;
      let payload = {
        tokendetails: {
          cardtoken: user.uid || "",
          payment_intent: 0,
          merchantAccountNumber: "",
          isPchClaim: pchClaim
        },
        transactiondetails: {
          total_amount: parseFloat(amtPayable),
          currency: currencyCode,
          purchase_level: 1,
          transaction_date: new Date(),
          transaction_description: `bill payment `,
        },
        billingaddress: {
          billing_username: user.pid,
          user_email: user.email,
          phone_Number: user.mobileNumber,
          user_address1: user.addressLine1 || "",
          user_address2: user.addressLine2 || "",
          user_zip: user.postCode || "",
          user_city: (user.cityObject && user.cityObject.label ? user.cityObject.label : user.town || ""),
          user_state: (user.stateObject && user.stateObject.label ? user.stateObject.label : user.state || ""),
        },
      };
      let response = {};
      response = await this.props.app.dm.billDetails.stripePaymentCheckout(payload);
      this.props.handleCheckout();
      this.setState({
        clientSecret: response.transactionId,
        customerId: response.customer,
        customerEphemeralKeySecret: response.ephemeralKey,
      });
    }

    render() {
      let { clientSecret } = this.state;
      const { isPaymentCheckout, btnText, bill } = this.props;
      if (!!bill) {
        clientSecret = !clientSecret ? (!!bill.clientSecret ? bill.clientSecret : clientSecret) : clientSecret;
        // delete bill.clientSecret;
        delete bill.customerId;
        delete bill.loadcustomerEphemeralKeySecret;
      }
      let stripePromise;
      if (this.state.stripeDetails && this.state.stripeDetails.publishableKey) {
        stripePromise = loadStripe(this.state.stripeDetails.publishableKey);
      };
      const appearance = {
        theme: 'night',
      
        variables: {
          colorPrimary: '#0570de',
          colorBackground: '#ffffff',
          colorText: '#30313d',
          colorDanger: '#df1b41',
          fontFamily: 'Work Sans',
          fontSizeBase:"15px",
          // spacingUnit: '2px',
          borderRadius: '4px',
          textTransform: "capitalize",
          boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0)'
          // See all possible variables below
        },
        rules:{
          '.Accordion': {
            // borderWidth: '0px',
            // boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
          },
          '.AccordionItem': {
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
            borderWidth:"1px",
            borderColor:"#e2e2e2",
            borderStyle:"solid",
            borderRadius: '5px',
          },
          '.Label':{
            fontFamily: 'Work Sans',
            fontSize:"14px",
            fontWeight: 400,
            textTransform: "capitalize",
            color:"#151b26",
            colorText:"#151b26"
          },
          '.Input':{
            fontFamily: 'Work Sans',
            fontSize:"13px",
            fontWeight: 400,
            borderRadius: '5px',
            boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0)',
            height:"30px",
            borderWidth:"1px",
            borderColor:"#e2e2e2",
            borderStyle:"solid",
            minHeight: "30px",
            padding:"6px"
          },
          '.Input:focus':{
            fontFamily: 'Work Sans',
            fontSizeBase:"13px",
            borderRadius: '5px',
            fontWeight: 400,
            boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0)',
            borderWidth:"2px",
            borderColor:"#397EA0",
            borderStyle:"solid",
          },
          '.Input--invalid': {
            boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0)',
          },
        }
      };
      return (
        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexWrap, commonStyles.flexGrow0, commonStyles.alignItemsCenter, commonStyles.marginRight16]}>
          {!!clientSecret && !!stripePromise && !!isPaymentCheckout ? <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
            <PaymentElementComponent
              props={this.props}
              handleCheckout={this.props.handleCheckout}
              bill={bill}
            />
          </Elements>
            :
            !btnText && <Touchable
              style={!!btnText ? null : [responsiveTableStyle.paynowBtn, styles.actionBtnWidth, commonStyles.btnHeight24, commonStyles.bgTransparent]}
              onPress={() => this.onProcessIntent()}>
              <View style={[responsiveTableStyle.paynowBtnInnerWrap, customCommonStyles.customPaynowBtnInnerWrap, styles.btnWidth]}>
                <Text style={[commonStyles.primaryBtnText, commonFontStyles.workSans, commonStyles.textAlignCenter, commonStyles.fullWidth,]}>Make Payment</Text>
              </View>
            </Touchable>
          }
        </View>
      )
    }
  }
);

const styles = StyleSheet.create({
  actionBtnWidth: {
    width: 125,
    height: 38,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "50%"
        };
      }
    },
  },
  btnWidth: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: 125,
          maxWidth: 125
        };
      }
    },
  },
});
