import React, { Component } from "react";
import R14, { StyleSheet, Text, View, Platform, AsyncStorage } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import SubmittedComponent from "../../components/PCH/SubmittedComponent";
import DateUtils from "../../utils/DateUtils";

export default R14.connect(
  class SubmittedSmbReceiptScreen extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
      };
    }

    componentDidMount() {
      this.onPaymentDone();
      AsyncStorage.removeItem("smbReceipts");
    }
    onPaymentDone = async () => {
      const {uploadBill, referenceNo, transactionId, user} = this.props;
      const payload = {
        "pid": user.pid,
        "type":"bills",
        "invoiceId": `${uploadBill.invoiceID}`,
        "paymentAmount": uploadBill.dueAmount,
        "bankCharges":"0.00",
        "paymentDate": DateUtils.formatDateInYYYYMMDD(new Date()), //"2023-07-20"
        "paymentMode":"Cash",
        "referenceNo": referenceNo || "",
        "notes":"",
        "paymentMethod": "Stripe",
        "paymentType": "Net",
        "transactionId": `${transactionId}`,
        "transcationRemarks": "Success",
        "transcationStatus": "Success",
        "from": "Mobile App"
      }
      const markAsPaid = await this.props.app.dm.smbApi.saveSmbMarkAsPaidPayment(payload);
      if(!!markAsPaid) this.props.app.dm.commonDomain.showToastr( "success", `Invoice No. ${uploadBill.invoiceNo}, successfully paid.`);
      return false;
    }
    render() {
      let { userType, uploadBill } = this.props;
      let message = "Bill paid successfully.";
      let commonFontStyles = fontFamilyStyles;
      return (
        <View
          style={[
            commonStyles.marginBottom15,
            styles.billDetailsScreen,
          ]}>
          <View style={[commonStyles.flex1, styles.customflexDirection, commonStyles.flexGrow0]}>
            <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
              <View>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.heading6,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}>
                  Vendor Name
                </Text>
              </View>
              <View>
                <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                  {uploadBill.businessName}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
              <View>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.heading6,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}>
                  ExtRef No.
                </Text>
              </View>
              <View>
                <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                  {uploadBill.extRefNo}
                </Text>
              </View>
            </View>
          </View>

          <View style={[commonStyles.flex1, styles.customflexDirection, styles.customMarginTop65, commonStyles.flexGrow0]}>
            <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
              <View>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.heading6,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}>
                  Invoice#
                </Text>
              </View>
              <View>
                <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                  {uploadBill.invoiceNo}
                </Text>
              </View>
            </View>
            <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
              <View>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.heading6,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}>
                  Due Amount
                </Text>
              </View>
              <View>
                <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                  {uploadBill.dueAmount}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.customMarginTop65]}>
            <SubmittedComponent message={message} customMargin={styles.customMargin} userType={userType} goTo={"makePaymentsRoute"} goToTitle={"Go to Make Payment"} />
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  billDetailsWrapper: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          backgroundColor: "#FFF",
        };
    },
  },
  pageTitle: {
    margin: 16,
  },
  billDetailsScreen: {
    flex: 1,
    flexGrow: 0,
    // alignItems: "flex-end",
    backgroundColor: "#fff",
    padding: 15,
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          marginRight: 0,
          marginLeft: 0,
        };
    },
  },
  redClr: {
    color: "#CC0000",
  },

  detailRow: {
    borderBottomWidth: 1,
    borderBottom: "1px solid rgb(218, 218, 218)",
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
    paddingBottom: 5,
    marginBottom: 15,
    flexBasis: "48%",
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginBottom: 10,
          height: 20,
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    flexGrow: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
          flexGrow: 0,
          height: 50,
        };
      }
    },
  },
  mViewMarginBottom: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 25,
          paddingTop: 0,
        };
      }
    },
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMargin: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 5,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  border0: {
    borderBottomWidth: 0,
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
    paddingBottom: 5,
    marginBottom: 15,
    flexBasis: "48%",
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          borderBottomWidth: 0,
          flexBasis: "100%",
          marginBottom: 10,
        };
      }
    },
  },
  customMarginTop65: {
    screen: ({ width }) => {
      if (width <= 991 && Platform.OS !== "web") {
        return {
          marginTop: 65,
        };
      }
    },
  },
});
