import React from "react";
import { View } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { CHART_TYPE } from "../../constant/app.data.constant";
import BarChartComponent from "./BarChartComponent";
import PieChartComponent from "./PieChartComponent";
import LineChartComponent from "./LineChartComponent";
/* eslint-disable no-unused-vars */
import { Chart as ChartJs } from "chart.js/auto";

export default class ChartComponent extends React.Component {
    render() {
        const { chartData, chartTypes, chartText, customContainer } = this.props;
        return (
            <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.flexWrap, commonStyles.marTop40]}>
                {
                    chartTypes.includes(CHART_TYPE.PIECHART) ?
                        <PieChartComponent customContainer = {customContainer} chartData={chartData} chartText={chartText}/> : null
                }
                {
                    chartTypes.includes(CHART_TYPE.LINECHART) ?
                        <LineChartComponent customContainer = {customContainer} chartData={chartData} chartText={chartText}/> : null
                }
                {
                    chartTypes.includes(CHART_TYPE.BARCHART) ?
                        <BarChartComponent customContainer = {customContainer} chartData={chartData} chartText={chartText}/> : null
                }
            </View>
        )
    }
}