import { StyleSheet } from "../../core";
const commonMetricsStyle = StyleSheet.create({
    helperTextFontStyle: {
        fontSize: 10,
        fontWeight: 400,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 10
              };
            }
          },
      },
      socialLoginText: {
        fontSize: 14,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 12
              };
            }
          },
      },
      heading1: {
        fontSize: 24,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 22
              };
            }
          },
      },
      heading2: {
        fontSize: 22,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 20
              };
            }
          },
      },
      heading3: {
        fontSize: 20,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 18
              };
            }
          },
      },
      heading4: {
        fontSize: 18,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 16
              };
            }
          },
      },
      heading5: {
        fontSize: 16,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 14
              };
            }
          },
      },
      heading6: {
        fontSize: 14,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 12
              };
            }
          },
      },
      heading7: {
        fontSize: 16,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 14
              };
            }
          },
      },
      smallHeading: {
        fontSize: 12,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 10
              };
            }
          },
      },
      exSmallHeading: {
        fontSize: 11,
        screen: ({ width }) => {
            if (width <= 420) {
              return {
                fontSize: 10
              };
            }
          },
      },
    
})
export { commonMetricsStyle }; 