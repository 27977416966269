import React from "react";
import R14 from "../../core";
import { authorizationEndpoint,NATIVE_APP_URL, URL_PATH } from "../../constant/app.constant";
import UserAgentValidator from "../../utils/UserAgentValidator";
import { USER_TYPE } from "../../constant/app.data.constant";

export default R14.connect(
  class KCAuthSessionComponent extends React.Component {

    promptAsync = async () => {
      const { source, message, signInLocation, app, clientId } = this.props;
      let url = `${authorizationEndpoint}?response_type=code&client_id=${clientId}&scope=openid`;
      let returnUrl = app.route._location.search
      if(returnUrl.indexOf('ReturnUrl') === -1)
      returnUrl = "";
      
      if (signInLocation === USER_TYPE.XBP) { 
        url = `${url}&redirect_uri=${encodeURIComponent(NATIVE_APP_URL)}/${URL_PATH}/payer${returnUrl}&application_url=${encodeURIComponent(NATIVE_APP_URL)}`;
      }
      else
      {
        url = `${url}&redirect_uri=${encodeURIComponent(NATIVE_APP_URL)}/${URL_PATH}/claims${returnUrl}&application_url=${encodeURIComponent(NATIVE_APP_URL)}/claims`;
      }
      
      if (source === "changePwd") { // In case change password
        url = `${url}&kc_action=UPDATE_PASSWORD`;
      }
      if(!!message) // Use for custom message
        url = `${url}&error_message=${message}`;
        
      UserAgentValidator.fallback(url, "_self");
      return false;
    }

    render() {
      this.promptAsync();
      return (
       <></>
      );
    }
  });