import React, { useState } from "react";
import { View, Text, Touchable, AsyncStorage, StyleSheet, Platform } from '../../core';
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { CommonString } from "../../constant/appCommonText";

export default function PaymentElementComponent({ props, handleCheckout, bill }) {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleSubmit = async (e) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!!bill && !!bill.sepaBillDetail) {
      bill.sepaBillDetail.paymentMode = paymentMethod;
    }
    
    bill.paymentMode = paymentMethod;
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS, JSON.stringify(bill));

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: (!!props && !!props.successUrl) ? props.successUrl : (!!props && !!props.path) ? `${window.location.origin}/${props.path}` : `${window.location.origin}/smb/submitted-receipt`,
      },
    });


    if (error.type === "card_error" || error.type === "validation_error" || error.type === "invalid_request_error") {
      AsyncStorage.removeItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
      props.app.dm.commonDomain.showToastr("error", error.message);
      return false;
    } else {
      AsyncStorage.removeItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
      props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
    }
    AsyncStorage.removeItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
    handleCheckout("close");
  };

  const onChange = async (e) => {
    setPaymentMethod((!!e && !!e.value && e.value.type.replace(/[^a-zA-Z]+/g, ' ').toUpperCase()) || "");
  }

  const options = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true
    },
    wallets: {
      applePay: 'auto',
      googlePay: 'auto'
    },
    defaultValues: {
      billingDetails: {
        name: props.user.firstName,
        email: props.user.email,
        phone: props.user.mobileNumber,
        address: {
          line1: props.user.addressLine1 || "",
          line2: props.user.addressLine2 || "",
          // postal_code: props.user.postCode || "",
          // city: (props.user.cityObject && props.user.cityObject.label ? props.user.cityObject.label : props.user.town || ""),
          // state: (props.user.stateObject && props.user.stateObject.label ? props.user.stateObject.label : props.user.state || ""),
          // country: (props.user.stateObject && props.user.stateObject.label ? props.user.stateObject.label : props.user.state || ""),
        }
      }
    },
    terms: {
      sepaDebit: "never",
      usBankAccount: "never"
    },
    supportedCountries: ["SEPA"]
  };


  return (

    <View style={[commonStyles.flex1, commonStyles.flexDirectionColumn]}>
      <PaymentElement id="payment-element" options={options} onChange={(e) => onChange(e)} style={{ width: "100%" }} />
      <View style={[commonStyles.marginTop16, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.fullFlexContainer]}>
        <Touchable
          style={[commonStyles.flexBasis49]}
          onPress={() => handleCheckout("close")}>
          <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, commonStyles.textAlignCenter, styles.secondaryButton]}>
            <Text style={[commonFontStyles.workSans,
            commonStyles.fontWeight500,
            commonStyles.exSmallHeading,
            commonStyles.whiteColor,
            commonStyles.secondayWhiteBtnText,
            Platform.OS === 'web' && styles.fullWidth,
            commonStyles.textAlignCenter
            ]}>Cancel</Text>
          </View>
        </Touchable>

        <Touchable
          style={[commonStyles.marginLeft16, commonStyles.flexBasis49]}
          onPress={(e) => handleSubmit(e)}>
          <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, commonStyles.textAlignCenter, styles.blueButton]}>
            <Text style={[commonFontStyles.workSans,
            commonStyles.fontWeight500,
            commonStyles.exSmallHeading,
            commonStyles.whiteColor,
            Platform.OS === 'web' && styles.fullWidth,
            commonStyles.textAlignCenter
            ]}>Pay Now</Text>
          </View>
        </Touchable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  fullWidth: {
    width: "100%",
  },
  blueButton: {
    flexGrow: 1,
    backgroundColor: "#d71996",
    minHeight: 24,
    height: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  secondaryButton: {
    flexGrow: 1,
    backgroundColor: "#fff",
    minHeight: 24,
    height: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "rgb(18, 119, 217)"
  },
});
