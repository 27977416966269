import React from "react";
import R14, {
  View,
  StyleSheet,
  Colors,
  Touchable,
  Image,
  Platform
} from "../core";
import UserAgentValidator from "../utils/UserAgentValidator";
import { commonStyles } from "../assets/css/commonStyle";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

const filterIcon = require("../assets/images/NewFilterIcon.png");

export default R14.connect(
  class FilterIconComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isSortModalActive: false,
        isFilterOpen: false,
        //  isSearchOpen: false,
        isSearchHistoryOpen: false,
        selectedHistoryString: "",
        isInactive: true,
        bShowConcurrentLoginPopUp: false,
        lastReadDateTime: new Date(),
        lastReadNotificationTime: null,
      };
      this.currentScreen = "";
      this.oldScreen = "";
      this.searchHistory = [];
      this.localSearchHistory = [];
      //For Idle Timer.
      this.userLoginDetail = {};
      this.billerList = [];
    }

    async initialize() {
      const { userLoginDetail } = this.props.app.dm.userSession.state;
      this.userLoginDetail = await this.props.app.dm.rest.getUser();
      if (!!!this.userLoginDetail)
        this.userLoginDetail = userLoginDetail;
      this.billerList = await this.props.app.dm.rest.getBillers();

      this.setState({ initialized: true });
    }

    componentDidMount = async () => {
      this.initialize();
      this.props.app.dm.notificationDomain.setState({
        isMobileDevice: UserAgentValidator.isMobileDevice(),
      });
    }
    
    onFilterOpen = () => {
      analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.FILTER_OPENED_ACTION, this.props.app);
      this.props.app.dm.dashboard.setState({
        isBlockNavigation: false,
      });
      this.props.app.dm.userSearchDomain.setState({
        isSearchOpen: false,
        textInSearchBox: "",
        currentOpenScreen: this.currentScreen.name,
      });
      this.props.app.dm.filterDomain.setState({ isFilterOpen: true });
    };

    render() {
      if(!!this.props.app.dm.userSession.state.isUberDocs) return null;
      this.currentScreen = this.props.app.nav.route;

      return (
        <View style={[styles.headerControlsRight, Platform.OS !== "web" ? commonStyles.marginLeft10 : null]}>
          <Touchable onPress={this.onFilterOpen}>
            <View>
                <Image source={filterIcon} style={commonStyles.icon18} />
            </View>
          </Touchable>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  headerControlsRight: {
    paddingRight: 15,
    position: "relative",
  },
  searchBill: {
    position: "absolute",
    width: 400,
    right: 84,
    top: 45,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100vw",
          right: 0,
          top: -16,
          // right: 40,
        };
    },
  },
  textFieldOuter: {
    color: "#fff",
  },
  messageTextField: {
    // height: 48,
  },
  accountMenu: {
    width: 256,
  },
  header: {
    flex: 1,
    flexDirection: "column",
    borderBottomWidth: 1,
    minWidth: "auto",
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.1),
    borderStyle: "solid",
    boxShadow: "none",
    paddingTop: 0,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 12,
  },
  headerClientText: {
    fontSize: 14,
    fontWeight: "300",
  },
  menuIconsInprofilesection: {
    height: 18,
    width: 18,
    display: "block",
    backgroundSize: "contain",
  },
  makePayment: {},
  notificationCount: {
    lineHeight: 8,
  },
  block: { display: "block" },
  none: { display: "none" },
  noStyle: {},
  sessionExpirPopupBodyWrapper: {
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          fontWeight: 600,
        };
    },
  },
  modalOuterWrapper: {
    width: "auto",
    backgroundColor: "#0000005c",
  },
  closeBtn: {
    position: "absolute",
    right: 0,
    top: 10,
  },
  sessionModelHeaderStyle: {
    color: "#4DC5F2",
    paddingTop: 8,
  },
  sessionModelOkBtn: {
    width: 100,
  },
  btnTexClr: {
    color: "#fff",
  },
  autoCompleteBox: {
    boxShadow: "0px 3px 10px -5px #000",
    border: "1px solid #9ca6ad",
    marginTop: -4,
    marginRight: 4,
    marginBottom: 0,
    marginLeft: 4,
    borderTop: 0,
  },
  addProfilePopupMenu: {
    // marginRight: -15,
  },
  profileRightSection: {
    justifyContent: "center",
  },
  profileIconWrapper: {
    width: 40,
    height: 40,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    backgroundColor: "#e2e2e2",
  },
  profileIcon: {
    width: 40,
    height: 40,
    minWidth: 40,
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  buttons: {
    height: 38,
  },
  titleColor: {
    color: "#38c2ef",
  },
  profileSection: {
    borderRadius: 60,
    backgroundColor: "#e0e0e0",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: 40,
    minWidth: 40,
    height: 40,
  },
  profileSectionInner: {
    marginTop: -20,
    backgroundColor: "#e0e0e0",
    borderWidth: 1,
    borderRadius: 40,
    borderStyle: "solid",
    borderColor: "#fff",
    width: 40,
    minWidth: 40,
    height: 40,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
  },
  profileDetailSectionWrap: {
    position: "relative"
  },
  profileDetailSection: {
    backgroundColor: "#fff",
    position: "absolute",
    width: 250,
    right: 2,
    boxShadow: "0px 3px 6px rgb(8 8 8 / 21%)",
    // box- shadow: rgb(8 8 8 / 21 %) 0px 3px 6px;
    borderRadius: 4,
    top: 41,
    flex: 1,
    flexDirection: "column",
    zIndex: 999,
  },
  blueBrackground: {
    background: "#48c2ff",
    height: 45,
    width: "100%",
    borderRadius: "4px 4px 0 0",
  },
  profileSectionLinks: {
    padding: 16,
    width: "100%",
    color: "#182832",
  },
  linkColor: {
    color: "#182832",
  },
  profileSectionName: {
    marginTop: 8,
    textAlign: "center",
    borderBottom: "1px solid #dfdfdf",
    paddingBottom: 8,
    wordBreak: "break-all",
    paddingLeft: 10,
    paddingRight: 10,
  },
  styleForMobileProvider: {
    width: 215,
  },
  styleForMobilePatient: {
    width: 160,
  },
  profileOverlaySection: {
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 99,
    left: 0,
    top: 56,
  },
  searchBillInnerWrap: {
    minHeight: 48,
  },
  marginLeft24: {
    marginLeft: 22,
  },
  threeDigiNotification: {
    minWidth: 22,
    left: 8,
  },
  fourDigiNotification: {
    minWidth: 26,
    left: 4,
  },
});
