import React, { PureComponent } from "react";
import { StyleSheet, Image, View, Text, Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";

export default class LightBlueRadioButtons extends PureComponent {
  render() {
    const { options, onValueChange, isSelected, radioButtonsClass, imgClass } = this.props;
    let imgpath = isSelected === options.value ? require("../assets/images/radio_on_light.png") : require("../assets/images/radio_off_light.png");
    return (
      <Touchable onPress={() => onValueChange(options.value)}>
        <View style={[styles.buttonContainer, radioButtonsClass]}>
        <View>
          <Image resizeMode="contain" style={[styles.imgCls, styles.checkMark, imgClass]} source={imgpath} />
        </View>
        <View>
          <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>{options.label}</Text>
        </View>
        </View>
      </Touchable>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    paddingTop: 6,
    paddingRight: 8,
    paddingBottom: 6,
    paddingLeft: 8,
  },
  imgCls: {
    flex: 1,
    marginRight: 10,
  },
  checkMark: {
    height: 20,
    width: 20,
  },
});
