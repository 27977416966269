import React, { useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Touchable,
  IconButton,
  Platform,
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
const CustomPopupComponent = ({ menuItem, handleClick, onClickPopupMenu, leftMenuOverlay  }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const onPressOpenMenu = () => {
    if(!!onClickPopupMenu && !!leftMenuOverlay)
        onClickPopupMenu();
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <View
      style={[
        Platform.OS !== "web"
          ? styles.dropdownMenuHolderNative
          : styles.dropdownMenuHolderWeb,
      ]}
    >
      <IconButton
        icon="dotsVertical"
        size="small"
        style={
          Platform.OS !== "web" ? commonStyles.justifyContentFlexEnd : null
        }
        onPress={() => onPressOpenMenu()}
      />

      {isOpenMenu ? (
        <View
          style={[
            Platform.OS === "web"
              ? styles.dropdownMenuWeb
              : styles.dropdownMenuNative,
          ]}
        >
          {menuItem.map((item, key) => (
            <Touchable key={`__${key}`} style={[styles.dropdownMenuItem]} onPress={() => handleClick(item.value)}>
                <Text
                  style={[commonStyles.exSmallHeading, commonFontStyles.workSans]}
                >
                  {item.label}
                </Text>
            </Touchable>
          ))}
        </View>
      ) : null}
    </View>
  );
};

export default CustomPopupComponent;

const styles = StyleSheet.create({
  dropdownMenuHolderNative: {
    top: 0,
    right: 0,
    width: 40,
    elevation: 3,
    position: "absolute",
    zIndex: 99999,
  },
  dropdownMenuHolderWeb: {
    position: "relative",
    zIndex: 999,
  },
  dropdownMenuNative: {
    right: 130,
    top: -5,
    width: 150,
    maxWidth: 200,
    zIndex: 99999,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccc",
    backgroundColor: "#fff"
  },
  dropdownMenuWeb: {
    position: "absolute",
    top: 30,
    right: 0,
    width: 150,
    maxWidth: 250,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccc",
  },
  dropdownMenuItem: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccc",
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
  },
});
