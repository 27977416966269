import React from "react";
import R14, { StyleSheet,  Image, Text, View, Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import ChatInputField from "../components/ChatInputField";
import MessageComponent from "../components/MessageComponent";
import BottomPaySection from "../components/BottomPaySection";
import { BILL_STATUS, MSG_TYPE, RTP_STATUS, MESSAGE_TYPE } from "../constant/app.data.constant";
import BillerDetails from "../components/BillerDetails";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import PopupModalComponent from "../components/PopupModalComponent";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

const midumChatBlackIcon = require("../assets/images/midumChatBlackIcon.png");
const defaultSec = 30;

export default R14.connect(
  class BillDetailsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        billDetails: this.props.billDetails,
        height: 0,
        messages: this.props.messages.messages.filter((element) => !element.envelope.errorBody),
        msgCount: 0,
        isChatAllow: this.props.messageFlag,
        isAccountLockedModalVisible: false,
        lastReadDateTime: null
      };
      this.isBook = null;
    }
    fullMessageWindow = () => {
      let newThread = this.props.billDetails.threadId; //.replaceAll("#", ">>");
      this.props.app.nav.to("messageScreenRoute", { threadId: newThread });
    };

    async componentDidMount() {
      const { billDetails } = this.props;
      this.timerInstance = setInterval(async () => {
        const billRes = await this.props.app.dm.dashboard.getBillerFromThread(billDetails.threadId, true);
        if (!!billRes) {
          let bd = this.props.app.dm.dashboard.manageBillDetails(billRes.messages);
          if (!!bd) {
            let saveDBPayload = await this.props.app.dm.dashboard.payloadForGraphQl(bd);
            delete saveDBPayload.payedAt;
            await this.props.app.dm.dashboard.updateBill({ ...saveDBPayload, uid: billDetails.uid, paymentOption: bd.paymentOptions });
            const res = await this.props.app.dm.dashboard.getBills({ filter: { threadId: { eq: billDetails.threadId } } });
            let complileBill = !!res && res.length > 0 ? { ...bd, ...res[0] } : {...bd};
            const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;
            if (!!compiledMsgList.length && !!filterBills.length) {
              let ind = compiledMsgList.findIndex((el) => el.threadId === complileBill.threadId);
              compiledMsgList[ind].billStatus = complileBill.billStatus;
              compiledMsgList[ind].paymentStatus = complileBill.paymentStatus;
              compiledMsgList[ind].dueDate = complileBill.dueDate;
              compiledMsgList[ind].amountDue = complileBill.amountDue;
              compiledMsgList[ind].amountPaid = complileBill.amountPaid;
              compiledMsgList[ind].rtpMessageType = complileBill.rtpMessageType;
              compiledMsgList[ind].fullPaidAndDeclined = complileBill.fullPaidAndDeclined;
              compiledMsgList[ind].failReason = complileBill.failReason;
              compiledMsgList[ind].isFailedPayment = complileBill.isFailedPayment;
              compiledMsgList[ind].failDate = complileBill.failDate;
              //new 
              if (complileBill.rtpMessageType === MSG_TYPE.REQ_PAY_EXT) {
                compiledMsgList[ind].isExtentionRequested = true;
                compiledMsgList[ind].isExtentionApproved = false;
                compiledMsgList[ind].isExtentionDeclined = false;
              }
              else if (complileBill.rtpMessageType === MSG_TYPE.EXT_GRANTED) {
                compiledMsgList[ind].isExtentionApproved = true;
                compiledMsgList[ind].isExtentionRequested = false;
                compiledMsgList[ind].isExtentionDeclined = false;
              }
              else if (complileBill.rtpMessageType === MSG_TYPE.EXT_DECLINED) {
                compiledMsgList[ind].isExtentionDeclined = true;
                compiledMsgList[ind].isExtentionRequested = false;
                compiledMsgList[ind].isExtentionApproved = false;
              }

              let fndx = filterBills.findIndex((el) => el.threadId === complileBill.threadId);
              filterBills[fndx].billStatus = complileBill.billStatus;
              filterBills[fndx].paymentStatus = complileBill.paymentStatus;
              filterBills[fndx].dueDate = complileBill.dueDate;
              filterBills[fndx].amountDue = complileBill.amountDue
              filterBills[fndx].amountPaid = complileBill.amountPaid
              filterBills[fndx].rtpMessageType = complileBill.rtpMessageType;
              filterBills[fndx].fullPaidAndDeclined = complileBill.fullPaidAndDeclined;
              filterBills[fndx].failReason = complileBill.failReason;
              filterBills[fndx].isFailedPayment = complileBill.isFailedPayment;
              filterBills[fndx].failDate = complileBill.failDate;
              // new 
              if (complileBill.rtpMessageType === MSG_TYPE.REQ_PAY_EXT) {
                filterBills[fndx].isExtentionRequested = true;
                filterBills[fndx].isExtentionApproved = false;
                filterBills[fndx].isExtentionDeclined = false;
              }
              else if (complileBill.rtpMessageType === MSG_TYPE.EXT_GRANTED) {
                filterBills[fndx].isExtentionApproved = true;
                filterBills[fndx].isExtentionRequested = false;
                filterBills[fndx].isExtentionDeclined = false;
              }
              else if (complileBill.rtpMessageType === MSG_TYPE.EXT_DECLINED) {
                filterBills[fndx].isExtentionDeclined = true;
                filterBills[fndx].isExtentionRequested = false;
                filterBills[fndx].isExtentionApproved = false;
              }

              await this.props.app.dm.dashboard.updateBillCount(compiledMsgList, filterBills);
            }

            this.setState({
              billDetails: complileBill,
              messages: billRes.messages.filter((element) => !element.envelope.errorBody),
              msgCount: this.state.msgCount + 1,
            });
          }
        }
      }, 1000 * defaultSec);
    }

    componentWillUnmount() {
      if (this.timerInstance) {
        clearInterval(this.timerInstance);
      }
    }

    onClose = () => {
      this.setState({
        isAccountLockedModalVisible : false
      })
    }

    toggelBookmark = async (bkmrk) => {
      const { billDetails } = this.state;
      const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;
      analyticsLogs(CommonString.RTP_BILL_DETAILS, !bkmrk ? CommonString.Removed_Priority : CommonString.Prioritize_Bill, this.props.app);

      if (!!compiledMsgList.length && !!filterBills.length) {
        let ind = compiledMsgList.findIndex((el) => el.uid === billDetails.uid);
        compiledMsgList[ind].bookmark = bkmrk;
        this.setState({ billDetails: { ...billDetails, ...compiledMsgList[ind] } });
        let findx = filterBills.findIndex((el) => el.uid === billDetails.uid);
        filterBills[findx].bookmark = bkmrk;
        await this.props.app.dm.dashboard.setState({ compiledMsgList, filterBills });
      }
    };

    onSent = (msgData) => {
      this.setState({ messages: this.state.messages.concat(msgData), msgCount: this.state.msgCount + 1 });
    };
    openPayOpt = () => {
      this.setState({
        isPayOptVisible: !this.state.isPayOptVisible,
      });
    };

    render() {
      const { pid } = this.props.userDetails;
      const { messages, isChatAllow, isAccountLockedModalVisible } = this.state;
      const messageType = !!messages && messages.length > 0 ? messages[0].envelope.messageHeader.senderPid !== pid ? "NotetoBiller" : "NotetoPayer" : "";
      const lastTwoMessages = !!messages && messages.length > 0 ? messages.slice(Math.max(messages.length - 2, 0)) :[];
      let newThread = this.props.billDetails.threadId; //.replaceAll("#", ">>");
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      let isTooltipOpen = this.props.app.dm.userSession.state.isTooltipOpen;
      return (
        <View style={[styles.BillDetailScreenContainer]}> 
          <View style={[commonStyles.marginLeftRight16, commonStyles.marginTop15, commonStyles.flex1, styles.billDetailsScreenInnerWrap, commonStyles.flex1]}>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.billerDetailsWrap, customCommonStyles.customBillerDetailsWrap]}>
              <View
                style={[
                  styles.container,
                  commonStyles.flex1,
                  commonStyles.flexBasis50,
                  commonStyles.flex1,
                  commonStyles.flexDirectionColumn,
                  commonStyles.padding0,
                  customCommonStyles.customBillerDetailsContainer,
                  isTooltipOpen ? styles.billContainerWithTooltip : null,
                ]}>
                <BillerDetails
                  padding1={styles.padding1}
                  padding2={[styles.padding2]}
                  padding3={styles.padding3}
                  userIconDiv={[styles.userIconDiv, commonStyles.flex1]}
                  billData={this.state.billDetails}
                  onBkmkToggle={this.toggelBookmark}
                  customFlexDirection={styles.customFlexDirection}
                />
                {isMobileDevice ? (
                  <>
                    {!this.state.billDetails?.biller?.isBlocked &&
                      ((!RTP_STATUS[this.state.billDetails.rtpStatus] && !BILL_STATUS[this.state.billDetails.billStatus])
                        || this.state.billDetails.billStatus === "Failed")
                      ? (
                        <View style={styles.bottomPaySection}>
                          <BottomPaySection billerDetails={this.props.billerDetails} threadId={newThread} billDetails={this.state.billDetails} extraOpt={this.state.billDetails.rtpMessageType} isDisabled={BILL_STATUS[this.state.billDetails.billStatus]} />
                        </View>
                      ) : null}
                    <View style={[styles.mView]}>
                      <View
                        style={[
                          styles.messageHeader,
                          commonStyles.flex1,
                          commonStyles.flexDirectionRow,
                          commonStyles.justifyContentSpaceBetween,
                          customCommonStyles.marginTop16InNative,
                        ]}>
                        <View>
                          <Text
                            style={[
                              styles.cardSubHeadingText,
                              commonStyles.heading6,
                              commonStyles.fontWeight700,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                            ]}>
                            Messages
                          </Text>
                        </View>

                        <View>
                          <Touchable onPress={this.fullMessageWindow}>
                            <View>
                              <Image source={midumChatBlackIcon} style={styles.meadiumChatICon} />
                            </View>
                          </Touchable>
                        </View>
                      </View>
                      <View style={[styles.customPaddingLeftRight16]}>
                        <MessageComponent
                          displayMessages={lastTwoMessages}
                          key={this.state.msgCount}
                          billDetails={this.state.billDetails}
                          userDetails={this.props.userDetails}
                        />
                      </View>
                      {!this.state.billDetails.biller.isBlocked ? (
                        <View style={[commonStyles.marTAuto]}>
                          <View style={[styles.chatInputField, customCommonStyles.customChatInputFieldPadding]}>
                            <ChatInputField
                              messageType={messageType}
                              billData={this.state.billDetails}
                              msgList={messages}
                              onAfterSent={this.onSent}
                              notAllowedChat={isChatAllow}
                              eventName={CommonString.RTP_BILL_DETAILS}
                            />
                          </View>
                        </View>
                      ) : null}
                    </View>
                  </>
                ) : null}

                {!this.state.billDetails.biller.isBlocked && !isMobileDevice ? (
                  <View style={[commonStyles.marTAuto, styles.webView, commonStyles.flexDirectionColumn, styles.bottomPaySectionWebView]}>
                    {((!RTP_STATUS[this.state.billDetails.rtpStatus] && !BILL_STATUS[this.state.billDetails.billStatus])
                      || this.state.billDetails.billStatus === "Failed") ?
                      (
                        <View style={[styles.bottomPaySection, commonStyles.marTAuto, commonStyles.webView]}>
                          <BottomPaySection
                            isDisabled={BILL_STATUS[this.state.billDetails.billStatus]}
                            billerDetails={this.props.billerDetails}
                            threadId={newThread}
                            billDetails={this.state.billDetails}
                            extraOpt={this.state.billDetails.rtpMessageType} />
                        </View>
                      ) : null}
                  </View>
                ) : null}
              </View>
              {(window.innerWidth >= 1024) ? (
                <View
                  style={[
                    commonStyles.flex1,
                    commonStyles.flexBasis50,
                    commonStyles.flexDirectionColumn,
                    styles.container,
                    commonStyles.padding0,
                    styles.messageSection,
                    customCommonStyles.customMessageSection,
                  ]}>
                  <View
                    style={[styles.messageHeader, styles.BorderBottom]}>
                    <View style={[commonStyles.paddingLeftRight16]}>
                      <Text
                        style={[
                          styles.cardSubHeadingText,
                          commonStyles.heading4,
                          commonStyles.fontWeight600,
                          commonFontStyles.workSans,
                          commonStyles.darkGrayColor,
                        ]}>
                        Messages
                      </Text>
                    </View>
                  </View>
                  <View style={[customCommonStyles.customMessageContainer]}>
                    <View style={[styles.messageOuter]}>
                      <MessageComponent
                        displayMessages={messages}
                        key={this.state.msgCount}
                        billDetails={this.state.billDetails}
                        userDetails={this.props.userDetails}
                      />
                    </View>
                  </View>
                  {!this.state.billDetails.biller.isBlocked ? (
                    <View style={[commonStyles.marTAuto]}>
                      <View style={[styles.chatInputField]}>
                        <ChatInputField
                          messageType={messageType}
                          billData={this.state.billDetails}
                          msgList={messages}
                          onAfterSent={this.onSent}
                          notAllowedChat={isChatAllow}
                          eventName={CommonString.RTP_BILL_DETAILS}
                        />
                      </View>
                    </View>
                  ) : null}
                </View>
              ) : null}
            </View>
            <PopupModalComponent
              visibility={isAccountLockedModalVisible}
              bodyWrapper={[styles.bodyWrapper, customCommonStyles.customFilePassowrdModalBodyWrapper]}
              messageType={MESSAGE_TYPE.error}
              headingIcon={true}
              heading={"Account Locked"}
              modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
              bodyContent={this.state.alertMessage}
              okLabel={"Ok"}
              handleOk={this.onClose}
              closeLabel={""}
              handleClose={null}
              modalHeading={[commonStyles.nativeModalHeading]}
            /> 
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  billDetailsScreen: {
    // flex: 1,
    // height: "100%",
    // justifyContent: "space-between",
    // alignItems: "flex-start",
    // backgroundColor: "#fff",
    // paddingRight: 15,
    // paddingLeft: 15,
    screen: ({ width }) => {
      if (width <= 991)
        return {
        //   margin: 0,
        //   minHeight: "100%",
        backgroundColor: "#fff",
          minHeight: "100%"
        };
    },
  },
  closeBillDetailScreenContainer: {
    screen: ({ width }) => {
      if (width >= 991){
        return {
          width: "95%",
        }
      }
    }
  },
  BillDetailScreenContainer: {
    screen: ({ width }) => {
      if (width >= 991)
      return {
        marginTop: 60,
        height: "88%",
        overflow: "hidden auto",
        position: "absolute",
        inset: "0px",
        display: "flex",
        flexGrow: "1",
        flexDirection: "column",
        transition: "all 0.3s ease 0s",
        zIndex: "0", 
      }
    }
  },

  // BorderBottom: {
  //   borderBottomWidth: 1,
  //   borderBottomStyle: "solid",
  //   borderBottomColor: "#D6D6D6",
  // },
  meadiumChatICon: {
    width: 30,
    height: 27,
    display: "inline-block",
    backgroundSize: "contain",
  },
  chatInputField: {
    border: 0,
    marginTop: 8,
    marginBottom: 8,
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991){
        return {
        marginTop: 10,
        marginBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        };
      }
      if (width >= 992 && width <= 1024){
        return {
          maxWidth: "100%",
          paddingRight: 0,
          paddingLeft: 0,
        };
      }
    },
  },
  bottomPaySection: {
    border: 0,
    paddingRight: 16,
    paddingLeft: 16,
    // marginTop: "auto",
    screen: ({ width }) => {
      if (width <= 991)
      return {
          paddingRight: 0,
          paddingLeft: 0,
          // marginBottom: 16,
        };
    },
  },
  messageOuter: {
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 8,
    screen: ({ width }) => {
      if (width <= 320)
        return {
          // height: 100,
          // overflow: "hidden auto",
        };
    },
  },
  titleBreadcrumbs: {
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // display: "none",
        };
    },
  },
  container: {
    padding: 10,
    marginTop: 30,
    maxWidth: "49%", 
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    backgroundColor: "#fff",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 0,
          maxWidth: "100%",
          boxShadow: "none",
          borderWidth: 0,
        };
    },
  },
  billerDetailsWrap: {
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexWrap: "wrap",
          paddingBottom: 30,
          marginBottom: 0,
          backgroundColor: "#fff",
        };
    },
  },
  messageSection: {
    marginTop: 30,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 15,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 15,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  },
  padding1: {
    paddingTop: 8,
    paddingBottom: 0,
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
      return {
          paddingRight: 0,
          paddingLeft: 0,
        };
    },
  },
  padding2: {
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 4,
  },
  padding3: {
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
  },
  messageHeader: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    paddingRight: 16,
    paddingLeft: 16,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#DADADA",
    alignItems: "center",
    screen: ({ width }) => {
      if (width >= 991){
        return {
          paddingTop: 8,
          paddingRight: 0,
          paddingBottom: 8,
          paddingLeft: 0,
          border: 0,
          marginRight: 0,
          marginLeft: 0,
        };
      }
      if (width <= 991){
        return {
          marginRight: 0,
          marginLeft: 0,
          paddingRight: 0,
          paddingLeft: 0,
        };
      }
    },
  },
  webView: {
    display: "none",
    screen: ({ width }) => {
      if (width >= 1024)
        return {
          display: "flex",
        };
    },
  },
  mView: {
    display: "none",
    screen: ({ width }) => {
      if (width < 1024)
        return {
          display: "flex",
          flexDirection: "column",
        };
    },
  },
  blueButton: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#4dc5f2",
    minHeight: 34,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    marginRight: 13,
    marginLeft: 13,
  },
  displayNoneInMView: {
    display: "none",
  },
  dBlockInMView: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "block",
        };
    },
  },
  customPaddingLeftRight16: {
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991){
      return {
          paddingRight: 0,
          paddingLeft: 0,
        };
      }
    },
  },
  billDetailsScreenInnerWrap: {
    marginTop: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 16,
          paddingLeft: 16,
          marginLeft: 0,
          marginRight: 0,
          backgroundColor: "#fff",
          minHeight: "100%"
        };
    },
    // screen: ({ width }) => {
    //   if (width <= 420)
    //     return {
    //     //   minHeight: "100"
    //     };
    // },
  },
  customFlexDirection: {
    flexDirection: "column",
  },
  bottomPaySectionWebView: {
    marginBottom: 16,
  },
  bodyWrapper: {
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        };
    },
  },
  billContainerWithTooltip: {
    overflow: "unset",
  },
});
