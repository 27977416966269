import { StyleSheet } from "../../core";
const customCommonStyles = StyleSheet.create({

  customDashboardMessageComponent: {
    marginBottom: 70,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginBottom: 0,
          position: "static",
          height: "calc(100vh - 375px)",
          overflowX: "scroll",
        };
      }
    }
  },
  customFilePassowrdModalBodyWrapper: {
    flexGrow: 0,
    screen: ({ width }) => {
      if (width <= 599) {
        return {
          marginTop: "auto",
          marginBottom: "auto", // newly added
          // width: "100vw",
          // borderRadius: "15px 15px 6px 6px",
          borderRadius: "4px 4px 4px 4px",
          flexGrow: 0,
        };
      }
    },
  },
  customVideoViewBodyWrapper: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
          marginBottom: "auto", // newly added
          // width: "100vw",
          width: "99vw",
          // borderRadius: "15px 15px 6px 6px",
          borderRadius: "4px 4px 4px 4px",
          padding: 15,
          flexGrow: 0,
        };
      }
    },
  },
  customautoFillModelBodyWrapper: {
    width: 500,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
          width: "100vw",
          borderRadius: "15px 15px 0 0",
          padding: 15,
          flexGrow: 0,
        };
      }
    },
  },
  customBtnHolder: {},
  logoutModalBodyWrapper: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
          width: "100vw",
          borderRadius: "15px 15px 0 0",
        };
      }
    },
  },
  topMarginCustom: {
    marginTop: 0,
  },
  customEditProfileIconBtn: {},
  customPasswordBtnHolder: {},
  editIcon: {},
  customAction: {},
  radioBtnCircleCutomMargin: {},
  userListComponent: {
    marginTop: 120,
    marginRight: 16,
    marginBottom: 70,
    marginLeft: 16,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          minHeight: 270,
          marginTop: 64,
          marginRight: 0,
          marginBottom: 110,
          marginLeft: 0,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        };
    },
  },
  emptyIconTextWrap: {},
  customProfileDetailSection: {},
  customActionColumn: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          right: 20,
          zIndex: 999999,
          maxWidth: "21%",
          flexBasis: "20%",
          justifyContent: "space-between",
        }
      }
    }
  },
  customActionBtn: {
    minWidth: 30,
    minHeight: 30,
    flex: 1,
    flexGrow: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  customUploadCardWrap: {},
  helperTextStylecustom: {
    position: "absolute",
    lineHeight: 10,
    bottom: -12
  },
  mobileNumErrorTest: {
    position: "absolute",
    width: "128%",
  },
  borderBottom1: {
    borderBottom: "1px solid #dadada",
  },
  customHeadingStyle: {},
  customRightSection: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 16,
          paddingRight: 16,
          paddingBottom: 16,
          paddingLeft: 16,
          height: "100%",
        }
      }
      if (width >= 1024) {
        return {
          height: "100vh",
          overflowX: "auto",
          boxShadow: "none",
        }
      }
    }
  },
  customRightSectionInnerWrap: {
    height: "100%",
    overflowX: "auto",
    flex: 1,
    // paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    screen: ({ width }) => {
      if (width === 1366) {
        return {
          // minHeight: 766,
        }
      }
      if (width === 1024) {
        return {
          minHeight: 800,
          height: "auto",
        }
      }
    }
  },
  welcomeRightSection: {
    // paddingTop: 16,
    screen: ({ width }) => {
      if (width === 1366) {
        return {
          // minHeight: 640,
        }
      }
      if (width === 1024) {
        return {
          minHeight: 640,
        }
      }
    }
  },
  modalHeadingMargin: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: 60,
        };
      }
    },
  },
  rotateArrow45Deg: {
    // transform: "rotate(45deg);",
  },
  paginationBtnWidth: {},
  customCameraOpt: {},
  customOpenMenu: {
    flex: 1,
    flexDirection: "row"
  },
  customUploadImageModalBodyWrapper: {
    width: 400,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minHeight: 370,
          // width: "100vw",
          width: "90vw",
          marginTop: "auto",
          marginBottom: "auto", // Newly added
          // borderTopLeftRadius: 15,
          // borderTopRightRadius: 15,
          // borderBottomLeftRadius: 0,
          // borderBottomRightRadius: 0,
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
        };
      }
    },
  },
  customMarginBottom30: {
    marginBottom: 16,
  },
  passwordModalHeadingMargin: {},
  customAttachmentsHolder: {
    maxHeight: 120,
    overflowX: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          overflowX: "scroll",
          marginBottom: 20,
        };
      }
    },
  },
  deleteIconMargin: {},
  customToolTipHolder: {
    position: "relative",
    marginLeft: 15,
    maxHeight: 20,
    top: 25,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          top: 28,
        };
      }
    },
    // top: 16,
    // screen: ({ width }) => {
    //   if (width <= 991) {
    //     return {
    //       minHeight: 26,
    //       top: 18,
    //     };
    //   }
    // },
  },
  wordBreakWord: {
    wordBreak: "break-word",
  },
  // textOverflowEllipsis: {
  //   textOverflow: "ellipsis",
  //   overflow: "hidden",
  //   whiteSpace: "nowrap",
  //   paddingRight: 20,
  // },
  customMarginTopAuto: {
    marginTop: "auto",
  },
  customAlignItemsCenter: {
    textAlign: "center",
  },
  customTitleMargin: {
    marginBottom: 0,
  },
  changePasswordForm: {
    padding: 0,
  },
  customInputHolder: {},
  customChangePasswordCloseBtn: {
    top: 5,
  },
  customBillerDetails: {
    flex: 1,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customLoginHeader: {
    flexGrow: 0,
  },
  customForGotPasswordRightSection: {
    width: "550px",
    flex: 1,
    paddingBottom: 10,
    boxShadow: "0px 0px 6px #00000033",
    justifyContent: "flex-start",
    screen: ({ width }) => {
      if (width <= 1024) {
        return {
          width: "100%",
          paddingTop: 0,
          paddingRight: 0,
          paddingLeft: 0,
        };
      }
    },
  },
  forgotPasswordCopyRight: {},
  customfullWidthBtn: {
    width: "100%",
  },
  toolTipMessageSignupCss: {
    top: 13,
  },
  customProviderListComponent: {
    marginTop: 106,
    borderTopWidth: 0,
    top: 12,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          borderTopWidth: 1,
          marginTop: 0,
          marginBottom: 0,
          position: "static",
          // height: "calc(100vh - 120px)",
          overflowX: "auto",
        };
      }
    },
  },
  customEmailInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 32,
        };
      }
    },
  },
  customAttachFileScetion: {
    marginBottom: 32,
  },
  ssidandDobHolder: {
    marginBottom: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginBottom: 32,
        };
      }
    },
  },
  customAttachmentFieldOuter: {
    height: 60,
  },
  orSection: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 16,
          marginBottom: 16,
        };
      }
    },
  },
  customSsidInputField: {
    marginBottom: 0,    
  },
  customBobInputField: {
    marginBottom: 0,    
  },
  customBobInputFieldHolder: {
    marginBottom: 0,    
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginBottom: 32,
        };
      }
    },
  },
  customSSIDDOBHolder: {
    marginBottom: 32,
  },
  customAutoFillModel:{
    position: "static",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: -15,
          marginLeft: -15,
        };
      }
    },
  },
  disabledBtn: {
    opacity: 1,
    cursor: "default",
    pointerEvents: "none",
    backgroundColor:"#f4f4f4"
  },
  disabledBtnText:{
    color:"#888888"
  },
  disabledInput: {
    opacity: 0.5,
    cursor: "no-drop",
    pointerEvents: "none",
  },

  activeBtn: {
    cursor: "default",
    backgroundColor: "#b3e5f8",
  },
  leftMenuWrap: {},
  customAddInsuranceAlert: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "91vw",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          // marginTop: "auto",

        };
      }
    },
  },
  customAddInsuranceAlertText: {
    marginBottom: 24,
  },
  customDobHolder: {
    minHeight: 54,
  },
  customAddIncpageMarginBottom15: {
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,

        };
      }
    },
  },
  textOverflowEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  customRemoveProfilePicIcon: {},
  customNoRecordsStyle: {
    marginTop: 55,
  },
  customXBPRegibgContainer: {
    height: "100vh"
  },
  customXBPRegiRightSection: {
    height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    flex: 1,
  },
  customBillerDetailsWrap: {
    height: "calc(100vh - 250px)",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          height: "100%",
        };
      }
    },
  },
  customMessageSection: {
    overflowX: "auto",
  },
  customSearchResultWithAccountInput: {
    height: "calc(100vh - 450px)",
    screen: ({ width, height }) => {
      if (width <= 1024) {
        return {
          height: "calc(100vh - 510px)",
        };
      }
    },
  },
  customSearchResult: {
    height: "calc(100vh - 475px)",
    overflowX: "auto",
    screen: ({ width, height }) => {
      if (width <= 1024) {
        return {
          overflow: "scroll",
          height: "calc(100vh - 460px)",
        };
      }
    },
  },
  customNoRecordsFound: {
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          marginTop: 16,
        };
      }
    },
  },
  xbpLoginRightSection: {
    maxWidth: 550,
    screen: ({ width, height }) => {
      if (width <= 1024) {
        return {
          minWidth: 300,
          maxWidth: 365,
          boxShadow: "none",
          marginRight: "auto",
          marginLeft: "auto",
        };
      }
    },
  },
  customMarginBottom16Native0: {
    marginBottom: 16,
  },
  xbpCustomDashboardBillAndMakepaymentscreen: {
    // marginTop: 116,
    // marginBottom: 0,
    // borderBottomWidth: 0,
    // borderStyle: "solid",
    // borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
        };
      }
    }
  },
  makePaymentMessageComponentWithFilter: {
    marginTop: 179,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          position: "static",
          margin: 0,
        };
      }
    }
  },
  customPaynowBtnInnerWrap: {},
  customLoginWithSocialWrap: {
    marginRight: 16,
    marginLeft: 16,
  },
  innerSocialWrap: {},
  selectCountryModalContent: {
    height: 140,
    overflowX: "auto"
  },
  customAddInsuranceScreen: {
    marginTop: 55,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 50
        };
      }
    }
  },
  customPopupmenuItemHolder: {},
  customDashboardHeader: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          paddingBottom: 22,
        };
      }
    }
  },
  customXbpProfileDetailSection: {
    top: 50,
    bottom: 50,
    padding: 16,
    position: "static",
    screen: ({ width }) => {
      if (width <= 640)
      return {
          marginBottom: 0,
          border: 0,
          height: "auto",
          bottom: 0,
        };
    },
  },
  extraSpaceatBottom: {},
  formUpdateBtnHolder: {},
  customMessageNotification: {
    position: "absolute",
    right: -12,
    top: 0,
  },
  customHideEyeIconWrap: {
    position: "absolute",
    top: 4,
    right: 16,
  },
  customBackgroundImageHideIcon: {
    position: "relative",
    top: 1,
    right: -27,
  },
  customDashboardNoListView: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    screen: ({ width }) => {
      if (width <= 1023) {
        return {
          marginLeft: 0,
          marginRight: 0,
        };
      }
    },
  },
  customPayNowWrapper: {
    // marginTop: -29,
  },
  xbpChangePasswordModalBodayWrapper: {
    boxShadow: "0px 0px 3px -1px #000",
  },
  customFullWidth: {
    flexBasis: "50%",
  },
  customCardRightDiv: {
    flexBasis: "100%",
  },
  customPamMessageCenterScrollable: {
    top: 140,
    bottom: 97,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: 200,
          minHeight: 200,
        };
      }
    }
  },
  pamMessageCenterScrollableWithoutBottom: {
    height: "45vh",
    minHeight: "45vh",
  },
  pamMessageWithPreference: {
    height: "40vh",
    minHeight: "40vh",
  },
  pamMessageWithPreferenceChatInput: {
    height: 100,
    minHeight: 100,
  },
  makePaymentMessageComponent: {
    marginTop: 100,
    marginLeft: 33,
    marginRight: 33,
    marginBottom: 66,
    screen: ({ width }) => {
      if (width >= 1281 && width <= 1366) {
        return {
          maxHeight: "63vh",
        };
      }
      if (width <= 1023) {
        return {
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 70,
        };
      }
    },
  },
  customSimpleBillMakePaymentPage: {
    // marginTop: 116,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 50,
        }
      }
    }
  },
  customBillerDetailsContainer: {
    overflow: "auto"
  },
  customTr: {
    width: "94%",
  },
  customLongEmailWidth: {
    // marginRight: -16,
  },
  customForGotPasswordMainLogin: {
    flexGrow: 1,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
        }
      }
    }
  },
  customPaginationWrap: {
    marginTop: "auto",
    marginBottom: 0,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
          marginRight: 0,
          marginBottom: 10,
          // paddingTop: 8,
        }
      }
    }
  },
  addDrPaginationWrap: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 90,
        }
      }
    }

  },
  customMessageContainer: {
    height: "calc(100vh - 292px)",
    overflowX: "auto",
    screen:({width}) => {
      if (width >= 992 && width <= 1024) {
          return {
              maxWidth: "91%",
          }
      }
    }
  },
  sepaCustomMessageContainer: {
    height: "calc(100vh - 210px)",
    overflowX: "auto",
    screen:({width}) => {
      if (width >= 992 && width <= 1024) {
          return {
              maxWidth: "91%",
          }
      }
    }
  },
  attachmentPopupCloseBtn: {
    position: "absolute",
    right: -15,
    top: -15,
    // screen: ({ width }) => {
    //   if (width <= 991) {
    //     return {
    //       top: -7,
    //       right: -25,
    //     };
    //   }
    // },
  },
  customLoginFormContainer: {},
  customForgotPasswordContainer: {},
  ppAndTcMainContent: {
    marginTop: 90,
  },
  customLogoImageContainer: {
    width: "100%",
    paddingTop: 10,
    paddingRight: 30,
    paddingBottom: 10,
    paddingLeft: 30,
    boxShadow: "0px 0px 6px #00000033",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "flex",
          width: "auto",
          alignItems: "center",
          // marginTop: -16,
          // marginRight: -16,
          marginBottom: 16,
          // marginLeft: -16,
          paddingTop: 10,
          paddingRight: 30,
          paddingBottom: 10,
          paddingLeft: 30,
        };
    },
  },  
  customBillerName: {
    marginTop: -20,
    width: "70%",
  },
  simpleMessageSection: {
    // position: "relative",
    marginTop: 100,
    // borderBottom: "1px solid #e2e2e2",
    marginLeft: 33,
    marginRight: 33,
    marginBottom: 66,
    screen: ({ width }) => {
      if (width <= 1023)
        return {
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 0,
        };
    },
  },
  xbpCustomBillerDetails: {},  
  withoutCircle: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          justifyContent: "flex-end",
          minWidth: "75%",
        }
      }
    }
  },
  customExtensionRequestModal: {},
  extensoinInputHolder: {
    marginBottom: 32,
  },
  customUploadHolder: {},
  customDatePickerField: {},
  customDatePickerFieldErrorMsg: {},
  customSubmitClaimButton: {
    display: "inline-block",
    marginTop: 10,
    minWidth: 123,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          display: "inline-block"
        }
      }
    }
  },
  valueInMbile: {
    wordBreak: "break-all",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          maxWidth: "75%",
        };
    },
  },
  valueInMbileBreakWord: {
    wordBreak: "break-word",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          maxWidth: "75%",
        };
    },
  },
  valueInMbileMemberName: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          maxWidth: "45%",
          wordBreak: "break-all",
        };
    },
  },
  passwordFieldWrapper: {
    screen: ({ width, height }) => {
      if (width === 1366)
        return {
          marginTop: -15,
        };
    },
  },
  noBoxShadow: {
    boxShadow: "none",
  },
  mailGreenSection: {
    minWidth: 195,
    left: "calc(50% - 102px)",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          textAlign: "left",
          minWidth: 200,
          left: "calc(50% - 160px)",
        };
    },
  },
  mailRedSection: {
    minWidth: 215,
    left: "calc(50% - 111px)",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          textAlign: "left",
          left: "calc(50% - 175px)",
        };
    },
  },
  smsRedSection: {
    minWidth: 210,
    left: "calc(50% - 105px)",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          textAlign: "left",
          minWidth: 210,
          left: "calc(50% - 168px)",
        };
    },
  },
  smsGreenSection: {
    minWidth: 185,
    left: "calc(50% - 94px)",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          textAlign: "left",
          minWidth: 200,
          left: "calc(50% - 160px)",
        };
    },
  },
  customAddUserForm: {
    screen: ({ width }) => {
      if (width >= 991){
        return {
          flex: 1,
          flexDirection: "row",
          alignContent: "baseline",
        };
      }
      if (width <= 991){
        return {
          display: "block",
        };
      }
    },
  },
  adduserFormStyle: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minHeight: 790,
        };
      }
    },
  },
  closeBtnPatUploadBillPage: {
    position: "absolute",
    right: -10,
    top: 1,
  },
  customDisplayNone: {
    display: "none",
  },
  customAddUserRow: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display :"block"
        };
      }
    },
  },
  customFlex1: {
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          flex: 1,
        };
      }
    },
  },
  viewPDFCloseBtn: {
    marginRight: 20,
  },
  customStatusFormSpacing: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: -13,
          marginRight: 0,
          marginBottom: 22,
          marginLeft: 0,
        };
      }
    },
  },
  messageTextInputOuter: {
    flexBasis: "100%"
  },
  simpleMessagemessageTextInputOuter: {
    flexBasis: "100%",
  },
  chooseAttachModalColsebtn: {
    marginRight: -11,
  },
  cursorDefault: {
    cursor: "default",
  },
  countBoxSection: {},
  
  customCountBoxHolder: {
    marginBottom: 20,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  customPriorityInfoTooltip: {
    top: 35,
    // minWidth: 205,
  },
  customResponsiveTableComponent: {},
  changePasswordTitle: {},
  payerChangePasswordInputs: {},
  forgotPasswordSubmitBtn: {},
  forgotPasswordEmailInput: {},
  customForgotPasswordForm: {},
  customHaveAccount: {},
  payButtons: {},
  payerChangePwdEle: {},
  customAccountNumberCls: {},
  chatMessageTextInputOuter: {},
  chatMessageTextInputOuter1: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  chatMessageTextInputError: {},
  marginTop16InNative: {},
  customMessageComponent: {},
 
  attachedFileName: {},
  newAttachedFileName: {},
  attachedFileCloseBtn: {},
  declineOpt: {},
  customChatMessageTextInputError: {
    // minWidth: "150%",
    position: "static",
  },
  declineModalWrapperWithOther: {},
  inputHeight: {
    height: 30,
    minHeight: 25,
  },
  inputLableStyle: {
    top: 10,
    // paddingBottom: 4,
    // paddingBottom: 3
    paddingBottom: 5
  },
  selectMenuInputLabelStyle:{
    // paddingBottom: 2
  },
  messageScreenMainSection: {},
  centerScrollable: {
    top: 140,
    bottom: 122,
    height: "auto",
    overflowY: "auto",
    // paddingRight: 15,
    position: "absolute",
    // width: "95.3%",
    marginBottom: 22,
  },
  attachmentFieldOuterCustomHeight: {
    position: "relative",
    height: 48,
    // height: 64,
    width: "6vw",
    minWidth: 60,
  },
  customSendBtn: {},
  createMessageFormBtn: {},
  customBillerHistoryScreen: {
    display: "block",
  },
  billHistorytotalAmount: {
    // marginTop: 14,
  },
  declineModalWrapperMsgComp: {
    marginBottom: 20.
  },
  extesionModalWrapperMsgComp: {
    marginBottom: 20.
  },
  customExtensoinModal: {},
  declineModalWrapper: {},
  filterModalWrapper: {
    width: 420
  },
  customFilterForm: {},
  chipsHolder: {},
  paymentChipsHolder: {},
  customMessageTextField: {
    maxHeight: 35,
    minHeight: 35,
    lineHeight: 16,
    paddingTop: 8,
  },
  minHeight0:{
    minHeight:0
  },
  messageAttachmentBtn: {},
  customSendBtnStyle: {},
  customPamSendBtnStyle: {
    marginTop: 4,
  },
  simpleMessagehelperTextStyle: {},
  extensionMessageTextInputOuter: {
    flexBasis: "95%",
  },
  customMessageInptSpacing: {
    paddingTop: 5,
    paddingRight: 11,
    paddingBottom: 0,
    paddingLeft: 11,
  },
  paymentScreenMessageTextInputOuter: {},
  chooseAttachmentModal: {
    minHeight: 170,
  },
  toggleSwitchBtnouterStyle: {
    maxWidth: 60,
    minWidth: 60,
  },
  intendedDateError: {},
  customAttachedFile: {},
  customPaddingDecBill: {
    width: "100%"
  },
  customPaddingDecBillOuter: {
    flexBasis: "100%",
  },
  textInputFileUploadHolder: {},
  simpleMsdDetailstextInputFileUploadHolder: {
    flexBasis: "100%",
  },
  customArrowPositionPasswordInfo: {
    height: 0,
    width: 0,
    position: "absolute",
    // bottom: 20,
    // right: -10,
    borderStyle: "solid",
    borderTop: "5px solid rgba(255,255,255, 0.0)",
    borderRight: "5px solid rgba(255,255,255, 0.0)",
    borderBottom: "5px solid rgba(255,255,255, 0.0)",
    borderLeft: "5px solid #37c2ef",
  },
  customArrowPosition: {
    right: "46%",
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderBottom: "5px solid #37c2ef",
    top: -5,
    screen: ({ width }) => {
      if (width <= 991){
        return {
          borderTop: "5px solid #37c2ef",
          borderLeft: "5px solid rgba(255,255,255, 0.0)",
        };
      }
    },
  },
  customChatInputFieldPadding: {},
  billDetailsChatInput: {
    lineHeight: 14,
    paddingTop: 8,
  },
  customMessageTextFieldNative: {
    lineHeight: 14,
    paddingTop: 8,
  },
  billHistoryScreenStatus: {},
  billHistoryRightSec: {
    flexBasis: "100%",
    // maxWidth: "65%",
  },
  gustNamecustomPadding: {
    // whiteSpace: "nowrap",
    // width: "120%",
  },
  forgotPasswordLeftSection: {
    screen: ({ width }) => {
      if (width <= 1024) {
        return {
          display: "none",
        };
      }
    },
  },
  billHistorycardHeadingText: {},
  makePaymentMessageComponentNoListViewWithFilter: {
    marginTop: 121,
    borderTop: "1px solid #e2e2e2",
    screen: ({ width }) => {
      if (width === 1024) {
        return {
          marginTop: 121,
          paddingLeft: 0,
          paddingRight: 0,
        };
      }
      if (width <= 1024) {
        return {
          marginTop: 0,
        };
      }
    },
  },
  simpleMessageDetailsSendBtnStyle: {},
  attachedFileMargin: {
    marginBottom: 10,
  },
  customMarginRight15: {
    marginRight: 15,
  },
  customPaddingRight: {
    paddingRight: 16,
    screen: ({ width }) => {
      if (width <= 1024) {
        return {
          paddingRight: 0,
        };
      }
    },
  },
  bulkPayMessageComponent: {
    height: "calc(60vh - 41%)",
    top: 235,
    display: "block",
  },
  billAggregationComponentButtonContainers: {
    marginTop: "auto",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          marginTop: 20,
        }
      }
    },
  },
  BillAggregationComponentTable: {
    height: "calc(100vh - 30vh)",
    screen: ({ width, height }) => {
      if (height === 913) {
        return {
          height: "calc(100vh - 20vh)",
        };
      }
      if (width === 1081) {
        return {
          height: "calc(100vh - 17vh)",
        }
      }
      if (height === 860) {
        return {
          height: "calc(100vh - 20vh)",
        }
      }
      if (width === 1366) {
        return {
          height: "calc(100vh - 23vh)",
        }
      }
      if (height === 1292) {
        return {
          height: "calc(100vh - 17vh)",
        }
      }
      if (width === 1024 && height === 1366) {
        return {
          height: "calc(100vh - 15vh)",
        }
      }
      if (width <= 991) {
        return {
          height: "auto",
          marginBottom: 30,
        }
      }
    },
  },
  bankListTableComponent: {
    // height: "calc(100vh - 33.5vh)",
    // screen: ({ width, height }) => {
    //   if (height === 913) {
    //     return {
    //       height: "calc(100vh - 20vh)",
    //     };
    //   }
    //   if (height === 1081) {
    //     return {
    //       height: "calc(100vh - 17vh)",
    //     }
    //   }
    //   if (height === 569) {
    //     return {
    //       height: "calc(100vh - 34vh)",
    //     }
    //   }
    //   if (height === 881) {
    //     return {
    //       height: "calc(100vh - 18.5vh)",
    //     }
    //   }
    //   if (width <= 991) {
    //     return {
    //       height: "auto",
    //       marginBottom: 60,
    //     }
    //   }
    //   if (width >= 991) {
    //     return {
    //       position: "relative",
    //     }
    //   }
    //   if (height === 768) {
    //     return {
    //       height: "calc(100vh - 32vh)",
    //     };
    //   }
    // },
  },
  customMainSectionMyBankPage: {
    height: "calc(100vh - 28.5vh)",
    overflowY: "auto",
    screen: ({ width, height }) => {
      if (height === 489) {
        return {
          height: "calc(68.5vh)",
        }
      }
      if (height === 569) {
        return {
          height: "calc(100vh - 27vh)",
        }
      }
      if (height === 649) {
        return {
          height: "calc(75.5vh)",
        }
      }
      if (height === 625) {
        return {
          height: "calc(77.5vh)",
        }
      }
      if (height === 881) {
        return {
          height: "calc(100vh - 18.5vh)",
        }
      }
    },
  },
  cusromRightMargin30: {},
  billAggregationComponent: {
    border: "1px solid #dadada",
    borderTop: 0,
    screen: ({ width, height }) => {
      if (width === 1024 && height >= 640 && height <= 768 ) {
        return {
          marginTop: 275,
          marginBottom: 180,
        }
      }
      if (width === 1024) {
        return {
            marginTop: 275,
        }
      }
      if (width <= 991) {
        return {
          // maxHeight: "23vh",
          position: "static",
          marginTop: 0
        }
      }
    },
  },
  customMarginBottom: {},
  prefrenceModalWrapper: {},
  pamHeading: {},
  customBottomPaySection: {},
  payOptTitle: {
    marginTop: 20,
  },
  linkLineHight: {},
  addBankFormModalHeight: {
      overflow: "auto",
  },
  bankAddedModalHeight: {
    minHeight: 195,
  },
  paymentConfirmationModalHeight: {},
  mainModalHeight: {
    minHeight: 420,
  },
  bankListContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    minHeight: 290,
    marginTop: 166,
    marginBottom: 90,
    screen: ({ width }) => {
      if (width === 375) {
        return {
          minHeight: 260,
          maxHeight: 260,
        }
      }
    }
  },
  scrollViewHack: {
    marginTop: 160,
    marginRight: 0,
    marginBottom: 70,
    marginLeft: 24,
  },
  newBankAddedMsg: {},
  banklistComponent: {
    backgroundColor: "#fff",
    marginTop: 103,
    marginRight: 16,
    marginBottom: 65,
    marginLeft: 16,
    paddingTop: 0,
    display: "block",
    flexGrow: 0,
    // position: 'relative',
    borderTop: 0,
    zIndex: 999999,
    screen: ({ width, height }) => {
      if (height === 1200) {
        return {
          marginBottom: 60,
        }
      }
      if (height === 881) {
        return {
          marginBottom: 170,
        }
      }
      if (height === 768) {
        return {
          marginBottom: 120,
        }
      }
      if (width <= 991) {
        return {
          // marginTop: 45,
          marginRight: 0,
          // marginBottom: 20,
          marginLeft: 0,
          paddingRight: 0,
          paddingLeft: 0,
          borderBottomWidth: 0,
          borderTop: 1,
        };
      }
    },
  },
  myBankPageTooltipStyles: {
      // marginTop: -10,
      marginLeft: 0,
      top: 0,
  },
  myBankArrowHolderStyle: {
    position: "absolute",
    screen: ({ width }) => {
      if (width >= 992){
      return {
          left: -5,
        }
      }
      if (width <= 991){
      return {
          top: -2,
          left: 8,
        };
      }
    },
  },
  customCancelPayButtons: {
    screen: ({ width }) => {
      if (width === 375) {
        return {
          marginBottom: 0,
        }
      }
    }
  },
  accountTypeContainerHeight: {},
  priorityInfoTooltipStyle: {
    screen: ({ width }) => {
      if (width >= 991){
      return {
        right: "109%"

      };
    }
    if (width <= 991){
      return {
        //   top: -85,
          marginBottom: 20,
          right: 20,
          left: 0
        };
      }
    },
  },
  priorityInfoTooltipStyleTop3entry: {
    top: 0,
    screen: ({ width }) => {
      if (width <= 991){
          return {
            top: 18,
            opacity: 1,
            elevation: 3,
          };
      }
    }
  },
  priorityArrowTooltipStyle: {
    right: "46%",
    borderLeft: "5px solid #37c2ef",
    borderRight: "5px solid transparent",
    borderBottom: "5px solid transparent",
    top: -5,
    screen: ({ width }) => {
      if (width <= 991){
        return {
          right: "46%",
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderTop: "5px solid #37c2ef",
          borderBottom: "5px solid transparent",
          top: 1,
          left: 1,
        };
      }
    },
  },
  priorityArrowTooltipStyleTop3entry: {
    right: "46%",
    borderLeft: "5px solid #37c2ef",
    borderRight: "5px solid transparent",
    borderBottom: "5px solid transparent",
    top: -2,
    screen: ({ width }) => {
      if (width <= 991){
        return {
          right: "46%",
          top: 10,
          left: 1,
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderBottom: "5px solid #37c2ef",
        };
      }
    },
  },
  blockpriorityInfoTooltipStyle: {},
  myBankPageTboday: {
    position: "relative",
    minHeight: "59vh",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          minHeight: "75vh",
        }
      }
      if (height === 881) {
        return {
          minHeight: "73vh",
        }
      }
      if (height === 625) {
        return {
          minHeight: "63vh",
        }
      }
    }
  },
  achCreateFormContainer: {
      minHeight: 500,
  },
  iosPadding: {
    paddingTop: 13,
    paddingBottom: 13,
  },
  sendBtnChatBoxStyle: {},
  smallAttachmentBoxStyle: {},
  attachmentFieldOuter: {
      minWidth: 20,
      minHeight: 20,
      flexGrow: "unset",
      flexBasis: "unset"
  },
  smallAttachmentTop: {},
  txtBoxWithOnlyAttachment: {},
  txtBoxWithAttachmentAndSend: {},
  attachmentContainerStyle: {},
  chatFieldStyle: {},
  onFocusStyle: {},
  onSmallFocusStyle: {},
  chatBoxAlignItemsFlexEnd: {
    alignItems: "flex-end"
  }
});

export { customCommonStyles };
