import React from "react";
import R14, { StyleSheet, Surface, View, Touchable, Image, Text } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import WelcomeToPCH from "../../components/PCH/WelcomeToPCH";
import PCHSignupComponent from "../../components/PCH/PCHSignupComponent";
import CopyRight from "../../components/CopyRight";
const xbpLogo = require("../../assets/images/NewXbpLogo150.png");
const back_icon = require("../../assets/images/back_icon.png");
const mainImage = require("../../assets/images/mainImage.png");

export default R14.connect(
  class PCHSignupScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        enableErrorStyle: false,
        defaultDomain: "#xbp.exela.global",
        // socialMediaRegistrationEnabled: this.props.registrationType.type !== "new" ? true : false,
        currentContextProfileId: "",
        isOpenRegistrationHelpTooltip: false,
      };
      this.form = null;
    }

    componentDidMount() {
      let unbounceEmail = this.props.app.route._location.search;
      if (!!unbounceEmail && unbounceEmail.indexOf("?UnbEmail=") > -1) {
        unbounceEmail = decodeURIComponent(unbounceEmail.split("=").pop());
        if (!!unbounceEmail && unbounceEmail.length > 0)
          this.props.app.dm.userSession.setState({
            pchRegistrationTypeProvider: true,
            pchRegistrationTypePatient: false,
            unbounceEmail: unbounceEmail,
          });
      }
    }

    accessServicesAgreementRoute = async () => {
      this.props.app.nav.to("accessServicesAgreementRoute");
      return false;
    }
    redirectTopchusertermsRoute = async () => {
      this.props.app.nav.to("pchusertermsRoute");
      return false;
    }
    termsAndConditionsScreenRoute = async () => {
      this.props.app.nav.to("termsAndConditionsScreenRoute");
      return false;
    }
    
    navToWelcomeToPch = () => {
      let unbounceEmail = this.props.app.route._location.search;
      if (!!unbounceEmail && unbounceEmail.indexOf("?UnbEmail=") > -1) return false;
      else
        this.props.app.dm.userSession.setState({
          pchRegistrationTypeProvider: false,
          pchRegistrationTypePatient: false,
        });
    };

    navPchLogin = () => {
      // let returnUrl = await AsyncStorage.getItem("returnUrl")
      let returnUrl = this.props.app.route._location.search;
      this.props.app.dm.userLoginDomain.setState({
        socialLoginProfile: null,
        socialLoginType: "",
      });
      if (!!returnUrl && returnUrl.length > 0) document.location = `${window.location.origin}/claims/login${returnUrl}`;
      else this.props.app.nav.to("pchLogin");
    };

    render() {
      const { pchRegistrationTypeProvider, pchRegistrationTypePatient } = this.props.app.dm.userSession.state;
      return (
        <View style={[styles.mainLogin, commonStyles.flex1, commonStyles.flexDirectionRow]}>
          <View style={[styles.bgContainer, styles.leftSection]}>
            <Image source={mainImage} style={[styles.backgroundImage]} />
          </View>
          <Surface style={[styles.rightSection, commonStyles.flex1, customCommonStyles.customRightSection]}>
            <View style={[customCommonStyles.customRightSectionInnerWrap, pchRegistrationTypeProvider || pchRegistrationTypePatient ? null : customCommonStyles.welcomeRightSection]}>
              <View>
              </View>
              <View style={[commonStyles.mView]}>
                <View style={[styles.logoImageContainer, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                  {!!pchRegistrationTypeProvider || !!pchRegistrationTypePatient ? (
                    <Touchable onPress={this.navPchLogin}>
                      <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                    </Touchable>
                  ) : (
                    <Touchable onPress={this.navPchLogin}>
                      <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                    </Touchable>
                  )}
                  <View style={[commonStyles.margin0Auto]}>
                    <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
                  </View>
                </View>
              </View>
              <View style={[styles.loginHeader, commonStyles.justifyContentCenter]}>
                {!!pchRegistrationTypeProvider || !!pchRegistrationTypePatient ? (
                  <Touchable onPress={this.navPchLogin}>
                    <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                  </Touchable>
                ) : (
                  <Touchable onPress={this.navPchLogin}>
                    <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                  </Touchable>
                )}
                <View style={commonStyles.margin0Auto}>
                  <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
                </View>
              </View>
              {pchRegistrationTypeProvider || pchRegistrationTypePatient ? (
                <PCHSignupComponent
                  navToWelcomeToPch={this.navPchLogin}
                  registrationType={this.props.registrationType.type}
                  navPchLogin={this.navPchLogin}
                  userLoginDetail={this.props.userLoginDetail}
                />
              ) : (
                <WelcomeToPCH registrationType={this.props.registrationType.type} />
              )}

              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexGrow0,
                  commonStyles.justifyContentCenter,
                  commonStyles.alignItemsCenter,
                  styles.copyRight,
                ]}>
                <View
                  style={[
                    commonStyles.flex1,
                    commonStyles.flexDirectionRow,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                    commonStyles.textAlignLeft,
                    styles.createAccHolder
                  ]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lightGrayColor]}>Already have an account? </Text>
                  <Touchable onPress={this.navPchLogin}>
                    <Text style={[styles.createAccount, commonFontStyles.workSans, commonStyles.heading6]}>Login</Text>
                  </Touchable>
                </View>
                <View style={[styles.termsAndConditionsWrapper]}>
                  <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.lightGrayColor, commonStyles.fullWidth, commonStyles.textAlignCenter, commonStyles.heading6]}>By creating an account, you agree to our</Text>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentCenter, commonStyles.flexWrap]}>
                    <Touchable onPress={this.termsAndConditionsScreenRoute}>
                      <Text style={[commonFontStyles.workSans, styles.termsAndConditions, commonStyles.smallHeading]}>Terms & Conditions</Text>
                    </Touchable>
                    <View>
                      <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.lightGrayColor, commonStyles.smallHeading]}>,</Text>
                    </View>
                    <Touchable style={commonStyles.marginLeft5} onPress={this.accessServicesAgreementRoute}>
                      <Text style={[commonFontStyles.workSans, styles.termsAndConditions, commonStyles.smallHeading]}>Access Services Agreement</Text>
                    </Touchable>

                    <View style={commonStyles.marginLeftRight5}>
                      <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.lightGrayColor, commonStyles.smallHeading]}>and</Text>
                    </View>
                    <Touchable onPress={this.redirectTopchusertermsRoute}>
                      <Text style={[commonFontStyles.workSans, styles.termsAndConditions, commonStyles.smallHeading]}>User Terms</Text>
                    </Touchable>
                  </View>
                </View>
                <View
                  style={[
                    commonStyles.flex1,
                    commonStyles.flexDirectionRow,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                    commonStyles.textAlignLeft,
                    styles.createAccHolder,
                    commonStyles.marginBottom10
                  ]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.lightGrayColor]}>Need help? </Text>
                  <Touchable onPress={() => this.props.app.nav.to("helps")}>
                    <Text style={[styles.createAccount, commonFontStyles.workSans, commonStyles.smallHeading]}>click here!</Text>
                  </Touchable>
                </View>

                <CopyRight/>
              </View>
            </View>
          </Surface>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  loginHeader: {
    display: "flex",
    paddingTop: 8,
    paddingBottom: 12,
    textAlign: "center",
    alignItems: "center",
    position: "relaive",
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          display: "none",
        };
      }
      if (width === 1366) { }
      return {
        paddingTop: 0,
      };
    },
  },
  mainLogin: {
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "row",
    height: "100%"
  },
  leftSection: {
    flex: 2,
    display: "flex",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "none",
        };
      }
    },
  },
  rightSection: {
    flex: 1,
    width: 550,
    maxWidth: 550,
    // justifyContent: "center",
    position: "relative",
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 16,
    height: "100%",
    screen: ({ width, height }) => {
      if (width <= 1024) {
        return {
          // paddingTop: 20, 
          paddingLeft: 10,
          paddingRight: 10,
          marginTop: 0,
          marginRight: "auto",
          marginBottom: 0,
          marginLeft: "auto",
          maxWidth: "100%",
          minWidth: "100%",
          boxShadow: "none",
          // height: "100vh",
          alignItems: "stretch",
          alignSelf: "stretch",
        };
      }
    },
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center left",
    screen: ({ width, height }) => {
      if (width === 1024 && height === 768)
        return {
          backgroundPosition: "-65px",
        };
    },
  },
  bgContainer: {
    width: "100%",
    display: "block",
    height: "100vh",
    screen: ({ width }) => {
      if (width <= 1024)
        return {
          display: "none",
          width: 0,
        };
    },
  },
  registerButton: {
    width: "100%",
  },
  fNameField: {
    width: "100%",
  },
  lNameField: {
    width: "100%",
  },
  uNameField: {
    width: "100%",
  },
  mNumberField: {
    width: "100%",
  },
  emailField: {
    width: "100%",
  },
  passwordField: {
    width: "100%",
  },
  cPasswordField: {
    width: "100%",
  },
  loginLogo: {
    width: "100%",
    height: 90,
    marginBottom: 90,
  },
  rememberMeText: {
    position: "relative",
    top: -28,
    left: 30,
  },
  addBank: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 30,
    marginBottom: 30,
    color: "#37c2ef",
    fontSize: "14",
  },
  addBankSection: {
    textAlign: "center",
    color: "#0B4A87",
    width: "100%",
  },
  loginControls: {
    width: "100%",
  },
  twoCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hideField: {
    display: "none",
  },
  redHelperText: {
    color: "#B00020",
    position: "static",
  },
  greenHelperText: {
    color: "green",
  },
  logoImageContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 16,
    marginLeft: "auto",
  },
  form: {
    zIndex: 1,
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    flexGrow: 0,
    screen: ({ width, height }) => {
      if (width <= 414 && height === 736) {
        return {
          paddingTop: 20,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        };
      } else if (width <= 991)
        return {
          paddingTop: 5,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginBottom: 20,
        };
    },
  },
  countryCode: {
    maxWidth: 70,
  },
  customHelperTextStyle: {
    position: "static",
    marginLeft: 0,
    display: "block",
  },
  customHelperTextStyleMobile: {
    position: "static",
    display: "block",
    marginLeft: -58,
  },
  copyRight: {
    textAlign: "center",
    marginTop: "auto",
    marginBottom: 10,
    screen: ({ width }) => {
      if (width === 1366) {
        return {
          marginBottom: 0,
        }
      };
    },
  },
  helpIcon: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  registrationHelpTooltipHolder: {
    position: "relative",
  },
  registrationHelpTooltip: {
    position: "absolute",
    backgroundColor: "#333",
    color: "#fff",
    top: 45,
    zIndex: 999,
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  registrationHelpTooltipArrow: {
    width: 10,
    height: 10,
    display: "block",
    position: "absolute",
    backgroundColor: "#333",
    top: -4,
    right: 17,
    transform: [{ rotate: "45deg" }],
  },
  logoIcon: {
    width: 160,
    height: 64,
    display: "block",
    backgroundSize: "contain",
  },
  icon55150: {
    height: 55,
    width: 150,
    display: "block",
    backgroundSize: "contain",
  },

  createAccHolder: {
    marginBottom: 20,
    screen: ({ width }) => {
      if (width <= 359) {
        return {
          marginRight: -16,
          marginLeft: -16,
        };
      }
    },
  },
  createAccount: {
    color: "#38c2ef",
    fontSize: "14",
    textTransform: "none",
    screen: ({ width }) => {
      if (width <= 640) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  
  termsAndConditionsWrapper: {
    marginBottom: 16,
    screen: ({ width, height }) => {
      if (width === 1366) {
        return {
          marginBottom: 20,
        };
      }
      if (width <= 991) {
        return {
          marginBottom: 10,
        };
      }
    },
  },
  termsAndConditions: {
    color: "#38c2ef",
  },
});
