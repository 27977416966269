import R14 from "../core";
import { STATUS, RADIO_OPT } from "../constant/app.data.constant";
export default class FilterDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.initialValues = this.getInitialStates();
    this.state = {
      isFilterOpen: false,
      showResetFilterBtn: false,
      ...this.initialValues,
      oldFilterState: this.initialValues,
      selectedBU: "",
      fromDate: null,
      toDate: null
    };
  }

  resetFilter() {
    this.setState({
      selectedChk: "billDate",
      isFilterOpen: false,
      showResetFilterBtn: false,
      ...this.initialValues,
      oldFilterState: this.initialValues,
      selectedBU: "",
      selectedDateChk: "billDate",
      fromDate: null,
      toDate: null,
    });
  }

  getInitialStates() {
    return {
      selectedChk: "billDate",
      sortOrder: "DESC",
      statusType: STATUS,
      selectedStatus: [],
      radioOpt: RADIO_OPT,
      selectedDateChk: "billDate",
    };
  }
}
