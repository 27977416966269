import React from "react";
import R14, {
  View,
  Text,
  StyleSheet,
  SelectMenuField,
  TextInputField,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { SEARCH_TYPE } from "../../constant/app.data.constant";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";


export default R14.connect(
  class PrimaryProviderAddress extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        alertMessage: ""
      };
    }

    render() {
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      const { onSearchStateCity, onStateChange, statesList, trimExceededCharZipCode, onCityName, citiesList, isVisiable, formatLicenseText, isEditTreatmentAddress, isTreatmentAddressList } = this.props;
      return (
        <View style={[!!isVisiable ? null : styles.displayNone]}>
          {/* Treatment Address Title */}
          <View style={[commonStyles.marginBottom16]}>
            <Text style={[
              commonStyles.blueButtonColor,
              commonStyles.fontWeight500,
              commonFontStyles.workSans,
              commonStyles.heading6,
              commonStyles.darkGrayColor
            ]}>{'Primary Treatment Address'}</Text>
          </View>
          <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexBasis48,
                styles.provideName,
              ]}
            >
              <TextInputField
                name="primaryAddressLine1"
                label={CommonString.AddressLabel + "* "}
                required={CommonString.EnterAddressRequired}
                validator="min2Max70"
                multiline
                style={[
                  commonFontStyles.workSans,
                  commonStyles.heading6,
                  customCommonStyles.customSsidInputField,
                  styles.commentsField,
                  customCommonStyles.inputHeight,
                  commonStyles.paddingTop8
                ]}
                labelTextStyle={[
                  commonStyles.labelText,
                  commonFontStyles.workSans,
                  commonStyles.heading6,
                  clientStyle.secondaryBgColor
                ]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexBasis48,
                styles.enterNPI,
                styles.customMarginTop30
              ]}
            >
              <SelectMenuField
                style={[commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, commonStyles.marB0, commonStyles.paddingTop8]}
                name="primaryStateObject"
                label={CommonString.StatesLabel + "*"}
                required={CommonString.SelectStateRequired}
                items={statesList}
                onValueChange={(val) => onStateChange(val)}
                searchable
                itemLoader={async ({ search }) => {
                  return await onSearchStateCity(SEARCH_TYPE.STATE, false, search);
                }}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor,]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
            </View>
          </View>

          <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexBasis48,
                styles.provideName,
                commonStyles.marTop30
              ]}
            >
              <SelectMenuField
                style={[commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, commonStyles.marB0]}
                name="primaryCityObject"
                label={CommonString.CityLabel + "*"}
                required={CommonString.SelectCityRequired}
                items={citiesList}
                onValueChange={(val) => onCityName(val)}
                searchable
                itemLoader={async ({ search }) => {
                  return await onSearchStateCity(SEARCH_TYPE.CITY, false, search);
                }}

                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor,]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexBasis48,
                styles.enterNPI,
                commonStyles.marTop30,
                commonStyles.marginBottom30
              ]}
            >
              <TextInputField
                name="primaryPostCode"
                label={CommonString.ZipCodeLabel + "* "}
                required={CommonString.EnterZipCodeRequired}
                validator="min5Max10"
                onChangeText={trimExceededCharZipCode}
                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                labelTextStyle={[
                  commonStyles.labelText,
                  commonFontStyles.workSans,
                  commonStyles.heading6,
                  clientStyle.secondaryBgColor
                ]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor,]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
            </View>
          </View>
          <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexBasis48,
                styles.provideName,
                commonStyles.marginTop3
              ]}
            >
              <TextInputField
                name="primaryLicenseDetails"
                label={`${CommonString.StateLicenseLabel} `}
                validator="max20AlphaNumeric"
                onChangeText={formatLicenseText}
                readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                disabled={!isEditTreatmentAddress && !isTreatmentAddressList ? false : true}
                style={[
                  commonFontStyles.workSans,
                  commonStyles.heading6,
                  customCommonStyles.customSsidInputField,
                  styles.commentsField,
                  customCommonStyles.inputHeight
                ]}
                labelTextStyle={[
                  commonStyles.labelText,
                  commonFontStyles.workSans,
                  commonStyles.heading6,
                  clientStyle.secondaryBgColor
                ]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexBasis48,
                styles.enterNPI,
                styles.customMarginTop30,
              ]}
            ></View>
          </View>
        </View>
      )
    }
  }
);
const styles = StyleSheet.create({
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  textFieldsHolder: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexDirection: "column",
        };
    },
  },
  provideName: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 0,
        };
    },
  },
  commentsField: {
    resize: "none",
    lineHeight: 15,
  },
  enterNPI: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginLeft: 0,
        };
    },
  },
  customMarginTop30: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 30,
        };
    },
  },
});
