import R14 from "../../core";
import { FULL_PAYMENT } from "../../constant/sepa.constant";
import { PAGE_PATH } from "../../constant/app.data.constant";
import { CommonString } from "../../constant/appCommonText";

export default class SEPARTPDomain extends R14.Domain {
    constructor(props) {
        super(props);
        this.state = {
            sepaBills: [],
            totalBills: 0,
            currentPage: 1,
            totalPages: 1,
            resultsPerPage: 9,
            isFetched: false,
            newBillCount: 0,
            outstandingBillCount: 0,
            acceptBillCount: 0,
            rejectBillCount: 0,
            sepaBlockStatus: "NEW",   // New/Accepted/Rejected/Outstanding/All
            c_bill: null
        }
    }

    async getSPABillsList(payload, fields = "uid payerId payerName billerId billerName accountNumber billStatus paymentTypes updatedOn createdOn billAmount expiryDate resourceId bookmark selectedPaymentType noOfTrans billerIBAN billDate") {
        try {
            let res = await this.dm.queryDomain.middleware(this.api.qry(
                ` query GetSepaBills($search: String,  $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
                getSepaBills(search: $search,  page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
                    nodes { ${fields} }
                    totalCount
                }
            }`,
                payload,
            ));
            let bills = res.data.getSepaBills
            if (!!bills.nodes && bills.nodes.length) bills.nodes = this.mangePaymentData(bills.nodes);
            this.setState({
                sepaBills: bills.nodes,
                totalBills: bills.totalCount,
            })
            return bills;
        } catch (err) {
            console.log(err)
            return [];
        }
    };

    async syncSEPABills(payload, fields = "uid resourceId bookmark payerId billerId billerName billAmount paymentTypes selectedPaymentType messageId billDate billStatus expiryDate createdOn") {
        let filter = {
            sort: { field: "billDate", order: "ASC" },
            page: 1,
            resultsPerPage: this.state.resultsPerPage
        };

        let result = await this.dm.queryDomain.middleware(this.api.qry(
            `query SyncSEPABills($page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
            syncSEPABills(page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
                newCount
                outstandingCount
                acceptCount
                rejectCount
                sepaBillResultSet
                {
                    totalCount @include(if: true)
                    nodes {
                    ${fields}
              } 
             }
            }
          }`,
            !!payload ? payload : filter
        ));

        if (!!result.data.syncSEPABills) {
            this.setState({
                newBillCount: result.data.syncSEPABills.newCount,
                outstandingBillCount: result.data.syncSEPABills.outstandingCount,
                acceptBillCount: result.data.syncSEPABills.acceptCount,
                rejectBillCount: result.data.syncSEPABills.rejectCount,
            });

            if (!!result.data.syncSEPABills.sepaBillResultSet && !!result.data.syncSEPABills.sepaBillResultSet.nodes) {
                let totalCount = result.data.syncSEPABills.sepaBillResultSet.totalCount;
                const bills = this.mangePaymentData(result.data.syncSEPABills.sepaBillResultSet.nodes);
                const { currentPage, resultsPerPage } = this.state;
                this.setState({
                    totalBills: totalCount,
                    totalPages: Math.ceil(totalCount / resultsPerPage),
                    isFetched: currentPage === 1 ? true : false,
                    sepaBills: bills
                });
                return this.state.sepaBills;
            }
            else return [];
        }
        else return [];
    }

    async toggleBookmark(uid) {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.mutate(
                `mutation ToggleSrtpPriority($bid: String!) {
                  toggleSrtpPriority(bid: $bid){
                   success
                    }
                }`,
                { bid: uid }
            ));
            return result.data.toggleSrtpPriority.success;
        } catch (error) {
            return false
        }
    }

    async updateSelectedPmtType(payload) {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.mutate(
                `mutation UpdateSelectedPmtType($bid: String!, $pmtInfId: String!) {
                  updateSelectedPmtType(bid: $bid, pmtInfId: $pmtInfId){
                    success
                  }
                }`,
                payload
            ));
            return result.data.updateSelectedPmtType.success;
        } catch (error) {
            return false
        }
    }

    mangePaymentData(bills) {
        return bills.map(bill => {
            let paymentType = !!bill.paymentTypes ? JSON.parse(bill.paymentTypes) : [];
            bill.fullPay = [];
            bill.fullPayAmount = 0;
            bill.installment = [];
            bill.installmentAmount = 0;
            paymentType = paymentType.map(type => {
                if (!!type.pmtInfId) {
                    const OT_IN = type.pmtInfId.split("/")[0];
                    if (OT_IN === FULL_PAYMENT) {
                        type.cdtTrfTx.forEach((cdt) => {
                            bill.fullPayAmount = !!cdt.billAmount ? +cdt.billAmount + bill.fullPayAmount : bill.fullPayAmount;
                            bill.fullPay.push({
                                pmtInfId: type.pmtInfId,
                                expiryDate: type.expiryDate,
                                billAmount: cdt.billAmount,
                                instalmentId: cdt.InstalmentId,
                                endToEndIdentification: cdt.endToEndIdentification,
                                executionDate: cdt.executionDate,
                                createdOn: bill.createdOn,
                            })
                        })
                    } else {
                        type.cdtTrfTx.forEach((cdt, idx) => {
                            bill.installmentAmount = !!cdt.billAmount ? +cdt.billAmount + bill.installmentAmount : bill.installmentAmount;
                            bill.installment.push({
                                pmtInfId: type.pmtInfId,
                                expiryDate: type.expiryDate,
                                billAmount: cdt.billAmount,
                                instalmentId: cdt.InstalmentId,
                                endToEndIdentification: cdt.endToEndIdentification,
                                executionDate: cdt.executionDate,
                                createdOn: bill.createdOn,
                                installment: `${idx + 1} out of ${type.cdtTrfTx.length}`
                            })
                        });
                    }
                    return OT_IN;
                }
                return ""
            });
            paymentType = !!paymentType.length ? paymentType.join("/") : "";
            if (!!bill.selectedPaymentType) paymentType = bill.selectedPaymentType.split("/")[0];
            return {
                ...bill,
                paymentTypes: !!bill.paymentTypes ? JSON.parse(bill.paymentTypes) : [],
                paymentType: paymentType,
                fullPayAmount: bill.fullPayAmount.toFixed(2),
                installmentAmount: bill.installmentAmount.toFixed(2),
            };
        })
    }

    async getBill(rid, field = "uid payerId payerName billerId billerName accountNumber billStatus paymentTypes updatedOn createdOn billAmount expiryDate resourceId bookmark selectedPaymentType noOfTrans billerIBAN billDate currency") {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.qry(
                `query GetSEPABill($rid: String!) {
                    getSEPABill(rid: $rid){
                        sepaBill { ${field} }
                        sepaBillDetail { uid sepaBillUid pmtInfId InstalmentId endToEndIdentification billAmount executionDate billStatus amountPaid currency }
                    }
                }`,
                { rid: rid }
            ));
            return result.data.getSEPABill;
        } catch (error) {

        }
    }

    async getSEPABillMessage(bId, fields = "uid sepaBillDetailUid messageType resourceId messageId stsId transactionStatus statusReasonInfo actionOn actionId actionBy createdBy createdOn attachments {id issueDate fileName format }") {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.qry(
                `query GetSEPABillMessage($bid: String!) {
                    getSEPABillMessage(bid: $bid){
                        nodes { ${fields} }
                    totalCount
                    }
                }`,
                {bid : bId}
            ));
            return result.data.getSEPABillMessage.nodes;
        } catch (error) {
                return [];
        }
    }

    async getSEPABillSubMessage(bId, bDetailid, fields = "uid sepaBillDetailUid messageType resourceId messageId stsId transactionStatus statusReasonInfo actionOn actionId actionBy createdBy createdOn attachments {id issueDate fileName format }") {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.qry(
                `query GetSEPABillSubMessage($bid: String!, $bdetailId: String!) {
                    getSEPABillSubMessage(bid: $bid, bdetailId: $bdetailId){
                        nodes { ${fields} }
                    totalCount
                    }
                }`,
                {bid : bId, bdetailId: bDetailid }
            ));
            return result.data.getSEPABillSubMessage.nodes;
        } catch (error) {
            return [];
        }
    }

    async acceptSRTP(payload, field) {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.mutate(`
            mutation AcceptSRTP($input: AcceptRejectInput!) {
                    acceptSRTP(input: $input){
                    success
                    message
                    sepaBill { ${field} }
                  }
                }`,
                { input: payload }
            ));
            return result.data.acceptSRTP;
        } catch (error) {
            return { success: false, message: error.message }
        }
    }

    async acceptRejectSRTP(payload, action) {
        let field = "uid payerId payerName billerId billerName accountNumber billStatus paymentTypes updatedOn createdOn billAmount expiryDate resourceId bookmark selectedPaymentType noOfTrans billerIBAN billDate currency"
        if (action === PAGE_PATH.ACCEPTLATER || action === PAGE_PATH.ACCEPTNOW)
            return await this.acceptSRTP(payload, field);
        else if (action === PAGE_PATH.REJECT)
            return await this.rejectSRTP(payload, field);
        else
            return { success: false, message: CommonString.InvalidAccess }
    }

    async rejectSRTP(payload, field) {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.mutate(`
                mutation RejectSRTP($input: AcceptRejectInput!) {
                    rejectSRTP(input: $input){
                    success
                    message
                    sepaBill { ${field} }
                  }
                }`,
                { input: payload }
            ));
            return result.data.rejectSRTP;
        } catch (error) {
            return { success: false, message: error.message }
        }
    }

    async getSEPAFile(file) {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.qry(
                `query GetSEPAFile($rId: String!, $pmtInfId: String!, $bId: String!, $fileId: String!) {
                    getSEPAFile(rId: $rId, pmtInfId: $pmtInfId bId: $bId, fileId: $fileId)
                }`,
                { ...file }
            ));
            return result.data.getSEPAFile;
        } catch (error) {
            return "";
        }
    }

    async markAsPaidPayment(payload) {
        try {
            let result = await this.dm.queryDomain.middleware(this.api.mutate(`
                mutation MarkAsPaidPayment($input: UpdateSepaBillDetailInput!) {
                    markAsPaidPayment(input: $input){
                    success
                    message
                  }
                }`,
                { input: { ...payload } }
            ));
            return result.data.markAsPaidPayment;
        } catch (error) {
            return { success: false, message: error.message }
        }
    }

}