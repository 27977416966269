import React from "react";
import R14, { StyleSheet, View, Text, Platform } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import DateUtils from "../../utils/DateUtils";
import DownloadComponent from "./DownloadComponent";
import CommonUtility from "../../utils/CommonUtility";

export default R14.connect(
  class SenderComponent extends React.Component {
    render() {
      const { leaf, userDetails } = this.props;
      let userShortName = (userDetails.firstName + " " + userDetails.lastName).trim();
      return (
        <View style={[styles.chatBoxWidth]}>
          <View style={[commonStyles.marLAuto]}>
            <View style={[commonStyles.minWidth150]}>
              <View style={[Platform.OS === 'web' && commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View
                  style={[
                    styles.userInitialOuter,
                    commonStyles.flex1,
                    commonStyles.alignItemsCenter,
                    commonStyles.justifyContentCenter,
                  ]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.fileSize, commonStyles.fontWeight500]}>
                    {CommonUtility.shortName(userShortName)}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.transperentBtnColor, commonStyles.guestName, commonFontStyles.workSans, styles.guestNameStyle]}>
                    {userShortName}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[commonStyles.minWidth150, commonStyles.hostText]}>
              <View>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans, commonStyles.flexWrap, commonStyles.flex1, commonStyles.wordBreakAll, commonStyles.flexGrow0]}>{leaf.envelope.messageBody.messageBody}</Text>
              </View>
              {!!leaf.attachments && !!leaf.attachments.length ?
                  <View>
                    <DownloadComponent item={leaf} />
                  </View>
                : null}
              <Text style={[commonStyles.hostTextBorder]}></Text>
              </View>
            <View style={commonStyles.textAlignRight}>
              <Text
                style={[
                  commonStyles.exSmallHeading,
                  // commonStyles.chatTiming,
                  commonStyles.transperentBtnColor,
                  commonStyles.marLAuto,
                  commonFontStyles.workSans,
                  commonStyles.textAlignRight
                ]}>
                {DateUtils.dateTodayYesterday(leaf.envelope.messageHeader.messageDateTime)}
              </Text>
            </View>
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  chatBoxWidth: {
    marginBottom: 15,
    maxWidth: "85%",
    marginLeft: "auto",
    paddingRight: 12,
    // marginRight: -16,
    screen: ({ width }) => {
      if (width <= 380)
      return {
          maxWidth: "90%",
        }
    }
  },
  responceMsg: {
    marginBottom: 3,
  },
  userInitialOuter: {
    height: 20,
    width: 20,
    minWidth: 20,
    maxWidth: 20,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    backgroundColor: "#e7e7e7",
  },
  guestNameStyle: {
    paddingRight: 10,
  }
});
