import React, { Component } from "react";
import { StyleSheet, View, Text, Touchable, Image, FlatList, Platform, Dimensions } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
import { responsiveTableStyle } from "../assets/css/responsiveTableStyle";
import CommonUtility from "../utils/CommonUtility";
import Bookmarked from "./Bookmared";
import StatusComponent from "./StatusComponent";
import NoRecordsFound from "../components/NoRecordsFound";
import DateUtils from "../utils/DateUtils";
import { CURRENCY, BILLER_STATUS, BLOCK_UNBLOCK, BILL_STATUS, BILL_OPTS, } from "../constant/app.data.constant";
import BlockMenu from "../components/BlockMenu";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import SearchIconComponent from "./SearchIconComponent";
import FilterIconComponent from "./FilterIconComponent";
import AddMessageIconComponent from "./AddMessageIconComponent";
import DashboardHeaderSection from "./DashboardHeaderSection";
import RadioButtons from "./RadioButtons";
import SepaBillsComponent from "./SEPA/SepaBillsComponent";

const delinedIcon = require("../assets/images/delined.png");
const attachedFileIcon = require("../assets/images/attachedFileIcon.png");
const removeFilterIcon = require("../assets/images/NewRemoveFilterIcon.png");

export default class ResponsiveTableComponent extends Component {

  renderSelectedFilterOpt(isMakePayment, showResetFilterBtn) {
    const { statusOtp, handleCross } = this.props;
    return !!statusOtp && !!statusOtp.length ? (
      <View
        style={[
          commonStyles.flex1,
          commonStyles.flexDirectionRow,
          commonStyles.justifyContentSpaceBetween,
          responsiveTableStyle.filterBySection,
          !!statusOtp && !!statusOtp.length ? responsiveTableStyle.filterBySectionWithFiler : null,
          !!isMakePayment ? null : commonStyles.displayNone
        ]}>
        <View style={[responsiveTableStyle.filtereHading]}>
          <Text style={[responsiveTableStyle.marB10, commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight700]}>Filter by: </Text>
        </View>
        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexGrow1, commonStyles.flexWrap]}>
          {statusOtp.map((el, ind) => (
            <StatusComponent
              key={ind}
              label={el}
              isSelected={true}
              circleNotAllowed={true}
              hasClose
              marginBottom={[commonStyles.marB0]}
              onCrossClick={() => {
                handleCross(el);
              }}
              wrapperStyle={[styles.statusWrapperStyle]}
            />
          ))}
        </View>
        {!!statusOtp && !!statusOtp.length ? (
          <View style={[commonStyles.marginLeftRight16]}>
            {!!showResetFilterBtn ?
              <Touchable onPress={this.props.onRestoreFilter}>
                <View>
                  <Image source={removeFilterIcon} style={commonStyles.icon18} />
                </View>
              </Touchable>
              : null}
          </View>
        ) : null
        }
      </View>
    ) : null;
  }

  render() {
    const { simpleMessage, displayPamList, filterBills, isDashboard, leftMenuToggle, isMobileDevice, isSimpleMessageScreen, isBillerDirectory, isMakePayment, currentRoute, headerText, newBillCount, overdueBillCount, outstandingBillCount, selectedChk, sortOrder, showResetFilterBtn, selectedScreen, acceptBillCount, rejectBillCount, userLoginDetail } = this.props;

    let getData = [];
    if (isDashboard && selectedScreen !== "RTP" ) {
      getData = filterBills;
    } else if (!!isSimpleMessageScreen) {
      getData = simpleMessage;
    } else if (!!isBillerDirectory) {
      getData = displayPamList;
    } else if (!!isMakePayment) {
      getData = !!showResetFilterBtn ? filterBills : CommonUtility.displayBillsFilter(filterBills, selectedChk, sortOrder);
    } else if (!!isDashboard) {
      getData = CommonUtility.displayBillsFilter(filterBills, selectedChk, sortOrder);
    }
    let maxLimit = isBillerDirectory ? Dimensions.get("window").width <= 375 ? 35 : 40 : Dimensions.get("window").width <= 375 ? 20 : 20;
    return (
      <>
        {(isDashboard || isMakePayment) ? (
          <View style={[responsiveTableStyle.pageTitle, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexGrow0, commonStyles.justifyContentSpaceBetween, commonStyles.alignItemsCenter]}>
            <View>
              <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.contentColor]}>
                {headerText}
              </Text>
            </View>
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd, commonStyles.alignItemsCenter, commonStyles.flexBasis50]}>
              {isDashboard && !!userLoginDetail && userLoginDetail.resourceAccess && userLoginDetail.resourceAccess.length > 1 ?
                BILL_OPTS.map((el, key) => (
                  <View key={"tabItems_" + key} style={[commonStyles.marginRight16]}>
                    <RadioButtons radioButtonsClass={styles.minHeight27} textOnly={true} textOnlyStyles={styles.height11} key={key} options={el} onValueChange={this.props.onValueChange} isSelected={selectedScreen} />
                  </View>
                ))
                : null}
              {!!isMakePayment ? (
                <View style={[commonStyles.paddingRight16]} >
                  <Touchable onPress={() => this.props.convertToCSV(getData, "Make Payment")} style={[currentRoute === "makePaymentRoutes" ? null : commonStyles.marginRight16]}>
                    <Image source={attachedFileIcon} style={commonStyles.icon18} />
                  </Touchable>
                </View>
              ) : null}
              {currentRoute === "dashboard" || currentRoute === "makePaymentRoutes" ? <SearchIconComponent screenName={currentRoute} /> : null}
              {currentRoute === "makePaymentRoutes" ? <FilterIconComponent /> : null}
              {currentRoute === "makePaymentRoutes" ? (
                <Touchable style={[currentRoute === "makePaymentRoutes" ? null : commonStyles.marginRight16]} onPress={() => this.props.onBulkPaymentNavigation(getData)}>
                  <View>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight500, commonStyles.exSmallHeading, responsiveTableStyle.viewAllLink]}>Bulk Pay</Text>
                  </View>
                </Touchable>
              ) : null}
            </View>
          </View>
        ) : (
          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexGrow0, commonStyles.justifyContentSpaceBetween, commonStyles.alignItemsCenter]}>
            <View style={[responsiveTableStyle.pageTitle]}>
              <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500]}>
                {headerText}
              </Text>
            </View>
            <View style={[commonStyles.paddingLeftRight16, responsiveTableStyle.pageTitle]}>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                {currentRoute === "billersDirectoryRoute" || currentRoute === "simpleMessageRoute" ? (
                  <View style={[commonStyles.paddingRight16]} >
                    <Touchable onPress={() => this.props.convertToCSV(getData, currentRoute === "billersDirectoryRoute" ? "Payer Authorization" : "Message Biller")}>
                      <Image source={attachedFileIcon} style={commonStyles.icon18} />
                    </Touchable>
                  </View>
                ) : null}
                {currentRoute === "simpleMessageRoute" ? <AddMessageIconComponent /> : null}
                {currentRoute === "billersDirectoryRoute" || currentRoute === "simpleMessageRoute" ? <SearchIconComponent screenName={currentRoute} /> : null}
              </View>
            </View>
          </View>
        )}
        <View style={[Platform.OS === 'web' && responsiveTableStyle.filterBySectionWrapper]}>{isMakePayment && !isDashboard ? this.renderSelectedFilterOpt(isMakePayment, showResetFilterBtn) : null}
        </View>
        {isDashboard ? (
          <DashboardHeaderSection
            itemCount={!!getData && !!getData.length ? getData.length : 0}
            onViewAllNavigation={this.props.onViewAllNavigation}
            onBulkPaymentNavigation={() => this.props.onBulkPaymentNavigation(getData)}
            convertToCSV={() => this.props.convertToCSV(getData, "Dashboard")}
            navigateTo={() => this.props.navigateTo("dashboard")}
            onBlockNavigatation={(val) => this.props.onBlockNavigatation(val)}
            overdueBillCount={overdueBillCount}
            outstandingBillCount={outstandingBillCount}
            newBillCount={newBillCount}
            acceptBillCount={acceptBillCount}
            rejectBillCount={rejectBillCount}
            selectedScreen={selectedScreen}
          />
        ) : null}
        {isDashboard && selectedScreen !== "RTP" ? <SepaBillsComponent isDashboard = {isDashboard} customStyles = {styles.listingSection} items={filterBills} /> :
          <View style={[responsiveTableStyle.table, commonStyles.flexGrow0, isDashboard ? null : responsiveTableStyle.tableCustomPadding, !!getData.length <= 0 ? responsiveTableStyle.tablepadding : null, commonStyles.flex1]}>
            {!!getData.length > 0 ? (
              <View
                style={[
                  responsiveTableStyle.thead,
                  commonStyles.justifyContentSpaceBetween,
                  isBillerDirectory ? responsiveTableStyle.billerDirectoryThead : null
                ]}>
                <View
                  style={[
                    commonStyles.dFlex,
                    commonStyles.flexGrow1,
                    commonStyles.flexGrow1,
                    responsiveTableStyle.tdLeftSection,
                    responsiveTableStyle.thLeftSection,
                    isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerLeftSection : null,
                    isBillerDirectory ? responsiveTableStyle.billerDirectoryBillerLeftSection : null,
                    isBillerDirectory ? responsiveTableStyle.billerDirectoryBillerThLeftSection : null,
                  ]}>
                  {isDashboard || isMakePayment ? (
                    <View style={[responsiveTableStyle.bookedMarkTh, isDashboard ? responsiveTableStyle.dashboardBookedMarkTh : null]}></View>
                  ) : null}

                  <View
                    style={[
                      responsiveTableStyle.billerName,
                      commonStyles.dFlex,
                      isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerName : null,
                      !isDashboard && isMakePayment ? responsiveTableStyle.makePaymentBillName : null,
                      isBillerDirectory ? responsiveTableStyle.billerDirectoryBillrName : null,
                      leftMenuToggle && isMakePayment ? responsiveTableStyle.leftMenuToggleMakePaymentBillname : responsiveTableStyle.leftMenuToggleOffMakePaymentBillname,
                      leftMenuToggle && isDashboard ? responsiveTableStyle.leftMenuToggleDashboardBillname : null,
                      leftMenuToggle ? null : isDashboard ? responsiveTableStyle.leftMenuToggleisDashboardBillname : null,
                      isBillerDirectory
                        ? leftMenuToggle
                          ? responsiveTableStyle.leftMenuToggleisBillerDirectoryBillname
                          : responsiveTableStyle.leftMenuToggleisOffBillerDirectoryBillname
                        : null,
                      isSimpleMessageScreen
                        ? leftMenuToggle
                          ? responsiveTableStyle.leftMenuToggleisSimpleMessageBillname
                          : responsiveTableStyle.leftMenuToggleisOffSimpleMessageBillname
                        : null,
                    ]}>
                    <View style={[responsiveTableStyle.th]}>
                      <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>Biller</Text>
                    </View>
                  </View>

                  {!!isBillerDirectory ? (
                    <View
                      style={[
                        responsiveTableStyle.accountNumberTh,
                        commonStyles.flex1,
                        leftMenuToggle ? responsiveTableStyle.leftMenuToggleAccountNumberTh : responsiveTableStyle.leftMenuToggleOffAccountNumberTh
                      ]}>
                      <View style={[responsiveTableStyle.th]}>
                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>Account Number</Text>
                      </View>
                    </View>
                  ) : null}

                  <View
                    style={[
                      responsiveTableStyle.billerDetails,
                      commonStyles.dFlex,
                      commonStyles.flexGrow1,
                      isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerDetails : null,
                      isBillerDirectory ? responsiveTableStyle.billerDirectoryBillerDetailsTh : null,
                      isBillerDirectory && leftMenuToggle ? responsiveTableStyle.leftMenuTogglebillerDirectoryBillerDetailsTh : null,
                    ]}>
                    {!isBillerDirectory && (
                      <View
                        style={[
                          responsiveTableStyle.th,
                          responsiveTableStyle.billerSubj,
                          commonStyles.flexGrow1,
                          isSimpleMessageScreen ? responsiveTableStyle.simpleMessageTheadBillerSubject : null,
                          leftMenuToggle ? null : isMakePayment ? responsiveTableStyle.leftMenuToggleBillerSubj : null,
                          leftMenuToggle ? null : isSimpleMessageScreen ? responsiveTableStyle.leftMenuToggleSimpleMessageBillerSubj : null,
                          currentRoute === "makePaymentRoutes" ? responsiveTableStyle.makePayBillerSubj : null,
                          leftMenuToggle ? null : currentRoute === "dashboard" ? responsiveTableStyle.leftMenuToggleOffDashboardBillerSubj : null,
                          leftMenuToggle ? null : currentRoute === "makePaymentRoutes" ? responsiveTableStyle.leftMenuToggleOffMakePaymentBillerSubj : null,
                        ]}>
                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>{isSimpleMessageScreen ? "Subject" : "Bill Subject"}</Text>
                      </View>
                    )}
                    {isSimpleMessageScreen && (
                      <View
                        style={[
                          responsiveTableStyle.th,
                          responsiveTableStyle.billerSubj,
                          commonStyles.flexGrow1,
                          isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerLastMsg : commonStyles.displayNone,
                        ]}>
                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>Last Message</Text>
                      </View>
                    )}
                    {!isBillerDirectory && (
                      <View
                        style={[
                          responsiveTableStyle.th,
                          responsiveTableStyle.dateAndTime,
                          commonStyles.flexGrow1,
                          isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillDateTimeTHead : null,
                          leftMenuToggle ? null : currentRoute === "dashboard" ? responsiveTableStyle.leftMenuToggleDateAndTime : null,
                          leftMenuToggle ? null : isSimpleMessageScreen ? responsiveTableStyle.leftMenuToggleSimpleMessageDateAndTime : null,
                          currentRoute === "makePaymentRoutes" ? responsiveTableStyle.makePayDateAndTime : null,
                        ]}>
                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>{isSimpleMessageScreen ? "Date" : "Creation Date"}</Text>
                      </View>
                    )}
                    {!isSimpleMessageScreen && !isBillerDirectory && (
                      <View
                        style={[
                          responsiveTableStyle.th,
                          responsiveTableStyle.dueOn,
                          commonStyles.flexGrow1,
                          isMakePayment ? responsiveTableStyle.makePaymentDueOn : null,
                          isDashboard ? responsiveTableStyle.dashboardDueOn : null,
                          leftMenuToggle ? null : isMakePayment ? responsiveTableStyle.leftMenuToggleDueOn : null,
                        ]}>
                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>Due On</Text>
                      </View>
                    )}
                    {isBillerDirectory ? (
                      <View style={[responsiveTableStyle.th, responsiveTableStyle.billerDirectoryStatus]}>
                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>Status</Text>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View
                  style={[
                    responsiveTableStyle.billerStatus,
                    commonStyles.dFlex,
                    responsiveTableStyle.tdRightSection,
                    responsiveTableStyle.thRightSection,
                    isMakePayment ? responsiveTableStyle.makePaymentTheadRightSection : null,
                    isMakePayment ? responsiveTableStyle.makePaymenttdRightSection : null,
                    isBillerDirectory ? responsiveTableStyle.billerDirectorytdRightSection : null,
                    leftMenuToggle ? null : isMakePayment ? responsiveTableStyle.leftMenuToggleTheadRightSection : null,
                    leftMenuToggle || isDashboard ? responsiveTableStyle.dashboardMakePaymenttdRightSection : responsiveTableStyle.LeftMenuOffdashboardMakePaymenttdRightSection,
                    isBillerDirectory ? responsiveTableStyle.billerDirectoryMakePaymenttdRightSection : null,
                    !isSimpleMessageScreen ? null : responsiveTableStyle.simpleMessageBillRightSectionTHead,
                    currentRoute === "makePaymentRoutes" ? responsiveTableStyle.makePayBillRightSectionTHead : null,
                  ]}>
                  {!isSimpleMessageScreen && !isBillerDirectory && (
                    <View
                      style={[
                        responsiveTableStyle.th,
                        responsiveTableStyle.billStatus,
                        responsiveTableStyle.thBillStatus,
                        isDashboard ? responsiveTableStyle.dashboardThBillStatus : null,
                        isMakePayment ? responsiveTableStyle.makePaymentThBillStatus : null,
                        leftMenuToggle ? null : isMakePayment ? responsiveTableStyle.leftMenuToggleBillStatus : null,
                      ]}>
                      <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>Status</Text>
                    </View>
                  )}
                  {!isSimpleMessageScreen && !isBillerDirectory && (
                    <View
                      style={[
                        responsiveTableStyle.th,
                        responsiveTableStyle.billAmount,
                        responsiveTableStyle.thBillAmount,
                        leftMenuToggle ? null : isMakePayment ? responsiveTableStyle.leftMenuToggleBillAmount : null,
                      ]}>
                      <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>Amount</Text>
                    </View>
                  )}
                  <View
                    style={[
                      responsiveTableStyle.th,
                      responsiveTableStyle.thBillAction,
                      responsiveTableStyle.billAction,
                      responsiveTableStyle.thAction,
                      isSimpleMessageScreen || isBillerDirectory ? responsiveTableStyle.simpleMessageBillerAction : null,
                      isMakePayment ? responsiveTableStyle.makePaymentAction : null,
                      isBillerDirectory ? responsiveTableStyle.billerDirectoryAction : null,
                      leftMenuToggle ? null : isMakePayment ? responsiveTableStyle.leftMenuToggleBillACtion : null,
                      leftMenuToggle
                        ? isSimpleMessageScreen
                          ? responsiveTableStyle.leftMenuToggleSimpleMessageBillACtion
                          : responsiveTableStyle.leftMenuToggleOffSimpleMessageBillACtion
                        : null,
                      leftMenuToggle ? (isDashboard ? responsiveTableStyle.leftMenuToggleDashboardBillACtion : responsiveTableStyle.leftMenuToggleOffDashboardBillACtion) : null,
                    ]}>
                    <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.contentColor]}>Action</Text>
                  </View>
                </View>
              </View>
            ) : null}
            <View style={[responsiveTableStyle.tboday]}>
              <FlatList
                style={[
                  responsiveTableStyle.messageComponent,
                  getData.length === 0 ? customCommonStyles.customDashboardNoListView :  null,
                ]}
                ListEmptyComponent={
                  <View style={responsiveTableStyle.padd10}>
                    <NoRecordsFound
                      noRecords={[responsiveTableStyle.noRecords, isDashboard ? null : commonStyles.marginTop15]}
                      message={this.props.message} />
                  </View>
                }
                data={getData}
                keyExtractor={(el, i) => i}
                renderItem={({ item, index }) => (
                  <View
                    key={"item_" + index}
                    style={[
                      responsiveTableStyle.tr,
                      commonStyles.flex1,
                      commonStyles.flexDirectionRow,
                      commonStyles.flexWrap,
                      commonStyles.justifyContentSpaceBetween,
                      isBillerDirectory ? responsiveTableStyle.billerDirectoryTr : null,
                      getData.length - 1 === index ? responsiveTableStyle.borderBottom1 : null,
                      currentRoute === "simpleMessageRoute" ? responsiveTableStyle.simpleMessageTr : null
                    ]}>
                    {(isMakePayment || isDashboard) &&
                      <View style={[responsiveTableStyle.bookmarkedOuter]}>
                        <View style={[commonStyles.dFlex, commonStyles.alignItemCenter]}>
                          {!BILL_STATUS[item.billStatus] && !(item.status === "blocked" || item.status === "payerblocked") ? (
                            <Bookmarked
                              bookedMarkSpacing={responsiveTableStyle.bookedMarkSpacing}
                              onToggle={() => this.props.toggleBookmark(item)}
                              isBookmarked={item.bookmark}
                            />
                          ) : null}
                          {!!(item.status === "blocked" || item.status === "payerblocked") ? (
                            <Image source={delinedIcon} resizeMode="contain" style={[responsiveTableStyle.decline]} />
                          ) : null}
                        </View>
                      </View>
                    }
                    <Touchable onPress={() => this.props.onNavigate(item)} >
                      <View style={[responsiveTableStyle.userIconDiv, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
                        {isSimpleMessageScreen && item.currentMsgCount - item.readMsgCount > 0 ? (
                          <View style={[responsiveTableStyle.messageNotification, customCommonStyles.customMessageNotification]}>
                            <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonStyles.whiteColor]}>
                              {item.currentMsgCount - item.readMsgCount}
                            </Text>
                          </View>
                        ) : null}
                        {isBillerDirectory && (
                          <View>
                            {item.totalMessages - (item.readMessages || 0) > 0 && item.status === "requested" ? (
                              <View style={[responsiveTableStyle.messageNotification, customCommonStyles.customMessageNotification]}>
                                <Text
                                  style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonStyles.whiteColor]}>
                                  {item.totalMessages - (item.readMessages || 0)}
                                </Text>
                              </View>
                            ) : null}
                          </View>
                        )}
                        <Text
                          style={[
                            commonMetricsStyle.heading6,
                            commonStyles.fontWeight500,
                            commonFontStyles.workSans,
                            commonStyles.smallHeading,
                            commonStyles.userInitial,
                            commonStyles.contentColor,
                            responsiveTableStyle.userInitial,
                            commonStyles.dBlock
                          ]}>
                          {isSimpleMessageScreen ? CommonUtility.shortName(item.billerName) : CommonUtility.shortName(item.biller && item.biller.billerName)}
                        </Text>
                      </View>
                    </Touchable>
                    <View
                      style={[
                        commonStyles.dFlex,
                        commonStyles.flexGrow1,
                        responsiveTableStyle.tdLeftSectionTd,
                        responsiveTableStyle.tdLeftSection,
                        isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerLeftSection : null,
                        isBillerDirectory ? responsiveTableStyle.billerDirectoryBillerLeftSection : null,
                      ]}>
                      <View
                        style={[
                          responsiveTableStyle.billerName,
                          commonStyles.dFlex,
                          commonStyles.margin0,
                          isSimpleMessageScreen ? responsiveTableStyle.simpleMessageTdBillerName : null,
                          (isMakePayment || isDashboard) ? responsiveTableStyle.makePaymentBillerName : null,
                          isDashboard ? responsiveTableStyle.dashboardBillerName : null,
                          isBillerDirectory ? responsiveTableStyle.billerDirectoryTdBillrName : null,
                          leftMenuToggle ? null : isBillerDirectory ? responsiveTableStyle.leftMenuToggleOffBillerDirectoryTdBillrName : null,
                          leftMenuToggle ? null : isSimpleMessageScreen ? responsiveTableStyle.leftMenuToggleOffSimpleMessageScreenTdBillrName : null,
                          commonStyles.wordBreakWord,
                        ]}>
                        <View
                          style={[
                            responsiveTableStyle.td,
                            commonStyles.dFlex,
                            commonStyles.alignItemCenter,
                            isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerNameAlignItems : null,
                            isBillerDirectory ? responsiveTableStyle.billerDirectoryBillerName : null,
                            commonStyles.wordBreakWord,
                          ]}>
                          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, responsiveTableStyle.billName, customCommonStyles.wordBreakWord, commonStyles.flexDirectionRow, commonStyles.flexGrow0, commonStyles.wordBreakWord,]}>
                            <Touchable onPress={() => this.props.onNavigate(item)} style={[responsiveTableStyle.billNameText, commonStyles.wordBreakWord,]}>
                              <View>
                                <Text
                                  style={[
                                    commonMetricsStyle.heading6,
                                    commonFontStyles.workSans,
                                    commonStyles.wordBreakWord,
                                    isBillerDirectory ? [responsiveTableStyle.billerDirectoryBillerNameText, responsiveTableStyle.paddingTop6] : null,
                                    commonStyles.fontWeight400,
                                    commonStyles.exSmallHeading,
                                    commonStyles.contentColor,
                                    responsiveTableStyle.boldTextInMView,
                                    commonStyles.dBlock
                                  ]}
                                  numberOfLines={1}>
                                  {isMobileDevice ?
                                    isSimpleMessageScreen ?
                                      !!item.billerName && item.billerName.length > maxLimit ?
                                        item.billerName.substring(0, maxLimit - 3) + '...' : item.billerName :
                                      !!item.biller.billerName && item.biller.billerName.length > maxLimit ?
                                        item.biller.billerName.substring(0, maxLimit - 3) + '...' :
                                        item.biller.billerName :
                                    isSimpleMessageScreen ?
                                      item.billerName :
                                      item.biller.billerName}
                                </Text>
                              </View>
                            </Touchable>
                          </View>
                        </View>
                      </View>
                      {
                        !!isBillerDirectory ? (
                          <View style={[commonStyles.flex1, responsiveTableStyle.webWidth16]}>
                            <View style={[leftMenuToggle && Platform.web ? responsiveTableStyle.accountNumberCls : responsiveTableStyle.leftToggleOffAccountNumberCls, customCommonStyles.customAccountNumberCls,
                            ]}>
                              <Text
                                style={[
                                  commonFontStyles.workSans,
                                  commonStyles.fontWeight400,
                                  commonStyles.exSmallHeading,
                                  commonStyles.contentColor,
                                  responsiveTableStyle.boldTextInMView,
                                  commonStyles.fullWidth,
                                  commonStyles.dBlock,
                                  styles.height18
                                ]}>
                                {!!item.accountNumber ? item.accountNumber : "N/A"}
                              </Text>
                            </View>
                          </View>
                        ) : null
                      }

                      <View
                        style={[
                          responsiveTableStyle.billerDetails,
                          customCommonStyles.xbpCustomBillerDetails,
                          commonStyles.flex1,
                          commonStyles.flexGrow1,
                          responsiveTableStyle.customFlexDirection,
                          isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerDetails : null,
                          isBillerDirectory ? responsiveTableStyle.billerDirectoryBillerDetails : null,
                        ]}>
                        {/* Biller Subject */}
                        {!isBillerDirectory && (
                          <View
                            style={[
                              responsiveTableStyle.td,
                              commonStyles.flexGrow1,
                              responsiveTableStyle.tdType,
                              responsiveTableStyle.billerSubj,
                              leftMenuToggle ? responsiveTableStyle.tdBillerSubj : responsiveTableStyle.leftMenuOfftdBillerSubj,
                              isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerSubject : null,
                              currentRoute === "makePaymentRoutes" ? responsiveTableStyle.makePayTdBillerSubj : null,
                            ]}>
                            <Touchable onPress={() => this.props.onNavigate(item)}>
                              <View style={[customCommonStyles.textOverflowEllipsis]}>
                                <Text
                                  style={[responsiveTableStyle.cardSubHeadingText, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.wordBreakWord,
                                  commonStyles.fontWeight400,
                                  commonStyles.exSmallHeading,
                                  commonStyles.contentColor, commonStyles.dBlock]}
                                  numberOfLines={1}>
                                  {isMobileDevice ? isSimpleMessageScreen ? !!item.subject && item.subject.length > maxLimit ? item.subject.substring(0, maxLimit - 3) + '...' : item.subject : !!item.subject && item.subject.length > maxLimit ? item.subject.substring(0, maxLimit - 3) + '...' : item.subject : isSimpleMessageScreen ? item.subject : item.subject}
                                </Text>
                              </View>
                            </Touchable>
                          </View>
                        )}
                        {isSimpleMessageScreen && (
                          <View
                            style={[
                              responsiveTableStyle.td,
                              commonStyles.flexGrow1,
                              responsiveTableStyle.tdType,
                              isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerLastMsg : null,
                            ]}>
                            <View>
                              <Touchable onPress={() => this.props.onNavigate(item)}>
                                <View>
                                  <Text
                                    style={[
                                      responsiveTableStyle.cardSubHeadingText,
                                      commonMetricsStyle.heading6,
                                      commonFontStyles.workSans,
                                      commonStyles.wordBreakWord,
                                      commonStyles.lightGray,
                                      commonStyles.fontWeight400,
                                      commonStyles.exSmallHeading,
                                      commonStyles.contentColor,
                                      responsiveTableStyle.boldTextInMView,
                                      commonStyles.dBlock
                                    ]}>
                                    {/* {item.lastMessage} */}
                                    {!!item.lastMessage ?
                                      item.lastMessage.length < 35 ?
                                        `${item.lastMessage}`
                                        : `${item?.lastMessage?.substring(0, 16)}...` : ""
                                    }
                                  </Text>
                                </View>
                              </Touchable>
                            </View>
                          </View>
                        )}
                        {/* Biller Date & Time */}
                        {!isBillerDirectory && (
                          <View
                            style={[
                              responsiveTableStyle.td,
                              commonStyles.flexGrow1,
                              responsiveTableStyle.dateAndTime,
                              responsiveTableStyle.tdDateAndTime,
                              leftMenuToggle ? responsiveTableStyle.leftmenuOnTdDateAndTime : null,
                              isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillDateTime : null,
                              currentRoute === "makePaymentRoutes" ? responsiveTableStyle.makePayTdDateAndTime : null,
                            ]}>
                            {isSimpleMessageScreen && (<Text style={[commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.dBlock,
                            commonStyles.fontWeight400,
                            commonStyles.exSmallHeading,
                            commonStyles.contentColor, responsiveTableStyle.simpleMessageDateTime, commonStyles.dBlock]}>
                              {!!item.lastMessageDate ? DateUtils.formatDate(item.lastMessageDate, "MM/DD/YYYY") : ""}
                            </Text>)}
                            {(isMakePayment || isDashboard) && (<Text style={[commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.dBlock,
                            commonStyles.fontWeight400,
                            commonStyles.exSmallHeading,
                            commonStyles.contentColor, commonStyles.dBlock]}>
                              {DateUtils.formatDate(item.billDate)}
                            </Text>)}
                          </View>
                        )}
                        {/* Biller Due On */}
                        {(isMakePayment || isDashboard) && (
                          <View style={[
                            responsiveTableStyle.td,
                            commonStyles.flexGrow1,
                            responsiveTableStyle.tdDueIn,
                            responsiveTableStyle.dueOn,
                            currentRoute === "makePaymentRoutes" ? responsiveTableStyle.makePayTdDueIn : null,
                            leftMenuToggle ? responsiveTableStyle.leftMenuToggleDueOn : null]}>
                            <View>
                              <Text
                                style={[
                                  commonStyles.darkGrayColor,
                                  commonMetricsStyle.heading6,
                                  commonStyles.fontWeight500,
                                  commonFontStyles.workSans,
                                  commonStyles.fontWeight500,
                                  commonStyles.smallHeading,
                                  commonStyles.contentColor,
                                  responsiveTableStyle.titleInMobile,
                                  responsiveTableStyle.boldTextInMView
                                ]}>
                                Due On
                              </Text>
                              <Text style={[commonStyles.darkGrayColor, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.fontWeight400,
                              commonStyles.exSmallHeading,
                              commonStyles.contentColor, commonStyles.dBlock]}>
                                {DateUtils.formatDate(item.dueDate)}
                              </Text>
                            </View>
                          </View>
                        )}
                        {isBillerDirectory ? (
                          <View style={[responsiveTableStyle.td, responsiveTableStyle.billerDirectoryStatus,]}>
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonMetricsStyle.smallHeading,
                                commonStyles.transformUpperCase,
                                commonStyles.fontWeight400,
                                commonStyles.exSmallHeading,
                                commonStyles.contentColor,
                                responsiveTableStyle.blocked,
                                responsiveTableStyle[`${item.status}`],
                                isBillerDirectory ? [responsiveTableStyle.paddingTop3] : null,
                                commonStyles.dBlock
                              ]}>
                              {BILLER_STATUS[item.status]}
                            </Text>
                          </View>
                        ) : null}
                      </View>
                      {/* Biller Status */}
                    </View>
                    <View
                      style={[
                        responsiveTableStyle.billerStatus,
                        commonStyles.dFlex,
                        responsiveTableStyle.tdRightSection,
                        isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillRightSection : commonStyles.flexGrowUnset,
                        isSimpleMessageScreen ? commonStyles.alignItemsStart : null,
                        isBillerDirectory ? responsiveTableStyle.billerDirectorytdRightSection : null,
                        isDashboard ? responsiveTableStyle.dashboardAlignItemsStart : null,
                        (isMakePayment || isDashboard) ? responsiveTableStyle.makePaymentTdRightPart : null,
                      ]}>
                      {(isMakePayment || isDashboard) ? (
                        <View style={[responsiveTableStyle.billStatus, (isMakePayment || isDashboard) ? responsiveTableStyle.makePaymentBillStatus : null]}>
                          <StatusComponent
                            marginRight={[commonStyles.marRT0, commonStyles.marB0]}
                            label={item.billStatus}
                            isSelected={false}
                            isBackground={false}
                            paymentStatusLabel={item.paymentStatus}
                            statusTextAlignment={[commonStyles.paddingRight0, commonStyles.padding0]}
                            circleNotAllowed={false}
                            customStatusContainer={responsiveTableStyle.customStatusContainer}
                            wrapperStyle={[styles.statusWrapperStyle]}
                            marginBottom={[commonStyles.paddingTop0, commonStyles.paddingBottom0]}
                          />
                        </View>
                      ) : null}
                      {isBillerDirectory && BLOCK_UNBLOCK[item.status] ? (
                        <View style={[responsiveTableStyle.toggleSwitch]} key={`BlockMenu_${index}`}>
                          <BlockMenu
                            status={item.status}
                            directory={true}
                            biller={item}
                            popupMenuStyle={responsiveTableStyle.popupMenuStyle}
                          />
                        </View>
                      ) : null}
                      {(isMakePayment || isDashboard) && (
                        <View style={[responsiveTableStyle.td, responsiveTableStyle.billAmount, isMakePayment ? responsiveTableStyle.makePaymentBillAmount : null, responsiveTableStyle.tdAmmount]}>
                          <Text
                            style={[
                              commonStyles.darkGrayColor,
                              commonMetricsStyle.heading6,
                              commonFontStyles.workSans,
                              commonStyles.fontWeight500,
                              commonStyles.smallHeading,
                              commonStyles.contentColor,
                              responsiveTableStyle.titleInMobile,
                              responsiveTableStyle.customTextAlignmentRight
                            ]}>
                            Amount
                          </Text>
                          <Text style={[commonStyles.darkGrayColor, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.fontWeight400,
                          commonStyles.exSmallHeading,
                          commonStyles.contentColor, responsiveTableStyle.customTextAlignmentRight, commonStyles.dBlock]}>
                            {CURRENCY.USD}{item.amountDue.toFixed(2)}
                          </Text>
                        </View>
                      )}
                      {!isBillerDirectory && (
                        <View
                          style={[
                            responsiveTableStyle.td,
                            responsiveTableStyle.billAction,
                            isSimpleMessageScreen ? responsiveTableStyle.simpleMessageBillerAction : null,
                            isMakePayment ? responsiveTableStyle.makePaymentAction : null,
                            isMakePayment ? responsiveTableStyle.makePaymentActionColumn : null,
                            isDashboard ? responsiveTableStyle.dashboardActionColumn : null,
                          ]}>
                          {isSimpleMessageScreen ? (
                            <Touchable style={responsiveTableStyle.paynowBtn} onPress={() => this.props.onNavigate(item)}>
                              <View style={[responsiveTableStyle.paynowBtnInnerWrap, customCommonStyles.customPaynowBtnInnerWrap]}>
                                <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.whiteColor, commonStyles.textAlignCenter, commonStyles.transformUpperCase,
                                commonStyles.fontWeight400,
                                commonStyles.exSmallHeading,]}>Details</Text>
                              </View>
                            </Touchable>
                          ) : null}
                          {(isMakePayment || isDashboard) ? (
                            this.props.CheckPaymentAllowOrNot(item) ?
                            <Touchable
                              style={[
                                responsiveTableStyle.paynowBtn,
                              ]}
                              onPress={() =>this.props.showPayType(item)}>
                              <View style={[responsiveTableStyle.paynowBtnInnerWrap, customCommonStyles.customPaynowBtnInnerWrap, Platform.OS !== "web" ? commonStyles.paddingTop3 : null
                              ]}>
                                <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.whiteColor, commonStyles.textAlignCenter, commonStyles.fullWidth, commonStyles.exSmallHeading, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemCenter]}>PAY NOW</Text>
                              </View>
                            </Touchable> :
                            <View
                            style={[responsiveTableStyle.paynowBtn]}>
                            <View style={[responsiveTableStyle.paynowBtnInnerWrap, customCommonStyles.customPaynowBtnInnerWrap, Platform.OS !== "web" ? commonStyles.paddingTop3 : null, customCommonStyles.disabledBtn
                            ]}>
                              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.whiteColor, commonStyles.textAlignCenter, commonStyles.fullWidth, commonStyles.exSmallHeading, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemCenter, customCommonStyles.disabledBtnText
                              ]}>PAY NOW</Text>
                            </View>
                          </View> 
                          ) : null}
                        </View>
                      )}
                    </View>
                  </View>
                )}
              />
            </View>
          </View>} 
      </>
    );
  }
}

const styles = StyleSheet.create({
  bulkPayContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 0,
  },
  statusWrapperStyle: {
    maxHeight: 26,
    minHeight: 30,
    borderRadius: 30,
    paddingLeft: 5,
    marginRight: 5,
  },
  height18: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: 14
        };
      }
    },
  },
  listingSection: {
    backgroundColor: "#fff",
    marginTop: 0,
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          borderWidth: 0,  
        };
      }
    },
  },
  minHeight27: {
    minHeight: 27,
    paddingBottom: 8,
  },
  height11: {
    height: 11,
    width: "auto"
  }
});