import R14, { } from "../core";
import CommonUtility from "../utils/CommonUtility";


export default class BillRequestDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      industries: []
    };
  }

  async getBillerCSEPaymentToggle(payload) {
    let fields = "buId buName billerId billerRef billerName industryName messagingToggleValue isPaymentDecline isPaymentDueDateExtensions isPaymentsFull isPaymentsPartial";
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(`query GetActiveBillerList($input: ActiveBillerInput!) {
        getActiveBillerList(input: $input){
              ${fields}
          }
        }`,
        { input: payload }
      ));
      return res.data.getActiveBillerList;
    } catch (err) {
      console.log("CSE/Payment Toggle :::: ", err);
    }
    return [];
  }
  
  async getActiveBillersWithAPI(billerPids = [], isMessagingConfig = false, industryName = "") {
    const { pid } = await this.dm.rest.getUser();
    if (!!pid) {
      let res = [];
      if (billerPids.length > 0)
        res = await this.dm.dashboard.getAllDateTimeHistory();

      let currentDate = new Date();
      let x = new Date(
        !!res.length && !!res[0].activeBillerLastDateTime ? res[0].activeBillerLastDateTime : `01/01/${currentDate.getFullYear()}`
      );
      x.setHours(x.getHours() - 5);
      x.toUTCString();
      let activeBillerDt = parseInt((x.getTime() / 1000).toFixed());
      let nowDt = parseInt((currentDate.getTime() / 1000).toFixed());
      let payload = {
        isMessagingConfig,
        billerPIDList: billerPids,
        from: activeBillerDt,
        to: nowDt
      }
      if(!!industryName)
      {
        payload.industryName = industryName
      } 
      
      let activeBillers = [];
      try {
        activeBillers = await this.getBillerCSEPaymentToggle(payload);
        return activeBillers;
      } catch (err) {
        console.log("Payment Message :::: ", err);
      } finally {
        if (billerPids.length > 0 && !!res.length && activeBillers.length > 0) {
          currentDate.toUTCString();
          let payload = { payerId: pid };
          payload.activeBillerLastDateTime = currentDate;
          payload.uid = res[0].uid;
          const { dateTimeHistorys } = this.dm.dashboard.state;
          if (!!dateTimeHistorys && dateTimeHistorys.length > 0) {
            dateTimeHistorys[0].activeBillerLastDateTime = currentDate
            this.dm.dashboard.setState({
              dateTimeHistorys: dateTimeHistorys
            });
          }
          this.dm.dashboard.updateMsgDateHistory(payload);
        }
      }
    }
  }

  async linkBilltoBiller(input) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `
            mutation LinkBiller($input:linkBillerInput!) {
                linkBiller(input: $input){
                success
                error
                message
                }
            }`,
        {
          input: input,
        }
      ));
      return !!res ? res.data.linkBiller : null;
    } catch (error) {
      return error;
    }
  }

  async getIndustries() {
    try {
      const { industries } = this.state;
      if (!!industries && industries.length > 0)
        return industries;
      else {
        let result = await this.api.qry(
          `query Industrys($filter: IndustryFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
            industrys(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            nodes { industryName }
          }
        }`
        );
        let industry = result.data.industrys.nodes;
        industry = industry.map((item) => ({ label: item.industryName, value: item.industryName }));
        industry = industry.sort((n1, n2) => {
          if (n1.label > n2.label) {
            return 1;
          }
          if (n1.label < n2.label) {
            return -1;
          }
          return 0;
        });
        
        this.setState({
          industries: industry,
        });
        return industry
      }
    } catch (error) {
      console.log(error)
      return [];
    }
  }
 
}
