import R14, { AsyncStorage } from "../core";
import { MESSAGE_METADATA } from "../constant/app.constant";
import UserAgentValidator from "../utils/UserAgentValidator";
import { CommonString } from "../constant/appCommonText";

export default class TextToPayDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state={
      showErrorPopup: false,
      isUserAlreadyLoggedIn: false
    }
  }

  async getMessageMetadata(messageMetaIdentifier) {
    const payerId = messageMetaIdentifier[0];
    const billerId = messageMetaIdentifier[1];
    const endToEndIdentification = messageMetaIdentifier[2];
    const buiId = messageMetaIdentifier[3];
    try {
      const isLoggedIn = await this.isUserLoggedIn(payerId);
      if (!!isLoggedIn) {
        const messageMetadata = await this.dm.rest.get(MESSAGE_METADATA, {EndToEndIdentification: endToEndIdentification, pid: payerId, buId: buiId, BillerPID: billerId, isValidPAM: true});
        if (messageMetadata && messageMetadata.messages.length > 0) {
          let data = messageMetadata.messages.find(
            (x) => x.envelope.threadHeader.originatorPid === billerId.trim()
              && x.envelope.threadHeader.respondentPid === payerId.trim()
              && x.envelope.messageBody.endToEndIdentification === endToEndIdentification);
          if (!!data) {
            const { threadId } = messageMetadata.messages[0].envelope.threadHeader;
            await this.dm.dashboard.setCompiledMsgMetadata(messageMetadata, threadId);
            AsyncStorage.setItem("homeScreen", "dashboard");
            this.nav.to("billDetailsRoute", { threadId: threadId });

          } else {
            this.updateState(true, payerId)
          }
        } else {
          this.updateState(true, payerId)
        }
      } else {
        const textToPay = await AsyncStorage.getItem('textToPay')
        UserAgentValidator.redirectUrl(`login?ReturnUrl=t2p/${encodeURIComponent(textToPay)}`)
      }
    } catch (error) {
      console.log("UpdateUserDetails error ", error);
      this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      return error;
    }
  }

  async decryptTextToPayMetadata(URL) {
    const encryptedMetaUrl = URL.split('t2p/')[1]
    let textToPayMetaUrl = await AsyncStorage.getItem("textToPay");
    if (textToPayMetaUrl !== URL) {
        await AsyncStorage.setItem('textToPay', encryptedMetaUrl)
    }
    const getSignedData = await this.dm.userLoginDomain.decodeCryptoRfc(encryptedMetaUrl, true);
    return getSignedData.split('/')          
  }

  async isUserLoggedIn() {
    try {
      const getStoredData = await this.dm.rest.getUser();
      return !!getStoredData && !!getStoredData.pid; // === payerId
    } catch (error) {
      return false;
    }
  }

  async updateState(showError, payerId) {
    const isLoggedIn = await this.isUserLoggedIn(payerId)
    this.setState({
      showErrorPopup: showError,
      isUserAlreadyLoggedIn: isLoggedIn
    })
  }
}
