import { LOGIN_TYPE } from "../constant/app.data.constant";
import { CommonString } from "../constant/appCommonText";
import R14, { AsyncStorage } from "../core";
import CommonUtility from "../utils/CommonUtility";
import RSAencryption from "../utils/RSAencryption";

export default class UserRegistrationDomain extends R14.Domain {

  async updateUserDetails(
    userDetails,
    profileUpdate = true,
    fields = "uid pid payerId sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType email mobileNumber EIN parentUid isActive"
  ) {
    await CommonUtility.htmlDecode(userDetails);
    try {
      let res = await this.api.mutate(
        `mutation UpdateUserDetails($input: UpdateUserLoginDetailInput){
          updateUserLoginDetail(input: $input){
            userLoginDetail {
                ${fields}
              }              
            }
          }`,
        { input: userDetails }
      );
      let details = res.data.updateUserLoginDetail.userLoginDetail
      // update user details on update into local storage
      if (profileUpdate) {
        let userLoginDetail = await this.dm.rest.getUser();
        details.userType = userLoginDetail.userType;
        this.dm.userSession.setState({
          userLoginDetail: details,
        });    
        await this.dm.userSession.updateSMBOrganization(details);
      }
      return details;
    } catch (error) {
      return error;
    }
  }


  async updateUserCustomDetails(userDetails, profileUpdate = true, fields = "uid") {
    await CommonUtility.htmlDecode(userDetails);
    try {
      this.api.mutate(
        `mutation UpdateUserDetails($input: UpdateUserLoginDetailInput){
          updateUserLoginDetail(input: $input){
            userLoginDetail {
                ${fields}
              }              
            }
          }`,
        { input: userDetails }
      );
      if (profileUpdate) {
        let userLoginDetail = await this.dm.rest.getUser();
        userLoginDetail = { ...userLoginDetail, ...userDetails };
        await this.dm.userSession.updateSMBOrganization(userLoginDetail);
        return userLoginDetail;
      }
      return userDetails;
    } catch (error) {
      return error;
    }
  }

  async UpdatePIDDetails(input, profileUpdate = true, fields = "uid") {
    await CommonUtility.htmlDecode(input);
    let pid = input.pid;
    delete input.pid;
    try {
      this.dm.queryDomain.middleware(this.api.mutate(
        `mutation UpdatePIDDetails($input: UpdateUserBUDetailInput!, $pid: String!){
          updatePIDDetails(input: $input, pid: $pid){
            userBUDetail {
                ${fields}
              }              
            }
          }`,
        { input: input, pid: pid}
      ));
      if (profileUpdate) {
        let userBUDetails = await this.dm.rest.getuserBUDetails();
        let ind = userBUDetails.findIndex((el) => el.uid === input.uid);
        userBUDetails[ind] = {...userBUDetails[ind], ...input};
        // check if bu details length is greater than 1 & buId === ""
        if(userBUDetails.length >= 2){
          userBUDetails = userBUDetails.filter(bu => {
            if(!bu.buId || bu.buId === "0" || !bu.billerRef){
            // call delete api
            this.api.mutate(
              `mutation DeleteUserBUDetail($uid: ID!){
                deleteUserBUDetail(uid: $uid){
                  success             
                }
              }`,
              { uid: bu.uid }
            );
            return false
            } return true
          });
        }
        await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_BUDETAILS, RSAencryption.EnctyptString(JSON.stringify(userBUDetails)));
        this.dm.userSession.setState({userBUDetails: !!userBUDetails && userBUDetails.length ? userBUDetails : []});
        return userBUDetails[ind];
      }
      return input;
    } catch (error) {
      return error;
    }
  }
  
  async linkSocialProfile(userDetails, profileUpdate = true) {
    await CommonUtility.htmlDecode(userDetails);
    let response = userDetails
    try {
      let res = await this.api.mutate(
        `mutation LinkIdentityProvider($input: IdentityProviderPayload!){
          linkIdentityProvider(input: $input) {
              success message
            }
          }`,
        { input: userDetails }
      );
      response = res.data.linkIdentityProvider;
      if (profileUpdate && !!response && !!response.success) {
        let userLoginDetail = await this.dm.rest.getUser();
        if (userDetails.identityProvider === LOGIN_TYPE.GOOGLE)
          userLoginDetail.googleProfileID = userDetails.socialId
        else if (userDetails.identityProvider === LOGIN_TYPE.APPLE)
          userLoginDetail.appleProfileID = userDetails.socialId
        await this.dm.userSession.updateSMBOrganization(userLoginDetail);
      }
      return response;
    } catch (error) {
      return error;
    }
  }

  async updateProfileImage(uid) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(
        `mutation UpdateProfileImage($uid: String!){
          updateProfileImage(uid: $uid) 
          }`,
        { uid: uid }
      ));
      let result = res.data.updateProfileImage
      return result;
    } catch (error) {
      return error;
    }
  }


  async signup(data, fields = "uid pid payerId kcId lastLoginDate sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType email mobileNumber subRole {appName appRole} isActive EIN parentUid parentUserDetail { uid email firstName lastName} ") {
    try {
      await CommonUtility.htmlDecode(data);
      data.email = data.email.toLowerCase();
      let res = await this.api.mutate(
        `mutation Signup($input: SignupInput!) {
        signup(input: $input){
          access_token refresh_token error success message 
          linkedPayers { isPrimaryPID pid buId billerId} 
          userBUDetails { uid firstName lastName userDetailUid payerRef buId buName billerId billerRef billerName country addressLine1 addressLine2 state town postCode countryObject{label value} stateObject{label value} cityObject{label value} }
          userLoginDetail {
             ${fields}
            }
          }
        }`,
        {
          input: data,
        });
      res.data.signup.userBUDetails = res.data.signup.userBUDetails || [{firstName: data.firstName, lastName: data.lastName }];
      return res.data.signup;
    } catch (error) {
      return error;
    }
  }

  async bUserExistsByEmail(input, filterBy = "Email", fields = "payerId firstName lastName email mobileNumber addressLine1 addressLine2 town state country postCode EIN pid updatedAt sessionString password changePasswordNow tokenKey userType googleProfileID appleProfileID amazonProfileID uid") {
    try {
      let options = {};
      if (filterBy === "Phone") {
        options = {
          filter: {
            mobileNumber: { eq: input.mobileNumber }
          },
        };
      } else if (filterBy === "Email") {
        options = {
          filter: {
            email: { eq: input.email.toLowerCase() },
          },
        };
      }

      let res = await this.api.qry(
        `query FindUserLoginDetail($filter: UserLoginDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          userLoginDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            nodes {
             ${fields}  
            }
          }
        }`,
        options
      );

      return res.data.userLoginDetails ? res.data.userLoginDetails.nodes : null;
    } catch (error) {
      return error;
    }
  }
}
