import React from "react";
import R14, { View, Text, Image, StyleSheet, Button } from "../../core";
import { APPLE_AUTHKEY } from "../../constant/app.constant";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import AppleSignin from "react-apple-signin-auth";
import RSAencryption from "../../utils/RSAencryption";
import {LOGIN_TYPE, USER_TYPE } from "../../constant/app.data.constant";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import ModalPopUp from "../../components/ModalPopUp";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";

const APPLE_REDIRECT_URI = window.location.origin + "/login";

const appleIcon = require("../../assets/images/appleIcon48.png");
const applelogoblack = require("../../assets/images/Apple_logo_black.png");

export default R14.connect(
  class AppleLogin extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        buttonType: this.props.buttonType,
        buttonClickable: true,
        currentRoute: "",
        isVisible: false,
        isClickSocialbutton:true
      };
    }

    componentDidMount() {
      const { socialLoginType, socialLoginProfile } = this.props.app.dm.userLoginDomain.state;
      if (socialLoginType === LOGIN_TYPE.APPLE && !!socialLoginProfile) {
        this.setState({
          buttonClickable: false
        });
      }
      this.setState({
        currentRoute: this.props.r14.navigation.activeRouteName,
      });
    }

    // SECTION: Route Registration.
    routeToRegistration = (val) => {
      this.setState({ buttonClickable: true, isClickSocialbutton: true });
      let returnUrl = this.props.app.route._location.search;
      if (this.props.userType === USER_TYPE.XBP) {
        this.props.app.nav.to("registration", { type: `${val.toLowerCase()}${!!returnUrl ? returnUrl : ""}` });
      } else {
        this.props.app.nav.to("pchSignup", { type: `${val.toLowerCase()}${!!returnUrl ? returnUrl : ""}` });
      }
    };

    onSuccess = async (res) => {
      this.setState({ buttonClickable: false, isClickSocialbutton: false });
      this.props.app.dm.userLoginDomain.setState({
        socialLoginProfile: res,
        socialLoginType: LOGIN_TYPE.APPLE,
      });

      const { authorization, user = "" } = res;
      const payload = RSAencryption.AppleTokenDecode(authorization.id_token);
      if (this.props.userType === USER_TYPE.XBP) {
        if (this.state.buttonType === "Profile") {
          const { kcId, pid, email } = this.props.profile;
          let apppleEmail = user !== "" ? user.email : payload.email;
          let reqBody = {
            kcId: kcId,
            pid: pid,
            email: email,
            identityProvider: LOGIN_TYPE.APPLE,
            socialId: payload.sub,
            socialUserName: apppleEmail.toLowerCase(),
          };
          // Check same email in user detail
          let emailExist = this.props.profile;
          if (!!emailExist && emailExist.email === apppleEmail.toLowerCase().trim()) {
            let res = await this.props.app.dm.userRegistration.linkSocialProfile(reqBody, true);
            if (!!res && !!res.success) {
              this.props.app.dm.commonDomain.showToastr("success", CommonString.AppleSync);
              this.setState({ buttonClickable: true, isClickSocialbutton: true });
              this.setState({ buttonType: "ProfileSynced" });
              return false;
            }
            else
            {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
              return false;
            }
          } else {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.EmailNotMatch);
              this.setState({ buttonClickable: true, isClickSocialbutton: true });
              return false;
          }
        } else {
          const { authorization, user = "" } = res;
          const payload = RSAencryption.AppleTokenDecode(authorization.id_token);
          if (!!payload) {
            try {
              if (!!payload.email) {
              } else if (!!!payload.email) {
                this.setState({ isVisible: true });
                this.setState({ buttonClickable: true, isClickSocialbutton: true });
                return false;
              }

              let apppleEmail = user !== "" ? user.email : payload.email;
              let response = await this.props.app.dm.userSession.loginAuth({
                socialProfileId: payload.sub,
                username: !!apppleEmail ? apppleEmail.toLowerCase().trim() : apppleEmail,
                loginType: LOGIN_TYPE.APPLE,
                signInLocation: this.props.signInLocation,
              });

              if (!!response) {
                const userLoginDetail = await this.props.app.dm.rest.getUser();
                if (!!userLoginDetail) {
                  if (!!!userLoginDetail.userType) {
                    this.routeToRegistration("existingapple");
                    return false;
                  } else {
                    this.props.app.dm.commonDomain.showToastr("success", CommonString.LoggedIn);
                    this.setState({ buttonClickable: true, isClickSocialbutton: true });
                    this.props.app.dm.userLoginDomain.setState({
                      socialLoginProfile: null,
                      socialLoginType: "",
                    });
                    await this.props.redirectToReturnUrl();
                    return false;
                  }
                } else this.routeToRegistration("SocialLogin");
              } else this.routeToRegistration("SocialLogin");
            } catch (err) {
              this.routeToRegistration("SocialLogin");
            }
          } else 
          {
            this.routeToRegistration("SocialLogin");
        }
        }
      } else {
        if (!!payload) {
          try {

            let apppleEmail = user !== "" ? user.email : payload.email;
            let response = await this.props.app.dm.userSession.loginAuth({
              socialProfileId: payload.sub,
              username: apppleEmail.toLowerCase().trim(),
              loginType: LOGIN_TYPE.APPLE,
              signInLocation: this.props.signInLocation,
            });

            if (!!response) {
              const userLoginDetail = await this.props.app.dm.rest.getUser();
              if (!!userLoginDetail) {
                if (!!!userLoginDetail.userType) {
                  this.routeToRegistration("existingapple");
                  return false;
                } else {
                  this.props.app.dm.commonDomain.showToastr("success", CommonString.LoggedIn);
                  this.setState({ buttonClickable: true, isClickSocialbutton: true });
                  this.props.app.dm.userLoginDomain.setState({
                    socialLoginProfile: null,
                    socialLoginType: "",
                  });
                  await this.props.redirectToReturnUrl();
                  return false;
                }
              } else this.routeToRegistration("SocialLogin");
            } else this.routeToRegistration("SocialLogin");
          } catch (err) {
            this.routeToRegistration("SocialLogin");
          }
        } else this.routeToRegistration("SocialLogin");
      }
    };

    onFailure = (res) => {
      console.log("Login failed:", res);
      this.setState({ buttonClickable: true, isClickSocialbutton: true });
    };

    onClose = async () => {
      this.setState({ isVisible: false });
    };

    render() {
      return (
        <View>
          {this.props.userType === USER_TYPE.XBP ? (
            <View style={[commonStyles.flex1, commonStyles.alignItemsCenter]}>
              <AppleSignin
                authOptions={{
                  clientId: APPLE_AUTHKEY,
                  scope: "email name",
                  redirectURI: APPLE_REDIRECT_URI,
                  state: "state",
                  nonce: "nonce",
                  usePopup: true,
                }}
                uiType="dark"
                className="apple-auth-btn"
                noDefaultStyle={false}
                buttonExtraChildren=""
                onSuccess={(res) => this.onSuccess(res)}
                onError={(error) => this.onFailure(error)}
                skipScript={false}
                iconprop={{ style: { marginTop: 10 } }}
                render={(props) =>
                  this.state.buttonType === "Login" ? (
                    <button
                      {...props}
                      style={
                        this.state.buttonClickable
                          ? {
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              display: "flex",
                              width: "100%",
                              border: "1px solid rgb(218, 218, 218)",
                              minHeight: 48,
                              alignItems: "center",
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5,
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              cursor: "pointer",
                              // paddingLeft: 20,
                              justifyContent: "center",
                            }
                          : {
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              display: "flex",
                              width: "100%",
                              border: "1px solid rgb(218, 218, 218)",
                              minHeight: 48,
                              alignItems: "center",
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5,
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              cursor: "pointer",
                              pointerEvents: "none",
                              opacity: 0.5,
                              justifyContent: "center",
                            }
                      }>
                      <Image source={appleIcon} style={[commonStyles.icon20, commonStyles.marginRight16]} />
                      <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.whiteColor, commonStyles.socialLoginText]}>
                        {!!this.props.label ? this.props.label : "Continue with Apple"}
                      </Text>
                    </button>
                  ) : this.state.buttonType === "Profile" ? (
                    <button
                      {...props}
                      style={
                        this.state.buttonClickable && this.state.currentRoute !== "profileEditRoute"
                          ? { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer" }
                          : this.state.currentRoute === "profileEditRoute"
                          ? { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer", pointerEvents: "none" }
                          : {
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              padding: 0,
                              border: 0,
                              cursor: "pointer",
                              pointerEvents: "none",
                              opacity: 0.5,
                            }
                      }>
                      <Image source={appleIcon} style={commonStyles.icon20} />
                    </button>
                  ) : (
                    <button
                      {...props}
                      style={{
                        backgroundColor: "rgba(255,255,255, 0.0)",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#dadada",
                        cursor: "pointer",
                        paddingTop: 4,
                        paddingRight: 8,
                        paddingBottom: 4,
                        paddingLeft: 8,
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                        borderBottomLeftRadius: 50,
                        borderBottomRightRadius: 50,
                        display: "flex",
                        alignItems: "center",
                        pointerEvents: "none",
                      }}>
                      <Image source={appleIcon} style={[commonStyles.icon20, commonStyles.marginRight4]} />
                      <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.darkGray]}>Synced</Text>
                    </button>
                  )
                }
              />
            </View>
          ) : (
            <>
              <AppleSignin
                authOptions={{
                  clientId: APPLE_AUTHKEY,
                  scope: "email name",
                  redirectURI: APPLE_REDIRECT_URI,
                  state: "state",
                  nonce: "nonce",
                  usePopup: true,
                }}
                uiType="dark"
                className="apple-auth-btn"
                noDefaultStyle={false}
                buttonExtraChildren=""
                onSuccess={(res) => this.onSuccess(res)}
                onError={(error) => this.onFailure(error)}
                skipScript={false}
                iconprop={{ style: { marginTop: 10 } }}
                render={(props) =>
                  this.state.buttonType === "Login" ? (
                    // <button {...props}
                    // style={ this.state.buttonClickable ?  { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer"  } :  { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer", pointerEvents: "none", opacity: 0.5 }}>
                    //   <Image source={appleIcon} style={commonStyles.icon48} />
                    // </button>
                    <button
                      {...props}
                      style={
                        this.state.buttonClickable
                          ? {
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              display: "flex",
                              width: "100%",
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "#dadada",
                              minHeight: 48,
                              alignItems: "center",
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5,
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              justifyContent: "center",
                              cursor: "pointer",
                              // paddingLeft: 20,
                            }
                          : {
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              display: "flex",
                              width: "100%",
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "#dadada",
                              minHeight: 48,
                              alignItems: "center",
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5,
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              justifyContent: "center",
                              pointerEvents: "none",
                              opacity: 0.5,
                              // paddingLeft: 20,
                            }
                      }>
                      <Image resizeMode="contain" style={[commonStyles.icon20, commonStyles.marginRight16]} source={applelogoblack} />
                      <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.whiteColor, commonStyles.socialLoginText]}>
                        {!!this.props.label ? this.props.label : "Continue with Apple"}
                      </Text>
                    </button>
                  ) : this.state.buttonType === "Profile" ? (
                    <button
                      {...props}
                      style={
                        this.state.buttonClickable && this.state.currentRoute !== "profileEditRoute"
                          ? { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer" }
                          : this.state.currentRoute === "profileEditRoute"
                          ? { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer", pointerEvents: "none" }
                          : {
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              padding: 0,
                              border: 0,
                              cursor: "pointer",
                              pointerEvents: "none",
                              opacity: 0.5,
                            }
                      }>
                      <Image source={appleIcon} style={commonStyles.icon20} />
                    </button>
                  ) : (
                    <button
                      {...props}
                      style={{
                        backgroundColor: "rgba(255,255,255, 0.0)",
                        padding: 0,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#dadada",
                        cursor: "pointer",
                        paddingTop: 4,
                        paddingRight: 8,
                        paddingBottom: 4,
                        paddingLeft: 8,
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                        borderBottomLeftRadius: 50,
                        borderBottomRightRadius: 50,
                        display: "flex",
                        alignItems: "center",
                        pointerEvents: "none",
                      }}>
                      <Image source={appleIcon} style={[commonStyles.icon20, commonStyles.marginRight4]} />
                      <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.darkGray]}>Synced</Text>
                    </button>
                  )
                }
              />
              <PCHLoaderComponent isVisiable={!this.state.buttonClickable && !this.state.isClickSocialbutton} />
              <ModalPopUp
                visibility={this.state.isVisible}
                isFooter={false}
                bodyWrapper={styles.bodyWrapper}
                modalOuterWrapper={styles.modalOuterWrapper}
                heading={" "}
                handleCancel={this.onClose}
                closeButton={styles.closeBtn}
                modalHeading={[]}
                modalContent={commonStyles.marginTop15}>
                <View style={commonStyles.textAlignCenter}>
                  <View style={[styles.sessionModelHeaderStyle]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.textAlignCenter, commonStyles.heading5, commonStyles.fontWeight500]}>
                      Apple Signup!
                    </Text>
                  </View>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock]}>Please share email id to </Text>
                  <Text style={[commonStyles.marginBottom15, commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock]}>
                    signup {this.state.displayAccountNumber}
                  </Text>
                </View>
                <View style={[styles.payNowWrapper]}>
                  <Button
                    title={"Ok"}
                    style={[commonStyles.blueButton, styles.buttons, customCommonStyles.noBoxShadow,]}
                    onPress={this.onClose}
                    titleTextStyle={[
                      styles.btnTexClr,
                      commonStyles.fontWeight700,
                      commonFontStyles.workSans,
                      commonStyles.heading6,
                      commonStyles.blueButtonColor,
                    ]}
                  />
                </View>
              </ModalPopUp>
            </>
          )}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  bankIcon: {
    marginRight: 10,
  },
  bankLogo: {
    width: 40,
    height: 40,
    display: "block",
    backgroundSize: "contain",
  },
  cardListHolder: {
    top: 50,
    paddingBottom: 60,
  },
  bottomButtons: {
    position: "relative",
    zIndex: 999,
  },
  buttons: {
    padding: 0,
    minWidth: "40%",
  },
  bodyWrapper: {
    width: 280,
  },
  modalOuterWrapper: {
    backgroundColor: "#00000059",
  },
  payNowWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  margin15: {
    margin: 15,
  },
  marginLeftTopRight: {
    marginLeft: 15,
    // marginTop:15,
    marginRight: 15,
  },
  sessionModelHeaderStyle: {
    marginTop: -45,
    marginRight: 0,
    marginBottom: 15,
    marginLeft: 0,
  },
  closeBtn: {
    position: "relative",
    zIndex: 999,
  },
  socialLoginText: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontSize: 12,
        };
      }
    },
  },
});
