import React from "react";
import R14, {
  TextInputField,
  Touchable,
  StyleSheet,
  Button,
  Form,
  Image,
  Text,
  View,
  SubmitButton,
  FlatList,
  TextInput,
  Platform,
} from "../core";
import NoRecordsFound from "../components/NoRecordsFound";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import BottomPaySection from "../components/BottomPaySection";
import CommonValidators from "../utils/CommonValidators";
import { PAGE_PATH, MSG_TYPE, BILL_STATUS, CURRENCY, PAYMENT_MODE } from "../constant/app.data.constant";
import BillerDetails from "../components/BillerDetails";
import LightBlueRadioButtons from "../components/LightBlueRadioButtons";
import PaymentOptions from "../components/payments/PaymentOptions";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import CommonUtility from "../utils/CommonUtility";
import getIpAddress from "../utils/GetIPAddress";
import ModalPopUp from "../components/ModalPopUp";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";
import ChatboxInputField from "../components/ChatboxInputField";

export default R14.connect(
  class PaymentScreen extends React.Component {
    constructor(props) {
      super(props);
      this.form = null;
      this.state = {
        isSuccess: false,
        isLoading: false,
        cmnts: null,
        amt: null,
        amtPayable: parseFloat(props.billData.amountDue),
        addCardVisible: false,
        cardValue: "",
        isDebit: true,
        cvvNumber: null,
        debitCardList: [],
        creditCardList: [],
        billData: this.props.billData,
        isCardSelected: true,
        defaultSelectedItem: [],
        IsAllowOtherPay: false,
        errorMessage: ''
      };
      this.cardToken = null;
      this.initialFormData = {
        currencyCode: { label: "USD", value: "1" },
      };
      this.cardIconPath = null;
      // Create the child instance using react createRef
      this.child = React.createRef();
    }

    componentDidMount = async () => {
      let { payType } = this.props;
      if (payType === PAGE_PATH.PAYPART) {
        this.setState({
          isLoading: true,
        });
      }

      this.form = this.props.app.ui.form("payOpt");
      let debitCardList = [];
      let creditCardList = [];
      let extOpt = { cvvNumber: "", isChecked: "" };
      if (this.props.cardDisplayList) {
        this.props.cardDisplayList.forEach((item) => {
          if (item.paymentType === "Debit Card") {
            debitCardList.push({ ...item, ...extOpt });
          } else {
            creditCardList.push({ ...item, ...extOpt });
          }
        });
        this.setState({ debitCardList: debitCardList, creditCardList: creditCardList });
      }
      let defaultSelectedItem = this.props.cardDisplayList.filter((el) => el.isSetDefault === true);
      if (!!defaultSelectedItem.length > 0) {
        this.setState({ defaultSelectedItem: defaultSelectedItem });
        this.cardToken = defaultSelectedItem[0].token ? defaultSelectedItem[0].token : null;
      } else {
        this.cardToken = null;
      }
    };

    navigateToBack = async () => {
      analyticsLogs(this.props.payType === PAGE_PATH.PAYPART ? CommonString.PART_PAY_BILL : CommonString.FULL_PAY_BILL, `${CommonString.CLICKED_ON_CANCEL_BUTTON}`, this.props.app);
      this.props.app.nav.to("makePaymentRoutes");
    };

    toggleAddCard = async () => {
      this.setState({
        addCardVisible: !this.state.addCardVisible,
      });
    };

    bCardIconExists(cardType) {
      let imgSrc = "";
      try {
        //imgSrc = require("../assets/images/cards/" + cardType.toLowerCase() + ".png");
      } catch {
        //imgSrc = require("../assets/images/cards/default.png");
      } finally {
        this.cardIconPath = imgSrc;
      }
    }

    onSelectDebit = (val) => {
      const { debitCardList, creditCardList, defaultSelectedItem } = this.state;
      if (!!defaultSelectedItem.length > 0 && defaultSelectedItem[0].paymentType === "Debit Card") {
        debitCardList.forEach(
          (ele) => {
            ele.cvvNumber = "";
            ele.isSetDefault = (ele.uid === defaultSelectedItem[0].uid) ? defaultSelectedItem[0].isSetDefault : false;
            ele.isChecked = (ele.uid === defaultSelectedItem[0].uid) ? "selectedCard" : ele.isChecked = "";
          }
        );
        this.cardToken = defaultSelectedItem[0].isSetDefault ? defaultSelectedItem[0].token : null;
      } else {
        debitCardList.forEach((ele) => {
          ele.cvvNumber = "";
          ele.isSetDefault = false;
          ele.isChecked = "";
        });
        this.cardToken = null;
      }
      this.setState({
        isDebit: true,
        debitCardList,
        creditCardList,
      });
    };
    onSelectCredit = (val) => {
      const { debitCardList, creditCardList, defaultSelectedItem } = this.state;
      if (!!defaultSelectedItem.length > 0 && defaultSelectedItem[0].paymentType === "Credit Card") {
        creditCardList.forEach(
          (ele) => {
            ele.cvvNumber = "";
            ele.isSetDefault = (ele.uid === defaultSelectedItem[0].uid) ? defaultSelectedItem[0].isSetDefault :  false;
            ele.isChecked = (ele.uid === defaultSelectedItem[0].uid) ? "selectedCard" : "";
          }
        );
        this.cardToken = defaultSelectedItem[0].isSetDefault ? defaultSelectedItem[0].token : null;
      } else {
        creditCardList.forEach((ele) => {
          ele.cvvNumber = "";
          ele.isSetDefault = false;
          ele.isChecked = "";
      });
        this.cardToken = null;
      }
      this.setState({
        isDebit: false,
        debitCardList,
        creditCardList,
      });
    };

    onRadioChange = (val, item, ptype, ind) => {
      const { debitCardList, creditCardList } = this.state;
      debitCardList.forEach((ele) => {
        ele.isChecked = "";
        ele.isSetDefault = false
      });
      creditCardList.forEach((ele) => {
        ele.isChecked = "";
        ele.isSetDefault = false;
        });
      if (ptype === "Debit Card") {
        debitCardList[ind].isChecked = "selectedCard";
        debitCardList[ind].isSetDefault = true;
      } else {
        creditCardList[ind].isChecked = "selectedCard";
        creditCardList[ind].isSetDefault = true;
      }
      this.setState({ debitCardList, creditCardList });
      this.cardToken = item.token;
      this.setState({ isCardSelected: false });
    };

    onSubmit = async (form) => {
      const { amtPayable } = this.state;
      const { billData, payType } = this.props;
      const { comments, amount } = form.values;

      let clonedBill = { ...billData }; /// Cloning object
      clonedBill.additionalInformation = comments;
      let lastPaidAmount = amount;

      this.setState({
        isLoading: true,
      });

      if (payType === PAGE_PATH.PAYPART) {
        if (lastPaidAmount > amtPayable) {
          this.form.elmts.amount.setError("Amount should not be greater than the Amount Payable.");
          this.setState({ isLoading: false });
          return false;
        }
        clonedBill.amount = lastPaidAmount;
      } else {
        lastPaidAmount = amtPayable;
        clonedBill.amount = amtPayable;
      }
      let transactionId;
      if (!this.cardToken) {
        this.setState({ isLoading: false });
        this.props.app.dm.commonDomain.showToastr("error", CommonString.CardTypeError);
        return false;
      }

      this.onPaymentDone(transactionId);
    };

    addPaymentHistory = async (billData, payType, transaction) => {
      const ipAddress = await getIpAddress();
      this.props.app.dm.achPaymentDomain.createBillPaymentHistory({
        billDetailId: billData.uid,
        paymentMode: transaction.paymentMode,
        paymentType: payType,
        curreny: billData.currency,
        paymentAmount: billData.amount,
        accountIntentId: transaction.achBankAccountId,
        transactionId: transaction.transactionId,
        status: "initiated",
        pId: billData.payerId,
        billerPid: billData.billerId,
        ipAddress: ipAddress,
        userAgentId: "",
        platform: Platform.OS,
        message: transaction.error,
        createdOn: new Date()
      });
    }

    onPaymentDone = async (transactionId) => {
      const { amtPayable } = this.state;
      const { billData, payType, msgList } = this.props;
      const { comments, amount } = this.form.values;
      let clonedBill = { ...billData }; /// Cloning object
      clonedBill.additionalInformation = (transactionId && transactionId.paymentMode ? `${transactionId.paymentMode}` : ``) + (!!comments ? `-${comments}` : "");
      let lastPaidAmount = amount;

      this.setState({
        isLoading: true,
      });

      if (payType === PAGE_PATH.PAYPART) {
        if (lastPaidAmount > amtPayable) {
          this.form.elmts.amount.setError("Amount should not be greater than the Amount Payable.");
          this.setState({ isLoading: false });
          return false;
        }
        clonedBill.amount = lastPaidAmount;
      } else {
        lastPaidAmount = amtPayable;
        clonedBill.amount = amtPayable;
      }

      if (!!transactionId.success) {
        this.addPaymentHistory(clonedBill, payType, transactionId);
        clonedBill.transactionId = transactionId.transactionId;
        let lastLeaf = {
          ...msgList[msgList.length - 1],
          billData: clonedBill,
          attachments: !!this.fileAttach ? [this.fileAttach] : null,
          messageType:
            payType === PAGE_PATH.PAYPART
              ? billData.billAmount === clonedBill.amountPaid + parseFloat(amount)
                ? MSG_TYPE.PAY_ALL
                : MSG_TYPE.PAY_PART
              : MSG_TYPE.PAY_ALL,
        };

        let paylod = await this.props.app.dm.billDetails.getPaymentPayload(lastLeaf, transactionId.paymentMode, false);

        try {
          let res = await this.props.app.dm.billDetails.postMessageForPayment(paylod);
          if (res && res.threadId) {
            analyticsLogs(this.props.payType === PAGE_PATH.PAYPART ? CommonString.PART_PAY_BILL : CommonString.FULL_PAY_BILL, `${CommonString.BILL_PAID_SUCCESSFULLY} by ${transactionId.paymentMode}`, this.props.app);
            let dbpayload = {};
            if (payType === PAGE_PATH.PAYFULL) {
              dbpayload = {
                amountPaid: clonedBill.billAmount,
                amountDue: 0.00,
                billStatus: "Paid",
                bookmark: false,
                uid: billData.uid,
                rtpMessageType: MSG_TYPE.PAY_ALL,
                paymentStatus: "In Process",
                // fullPaidAndDeclined: paylod.envelope.messageHeader.messageDateTime,
                isFailedPayment: false,
                failDate: null,
                lastPaidAmount: lastPaidAmount,
              };
            } else {
              let isOverDueDate = CommonUtility.compareDueDate(clonedBill.dueDate);
              dbpayload = {
                amountPaid: clonedBill.amountPaid + lastPaidAmount,
                amountDue: billData.billAmount - (clonedBill.amountPaid + lastPaidAmount),
                billStatus:
                  billData.billAmount === clonedBill.amountPaid + parseFloat(amount)
                    ? "Paid"
                    : !!isOverDueDate
                      ? "Overdue"
                      : "Outstanding",
                uid: billData.uid,
                isFailedPayment: false,
                failDate: null,
                lastPaidAmount: lastPaidAmount,
                paymentStatus: "In Process",
              };
              dbpayload.amountDue = dbpayload.billStatus === "Paid" ? 0.00 : dbpayload.amountDue;
              dbpayload.amountPaid = dbpayload.billStatus === "Paid" ? clonedBill.billAmount : dbpayload.amountPaid;
              dbpayload.bookmark = dbpayload.billStatus === "Paid" ? false : clonedBill.bookmark;
              dbpayload.rtpMessageType = dbpayload.billStatus === "Paid" ? MSG_TYPE.PAY_ALL : MSG_TYPE.PAY_PART;
            }

            if (transactionId.paymentMode === PAYMENT_MODE.ACH_PAY) {
              dbpayload.achPaymentStatus = 'PaymentInitiated';
              dbpayload.achPaymentIntentId = !!transactionId.achPaymentIntentId ? transactionId.achPaymentIntentId : null;
              dbpayload.achBankAccountId = transactionId.achBankAccountId;
              dbpayload.achBankAccountNumber = transactionId.achBankAccountNumber;
              dbpayload.paymentType = dbpayload.rtpMessageType;
              dbpayload.additionalInformation = !!clonedBill.additionalInformation ? clonedBill.additionalInformation : null;
            }

            dbpayload.transactionId = transactionId.transactionId;
            dbpayload.paymentMode = transactionId.paymentMode;
            dbpayload.messageId = res.messageId;
            await this.props.app.dm.dashboard.updateBill({ ...dbpayload });
            const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;
            if (!!compiledMsgList.length && !!filterBills.length) {
              let ind = compiledMsgList.findIndex((el) => el.uid === clonedBill.uid);
              compiledMsgList[ind].billStatus = dbpayload.billStatus;
              compiledMsgList[ind].amountDue = dbpayload.amountDue;
              compiledMsgList[ind].amountPaid = dbpayload.amountPaid;
              compiledMsgList[ind].bookmark = dbpayload.bookmark;
              compiledMsgList[ind].lastPaidAmount = dbpayload.lastPaidAmount;
              compiledMsgList[ind].paymentMode = dbpayload.paymentMode;
              compiledMsgList[ind].paymentStatus = dbpayload.paymentStatus;
              compiledMsgList[ind].rtpMessageType = dbpayload.rtpMessageType;
              compiledMsgList[ind].isFailedPayment = dbpayload.isFailedPayment;
              compiledMsgList[ind].paymentType = dbpayload.paymentType;
              let fndx = filterBills.findIndex((el) => el.uid === clonedBill.uid);
              filterBills[fndx].billStatus = dbpayload.billStatus;
              filterBills[fndx].amountDue = dbpayload.amountDue;
              filterBills[fndx].amountPaid = dbpayload.amountPaid;
              filterBills[fndx].bookmark = dbpayload.bookmark;
              filterBills[fndx].lastPaidAmount = dbpayload.lastPaidAmount;
              filterBills[fndx].paymentMode = dbpayload.paymentMode;
              filterBills[fndx].paymentStatus = dbpayload.paymentStatus;
              filterBills[fndx].rtpMessageType = dbpayload.rtpMessageType;
              filterBills[fndx].isFailedPayment = dbpayload.isFailedPayment;
              filterBills[fndx].paymentType = dbpayload.paymentType;

              await this.props.app.dm.dashboard.updateBillCount(compiledMsgList, filterBills);
            }

            let newBill = {
              ...billData,
              ...dbpayload,
              paidAmount: parseFloat(billData.paidAmount) + parseFloat(amount || amtPayable),
            };
            this.props.app.dm.billDetails.setState({
              billDetails: newBill
            });
            setTimeout(() => {
              let threadId = billData.threadId; //.replaceAll("#", ">>");
              this.props.app.nav.to("billReceiptRoute", { threadId: threadId });
            }, 100);
          } else {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.MessageFail);
          }
        } catch (err) {
          this.setState({ isLoading: false });
          console.log("Error: ", err);
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        } finally {
          this.setState({ isLoading: false });
          return false;
        }
      } else {
        clonedBill.transactionId = transactionId.transactionId;
        {
          let dbpayload = {
            billStatus: "Failed",
            paymentStatus: "Failed",
            uid: billData.uid,
            isFailedPayment: true,
            failDate: new Date().toUTCString(),
            lastPaidAmount: lastPaidAmount,
            failReason: transactionId.error ? transactionId.error : "Transaction Failed.",
          };
          await this.props.app.dm.dashboard.updateBill({ ...dbpayload });
          const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;
          if (!!compiledMsgList.length && !!filterBills.length) {
            let ind = compiledMsgList.findIndex((el) => el.uid === clonedBill.uid);
            compiledMsgList[ind].billStatus = dbpayload.billStatus;
            compiledMsgList[ind].failReason = dbpayload.failReason;
            compiledMsgList[ind].paymentStatus = dbpayload.paymentStatus;
            let fndx = filterBills.findIndex((el) => el.uid === clonedBill.uid);
            filterBills[fndx].billStatus = dbpayload.billStatus;
            filterBills[fndx].failReason = dbpayload.failReason;
            filterBills[fndx].paymentStatus = dbpayload.paymentStatus;

            await this.props.app.dm.dashboard.updateBillCount(compiledMsgList, filterBills);
          }

          let newBill = {
            ...billData,
            billStatus: dbpayload.billStatus,
            paymentStatus: dbpayload.paymentStatus,
            isFailedPayment: dbpayload.isFailedPayment,
            failDate: dbpayload.failDate,
            failReason: dbpayload.failReason,
          };
          this.props.app.dm.billDetails.setState({
            billDetails: newBill
          });
          this.setState({ billData: newBill, isLoading: false });

          if (!!!transactionId.isCancelByUser) {
            this.props.app.dm.billDetails.setState({
              isShowErrorMsg: true
            })
          }
          else
            this.setState({
              errorMessage: `${transactionId.error ? transactionId.error : "Transaction Failed."}`
            });
        }
      }
    };

    gotoAddNewCard = async () => {
      this.props.app.nav.to("addNewCardRoute");
    };

    onTextChange = (val, ind, ptype) => {
      // val.substring(0, 3);
      let cvvValue = val;
      if (!!val) {
        cvvValue = cvvValue.replace(/[^\d]/g, "").substring(0, 3);
        if (ptype === "Debit Card") {
          const { debitCardList } = this.state;
          debitCardList[ind].cvvNumber = cvvValue;
          this.setState({ debitCardList });
        } else {
          const { creditCardList } = this.state;
          creditCardList[ind].cvvNumber = cvvValue;
          this.setState({ creditCardList });
        }
      } else if (val === "") {
        if (ptype === "Debit Card") {
          const { debitCardList } = this.state;
          debitCardList[ind].cvvNumber = cvvValue;
          this.setState({ debitCardList });
        } else {
          const { creditCardList } = this.state;
          creditCardList[ind].cvvNumber = cvvValue;
          this.setState({ creditCardList });
        }
      }
    };

    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 125) {
        this.form.elmts.comments.setError(null);
      } else if (val.length > 125) {
        this.form.elmts.comments.setValue(val.substring(0, 125));
        this.form.elmts.comments.setError("Character Limit Exceeds 125 Characters");
      }
    };
    onAttachment = async (val) => {
      if (val) analyticsLogs(this.props.payType === PAGE_PATH.PAYPART ? CommonString.PART_PAY_BILL : CommonString.FULL_PAY_BILL, `${CommonString.ATTACHMENT_ADDED}`, this.props.app);
      else analyticsLogs(this.props.payType === PAGE_PATH.PAYPART ? CommonString.PART_PAY_BILL : CommonString.FULL_PAY_BILL, `${CommonString.ATTACHMENT_REMOVED}`, this.props.app);
      this.fileAttach = val;
    };

    amountText = async (val) => {
      let amtText = val;
      if (!!val) {
        amtText = amtText.replace(/[^\d.]/g, "").substring(0, 10);
        amtText = amtText.indexOf('.') > -1 && amtText.split('.')[1].length > 2 > 0 ? parseFloat(amtText).toFixed(2) : amtText;
        this.form.elmts.amount.setValue(amtText);
      }
      let isValid = /^\+?(\d*[1-9]\d*\.?|\d*\.\d*[0-9]\d*)$/gi.test(amtText);

      if (isValid && (amtText < 0.5 || amtText.length > 9)) {
        isValid = false;
      }
      const { amtPayable } = this.state;
      const { payType } = this.props;

      this.setState({ isLoading: true });
      if (isValid) {
        this.form.elmts.amount.setValue(amtText);
        this.form.elmts.amount.setError(null);
        if (payType === PAGE_PATH.PAYPART) {
          if (parseFloat(amtText) === parseFloat("0.00")) {
            this.form.elmts.amount.setError(CommonString.InvalidAmount);
            return false;
          }
          else if (parseFloat(amtText) > parseFloat(amtPayable)) {
            this.form.elmts.amount.setError(CommonString.AmountPayableExceedError);
            return false;
          } else {
            if (!!this.child && !!this.child.current && !!this.child.current.wrappedInstance)
              await this.child.current.wrappedInstance.updateOnPartPayment(payType, amtText, amtPayable);
            this.setState({
              isLoading: false,
            });
          }
        }
      } else if (amtText === "") {
        this.form.elmts.amount.setError(null);
      } else {
        this.form.elmts.amount.setError("Enter valid amount");
      }
    }

    toggelBookmark = async (bkmrk) => {
      analyticsLogs(this.props.payType === PAGE_PATH.PAYPART ? CommonString.PART_PAY_BILL : CommonString.FULL_PAY_BILL, !!bkmrk ? CommonString.Prioritize_Bill : CommonString.Removed_Priority, this.props.app);
      const { billData } = this.props;
      const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;
      if (!!compiledMsgList.length && !!filterBills.length) {
        let ind = compiledMsgList.findIndex((el) => el.uid === billData.uid);
        compiledMsgList[ind].bookmark = bkmrk;
        this.setState({ billData: { ...billData, ...compiledMsgList[ind] } });
        let findx = filterBills.findIndex((el) => el.uid === billData.uid);
        filterBills[findx].bookmark = bkmrk;
        await this.props.app.dm.dashboard.setState({ compiledMsgList, filterBills });
      }
    };

    handleErrorMessage = () => {
      this.setState({
        errorMessage: ''
      })
    }

    errorMessageView = () => {
      return (
        <View style={commonStyles.textAlignCenter}> 
          <View style={[commonStyles.marginTop15]}>
            <View style={[commonStyles.alignItemsCenter]}>
              <View style={[customCommonStyles.newBankAddedMsg]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.textAlignCenter]}>
                  {this.state.errorMessage}
                </Text>
              </View>
              <View style={[styles.okayContainter, commonStyles.marginTop15, styles.okBtn]}>
                <Touchable style={[styles.alignSelfCenter]} onPress={() => this.handleErrorMessage('')}>
                  <View style={[styles.activeButton, commonStyles.blueBorderButtonColor]}>
                    <Text style={[
                      commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading, styles.btnText, commonStyles.whiteColor
                    ]}>OK</Text>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        </View>
      )
    }

    render() {
      const { payType, isAllowPayment } = this.props;
      const { billData } = this.state;
      const { isDebit, isLoading, amtPayable, debitCardList, creditCardList } = this.state;
      let cardD = !!isDebit ? debitCardList : creditCardList;
      return (
        <View style={[styles.container, styles.position]}>
          <ModalPopUp
            visibility={!!this.state.errorMessage}
            isFooter={true}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.customHeight]}
            contentInner={[styles.contentInner]}
          >{this.errorMessageView()}</ModalPopUp>
          <Form
            name="payOpt"
            style={[commonStyles.paddingLeft0, commonStyles.paddingRight0, commonStyles.paddingTop0, commonStyles.flexGrow0]}
            backgroundColor="#fff"
            onSubmit={this.onSubmit}
            validateBeforeSubmit={true}
            initialValues={this.initialFormData}
            validators={CommonValidators.getFormValidator()}>
            <BillerDetails customStyles={commonStyles.padding0} billData={billData} payType={payType} onBkmkToggle={this.toggelBookmark} customFlexDirection={styles.customFlexDirection} />
            <View
              style={[
                commonStyles.flex1,
                commonStyles.justifyContentSpaceBetween,
                commonStyles.alignItemsCenter,
                styles.customPaddingTopBottom5,
                commonStyles.borderTopBottom,
                commonStyles.flexDirectionRow
              ]}>
              <View>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight700]}>Amount Payable</Text>
              </View>
              <View style={[commonStyles.minWidth110]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight700, commonStyles.amountPayable]}>
                  {CURRENCY[billData.currency]}{amtPayable.toFixed(2)}
                </Text>
              </View>
            </View>

            <View style={[styles.bottomSectionOuter]}>
              <View style={styles.bottomPaySection}>
                <BottomPaySection
                  threadId={billData.threadId}
                  activePage={payType}
                  isDisabled={BILL_STATUS[billData.billStatus]}
                  extraOpt={billData.rtpMessageType}
                  billerDetails={this.props.billData.biller}
                  billDetails={this.props.billData}
                />
              </View>
            </View>

            <View style={[commonStyles.marginBottom30, commonStyles.marginTop5]}>
              {payType === PAGE_PATH.PAYPART ? (
                <View style={[commonStyles.marginBottom30]}>
                  <TextInputField
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    name="amount"
                    label={CommonString.EnterAmountLabel + "*"}
                    required={CommonString.EnterAmountRequired}
                    validator="max10WithDecimal"
                    onChangeText={async (val) => await this.amountText(val)}
                    valueParser={(val) => parseFloat(val)}
                    style={[commonStyles.marB0, customCommonStyles.inputHeight, commonFontStyles.workSans]}
                    helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  />
                </View>
              ) : null} 
              <ChatboxInputField
                onValueChange={this.onAttachment}
                onChangeText={this.readText}
                commentsProps={{
                  name: "comments",
                  required: "Enter the comments",
                  label: "Comments",
                }}
                multiline={true}
                validator="cmtsVali"
                textBoxStyle={[commonStyles.smallTextBoxStyle]}
                attachmentBoxStyle={[commonStyles.smallAttachmentBoxStyle]}
                sendBtnBoxStyle={[commonStyles.smallSendBtnBoxStyle]}
                />
              {this.state.addCardVisible ? (
                <View>
                  <TextInputField
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.resizeNone]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    name="cardHolderName"
                    label="Card Holder Name"
                    style={[commonStyles.resizeNone, customCommonStyles.inputHeight, commonFontStyles.workSans]}
                    helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  />
                  <TextInputField
                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.resizeNone]}
                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                    name="cardNumber"
                    label="Credit/Debit/Prepaid Card Number"
                    validator="cardValidator"
                    value={this.state.cardValue}
                    style={[commonStyles.resizeNone, customCommonStyles.inputHeight, commonFontStyles.workSans]}
                    helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  />
                  <View>
                    <Text>Card Expiry Date</Text>
                    <TextInputField
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.resizeNone]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                      name="monthExpDate"
                      label="MM"
                      validator="monthValidator"
                      style={[commonStyles.resizeNone, customCommonStyles.inputHeight, commonFontStyles.workSans]}
                      helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                    />
                    <TextInputField
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.resizeNone]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                      name="yearExpDate"
                      label="YYYY"
                      validator="onlyNumbers"
                      style={[commonStyles.resizeNone, customCommonStyles.inputHeight, commonFontStyles.workSans]}
                      helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                    />
                    <TextInputField
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.resizeNone]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                      name="validCVV"
                      label="Valid CVV"
                      style={[commonStyles.resizeNone, customCommonStyles.inputHeight, commonFontStyles.workSans]}
                      helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                    />
                  </View>
                </View>
              ) : null}
            </View>

            {!!this.state.IsAllowOtherPay ? (
              <>
                <View
                  style={[
                    styles.cardHeading,
                    commonStyles.dFlex,
                    commonStyles.alignItemsCenter,
                    commonStyles.marginTop20,
                    commonStyles.borderTopBottom,
                    commonStyles.marginLeftRightMinus15,
                  ]}>
                  <Touchable onPress={() => this.onSelectDebit()} style={[styles.cardHeadingText]}>
                    <View>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.heading6,
                          commonStyles.fontWeight500,
                          this.state.isDebit ? commonStyles.transperentBtnColor : null,
                        ]}>
                        Debit Card
                      </Text>
                      {this.state.isDebit ? <Text style={[styles.cardBoxBorder]}></Text> : null}
                    </View>
                  </Touchable>
                  <Touchable style={[styles.cardHeadingText]} onPress={() => this.onSelectCredit()}>
                    <View>
                      <Text
                        style={[
                          commonFontStyles.workSans,
                          commonStyles.heading6,
                          commonStyles.fontWeight500,
                          !this.state.isDebit ? commonStyles.transperentBtnColor : null,
                        ]}>
                        Credit Card
                      </Text>
                      {!this.state.isDebit ? <Text style={[styles.cardBoxBorder]}></Text> : null}
                    </View>
                  </Touchable>
                </View>
                <View>
                  <FlatList
                    style={styles.noRecords}
                    ListEmptyComponent={<NoRecordsFound message={"No cards found."} />}
                    data={cardD}
                    extraData={this.state}
                    checkboxes={true}
                    keyExtractor={(el) => el.uid}
                    renderItem={({ item, index }) => (
                      <View>
                        <View style={[styles.card, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.marginTop20]}>
                          <View style={[styles.cardDetail, commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                            <View style={[styles.cardIcon, commonStyles.marginRight15]}>
                              {this.bCardIconExists(item.cardType)}
                              <Image source={this.cardIconPath} style={[styles.cardIconImage]} />
                            </View>
                            <View style={[styles.cardInfo]}>
                              <View style={commonStyles.marginBottom10}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>
                                  {item.displayNumber}
                                </Text>
                              </View>
                              <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                                <View>
                                  <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Valid CVV</Text>
                                </View>
                                <View>
                                  <TextInput
                                    secureTextEntry={true}
                                    name="cvv"
                                    label="cvv"
                                    style={[styles.textField, styles.cvv, commonStyles.exSmallheading, customCommonStyles.inputHeight]}
                                    onChangeText={(val) => this.onTextChange(val, index, item.paymentType)}
                                    value={item.cvvNumber}
                                    disabled={!item.isSetDefault}
                                    keyboardType="number-pad"
                                    labelStyle={[customCommonStyles.inputLableStyle]}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                          <View style={[commonStyles.marLAuto]}>
                            <LightBlueRadioButtons
                              radioButtonsClass={[commonStyles.margin0, commonStyles.padding0]}
                              imgClass={commonStyles.margin0}
                              options={{ label: "", value: "selectedCard" }}
                              key={item.uid}
                              onValueChange={(val) => this.onRadioChange(val, item, item.paymentType, index)}
                              isSelected={item.isSetDefault ? "selectedCard" : item.isChecked}
                            // isSelected={item.isSetDefault}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                  />
                </View>
                <View style={[commonStyles.marginTopBottom10, commonStyles.dFlex, commonStyles.justifyContentFlexEnd]}>
                  <Touchable onPress={this.gotoAddNewCard}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.transperentBtnColor]}>Add New Card</Text>
                    </View>
                  </Touchable>
                </View>
                <View style={[commonStyles.dFlex]}>
                  <Button
                    key="cancel"
                    title="Cancel"
                    style={[commonStyles.blueBorderButton, commonStyles.flexBasis50, commonStyles.marginRight10]}
                    titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.blueBorderButtonFontStyle]}
                    onPress={this.navigateToBack}
                  />
                  <SubmitButton
                    title="pay"
                    key="Pay"
                    style={[commonStyles.flexBasis50, commonStyles.blueButton, commonStyles.marginLeft10]}
                    titleTextStyle={[commonStyles.whiteColor]}
                    disabled={isLoading}
                  />
                </View>
              </>
            ) : null}

            {!!isAllowPayment
              && (!!this.props.billData.biller && (!!this.props.billData.biller.isPaymentsFull || this.props.billData.biller.isPaymentsPartial))
              ? <>
                <View style={[commonStyles.marginBottom16]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight700, commonStyles.darkGrayColor]}>
                    Select Payment Option
                  </Text>
                </View>
                <PaymentOptions
                  ref={this.child}
                  billerName={this.props.billData.biller.billerName}
                  billDetailId={this.props.billData.uid}
                  billerId={this.props.billData.biller.billerId}
                  buId={this.props.billData.biller.buId}
                  buName={this.props.billData.biller.buName}
                  subject={this.props.billData.subject}
                  amtPayable={this.state.amtPayable}
                  paymentOptions={this.props.billData.paymentOptions}
                  currencyCode={this.props.billData.currency}
                  user={this.props.user}
                  pchClaim={false}
                  bulkPay={false}
                  rtpPay={true}
                  gpayContainer={styles.justifyContentCenter}
                  googleBtnStyle={styles.googleBtnStyle}
                  onPaymentDone={(res) => this.onPaymentDone(res)}
                  isLoading={this.state.isLoading}
                />
              </>
              : null}
          </Form>

        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  position: {
    position: "relative",
    flexGrow: 1,
    // backgroundColor: "#fff",
  },
  container: {
    backgroundColor: "#fff",
    paddingTop: 16,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
    position: "relative",
    ...StyleSheet.margin(0, 16, 16, 16),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderWidth: 0,
          paddingTop: 0,
          paddingRight: 16,
          paddingBottom: 30,
          paddingLeft: 16,
          ...StyleSheet.margin(0, 0, 0, 0),
        };
    },
  },
  cardBox: {
    padding: 15,
    marginTop: 0,
    marginRight: -15,
    marginBottom: 0,
    marginLeft: -15,
  },
  userIcon: {
    height: 40,
    width: 40,
    marginRight: 10,
    display: "block",
  },
  cardRightDiv: {
    width: "calc(100% - 50px)",
  },
  cardHeadingText: {
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    flexDirection: "column",
    position: "relative",
  },
  cardBoxBorder: {
    display: "block",
    width: "100%",
    bottom: 0,
    height: 2,
    backgroundColor: "#0C7DA6",
    position: "absolute",
  },
  cardSubHeadingText: {
    color: "#444444",
    display: "block",
    fontSize: 14,
    marginTop: 5,
    marginRight: 0,
    marginBottom: 5,
    marginLeft: 0,
  },
  cardSmlHeadingText: {
    color: "#888888",
    display: "block",
    fontSize: 12,
    margin: 0,
  },
  bokMark: {
    display: "block",
    width: 17,
    height: 22,
    backgroundSize: "contain",
  },
  payOptImg: {
    width: 76,
    height: 76,
    display: "block",
    backgroundSize: "contain",
  },
  activePayOptBorder: {
    borderBottom: "2px solid #0C7DA6",
  },
  activePayOptColor: {
    color: "#0C7DA6",
  },
  card: {
    minHeight: 65,
  },
  cardDetail: {},
  cardIcon: {},
  cardIconImage: {
    width: 38,
    height: 38,
    display: "block",
    backgroundSize: "contain",
  },
  cvv: {
    width: 46,
    paddingTop: 0,
    paddingRight: 5,
    paddingBottom: 4,
    paddingLeft: 5,
    height: 26,
    margin: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    textAlign: "center",
    fontSize: "12px",
    marginLeft: 7,
  },
  cvvLabel: {
    top: 8,
    left: "calc(50% - 16px)",
  },
  bottomSectionOuter: {
    // position: "absolute",
    bottom: 0,
    width: "100%",
    // backgroundColor: "#fff",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    left: 0,
    zIndex: 101,
    marginTop: 0,
    marginBottom: 0,
  },
  bottomPaySection: {
    border: 0,
  },
  textAliR: {
    textAlign: "right",
  },
  noRecords: {
    color: "red",
    position: "static",
    marginTop: 15,
  },
  gpayDisable: {
    width: "48%",
    textAlign: "center",
    height: "40px",
    justifyContent: "center",
    backgroundColor: "#212121",
    borderRadius: "4px",
    cursor: "not-allowed",
  },
  gpayText: {
    color: "#fff",
    alignItems: "center",
    justifyContent: "space-around",
    height: "36px",
    display: "flex",
  },
  gpayButttonDis: {
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  pageTitleHolder: {
    // backgroundColor: "#fff",
    marginTop: 16,
    paddingRight: 16,
    marginBottom: 16,
    paddingLeft: 16,
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexDirection: "column",
        };
    },
  },
  customPaddingTopBottom5: {
    paddingTop: 10,
    paddingBottom: 10,
    screen: ({ width, height }) => {
      if (width === 1366 && height === 657)
        return {
          paddingTop: 0,
          paddingBottom: 0,
        };
    },
  },
  customMarginCopyRight: {
    screen: ({ width, height }) => {
      if (width === 1366 && height === 657)
        return {
          marginBottom: 7,
        };
    },
  },
  mediumModalBodyWrapperHeight: {
    // width: 350,
    // padding: 15,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          minHeight: 180,
        };
    },
  },
  contentInner: {
    minHeight: "100%",
    height: "100%",
  },
  okayContainter: {
    width: '100%',
    justifyContent: 'center',
    flex: 1,
  },
  btnText: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  okBtn: {
    maxWidth: 100,
    marginLeft: "auto",
    marginRight: "auto",
  },
  activeButton: {
    backgroundColor: "#d71996",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#d71996",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
    justifyContent: 'center',
    minWidth: 100,
    textAlign: 'center',
    minHeight: 24,
    height: 24,
  },
  googleBtnStyle: {
    display: "flex",
    justifyContent: "center",
    marginRight: 10,
    maxWidth: 110,
    flexBasis: "45%",
    backgroundColor: "#d71996",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    height: 24,
    overflow: "hidden"
  },
  justifyContentCenter:{
    screen: ({ width }) => {
      if (width <= 991)
        return {
          justifyContent: "center"
        };
    },
  },
  customHeight:{
    minHeight: 125,
    height: 125
  }
});
