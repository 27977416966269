const getBaseUrl = () => {
  if (!window || !window.location) return "";
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) url = `${url}:${window.location.port}`;
  return url;
};
let baseUrl =  getBaseUrl();
let devBaseUrl = "https://server-6364667854546b3269.am.os.inventr.ai";

const Config = {
  name: "r14-dev-app",
  api: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
  },
  io: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
  },
  timeout: 100000,
  loginConfig: {
    biometricsAuth: true,
  },
  headers: {
    "set-cookie": "HttpOnly",
    "X-Frame-Options": "sameorigin",
  },
};
export default Config;