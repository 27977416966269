import React from "react";
import R14, { View, Text, StyleSheet, Touchable, Platform, ControlsView } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { PAGE_PATH } from "../../constant/app.data.constant";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { analyticsLogs } from "../../utils/AnalyticLogs";
import { CommonString } from "../../constant/appCommonText";

export default R14.connect(
  class BottomControlComponent extends React.Component {

    async paymentNavigation(action, buttonText) {
      analyticsLogs(CommonString.SEPA_BILL_DETAILS, buttonText === "Reject" ? CommonString.Rejected_Bill :  `${CommonString.CLICKED_ON} ${buttonText}`, this.props.app);
      await this.props.paymentNavigation(action);
      return false;
    }

    renderButton(navType, isDisabled, buttonText) {
      return <View
        style={[customCommonStyles.payButtons, commonStyles.flex1, styles.bottomPayButtons]}>
        <Touchable key={navType} style={[]}
          onPress={() => !isDisabled ? this.paymentNavigation(navType, buttonText) : null}>
          <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter,
          commonStyles.textAlignCenter, styles.blueButton, 
          navType === PAGE_PATH.REJECT && !isDisabled ? styles.declineButton : null,
          !!isDisabled && commonStyles.disabledBtn]}>
            <Text style={[commonFontStyles.workSans,
            commonStyles.fontWeight500,
            commonStyles.exSmallHeading,
            commonStyles.whiteColor,
            navType === PAGE_PATH.REJECT  ? commonStyles.transperentBtnColor : null,
            !!isDisabled && commonStyles.disabledBtnText,
            Platform.OS === 'web' && styles.fullWidth,
            commonStyles.textAlignCenter
            ]}>{buttonText}</Text>
          </View>
        </Touchable>
      </View>
    }

    render() {
      const { isDisabled } = this.props;
      return (
        <ControlsView
          type="buttons"
          style={[styles.bottomControlsView]}
          children={[
            <View key="controlViewBottom" style={[styles.bottomPaySection, commonStyles.flex1, commonStyles.justifyContentSpaceBetween, commonStyles.flexDirectionRow]}>
              {this.renderButton(PAGE_PATH.REJECT, isDisabled, "Reject")}
              {this.renderButton(PAGE_PATH.ACCEPTLATER, isDisabled || true, "Accept Later")}
              {this.renderButton(PAGE_PATH.ACCEPTNOW, isDisabled, "Accept Now")}
            </View>]}
        />
      );
    }
  }
);

const styles = StyleSheet.create({
  bottomPaySection: {
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  active: {
    color: "#182832",
  },
  disabledBtn: {
    opacity: 1,
    pointerEvents: "none",
    backgroundColor: "#888888",
    cursor: "auto",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  bottomControlsView: {
    height: "auto",
  },
  blueButton: {
    flexGrow: 1,
    backgroundColor: "#d71996",
    minHeight: 24,
    height: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  bottomPayButtons: {
    flexBasis: "25%",
    width: "25%",
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 1024) {
        return {
          flexBasis: "24%",
          width: "24%",
          marginRight: 4,
        };
      }
    },
  },
  declineButton: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
    minHeight: 24,
    height: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderColor: "#1277d9",
    borderWidth: 1,
    borderStyle: "solid"
  }
});
