import React from "react";
import R14, { AsyncStorage } from "../core";
import FilterComponent from "../components/FilterComponent";
import CommonUtility from "../utils/CommonUtility";
import ResponsiveTableComponent from "../components/ResponsiveTableComponent";

import DateUtils from "../utils/DateUtils";
import { CURRENCY, BILL_STATUS, MSG_TYPE, } from "../constant/app.data.constant";

import { CommonString } from "../constant/appCommonText";
import JsonToCSV from "../utils/JsonToCSV";
import HtmlDecode from "../utils/HtmlDecode";
import { analyticsLogs } from "../utils/AnalyticLogs";

export default R14.connect(
  class MakePaymentScreen extends React.Component {

    async componentDidMount() {
      AsyncStorage.setItem("homeScreen", this.props.r14.navigation.activeRouteName);
    }

    onCheckboxChange = (selectedChk) => {
      this.props.app.dm.filterDomain.setState({ selectedChk });
    };

    onDateChkboxChange = (selectedDateChk) => {
      this.props.app.dm.filterDomain.setState({ selectedDateChk });
    };

    onSwitchChange = (sortOrder) => {
      this.props.app.dm.filterDomain.setState({ sortOrder });
    };

    onStatusChange = async (ind) => {
      const { statusType } = this.props.app.dm.filterDomain.state;
      statusType[ind].isSelected = !statusType[ind].isSelected;
      this.props.app.dm.filterDomain.setState({ statusType });
    };
    applyFilter = async () => {
      const { selectedChk, sortOrder, statusType, radioOpt, selectedBU, fromDate, toDate, selectedDateChk } = this.props.app.dm.filterDomain.state;
      const data = this.props.app.dm.dashboard.state.compiledMsgList;
      let selectedStatus = statusType.filter((el) => el.isSelected === true).map((itm) => itm.label);

      let list = CommonUtility.displayBillsFilter(data, selectedChk, sortOrder, "XBP", selectedStatus.includes("Paid") || selectedStatus.includes("Declined"), selectedBU, selectedDateChk, fromDate, toDate);
      if (!!selectedStatus.length) {
        list = CommonUtility.filterStatus(list, selectedStatus);
      }
      this.props.app.dm.dashboard.setState({ filterBills: list });

      if (selectedDateChk && (fromDate || toDate)) selectedStatus.push("Date Range");
      if (selectedBU && selectedBU.label) selectedStatus.push("Biller");
      this.props.app.dm.filterDomain.setState({
        selectedStatus,
        showResetFilterBtn: true,
        isFilterOpen: false,
        oldFilterState: { selectedChk, sortOrder, selectedStatus, statusType, radioOpt, selectedDateChk, fromDate, toDate },
      });
    };

    onCloseFilter = () => {
      const {
        selectedStatus,
        selectedChk,
        sortOrder,
        statusType,
        radioOpt,
        selectedDateChk,
        fromDate,
        toDate
      } = this.props.app.dm.filterDomain.state.oldFilterState;
      analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.FILTER_CANCELLED_ACTION, this.props.app);
      this.props.app.dm.filterDomain.setState({
        isFilterOpen: false,
        selectedChk,
        sortOrder,
        statusType,
        radioOpt,
        selectedStatus,
        selectedDateChk,
        fromDate,
        toDate
      });
    };

    onCrossClick = async (el) => {
      let { selectedStatus, statusType } = this.props.app.dm.filterDomain.state;
      statusType.forEach((itm) => {
        if (itm.label.toLowerCase() === el.toLowerCase()) {
          itm.isSelected = false;
        }
      });
      analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.Filter_Removed_Action, this.props.app);
      let idx = selectedStatus.indexOf(el);
      if (idx > -1 && !(el === "Date Range" || el === "Biller")) {
        selectedStatus.splice(idx, 1);
        await this.props.app.dm.filterDomain.setState({ selectedStatus: selectedStatus, statusType: statusType });
      } else if (el === "Date Range" || el === "Biller") {
        selectedStatus = selectedStatus.filter(status => status !== el);
        if (el === "Date Range") {
          await this.props.app.dm.filterDomain.setState({ selectedStatus: selectedStatus, selectedDateChk: "billDate", fromDate: null, toDate: null });
        }
        if (el === "Biller") {
          await this.props.app.dm.filterDomain.setState({ selectedStatus: selectedStatus, selectedBU: "" });
        }
      }
      this.applyFilter();
    };

    onNavigate = (item) => {
        const { threadId } = item;
        let newThreadId = threadId;
        this.props.app.dm.billDetails.setState({
          billDetails: item
        });

        analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.BILL_DETAILS_ACTION, this.props.app);
        this.props.app.nav.to("billDetailsRoute", { threadId: newThreadId });
        return false;
    };

    onBulkPaymentNavigation = async (getData = []) => {
      try {
        let bulkBills = [...getData];
        let billerList = await this.props.app.dm.rest.getBillers();
        bulkBills.forEach((bill) => {
          let biller = billerList.find(x => x.billerId.toLowerCase() === bill.billerId.toLowerCase());
          if (!!biller && !!biller.uid)
          {
              bill.biller.isPaymentsFull = biller.isPaymentsFull;
              bill.biller.isPaymentsPartial = biller.isPaymentsPartial;
          }
        });

        bulkBills = bulkBills.filter(bill => {
          return (
            !["In Process", 'Declined', 'Paid'].includes(bill.paymentStatus ? bill.paymentStatus : bill.billStatus) &&
            (bill.biller.isPaymentsFull || bill.biller.isPaymentsPartial)
            && !!!bill.isExtentionRequested
            // if isBillerBlocked is true than remove bill from bulk pay
            && !!!bill.biller.isBillerBlocked
          )
        });

        if (!!bulkBills && bulkBills.length > 0) {
          analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.BULK_PAY_ACTION, this.props.app);
          this.props.app.dm.dashboard.setState({ bulkPayBills: bulkBills })
          this.props.app.nav.to("bulkPaymentRoutes")
        } else if (!!bulkBills && bulkBills.length === 0){
          if (!!getData && getData.length){
            this.props.app.dm.commonDomain.showToastr("error", CommonString.NoBillApplicable);
          } else this.props.app.dm.commonDomain.showToastr("error", CommonString.NoBillFound);
        }
      } catch (error) {
          console.error(error);
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);

      }
    };

    showPayType = async(data) => {
        this.props.app.dm.billDetails.setState({
          billDetails: data
        });
        this.setState({ isPayNowVisible: false, selectedThreadId: data.threadId });
        let payType = !!data && !!data.biller && !!data.biller.isPaymentsFull ? "PAYFULL" :
          !!data && !!data.biller && !!data.biller.isPaymentsPartial ? "PAYPART" : ""
        if (!!payType) {
          analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.PAY_NOW_ACTION, this.props.app);
          this.props.app.nav.to("payTypeRoute", { paytype: payType });

        }
      return false;
    }

    toggleBookmark = async (el) => {
      const { compiledMsgList, filterBills, headerSearchList } = this.props.app.dm.dashboard.state;
      let ind = compiledMsgList.findIndex((itm) => itm.threadId === el.threadId);
      let fidx = filterBills.findIndex((itm) => itm.threadId === el.threadId);
      let hidx = headerSearchList.findIndex((itm) => itm.threadId === el.threadId);
      analyticsLogs(CommonString.RTP_ALL_BILLS, !el.bookmark ? CommonString.Prioritize_Bill : CommonString.Removed_Priority, this.props.app);
      let res = await this.props.app.dm.dashboard.addUpdateBookmark({
        bookmark: !el.bookmark,
        uid: el.uid,
      });
      if (res.uid) {
        if (ind >= 0)
          compiledMsgList[ind].bookmark = res.bookmark;
        if (fidx >= 0)
          filterBills[fidx].bookmark = res.bookmark;
        if (hidx >= 0)
          headerSearchList[hidx].bookmark = res.bookmark;
        this.props.app.dm.dashboard.setState({ compiledMsgList, filterBills, headerSearchList });
      }
    };
    navigateTo = async (path) => {
      this.props.app.nav.to(path);
    };

    onRestoreFilter = () => {
      analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.All_Filter_Removed_Action, this.props.app);
      const { compiledMsgList } = this.props.app.dm.dashboard.state;
      this.props.app.dm.dashboard.setState({ filterBills: compiledMsgList });
      this.props.app.dm.filterDomain.resetFilter();
    };

    CheckPaymentAllowOrNot = (item) => {
      let isPayNowDistable = true;
      if ([MSG_TYPE.PAY_ALL, MSG_TYPE.PAY_PART, MSG_TYPE.REQ_PAY_EXT].includes(item.rtpMessageType) || BILL_STATUS[item.billStatus] || item.billStatus === "In Process") {
        isPayNowDistable = false;
      }
      else if (!!isPayNowDistable && !!!item.biller.isPaymentsFull && !!!item.biller.isPaymentsPartial) {
        isPayNowDistable = false;
      }

      if (item.billStatus === "Failed" && (!!item.biller.isPaymentsFull || !!item.biller.isPaymentsPartial)) {
        isPayNowDistable = true;
      }
      return isPayNowDistable;
    }

    convertToCSV = async(items, screenName = "data") => {
      if (items.length){
          analyticsLogs(CommonString.RTP_ALL_BILLS, CommonString.DOWNLOAD_CSV, this.props.app);
          items = items.map(item => {
            return {
              "Biller Name": item.billerName || item.biller.billerName,
              "Business Unit Name": item.buName || item.biller.buName,
              "Bill Subject": item.subject,
              "Account Number": item.accountNumber,
              "Creation Date": DateUtils.formatDate(item.billDate),
              "Due On": DateUtils.formatDate(item.dueDate),
              "Status": item.billStatus,
              "Initial Amount": `${CURRENCY.USD}${item.billAmount}`,
              "Paid Amount": `${CURRENCY.USD}${item.amountPaid}`,
              "Amount Payable": `${CURRENCY.USD}${item.amountDue.toFixed(2)}`,
              "Reference ID": item.endToEndIdentification,
            }
          })
        const csvContent = JsonToCSV.convertJsonToCSV(items);
        HtmlDecode.downloadUTF8file({
          content: csvContent,
          filename: `${screenName}_${DateUtils.formatYYYYMMDDHHMMSS()}.csv`
        })
      }
      return false;
    }

    render() {
      const { isFilterOpen, selectedChk, sortOrder, statusType, selectedStatus, radioOpt, selectedDateChk, showResetFilterBtn } = this.props.app.dm.filterDomain.state;
      let option = { selectedChk, sortOrder, statusType, selectedStatus, radioOpt, selectedDateChk, showResetFilterBtn };
      const { isSearchOpen } = this.props.app.dm.userSearchDomain.state;
      const { notificationThreadIds } = this.props.app.dm.notificationDomain.state;
      const { filterBills, headerSearchList, isBlockNavigation} = this.props.app.dm.dashboard.state;
      let leftMenuToggle = this.props.app.dm.userSession.state.leftMenuToggle;
      let currentRoute =this.props.r14.navigation.activeRouteName;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;

      let withBookmarked = [];

      if (!!isSearchOpen && !!isBlockNavigation) {
        withBookmarked = headerSearchList;
      }
      else if (!!isBlockNavigation) {
        withBookmarked = filterBills;
      } else if (!!isSearchOpen) {
        withBookmarked = headerSearchList;
      } else {
        withBookmarked = filterBills;
      }
      withBookmarked = withBookmarked.map((data, i) => {
        return data;
      });
      if (notificationThreadIds.length > 0) {
        let d = notificationThreadIds.map((data, i) => {
          return data.threadId;
        });
        withBookmarked = withBookmarked.filter((message) => d.includes(message.threadId));
      }


      return (
        <>
          <ResponsiveTableComponent
            currentRoute={currentRoute}
            statusOtp={selectedStatus}
            handleCross={this.onCrossClick}
            filterBills={withBookmarked}
            screenName="MakePaymentScreen"
            leftMenuToggle={leftMenuToggle}
            onNavigate={this.onNavigate}
            onBlockNavigatation={this.onBlockNavigatation}
            onViewAllNavigation={this.onViewAllNavigation}
            onBulkPaymentNavigation={this.onBulkPaymentNavigation}
            showPayType={this.showPayType}
            toggleBookmark={this.toggleBookmark}
            navigateTo={this.navigateTo}
            onRestoreFilter={this.onRestoreFilter}
            CheckPaymentAllowOrNot={this.CheckPaymentAllowOrNot}
            convertToCSV={this.convertToCSV}
            isSimpleMessageScreen={false}
            isBillerDirectory={false}
            isMakePayment={true}
            isDashboard={false}
            isMobileDevice={isMobileDevice}
            message = {"No bills found."}
            defaultFilter={option}
            headerText = {this.props.r14.navigation.currRoute._config.label}
            selectedChk= {selectedChk}
            sortOrder= {sortOrder}
            showResetFilterBtn={showResetFilterBtn}
          />
          <FilterComponent
            isVisible={isFilterOpen}
            defaultFilter={option}
            onCancelFilter={this.onCloseFilter}
            onFilter={this.applyFilter}
            onCheckboxChange={this.onCheckboxChange}
            onSwitchChange={this.onSwitchChange}
            onStatusChange={this.onStatusChange}
            billerNameList={this.props.billers}
            onDateChkboxChange={this.onDateChkboxChange}
          /> 
        </>
      );
    }
  }
);