import React from "react";
import { Image, StyleSheet, Touchable, View, Text } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";

const mailGreen = require("../../assets/images/ic_mailgreen.png");
const smsGreen = require("../../assets/images/messagegreen.png");

const mailRed = require("../../assets/images/mailred.png");
const smsRed = require("../../assets/images/messagered.png");

export default class ChannelInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Isopen: false
        };
    }

    toggleInfo = () => {
        this.setState({
            Isopen: !this.state.Isopen,
        })

        setTimeout(() => {
            this.setState({
                Isopen: false,
            })
        }, 1000);
    }

    render() {
        const { source, tooltipText, infoTooltipStyle } = this.props;
        return (
            <View style={[styles.infoTooltipHolder]}>
                <Touchable onPress={this.toggleInfo}>
                    <View>
                        {source === "mailGreen" ?
                            <Image source={mailGreen} style={[commonStyles.icon10, commonStyles.marginRight8]} />
                            : source === "mailRed" ?
                                <Image source={mailRed} style={[commonStyles.icon10, commonStyles.marginRight8]} />
                                : source === "smsGreen" ?
                                    <Image source={smsGreen} style={[commonStyles.icon10]} />
                                    : source === "smsRed" ?
                                        <Image source={smsRed} style={[commonStyles.icon10]} />
                                        : null
                        }
                    </View>
                </Touchable>
                {this.state.Isopen ? (
                    <View style={[styles.infoTooltip, infoTooltipStyle ]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.smallHeading,]}>
                            {tooltipText}
                        </Text>
                        <Text style={[styles.infoTooltipArrow, customCommonStyles.rotateArrow45Deg]}></Text>
                    </View>
                ) : null}
            </View>
        )
    }
}


const styles = StyleSheet.create({
    infoTooltipHolder: {
        position: "relative",
    },
    infoTooltip: {
        position: "absolute",
        backgroundColor: "#37c2ef",
        color: "#fff",
        top: -27,
        zIndex: 999999,
        paddingTop: 4,
        paddingRight: 10,
        paddingBottom: 4,
        paddingLeft: 10,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        flex: 1,
        screen: ({ width }) => {
            if (width <= 768){
                return {
                    top: -30,
                    left: "initial",
                    right: 0,
                };
            }
        },
    },
    infoTooltipArrow: {
        width: 0,
        height: 0,
        borderLeft: "5px solid #37c2ef",
        borderRight: "5px solid transparent",
        borderBottom: "5px solid transparent",
        position: "absolute",
        bottom: -5,
        left: "49.5%",
        screen: ({ width }) => {
            if (width <= 991){
                return {
                    left: "initial",
                    right: 32,
                };
            }
        }
    },
});
