import React from "react";
import R14, { Redirect, AsyncStorage, Platform } from "./core";
import DashboardScreen from "./screens/DashboardScreen";
import RegistrationScreen from "./screens/RegistrationScreen";
import BillDetailsScreen from "./screens/BillDetailsScreen";
import ProfileViewScreen from "./screens/ProfileViewScreen";
import MessageScreen from "./screens/MessageScreen";
import MakePaymentScreen from "./screens/MakePaymentScreen";
import BillHistoryScreen from "./screens/BillHistoryScreen";
import PaymentScreen from "./screens/PaymentScreen";
import DeclineComponent from "./components/DeclineComponent";
import ExtensionScreen from "./screens/ExtensionScreen";
import BillersDirectoryScreen from "./screens/BillersDirectoryScreen";
import PamMessageScreen from "./screens/PamMessageScreen";
import SimpleMessageScreen from "./screens/SimpleMessageScreen";
import CreatingMessageScreen from "./screens/CreatingMessageScreen";
import SimpleMessageDetailScreen from "./screens/SimpleMessageDetailScreen";
import BillReceiptScreen from "./screens/BillReceiptScreen";
import AddNewCardScreen from "./screens/AddNewCardScreen";
import PaymentOptionsScreen from "./screens/PaymentOptionsScreen";
import CommonUtility from "./utils/CommonUtility";
import AddNewAccountScreen from "./screens/AddNewAccountScreen";
import MyPreferencesScreen from "./screens/MyPreferencesScreen";
import NotificationScreen from "./screens/NotificationScreen";
import BillRequestScreen from "./screens/BillRequestScreen";
import ExternalScannerScreen from "./screens/ExternalScannerScreen";
import BulkPaymentScreen from "./screens/BulkPaymentScreen";
import TextToPayScreen from "./screens/TextToPayScreen";
import MyBanksScreen from "./screens/MyBanksScreen";
import LinkPayerScreen from "./screens/LinkPayerScreen";
import KCAuthSessionComponent from "./components/keyCloak/KCAuthSessionComponent";
import KCSessionScreen from './screens/KCSessionScreen';
import ReceiptScreen from './screens/ReceiptScreen';

import { BILL_OPTS, BILL_STATUS, LOGIN_TYPE, USER_TYPE } from "./constant/app.data.constant";
import SEPABillsScreen from "./screens/SEPA/SEPABillsScreen";
// SMB Screens
import ScanQRcodeScreen from "./screens/SMB/ScanQRcodeScreen";
import ApproveBillScreen from "./screens/SMB/ApproveBillScreen";
import UploadBillScreen from "./screens/SMB/UploadBillScreen";
import MakePaymentsScreen from "./screens/SMB/MakePaymentsScreen";
import ReceivePaymentsScreen from "./screens/SMB/ReceivePaymentsScreen";
import VendorStatementsScreen from "./screens/SMB/VendorStatementsScreen";
import OrganizationScreen from "./screens/SMB/OrganizationScreen";

// PCH Screens
import PCHSignupScreen from "./screens/PCH/PCHSignupScreen";
import PCHProfileViewScreen from "./screens/PCH/PCHProfileViewScreen";
import PractUploadBillsScreen from "./screens/PCH/PractUploadBillsScreen";
import PatientUploadBillsScreen from "./screens/PCH/PatientUploadBillsScreen";
import PatientBillClaimScreen from "./screens/PCH/PatientBillClaimScreen";
import PatientViewBillScreen from "./screens/PCH/PatientViewBillScreen";
import ProviderListScreen from "./screens/PCH/ProviderListScreen";
import PatientInsuranceScreen from "./screens/PCH/PatientInsuranceScreen";
import PCHDashboardScreen from "./screens/PCH/PCHDashboardScreen";
import SubmittedReceiptScreen from "./screens/PCH/SubmittedReceiptScreen";
import SubmittedSmbReceiptScreen from "./screens/SMB/SubmittedSmbReceiptScreen";
import AddUsers from "./screens/PCH/AddUsers";
import PCHTermsAndconditions from "./screens/PCH/PCHTermsAndconditions";
import PCHPrivacyPolicy from "./screens/PCH/PCHPrivacyPolicy";
import HelpScreen from "./screens/HelpScreen";
import PCHUserTerms from "./screens/PCH/PCHUserTermsScreen";
import AccessServicesAgreement from "./screens/PCH/AccessServicesAgreementScreen";
import TermsAndConditionsScreen from "./screens/PCH/TermsAndConditionsScreen";
import SignupConfirmationScreen from "./screens/PCH/SignupConfirmationScreen";
import ManagePaymentScreen from "./screens/PCH/ManagePaymentScreen";
import ViewManagePaymentScreen from "./screens/PCH/ViewManagePaymentScreen";
import ApplyManagePaymentScreen from "./screens/PCH/ApplyManagePaymentScreen";
import MOOPScreen from "./screens/PCH/MOOPScreen";

import UserAgentValidator from "./utils/UserAgentValidator";
import { CLAIM_CLIENT_ID, KC_CLIENT_ID, NATIVE_APP_URL } from "./constant/app.constant";
import Linking from "./utils/Linking";
import { CommonString } from "./constant/appCommonText";
import { analyticsLogs } from "./utils/AnalyticLogs";
import SEPABillDetailsScreen from "./screens/SEPA/SEPABillDetailsScreen";
import PaymentReceiptScreen from "./screens/SEPA/PaymentReceiptScreen";
import ClientPOCScreen from "./screens/ClientPOCScreen";
import LayoutComponent from "./components/LayoutComponent";
import SubscriptionComponent from "./components/SubscriptionComponent";
import RSAencryption from "./utils/RSAencryption";
import ServiceSubscriptionComponent from "./components/ServiceSubscriptionComponent";
import ClientDataTable from "./components/ClientDataTable";
import AddEditClientComponent from "./components/AddEditClientComponent";
import DeleteAccountScreen from "./screens/DeleteAccountScreen";
import CheckoutSessionComponent from "./components/payments/CheckoutSessionComponent";

export default class Actions extends R14.Actions {

  async shouldActionLoad({ to, from, app }) {
    let ret = true;
    /** Start - Mix Panel tracking event */
    // First try to get label if label is not available then path.. if both are not available then ""
    let toMixEvent = to && to._config ? to._config.label ? to._config.label : to._config.path : to._name;
    // Remove leading slashes from paths and convert spaces to underscores
    if (toMixEvent.startsWith('/')) toMixEvent = toMixEvent.substring(1);
    toMixEvent = toMixEvent.toUpperCase().replace(/[^A-Z0-9]/ig, '_');

    analyticsLogs(`${CommonString.PAGE_NAVIGATION}_SWITCH_TO_${toMixEvent}`, null, this,
      (from && from._path ? `${NATIVE_APP_URL + from._path}` : null),
      (to && to._path ? `${NATIVE_APP_URL + to._path}` : null));
    /** End - Mix Panel tracking event */

    // await app.dm.userSession.init();
    if (!to.metadata.public) {
      if (to._path.indexOf(`/claims/`) > -1)
        ret = <Redirect to="pchLogin" />; // "login"
      else
        ret = <Redirect to="login" />; // "login"
      if (app.dm.userSession.isLoggedIn) {
        // search screen reset
        let isAlreadyOpen = !!from && !!["dashboard", "makePaymentRoutes", "simpleMessageRoute", "billersDirectoryRoute"].includes(from._name) ? app.dm.userSearchDomain.state.isSearchOpen : app.dm.userSearchDomain.state.isAlreadyOpen;
        app.dm.userSearchDomain.setState({
          isAlreadyOpen: isAlreadyOpen,
          isSearchOpen: false,
          currentOpenScreen: from,
        });

        if (!!app.dm.userSearchDomain.state.isAlreadyOpen && !!from && !!to) {
          if ((!!["payTypeRoute", "payPartialRoute", "declinedRoute", "extensionRoute", "billReceiptRoute", "billDetailsRoute"].includes(from._name) && ["makePaymentRoutes", "dashboard"].includes(to._name))
            || (["simpleMessageDetailRoute"].includes(from._name) && ["simpleMessageRoute"].includes(to._name))
            || (["pamMessageRoute"].includes(from._name) && ["billersDirectoryRoute"].includes(to._name))) {
            let oldRoute = await AsyncStorage.getItem("homeScreen");
            app.dm.userSearchDomain.setState({
              isSearchOpen: oldRoute === to._name ? app.dm.userSearchDomain.state.isAlreadyOpen : false,
            });
          }
        }
        ret = true;
      }
    }
    if (!!from && !!["simpleMessageRoute", "billersDirectoryRoute"].includes(from._name))
      app.dm.notificationDomain.setState({
        notificationThreadIds: [],
      });

    let tab = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_TABSELECTION);
    app.dm.dashboard.setState({
      oldRoute: from ? from._name : "dashboard",
      selectedScreen: tab ? tab : app.dm.dashboard.state.selectedScreen,
    });
    app.dm.notificationDomain.setState({
      isMobileDevice: UserAgentValidator.isMobileDevice(),
    });
    return ret;
  }

  async kcsession({ application }) {
    let access_token = await this.dm.userSession.getAccessToken();
    let clientId = KC_CLIENT_ID;
    if (application === "claims") {
      await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION, USER_TYPE.CLAIMS);
      clientId = CLAIM_CLIENT_ID;
    }
    else
      await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION, USER_TYPE.XBP);

    return <KCSessionScreen access_token={access_token} application={application} clientId={clientId} />;
  }

  async login({ message }) {
    await this.dm.userSession.logout();
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION, USER_TYPE.XBP);
    return <KCAuthSessionComponent source="login" signInLocation={USER_TYPE.XBP} message={message} clientId={KC_CLIENT_ID} />
  }

  async changePassword() {
    if (!(await this.dm.rest.getUser())) {
      return <Redirect to="login" />;
    }
    return <KCAuthSessionComponent source="changePwd" signInLocation={USER_TYPE.XBP} clientId={KC_CLIENT_ID} />
  }

  async pchChangePassword() {
    if (!(await this.dm.rest.getUser())) {
      return <Redirect to="pchLogin" />;
    }
    return <KCAuthSessionComponent source="changePwd" signInLocation={USER_TYPE.CLAIMS} clientId={CLAIM_CLIENT_ID} />
  }

  async logout() {
    if (!(await this.dm.rest.getUser())) {
      let _sign = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION);
      this.nav.to(_sign === USER_TYPE.CLAIMS ? "pchLogin" : "login");
    }
    else {
      analyticsLogs(CommonString.LOG_OUT, `${CommonString.Clicked_Log_Out}`, this);
      this.dm.userSession.setState({
        isLoggedOut: true,
      });
      return <LayoutComponent hideHeader={true} isAuthenticated={true} hideCopyright={false} />
    }
    return false;
  }

  async landing() {
    return <Redirect to={"login"} />
  }

  async pchlanding() {
    return <Redirect to={"pchLogin"} />
  }

  async registration(type) {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} hideCopyright={true} children={<RegistrationScreen registrationType={type} />} />;
  }

  async pchSignup(type) {
    const userLoginDetail = await this.dm.rest.getUser();
    this.dm.userSession.setState({
      pchRegistrationTypeProvider: false,
      pchRegistrationTypePatient: true,
    });
    return <LayoutComponent hideHeader={true} isAuthenticated={false} hideCopyright={true} children={<PCHSignupScreen registrationType={type} userLoginDetail={userLoginDetail} />} />;
  }

  async dashboard() {
    const userLoginDetail = await this.dm.rest.getUser();
    if (userLoginDetail !== null) {
      await this.validateSubscription();
      let userType = !!userLoginDetail && userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      const { compiledMsgList } = this.dm.dashboard.state;
      if (userType === USER_TYPE.XBP_ADMIN) {
        this.nav.to("clientListRoute");
      }
      else if (userType === USER_TYPE.PCH_PROVIDER || userType === USER_TYPE.PCH_PATIENT) {
        let selectedBlock = this.dm.uploadBillDomain.state.selectedBlock; //  "New";
        this.dm.uploadBillDomain.setState({
          currentPage: 1
        })
        let bills = await this.dm.uploadBillDomain.getUploadedBillsWithPage(selectedBlock);
        return <LayoutComponent hideHeader={true} children={<PCHDashboardScreen userLoginDetail={userLoginDetail} selectedBlock={selectedBlock} filterBills={bills} userType={userType} />} />;
      } else {
        this.dm.dashboard.setState({
          selectedBlock: [],
          isBlockNavigation: false
        })
        this.dm.notificationDomain.setState({
          notificationThreadIds: [],
        });

        let { selectedScreen } = this.dm.dashboard.state;
        if (!!userLoginDetail.resourceAccess && !userLoginDetail.resourceAccess.includes(BILL_OPTS[0].value) && userLoginDetail.resourceAccess.includes(BILL_OPTS[1].value)) {
          selectedScreen = BILL_OPTS[1].value;
          await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_TABSELECTION, selectedScreen);
          this.dm.dashboard.setState({
            selectedScreen: selectedScreen
          })
        }
        if (selectedScreen === BILL_OPTS[0].value) {
          await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_TABSELECTION, selectedScreen);
          if (!compiledMsgList.length) {
            await this.dm.dashboard.setCompiledMsgList();
          }
          else {
            let list = await this.dm.rest.getBillers();
            for (let i = 0; i < compiledMsgList.length; i++) {
              let biller = list.find(x => x.uid === compiledMsgList[i].billerUid);
              compiledMsgList[i].biller = biller;
            }
            this.dm.dashboard.setState({
              compiledMsgList: compiledMsgList,
              filterBills: compiledMsgList,
            });
          }
        }
        else {
          this.dm.sepa.setState({ currentPage: 1 });
          await this.dm.sepa.syncSEPABills();
        }
        return <LayoutComponent hideHeader={true} children={<DashboardScreen userLoginDetail={userLoginDetail} />} />;
      }
    }
    else {
      // <Redirect to={"pchLogin"} />
      this.nav.to("login");
    }
  }

  async makePaymentAction() {
    const { compiledMsgList } = this.dm.dashboard.state;
    if (!compiledMsgList.length) {
      await this.dm.dashboard.setCompiledMsgList();
    }
    let billers = [];
    const buBillerDetail = await this.dm.rest.getBillerBUs();
    if (buBillerDetail && buBillerDetail.billNameList && buBillerDetail.billNameList.length > 0)
      billers = buBillerDetail.billNameList;

    return <LayoutComponent hideHeader={true} children={<MakePaymentScreen billers={billers} />} />;
  }

  async bulkPaymentAction() {
    let userLoginDetail = await this.dm.rest.getUserWithDetails();
    return <LayoutComponent hideHeader={true} children={<BulkPaymentScreen userDetail={userLoginDetail} />} />;
  }

  async billersDirectoryScreenAction() {
    const { pamList } = this.dm.billerDirectoryDomain.state;
    if (!pamList || pamList.length === 0)
      await this.dm.billerDirectoryDomain.displayBillerDirectoryData(true);
    return <LayoutComponent hideHeader={true} children={<BillersDirectoryScreen />} />;
  }

  async pamMessageAction({ pamId, pid }) {
    try {
      let pamM = await this.dm.billerDirectoryDomain.getPamMessagesByPamId(pamId);
      let pamA = await this.dm.billerDirectoryDomain.moreInfoMessages(pamId, pid);
      let userDetails = null;
      if (!pamA) {
        this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        return false;
      }
      let list = await this.dm.rest.getBillers();
      let flag = true;
      let biller = list.find((elm) => elm.uid === pamM[0].billerUid);
      if (!!biller) {
        flag = 'messagingToggleValue' in biller ? biller.messagingToggleValue : true;
        pamM[0].biller = biller;
        userDetails = await this.dm.rest.getuserBUDetails(biller)
      }

      if (!!pamM && pamM.length > 0 && !!pamA && pamA.textToPayPreferences)
        pamM[0].textToPayPreference = pamA.textToPayPreferences;

      return <LayoutComponent hideHeader={false} children={<PamMessageScreen pam={pamM[0]} moreInfo={pamA} userDetails={userDetails} messageFlag={flag} />} />;
    } catch (error) {
      this.nav.back();
    }
  }

  async simpleMessageScreenAction() {
    const { simpleMessage } = this.dm.simpleMessage.state;
    if (!simpleMessage || simpleMessage.length === 0)
      await this.dm.simpleMessage.displaySimpleMessage(true);
    return <LayoutComponent hideHeader={true} children={<SimpleMessageScreen />} />;
  }

  async simpleMessagesDetailsAction({ threadId }) {
    let data = await this.dm.simpleMessage.getBillerFromThread(threadId);
    if (!data) {
      this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      return false;
    } else {
      let dbData = await this.dm.simpleMessage.getMessageById(threadId);
      let msg = this.dm.simpleMessage.hasOnlyMessageBody(data.messages);
      await this.dm.simpleMessage.setState({ messageList: msg });
      let linkPids = await this.dm.rest.getLinkedPID();
      const { threadHeader, messageHeader } = data.messages[0].envelope;
      let biller = null;
      if (linkPids.split(',').includes(threadHeader.originatorPid)) {
        biller = { billerRef: messageHeader.recipientPIDRef, payerRef: messageHeader.senderPIDRef, buId: messageHeader.buId, payerId: messageHeader.senderPid };
      }
      else {
        biller = { billerRef: messageHeader.senderPIDRef, payerRef: messageHeader.recipientPIDRef, buId: messageHeader.buId, payerId: messageHeader.recipientPid };
      }

      let billerDetails = await this.dm.rest.getBillers(biller);
      let userDetails = await this.dm.rest.getuserBUDetails(biller);

      return <LayoutComponent hideHeader={false} children={<SimpleMessageDetailScreen messageDetails={msg} dbData={dbData[0]} billerDetails={billerDetails} userDetails={userDetails} linkPids={linkPids} />} />;
    }
  }

  async createMessageAction() {
    let data = await this.dm.rest.getBillerBUs();
    const userLoginDetail = await this.dm.rest.getUser();
    let billNameList = data.billNameList.filter(x => !!x.messagingToggleValue && x.payerId === userLoginDetail.pid);
    if (!!billNameList && billNameList.length > 0) {
      let { pamList } = this.dm.billerDirectoryDomain.state.pamList;
      if (!pamList || pamList.length === 0)
        await this.dm.billerDirectoryDomain.displayBillerDirectoryData(true);

      pamList = this.dm.billerDirectoryDomain.state.pamList;
      billNameList = billNameList.filter(o1 => !!pamList.some(o2 => o1.value === o2.biller.uid && o2.status !== "denied"));
    }
    return <LayoutComponent hideHeader={false} children={<CreatingMessageScreen billerNameList={billNameList} leftMenuToggle={this.dm.userSession.state.leftMenuToggle} isMobileDevice={this.dm.notificationDomain.state.isMobileDevice} />} />;
  }

  async profileViewAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    const userBUDetails = await this.dm.rest.getuserBUDetails();
    return <LayoutComponent hideHeader={true} children={<ProfileViewScreen profile={userLoginDetail} profileActionType={"view"} clientId={KC_CLIENT_ID} userBUDetails={userBUDetails} />} />;
  }
  async profileEditAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    const userBUDetails = await this.dm.rest.getuserBUDetails();
    return <LayoutComponent hideHeader={true} children={<ProfileViewScreen profile={userLoginDetail} profileActionType={"edit"} countryList={[]} clientId={KC_CLIENT_ID} userBUDetails={userBUDetails} />} />;
  }

  //PCH Profile view/edit
  async pchProfileViewAction() {
    await this.validateSubscription();
    const userLoginDetail = await this.dm.rest.getUser();
    const userBUDetails = await this.dm.rest.getuserBUDetails();
    const enableSocialLogin = await this.dm.user.hasSocialLogin();
    if (!!userLoginDetail.parentUid) {
      let parentUserDetail = await this.dm.user.getUserBUName(userLoginDetail.parentUid);
      userLoginDetail.parentUserDetail = parentUserDetail;
    }
    return <LayoutComponent hideHeader={true} children={<PCHProfileViewScreen profile={userLoginDetail} enableSocialLogin={enableSocialLogin} profileActionType={"view"} userBUDetails={userBUDetails} clientId={CLAIM_CLIENT_ID} />} />;
  }
  async pchProfileEditAction({ mode }) {
    await this.validateSubscription();
    const userLoginDetail = await this.dm.rest.getUser();
    const userBUDetails = await this.dm.rest.getuserBUDetails();
    const enableSocialLogin = await this.dm.user.hasSocialLogin();
    if (!!userLoginDetail.parentUid) {
      let parentUserDetail = await this.dm.user.getUserBUName(userLoginDetail.parentUid);
      userLoginDetail.parentUserDetail = parentUserDetail;
    }
    return <LayoutComponent hideHeader={true} children={<PCHProfileViewScreen defaultMode={mode} enableSocialLogin={enableSocialLogin} profile={userLoginDetail} profileActionType={"edit"} countryList={[]} userBUDetails={userBUDetails} clientId={CLAIM_CLIENT_ID} />} />;
  }

  async messageScreenAction({ threadId }) {
    const res = await this.dm.dashboard.getBillerFromThread(threadId);
    if (!res) {
      this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      return false;
    }
    const { compiledMsgList } = this.dm.dashboard.state;
    let bills = [];
    if (!!compiledMsgList && compiledMsgList.length > 0) {
      bills = compiledMsgList.filter(x => x.threadId === threadId)
    }
    if (!!bills && bills.length === 0)
      bills = await this.dm.dashboard.getBills({ filter: { threadId: { eq: threadId } } });

    let complileBill = bills[0];
    let bd = this.dm.dashboard.onlyMessageBody(res.messages);
    let list = await this.dm.rest.getBillers();
    let userDetails = await this.dm.rest.getUser();

    let userMoreInfo = await this.dm.rest.getuserBUDetails({
      buId: res.messages[0].envelope.messageHeader.buId,
      billerRef: res.messages[0].envelope.messageHeader.senderPIDRef,
      payerRef: res.messages[0].envelope.messageHeader.recipientPIDRef,
      payerId: res.messages[0].envelope.messageHeader.recipientPid
    })
    let linkPids = await this.dm.rest.getLinkedPID();
    userDetails = { ...userDetails, ...userMoreInfo };
    userDetails.linkPids = linkPids.split(',');

    return <LayoutComponent hideHeader={false} children={<MessageScreen displayMessages={bd} billDetails={complileBill} billerList={list} userDetails={userDetails} />} />;
  }

  async billDetailsAction({ threadId }) {
    let userDetails = await this.dm.rest.getUserWithDetails();
    if (!!userDetails) {
      const messages = await this.dm.dashboard.getBillerFromThread(threadId, true);
      if (!messages) {
        this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        return false;
      }
      const { compiledMsgList } = this.dm.dashboard.state;
      let res = [];
      if (!!compiledMsgList && compiledMsgList.length > 0) {
        let list = await this.dm.rest.getBillers();
        for (let i = 0; i < compiledMsgList.length; i++) {
          let biller = list.find(x => x.billerId === compiledMsgList[i].billerId);
          compiledMsgList[i].biller = biller;
        }
        this.dm.dashboard.setState(compiledMsgList);
        res = compiledMsgList.filter(x => x.threadId === threadId)
      }

      if (!!res && res.length === 0)
        res = await this.dm.dashboard.getBills({ filter: { threadId: { eq: threadId } } });

      let bd = this.dm.dashboard.manageBillDetails(messages.messages);
      let complileBill = !!res ? { ...bd, ...res[0] } : { ...bd };
      let flagBiller = complileBill.biller;
      let messageFlag = !!flagBiller ? flagBiller.messagingToggleValue : true;
      if (!!!complileBill.biller) {
        this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        this.nav.to("dashboard")
        return false;
      }
      let userMoreInfo = await this.dm.rest.getuserBUDetails({
        buId: messages.messages[0].envelope.messageHeader.buId,
        billerRef: messages.messages[0].envelope.messageHeader.senderPIDRef,
        payerRef: messages.messages[0].envelope.messageHeader.recipientPIDRef,
        payerId: messages.messages[0].envelope.messageHeader.recipientPid
      })
      let linkPids = await this.dm.rest.getLinkedPID();
      userDetails = { ...userDetails, ...userMoreInfo };
      userDetails.linkPids = linkPids.split(',');
      return <LayoutComponent hideHeader={false} children={<BillDetailsScreen billDetails={complileBill} messages={messages} userDetails={userDetails} messageFlag={messageFlag} billerDetails={flagBiller} />} />;
    }
    else this.nav.to("login");
  }

  async billHistoryScreenAction() {
    let pids = await this.dm.rest.getLinkedPID();
    let linkPids = pids.split(',');
    let { billDetails } = this.dm.billDetails.state;
    if (!!billDetails && !!billDetails.uid) {
      const { compiledMsgList } = this.dm.dashboard.state;
      let messagesList = [];
      if (!!compiledMsgList && compiledMsgList.length > 0) {
        messagesList = compiledMsgList.filter(x => x.billerId === billDetails.billerId && linkPids.find(y => y === x.payerId))
      }
      if (!!messagesList && messagesList.length === 0)
        messagesList = await this.dm.dashboard.getBills({ filter: { billerId: { eq: billDetails.billerId }, payerId: { in: linkPids } } });

      const messages = CommonUtility.displayHistoryBills(messagesList, billDetails);
      return <LayoutComponent hideHeader={false} children={<BillHistoryScreen billDetails={billDetails} messages={messages} />}/>;
    }
    else {
      this.nav.back();
    }
  }

  async payTypeAction({ paytype }) {
    let { billDetails } = this.dm.billDetails.state;
    if (!!billDetails && !!billDetails.threadId) {
      let user = await this.dm.rest.getUserWithDetails();
      const res = await this.dm.dashboard.getBillerFromThread(billDetails.threadId);
      if (!res) {
        // return <Redirect to="login" />;
        this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        return false;
      }
      const { compiledMsgList } = this.dm.dashboard.state;
      let bills = [];
      if (!!compiledMsgList && compiledMsgList.length > 0) {
        let list = await this.dm.rest.getBillers();
        for (let i = 0; i < compiledMsgList.length; i++) {
          let biller = list.find(x => x.billerId === compiledMsgList[i].billerId);
          compiledMsgList[i].biller = biller;
        }
        this.dm.dashboard.setState(compiledMsgList);
        bills = compiledMsgList.filter(x => x.threadId === billDetails.threadId)
      }
      if (!!bills && bills.length === 0)
        bills = await this.dm.dashboard.getBills({ filter: { threadId: { eq: billDetails.threadId } } });

      let filterMsgs = [];
      if (res && res.messages) {
        filterMsgs = res.messages.filter((el) => el.envelope.messageBody);
      }
      let bd = this.dm.dashboard.manageBillDetails(res.messages);
      let billData = !!bills ? { ...bd, ...bills[0] } : bd;
      let isAllowPayment = true;
      if (!billData.paymentOptions || !billData.paymentOptions.merchantAccountNumber) {
        isAllowPayment = false;
        this.dm.billDetails.setState({
          isShowErrorMsg: true
        })
      }
      else if (!!billData.paymentOptions && !!billData.paymentOptions.merchantAccountNumber) {
        let isConnnected = await this.dm.billDetails.validateBillAccount(billData.paymentOptions.merchantAccountNumber);
        if (!!!isConnnected) {
          isAllowPayment = false;
          this.dm.billDetails.setState({
            isShowErrorMsg: true
          })
        }
      }

      let senderName = await this.dm.billDetails.getSenderName({
        buId: billData.messageHeader.buId,
        billerRef: billData.messageHeader.senderPIDRef,
        payerRef: billData.messageHeader.recipientPIDRef,
        payerId: billData.messageHeader.recipientPid
      });

      user.firstName = senderName;

      return <LayoutComponent hideHeader={false} children={<PaymentScreen isAllowPayment={isAllowPayment} payType={paytype} billData={billData} msgList={filterMsgs} cardDisplayList={[]} user={user} />}/>;
    }
    else
      this.nav.to(await AsyncStorage.getItem("homeScreen"));
  }

  async declinedAction({ paytype }) {
    let { billDetails } = this.dm.billDetails.state;
    if (!!billDetails && !!billDetails.threadId) {
      const res = await this.dm.dashboard.getBillerFromThread(billDetails.threadId);
      if (!res) {
        // return <Redirect to="login" />;
        this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        return false;
      }
      const { compiledMsgList } = this.dm.dashboard.state;
      let bills = [];
      if (!!compiledMsgList && compiledMsgList.length > 0) {
        bills = compiledMsgList.filter(x => x.threadId === billDetails.threadId)
      }
      if (!!bills && bills.length === 0)
        bills = await this.dm.dashboard.getBills({ filter: { threadId: { eq: billDetails.threadId } } });

      let bd = this.dm.dashboard.manageBillDetails(res.messages);
      return <LayoutComponent hideHeader={false} children={<DeclineComponent payType={paytype} billData={{ ...bd, ...bills[0] }} />}/>;
    }
    else
      this.nav.to(await AsyncStorage.getItem("homeScreen"));
  }

  async extensionAction({ paytype }) {
    let { billDetails } = this.dm.billDetails.state;
    if (!!billDetails && !!billDetails.threadId) {
      const res = await this.dm.dashboard.getBillerFromThread(billDetails.threadId);
      if (!res) {
        // return <Redirect to="login" />;
        this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        return false;
      }
      const { compiledMsgList } = this.dm.dashboard.state;
      let bills = [];
      if (!!compiledMsgList && compiledMsgList.length > 0) {
        bills = compiledMsgList.filter(x => x.threadId === billDetails.threadId)
      }
      if (!!bills && bills.length === 0)
        bills = await this.dm.dashboard.getBills({ filter: { threadId: { eq: billDetails.threadId } } });
      let bd = this.dm.dashboard.manageBillDetails(res.messages);
      return <LayoutComponent hideHeader={false} children={<ExtensionScreen payType={paytype} billData={{ ...bd, ...bills[0] }} msgList={null} leftMenuToggle={this.dm.userSession.state.leftMenuToggle} isMobileDevice={this.dm.notificationDomain.state.isMobileDevice} />}/>;
    }
    else
      this.nav.to(await AsyncStorage.getItem("homeScreen"));
  }

  async deleteAccountAction() {
    return <LayoutComponent hideHeader={false} children={<DeleteAccountScreen />}/>;
  }

  async billReceiptAction({ threadId }) {
    let { billDetails } = this.dm.billDetails.state;
    if (!!billDetails && !!billDetails.threadId && billDetails.threadId === threadId) {
      return <LayoutComponent hideHeader={false} children={<BillReceiptScreen billDetails={billDetails} />}/>;
    }
    else {
      this.nav.back();
      return false;
    }
  }

  async addNewCardAction() {
    const userDetails = await this.dm.rest.getUserWithDetails();
    return <LayoutComponent hideHeader={false} children={<AddNewCardScreen userDetails={userDetails} />}/>;
  }

  async addNewAccountAction({ uid = null }) {
    const userDetails = await this.dm.rest.getUserWithDetails();
    let formData = await this.dm.billDetails.fetchPayerAccountDetails(uid);
    return <LayoutComponent hideHeader={false} children={<AddNewAccountScreen formData={formData} userDetails={userDetails} />}/>;
  }

  async editAccountAction({ uid = null }) {
    let formData = await this.dm.billDetails.fetchPayerAccountDetails(uid);
    return <LayoutComponent hideHeader={false} children={<AddNewAccountScreen formData={formData} />}/>;
  }

  async myPreferencesAction() {
    let myPreferenceList = await this.dm.myPreferenceDomain.getMyPreference();
    return <LayoutComponent hideHeader={false} children={<MyPreferencesScreen myPreferenceList={myPreferenceList} />}/>;
  }

  async paymentOptionsAction() {
    let cardDisplayList = await this.dm.billDetails.getPayerCardDetailsList();
    let bankAccountList = await this.dm.billDetails.getPayerAccountDetailsList();
    let prevRoute = this.nav._r14.navigation._prevRoute != null ? this.nav._r14.navigation._prevRoute.name : null;
    if (!!prevRoute && prevRoute !== "addNewAccountRoute" && prevRoute !== "editAccountRoute") {
      this.dm.billDetails.setState({ bankTabActive: "Card" });
    }
    return <LayoutComponent hideHeader={false} children={<PaymentOptionsScreen cardDisplayList={cardDisplayList} bankAccountList={bankAccountList} />}/>;
  }

  async notificationScreenAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    await this.dm.notificationDomain.notificationDetail();
    return <LayoutComponent hideHeader={false} children={<NotificationScreen userLoginDetail={userLoginDetail} />}/>;
  }

  async billRequestScreenAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    let industry = await this.dm.billRequest.getIndustries();
    return <LayoutComponent hideHeader={false} children={<BillRequestScreen userLoginDetail={userLoginDetail} industries={industry} />}/>;
  }

  async externalScannerAction({ threadId }) {
    return <LayoutComponent hideHeader={false} children={<ExternalScannerScreen threadId={threadId} />}/>;
  }

  async bankAccountAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    let payerBankAccounts = await this.dm.achPaymentDomain.getPayerBankAccounts(
      {
        pId: { eq: userLoginDetail.pid },
        isActive: true
      });
    payerBankAccounts = !!payerBankAccounts ? payerBankAccounts.filter(account => account.isActive) : [];
    const sortedPayerBankAccounts = payerBankAccounts.sort((bankA, bankB) => bankB.bankName > bankA.bankName ? -1 : 1);
    return <LayoutComponent hideHeader={true} children={<MyBanksScreen profile={userLoginDetail} payerBankAccounts={sortedPayerBankAccounts} />}/>;
  }

  async linkPayerAction() {
    return <LayoutComponent hideHeader={false} children={<LinkPayerScreen />}/>;
  }

  /** SMB Actions */
  async scanQRcodeAction() {
    return <LayoutComponent hideHeader={false} children={<ScanQRcodeScreen />}/>;
  };

  async approveBillAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    return <LayoutComponent hideHeader={false} children={<ApproveBillScreen user={userLoginDetail} />}/>;
  };

  async makePaymentsAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    let getuserBUDetails = await this.dm.rest.getuserBUDetails();
    if (getuserBUDetails && getuserBUDetails.length) {
      getuserBUDetails = getuserBUDetails.find(bu => bu.userDetailUid === userLoginDetail.uid);
      userLoginDetail.firstName = getuserBUDetails.firstName || userLoginDetail.firstName;
      userLoginDetail.lastName = getuserBUDetails.lastName || userLoginDetail.lastName;
    }
    return <LayoutComponent hideHeader={false} children={<MakePaymentsScreen user={userLoginDetail} />}/>;
  };

  async uploadBillAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    return <LayoutComponent hideHeader={false} children={<UploadBillScreen user={userLoginDetail} />}/>;
  };

  async vendorStatementsAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    return <LayoutComponent hideHeader={false} children={<VendorStatementsScreen user={userLoginDetail} />}/>;
  };

  async receivePaymentsAction() {
    const userLoginDetail = await this.dm.rest.getUser();
    return <LayoutComponent hideHeader={false} children={<ReceivePaymentsScreen user={userLoginDetail} />}/>;
  };

  async switchOrganizations() {
    let organizations = this.dm.userSession.state.userOrgs;
    let userLoginDetail = await this.dm.rest.getUser();
    if (!organizations || organizations.length === 0) {
      await this.dm.userSession.updateSMBOrganization(userLoginDetail);
      organizations = this.dm.userSession.state.userOrgs;
      userLoginDetail = await this.dm.rest.getUser();
    }
    return <LayoutComponent hideHeader={false} children={<OrganizationScreen organizations={organizations} userLoginDetail={userLoginDetail} />}/>;

  }

  /* PCH Actions Start */

  async textToPayAction({ messageMetaIdentifier }) {
    return <LayoutComponent hideHeader={true} children={<TextToPayScreen messageMetaIdentifier={messageMetaIdentifier} />}/>;
  }

  async pchLogin({ message }) {
    await this.dm.userSession.logout();
    await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_SIGNINLOCATION, USER_TYPE.CLAIMS);
    return <KCAuthSessionComponent source="login" signInLocation={USER_TYPE.CLAIMS} message={message} clientId={CLAIM_CLIENT_ID} />
  }

  async uploadBillScreenAction() {
    await this.validateSubscription();
    const userLoginDetail = await this.dm.rest.getUser();
    let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
    if (userType === USER_TYPE.PCH_PATIENT) {
      let insurances = await this.dm.addInsuranceDomain.getInsuranceDetailsWithPage(null, 50);
      return <LayoutComponent hideHeader={false} children={<PatientUploadBillsScreen insurances={insurances} userLoginDetail={userLoginDetail} />}/>;
    }
    else {
      let getuserBUDetails = await this.dm.rest.getuserBUDetails();
      if (getuserBUDetails && getuserBUDetails.length) {
        getuserBUDetails = getuserBUDetails.find(bu => bu.userDetailUid === userLoginDetail.uid);
        userLoginDetail.firstName = getuserBUDetails.firstName || userLoginDetail.firstName;
        userLoginDetail.lastName = getuserBUDetails.lastName || userLoginDetail.lastName;
      }
      if (!!userLoginDetail.parentUid) {
        let parentUserDetail = await this.dm.user.getUserBUName(userLoginDetail.parentUid);
        userLoginDetail.parentUserDetail = parentUserDetail;
      }
      let providers = await this.dm.addDoctorsDomain.getDoctorsDetailsWithPage();
      return <LayoutComponent hideHeader={false} children={<PractUploadBillsScreen providers={providers} userLoginDetail={userLoginDetail} />}/>;
    }
  }

  async uploadedBillAction({ uid, type = "N" }) {
    if (!!uid && uid !== "undefined")
      return <LayoutComponent hideHeader={true} children={<ExternalScannerScreen uid={uid} type={type} />}/>;
    else this.nav.to("pchLogin");
  }

  async pCHGlobalUploadBillAction({ redirect_url, access_token }) {
    redirect_url = decodeURIComponent(redirect_url);
    let isvalid = await CommonUtility.isValidUrl(redirect_url);
    if (!!isvalid && !!redirect_url && redirect_url !== "undefined"
      && !!access_token && access_token !== "undefined") {
      await AsyncStorage.setItem("REDIRECT_URI", redirect_url);
      await AsyncStorage.setItem("CLIENT_ID", CLAIM_CLIENT_ID);
      await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN, access_token);
      let res = await this.dm.kcAPI.validatePCHGlobalLogin(access_token, LOGIN_TYPE.PCHGLOBAL);
      if (!!res && typeof res === "boolean") {
        this.nav.to("uploadBillsRoute");
        return false;
      } else {
        this.dm.commonDomain.showToastr("error", res.error);
        Linking.LinkingURL(`${redirect_url}?errorMessage=${res.error}&access_token=${access_token}`, "_self")
        return false;
      }
    } else {
      this.dm.commonDomain.showToastr("error", CommonString.InvalidAccess);
      if (isvalid)
        Linking.LinkingURL(`${redirect_url}?errorMessage=${CommonString.InvalidAccess}&access_token=${access_token}`, "_self")
      else
        this.nav.to("pchLogin");
      return false;
    }
  }

  async externalBillAction(type, appointment, redirect_url, access_token) {
    this.dm.userSession.setState({
      isLoggedIn: true,
      leftMenuToggle: false,
      isUberDocs: true,
      isExternalLogin: true
    })
    redirect_url = decodeURIComponent(redirect_url);
    if (!!appointment && appointment !== "undefined" &&
      !!redirect_url && redirect_url !== "undefined"
      && !!access_token && access_token !== "undefined") {
      await AsyncStorage.setItem("REDIRECT_URI", redirect_url);
      await AsyncStorage.setItem("appointment", appointment);
      await AsyncStorage.setItem("CLIENT_ID", CLAIM_CLIENT_ID);
      await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN, access_token);
      let res = await this.dm.kcAPI.validateExternalLogin(type, appointment, access_token);
      if (!!res && typeof res === "boolean") {
        if (["u", "v"].includes(type)) {
          let bill = await this.dm.uploadBillDomain.fetchUploadedBill(appointment);
          const userLoginDetail = await this.dm.rest.getUserWithDetails();
          if (!!bill) {
            // if (type === "u") {
            if (["Draft", "New"].includes(bill.billStatus)) {
              if (!!bill.notes)
                bill.notes = await this.dm.userLoginDomain.getValue(bill.notes);
              let insurances = await this.dm.addInsuranceDomain.getInsuranceDetailsWithPage();
              return <LayoutComponent hideHeader={false} children={<PatientUploadBillsScreen insurances={insurances} userLoginDetail={userLoginDetail} draftBill={bill} redirect_url={redirect_url} access_token={access_token} appointment={appointment} />}/>;
            }
            else //if (type === "v")
              return <LayoutComponent hideHeader={false} children={<PatientViewBillScreen uploadBill={bill} userLoginDetail={userLoginDetail} />}/>;
          } else {
            this.dm.commonDomain.showToastr("error", res.error);
            Linking.LinkingURL(`${redirect_url}&appointment=${appointment}&errorMessage=${res.error}&access_token=${access_token}`, "_self")
            return false;
          }
        }
        else if (type === "h") {
          let selectedBlock = "All";
          this.dm.uploadBillDomain.setState({
            currentPage: 1
          })
          let bills = await this.dm.uploadBillDomain.getUploadedBillsWithPage(selectedBlock);
          return <LayoutComponent hideHeader={false} children={<PCHDashboardScreen selectedBlock={selectedBlock} filterBills={bills} userType={USER_TYPE.PCH_PATIENT} isUberDoc={true} />}/>;
        }
      } else {
        this.dm.commonDomain.showToastr("error", res.error);
        Linking.LinkingURL(`${redirect_url}&appointment=${appointment}&errorMessage=${res.error}&access_token=${access_token}`, "_self")
        return false;
      }
    }
    else {
      let errorMessage = CommonString.InvalidUberDocToken;
      this.dm.commonDomain.showToastr("error", errorMessage);
      Linking.LinkingURL(`${redirect_url}&appointment=${appointment}&errorMessage=${errorMessage}&access_token=${access_token}`, "_self")
      return false;
    }
  }
  async externalUploadBillAction({ appointment, redirect_url, access_token }) {
    return await this.externalBillAction('u', appointment, redirect_url, access_token);
  }

  async externalViewBillAction({ appointment, redirect_url, access_token }) {
    return await this.externalBillAction('v', appointment, redirect_url, access_token);
  }

  async externalHistroyBillAction({ appointment, redirect_url, access_token }) {
    return await this.externalBillAction('h', appointment, redirect_url, access_token);
  }

  async managePaymentScreenAction() {
    await this.validateSubscription();
    const userLoginDetail = await this.dm.rest.getUser();
    let insurances = await this.dm.addInsuranceDomain.getInsuranceDetailsWithPage();
    let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
    if (userType === USER_TYPE.PCH_PATIENT) {
      return <LayoutComponent hideHeader={false} children={<ManagePaymentScreen insurances={insurances} userType={userType} userLoginDetail={userLoginDetail} />}/>;
    }
  }

  async viewManagePaymentAction({ uid }) {
    if (!!uid) {
      const userLoginDetail = await this.dm.rest.getUser();
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      const bill = !!(await AsyncStorage.getItem("viewPayment")) ? JSON.parse(await AsyncStorage.getItem("viewPayment")) : {};
      if (!!bill && bill.uid === uid) {
        AsyncStorage.removeItem("viewPayment");
        return <LayoutComponent hideHeader={false} children={<ViewManagePaymentScreen billUid={uid} uploadedBill={bill} userType={userType} userLoginDetail={userLoginDetail} />}/>;
      } else this.nav.back();
    } else this.nav.back();
  }

  async applyManagePaymentAction({ uid }) {
    if (!!uid) {
      const userLoginDetail = await this.dm.rest.getUser();
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      const bill = !!(await AsyncStorage.getItem("applyPayment")) ? JSON.parse(await AsyncStorage.getItem("applyPayment")) : {};
      if (!!bill && bill.uid === uid) {
        AsyncStorage.removeItem("applyPayment");
        return <LayoutComponent hideHeader={false} children={<ApplyManagePaymentScreen billUid={uid} uploadedBill={bill} userType={userType} userLoginDetail={userLoginDetail} />}/>;
      } else this.nav.back();
    } else this.nav.back();
  }

  async viewBillDetailsAction({ uid }) {
    await this.validateSubscription();
    const { InSideNavigation } = this.dm.uploadBillDomain.state;
    if (!!uid) {
      const userLoginDetail = await this.dm.rest.getUser();
      let bill = await this.dm.uploadBillDomain.fetchUploadedBill(uid);
      if (!!bill) {
        if (!!userLoginDetail && userLoginDetail.userType === USER_TYPE.PCH_PATIENT && !!!BILL_STATUS[bill.billStatus]) {
          await this.dm.addInsuranceDomain.setState({ currentPage: 1 });
          let insurances = await this.dm.addInsuranceDomain.getInsuranceDetailsWithPage(null, 50);
          if (["Draft"].includes(bill.billStatus)) {
            if (!!bill.notes)
              bill.notes = await this.dm.userLoginDomain.getValue(bill.notes);
            return <LayoutComponent hideHeader={false} children={<PatientUploadBillsScreen insurances={insurances} userLoginDetail={userLoginDetail} draftBill={bill} />}/>;
          }
          else
            return <LayoutComponent hideHeader={false} children={<PatientBillClaimScreen uploadBill={bill} insurances={insurances} userLoginDetail={userLoginDetail} />}/>;
        }
        else
          return <LayoutComponent hideHeader={true} children={<PatientViewBillScreen uploadBill={bill} userLoginDetail={userLoginDetail} />}/>;
      } else {
        this.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        if (!!InSideNavigation) {
          this.nav.back();
        } else return <Redirect to="pchLogin" />;
      }
    } else {
      if (!!InSideNavigation) {
        this.nav.back();
      } else return <Redirect to="pchLogin" />;
    }
  }
  async manageProviderAction({ mode }) {
    await this.validateSubscription();
    let appointment = await AsyncStorage.getItem("appointment");
    if (!!appointment)
      this.dm.userSession.setState({
        leftMenuToggle: false,
        isUberDocs: true,
      })
    let providerItems = [];
    if (!!mode) {
      providerItems = [];
    }
    else {
      await this.dm.addDoctorsDomain.setState({ currentPage: 1 });
      providerItems = await this.dm.addDoctorsDomain.getDoctorsDetailsWithPage();
    }
    const userLoginDetail = await this.dm.rest.getUser();
    if (!!userLoginDetail.parentUid) {
      let parentUserDetail = await this.dm.user.getUserBUName(userLoginDetail.parentUid);
      userLoginDetail.parentUserDetail = parentUserDetail;
    }
    return <LayoutComponent hideHeader={true} children={<ProviderListScreen defaultMode={mode} providerItems={providerItems} userLoginDetail={userLoginDetail} />}/>;
  }

  async insuranceListAction({ mode }) {
    try {
      this.dm.userSession.setState({ isLoading: true });
      let queryString = this.nav._r14.navigation._currRoute._data.query;
      if (!!queryString && !!queryString.redirect_status && !!queryString._inq) {
        let payload = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
        if (!!payload) {
          await AsyncStorage.removeItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
          payload = JSON.parse(payload);
          this.dm.addInsuranceDomain.updateEligibilityLog(queryString.insId, payload.paymentMode, payload.paymentIntentId);
        }
        let access_token = await this.dm.userSession.getAccessToken();
        Linking.LinkingURL(`${queryString._inq.replace(/ /g, '+')}&access_token=${access_token}`, "_self");
        return false;
      }
      else if (!!queryString && !!queryString.insId && !!queryString.status) {
        this.dm.addInsuranceDomain.updateEligibilityLog(queryString.insId, queryString.requestFor, queryString.status);
      }
    } catch (error) {

    }
    this.dm.userSession.setState({ isLoading: false });
    await this.validateSubscription();
    let appointment = await AsyncStorage.getItem("appointment");
    if (!!appointment)
      this.dm.userSession.setState({
        leftMenuToggle: false,
        isUberDocs: true,
      })

    let insuranceItems = [];
    const userLoginDetail = await this.dm.rest.getUser();

    if (!!mode) {
      insuranceItems = [];
    } else {
      await this.dm.addInsuranceDomain.setState({ currentPage: 1 });
      insuranceItems = await this.dm.addInsuranceDomain.getInsuranceDetailsWithPage();
    }

    return <LayoutComponent hideHeader={true} children={<PatientInsuranceScreen userLoginDetail={userLoginDetail} defaultMode={mode} insuranceItems={insuranceItems} />}/>;
  }

  async submittedSmbReceipt() {
    let params = {};
    const smbBill = !!(await AsyncStorage.getItem("smbReceipts")) ? JSON.parse(await AsyncStorage.getItem("smbReceipts")) : {};

    if (Platform.OS === "web") {
      params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
    } else {
      params = { payment_intent: smbBill.paymentIntentId, payment_intent_client_secret: smbBill.ephemeralKey, redirect_status: "succeed" }
    }
    if (!!smbBill && params && params.payment_intent && params.payment_intent_client_secret && params.redirect_status) {
      const userLoginDetail = await this.dm.rest.getUser();
      return <LayoutComponent hideHeader={false} children={<SubmittedSmbReceiptScreen uploadBill={smbBill.length ? smbBill[0] : {}} user={userLoginDetail} userType={userLoginDetail.userType} transactionId={params.payment_intent} referenceNo={params.payment_intent_client_secret} />}/>;
    } this.nav.to("makePaymentsRoute");
  }

  async submittedReceipt({ uid }) {
    if (!!uid) {
      const userLoginDetail = await this.dm.rest.getUser();
      const bill = !!(await AsyncStorage.getItem("receipts")) ? JSON.parse(await AsyncStorage.getItem("receipts")) : {};
      if (!!bill && bill.uid === uid) {
        // AsyncStorage.removeItem("receipts");
        return <LayoutComponent hideHeader={true} children={<SubmittedReceiptScreen uploadBill={bill} userType={userLoginDetail.userType} />}/>;
      }
      else this.nav.to("uploadBillsRoute");
    }
    else this.nav.to("uploadBillsRoute");
  }

  async manageUsersAction() {
    await this.validateSubscription();
    const userLoginDetail = await this.dm.rest.getUser();
    let userUID = !!userLoginDetail ? userLoginDetail.uid : null;
    if (userLoginDetail.userType === USER_TYPE.PCH_PROVIDER) {
      this.dm.userSession.setState({
        pchRegistrationTypeProvider: true,
        pchRegistrationTypePatient: false,
      });
    } else if (userLoginDetail.userType === USER_TYPE.PCH_PATIENT) {
      this.dm.userSession.setState({
        pchRegistrationTypeProvider: false,
        pchRegistrationTypePatient: true,
      });
    }
    let previousRoute = this.dm.dashboard.state.oldRoute
    let currentPage = this.dm.user.state.currentPage;
    await this.dm.user.setState({ currentPage: previousRoute === "editUsersRoute" ? currentPage : 1 });
    await this.dm.user.getUserDetailsListWithPage(userUID);
    return <LayoutComponent hideHeader={true} children={<AddUsers parentUID={userUID} userLoginDetail={userLoginDetail} />}/>;
  }

  async userEditAction({ uid }) {
    await this.validateSubscription();
    const userLoginDetail = await this.dm.userLoginDomain.getLoginDetail(uid);
    const userBUDetails = await this.dm.user.getUserBUName(uid, "uid firstName lastName userDetailUid payerRef buId buName billerId billerRef billerName country addressLine1 addressLine2 state town postCode countryObject{label value} stateObject{label value} cityObject{label value}");
    return <LayoutComponent hideHeader={false} children={<PCHProfileViewScreen profile={userLoginDetail} profileActionType={"edit"} countryList={[]} screen={"addUser"} userBUDetails={[userBUDetails]} clientId={CLAIM_CLIENT_ID} />}/>;
  }

  async pchTermsAndconditionsAction() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} children={<PCHTermsAndconditions />} />;
  }

  async pchPrivacyPolicy() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} children={<PCHPrivacyPolicy />} />;

  }
  async termsAndconditionsAction() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} children={<PCHTermsAndconditions />} />;
  }
  async privacyPolicy() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} children={<PCHPrivacyPolicy />} />;
  }
  async pchuserterms() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} children={<PCHUserTerms />} />;
  }
  async accessServicesAgreement() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} children={<AccessServicesAgreement />} />;
  }
  async termsAndConditionsScreen() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} children={<TermsAndConditionsScreen />} />;
  }
  async helpScreenAction() {
    await this.validateSubscription();
    const userLoginDetail = await this.dm.rest.getUser();
    return <LayoutComponent hideHeader={false} isAuthenticated={true} children={<HelpScreen userType={userLoginDetail.userType} />}/>;
  }

  async pchSignupConfirmation() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} children={<SignupConfirmationScreen />} />;
  }

  async helps() {
    return <LayoutComponent hideHeader={false} isAuthenticated={false} children={<HelpScreen />}/>;
  }

  async navPCHGlobalAction() {
    await this.logout();
    return false;
  }

  async moopAction() {
    await this.validateSubscription();
    let insurances = await this.dm.addInsuranceDomain.getInsuranceDetailsWithPage();
    let data = [];
    if (insurances && insurances.length > 0)
      data = await this.dm.moop.getMoopData(insurances[0].uid);

    let user = await this.dm.rest.getUserWithDetails();
    return <LayoutComponent hideHeader={false} children={<MOOPScreen data={data} user={`${user.firstName}${!!user.lastName ? " " + user.lastName : ''}`} userLoginDetail={user} insurances={insurances} />}/>;
  }

  async unauthorize({ content }) {
    return <KCAuthSessionComponent source="login" signInLocation={USER_TYPE.XBP} message={CommonString.InvalidAccess} clientId={KC_CLIENT_ID} />
    // return <ErrorScreen content={!!content ? content : "Invalid Access Please Contact Administrator."}/>
  }
  /* PCH Actions End */

  async sepaBillAction() {
    const { sepaBlockStatus = "NEW", resultsPerPage } = this.dm.sepa.state;
    this.dm.sepa.setState({ currentPage: 1 })
    const payload = {
      "search": sepaBlockStatus.toUpperCase(),
      "page": 1, "resultsPerPage": resultsPerPage, "sort": [{ "field": "billDate", "order": "ASC" }]
    }
    await this.dm.sepa.getSPABillsList(payload);
    return <LayoutComponent hideHeader={true} children={<SEPABillsScreen />}/>;
  }

  async sepaBillDetail({ resourceId }) {
    const userDetails = await this.dm.rest.getUserWithDetails();
    let b = this.dm.sepa.state.c_bill;
    if (b === resourceId) {
      let bill = await this.dm.sepa.getBill(resourceId);
      let billDetails = bill && bill.sepaBill;
      let installmentDetails = bill && bill.sepaBillDetail;

      if (!!installmentDetails && installmentDetails.length > 0) {
        installmentDetails[0].messages = [];
        if (billDetails.selectedPaymentType.indexOf('OT/') > -1 || billDetails.billStatus !== "NEW") {
          let data = await this.dm.sepa.getSEPABillMessage(billDetails.uid);
          installmentDetails[0].messages = data;
        }
        return <LayoutComponent hideHeader={false} children={<SEPABillDetailsScreen userDetails={userDetails} billDetails={billDetails} installmentDetails={installmentDetails} />}/>;
      }
      else
        this.nav.to("login");
    }
    else this.nav.to("dashboard");
  }

  async paymentReceipt() {
    let params = {};
    this.dm.sepa.setState({ c_bill: null });
    const response = !!(await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS)) ? JSON.parse(await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS)) : null;
    if (Platform.OS === "web") {
      params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
    } else {
      params = { payment_intent: response.paymentIntentId, payment_intent_client_secret: response.ephemeralKey, redirect_status: "succeed" }
    }

    if (!!response && params && params.payment_intent && params.payment_intent_client_secret && params.redirect_status) {
      const userLoginDetail = await this.dm.rest.getUser();
      let ind = response.installmentDetails.findIndex((x) => x.uid === response.sepaBillDetail.uid);
      if (ind > -1) {
        response.sepaBillDetail.billStatus = "PAID"
        response.installmentDetails[ind].amountPaid = response.sepaBillDetail.billAmount; 
      }
      return <LayoutComponent hideHeader={false} children={<PaymentReceiptScreen response={response} user={userLoginDetail} userType={userLoginDetail.userType} transactionId={params.payment_intent} referenceNo={params.payment_intent_client_secret} />}/>;
    }
    else this.nav.to("dashboard");
  }

  async clientPOC() {
    return <LayoutComponent hideHeader={true} isAuthenticated={false} hideCopyright={true} children={<ClientPOCScreen />}/>;
  }

  async validateSubscription(action) {
    return true;
    // actual subscription plan validation
    // const plan = await this.dm.achPaymentDomain.validatePlan(!!action ? action : this.nav.route.name);
    // if (!plan || !!!plan.success) {
    //   this.dm.dashboard.setState({ isSubscriptionValid: false });
    //   this.nav.to("subscriptionRoute");
    //   return false;
    // }
    // else
    //   return true;
  }

  async appsubscription() {
    let queryString = this.nav._r14.navigation._currRoute._data.query;
    const userLoginDetail = await this.dm.rest.getUser();

    let planId = userLoginDetail.planUid || "";
    let sessionId = "";
    let status = "";
    if (!!queryString && queryString.act === "success") {
      sessionId = queryString.session_id;
      status = queryString.act;
      let u_plan = await this.dm.achPaymentDomain.updateUserPlan(sessionId);
      planId = u_plan.planUid;
      userLoginDetail.planUid = u_plan.planUid;
      userLoginDetail.isSubscriptionExpired = u_plan.isSubscriptionExpired;
      userLoginDetail.subscriptionUid = u_plan.subscriptionUid;
      this.dm.userSession.setState({
        userLoginDetail: userLoginDetail
      });
      await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_PROFILE, RSAencryption.EnctyptString(JSON.stringify(userLoginDetail)));
    }
    else if (!!queryString && queryString.act === "cancel") {
      status = queryString.act;
    }

    let packages = await this.dm.achPaymentDomain.getPackage();
    return <LayoutComponent children={<SubscriptionComponent packages={packages} planId={planId} sessionId={sessionId} status={status} />} />
  }

  async serviceSubscription() {
    let queryString = this.nav._r14.navigation._currRoute._data.query;
    const userLoginDetail = await this.dm.rest.getUser();

    let planId = userLoginDetail.planUid || "";
    let sessionId = "";
    let status = "";
    if (!!queryString && queryString.act === "success") {
      sessionId = queryString.session_id;
      status = queryString.act;
      let u_plan = await this.dm.achPaymentDomain.updateUserPlan(sessionId);
      planId = u_plan.planUid;
      userLoginDetail.planUid = u_plan.planUid;
      userLoginDetail.isSubscriptionExpired = u_plan.isSubscriptionExpired;
      userLoginDetail.subscriptionUid = u_plan.subscriptionUid;
      this.dm.userSession.setState({
        userLoginDetail: userLoginDetail
      });
      await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_PROFILE, RSAencryption.EnctyptString(JSON.stringify(userLoginDetail)));
    }
    else if (!!queryString && queryString.act === "cancel") {
      status = queryString.act;
    }

    let packages = await this.dm.achPaymentDomain.serviceModules();
    return <LayoutComponent children={<ServiceSubscriptionComponent packages={packages} planId={planId} sessionId={sessionId} status={status} />} />
  }

  async nonRegisterAction() {
    this.dm.userSession.setState({
      isLoggedIn: true,
      leftMenuToggle: false,
      isExternalLogin: true,
    });
    let userLoginDetail = await this.dm.rest.getUserWithDetails();
    let insurances = [];
    if (!userLoginDetail || !userLoginDetail.uid) {
      userLoginDetail = null;
    }
    else {
      insurances = await this.dm.addInsuranceDomain.getInsuranceDetailsWithPage();
    }
    return <LayoutComponent hideHeader={true} children={<PatientUploadBillsScreen insurances={insurances} userLoginDetail={userLoginDetail} />}/>;
  }

  async nonregisterReceipt({ action }) {
    let hasCancel = (action === "cancelled");
    let message = "Thank you to use XBP Claims. Please click below to continue..."
    const bill = !!(await AsyncStorage.getItem("receipts")) ? JSON.parse(await AsyncStorage.getItem("receipts")) : {};
    await this.dm.dashboard.resetDashboardDomain();
    this.dm.userSession.setState({
      leftMenuToggle: false,
      isExternalLogin: true,
    });
    if (!!bill && bill.uid === action) {
      AsyncStorage.removeItem("receipts");
      message = "Thank you to use XBP Claims. Your claim reference " + bill.uniqueCode + " received successfully."
    }
    else if (!!!hasCancel)
      this.nav.to("directSubmission");

    return <LayoutComponent hideTextHeader={true} children={<ReceiptScreen message={message} hasError={hasCancel} />} />
  }

  async manageClientAction() {
    return <LayoutComponent hideHeader={false} children={<ClientDataTable />} />
  }

  async addClientAction() {
    return <LayoutComponent hideHeader={false} children={<AddEditClientComponent mode={"add"} />} />
  }

  async editClientAction() {
    let { client } = this.dm.client.state;
    if (!!client && client.uid)
      return <LayoutComponent hideHeader={false} children={<AddEditClientComponent mode={"edit"} client={client} />} />
    else
      this.nav.to("clientListRoute")
  }
  async viewClientAction() {
    let { client } = this.dm.client.state;
    if (!!client && client.uid)
      return <LayoutComponent hideHeader={false} children={<AddEditClientComponent mode={"view"} client={client} />} />
    else
      this.nav.to("clientListRoute")
  }

  async checkoutPayment({ data }) {
    const userDetails = await this.dm.rest.getUserWithDetails();
    let payload = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
    if (!!userDetails && !!payload) {
      payload = RSAencryption.decryptDataByATOB(payload);
      await AsyncStorage.removeItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
      payload = JSON.parse(payload);
      let stripePayload = {
        ...payload,
        ...{
          billingaddress: {
            ...payload.billingaddress,
            user_address1: userDetails.addressLine1 || "",
            user_address2: userDetails.addressLine2 || "",
            user_zip: userDetails.postCode || "",
            user_city: (userDetails.cityObject && userDetails.cityObject.label ? userDetails.cityObject.label : userDetails.town || ""),
            user_state: (userDetails.stateObject && userDetails.stateObject.label ? userDetails.stateObject.label : userDetails.state || ""),
          }
        },
      };
      stripePayload.data = RSAencryption.decryptDataByATOB(data);
      return <LayoutComponent hideHeader={false} children={<CheckoutSessionComponent userDetails={userDetails} paymentPayload={stripePayload} />} />
    }
    else this.nav.back();
  }
}