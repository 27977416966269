import UserDomain from "../domains/UserDomain";
import UserSessionDomain from "../domains/UserSessionDomain";
import ApiDomain from "../domains/APIDomain";
import DashboardDomain from "../domains/DashboardDomain";
import BillDetailsDomain from "../domains/BillDetailsDomain";
import FilterDomain from "../domains/FilterDomain";
import CommonUtilityDomain from "../domains/CommonUtilityDomain";
import BillerDirectoryDomain from "../domains/BillerDirectoryDomain";
import SimpleMessageDomain from "../domains/SimpleMessageDomain";
import CommonQueryDomain from "../domains/CommonQueryDomain";
import UserLoginDomain from "../domains/UserLoginDomain";
import UserSearchHistoryDomain from "../domains/UserSearchHistoryDomain";
import UserRegistrationDomain from "../domains/UserRegistrationDomain";
import MiscellaneousDomain from "../domains/MiscellaneousDomain";
import MyPreferenceDomain from "../domains/MyPreferenceDomain";
import NotificationDomain from "../domains/NotificationDomain";
import BillRequestDomain from "../domains/BillRequestDomain"
import TextToPayDomain from "../domains/TextToPayDomain"
import ACHPaymentDomain from "../domains/ACHPaymentDomain";
import KeyCloakAPIDomain from "../domains/KeyCloakAPIDomain";
import ClientDomain from "../domains/ClientDomain";
import SEPARTPDomain from "../domains/SEPA/SEPARTPDomain";

// PCH Domains
import UploadBillsDomain from "../domains/PCH/UploadBillsDomain";
import AddDoctorsDomain from "../domains/PCH/AddDoctorsDomain";
import AddInsuranceDomain from "../domains/PCH/AddInsuranceDomain";
import MOOPDomain from "../domains/PCH/MOOPDomain";
import ClaimPaymentDomain from "../domains/PCH/ClaimPaymentDomain";

// SMB Domains
import SMBAPIDomain from "../domains/SMB/SMBApiDomain";

const domain = {
  domain: {
    user: UserDomain,
    userSession: UserSessionDomain,
    rest: ApiDomain,
    dashboard: DashboardDomain,
    billDetails: BillDetailsDomain,
    filterDomain: FilterDomain,
    commonDomain: CommonUtilityDomain,
    billerDirectoryDomain: BillerDirectoryDomain,
    simpleMessage: SimpleMessageDomain,
    queryDomain: CommonQueryDomain,
    userLoginDomain: UserLoginDomain,
    userSearchDomain: UserSearchHistoryDomain,
    userRegistration: UserRegistrationDomain,
    miscellaneousDomain: MiscellaneousDomain,
    myPreferenceDomain: MyPreferenceDomain,
    notificationDomain: NotificationDomain,
    addInsuranceDomain: AddInsuranceDomain,
    uploadBillDomain: UploadBillsDomain,
    addDoctorsDomain: AddDoctorsDomain,
    billRequest: BillRequestDomain,
    textToPay: TextToPayDomain,
    achPaymentDomain: ACHPaymentDomain,
    kcAPI: KeyCloakAPIDomain,
    smbApi: SMBAPIDomain,
    moop: MOOPDomain,
    claimPaymentDomain: ClaimPaymentDomain,
    sepa: SEPARTPDomain,
    client: ClientDomain
  },
  ui: {},
};

export default domain;