import React, { Component } from "react";
import R14, { StyleSheet, View, Text, Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
export default R14.connect(
  class TooltipComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        toolTipToggle: false,
      }
      this.timer = null
    }

    onPressToolTip = () => {
      this.setState({
        toolTipToggle: !this.state.toolTipToggle,
      });
      this.props.app.dm.userSession.setState({
          isTooltipOpen : true,
      })

      this.timer = setTimeout(() => {
        this.setState({
          toolTipToggle: false,
        });
        this.props.app.dm.userSession.setState({
          isTooltipOpen : false,
        })
      }, 5000);
    }

    componentWillUnmount() {
      if (this.timer)
        clearTimeout(this.timer)
    }


    render() {
      const { toolTipMessage, toolTipMessageCss, priorityInfoTooltipStyle, verificationTipCss, verificationStatus, source, isMobileDevice, arrowHolderStyle, customArrowPosition, row, smallPriorityInfoTooltipStyle, bigPriorityInfoTooltipStyle, isTop3Entry, justifyContentEnd} = this.props;
      const { toolTipToggle } = this.state;
      return (
        <View style={[styles.priorityInfoTooltipHolder, customCommonStyles.customToolTipHolder, toolTipMessageCss, verificationTipCss]}>
          {/* Elapsed, Declined, Invalid, Blocked */}
          <Touchable onPress={() => ['Elapsed', 'Declined', 'Invalid', 'Blocked'].includes(verificationStatus) || source !== "mybank" ? this.onPressToolTip() : null} style={[commonStyles.flex1]}>
            <View style={[styles.infoIconHolder, commonStyles.flex1, commonStyles.flexDirectionRow, !!verificationStatus ? commonStyles.justifyContentFlexStart : null, justifyContentEnd]}>
            {toolTipToggle ? (
              <View style={[styles.arrowHolder, arrowHolderStyle]}>
                  <View style={[styles.priorityInfoTooltipArrow, customCommonStyles.customArrowPositionPasswordInfo, customArrowPosition]}></View>
              </View>) : null}
              {!!verificationStatus || !!verificationTipCss ? (
                <View>
                  <Text style={[commonStyles.darkGrayColor, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.tableValue, commonStyles.dBlock]}>
                    {!!isMobileDevice && source === "mybank" ? 'Status: ': ''}
                    {verificationStatus}
                  </Text>
                </View>
              ) : (
                <View style={[styles.infoIcon]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>
                    ?
                  </Text>
                </View>
              )}
            </View>

          </Touchable>
          {toolTipToggle ? (
            <View style={[!!isTop3Entry ? null : styles.bottom0, styles.priorityInfoTooltip, priorityInfoTooltipStyle, row && row < 1 ? styles.tooltipRowItemStyle : null, toolTipMessage.length < 20 ? smallPriorityInfoTooltipStyle : bigPriorityInfoTooltipStyle ]}>
              <Text style={[commonFontStyles.workSans, commonStyles.smallHeading]}>
                {toolTipMessage}
              </Text>

            </View>
          ) : null}
          {/* <View style={[styles.priorityInfoTooltip, toolTipToggle ? commonStyles.dBlock : commonStyles.displayNone]}>
            <Text style={[commonFontStyles.workSans, commonStyles.smallHeading]}>
              {toolTipMessage}
            </Text>
            <Text style={[styles.priorityInfoTooltipArrow]}></Text>
          </View> */}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({

  priorityInfoTooltipHolder: {
    
  },
  priorityInfoTooltip: {
    position: "absolute",
    backgroundColor: "#37c2ef",
    color: "#fff",
    // bottom: 0,
    right: 25,
    zIndex: 999999,
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    minWidth: 220,
    // right: 0,
  },
  priorityInfoTooltipArrow: {
    width: 0,
    height: 0,
    // position: "absolute",
  },
  infoIconHolder: {
    position: "relative",
    minWidth: 25,
    justifyContent: "space-between",
  },
  infoIcon: {
    height: 20,
    minHeight: 20,
    minWidth: 20,
    maxWidth: 20,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#a1a4a8",
    borderRadius: 15,
    flex: 1,
    flexGrow: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  arrowHolder: {
    width: 5,
    minWidth: 5,
    height: 10,
    position: "relative",
    top: 5,
  },
  tooltipRowItemStyle: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          bottom: -60
        }
      }
    }
  },
  bottom0: {
    bottom: 0
  }
});
