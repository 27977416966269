import React from "react";
import { View } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { Line } from "react-chartjs-2";

export default class LineChartComponent extends React.Component {
    render() {
        const { chartData, chartText, customContainer} = this.props;
        return (
            <View style={[commonStyles.chartContainer, customContainer]}>
                <Line
                    data={chartData}
                    options={{
                        plugins: {
                            title: {
                                display: !!chartText,
                                text: chartText
                            },
                            legend: {
                                display: false
                            }
                        }
                    }}
                />
            </View>
        )
    }
}