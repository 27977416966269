import { StyleSheet, Colors, Platform } from "../../core";
const responsiveTableStyle = StyleSheet.create({
 // Responsive table components
  table: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    backgroundColor: "#fff",
    paddingTop: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    position:"relative",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          borderWidth: 0,
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 0,
        };
    },
  },
  userIconDiv: {
    width: 25,
    minWidth: 25,
    maxWidth: 25,
    height: 25,
    maxHeight: 25,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    backgroundColor: "#e7e7e7",
    position: "relative",
    marginLeft: 16,
    marginTop: 2,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024)
        return {
          display: "none",
        };
    },
  },
  billName: {
    marginRight: 15,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024)
        return {
          marginLeft: 0,
        };
    },
  },
  decline: {
    height: 15,
    minWidth: 18,
    marginTop: 3,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 15,
  },
  cardSubHeadingText: {
    color: "#444444",
    display: "block",
    fontSize: 14,
  },
  paynowBtn: {
    backgroundColor: "#d71996",
    color: "#fff",
    // paddingTop: 5,
    // paddingRight: 10,
    // paddingBottom: 5,
    // paddingLeft: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    width: 95,
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 500,
    height: 20,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  paynowBtnInnerWrap: {
    backgroundColor: "#1277d9",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    width: "100%",
    maxHeight: 20,
    minHeight: 20,
    textAlign: "center",
    // paddingTop: 5,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  thead: {
    display: "flex",
    flexDirection: "row",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderBottom: 0,
    // paddingTop: 3,
    // paddingBottom: 3,
    position: "relative",
    zIndex: 0,
    backgroundColor: "#DADADA",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    minHeight: 25,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  thAction: {},
  myBankDirectoryTr: {
    screen: ({ width }) => {
      if (width <= 991){
        return {
          paddingLeft: 16,
        };
      }
    }
  },
  tr: {
    paddingTop: 0,
    paddingBottom: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    alignItems: "center",
    minHeight: 30,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          alignItems: "flex-start",
          paddingTop: 10,
          paddingBottom: 10,
          width: "100%",
          borderLeftWidth: 0,
          borderRightWidth: 0,
        };
    },
  },
  billerName: {
    width: "30.2%",
    paddingRight: 10,
    alignItems: "center",
    marginLeft: 4,
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "28%",
        };
      }
      if (width >= 1538 && width <= 1600) {
        return {
          width: "28.8%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "29%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "43%",
        };
      }
      if (width >= 992) {
        return {
          marginLeft: 0,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          alignItems: "flex-start",
        };
      }
    },
  },
  billerDetails: {
    width: "57%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          // alignItems: "self-start",
          width: "100%",
        };
      }
      if (width <= 991) {
        return {
          flexDirection: "column",
          width: "100%",
          // alignItems: "baseline",
          // marginLeft: 5,
        };
      }
    },
  },
  thLeftSection: {
    width: "54%",
    paddingTop: 0
  },
  tdLeftSection: {
    width: "51%",
    paddingLeft: 15,
    // alignItems: "center",
    screen: ({ width }) => {
      if (width <= 359) {
        return {
          width: "34%",
          flexDirection: "column",
        };
      }
      if (width <= 991) {
        return {
          width: "34%",
          alignItems: "flex-start",
          flexDirection: "column",
        };
      }
      if (width >= 359 && width <= 991) {
        return {
          width: "45%",
          flexDirection: "column",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "56%",
        };
      }
    },
  },
  tdRightSection: {
    width: "30%",
    justifyContent: "space-between",
    paddingTop:5,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 16,
          flexDirection: "column",
          textAlign: "right",
          alignItems: "flex-end",
          width: "37%",
        };
      if (width >= 992 && width <= 1024) {
        return {
          flexWrap: "wrap",
        };
      }
    },
  },
  
  thRightSection: {
    paddingTop: 0
  },
  bookedMarkSpacing: {
    marginLeft: 9,
  },
  billerSubj: {
    width: "31%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "30%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "30%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "30.2%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "25.7%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "31%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "32%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 85,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  makePayBillerSubj: {
    width: "31%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "30%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "29.5%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "30.2%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "25.7%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "31%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "30.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 87,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  makePayTdBillerSubj: {
    width: "28%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 66,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  tdBillerSubj: {
    width: "29%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 68,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  leftMenuOfftdBillerSubj: {
    width: "21%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 90,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  dateAndTime: {
    width: "27.5%",
    // marginLeft: 9,
    marginRight: 9,
    screen: ({ width }) => {
      if (width >= 1681 && width >= 1920) {
        return {
          width: "37.2%",
        };
      }
      if (width >= 1601 && width >= 1680) {
        return {
          width: "37.2%",
        };
      }
      if (width >= 1537 && width >= 1600) {
        return {
          width: "33.2%",
        };
      }
      if (width >= 1441 && width >= 1536) {
        return {
          width: "25%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "27.33%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "21.5%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  leftmenuOnTdDateAndTime: {
    screen: ({ width }) => {
      if (width >= 1441 && width <= 1600) {
        return {
          width: "31.33%",
        };
      }
    }
  },
  tdDateAndTime: {
    width: "27.33%",
    marginLeft: 15,
    marginRight: 15,
    screen: ({ width }) => {
      if (width >= 1441 && width <= 1600) {
        return {
          width: "32.33%",
        };
      }
      if (width <= 1024 && width >= 992) {
        return {
          width: 66,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
        };
      }
    },
  },
  dueOn: {
    width: "20%",
    screen: ({ width }) => {
      if (width >= 1441 && width <= 1600) {
        return {
          width: "23.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 105,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  makePayTdDueIn: {
    width: "25%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "25%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  dashboardDueOn: {
    width: "20%",
    screen: ({ width }) => {
      if (width >= 1537 && width <= 1600) {
        return {
          width: "25%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "23%",
        };
      }
    },
  },
  billStatus: {
    width: "35%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "100%",
          // marginTop: -10,
          order: 3,
        };
      }
      if (width <= 992) {
        return {
          width: "100%",
          textAlign: "right",
        };
      }
    },
  },
  billAmount: {
    width: "47%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
          marginBottom: 10,
        };
    },
  },
  thBillAmount: {
    width: "44%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "43%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "43%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "44%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "44%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "43%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "43%",
        };
      }
      if (width <= 991)
        return {
          width: "100%",
        };
    },
  },
  thBillAction: {
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 112,
        };
      }
      if (width <= 991)
        return {
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          padding: 0,
        };
    },
  },
  billAction: {
    width: 90,
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          padding: 0,
          marginRight: 0,
          marginTop: 0,
        };
    },
  },
  titleInMobile: {
    display: "none",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "block",
        };
    },
  },
  messageComponent: {
    backgroundColor: "#fff",
    paddingTop: 0,
    // paddingRight: 16,
    // paddingBottom: 16,
    // paddingLeft: 16,
    display: "block",
    flexGrow: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTop: 0,
    marginTop: 0,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 0,
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 0,
          paddingRight: 0,
          paddingLeft: 0,
          borderBottomWidth: 0,
          borderTop: 1,
        };
      }
    },
  },
  simpleMessageBillerLeftSection: {
    width: "74%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "45%",
        };
      }
    },
  },
  simpleMessageBillerName: {
    width: "30.5%",
    paddingRight: 0,
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "25.5%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageTdBillerName: {
    width: "25%",
    paddingRight: 0,
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 992 && width >= 1024) {
        return {
          width: "23%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageTheadBillerSubject: {
    width: "32%",
    paddingRight: 20,
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "32.3%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "31.5%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "32%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "31%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "31.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "31%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillerSubject: {
    width: "34%",
    paddingRight: 20,
    screen: ({ width }) => {
      if (width <= 991 && width >= 1024) {
        return {
          width: "36%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillerDetails: {
    width: "33%",
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillDateTimeTHead: {
    width: "31.3%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "31.9%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillDateTime: {
    width: "33%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBillerLastMsg: {
    width: "35%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "32.6%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "33.5%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "33.1%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "31%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "33%",
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  simpleMessageBiller: {
    color: "red",
  },
  simpleMessageBillRightSectionTHead: {
    width: "90px",
    minWidth: 90,
    flexGrow: 0,
    marginRight: 16,
    // screen: ({ width }) => {
    //   if (width >= 991 && width <= 1024) {
    //     return {
    //       width: "11%",
    //     };
    //   }
    // },
  },
  flexGrowUnset: {
    flexGrow: 0,
  },
  simpleMessageBillRightSection: {
    width: 90,
    flexGrow: 0,
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          // display: "none",
        };
      }
    },
  },
  simpleMessageBillerAction: {
    width: 90,
    padding: 0,
  },
  userInitial: {
    // textIndent: 3,
  },
  // makePaymenttdRightSection: {
  //   width: "32%",
  // },
  billerDirectoryBillerLeftSection: {
    width: "46.8%",
  },
  billerDirectoryBillerThLeftSection: {
    width: "56.8%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          width: "53.8%",
        };
      }
    },
  },
  makePaymentBillName: {
    width: "30%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "28%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "28.8%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "29.5%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "29.2%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "30%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "31%",
        };
      }
      if (width <= 991) {
        return {
          width: "34%",
        };
      }
    },
  },
  makePaymentDueOn: {
    width: "25.5%",
  },
  toggleSwitch: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: "auto",
        };
      }
    },
  },
  billerDirectoryStatus: {
    width: "100%",
    // textAlign: "center",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          textAlign: "left",
        };
      }
    },
  },
  billerDirectorytdRightSection: {
    minWidth: 128,
    width: 128,
    margin: 0,
    padding: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: 42,
          width: 42,
          marginRight: 16,
        };
      }
    },
  },
  billerDirectoryAction: {
    minWidth: 90,
  },
  dashboardAlignItemsStart: {
    width: "29%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
      if (width <= 1024) {
        return {
          alignItems: "flex-start",
        };
      }
    },
  },
  makePaymentTheadRightSection: {
    width: "34%",
  },
  makePaymenttdRightSection: {
    width: "35%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "19%",
        };
      }
    },
  },
  makePaymentTdRightPart: {
    width: "36.3%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "31.3%",
        };
      }
      if (width >= 320 && width <= 359) {
        return {
          width: "37%",
        };
      }
      if (width >= 1281) {
        return {
          width: "35.1%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "31%",
          alignItems: "flex-start",
        };
      }
    },
  },
  makePaymentBillAmount: {
    width: "38%",
    screen: ({ width }) => {
      if (width >= 991 && width <= 1024) {
        return {
          marginBottom: 10,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: 121,
        };
      }
    },
  },
  makePaymentAction: {
    // width: "29%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
          maxWidth: 125,
        };
      }
    },
  },
  makePaymentActionColumn: {
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginTop: 0,
          marginRight: 16,
          marginBottom: 0,
          marginLeft: 0,
        };
      }
    },
  },

  // dasboard header css
  dashboardScreen: {
    flex: 0,
    alignItems: "flex-start",
    backgroundColor: "#fff",
    padding: 15,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          alignItems: "center",
          margin: 0,
          padding: 10,
        };
    },
  },
  filtereHading: {
    minWidth: 95,
    paddingRight: 16,
    paddingLeft: 16,
  },
  logoView: {
    ...StyleSheet.margin(32, 0, 48, 0),
  },
  textWrapperView: {
    paddingBottom: 16,
  },
  text: {
    color: Colors.onBackground,
  },
  viewAllLink: {
    color: "#0B4A87",
  },
  titleDiv: {
    marginBottom: 15,
  },
  btnTexClr: {
    color: "#333",
  },
  modalOuterWrapper: {
    width: "auto",
    backgroundColor: "#0000005c",
  },
  sessionExpirPopupBodyWrapper: {
    width: 315,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    // boxShadow: "0px 0px 3px -1px #000",
    position: "relative",
  },
  bodyWrapper: {
    width: 300,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    // boxShadow: "0px 0px 3px -1px #000",
    position: "relative",
    // screen: ({ width }) => {
    //   if (width <= 360)
    //     return {
    //       width: "70%",
    //     };
    // },
  },
  popupTitle: {
    textAlign: "center",
    marginTop: 5,
    marginRight: 0,
    marginBottom: 30,
    marginLeft: 0,
    display: "block",
  },
  countBoxWrapper: {
    flexBasis: "32.20%",
    // width: "33.33%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "30%",
          flexBasis: "30%",
        };
    },
  },
  sepaCountBoxWrapper: {
    // flexBasis: "24%",
    flexBasis: "32.2%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // marginBottom:12,
          // width: "48%",
          // flexBasis: "48%",
          width: "30%",
          flexBasis: "30%",
        };
    },
  },
  countBox: {
    flex: 1,
    // width: 100,
    height: 85,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    overflow: "hidden",
    // boxShadow: "0px 2px 4px #00000029",
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
      if (width <= 640) {
        return {
          marginTop: 0,
          marginRight: 5,
          marginBottom: 0,
          marginLeft: 5,
        };
      }
    },
  },
  newBills: {
    backgroundColor: "#b3e5fc",
  },
  overdue: {
    backgroundColor: "#878fd6",
  },
  accept: {
    backgroundColor:"#92b5d5"
  },
  outstanding: {
    backgroundColor: "#92b5d5",
    marginTop: 0,
    // marginRight: 15,
    marginBottom: 0,
    // marginLeft: 15,
    borderRadius: 5,
    screen: ({ width }) => {
      if (width >= 320 && width <= 359) {
        return {
          marginTop: 0,
          // marginRight: 5,
          marginBottom: 0,
          // marginLeft: 5,
        };
      }
      if (width >= 360 && width <= 640) {
        return {
          marginTop: 0,
          // marginRight: 16,
          marginBottom: 0,
          // marginLeft: 16,
        };
      }
    },
  },
  countBoxTitle: {},
  countBoxImage: {
    flexDirection: "row",
    paddingRight: 16,
    paddingLeft: 16,
    alignItems: "center",
    minWidth: 94,
    screen: ({ width }) => {
      if (width >= 320 && width <= 991) {
        return {
          paddingRight: 6,
          paddingLeft: 6,
          backgroundColor: "#00000021",
          flex: 1,
          flexDirection: "row",
          maxHeight: 42,
        };
      }
    },
  },
  dollarSign: {
    height: 64,
    width: 64,
    display: "block",
    backgroundSize: "contain",
    // fontSize: 18,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: 30,
          width: 30,
        };
      }
    },
  },
  countBoxHolder: {
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 16,
          paddingRight: 16,
          paddingLeft: 16,
          margin: 0,
        };
      }
    },
  },
  flex1: {
    flex: 1,
  },
  marB10: {
    marginBottom: 10,
  },
  closeBtn: {
    position: "absolute",
    right: 0,
    top: 10,
  },
  headerStyle: {
    color: "#4DC5F2",
    paddingTop: 20,
  },
  sessionModelHeaderStyle: {
    color: "#4DC5F2",
    paddingTop: 8,
  },
  sessionModelOkBtn: {
    width: 100,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    marginBottom: 10,
  },
  dashboardMessageComponent: {
    marginRight: 33,
    marginBottom: 50,
    marginLeft: 33,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    display: "block",
    flexGrow: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTopWidth: 0,
    borderBottomWidth: 1,
    screen: ({ width }) => {
      if (width >= 320 && width <= 991) {
        return {
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          padding: 0,
        };
      }
    },
  },
  dashboardHeader: {
    // marginTop: 12,
    marginRight: 16,
    // marginBottom: 16,
    marginLeft: 16,
    // borderBottom: "1px solid #cfcfcf",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
          paddingTop: 12,
        };
      }
    },
  },
  dashboardScrollView: {
    backgroundColor: "#F4F4F4",
    zIndex: 1,
    elevation: (Platform.OS === 'android') ? 1 : 0
  },

  messageNotification: {
    width: 18,
    height: 18,
    maxHeight: 18,
    backgroundColor: "#C63F9B",
    borderTopLeftRadius: 18,
    borderTopRightRadius: 18,
    borderBottomLeftRadius: 18,
    borderBottomRightRadius: 18,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  makePaymentBillerName: {
    width: "25.6%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 107,
        };
      }
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  dashboardBillerName: {
    // width: "26%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "18%",
        };
      }
    },
  },
  thBillStatus: {
    width: "35%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          display: "none",
        };
      }
    },
  },
  dashboardThBillStatus: {
    marginLeft: 0,
  },
  makePaymentThBillStatus: {
    width: "40%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "39%",
        };
      }
    },
  },
  active: {
    color: "#218666",
  },
  requested: {
    color: "#800080",
  },
  new: {
    color: "#800080",
  },
  inprogress: {
    color: "#EE781E",
  },
  accepted: {
    color: "#218666",
  },
  unblocked: {
    color: "#218666",
  },
  denied: {
    color: "#B40220",
  },
  blocked: {
    color: "#B40220",
  },
  popupMenuStyle: {
    color: "red",
  },
  billerDirectoryBillerName: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          alignItems: "flex-start",
        };
      }
    },
  },
  billerDirectoryTr: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingTop: 10,
          paddingBottom: 10,
        };
      }
    },
  },
  billerDirectoryBillerNameText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // width: 230,
    display: "block",
  },
  simpleMessageBillerNameAlignItems: {
    alignItems: "flex-start",
  },
  billerDirectoryBillrName: {
    width: "55%",
    screen: ({ width }) => {
      if (width >= 1367 && width <= 1440) {
        return {
          width: "53.4%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "54.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "97%",
        };
      }
    },
  },
  billerDirectoryTdBillrName: {
    width: "39.5%",
    display: "block",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
      if (width >= 1600) {
        return {
          width: "53%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "60.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "59%",
        };
      }
    },
  },
  leftMenuToggleBillerDirectoryTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1537 && width <= 1600) {
        return {
          width: "51.8%",
        };
      }
    },
  },
  leftMenuToggleOffBillerDirectoryTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "52.2%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "51%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "51.8%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "51.5%",
        };
      }
      if (width >= 991 && width <= 1024) {
        return {
          width: "67%",
        };
      }
    },
  },
  leftMenuToggleSimpleMessageScreenTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1537 && width <= 1600) {
        return {
          width: "26%",
        };
      }
    },
  },
  leftMenuToggleOffSimpleMessageScreenTdBillrName: {
    screen: ({ width }) => {
      if (width >= 1537 && width <= 1600) {
        return {
          width: "26%",
        };
      }
    },
  },
  billerDirectoryBillerDetails: {
    width: "9%",
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          width: "100%",
        };
      }
    },
  },
  billerDirectoryBillerDetailsTh: {
    width: "17.3%",
  },
  leftMenuTogglebillerDirectoryBillerDetailsTh: {
    width: "17.3%",
  },
  leftMenuToggleisDashboardBillname: {
    width: "29.5%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "27.7%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "28%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "28.2%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "28.9%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "28.6%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "29.3%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "31.5%",
        };
      } 
    },
  },
  leftMenuToggleDashboardBillname: {
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "28%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "28.7%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "34.5%",
        };
      }
    },
  },
  leftMenuToggleMakePaymentBillname: {
    width: "30.5%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "28%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "28.5%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "28.2%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "28.7%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "28.6%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "30%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "48%",
        };
      }
    },
  },
  leftMenuToggleOffDashboardBillerSubj: {
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "22%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "22%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "25.4%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "23.4%", 
        };
      }
    },
  },
  leftMenuToggleOffMakePaymentBillname: {
    width: "29.5%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "27.5%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "28.5%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "28%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "34%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "29%",
        };
      }
    },
  },
  leftMenuToggleTheadRightSection: {
    width: "35%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "33.2%",
        };
      }
    },
  },
  leftMenuToggleOffMakePaymentBillerSubj: {
    width: "30%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1680) {
        return {
          width: "29%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "23%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "29.4%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "30%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "23.8%",
        };
      }
    },
  },
  leftMenuToggleBillerSubj: {
    width: "23%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "24%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "25.4%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "25.2%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "24.4%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "23.1%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "29%",
        };
      }
    },
  },
  leftMenuToggleBillStatus: {
    width: "34%",
  },
  leftMenuToggleBillAmount: {
    width: "38%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "36.9%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "37%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "36.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "36.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "37%",
        };
      }
    },
  },
  leftMenuToggleBillACtion: {
    width: 90,
    padding: 0,
  },
  leftMenuToggleDateAndTime: {
    screen: ({ width }) => {
      if (width >= 1441 && width <= 1600) {
        return {
          width: "35.2%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "44.33%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "27.33%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "15%",
        };
      }
    },
  },
  makePayDateAndTime: {
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1680) {
        return {
          width: "27.5%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "35.2%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "40.33%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "27.33%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "24%",
        };
      }
    },
  },
  leftMenuToggleDueOn: {
    screen: ({ width }) => {
      if (width >= 1441 && width <= 1600) {
        return {
          width: "26%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "24%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "24%",
        };
      }
    },
  },
  leftMenuToggleisOffBillerDirectoryBillname: {
    width: "39.6%",
    screen: ({ width }) => {
      // if (width >= 1920) {
      //   return {
      //     width: "53%",
      //   };
      // }
      if (width >= 1681 && width <= 1920) {
        return {
          width: "51.3%",
        };
      }
      if (width >= 1600 && width <= 1919) {
        return {
          width: "50%",
        };
      }
      if (width >= 1537 && width <= 1599) {
        return {
          width: "53%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "39.7%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "39.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "50.6%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "63%",
        };
      }
    },
  },
  leftMenuToggleisBillerDirectoryBillname: {
    width: "39.6%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "52%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "51.8%",
        };
      }
      if (width >= 1600 && width <= 1919) {
        return {
          width: "51%",
        };
      }
      if (width >= 1537 && width <= 1599) {
        return {
          width: "53.4%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "39.5%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "39.4%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "58.4%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "55%",
        };
      }
    },
  },
  leftMenuToggleSimpleMessageDateAndTime: {
    width: "32.3%",
  },
  leftMenuToggleOffSimpleMessageBillACtion: {
    minWidth: 90,
  },
  leftMenuToggleisSimpleMessageBillname: {
    width: "29.4%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "27.2%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "27.5%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "27.8%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "28.5%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "28.3%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "28.8%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "30.5%",
        };
      }
    },
  },
  leftMenuToggleisOffSimpleMessageBillname: {
    width: "28.6%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "27%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "27.3%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "28.4%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "28%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "27.8%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "28.3%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "29%",
        };
      }
    },
  },
  leftMenuToggleSimpleMessageBillerSubj: {
    width: "32%",
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1920) {
        return {
          width: "32.6%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "32.7%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "32.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "32.5%",
        };
      }
    },
  },
  leftMenuToggleDashboardBillACtion: {
    minWidth: 90,
    marginRight: 16,
    padding: 0,
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 90,
        };
      }
    },
  },
  dashboardMakePaymenttdRightSection: {
    width: "35.8%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "34.7%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "34.6%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "34.8%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "34.3%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "34.8%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "31%",
        };
      }
    },
  },
  LeftMenuOffdashboardMakePaymenttdRightSection: {
    width: "35.8%",
    screen: ({ width }) => {
      if (width >= 1441 && width <= 1600) {
        return {
          width: "34.8%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "34.8%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "34.8%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "33%",
        };
      }
    },
  },
  makePayBillRightSectionTHead: {
    width: "35.8%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "34.7%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "34.6%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "34.8%",
        };
      }
      if (width >= 1367 && width <= 1440) {
        return {
          width: "34.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "34.5%",
        };
      }
      if (width >= 992 && width <= 1024) {
        return {
          width: "30.5%",
        };
      }
    },
  },
  billerDirectoryMakePaymenttdRightSection: {
    minWidth: 45,
    width: 45,
    marginRight: 16,
    // screen: ({ width }) => {
    //   if (width >= 992 && width <= 1024) {
    //     return {
    //       width: 90,
    //     };
    //   }
    // },
  },
  priorityInfoTooltipHolder: {
    position: "relative",
  },
  priorityInfoTooltip: {
    position: "absolute",
    backgroundColor: "#48c2ff",
    color: "#fff",
    zIndex: 999999,
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    textAlign: "center",
  },
  priorityInfoTooltipArrow: {
    width: 0,
    height: 0,
    position: "absolute",
  },
  bookedMark: {
    height: 22,
    width: 15,
    marginTop: 0,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
    display: "block",
    backgroundSize: "contain",
  },
  top50: {
    top: 50,
  },
  top0: {
    top: 0,
    inset: "0px 0px 0px",
  },
  priorityListTxt: {
    marginRight: -10,
  },
  filterBySection: {
    position: "relative",
    zIndex: 9999,
    backgroundColor: "#fff",
    alignItems: "center",
    // marginTop: 16,
    paddingTop: 5,
  },
  positionStatic: {
    marginTop: 0,
    padding: 0,
    position: "static",
  },
  // textOverflowEllipsis: {
  //   width: "95%",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  //   whiteSpace: "nowrap",
  //   display: "block",
  // },
  customPaddingRight16: {
    paddingRight: 16,
    screen: ({ width }) => {
      if (width <= 992 && width >= 1024) {
        return {
          paddingRight: 0,
        };
      }
    },
  },
  dashboardTitleDiv: {
    backgroundColor: "#fff",
    margin: 0,
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    borderBottomWidth: 0,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          borderWidth: 0,
        };
      }
    },
  },
  tablepadding: {
    paddingTop: 0,
    paddingBottom: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderBottomColor: "#e2e2e2",
  },
  tableCustomPadding: {
    paddingTop: 16,
    paddingBottom: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
          paddingBottom: 0,
          padding: 0,
          position: "relative"
        };
      }
    },
  },
  countBoxTitleText: {
    fontSize: 18,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontSize: 13,
        };
      }
      // if (width >= 360 && width <= 991) {
      //   return {
      //     fontSize: 25,
      //   };
      // }
    },
  },
  countBoxTextHolder: {
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingTop: 0,
          paddingRight: 6,
          paddingBottom: 0,
          paddingLeft: 6,
          justifyContent: "center",
        };
      }
    },
  },
  noRecords: {
    // margin: 0,
    marginRight: 16,
    marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginTop: 0,
          marginBottom: 200,
        };
      }
    },
  },

  pageTitle: {
    marginTop: 15,
    marginBottom: 15,
    // backgroundColor: "#00000000",
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingTop: 12,
          paddingRight: 16,
          paddingBottom: 12,
          paddingLeft: 16,
          marginTop: 0,
          marginBottom: 0,
          // backgroundColor: "#fff",
        };
      }
    },
  },
  billCountText: {
    fontSize: 32,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginTop: 0,
          marginRight: 16,
          marginBottom: 0,
          marginLeft: 16,
          fontSize: 25,
        };
      }
    },
  },
  borderBottom1: {
    borderBottomWidth: 1,
    borderColor: "#dadada",
    borderStyle: "solid",
  },
  dashboardBookedMarkTh: {
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginRight: 10,
        };
      }
    },
  },
  bookmarkedOuter: {
    width: 26,
    marginTop: 5,
  },
  bookedMarkTh: {
    width: 26,
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: 0,
          marginRight: 0,
        };
      }
    },
  },
  newCounter: {
    borderBottom: "2px solid #58c8f2",
    paddingBottom: 10,
  },
  accountNumberCls: {
    width: "19%",
    paddingRight: 16,
  },
  leftToggleOffAccountNumberCls: {
    width: "16.5%",
    paddingRight: 16,
    screen: ({ width }) => {
      if (width >= 1601 && width <= 1680) {
        return {
          width: "13%",
        };
      }
      if (width <= 992) {
        return {
          width: "100%",
          // marginLeft: 5,
        };
      }
    },
  },
  accountNumberTh: {
    width: "16.5%",
  },
  leftMenuToggleAccountNumberTh: {
    width: "16.5%",
    screen: ({ width }) => {
      if (width >= 1681 && width <= 1920) {
        return {
          width: "19.5%",
        };
      }
      if (width >= 1601 && width <= 1680) {
        return {
          width: "15.5%",
        };
      }
      if (width >= 1441 && width <= 1600) {
        return {
          width: "19.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "17%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "19.5%",
        };
      }
      if (width >= 991 && width <= 1024) {
        return {
          width: "12.5%",
        };
      }
    },
  },
  leftMenuToggleOffAccountNumberTh: {
    width: "16.5%",
    screen: ({ width }) => {
      if (width >= 1680 && width <= 1920) {
        return {
          width: "20.5%",
        };
      }
      if (width >= 1537 && width <= 1600) {
        return {
          width: "18.5%",
        };
      }
      if (width >= 1441 && width <= 1536) {
        return {
          width: "18.5%",
        };
      }
      if (width >= 1281 && width <= 1366) {
        return {
          width: "18.5%",
        };
      }
      if (width >= 991 && width <= 1024) {
        return {
          width: "15.5%",
        };
      }
    },
  },
  customTextAlignmentRight: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          textAlign: "right",
          // minWidth: 100,
        };
      }
    },
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column"
        }
      }
    }
  },
  boldTextInMView: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontWeight: 500,
          color: "#182832",
        }
      }
    }
  },
 
  billNameText: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: "75%",
        }
      }
    }
  },
  bookMarkIcon: {},
  simpleMessageTr: {
    alignItems: "flex-start",
  },
  billerDirectoryNoListView: {
    margin: "58px 16px 50px",
    screen: ({ width }) => {
      if (width === 1024) {
        return {
          margin: "50px 16px",
          padding: "0 16px"
        }
      }
      if (width <= 1024) {
        return {
          margin: "0px 0px 0px",
        }
      }
    }
  },
  countBoxImg: {
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          marginTop: 11,
        }
      }
    }
  },
  customStatusContainer: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  copyRightWrapper: {
    zIndex: 999
  },
  mView: {
    display: "block",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          display: "none",
        };
      }
    },
  },
  billerDirectoryNoRecord: {
    marginLeft: 0,
    marginRight: 0,
    screen: ({ width }) => {
      if (width < 1024) {
        return {
          marginLeft: 16,
          marginRight: 16,
        }
      }
    }
  },
  paddingTop6: {
    paddingTop: 6,
  },
  filterBySectionWithFiler: {
    minHeight: 63,
  },
  filterBySectionWrapper: {
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width < 1024) {
        return {
          paddingRight: 0,
          paddingLeft: 0,
        }
      }
    }
  },
  paddingTop3: {
    paddingTop: 3,
  },
  simpleMessageDateTime: {
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          width: "65%",
        }
      }
    }
  },
  myBankMainSection: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 11,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    backgroundColor: "#fff",
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          marginLeft: 16,
          marginRight: 16,
          paddingTop: 16,
          paddingRight: 16,
          paddingBottom: 16,
          paddingLeft: 16,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#dadada',
          height:"100%"
        }
      }
    }
  },
  webWidth16: {
    screen: ({ width }) => {
      if (width >= 991) {
        return {
          width: "16%",
        }
      }
    }
  },
  mobileMarginRight8: {}
});

export { responsiveTableStyle }