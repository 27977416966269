import {mixPanelEvent} from './MixPanel';
import {gaEvent} from './GoogleAnaylitics';

/**
 * groupName = String
 * action = String
 * userLoginDetail = {} // any
 * from = String  // url 
 * to = String  // url 
 */
const analyticsLogs = async (groupName, action = null, app = {}, from = null, to = null) => {
    const trackingEvent = {};
    const userLoginDetail = await app.dm.rest.getUser();
    // if userType is Claims Provider or Patient
    // if(userLoginDetail.userType === USER_TYPE.PCH_PROVIDER || userLoginDetail.userType === USER_TYPE.PCH_PATIENT || !userLoginDetail.userType){
        if(!!action) trackingEvent.action = action;
        if(!!from) trackingEvent.from = from;
        if(!!to) trackingEvent.to = to;
        if(!!userLoginDetail.pid) trackingEvent.pid = userLoginDetail.pid;
        if(!!userLoginDetail.email) trackingEvent.email = userLoginDetail.email;
        trackingEvent.time = Date.now();
        mixPanelEvent(groupName, trackingEvent);
        gaEvent(groupName, trackingEvent);
    // }
    return true;
}

export {
  analyticsLogs
};
