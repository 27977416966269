import React from "react";
import { StyleSheet, Touchable, Image, View, Text, IconButton } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
import { responsiveTableStyle } from "../assets/css/responsiveTableStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";


const newBillsIcon = require("../assets/images/New-bills.png");
const outstandingIcon = require("../assets/images/Outstanding.png");
const overdueIcon = require("../assets/images/Overdue.png");
const acceptIcon = require("../assets/images/accept.png");
const rejectIcon = require("../assets/images/reject.png");
const attachedFileIcon = require("../assets/images/attachedFileIcon.png");
const bookmaredActiveIcon = require("../assets/images/bookmared_active.png");

export default class DashboardHeaderSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenPriorityInfoTooltip: false
    }
  }

  onClickPriorityInfo = () => {
    this.setState({
      isOpenPriorityInfoTooltip: !this.state.isOpenPriorityInfoTooltip,
    });
    this.timer = setTimeout(() => {
      this.setState({
        isOpenPriorityInfoTooltip: false,
      });
    }, 5000)
  };

  renderBlock(count, icon, label, style) {
    const { selectedScreen } = this.props;
    return (
      <Touchable style={[responsiveTableStyle.countBoxWrapper, count > 0 ? null : customCommonStyles.cursorDefault, selectedScreen !== "RTP" && responsiveTableStyle.sepaCountBoxWrapper]} onPress={() => this.props.onBlockNavigatation(label)}>
        <View style={[responsiveTableStyle.countBox, style]}>
          <View style={[commonStyles.dFlex, commonStyles.flexDirectionColumn, commonStyles.flex1, responsiveTableStyle.countBoxTextHolder]}>
            <View style={[commonStyles.paddingTop5, commonStyles.webView]}>
              <Text style={[responsiveTableStyle.billCountText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight600, commonStyles.contentColor]}>{count}</Text>
            </View>
            <View style={[responsiveTableStyle.countBoxTitle]}>
              <Text style={[responsiveTableStyle.countBoxTitleText, commonStyles.fontWeight600, commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight500, commonStyles.contentColor]}>{label}</Text>
            </View>
          </View>
          <View style={[responsiveTableStyle.countBoxImage, commonStyles.justifyContentSpaceBetween]}>
            <View style={[responsiveTableStyle.mView]}>
              <Text style={[responsiveTableStyle.billCountText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight600, commonStyles.contentColor]}>{count}</Text>
            </View>
            <View style={[responsiveTableStyle.countBoxImg]}>
              <Image source={icon} style={responsiveTableStyle.dollarSign} />
            </View>
          </View>
        </View>
      </Touchable>
    )
  }

  render() {
    const { newBillCount, outstandingBillCount, overdueBillCount, itemCount, selectedScreen, acceptBillCount, rejectBillCount } = this.props;
    return (
      <View style={[responsiveTableStyle.dashboardHeader, customCommonStyles.customDashboardHeader, commonStyles.paddingBottom0]}>
        <View style={[commonStyles.flex1, commonStyles.justifyContentSpaceBetween, responsiveTableStyle.countBoxHolder, commonStyles.flexDirectionRow, customCommonStyles.customCountBoxHolder, selectedScreen !== "RTP" && commonStyles.flexWrap]}>
        {this.renderBlock(newBillCount, newBillsIcon, "New",  [responsiveTableStyle.newBills, responsiveTableStyle.mobileMarginRight8])}
        {selectedScreen === "RTP" && this.renderBlock(outstandingBillCount, outstandingIcon, "Outstanding",  [responsiveTableStyle.outstanding, responsiveTableStyle.mobileMarginRight8])}
        {selectedScreen !== "RTP" && this.renderBlock(acceptBillCount, acceptIcon, "Accepted",  [responsiveTableStyle.accept, responsiveTableStyle.mobileMarginRight8])}
        {selectedScreen !== "RTP" && this.renderBlock(rejectBillCount, rejectIcon, "Rejected",  responsiveTableStyle.overdue)}
        {selectedScreen === "RTP" && this.renderBlock(overdueBillCount, overdueIcon, "Overdue",  responsiveTableStyle.overdue)}
        </View>
        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, responsiveTableStyle.titleDiv, commonStyles.alignItemsCenter, responsiveTableStyle.dashboardTitleDiv]}>
          <View style={[responsiveTableStyle.priorityInfoTooltipHolder, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, styles.zIndex1000]}>
            <View onPress={() => this.props.navigateTo("dashboard")} style={responsiveTableStyle.priorityListTxt}>
                <Text style={[commonMetricsStyle.heading6, commonStyles.darkGrayColor, commonStyles.fontWeight600, commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.contentColor]}>
                  Priority List
                </Text>
            </View>
            <IconButton icon="help" size="small" key="help" style={[styles.customMargin]} onPress={this.onClickPriorityInfo} />
            {this.state.isOpenPriorityInfoTooltip && (
              <View style={[responsiveTableStyle.priorityInfoTooltip, commonStyles.flex1, commonStyles.flexDirectionRow, customCommonStyles.customPriorityInfoTooltip, commonStyles.justifyContentSpaceBetween]}>
                <View>
                  <Text style={[commonMetricsStyle.helperTextFontStyle, commonFontStyles.workSans, commonStyles.darkGrayColor]}>List of "Prioritized"</Text>
                </View>
                <Image source={bookmaredActiveIcon} style={responsiveTableStyle.bookedMark} />
                <View>
                  <Text style={[commonMetricsStyle.helperTextFontStyle, commonFontStyles.workSans, commonStyles.darkGrayColor]}>bills</Text>
                </View>
                <View style={[responsiveTableStyle.priorityInfoTooltipArrow, customCommonStyles.customArrowPosition]}></View>
              </View>
            )}
          </View>
          {itemCount > 0 ?
            <Touchable onPress={() => this.props.convertToCSV()}>
              <View style={[commonStyles.marginRight15]}>
                <Image source={attachedFileIcon} style={commonStyles.icon18} />
              </View>
            </Touchable> :

            <View style={[commonStyles.marginRight15]}>
              <Image source={attachedFileIcon} style={commonStyles.icon18} />
            </View>
          }
         {selectedScreen === "RTP" && <Touchable onPress={() => this.props.onBulkPaymentNavigation()}>
            <View style={[commonStyles.marginRight15]}>
              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonFontStyles.workSans, responsiveTableStyle.viewAllLink,]}>Bulk Pay</Text>
            </View>
          </Touchable> }
          
          <Touchable onPress={() => this.props.onViewAllNavigation()}>
            <View>
              <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonFontStyles.workSans, responsiveTableStyle.viewAllLink]}>View All</Text>
            </View>
          </Touchable>
        </View>
      </View>
    )

  }
}

const styles = StyleSheet.create({
  zIndex1000: {
    zIndex: 1000
  },
  customMargin: {
    marginTop: 0,
    marginLeft: 6,
    marginBottom: 0,
    marginRight: 6
  }
});
