import R14, { Platform } from "../core";

export default class CommonUtilityDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      isToasterVisible: false,
      toasterMsg: "",
      toasterVariant: "",
      cbFunction: null
    };
  }

  async showToastr(variant = "", msg = "",  cbFunction = null) {
    if (Platform.OS !== 'web') { 
      if(!!msg) this.setState({ isToasterVisible: true, toasterMsg: msg, toasterVariant: variant, cbFunction: cbFunction });
      else {
        if(this.state.cbFunction)this.state.cbFunction()
          this.setState({ isToasterVisible: false, toasterMsg: "", toasterVariant: "", cbFunction: null });
      }
    } else {
     this.ui.snackBar.hide().show({
        message: msg,
        variant: variant,
        dismissTimeout: 5,
      });
    }
  }

  async writeLogs(data) {
    try {
       this.api.mutate(
        `mutation appLogging($input: String!){
          appLogging(input: $input)
        }`,
        {
          input: data,
        }
      );
    } catch (error) {
      return error;
    }
  }
  
}
