import React from "react";
import R14, {
  TextInputField,
  SubmitButton,
  StyleSheet,
  View,
  Form,
  SelectMenuField,
  Button,
  Platform
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import CommonValidators from "../../utils/CommonValidators";
import { LOGIN_TYPE, USER_TYPE } from "../../constant/app.data.constant";
import { CLAIM_CLIENT_ID } from "../../constant/app.constant";
import { CommonString } from "../../constant/appCommonText";  

export default R14.connect(
  class PCHSignupComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        enableErrorStyle: false,
        defaultDomain: "#xbp.exela.global",
        socialMediaRegistrationEnabled: this.props.registrationType !== "new" ? true : false,
        currentContextProfileId: "",
        isOpenRegistrationHelpTooltip: false,
        osName: "",
        showPassword: true,
        showConfirmPassword: true,
      };
      this.form = null;
      this.password = "";
      this.confirmPassword = "";
    }
    componentDidMount = async () => {
      try {

        const { pchRegistrationTypePatient, unbounceEmail } = this.props.app.dm.userSession.state;

        this.setState({
          osName: Platform.OS,
        });

        this.form = this.props.app.ui.form("registrationForm");
        this.form.elmts.countryCode.setValue({ label: "+1", value: "+1" });
        // Fill data received from google auth
        const { socialLoginProfile, socialLoginType } = this.props.app.dm.userLoginDomain.state;
        if (this.state.socialMediaRegistrationEnabled && socialLoginType === LOGIN_TYPE.GOOGLE) {
          let form = this.props.app.ui.form("registrationForm");
          if (pchRegistrationTypePatient) {
            form.elmts.firstName.setValue(socialLoginProfile.givenName);
            form.elmts.lastName.setValue(!!socialLoginProfile.familyName ? socialLoginProfile.familyName : "");
          } else {
            let fullName = socialLoginProfile.givenName;
            if (!!socialLoginProfile.familyName)
              fullName = fullName + " " + socialLoginProfile.familyName;
            form.elmts.firstName.setValue(fullName);
          }
          form.elmts.userEmail.setValue(socialLoginProfile.email);
          this.setState({ currentContextProfileId: socialLoginProfile.googleId });
        } else if (this.state.socialMediaRegistrationEnabled && socialLoginType === LOGIN_TYPE.APPLE) {
          let form = this.props.app.ui.form("registrationForm");
          let { authorization, user = "" } = socialLoginProfile;
          const token = Platform.OS === 'ios' ? socialLoginProfile.identityToken : authorization.id_token
          const jwt = await this.props.app.dm.userLoginDomain.decodeJwtToken(token);
          const payload = !!jwt ? JSON.parse(jwt) : null
          this.setState({ currentContextProfileId: payload.sub });
          if (!!user && !!user.email) {
            if (pchRegistrationTypePatient) {
              form.elmts.firstName.setValue(user.name.firstName);
              form.elmts.lastName.setValue(!!user.name.lastName ? user.name.lastName : "");
            } else {
              let fullName = user.name.firstName;
              if (!!user.name.lastName)
                fullName = fullName + " " + user.name.lastName;
              form.elmts.firstName.setValue(fullName);
            }

            form.elmts.userEmail.setValue(user.email);
          } else {
            form.elmts.userEmail.setValue(!!user && !!user.email ? user.email : payload.email);
            // form.elmts.userEmail.setValue(Platform.OS === 'ios' ? payload.email : user.email);
          }
        }
        else if (this.state.socialMediaRegistrationEnabled && socialLoginType === LOGIN_TYPE.AMAZON) {
          let form = this.props.app.ui.form("registrationForm");
          form.elmts.firstName.setValue(socialLoginProfile.firstName);
          if (pchRegistrationTypePatient)
            form.elmts.lastName.setValue(socialLoginProfile.lastName);
          form.elmts.userEmail.setValue(socialLoginProfile.email);
          this.setState({ currentContextProfileId: socialLoginProfile.id });
        }
        else if (socialLoginType === LOGIN_TYPE.KEYCLOAK) {
          let form = this.props.app.ui.form("registrationForm");
          form.elmts.firstName.setValue(socialLoginProfile.firstName);
          if (pchRegistrationTypePatient)
            form.elmts.lastName.setValue(socialLoginProfile.lastName);
          form.elmts.userEmail.setValue(socialLoginProfile.email);
          this.setState({ currentContextProfileId: socialLoginProfile.kcId });
        }
        else if (!!socialLoginProfile && this.props.registrationType === "new") {
          let form = this.props.app.ui.form("registrationForm");
          form.elmts.userEmail.setValue(socialLoginProfile.email);
          this.mobileNumberText(socialLoginProfile.mobileNumber);
        }
        if (!!unbounceEmail && !!this.form.elmts.userEmail) this.form.elmts.userEmail.setValue(unbounceEmail);

      } catch (error) {

      }
    }

    handleSubmit = async (form) => {
      this.props.app.ui.progressIndicator.show();
      const { osName } = this.state;
      const { pchRegistrationTypeProvider, pchRegistrationTypePatient } = this.props.app.dm.userSession.state;
      const { socialLoginType } = this.props.app.dm.userLoginDomain.state;
      let payerPhoneNumber = form.elmts.countryCode.value.value + " " + form.elmts.mobileNumber.value.replace(/[^\w\d\s]/g, "").replace(" ", "");
      this.clearAllValidationMessages();
      let validateNameText = this.nameFieldsValidation();
      let validatePassword = false;
      let validateBothPasswords = false;
      let validateAppleMail = false;
      let passwordCriteriaCheck = false;
      if (!!socialLoginType && this.props.screen !== "addUser") {
        validateAppleMail = await this.applePrivateEmailValidation();
        validatePassword = true;
        validateBothPasswords = true;
        passwordCriteriaCheck = true;
      } else {
        validateAppleMail = true;
        passwordCriteriaCheck = this.min8Max15(this.password);
        validatePassword = true; //this.passwordValidation();
        validateBothPasswords = true; //this.matchPasswordValidation();
      }
      // this.app.props.dm.commonDomain.writeLogs("")

      if (validateNameText && validateAppleMail && validatePassword && validateBothPasswords && passwordCriteriaCheck) {
        if (!!this.props.onNonRegistrationSubmit) {
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          this.props.onNonRegistrationSubmit({
            firstName: form.elmts.firstName.value,
            email: form.elmts.userEmail.value.trim(),
            mobileNumber: payerPhoneNumber,
          });
          return false;
        }
        else {
          let reqBody = {
            firstName: form.elmts.firstName.value,
            lastName: (pchRegistrationTypePatient || this.props.screen === "addUser") && !!form.elmts.lastName.value ? form.elmts.lastName.value : "",
            email: form.elmts.userEmail.value.trim(),
            mobileNumber: payerPhoneNumber,
            // password: this.confirmPassword, //form.elmts.confirmPassword.value,
            socialProfileId: this.state.currentContextProfileId,
            userType: pchRegistrationTypeProvider ? USER_TYPE.PCH_PROVIDER : USER_TYPE.PCH_PATIENT,
            loginType: !!socialLoginType ? socialLoginType : LOGIN_TYPE.NORMAL,
            EIN: pchRegistrationTypeProvider && this.props.screen !== "addUser" ? form.elmts.EIN.value : "",
            parentUid: this.props.screen === "addUser" ? this.props.parentUID : null,
            createdBy: this.props.userLoginDetail.uid,
            signInLocation: USER_TYPE.CLAIMS,
            clientId: CLAIM_CLIENT_ID
          };
          try {
            let res = await this.props.app.dm.userRegistration.signup(reqBody);
            if (!!res && !!res.success) {
              const { access_token, refresh_token } = this.props.app.dm.userLoginDomain.state;
              this.props.app.dm.commonDomain.showToastr("success", res.message);
              if (this.props.screen === "addUser") {
                this.props.refreshList();
              } else {
                this.props.app.ui.progressIndicator.hide({ timeout: 750 });
                if (!!reqBody.socialProfileId && !!res.userLoginDetail && !!res.userLoginDetail.uid) {
                  this.props.app.dm.userLoginDomain.setState({
                    socialLoginProfile: null,
                    socialLoginType: "",
                  });
                  res.access_token = access_token;
                  res.refresh_token = refresh_token;
                  await this.props.app.dm.userSession._processAuthResult(res);
                  if (osName === "android" || osName === 'ios') { this.props.app.nav.to("pchProfileEditRoute"); }

                  else { await this.redirectToReturnUrl(); }
                  return false;
                } else {
                  // this.props.navPchLogin();
                  this.props.app.nav.to("pchSignupConfirmation");
                  return false;
                }
              }
            } else {
              this.props.app.dm.commonDomain.showToastr("error", !!res.message ? res.message : res.error);
              this.props.app.ui.progressIndicator.hide({ timeout: 750 });
              return false;
            }
          } catch (err) {
            this.props.app.dm.commonDomain.showToastr("error", err.message);
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            return false;
          }
        }
      } else {
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        return false;
      }
    };

    // Clear Validation Message
    clearAllValidationMessages = () => {
      const { pchRegistrationTypePatient } = this.props.app.dm.userSession.state;
      let regForm = this.props.app.ui.form("registrationForm");
      regForm.elmts.firstName.setError("");
      if (pchRegistrationTypePatient) regForm.elmts.lastName.setError("");
      regForm.elmts.userEmail.setError("");
      regForm.elmts.mobileNumber.setError("");
      // regForm.elmts.newPassword.setError("");
      // regForm.elmts.confirmPassword.setError("");
    };

    resetValues = () => {
      const { pchRegistrationTypePatient } = this.props.app.dm.userSession.state;
      let regForm = this.props.app.ui.form("registrationForm");
      regForm.elmts.firstName.setValue("");
      if (pchRegistrationTypePatient) regForm.elmts.lastName.setValue("");
      regForm.elmts.userEmail.setValue("");
      regForm.elmts.mobileNumber.setValue("");
      // regForm.elmts.newPassword.setValue("");
      // this.password = "";
      // regForm.elmts.confirmPassword.setValue("");
      // this.confirmPassword = "";
    };

    // Validate names fields.
    nameFieldsValidation = () => {
      let fName = this.props.app.ui.form("registrationForm").elmts.firstName;
      let lName = this.props.app.ui.form("registrationForm").elmts.lastName;
      let isValidFirstName = false;
      // let isValidLastName = false;
      // --- First Name
      if (fName.value !== "") {
        if (/^[a-zA-Z0-9\s]{2,50}$/gi.test(fName.value.trim()) === false) {
          fName.setError(CommonString.MIN2MAX50Validation);
        } else {
          fName.setError("");
          isValidFirstName = true;
        }
      }

      if (!!isValidFirstName && !!lName && lName.value !== "") {
        if (/^[a-zA-Z0-9\s]{2,66}$/gi.test(fName.value.trim() + ' ' + lName.value.trim()) === false) {
          fName.setError(CommonString.FullNameExceed);
          isValidFirstName = false;
        } else {
          fName.setError("");
          isValidFirstName = true;
        }
      }
      if (isValidFirstName) {
        return true;
      } else {
        return false;
      }
    };

    trimWhiteSpacesFirstName = (val) => {
      this.form.elmts.firstName.setValue(val.trimStart());
    };

    trimWhiteSpacesLastName = (val) => {
      const { pchRegistrationTypePatient } = this.props.app.dm.userSession.state;
      if (pchRegistrationTypePatient) this.form.elmts.lastName.setValue(val.trimStart());
    };

    // Validate password field.
    passwordValidation = () => {
      let formElement = this.props.app.ui.form("registrationForm").elmts.newPassword;
      let passwordTxt = this.password; // formElement.value;
      let isUpper = false;
      let isNumeric = false;
      let isSpecial = false;
      let minMaxLength = false;
      let bContainsSpace = false;
      let bContainsLowerCase = false;

      // Upper Case check
      if (passwordTxt.replace(/[^A-Z]/g, "").length > 0) {
        isUpper = true;
      }
      // Numeric Character check
      if (passwordTxt.replace(/[^0-9]/g, "").length > 0) {
        isNumeric = true;
      }
      // Special Character check
      if (passwordTxt.replace(/[^#@$!%*?]/g, "").length > 0) {
        isSpecial = true;
      }
      // Lower case check
      if (passwordTxt.replace(/[^a-z]/g, "").length > 0) {
        bContainsLowerCase = true;
      }
      // Check if any whitespace.
      if (/\s/.test(passwordTxt)) {
        bContainsSpace = true;
      }
      // Length Check
      if (passwordTxt.length >= 8 && passwordTxt.length <= 15) {
        minMaxLength = true;
      }
      if (isUpper && isNumeric && isSpecial && minMaxLength && bContainsLowerCase && !bContainsSpace) {
        formElement.setError("");
        return true;
      } else {
        formElement.setError(CommonString.PasswordValidation);
        return false;
      }
    };

    // Validate both password fields.
    matchPasswordValidation = () => {
      let confirmPasswordTxt = this.props.app.ui.form("registrationForm").elmts.confirmPassword;
      let isValid = false;
      if (this.password !== "") {
        if (this.confirmPassword !== "") {
          if (this.password !== this.confirmPassword) {
            confirmPasswordTxt.setError(CommonString.PasswordMisMatch);
            isValid = false;
          } else {
            confirmPasswordTxt.setError("");
            isValid = true;
          }
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
      return isValid;
    };

    // Mobile number validation.
    mobileNumberText(val) {
      let mobNumText = val;
      if (!!val) {
        let cleaned = ("" + mobNumText).replace(/\D/g, "").substring(0, 10);
        this.form.elmts.mobileNumber.setValue(cleaned);
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          let formattedString = "";
          if (match[1] !== undefined) {
            match[1] = this.form.elmts.countryCode.value.value;
          } else {
            match[1] = "+1 ";
          }
          formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
          this.form.elmts.mobileNumber.setValue(formattedString);
        }
      }
    }

    //Apple Private validation.
    applePrivateEmailValidation = async () => {
      // --- mg6bydhtm5@privaterelay.appleid.com
      let frmElmt = this.props.app.ui.form("registrationForm").elmts.userEmail;
      let privateEmail = frmElmt.value;
      let res = privateEmail.includes("privaterelay.appleid.com");
      if (res) {
        frmElmt.setError("");
        return true;
      } else {
        frmElmt.setError("");
        return true;
      }
    };


    redirectToReturnUrl = async () => {
      let returnUrl = this.props.app.route._location.search;
      if (returnUrl.indexOf("?ReturnUrl=") > -1) {
        if (returnUrl.indexOf("medical-bills") > -1) {
          let uid = returnUrl.split("/");
          uid = uid[uid.length - 1];
          this.props.app.nav.to("uploadedBillsRoute", { uid: uid });
          return false;
        } else {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.InvalidBill);
          this.props.app.nav.to("pchProfileEditRoute");
        }
      } else {
        this.props.app.nav.to("pchProfileEditRoute");
      }
    };

    EINValidation(val) {
      if (!!val) {
        let cleaned = val.replace(/\D/g, "").substring(0, 9);
        let b = "-";
        let position = 2;
        let output = cleaned.length > 2 ? [cleaned.slice(0, position), b, cleaned.slice(position)].join('') : cleaned;
        this.form.elmts.EIN.setValue(output);
        // this.props.app.ui.form("registrationForm").elmts.EIN.setValue(cleaned);
      }
    }

    readText = (val) => {
      this.password = val; 
    }

    readTextConfirmPassword = (val) => {
      this.confirmPassword = val; 
    }

    min8Max15 = async (value) => {
      // let isValid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#@$!%*?]).{8,15}$/gi.test(value);
      let isValid = /^[a-zA-Z0-9!@#$%?*]{8,15}$/gi.test(value);
      isValid = !!isValid ? /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#@$!%*?]).{8,15}$/gi.test(value) : isValid;
      return isValid
        ? true
        : {
          error: CommonString.PasswordValidation,
        };
    };

    toggleShowPassword() {
      this.setState({ showPassword: !this.state.showPassword });
    }
    toggleShowConfirmPassword() {
      this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }

    render() {
      const { socialLoginProfile } = this.props.app.dm.userLoginDomain.state;
      this.enlistedCountryCode = [
        {
          label: "+1",
          value: "+1",
        },
      ];
      const {
        pchRegistrationTypeProvider,
        pchRegistrationTypePatient,
      } = this.props.app.dm.userSession.state;
      const { formFlexDirection, addUsersFormAlignment, leftInput, rightInput, addUserBtnHolder, adduserBtns } = this.props;
      let userType = !!this.props.userLoginDetail ? this.props.userLoginDetail.userType : null;
      return (
          <Form
            name="registrationForm"
            style={[commonStyles.flex1, commonStyles.flexDirectionColumn, styles.form, formFlexDirection, this.props.screen === "addUser" ? customCommonStyles.adduserFormStyle : styles.newRegisterFormStyle]}
            onSubmit={this.handleSubmit}
            validateBeforeSubmit={true}
            validators={CommonValidators.getFormValidator()}>
            {/* NOTE: An approach to bunk browser autofill. */}
            <View style={styles.hideField}>
              <TextInputField name="uEmail" label="Email Address" style={styles.hideField} />
            </View>
            <View style={[addUsersFormAlignment, leftInput]}>
              <TextInputField
                name="firstName"
                label={pchRegistrationTypeProvider ? (this.props.screen === "addUser" ? "First Name*" : "Practice Group Name*") : "First Name*"}
                // icon="account"
                required={
                  pchRegistrationTypeProvider ? (this.props.screen === "addUser" ? "Enter First Name" : "Enter Practice Group Name") : "Enter First Name"
                }
                style={[styles.fNameField, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                // tooltip="Min 2 - Max 50 alphanumeric characters allowed"
                validator="min2Max50"
                onChangeText={this.trimWhiteSpacesFirstName}
                helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                disabled={!!socialLoginProfile && !!socialLoginProfile.firstName}
                readOnly={!!socialLoginProfile && !!socialLoginProfile.firstName}
              />
            </View>
            {pchRegistrationTypePatient || this.props.screen === "addUser" ? (
              <View style={[addUsersFormAlignment, rightInput, this.props.screen === "addUser" ? null : styles.margintopM15]}>
                <TextInputField
                  name="lastName"
                  label="Last Name*"
                  // required="Enter Provider last name"
                  required={pchRegistrationTypeProvider ? "Enter Last Name" : "Enter Last Name"}
                  style={[styles.lNameField, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  // tooltip="Min 2 - Max 50 alphanumeric characters allowed"
                  validator="min2Max50"
                  onChangeText={this.trimWhiteSpacesLastName}
                  helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  disabled={!!socialLoginProfile && !!socialLoginProfile.firstName}
                  readOnly={!!socialLoginProfile && !!socialLoginProfile.firstName}
                />
              </View>
            ) : null}
            <View
              style={[
                addUsersFormAlignment,
                userType === USER_TYPE.PCH_PATIENT ? leftInput : this.props.screen === "addUser" ? leftInput : styles.margintopM15,
              ]}>
              <TextInputField
                name="userEmail"
                label="Email*"
                required={CommonString.EmailRequired}
                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                validator="validateEmail"
                helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                disabled={!!socialLoginProfile && !!socialLoginProfile.firstName}
                readOnly={!!socialLoginProfile && !!socialLoginProfile.firstName}
              />
            </View>
            <View style={this.props.screen === "addUser" || !!pchRegistrationTypePatient ? null : styles.margintopM15}>
              {!!pchRegistrationTypeProvider && this.props.screen !== "addUser" ? (
                <TextInputField
                  name="EIN"
                  label="EIN#*"
                  required="Enter EIN"
                  style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  validator="EINValidation"
                  onChangeText={this.EINValidation}
                  // tooltip="Employer Identification Number"
                  helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                />
              ) : null}
            </View>
            {/* NOTE: An approach to bunk browser autofill. */}
            <TextInputField name="mobileNumber" label="Mobile Number" style={styles.hideField} value="checked" />
            {/* TODO: To add drop down listing the International country code and a text box to enter the mobile number.*/}
            <View
              style={[
                addUsersFormAlignment,
                userType === USER_TYPE.PCH_PATIENT ? rightInput : this.props.screen === "addUser" ? rightInput : styles.margintopM15,
              ]}>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                <TextInputField
                  name="+1"
                  value="+1"
                  style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.countryCode]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}

                  helperTextStyle={[styles.customHelperTextStyle, customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                  disabled={true}
                  readOnly={true}
                />
                <SelectMenuField
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.countryCode, customCommonStyles.inputHeight, styles.hideField]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                  name="countryCode"
                  required="Select Country Code"
                  items={this.enlistedCountryCode}
                  // onValueChange={this.onDomainExtensionChange}
                  searchable
                  disabled={true}
                  itemLoader={async ({ search }) => {
                    let items = this.enlistedCountryCode;
                    return items
                      .filter((item) => {
                        return item.label.toLowerCase().includes(search.toLowerCase());
                      })
                      .sort((a, b) => b.label - a.label);
                  }}
                  helperTextStyle={[commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                />
                <TextInputField
                  name="mobileNumber"
                  label="Mobile Number*"
                  required="Enter Mobile Number"
                  style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  validator="max10phoneNoValidator"
                  onChangeText={this.mobileNumberText}
                  valueParser={(val) => parseInt(val)}
                  helperTextStyle={[styles.customHelperTextStyleMobile, commonStyles.helperTextFontStyle, commonFontStyles.workSans, customCommonStyles.mobileNumErrorTest,]}
                />
              </View>
            </View>
            <View style={[addUsersFormAlignment, userType === USER_TYPE.PCH_PATIENT ? leftInput : rightInput]}></View>
            <View
              style={[
                commonStyles.marTAuto,
                commonStyles.flex1,
                commonStyles.flexDirectionRow,
                addUserBtnHolder,
                this.props.screen === "addUser" ? rightInput : styles.customMargintop,
              ]}>
              <View style={[customCommonStyles.customFullWidth, commonStyles.marginRight8, adduserBtns]}>
                <Button
                  key="Cancel"
                  title="Cancel"
                  onPress={this.props.screen === "addUser" ? this.props.handleCancel : 
                  !!this.props.onNonRegistrationCancel ? this.props.onNonRegistrationCancel : this.props.navToWelcomeToPch}
                  style={[commonStyles.blueBorderButton, commonStyles.fullWidth]}
                  titleTextStyle={[commonStyles.blueBorderButtonColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6]}
                />
              </View>
              <View style={[customCommonStyles.customFullWidth, commonStyles.marginLeft8, adduserBtns]}>
                <SubmitButton
                  key="register"
                  title={this.props.screen === "addUser" ? "Add" : !!this.props.onNonRegistrationSubmit ? "Continue" : "Register"}
                  style={[commonStyles.blueButton, commonStyles.fullWidth, customCommonStyles.noBoxShadow,]}
                  titleTextStyle={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.transformNone, styles.loginButtonTextStyle, commonStyles.whiteColor]}
                />
              </View>
            </View>
          </Form>
       
      );
    }
  }
);

const styles = StyleSheet.create({
  fNameField: {
    width: "100%",
  },
  lNameField: {
    width: "100%",
  },    
  hideField: {
    display: "none",
  },  
  countryCode: {
    maxWidth: 70,
  },
  customHelperTextStyle: {
    position: "static",
  },
  customHelperTextStyleMobile: {
    display: "block",
    marginLeft: -80,
  }, 
  form: {
    zIndex: 1,
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    flexGrow: 0,
    screen: ({ width, height }) => {
      if (width <= 414 && height === 736) {
        return {
          paddingTop: 20,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        };
      } else if (width <= 991)
        return {
          paddingTop: 5,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginBottom: 20,
        };
    },
  },  
  margintopM15: {
    // marginTop: -2,
  },
  customMargintop: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 30,
        }
      }
    },
  },   
  newRegisterFormStyle: {
    screen: ({ width }) => {
      if (width === 1024) {
        return {
          paddingLeft: 0,
          paddingRight: 0,
        };
      }
    }
  }
});
