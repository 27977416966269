import React from "react";
import R14, { StyleSheet, View, Text, Touchable, Image } from "../../core"; 
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";

const xbpLogo = require("../../assets/images/NewXbpLogo150.png");
const back_icon = require("../../assets/images/back_icon.png");

export default R14.connect(
  class PCHUserTerms extends React.Component {
    navPchLogin = () => {
      this.props.app.nav.back();
      return false;      
    };
    accessServicesAgreementRoute = async () => {
      this.props.app.nav.to("accessServicesAgreementRoute");
      return false;
    };
    render() {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      return (
        <View style={[styles.pchPrivacyPolicy]}>
          {isMobileDevice ?
            (<View>
              <View style={[customCommonStyles.customLogoImageContainer, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <Touchable onPress={this.navPchLogin}>
                  <View>
                    <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                  </View>
                </Touchable>
                <View style={[styles.logoStyle, commonStyles.justifyContentCenter]}>
                  <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
                </View>
                {/* <View>
                    <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500]}>Registration</Text>
                  </View> */}
              </View>
            </View>) :
            (<View style={[styles.loginHeader]}>
              <Touchable onPress={this.navPchLogin}>
                <View>
                  <Image resizeMode="contain" style={[commonStyles.icon20]} source={back_icon} />
                </View>
              </Touchable>
              <View style={commonStyles.marginLeft16}>
                <Image resizeMode="contain" style={[commonStyles.logoImage]} source={xbpLogo} />
              </View>
            </View>)}
            <View style={[commonStyles.marginBottom35, commonStyles.marginTop20, commonStyles.alignItemsCenter]}>
              <View style={[commonStyles.textAlignCenter]}>
                <Text style={[styles.mainHeading, commonFontStyles.workSans, commonStyles.textAlignCenter]}>AUTHORIZED USER TERMS</Text>
              </View>
              <View style={[commonStyles.textAlignCenter]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading4, commonStyles.fullWidth, commonStyles.textAlignCenter]}>(Last updated on September 13, 2022)
              </Text>
              </View>
            </View>
            <View style={styles.pchPrivacyPolicyWrapper}>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}> 
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>Pursuant to a legal agreement between Customer (as defined below) and Exela Enterprise Solutions, Inc. (together with its subsidiaries, “Exela”) which govern the access to and use of the Xchange of Bills and Payment software and service (“XBP”), a party that is accessing or using XBP or related sites is a “Customer” or “User.”</Text> 
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                  Authorized User Terms
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  “User” or “Authorized User” is the user who is registered to use the XBP Portal and has been authorized by an Exela Customer to access and use the Services. The Customer is directly registered with/on Exela XBP Portal and is bound by the Terms of Service and Access Service Agreement, including these Authorized User Terms.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  These Authorized User Terms are between Exela and all Authorized Users of Customers, hereafter referred to as “you”, “they” or collectively, as “Authorized Users.”
                </Text>
              </View>

              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  All Authorized Users must be of legal age necessary to operate the user account.
                  </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Exela reserves the right to deny or revoke access to Authorized Users at any time in its sole discretion, if there is perceived to be a violation of any terms of use.
                  </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Exela does not provide any healthcare services for Authorized Users of any Customer, nor is it liable in any way for healthcare services provided by Customers to their Authorized Users.
                  </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Authorized User is bound by the Acceptable Usage Policy defined in this document.
                </Text>
              </View>

              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading5]}>
                Acceptable Use Policy
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  This Acceptable Use Policy sets out a list of acceptable and unacceptable conduct for the Authorized User.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                Any violation of this Acceptable Use Policy may result in the suspension of user access to the XBP Portal.
                </Text>
              </View>

              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The username and password are unique to User, and User agrees not to disclose or share their username and password to or with any third party. User is responsible for keeping its password confidential. User will notify Exela and Customer immediately if the password has been hacked or stolen.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  User agrees that it will be solely responsible for any activities conducted on or through the XBP Portal with its account regardless of whether or not the User is the individual who undertakes such activities. This includes any unauthorized access and/or use of the account or computer. User hereby releases and holds harmless Exela from any and all claims and causes of action arising out of or resulting from a third party’s unauthorized use of the User’s account.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  User’s use and access of the XBP Portal, is entirely at User’s own initiative and risk. User is solely responsible for compliance with local laws in its respective country with regard to the use of such services. Exela does not claim that the XBP Portal are fully compliant for use within all countries and territories from where the services may be accessed.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela might be updating the XBP Portal in order to provide the best possible experience for its users. All users acknowledge and agree that the form and nature of the services provided by the XBP Portal may change from time to time, features may be added, improved, or changed without prior notice as per the demands of changing technology, domain, and the best interests of the users.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  User shall not use the XBP Portal for any purposes other than those intended by Exela and as determined by Exela at its sole discretion.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  User shall not attempt to circumvent any security measure put in place for the XBP Portal users or attempt to gain unauthorized access to any services, user accounts, or computer systems or networks owned and/or controlled by Exela, through hacking, password mining or any other means.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  User shall not upload or submit any data or information containing viruses or any computer code, corrupt files or programs engineered or intended to disrupt or destroy the functionality of any software, hardware, telecommunications, networks, servers, or other equipment.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  User shall not attempt to access or obtain information regarding any account other than their own, by any means whatsoever. User may not use the XBP Portal in any manner that could damage, disable, overload, or impair Exela servers or networks, or interfere with any other party’s use and enjoyment of the site and services.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  User may not use any robot, spider, scraper, or other automated means to access the XBP Portal or website for any purpose without using BOT License and Exela written permission. Violation of this policy may result in termination of User access to the site, deactivation or deletion of registration and all related information and files the User has stored on the site.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  If User is, or employed by, a competitor service provider, you shall not use this Service to study and copy features and services provided by Exela XBP Portal...
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  In order to protect the integrity of the XBP Portal, Exela reserves the right at any time in its sole discretion to block User from certain IP addresses.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  User is responsible for the information that User voluntarily posts and User agrees to defend (at your sole expense), indemnify, and hold Exela harmless from any damages, losses, costs, or expenses which Exela may incur as a result of information User posts.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  All Authorized Users of a Customer are to be made aware of the following by the Customer:
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela does not provide medical services of any kind and as a technology provider only enables engagement with the healthcare provider, through the XBP Portal, which the Customer have opted to use.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela does not recommend or endorse any healthcare providers or services using the XBP Portal 
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Authorized Users’ access or use of the XBP Portal does not create in any way, a relationship that may be confidential or privileged or of any kind that would obligate Exela, to fulfil any duties towards meeting their health needs.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  The accuracy, integrity, and completeness of the personal health information that Customers Authorized Users enter or allow to be entered on their behalf is solely their responsibility, and Exela assumes no liability for it.
                </Text>
              </View>
              <View style={[commonStyles.marginBottom20, commonStyles.flex1, commonStyles.flexDirectionRow, styles.textStyleHolder]}>
                <Text style={[styles.ppListing, commonFontStyles.workSans, styles.ppListingSquare]}>●</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.textStyle]}>
                  Exela shall not be responsible for any undesirable outcomes in Authorized Users engagement with the Customers.
                </Text>
              </View>
            </View>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  mainHeading: {
    fontSize: 26,
  },
  pchPrivacyPolicy: {
    backgroundColor: "#fff",
  },
  pchPrivacyPolicyWrapper: {
    marginLeft:"auto", 
    marginRight: "auto", 
    padding: 16, 
    width: "80%",
    screen: ({ width }) => {
      if (width <= 991)
      return {
          width: "100%",
        };
    },
  },
  ppListing: {
    fontSize: 14,
    marginRight: 5,
    lineHeight: 28,
  },
  textStyleHolder: {
    textAlign: "justify",
  },
  textStyle: {
    lineHeight: 28,
    color: "#18191b",
  },
  ppListingSquare: {
    fontSize: 10,
    marginRight: 9,
  },
  linkColor: {
    color: "#5ccaf3",
    fontSize: 14,
  },
  logoImageContainer: {
    width: "100%",
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    boxShadow: "0px 0px 6px #00000033",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "flex",
          width: "auto",
          alignItems: "center",
          marginBottom: 16,
          paddingTop: 10,
          paddingRight: 16,
          paddingBottom: 10,
          paddingLeft: 16,
        };
    },
  },
  logoImage: {
    height: 55,
    width: 150,
    marginTop: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    display: "block",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          //	height: 48,
          //	width: 144,
        };
    },
  },
  logoIcon: {
    width: 160,
    height: 64,
    display: "block",
    backgroundSize: "contain",
  },
  icon55150: {
    height: 55,
    width: 150,
    display: "block",
    backgroundSize: "contain",
  },
  loginHeader: {
    display: "flex",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    textAlign: "center",
    alignItems: "center",
    position: "relaive",
    boxShadow: "0px 0px 6px #00000033",
  },
  mainContent: {
    marginTop: 75,
  },
  logoStyle: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});
