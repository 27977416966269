import React from "react";
import R14, { StyleSheet, View, Text, Image, Touchable, FlatList, Colors, Platform, SelectMenuField, Form, Checkbox, Button } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../assets/css/commonMetricsStyle";
import CommonUtility from "../utils/CommonUtility";
import StatusComponent from "./StatusComponent";
import DateUtils from "../utils/DateUtils";
import { MSG_TYPE, CURRENCY, BILL_STATUS, PAYMENT_MODE } from "../constant/app.data.constant";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import PaymentOptions from "./payments/PaymentOptions";
import { responsiveTableStyle } from "../assets/css/responsiveTableStyle";
import PCHLoaderComponent from "./PCH/PCHLoaderComponent";
import ModalPopUp from "../components/ModalPopUp";
import Bookmarked from "./Bookmared";
import getIpAddress from "../utils/GetIPAddress";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";

const verifiedDoneIcon = require("../assets/images/verified_done.png");
const delinedIcon = require("../assets/images/delined.png");

export default R14.connect(
    class BillAggregationComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                bills: [],
                totalBillAmount: 0,
                selectedBiller: '',
                selectedBillerAccounts: [],
                filteredBills: [],
                isLoading: false,
                paymentPaidMode: '',
                isSuccessfullPayment: false,
            };
            this.child = React.createRef();
            this.isMakePayment = false;
            this.MIN_AMOUMT = 0.50;
            this.MAX_AMOUNT = 999999.99;
        }

        async componentDidMount() {
            if (this.props.billerNameList.length === 1) {
                // deep copy of bills
                const bills = this.props.bills && this.props.bills.length ? JSON.parse(JSON.stringify(this.props.bills)) : [];

                // total bill amount
                let totalBillAmount = 0.00;
                // get overDue bills
                const overDueBills = await Promise.all(bills.map(async(bill) => {
                    let isConnnected = await this.props.app.dm.billDetails.validateBillAccount(bill?.paymentOption?.merchantAccountNumber);
                    if (!isConnnected || !bill.paymentOption || !bill.paymentOption.merchantAccountNumber) {
                        bill.isChecked = false;
                    } else if (!!isConnnected && bill.paymentStatus === 'Overdue' && bill.amountDue + totalBillAmount <= this.MAX_AMOUNT) {
                        bill.isChecked = true;
                        totalBillAmount += bill.amountDue;
                    } else if (!!isConnnected && bill.billStatus === 'Overdue' && bill.amountDue + totalBillAmount <= this.MAX_AMOUNT) {
                        bill.isChecked = true;
                        totalBillAmount += bill.amountDue;
                    } else {
                        bill.isChecked = false
                    }
                    bill.isConnnected = isConnnected;
                    return bill;
                }));

                // update total amount paid in google pay
                await this.updateBillAmount(totalBillAmount);

                // check filtered bills have same accountNumber
                const selectedBillerAccounts = await this.getAccounts(overDueBills)

                let docForm = this.props.app.ui.form("selectBillerForm");
                if(docForm && docForm.elmts && docForm.elmts.billerAccount && docForm.elmts.billerName) {
                    docForm.elmts.billerAccount.setItems(selectedBillerAccounts);
                    docForm.elmts.billerAccount.setValue(selectedBillerAccounts.length === 1 ? selectedBillerAccounts[0] : null);
                    docForm.elmts.billerName.setItems(this.props.billerNameList);
                    docForm.elmts.billerName.setValue(this.props.billerNameList[0]);
                }
                this.setState({
                    bills: bills,
                    selectedBiller: this.props.billerNameList[0].label,
                    // totalBillAmount: +overDueBillTotalAmount ? +overDueBillTotalAmount.toFixed(2) : 0,
                    totalBillAmount: selectedBillerAccounts.length === 1 && +totalBillAmount ? +totalBillAmount.toFixed(2) : 0,
                    filteredBills: selectedBillerAccounts.length >= 2 ? [] : bills,
                    selectedBillerAccounts: selectedBillerAccounts,
                })
            }
        }

        getAccounts = async (bills) => {
            const selectedBillerAccounts = [];
            for await (let bill of bills) {
                if (!!bill.isConnnected) {
                    const merchantNumber = bill.paymentOption && bill.paymentOption.merchantAccountNumber;
                    // check selectedBillerAccounts already have current bill merchant account
                    if (!selectedBillerAccounts.find(account => account.value === merchantNumber)) {
                        selectedBillerAccounts.push({
                            value: merchantNumber,
                            label: merchantNumber.substring(0, 1) + 'x'.repeat(merchantNumber.length - 3) + merchantNumber.substring(merchantNumber.length - 3),
                        })
                    }
                } else {
                    // check selectedBillerAccounts already have no payment option
                    if (!selectedBillerAccounts.find(account => account.value === 'No Payment Option')) {
                        selectedBillerAccounts.push({
                            value: 'No Payment Option',
                            label: `NA`
                        })
                    }
                }
            };
            // sort biller account by label
            if (selectedBillerAccounts.length) {
                selectedBillerAccounts.sort((accountA, accountB) => accountA.label.localeCompare(accountB.label));
            }
            return selectedBillerAccounts;
        }

        onBillerName = async (val) => {
            const { label, value } = val;
            if (label !== "No Results Found" && !!value) {
                // filter bills by biller id
                const bills = JSON.parse(JSON.stringify(this.props.bills));
                const filteredBillList = bills.filter(bill => bill.billerId === value);

                // total amount paid
                let totalAmount = 0.00;
                // get overDue bills
                const overDueBills = await Promise.all(filteredBillList.map(async(bill) => {
                    let isConnnected = await this.props.app.dm.billDetails.validateBillAccount(bill.paymentOption.merchantAccountNumber);
                    if (!isConnnected || !bill.paymentOption || !bill.paymentOption.merchantAccountNumber) {
                        bill.isChecked = false;
                    } else if (!!isConnnected && bill.paymentStatus === 'Overdue' && totalAmount + bill.amountDue <= this.MAX_AMOUNT) {
                        bill.isChecked = true;
                        totalAmount += bill.amountDue;
                    } else if (!!isConnnected && bill.billStatus === 'Overdue' && totalAmount + bill.amountDue <= this.MAX_AMOUNT) {
                        bill.isChecked = true;
                        totalAmount += bill.amountDue;
                    } else {
                        bill.isChecked = false;
                    }
                    bill.isConnnected = isConnnected;
                    return bill;
                }));

                // update total amount paid in google pay
                await this.updateBillAmount(totalAmount);

                // check filtered bills have same accountNumber
                const selectedBillerAccounts = await this.getAccounts(overDueBills);

                let docForm = this.props.app.ui.form("selectBillerForm");
                docForm.elmts.billerAccount.setValue(selectedBillerAccounts.length === 1 ? selectedBillerAccounts[0] : null);
                docForm.elmts.billerAccount.setItems(selectedBillerAccounts);

                if (selectedBillerAccounts.length === 1 && selectedBillerAccounts[0].value === 'No Payment Option') {
                    // Show pop-up
                    this.props.app.dm.billDetails.setState({ isShowErrorMsg: true })
                };

                this.setState({
                    selectedBiller: label,
                    bills: filteredBillList,
                    totalBillAmount: selectedBillerAccounts.length === 1 && +totalAmount ? +totalAmount.toFixed(2) : 0,
                    selectedBillerAccounts: selectedBillerAccounts,
                    filteredBills: selectedBillerAccounts.length >= 2 ? [] : filteredBillList,
                })
            } else {
                let docForm = this.props.app.ui.form("selectBillerForm");
                docForm.elmts.billerName.setValue(null);
                docForm.elmts.billerAccount.setValue(null);

                this.setState({
                    totalBillAmount: 0,
                    selectedBiller: '',
                    selectedBillerAccounts: [],
                    filteredBills: [],
                })
            }
        };

        onBillerAccount = async (val) => {
            const { value } = val;
            // if payment options are available
            if (value !== 'No Payment Option') {
                const bills = this.state.bills.filter(bill => bill.paymentOption && bill.paymentOption.merchantAccountNumber === value);

                // total bill amount
                let totalAmount = 0.00;

                // get overDue bills
                bills.map(bill => {
                    if (!bill.paymentOption || !bill.paymentOption.merchantAccountNumber) {
                        bill.isChecked = false;
                    } else if (bill.paymentStatus === 'Overdue' && totalAmount + bill.amountDue <= this.MAX_AMOUNT) {
                        bill.isChecked = true;
                        totalAmount += bill.amountDue;
                    } else if (bill.billStatus === 'Overdue' && totalAmount + bill.amountDue <= this.MAX_AMOUNT) {
                        bill.isChecked = true;
                        totalAmount += bill.amountDue;
                    } else {
                        bill.isChecked = false;
                    }
                    return bill;
                });

                // update total amount paid in google pay
                await this.updateBillAmount(totalAmount);

                this.setState({
                    filteredBills: bills,
                    totalBillAmount: +totalAmount ? +totalAmount.toFixed(2) : 0,
                })
            } else if (value === "No Results Found") {
                let docForm = this.props.app.ui.form("selectBillerForm");
                docForm.elmts.billerAccount.setValue(null);
                this.setState({
                    totalAmount: 0,
                    filteredBills: []
                })
            } else {
                const bills = this.state.bills.filter(bill => !bill.paymentOption);
                // Show pop-up
                this.props.app.dm.billDetails.setState({ isShowErrorMsg: true })

                this.setState({
                    filteredBills: bills,
                    totalBillAmount: 0
                })
            }
        }

        onSelectBill = (bill) => {
            let docForm = this.props.app.ui.form("selectBillerForm");
            let { billerAccount } = docForm.values;
            if (!bill.isConnnected || !billerAccount || billerAccount.label === "NA" || !bill.paymentOption || !bill.paymentOption.merchantAccountNumber) {
                return this.props.app.dm.billDetails.setState({ isShowErrorMsg: true })
            }

            // select bill
            if (bill && bill.uid && bill.billAmount && bill.amountDue > 0 && !bill.isChecked) {

                const totalAmount = (+this.state.totalBillAmount + bill.amountDue).toFixed(2);

                // Check if total bill amount is more than Max limit
                if (totalAmount > this.MAX_AMOUNT) {
                    return this.props.app.dm.commonDomain.showToastr("error", `Total payable amount can't be more than ${this.MAX_AMOUNT}`);
                }

                const checkBill = this.state.filteredBills.map(filteredBill => {
                    if (filteredBill.uid === bill.uid) filteredBill.isChecked = true;
                    return filteredBill;
                })
                analyticsLogs(CommonString.BULK_PAYMENT, `${CommonString.SELECT_BILL}`, this.props.app);


                // update total amount for Payment gateway (e.g. - google pay)
                this.updateBillAmount(totalAmount)

                this.setState({
                    totalBillAmount: totalAmount,
                    filteredBills: checkBill,
                })
            } else if (bill && bill.uid && bill.billAmount && this.state.totalBillAmount > 0 && bill.isChecked) {
                // unselect bill
                const unCheckBill = this.state.filteredBills.map(filteredBill => {
                    if (filteredBill.uid === bill.uid) filteredBill.isChecked = false;
                    return filteredBill;
                })
                analyticsLogs(CommonString.BULK_PAYMENT, `${CommonString.UNSELECT_BILL}`, this.props.app);

                const totalAmount = (+this.state.totalBillAmount - bill.amountDue).toFixed(2);
                // update total amount for Payment gateway (e.g. - google pay)
                this.updateBillAmount(totalAmount);

                this.setState({
                    filteredBills: unCheckBill,
                    totalBillAmount: totalAmount,
                })
            }
        }

        onAllSelectBills = async () => {
            // un select all bills - if all bill is checked then do unSelect all first
            if (this.state.filteredBills.every(bill => bill.isChecked)) {
                const unselectAllBills = this.state.filteredBills.map(bill => {
                    bill.isChecked = false;
                    return bill;
                });
                analyticsLogs(CommonString.BULK_PAYMENT, `${CommonString.UNSELECTED_ALL_BILLS}`, this.props.app);

                this.setState({
                    filteredBills: unselectAllBills,
                    totalBillAmount: 0
                })
            } else {
                let docForm = this.props.app.ui.form("selectBillerForm");
                let { billerAccount } = docForm.values;
                if (!billerAccount || billerAccount.label === "NA")
                    return false;
                // total bill amount;
                let totalAmount = 0.00;
                // select all bills
                const selectedBills = this.state.filteredBills.map(bill => {
                    // if bill have payment option then isChecked true other wise false
                    if (bill.paymentOption && bill.paymentOption.merchantAccountNumber && totalAmount + bill.amountDue <= this.MAX_AMOUNT) {
                        bill.isChecked = true;
                        totalAmount += bill.amountDue
                    } else {
                        bill.isChecked = false;
                    }
                    return bill;
                });
                analyticsLogs(CommonString.BULK_PAYMENT, `${CommonString.SELECTED_ALL_BILLS}`, this.props.app);

                await this.updateBillAmount(totalAmount);

                this.setState({
                    filteredBills: selectedBills,
                    totalBillAmount: totalAmount ? totalAmount.toFixed(2) : 0.00,
                })
            }
        }

        onCancel = (itm) => {
            if(itm && typeof itm === "string" && itm.startsWith("Bulk Payment done")) analyticsLogs(CommonString.BULK_PAYMENT, `${itm}`, this.props.app); 
            else analyticsLogs(CommonString.BULK_PAYMENT, `${CommonString.CLICKED_ON_CANCEL_BUTTON}`, this.props.app);
            this.props.app.nav.back();
        }

        addPaymentHistory = async(billData, payType, transaction)=>{
            const ipAddress = await getIpAddress();
            this.props.app.dm.achPaymentDomain.createBillPaymentHistory({
              billDetailId: billData.uid,
              paymentMode: transaction.paymentMode,
              paymentType: payType,
              curreny: billData.currency,
              paymentAmount: billData.amountDue,
              accountIntentId: transaction.achBankAccountId,
              transactionId: transaction.transactionId,
              status: "initiated",
              pId: billData.payerId,
              billerPid: billData.billerId,
              ipAddress: ipAddress,
              userAgentId: "",
              platform: Platform.OS,
              message: transaction.error,
              createdOn: new Date()
          });    
        }

        onPaymentDone = async (transactionId) => {
            let successPaidBills = [];
            const selectedBills = this.state.filteredBills.filter(bill => bill.isChecked);
            this.setState({ isLoading: true });
            for await (const billData of selectedBills) {
                const res = await this.props.app.dm.dashboard.getBillerFromThread(billData.threadId);
                let filterMsgs = [];
                if (res && res.messages && res.messages.length) {
                    filterMsgs = res.messages.filter((el) => el.envelope.messageBody && el.envelope.messageBody.messageType === 'RequestToPay');
                } else {
                    // there should be re-login message  [ or get new access token from refersh token ]
                    this.setState({ isLoading: false });
                    return this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
                }
                const msgList = filterMsgs;

                if (msgList[msgList.length - 1]) {
                    billData.threadHeader = msgList[msgList.length - 1].envelope.threadHeader;
                    billData.messageHeader = msgList[msgList.length - 1].envelope.messageHeader;
                    billData.messageMeta = msgList[msgList.length - 1].messageMeta;
                    billData.paymentOptions = msgList[msgList.length - 1].envelope.messageBody.paymentOptions[0].creditorAgent.financialInstitutionId;

                    billData.currency = msgList[msgList.length - 1].envelope.messageBody.paymentOptions[0].creditorAccount.currency;
                    billData.endToEndIdentification = msgList[msgList.length - 1].envelope.messageBody.endToEndIdentification;
                }

                // expected output: 1
                let clonedBill = { ...billData }; /// Cloning object

                clonedBill.amount = billData.amountDue;
                clonedBill.recipientPid = clonedBill.payerId;
                clonedBill.senderPid = clonedBill.billerId;

                if (!!transactionId.success) {
                    this.addPaymentHistory(billData, "Bulk Pay", transactionId);
                    clonedBill.transactionId = transactionId.transactionId;
                    clonedBill.additionalInformation = "Bulk payment with " + (transactionId && transactionId.paymentMode ? `"${transactionId.paymentMode}"` : "");;
                    let lastLeaf = {
                        ...msgList[msgList.length - 1],
                        billData: clonedBill,
                        attachments: null,
                        messageType: billData.biller.isPaymentsFull ? MSG_TYPE.PAY_ALL : MSG_TYPE.PAY_PART,
                    };

                    let paylod = await this.props.app.dm.billDetails.getPaymentPayload(lastLeaf, transactionId.paymentMode, true);

                    try {
                        let res = await this.props.app.dm.billDetails.postMessageForPayment(paylod);
                        if (res && res.threadId) {
                            let dbpayload = {
                                amountPaid: clonedBill.billAmount,
                                amountDue: 0.00, //0,
                                billStatus: "Paid",
                                bookmark: false,
                                uid: billData.uid,
                                rtpMessageType: billData.biller.isPaymentsFull ? MSG_TYPE.PAY_ALL : MSG_TYPE.PAY_PART,
                                paymentStatus: "In Process",
                                // fullPaidAndDeclined: paylod.envelope.messageHeader.messageDateTime,
                                isFailedPayment: false,
                                failDate: null,
                                lastPaidAmount: billData.amountDue,
                            };

                            if (transactionId.paymentMode === PAYMENT_MODE.ACH_PAY) {
                                dbpayload.achPaymentStatus = 'PaymentInitiated';
                                dbpayload.achPaymentIntentId = !!transactionId.achPaymentIntentId ? transactionId.achPaymentIntentId : null;
                                dbpayload.achBankAccountId = transactionId.achBankAccountId;
                                dbpayload.achBankAccountNumber = transactionId.achBankAccountNumber;
                                dbpayload.paymentType = dbpayload.rtpMessageType;
                                dbpayload.additionalInformation = !!clonedBill.additionalInformation ? clonedBill.additionalInformation : null;
                            }

                            dbpayload.transactionId = transactionId.transactionId;
                            dbpayload.paymentMode = transactionId.paymentMode;
                            dbpayload.messageId = res.messageId;
                            await this.props.app.dm.dashboard.updateBill({ ...dbpayload });
                            const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;

                            if (!!compiledMsgList.length && !!filterBills.length) {
                                let ind = compiledMsgList.findIndex((el) => el.uid === clonedBill.uid);
                                compiledMsgList[ind].billStatus = dbpayload.billStatus;
                                compiledMsgList[ind].amountDue = dbpayload.amountDue;
                                compiledMsgList[ind].amountPaid = dbpayload.amountPaid;
                                compiledMsgList[ind].bookmark = dbpayload.bookmark;
                                compiledMsgList[ind].lastPaidAmount = dbpayload.lastPaidAmount;
                                compiledMsgList[ind].paymentMode = dbpayload.paymentMode;
                                // compiledMsgList[ind].fullPaidAndDeclined = dbpayload.fullPaidAndDeclined;
                                compiledMsgList[ind].paymentStatus = dbpayload.paymentStatus;
                                compiledMsgList[ind].rtpMessageType = dbpayload.rtpMessageType;
                                compiledMsgList[ind].isFailedPayment = dbpayload.isFailedPayment;
                                compiledMsgList[ind].paymentType = dbpayload.paymentType;
                                let fndx = filterBills.findIndex((el) => el.uid === clonedBill.uid);
                                filterBills[fndx].billStatus = dbpayload.billStatus;
                                filterBills[fndx].amountDue = dbpayload.amountDue;
                                filterBills[fndx].amountPaid = dbpayload.amountPaid;
                                filterBills[fndx].bookmark = dbpayload.bookmark;
                                filterBills[fndx].paymentMode = dbpayload.paymentMode;
                                // filterBills[fndx].fullPaidAndDeclined = dbpayload.fullPaidAndDeclined;
                                filterBills[fndx].paymentStatus = dbpayload.paymentStatus;
                                filterBills[fndx].rtpMessageType = dbpayload.rtpMessageType;
                                filterBills[fndx].lastPaidAmount = dbpayload.lastPaidAmount;
                                filterBills[fndx].isFailedPayment = dbpayload.isFailedPayment;
                                filterBills[fndx].paymentType = dbpayload.paymentType;

                                await this.props.app.dm.dashboard.updateBillCount(compiledMsgList, filterBills);

                            }


                            let newBill = {
                                ...billData,
                                ...dbpayload,
                                paidAmount: parseFloat(billData.paidAmount) + parseFloat(billData.amountDue),
                            };
                            this.props.app.dm.billDetails.setState({
                                billDetails: newBill
                            });
                            this.setState({ isLoading: false });
                            successPaidBills.push(newBill.uid);
                        } else {
                            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
                        }
                    } catch (err) {
                        this.setState({ isLoading: false });
                        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
                    }
                } else {
                    clonedBill.transactionId = transactionId.transactionId;
                    let dbpayload = {
                        billStatus: "Failed",
                        uid: billData.uid,
                        isFailedPayment: true,
                        failDate: new Date().toUTCString(),
                        lastPaidAmount: billData.amountDue,
                        failReason: transactionId.error ? transactionId.error : "Transaction Failed.",
                    };
                    await this.props.app.dm.dashboard.updateBill({ ...dbpayload });
                    const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;
                    if (!!compiledMsgList.length && !!filterBills.length) {
                        let ind = compiledMsgList.findIndex((el) => el.uid === clonedBill.uid);
                        compiledMsgList[ind].billStatus = dbpayload.billStatus;
                        compiledMsgList[ind].failReason = dbpayload.failReason;
                        let fndx = filterBills.findIndex((el) => el.uid === clonedBill.uid);
                        filterBills[fndx].billStatus = dbpayload.billStatus;
                        filterBills[fndx].failReason = dbpayload.failReason;

                        await this.props.app.dm.dashboard.updateBillCount(compiledMsgList, filterBills);
                    }

                    let newBill = {
                        ...billData,
                        billStatus: dbpayload.billStatus,
                        isFailedPayment: dbpayload.isFailedPayment,
                        failDate: dbpayload.failDate,
                        failReason: dbpayload.failReason,
                    };
                    this.props.app.dm.billDetails.setState({
                        billDetails: newBill
                    });

                    if (!!!transactionId.isCancelByUser) {
                        this.props.app.dm.billDetails.setState({
                            isShowErrorMsg: true
                        })
                    }
                    this.setState({ isLoading: false });

                }

            }

            // if transaction is cancelByUser
            if (transactionId && transactionId.isCancelByUser) {
                this.props.app.dm.commonDomain.showToastr("error", transactionId.error ? transactionId.error : CommonString.TransactionFailed);
                this.setState({ isLoading: false });
            }

            // filter successful paid bills
            if (successPaidBills.length) {
                const removePaidBillsFromList = this.state.bills.filter(bill => !successPaidBills.includes(bill.uid));
                const removePaidBillsFromFilterList = this.state.filteredBills.filter(bill => !successPaidBills.includes(bill.uid));
                this.setState({
                    bills: removePaidBillsFromList,
                    filteredBills: removePaidBillsFromFilterList,
                    isSuccessfullPayment: true,
                    isLoading: false,
                    paymentPaidMode: transactionId.paymentMode,
                })
            }
        };

        updateBillAmount = async (amount) => {
            if (!!this.child && !!this.child.current && !!this.child.current.wrappedInstance && amount && amount !== "0.00") {
                await this.child.current.wrappedInstance.updateOnPartPayment('PAYPART', amount, amount);
            };
        }

        renderPaymentPaidModel(isSuccessfullPayment) {
            return (
                <ModalPopUp
                    visibility={!!isSuccessfullPayment}
                    isFooter={true}
                    bodyWrapper={[commonStyles.smallModalBodyWrapper, commonStyles.flexWrap]}
                    handleCancel={this.onCancel}
                >
                    <View style={[styles.paidSuccessfullyComponent, commonStyles.flex1, commonStyles.alignItemsCenter]}>
                        <View>
                            <Image source={verifiedDoneIcon} style={styles.verified_done} />
                        </View>
                        <View style={[styles.successTextHolder, commonStyles.textAlignCenter]}>
                            <Text style={[commonFontStyles.workSans, commonStyles.heading4, commonStyles.greenColor, styles.successText]}>
                                {CURRENCY.USD}
                                {`${this.state.totalBillAmount} Successfully paid to ${this.state.selectedBiller}`}
                            </Text>
                        </View>

                        {!!this.state.paymentPaidMode ? (
                            <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.flexDirectionColumn, commonStyles.justifyContentCenter]}>
                                <View>
                                </View>
                                <View style={[commonStyles.marginBottom15]}>
                                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.successText]}>Payment made with {this.state.paymentPaidMode}</Text>
                                </View>
                            </View>
                        ) : null}

                    </View>
                    <View style={[styles.modelPopupButton, commonStyles.marLT0, commonStyles.justifyContentCenter, styles.paymentSuccessModelOkBtn]}>
                        <Button
                            title={"OK"}
                            key="payment-success-model"
                            style={[styles.activeButton, customCommonStyles.noBoxShadow, commonStyles.blueButton, commonStyles.minWidth110]}
                            onPress={() => this.onCancel(`Bulk Payment done by ${this.state.paymentPaidMode}`)}
                            titleTextStyle={[commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6, commonStyles.whiteColor]}
                        />
                    </View>
                </ModalPopUp>
            )
        }


        onNavigate = (item) => {
            const { threadId } = item;
            analyticsLogs(CommonString.BULK_PAYMENT, `${CommonString.BILL_DETAILS_ACTION}`, this.props.app);
            let newThreadId = threadId; //.replaceAll("#", ">>");
            this.props.app.dm.billDetails.setState({
                billDetails: item
            });
            this.props.app.nav.to("billDetailsRoute", { threadId: newThreadId });
        };

        render() {
            const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
            let getData = [];
            if (this.state.bills && this.state.bills.length) getData = this.state.bills;
            if (this.state.filteredBills && this.state.filteredBills.length) getData = this.state.filteredBills;
            const { leftMenuToggle } = this.props.app.dm.userSession.state;
            let billDetails;
            if (this.state.filteredBills && this.state.filteredBills.some(bill => bill.isChecked)) {
                billDetails = this.state.filteredBills.find(bill => bill.isChecked);
            }
            let maxLimit = 20;
            return (
                <View style={[styles.table, !!getData.length <= 0 ? styles.tablepadding : null, commonStyles.flex1, customCommonStyles.BillAggregationComponentTable]}>
                    {/* Loading Indicator */}
                    <PCHLoaderComponent isVisiable={this.state.isLoading} labelText={"Please wait"} />
                    {this.renderPaymentPaidModel(this.state.isSuccessfullPayment)}

                    <View style={[styles.creatingMessageScreenInnerWrap]}>
                        <Form
                            name="selectBillerForm"
                            style={[commonStyles.flex0, commonStyles.flexGrow0, styles.createMessageForm]}
                            onSubmit={() => console.log('on submit form')}>
                            <View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.billNameAndAccHolder]}>
                                <View style={[styles.leftInputField, styles.billerNameStyle]}>
                                    <SelectMenuField
                                        style={[commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, commonStyles.marB0]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.tableKey]}
                                        name="billerName"
                                        label={this.props.billerNameList.length === 1 ? null : "Biller Name*"}
                                        // label={"Biller Name*"}
                                        required={CommonString.SelectBillerRequired}
                                        items={this.props.billerNameList}
                                        onValueChange={this.onBillerName}
                                        searchable
                                        // value
                                        itemLoader={async ({ search }) => {
                                            let items = this.props.billerNameList;
                                            return items
                                                .filter((item) => {
                                                    return item.label.toLowerCase().includes(search.toLowerCase());
                                                })
                                                .sort((a, b) => b.label - a.label);
                                        }}
                                        disabled={this.props.billerNameList.length === 1 ? true : false}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, this.props.billerNameList.length === 1 ? [customCommonStyles.textOverflowEllipsis, styles.billerNameLabelStyle] : null]}
                                        helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                    />
                                </View>
                                <View style={[styles.leftInputField, styles.billerAccountStyle]}>
                                    <SelectMenuField
                                        style={[
                                            commonFontStyles.workSans,
                                            commonStyles.heading6,
                                            commonFontStyles.workSans,
                                            this.state.selectedBillerAccounts.length === 1 && styles.disable,
                                            commonStyles.marB0
                                        ]}
                                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                        name="billerAccount"
                                        label={this.state.selectedBillerAccounts.length === 1 ? null : "Biller Account*"}
                                        // label={"Biller Account*"}
                                        required={CommonString.SelectAccountRequired}
                                        items={this.state.selectedBillerAccounts}
                                        onValueChange={this.onBillerAccount}
                                        searchable
                                        itemLoader={async ({ search }) => {
                                            let items = this.state.selectedBillerAccounts;
                                            const filterBills = items
                                                .filter((item) => {
                                                    return item.label.toLowerCase().includes(search.toLowerCase());
                                                });
                                            return filterBills;
                                        }}
                                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                        helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                    />
                                </View>
                                <View style={[styles.totalAmountContainer]}>
                                    <View style={[styles.totalAmountTextContainter]}>
                                        <Text style={[styles.totalAmountText, commonStyles.fullWidth, commonStyles.textAlignCenter, commonFontStyles.workSans, commonStyles.heading6, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemCenter]}>Total Amount</Text>
                                    </View>
                                    <View style={[styles.totalAmountValueContainter]}>
                                        <Text style={[styles.totalAmountText, commonFontStyles.workSans, commonStyles.heading6]}>${this.state.totalBillAmount}</Text>
                                    </View>
                                </View>
                            </View>
                        </Form>
                    </View>

                    {(this.state.selectedBiller || this.props.billerNameList.length === 1)
                        && this.state.filteredBills.length
                        && !!getData.length > 0 ? (
                        <View
                            style={[
                                responsiveTableStyle.thead,
                                commonStyles.justifyContentSpaceBetween, styles.theadHeight
                            ]}>
                            <View
                                style={[
                                    commonStyles.dFlex,
                                    commonStyles.flexGrow1,
                                    responsiveTableStyle.tdLeftSection,
                                    responsiveTableStyle.billerDirectoryBillerLeftSection,
                                    commonStyles.alignItemsCenter,
                                ]}>
                                <View
                                    style={[
                                        responsiveTableStyle.billerName,
                                        commonStyles.dFlex,
                                        responsiveTableStyle.billerDirectoryBillrName,
                                        responsiveTableStyle.leftMenuToggleDashboardBillname,
                                        styles.leftMenuToggleisBillerDirectoryBillname,
                                        leftMenuToggle ? null : styles.leftMenuToggleOffisBillerDirectoryBillname,
                                        commonStyles.alignItemsCenter
                                    ]}>
                                    {/* Check box item */}
                                    <View>
                                        <Checkbox
                                            name="isPrimary"
                                            label={`${CommonString.isPrimaryInsuranceLabel}*`}
                                            value={this.state.filteredBills ? this.state.filteredBills.every(bill => bill.isChecked) : false}
                                            onPress={() => this.onAllSelectBills()}
                                            colorBlank="#585858"
                                            colorMarked="#4dc5f2"
                                            disabled={this.state.filteredBills && this.state.filteredBills[0].paymentOption && this.state.filteredBills[0].paymentOption.merchantAccountNumber ? false : true}
                                        />
                                    </View>
                                    <View style={[responsiveTableStyle.th]}>
                                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Biller Name</Text>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.billerDetails,
                                        commonStyles.dFlex,
                                        commonStyles.flexGrow1,
                                        styles.billerDirectoryBillerDetailsTh,
                                        styles.leftMenuTogglebillerDirectoryBillerDetailsTh,
                                    ]}>
                                    <View
                                        style={[
                                            styles.th,
                                            styles.billerSubj,
                                            commonStyles.flexGrow1,
                                            styles.makePayBillerSubj,
                                            leftMenuToggle ? styles.leftMenuToggleMakePayBillerSubj : styles.leftMenuToggleOffMakePayBillerSubj,
                                        ]}>
                                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Subject</Text>
                                    </View>

                                    <View
                                        style={[
                                            styles.th,
                                            styles.dateAndTime,
                                            commonStyles.flexGrow1,
                                            styles.makePayDateAndTime,
                                        ]}>
                                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Date &#38; Time</Text>
                                    </View>

                                    <View
                                        style={[
                                            styles.th,
                                            styles.dueOn,
                                            commonStyles.flexGrow1,
                                            styles.dashboardDueOn,
                                        ]}>
                                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Due On</Text>
                                    </View>

                                </View>
                                <View
                                    style={[
                                        styles.billerStatus,
                                        commonStyles.dFlex,
                                        styles.tdRightSection,
                                        styles.billerDirectorytdRightSection,
                                        styles.dashboardMakePaymenttdRightSection,
                                        styles.billerDirectoryMakePaymenttdRightSection,
                                        styles.simpleMessageBillRightSectionTHead,
                                        styles.makePayBillRightSectionTHead,
                                        leftMenuToggle ? styles.leftMenuToggleMakePayBillRightSectionTHead
                                            : null,]}>
                                    <View style={[styles.th, styles.billerDirectoryStatus]}>
                                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tableKey]}>Status</Text>
                                    </View>

                                    <View
                                        style={[
                                            styles.th,
                                            styles.billAmount,
                                            styles.thBillAmount,
                                        ]}>
                                        <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.darkGrayColor, commonStyles.tableKey]}>Amount</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    ) : null}

                    {(this.state.selectedBiller || this.props.billerNameList.length === 1)
                        && this.state.filteredBills.length
                        && !!getData.length ? (
                        <View style={[styles.tboday]}>
                            {isMobileDevice ?
                                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.marginLeft16, commonStyles.alignItemsCenter, styles.selectAllCheckBoxSec]}>
                                    <View>
                                        <Checkbox
                                            name="isPrimary"
                                            label={`${CommonString.isPrimaryInsuranceLabel}*`}
                                            value={this.state.filteredBills ? this.state.filteredBills.every(bill => bill.isChecked) : false}
                                            onPress={() => this.onAllSelectBills()}
                                            colorBlank="#585858"
                                            colorMarked="#4dc5f2"
                                            disabled={this.state.filteredBills && this.state.filteredBills[0].paymentOption && this.state.filteredBills[0].paymentOption.merchantAccountNumber ? false : true}
                                        />
                                    </View>
                                    <View>
                                        <Text
                                            style={[
                                                commonMetricsStyle.heading6,
                                                commonStyles.fontWeight700,
                                                commonFontStyles.workSans,
                                                commonStyles.userInitial,
                                            ]}>
                                            Select All
                                        </Text>
                                    </View>
                                </View>
                                : null}
                            <FlatList
                                style={[
                                    styles.messageComponent,
                                    !!getData.length === 0 ? customCommonStyles.customDashboardNoListView : null,
                                    customCommonStyles.billAggregationComponent,
                                    getData.length === 1 ? styles.singleBillAggregation : null,
                                    getData.length === 0 ? styles.simpleBillMakePaymentNoListView : null,
                                ]}
                                ListEmptyComponent={
                                    <View >
                                    </View>
                                }
                                data={getData}
                                keyExtractor={(el, i) => i}
                                renderItem={({ item, index }) => (
                                    <View
                                        style={[
                                            styles.tr,
                                            commonStyles.flex1,
                                            commonStyles.flexDirectionRow,
                                            commonStyles.flexWrap,
                                            styles.billerDirectoryTr,
                                            getData.length - 1 === index ? styles.borderBottom1 : null,
                                        ]}>
                                        <View
                                            style={[
                                                commonStyles.dFlex,
                                                commonStyles.flexGrow1,
                                                styles.tdLeftSection,
                                                styles.thLeftSection,
                                                styles.billerDirectoryBillerLeftSection,
                                                styles.billerDirectoryBillerThLeftSection
                                            ]}>
                                            {/* Check box item */}
                                            <View style={[commonStyles.marginTop5]}>
                                                <Checkbox
                                                    name="isPrimary"
                                                    label="IsPrimary Insurance*"
                                                    value={item.isChecked}
                                                    onPress={() => this.onSelectBill(item)}
                                                    colorBlank="#585858"
                                                    colorMarked="#4dc5f2"
                                                />
                                            </View>
                                            {/* Biller Name Icon */}
                                            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                                                <View style={[responsiveTableStyle.bookmarkedOuter]}>
                                                    <View style={[commonStyles.dFlex, commonStyles.alignItemCenter, commonStyles.justifyContentFlexEnd]}>
                                                        {!BILL_STATUS[item.billStatus] && !item.biller.isBlocked ? (
                                                            <Bookmarked
                                                                bookedMarkSpacing={responsiveTableStyle.bookedMarkSpacing}
                                                                isBookmarked={item.bookmark}
                                                                source="bA"
                                                                bookedMarkImgStyle={[commonStyles.marLT0, commonStyles.marginRight15]}
                                                            />
                                                        ) : null}
                                                        {!!item.biller.isBlocked ? (
                                                            <Image source={delinedIcon} resizeMode="contain" style={[responsiveTableStyle.decline]} />
                                                        ) : null}
                                                    </View>
                                                </View>
                                                <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
                                                    <View>
                                                        {item.totalMessages - item.readMessages > 0 && item.status === "requested" && !!!item.biller.isBlocked ? (
                                                            <View style={[styles.messageNotification, customCommonStyles.customMessageNotification]}>
                                                                <Text
                                                                    style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonStyles.whiteColor]}>
                                                                    {item.totalMessages - item.readMessages}
                                                                </Text>
                                                            </View>
                                                        ) : null}
                                                    </View>

                                                    <Text
                                                        style={[
                                                            commonMetricsStyle.heading6,
                                                            commonStyles.fontWeight500,
                                                            commonFontStyles.workSans,
                                                            commonStyles.userInitial,
                                                            commonStyles.tableValue,
                                                            commonStyles.dBlock
                                                        ]}>
                                                        {CommonUtility.shortName(item.biller.billerName)}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>

                                        <View
                                            style={[
                                                styles.billerDetails,
                                                customCommonStyles.xbpCustomBillerDetails,
                                                commonStyles.flex1,
                                                commonStyles.flexGrow1,
                                                styles.customFlexDirection,
                                                styles.billerDirectoryBillerDetails,
                                                leftMenuToggle ? null : styles.leftMenuToggleBillerDirectoryBillerDetails,
                                            ]}>

                                            {/* Biller Name */}
                                            <View
                                                style={[
                                                    styles.billerName,
                                                    commonStyles.dFlex,
                                                    commonStyles.margin0,
                                                    commonStyles.marginLeft5,
                                                    commonStyles.alignItemsStart,
                                                    styles.dashboardBillerName,
                                                    styles.billerDirectoryTdBillrName,
                                                ]}>
                                                <View
                                                    style={[
                                                        styles.td,
                                                        commonStyles.dFlex,
                                                        commonStyles.alignItemsStart,
                                                        styles.billerDirectoryBillerName,
                                                    ]}>
                                                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.billName, customCommonStyles.wordBreakWord, commonStyles.flexDirectionRow, commonStyles.flexGrow0]}>
                                                        <Touchable onPress={() => this.onNavigate(item)} style={[responsiveTableStyle.billNameText, commonStyles.wordBreakWord,]}>
                                                            <View style={[
                                                                customCommonStyles.textOverflowEllipsis]}>
                                                                <Text
                                                                    style={[
                                                                        commonMetricsStyle.heading6,
                                                                        commonFontStyles.workSans,
                                                                        commonStyles.wordBreakWord,
                                                                        styles.billerDirectoryBillerNameText,
                                                                        styles.boldTextInMView,
                                                                        commonStyles.tableValue,
                                                                        commonStyles.dBlock
                                                                    ]}
                                                                    numberOfLines={1}>
                                                                    {/* {item.biller.billerName} */}
                                                                    {!!isMobileDevice ?
                                                                        !!item.biller.billerName && item.biller.billerName.length > maxLimit ? item.biller.billerName.substring(0, maxLimit - 3) + '...' : item.biller.billerName : item.biller.billerName}
                                                                </Text>
                                                            </View>
                                                        </Touchable>
                                                    </View>
                                                </View>
                                            </View>

                                            <View
                                                style={[
                                                    styles.td,
                                                    commonStyles.flexGrow1,
                                                    styles.tdType,
                                                    styles.billerSubj,
                                                    styles.tdBillerSubj,
                                                    styles.makePayTdBillerSubj,
                                                ]}>
                                                <View>
                                                    <View>
                                                        <Text
                                                            style={[styles.cardSubHeadingText, commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.wordBreakWord,
                                                            commonStyles.tableValue,
                                                            commonStyles.dBlock]}
                                                            numberOfLines={1}>
                                                            {/* {item.subject} */}
                                                            {/* {item.subject.length < 35 ? `${item.subject}` : `${item.subject.substring(0, 16)}...`} */}
                                                            {!!isMobileDevice ?
                                                                !!item.subject && item.subject.length > maxLimit ? item.subject.substring(0, maxLimit - 3) + '...' : item.subject : item.subject}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>

                                            <View
                                                style={[
                                                    styles.td,
                                                    commonStyles.flexGrow1,
                                                    styles.dateAndTime,
                                                    styles.tdDateAndTime,
                                                    styles.makePayTdDateAndTime,
                                                ]}>
                                                <Text style={[commonMetricsStyle.heading6, commonFontStyles.workSans, commonStyles.dBlock, commonStyles.fullWidth,
                                                commonStyles.tableValue,
                                                commonStyles.dBlock]}>
                                                    {DateUtils.dateAndTimeWithAmPm(item.billDate)}
                                                </Text>
                                            </View>

                                            {/* Biller Due On */}
                                            <View style={[styles.td, commonStyles.flexGrow1, styles.tdDueIn, styles.dueOn, styles.makePayTdDueIn, commonStyles.textAlignLeft]}>
                                                <View>
                                                    <Text
                                                        style={[
                                                            commonStyles.darkGrayColor,
                                                            commonMetricsStyle.heading6,
                                                            commonStyles.fontWeight500,
                                                            commonFontStyles.workSans,
                                                            styles.titleInMobile,
                                                            styles.boldTextInMView,
                                                            commonStyles.tableKey
                                                        ]}>
                                                        Due On
                                                    </Text>
                                                    <Text style={[commonStyles.darkGrayColor, commonMetricsStyle.heading6, commonFontStyles.workSans, 
                                                    commonStyles.tableValue,
                                                    commonStyles.dBlock]}>
                                                        {DateUtils.formatDate(item.dueDate)}
                                                    </Text>
                                                </View>
                                            </View>

                                        </View>
                                        {/* Biller Status */}

                                        <View
                                            style={[
                                                styles.billerStatus,
                                                commonStyles.dFlex,
                                                styles.tdRightSection,
                                                commonStyles.flexGrowUnset,
                                                styles.billerDirectorytdRightSection,
                                                styles.dashboardAlignItemsStart,
                                                commonStyles.justifyContentFlexStart,
                                                leftMenuToggle ? null : styles.leftMenuToggleTdRightSection,
                                                !!isMobileDevice ? styles.fullFlexHeight : null
                                            ]}>


                                            <View style={[styles.billStatus]}>
                                                <StatusComponent
                                                    marginRight={[commonStyles.marRT0, commonStyles.marB0]}
                                                    label={item.billStatus}
                                                    isSelected={false}
                                                    paymentStatusLabel={item.paymentStatus}
                                                    statusTextAlignment={[styles.customTextAlignmentRight]}
                                                    circleNotAllowed={false}
                                                    customStatusContainer={styles.customStatusContainer}
                                                />
                                            </View>

                                            <View style={[styles.td, styles.billAmount, styles.tdAmmount]}>
                                                <View>
                                                    <Text
                                                        style={[
                                                            commonStyles.darkGrayColor,
                                                            commonMetricsStyle.heading6,
                                                            commonStyles.fontWeight500,
                                                            commonFontStyles.workSans,
                                                            styles.titleInMobile,
                                                            styles.customTextAlignmentRight,
                                                            commonStyles.tableKey
                                                        ]}>
                                                        Amount
                                                    </Text>
                                                    <Text style={[commonStyles.darkGrayColor, commonMetricsStyle.heading6, commonFontStyles.workSans, styles.customTextAlignmentRight,
                                                    commonStyles.tableValue,
                                                    commonStyles.dBlock]}>
                                                        {CURRENCY.USD}{item.amountDue.toFixed(2)}
                                                    </Text>
                                                </View>
                                            </View>

                                        </View>
                                    </View>
                                )}
                            />
                        </View>
                    ) : null}
                    <View style={[styles.buttonContainers, customCommonStyles.billAggregationComponentButtonContainers]}>
                        <View style={[styles.modelPopupButton, commonStyles.marginTop15, commonStyles.flexWrap]}>
                            {this.state.totalBillAmount > 1 && billDetails && this.state.filteredBills.length ? (
                                <PaymentOptions
                                    ref={this.child}
                                    billerName={billDetails.biller.billerName}
                                    billerId={billDetails.biller.billerId}
                                    buId={billDetails.biller.buId}
                                    buName={billDetails.biller.buName}
                                    billDetailId={billDetails.uid}
                                    subject={'Bulk Payment'}
                                    amtPayable={+this.state.totalBillAmount}
                                    paymentOptions={billDetails.paymentOption}
                                    currencyCode={billDetails.currency}
                                    user={this.props.userDetail}
                                    pchClaim={false}
                                    bulkPay={true}
                                    payStyle={styles.applyPayStyle}
                                    googleBtnStyle={styles.googleBtnStyle}
                                    width={1}
                                    onPaymentDone={(res) => this.onPaymentDone(res)}
                                    isLoading={this.state.totalBillAmount >= this.MIN_AMOUMT && this.state.totalBillAmount <= this.MAX_AMOUNT ? false : true}
                                    cancelBtnStyle={[commonStyles.blueBorderButton, styles.minwidth, customCommonStyles.noBoxShadow, styles.customColorForButton]}
                                    cancelTextStyle={[commonStyles.blueBorderButtonColor, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                                    onCancel={this.onCancel}
                                    cancelText ="Cancel"
                                />
                            ) : null}
                        </View>
                    </View>

                </View>
            )
        }

    }
);

const styles = StyleSheet.create({
    table: {
        marginTop: 0,
        marginRight: 16,
        marginBottom: 0,
        marginLeft: 16,
        backgroundColor: "#fff",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    borderWidth: 0,
                    marginRight: 0,
                    marginLeft: 0,
                };
        },
    },
    tablepadding: {
        paddingTop: 0,
        paddingBottom: 0,
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderBottomColor: "#e2e2e2",
        marginBottom: 0,
    },
    creatingMessageScreenInnerWrap: {
        backgroundColor: "#fff",
        // marginTop: 16,
        // marginRight: 16,
        marginBottom: 16,
        // marginLeft: 16,
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                };
        },
    },
    createMessageForm: {
        paddingBottom: 0,
        paddingTop: 18
    },
    leftInputField: {
        flexBasis: "50%",
        maxWidth: "33%",
        screen: ({ width }) => {
            if (width <= 991){
                return {
                    maxWidth: "100%",
                    flexBasis: "100%",
                    marginRight: 0,
                    marginBottom: 16,
                };
            }
            if (width >= 992 && width <= 1024){
                return {
                    maxWidth: "49%",
                    flexBasis: "49%",
                };
            }
        },
    },
    billNameAndAccHolder: {
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024){
                return {
                    flexWrap: "wrap",
                    justifyContent: "space-between",

                };
            }
        },
    },
    billerNameStyle: {
        marginRight: 8,
        screen: ({ width }) => {
            if (width <= 991){
                return {
                    marginRight: 0,
                };
            }
            if (width >= 992 && width <= 1024){
                return {
                    marginRight: 14,
                };
            }
        },
    },
    billerAccountStyle: {
        marginRight: 8,
        screen: ({ width }) => {
            if (width <= 1024){
                return {
                    marginRight: 0,
                };
            }            
        },
    },
    customHelperTextStyle: {
        left: 0,
    },
    userIconDiv: {
        width: 25,
        minWidth: 25,
        maxWidth: 25,
        height: 25,
        maxHeight: 25,
        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
        borderBottomLeftRadius: 32,
        borderBottomRightRadius: 32,
        backgroundColor: "#e7e7e7",
        position: "relative",
        // marginLeft: 16,
        marginTop: 5,
        screen: ({ width }) => {
            if (width >= 991 && width <= 1024)
                return {
                    display: "none",
                };
        },
    },
    billName: {
        marginRight: 15,
        screen: ({ width }) => {
            if (width >= 991 && width <= 1024)
                return {
                    marginLeft: 0,
                };
        },
    },
    cardSubHeadingText: {
        color: "#444444",
        display: "block",
        fontSize: 14,
    },
    tr: {
        paddingTop: 0,
        paddingBottom: 0,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#dadada",
        borderBottom: 0,
        alignItems: "flex-start",
        borderLeftWidth: 0,
        borderRightWidth: 0,
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    paddingTop: 10,
                    paddingBottom: 10,
                    width: "100%",
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                };
        },
    },
    billerName: {
        width: "30.2%",
        paddingRight: 10,
        alignItems: "center",
        marginLeft: 4,
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "28%",
                };
            }
            if (width >= 1538 && width <= 1600) {
                return {
                    width: "28.8%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "29%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "43%",
                };
            }
            if (width >= 992) {
                return {
                    marginLeft: 0,
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    billerDetails: {
        width: "57%",
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    // alignItems: "self-start",
                    width: "100%",
                };
            }
            if (width <= 991) {
                return {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "baseline",
                    marginLeft: 0,
                };
            }
        },
    },
    thLeftSection: {
        width: "54%",
    },
    tdLeftSection: {
        width: "51%",
        paddingLeft: 15,
        paddingTop: 0,
        // alignItems: "center",
        screen: ({ width }) => {
            if (width <= 359) {
                return {
                    width: "34%",
                    flexDirection: "row",
                };
            }
            if (width <= 991) {
                return {
                    width: "34%",
                    alignItems: "flex-start",
                    flexDirection: "row",
                };
            }
            if (width >= 359 && width <= 991) {
                return {
                    width: "45%",
                    flexDirection: "row",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "56%",
                };
            }
        },
    },
    tdRightSection: {
        width: "30.7%",
        justifyContent: "space-between",
        paddingTop:5,
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    paddingRight: 16,
                    flexDirection: "column",
                    textAlign: "right",
                    alignItems: "flex-end",
                    width: "37%",
                };
            if (width >= 992 && width <= 1024) {
                return {
                    flexWrap: "wrap",
                };
            }
        },
    },
    billerSubj: {
        width: "32%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "24%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "30.2%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "25.7%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "30%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "31%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: 85,
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    makePayBillerSubj: {
        width: "22.5%",
        paddingLeft: 10,
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "24%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "31.2%",
                };
            }
            if (width >= 1518 && width <= 1536) {
                return {
                    width: "25.7%",
                };
            }
            if (width >= 1441 && width <= 1517) {
                return {
                    width: "32.7%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "21%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "23.5%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: 87,
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    leftMenuToggleOffMakePayBillerSubj: {
        screen: ({ width }) => {
            if (width >= 1680 && width <= 1919) {
                return {
                    width: "20%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: 103,
                };
            }
        },
    },
    leftMenuToggleMakePayBillerSubj: {
        screen: ({ width }) => {
            if (width >= 1680 && width <= 1920) {
                return {
                    width: "21%",
                };
            }
            if (width >= 1601 && width <= 1679) {
                return {
                    width: "20%",
                };
            }
             if (width >= 1441 && width <= 1517) {
                return {
                    width: "31.2%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "31.2%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "21%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "22.5%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: 77,
                };
            }
        }
    },
    makePayTdBillerSubj: {
        width: "28%",
        screen: ({ width }) => {
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "39%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "28%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: 79,
                };
            } else if (width <= 991) {
                return {
                    width: "100%",
                }
            } else {
                return {
                    // marginLeft: '18%'
                }
            }
        },
    },
    tdBillerSubj: {
        width: "29%",
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: 90,
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    dateAndTime: {
        width: "33.33%",
        // marginLeft: 9,
        marginRight: 9,
        screen: ({ width }) => {
            if (width >= 1601 && width >= 1920) {
                return {
                    width: "34.2%",
                };
            }
            if (width >= 1537 && width >= 1600) {
                return {
                    width: "34.2%",
                };
            }
            if (width >= 1441 && width >= 1536) {
                return {
                    width: "25%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "36.33%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "21.5%",
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    tdDateAndTime: {
        width: "45.33%",
        marginLeft: 15,
        marginRight: 15,
        screen: ({ width }) => {
            if (width <= 1024 && width >= 992) {
                return {
                    width: 85,
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                    marginLeft: 0,
                    marginRight: 0,
                };
            }
        },
    },
    makePayTdDueIn: {
        width: "29%",
        screen: ({ width }) => {
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "30%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: 105,
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    dashboardDueOn: {
        width: "19%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "18%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "20%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "26%",
                };
            }
        },
    },
    billStatus: {
        width: "48%",
        screen: ({ width }) => {
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "52%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "54%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "45%",
                    // marginTop: -10,
                    // order: 3,
                };
            }
            if (width <= 992) {
                return {
                    width: "100%",
                    textAlign: "right",
                };
            }
        },
    },
    billAmount: {
        width: "47%",
        marginLeft: 15,
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    width: "100%",
                };
        },
    },
    thBillAmount: {
        width: "44%",
        screen: ({ width }) => {
            if (width >= 1680 && width <= 1920) {
                return {
                    width: "44%",
                };
            }
            if (width >= 1601 && width <= 1679) {
                return {
                    width: "43%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "43%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "44%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "44%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "43%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "43%",
                };
            }
            if (width <= 991)
                return {
                    width: "100%",
                };
        },
    },
    titleInMobile: {
        display: "none",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    display: "block",
                };
        },
    },
    messageComponent: {
        // backgroundColor: "#fff",
        marginTop: 0,
        marginRight: 16,
        marginBottom: 30,
        marginLeft: 16,
        paddingTop: 0,
        display: "block",
        flexGrow: 0,
        position: 'relative',
        borderTop: 0,
        height: "calc(70vh - 200px)",
        screen: ({ width, height }) => {
            if ((width === 1024 && height === 1366) || (width === 1024 && height === 1292)  || (width === 1280 && height === 1292)) {
                return {
                    marginTop: 277,
                    marginBottom: 185,
                    // borderWidth: 0,
                }
            }
            if (width === 1024 && height === 1247) {
                return {
                    marginTop: 277,
                    marginBottom: 280,
                    // borderWidth: 0,
                }
            }
            if (width === 1366 && height === 950) {
                return {
                    marginBottom: 150,
                }
            }
            if (width === 1680 && height === 970) {
                return {
                    marginBottom: 250,
                }
            }
            if ((width === 1280 && height === 883) || (width === 1280 && height === 881)) {
                return {
                    marginBottom: 223,
                    // borderBottomColor: "transparent",
                }
            }
            if (height === 905 || height === 883) {
                return {
                    // borderWidth: 0,
                }
            }
            if ((width === 1024 && height === 768) || (width === 1024 && height === 664) || (width === 1024 && height === 649) || (width === 1024 && height === 707) ||( width === 1024 && height === 657) || (width === 1024 && height === 691)) {
                return {
                    marginTop: 277,
                    marginBottom: 180,
                    // borderBottom: 0,
                }
            }
            if (height === 768) {
                return {
                    marginBottom: 180,
                }
            }
            if (height === 1200) {
                return {
                    marginBottom: 60,
                }
            }
            if (width <= 991) {
                return {
                    marginTop: 320,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    paddingRight: 0,
                    paddingLeft: 0,
                    borderBottomWidth: 0,
                    borderTop: 1,
                };
            }
        },
    },
    simpleMessageBillRightSectionTHead: {
        width: "90px",
        minWidth: 90,
        flexGrow: 0,
        marginRight: 16,
        // screen: ({ width }) => {
        //   if (width >= 991 && width <= 1024) {
        //     return {
        //       width: "11%",
        //     };
        //   }
        // },
    },
    flexGrowUnset: {
        flexGrow: 0,
    },
    billerDirectoryBillerLeftSection: {
        width: "46.8%",
    },
    billerDirectoryBillerThLeftSection: {
        marginRight: 16,
        minWidth: 105,
        screen: ({ width }) => {
            if (width >= 991 && width <= 1024) {
                return {
                    width: "1.8%",
                };
            }
            if (width <= 991) {
                return {
                    width: "2%",
                    minWidth: 110,
                    maxWidth: 110,
                }
            } else {
                return {
                    width: '0%'
                }
            }
        },
    },
    billerDirectorytdRightSection: {
        minWidth: 128,
        width: 128,
        margin: 0,
        padding: 0,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minWidth: 42,
                    width: 42,
                };
            }
        },
    },
    dashboardAlignItemsStart: {
        width: "29%",
        paddingTop: 5,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "auto",
                    marginRight: 20
                };
            }
            if (width <= 1024) {
                return {
                    alignItems: "flex-start",
                };
            }
        },
    },
    leftMenuToggleTdRightSection: {
        width: "29%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "21%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "22%",
                };
            }
            if (width <= 991) {
                return {
                    width: "22%",
                };
            }
        },
    },
    makePaymentTheadRightSection: {
        width: "34%",
    },
    makePaymenttdRightSection: {
        width: "35%",
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: "19%",
                };
            }
        },
    },
    makePaymentTdRightPart: {
        width: "36.3%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "31.3%",
                };
            }
            if (width >= 320 && width <= 359) {
                return {
                    width: "37%",
                };
            }
            if (width >= 1281) {
                return {
                    width: "35.1%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "28%",
                    alignItems: "flex-start",
                };
            }
        },
    },
    makePaymentBillAmount: {
        width: "38%",
        screen: ({ width }) => {
            if (width >= 991 && width <= 1024) {
                return {
                    marginBottom: 10,
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: 121,
                };
            }
        },
    },
    makePaymentAction: {
        // width: "29%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "100%",
                    maxWidth: 125,
                };
            }
        },
    },
    makePaymentActionColumn: {
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    marginTop: 0,
                    marginRight: 16,
                    marginBottom: 0,
                    marginLeft: 0,
                };
            }
        },
    },

    // dasboard header css
    dashboardScreen: {
        flex: 0,
        alignItems: "flex-start",
        backgroundColor: "#fff",
        padding: 15,
        screen: ({ width }) => {
            if (width <= 640)
                return {
                    alignItems: "center",
                    margin: 0,
                    padding: 10,
                };
        },
    },
    filtereHading: {
        minWidth: 95,
        paddingRight: 16,
        paddingLeft: 16,
    },
    logoView: {
        ...StyleSheet.margin(32, 0, 48, 0),
    },
    textWrapperView: {
        paddingBottom: 16,
    },
    text: {
        color: Colors.onBackground,
    },
    viewAllLink: {
        color: "#0B4A87",
    },
    titleDiv: {
        marginBottom: 15,
    },
    btnTexClr: {
        color: "#333",
    },
    modalOuterWrapper: {
        width: "auto",
        backgroundColor: "#0000005c",
    },
    sessionExpirPopupBodyWrapper: {
        width: 315,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // boxShadow: "0px 0px 3px -1px #000",
        position: "relative",
    },
    bodyWrapper: {
        width: 300,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        // boxShadow: "0px 0px 3px -1px #000",
        position: "relative",
        // screen: ({ width }) => {
        //   if (width <= 360)
        //     return {
        //       width: "70%",
        //     };
        // },
    },
    popupTitle: {
        textAlign: "center",
        marginTop: 5,
        marginRight: 0,
        marginBottom: 30,
        marginLeft: 0,
        display: "block",
    },
    countBoxWrapper: {
        flexBasis: "32.20%",
        // width: "33.33%",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    width: "30%",
                    flexBasis: "30%",
                };
        },
    },
    countBox: {
        flex: 1,
        // width: 100,
        height: 85,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        overflow: "hidden",
        // boxShadow: "0px 2px 4px #00000029",
        flexDirection: "row",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexDirection: "column",
                };
            }
            if (width <= 640) {
                return {
                    marginTop: 0,
                    marginRight: 5,
                    marginBottom: 0,
                    marginLeft: 5,
                };
            }
        },
    },
    newBills: {
        backgroundColor: "#ABC6FC",
    },
    overdue: {
        backgroundColor: "#F2A9FC",
    },
    outstanding: {
        backgroundColor: "#FCE5B3",
        marginTop: 0,
        // marginRight: 15,
        marginBottom: 0,
        // marginLeft: 15,
        borderRadius: 5,
        screen: ({ width }) => {
            if (width >= 320 && width <= 359) {
                return {
                    marginTop: 0,
                    // marginRight: 5,
                    marginBottom: 0,
                    // marginLeft: 5,
                };
            }
            if (width >= 360 && width <= 640) {
                return {
                    marginTop: 0,
                    // marginRight: 16,
                    marginBottom: 0,
                    // marginLeft: 16,
                };
            }
        },
    },
    countBoxTitle: {},
    countBoxImage: {
        flexDirection: "row",
        paddingRight: 16,
        paddingLeft: 16,
        alignItems: "center",
        minWidth: 94,
        screen: ({ width }) => {
            if (width >= 320 && width <= 991) {
                return {
                    paddingRight: 6,
                    paddingLeft: 6,
                    backgroundColor: "#00000021",
                    flex: 1,
                    flexDirection: "row",
                    maxHeight: 42,
                };
            }
        },
    }, 
    countBoxHolder: {
        marginTop: 0,
        marginRight: 0,
        marginLeft: 0,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    paddingTop: 16,
                    paddingRight: 16,
                    paddingLeft: 16,
                    margin: 0,
                };
            }
        },
    },
    flex1: {
        flex: 1,
    },
    marB10: {
        marginBottom: 10,
    },
    closeBtn: {
        position: "absolute",
        right: 0,
        top: 10,
    },
    headerStyle: {
        color: "#4DC5F2",
        paddingTop: 20,
    },
    sessionModelHeaderStyle: {
        color: "#4DC5F2",
        paddingTop: 8,
    },
    sessionModelOkBtn: {
        width: 100,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        marginBottom: 10,
    },
    dashboardMessageComponent: {
        marginRight: 16,
        marginBottom: 50,
        marginTop: 0,
        marginLeft: 16,
        paddingTop: 0,
        paddingRight: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        display: "block",
        flexGrow: 0,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        borderTopWidth: 0,
        screen: ({ width }) => {
            if (width >= 320 && width <= 991) {
                return {
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    padding: 0,
                };
            }
        },
    },
    dashboardHeader: {
        // marginTop: 12,
        marginRight: 16,
        // marginBottom: 16,
        marginLeft: 16,
        // borderBottom: "1px solid #cfcfcf",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    backgroundColor: "#fff",
                    marginTop: 0,
                    marginRight: 0,
                    marginLeft: 0,
                    paddingTop: 12,
                };
            }
        },
    },
    dashboardScrollView: {
        backgroundColor: "#F4F4F4",
        zIndex: 1,
        elevation: (Platform.OS === 'android') ? 1 : 0
    },

    simpleMessageNoListView: {
        marginTop: 58,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    borderTopWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#e2e2e2",
                };
            }
        },
    },
    messageNotification: {
        width: 18,
        height: 18,
        maxHeight: 18,
        backgroundColor: "#C63F9B",
        borderTopLeftRadius: 18,
        borderTopRightRadius: 18,
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    makePaymentBillerName: {
        width: "25.6%",
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: 148,
                };
            }
            if (width <= 991) {
                return {
                    width: "100",
                };
            }
        },
    },
    dashboardBillerName: {
        // width: "26%",
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: "25%",
                };
            }
            if (width <= 991) {
                return {
                    marginLeft: 0,
                    paddingRight: 0,
                    height: "auto"
                }
            }
        },
    },
    thBillStatus: {
        // width: "30%",
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    display: "none",
                };
            }
        },
    },
    dashboardThBillStatus: {
        marginLeft: 0,
    },
    makePaymentThBillStatus: {
        width: "40%",
    },
    active: {
        color: "#218666",
    },
    requested: {
        color: "#800080",
    },
    new: {
        color: "#800080",
    },
    inprogress: {
        color: "#EE781E",
    },
    accepted: {
        color: "#218666",
    },
    denied: {
        color: "#B40220",
    },
    blocked: {
        color: "#B40220",
    },
    popupMenuStyle: {
        color: "red",
    },
    billerDirectoryBillerName: {
        screen: ({ width }) => {
            if (width <= 992) {
                return {
                    alignItems: "flex-start",
                };
            }
        },
    },
    billerDirectoryTr: {
        flexWrap: "wrap",
        screen: ({ width }) => {
            if (width >= 992) {
                return {
                    justifyContent: "space-between"
                };
            }
            if (width <= 992) {
                return {
                    paddingTop: 10,
                    paddingBottom: 10,
                    flexDirection: 'row',
                };
            }
        },
    },
    billerDirectoryBillerNameText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // width: 230,
        display: "block",
    },
    simpleMessageBillerNameAlignItems: {
        alignItems: "flex-start",
    },
    billerDirectoryBillrName: {
        width: "55%",
        screen: ({ width }) => {
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "53.4%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "54.5%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "97%",
                };
            }
        },
    },
    billerDirectoryTdBillrName: {
        width: "31.5%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "35.2%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "32%",
                };
            }
            if (width >= 1376 && width <= 1440) {
                return {
                    width: "28.5%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "22.5%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "18%",
                };
            }
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        },
    },
    leftMenuToggleBillerDirectoryTdBillrName: {
        screen: ({ width }) => {
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "51.8%",
                };
            }
        },
    },
    leftMenuToggleOffBillerDirectoryTdBillrName: {
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "52.2%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "51.8%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "51.5%",
                };
            }
            if (width >= 991 && width <= 1024) {
                return {
                    width: "67%",
                };
            }
        },
    },
    leftMenuToggleSimpleMessageScreenTdBillrName: {
        screen: ({ width }) => {
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "26%",
                };
            }
        },
    },
    leftMenuToggleOffSimpleMessageScreenTdBillrName: {
        screen: ({ width }) => {
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "26%",
                };
            }
        },
    },
    leftMenuToggleBillerDirectoryBillerDetails: {
        width: "52%",
        screen: ({ width }) => {
            if (width >= 1680 && width <= 1920) {
                return {
                    width: "65%",
                };
            }
            if (width >= 1601 && width <= 1679) {
                return {
                    width: "69%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "64%",
                };
            }
            if (width <= 991) {
                return {
                    width: "35%",
                    justifyContent: "flex-start",
                };
            }
        }
    },
    billerDirectoryBillerDetails: {
        width: "50%",
        paddingTop: 5,
        screen: ({ width }) => {
            if (width >= 1680 && width <= 1920) {
                return {
                    width: "56%",
                };
            }
            if (width >= 1601 && width <= 1679) {
                return {
                    width: "69%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "60%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "66%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "60%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "58%",
                    marginLeft: 0,
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "56%",
                    marginLeft: 0,
                };
            }
            if (width >= 993 && width <= 1024) {
                return {
                    width: "46%",
                    marginLeft: 0,
                };
            }
            if (width <= 992) {
                return {
                    width: "40%",
                    // marginLeft: 80,
                };
            }
        },
    },
    billerDirectoryBillerDetailsTh: {
        width: "17.3%",
    },
    leftMenuTogglebillerDirectoryBillerDetailsTh: {
        width: "17.3%",
    },
    leftMenuToggleisDashboardBillname: {
        width: "29.5%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "27.7%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "28.2%",
                };
            }
            if (width >= 1518 && width <= 1536) {
                return {
                    width: "28.9%",
                };
            }
            if (width >= 1441 && width <= 1517) {
                return {
                    width: "28.5%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "28.6%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "29.3%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "38.5%",
                };
            }
        },
    },
    leftMenuToggleDashboardBillname: {
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: "34.5%",
                };
            }
        },
    },
    leftMenuToggleMakePaymentBillname: {
        width: "29.7%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "27.7%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "28.2%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "28.7%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "28.6%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "29.3%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "46.5%",
                };
            }
        },
    },
    leftMenuToggleOffDashboardBillerSubj: {
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: "17.8%",
                };
            }
        },
    },
    leftMenuToggleOffMakePaymentBillname: {
        width: "33%",
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: "34%",
                };
            }
        },
    },
    leftMenuToggleTheadRightSection: {
        width: "35%",
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: "33.2%",
                };
            }
        },
    },
    leftMenuToggleOffMakePaymentBillerSubj: {
        width: "23%",
        screen: ({ width }) => {
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "23%",
                };
            }
            if (width >= 1441 && width <= 1517) {
                return {
                    width: "25%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "23.4%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "23.8%",
                };
            }
        },
    },
    leftMenuToggleBillerSubj: {
        width: "21%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "24%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "25.4%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "25.2%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "24.4%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "25.1%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "29%",
                };
            }
        },
    },
    leftMenuToggleBillStatus: {
        width: "34%",
    },
    leftMenuToggleBillAmount: {
        width: "38%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "36.9%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "37%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "36.5%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "36.5%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "37%",
                };
            }
        },
    },
    leftMenuToggleBillACtion: {
        width: 90,
        padding: 0,
    },
    leftMenuToggleDateAndTime: {
        screen: ({ width }) => {
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "35.2%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "35.33%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "15%",
                };
            }
        },
    },
    leftMenuToggleDueOn: {
        screen: ({ width }) => {
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "20%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "20%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "19%",
                };
            }
        },
    },
    leftMenuToggleisOffBillerDirectoryBillname: {
        width: "39.6%",
        screen: ({ width }) => {
            if (width >= 1920) {
                return {
                    width: "53%",
                };
            }
            if (width >= 1600 && width <= 1919) {
                return {
                    width: "50%",
                };
            }
            if (width >= 1537 && width <= 1599) {
                return {
                    width: "53%",
                };
            }
            if (width >= 1518 && width <= 1536) {
                return {
                    width: "39.7%",
                };
            }
            if (width >= 1441 && width <= 1517) {
                return {
                    width: "22.3%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "39.5%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "50%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "63%",
                };
            }
        },
    },
    leftMenuToggleOffisBillerDirectoryBillname: {
        width: "23%",
        screen: ({ width }) => {
            if (width >= 1920) {
                return {
                    width: "53%",
                };
            }
            if (width >= 1680 && width <= 1919) {
                return {
                    width: "24.4%",
                };
            }
            if (width >= 1600 && width <= 1679) {
                return {
                    width: "22%",
                };
            }
            if (width >= 1537 && width <= 1599) {
                return {
                    width: "53%",
                };
            }
            if (width >= 1518 && width <= 1536) {
                return {
                    width: "39.7%",
                };
            }
            if (width >= 1441 && width <= 1517) {
                return {
                    width: "22.4%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "21.7%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "20%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "21.6%",
                };
            }
        },
    },
    leftMenuToggleisBillerDirectoryBillname: {
        width: "24.1%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "22.6%",
                };
            }
            if (width >= 1600 && width <= 1919) {
                return {
                    width: "21%",
                };
            }
            if (width >= 1537 && width <= 1599) {
                return {
                    width: "20.4%",
                };
            }
            if (width >= 1518 && width <= 1536) {
                return {
                    width: "20.4%",
                };
            }
            if (width >= 1441 && width <= 1517) {
                return {
                    width: "21.5%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "21.7%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "20.5%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "24%",
                };
            }
        },
    },
    leftMenuToggleSimpleMessageDateAndTime: {
        width: "32.3%",
    },
    leftMenuToggleOffSimpleMessageBillACtion: {
        minWidth: 90,
    },
    leftMenuToggleisSimpleMessageBillname: {
        width: "29.4%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "27%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "27.8%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "28.5%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "28.3%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "28.8%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "30.5%",
                };
            }
        },
    },
    leftMenuToggleisOffSimpleMessageBillname: {
        width: "28.6%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "27%",
                };
            }
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "28.4%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "28%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "27.8%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "28.3%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "29%",
                };
            }
        },
    },
    leftMenuToggleSimpleMessageBillerSubj: {
        width: "32%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "32.6%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "32.7%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "32.5%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "32.5%",
                };
            }
        },
    },
    leftMenuToggleDashboardBillACtion: {
        minWidth: 90,
        marginRight: 16,
        padding: 0,
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: 90,
                };
            }
        },
    },
    dashboardMakePaymenttdRightSection: {
        width: "35.8%",
        screen: ({ width }) => {
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "34.8%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "34.3%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "34.8%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "28%",
                };
            }
        },
    },
    LeftMenuOffdashboardMakePaymenttdRightSection: {
        width: "35.8%",
        screen: ({ width }) => {
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "34.8%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "34.8%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "34.8%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "33%",
                };
            }
        },
    },
    leftMenuToggleMakePayBillRightSectionTHead: {
        width: "27.2%",
        screen: ({ width, height }) => {
            if (width >= 1680 && width <= 1920) {
                return {
                    width: "27.9%",
                };
            }
            if (width >= 1601 && width <= 1679) {
                return {
                    width: "20%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "27.8%",
                };
            }
            if (width === 1024 && height === 707) {
                return {
                    width: "26.5%",
                };
            }
        }
    },
    makePayBillRightSectionTHead: {
        width: "27.2%",
        screen: ({ width }) => {
            if (width >= 1601 && width <= 1920) {
                return {
                    width: "20%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "20.8%",
                };
            }
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "20.8%",
                };
            }
            if (width >= 1367 && width <= 1440) {
                return {
                    width: "27.5%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "27.5%",
                };
            }
            if (width >= 992 && width <= 1024) {
                return {
                    width: "26.5%",
                };
            }
        },
    },
    billerDirectoryMakePaymenttdRightSection: {
        minWidth: 45,
        width: 45,
        marginRight: 16,
        // screen: ({ width }) => {
        //   if (width >= 992 && width <= 1024) {
        //     return {
        //       width: 90,
        //     };
        //   }
        // },
    },
    priorityInfoTooltipHolder: {
        position: "relative",
    },
    priorityInfoTooltip: {
        position: "absolute",
        backgroundColor: "#333",
        color: "#fff",
        zIndex: 999999,
        padding: 10,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        textAlign: "center",
    },
    priorityInfoTooltipArrow: {
        width: 0,
        height: 0,
        position: "absolute",
    },
    bookedMark: {
        height: 22,
        width: 15,
        marginTop: 0,
        marginRight: 5,
        marginBottom: 0,
        marginLeft: 5,
        display: "block",
        backgroundSize: "contain",
    },
    top50: {
        top: 50,
    },
    top0: {
        top: 0,
        inset: "0px 0px 0px",
    },
    priorityListTxt: {
        marginRight: -10,
    },
    filterBySection: {
        position: "relative",
        zIndex: 9999,
        backgroundColor: "#fff",
        alignItems: "center",
        // marginTop: 16,
        paddingTop: 5,
    },
    positionStatic: {
        marginTop: 0,
        padding: 0,
        position: "static",
    },
    // textOverflowEllipsis: {
    //   width: "95%",
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    //   whiteSpace: "nowrap",
    //   display: "block",
    // },
    customPaddingRight16: {
        paddingRight: 16,
        screen: ({ width }) => {
            if (width <= 992 && width >= 1024) {
                return {
                    paddingRight: 0,
                };
            }
        },
    },
    dashboardTitleDiv: {
        backgroundColor: "#fff",
        margin: 0,
        paddingTop: 7,
        paddingRight: 16,
        paddingBottom: 7,
        paddingLeft: 16,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderWidth: 1,
        borderColor: "#e2e2e2",
        borderStyle: "solid",
        borderBottomWidth: 0,
        screen: ({ width }) => {
            if (width <= 992) {
                return {
                    borderWidth: 0,
                };
            }
        },
    },
    countBoxTitleText: {
        fontSize: 18,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    fontSize: 13,
                };
            }
            // if (width >= 360 && width <= 991) {
            //   return {
            //     fontSize: 25,
            //   };
            // }
        },
    },
    countBoxTextHolder: {
        paddingTop: 0,
        paddingRight: 16,
        paddingBottom: 0,
        paddingLeft: 16,
        screen: ({ width }) => {
            if (width <= 992) {
                return {
                    paddingTop: 0,
                    paddingRight: 6,
                    paddingBottom: 0,
                    paddingLeft: 6,
                    justifyContent: "center",
                };
            }
        },
    },
    noRecords: {
        margin: 0,
        screen: ({ width }) => {
            if (width <= 992) {
                return {
                    marginTop: 0,
                    marginRight: 16,
                    marginBottom: 200,
                    marginLeft: 16,
                };
            }
        },
    },

    pageTitle: {
        marginTop: 15,
        marginBottom: 15,
        // backgroundColor: "#00000000",
        paddingLeft: 16,
        screen: ({ width }) => {
            if (width <= 992) {
                return {
                    paddingTop: 12,
                    paddingRight: 16,
                    paddingBottom: 12,
                    paddingLeft: 16,
                    marginTop: 0,
                    marginBottom: 0,
                    // backgroundColor: "#fff",
                };
            }
        },
    },
    billCountText: {
        fontSize: 32,
        screen: ({ width }) => {
            if (width <= 992) {
                return {
                    marginTop: 0,
                    marginRight: 16,
                    marginBottom: 0,
                    marginLeft: 16,
                    fontSize: 25,
                };
            }
        },
    },
    borderBottom1: {
        borderBottomWidth: 1,
        borderColor: "#dadada",
        borderStyle: "solid",
    },
    bookedMarkTh: {
        width: 26,
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    width: 0,
                    marginRight: 0,
                };
            }
        },
    },
    dashboardBookedMarkTh: {
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    marginRight: 10,
                };
            }
        },
    },
    bookmarkedOuter: {
        width: 26,
        marginTop: 5,
        screen: ({ width }) => {
            if (width >= 992 && width <= 1024) {
                return {
                    display: "none",
                };
            }
        },
    },
    newCounter: {
        borderBottom: "2px solid #58c8f2",
        paddingBottom: 10,
    },
    accountNumberCls: {
        width: "19%",
        paddingRight: 16,
    },
    leftToggleOffAccountNumberCls: {
        width: "16.5%",
        paddingRight: 16,
        screen: ({ width }) => {
            if (width <= 992) {
                return {
                    width: "100%",
                    // marginLeft: 5,
                };
            }
        },
    },
    accountNumberTh: {
        width: "16.5%",
    },
    leftMenuToggleAccountNumberTh: {
        width: "16.5%",
        screen: ({ width }) => {
            if (width >= 1441 && width <= 1600) {
                return {
                    width: "19.5%",
                };
            }
            if (width >= 1281 && width <= 1366) {
                return {
                    width: "17.5%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "19.5%",
                };
            }
            if (width >= 991 && width <= 1024) {
                return {
                    width: "12.5%",
                };
            }
        },
    },
    leftMenuToggleOffAccountNumberTh: {
        width: "16.5%",
        screen: ({ width }) => {
            if (width >= 1537 && width <= 1600) {
                return {
                    width: "18.5%",
                };
            }
            if (width >= 1441 && width <= 1536) {
                return {
                    width: "18.5%",
                };
            }
            if (width >= 991 && width <= 1024) {
                return {
                    width: "15.5%",
                };
            }
        },
    },
    customTextAlignmentRight: {
        screen: ({ width }) => {
            if (width <= 992) {
                return {
                    textAlign: "right",
                    // minWidth: 100,
                };
            }
        },
    },
    customFlexDirection: {
        flexDirection: "row",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexDirection: "column"
                }
            }
        }
    },
    boldTextInMView: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    fontWeight: 500,
                    color: "#182832",
                }
            }
        }
    },

    simpleBillMakePaymentNoListView: {
        marginTop: 50,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        paddingRight: 16,
        paddingLeft: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    paddingRight: 0,
                    paddingLeft: 0,
                }
            }
        }
    },
    billNameText: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minWidth: "75%",
                }
            }
        }
    },
    bookMarkIcon: {},
    simpleMessageTr: {
        alignItems: "flex-start",
    },
    billerDirectoryNoListView: {
        marginTop: 16,
    },
    makePaymentNobillComponentWithFilter: {
        marginTop: 0,
        border: 0,
    },
    makePaymentNobillComponent: {
        marginTop: 16,
    },
    countBoxImg: {
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    marginTop: 11,
                }
            }
        }
    },
    customStatusContainer: {
        paddingRight: 0,
        paddingLeft: 0,
    },
    totalAmountMainContainer: {
        flex: 1,
        width: '40%',
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    totalAmountContainer: {
        flex: 1,
        height: 33,
        flexDirection: 'row',
        // width: '70%',
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#d0d0d0",
        alignItems: 'center',
        justifyContent: 'center',
        // marginLeft: '12.5%',
        width: '50%',
        borderRadius: 5,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 24,
                    width: 'auto'
                }
            }
            if (width >= 992 && width <= 1024) {
                return {
                    marginTop: 26,
                }
            }
        }
    },
    totalAmountTextContainter: {
        flex: 1,
        flexBasis: '50%',
        height: 33,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#d0d0d0',
        borderRadius: "4px 0 0 3px",
        marginLeft: -1,
        marginTop: -1,
    },
    totalAmountText: {
        // textAlign: 'center',
        fontWeight: 500
    },
    totalAmountValueContainter: {
        flex: 1,
        flexBasis: '48%',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',

        backgroundColor: 'white',
        marginRight: 2.5
    },
    buttonContainers: {
        // flex: 1,
        flexDirection: "row",
        marginTop: 60,
        marginBottom: 20,
        flexGrow: 0,
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    // flex: 1,
                    // flexDirection: 'row',
                    // flexGrow: 0,
                }
            }
        }
    },
    modelPopupButton: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    flexBasis: "50%",
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end'
                }
            } else {
                return {
                    marginRight: 0
                }
            }
        }
    },
    paymentSuccessModelOkBtn: {
        marginRight: 0,
    },
    activeButton: {
        backgroundColor: "rgba(255,255,255, 1.0)",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "#37c2ef",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        boxShadow: "none",
        height: 80,
        maxHeight: 80,
        // marginRight: 10,
        // marginTop: 10,
        justifyContent: 'center',
        minWidth: 120,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: '40vw',
                    height: 38,
                    marginTop: 15,
                    alignItems: "flex-end"

                }
            }
        }
    },
    blueBackground: {
        backgroundColor: '#37c2ef',
    },
    billerStatusContainer: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    alignItems: 'flex-start',
                }
            } else {
                return {
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }
        }
    },
    preferenceButton: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: '30vw',
                    height: 38,
                    marginTop: 5,
                }
            }
        }
    },
    maxHeight50vh: {
        maxHeight: "60vh",
    },
    loading: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 300,
        bottom: 0,
        // minHeight: "100%",
        // minWidth: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    mediumModalWrapper: {
        width: '80vw',
        screen: ({ width }) => {
            if (width >= 991) {
                return {
                    width: '50vw',
                }
            }
        }
    },
    paidSuccessfullyComponent: {
        position: "relative",
        // minHeight: 210,
        // paddingTop: 10,
        // paddingRight: 15,
        // paddingBottom: 10,
        // paddingLeft: 15,
    },
    verified_done: {
        height: 36,
        width: 36,
        display: "block",
        backgroundSize: "contain",
    },
    successImage: {
        marginTop: 22,
        marginRight: 0,
        marginBottom: 10,
        marginLeft: 0,
    },
    successText: {
        lineHeight: 29,
    },
    successTextHolder: {
        marginTop: 16,
        marginRight: 0,
        marginBottom: 16,
        marginLeft: 0,
    },
    disable: {
        opacity: 0.5,
        pointerEvents: "none",
    },
    minwidth: {
        minWidth: 95,
        width: 95,
        screen: ({ width }) => {
            if (width > 991) {
                return {
                    width: 110
              }
            }
          }
    },
    customColorForButton: {
        borderColor: "#1277D9",
        height: 24,
        maxHeight: 24,
        minHeight: 24
    },
    applyPayStyle: {
        width: 155,
        minWidth: 155,
        maxWidth: 155,
        marginTop: 10
    },
    billerNameLabelStyle: {
        maxWidth: "90%",
    },
    singleBillAggregation: {
        // border: 0,
    },
    fullFlexHeight: {
        height: "100%",
        justifyContent: "space-between"
    },
    customBodyWrapperWidth: {
        width: "50%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "70%"
              }
            }
        }
    },
    selectAllCheckBoxSec: {
        screen: ({ width }) => {
            if (width >= 1024) {
                return {
                    display: "none",
              }
            }
        }
    },
    theadHeight: {
        height: 25,
        minHeight: 25,
        paddingBottom: 3,
        paddingTop: 0
    },
    googleBtnStyle:{
        display:"flex",
        justifyContent:"center",
        backgroundColor:"#d71996",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        height: 24,
        marginLeft: 10,
        overflow: "hidden",
        screen: ({ width }) => {
            if (width > 991) {
                return {
                 width: 110, 
              }
            }
        }
      }
});
