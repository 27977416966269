import React from "react";
import R14, { StyleSheet, ScreenLayout, Text, View, ScrollView } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import LeftMenuComponent from "../components/LeftMenuComponent";
import HeaderControlsLeft from "../components/HeaderControlsLeft";
import HeaderControlsRight from "../components/HeaderControlsRight";
import CopyRight from "./CopyRight";

export default R14.connect(
    class LayoutComponent extends React.Component {

        renderPageTitleSection() {
            const { hideHeader = false } = this.props;
            return (
                !!this.props.r14.navigation.currRoute._config.label && !hideHeader ?
                    <View style={[styles.pageTitleHolder]}>
                        <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, styles.pageTitle]}>
                            {!this.props.hideTextHeader ? this.props.r14.navigation.currRoute._config.label : ""}{" "}
                        </Text>
                    </View> : null
            );
        }

        render() {
            const { leftMenuToggle } = this.props.app.dm.userSession.state;
            const { isAuthenticated = true, hideCopyright = false, } = this.props;
            return (
                <ScreenLayout style={[styles.layoutScreen, commonStyles.padding0]}>
                    {isAuthenticated ? <View style={[styles.header]}>
                        <View style={[styles.headerControl]}>
                            <View style={[styles.controlsLeft]}>
                                <HeaderControlsLeft />
                            </View>
                            <View style={[styles.controlsRight]}>
                                <HeaderControlsRight />
                            </View>
                        </View>
                    </View> : null}
                    <ScrollView style={[commonStyles.dashboardScrollView, isAuthenticated ? (leftMenuToggle ? commonStyles.openMenu : commonStyles.closeMenu) : null]}>
                        <View style={[styles.container, commonStyles.dashboardScrollView, commonStyles.flex1, !!isAuthenticated ? null : commonStyles.marT0]}>
                            {this.renderPageTitleSection()}
                            {this.props.children}
                        </View>
                        <View style={[commonStyles.textAlignCenter, commonStyles.copyRightWrapper, !!hideCopyright ? commonStyles.displayNone : null]}>
                            <CopyRight />
                        </View>
                    </ScrollView>
                    {isAuthenticated ? <LeftMenuComponent /> : null}
                </ScreenLayout>
            );
        }
    }
);

const styles = StyleSheet.create({
    layoutScreen: {
        padding: 15,
        ...StyleSheet.margin(0, 0, 0, 0),
        height: "100%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    backgroundColor: "#f4f4f4",
                };
            }
            if (width <= 640) {
                return {
                    margin: 0,
                    paddingBottom: 20
                };
            }
        },
    },
    pageTitleHolder: {
        zIndex: 999,
        paddingRight: 16,
        paddingLeft: 16,
        marginTop: 15,
        marginBottom: 15,
        background: "#f4f4f4",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 0,
        maxHeight: 20,
    },
    pageTitle: {
        color: "#151b26",
    },

    container: {
        marginTop: 40,
    },
    header: {
        boxShadow: "none",
        top: 0,
        right: 0,
        left: 0,
        display: "block",
        height: 40,
        minHeight: 40,
        maxHeight: 40,
        minWidth: 320,
        zIndex: 1000,
    },
    headerControl: {
        width: "auto",
        flex: 1,
        flexDirection: "row",
        margin: 0,
        alignItems: "center",
        backgroundColor: "rgb(255, 255, 255)",
        color: "rgb(21, 27, 38)",
    },
    controlsLeft: {
        flexDirection: "row",
        margin: 0,
        alignItems: "center",
        opacity: 0.94,
        flex: 1,
        flexGrow: 0,
        maxHeight: 40,
        minWidth: 100,
    },
    controlsRight: {
        flexDirection: "row",
        margin: "0px 0px 0px auto",
        alignItems: "center",
        opacity: 0.94,
        flex: 1,
        flexGrow: 1,
    },
});
