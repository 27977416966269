import React from "react";
import R14, { AsyncStorage } from "../core";
import ResponsiveTableComponent from "../components/ResponsiveTableComponent";
import { commonStyles } from "../assets/css/commonStyle";

import CommonUtility from "../utils/CommonUtility";
import DateUtils from "../utils/DateUtils";
import { CURRENCY, BILL_STATUS, MSG_TYPE, BILL_OPTS, } from "../constant/app.data.constant";
import { analyticsLogs } from "../utils/AnalyticLogs";

import { CommonString } from "../constant/appCommonText";
import JsonToCSV from "../utils/JsonToCSV";
import HtmlDecode from "../utils/HtmlDecode";

const defaultSec = 10; //60

export default R14.connect(
  class DashboardScreen extends React.Component {
    constructor(props) {
      super(props);
      AsyncStorage.setItem("homeScreen", this.props.r14.navigation.activeRouteName);
      this.timerInstance = null;
    }

    async componentDidMount() {
      this.timerInstance = setInterval(async () => {
        const { isSearchOpen } = this.props.app.dm.userSearchDomain.state;
        const { selectedScreen } = this.props.app.dm.dashboard.state;
        if (!!isSearchOpen) return false;
        else {
          if (selectedScreen === BILL_OPTS[0].value)
            await this.props.app.dm.dashboard.setCompiledMsgList(false);
          else if (selectedScreen === BILL_OPTS[1].value) {
            const { currentPage, resultsPerPage } = this.props.app.dm.sepa.state;
            await this.props.app.dm.sepa.syncSEPABills({ page: currentPage, resultsPerPage: resultsPerPage, sort: [{ "field": "billDate", "order": "ASC" }] });
          }
        }
      }, 1000 * defaultSec);
    }

    componentWillUnmount() {
      if (this.timerInstance) {
        clearInterval(this.timerInstance);
      }
    }

    onNavigate = (item) => {
      const { threadId } = item;
      let newThreadId = threadId;
      this.props.app.dm.billDetails.setState({
        billDetails: item
      });
      const { selectedScreen } = this.props.app.dm.dashboard.state;
      analyticsLogs(selectedScreen === "RTP" ? CommonString.RTP_DASHBOARD : CommonString.SEPA_DASHBOARD, CommonString.Click_on_Prioritized_Bill, this.props.app);
      this.props.app.nav.to("billDetailsRoute", { threadId: newThreadId });
    };

    onBlockNavigatation = async (type) => {
      const { newBillCount, overdueBillCount, outstandingBillCount, compiledMsgList, selectedScreen } = this.props.app.dm.dashboard.state;
      if (selectedScreen === "RTP") {
        if (
          (newBillCount > 0 && type === "New") ||
          (overdueBillCount > 0 && type === "Overdue") ||
          (outstandingBillCount > 0 && type === "Outstanding")
        ) {
          analyticsLogs(CommonString.RTP_DASHBOARD, `${CommonString.CLICKED} ${type} ${CommonString.BLOCK_NAVIGATION_ACTION}`, this.props.app);
          this.props.app.dm.userSearchDomain.setState({
            isSearchOpen: false,
            textInSearchBox: "",
            currentOpenScreen: this.props.r14.navigation.activeRouteName,
          });
          this.props.app.dm.filterDomain.resetFilter();
          this.props.app.dm.dashboard.setState({
            isBlockNavigation: true,
            selectedBlock: [type],
            filterBills: CommonUtility.filterStatus(compiledMsgList, [type])
          });
          this.navigateTo("makePaymentRoutes");
          return false;
        }
      }
      else {
        let { newBillCount, acceptBillCount, outstandingBillCount, rejectBillCount } = this.props.app.dm.sepa.state;
        if (
          (newBillCount > 0 && type === "New") ||
          (acceptBillCount > 0 && type === "Accepted") ||
          (rejectBillCount > 0 && type === "Rejected") ||
          (outstandingBillCount > 0 && type === "Outstanding")
        ) {
          analyticsLogs(CommonString.SEPA_DASHBOARD, `${CommonString.CLICKED} ${type} ${CommonString.BLOCK_NAVIGATION_ACTION}`, this.props.app);
          this.props.app.dm.sepa.setState({sepaBlockStatus: type.toUpperCase()});
          this.navigateTo("sepaBillsRoute");
          return false;
        }
      }
    };

    onViewAllNavigation = async () => {
      const { compiledMsgList, selectedScreen } = this.props.app.dm.dashboard.state;
      if (selectedScreen === "RTP") {
        this.props.app.dm.dashboard.setState({
          filterBills: compiledMsgList,
          isBlockNavigation: false,
          selectedBlock: [],
        });
        this.props.app.dm.userSearchDomain.setState({
          isSearchOpen: false,
          textInSearchBox: "",
          currentOpenScreen: this.props.r14.navigation.activeRouteName,
        });
        this.props.app.dm.notificationDomain.setState({
          notificationThreadIds: [],
        });
        this.props.app.dm.filterDomain.resetFilter();
        analyticsLogs(CommonString.RTP_DASHBOARD, `${CommonString.VIEW_ALL_BILLS_ACTION}`, this.props.app);
        this.navigateTo("makePaymentRoutes");
        return false;
      }
      else {
        this.props.app.dm.sepa.setState({sepaBlockStatus: 'ALL'});
        analyticsLogs(CommonString.SEPA_DASHBOARD, `${CommonString.VIEW_ALL_BILLS_ACTION}`, this.props.app);
        this.navigateTo("sepaBillsRoute");
        return false;
      }
    }

    onBulkPaymentNavigation = async (getData = []) => {
      try {
        let bulkBills = [...getData];
        let billerList = await this.props.app.dm.rest.getBillers();
        bulkBills.forEach((bill) => {
          let biller = billerList.find(x => x.billerId.toLowerCase() === bill.billerId.toLowerCase());
          if (!!biller && !!biller.uid) {
            bill.biller.isPaymentsFull = biller.isPaymentsFull;
            bill.biller.isPaymentsPartial = biller.isPaymentsPartial;
          }
        });

        bulkBills = bulkBills.filter(bill => {
          return (
            !["In Process", 'Declined', 'Paid'].includes(bill.paymentStatus ? bill.paymentStatus : bill.billStatus) &&
            (bill.biller.isPaymentsFull || bill.biller.isPaymentsPartial)
            && !!!bill.isExtentionRequested
            // if isBillerBlocked is true than remove bill from bulk pay
            && !!!bill.biller.isBillerBlocked
          )
        });

        if (!!bulkBills && bulkBills.length > 0) {
          analyticsLogs(CommonString.RTP_DASHBOARD, `${CommonString.BULK_PAY_ACTION}`, this.props.app);
          this.props.app.dm.dashboard.setState({ bulkPayBills: bulkBills })
          this.props.app.nav.to("bulkPaymentRoutes")
        } else if (!!bulkBills && bulkBills.length === 0) {
          if (!!getData && getData.length) {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.NoBillApplicable);
          } else this.props.app.dm.commonDomain.showToastr("error", CommonString.NoBillFound);
        }
      } catch (error) {
        console.error(error);
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);

      }
    };

    showPayType = async (data) => {
      const { selectedScreen } = this.props.app.dm.dashboard.state;
      this.props.app.dm.billDetails.setState({
        billDetails: data
      });
      let payType = (!!data && !!data.biller && !!data.biller.isPaymentsFull) ? "PAYFULL" : (!!data && !!data.biller && !!data.biller.isPaymentsPartial) ? "PAYPART" : ""
      if (!!payType) {
        analyticsLogs(selectedScreen === "RTP" ? CommonString.RTP_DASHBOARD : CommonString.SEPA_DASHBOARD, `${CommonString.PAY_NOW_ACTION}`, this.props.app);
        this.props.app.nav.to("payTypeRoute", { paytype: payType });
      }

      return false;
    }

    toggleBookmark = async (el) => {
      const { compiledMsgList, filterBills, headerSearchList, selectedScreen } = this.props.app.dm.dashboard.state;
      let ind = compiledMsgList.findIndex((itm) => itm.threadId === el.threadId);
      let fidx = filterBills.findIndex((itm) => itm.threadId === el.threadId);
      let hidx = headerSearchList.findIndex((itm) => itm.threadId === el.threadId);
      analyticsLogs(selectedScreen === "RTP" ? CommonString.RTP_DASHBOARD : CommonString.SEPA_DASHBOARD, `${CommonString.Removed_Priority}`, this.props.app);
      let res = await this.props.app.dm.dashboard.addUpdateBookmark({
        bookmark: !el.bookmark,
        uid: el.uid,
      });
      if (res.uid) {
        if (ind >= 0)
          compiledMsgList[ind].bookmark = res.bookmark;
        if (fidx >= 0)
          filterBills[fidx].bookmark = res.bookmark;
        if (hidx >= 0)
          headerSearchList[hidx].bookmark = res.bookmark;
        this.props.app.dm.dashboard.setState({ compiledMsgList, filterBills, headerSearchList });
      }
    };

    navigateTo = async (path) => {
      this.props.app.nav.to(path);
    };

    CheckPaymentAllowOrNot = (item) => {
      let isPayNowDistable = true;
      if ([MSG_TYPE.PAY_ALL, MSG_TYPE.PAY_PART, MSG_TYPE.REQ_PAY_EXT].includes(item.rtpMessageType) || BILL_STATUS[item.billStatus] || item.billStatus === "In Process") {
        isPayNowDistable = false;
      }
      else if (!!isPayNowDistable && !!!item.biller.isPaymentsFull && !!!item.biller.isPaymentsPartial) {
        isPayNowDistable = false;
      }

      if (item.billStatus === "Failed" && (!!item.biller.isPaymentsFull || !!item.biller.isPaymentsPartial)) {
        isPayNowDistable = true;
      }
      return isPayNowDistable;
    }

    convertToCSV = async (items, screenName = "data") => {
      if (items.length) {
        const { selectedScreen } = this.props.app.dm.dashboard.state;
        analyticsLogs(selectedScreen === "RTP" ? CommonString.RTP_DASHBOARD : CommonString.SEPA_DASHBOARD, `${CommonString.DOWNLOAD_CSV}`, this.props.app);
        if (selectedScreen === "RTP") {
          items = items.map(item => {
            return {
              "Biller Name": item.billerName || item.biller.billerName,
              "Business Unit Name": item.buName || item.biller.buName,
              "Bill Subject": item.subject,
              "Account Number": item.accountNumber,
              "Creation Date": DateUtils.formatDate(item.billDate),
              "Due On": DateUtils.formatDate(item.dueDate),
              "Status": item.billStatus,
              "Initial Amount": `${CURRENCY.USD}${item.billAmount}`,
              "Paid Amount": `${CURRENCY.USD}${item.amountPaid}`,
              "Amount Payable": `${CURRENCY.USD}${item.amountDue.toFixed(2)}`,
              "Reference ID": item.endToEndIdentification,
            }
          })
        } else {
          items = items.map(item => {
            return {
              "Biller": item.billerName,
              "Receive Date": DateUtils.formatDate(item.createdOn),
              "Amount": item.billAmount,
              "Status": item.billStatus,
              "Payment Type": item.paymentType,
              "Expiry Date": DateUtils.formatDate(item.expiryDate)
            }
          })
        }
        const csvContent = JsonToCSV.convertJsonToCSV(items);
        HtmlDecode.downloadUTF8file({
          content: csvContent,
          filename: `${screenName}_${DateUtils.formatYYYYMMDDHHMMSS()}.csv`
        })
      }
      return false;
    }

    onValueChange = async (item) => {
        analyticsLogs(item === 'RTP' ? CommonString.SEPA_DASHBOARD : CommonString.RTP_DASHBOARD, item === 'RTP' ? CommonString.RTP_Bills : CommonString.SEPA_Bills, this.props.app)
        this.props.app.dm.dashboard.setState({
          selectedScreen: item
        })
        await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_TABSELECTION, item);
    }

    render() {
      let { compiledMsgList, filterBills, headerSearchList, isBlockNavigation, selectedBlock,
        newBillCount, overdueBillCount, outstandingBillCount, selectedScreen } = this.props.app.dm.dashboard.state;
      const { isSearchOpen } = this.props.app.dm.userSearchDomain.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      let leftMenuToggle = this.props.app.dm.userSession.state.leftMenuToggle;
      let currentRoute = this.props.r14.navigation.activeRouteName;
      let withBookmarked = [];
      let isHeader = true;
      if (isHeader) {
        withBookmarked = isSearchOpen
          ? headerSearchList.filter(
            (el) => el.bookmark === true && el.billStatus !== "Paid" && el.billStatus !== "Declined"
          )
          : compiledMsgList.filter(
            (el) => el.bookmark === true && el.billStatus !== "Paid" && el.billStatus !== "Declined"
          );
      } else if (isSearchOpen) {
        withBookmarked = headerSearchList.length > 0 ? headerSearchList.filter(
          (el) => el.bookmark === true && el.billStatus !== "Paid" && el.billStatus !== "Declined"
        ) : [];
      } else {
        withBookmarked = filterBills;
      }
      let acceptBillCount = 0, rejectBillCount = 0, itemCount = 0;
      if (selectedScreen !== "RTP") {
        newBillCount = this.props.app.dm.sepa.state.newBillCount;
        outstandingBillCount = this.props.app.dm.sepa.state.outstandingBillCount;
        acceptBillCount = this.props.app.dm.sepa.state.acceptBillCount;
        rejectBillCount = this.props.app.dm.sepa.state.rejectBillCount;
        withBookmarked = this.props.app.dm.sepa.state.sepaBills;
        itemCount = this.props.app.dm.sepa.state.totalBills;
      }
      return (
            <ResponsiveTableComponent
              currentRoute={currentRoute}
              filterBills={withBookmarked}
              leftMenuToggle={leftMenuToggle}
              onNavigate={this.onNavigate}
              onBlockNavigatation={this.onBlockNavigatation}
              onViewAllNavigation={this.onViewAllNavigation}
              onBulkPaymentNavigation={this.onBulkPaymentNavigation}
              showPayType={this.showPayType}
              toggleBookmark={this.toggleBookmark}
              navigateTo={this.navigateTo}
              CheckPaymentAllowOrNot={this.CheckPaymentAllowOrNot}
              convertToCSV={this.convertToCSV}
              isSimpleMessageScreen={false}
              isBillerDirectory={false}
              isMakePayment={false}
              isDashboard={true}
              selectedScreen={selectedScreen}
              isMobileDevice={isMobileDevice}
              overdueBillCount={overdueBillCount}
              outstandingBillCount={outstandingBillCount}
              newBillCount={newBillCount}
              acceptBillCount={acceptBillCount}
              rejectBillCount={rejectBillCount}
              itemCount={itemCount}
              onValueChange={this.onValueChange}
              userLoginDetail={this.props.userLoginDetail}
              message={!!isBlockNavigation ? "No bills to display." : "No priority bills found."}
              headerText={!!(isBlockNavigation && selectedBlock) ? `${selectedBlock[0]} Bills` : "Dashboard"}
              headerContainerStyle={[selectedScreen !== "RTP" ? (commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexStart, commonStyles.alignItemsCenter, commonStyles.flexBasis50) : null]}
            />
      );
    }
  }
);