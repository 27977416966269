import React from "react";
import R14, { StyleSheet, Text, Touchable, View } from "../core";
import SubmittedComponent from "../components/PCH/SubmittedComponent";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";

export default R14.connect(
    class ReceiptScreen extends React.Component {
        render() {
            const { message, hasError } = this.props;
            return (
                <View style={[styles.receiptScreen]}>
                    <SubmittedComponent message={message} hasError={hasError} hideButton={true} />
                    <View style={[commonStyles.marginTop20, commonStyles.flex1, commonStyles.alignItemsCenter]}>
                        <Touchable style={commonStyles.marginBottom10} onPress={async () => { this.props.app.nav.to("directSubmission") }}>
                            <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, commonStyles.primaryButton, styles.loginButton]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.whiteColor, commonStyles.textAlignCenter]}>
                                    Submit Claim
                                </Text>
                            </View>
                        </Touchable>
                        <View style={[styles.orSection]}>
                            <Text style={[commonFontStyles.workSans, styles.or, commonStyles.smallHeading, commonStyles.fontWeight400]}>
                                Or
                            </Text>
                        </View>
                        <View style={[commonStyles.marginBottom10, commonStyles.marginTop5]}>
                            <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lightGrayColor]}>Already have an account? </Text>
                        </View>
                        <Touchable style={commonStyles.marginBottom10} onPress={async () => { this.props.app.nav.to("pchLogin") }}>
                            <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, commonStyles.secondayWhiteBtn, styles.loginButton]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.secondayWhiteBtnText, commonStyles.textAlignCenter]}>
                                    Login
                                </Text>
                            </View>
                        </Touchable>

                    </View>
                </View>
            );
        }
    }
);

const styles = StyleSheet.create({
    receiptScreen: {
        backgroundColor: "#fff",
        paddingTop: 15,
        paddingRight: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        marginTop: 25,
        marginRight: 16,
        marginBottom: 16,
        marginLeft: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    marginTop: 25,
                    marginRight: 0,
                    marginLeft: 0,
                    marginBottom: 70,
                    borderColor: "#fff",
                };
        },
    },
    orSection: {
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderBottomColor: "rgba(186, 186, 186, 1)",
        position: "relative",
        textAlign: "center",
        marginBottom: 22,
        width: "90%"
    },
    or: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderTopLeftRadius: 50,
        borderTopRightRadius: 50,
        borderBottomLeftRadius: 50,
        borderBottomRightRadius: 50,
        backgroundColor: "#fff",
        position: "relative",
        top: 10,
        paddingTop: 1,
        paddingRight: 5,
        paddingBottom: 1,
        paddingLeft: 5,
    },
    loginButton: {
        width: 180,
        height: 24,
        minHeight: 24,
        maxHeight: 24
    }
});