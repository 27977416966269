
const APISOURCE = "PROD";

const BASE = APISOURCE === "UAT" ? require("../constant/app.uat.constant"): 
  APISOURCE === "PROD" ? require("../constant/app.prod.constant") : 
  APISOURCE === "QA" ? require("../constant/app.qa.constant") : 
  require("../constant/app.dev.constant");

const { BASE_URL, USER_API, NATIVE_APP_URL, KC_BASE_URL, REALM_NAME, KC_CLIENT_ID, CLAIM_CLIENT_ID, MIXPANEL_TOKEN, GOOGLE_ANALYTICS_TOKEN } = BASE;

const THREAD_LIST = `${USER_API}/threads/list`;
const GET_MSG_FROM_THREAD = `${USER_API}/messages`;
const PAM_CREATION = `${USER_API}/authorisation-requests`;
const MSG_LIST = `${USER_API}/messages/list`;
const ATTACHMENTS = `${USER_API}/messages/attachments`;
const MESSAGE_METADATA = `${USER_API}/message/metadata`;
const UPDATE_TEXT_TO_PAY_PREFERENCE =  `${USER_API}/authorisation-requests-updateTextToPay`;

const MIXPANEL_URL = 'https://api.mixpanel.com/track?ip=1&verbose=1';

let URL_PATH = "kcsession";

const realmEndpoint = `${KC_BASE_URL}/realms/${REALM_NAME}`;
const authorizationEndpoint = `${KC_BASE_URL}/realms/${REALM_NAME}/protocol/openid-connect/auth`;
const tokenEndpoint = `${KC_BASE_URL}/realms/${REALM_NAME}/protocol/openid-connect/token`;
const userInfoEndpoint = `${KC_BASE_URL}/realms/${REALM_NAME}/protocol/openid-connect/userinfo`;
const logoutEndpoint = `${KC_BASE_URL}/realms/${REALM_NAME}/protocol/openid-connect/logout`;
const jwksUriEndpoint = `${KC_BASE_URL}/realms/${REALM_NAME}/protocol/openid-connect/certs`;

/* KC Urls End*/

const RSA_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC4o86kyL6UQVzyyVbvJvcZ85+f
rf2k7HxixScLk3tnnezxGysULfOZmTKep/rZTiG01sOIaiI4tED9lUmQBMMoo7yF
Xek05eTuEtym6nsS384nVrdlBCxDqS9Q7T5VvIP2tGNAEQnqnmTDA3w6S8vZ7VIg
VVFJNS2UZXAk1xjd8QIDAQAB
-----END PUBLIC KEY-----`;
const SITE_KEY = "6LchYl4aAAAAAN0zKh-E4AcdlVSp438jLhsKYckX";
const GOOGLE_AUTHKEY = "529507144249-u08vk1l6gebqvp38pid649am7lben1bi.apps.googleusercontent.com";
const GOOGLE_AUTHKEY_ANDROID_NATIVE = "529507144249-vpt7682d9inh51fdbq497aqh1kmha9eg.apps.googleusercontent.com";
const GOOGLE_AUTHKEY_IOS_NATIVE = "529507144249-8s9g37bb8ev63vcnf9nov0euh8aqh499.apps.googleusercontent.com";
const EXPO_AUTHKEY_NATIVE = "529507144249-g6o06ahmboqf9759dmr16r5kcfc4kro3.apps.googleusercontent.com";
const AMAZON_AUTHKEY = "amzn1.application-oa2-client.b89ecad71c9a4260897eac00fe31a3b8";
const APPLE_AUTHKEY = "com.exelaonline.XBPApplication";
const GEO_LOCATION_URL ="https://geolocation-db.com/json";
export {
  BASE_URL,
  USER_API,
  THREAD_LIST,
  GET_MSG_FROM_THREAD,
  PAM_CREATION,
  MSG_LIST,
  ATTACHMENTS,
  RSA_PUBLIC_KEY,
  SITE_KEY,
  GOOGLE_AUTHKEY,
  APPLE_AUTHKEY,
  AMAZON_AUTHKEY,
  GOOGLE_AUTHKEY_ANDROID_NATIVE,
  GOOGLE_AUTHKEY_IOS_NATIVE,
  EXPO_AUTHKEY_NATIVE,
  NATIVE_APP_URL,
  GEO_LOCATION_URL,
  UPDATE_TEXT_TO_PAY_PREFERENCE,
  MESSAGE_METADATA,
  KC_CLIENT_ID,
  CLAIM_CLIENT_ID,
  URL_PATH,
  realmEndpoint,
  authorizationEndpoint,
  jwksUriEndpoint,
  tokenEndpoint,
  userInfoEndpoint,
  logoutEndpoint,
  MIXPANEL_URL,
  MIXPANEL_TOKEN,
  GOOGLE_ANALYTICS_TOKEN,
};