import React from "react";
import R14, {
  StyleSheet,
  Image,
  Text,
  View,
  Touchable,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
// import ModalPopUp from "../ModalPopUp";
 import { USER_TYPE } from "../../constant/app.data.constant";
import { CommonString } from "../../constant/appCommonText";
// import PCHLoaderComponent from "./PCHLoaderComponent";

const pdfPractitionerIcon = require("../../assets/images/pdfPractitionerIcon.png");
const pdfHartIcon = require("../../assets/images/pdfHartIcon.png");

export default R14.connect(
  class WelcomeToPCH extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isVisible: false,
        isLoading: false,
      };
    }

    goToProviderRegistration = async (type) => {
      const { subRoleUpdate} = this.props.app.dm.userSession.state;
      let userLoginDetail = await this.props.app.dm.rest.getUser();
      
      this.setState({ isLoading: true });
      if (type === "Provider") {
        if (subRoleUpdate === true) {
          await this.updateSubRole(userLoginDetail, "PCH_PROVIDER");
        }
        else {    //NOTE:google/apple/normal login
          if (!!["existinggoogle", "existingapple", "existingaccount"].includes(this.props.registrationType.toLowerCase())) {
            await this.updateExistingAccount(userLoginDetail, "PCH_PROVIDER", userLoginDetail.email);
          } else {
            this.props.app.dm.userSession.setState({
              pchRegistrationTypeProvider: true,
              pchRegistrationTypePatient: false,
            });
          }
        }
      } else {
        if (subRoleUpdate === true) {
          await this.updateSubRole(userLoginDetail, "PCH_PATIENT");
        }
        else {    //NOTE:google/apple/normal login
          if (!!["existinggoogle", "existingapple", "existingaccount"].includes(this.props.registrationType.toLowerCase())) {
            await this.updateExistingAccount(userLoginDetail, "PCH_PATIENT", userLoginDetail.email);
          } else {
            this.props.app.dm.userSession.setState({
              pchRegistrationTypeProvider: false,
              pchRegistrationTypePatient: true,
            });
          }
        }
      }
      this.setState({ isLoading: false });
    };

    updateExistingAccount = async (userDetailobj, userType, email) => {
      const updateUserReqBody = {
        uid: userDetailobj.uid,
        userType: userType,
      };
      let res = await this.props.app.dm.userRegistration.updateUserDetails(updateUserReqBody, true);
      if (res) {
        this.props.app.dm.commonDomain.showToastr("success", CommonString.LoggedIn);
        this.props.app.dm.userLoginDomain.setState({
          socialLoginProfile: null,
          socialLoginType: "",
        });
        this.props.app.nav.to(userType === USER_TYPE.PCH_PROVIDER ? "uploadBillsRoute" : "patientDashboard");
        return false;
      }
    };

    updateSubRole = async (userDetailobj, userType) => {
      const updateUserReqBody = {
        uid: userDetailobj.uid,
        subRole: [{appName:"CLAIMS", appRole:userType}],
      };
      let res = await this.props.app.dm.userRegistration.updateUserDetails(updateUserReqBody, true);
      if (res) {
        let userLoginDetail = await this.props.app.dm.rest.getUser();
        userLoginDetail.userType = userType;
        await this.props.app.dm.userSession.updateSMBOrganization(userLoginDetail);
        this.props.app.dm.userSession.setState({
          userLoginDetail: userLoginDetail
        })
        this.props.app.dm.commonDomain.showToastr("success", CommonString.LoggedIn);
        this.props.app.dm.userLoginDomain.setState({
          socialLoginProfile: null,
          socialLoginType: "",
        });
        this.props.app.nav.to(userType === USER_TYPE.PCH_PROVIDER ? "uploadBillsRoute" : "patientDashboard");
        return false;
      }
    };

    render() {
      return (
        <View style={[styles.welcomeToPCH, commonStyles.flex1, commonStyles.justifyContentSpaceBetween, commonStyles.flexGrow0]}>
          <View>
            <View style={[commonStyles.marginBottom16, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
              <Text style={[commonStyles.heading5, commonFontStyles.workSans, commonStyles.fontWeight500]}>Welcome to Claims!</Text>
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.justifyContentCenter,
                commonStyles.marginBottom10,
                commonStyles.alignItemsCenter,
                commonStyles.marginBottom45,
              ]}>
              <Text style={[commonFontStyles.workSans, commonStyles.fullWidth, commonStyles.textAlignCenter]}>Let's quickly get you registered so you</Text>
              <Text style={[commonFontStyles.workSans, commonStyles.fullWidth, commonStyles.textAlignCenter]}>can submit any claim to any payer.</Text>
            </View>
          </View>
          <View style={[commonStyles.flex1]}>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.justifyContentCenter,
                commonStyles.marginBottom20,
                commonStyles.alignItemsCenter,
                styles.customMarginBottom,
                commonStyles.flexDirectionRow,
              ]}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight500, commonStyles.fullWidth, commonStyles.textAlignCenter]}>What type of user are you?</Text>
            </View>
            <View style={[commonStyles.flex1, commonStyles.alignItemsStart, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow]}>
              <Touchable
                onPress={() => this.goToProviderRegistration("Provider")}
                style={[commonStyles.flex1, commonStyles.marginBottom16, commonStyles.justifyContentCenter, styles.flexBasis29, styles.marginRight8, commonStyles.marginBottom20,]}>
                <View
                  style={[
                    styles.registrationTypeBtn,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                    
                  ]}>
                  <Image source={pdfPractitionerIcon} style={[commonStyles.icon48, commonStyles.marginRight30, commonStyles.marginLeft30]} />
                  <View>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight500]}>Practice Group</Text>
                  </View>
                </View>
              </Touchable>

              <Touchable
                onPress={() => this.goToProviderRegistration("Patient")}
                style={[commonStyles.flex1, commonStyles.marginBottom16, commonStyles.justifyContentCenter, styles.flexBasis29, styles.marginLeft8, commonStyles.marginBottom20,]}>
                <View
                  style={[
                    styles.registrationTypeBtn,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                    
                  ]}>
                  <Image source={pdfHartIcon} style={[commonStyles.icon48, commonStyles.marginRight30, commonStyles.marginLeft30]} />
                  <View>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.fontWeight500]}>Patient</Text>
                  </View>
                </View>
              </Touchable>
            </View>
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  welcomeToPCH: {
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        };
    },
  },
  block: { display: "block" },
  none: { display: "none" },
  registrationTypeBtn: {
    borderWidth: 1,
    borderColor: "#dadada",
    borderStyle: "solid",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    minHeight: 132,
    maxHeight: 132,
    alignItems: "center",
  },
  howItWorks: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 40,
    marginLeft: 16,
  },
  sessionModelHeaderStyle: {
    marginBottom: 15,
  },
  closeBtn: {
    position: "absolute",
    right: 12,
    top: 10,
  },
  bodyWrapper: {
    position: "relative",
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    width: 420,
    paddingTop: 10,
    paddingRight: 24,
    paddingBottom: 10,
    paddingLeft: 24,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: "auto",
          width: "100vw",
          borderTopLeftRadius: 14,
          borderTopRightRadius: 14,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        };
    },
  },
  flexBasis29: {
    flexBasis: "17%",
    maxWidth: 132,
  },
  marginLeft8: {
    marginLeft: 8,
  },
  marginRight8: {
    marginRight: 8,
  },
  customMarginBottom: {
    marginBottom: 9,
  },
});
