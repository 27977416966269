import React from "react";
import R14, { View, Touchable, ScrollView, StyleSheet, FlatList, Text, Image, Platform } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import NoRecordsFound from "../components/NoRecordsFound";
import DateUtils from "../utils/DateUtils";
import { CURRENCY } from "../constant/app.data.constant";
import { ATTACHMENTS } from "../constant/app.constant";
import CommonUtility from "../utils/CommonUtility";
import HtmlDeCode from "../utils/HtmlDecode";
import { customCommonStyles } from "../assets/css/customCommonStyle";

const attachedFileIcon = require("../assets/images/attachedFileIcon.png");

export default R14.connect(
  class MessageComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        displayMessages: this.props.displayMessages,
        device: false,
      };
      this.scrollRef = React.createRef();
      this.billDetails = this.props.billDetails;
      this.height = window.innerHeight - 64 - 54 - 62;
    }

    downloadFile = async ({ item, pathid, threadId, messageId }) => {
      let base64Format = await this.props.app.dm.rest.get(`${ATTACHMENTS}/${pathid}`, {
        "thread-id": threadId,
        "message-id": messageId,
        download: true,
      });
      const fileObj = {
        content: `${Platform.OS === 'web' ? 'data:application/octet-stream;base64,' : ''}${(base64Format && base64Format.data) ? base64Format.data : base64Format}`,
        contentType: item.contentType,
        filename: item.fileName
      };
      HtmlDeCode.downloadFile(fileObj);
    };

    getSenderName = (messageHeader) => {
      const userLoginDetail = this.props.userDetails;
      let userShortName = userLoginDetail.firstName + (userLoginDetail.lastName ? " " + userLoginDetail.lastName : "");
      let msgSenderName =
         userLoginDetail.linkPids.find(x => x === messageHeader.senderPid)
          ? userShortName
          : messageHeader.senderName
      return msgSenderName;
    };

    renderMessages(item, style) {
      var displayTime = DateUtils.dateTodayYesterday(item.envelope.messageHeader.messageDateTime);

      switch (item.envelope.messageBody.messageType) {
        case "RequestToPay":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              {/* <View style={[styles.userIconDiv, style ? styles.lightBlueBg : styles.grayBg, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, styles.circleText]}> */}
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding,
                    ]}>
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                  REQUEST Received {CURRENCY.USD}{/* {CURRENCY[item.envelope.messageBody.amount.currency]} */}{item.envelope.messageBody.amount.dueAmount}
                </Text>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Bill Name: {item.envelope.messageBody.remittanceInformation.structured.billName}
                  </Text>
                </View>
                {/* <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Reference:
                    {item.envelope.messageBody.creditor.name}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Remittance:
                    {item.envelope.messageBody.remittanceInformation.unstructured}
                  </Text>
                </View> */}

                {item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart, commonStyles.marginBottom5]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        case "PayAll":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {/* <Text
                  style={[
                    styles.circleText,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    style ? styles.lightBlueBg : styles.grayBg,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                  ]}> */}
                    {/* {`${item.envelope.messageHeader.senderPid.split("#")[0].toUpperCase().charAt(0)}${item.envelope.messageHeader.senderPid
                    .split("#")[0]
                    .toUpperCase()
                    .charAt(item.envelope.messageHeader.senderPid.split("#")[0].length - 1)}`} */}
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding,
                    ]}>
                    {/* {item.envelope.messageHeader.senderPid.split("#")[0]} */}
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                  PAID IN FULL {CURRENCY.USD}{/* {CURRENCY[item.envelope.messageBody.amount.currency]} */}{item.envelope.messageBody.amount.instructedAmount}
                </Text>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Transaction Ref: {item.envelope.messageBody.transactionId}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Transaction Status: {item.envelope.messageBody.transactionStatus}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Requested Execution: {DateUtils.formatDate(item.envelope.messageBody.requestedExecutionDate)}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Acceptance: {DateUtils.formatDate(item.envelope.messageBody.acceptanceDateTime)}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Additional Information: {item.envelope.messageBody.additionalInformation}
                  </Text>
                </View>
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
                {item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}
                    style={[commonStyles.marginBottom5]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );

        case "PayPartial":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {/* {`${item.envelope.messageHeader.senderPid.split("#")[0].toUpperCase().charAt(0)}${item.envelope.messageHeader.senderPid
                    .split("#")[0]
                    .toUpperCase()
                    .charAt(item.envelope.messageHeader.senderPid.split("#")[0].length - 1)}`} */}
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding,
                    ]}>
                    {/* {item.envelope.messageHeader.senderPid.split("#")[0]} */}
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                  PAID IN PART {CURRENCY.USD}{/* {CURRENCY[item.envelope.messageBody.amount.currency]} */}{item.envelope.messageBody.amount.instructedAmount}
                </Text>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Transaction Ref: {item.envelope.messageBody.transactionId}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Transaction Status: {item.envelope.messageBody.transactionStatus}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Requested Execution: {DateUtils.formatDate(item.envelope.messageBody.requestedExecutionDate)}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Acceptance: {DateUtils.formatDate(item.envelope.messageBody.acceptanceDateTime)}
                  </Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Additional Information: {item.envelope.messageBody.additionalInformation}
                  </Text>
                </View>
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
                {item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}
                    style={[commonStyles.marginBottom5]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        case "Decline":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                    ]}>
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding,
                    ]}>
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>DECLINED</Text>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    Additional Information: {item.envelope.messageBody.additionalInformation}
                  </Text>
                </View>
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
                {item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}
                    style={[commonStyles.marginBottom5]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        case "AcknowledgeInFull":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {/* <Text
                  style={[
                    styles.circleText,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    style ? styles.lightBlueBg : styles.grayBg,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                  ]}> */}
                    {/* {`${item.envelope.messageHeader.senderPid.split("#")[0].toUpperCase().charAt(0)}${item.envelope.messageHeader.senderPid
                    .split("#")[0]
                    .toUpperCase()
                    .charAt(item.envelope.messageHeader.senderPid.split("#")[0].length - 1)}`} */}
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding,
                    ]}>
                    {/* {item.envelope.messageHeader.senderPid.split("#")[0]} */}
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>PAID IN FULL - ACKNOWLEDGED</Text>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                  {item.envelope.messageBody.additionalInformation ? item.envelope.messageBody.additionalInformation : ""}
                </Text>
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
                {item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}
                    style={[commonStyles.marginBottom5]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        case "AcknowledgePayment":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {/* <Text
                  style={[
                    styles.circleText,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    style ? styles.lightBlueBg : styles.grayBg,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                  ]}> */}
                    {/* {`${item.envelope.messageHeader.senderPid.split("#")[0].toUpperCase().charAt(0)}${item.envelope.messageHeader.senderPid
                    .split("#")[0]
                    .toUpperCase()
                    .charAt(item.envelope.messageHeader.senderPid.split("#")[0].length - 1)}`} */}
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding,
                    ]}>
                    {/* {item.envelope.messageHeader.senderPid.split("#")[0]} */}
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                  PAYMENT ACKNOWLEDGED {CURRENCY[item.envelope.messageBody.amount.currency]}{item.envelope.messageBody.amount.paidAmount}
                </Text>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                  {item.envelope.messageBody.additionalInformation ? item.envelope.messageBody.additionalInformation : ""}
                </Text>
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
                {item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}
                    style={[commonStyles.marginBottom5]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        case "ExtensionGranted":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {/* <Text
                  style={[
                    styles.circleText,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    style ? styles.lightBlueBg : styles.grayBg,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                  ]}> */}
                    {/* {`${item.envelope.messageHeader.senderPid.split("#")[0].toUpperCase().charAt(0)}${item.envelope.messageHeader.senderPid
                    .split("#")[0]
                    .toUpperCase()
                    .charAt(item.envelope.messageHeader.senderPid.split("#")[0].length - 1)}`} */}
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <Text
                  style={[
                    style ? commonStyles.transperentBtnColor : null,
                    commonStyles.guestName,
                    commonFontStyles.workSans,
                    customCommonStyles.gustNamecustomPadding,
                  ]}>
                  {/* {item.envelope.messageHeader.senderPid.split("#")[0]} */}
                  {this.getSenderName(item.envelope.messageHeader)}
                </Text>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>EXTENSION APPROVED </Text>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    {item.envelope.messageBody.additionalInformation ? item.envelope.messageBody.additionalInformation : ""}
                  </Text>
                </View>
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
                {item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}
                    style={[commonStyles.marginBottom5]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        case "ExtensionDeclined":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {/* <Text
                  style={[
                    styles.circleText,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    style ? styles.lightBlueBg : styles.grayBg,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                  ]}> */}
                    {/* {`${item.envelope.messageHeader.senderPid.split("#")[0].toUpperCase().charAt(0)}${item.envelope.messageHeader.senderPid
                    .split("#")[0]
                    .toUpperCase()
                    .charAt(item.envelope.messageHeader.senderPid.split("#")[0].length - 1)}`} */}
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding,
                    ]}>
                    {/* {item.envelope.messageHeader.senderPid.split("#")[0]} */}
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>EXTENSION DECLINED </Text>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                    {item.envelope.messageBody.additionalInformation ? item.envelope.messageBody.additionalInformation : ""}
                  </Text>
                </View>
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
                {item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}
                    style={[commonStyles.marginBottom5]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        case "NotetoPayer":
        case "NotetoBiller":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {/* <Text
                  style={[
                    styles.circleText,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    style ? styles.lightBlueBg : styles.grayBg,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                  ]}> */}
                    {/* {`${item.envelope.messageHeader.senderPid.split("#")[0].toUpperCase().charAt(0)}${item.envelope.messageHeader.senderPid
                    .split("#")[0]
                    .toUpperCase()
                    .charAt(item.envelope.messageHeader.senderPid.split("#")[0].length - 1)}`} */}
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding
                    ]}>
                    {/* {item.envelope.messageHeader.senderPid.split("#")[0]} */}
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.flex1, commonStyles.smallHeading, commonFontStyles.workSans, commonStyles.flexWrap, styles.messageText]}>{item.envelope.messageBody.note}
                </Text>
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
                {
                // item.envelope.messageBody.note.indexOf("Initiated full payment of") < 0 &&
                // item.envelope.messageBody.note.indexOf("Initiated partial payment of") < 0 && 
                item.attachments && !!item.attachments.length ? (
                  <Touchable
                    onPress={() => {
                      this.downloadFile({
                        item: item.attachments[0],
                        pathid: item.attachments[0].path,
                        threadId: item.envelope.threadHeader.threadId,
                        messageId: item.envelope.messageHeader.messageId,
                      });
                    }}
                    style={[commonStyles.marginBottom5]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                      <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                      <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                        {item.attachments[0].fileName || item.attachments[0].filename}
                      </Text>
                    </View>
                  </Touchable>
                ) : null}
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        case "ReqPayExtension":
          return (
            <View
              style={
                !style
                  ? [commonStyles.marRAuto]
                  : [commonStyles.marLAuto]
              }>
              <View style={[styles.userIconDiv, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                <View style={[styles.circleText, style ? styles.lightBlueBg : styles.grayBg]}>
                  <Text
                    style={[
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      // commonStyles.flex1,
                      // commonStyles.justifyContentCenter,
                      // commonStyles.alignItemsCenter,
                    ]}>
                    {/* <Text
                  style={[
                    styles.circleText,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    style ? styles.lightBlueBg : styles.grayBg,
                    commonStyles.flex1,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                  ]}> */}
                    {/* {`${item.envelope.messageHeader.senderPid.split("#")[0].toUpperCase().charAt(0)}${item.envelope.messageHeader.senderPid
                    .split("#")[0]
                    .toUpperCase()
                    .charAt(item.envelope.messageHeader.senderPid.split("#")[0].length - 1)}`} */}
                    {CommonUtility.shortName(this.getSenderName(item.envelope.messageHeader))}
                  </Text>
                </View>
                <View style={[commonStyles.flexGrow1]}>
                  <Text
                    style={[
                      style ? commonStyles.transperentBtnColor : null,
                      commonStyles.guestName,
                      commonFontStyles.workSans,
                      customCommonStyles.gustNamecustomPadding,
                    ]}>
                    {/* {item.envelope.messageHeader.senderPid.split("#")[0]} */}
                    {this.getSenderName(item.envelope.messageHeader)}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  commonStyles.minWidth150,
                  commonStyles.flex1,
                  style ? commonStyles.hostText : commonStyles.guestText,
                ]}>
                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                  EXTENSION REQUESTED: {DateUtils.formatDate(item.envelope.messageBody.requestedExtensionDate)}
                </Text>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>Additional Information:</Text>
                </View>
                <View>
                  <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>{item.envelope.messageBody.additionalInformation}</Text>
                </View>
                {item.attachments && !!item.attachments.length ? (
                  <View style={[commonStyles.flex1, commonStyles.alignItems]}>
                    {/* <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>Attachment: </Text> */}
                    <Touchable
                      onPress={() => {
                        this.downloadFile({
                          item: item.attachments[0],
                          pathid: item.attachments[0].path,
                          threadId: item.envelope.threadHeader.threadId,
                          messageId: item.envelope.messageHeader.messageId,
                        });
                      }} style={[commonStyles.marginBottom5]}>
                      <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.flexGrow1]}>
                        <View>
                          <Image source={attachedFileIcon} style={[commonStyles.icon14]} />
                        </View>
                        <View style={[
                          commonStyles.wordBreakAll,
                          commonStyles.wordBreakWord,
                          commonStyles.marginLeft5,
                        ]}>
                          <Text
                            style={[
                              commonStyles.smallHeading,
                              commonFontStyles.workSans,
                              commonStyles.wordBreakAll,
                              commonStyles.wordBreakWord,
                              styles.fileNameStyle
                            ]}>
                            {item.attachments[0].fileName || item.attachments[0].filename}
                          </Text>
                        </View>
                      </View>
                      {/* <Image
                        source={attachedFileIcon}
                        style={[commonStyles.icon14, styles.attachedFileIcon, commonStyles.marginLeft10]}
                      /> */}
                    </Touchable>
                  </View>
                ) : null}
                <View
                  style={
                    style ? [commonStyles.hostTextBorder, commonFontStyles.workSans] : [commonStyles.guestTextBorder, commonFontStyles.workSans]
                  }></View>
              </View>
              <View style={[commonStyles.textAlignRight]}>
                <Text
                  style={[
                    commonStyles.exSmallHeading,
                    commonStyles.chatTiming,
                    commonStyles.blackTextColor,
                    commonStyles.marLAuto,
                    commonFontStyles.workSans,
                  ]}>
                  {displayTime}
                </Text>
              </View>
            </View>
          );
        default:
          return null;
      }
    }

    componentDidMount() {
      this.scrollToEnd();
      // this.checkDevice();
    }
    scrollToEnd(options = { animated: false }) {
      if (this.scrollRef.current) this.scrollRef.current.scrollToEnd(options);
    }

    checkDevice() {
      var ua = navigator.userAgent;
      var isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(ua);
      if (isMobile) {
        this.setState({
          device: true,
        });
      }
    }

    render() {
      let { customClass } = this.props;
      return (
        <ScrollView
          ref={this.scrollRef}
          // height={this.props.height ? this.props.height : this.height}
          style={[styles.messageComponent, commonStyles.padding0, customClass]}>
          <FlatList
            style={[styles.messageComponent]}
            ListEmptyComponent={<NoRecordsFound message={"No bills found."} />}
            data={this.state.displayMessages}
            keyExtractor={(el) => el.envelope.messageHeader.messageId}
            renderItem={(item, index) => (
              <View style={[commonStyles.padding5_0, commonStyles.flex1]} key={`msg_${index}`}>
                <View
                  style={[
                    commonStyles.dInlineFlex,
                    commonStyles.flexDirectionColumn,
                    this.billDetails.payerId === item.item.envelope.messageHeader.recipientPid ? null : commonStyles.marLAuto,
                    this.billDetails.payerId === item.item.envelope.messageHeader.recipientPid ? null : commonStyles.marginRight5,
                    commonStyles.maxWidth90percent,
                  ]}>
                  {this.billDetails.payerId === item.item.envelope.messageHeader.recipientPid ? (
                    <View style={[commonStyles.hostChat, styles.customMarginBottom, commonStyles.marginLeft5, commonStyles.flex1]}>
                      <View style={commonStyles.flex1}>{this.renderMessages(item.item, false)}</View>
                    </View>
                  ) : (
                    <View style={[commonStyles.guestChat, styles.customMarginBottom]}>
                      <View style={commonStyles.flex1}>{this.renderMessages(item.item, true)}</View>
                    </View>
                  )}
                </View>
              </View>
            )}
          />
        </ScrollView>
      );
    }
  }
);

const styles = StyleSheet.create({
  test: {
    flex: 1,
  },
  fieldsHolder: {
    position: "relative",
    flexGrow: 1,
  },
  messageTextField: {
    paddingTop: 0,
    paddingRight: 45,
    paddingBottom: 0,
    paddingLeft: 10,
  },
  attachment: {
    position: "absolute",
    top: 0,
    right: 66,
  },
  attachmentCover: {
    position: "absolute",
    top: 0,
    right: 66,
    height: 4,
    width: 4,
    backgroundColor: "#fff",
  },
  blueButton: {
    backgroundColor: "#37c2ef",
    color: "#333",
    height: 52,
    width: 75,
    border: 0,
  },
  BorderTop: {
    borderTop: "1px solid #D6D6D6",
  },
  attachedFileIcon: {
    marginTop: 3,
    marginRight: 5,
  },
  messageComponent: {
    position: "static",
  },
  userIconDiv: {
    // width: 20,
    maxWidth: "100%",
    minWidth: "50%",
    paddingLeft: 0,
    // flexGrow: 1,
    // width: "110%",
  },
  circleText: {
    minWidth: 25,
    maxWidth: 25,
    maxHeight: 25,
    minHeight: 25,
    // paddingTop: 5,
    // paddingLeft: 5,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // textAlignVertical: "bottom",
  },
  grayBg: {
    // backgroundColor: "#e8e5e5",
    backgroundColor: "#e7e7e7",
  },
  lightBlueBg: {
    backgroundColor: "#e7e7e7",
  },
  customMarginBottom: {
    marginBottom: 2,
  },
  messageText: {
    flexShrink: 1,
  },
  paddingRight30: {
    paddingRight: 30,
  },
  fileNameStyle: {
    // flexBasis: "90%",
    // maxWidth: "90%",
  },
  attachmentIconStyle: {
    flexBasis: "10%",
  },
});
