const icons = {
  account: {
    family: "MaterialCommunityIcons",
    name: "account",
  },
  accountCircle: {
    family: "MaterialCommunityIcons",
    name: "account-circle",
  },
  accountMultiple: {
    family: "MaterialCommunityIcons",
    name: "account-multiple",
  },
  accountSettings: {
    family: "MaterialCommunityIcons",
    name: "account-settings",
  },
  accountGroup: {
    family: "MaterialCommunityIcons",
    name: "account-group",
  },
  eye: {
    family: "MaterialCommunityIcons",
    name: "eye",
  },
  eyeOff: {
    family: "MaterialCommunityIcons",
    name: "eye-off",
  },
  add: {
    family: "MaterialIcons",
    name: "add",
  },
  arrowBack: {
    family: "MaterialIcons",
    name: "arrow-back",
  },
  arrowForward: {
    family: "MaterialIcons",
    name: "arrow-forward",
  },
  arrowDown: {
    family: "MaterialIcons",
    name: "arrow-downward",
  },
  arrowUp: {
    family: "MaterialIcons",
    name: "arrow-upward",
  },
  calendar: {
    family: "MaterialCommunityIcons",
    name: "calendar",
  },
  calendarClock: {
    family: "MaterialCommunityIcons",
    name: "calendar-clock",
  },
  checkboxBlank: {
    family: "MaterialCommunityIcons",
    name: "checkbox-blank-outline",
  },
  checkboxMarked: {
    family: "MaterialCommunityIcons",
    name: "checkbox-marked",
  },
  checkboxMultipleBlank: {
    family: "MaterialCommunityIcons",
    name: "checkbox-multiple-blank-outline",
  },
  checkboxMultipleMarked: {
    family: "MaterialCommunityIcons",
    name: "checkbox-multiple-marked",
  },
  clock: {
    family: "MaterialCommunityIcons",
    name: "clock-outline",
  },
  cloudSync: {
    family: "MaterialCommunityIcons",
    name: "cloud-sync",
  },
  close: {
    family: "MaterialIcons",
    name: "close",
  },
  codeBraces: {
    family: "MaterialCommunityIcons",
    name: "code-braces",
  },
  console: {
    family: "MaterialCommunityIcons",
    name: "console",
  },
  consoleNetwork: {
    family: "MaterialCommunityIcons",
    name: "console-network",
  },
  databaseCheck: {
    family: "MaterialCommunityIcons",
    name: "database-check",
  },
  deleteSweep: {
    family: "MaterialIcons",
    name: "delete-sweep",
  },
  developerBoard: {
    family: "MaterialCommunityIcons",
    name: "developer-board",
  },
  documents: {
    family: "MaterialCommunityIcons",
    name: "file-document-box-multiple-outline",
  },
  dotsVertical: {
    family: "MaterialCommunityIcons",
    name: "dots-vertical",
  },
  dotsHorizontal: {
    family: "MaterialCommunityIcons",
    name: "dots-horizontal",
  },
  edit: {
    family: "MaterialCommunityIcons",
    name: "pencil",
  },
  email: {
    family: "MaterialCommunityIcons",
    name: "email-outline",
  },
  filter: {
    family: "MaterialCommunityIcons",
    name: "filter-variant",
  },
  formatListCheckbox: {
    family: "MaterialCommunityIcons",
    name: "format-list-checkbox",
  },
  heartPulse: {
    family: "MaterialCommunityIcons",
    name: "heart-pulse",
  },
  help: {
    family: "MaterialCommunityIcons",
    name: "help-circle-outline",
  },
  import: {
    family: "MaterialCommunityIcons",
    name: "import",
  },
  keyboardArrowDown: {
    family: "MaterialIcons",
    name: "keyboard-arrow-down",
  },
  keyboardArrowLeft: {
    family: "MaterialIcons",
    name: "keyboard-arrow-left",
  },
  keyboardArrowRight: {
    family: "MaterialIcons",
    name: "keyboard-arrow-right",
  },
  keyboardArrowUp: {
    family: "MaterialIcons",
    name: "keyboard-arrow-up",
  },
  lock: {
    family: "MaterialIcons",
    name: "lock",
  },
  messageTextOutline: {
    family: "MaterialCommunityIcons",
    name: "message-text-outline",
  },
  message: {
    family: "MaterialIcons",
    name: "message",
  },
  search: {
    family: "MaterialCommunityIcons",
    name: "magnify",
  },
  notifications: {
    family: "MaterialIcons",
    name: "notifications",
  },
  notificationsNone: {
    family: "MaterialIcons",
    name: "notifications-none",
  },
  notificationsActive: {
    family: "MaterialIcons",
    name: "notifications-active",
  },
  menu: {
    family: "MaterialCommunityIcons",
    name: "menu",
  },
  menuDown: {
    family: "MaterialCommunityIcons",
    name: "menu-down",
  },
  menuUp: {
    family: "MaterialCommunityIcons",
    name: "menu-up",
  },
  monitorDashboard: {
    family: "MaterialCommunityIcons",
    name: "monitor-dashboard",
  },
  powerPlug: {
    family: "MaterialCommunityIcons",
    name: "power-plug",
  },
  refresh: {
    family: "MaterialIcons",
    name: "refresh",
  },
  play: {
    family: "MaterialCommunityIcons",
    name: "play",
  },
  playCircle: {
    family: "MaterialCommunityIcons",
    name: "play-circle",
  },
  playCircleOutline: {
    family: "MaterialCommunityIcons",
    name: "play-circle-outline",
  },
  securityAccount: {
    family: "MaterialCommunityIcons",
    name: "security-account",
  },
  settings: {
    family: "MaterialCommunityIcons",
    name: "settings-outline",
  },
  server: {
    family: "MaterialCommunityIcons",
    name: "server",
  },
  sort: {
    family: "MaterialIcons",
    name: "sort",
  },
  stop: {
    family: "MaterialCommunityIcons",
    name: "stop",
  },
  stopCircle: {
    family: "MaterialCommunityIcons",
    name: "stop-circle",
  },
  stopCircleOutline: {
    family: "MaterialCommunityIcons",
    name: "stop-circle-outline",
  },
  restore: {
    family: "MaterialCommunityIcons",
    name: "restore",
  },
  viewDashboard: {
    family: "MaterialCommunityIcons",
    name: "view-dashboard-outline",
  },
  pagePrevious: {
    family: "MaterialCommunityIcons",
    name: "page-previous-outline",
  },
};

export default icons;