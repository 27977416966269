
/* AKS UAT API URLs*/
const BASE_URL = "https://xbpuatrepository.exela.global";
const USER_API = `${BASE_URL}/apis/requesttopay/v1.0/app/user`;
const NATIVE_APP_URL = "https://xbpliteuat.exela.global";
const MIXPANEL_TOKEN = '3f37d9c119c9adf090aa334976f37dc3';
const GOOGLE_ANALYTICS_TOKEN = 'G-NS0MEJ2SCV';

const KC_BASE_URL = 'https://auth-dev.exelatech.com';

const REALM_NAME = "ConsumerRealm";
const KC_CLIENT_ID = "uatxbppayer";
const CLAIM_CLIENT_ID = "uatxbpclaim";

export {
    BASE_URL, USER_API, NATIVE_APP_URL, KC_BASE_URL, REALM_NAME, KC_CLIENT_ID, CLAIM_CLIENT_ID, MIXPANEL_TOKEN, GOOGLE_ANALYTICS_TOKEN
}