const theme = {
  default: {
    colors: {
      primary: "#397ea0",
      onPrimary: "#FFFFFF",
      primaryDark: "#005271",
      onPrimaryDark: "#FFFFFF",
      primaryLight: "#4dc5f2",
      onPrimaryLight: "#FFFFFF",
      secondary: "#bc1683",
      onSecondary: "#FFFFFF",
      secondaryDark: "#f256b3",
      onSecondaryDark: "#FFFFFF",
      secondaryLight: "#870056",
      onSecondaryLight: "#FFFFFF",
      background: "#F4F4F4",
      onBackground: "#151b26",
      surface: "#FFFFFF",
      onSurface: "#151b26",
      error: "#B00020",
      onError: "#FFFFFF",
    },
    components: {
      header: { colors: { background: "#fff", onBackground: "#151b26" } },
      logo: { options: { company: "exela", colorScheme: "onLight" } },
    },
  },
  dark: {
    colors: {
      primary: "#00acc1",
      onPrimary: "#FFFFFF",
      primaryDark: "#005662",
      onPrimaryDark: "#FFFFFF",
      primaryLight: "#5ddef4",
      onPrimaryLight: "#FFFFFF",
      secondary: "#bc1683",
      onSecondary: "#FFFFFF",
      secondaryDark: "#870056",
      onSecondaryDark: "#FFFFFF",
      secondaryLight: "#f256b3",
      onSecondaryLight: "#FFFFFF",
      background: "#202020",
      onBackground: "#FFFFFF",
      surface: "#262626",
      onSurface: "#FFFFFF",
      error: "#B00020",
      onError: "#FFFFFF",
    },
    components: {
      header: { colors: { background: "#fff", onBackground: "#FFFFFF" } },
      logo: { options: { company: "exela", colorScheme: "onDark" } },
    },
  },
};

export default theme;