import React from "react";
import R14, {
  StyleSheet,
  Text,
  View,
  Form,
  SelectMenuField,
  FlatList,
  Touchable,
  Checkbox,
  Image,
} from "../../core";

import NoRecordsFound from "../../components/NoRecordsFound";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import { CommonString } from "../../constant/appCommonText";

import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { responsiveTableStyle } from "../../assets/css/responsiveTableStyle";
import { commonMetricsStyle } from "../../assets/css/commonMetricsStyle";
import DateUtils from "../../utils/DateUtils";
import { analyticsLogs } from "../../utils/AnalyticLogs";

const arrowLeft = require("../../assets/images/icPrevious.png");
const arrowRight = require("../../assets/images/icNext.png");
const arrowFirst = require("../../assets/images/icFirst.png");
const arrowLast = require("../../assets/images/icLast.png");
const type = "bills";
const readyToApprove = "ready to approve";
const paymentStatus = "unpaid";
const approvedStatus = "approved";
const declinedStatus = "declined";

export default R14.connect(
  class ApproveBillScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isAllBillsSelected: false,
        selectedBills: [],
        bills: [],
        currentPage: 1,
        resultsPerPage: 9,
        totalBills: 0,
        approverList: [],
        isLoading: false,
        approver: null,
        approvalStatus: readyToApprove,
      };
    }

    async componentDidMount() {
      try {
        const docForm = this.props.app.ui.form("approvalStatusForm");
        if (docForm) docForm.elmts.approvalStatus.setValue({ label: "Ready to Approve", value: readyToApprove });
        this.setState({isLoading: true});
        const payload = {
          "pid": this.props.user.pid,
          type,
          approvalStatus: readyToApprove,
          paymentStatus,
          "page": this.state.currentPage,
          "resultsPerPage": this.state.resultsPerPage
        };
        const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
        if(bill.success === false){
          this.props.app.dm.commonDomain.showToastr( "error", CommonString.SomethingWentWrong);
          this.setState({isLoading: false})
          return false;
        }
        const approverList = bill.approverList.map(approver => {
          return {label: approver.userName, value: approver.userID}
        })
        if (docForm) docForm.elmts.selectApprover.setItems(approverList);
        this.setState({
          bills: bill.data || [],
          totalBills: bill.totalRecords,
          approverList: approverList,
          isLoading: false,
        });
      } catch (error) {
        this.setState({
          isLoading: false,
        });
      }
    }

    onSelectAllBills = async () => {
      if(this.state.isAllBillsSelected) {
        this.setState({ isAllBillsSelected: false, selectedBills: [] });
        analyticsLogs(CommonString.APPROVE_BILL, `${CommonString.UNSELECTED_ALL_BILLS}`, this.props.app);
      }
      else {
        analyticsLogs(CommonString.APPROVE_BILL, `${CommonString.SELECTED_ALL_BILLS}`, this.props.app);
        const bills = this.state.bills.filter(bill => bill.approver === this.props.user.email);
        this.setState({ isAllBillsSelected: !this.state.isAllBillsSelected, selectedBills: bills });
      }
    }

    onSelectBill = (bill) => {
      const selectedBills = this.state.selectedBills;
      const isBillAlreadySelected = selectedBills.map(e => e.invoiceID).includes(bill.invoiceID);

      if (!!isBillAlreadySelected) {
        analyticsLogs(CommonString.APPROVE_BILL, `${CommonString.UNSELECT_BILL}`, this.props.app);
        this.setState({ selectedBills: selectedBills.filter(e => e.invoiceID !== bill.invoiceID), isAllBillsSelected: false });
      } else {
        analyticsLogs(CommonString.APPROVE_BILL, `${CommonString.SELECT_BILL}`, this.props.app);
        // selectedBills.push(bill);
        this.setState({ selectedBills: [bill], isAllBillsSelected: false });
      }
      return false;
    }

    async onApprovalStatusFilter(data) {
      const { label, value } = data;
      analyticsLogs(CommonString.APPROVE_BILL, `${label} ${CommonString.APPROVAL_STATUS_CHANGED}`, this.props.app);
      this.setState({isLoading: true});
        const payload = {
          "pid": this.props.user.pid,
          type,
          approvalStatus: value,
          paymentStatus,
          "page": 1,
          "resultsPerPage": this.state.resultsPerPage
        };
        const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
        if(bill.success === false){
          this.props.app.dm.commonDomain.showToastr( "error", CommonString.SomethingWentWrong);
          this.setState({isLoading: false})
          return false;
        }
        const docForm = this.props.app.ui.form("approvalStatusForm");
        const approverList = bill.approverList.map(approver => {
          return {label: approver.userName, value: approver.userID}
        })
        if (docForm) docForm.elmts.selectApprover.setItems(approverList);
        this.setState({
          bills: bill.data || [],
          totalBills: bill.totalRecords,
          approverList: approverList,
          isLoading: false,
          approvalStatus: value,
          currentPage: 1,
          resultsPerPage: 9,
          selectedBills: [],
          isAllBillsSelected: false
        });
    }

    async selectApprover(data) {
      analyticsLogs(CommonString.APPROVE_BILL, `${CommonString.APPROVER_SELECTED}`, this.props.app);
      this.setState({
        approver: data,
      });
    }

    getNext = async () => {
      let { currentPage, totalBills, resultsPerPage, approvalStatus } = this.state;
      const totalPages = Math.ceil(totalBills / resultsPerPage);
      if (currentPage <= totalPages) {
        currentPage = currentPage + 1;
        this.setState({isLoading: true});
        const payload = {
          "pid": this.props.user.pid,
          type,
          approvalStatus,
          paymentStatus,
          "page": currentPage,
          resultsPerPage: currentPage * resultsPerPage,
        };
        const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
        const docForm = this.props.app.ui.form("approvalStatusForm");
        const approverList = bill.approverList.map(approver => {
          return {label: approver.userName, value: approver.userID}
        })
        if (docForm) docForm.elmts.selectApprover.setItems(approverList);
        bill.data = bill.data.slice((currentPage - 1) * resultsPerPage, currentPage * (resultsPerPage + 1));
        this.setState({
          bills: bill.data,
          totalBills: bill.totalRecords,
          approverList: approverList,
          isLoading: false,
          currentPage: currentPage,
          isAllBillsSelected: false,
        });
      }
    }

    getPrevious = async () => {
      let { currentPage, resultsPerPage, approvalStatus } = this.state;
      if (currentPage >= 1) {
        currentPage = currentPage - 1;
        if (currentPage > 0) {
          this.setState({isLoading: true});
          const payload = {
            "pid": this.props.user.pid,
            type,
            approvalStatus,
            paymentStatus,
            "page": currentPage,
            resultsPerPage: currentPage * resultsPerPage
          };
          const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
          const docForm = this.props.app.ui.form("approvalStatusForm");
          const approverList = bill.approverList.map(approver => {
            return {label: approver.userName, value: approver.userID}
          })
          if (docForm) docForm.elmts.selectApprover.setItems(approverList);
          bill.data = bill.data.slice((currentPage - 1) * resultsPerPage, currentPage * (resultsPerPage + 1));
          this.setState({
            bills: bill.data,
            totalBills: bill.totalRecords,
            approverList: approverList,
            isLoading: false,
            currentPage: currentPage,
            isAllBillsSelected: false,
          });
        }
      }
    }


    getFirst = async () => {
      this.setState({isLoading: true});
      const payload = {
        "pid": this.props.user.pid,
        type,
        approvalStatus: this.state.approvalStatus,
        paymentStatus,
        "page": 1,
        "resultsPerPage": this.state.resultsPerPage
      };
      const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
      const docForm = this.props.app.ui.form("approvalStatusForm");
      const approverList = bill.approverList.map(approver => {
        return {label: approver.userName, value: approver.userID}
      })
      if (docForm) docForm.elmts.selectApprover.setItems(approverList);
      bill.data = bill.data.slice(0, this.state.resultsPerPage + 1);
      this.setState({
        bills: bill.data,
        totalBills: bill.totalRecords,
        approverList: approverList,
        isLoading: false,
        currentPage: 1,
        isAllBillsSelected: false,
      });
    }

    getLast = async () => {
      let { totalBills, resultsPerPage, approvalStatus } = this.state;
      const totalPages = Math.ceil(totalBills / resultsPerPage);

      this.setState({isLoading: true});
      const payload = {
        "pid": this.props.user.pid,
        type,
        approvalStatus,
        paymentStatus,
        "page": totalPages,
        resultsPerPage: totalPages * resultsPerPage
      };
      const bill = await this.props.app.dm.smbApi.getSMBInvoiceList(payload);
      const docForm = this.props.app.ui.form("approvalStatusForm");
      const approverList = bill.approverList.map(approver => {
        return {label: approver.userName, value: approver.userID}
      })
      if (docForm) docForm.elmts.selectApprover.setItems(approverList);
      bill.data = bill.data.slice((totalPages - 1) * resultsPerPage, totalPages * (resultsPerPage + 1));
      this.setState({
        bills: bill.data,
        totalBills: bill.totalRecords,
        approverList: approverList,
        isLoading: false,
        currentPage: totalPages,
        isAllBillsSelected: false,
      });
    }

    onApprove = async () => {
      const payload = {
        "pid":this.props.user.pid,
        type,
        "invoiceId": `${this.state.selectedBills[0].invoiceID}`,
        "status": approvedStatus,
        "from":"Mobile App"
      }
      if(!this.state.selectedBills[0].invoiceID) return false;
      const result = await this.props.app.dm.smbApi.saveSmbApproveInvoiceStatus(payload);
      if(result === false){
        this.props.app.dm.commonDomain.showToastr( "error", CommonString.SomethingWentWrong);
        this.setState({isLoading: false})
        return false;
      }
      analyticsLogs(CommonString.APPROVE_BILL, `${CommonString.APPROVED_BILL}`, this.props.app);
      this.props.app.dm.commonDomain.showToastr( "success", CommonString.BillApproved);
      this.getFirst();
      this.setState({
        selectedBills: [],
      })
    }

    onDecline = async () => {
      const payload = {
        "pid":this.props.user.pid,
        type,
        "invoiceId": `${this.state.selectedBills[0].invoiceID}`,
        "status": declinedStatus,
        "from":"Mobile App"
      }
      if(!this.state.selectedBills[0].invoiceID) return false;
      const result = await this.props.app.dm.smbApi.saveSmbApproveInvoiceStatus(payload);
      if(result === false){
        this.props.app.dm.commonDomain.showToastr( "error", CommonString.SomethingWentWrong);
        this.setState({isLoading: false})
        return false;
      }
      analyticsLogs(CommonString.APPROVE_BILL, `${CommonString.DECLINED_BILL}`, this.props.app);
      this.props.app.dm.commonDomain.showToastr( "error", CommonString.BillDeclined);
      this.getFirst();
      this.setState({
        selectedBills: [],
      })
    }

    renderPageTitleSection() {
      return (
        <View style={[styles.pageTitleHolder]}>
          <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, styles.pageTitle]}>
            {this.props.r14.navigation.currRoute._config.label}
          </Text>
        </View>
      );
    }

    approverUserName = (approverId) => {
      const {approverList} = this.state;
      const approver = approverList.find(e => e.value === approverId);
      return !!approver && approver.label ? approver.label : "";
    }

    render() {
      const {email} = this.props.user;
      const approvalBills = this.state.bills;
      const {currentPage, resultsPerPage, totalBills, isLoading, selectedBills, approvalStatus} = this.state;
      const totalPages = Math.ceil(totalBills / resultsPerPage);

      let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
      let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
      let rowItems = endIndex - startIndex + 1;
      return (
        <>
          <PCHLoaderComponent isVisiable={!!isLoading} labelText={"Please wait"} />
            <View style={[styles.container, commonStyles.dashboardScrollView, commonStyles.flex1]}>
              <View style={[styles.table]}>
                <View style={[styles.approvalHeader,]}>
                  <Form
                    name="approvalStatusForm"
                    validateBeforeSubmit={false}
                    style={[styles.optFormStyles, selectedBills.length ? null : styles.customFormHeight]}
                  >
                    <View style={[styles.statusFieldOuter, commonStyles.flex1, commonStyles.customFlexDirection]}>
                      <View style={[commonStyles.flexBasis49, styles.customMarginRight16]}>
                        <SelectMenuField
                          name="approvalStatus"
                          label={"Approval Status"}
                          style={[styles.statusField,]}
                          items={[{ label: "Ready to Approve", value: readyToApprove }, { label: "Approved", value: "approved" }, { label: "Declined", value: "declined" }]}
                          onValueChange={(e) => this.onApprovalStatusFilter(e)}
                          labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                          labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        />
                      </View>
                      <View style={[commonStyles.flexBasis49, commonStyles.displayNone]}>
                        <SelectMenuField
                          name="selectApprover"
                          label={"Select Approver"}
                          style={[styles.statusField,]}
                          items={this.state.approverList}
                          onValueChange={(e) => this.selectApprover(e)}
                          labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                          labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle]}
                        />
                      </View>
                    </View>
                  </Form>
                  <View style={[commonStyles.flexBasis50, commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, styles.customApproveBtns, selectedBills.length ? null : commonStyles.displayNone ]}>
                    <View style={[commonStyles.marginRight16]}>
                      <Touchable
                        style={[responsiveTableStyle.paynowBtn, styles.btnStyle, commonStyles.bgTransparent]}
                        onPress={() => this.onDecline() }>
                        <View style={[responsiveTableStyle.paynowBtnInnerWrap, customCommonStyles.customPaynowBtnInnerWrap, styles.btnWidth]}>
                          <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.whiteColor, commonStyles.textAlignCenter, commonStyles.fullWidth,]}>DECLINE</Text>
                        </View>
                      </Touchable>
                    </View>
                    <View style={[]}>
                      <Touchable
                        style={[responsiveTableStyle.paynowBtn, styles.btnStyle, commonStyles.bgTransparent]}
                        onPress={() => this.onApprove() }>
                        <View style={[responsiveTableStyle.paynowBtnInnerWrap, customCommonStyles.customPaynowBtnInnerWrap, styles.btnWidth]}>
                          <Text style={[commonMetricsStyle.heading6, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.whiteColor, commonStyles.textAlignCenter, commonStyles.fullWidth,]}>APPROVE</Text>
                        </View>
                      </Touchable>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, styles.selectAllChk, approvalBills.length ? null : commonStyles.displayNone]}>
                    <View style={[styles.th, styles.selectBillTh, approvalStatus === readyToApprove ? null : styles.opacity0,]}>
                      <Checkbox
                        name="selectAllBills"
                        label={`Select All`}
                        value={this.state.isAllBillsSelected}
                        onPress={() => this.onSelectAllBills()}
                        colorBlank="#585858"
                        colorMarked="#4dc5f2"
                        disabled={approvalStatus === readyToApprove ? false : true}
                      />
                    </View>
                    <View style={[commonStyles.paddingLeftRight16]}>
                      <Text style={[commonFontStyles.workSans, commonStyles.fontWeight700, commonStyles.heading6,]}>Select All</Text>
                    </View>
                  </View>
                </View>
                {!!approvalBills.length > 0 ? (
                  <View style={[styles.table, commonStyles.margin0, commonStyles.padding0, styles.borderWidth0]}>
                    <View style={styles.theadOuter}>
                      <View
                        style={[
                          styles.thead,
                          commonStyles.flex1,
                          commonStyles.justifyContentSpaceBetween,
                          commonStyles.flexDirectionRow,
                        ]}
                      >
                        <View style={[styles.th, styles.selectAllBillTh, approvalStatus === readyToApprove ? null : styles.opacity0]}>
                          <Checkbox
                            name="selectAllBills"
                            label={`Select All Bills`}
                            value={this.state.isAllBillsSelected}
                            onPress={() => this.onSelectAllBills()}
                            colorBlank="#585858"
                            colorMarked="#4dc5f2"
                            disabled={approvalStatus === readyToApprove ? false : true}
                          />
                        </View>
                        <View style={[styles.th, commonStyles.paddingRight16]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Vendor Name
                      </Text>
                        </View>
                        <View style={[styles.th]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            ExtRef. No
                      </Text>
                        </View>
                        <View style={styles.th}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Invoice#
                      </Text>
                        </View>
                        <View style={[styles.th]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Due Date
                      </Text>
                        </View>
                        <View style={[styles.th]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Due Amount
                      </Text>
                        </View>
                        <View style={[styles.th]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Payment Status
                      </Text>
                        </View>
                        <View style={[styles.th]}>
                          <Text
                            style={[
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.darkGrayColor,
                              commonStyles.tableKey,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            Approver
                      </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                ) : null}
                <FlatList
                  style={[
                    styles.flatlistEle,
                    approvalBills.length > 0
                      ? styles.memberListComponent
                      : styles.noItemsComponent,
                    this.props.app.dm.addInsuranceDomain.state.totalPages > 1 ? null : styles.insuranceListComponentWithNoPagi,
                    styles.borderWidth0,
                    commonStyles.marT0,
                    commonStyles.marRT0,
                    commonStyles.marLT0,
                    commonStyles.positionRelative,
                  ]}
                  data={approvalBills || []}
                  ListEmptyComponent={
                    <View style={[styles.padd10, commonStyles.flex1]}>
                      <NoRecordsFound
                        noRecords={[commonStyles.marginTop15, commonStyles.marT0, commonStyles.marRT0, commonStyles.marLT0, styles.customMargin16]}
                        message={CommonString.NoBillFound}
                      />
                    </View>
                  }
                  keyExtractor={(el, i) => i}
                  renderItem={({ item, index }) => (
                    <View key={"insuranceRow_" + index} style={[styles.customFlex]}>
                      <View style={[styles.th, styles.selectBillTh, approvalStatus === readyToApprove ? email === item.approver ? null : styles.opacity0 : styles.opacity0]}>
                        <Checkbox
                            name="selectBill"
                            label={`Select Bills`}
                            value={selectedBills.map(e => e.invoiceID).includes(item.invoiceID)}
                            onPress={() => this.onSelectBill(item)}
                            colorBlank="#585858"
                            colorMarked="#4dc5f2"
                            disabled={approvalStatus === readyToApprove ? email === item.approver ? false : true : true}
                          />
                      </View>
                      <View
                        style={[
                          styles.tr,
                          commonStyles.flex1,
                          commonStyles.justifyContentSpaceBetween,
                          styles.customFlexDirection,
                          commonStyles.marLT0,
                          commonStyles.marRT0
                        ]}
                      >
                        <View style={[styles.td, commonStyles.paddingRight16]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Vendor Name:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileBreakWord]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.businessName}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.memberName]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              ExtRef. No:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileMemberName]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.extRefNo}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.fullMaxWidthTd]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Invoice#:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileBreakWord, styles.fullMaxWidthTd]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.invoiceNo}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.memberName]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Due Date:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileMemberName]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {DateUtils.formatDateInMMDDYYYY(item.dueDate)}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.memberName]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Due Amount:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileMemberName]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.dueAmount}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.memberName]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Payment Status:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileMemberName]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {item.paymentStatus}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.td, styles.memberName]}>
                          <View
                            style={[
                              commonStyles.mView,
                              commonStyles.marginBottom5,
                              styles.mobileLabels,
                            ]}
                          >
                            <Text
                              style={[
                                commonFontStyles.workSans,
                                commonStyles.fontWeight700,
                                commonStyles.heading6,
                              ]}
                            >
                              Approver:
                        </Text>
                          </View>
                          <View style={[customCommonStyles.valueInMbileMemberName]}>
                            <Text
                              style={[
                                commonStyles.wordBreakWord,
                                commonStyles.darkGrayColor,
                                commonStyles.heading6,
                                commonFontStyles.workSans,
                                commonStyles.tableValue,
                                commonStyles.dBlock
                              ]}
                            >
                              {this.approverUserName(item.approverUserID)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                />
              { approvalBills.length ?
            <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.paginationWrap, commonStyles.flexGrow0, customCommonStyles.customPaginationWrap, styles.paginationWrap]}>
              <View style={[commonStyles.rowPerPage]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans]}>Rows per page: {rowItems}</Text>
              </View>
              <View style={[commonStyles.displayingItem]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans]}>Displaying item {startIndex}-{endIndex} of {totalBills}</Text>
              </View>
              <View style={[commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, commonStyles.paginationInnerWrap, commonStyles.flexGrow0]}>
                <View>
                  <Touchable
                    style={[currentPage === 1 ? commonStyles.disabledInput : null]}
                    onPress={() => currentPage === 1 ? null : this.getFirst()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === 1 ? commonStyles.disabledInput : null]}>
                      <Image source={arrowFirst} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View>
                  <Touchable
                    style={[currentPage === 1 ? commonStyles.disabledInput : null]}
                    onPress={() => currentPage === 1 ? null : this.getPrevious()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === 1 ? commonStyles.disabledInput : null]}>
                      <Image source={arrowLeft} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View>
                  <Touchable
                    style={[currentPage === totalPages ? commonStyles.disabledInput : null]}
                    onPress={() => currentPage === totalPages ? null : this.getNext()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === totalPages ? commonStyles.disabledInput : null]}>
                      <Image source={arrowRight} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View>
                  <Touchable
                    style={[currentPage === totalPages ? commonStyles.disabledInput : null]}
                    onPress={() => currentPage === totalPages ? null : this.getLast()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === totalPages ? commonStyles.disabledInput : null]}>
                      <Image source={arrowLast} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
            : null}
              </View>
            </View>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  pageTitleHolder: {
    // backgroundColor: "#fff",
    zIndex: 999,
    paddingTop: 7,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    background: "#f4f4f4",
  },
  pageTitle: {
    color: "#151b26",
  },
  container: {
    marginBottom: 16
  },
  table: {
    marginRight: 16,
    marginLeft: 16,
    backgroundColor: "#fff",
    padding: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderBottom: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          padding: 0,
          marginTop: 2,
          marginRight: 0,
          marginLeft: 0,
        };
    },
  },
  approvalHeader: {
    marginTop: -16,
    marginRight: -16,
    marginBottom: 0,
    marginLeft: -16,
    position: "relative",
    zIndex: 99,
    flex: 1,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          flexDirection: "column",
        };
      }
    },
  },
  optFormStyles: {
    paddingBottom: 10,
    paddingRight: 0,
    maxWidth: "50%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
          marginRight: 16,
        }
      }
    },
  },
  customFormHeight: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: 70,
        }
      }
    },
  },
  statusFieldOuter: {
    marginBottom: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        }
      }
    },
  },
  statusField: {
    marginBottom: 0,
  },
  borderWidth0: {
    borderWidth: 0
  },
  theadOuter: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  thead: {
    paddingTop: 3,
    paddingRight: 16,
    paddingBottom: 3,
    paddingLeft: 16,
    position: "relative",
    zIndex: 999,
    backgroundColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    flexDirection: "row",
  },
  tr: {
    flexDirection: "row",
    paddingTop: 3,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 10,
          paddingRight: 16,
          paddingBottom: 10,
          paddingLeft: 16,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          borderRightWidth: 0,
          // borderBottomWidth: 1,
          borderLeftWidth: 0,
          flexDirection: "column",
        };
    },
  },
  th: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
  },
  selectAllBillTh: {
    flex: 1,
    flexBasis: 45,
    maxWidth: 45,
    paddingRight: 16,
    // marginTop: -8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "75%",
          flexDirection: "row",
        };
    },
  },
  td: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "100%",
          flexDirection: "row",
        };
    },
  },
  flatlistEle: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "block",
        }
      }
    },
  },
  memberListComponent: {
    marginTop: 0,
    marginRight: -16,
    marginBottom: 30,
    marginLeft: -16,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    position: 'relative',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // borderTopWidth: 1,
          marginTop: 150,
          marginRight: 0,
          marginBottom: 100,
          marginLeft: 0,
          paddingBottom: 0,
        };
    },
  },
  noItemsComponent: {
    marginTop: 88,
    marginBottom: 50,
    marginLeft: 16,
    marginRight: 16,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 145,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        };
    },
  },
  insuranceListComponentWithNoPagi: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 35,
        };
    },
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  mobileLabels: {
    marginRight: 10,
    minWidth: 120,
  },
  fullMaxWidthTd: {
    wordBreak: "break-word",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
        }
      }
    },
  },
  memberName: {
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          flexBasis: "100%",
          maxWidth: "100%",
        };
      }
    },
  },
  customFlex: {
    flex: 1,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          border: "solid",
          borderTop: "none",
          borderWidth: 1,
          borderColor: "#dadada",
        };
      }
      if (width <= 992) {
        return {
          borderBottomWidth: 1,
          borderBottom: "solid",
          borderColor: "#dadada",
        };
      }
    },
  },
  selectBillTh: {
    paddingRight: 0,
    paddingLeft: 16,
    marginTop: 0,
    maxWidth: 45,
    flexBasis: 45,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingRight: 0,
          paddingLeft: 10,
          marginTop: 5,
        };
      }
    },
  },
  selectAllChk: {
    borderBottomWidth: 1,
    borderBottom: "solid",
    borderColor: "#dadada",
    paddingBottom: 10,
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          display: "none"
        };
      }
    },
  },
  customApproveBtns: {
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 10,
    maxWidth: "50%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "100%",
          paddingTop: 10,
        };
      }
    },
  },
  paginationWrap: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          marginTop: 16,
          marginBottom: 28,
        };
      }
    },
  },
  btnWidth: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: 95,
          maxWidth: 95
        };
      }
    },
  },
  customMargin16: {
    marginTop: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          margin: 16
        };
      }
    },
  },
  customMarginRight16: {
    marginRight: 0,
    width: "100%",
    minWidth: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0
        };
      }
    },
  },
  btnStyle: {
    height: 24,
    minHeight: 24,
    maxHeight: 24,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%"
        };
      }
    },
  },
  opacity0: {
    opacity: 0,
  }
});
