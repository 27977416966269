import React, { Component } from "react";
import R14, { StyleSheet, View, Text, Touchable, Image, Button, FlatList } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import RadioButtons from "../components/RadioButtons";
import NoRecordsFound from "../components/NoRecordsFound";
import PopupModalComponent from "../components/PopupModalComponent";
import { CommonString } from "../constant/appCommonText";
import { MESSAGE_TYPE } from "../constant/app.data.constant";

const bank_logo = require("../assets/images/bank_logo.png");
const edit_bank = require("../assets/images/edit_bank.png");
const deleteIcon = require("../assets/images/delete.png");

export default R14.connect(
  class BankList extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedOpt: false,
        optVal: "Set Default",
        accountList: [],
        displayAccountNumber: null,
        isVisible: false,
      };
    }
    componentDidMount = async () => {
      let accountList = [];
      const { bankAccountList } = this.props;
      let extOpt = { optVal: "" };
      if (bankAccountList) {
        bankAccountList.forEach((item) => {
          accountList.push({ ...item, ...extOpt });
        });
        this.setState({ accountList: accountList });
      }
    };
    onValueChange = async (item, index) => {
      this.props.app.ui.progressIndicator.show();
      let { accountList } = this.state;
      let selectedItem = item.isSetDefault ? false : true;
      accountList.forEach((ele) => (ele.optVal = ""));
      let selectedAccountItem = [];
      accountList.forEach((ele, key) => {
        if (ele.uid === item.uid) {
          selectedAccountItem.push(ele);
        }
      });
      let getAccountList = accountList;
      let getfilterdata = getAccountList.filter((el) => el.isSetDefault === true);
      if (!!getfilterdata.length > 0) {
        await Promise.all(
          getfilterdata.map(async (ele, key) => {
            let accountDetails = {
              payerId: ele.payerId,
              accountType: ele.accountType,
              accountHolderName: ele.accountHolderName,
              bankName: ele.bankName,
              accountNumber: ele.accountNumber,
              displayAccountNumber: ele.displayAccountNumber,
              rtnNumber: ele.rtnNumber,
              bicCode: ele.bicCode,
              isSetDefault: false,
              status: ele.status,
              uid: ele.uid,
            };
            await this.props.app.dm.billDetails.updatePayerAccountDetails(accountDetails);
          })
        );
      }
      if (!!selectedAccountItem.length > 0) {
        let accountDetails = {
          payerId: selectedAccountItem[0].payerId,
          accountType: selectedAccountItem[0].accountType,
          accountHolderName: selectedAccountItem[0].accountHolderName,
          bankName: selectedAccountItem[0].bankName,
          accountNumber: selectedAccountItem[0].accountNumber,
          displayAccountNumber: selectedAccountItem[0].displayAccountNumber,
          rtnNumber: selectedAccountItem[0].rtnNumber,
          bicCode: selectedAccountItem[0].bicCode,
          isSetDefault: selectedItem,
          status: selectedAccountItem[0].status,
          uid: selectedAccountItem[0].uid,
        };
        let update = await this.props.app.dm.billDetails.updatePayerAccountDetails(accountDetails);
        if (update) {
          let accountList = await this.props.app.dm.billDetails.getPayerAccountDetailsList();
          accountList.forEach((ele) => (ele.isSetDefault ? (ele.optVal = "Set Default") : (ele.optVal = "")));
          this.setState({ selectedOpt: selectedItem, accountList: accountList });
          this.props.app.dm.commonDomain.showToastr(
            "success",
            `${selectedAccountItem[0].displayAccountNumber} successfully set as default bank account`
          );
        } else {
          this.setState({ accountList: accountList });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    };
    gotoAddNewAccount = async () => {
      this.props.app.dm.billDetails.setState({ bankTabActive: "Bank" });
      this.props.app.nav.to("addNewAccountRoute");
    };
    editAccount = (uid) => {
      this.props.app.dm.billDetails.setState({ bankTabActive: "Bank" });
      this.props.app.nav.to("editAccountRoute", { uid: uid });
    };

    deleteAccount = (uid) => {
      let { accountList } = this.state;
      let selectedAccountItem = [];
      accountList.forEach((ele, key) => {
        if (ele.uid === uid) {
          selectedAccountItem.push(ele);
        }
      });
      if (!!selectedAccountItem) {
        this.setState({ isVisible: true, displayAccountNumber: selectedAccountItem[0].displayAccountNumber });
      } else {
        this.setState({ isVisible: false, displayAccountNumber: null });
      }
    };
    handleDeletePress = async () => {
      let { accountList, displayAccountNumber } = this.state;
      let selectedAccountItem = [];
      accountList.forEach((ele, key) => {
        if (ele.displayAccountNumber === displayAccountNumber) {
          selectedAccountItem.push(ele);
        }
      });
      if (!!selectedAccountItem.length) {
        let accountDetails = {
          payerId: selectedAccountItem[0].payerId,
          accountType: selectedAccountItem[0].accountType,
          accountHolderName: selectedAccountItem[0].accountHolderName,
          bankName: selectedAccountItem[0].bankName,
          accountNumber: selectedAccountItem[0].accountNumber,
          displayAccountNumber: selectedAccountItem[0].displayAccountNumber,
          rtnNumber: selectedAccountItem[0].rtnNumber,
          bicCode: selectedAccountItem[0].bicCode,
          isSetDefault: selectedAccountItem[0].isSetDefault,
          status: "Disabled",
          uid: selectedAccountItem[0].uid,
        };
        try {
          let update = await this.props.app.dm.billDetails.updatePayerAccountDetails(accountDetails);
          if (update) {
            this.props.app.dm.commonDomain.showToastr("success", `Bank Account ${displayAccountNumber} deleted successfully`);
          } else {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }
        } catch (error) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
        let accountList = await this.props.app.dm.billDetails.getPayerAccountDetailsList();
        this.setState({ isVisible: false, displayAccountNumber: null, accountList: accountList });
      }
    };
    gotoAddNewAccount = async () => {
      this.props.app.nav.to("addNewAccountRoute");
    };
    onClose = async () => {
      this.setState({ isVisible: false });
    };

    render() {
      const { accountList } = this.state;
      return (
        <View style={[commonStyles.dFlex, commonStyles.flexDirectionColumn, commonStyles.flexGrow1, commonStyles.paddingBottom30]}>
          <FlatList
            style={[styles.noRecords, styles.cardListHolder]}
            ListEmptyComponent={<NoRecordsFound message={"No Bank Accounts to display."} />}
            data={accountList}
            extraData={this.state}
            keyExtractor={(el) => el.uid}
            renderItem={({ item, index }) => (
              <View
                style={[
                  commonStyles.shadowBox,
                  commonStyles.dFlex,
                  commonStyles.marginBottom15,
                  styles.cardListItem,
                  index === 0 ? styles.margin15 : styles.marginLeftTopRight,
                ]}>
                <View style={styles.bankIcon}>
                  <Image source={bank_logo} style={styles.bankLogo} />
                </View>
                <View style={[styles.bankDetails, commonStyles.flexGrow1]}>
                  <View>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.transperentBtnColor, commonStyles.fontWeight500]}>
                      {item.bankName ? item.bankName : "N/A"}
                    </Text>
                  </View>
                  <View style={[commonStyles.dFlex, commonStyles.justifyContentSpaceBetween]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight500]}>
                      {item.accountHolderName ? item.accountHolderName : "N/A"}
                    </Text>
                    <Text
                      style={[commonStyles.transperentBtnColor, commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight700]}>
                      {item.accountType}
                    </Text>
                  </View>
                  <View>
                    <Text style={[commonStyles.darkGray, commonFontStyles.workSans, commonStyles.smallHeading]}>{item.displayAccountNumber} </Text>
                  </View>
                  <View style={[commonStyles.dFlex, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <RadioButtons
                        isSelected={item.isSetDefault ? "Set Default" : item.optVal}
                        onValueChange={() => this.onValueChange(item, index)}
                        options={{ label: "", value: "Set Default" }}
                        key={item.uid}
                        radioButtonsClass={[commonStyles.padding0]}
                      />
                      <View>
                        <Text style={[commonStyles.darkGray, commonFontStyles.workSans, commonStyles.smallHeading]}>Set Default</Text>
                      </View>
                    </View>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <Touchable onPress={() => this.editAccount(item.uid)}>
                        <View>
                          <Image source={edit_bank} style={commonStyles.icon20} />
                          </View>
                      </Touchable>
                      <Touchable style={commonStyles.marginLeft15} onPress={() => this.deleteAccount(item.uid)}>
                        <View>
                          <Image source={deleteIcon} style={commonStyles.icon20} />
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </View>
              </View>
            )}
          />
          <View style={[commonStyles.marTAuto, commonStyles.marginBottom15, styles.bottomButtons]}>
            <View>
              <Button
                title={"+ Add New Account"}
                style={[commonStyles.blueButton, styles.flex1, commonStyles.fullWidth]}
                titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading5]}
                onPress={this.gotoAddNewAccount}
              />
            </View>
            {/* <View style={[commonStyles.marginTop15]}>
              <Button
                title={"Prepopulated Account"}
                style={[commonStyles.blueButton, styles.flex1, commonStyles.fullWidth]}
                titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading5]}
              />
            </View> */}
          </View>
          <PopupModalComponent
            visibility={this.state.isVisible}
            bodyWrapper={[styles.bodyWrapper]}
            messageType={MESSAGE_TYPE.delete}
            headingIcon={true}
            heading={"Delete Bank Account"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={`Are you sure you want to delete the account ${this.state.displayAccountNumber}?`}
            okLabel={"No"}
            handleOk={this.onClose}
            closeLabel={"Yes"}
            handleClose={() => this.handleDeletePress()}
            modalHeading={[commonStyles.nativeModalHeading]}
          />
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  bankIcon: {
    marginRight: 10,
  },
  bankLogo: {
    width: 40,
    height: 40,
    display: "block",
    backgroundSize: "contain",
  },
  cardListHolder: {
    top: 50,
    paddingBottom: 60,
  },
  bottomButtons: {
    position: "relative",
    zIndex: 999,
  },
  buttons: {
    padding: 0,
    minWidth: "40%",
  },
  bodyWrapper: {
    width: 280,
  },
  modalOuterWrapper: {
    backgroundColor: "#00000059",
  },
  payNowWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  margin15: {
    margin: 15,
  },
  marginLeftTopRight: {
    marginLeft: 15,
    // marginTop:15,
    marginRight: 15,
  },
  sessionModelHeaderStyle: {
    marginTop: -45,
    marginRight: 0,
    marginBottom: 15,
    marginLeft: 0,
  },
  closeBtn: {
    position: "relative",
    zIndex: 999,
  },
});
