import R14, { AsyncStorage } from "../core";
import CommonUtility from "../utils/CommonUtility";
import { USER_TYPE } from "../constant/app.data.constant";
import RSAencryption from "../utils/RSAencryption";
import { CommonString } from "../constant/appCommonText";

export default class CommonQueryDomain extends R14.Domain {
  async getBiller(opt, fields = "uid buId billerId billerRef billerName industryName buName payerId payerRef messagingToggleValue isPaymentDecline isPaymentDueDateExtensions isPaymentsFull isPaymentsPartial actionBy", isUpdateStorage = true) {
    let options = { ...opt };
    try {
      const userLoginDetail = await this.dm.rest.getUser();
      if (!!userLoginDetail && (userLoginDetail.userType === USER_TYPE.PCH_PROVIDER || userLoginDetail.userType === USER_TYPE.PCH_PATIENT)) return [];
      let res = await this.api.qry(
        `
        query Biller($filter: BillerFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
          billers(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            nodes {
              ${fields}
            }
          }
        }`,
        options
      );
      let billers = res.data.billers.nodes;
      if (!!isUpdateStorage) {
        // to sync billerList & userBUDetails 
        const billerBUs = await this.dm.rest.getuserBUDetails();
        billers = billers.map(biller => {
          billerBUs.forEach(bu => {
            if (biller.buId === bu.buId) {
              biller.buName = bu.buName;
              // biller.billerRef = bu.billerRef;
              // biller.payerRef = bu.payerRef;
            }
          });
          return biller;
        });

        let pidBUs = await this.dm.rest.getLinkedPIDBU();
        billers = billers.filter((el) => {
          return pidBUs.some((f) => {
            return ((f.buId === el.buId && f.pid === el.payerId && f.billerId === el.billerId) || !f.buId);
          });
        });
        this.dm.userSession.setState({
          billers: billers
        })
        AsyncStorage.setItem(CommonString.LOCAL_STORAGE_BILLER, RSAencryption.EnctyptString(JSON.stringify(billers)));
      }
      return billers;
    } catch (error) {
      return error;
    }
  }

  async getBiller_new(resultsPerPage = 10, fields = "uid buId billerId billerRef billerName industryName buName payerId payerRef messagingToggleValue isPaymentDecline isPaymentDueDateExtensions isPaymentsFull isPaymentsPartial actionBy", isUpdateStorage = true) {
    try {
      const userLoginDetail = await this.dm.rest.getUser();
      if (!!userLoginDetail && (userLoginDetail.userType === USER_TYPE.PCH_PROVIDER || userLoginDetail.userType === USER_TYPE.PCH_PATIENT)) return [];
      let options = {
        "resultsPerPage": resultsPerPage,
        "sort": {
          "field": "billDate",
          "order": "DESC"
        }
      };
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query getBillersList($page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          getBillersList(page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            nodes {
              ${fields}
            }
            totalCount
          }
        }
        `,
        options
      ));
      let billers = res.data.getBillersList.nodes;
      if (!!isUpdateStorage) {
        // to sync billerList & userBUDetails 
        const billerBUs = await this.dm.rest.getuserBUDetails();
        billers = billers.map(biller => {
          billerBUs.forEach(bu => {
            if (biller.buId === bu.buId) {
              biller.buName = bu.buName;
              // biller.billerRef = bu.billerRef;
              // biller.payerRef = bu.payerRef;
            }
          });
          return biller;
        });

        let pidBUs = await this.dm.rest.getLinkedPIDBU();
        billers = billers.filter((el) => {
          return pidBUs.some((f) => {
            return ((f.buId === el.buId && f.pid === el.payerId && f.billerId === el.billerId) || !f.buId);
          });
        });

        this.dm.userSession.setState({
          billers: billers
        })
        AsyncStorage.setItem(CommonString.LOCAL_STORAGE_BILLER, RSAencryption.EnctyptString(JSON.stringify(billers)));
      }
      return billers;
    } catch (error) {
      return error;
    }
  }

  async addBiller(input, fields = "uid") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(` mutation CreateBiller($input: CreateBillerInput) {
        createBiller(input: $input){
          biller {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
      return res.data.createBiller.biller;
    } catch (error) {
      return error;
    }
  }

  async updateBiller(input, fields = "uid") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(`
      mutation UpdateBiller($input: UpdateBillerInput) {
        updateBiller(input: $input){
          biller {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
      return res.data.updateBiller.biller;
    } catch (error) {
      return error;
    }
  }

  async blockBiller(input, fields = "uid") {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(`
      mutation BlockBiller($uid: String!) {
        blockBiller(uid: $uid){
          biller {
            ${fields}
          }
          success
        }
      }`,
        input
      ));
      return res.data.blockBiller.biller;
    } catch (error) {
      return error;
    }
  }

  async unBlockBiller(input, fields = "uid") {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(`
      mutation UnBlockBiller($uid: String!) {
        unBlockBiller(uid: $uid){
          biller {
            ${fields}
          }
          success
        }
      }`,
        input
      ));
      return res.data.unBlockBiller.biller;
    } catch (error) {
      return error;
    }
  }

  getBillerDirectoryPayload(data) {
    return {
      buId: data.buId,
      billerId: data.billerId,
      billerRef: data.billerRef,
      billerName: data.billerName,
      payerId: data.payerId,
      payerRef: data.payerRef,
      industryName: data.industryName,
      buName: data.buName,
      orgId: data.orgId,
      orgName: data.orgName,
      suId: data.suId,
      suName: data.suName,
      messagingToggleValue: !!data.biller ? data.biller.messagingToggleValue : true,
      isPaymentDecline: !!data.biller ? data.biller.isPaymentDecline : true,
      isPaymentDueDateExtensions: !!data.biller ? data.biller.isPaymentDueDateExtensions : true,
      isPaymentsFull: !!data.biller ? data.biller.isPaymentsFull : true,
      isPaymentsPartial: !!data.biller ? data.biller.isPaymentsPartial : true,
    };
  }

  async addUpdateBiller(data, textToPayPreference) {
    let payload = this.getBillerDirectoryPayload(data);
    if (!!data.biller && !!data.biller.uid) {
      let { pams } = this.dm.billerDirectoryDomain.state;
      if (!pams || pams.length === 0)
        pams = await this.dm.billerDirectoryDomain.getPamMessages();

      if (!!data.uid && !!data.status) {
        let pam = pams.find(x => x.uid === data.uid);
        // update pamMessage Table for account/pam status
        if (!pam || pam.status !== data.status)
          await this.dm.billerDirectoryDomain.updatePamMessages({ status: data.status, uid: data.uid, textToPayPreference: textToPayPreference });
      }
      return await this.updateBiller({ ...payload, uid: data.biller.uid });
    } else {
      //-re-check biller detail
      let billers = await this.getBiller({
        filter: {
          payerId: { eq: payload.payerId },
          billerId: { eq: payload.billerId },
          billerRef: { eq: payload.billerRef },
          payerRef: { eq: payload.payerRef },
          buId: { eq: payload.buId },
        }
      }, "uid", false);
      if (!!billers && billers.length > 0) {
        return await this.updateBiller({ ...payload, uid: billers[0].uid });
      }
      else {
        return await this.addBiller(payload);
      }
    }
  };

  async ValidateAddressDetails(filter) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(`
      query validateAddressDetails($address: ValidAddressInput) {
        validateAddressDetails(address: $address){
          success message error data
        }
      }`,
        { address: filter }
      ));

      res.data.validateAddressDetails.updatedAddress = {};
      res.data.validateAddressDetails.alertMessage = "";

      if (res && res.data && res.data.validateAddressDetails && res.data.validateAddressDetails.success) {
        const data = res.data.validateAddressDetails.data ? JSON.parse(res.data.validateAddressDetails.data) : {};
        if (!Object.hasOwn(filter, 'address1')) {
          if (data.Address1 && data.Address1._text && data.Address1._text !== "UNDEFINED" && data.Address2 && data.Address2._text && filter.address2 !== `${data.Address1._text} ${data.Address2._text}`)
            res.data.validateAddressDetails.updatedAddress.address1 = `${data.Address1._text} ${data.Address2._text}`;
          else if (data.Address1 && data.Address1._text && filter.address2 !== data.Address1._text && data.Address1._text !== "UNDEFINED" && filter.address2 !== `${data.Address1._text} ${data.Address2._text}`)
            res.data.validateAddressDetails.updatedAddress.address1 = data.Address1._text;
          else if (data.Address2 && data.Address2._text && filter.address2 !== data.Address2._text && filter.address2 !== `${data.Address1._text} ${data.Address2._text}`)
            res.data.validateAddressDetails.updatedAddress.address1 = data.Address2._text;
        }
        else {
          if (data.Address1 && data.Address1._text && filter.address1 !== data.Address1._text) res.data.validateAddressDetails.updatedAddress.address1 = data.Address1._text;
          if (data.Address2 && data.Address2._text && filter.address2 && filter.address2 !== data.Address2._text) res.data.validateAddressDetails.updatedAddress.address2 = data.Address2._text;
        }
        if (data.City && data.City._text && filter.city !== data.City._text) res.data.validateAddressDetails.updatedAddress.city = data.City._text;
        if (data.State && data.State._text && filter.state !== data.State._text) res.data.validateAddressDetails.updatedAddress.state = data.State._text;

        let zipCode = "";
        if (data.Zip5 && data.Zip5._text) zipCode = data.Zip5._text;
        if (data.Zip4 && data.Zip4._text) zipCode = zipCode + "-" + data.Zip4._text;
        if (zipCode !== filter.zipCode) res.data.validateAddressDetails.updatedAddress.zipCode = zipCode;

        if (data.ReturnText && data.ReturnText._text) res.data.validateAddressDetails.alertMessage = data.ReturnText._text;
        if (data.Business && data.Business._text) res.data.validateAddressDetails.isBusiness = data.Business._text;
      }

      return res.data.validateAddressDetails;
    } catch (error) {
      return {
        success: false,
        message: "Invalid Address"
      };
    }
  }

  async analyzeUploadDocument(input) {
    try {
      let res = await this.middleware(this.api.qry(`
      query analyzeUploadDocument($input: CreateDocumentLogInput!) {
        analyzeUploadDocument(input: $input)
        }`,
        { input }
      ));
      if (res.data.analyzeUploadDocument) {
        const result = JSON.parse(res.data.analyzeUploadDocument);
        if (result.InvoiceId) return result;
      }
      return {
        success: false,
        message: "Invalid Document Format"
      };
    } catch (error) {
      return {
        success: false,
        message: "Invalid Document"
      };
    }
  }

  async middleware(fn) {
    let result = await fn;
    if (!!result.errors && !!result.errors.length && result.errors[0].message.indexOf('TOKEN: ') > -1) this.dm.dashboard.setState({ sessionInActive: true });
    return result;
  }

  async clientResponse(payload) {
    try {
      let res = await  this.api.qry(`
      query ClientResponse($payload: String!) {
        clientResponse(payload: $payload) {
          bancHubUserName AuthorizeResult error
        } }`,
        { payload }
      )
      return res.data.clientResponse;

    } catch (error) {
      return null;
    }
  }
}
