import R14 from "../core";

export default class ACHPaymentDomain extends R14.Domain {
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            services: []
        }
    }

    async createBillPaymentHistory(payload) {
        try {
            this.api.mutate(`mutation CreateBillPaymentHistory($input: CreateBillPaymentHistoryInput!) {
                createBillPaymentHistory(input: $input){       
                    billPaymentHistory {
                        uid 
                    }
                }            
            }`,
            {
                input: payload,
            });
        } catch (error) {
            return error;
        }
    }

    // create-setup-intent
    async createSetupIntent(payload) {
        try {
            let res = await this.dm.queryDomain.middleware(this.api.mutate(
                `mutation CreateSetupIntent($input: CreateSetupIntentInput!) {
                createSetupIntent(input: $input){       
                    success
                    message
                    payerBankAccount {
                        uid intentId routingNumber accountNumber pId billerPid metaData isBankVerified isActive createdAt bankName billerName
                    }
                }            
            }`,
                {
                    input: payload,
                }
            ));
            return res.data;
        } catch (error) {
            return error;
        }
    }

    async getPayerBankAccounts(filter) {
        try {
            let res = await this.api.qry(`
             query PayerBankAccount($filter: PayerBankAccountFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
                    payerBankAccounts(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
                    totalCount @include(if: true)
                    nodes {
                        uid intentId routingNumber accountNumber pId billerPid metaData isBankVerified isActive createdAt bankName billerName verificationStatus message buId buName
                    }
                    }
                }`,
                {
                    filter: filter,
                    sort: [
                        {
                            field: "pid",
                            order: "ASC",
                        },
                    ],
                }
            );
            return res.data.payerBankAccounts.nodes;
        } catch (error) {
            return error;
        }
    }

    // payment-intent
    async paymentIntent(payload) {
        try {
            let res = await this.dm.queryDomain.middleware(this.api.mutate(
                `mutation PaymentIntent($input: PaymentIntent!) {
                paymentIntent(input: $input)
            }`,
                {
                    input: payload,
                }
            ));
            return res.data;
        } catch (error) {
            return error;
        }
    }

    async updatePayerBankAccount(payload){
        try {
            let res = await this.api.mutate(
                `mutation UpdatePayerBankAccount($input: UpdatePayerBankAccountInput!) {
                updatePayerBankAccount(input: $input) {
                    success
                }
            }`,
                {
                    input: payload,
                }
            );
            return res.data;
        } catch (error) {
            return error;
        }
    }

    async getPackage() {
        try {
            if (!this.state.packages || this.state.packages.length === 0) {
                let res = await this.dm.queryDomain.middleware(this.api.qry(`
            query GetPackage {
                getPackage { nodes {
                    uid
                    serialNo
                    name
                    modules
                    amount
                    planType
                    duration
                    isTrial
              } } }`));
                let pack = res.data.getPackage.nodes;
                this.setState({ packages: pack });
                return pack;
            }
            else
                return this.state.packages

        } catch (error) {
            return null;
        }
    }

    async serviceModules() {
        try {
            if (!this.state.services || this.state.services.length === 0) {
                let res = await this.dm.queryDomain.middleware(this.api.qry(`
            query GetServices {
                getServices { nodes {
                    uid
                    serialNo
                    name
                    moduleName
                    subModules { id name routeName}
                    routeUrl
                    amount
                    userType
              } } }`));
                let pack = res.data.getServices.nodes;
                this.setState({ services: pack });
                return pack;
            }
            else
                return this.state.services

        } catch (error) {
            return null;
        }
    }


    async planCheckout(planId) {
        try {
            let res = await this.dm.queryDomain.middleware(this.api.mutate(
                `mutation PlanCheckout($plan: String!) {
                    createPlanCheckout(plan: $plan)
            }`,
                {
                    plan: planId,
                }
            ));
            return res.data.createPlanCheckout;
        } catch (error) {
            return "";
        }
    }

    async updateUserPlan(session) {
        try {
            let res = await this.dm.queryDomain.middleware(this.api.mutate(
                `mutation UpdateUserPlan($session: String!) {
                    updateUserPlan(session: $session)
                    {
                        uid isSubscriptionExpired planUid subscriptionUid
                    } }`,
                {
                    session: session
                }
            ));
            return res.data.updateUserPlan;
        } catch (error) {
            return "";
        }
    }

    async validatePlan(action) {
        try {
            let res = await this.dm.queryDomain.middleware(this.api.qry(`
            query ValidatePlan($action: String!) {
                validatePlan(action: $action) { 
                    success message
              } }`, {action : action} ));
            return res.data.validatePlan;

        } catch (error) {
            return { success: false, message: "ERROR" };
        }
    }


}