import React from "react";
import R14, { StyleSheet, View, Text, Form, ScrollView } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import DateUtils from "../utils/DateUtils";
import CommonUtility from "../utils/CommonUtility";
import SenderComponent from "../components/simpleMessage/SenderComponent";
import ReceiverComponent from "../components/simpleMessage/ReceiverComponent";
import CommonValidators from "../utils/CommonValidators";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";
import ChatboxInputField from "../components/ChatboxInputField";
const defaultSec = 30;

export default R14.connect(
  class SimpleMessageDetailScreen extends React.Component {
    constructor(props) {
      super(props);
      this.filesList = [];
      this.scrollRef = React.createRef();
      this.state = {
        messageContentHeight: 0,
        scrollCount: 0,
        messageDetails: this.props.messageDetails,
        isLoading: false,
        billerDetails: this.props.billerDetails,
        isChatAllow: this.props.billerDetails.messagingToggleValue,
      };
      this.timerIntance = null;
      this.form = null;
    }

    componentDidMount() {
      this.form = this.props.app.ui.form("simpleMsgChat");
      const { messageDetails, dbData, userDetails } = this.props;
      let count = messageDetails.filter((el) => el.envelope.messageHeader.recipientPid === userDetails.pid).length;
      let lastItem = JSON.parse(JSON.stringify(messageDetails[messageDetails.length - 1]));
      const { threadHeader } = lastItem.envelope;
      const { simpleMessage } = this.props.app.dm.simpleMessage.state;
      if (!!simpleMessage.length) {
        let ind = simpleMessage.findIndex((el) => el.threadId === threadHeader.threadId);
        simpleMessage[ind].readMsgCount = count;
        this.props.app.dm.simpleMessage.setState({ simpleMessage });
      }
      this.timerIntance = setInterval(async () => {
        let res = await this.props.app.dm.simpleMessage.getBillerFromThread(threadHeader.threadId);
        if (res !== undefined) {
          let onlyMessage = this.props.app.dm.simpleMessage.hasOnlyMessageBody(res.messages);
          let newCount = onlyMessage.filter((el) => el.envelope.messageHeader.recipientPid === userDetails.pid).length;
          let newLastItem = onlyMessage[onlyMessage.length - 1];
          this.setState({ messageDetails: onlyMessage });
          await this.props.app.dm.simpleMessage.updateMessage({
            ...dbData,
            lastMessage: newLastItem.envelope.messageBody.messageBody,
            lastMessageDate: newLastItem.envelope.messageHeader.messageDateTime,
            readMsgCount: newCount,
          });
        }
      }, 1000 * defaultSec);
    }

    componentWillUnmount() {
      if (this.timerIntance) {
        clearInterval(this.timerIntance);
      }
    }

    onSend = async (form) => {
      const { messageDetails } = this.state;
      if (await form.validate()) {
        const { comments } = form.values;
        let newDatetime = DateUtils.isoFormateDate();
        const { messageMeta, envelope } = messageDetails[0];
        let newPayload = {
          envelope: {
            ...envelope,
            messageBody: {
              messageBody: comments,
            },
          },
          messageMeta,
          attachments: !!this.filesList.length ? this.filesList : null,
        };

        try {
          const { dbData } = this.props;
          this.setState({ isLoading: true });
          newPayload = await this.props.app.dm.simpleMessage.replySimpleMessage(newPayload);
          let res = await this.props.app.dm.simpleMessage.postSimpleMessage(newPayload);
          // let res = null;
          if (!!res && !!res.threadId) {
            analyticsLogs(CommonString.SIMPLE_MESSAGE_DETAILS, `${CommonString.MESSAGE_SENT}`, this.props.app);
            this.props.app.dm.commonDomain.showToastr("success", CommonString.MessageSent);
            this.scrollToEnd({ y: this.state.messageContentHeight + 570 });
            this.setState({
              scrollCount: this.state.scrollCount + 1,
              messageDetails: this.state.messageDetails.concat(newPayload),
            });
            await this.props.app.dm.simpleMessage.updateMessage({ ...dbData, lastMessage: comments, lastMessageDate: newDatetime });
            this.filesList = [];
            const { simpleMessage } = this.props.app.dm.simpleMessage.state;
            if (!!simpleMessage && simpleMessage.length > 0) {
              let ind = simpleMessage.findIndex((el) => el.uid === dbData.uid);
              simpleMessage[ind].lastMessage = comments;
              simpleMessage[ind].lastMessageDate = newDatetime;
              this.props.app.dm.simpleMessage.setState({ simpleMessage: simpleMessage });
            }
            form.elmts.comments.setValue(null);
            if (!!form.elmts.attachment) {
              form.elmts.attachment.setValue(null);
            }
            return true;
          } else {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.MessageFail);
          }
        } catch (error) {
          this.props.app.dm.commonDomain.showToastr("error", error);
        } finally {
          this.setState({
            isLoading: false,
          });
        }
      }
      return false;
    };

    onFileChange = (val, isPop = false) => {
      !!val ? analyticsLogs(CommonString.SIMPLE_MESSAGE_DETAILS, `${CommonString.ATTACHMENT_ADDED}`, this.props.app) :
        analyticsLogs(CommonString.SIMPLE_MESSAGE_DETAILS, `${CommonString.ATTACHMENT_REMOVED}`, this.props.app);
      this.filesList = !!val ? [val]: [];
    }

    layoutChange = (event) => {
      let evtobj = event.nativeEvent.layout;
      // this.setState({
      //   messageContentHeight: evtobj.height,
      // });
      this.scrollToEnd({ y: evtobj.height });
      return evtobj.height;
    }

    scrollToEnd(options = { animated: true }) {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollToEnd(options);
      }
    }

    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.comments.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.comments.setValue(val.substring(0, 140));
        this.form.elmts.comments.setError("Character Limit Exceeds 140 Characters");
      }
    };

    render() {
      const { messageDetails, billerDetails, isChatAllow } = this.state;
      const { dbData, linkPids } = this.props;
      return (
        <View style={[commonStyles.flex1, styles.messageDetailsInnerScreen]}>
          <View style={[commonStyles.flex1, commonStyles.flexDirectionColumn]}>
            <View style={[commonStyles.flex1, commonStyles.alignItemsStart, commonStyles.borderBottomSpace10, styles.messageHeaderStyle, commonStyles.flexDirectionRow, commonStyles.flexGrow0]}>
              <View
                style={[
                  commonStyles.userIconDiv,
                  commonStyles.flex1,
                  commonStyles.alignItemsCenter,
                  commonStyles.justifyContentCenter,
                  commonStyles.marginRight15,
                  commonStyles.flexGrow0,
                ]}>
                <Text style={[commonStyles.heading6, commonStyles.fontWeight700, commonStyles.userInitial, commonFontStyles.workSans]}>
                  {CommonUtility.shortName(dbData.billerName)}
                </Text>
              </View>
              <View style={[commonStyles.flex1]}>
                <View>
                  <Text
                    style={[
                      styles.cardHeadingText,
                      commonFontStyles.workSans,
                      commonStyles.transformCapitalize,
                      commonStyles.heading5,
                      commonStyles.fontWeight500,
                      commonStyles.wordBreakWord,
                    ]}>
                    {dbData.billerName}
                  </Text>
                </View>
                <View>
                  <Text
                    style={[
                      styles.cardHeadingText,
                      commonFontStyles.workSans,
                      commonStyles.transformCapitalize,
                      commonStyles.heading6,
                      commonStyles.fontWeight500,
                      commonStyles.wordBreakWord,
                    ]}>
                    {!!dbData.biller ? !!dbData.biller.buName ? dbData.biller.buName : dbData.biller.buId : 'NA'}
                  </Text>
                </View>
                <View>
                  <Text
                    style={[
                      styles.cardHeadingText,
                      commonFontStyles.workSans,
                      commonStyles.heading6,
                      commonStyles.fontWeight500,
                      commonStyles.wordBreakWord,
                    ]}>
                    {messageDetails[0].envelope.threadHeader.subject}
                  </Text>
                </View>
              </View>
            </View>

            {/* Chat message starts here */}
            <ScrollView
              key={this.state.scrollCount}
              ref={this.scrollRef}
              onLayout={this.layoutChange}
              height={this.layoutChange}
              style={[customCommonStyles.centerScrollable, styles.simpleMessageCenterScrollable]}
              contentContainerStyle={styles.messagesData}
            >
              {messageDetails.map((ele, i) => (
                <View style={[commonStyles.flex1]} key={`msg-${i}`}>
                  {linkPids.split(',').includes(ele.envelope.messageHeader.senderPid) ? (
                    <SenderComponent leaf={ele} userDetails={(this.props.userDetails)} />
                  ) : (
                    <ReceiverComponent leaf={ele} billerName={dbData.billerName} />
                  )}
                </View>
              ))}
            </ScrollView>
            {/* Chat message ends here */}
          </View>
          {!billerDetails.isBlocked ? (
            !!isChatAllow ? (
              <View style={[styles.chatInputFieldSection]}>
                <Form
                  name="simpleMsgChat"
                  style={[commonStyles.paddingRight0, commonStyles.paddingLeft0, commonStyles.paddingTop0]}
                  validators={CommonValidators.getFormValidator()}
                  validateBeforeSubmit={true}
                  onSubmit={this.onSend}>
                  <ChatboxInputField
                    commentsProps={{ name: "comments", required: "Enter the Message" }}
                    onChangeText= {this.readText}
                    onValueChange={this.onFileChange}
                    onSend = {()=> this.onSend(this.form)}
                  />
                </Form>
              </View>
            ) : null
          ) : null}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  messageScreen: {
    height: "100%",
    flex: 1,
    minHeight: "100%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
          minWidth: "100%",
          alignItems: "center",
          margin: 0,
          paddingTop: 0,
          flexBasis: "100%",
          flexGrow: 1,
        };
    },
  },
  chatInputFieldSection: {
    marginTop: "auto",
  },
  billerShortName: {
    minWidth: 40,
  },
  messageHeaderStyle: {
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: -16,
    marginRight: -16,
  },

  customePadding: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  messageDetailsInnerScreen: {
    backgroundColor: "#fff",
    marginTop: 0,
    marginRight: 16,
    marginBottom: 16,
    marginLeft: 16,
    padding: 16,
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    overflowX: "auto",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  },
  errorMsgHolder: {
    position: "absolute",
    left: 0,
  },
  customMessageInptSpacing: {
    paddingTop: 5,
    paddingRight: 11,
    paddingBottom: 0,
    paddingLeft: 11,
  },
  titleBreadcrumbs: {
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // display: "none",
        };
    },
  },
  messagesData: {
    justifyContent: "flex-start",
  },
  customStylesFileUpload: {
    backgroundColor: "rgba(255,255,255, 0.0)",
  },
  simpleMessageCenterScrollable: {
    position: "static",
    height: "calc(100vh - 330px)", 
  },
  simpleMessageInputField: {
    lineHeight: 14,
  },
});
