import React from "react";
import R14, { StyleSheet, View, Text, Touchable, Image } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { STATUS_CLR } from "../constant/app.data.constant";
import { customCommonStyles } from "../assets/css/customCommonStyle";

export default R14.connect(
  class StatusComponent extends React.Component {
    render() {
      let currentRoute = this.props.r14.navigation.activeRouteName;
      const {
        label,
        toggleStatus,
        isSelected,
        hasClose,
        onCrossClick,
        marginRight,
        marginBottom,
        paymentStatusLabel,
        circleNotAllowed,
        isBackground,
        fontWeight,
        statusTextAlignment,
        isStatusColor,
        statusWrapStyle,
      } = this.props;
      let imgpath = require("../assets/images/bookmared_active.png");
      let statusLabel = !!paymentStatusLabel ? paymentStatusLabel : label;
      //label === "Paid" ? ( !paymentStatusLabel ? label: paymentStatusLabel ) : label
      return (
        <View style={[
          isBackground, isSelected ? styles[STATUS_CLR[statusLabel]] : null, styles.margingBtm,
          commonStyles.marginRight5,
          currentRoute === "bulkPaymentRoutes" || currentRoute === "makePaymentRoutes" || currentRoute === "dashboardNavigator" ? styles.customPadding : commonStyles.filterChips,
          !!circleNotAllowed ? [styles.paddwithoutcircle, commonStyles.alignItemsCenter] : null,
          marginRight,
          marginBottom,
          hasClose ? [
            commonStyles.flex1,
            commonStyles.flexDirectionRow, commonStyles.flexGrow0, commonStyles.marginBottom5] : null,
          isStatusColor ? styles.roundedBorder : null,
          isBackground && isSelected && isStatusColor ? styles.borderRadius15 : null,
        ]}>
          <Touchable onPress={toggleStatus}>
            <View style={[
              commonStyles.flex1,
              commonStyles.alignItemsCenter,
              !!isStatusColor || !!circleNotAllowed || label === "Priority" ? null : customCommonStyles.withoutCircle,
              commonStyles.flexDirectionRow,
              statusWrapStyle
            ]}>
              {!!circleNotAllowed ? null : label === "Priority" ? (
                <Image source={imgpath} resizeMode="contain" style={[styles.bookedMark]} />
              ) : (
                !!isStatusColor ? (<Text
                  style={[commonStyles.statusColor, styles[STATUS_CLR[statusLabel]], isSelected ? styles.bgCls : null, commonStyles.marginRight5]}></Text>)
                  : null)}
              <Text
                style={[
                  commonStyles.smallHeading,
                  commonFontStyles.workSans,
                  commonStyles.fontWeight400,
                  commonStyles.exSmallHeading,
                  isSelected ? commonStyles.whiteColor : commonStyles.contentColor,
                  commonStyles.transformUpperCase,
                  styles.padding3,
                  fontWeight,
                  statusTextAlignment
                ]}>
                {statusLabel}
              </Text>
            </View>
          </Touchable>
          {hasClose ? (
            <Touchable onPress={onCrossClick} style={styles.closeIcon}>
              <View>
                <Text style={[commonStyles.heading6, styles.activeCls]}>&#10006;</Text>
              </View>
            </Touchable>
          ) : null}
        </View>
      );
    }
  })

const styles = StyleSheet.create({
  NW: {
    backgroundColor: "#7accf6",
  },
  PD: {
    backgroundColor: "#008000",
  },
  DL: {
    backgroundColor: "#DC143C",
  },
  OD: {
    backgroundColor: "#EE82EE",
  },
  OS: {
    backgroundColor: "#FFA500",
  },
  PB: {
    backgroundColor: "#3f00ff",
  },
  IP: {
    backgroundColor: "#5286ff",
  },
  FD: {
    backgroundColor: "#b40220",
  },
  AD: {
    backgroundColor: "#218666",
  },
  RD: {
    backgroundColor: "#914518",
  },
  DR: {
    backgroundColor: "#7accf6",
  },
  BU: {
    backgroundColor: "#218666",
  },
  RQD: {
    backgroundColor: "#b43fee",
  },
  activeCls: {
    color: "#FFFFFF",
  },
  bgCls: {
    backgroundColor: "#FFFFFF",
  },
  margingBtm: {
    ...StyleSheet.margin(0, 0, 5, 0),
  },
  closeIcon: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 10,
    backgroundColor: "#00000000",
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderWidth: 0,
    height: "auto"
  },
  paddwithoutcircle: {
    paddingTop: 2,
    paddingRight: 10,
    paddingBottom: 2,
    paddingLeft: 10,
  },
  bookedMark: {
    height: 15,
    width: 15,
    backgroundSize: "contain",
    display: "block",
  },
  roundedBorder: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderRadius: 15,
    maxHeight: 26,
  },
  borderRadius15: {
    borderRadius: 15,
    maxHeight: 26,
  },
  paddingRight0: {
    paddingRight: 0,
  },
  customPadding: {
    screen: ({ width }) => {
      if (width >= 991)
        return {
          paddingRight: 5,
          paddingLeft: 5,
        };
    },
  },
  padding3: {
    paddingTop: 3,
    paddingRight: 3,
    paddingBottom: 3,
    paddingLeft: 3,
  }
});
