import React from "react";
import { StyleSheet, View, Text, AnimatedView } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import ModalPopUp from "../../components/ModalPopUp";
export default class PCHLoaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaderVisible: true,
    };
  }

  render() {
    let { labelText, clientStyle } = this.props;
    return this.props.isVisiable ? (
      <ModalPopUp
        visibility={this.state.isLoaderVisible}
        isFooter={true}
        modalContent={[commonStyles.flexGrow0,commonStyles.marginTop15]}
        bodyWrapper={[
          styles.isLoaderVisibleWrapper,
          commonStyles.flex1,
          commonStyles.flexGrow0,
          commonStyles.justifyContentCenter,
          commonStyles.alignItemsCenter,
          !!clientStyle ? clientStyle.secondaryBgColor : null,
        ]}
        modalOuterWrapper={[styles.modalOuterWrapper, commonStyles.flexGrow0]}
        heading={""}
        closeButton={commonStyles.displayNone} //{styles.invalidReferenceModalCloseBtn}
        modalHeading={[styles.sessionModelHeaderStyle, commonFontStyles.workSans, commonStyles.textAlignCenter, commonStyles.heading5]}>
        <View style={styles.positionRelative}>
          <AnimatedView
            style={styles.loaderCircle}
            animation={{
              from: {
                rotate: "0deg",
                transform: [{ rotate: "80deg" }],
              },
              to: {
                opacity: 1,
                transform: [{ rotate: "360deg" }],
              },
            }}
            timingFunction="ease-in"
            iterationCount="infinite"
            duration={500}>
            <Text style={[styles.circleGap, !!clientStyle ? clientStyle.circleGap : null]}></Text>
          </AnimatedView>
          <View style={[styles.processingText, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
            <Text style={[commonFontStyles.workSans, commonStyles.heading5, commonStyles.whiteColor, styles.positionRelative, !!clientStyle ? clientStyle.backAndCancelButtonText : null]}>{!!labelText ? labelText : "Processing"}</Text>
          </View>
        </View>
      </ModalPopUp>
    ) : null;
  }
}
const styles = StyleSheet.create({
  positionRelative: {
    position: "relative",
  },
  loaderCircle: {
    height: 100,
    width: 100,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    borderWidth: 4,
    borderStyle: "solid",
    borderColor: "#fff",
  },
  circleGap: {
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    borderWidth: 4,
    borderStyle: "solid",
    borderColor: "#00000020",
    display: "block",
    height: 100,
    width: 100,
    borderLeftColor: "#38c2ef",
    margin: -4,
  },
  processingText: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 100,
    width: 100,
  },
  firstDot: {
    position: "absolute",
  },
  secondDot: {
    position: "absolute",
  },
  thirdDot: {
    position: "absolute",
  },
  isLoaderVisibleWrapper: {
    backgroundColor: "rgba(255,255,255, 0.0)",
    border: 0,
    flexGrow: 0,
    width: "100%",
  },
});
