import React from "react";
import R14, { View, Text, StyleSheet, Touchable, Image, ControlsView } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import PayerPerferenceBottomSection from "./PayerPerferenceBottomSection";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";

const acceptIcon = require("../assets/images/accept_icon.png");
const declineIcon = require("../assets/images/decline-icon.png");

export default R14.connect(
  class PamMessasgeBottomSection extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        emailStatus: props.emailStatus,
        SMSstatus: props.SMSstatus,
      }
    }

    onEmailNotification = () => {
      this.setState({
        emailStatus: !this.state.emailStatus
      })
    }

    onSMSnotification = () => {
      this.setState({
        SMSstatus: !this.state.SMSstatus
      })
    }

    accept = async () => {
      let message = "accepted";

      // Text to pay preference 
      let textToPayPreference = "None";
      if (this.state.emailStatus && this.state.SMSstatus) {
        textToPayPreference = "Both";
      } else if (this.state.SMSstatus) {
        textToPayPreference = "SMS";
      } else if (this.state.emailStatus) {
        textToPayPreference = "Email";
      }
      analyticsLogs(CommonString.PAM_DETAILS, `${CommonString.ACCEPT_PAM}`, this.props.app);
      await this.sendMessage(message, textToPayPreference);
      return false;
    };

    sendMessage = async (message, textToPayPreference) => {
      try {
        const { pam } = this.props;
        let sendMessage = await this.props.app.dm.billerDirectoryDomain.updatePamMessageStatus({ status: message, textToPayPreference: textToPayPreference }, pam);
        if (!!sendMessage && !!sendMessage.pamId) {
          if (message === "accepted")
            this.props.app.dm.commonDomain.showToastr("success", CommonString.AcceptedAccount);
          else
            this.props.app.dm.commonDomain.showToastr("error", CommonString.DeclinedAccount);

          const { pamList } = this.props.app.dm.billerDirectoryDomain.state;
          if (!!pamList && !!pamList.length) {
            let ind = pamList.findIndex((itm) => itm.uid === pam.uid);
            pamList[ind].status = message;
            pamList[ind].textToPayPreference = textToPayPreference;
            this.props.app.dm.billerDirectoryDomain.setState({ pamList });
            await this.props.app.dm.billerDirectoryDomain.updatePamMessages({ uid: pam.uid, textToPayPreference: textToPayPreference, status: message });
          }
          this.props.app.nav.to("billersDirectoryRoute");
        }
        else
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      } catch (err) {
        console.log("error", err);
        return new Error(err);
      }
    }

    decline = async () => {
      let message = "denied";
      let textToPayPreference = "None";
      analyticsLogs(CommonString.PAM_DETAILS, `${CommonString.DECLINE_PAM}`, this.props.app);
      await this.sendMessage(message, textToPayPreference);
      return false;
    };

    render() {
      return (
        <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
          <PayerPerferenceBottomSection
            emailStatus={this.state.emailStatus}
            SMSstatus={this.state.SMSstatus}
            onEmailNotification={this.onEmailNotification}
            onSMSnotification={this.onSMSnotification}
            source="bottom"
          />
          <ControlsView
            type="buttons"
            style={[styles.bottomControlsView]}
            children={[
              <View key="controlViewBottom" style={[styles.bottomPaySection]}>
                <View key="payerPreferenceBottomHeading" style={[customCommonStyles.pamHeading]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.socialLoginText, commonStyles.fontWeight500, commonStyles.contentColor]}>Authorise PAM {`&`} Preferences</Text>
                </View>
                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceEvenly]}>
                  <View style={[commonStyles.marginLeft30, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.textAlignCenter]}>
                    <Touchable style={[commonStyles.textAlignCenter]} onPress={this.accept}>
                      <View>
                        <Image source={acceptIcon} style={[styles.payOptImages]} />
                      </View>
                    </Touchable>
                    <Touchable onPress={this.accept}>
                      <View style={[commonStyles.marginLeft10]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.smallHeading]}>Accept</Text>
                      </View>
                    </Touchable>
                  </View>
                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.textAlignCenter]}>
                    <Touchable style={[commonStyles.textAlignCenter]} onPress={this.decline}>
                      <View>
                        <Image source={declineIcon} style={[styles.payOptImages]} />
                      </View>
                    </Touchable>
                    <Touchable onPress={this.decline}>
                      <View style={[commonStyles.marginLeft10]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.smallHeading]}>Decline</Text>
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
            ]}
          />
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  bottomPaySection: {
    borderTop: "1px solid #C8C8C8",
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 10,
    paddingLeft: 15,
    width: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 10,
        }
      }
    }
  },
  payOptImages: {
    display: "block",
    width: 24,
    height: 24,
    backgroundSize: "contain",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
  },
  active: {
    color: "#37c2ef",
  },
  disable: {
    opacity: 0.5,
    cursor: "auto",
  },
  bottomControlsView: {
    // display: "block",
    height: "auto",
    flexBasis: "50%",
    borderLeftWidth: 1,
    borderStyle: "solid",
    borderColor: "#C8C8C8",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          borderLeftWidth: 0,
          width: "100%",
        }
      }
    }
  },
});
