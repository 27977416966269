import { CommonString } from "../constant/appCommonText";

export default class DeviceUtils {

  static getDeviceStatus = (name) => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .enumerateDevices()
        .then(this.gotDevices)
        .then(async(availableVideoInputs) => {
          if (availableVideoInputs.length > 0) {
            //  this.getPermission({name:"microphone"})
            const permission = await this.getPermission({ name: "camera" })
            return resolve(permission);   
          }
        })
        .catch((err) => {
          console.log("getDeviceStatus: ", err)
          return resolve("deviceNA");
        });
    });
  };


  static gotDevices = (mediaDevices) =>
    new Promise((resolve, reject) => {
      const availableVideoInputs = [];
      mediaDevices.forEach((mediaDevice) => {
        if (mediaDevice.kind === "videoinput") {
          // audioinput //videoinput
          availableVideoInputs.push({
            deviceId: mediaDevice.deviceId,
            label: mediaDevice.label,
          });
        }
      });

      if (availableVideoInputs.length > 0) {
        resolve(availableVideoInputs);
      } else {
        reject(new Error("ERR::NO_MEDIA_TO_STREAM"));
      }
    });


  static resolvePermission = (permission) =>
    new Promise((resolve, reject) => {
      permission.onchange = function () {
        console.log("Permission changed to " + permission.state);
      };

      const permissions = [permission.state];
      if (permissions.length > 0) {
        resolve(permissions);
      } else {
        reject(new Error("ERR::NO_MEDIA_TO_STREAM_PERMISSION"));
      }
    });
  
  static getPermission = (PermissionDescriptor) => {
    return new Promise((resolve, reject) => {
      try {
        if (!!navigator.permissions) {
          navigator.permissions
            .query(PermissionDescriptor)
            .then(this.resolvePermission)
            .then((permission) => {
              if (permission.includes("prompt") || permission.includes("granted")) {
                return resolve("granted");
              } else if (permission.includes("denied")
              ) {
                return resolve("allowDevie");
              }
            })
            .catch((err) => {
              console.log("getPermission: ", err);
              return reject(err);
            });
        }
        else
          return resolve("granted")
      } catch (err) {
        return reject(err);
      }
    });
  };

  static getDevice = () => {
    return new Promise((resolve, reject) => {  
      this.getDeviceStatus("camera")
        .then((deviceResponse) => {
          if (deviceResponse === "granted")
            return resolve("granted");
          else {
            if (deviceResponse === "allowDevie") {
              return resolve(CommonString.CameraAccessDenied);
            }
            else if (deviceResponse === "deviceNA") {
              return resolve(CommonString.CameraNotFound);
            }
            else
            {
              return resolve(CommonString.CameraNotFound);
            }
          }
          // return  resolve(null);
        });
    });
  }


  static resolvePermission = (permission) =>
    new Promise((resolve, reject) => {
      permission.onchange = function () {
        console.log("Permission changed to " + permission.state);
      };

      const permissions = [permission.state];
      if (permissions.length > 0) {
        resolve(permissions);
      } else {
        reject(new Error("ERR::NO_MEDIA_TO_STREAM_PERMISSION"));
      }
    });
  
}


