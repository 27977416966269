import React from "react";
import R14, {
  View,
  Text,
  StyleSheet,
  TextInputField,
  Platform
} from "../../core";
import DropDown from "../DropDown";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";

export default R14.connect(
  class DiagnosticDetailsComponent extends React.Component {

    render() {
        const { isUberDocs } = this.props.app.dm.userSession.state;
        let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
        let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

        // to check brower and add class accordingly 
        if(Platform.OS === 'web' && /Android/i.test(window.navigator.userAgent)){
          document.body.classList.add("android");
        } else if (Platform.OS === 'web' && /iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
          document.body.classList.add("ios");
        };

      return (
        <View style={[commonStyles.marginTopMinus20]}>
          {/* Diagnosis Details */}
          < View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, this.props.isSuperBill && styles.marginBottom32, this.props.isSuperBill && styles.displayNone, this.props.isEditOrAddCpt && styles.displayNone]} >
            <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexBasis49, commonStyles.flexDirectionRow, styles.treatmentDetails]}>
                <Text
                  style={[
                    commonStyles.dBlock,
                    commonStyles.fontWeight500,
                    commonStyles.heading5,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}
                >Diagnosis Details</Text>
              </View>
          </View >
          {/* Diagnosis code & Total Claims amount */}
          < View style={[commonStyles.flex1, commonStyles.customFlexDirection, styles.inputHolder, styles.customAlignItemsStart, commonStyles.marginTop5, this.props.isSuperBill && styles.displayNone, this.props.isEditOrAddCpt && styles.displayNone]} >
            <View
              style={[
                commonStyles.flex1,
                styles.leftInputHolder,
                commonStyles.flexBasis48,
                styles.addIncProText,
                commonStyles.customFlexDirection,
                styles.custoomMarginTop30,
                commonStyles.flexDirectionColumn
              ]}>
              <DropDown
                name="diagnosisCodes"
                label={"Diagnosis Code"}
                onValueChange={(val) => this.props.onDiagnosticChange(val)}
                multiple={true}
                searchable={true}
                itemLoader={async ({ search }) => {
                  return await this.props.getDiagnosisList(search);
                }}
                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
              />
            </View>
            <View style={[
              commonStyles.flex1,
              styles.rightInputHolder,
              commonStyles.flexBasis48
            ]}>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, commonStyles.flexGrow0, commonStyles.flexDirectionColumn, commonStyles.displayNone]}>
                <TextInputField
                  name={"totalClaimAmount_copy"}
                  label={"Total Claim Amount*($)"}
                  secure={false}
                  onChangeText={(amount) => this.props.onTotalClaimAmount(amount)}
                  style={[styles.formFields, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonFontStyles.workSans, commonStyles.left0, commonStyles.helperTextFontStyle]}
                />
                {!this.props.isSuperBill && this.props.isTotalClaimAmountError ? (
                  <View style={[styles.totalAmountErrorLabel]}>
                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.helperTextFontStyle]} >Enter the total claim amount</Text>
                  </View>
                ) : null}
              </View>
            </View>
          </View >
        </View>
      )
    }
  }
);
const styles = StyleSheet.create({
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customAlignItemsStart: {
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: null,
        };
      }
    },
  },
  marginBottom32: {
    marginBottom: 32
  },
  displayNone: {
    display: "none",
  },
  treatmentDetails: {
    width: "100%",
    justifyContent: "space-between",
    flexGrow: 0,
    paddingBottom: 3
  },
  addIncProText: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  custoomMarginTop30: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          maxWidth: "49%",
        };
      }
    }
  },
  diagnosisCodesErrorLabel: {
    marginLeft: 0,
    marginTop: -32
  },
  disableInputField: {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "default",
  },
  totalAmountErrorLabel: {
    marginLeft: 0,
    marginTop: -28
  },
});
