import React, { Component } from "react";
import R14, { StyleSheet, Text, View } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import SubmittedComponent from "../../components/PCH/SubmittedComponent";
import DateUtils from "../../utils/DateUtils";
import { USER_TYPE } from "../../constant/app.data.constant";

export default R14.connect(
  class SubmittedReceiptScreen extends Component {
    render() {
      let {userType, uploadBill} = this.props;
      let message = "Bill uploaded successfully.";
      if (userType === USER_TYPE.PCH_PROVIDER) message = "Bill uploaded successfully.";
      else if (userType === USER_TYPE.PCH_PATIENT) message = "Your claim received successfully.";
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <>
          <View style={[styles.pageTitle]}>
            <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500]}>
              {userType === USER_TYPE.PCH_PROVIDER ? "Bill Details" : this.props.r14.navigation.currRoute._config.label}
            </Text>
          </View>
          <View
            style={[
              // commonStyles.dFlex,
              // commonStyles.flexDirectionColumn,
              // commonStyles.flexGrow1,
              commonStyles.marginBottom15,
              styles.billDetailsScreen,
              clientStyle.secondaryBgColor
            ]}>
            {!!uploadBill && !!uploadBill.pchInsuranceDetail ? (
              <View>
                <View style={[commonStyles.flex1, styles.customflexDirection]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow]}>
                    <View style={[]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                        ]}>
                        Insurance
                      </Text>
                    </View>
                    <View>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {uploadBill.pchInsuranceDetail.name}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow]}>
                    <View style={[]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                        ]}>
                        Group
                      </Text>
                    </View>
                    <View>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {uploadBill.pchInsuranceDetail.groupNumber}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            ) : null}
            <View>
              {/* Insurance Address & Patient Name */}
              {userType === USER_TYPE.PCH_PATIENT ? (
                <View style={[commonStyles.flex1, styles.customflexDirection]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow]}>
                    <View style={[]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                        ]}>
                        Insurance Address
                      </Text>
                    </View>
                    <View>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {uploadBill.insuranceAddress}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.border0, styles.detailRow]}>
                    <View style={[]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                        ]}>
                        Patient Name
                      </Text>
                    </View>
                    <View style={[]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {uploadBill.patientName}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : null}
              <View style={[commonStyles.flex1, styles.customflexDirection]}>
                <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow]}>
                  <View style={[]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                      ]}>
                      ICN
                    </Text>
                  </View>
                  <View>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {uploadBill.uniqueCode}
                    </Text>
                  </View>
                </View>
                <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow]}>
                  <View style={[]}>
                    <Text
                      style={[
                        commonStyles.dBlock,
                        commonStyles.fontWeight500,
                        commonStyles.heading6,
                        commonFontStyles.workSans,
                        commonStyles.marginBottom5,
                      ]}>
                      Uploaded On
                    </Text>
                  </View>
                  <View style={[]}>
                    <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                      {!!uploadBill.uploadedDateTime ? uploadBill.uploadedDateTime : DateUtils.dateAndTimeWithAmPm(uploadBill.createdAt, "PCH")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {(!!uploadBill.parentName || !!uploadBill.providerName) ? (
              <View>
                <View style={[commonStyles.flex1, styles.customflexDirection]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow]}>
                    <View style={[]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                        ]}>
                        Practice Group
                      </Text>
                    </View>
                    <View>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {
                          !!uploadBill.parentName ? uploadBill.parentName : "Refer Claim Doc"
                        }
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow]}>
                    <View style={[]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                        ]}>
                        Provider
                      </Text>
                    </View>
                    <View style={[commonStyles.fullWidth]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla]}>
                        {!!uploadBill.providerName ? uploadBill.providerName : ""}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            ) : null}
            <SubmittedComponent message={message} customMargin={styles.customMargin} userType={userType} isUberDocs={isUberDocs} />
          </View>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  billDetailsWrapper: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          backgroundColor: "#FFF",
        };
    },
  },
  pageTitle: {
    margin: 16,
  },
  billDetailsScreen: {
    flex: 1,
    flexGrow: 1,
    // alignItems: "flex-end",
    backgroundColor: "#fff",
    padding: 15,
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          marginRight: 0,
          marginLeft: 0,
        };
    },
  },
  redClr: {
    color: "#CC0000",
  },

  detailRow: {
    borderBottomWidth: 1,
    borderBottom: "1px solid rgb(218, 218, 218)",
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
    paddingBottom: 5,
    marginBottom: 15,
    flexBasis: "48%",
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginBottom: 10,
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  mViewMarginBottom: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 25,
          paddingTop: 0,
        };
      }
    },
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMargin: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 5,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  border0: {
    borderBottomWidth: 0,
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
    paddingBottom: 5,
    marginBottom: 15,
    flexBasis: "48%",
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          borderBottomWidth: 0,
          flexBasis: "100%",
          marginBottom: 10,
        };
      }
    },
  },
});
