import React from "react";
import { StyleSheet, ExternalLink } from "../../core";
import { commonFontStyles } from "../../assets/css/commonFontStyles";

export default class ExternalLinkComponent extends React.Component {
    render() {
        const { linkStyle, linkURL, linkText} = this.props
        return (
            <ExternalLink style={[styles.linkColor, commonFontStyles.workSans, linkStyle]} url={linkURL}>{linkText}</ExternalLink>
        )
    }
}

const styles = StyleSheet.create({
    linkColor: {
        color: "#5ccaf3",
        // textDecoration: "none",
        fontSize: 14,
    }
});
