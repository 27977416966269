import R14, { AsyncStorage } from "../core";
import axios from "axios";
import { BASE_URL } from "../constant/app.constant";
import { CommonString } from "../constant/appCommonText";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 1000000,
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Expose-Headers": "Access-Token, Uid",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": true
  }
  // ,httpsAgent: agent
});

axios.defaults.headers.common["Authorization"] = `Bearer `;
// axios.defaults.headers.common["grant_type"] = `refresh_token`;

instance.interceptors.request.use(
  (req) => {
    if (axios.defaults.headers.common["Authorization"]) return req;
    // eslint-disable-next-line no-throw-literal
    throw { message: "Authorization token is not available" };
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    return Promise.reject(error);
  }
);

export default class ApiDomain extends R14.Domain {
  async getAccessToken() {
    return `Bearer ${await this.dm.userSession.getAccessToken()}`;
  }

  async getUser() {
    try {
      const user = this.dm.userSession.state.userLoginDetail;
      if (user === null) {
        let profiles = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_PROFILE);
        profiles = !!profiles ? this.parseJSON(await this.dm.userLoginDomain.getValue(profiles)) : {};
        this.dm.userSession.setState({
          userLoginDetail: profiles
        })
      }
      return this.dm.userSession.state.userLoginDetail;
    } catch (error) {
      console.log(error);
      return {}
    }
  }

  async getUserWithDetails() {
    const userLoginDetail = await this.getUser();
    const buDetails = await this.getuserBUDetails();
    const buDetail = buDetails.find(bu => bu.userDetailUid === userLoginDetail.uid)
    return { ...userLoginDetail, ...buDetail, uid: userLoginDetail.uid }
  }

  async getLinkedPID() {
    let pidBUs = await this.getLinkedPIDBU();
    if (pidBUs.length > 0) {
      let pids = pidBUs.map((item) => { return item['pid'] });
      return pids.toString();
    } else return '';
  }

  async getLinkedPIDBU() {
    let linkedPayers = this.dm.userSession.state.linkedPayers;
    if (linkedPayers === null || !linkedPayers.length) {
      linkedPayers = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_LINKPAYER)
      linkedPayers = !!linkedPayers ? this.parseJSON(await this.dm.userLoginDomain.getValue(linkedPayers)) : [];
      this.dm.userSession.setState({
        linkedPayers: linkedPayers
      })
    }
    if (this.dm.userSession.state && this.dm.userSession.state.linkedPayers && this.dm.userSession.state.linkedPayers.length) {
      return this.dm.userSession.state.linkedPayers;
    } else return [];
  }

  async getBillers(input) {
    let billers = this.dm.userSession.state.billers;
    if (billers === null || !billers.length) {
      billers = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_BILLER);
      billers = !!billers ? this.parseJSON(await this.dm.userLoginDomain.getValue(billers)) : [];
      this.dm.userSession.setState({
        billers: billers
      })
    }
    if (!!input && !!input.buId) {
      return billers.find((x) => x.buId === input.buId && x.billerRef === input.billerRef && x.payerRef === input.payerRef);
    }
    return billers;
  }

  parseJSON(string) {
    try {
      return JSON.parse(string);
    } catch (error) {
      return "";
    }
  }

  async getuserBUDetails(input) {
    let userBUDetails = this.dm.userSession.state.userBUDetails;
    if (!userBUDetails || !userBUDetails.length) {
      userBUDetails = this.parseJSON(await this.dm.userLoginDomain.getValue(await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_BUDETAILS)) || "null") || [];
      this.dm.userSession.setState({
        userBUDetails: !!userBUDetails && userBUDetails.length ? userBUDetails : []
      })
    }
    if (!!input && !!input.buId) {
      let userBUDetail = userBUDetails.find((x) => x.buId === input.buId && x.billerRef === input.billerRef && x.payerRef === input.payerRef);
      if (!!userBUDetail && !!userBUDetail.uid)
        return userBUDetail;
      else {
        let linkedUserBUDetails = this.parseJSON(await AsyncStorage.getItem(`userBUDetails_${input.payerId}`) || "null") || [];
        if (!!linkedUserBUDetails && linkedUserBUDetails.length > 0) {
          let userBUDetail = linkedUserBUDetails.find((x) => x.buId === input.buId && x.billerRef === input.billerRef && x.payerRef === input.payerRef);
          if (!!userBUDetail && !!userBUDetail.uid)
            return userBUDetail;
          else
            return userBUDetails[0]
        }
        else {
          linkedUserBUDetails = await this.dm.user.getUserFromBiller(input.payerId, await this.dm.userSession.getAccessToken());
          userBUDetail = linkedUserBUDetails.find((x) => x.buId === input.buId && x.billerRef === input.billerRef && x.payerRef === input.payerRef);
          if (!!userBUDetail && !!userBUDetail.uid)
            return userBUDetail;
          else
            return userBUDetails[0]
        }
      }
    }
    return userBUDetails;
  }

  async billerBUdetails(uid) {
    try {
      let billers = await this.getBillers();
      // let billers = [{ "uid": "ZVMUHPvaW", "buId": "BU00000521", "billerId": "XBP2023000003259", "billerRef": "billerStripe", "billerName": "My Stripe Biller", "buName": null, "payerId": "XBP2023000003342" }, { "uid": "ZVMUHPvaW001", "buId": "BU00000521001", "billerId": "XBP2023000003259001", "billerRef": "billerStripe", "billerName": "Vodafone Biller", "buName": "Airtel 001", "payerId": "XBP2023000003342" }];

      if (!!uid) {
        const billerBU = billers.find(biller => {
          return biller.uid === uid;
        })
        return billerBU;
      } else return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getBillerBUs() {
    let billers = await this.getBillers();
    // let billers = [{ "uid": "ZVMUHPvaW", "buId": "BU00000521", "billerId": "XBP2023000003259", "billerRef": "billerStripe", "billerName": "My Stripe Biller", "buName": null, "payerId": "XBP2023000003342" }, { "uid": "ZVMUHPvaW001", "buId": "BU00000521001", "billerId": "XBP2023000003259001", "billerRef": "billerStripe", "billerName": "Vodafone Biller", "buName": "Airtel 001", "payerId": "XBP2023000003342" }];

    billers = billers.map(biller => {
      return {
        value: `${biller.uid}`,
        label: `${biller.billerName} (${biller.buName || biller.buId})`,
        buId: biller.buId,
        billerRef: biller.billerRef,
        senderPIDRef: biller.payerRef,
        messagingToggleValue: biller.messagingToggleValue,
        payerId: biller.payerId
      }
    });
    return { list: billers, billNameList: billers };
  }

  async getRefreshToken() {
    return `Bearer ${await this.dm.userSession.getRefreshToken()}`;
  }

  async auditLog(requestType, requestUrl, startTime, endTime, params, responseStatus, content) {
    if (1 === 2) {
      const user = await this.getUser();
      let input = {
        requestType: requestType,
        requestUrl: requestUrl,
        startTime: startTime,
        endTime: endTime,
        responseStatus: responseStatus,
        content: content,
        params: !!params ? JSON.stringify(params) : "",
        duration: endTime - startTime,
        userLoginDetailUid: !!user ? user.uid : null,
      };

      try {
        this.api.mutate(`mutation CreateAuditLog($input: CreateAuditLogInput){
          createAuditLog(input: $input){              
            success
          }
        }`,
          { input }
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  async auditLog_V1(requestType, requestUrl, startTime, endTime, params, responseStatus, content) {
    if (requestUrl.indexOf("apis/requesttopay/v1.0/app/user/messages") > -1 && 1 === 2) {
      const user = await this.getUser();
      let input = {
        requestType: requestType,
        requestUrl: requestUrl,
        startTime: startTime,
        endTime: endTime,
        responseStatus: responseStatus,
        content: content,
        params: !!params ? JSON.stringify(params) : "",
        duration: endTime - startTime,
        userLoginDetailUid: !!user ? user.uid : null,
      };

      try {
        this.api.mutate(`mutation CreateAuditLog($input: CreateAuditLogInput){
          createAuditLog(input: $input){              
            success
          }
        }`,
          { input }
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  async get(url, config = null, totalCount = 0, recallCount = 0) {
    let startTime = new Date();
    // let pids = await this.getLinkedPID();
    try {
      let response = await instance({
        method: "GET",
        url,
        headers: { Authorization: await this.getAccessToken(), ...{ 'Content-Encoding': 'gzip' }, ...config },
      });

      this.auditLog_V1("GET", url, startTime, new Date(), config, "Ok", totalCount.toString());
      return response;
    } catch (error) {
      this.auditLog_V1("GET", url, startTime, new Date(), config, "Error", JSON.stringify(error.message));
      console.log(`GET Error: ${url}`, error);
      if (error.response.status === 401) {
        setTimeout(async () => {
          if (recallCount > 3) {
            // this.dm.dashboard.setState({ sessionInActive: true });
            return false;
          } else {
            recallCount = recallCount + 1;
            await this.dm.userSession.issueNewToken();
            return await this.get(url, config, totalCount, recallCount);
          }
        }, 5000)
      }
    }
  }

  async post(url, data, config = null) {
    let startTime = new Date();
    try {
      let pids = await this.getLinkedPID();
      let response = await instance({
        method: "POST",
        url,
        data,
        headers: { Authorization: await this.getAccessToken(), pid: `${pids}`, ...config },
      });
      this.auditLog("POST", url, startTime, new Date(), data, "Ok");
      return response;
    } catch (error) {
      this.auditLog("POST", url, startTime, new Date(), data, "Error", JSON.stringify(error.message));
      console.log("GET Error: ", error);
      if (error != null) {
        if (error.response.status === 401) {
          await this.dm.userSession.issueNewToken();
          return await this.post(url, data, config);
        }
      }
      return error;
    }
  }

  async put(url, data, config = null) {
    let startTime = new Date();
    try {
      let pids = await this.getLinkedPID();
      let response = await instance({
        method: "PUT",
        url,
        data,
        headers: { Authorization: await this.getAccessToken(), pid: `${pids}`, ...config },
      });

      this.auditLog("PUT", url, startTime, new Date(), data, "Ok");
      return response;
    } catch (error) {
      this.auditLog("PUT", url, startTime, new Date(), data, "Error", JSON.stringify(error.message));
      console.log("GET Error: ", error);
      if (error.response.status === 401) {
        return await this.dm.userSession.issueNewToken();
      }
    }
  }

  async putS3(url, data, config = null) {
    let startTime = new Date();
    try {
      delete axios.defaults.headers.common["Authorization"];
      let response = await axios.put(
        url,
        data,
        config);
      this.auditLog("PUT S3", url, startTime, new Date(), data, "Ok");
      return response;
    } catch (error) {
      this.auditLog("PUT S3", url, startTime, new Date(), data, "Error", JSON.stringify(error.message));
      console.log("GET Error: ", error);
    }
  }

  async getS3(url, config = null) {
    let startTime = new Date();
    try {
      delete axios.defaults.headers.common["Authorization"];
      let response = await axios.get(
        url,
        config);
      this.auditLog("GET S3", url, startTime, new Date(), config, "Ok");
      return response;
    } catch (error) {
      this.auditLog("GET S3", url, startTime, new Date(), config, "Error", JSON.stringify(error.message));
      console.log("GET Error: ", error);
    }
  }

  async patch(url, data, config = null) {
    let startTime = new Date();
    try {
      let pids = await this.getLinkedPID();
      let response = await instance({
        method: "PATCH",
        url,
        data,
        headers: { Authorization: await this.getAccessToken(), pid: `${pids}`, ...config },
      });
      this.auditLog("PATCH", url, startTime, new Date(), data, "Ok");
      return response;
    } catch (error) {
      this.auditLog("PATCH", url, startTime, new Date(), data, "Error", JSON.stringify(error.message));
      console.log("GET Error: ", error);
      if (error.response.status === 401) {
        return await this.dm.userSession.issueNewToken();
      }
    }
  }

  async delete(url, data, config = null) {
    let startTime = new Date();
    try {
      let pids = await this.getLinkedPID();
      let response = await instance({
        method: "DELETE",
        url,
        data,
        headers: { Authorization: await this.getAccessToken(), pid: `${pids}`, ...config },
      });
      this.auditLog("DELETE", url, startTime, new Date(), data, "Ok");
      return response;
    } catch (error) {
      this.auditLog("DELETE", url, startTime, new Date(), data, "Error", JSON.stringify(error.message));
      console.log("GET Error: ", error);
      if (error.response.status === 401) {
        return await this.dm.userSession.issueNewToken();
      }
    }
  }
}
