import React, { Component } from "react";
import R14, { View, Text,  Button } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import ModalPopUp from "./ModalPopUp";
// import QrReader from "react-qr-scanner";
import {QrReader} from "react-qr-reader";

import { customCommonStyles } from "../assets/css/customCommonStyle";
import { CommonString } from "../constant/appCommonText";

// const url = window.location.origin + "/make-payment";
// const url = window.location.origin + "/bill-details/XBP2021000000103-XBP2021000000089-1622525125276772864-aifkmykAPv4=";

export default R14.connect(
  class InAppScannerComponent extends Component {
    constructor(props) {
      super(props);
      this.state ={
        delay: 5000,
        result: ""
      }      
    }


    onScan = async (data) => {
      if (data) {
        data = data.text || "";
        this.setState({
          result: data
        })
        const domailurl = window.location.origin;
        //const domailurl = null;
        if (data && data.indexOf(domailurl) > -1) {
          if (data.indexOf("bill-details") > -1) {
            data = data.split("/");
            let threadId = data[data.length - 1];
            await this.props.app.dm.dashboard.setCompiledMsgList();
            const { compiledMsgList } = this.props.app.dm.dashboard.state;
            if (!!compiledMsgList.length) {
              let item = compiledMsgList.filter((message) => message.threadId === threadId);
              if (!!item.length) {
                // threadId = threadId.replaceAll("#", ">>");
                this.props.app.dm.billDetails.setState({
                  billDetails: item[0]
                });
                this.onCancel();
                this.props.app.nav.to("billDetailsRoute", { threadId: threadId });
              } else this.onRetry();
            } else this.onRetry();
          } else this.onRetry();
        } else this.onRetry();
      };
    };

    onRetry = (message = "Invalid QR Code", isOnError = false) => {
      let retryCount = this.props.app.dm.dashboard.state.retryCount;
      this.props.app.dm.commonDomain.showToastr("error", message);
      this.props.app.dm.dashboard.setState({
        retryCount: retryCount + 1,
      });

      if (!!isOnError) {
        if (retryCount > 3)
          setTimeout(() => {
            this.onCancel();
          }, 5000);
        else
          setTimeout(() => {
            this.onRetry(message, isOnError);
          }, 5000);
      }
    };

    onError = (err) => {
      console.log(err)
      this.onRetry(CommonString.SomethingWentWrong, true);
    };

    onCancel = () => {
      this.props.app.dm.dashboard.setState({
        IsScannerVisible: false,
        retryCount: 0,
      });
    };

    render() {
      let { IsScannerVisible, isAlertBox, alertMessage, alertMessageHeading } = this.props.app.dm.dashboard.state;
      if (this.props.IsScannerVisible) {
        IsScannerVisible = this.props.IsScannerVisible;
        isAlertBox = this.props.isAlertBox;
        alertMessage = this.props.alertMessage;
        alertMessageHeading = this.props.alertMessageHeading;
      }
      return (
        <ModalPopUp
          visibility={IsScannerVisible}
          modalPopupCancelBtn={this.props.IsScannerVisible ? this.props.onCancel : this.onCancel}
          modalPopupOkBtn={this.props.IsScannerVisible ? this.props.onCancel : this.onCancel}
          closeButton={this.props.IsScannerVisible ? this.props.onCancel : this.onCancel}
          bodyWrapper={[commonStyles.smallModalBodyWrapper]}
          modalContent={[commonStyles.marginBottom0]}
          isFooter={true}>
          <View style={[commonStyles.marginLeft10, commonStyles.dFlex, commonStyles.flexDirectionColumn, commonStyles.alignItemsCenter]}>
            <View style={commonStyles.marginBottom13}>
              <Text style={[commonStyles.heading5, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.darkGrayColor]}>{alertMessageHeading}</Text>
            </View>
          </View>
          <View>
            {!!(isAlertBox && alertMessage) ? (
              <View style={[commonStyles.textAlignCenter,]}>
                <Text style={[commonFontStyles.workSans, commonStyles.heading6]}>{alertMessage}</Text>
              </View>
            ) : (
              <QrReader 
                scanDelay={500}
                containerStyle={{width: "100%" }}
                constraints={{
                    facingMode:"environment"
                }}
                onResult={(result, error) => {
                  if (!!result) {
                    this.props.IsScannerVisible ? this.props.onScan(result) : this.onScan(result)
                  }
                  if (!!error) {
                      console.info({error});
                    }
                  }
                }
              />
            )}
          </View>
          <View
            style={[
              commonStyles.flex1,
              commonStyles.justifyContentCenter,
              commonStyles.alignItemsCenter,
              commonStyles.smallModalBtnWrapper,
              // !!(isAlertBox && alertMessage) ? null : commonStyles.marginTop24,
            ]}>
            {!!(isAlertBox && alertMessage) ? (
              <Button
                title={"Ok"}
                style={[
                  commonStyles.blueBorderButton,
                  commonStyles.marginRight8,
                  commonStyles.blueButton,
                  commonStyles.minWidth110,
                  commonStyles.blueButtonBorder,
                  customCommonStyles.noBoxShadow,
                ]}
                onPress={this.props.IsScannerVisible ? this.props.onCancel : this.onCancel}
                titleTextStyle={[
                  commonFontStyles.workSans,
                  commonStyles.fontWeight500,
                  commonStyles.heading6,
                  commonStyles.transperentBtnColor,
                  commonStyles.whiteColor,
                ]}
              />
            ) : (
              <Button
                title={"Cancel"}
                style={[commonStyles.secondayWhiteBtn, commonStyles.marginRight8, commonStyles.minWidth110, commonStyles.btnHeight24]}
                onPress={this.props.IsScannerVisible ? this.props.onCancel : this.onCancel}
                titleTextStyle={[commonFontStyles.workSans, commonStyles.secondayWhiteBtnText, commonStyles.transperentBtnColor]}
              />
            )}
          </View>
        </ModalPopUp>
      );
    }
  }
);

