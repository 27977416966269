import React from "react";
import { View, StyleSheet, Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
export default class ToggleRadioBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      on: false,
    };
  }
  // toggleSwitch = () => {
  //   this.setState({
  //     on: !this.state.on,
  //   });
  // };

  render() {
    const { toggleSwitch, value, on, disable, toggleRadioBtnSize } = this.props;
    //const { on } = this.state;
    return (
      <View
        style={[
          commonStyles.dFlex,
          styles.toggleSwitchBtnouter,
          commonStyles.alignItemsCenter,
          on ? commonStyles.skyBlueBackground : commonStyles.exLightGray,
          toggleRadioBtnSize,
        ]}>
        <Touchable
          onPress={() => toggleSwitch(value)}
          style={[on ? commonStyles.marLAuto : commonStyles.marRAuto, styles.switchBtn, disable, commonStyles.flex1, commonStyles.alignItemsCenter]}>
            <View style={[styles.toggleSwitchBtnCircle, customCommonStyles.radioBtnCircleCutomMargin, on ? commonStyles.marLAuto : commonStyles.marRAuto,]}></View> 
        </Touchable>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  toggleSwitchBtnouter: {
    width: 40,
    height: 24,
    backgroundColor: "#e6e6e6",
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    transition: "all 0.5s ease",
    paddingRight: 1,
    paddingLeft: 1,
  },
  switchBtn: {
    width: 22,
    height: 22,
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    borderBottomLeftRadius: 22,
    borderBottomRightRadius: 22,
    transition: "all 0.5s ease",
    display: "block",
    screen: ({ width }) => {
      if (width <= 991)
      return {
          flex: 1,
          alignItems: "center",
          height: 24,
          minHeight: 24,
          backgroundColor: "rgba(0, 0, 0, 0)",
          border: 0,
          justifyContent: "center",
        };
    },
  },
  deactivate: {
    display: "none",
  },
  ascendingActive: {
    marginRight: "auto",
    backgroundColor: "#37c2ef",
  },
  descendingActive: {
    marginLeft: "auto",
    backgroundColor: "#37c2ef",
  },
  normal: {
    minWidth: 20,
    height: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    border: "1px solid #37c2ef",
    backgroundColor: "#fff",
  },
  visibilityHidden: {
    opacity: 0,
  },
  toggleSwitchBtnCircle: {
    width: 22,
    height: 22,
    backgroundColor: "#fff",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  }
});
