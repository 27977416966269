const STATUS = [
  { label: "New", isSelected: false, groupLabel: "Bills", id: 0 },
  { label: "Declined", isSelected: false, groupLabel: "Bills", id: 1 },
  { label: "Priority", isSelected: false, groupLabel: "Bills", id: 2 },
  { label: "Paid", isSelected: false, groupLabel: "Payment", id: 3 },
  { label: "Overdue", isSelected: false, groupLabel: "Payment", id: 4 },
  { label: "Outstanding", isSelected: false, groupLabel: "Payment", id: 5 },
  { label: "In Process", isSelected: false, groupLabel: "Payment", id: 6 },
  { label: "Failed", isSelected: false, groupLabel: "Payment", id: 7 },
  { label: "Requested", isSelected: false, groupLabel: "Extensions", id: 8 },
  { label: "Approved", isSelected: false, groupLabel: "Extensions", id: 9 },
  { label: "Rejected", isSelected: false, groupLabel: "Extensions", id: 10 }
];

const RADIO_OPT = [
  { label: "Bill Received Date", value: "billDate" },
  { label: "Due date", value: "dueDate" },
];
const DECLINERADIO_OPT = [
  { label: "Already Paid", value: "Already paid" },
  { label: "Duplicate Bill", value: "Duplicate bill" },
  { label: "Other", value: "Other" },
];

const ORDER_TYPE = { false: "ASC", true: "DESC" };

const CURRENCY = {
  usd: "\u0024",
  USD: "\u0024",
  EUR: "\u20ac ",
  GBP: "\u00A3 ",
  SEK: "kr ",
  CHF: "₣ ",
  NOK: "kr ",
  DKK: "kr ",
  PLN: "zł ",
  CZK: "Kč ",
  HUF: "Ft ",
  RON: "lei ",
  BGN: "Лв ",
  HRK: "kn ",
  ISK: "kr ",
};
const ICONS = {
  closeIconBold: "\u2716",
  closeIconLight: "\u2715",
};

const BILL_STATUS = {
  ReqPayExtension: true,
  PayAll: true,
  AcknowledgeInFull: true,
  DeclineBlock: true,
  Paid: true,
  Declined: true,
  // PCH
  Submitted: true,
  // "In Process": true
  ACCEPTED: true,
  REJECTED: true,
  CANCELLED: true,
  PAID: true,
  VOID: true,
  "PART PAID": true,
};

const PAGE_PATH = {
  PAYFULL: "PAYFULL",
  PAYPART: "PAYPART",
  DECLINED: "DECLINED",
  EXTENSION: "EXTENSION",
  REJECT: "REJECT",
  ACCEPTLATER: "ACCEPTLATER",
  ACCEPTNOW: "ACCEPTNOW",
};

const STATUS_CLR = {
  New: "NW",
  Paid: "PD",
  Decline: "DL",
  Declined: "DL",
  Overdue: "OD",
  Outstanding: "OS",
  Failed: "FD",
  Approved: "AD",
  Rejected: "RD",
  Requested: "RQD",
  Priority: "PB",
  "In Process": "IP",
  "Date Range": "DR",
  "Biller": "BU",
  // PCH
  Submitted: "NW",
};

const MSG_TYPE = {
  DECLINE: "Decline",
  DECLINE_BLOCK: "DeclineBlock",
  ACKNOWLEGE_FULL: "AcknowledgeInFull",
  PAY_ALL: "PayAll",
  PAY_PART: "PayPartial",
  NOTE_TO_BILLER: "NotetoBiller",
  NOTE_TO_PAYER: "NotetoPayer",
  REQ_PAY_EXT: "ReqPayExtension",
  EXT_GRANTED: "ExtensionGranted",
  EXT_DECLINED: "ExtensionDeclined",
  REQ_PAY: "RequestToPay",
  ACK_PAYMENT: "AcknowledgePayment",
  EXT_RES: "ExtensionResponse",
  ERRORS: "Errors",
};

const BILLER_STATUS = {
  accepted: "Active",
  unblocked: "Active",
  denied: "Declined",
  inprogress: "In Progress",
  new: "New",
  blocked: "Blocked",
  payerblocked: "Blocked",
  requested: "New",
};

const RTP_STATUS = {
  ReqPayExtension: true,
  PayAll: true,
  AcknowledgeInFull: true,
  DeclineBlock: true,
  Decline: true,
};

const BLOCK_UNBLOCK = {
  accepted: true,
  blocked: true,
  unblocked: true,
};
const MY_PREFERENCES_LIST = {
  email: false,
  sms: false,
  fingerprint: false,
  pattern: false,
  oneTimePassword: false,
  pin: false,
  faceRecognition: false,
  notifications: true,
};
const NOTIFICATION_OPT = [
  {
    label: "Bill Payment Due Date Reminders",
    value: "Bill Due Date Reminder",
    seqNum: 1,
    img: "alarmIcon.png",
    comment: "$ bills are nearing due days",
  },
  {
    label: "Simple Messages Notification",
    value: "simpleMessage",
    seqNum: 2,
    img: "chatIcon.png",
    comment: "$ Simple Messages Received from Billers",
  },
  { label: "New Bill Received", value: "New Bill Received", seqNum: 3, img: "notesIcon.png", comment: "$ New Bills has been generated" },
  {
    label: "Over Due Reminder",
    value: "Over Due Reminder",
    seqNum: 4,
    img: "alarmIcon.png",
    comment: "$ Bills have passed the due date, please make payment to avoid any late payment charges",
  },
  {
    label: "Extension Request Approved",
    value: "Extension Request Approved",
    seqNum: 5,
    img: "calenderIcon.png",
    comment: "Your $ Extension Requests have been Approved by billers",
  },
  {
    label: "Extension Request Rejected",
    value: "Extension Request Rejected",
    seqNum: 6,
    img: "calenderIcon.png",
    comment: "Your $ Extension Requests have been Rejected by billers",
  },
  {
    label: "Pre Authorization Message(PAM) Request",
    value: "PAM",
    seqNum: 7,
    img: "chatIcon.png",
    comment: "$ PAM Requests Received from Billers, Please respond to authorize the biller",
  },
  {
    label: "Full Payment Acknowledgement",
    value: "Full Payment Acknowledgement",
    seqNum: 8,
    img: "cardsIcon.png",
    comment: "Received $ full Payments",
  },
  {
    label: "Part Payment Acknowledgement",
    value: "Part Payment Acknowledgement",
    seqNum: 9,
    img: "cardsIcon.png",
    comment: "Received $ part Payments",
  },
  { label: "Note to Payer Message", value: "NoteToPayer", seqNum: 10, img: "chatIcon.png", comment: "$ Note Messages Received from Billers" },
];

const SOCIAL_MEDIA_ENTITY = {
  googleProfileID: "googleProfileID",
  appleProfileID: "appleProfileID",
  amazonProfileID: "amazonProfileID",
};

const PAYMENT_MODE = {
  ANDROID_PAY: "Google Pay",
  APPLE_PAY: "Apple Pay",
  AMAZON_PAY: "Amazon Pay",
  ACH_PAY: "ACH Pay",
  SEPA_DEBIT: "SEPA Debit"
};

const PAY_ENVIRONMENT = {
  ENVIRONMENT: "TEST", //"PRODUCTION"
};

const BTN_TYPE = {
  signIn: "Sign-In",
  continue: "Continue",
  signUp: "Sign-up"
}

const STRIPE_KEY = {
    version: "2019-05-16",
    merchantAccountNumber:"acct_1IvKFXKj1xF31gFd",
    publishableKey: "pk_test_51IvKFXKj1xF31gFdPNvzFSDYZYpAhqMWHpKSGVTGhoKf6RH8U4CCDTG3g2kSm3L5NiDGxaERf4S813NhxZuLSnRH001bhe9iy3"
};

const PLATFORM_NAME = {
  Window: "Windows Phone",
  Android: "Android",
  IOS: "IOS",
  UNKNOWN: "Unknown",
};

const USER_TYPE = {
  XBP: "XBP",
  SMB: "SMB",
  XBPSMB: "XBP_SMB",
  PCH_PROVIDER: "PCH_PROVIDER",
  PCH_PATIENT: "PCH_PATIENT",
  XBP_ADMIN: "SUPER_ADMIN",
  CLAIMS:"CLAIMS"
};

const PCH_STATUS = [
  { label: "All", isSelected: false, groupLabel: "Bills", id: 0 },
  { label: "New", isSelected: false, groupLabel: "Bills", id: 1 },
  { label: "Submitted", isSelected: false, groupLabel: "Bills", id: 2 },
];

const LOGIN_TYPE ={
  NORMAL: "NORMAL",
  GOOGLE: "GOOGLE",
  APPLE: "APPLE",
  AMAZON: "AMAZON",
  EMAIL: "EMAIL",
  KEYCLOAK: "KEYCLOAK",
  UBERDOC: "UBERDOC",
  PCHGLOBAL: "PCHGLOBAL",
  NONREGISTER:"NON-REGISTER"
};

const SEARCH_TYPE ={
  COUNTRY: "COUNTRY",
  STATE: "STATE",
  CITY: "CITY",
  PROVIDER: "PROVIDER",
  PRACTITIONER: "PRACTITIONER"
};

const MANAGE_PASSWORD ={
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
};

const VERSION_NO ={
  label: "V.0.1.6"
}

const DEFAULT_COUNTRY = {
  label: "United States",
  value: "2REY4OoOJF"
};

const RESOURCE_ACCESS = [
  {
    client: 'xbppayer',
    roles: ['xbp-payer', 'xbp-smb-payer']
  },
  {
    client: 'xbpsmbdev',
    roles: ['Custom_Roles']
  },
];

const MESSAGE_TYPE = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
  delete: "delete",
}

const CHART_TYPE = {
  PIECHART: "Pie",
  LINECHART: "Line",
  BARCHART: "Bar"
}

// added space in label, so that iphone8 doesn't have two lines issue
const BILL_OPTS = [
  { label: "Bills", value: "RTP" },
  { label: "SEPA Bills ", value: "SRTP" },
];

const FILE_EXTENSION = ["PDF","JPEG","JPG","PNG","TIFF","TIF","CSV","DIF","ODS","OTS","TSV","XLM","XLS","XLSB","XLSM","XLSX","XLT","XLTM","XLTX","XML","DOC","DOCX","DOCM","DOTX","DOTM","DOCB","XSLT"];
const SRTP_RESOURES = ["dashboard", "profileViewRoute", "loginOut"];

export {
  STATUS,
  RADIO_OPT,
  ORDER_TYPE,
  CURRENCY,
  PAGE_PATH,
  DECLINERADIO_OPT,
  BILL_STATUS,
  STATUS_CLR,
  MSG_TYPE,
  BILLER_STATUS,
  RTP_STATUS,
  BLOCK_UNBLOCK,
  MY_PREFERENCES_LIST,
  NOTIFICATION_OPT,
  SOCIAL_MEDIA_ENTITY,
  PAY_ENVIRONMENT,
  STRIPE_KEY,
  ICONS,
  PAYMENT_MODE,
  PLATFORM_NAME,
  USER_TYPE,
  BTN_TYPE,
  PCH_STATUS,
  LOGIN_TYPE,
  SEARCH_TYPE,
  MANAGE_PASSWORD,
  VERSION_NO,
  DEFAULT_COUNTRY,
  RESOURCE_ACCESS,
  MESSAGE_TYPE,
  CHART_TYPE,
  BILL_OPTS,
  FILE_EXTENSION,
  SRTP_RESOURES
};
