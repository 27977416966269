import React from "react";
import R14, { IconButton, StyleSheet, Touchable, View } from "../../core";

export default R14.connect(
    class AccordionItemComponent extends React.Component {

        toggleExpand = async (item) => this.props.onExpand(item.itemData.uid);

        render() {
            let item = this.props;
            return (
                <View key={item.keyName} id={item.keyName} style={styles.accordianItem}>
                    <Touchable key={item.keyName} ref={this.accordian} onPress={() => this.toggleExpand(item)}>
                        <View style={styles.row}>
                        {item.title}
                        <IconButton onPress={() => this.toggleExpand(item)} icon={item.isExpanded ? 'keyboardArrowUp' : 'keyboardArrowDown'} size="small" key="arrow" />
                        </View>
                    </Touchable>
                    {
                        item.isExpanded ?
                        <View style={styles.chatChild}>
                            {item.data}
                        </View> : null
                    }
                </View>

            )
        }
    })

const styles = StyleSheet.create({
    row: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 25,
        paddingLeft: 10,
        alignItems: 'center',
        marginBottom: 5,
        marginTop: 5,
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    height: 35,
                }
        }
    },
    chatChild: {
    },
    accordianItem: {
        borderTop: "1px solid #dadada"
    }
});