import React from "react";
import R14, { AsyncStorage, Form, StyleSheet, Text, TextInputField, View } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { commonMetricsStyle } from "../../assets/css/commonMetricsStyle";
import PaymentCheckoutComponent from "./PaymentCheckoutComponent";
import PaymentOptions from "./PaymentOptions";
import { CommonString } from "../../constant/appCommonText";
import { CURRENCY } from "../../constant/app.data.constant";
import Linking from "../../utils/Linking";

export default R14.connect(
  class CheckoutSessionComponent extends React.Component {

    componentDidMount() {
      const form = this.props.app.ui.form("checkoutSession");
      if(this.props.paymentPayload && this.props.paymentPayload.billingaddress && form && form.elmts && form.elmts.email) {
        const {user_email} = this.props.paymentPayload.billingaddress;
        form.elmts.email.setValue(this.textSorten(user_email));
      }
    }

    textSorten = (text = '', numOfDigit = 55) => {
      if (text.length > numOfDigit) {
          return text.substring(0, numOfDigit) + "...";
      } else {
          return text;
      }
  }

    onPaymentDone = async (e) => {
      if (e === "close" || (!!e && !!!e.success)) {
        this.props.app.nav.back();
        return false;
      }
      else {
        const { paymentPayload, onPaymentDone } = this.props;
        if (!!paymentPayload.successUrl) {
          e.paymentIntentId = e.transactionId;
          await AsyncStorage.setItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS, JSON.stringify(e));
          Linking.LinkingURL(`${paymentPayload.successUrl}&redirect_status=succeeded`, "_self");
          return false;
        }
        else if (!!onPaymentDone) {
          onPaymentDone(e);
          return false
        }
      }
    }

    render() {
      const { paymentPayload, userDetails } = this.props;
      let {total_amount, currency} = paymentPayload.transactiondetails;
      total_amount = Number(total_amount).toFixed(2);
      return (
          <View style={[styles.marginLeftRight16, commonStyles.flex1, commonStyles.customFlexDirection]}>
            <View
              style={[
                styles.container,
                commonStyles.flex1,
                commonStyles.flexBasis50,
                commonStyles.flex1,
                commonStyles.flexDirectionColumn,
                customCommonStyles.customBillerDetailsContainer,
              ]}>
             <View style={[styles.customContainerMargin]}>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionColumn]}>
                <Text style={[commonStyles.heading5,commonStyles.fontWeight400, commonFontStyles.workSans, commonStyles.darkGrayColor]}>Pay {paymentPayload.payee}</Text>
                <Text style={[commonStyles.fontWeight500, commonStyles.transformUpperCase, commonFontStyles.workSans, commonStyles.darkGrayColor, commonStyles.heading1]}>{CURRENCY[currency]}{total_amount}</Text>
              </View>
              <View style={[styles.marTop40]}>
                {!!paymentPayload && !!paymentPayload.lineItems ?
                 paymentPayload.lineItems.map((item, i) => {
                   return (<View key={i + "_lineitem"} style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, commonStyles.paddingTop10]}>
                      <Text style={[commonStyles.exSmallHeading,commonStyles.fontWeight400, commonFontStyles.workSans, commonStyles.darkGrayColor]}>{item.label}</Text>
                      <Text style={[commonStyles.exSmallHeading, commonStyles.transformUpperCase, commonStyles.fontWeight400, commonFontStyles.workSans, commonStyles.darkGrayColor]}>{CURRENCY[currency]}{item.price}</Text>
                    </View>)
                  }) : null}
                  <View key={"total_lineitem"} style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, commonStyles.paddingTop10, commonStyles.marginTop20]}>
                    <View style={[styles.totalAmountContainer, ]}>
                      <Text style={[commonStyles.exSmallHeading,commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.darkGrayColor]}>{"Total Fees"}</Text>
                      <Text style={[commonStyles.exSmallHeading, commonStyles.transformUpperCase, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.darkGrayColor]}>{CURRENCY[currency]}{total_amount}</Text>
                    </View>
                    </View>
              </View>
             </View>
            </View>
            <View
              style={[
                commonStyles.flex1,
                commonStyles.flexBasis50,
                commonStyles.flexDirectionColumn,
                styles.container,
                styles.messageSection,
              ]}>
              <View style={[styles.paddingLeft16]}>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.marginTop10, commonStyles.marginBottom10]}>
                <Text style={[commonStyles.heading5,commonStyles.fontWeight400, commonFontStyles.workSans, commonStyles.darkGrayColor]}>Payment Options</Text>
              </View>
              <Form
                name="checkoutSession"
                style={[styles.checkoutSessionForm, commonStyles.justifyContentFlexStart]}>
                <TextInputField
                  name="email"
                  label={`${CommonString.EmailLabel}*`}
                  readOnly={true}
                  disabled={true}
                  required={CommonString.EmailRequired}
                  style={[commonFontStyles.workSans, commonMetricsStyle.heading6, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonMetricsStyle.heading6]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                  helperTextStyle={[styles.customHelperTextStyle, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonMetricsStyle.smallHeading, styles.left0]}
                />
              </Form>
                <PaymentOptions
                  showGooglePay={true}
                  amtPayable={!!paymentPayload ? paymentPayload.amtPayable : 0.99}
                  user={userDetails}
                  currencyCode={currency.toUpperCase()}
                  onPaymentDone={(a) => this.onPaymentDone(a)}
                  isLoading={false}
                  bill = {paymentPayload}
                  pchClaim={true}
                  externalClient={false}
                  buttonColor={"black"}
                  googleBtnStyle={[commonStyles.flexBasis100, commonStyles.flex1, styles.googleBtnStyle]}
                  gpayContainer={[commonStyles.paddingRight10, commonStyles.marginBottom16]}
                />
               <View style={[commonStyles.justifyContentCenter,commonStyles.alignItemsCenter, styles.orSection]}>
                  <View style={styles.orWrap}>
                    <Text style={[commonStyles.smallHeading,commonStyles.fontWeight500, commonFontStyles.workSans, styles.or]}>
                      OR
                    </Text>
                  </View>
                </View>
                <PaymentCheckoutComponent
                  btnText={true}
                  user={userDetails}
                  amtPayable={!!paymentPayload ? paymentPayload.amtPayable : 0.99}
                  currencyCode={!!paymentPayload ? paymentPayload.currency : "usd"}
                  isPaymentCheckout={true}
                  bill={paymentPayload}
                  handleCheckout={(data) => this.onPaymentDone(data)}
                  path={"payment-receipts"}
                  successUrl={paymentPayload.successUrl}
                />
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentCenter, commonStyles.textAlignCenter, commonStyles.marTAuto, styles.maxHeight20]}>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight300, commonStyles.exSmallHeading,]}>{'Powered by '}</Text>
                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading,]}>{'stripe'}</Text>
              </View>
            </View>
          </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  paddingLeft16: {
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 0,
          paddingLeft: 0,
        };
    },
  },
  googleBtnStyle: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#000000",
    backgroundColor: "#000000",
    boxShadow: "none",
    justifyContent: 'center'
  },
  container: {
    padding: 10,
    maxWidth: "49%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    backgroundColor: "#fff",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          maxWidth: "100%",
          boxShadow: "none",
          borderWidth: 0,
        };
    },
  },
  billerDetailsWrap: {
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexWrap: "wrap",
          marginBottom: 0,
          backgroundColor: "#fff",
        };
    },
  },
  messageSection: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 15,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 15,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
    },
  },
  billDetailsScreenInnerWrap: {
    marginTop: 0,
    marginBottom: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 16,
          paddingLeft: 16,
          marginLeft: 0,
          marginRight: 0,
          backgroundColor: "#fff",
          minHeight: "100%"
        };
    },
  },
  maxHeight20: {
    maxHeight: 20,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 16,
        };
    },
  },
  checkoutSessionForm: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 0
  },
  customContainerMargin: {
    marginTop: 10,
    marginBottom: 10,

    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 0,
          marginBottom: 0,
        };
    },
  }, 
  totalAmountContainer: {
    borderWidth: 0,
    borderTop: 1,
    borderStyle: 'solid',
    borderColor: "#dadada",
    width: '100%',
    justifyContent: 'space-between',
    flex: 1,
    flexDirection: 'row',
    paddingTop: 10,
  },
  marTop40:{
    marginTop: 40,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 10,
        };
    }
  },
  orSection: {
    borderBottomWidth: 1,
    width:"98%",
    borderStyle: "solid",
    borderBottomColor: "rgba(186, 186, 186, 1)",
    position: "relative",
    textAlign: "center",
    marginBottom: 30,
  },
  or: {
    display: "inline-flex",
    // flex:1,
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    backgroundColor: "#fff",
    position: "relative",
    top: 10,
    paddingTop: 1,
    paddingRight: 5,
    paddingBottom: 1,
    paddingLeft: 5,
    letterSpacing: 0.5,
  },
  marginLeftRight16: {
    marginLeft: 16,
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginLeft: 0,
          marginRight: 0,
        };
    }
  },
});
