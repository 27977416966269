import React, { Component } from "react";
import R14, { StyleSheet, View, Text, Touchable, Image, Button, FlatList } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import RadioButtons from "../components/RadioButtons";
import NoRecordsFound from "../components/NoRecordsFound";
import PopupModalComponent from "../components/PopupModalComponent";
import { CommonString } from "../constant/appCommonText";
import { MESSAGE_TYPE } from "../constant/app.data.constant";

const deleteIcon = require("../assets/images/delete.png");

export default R14.connect(
  class CardsList extends Component {
    constructor(props) {
      super(props);
      this.state = {
        optVal: "",
        cardList: [],
        isVisible: false,
        cardNumber: null,
        paymentType: null,
      };
    }
    onValueChange = async (item, index) => {
      this.props.app.ui.progressIndicator.show();
      let { cardList } = this.state;
      let selectedItem = item.isSetDefault ? false : true;
      cardList.forEach((ele) => (ele.optVal = ""));
      let selectedCardItem = [];
      cardList.forEach((ele, key) => {
        if (ele.uid === item.uid) {
          selectedCardItem.push(ele);
        }
      });
      let getCardList = cardList;
      let getfilterdata = getCardList.filter((el) => el.isSetDefault === true);
      if (!!getfilterdata.length > 0) {
        await Promise.all(
          getfilterdata.map(async (ele, key) => {
            let cardDetails = {
              payerID: ele.payerID,
              paymentType: ele.paymentType,
              cardType: ele.cardType,
              token: ele.token,
              displayNumber: ele.displayNumber,
              status: ele.status,
              isSetDefault: false,
              uid: ele.uid,
              cardHolderName: ele.cardHolderName,
            };
            await this.props.app.dm.billDetails.updatePayerCardDetails(cardDetails);
          })
        );
      }
      if (!!selectedCardItem.length > 0) {
        let cardDetails = {
          payerID: selectedCardItem[0].payerID,
          paymentType: selectedCardItem[0].paymentType,
          cardType: selectedCardItem[0].cardType,
          token: selectedCardItem[0].token,
          displayNumber: selectedCardItem[0].displayNumber,
          status: selectedCardItem[0].status,
          isSetDefault: selectedItem,
          uid: selectedCardItem[0].uid,
          cardHolderName: selectedCardItem[0].cardHolderName,
        };
        let update = await this.props.app.dm.billDetails.updatePayerCardDetails(cardDetails);
        if (update) {
          let cardList = await this.props.app.dm.billDetails.getPayerCardDetailsList();
          cardList.forEach((ele) => (ele.isSetDefault ? (ele.optVal = "Set Default") : (ele.optVal = "")));
          this.setState({ selectedOpt: selectedItem, cardList: cardList });
          this.props.app.dm.commonDomain.showToastr("success", `${selectedCardItem[0].displayNumber} successfully set as default card.`);
        } else {
          this.setState({ cardList: cardList });
          this.props.app.dm.commonDomain.showToastr("error",CommonString.SomethingWentWrong);
        }
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    };
    componentDidMount = async () => {
      let cardList = [];
      const { cardDisplayList } = this.props;
      let extOpt = { optVal: "" };
      if (cardDisplayList) {
        cardDisplayList.forEach((item) => {
          cardList.push({ ...item, ...extOpt });
        });
        this.setState({ cardList: cardList });
      }
    };
    deleteCard = (uid) => {
      let { cardList } = this.state;
      let selectedCardItem = [];
      cardList.forEach((ele, key) => {
        if (ele.uid === uid) {
          selectedCardItem.push(ele);
        }
      });
      if (!!selectedCardItem) {
        this.setState({ isVisible: true, cardNumber: selectedCardItem[0].displayNumber, paymentType: selectedCardItem[0].paymentType.toLowerCase() });
      } else {
        this.setState({ isVisible: false, cardNumber: null, paymentType: null });
      }
    };
    onClose = async () => {
      this.setState({ isVisible: false });
    };
    handleDeletePress = async () => {
      let { cardList, cardNumber } = this.state;
      let selectedCardItem = [];
      cardList.forEach((ele, key) => {
        if (ele.displayNumber === cardNumber) {
          selectedCardItem.push(ele);
        }
      });
      if (!!selectedCardItem.length) {
        let cardDetails = {
          payerID: selectedCardItem[0].payerID,
          paymentType: selectedCardItem[0].paymentType,
          cardType: selectedCardItem[0].cardType,
          token: selectedCardItem[0].token,
          displayNumber: selectedCardItem[0].displayNumber,
          status: "Disabled",
          isSetDefault: selectedCardItem[0].isSetDefault,
          uid: selectedCardItem[0].uid,
        };
        try {
          let update = await this.props.app.dm.billDetails.updatePayerCardDetails(cardDetails);
          if (update) {
            this.props.app.dm.commonDomain.showToastr("success", `${cardNumber} deleted successfully.`);
          } else {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }
        } catch (error) {
          console.log("error", error);
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
        let cardList = await this.props.app.dm.billDetails.getPayerCardDetailsList();
        this.setState({ isVisible: false, cardNumber: null, cardList: cardList });
      }
    };
    onAddNewCard = async () => {
      this.props.app.dm.billDetails.setState({ bankTabActive: "Card" });
      this.props.app.nav.to("addNewCardRoute");
    };
    bCardIconExists(cardType) {
      console.log("REQUIRE NOT AVAILABLE IN NATIVE");
      let imgSrc = "";
      try {
        //imgSrc = require("../assets/images/cards/" + cardType.toLowerCase() + ".png");
      } catch {
        //imgSrc = require("../assets/images/cards/default.png");
      } finally {
        this.cardIconPath = imgSrc;
      }
    }

    render() {
      const { cardList } = this.state;
      return (
        <View style={[commonStyles.dFlex, commonStyles.flexDirectionColumn, commonStyles.flexGrow1, commonStyles.paddingBottom30]}>
          <FlatList
            style={[styles.noRecords, styles.cardListHolder]}
            ListEmptyComponent={<NoRecordsFound message={"No cards to display."} />}
            data={cardList}
            extraData={this.state}
            keyExtractor={(el) => el.uid}
            renderItem={({ item, index }) => (
              <View>
                <View
                  style={[commonStyles.shadowBox, commonStyles.marginBottom15, styles.cardListItem, index === 0 ? commonStyles.marginTop15 : null]}>
                  <View style={[commonStyles.dFlex]}>
                    <View
                      style={[
                        styles.bankDetails,
                        commonStyles.flexGrow1,
                        commonStyles.dFlex,
                        commonStyles.justifyContentSpaceBetween,
                        commonStyles.marginTop5,
                      ]}>
                      <View>
                        {/* Card holder name */}
                        <View style={commonStyles.marginTopBottom10}>
                          <Text
                            style={[
                              commonStyles.transperentBtnColor,
                              commonFontStyles.workSans,
                              commonStyles.smallHeading,
                              commonStyles.fontWeight500,
                            ]}>
                            {item.cardHolderName ? item.cardHolderName : "N/A"}
                          </Text>
                        </View>
                        {/* Card  number */}
                        <View style={[commonStyles.dFlex, commonStyles.marginTopBottom10]}>
                          <Text
                            style={[
                              commonStyles.darkGray,
                              commonFontStyles.workSans,
                              commonStyles.heading5,
                              commonStyles.fontWeight500,
                              commonStyles.dBlock,
                              commonStyles.marginRight15,
                            ]}>
                            XXXX
                          </Text>
                          <Text
                            style={[
                              commonStyles.darkGray,
                              commonFontStyles.workSans,
                              commonStyles.heading5,
                              commonStyles.fontWeight500,
                              commonStyles.dBlock,
                              commonStyles.marginRight15,
                            ]}>
                            XXXX
                          </Text>
                          <Text
                            style={[
                              commonStyles.darkGray,
                              commonFontStyles.workSans,
                              commonStyles.heading5,
                              commonStyles.fontWeight500,
                              commonStyles.dBlock,
                              commonStyles.marginRight15,
                            ]}>
                            XXXX
                          </Text>
                          <Text
                            style={[
                              commonStyles.darkGray,
                              commonFontStyles.workSans,
                              commonStyles.heading5,
                              commonStyles.fontWeight500,
                              commonStyles.dBlock,
                            ]}>
                            {item.displayNumber.substr(-4)}
                          </Text>
                        </View>
                        <View style={[commonStyles.dFlex, commonStyles.marginTopBottom10]}></View>
                      </View>
                      <View style={[commonStyles.dFlex]}>
                        <Text
                          style={[
                            commonStyles.transperentBtnColor,
                            commonStyles.fontWeight500,
                            commonStyles.transformUpperCase,
                            commonFontStyles.workSans,
                            commonStyles.smallHeading,
                            commonStyles.fontWeight500,
                          ]}>
                          {item.paymentType === "Debit Card" ? "Dr" : "Cr"}
                        </Text>
                        {this.bCardIconExists(item.cardType)}
                        <Image source={this.cardIconPath} style={[commonStyles.icon20, commonStyles.marginLeft15]} />
                      </View>
                    </View>
                  </View>
                  <View style={[commonStyles.dFlex, commonStyles.justifyContentSpaceBetween, commonStyles.marginTop5]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <RadioButtons
                        isSelected={item.isSetDefault ? "Set Default" : item.optVal}
                        onValueChange={() => this.onValueChange(item, index)}
                        options={{ label: "", value: "Set Default" }}
                        // label="Set Default"
                        key={item.uid}
                        radioButtonsClass={[commonStyles.padding0]}
                      />
                      <View>
                        <Text style={[commonStyles.darkGray, commonFontStyles.workSans, commonStyles.smallHeading]}>Set Default</Text>
                      </View>
                    </View>
                    <View>
                      <Touchable style={commonStyles.marginLeft15} onPress={() => this.deleteCard(item.uid)}>
                        <View>
                          <Image source={deleteIcon} style={commonStyles.icon20} />
                        </View>
                      </Touchable>
                    </View>
                  </View>
                </View>
              </View>
            )}
          />
          <View></View>
          <View style={[styles.btnMarBottom]}>
            <Button
              title={"+ ADD new Cards"}
              style={[commonStyles.blueButton, styles.flex1, commonStyles.fullWidth]}
              onPress={this.onAddNewCard}
              titleTextStyle={[commonStyles.whiteColor, commonStyles.fontWeight700, commonFontStyles.workSans, commonStyles.heading5]}
            />
          </View>
          <PopupModalComponent
            visibility={!!this.state.isVisible}
            bodyWrapper={[styles.bodyWrapper]}
            messageType={MESSAGE_TYPE.delete}
            headingIcon={true}
            heading={"Delete Card"}
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={`Are you sure you want to delete the ${this.state.paymentType} ${this.state.cardNumber}?`}
            okLabel={"No"}
            handleOk={this.onClose}
            closeLabel={"Yes"}
            handleClose={this.handleDeletePress}
            modalHeading={[commonStyles.nativeModalHeading]}
          />
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  bankIcon: {
    marginRight: 10,
  },
  bankLogo: {
    width: 26,
    height: 26,
    display: "block",
    backgroundSize: "contain",
  },
  masterCardIcon: {
    width: 22,
    height: 14,
    display: "block",
    backgroundSize: "contain",
  },
  cardListHolder: {
    top: 50,
    paddingBottom: 60,
  },
  bodyWrapper: {
    width: 280,
  },
  modalOuterWrapper: {
    backgroundColor: "#00000059",
  },
  payNowWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  btnTexClr: {
    color: "#333",
  },
  buttons: {
    minWidth: "40%",
  },
  marginBottom5: {
    marginBottom: 5,
  },
  btnMarBottom: {
    marginTop: "auto",
    marginRight: -15,
    marginBottom: 0,
    marginLeft: -15,
    padding: 15,
    backgroundColor: "#fff",
    zIndex: 9999,
  },
  cardListItem: {
    marginRight: 15,
    marginLeft: 15,
  },
  sessionModelHeaderStyle: {
    marginTop: -45,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
  },
  closeBtn: {
    position: "relative",
    zIndex: 9999,
  },
});
