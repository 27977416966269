import React, { Component } from "react";
import R14, { Touchable, Text, Image, StyleSheet, View, Platform } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { ATTACHMENTS } from "../../constant/app.constant";
import HtmlDeCode from "../../utils/HtmlDecode";
import { analyticsLogs } from "../../utils/AnalyticLogs";
import { CommonString } from "../../constant/appCommonText";

const attachedFileIcon = require("../../assets/images/attachedFileIcon.png");

export default R14.connect(
  class DownloadComponent extends Component {
    downloadFile = async ({ item, pathid, threadId, messageId }) => {
      analyticsLogs(CommonString.SIMPLE_MESSAGE_DETAILS, `${CommonString.ATTACHMENT_DOWNLOADED}`, this.props.app);
      let base64Format = await this.props.app.dm.rest.get(`${ATTACHMENTS}/${pathid}`, {
        "thread-id": threadId,
        "message-id": messageId,
        download: true,
      });
      const fileObj = {
        content: `${Platform.OS === 'web' ? 'data:application/octet-stream;base64,' : ''}${(base64Format && base64Format.data) ? base64Format.data : base64Format}`,
        contentType: item.contentType,
        filename: item.fileName
      };
      HtmlDeCode.downloadFile(fileObj);
    };

    render() {
      const { item } = this.props;
      return (
        <>
          {!!item.attachments && !!item.attachments.length
            ? item.attachments.map((el, i) => (
                <Touchable
                  key={`download_${i}`}
                  onPress={() => {
                    this.downloadFile({
                      item: el,
                      pathid: el.path,
                      threadId: item.envelope.threadHeader.threadId,
                      messageId: item.envelope.messageHeader.messageId,
                    });
                  }}
                style={[commonStyles.marginBottom5]}>
                  <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsStart]}>
                    <Image source={attachedFileIcon} style={[commonStyles.icon14, styles.attachedFileIcon]} />
                    <Text style={[commonStyles.smallHeading, commonFontStyles.workSans]}>
                      {el.fileName || el.filename}
                    </Text>
                  </View>
                </Touchable>
              ))
            : null}
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  attachedFileIcon: {
    marginTop: 3,
    marginRight: 5,
  },
});
