import React from "react";
import R14, { View, Text, StyleSheet, FlatList, Touchable } from "../core";
import NoRecordsFound from "../components/NoRecordsFound";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import DateUtils from "../utils/DateUtils";
import CommonUtility from "../utils/CommonUtility";
import { CURRENCY } from "../constant/app.data.constant";
import StatusComponent from "../components/StatusComponent";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

export default R14.connect(
  class BillHistoryScreen extends React.Component {
    onPress = async (item) => {
      analyticsLogs(CommonString.BILL_HISTORY_PAGE, `${CommonString.BILL_HISTORY_DETAIL}`, this.props.app);
      let newThreadId = item.threadId; //.replaceAll("#", ">>");
      this.props.app.nav.to("billHistoryDetailsRoute", { threadId: newThreadId });
    };

    render() {
      const { messages } = this.props;
      return (
        <View style={[styles.billHistoryScreen]}> 
          <FlatList
            style={[styles.billerHistoryScreen, customCommonStyles.customBillerHistoryScreen]}
            ListEmptyComponent={<NoRecordsFound noRecords={[commonStyles.flexGrow0, commonStyles.paddingTop16]} message={"No bills found."} />}
            data={messages}
            keyExtractor={(el) => el.threadId}
            renderItem={({ item, index }) => (
              <View style={[commonStyles.flex1, styles.historyContainer]}>
                <Touchable styles={[commonStyles.flex1]} onPress={() => this.onPress(item)}>
                  <View style={[styles.cardBox, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                    <View style={styles.listCircle}></View>
                    <View style={[customCommonStyles.billHistoryRightSec]}>
                      <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, commonStyles.alignItemsFlexStart]}>
                        <View style={[styles.subjectName]}>
                          <Text
                            style={[
                              styles.cardSubHeadingText,
                              styles.cardSubject,
                              commonStyles.heading6,
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.wordBreakWord,
                              commonStyles.marginBottom5,
                            ]}>
                            {item.subject}
                          </Text>
                        </View>
                        <View>
                          <StatusComponent
                            marginBottom={[commonStyles.marB0, commonStyles.marRT0]}
                            label={item.billStatus}
                            paymentStatusLabel={item.paymentStatus}
                            statusWrapStyle={[commonStyles.justifyContentFlexEnd, commonStyles.flexGrow0]}
                            customStatusContainer={customCommonStyles.billHistoryScreenStatus}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={styles.cardSmlHeadingText}>
                          {/* {!!item.fullPaidAndDeclined ? DateUtils.dateAndTimeWithAmPm(item.fullPaidAndDeclined) : null} */}
                          {DateUtils.dateAndTimeWithAmPm(CommonUtility.displayBillsHisotryDate(item))}
                        </Text>
                      </View>
                      <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, commonStyles.alignItemsFlexStart]}>
                        <View>
                          <View>
                            <Text
                              style={[
                                styles.cardSubHeadingText,
                                commonStyles.fontWeight700,
                                commonFontStyles.workSans,
                                commonStyles.heading6,
                                styles.dueIn,
                              ]}>
                              Due On
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={[
                                styles.cardSubHeadingText,
                                // commonStyles.marginTop5,
                                commonStyles.marginBottom5,
                                commonFontStyles.workSans,
                                commonStyles.heading6,
                              ]}>
                              {item.dueDate}
                            </Text>
                          </View>
                        </View>
                        <View>
                          <View style={commonStyles.textAlignRight}>
                            <View style={[commonStyles.textAlignRight, customCommonStyles.billHistorytotalAmount,]}>
                              <Text
                                style={[
                                  styles.cardSubHeadingText,
                                  commonStyles.fontWeight700,
                                  commonFontStyles.workSans,
                                  commonStyles.heading6,
                                  commonStyles.textAlignRight
                                ]}>
                                Total Amount
                              </Text>
                            </View>
                            <View>
                              <Text
                                style={[
                                  styles.cardSubHeadingText,
                                  // commonStyles.marginTop5,
                                  commonStyles.marginBottom15,
                                  commonFontStyles.workSans,
                                  commonStyles.heading6,
                                  commonStyles.textAlignRight
                                ]}>
                                {CURRENCY["USD"]} {item.billAmount}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </Touchable>
              </View>
            )}
          />
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  billHistoryScreen: { 
    height: "100%",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          margin: 0,
        };
    },
  },
  cardSmlHeadingText: {
    color: "#888888",
    display: "block",
    fontSize: 12,
    margin: 0,
  },
  userIconDiv: {
    minWidth: 32,
  },
  circleText: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: "rgba(0,173,255,.20)",
    color: "rgba(12, 125, 166, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  cardBox: {
    paddingTop: 8,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    borderBottom: "1px solid #DADADA",
    marginBottom: 3,
    alignItems: "stretch",
    minHeight: 105,
    screen: ({ width }) => {
      if (width <= 420)
        return {
          paddingBottom: 0
        };
    },
  },
  cardSubHeadingText: {
    color: "#444444",
    fontSize: 14,
  },
  boldF: {
    fontWeight: "bold",
  },
  listCircle: {
    height: 12,
    width: 12,
    minWidth: 12,
    backgroundColor: "rgb(77, 197, 242)",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginRight: 8,
    marginTop: 5,
  },
  dueIn: {
    // marginTop: 5,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  decline: {
    height: 13,
    width: 15,
    marginTop: 3,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
  },
  billerName: {
    display: "flex",
    alignItems: "center",
    marginBottom:30
  },
  billerHistoryScreen: {
    backgroundColor: "#fff",
    marginTop: 90,
    marginBottom: 50,
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 640){
        return {
          marginRight: 0,
          marginLeft: 0,
          marginTop: 95,
        };
      } else if (width <= 420) {
        return {
          marginRight: 0,
          marginLeft: 0,
          marginTop: 95,
          minHeight: '100%',
        };
      }
    },
  },
  historyContainer: {
    minHeight: 101,
  },
  subjectName: {
    marginRight: 40,
    flexBasis: "70%",
    maxWidth: "70%",
  },
});
