/* PROD API URLs */
const BASE_URL = "https://xbpprodrepo.exela.global";
const USER_API = `${BASE_URL}/apis/requesttopay/v1.0/app/user`;
const NATIVE_APP_URL = "https://xbpclaims.exela.global";
const MIXPANEL_TOKEN = '9b69f0d1ffe4cfdf694af49ce1f46b3e';
const GOOGLE_ANALYTICS_TOKEN = 'G-THZ4M9J2Z3';

const KC_BASE_URL = 'https://auth.exelatech.com'; 

const REALM_NAME = "exela-consumers";
const KC_CLIENT_ID = "xbppayer";
const CLAIM_CLIENT_ID = "xbpclaim";

export {
    BASE_URL, USER_API, NATIVE_APP_URL, KC_BASE_URL, REALM_NAME, KC_CLIENT_ID, CLAIM_CLIENT_ID, MIXPANEL_TOKEN, GOOGLE_ANALYTICS_TOKEN
}