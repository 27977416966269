import React from "react";
import R14, {
  StyleSheet, 
  Text,
  View, 
  Touchable,
  Image
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import MyBankListComponent from "../components/MyBankListComponent";
import JsonToCSV from "../utils/JsonToCSV";
import HtmlDecode from "../utils/HtmlDecode";
import DateUtils from "../utils/DateUtils";
import { analyticsLogs } from "../utils/AnalyticLogs";
import { CommonString } from "../constant/appCommonText";

const attachedFileIcon = require("../assets/images/attachedFileIcon.png");

export default R14.connect(
  class MyBanksScreen extends React.Component {

    convertToCSV = async(items, screenName = "data") => {
      analyticsLogs(CommonString.MY_BANK, `${CommonString.DOWNLOAD_CSV}`, this.props.app);
      if (items.length){
        items = items.map(item => {
          return {
            "Biller Name": item.billerName,
            "Business Unit Name": item.buName,
            "Bank Name": item.bankName,
            "Account#": item.accountNumber,
            "Routing#": item.routingNumber,
            "Verification Status": item.verificationStatus,
          }
        })
        const csvContent = JsonToCSV.convertJsonToCSV(items);
        HtmlDecode.downloadUTF8file({
          content: csvContent,
          filename: `${screenName}_${DateUtils.formatYYYYMMDDHHMMSS()}.csv`
        })
      }
      return false;
    }

    renderPageTitleSection() {
      return (
        <View style={[styles.pageTitleHolder]}>
          <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, styles.pageTitle]}>
            {this.props.r14.navigation.currRoute._config.label}{" "}
          </Text>
          <View style={[]}>
            <Touchable onPress={() => this.convertToCSV(this.props.payerBankAccounts, "My Banks")}>
              <Image source={attachedFileIcon} style={commonStyles.icon18} />
            </Touchable>
          </View>
        </View>
      );
    }

    render() {
      return (
        <>
          {this.renderPageTitleSection()}
          <MyBankListComponent payerBankAccounts={this.props.payerBankAccounts} />
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  pageTitleHolder: {
    // backgroundColor: "#fff",
    zIndex: 999,
    paddingTop: 7,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    background: "#f4f4f4",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 0,
    maxHeight: 45,
  },
  pageTitle: {
    color: "#151b26",
  },
  container: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "white",
        };
      }
    },
  }
});
