import React from "react";
import { Touchable, View } from "../core";
import { commonStyles } from "../assets/css/commonStyle";

export default class TouchableComponent extends React.Component {
    render() {
        const {
            hidden,
            disabled,
            style,
            children,
            onPress,
        } = this.props;
        if (!!hidden) return null;
        else if (!!disabled) return <View style={[commonStyles.disable, style]}>{children}</View>;
        else return <Touchable onPress={onPress} style={style}>{children}</Touchable>
    }
}