import React from 'react';
import R14, { Platform } from '../core';
import CommonUtility from '../utils/CommonUtility';
import PCHLoaderComponent from '../components/PCH/PCHLoaderComponent';

export default R14.connect(
  class KCSessionScreen extends React.Component {

    componentDidMount = async() => {
      let app = this.props.app;
      let queryString = app.route._data.query;
      let returnUrl = queryString['ReturnUrl'];
      if(!!returnUrl)
      {
        let value =  app.route._location.search;
        returnUrl = !!value ? value.split('&').find(x => x.indexOf('ReturnUrl=')) : ""
      }

      if (Platform.OS === "web" && !!queryString && !!queryString.session_state) {
       await this.onSuccess(app, {
          session_state: queryString.session_state,
          code: queryString.code,
          scopes: 'openid',
          clientId: this.props.clientId,
          redirectUri: window.location.origin + app.route._path + (!!returnUrl ? returnUrl : "")
        })
      }
    }

    onSuccess = async (app, response) => {
      if (!!response) {
        await CommonUtility.getKCToken(app, response);
      }
    }

    render() {
      return(
        <PCHLoaderComponent isVisiable={true} labelText={"Loading..."} />
      )
    }
  }
);