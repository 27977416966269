import React from "react";
import R14, {
  StyleSheet,
  View,
  Text,
  Touchable,
  Image,
  ScrollView,
  Platform,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import ModalPopUp from "../ModalPopUp";
import Linking from "../../utils/Linking";
import PCHLoaderComponent from "../PCH/PCHLoaderComponent";
import ACHCreateForm from "./ACHCreateForm";
import { commonMetricsStyle } from "../../assets/css/commonMetricsStyle";
import { CommonString } from "../../constant/appCommonText";
import { analyticsLogs } from "../../utils/AnalyticLogs";
const deleteIcon = require("../../assets/images/delete.png");
const varifed = require("../../assets/images/selete_icon_active.png");
const notVarifed = require("../../assets/images/unverifiedicon.png");

export default R14.connect(
  class ACHModelPopUp extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        IsAddMode: false,
        paymentIntents: [],
        isLoading: false,
        payNow: false,
        newBankAdded: false,
        intent: {}
      }
    }

    async componentDidMount() {
      try {
      this.setState({
        isLoading: true
      })
      let payerBankAccounts = await this.props.app.dm.achPaymentDomain.getPayerBankAccounts(
        {
          pId: { eq: this.props.payerId },
          billerPid: { eq: this.props.billerId },
          isActive: true
        });
      payerBankAccounts = !!payerBankAccounts ? payerBankAccounts.filter(account => account.isActive && !['Elapsed', 'Declined'].includes(account.verificationStatus) ) : []; // "Invalid"
      const sortedPayerBankAccounts = payerBankAccounts.sort((bankA, bankB) => bankB.bankName > bankA.bankName ? -1 : 1);
      this.setState({
        paymentIntents: sortedPayerBankAccounts,
        // paymentIntents: [],
        isLoading: false,
        IsAddMode: sortedPayerBankAccounts.length === 0
        // IsAddMode: 0
      })
      } catch (error) {
        this.setState({ isLoading: false })
      }
    }

    handleCloseModel = () => {
      this.props.handleCloseModel({statusCode: 'CANCELLED'});
    }

    handleCreateForm = (intent) => {
      // if bank account added
      if (intent && intent.intent) {
        analyticsLogs(CommonString.ACH_PAY, `${CommonString.New_Bank_Added}`, this.props.app);
        let payerBankAccounts = this.state.paymentIntents || [];
        payerBankAccounts.push(intent.intent)
        const sortedPayerBankAccounts = !!payerBankAccounts ? payerBankAccounts.sort((bankA, bankB) => bankB.bankName > bankA.bankName ? -1 : 1) : [];
        this.setState({
          IsAddMode: !this.state.IsAddMode,
          newBankAdded: true,
          intent: intent.intent,
          paymentIntents: sortedPayerBankAccounts,
          isLoading: false
        })
      } else {
        this.setState({
          IsAddMode: !this.state.IsAddMode,
          isLoading: false
        })
      }
    }

    onPayerAccountDelete = async (uid) => {
      let payload = {
        isActive: false,
        uid: uid
      }
      analyticsLogs(CommonString.ACH_PAY, `${CommonString.ACCOUNT_DELETED}`, this.props.app);
      await this.props.app.dm.achPaymentDomain.updatePayerBankAccount(payload);
      this.setState({
        paymentIntents: this.state.paymentIntents.filter(intents => intents.uid !== uid)
      })
    }

    onPayNow = (intent) => {
      if (!!intent && !!intent.isBankVerified) {
        this.setState({
          payNow: !this.state.payNow,
          intent: intent
        })
      }
      else if (!!intent && !!!intent.isBankVerified) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.AccountNotYetVerified)
      }
      else {
        this.setState({
          payNow: !this.state.payNow,
          intent: intent
        })
      }
    }

    onPaymentIntent = async (intent) => {
      let payload = {
        uid: intent.uid,
        billDetailId: this.props.billDetailId,
        currency: this.props.currencyCode.toLowerCase(),
        amount: parseFloat(this.props.amount),
        metaData: {
          pid: this.props.payerId,
          billerPid: this.props.billerId,
          e2e: this.props.e2e,
          buId: this.props.buId,
          buName: !!this.props.buName? this.props.buName: this.props.buId,
        },
        merchantAccountNumber: this.props.merchantAccountNumber,
      };
      let response = {};
      analyticsLogs(CommonString.ACH_PAY, `${CommonString.PAY_ACTION}`, this.props.app);
      if (!!intent.isBankVerified)
        response = await this.props.app.dm.achPaymentDomain.paymentIntent(payload);
      if (response && response.paymentIntent && response.paymentIntent.indexOf('Error') > -1) {
        let errorResponse = {
          success: false,
          error: `${response.paymentIntent}`,
          achBankAccountId: intent.intentId,
          achBankAccountNumber: intent.accountNumber,
        };
        this.setState({
          isLoading: false
        })
        this.props.onPaymentIntent(errorResponse);
      }
      else {
        response.achBankAccountId = intent.intentId;
        response.achBankAccountNumber = intent.accountNumber;
        response.success = true;
        this.setState({
          isLoading: false
        })
        this.props.onPaymentIntent(response);
      }
    }

    onVerify = async (isVerfied, verfiyLink) => {
      if (isVerfied) {
        analyticsLogs(CommonString.ACH_PAY, `${CommonString.ALREADY_VERIFIED_BANK}`, this.props.app);
        this.props.app.dm.commonDomain.showToastr("success", CommonString.AccountAlreadyVerified)
      } else {
        analyticsLogs(CommonString.ACH_PAY, `${CommonString.VERIFY_BANK}`, this.props.app);
        if(!!verfiyLink) Linking.LinkingURL(verfiyLink);
      }
    }

    handleNewBankAdded = (intent) => {
      this.setState({
        newBankAdded: false,
        payNow: true,
        intent: intent
      })
    }

    payerBankAccountView = (intent, i) => {
      return (
        <View key={`bankActs_${i}`} style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, styles.selectedState, styles.borderBottomSpace15,  styles.banklistRow, i === 0 ? commonStyles.marginTop5 : null]}>
          <Touchable onPress={() => this.onPayNow(intent)}>
            <View style={[customCommonStyles.customPaddingBottom, commonStyles.paddingRight16]}>
              <View>
                <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.smallHeading]}>{ intent && intent.bankName && intent.bankName.length >= 20 ? `${intent.bankName.substring(0, 20)}...` : intent.bankName}</Text>
              </View>
              <View>
                <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.exSmallHeading]}>Account#: {intent && intent.accountNumber}</Text>
              </View>
            </View>
          </Touchable>
          {/* Right Sides icon */}
          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd ,commonStyles.alignItemsEnd]}>
            {/* verify icon */}
            <Touchable style={[commonStyles.marginLeft15, commonStyles.marginRight10]} onPress={() => this.onVerify(intent.isBankVerified, intent.metaData)}>
              <View>
                <Image source={intent.isBankVerified ? varifed : notVarifed} style={commonStyles.icon20} />
              </View>
            </Touchable>
            {/* Delete icon */}
            {!!this.props.isDeleteAllowed ?
              <Touchable style={[commonStyles.marginLeft15]} onPress={() => this.onPayerAccountDelete(intent.uid)}>
                <View>
                  <Image source={deleteIcon} style={commonStyles.icon20} />
                </View>
              </Touchable>
              : null}
          </View>
        </View>
      )
    }

    newBankAddedView = (intent) => {
      return (
        <View style={[commonStyles.alignItemsCenter]}>
          <View style={[customCommonStyles.newBankAddedMsg]}>
            <Text style={[
              commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock,commonStyles.textAlignCenter
            ]}>
              An email/sms has been sent to you for verification of your Bank details. Please check and verify to complete your payments.
            </Text>
          </View>
          <View style={[styles.okayContainter, commonStyles.marginTop15, styles.okBtn]}>
            <Touchable style={[styles.alignSelfCenter]} onPress={() => this.handleNewBankAdded(intent)}>
              <View style={[styles.activeButton, commonStyles.blueBorderButtonColor, styles.activeButtonPadding]}>
                <Text style={[
                  commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6, styles.btnText, commonStyles.whiteColor   
                ]}>OK</Text>
              </View>
            </Touchable>
          </View>
        </View>
      )
    }

    errorModelView = (message) => {
      return (
        <View style={commonStyles.textAlignCenter}>
          <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading5, commonStyles.textAlignCenter, commonStyles.darkGrayColor,commonStyles.textAlignCenter
          ]}>Bank Added</Text>
          <View style={[commonStyles.marginTop15]}>
            {this.newBankAddedView(this.state.intent)}
          </View>
        </View>
      )
    }

    showLoading = (value) => {
      this.setState({
        isLoading: value
      })
    }

    render() {
      return (
        <>
          <ModalPopUp
            visibility={!!this.props.visibility}
            isFooter={true}
            modalContent={commonStyles.marginBottom0}
            bodyWrapper={[
              !this.state.isLoading && commonStyles.mediumModalBodyWrapper, 
              !this.state.isLoading && styles.mediumModalBodyWrapperHeight, 
              !this.state.isLoading && this.state.IsAddMode ? customCommonStyles.addBankFormModalHeight : null, 
              !this.state.isLoading && this.state.newBankAdded ? customCommonStyles.bankAddedModalHeight : null,
              !this.state.isLoading && !this.state.payNow && 
              !this.state.newBankAdded && 
              this.state.paymentIntents.length ? customCommonStyles.mainModalHeight : null,
              !this.state.isLoading && this.state.payNow ? customCommonStyles.paymentConfirmationModalHeight : null, 
              this.state.isLoading && commonStyles.padding0
            ]}
            contentInner={[styles.contentInner]}
            >
            {this.state.IsAddMode || this.state.isLoading ?
            <>
              <ACHCreateForm
                handleCreateForm={this.handleCreateForm}
                billerName={this.props.billerName}
                payerName={this.props.payerName}
                payerId={this.props.payerId}
                buId={this.props.buId}
                buName={this.props.buName}
                email={this.props.email}
                billerId={this.props.billerId}
                stripeKeys={this.props.stripeKeys}
                showLoading={this.showLoading}
                isDisplayNone={!this.state.IsAddMode || this.state.isLoading  ? true : false}
                />
              <PCHLoaderComponent isVisiable={this.state.isLoading} labelText={"Please wait"} />
            </>
              :
              <>
                <PCHLoaderComponent isVisiable={this.state.isLoading} labelText={"Please wait"} />
                {!this.state.payNow && !this.state.newBankAdded ? (
                  <View style={[styles.contentInner, commonStyles.flex1, styles.bankListContainerOuter, customCommonStyles.achCreateFormContainer]}>
                    <View style={[styles.headerContainer, commonStyles.flexDirectionColumn]}>
                      <View style={[commonStyles.padding5, commonStyles.paddingLeft0]}>
                        <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.heading6 ]}>Biller Name: <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400,commonStyles.heading6]}>{this.props.billerName && this.props.billerName.length >= 20 ? `${this.props.billerName.substring(0, 20)}...` : this.props.billerName}</Text> </Text>
                      </View>
                      <View style={[commonStyles.padding5, commonStyles.paddingLeft0]}>
                        <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.heading6]}>Bill Subject: <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400,commonStyles.heading6]}>{this.props.subject && this.props.subject.length >= 20 ? `${this.props.subject.substring(0, 20)}...` : this.props.subject}</Text></Text>
                      </View>
                      <View style={[commonStyles.padding5, commonStyles.paddingLeft0]}>
                        <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,commonStyles.heading6]}>Amount: <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400,commonStyles.heading6]}>${this.props.amount}</Text></Text>
                      </View>
                   </View>
                   <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween, commonStyles.flexGrow0, styles.bankAndAddBankButton]}>
                      <View style={[styles.bottomLine]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500,commonStyles.heading6]}>
                          {!this.state.paymentIntents || this.state.paymentIntents.length === 0 ? null : "Select Bank" }
                        </Text>
                      </View>
                      <View style={[styles.addBankBtn]}>
                        <Touchable onPress={this.handleCreateForm}>
                          <View style={[styles.activeButton]}>
                            <Text style={[
                              commonStyles.fontWeight500,
                              commonFontStyles.workSans,
                              commonStyles.heading6,
                              commonStyles.alignItemsCenter,
                              styles.btnText,
                              commonStyles.whiteColor
                            ]}>ADD BANK</Text>
                          </View>
                        </Touchable>
                      </View>
                    </View>
                    {
                      this.state.paymentIntents.length > 0 ?
                        (Platform.OS === "web" ?
                          <ScrollView style={[this.state.paymentIntents.length <= 0 ? [commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter] : customCommonStyles.bankListContainer,]}>
                            <View>
                              {this.state.paymentIntents && this.state.paymentIntents.length > 0 && this.state.paymentIntents.map((intent, i) => intent && intent.isActive && this.payerBankAccountView(intent, i))}
                            </View>
                          </ScrollView> :
                          <ScrollView contentContainerStyle={[this.state.paymentIntents.length <= 0 ? [commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter] : customCommonStyles.bankListContainer,]}>
                            <View>
                              {this.state.paymentIntents && this.state.paymentIntents.length > 0 && this.state.paymentIntents.map((intent, i) => intent && intent.isActive && this.payerBankAccountView(intent, i))}
                            </View>
                          </ScrollView>
                        ) : null }
                    {/* Cancel & pay button */}
                    <View style={[styles.buttonContainter, styles.cancelPayButtons, customCommonStyles.customCancelPayButtons]}>
                      <View style={[commonStyles.marginRight16]}>
                        <Touchable  onPress={() => this.handleCloseModel()}>
                            <View style={[styles.activeButton, styles.border2, commonStyles.blueBorderButtonColor,  commonStyles.whiteBackgroundColor]}>
                            <Text style={[
                                commonStyles.fontWeight500,
                                commonFontStyles.workSans,
                                commonStyles.heading6,
                                styles.btnText,
                                commonStyles.blueBorderButtonColor
                            ]}>CANCEL</Text>
                            </View>
                        </Touchable>
                      </View>
                      <View>
                        <Touchable>
                            <View style={[styles.activeButton, styles.activeButtonPadding, commonStyles.disable]}>
                            <Text style={[
                                commonStyles.fontWeight500,
                                commonFontStyles.workSans,
                                commonStyles.heading6,
                                styles.btnText,
                                commonStyles.whiteColor,
                                commonStyles.disableText
                            ]}>PAY</Text>
                            </View>
                        </Touchable>
                      </View>
                    </View>
                  </View>
                ) : (
                  <>
                    {this.state.newBankAdded ? (
                      <View style={commonStyles.textAlignCenter}>
                        <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading5, commonStyles.textAlignCenter, commonStyles.darkGrayColor,commonStyles.textAlignCenter
                        ]}>Bank Added</Text>
                        <View style={[commonStyles.marginTop15]}>
                          {this.newBankAddedView(this.state.intent)}
                        </View>
                      </View>
                    ) : (
                      <View>
                        <View style={[commonStyles.textAlignCenter]}>
                          <Text style={[commonStyles.marginBottom10, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading5, commonStyles.textAlignCenter]}>Payment Confirmation</Text>
                        </View>
                        <View style={styles.headerContainer}>
                          <View style={[styles.selectedState]}>
                            <View style={[commonStyles.padding5]}>
                              <View>
                              <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,]}>Biller: {" "}</Text></View>
                              <View>
                              <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400,]}>{this.props.billerName && this.props.billerName.length >= 32 ? `${this.props.billerName.substring(0, 32)}...` : this.props.billerName}</Text>
                              </View>
                            </View>
                            <View style={[commonStyles.padding5]}>
                            <View>
                              <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,]}>Bill Subject:{" "}</Text>
                              </View>
                              <View>
                              <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400,]}>{this.props.subject && this.props.subject.length >= 32 ? `${this.props.subject.substring(0, 32)}...` : this.props.subject}</Text>
                              </View>
                            </View>
                            <View style={[commonStyles.padding5]}>
                            <View>
                              <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,]}>Amount:{" "}</Text>
                              </View>
                              <View>
                              <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400,]}>${this.props.amount}</Text>
                              </View>
                            </View>
                            <View style={[commonStyles.padding5]}>
                            <View>
                              <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,]}>Bank:{" "}</Text>
                              </View>
                              <View>
                              <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400,]}>{this.state.intent && this.state.intent.bankName && this.state.intent.bankName.length >= 32 ? `${this.state.intent.bankName.substring(0, 32)}...` : this.state.intent.bankName} </Text>
                              </View>
                            </View>
                            <View style={[commonStyles.padding5]}>
                            <View>
                              <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonMetricsStyle.heading6,]}>Account#:{" "}</Text>
                              </View>
                              <View>
                              <Text style={[commonFontStyles.workSans, commonMetricsStyle.heading6, commonStyles.fontWeight400,]}>{this.state.intent && this.state.intent.accountNumber}</Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        {/* Cancel and Pay Now buttons */}
                        <View style={[styles.buttonContainter, styles.cancelPayButtons]}>
                          <View>
                            <Touchable onPress={() => this.onPayNow(null)}>
                              <View style={[styles.activeButton, styles.border2, commonStyles.blueBorderButtonColor, commonStyles.whiteBackgroundColor, commonStyles.textAlignCenter]}>
                                <Text style={[
                                  commonStyles.fontWeight500,
                                  commonFontStyles.workSans,
                                  commonStyles.heading6,
                                  styles.btnText,
                                  commonStyles.textAlignCenter,
                                  commonStyles.blueBorderButtonColor
                                ]}>BACK</Text>
                              </View>
                            </Touchable>
                          </View>
                          <View style={[commonStyles.marginLeft16]} >
                            <Touchable onPress={() => this.onPaymentIntent(this.state.intent)}>
                              <View style={[styles.activeButton, styles.activeButtonPadding, commonStyles.textAlignCenter]}>
                                <Text style={[
                                  commonStyles.fontWeight500,
                                  commonFontStyles.workSans,
                                  commonStyles.heading6,
                                  commonStyles.textAlignCenter,
                                  commonStyles.whiteColor
                                ]}>PAY</Text>
                              </View>
                            </Touchable>
                          </View>
                        </View>
                      </View>
                    )}
                  </>
                )}
              </>
            }
          </ModalPopUp>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  mediumModalBodyWrapperHeight: {
    screen: ({ width }) => {
      if (width <= 640)
        return {
          minHeight: 280,
        };
    },
  },
  headerContainer: {
    paddingBottom: 10
  },
  billerName: {
    flex: 1,
    flexBasis: "50%",
    flexDirection: 'row'
  },
  borderBottomSpace15: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    marginBottom: 5,
  },
  selectedState: {
    borderColor: "#4dc5f2",
    marginBottom: 16,
  },
  activeButton: {
    backgroundColor: "#d71996",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    boxShadow: "none",
    justifyContent: 'center',
    minWidth: 100,
    display:"flex",
    alignItems:"center",
    minHeight: 24,
    height: 24,
    maxHeight: 24,
  },
  border2:{
    borderWidth: 2,
    borderStyle: "solid",
    borderColor:"rgb(18, 119, 217)"
  },
  buttonContainter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: "100%",
    maxHeight: 33,
    marginTop: "auto",
  },
  cancelPayButtons: {
    marginTop: "auto",
    justifyContent: "flex-end",
  },

  activeButtonPadding: {
    paddingLeft: 15,
    paddingRight: 15
  },
  okBtn: {
    maxWidth: 100,
    marginLeft: "auto",
    marginRight: "auto",
  },
  alignSelfCenter: {
    alignSelf: "center"
  },
  okayContainter: {
    width: '100%',
    justifyContent: 'center',
    flex: 1,
  },
  btnText: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  banklistRow: {
    // minHeight: 46,
    width: "100%",
  },
  contentInner: {
    minHeight: "100%",
    height: "100%",
  },
  bankListContainerOuter: {
    maxWidth: "100%",
  },
  bankAndAddBankButton: {
    minHeight: 35,
    marginBottom: 16,
    alignItems: "flex-end"
  },
  addBankBtn: {
    position: "relative",
    zIndex: 99999,
    left: 0,
  },
  bottomLine:{
    borderBottomWidth: 1,
    borderColor: 'rgb(77, 197, 242)',
    borderStyle: "solid",
    width: "100%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "60%"
        };
    },
  }
});
