import React from "react";
import R14 from "../../core";
import ViewSubmittedClaimComponent from "../../components/PCH/ViewSubmittedClaimComponent";

export default R14.connect(
  class PatientViewBillScreen extends React.Component {    
    render() {
      const { uploadBill, userLoginDetail } = this.props;
      return (
        <ViewSubmittedClaimComponent uploadBill= {uploadBill} userLoginDetail={userLoginDetail}  />
      );
    }
  }
);
