import R14 from "../core";
import CommonUtility from "../utils/CommonUtility";

export default class UserLoginDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      socialLoginProfile: {},
      osPlatform: {},
      userDetailUID: "",
      payerId: "",
      socialLoginType: "",
      access_token:"",
      refresh_token:""
    };
  }

  async getLoginDetail(uid, fields = "uid payerId sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType firstName lastName email mobileNumber EIN pid parentUid") 
   {
    let options = {
      uid: uid
    };
    try {
      let res = await this.api.qry(
        `query UserLoginDetail($uid: ID!){
          userLoginDetail(uid: $uid){
                ${fields}
            }
        }`,
        options
      );
      return res.data.userLoginDetail;
    } catch (error) {
      return error;
    }
  }
 
  // validate Captcha
  async validateCaptcha(token) {
    if (token === "Hide") {
      return {
        success: true,
      };
    } else {
      var inputToken = {
        token: token,
      };
      try {
        let res = await this.api.query(
          `
          query ValidRecaptcha($input: ValidRecaptchaInput!) {
            validRecaptcha(input: $input){       
                  success challenge_ts error              
              }            
          }`,
          {
            input: inputToken,
          }
        );
        return res.data.validRecaptcha ? res.data.validRecaptcha : null;
      } catch (error) {
        return error;
      }
    }
  }

  // Added by Azad
  async checkEIN(EIN, uid = "") {
    try {

      let childs = await this.dm.queryDomain.middleware(this.api.qry(
        `query ValidateEin($EIN: String, $uid: String ){
        validateEin( EIN: $EIN , uid: $uid)
          {
            isDuplicate
            msg
          }
      }`,
        {
          uid: uid,
          EIN: EIN,
        }
      ));
      return !!childs.data ? childs.data.validateEin : null;

    } catch (error) {
      return error;
    }
  }

// manage password
  async managePassword(input) {
  await CommonUtility.htmlDecode(input);
      try {
        let res = await this.api.mutate(
          `
          mutation ManagePassword($input: ManagePasswordInput!) {
            managePassword(input: $input){
               success
              error
              message
              }
            }`,
          {
            input: input,
          }
        );
        return !!res ? res.data.managePassword : null;
      } catch (error) {
        return error;
      }

  }

  // Decode jwt
  async decodeJwtToken(token) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.query(
        `query DecodeJwt($jwt: String!) {
              decodeJwt(jwt: $jwt)
            }`,
        {
          jwt: token,
        }
      ));
      return !!res.data.decodeJwt ? res.data.decodeJwt : null;
    } catch (error) {
      return error;
    }
  }
  
  // Decode jwt
  async decodeCrypto(input) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.query(`query DecodeCrypto($input: String!) { decodeCrypto(input: $input) }`,
        {
          input: input,
        }
      ));
      return !!res.data.decodeCrypto ? res.data.decodeCrypto : input;
    } catch (error) {
      return input;
    }
  }

  async decodeCryptoRfc(input, isEncoded = false) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.query(`query DecodeCryptoRfc($input: String!, $isEncoded: Boolean!) { decodeCryptoRfc(input: $input, isEncoded: $isEncoded) }`,
        {
          input: input,
          isEncoded: isEncoded
        }
      ));
      return !!res.data.decodeCryptoRfc ? res.data.decodeCryptoRfc : input;
    } catch (error) {
      return input;
    }
  }

  async getValue(input) {
    try {
      if (!!input) {
        let res = await this.dm.queryDomain.middleware(this.api.query(`query GetDecryptedValue($encryptedValue: String!, $key: String!) { getDecryptedValue(encryptedValue: $encryptedValue, key: $key) }`,
          {
            encryptedValue: input,
            key: "XBPAPP"
          }
        ));
        return !!res.data.getDecryptedValue ? res.data.getDecryptedValue : input;
      }
    }
    catch (err) {
    }
    return input;
  }
}
