import { LOGIN_TYPE } from "../constant/app.data.constant";
import { CommonString } from "../constant/appCommonText";
import R14, { AsyncStorage, AsyncSessionStorage } from "../core";

export default class KeyCloakAPIDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      audience: []
    };
  }

  async authToken(payload, isClaims) {
    try {
      let fields = "uid pid payerId kcId lastLoginDate sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType email mobileNumber subRole {appName appRole} isActive isSubscriptionExpired subscriptionUid clientUid planUid loginType resourceAccess"

      if(!!isClaims)
        fields = fields + " EIN parentUid parentUserDetail { uid email firstName lastName} "
        let response = await this.api.mutate(
        `mutation GetAuthToken($input: KCRequestPayload!){
            authToken(input: $input)
            {
              success
              message
              error
              roles
              access_token
              expires_in
              refresh_expires_in
              refresh_token
              id_token
              aud
              session_state      
              userLoginDetail
              {
               ${fields}
              }
              linkedPayers { isPrimaryPID pid buId billerId }
              userBUDetails { uid firstName lastName userDetailUid payerRef buId buName billerId billerRef billerName country addressLine1 addressLine2 state town postCode countryObject{label value} stateObject{label value} cityObject{label value} }
            }
        }`,
        {
          input: payload,
        });

      let data = response.data.authToken;
      if (!!data && !!data.access_token) {
        await AsyncStorage.setItem("CLIENT_ID", payload.clientId);
        await AsyncStorage.setItem("REDIRECT_URI", payload.redirectUri);
        this.setState({
          audience: data.aud
        });
        if (!!data.success) {
            await AsyncStorage.setItem("userRoles", data.roles);
            this.dm.userSession.setState({ userRoles: !!data.roles ? data.roles : "" });
            if(data.userLoginDetail && data.userLoginDetail.parentUid){
                const getParentBUName = await await this.dm.user.getUserBUName(data.userLoginDetail.parentUid);
                data.userLoginDetail.parentUserDetail = {...data.userLoginDetail.parentUserDetail, ...getParentBUName};
            }
            return await this.dm.userSession._processAuthResult(data, !!isClaims ? "CLAIMS" : "XBP");
        } else return data;
      }
      else return data;
    } catch (error) {
      return error;
    }
  }

  async validateExternalLogin(mode, appointmentId) {
    try {
      let fields = "uid pid payerId kcId lastLoginDate sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType email mobileNumber subRole {appName appRole} isActive EIN parentUid parentUserDetail { uid email} "
        let response = await this.api.mutate(
        `query validateExternalLogin($mode: String!, $appointmentId: String!){
          validateExternalLogin(mode: $mode, appointmentId: $appointmentId)
            {
              success
              message
              error
              access_token
              refresh_token
              userLoginDetail
              {
               ${fields}
              }
              linkedPayers { isPrimaryPID pid buId billerId}
              userBUDetails { uid firstName lastName userDetailUid payerRef buId buName billerId billerRef billerName country addressLine1 addressLine2 state town postCode countryObject{label value} stateObject{label value} cityObject{label value} }
            }
        }`,
        {
          mode: mode, appointmentId: appointmentId
        });

      let data = response.data.validateExternalLogin;
      if (!!data && !!data.access_token) {
        this.setState({
          audience: data.aud
        });
        if (!!data.success)
          return await this.dm.userSession._processAuthResult(data, "CLAIMS", LOGIN_TYPE.UBERDOC);
        else
          return data;
      }
      else return data;
    } catch (error) {
      return error;
    }
  }

  
  async validatePCHGlobalLogin() {
    try {
      let fields = "uid pid payerId kcId lastLoginDate sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType email mobileNumber subRole {appName appRole} isActive EIN parentUid parentUserDetail { uid email} "
        let response = await this.api.mutate(
        `query validatePCHGlobalLogin{
          validatePCHGlobalLogin
            {
              success
              message
              error
              access_token
              refresh_token
              userLoginDetail
              {
               ${fields}
              }
              linkedPayers { isPrimaryPID pid buId billerId}
              userBUDetails { uid firstName lastName userDetailUid payerRef buId buName billerId billerRef billerName country addressLine1 addressLine2 state town postCode countryObject{label value} stateObject{label value} cityObject{label value} }
            }
        }`);

      let data = response.data.validatePCHGlobalLogin;
      if (!!data && !!data.access_token) {
        this.setState({
          audience: data.aud
        });
        if (!!data.success)
          return await this.dm.userSession._processAuthResult(data, "CLAIMS", LOGIN_TYPE.PCHGLOBAL);
        else
          return data;
      }
      else return data;
    } catch (error) {
      return error;
    }
  }
  
  async validateNonRegister(payload) {
    try {
      let fields = "uid pid payerId kcId lastLoginDate sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType loginType email mobileNumber subRole {appName appRole} isActive EIN parentUid parentUserDetail { uid email} "
      let response = await this.api.mutate(
        `mutation NonRegisteredMember($input: SignupInput!){
          nonRegisteredMember(input: $input)
            {
              success
              message
              error
              access_token
              refresh_token
              userLoginDetail
              {
               ${fields}
              }
              linkedPayers { isPrimaryPID pid buId billerId}
              userBUDetails { uid firstName lastName userDetailUid payerRef buId buName billerId billerRef billerName country addressLine1 addressLine2 state town postCode countryObject{label value} stateObject{label value} cityObject{label value} }
            }
        }`,
        {
          input: payload
        });

      let data = response.data.nonRegisteredMember;
      if (!!data && !!data.access_token) {
        this.setState({
          audience: data.aud
        });
        if (!!data.success)
          return await this.dm.userSession._processAuthResult(data, "CLAIMS", LOGIN_TYPE.NONREGISTER);
        else
          return data;
      }
      else return data;
    } catch (error) {
      return error;
    }
  }

  async refreshAuthToken() {
    let access_token = await this.dm.userSession.getAccessToken();
    let refresh_token = await AsyncStorage.getItem(CommonString.LOCAL_STORAGE_REFRESHTOKEN);
    let clientId = await AsyncStorage.getItem("CLIENT_ID");
    let redirectUri =  await AsyncStorage.getItem("REDIRECT_URI");
        
    let payload = {
      clientId: clientId,
      refresh_token: refresh_token,
      access_token: access_token,
      redirectUri: redirectUri
    }
    try {
      let response = await this.api.mutate(
        `mutation RefreshAuthToken($input: KCRequestPayload!){
            refreshAuthToken(input: $input)
            {
            success
            message
            error
            access_token
            expires_in
            refresh_expires_in
            refresh_token
            aud
            id_token
            session_state      
            }
        }`,
        {
          input: payload,
        });
      let data = response?.data?.refreshAuthToken;
      if (!data || !!!data.success) {
        return false;
      } else if (!!data && !!data.access_token) {
        const { access_token, refresh_token, aud } = data;
        await AsyncSessionStorage.setItem(CommonString.LOCAL_STORAGE_ACCESSTOKEN, access_token);
        await AsyncSessionStorage.setItem(CommonString.LOCAL_STORAGE_REFRESHTOKEN, refresh_token);
        this.dm.userSession.setState({
          access_token: access_token,
          refresh_token: refresh_token,
        });
        this.setState({
          audience: aud
        });        
        return true;
      }
      return false;
    } catch (error) {
      return error;
    }
  }

  async logOut(payload) {
    try {
      let response = await this.api.mutate(
        `mutation Logout($input: KCRequestPayload!){
            logout(input: $input)
        }`,
        {
          input: payload,
        });
      return response.data.logout;
    } catch (error) {
      return error;
    }
  }

  async getUserInfo() {
    try {
      let response = await this.dm.queryDomain.middleware(this.api.get(
        `mutation GetUserInfo{
            getUserInfo
            {
              uid pid payerId kcId lastLoginDate sessionString changePasswordNow googleProfileID amazonProfileID appleProfileID userType firstName lastName email mobileNumber addressLine1 addressLine2 town state country postCode EIN  parentUid parentUserDetail { uid email firstName lastName} subRole {appName appRole} countryObject{label value}  stateObject{label value} cityObject{label value} resourceAccess
            }
        }`));
      return response.data.getUserInfo;

    } catch (error) {
      return error;
    }
  }

  async tokenIntrospect(uid, validateToken) {
    let access_token = await this.dm.userSession.getAccessToken();
    let clientId = await AsyncStorage.getItem("CLIENT_ID");
    let redirectUri = await AsyncStorage.getItem("REDIRECT_URI");

    let payload = {
      clientId: !!clientId ? clientId : "",
      access_token: access_token,
      redirectUri: !!redirectUri ? redirectUri : "",
      code: uid,
      validateToken: validateToken
    }
    try {
      let response = await this.api.mutate(
        `mutation TokenIntrospect($input: KCRequestPayload!){
          tokenIntrospect(input: $input)
            { success message error userLoginDetail { sessionString } }
        }`,
        {
          input: payload,
        });
      let data = response?.data?.tokenIntrospect;
      if (!!data && !!data.success) {
        return {
          tokenIsValid: true,
          sessionString: data.userLoginDetail?.sessionString,
          isConcurrentLogin: data.success
        };
      } else {
        return {
          tokenIsValid: false,
          sessionString: '',
          isConcurrentLogin: true
        };
      }
    } catch (error) {
      return {
        tokenIsValid: false,
        sessionString: '',
        isConcurrentLogin: true
      };
    }
  }
}
