import React, { Component } from "react";
import R14, { StyleSheet,  View, AsyncStorage } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import SubmittedComponent from "../../components/PCH/SubmittedComponent";
import BillDetailComponent from "../../components/SEPA/BillDetailComponent";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import { CommonString } from "../../constant/appCommonText";
import CommonUtility from "../../utils/CommonUtility";

export default R14.connect(
  class PaymentReceiptScreen extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isPaymentDone: true,
        loaderVisible: true
      };
    }

    async componentDidMount() {
      try {
        await this.onPaymentDone();
        await AsyncStorage.removeItem(CommonString.LOCAL_STORAGE_BILLRECEIPTS);
      } catch (error) {

      }
    }

    onPaymentDone = async () => {
      try {
        const { response, transactionId } = this.props;
        let payload = {
          ...response.sepaBillDetail
        };
        payload.transactionId = transactionId;
        delete payload.messages;
        delete payload.isExpanded;
        delete payload.subMessages;
        delete payload.clientSecret;

        setTimeout(() => {
          this.setState({
            loaderVisible: false
          })
          this.props.app.dm.commonDomain.showToastr("success", `Bill paid successfully.`);
        }, 2000)

        const markAsPaid = await this.props.app.dm.sepa.markAsPaidPayment(payload);
        if (!!markAsPaid && !!markAsPaid.success) {
          this.setState({
            loaderVisible: false
          })
        }
      } catch (error) {
        this.setState({
          loaderVisible: false
        })
      }
      return false;
    }

    render() {
      let { userType, response } = this.props;
      let message = "Bill paid successfully.";
      message = !!response && !!response.sepaBillDetail && !!response.sepaBillDetail.billAmount ? `${CommonUtility.formatAmount(response.sepaBillDetail.billAmount)} Successfully paid to ${response.billDetail.billerName}` : message;
      if (!!!this.state.isPaymentDone) {
        message = "Bill payment has been failed.";
      }

      return (
            <View style={[commonStyles.marginBottom15,styles.billDetailsScreen,]}>
              <BillDetailComponent
                padding1={styles.padding1}
                padding2={[styles.padding2]}
                padding3={styles.padding3}
                userIconDiv={[styles.userIconDiv, commonStyles.flex1]}
                billData={response.billDetail}
                installmentDetails={response.installmentDetails}
                isBookmarkRequire={false}
                customFlexDirection={styles.customFlexDirection}
              />
              <View style={[styles.customMarginTop65]}>
                <SubmittedComponent message={message} customMargin={styles.customMargin} userType={userType} goTo={"dashboard"} goToTitle={"Go To Dashboard"} currency={response.billDetail.currency} hasError={!this.state.isPaymentDone} />
              </View>
              <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} />
            </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  billDetailsWrapper: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          backgroundColor: "#FFF",
        };
    },
  },
  pageTitle: {
    margin: 16,
  },
  billDetailsScreen: {
    // alignItems: "flex-end",
    backgroundColor: "#fff",
    padding: 15,
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          flex: 1,
          flexGrow: 0,
          marginRight: 0,
          marginLeft: 0,
        };
    },
  },
  redClr: {
    color: "#CC0000",
  },

  detailRow: {
    borderBottomWidth: 1,
    borderBottom: "1px solid rgb(218, 218, 218)",
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
    paddingBottom: 5,
    marginBottom: 15,
    flexBasis: "48%",
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginBottom: 10,
          height: 20,
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    flexGrow: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
          flexGrow: 0,
          height: 50,
        };
      }
    },
  },
  mViewMarginBottom: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 25,
          paddingTop: 0,
        };
      }
    },
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMargin: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 5,
        };
      }
    },
  },
  displayNone: {
    display: "none",
  },
  border0: {
    borderBottomWidth: 0,
    borderStyle: "solid",
    borderColor: "rgb(218, 218, 218)",
    paddingBottom: 5,
    marginBottom: 15,
    flexBasis: "48%",
    alignItems: "flex-start",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          borderBottomWidth: 0,
          flexBasis: "100%",
          marginBottom: 10,
        };
      }
    },
  },
  customMarginTop65: {
    marginTop: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 45,
        };
      }
    },
  },

  padding1: {
    paddingTop: 8,
    paddingBottom: 0,
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingRight: 0,
          paddingLeft: 0,
        };
    },
  },
  padding2: {
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 4,
  },
  padding3: {
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
  },
  customFlexDirection: {
    flexDirection: "column",
  },
});
