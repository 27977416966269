import React from "react";
import R14, { StyleSheet, View, Text, Platform, Image } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import AccordionItemComponent from "./AccordionItemComponent";
import CommonUtility from "../../utils/CommonUtility";
import BubbleChatComponent from "./BubbleChatComponent";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import { CURRENCY } from "../../constant/app.data.constant";
import { CommonString } from "../../constant/appCommonText";
import HtmlDeCode from "../../utils/HtmlDecode";
import PaymentCheckoutComponent from "../payments/PaymentCheckoutComponent";
import { analyticsLogs } from "../../utils/AnalyticLogs";

const smsArrowL = require("../../assets/images/smsarrowl.png");
const paidMark = require("../../assets/images/tickMark.png");

export default R14.connect(
    class AccordionComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                installmentDetails: this.props.installmentDetails,
                loaderVisible: false,
                isfatched: false,
                paymentPayload: null
            }
        }

        onExpand = async (uid) => {
            this.setState({
                loaderVisible: true,
                isfatched: false
            })
            const { installmentDetails } = this.state;
            let ind = installmentDetails.findIndex((x) => x.uid === uid);
            if (ind > -1) {
                let data = [];
                if (!installmentDetails[ind].isExpanded) {
                    data = await this.props.app.dm.sepa.getSEPABillSubMessage(this.props.billDetails.uid, uid);

                    for (let ins of installmentDetails)
                        ins.isExpanded = false

                    installmentDetails[ind].isExpanded = true;
                    installmentDetails[ind].subMessages = data.filter(x => x.sepaBillDetailUid === uid);
                }
                else
                    installmentDetails[ind].isExpanded = false;

            }
            else {
                installmentDetails[ind].subMessages = [];
            }
            this.setState({
                installmentDetails,
                loaderVisible: false,
                isfatched: true
            })
        }

        downloadFile = async (payload, fileName) => {
            this.setState({
                loaderVisible: true,
            })
            let base64Format = await this.props.app.dm.sepa.getSEPAFile(payload);
            if (!!base64Format) {
                const fileObj = {
                    content: `${Platform.OS === 'web' ? 'data:application/octet-stream;base64,' : ''}${base64Format}`,
                    filename: fileName
                };
                HtmlDeCode.downloadFile(fileObj);
                analyticsLogs(CommonString.SEPA_BILL_DETAILS, `${CommonString.ATTACHMENT_DOWNLOADED}`, this.props.app);
            }
            else
                this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            this.setState({
                loaderVisible: false,
            })
        }

        onPaymentDone = async (payload) => {
            if (!!payload && payload === "close") {
                const { initiatePayment } = this.props;
                await initiatePayment(true);
                this.setState({
                    paymentPayload: {
                        isPaymentCheckout: false
                    }
                })
            }
        }

        paymentInit = async (payload) => {
            const { userDetails, initiatePayment } = this.props;
            await initiatePayment(false);
            this.setState({
                loaderVisible: true,
            })

            let currencyCode = !!payload.billDetail.currency ? payload.billDetail.currency.toLowerCase() : "eur";
            let stripePayload = {
                tokendetails: {
                    cardtoken: userDetails.uid || "",
                    payment_intent: 0,
                    merchantAccountNumber: payload.billDetail.accountNumber,
                    paymentMethodTypes: ["sepa_debit", "card"],
                    isPchClaim: false
                },
                transactiondetails: {
                    total_amount: parseFloat(payload.sepaBillDetail.billAmount),
                    currency: currencyCode,
                    purchase_level: 1,
                    transaction_date: new Date(),
                    transaction_description: `SEPA bill payment for biller [${payload.billDetail.billerName}]`,
                },
                billingaddress: {
                    billing_username: userDetails.pid,
                    user_email: userDetails.email,
                    phone_Number: userDetails.mobileNumber,
                    user_address1: userDetails.addressLine1 || "",
                    user_address2: userDetails.addressLine2 || "",
                    user_zip: userDetails.postCode || "",
                    user_city: (userDetails.cityObject && userDetails.cityObject.label ? userDetails.cityObject.label : userDetails.town || ""),
                    user_state: (userDetails.stateObject && userDetails.stateObject.label ? userDetails.stateObject.label : userDetails.state || ""),
                },
            };
            let response = await this.props.app.dm.billDetails.stripePaymentCheckout(stripePayload);
            if (!!response && !!!response.success) {
                this.setState({
                    paymentPayload: null,
                    loaderVisible: false
                })
                this.props.app.dm.commonDomain.showToastr("error", response.error);
            }
            else {
                payload.clientSecret = response.transactionId;
                payload.paymentIntentId = response.paymentIntentId;
                payload.customerId = response.customer;
                payload.loadcustomerEphemeralKeySecret = response.ephemeralKey;
                payload.amtPayable = payload.sepaBillDetail.billAmount;
                payload.installmentDetails = this.state.installmentDetails;
                this.setState({
                    paymentPayload: payload,
                    loaderVisible: false
                })
            }
            return false;
        }

        renderAccordians = (billDetails, userDetails, installmentDetails, isOneTime, isfatched, paymentPayload) => {
            let unPaidInstallment = installmentDetails.find(x => x.billStatus !== "PAID" && x.billStatus !== "Paid");
            return installmentDetails.map((item, i) => (
                <AccordionItemComponent
                    key={"Acc_item_" + i}
                    keyName={"Acc_item_" + i}
                    title={
                        <>
                            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, styles.width, commonStyles.marginRight8]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord]}>{`Instalment ${(++i)}`} </Text>
                                {(item.billStatus === "Paid" || item.billStatus === "PAID") ? <Image source={paidMark} style={[commonStyles.icon12, styles.height20, commonStyles.marginLeft10]} /> : null}
                            </View>
                            <Text style={[commonFontStyles.workSans, commonStyles.tooSmallHeading, commonStyles.fontWeight400, commonStyles.wordBreakWord, commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.alignItemsEnd, styles.width]}>{CURRENCY[billDetails.currency]}{CommonUtility.formatAmount(item.billAmount)}</Text>
                        </>
                    }
                    itemData={item}
                    billDetails={billDetails}
                    isExpanded={item.isExpanded}
                    onExpand={(uid) => this.onExpand(uid)}
                    data={!!isfatched &&
                        <BubbleChatComponent isOneTime={isOneTime} billDetails={billDetails} userDetails={userDetails} sepaBillDetail={item} displayMessages={!!item.subMessages ? item.subMessages : []} onDownloadFile={this.downloadFile} paymentInit={this.paymentInit} isPaymentCheckout={!!paymentPayload && (paymentPayload.isPaymentCheckout || false)} unPaidInstalment={!!unPaidInstallment ? unPaidInstallment.uid : ""} />
                    }
                />
            ));

        }

        componentDidMount = async () => {
            const { billDetails } = this.props;
            const { installmentDetails } = this.state;

            let isOneTime = billDetails.selectedPaymentType.indexOf('OT/') > -1;
            if (!!!isOneTime) {
                let unPaidInstallment = installmentDetails.find(x => x.billStatus !== "PAID" && x.billStatus !== "Paid");
                if (!!unPaidInstallment && !!unPaidInstallment.uid) {
                    await this.onExpand(unPaidInstallment.uid);
                    return false;
                }
            }
        }

        render() {
            const { billDetails, userDetails } = this.props;
            const { installmentDetails, loaderVisible, isfatched, paymentPayload } = this.state;
            let isOneTime = billDetails.selectedPaymentType.indexOf('OT/') > -1;
            let parentNodes = !!installmentDetails && installmentDetails[0].messages ? installmentDetails[0].messages.filter(x => !x.sepaBillDetailUid) : [];
            return (
                <View style={styles.container}>
                    {!!isOneTime ?
                        <BubbleChatComponent isOneTime={isOneTime} billDetails={billDetails} userDetails={userDetails} sepaBillDetail={installmentDetails[0]} displayMessages={!!installmentDetails[0].messages ? installmentDetails[0].messages : []} onDownloadFile={this.downloadFile} paymentInit={this.paymentInit} isPaymentCheckout={!!paymentPayload && (paymentPayload.isPaymentCheckout || false)} />
                        :
                        <View style={[!!paymentPayload && !!paymentPayload.isPaymentCheckout && commonStyles.displayNone]} >
                            <View style={styles.recieveSMS}>
                                <View style={[styles.reciverimg]}>
                                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight600, commonStyles.exSmallHeading]}>
                                        {CommonUtility.shortName(billDetails.billerName)}
                                    </Text>
                                </View>
                                <View style={[styles.messages]}>
                                    <View style={[styles.bubble]}>
                                        <View style={[styles.row]}>
                                            <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexStart, styles.width]}>Instalment Details</Text>
                                            <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.fontWeight600, commonStyles.wordBreakWord, commonStyles.dFlex, commonStyles.justifyContentFlexEnd, styles.width]}>{CURRENCY[billDetails.currency]}{CommonUtility.formatAmount(billDetails.billAmount)}</Text>
                                        </View>
                                        <View style={[styles.accordion]}>
                                            {this.renderAccordians(billDetails, userDetails, installmentDetails, isOneTime, isfatched, paymentPayload)}
                                        </View>
                                        <Image source={smsArrowL} style={[styles.guestTextBorder, commonStyles.icon15]} />

                                    </View>
                                </View>
                            </View>
                            {!!parentNodes && parentNodes.length > 0 && !!!isOneTime &&
                                <BubbleChatComponent isOneTime={isOneTime} billDetails={billDetails} userDetails={userDetails} sepaBillDetail={installmentDetails[0]} displayMessages={!!parentNodes ? parentNodes : []} onDownloadFile={this.downloadFile} paymentInit={this.paymentInit} isPaymentCheckout={!!paymentPayload && (paymentPayload.isPaymentCheckout || false)} />
                            }
                        </View>

                    }
                    <PaymentCheckoutComponent
                        isEnableIBAN={true}
                        btnText={true}
                        user={userDetails}
                        amtPayable={!!paymentPayload ? paymentPayload.amtPayable : 0.00}
                        currencyCode={billDetails.currency}
                        isPaymentCheckout={!!paymentPayload ? paymentPayload.isPaymentCheckout : false}
                        bill={paymentPayload}
                        handleCheckout={(data) => this.onPaymentDone(data)}
                        path={"payment-receipts"}
                        route={"paymentReceiptRoute"}
                        />

                    <PCHLoaderComponent isVisiable={loaderVisible} labelText={"Please wait"} />
                </View>
            )
        }
    })

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 25,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: "auto",
        marginRight: "auto",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginLeft: 0,
                    marginRight: 0,
                }
        }
    },
    width: {
        width: "50%"
    },
    recieveSMS: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        maxWidth: "100%",
    },
    reciverimg: {
        backgroundColor: "#e2f0ff",
        top: 0,
        padding: 2,
        borderRadius: "50%",
        width: 35,
        height: 35,
        maxWidth: 35,
        minWidth: 35,
        maxHeight: 35,
        textAlign: "center",
        boxShadow: "0px 3px 4px #dadada",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    messages: {
        marginBottom: 10,
        display: "grid",
        position: "relative",
        width: "100%",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    width: "90%"
                }
        }
    },
    bubble: {
        borderRadius: 10,
        maxWidth: "100%",
        padding: "10px 13px 0px 10px",
        marginBottom: 5,
        wordBreak: "break-word",
        position: "relative",
        background: "#fff",
        border: "1px solid #ded9d9",
        width: "98%",
        marginLeft: 5,
    },
    row: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    accordion: {
        background: "#ffffff",
        minWidth: 250,
        marginTop: "0.5rem",
    },
    guestTextBorder: {
        height: 0,
        width: 0,
        position: "absolute",
        top: Platform.OS !== "web" ? -1 : -2.7,
        left: -7.2,
    },
    height20: {
        height: 20,
    }
});