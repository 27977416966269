import React from "react";
import R14, { View, Text, Image, Button, StyleSheet } from "../../core";
import { GOOGLE_AUTHKEY } from "../../constant/app.constant";
import { GoogleLogin as GoogleSocialLogin } from "react-google-login";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { LOGIN_TYPE, USER_TYPE } from "../../constant/app.data.constant";
import ModalPopUp from "../../components/ModalPopUp";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { CommonString } from "../../constant/appCommonText";

//const googleIcon = require("../../assets/images/googleIcon48.png");
const googleIcon48 = require("../../assets/images/googleIcon48.png");
const googleSvgIcon = require("../../assets/images/Google__G__Logo.png");

export default R14.connect(
  class GoogleLogin extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        buttonType: this.props.buttonType,
        buttonClickable: true,
        currentRoute: "",
        tpcookiesEnabled: true,
        cookiesPopup: false,
        isClickSocialbutton: true,
      };
    }

    componentDidMount() {
      const { socialLoginType, socialLoginProfile } = this.props.app.dm.userLoginDomain.state;
      if (socialLoginType === LOGIN_TYPE.GOOGLE && !!socialLoginProfile) {
        this.setState({
          buttonClickable: false
        });
      }
      
      this.setState({
        currentRoute: this.props.r14.navigation.activeRouteName,
      });
    }
    // SECTION: Route Registration.
    routeToRegistration = (val) => {
      let returnUrl = this.props.app.route._location.search;
      this.setState({ buttonClickable: true, isClickSocialbutton: true });
      if (this.props.userType === USER_TYPE.XBP)
        this.props.app.nav.to("registration", { type: `${val.toLowerCase()}${!!returnUrl ? returnUrl : ""}` });
      else this.props.app.nav.to("pchSignup", { type: `${val.toLowerCase()}${!!returnUrl ? returnUrl : ""}` });
    };

    onSuccess = async (res) => {
      const googleObj = res.profileObj;
      this.setState({ buttonClickable: false, isClickSocialbutton:false });

      this.props.app.dm.userLoginDomain.setState({
        socialLoginProfile: res.profileObj,
        socialLoginType: LOGIN_TYPE.GOOGLE,
      });

      if (this.props.userType === USER_TYPE.XBP) {
        // XBP
        if (this.state.buttonType === "Profile") {
          const { kcId, pid, email } = this.props.profile;
          let reqBody = {
            kcId: kcId,
            pid: pid,
            email: email,
            identityProvider: LOGIN_TYPE.GOOGLE,
            socialId: res.profileObj.googleId,
            socialUserName: res.profileObj.email.toLowerCase(),
          };

          // Check same email in user detail
          let emailExist = this.props.profile;
          if (!!emailExist && emailExist.email === res.profileObj.email.toLowerCase().trim()) {
            let res = await this.props.app.dm.userRegistration.linkSocialProfile(reqBody, true);
            if (!!res && !!res.success) {
              this.props.app.dm.commonDomain.showToastr("success", CommonString.GoogleSync);
              this.setState({ buttonClickable: true, isClickSocialbutton: true });
              this.setState({ buttonType: "ProfileSynced" });
              return false;
            }
            else
            {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
              return false;
            }
          } else {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.EmailNotMatch);
            this.setState({ buttonClickable: true, isClickSocialbutton: true });
            return false;
          }
          //
        } else {
          try {
            let response = await this.props.app.dm.userSession.loginAuth({
              socialProfileId: googleObj.googleId,
              username: googleObj.email.toLowerCase().trim(),
              loginType: LOGIN_TYPE.GOOGLE,
              signInLocation: this.props.signInLocation,
            });

            if (!!response) {
              const userLoginDetail = await this.props.app.dm.rest.getUser();
              if (!!userLoginDetail) {
                if (!!!userLoginDetail.userType) {
                  this.routeToRegistration("existinggoogle");
                  return false;
                } else {
                  this.props.app.dm.commonDomain.showToastr("success", CommonString.LoggedIn);
                  this.setState({ buttonClickable: true, isClickSocialbutton: true });
                  this.props.app.dm.userLoginDomain.setState({
                    socialLoginProfile: null,
                    socialLoginType: "",
                  });
                  await this.props.redirectToReturnUrl();
                  return false;
                }
              } else this.routeToRegistration("SocialLogin");
            } else this.routeToRegistration("SocialLogin");
          } catch (err) {
            // this.routeToRegistration("SocialLogin");
            if (err.message === "socialError")
              this.routeToRegistration("SocialLogin");
            else {
              this.props.app.dm.commonDomain.showToastr("error", err.message);
            }
          }
        }
      } else {
        // PCH
        try {
          let response = await this.props.app.dm.userSession.loginAuth({
            socialProfileId: googleObj.googleId,
            username: googleObj.email.toLowerCase().trim(),
            loginType: LOGIN_TYPE.GOOGLE,
            signInLocation: this.props.signInLocation,
          });

          if (!!response) {
            const userLoginDetail = await this.props.app.dm.rest.getUser();
            if (!!userLoginDetail) {
              if (!!!userLoginDetail.userType) {
                this.routeToRegistration("existinggoogle");
                return false;
              } else {
                this.props.app.dm.commonDomain.showToastr("success", CommonString.LoggedIn);
                this.setState({ buttonClickable: true, isClickSocialbutton: true });
                this.props.app.dm.userLoginDomain.setState({
                  socialLoginProfile: null,
                  socialLoginType: "",
                });
                await this.props.redirectToReturnUrl();
                return false;
              }
            } else this.routeToRegistration("SocialLogin");
          } else this.routeToRegistration("SocialLogin");
        } catch (err) {
          // this.routeToRegistration("SocialLogin");
          if (err.message === "socialError")
            this.routeToRegistration("SocialLogin");
          else {
            this.props.app.dm.commonDomain.showToastr("error", err.message);
          }
        }
      }
    };

    onFailure = (res) => {
      console.log("Login failed:", res);
      this.setState({ buttonClickable: true, isClickSocialbutton: true });
      if (!this.state.tpcookiesEnabled) {
        this.setState({ cookiesPopup: true });
      }
    };

    checkThridPartyCookies = (res) => {
      const status_ = res.details;
      if (!!status_ && status_.includes("Cookies are not enabled in current environment")) {
        //await AsyncSessionStorage.setItem("tpcStatus", false);
        this.setState({ tpcookiesEnabled: false });
      }
    };

    onClose = async () => {
      this.setState({ cookiesPopup: false });
    };
 	
    render() {
      return (
        <View>
          <>
            {(this.props.userType === USER_TYPE.XBP) ? (
              <View style={[commonStyles.flex1, commonStyles.alignItemsCenter]}>
                <GoogleSocialLogin
                  clientId={GOOGLE_AUTHKEY}
                  buttonText=" "
                  onSuccess={this.onSuccess}
                  onFailure={this.onFailure}
                  cookiePolicy={"single_host_origin"}
                  //isSignedIn={true}
                  onScriptLoadFailure={this.checkThridPartyCookies}
                  render={(renderProps) =>
                    this.state.buttonType === "Login" ? (
                      <button
                        style={
                          this.state.buttonClickable
                            ? {
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              display: "flex",
                              width: "100%",
                              border: "1px solid rgb(218, 218, 218)",
                              minHeight: 48,
                              alignItems: "center",
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5,
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              cursor: "pointer",
                              // paddingLeft: 20,
                              justifyContent: "center",
                            }
                            : {
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              display: "flex",
                              width: "100%",
                              border: "1px solid rgb(218, 218, 218)",
                              minHeight: 48,
                              alignItems: "center",
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5,
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              cursor: "pointer",
                              pointerEvents: "none",
                              opacity: 0.5,
                              justifyContent: "center",
                            }
                        }
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}>
                        <Image source={googleSvgIcon} style={[commonStyles.icon20, commonStyles.marginRight16]} />
                        <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.whiteColor, commonStyles.socialLoginText]}>
                          {!!this.props.label ? this.props.label : "Continue with Google"}
                        </Text>
                      </button>
                    ) : this.state.buttonType === "Profile" ? (
                      <button
                        style={
                          this.state.buttonClickable && this.state.currentRoute !== "profileEditRoute"
                            ? { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer" }
                            : this.state.currentRoute === "profileEditRoute"
                              ? { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer", pointerEvents: "none" }
                              : {
                                backgroundColor: "rgba(255,255,255, 0.0)",
                                padding: 0,
                                border: 0,
                                cursor: "pointer",
                                pointerEvents: "none",
                                opacity: 0.5,
                              }
                        }
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}>
                        <Image resizeMode="contain" style={[commonStyles.icon20, commonStyles.marginRight16]} source={googleSvgIcon} />
                      </button>
                    ) : (
                      <button
                        style={{
                          backgroundColor: "rgba(255,255,255, 0.0)",
                          padding: 0,
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: "#dadada",
                          cursor: "pointer",
                          paddingTop: 4,
                          paddingRight: 8,
                          paddingBottom: 4,
                          paddingLeft: 8,
                          borderTopLeftRadius: 50,
                          borderTopRightRadius: 50,
                          borderBottomLeftRadius: 50,
                          borderBottomRightRadius: 50,
                          display: "flex",
                          alignItems: "center",
                          pointerEvents: "none",
                        }}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}>
                        <Image source={googleIcon48} style={[commonStyles.icon20, commonStyles.marginRight4]} />
                        <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.darkGray]}>Synced</Text>
                      </button>
                    )
                  }
                />
			  
                <ModalPopUp
                  visibility={this.state.cookiesPopup}
                  isFooter={false}
                  bodyWrapper={[styles.invalidReferenceModalBodyWrapper]}
                  modalOuterWrapper={styles.modalOuterWrapper}
                  heading={"Cookies are disabled"}
                  handleCancel={this.onClose}
                  closeButton={commonStyles.displayNone} //{styles.invalidReferenceModalCloseBtn}
                  modalHeading={[styles.sessionModelHeaderStyle, commonFontStyles.workSans, commonStyles.textAlignCenter, commonStyles.heading5]}
                  modalContent={commonStyles.marginTop15}>
                  <View style={commonStyles.textAlignCenter}>
                    <View style={commonStyles.marginBottom20}>
                      <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock]}>
                        Your browser has cookies disabled. Make sure that your cookies are enabled and try again.
                      </Text>
                    </View>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentCenter]}>
                      <Button
                        title={"Try Again"}
                        style={[commonStyles.blueButton, styles.sessionModelOkBtn, customCommonStyles.noBoxShadow,]}
                        onPress={this.onClose}
                        titleTextStyle={[styles.btnTexClr, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading5]}
                      />
                    </View>
                  </View>
                </ModalPopUp>
              </View>
            ) :
              (
                <>
                  <GoogleSocialLogin
                    clientId={GOOGLE_AUTHKEY}
                    buttonText=" "
                    onSuccess={this.onSuccess}
                    onFailure={this.onFailure}
                    cookiePolicy={"single_host_origin"}
                    //isSignedIn={true}
                    onScriptLoadFailure={this.checkThridPartyCookies}
                    render={(renderProps) =>
                      this.state.buttonType === "Login" ? (
                        <button
                          style={
                            this.state.buttonClickable
                              ? {
                                backgroundColor: "rgba(255,255,255, 0.0)",
                                display: "flex",
                                width: "100%",
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "#dadada",
                                minHeight: 48,
                                alignItems: "center",
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderBottomRightRadius: 5,
                                justifyContent: "center",
                                cursor: "pointer",
                                // paddingLeft: 20,
                              }
                              : {
                                backgroundColor: "rgba(255,255,255, 0.0)",
                                display: "flex",
                                width: "100%",
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "#dadada",
                                minHeight: 48,
                                alignItems: "center",
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderBottomRightRadius: 5,
                                justifyContent: "center",
                                pointerEvents: "none",
                                opacity: 0.5,
                                // paddingLeft: 20,
                              }
                          }
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}>
                          <Image resizeMode="contain" style={[commonStyles.icon20, commonStyles.marginRight16]} source={googleSvgIcon} />
                         <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.whiteColor, commonStyles.socialLoginText]}>
                            {!!this.props.label ? this.props.label : "Continue with Google"}
                          </Text>
                        </button>
                      ) : 
                        this.state.buttonType === "Profile" ? (
                          <button
                            style={
                              this.state.buttonClickable && this.state.currentRoute !== "profileEditRoute"
                                ? { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer" }
                                : this.state.currentRoute === "profileEditRoute"
                                  ? { backgroundColor: "rgba(255,255,255, 0.0)", padding: 0, border: 0, cursor: "pointer", pointerEvents: "none" }
                                  : {
                                    backgroundColor: "rgba(255,255,255, 0.0)",
                                    padding: 0,
                                    border: 0,
                                    cursor: "pointer",
                                    pointerEvents: "none",
                                    opacity: 0.5,
                                  }
                            }
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}>
                            <Image source={googleIcon48} style={commonStyles.icon20} />
                          </button>
                        ) : (
                          <button
                            style={{
                              backgroundColor: "rgba(255,255,255, 0.0)",
                              padding: 0,
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "#dadada",
                              cursor: "pointer",
                              paddingTop: 4,
                              paddingRight: 8,
                              paddingBottom: 4,
                              paddingLeft: 8,
                              borderTopLeftRadius: 50,
                              borderTopRightRadius: 50,
                              borderBottomLeftRadius: 50,
                              borderBottomRightRadius: 50,
                              display: "flex",
                              alignItems: "center",
                              pointerEvents: "none",
                            }}
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}>
                            <Image source={googleIcon48} style={[commonStyles.icon20, commonStyles.marginRight4]} />
                            <Text style={[commonFontStyles.workSans, commonStyles.smallHeading, commonStyles.darkGray]}>Synced</Text>
                          </button>
                        )
                    }
                  />
                  <ModalPopUp
                    visibility={this.state.cookiesPopup}
                    isFooter={false}
                    bodyWrapper={[styles.invalidReferenceModalBodyWrapper]}
                    modalOuterWrapper={styles.modalOuterWrapper}
                    heading={"Cookies are disabled"}
                    handleCancel={this.onClose}
                    closeButton={commonStyles.displayNone} //{styles.invalidReferenceModalCloseBtn}
                    modalHeading={[styles.sessionModelHeaderStyle, commonFontStyles.workSans, commonStyles.textAlignCenter, commonStyles.heading5]}
                    modalContent={commonStyles.marginTop15}>
                    <>
                      <View style={commonStyles.textAlignCenter}>
				  
                        <View style={commonStyles.marginBottom20}>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock]}>
                            Your browser has cookies disabled. Make sure that your cookies are enabled and try again.
                          </Text>
                        </View>
                        <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentCenter]}>
                          <Button
                            title={"Try Again"}
                            style={[commonStyles.blueButton, styles.sessionModelOkBtn, customCommonStyles.noBoxShadow,]}
                            onPress={this.onClose}
                            titleTextStyle={[styles.btnTexClr, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading5]}
                          />
                        </View>
				    
                      </View>
                    </>
                  </ModalPopUp>
                  <PCHLoaderComponent isVisiable={!this.state.buttonClickable && !this.state.isClickSocialbutton} />
                </>
              )
            }
          </>
        </View>
      )
    }
  }
);

const styles = StyleSheet.create({
  invalidReferenceModalBodyWrapper: {
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          marginTop: "auto",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        };
      }
    },
  },

  pchSocialLoginBtn: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    minHeight: 40,
    alignItems: "center",
  },
  
});
