import React from 'react';
import R14, { View, StyleSheet, Form, SafeAreaView, Button, SubmitButton, TextInputField, Text } from '../core';
import { commonStyles } from '../assets/css/commonStyle';
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import CommonValidators from '../utils/CommonValidators';
import { CommonString } from '../constant/appCommonText';
import DropDown from './DropDown';
import TableListComponent from './TableListComponent';
import RadioButtons from './RadioButtons';
import PopupModalComponent from './PopupModalComponent';
import { MESSAGE_TYPE } from '../constant/app.data.constant';
import PCHLoaderComponent from './PCH/PCHLoaderComponent';
import { analyticsLogs } from '../utils/AnalyticLogs';

const BODY_LABEL = "Body";
const HEADER_LABEL = "Header";
const PARAM_LABEL = "Param";
const JSON_LABEL = "JSON";
const SFTP_LABEL = "SFTP";
const VerificationAPI_LABEL = "VerificationAPI";
const API_TYPES = [{ label: "Exela SFTP", value: "1" }, { label: "Own SFTP", value: "2" }];
const METHOD_TYPES = [{ label: "GET", value: "1" }, { label: "POST", value: "2" }, { label: "PUT", value: "3" }, { label: "DELETE", value: "4" }];
const CONTENT_TYPES = [{ label: "application/json", value: "application/json" }, { label: "application/x-www-form-urlencoded", value: "application/x-www-form-urlencoded" }];
const ACTION_TYPES = [{ label: "Non-Replaceable", value: "1" }, { label: "Replaceable", value: "2" }, { label: "Token", value: "3" }];
const DATA_TYPES = [{ label: "String", value: "1" }, { label: "Number", value: "2" }, { label: "Date", value: "3" }, { label: "Boolean", value: "4" }, { label: "Array", value: "5" }];
const BODY_TYPES = [{ label: "Params", value: "1" }, { label: "JSON", value: "2" }];

export default R14.connect(
    class AddEditClientComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                section: 1,
                sftpType: "",
                addItem: false,
                itemType: "",
                client: this.props.client,
                apis: [],
                apiProps: [],
                loaderVisible: false,
                bodyType: "1"
            };
        }

        componentDidMount = () => {
            let form = this.props.app.ui.form("addclient");
            const { client } = this.props;
            let uid = !!client && !!client.uid ? client.uid : "";
            try {
                form.elmts.serviceAmount.setValue(client.serviceAmount || "0");
                if (!!uid) {
                    form.elmts.name.setValue(client.name);
                    form.elmts.description.setValue(client.description || "");
                    form.elmts.domainUrl.setValue(client.url || "");
                    form.elmts.clientEmail.setValue(client.email || "");
                    form.elmts.mobileNumber.setValue(client.phoneNo || "");
                }

            } catch (error) {
            }
        }

        mobileNumberText = (val) => {
            let mobNumText = val;
            let form = this.props.app.ui.form("addclient");
            if (!!val) {
                let cleaned = ("" + mobNumText).replace(/\D/g, "").substring(0, 10);
                form.elmts.mobileNumber.setValue(cleaned);
                let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    let formattedString = "";
                    if (match[1] !== undefined) {
                        match[1] = form.elmts.countryCode.value.value;
                    } else {
                        match[1] = "+1 ";
                    }
                    formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
                    form.elmts.mobileNumber.setValue(formattedString);
                }
            }
        }


        portText = (val) => {
            let mobNumText = val;
            let form = this.props.app.ui.form("addclient");
            if (!!val) {
                let cleaned = ("" + mobNumText).replace(/\D/g, "").substring(0, 4);
                form.elmts.port.setValue(cleaned);
            }
        }

        onSFTPValueChange = (val) => {
            const { value } = val;
            this.setState({
                sftpType: value
            })
            if (val === "2") {
                let form = this.props.app.ui.form("addclient");
                form.elmts.path.setError(null);
                form.elmts.username.setError(null);
                form.elmts.password.setError(null);
                form.elmts.host.setError(null);
                form.elmts.port.setError(null);
                form.elmts.port.setValue("22");
            }
            return false;
        }

        onValueChange = (val) => {
            this.setState({
                bodyType: val
            })
            let form = this.props.app.ui.form("addclient");
            form.elmts.key.setValue(null);
            form.elmts.key.setError(null);
            form.elmts.jsonBody.setError(null);
            form.elmts.jsonBody.setValue(null);
            form.elmts.value.setValue(null);
            form.elmts.value.setError(null);
            form.elmts.dataType.setValue(null);
            form.elmts.dataType.setError(null);
            form.elmts.actionType.setValue(null);
            form.elmts.actionType.setError(null);

            return false;
        }

        onAddItem = (param) => {
            if ([BODY_LABEL, HEADER_LABEL, PARAM_LABEL].includes(param)) {
                let form = this.props.app.ui.form("addclient");
                form.elmts.key.setValue(null);
                form.elmts.key.setError(null);
                form.elmts.jsonBody.setError(null);
                form.elmts.jsonBody.setValue(null);
                form.elmts.value.setValue(null);
                form.elmts.value.setError(null);
                form.elmts.dataType.setValue(null);
                form.elmts.dataType.setError(null);
                form.elmts.actionType.setValue(null);
                form.elmts.actionType.setError(null);
                this.setState({
                    addItem: true,
                    itemType: param,
                    bodyType: "1"
                })
            }
            else if (JSON_LABEL === param) {
                let form = this.props.app.ui.form("addclient");
                let { apiProps } = this.state;
                const { jsonBody = "" } = form.values;
                let hasError = false;
                if (!!jsonBody) {
                    form.elmts.jsonBody.setError(null);
                    try {
                        JSON.parse(jsonBody)
                    } catch (error) {
                        hasError = true;
                        form.elmts.jsonBody.setError("Enter Valid JSON");
                    }
                }
                else {
                    form.elmts.jsonBody.setError("Enter JSON Body");
                    hasError = true;
                }
                if (!!hasError)
                    return false;
                const item = { name: "NA", value: jsonBody, itemType: param };
                apiProps.push(item);
                this.setState({
                    apiProps: apiProps,
                })
            }
            else {
                let form = this.props.app.ui.form("addclient");
                let { itemType, apiProps } = this.state;
                const { key = "", value = "", dataType, actionType } = form.values;

                let hasError = false;
                if (!!key)
                    form.elmts.key.setError(null);
                else {
                    form.elmts.key.setError("Enter Name");
                    hasError = true;
                }
                if (!!value)
                    form.elmts.value.setError(null);
                else {
                    form.elmts.value.setError("Enter Value");
                    hasError = true;
                }
                if (!!dataType && !!dataType.value)
                    form.elmts.dataType.setError(null);
                else {
                    form.elmts.dataType.setError("Select Data Type");
                    hasError = true;
                }
                if (!!actionType && !!actionType.value)
                    form.elmts.actionType.setError(null);
                else {
                    form.elmts.actionType.setError("Select Action Type");
                    hasError = true;
                }
                if (!!hasError)
                    return false;

                const item = { name: key, value, dataType: !!dataType && dataType.label ? dataType.label : "", actionType: !!actionType && actionType.label ? actionType.label : "", itemType: itemType };
                apiProps.push(item);
                form.elmts.key.setError(null);
                form.elmts.key.setValue(null);
                form.elmts.value.setValue(null);
                form.elmts.value.setError(null);
                form.elmts.dataType.setValue(null);
                form.elmts.dataType.setError(null);
                form.elmts.actionType.setValue(null);
                form.elmts.actionType.setError(null);
                this.setState({
                    apiProps: apiProps,
                    bodyType: "1"
                })
            }
            return false;
        }

        onCancel = (log = true) => {
            if (log === true) {
                if (this.props.mode === "add")
                    analyticsLogs(CommonString.ADD_CLIENT_PAGE, CommonString.CANCEL_CLIENT, this.props.app);
                else if (this.props.mode === "view")
                    analyticsLogs(CommonString.VIEW_CLIENT_PAGE, CommonString.CANCEL_CLIENT, this.props.app);
                else
                    analyticsLogs(CommonString.UPDATE_CLIENT_PAGE, CommonString.CANCEL_CLIENT, this.props.app);
            }

            this.props.app.nav.to("clientListRoute");
            return false;
        }

        onNext = async (clientId, log = true) => {
            if (log === true) {
                if (this.props.mode === "add")
                    analyticsLogs(CommonString.ADD_CLIENT_PAGE, CommonString.NEXT_CLIENT, this.props.app);
                else if (this.props.mode === "view")
                    analyticsLogs(CommonString.VIEW_CLIENT_PAGE, CommonString.NEXT_CLIENT, this.props.app);
                else
                    analyticsLogs(CommonString.UPDATE_CLIENT_PAGE, CommonString.NEXT_CLIENT, this.props.app);
            }

            if (!!!this.state.loaderVisible)
                this.setState({ loaderVisible: true });
            let apiProps = [];
            let form = this.props.app.ui.form("addclient");
            form.elmts.name.setError(null);
            form.elmts.domainUrl.setError(null);
            form.elmts.description.setError(null);
            form.elmts.serviceAmount.setError(null);

            form.elmts.ddlSftpType.setError(null);
            form.elmts.path.setError(null);
            form.elmts.username.setError(null);
            form.elmts.password.setError(null);
            form.elmts.host.setError(null);
            form.elmts.port.setError(null);
            form.elmts.port.setValue("22");

            form.elmts.url.setError(null);
            form.elmts.methodType.setError(null);
            form.elmts.contentType.setError(null);
            form.elmts.responseProp.setError(null);

            let res = await this.props.app.dm.client.findClientAPI({ filter: clientId });
            if (!!res && res.nodes && res.nodes.length > 0) {
                let sftpDetail = res.nodes.find(x => x.apiType === SFTP_LABEL)
                if (!!sftpDetail && sftpDetail.uid) {
                    let sftpType = API_TYPES.find(x => x.value === sftpDetail.contentType);
                    if (!!sftpType) {
                        form.elmts.ddlSftpType.setValue(sftpType);
                        this.setState({ sftpType: sftpDetail.contentType })
                    }
                    if (!!sftpDetail.url)
                        form.elmts.path.setValue(sftpDetail.url);
                    if (sftpDetail.headers && sftpDetail.headers.length > 0) {
                        let username = sftpDetail.headers.find(x => x.name === "username");
                        if (!!username)
                            form.elmts.username.setValue(username.value);
                        let password = sftpDetail.headers.find(x => x.name === "password");
                        if (!!password)
                            form.elmts.password.setValue(password.value);
                        let host = sftpDetail.headers.find(x => x.name === "host");
                        if (!!host)
                            form.elmts.host.setValue(host.value);
                        let port = sftpDetail.headers.find(x => x.name === "port");
                        if (!!port)
                            form.elmts.port.setValue(port.value);
                    }
                }
                let verificationAPIDetail = res.nodes.find(x => x.apiType === VerificationAPI_LABEL);
                if (!!verificationAPIDetail && verificationAPIDetail.uid) {
                    if (!!verificationAPIDetail.url)
                        form.elmts.url.setValue(verificationAPIDetail.url);
                    if (!!verificationAPIDetail.methodType) {
                        let method = METHOD_TYPES.find(x => x.label === verificationAPIDetail.methodType);
                        form.elmts.methodType.setValue(method);
                    }
                    if (!!verificationAPIDetail.contentType) {
                        let method = CONTENT_TYPES.find(x => x.label === verificationAPIDetail.contentType);
                        form.elmts.contentType.setValue(method);
                    }
                    if (!!verificationAPIDetail.responseProp)
                        form.elmts.responseProp.setValue(verificationAPIDetail.responseProp);

                    apiProps = [].concat(...[verificationAPIDetail.headers.map((x) => { return { ...x, ...{ itemType: HEADER_LABEL } } })], ...[verificationAPIDetail.bodyParameters.map((x) => { return { ...x, ...{ itemType: BODY_LABEL } } })], ...[verificationAPIDetail.queryStrings.map((x) => { return { ...x, ...{ itemType: PARAM_LABEL } } })]);
                    if (!!verificationAPIDetail.dataBody)
                        apiProps.push({ value: verificationAPIDetail.dataBody, itemType: JSON_LABEL, name: "NA" })
                }
            }
            this.setState({
                apis: res.nodes,
                addItem: false,
                apiProps: apiProps,
                itemType: "",
                section: this.state.section + 1,
                loaderVisible: false
            })
            return false;
        }

        onActionClick = async (item) => {
            if (this.props.mode === "add")
                analyticsLogs(CommonString.ADD_CLIENT_PAGE, CommonString.DELETE_CLIENT_CONFIG, this.props.app);
            else
                analyticsLogs(CommonString.UPDATE_CLIENT_PAGE, CommonString.DELETE_CLIENT_CONFIG, this.props.app);
            this.setState({
                selectItem: item,
                isVisible: true
            })
        }

        handleSubmit = async (form) => {
            try {
                const { name = "", description = "", domainUrl = "", clientEmail = "", mobileNumber = "", serviceAmount = 0, url = "", methodType = "", contentType = "", responseProp = "", username = "", path = "", port = "22", host = "", password = "", ddlSftpType } = form.values;
                const { section, client, apiProps, apis, sftpType } = this.state;
                if (section === 1) { //add or update client
                    this.setState({ loaderVisible: true });
                    let uid = !!client && !!client.uid ? client.uid : "";
                    let res = await this.props.app.dm.client.addUpdateClient({
                        uid, name, description, email: clientEmail, phoneNo: mobileNumber, url: domainUrl, serviceAmount: parseFloat(serviceAmount)
                    });

                    if (!!res && !!res.success && !!res.client.uid) {
                        this.setState({
                            client: res.client
                        })
                        await this.onNext(res.client.uid, false);
                        if (this.props.mode === "add")
                            analyticsLogs(CommonString.ADD_CLIENT_PAGE, CommonString.ADD_NEW_CLIENT, this.props.app);
                        else
                            analyticsLogs(CommonString.UPDATE_CLIENT_PAGE, CommonString.UPDATE_CLIENT, this.props.app);

                        this.props.app.dm.commonDomain.showToastr("success", `Client ${this.props.mode === "add" ? "added" : "updated"} successfully.`);
                        return false;
                    }
                    else if (!!res && !!res.success && !res.client.uid) {
                        this.setState({ loaderVisible: false });
                        form.elmts.name.setError("Client name already exists")
                        return false;
                    }
                    else {
                        this.setState({ loaderVisible: false });
                        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
                        return false;
                    }
                }
                else if (section > 1) { //add or update configuration
                    let hasError = false;
                    if (!!ddlSftpType && !!ddlSftpType.value) {
                        form.elmts.ddlSftpType.setError(null);
                    }
                    else {
                        form.elmts.ddlSftpType.setError("Select SFTP Type");
                        hasError = true;
                    }
                    if (!!ddlSftpType && ddlSftpType.value === "2") {
                        if (!!path)
                            form.elmts.path.setError(null);
                        else {
                            form.elmts.path.setError("Enter Path");
                            hasError = true;
                        }
                        if (!!username)
                            form.elmts.username.setError(null);
                        else {
                            form.elmts.username.setError("Enter UserName");
                            hasError = true;
                        }
                        if (!!password)
                            form.elmts.password.setError(null);
                        else {
                            form.elmts.password.setError("Enter Password");
                            hasError = true;
                        }
                        if (!!host)
                            form.elmts.host.setError(null);
                        else {
                            form.elmts.host.setError("Enter Host");
                            hasError = true;
                        }
                        if (!!port)
                            form.elmts.port.setError(null);
                        else {
                            form.elmts.port.setError("Enter Port");
                            hasError = true;
                        }
                    }

                    if (!!url)
                        form.elmts.url.setError(null);
                    else {
                        form.elmts.url.setError("Enter URL");
                        hasError = true;
                    }
                    if (!!methodType && !!methodType.value)
                        form.elmts.methodType.setError(null);
                    else {
                        form.elmts.methodType.setError("Select Method Type");
                        hasError = true;
                    }
                    if (!!contentType && !!contentType.value)
                        form.elmts.contentType.setError(null);
                    else {
                        form.elmts.contentType.setError("Select Content Type");
                        hasError = true;
                    }
                    if (!!responseProp)
                        form.elmts.responseProp.setError(null);
                    else {
                        form.elmts.responseProp.setError("Enter Response Property");
                        hasError = true;
                    }

                    if (!!hasError) return false;
                    this.setState({ loaderVisible: true });
                    let clientUid = !!client && !!client.uid ? client.uid : "";
                    let sftpUid = "";
                    let verificationAPIUid = "";
                    let headers = apiProps.filter(x => x.itemType === HEADER_LABEL);
                    let bodyParameters = apiProps.filter(x => x.itemType === BODY_LABEL);
                    let queryStrings = apiProps.filter(x => x.itemType === PARAM_LABEL);
                    let dataBody = apiProps.find(x => x.itemType === JSON_LABEL);
                    headers.forEach(function (v) { delete v.itemType; });
                    queryStrings.forEach(function (v) { delete v.itemType; });
                    bodyParameters.forEach(function (v) { delete v.itemType; });

                    if (!!apis && apis.length > 0) {
                        let sftpDetail = apis.find(x => x.apiType === SFTP_LABEL);
                        if (!!sftpDetail && !!sftpDetail.uid) sftpUid = sftpDetail.uid;
                        sftpDetail = apis.find(x => x.apiType === VerificationAPI_LABEL);
                        if (!!sftpDetail && !!sftpDetail.uid) verificationAPIUid = sftpDetail.uid;
                    }
                    let sftpHeaders = [];

                    if (sftpType === "2") {
                        sftpHeaders = [{ name: "host", value: host }, { name: "username", value: username }, { name: "password", value: password }, { name: "port", value: port }]
                    }

                    let res = await this.props.app.dm.client.addUpdateClientAPI([
                        {
                            uid: sftpUid, clientUid, apiType: SFTP_LABEL, url: path, methodType: "", contentType: !!ddlSftpType && ddlSftpType.value ? ddlSftpType.value : "", headers: sftpHeaders, bodyParameters: [], queryStrings: [], dataBody: "", responseProp: ""
                        },
                        {
                            uid: verificationAPIUid, clientUid, apiType: VerificationAPI_LABEL, url, methodType: !!methodType && methodType.label ? methodType.label : "", contentType: !!contentType && contentType.label ? contentType.label : "", headers: headers, bodyParameters: bodyParameters, queryStrings: queryStrings, dataBody: !!dataBody && dataBody.value ? dataBody.value : "", responseProp
                        }]);
                    if (!!res && !!res.success) {
                        this.setState({
                            loaderVisible: false,
                            addItem: false,
                            apiProps: [],
                            itemType: "",
                            bodyType: "1"
                        })
                        if (this.props.mode === "add")
                            analyticsLogs(CommonString.ADD_CLIENT_PAGE, CommonString.ADD_NEW_CLIENT_CONFIG, this.props.app);
                        else
                            analyticsLogs(CommonString.UPDATE_CLIENT_PAGE, CommonString.UPDATE_CLIENT_CONFIG, this.props.app);
                        this.props.app.dm.commonDomain.showToastr("success", `${this.props.mode === "add" ? "Client configured successfully" : "Configuration data updated successfully"}`);
                        this.onCancel(false);
                    }
                    else {
                        this.setState({ loaderVisible: false });
                        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
                    }
                }

            } catch (error) {
                this.setState({ loaderVisible: false });
                this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
            return false;
        }

        onDeleteConfirm = async () => {

            const { selectItem, apiProps } = this.state;
            if (!!selectItem) {
                let i = apiProps.findIndex(x => x.name === selectItem.name && x.itemType === selectItem.itemType);
                if (i > -1) {
                    apiProps.splice(i, 1);
                    this.setState({
                        apiProps: apiProps
                    })
                    this.onDeleteCancel();
                }
                return false;
            }
            return false;
        }

        onDeleteCancel = async () => {
            this.setState({
                selectItem: null,
                isVisible: false
            })
        }

        createSFTPFormElements(section, sftpType) {
            return (
                <View style={[styles.containerStyle, commonStyles.marginBottom10, commonStyles.paddingBottom0, section > 1 ? null : commonStyles.displayNone]}>
                    <View style={[styles.legendLabel]}>
                        <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight500]}>SFTP Details</Text>
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.marginTop10]}>
                        <View style={[commonStyles.flexBasis50]}>
                            <DropDown
                                name="ddlSftpType"
                                label="SFTP Type*"
                                onValueChange={this.onSFTPValueChange}
                                items={API_TYPES}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                            />
                        </View>
                        <View style={[commonStyles.flexBasis50, styles.marginLeft10, sftpType === "2" ? null : commonStyles.displayNone]}>
                            <TextInputField
                                name="path"
                                label={"Path*"}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection, sftpType === "2" ? null : commonStyles.displayNone]}>
                        <View style={[commonStyles.flexBasis50]}>
                            <TextInputField
                                name="username"
                                label={"User Name*"}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                        <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                            <TextInputField
                                name="password"
                                label={"Password*"}
                                secure={true}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                    </View>

                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection, sftpType === "2" ? null : commonStyles.displayNone]}>
                        <View style={[commonStyles.flexBasis50]}>
                            <TextInputField
                                name="host"
                                label={"Host*"}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                        <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                            <TextInputField
                                name="port"
                                label={"Port*"}
                                value="22"
                                onChangeText={this.portText}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                    </View>
                </View>
            )
        };

        createClientFormElements(section) {
            return (
                <View style={[styles.containerStyle, commonStyles.marginBottom10, commonStyles.paddingBottom0, section > 1 ? commonStyles.displayNone : null]}>
                    <View style={[styles.legendLabel]}>
                        <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight500]}>Client Details</Text>
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.marginTop10]}>
                        <View style={[commonStyles.flexBasis50, section > 1 ? customCommonStyles.disabledInput : null]}>
                            <TextInputField
                                name="name"
                                label={"Client Name*"}
                                required={"Enter Client Name"}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                validator="min2Max50"
                                disabled={section > 1}
                                readOnly={section > 1}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                        <View style={[commonStyles.flexBasis50, styles.marginLeft10, section > 1 ? customCommonStyles.disabledInput : null]}>
                            <TextInputField
                                name="description"
                                label="Description"
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                validator="min2Max50"
                                disabled={section > 1}
                                readOnly={section > 1}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                        <View style={[commonStyles.flexBasis50]}>
                            <TextInputField
                                name="mobileNumber"
                                label="Mobile Number"
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                onChangeText={this.mobileNumberText}
                                helperTextStyle={[commonStyles.helperTextFontStyle, commonFontStyles.workSans, customCommonStyles.mobileNumErrorTest]}
                            />
                        </View>
                        <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                            <TextInputField
                                name="clientEmail"
                                label={CommonString.EmailLabel}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                validator="validateEmail"
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                        <View style={[commonStyles.flexBasis50]}>
                            <TextInputField
                                name="serviceAmount"
                                label={"Amount"}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                validator="max10Decimal"
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                        <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                            <TextInputField
                                name="domainUrl"
                                label={"Domain Url"}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>
                    </View>
                </View>
            )
        }

        createVerificationAPIFormElements(section, addItem, bodyType, itemType, apiProps, loaderVisible, mode) {
            let labelKeys = ["Type", "Name", "Value", "Data Type", "Action Type", "Action"];
            let labelValues = ["itemType", "name", "value", "dataType", "actionType", "delete"];
            if (mode === "view") {
                labelKeys.pop()
                labelValues.pop();
            }
            return (
                <View style={[styles.containerStyle, commonStyles.marginBottom10, commonStyles.paddingBottom0, section > 1 ? null : commonStyles.displayNone]}>
                    <View style={[styles.legendLabel]}>
                        <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight500]}>Verification API Details</Text>
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.marginTop10]}>
                        <View style={[commonStyles.flexBasis50]}>
                            <DropDown
                                name="methodType"
                                label="Method Type*"
                                items={METHOD_TYPES}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                            />
                        </View>
                        <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                            <DropDown
                                name="contentType"
                                label="Content Type*"
                                items={CONTENT_TYPES}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                            />
                        </View>
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                        <View style={[commonStyles.flexBasis50]}>
                            <TextInputField
                                name="url"
                                label={"Url*"}
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                            />
                        </View>

                        <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                            <TextInputField
                                name="responseProp"
                                label="Response Property*"
                                style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                            />
                        </View>
                    </View>
                    {mode === "view" && (!apiProps || apiProps.length === 0) ? null :
                        <View style={[styles.containerStyle, commonStyles.marginBottom10]}>
                            <View style={[styles.legendLabel]}>
                                <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight500]}>API Paramters</Text>
                            </View>
                            {mode === "view" ? null :
                                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.customJustifyContentSpaceBetween]}>
                                    <Button
                                        key="addHeader"
                                        title="Add Header"
                                        onPress={() => this.onAddItem(HEADER_LABEL)}
                                        style={[commonStyles.blueBorderButton, commonStyles.secondayWhiteBtn, styles.submitBtn, itemType === HEADER_LABEL ? styles.activeTab : null]}
                                        titleTextStyle={[commonStyles.secondayWhiteBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tooSmallHeading, styles.tabTextColor, itemType === HEADER_LABEL ? styles.activeTabText : null]}
                                    />
                                    <Button
                                        key="addBody"
                                        title="Add Body"
                                        onPress={() => this.onAddItem(BODY_LABEL)}
                                        style={[commonStyles.blueBorderButton, commonStyles.secondayWhiteBtn, styles.submitBtn, styles.marginLeft10, itemType === BODY_LABEL ? styles.activeTab : null]}
                                        titleTextStyle={[commonStyles.secondayWhiteBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tooSmallHeading, styles.tabTextColor, itemType === BODY_LABEL ? styles.activeTabText : null]}
                                    />
                                    <Button
                                        key="addParams"
                                        title="Add Param"
                                        onPress={() => this.onAddItem(PARAM_LABEL)}
                                        style={[commonStyles.blueBorderButton, commonStyles.secondayWhiteBtn, styles.submitBtn, styles.marginLeft10, itemType === PARAM_LABEL ? styles.activeTab : null]}
                                        titleTextStyle={[commonStyles.secondayWhiteBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.tooSmallHeading, styles.tabTextColor, itemType === PARAM_LABEL ? styles.activeTabText : null]}
                                    />
                                </View>
                            }
                            <View style={[itemType === BODY_LABEL ? commonStyles.marginTop5 : commonStyles.marginTop15]}>
                                {itemType === BODY_LABEL ?
                                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.marginBottom10]}>
                                        {BODY_TYPES.map((el, key) => (
                                            <View key={"bodyItems_" + key} style={[commonStyles.marginRight16]}>
                                                <RadioButtons radioButtonsClass={styles.minHeight27} key={key} options={el} onValueChange={this.onValueChange} isSelected={bodyType} />
                                            </View>))}
                                    </View> : null}
                                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, bodyType === "1" && !!addItem ? null : commonStyles.displayNone]}>
                                    <View style={[commonStyles.flexBasis50]}>
                                        <TextInputField
                                            name="key"
                                            label={"Name*"}
                                            style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                            validator="min2Max50"
                                            helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                        />
                                    </View>
                                    <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                                        <TextInputField
                                            name="value"
                                            label={"Value*"}
                                            style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                            validator="min2Max50"
                                            helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                        />
                                    </View>
                                    <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                                        <DropDown
                                            name="dataType"
                                            label="Data Type*"
                                            items={DATA_TYPES}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                                            helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                            style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                        />
                                    </View>
                                    <View style={[commonStyles.flexBasis50, styles.marginLeft10]}>
                                        <DropDown
                                            name="actionType"
                                            label="Action Type*"
                                            items={ACTION_TYPES}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6,]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,]}
                                            helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                            style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                                        />
                                    </View>
                                    <View style={[styles.addItemBtn, styles.marginLeft10]}>
                                        <Button
                                            key="add"
                                            title="Add"
                                            onPress={() => this.onAddItem("AddItem")}
                                            style={[commonStyles.blueButton, commonStyles.primaryButton, styles.addItemBtn]}
                                            titleTextStyle={[commonStyles.primaryBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                                        />
                                    </View>
                                </View>
                                <View style={[commonStyles.flex1, commonStyles.customFlexDirection, bodyType === "1" ? commonStyles.displayNone : null]}>
                                    <View style={[commonStyles.flexBasis100]}>
                                        <TextInputField
                                            name="jsonBody"
                                            label={"Json Body*"}
                                            multiline
                                            style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight, styles.commentsField]}
                                            labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                            labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                            helperTextStyle={[customCommonStyles.helperTextStylecustom, commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                                        />
                                    </View>
                                    <View style={[styles.addItemBtn, commonStyles.paddingTop5, styles.marginLeft10]}>
                                        <Button
                                            key="addJson"
                                            title="Add"
                                            onPress={() => this.onAddItem(JSON_LABEL)}
                                            style={[commonStyles.blueButton, commonStyles.primaryButton, styles.addItemBtn]}
                                            titleTextStyle={[commonStyles.primaryBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                                        />
                                    </View>
                                </View>
                                {!!apiProps && apiProps.length > 0 ?
                                    <TableListComponent
                                        dataList={apiProps}
                                        pagination={false}
                                        theadStyle={styles.padding7}
                                        containerStyle={commonStyles.marginBottom5}
                                        tableStyle={[commonStyles.marLT0, commonStyles.marRT0]}
                                        listingStyle={[commonStyles.marB0]}
                                        height={[styles.maxHeight250, !apiProps || !apiProps.length ? styles.customMarginLeftRight16 : null, this.props.customHeights]}
                                        labelKeys={labelKeys}
                                        labelValues={labelValues}
                                        visibility={!loaderVisible}
                                        textOnly={false}
                                        onActionClick={(item, act) => this.onActionClick(item, act)}
                                        message={"No records found."}
                                    /> : null}
                            </View>
                        </View>}
                </View>)
        }

        render() {
            const { addItem, itemType, section, apiProps, loaderVisible, bodyType, isVisible, sftpType, client } = this.state;
            const { mode } = this.props;
            return (
                <SafeAreaView style={[styles.screenLayout]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.customPadding]}>
                        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                            <View style={[commonStyles.flex1, commonStyles.flexDirectionColumn, commonStyles.alignItemsCenter]}>
                                <View style={[commonStyles.marginBottom5]}>
                                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight500]}>Client</Text>
                                </View>
                                <View style={[styles.circle, section > 1 ? null : styles.activeCircle]}>
                                    <View style={[section > 1 ? styles.inActive : styles.active]}></View>
                                </View>
                            </View>
                            <View style={[styles.borderTopStyle]}></View>
                        </View>
                        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter]}>
                            <View style={[styles.borderTopStyle]}></View>
                            <View style={[commonStyles.flex1, commonStyles.flexDirectionColumn, commonStyles.alignItemsCenter]}>
                                <View style={[commonStyles.marginBottom5]}>
                                    <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight500]}>Configuation</Text>
                                </View>
                                <View style={[styles.circle, styles.marginRight30Min, section > 1 ? styles.activeCircle : null]}>
                                    <View style={[section > 1 ? styles.active : styles.inActive]}></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <Form
                        name="addclient"
                        style={[commonStyles.flex1, commonStyles.flexDirectionColumn]}
                        onSubmit={this.handleSubmit}
                        validateBeforeSubmit={true}
                        validators={CommonValidators.getFormValidator()}>
                        {/* CLIENT SECTION */}
                        {this.createClientFormElements(section)}
                        {/* SFTP SECTION */}
                        {this.createSFTPFormElements(section, sftpType)}
                        {/* VERIFICATION API SECTION */}
                        {this.createVerificationAPIFormElements(section, addItem, bodyType, itemType, apiProps, loaderVisible, mode)}

                        <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.btnHolders, styles.customFlexProperties]}>
                            <Button
                                key="Cancel"
                                title="Cancel"
                                onPress={() => this.onCancel(true)}
                                style={[commonStyles.blueBorderButton, commonStyles.secondayWhiteBtn, styles.submitBtn]}
                                titleTextStyle={[commonStyles.secondayWhiteBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                            />
                            {(!!mode && ["edit", "view"].includes(mode.toLowerCase()) && section <= 1) ?
                                <Button
                                    key="next"
                                    title="Next"
                                    onPress={() => this.onNext(client.uid, true)}
                                    style={[commonStyles.blueBorderButton, commonStyles.secondayWhiteBtn, styles.submitBtn, commonStyles.marginLeft10]}
                                    titleTextStyle={[commonStyles.secondayWhiteBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                                />
                                : null}
                            {mode === "view" ? null :
                                <SubmitButton
                                    key="submitBtton"
                                    title={"Submit"}
                                    style={[commonStyles.blueButton, commonStyles.primaryButton, styles.submitBtn, commonStyles.marginLeft10]}
                                    titleTextStyle={[commonStyles.primaryBtnText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.exSmallHeading]}
                                />
                            }
                        </View>
                    </Form>
                    <PopupModalComponent
                        visibility={!!isVisible}
                        bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.modalBodyWrapper]}
                        messageType={MESSAGE_TYPE.info}
                        headingIcon={true}
                        heading={"Delete Configuration Data"}
                        modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
                        bodyContent={`Do you want to delete?`}
                        okLabel={"Yes"}
                        handleOk={this.onDeleteConfirm}
                        modalPopupOkBtn={[commonStyles.width76]}
                        closeLabel={"No"}
                        handleClose={this.onDeleteCancel}
                        modalPopupCancelBtn={[commonStyles.width76]}
                        modalHeading={[commonStyles.nativeModalHeading]}
                    />
                    <PCHLoaderComponent isVisiable={loaderVisible} labelText={"Please wait"} />
                </SafeAreaView>
            );
        }
    }
);
const styles = StyleSheet.create({
    screenLayout: {
        backgroundColor: "#fff",
        marginTop: 0,
        marginRight: 16,
        marginBottom: 0,
        marginLeft: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    marginTop: 0,
                    marginRight: 0,
                    marginLeft: 0,
                    marginBottom: 70,
                    borderColor: "#fff",
                };
        }
    },
    marginLeft10: {
        marginLeft: 10,
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    marginLeft: 0,
                };
        }
    },
    marginRight10: {
        marginRight: 10,
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    marginRight: 0,
                };
        }
    },
    btnHolders: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexBasis: "48%",
                };
            }
        },
    },
    customFlexProperties: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 10,
                    marginBottom: 5,
                    justifyContent: "flex-end",
                };
            }
            if (width >= 991) {
                return {
                    justifyContent: "flex-end",
                };
            }
        },
    },
    submitBtn: {
        minWidth: 108,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minWidth: 99,
                    maxWidth: 99,
                };
            }
        }
    },
    addItemBtn: {
        minWidth: 108,
        maxWidth: 108,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minWidth: 99,
                    maxWidth: 99,
                };
            }
        }
    },
    tabTextColor: {
        color: "#585858",
    },
    customJustifyContentSpaceBetween: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    justifyContent: "space-between"
                }
            }
        }
    },
    borderStyle: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#dadada",
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    paddingBottom: 15
                };
            }
        }
    },
    containerStyle: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#dadada",
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        position: 'relative',
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    paddingTop: 10,
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingBottom: 5
                };
            }
        }
    },
    circle: {
        minWidth: 25,
        maxWidth: 25,
        maxHeight: 25,
        minHeight: 25,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#e7e7e7",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e7e7e7",
    },
    activeCircle: {
        borderColor: "#1b63b5",
    },
    inActive: {
        backgroundColor: "#cccccc",
        minWidth: 10,
        maxWidth: 10,
        maxHeight: 10,
        minHeight: 10,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    active: {
        backgroundColor: "#1b63b5",
        minWidth: 10,
        maxWidth: 10,
        maxHeight: 10,
        minHeight: 10,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    borderTopStyle: {
        width: "100%",
        borderStyle: "solid",
        borderTopWidth: 1,
        borderColor: "#dadada",
        marginTop: 25,
        marginLeft: -5,
        marginRight: -35
    },
    marginRight30Min: {
        marginRight: -30
    },
    customPadding: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10
    },
    commentsField: {
        minHeight: 80,
        maxHeight: 80,
        resize: "none",
        lineHeight: 15,
        paddingTop: 5,
    },
    activeTab: {
        backgroundColor: "#1277d9",
    },
    activeTabText: {
        color: "#fff"
    },
    legendLabel: {
        position: 'absolute',
        top: -11,
        left: 8,
        backgroundColor: 'white',
        paddingLeft: 5,
        paddingRight: 5
    },
    padding7: {
        paddingLeft: 7,
        paddingRight: 7
    }
});