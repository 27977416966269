import React, { Component } from "react";
import R14, { StyleSheet, SubmitButton, View, Text, Form, Platform, Button } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { DECLINERADIO_OPT, MSG_TYPE, BILL_STATUS, MESSAGE_TYPE } from "../constant/app.data.constant";
import RadioButtons from "./RadioButtons";
import BillerDetails from "./BillerDetails";
import BottomPaySection from "./BottomPaySection";
import CommonValidators from "../utils/CommonValidators";
import PopupModalComponent from "./PopupModalComponent";
import { CommonString } from "../constant/appCommonText";
import { analyticsLogs } from "../utils/AnalyticLogs";
import ChatboxInputField from "./ChatboxInputField";

export default R14.connect(
  class DeclineComponent extends Component {
    constructor(props) {
      super(props);

      this.form = null;
      this.state = {
        selectedOpt: null,
        isLoading: false,
        isVisible: false,
        isDisabled: false
      };
      this.fileAttach = null;
    }

    componentDidMount() {
      this.form = this.props.app.ui.form("declineBill");
    }

    onValueChange = (val) => {
      analyticsLogs(CommonString.DECLINE_BILL, `${CommonString.CLICKED_ON} ${val}`, this.props.app);
      this.setState({
        selectedOpt: val,
      });
      // this.form.elmts.comments.setValue(val);
    };

    onAttachment = async (val) => {
      if(!!val) analyticsLogs(CommonString.DECLINE_BILL, `${CommonString.ATTACHMENT_ADDED}`, this.props.app);
      else analyticsLogs(CommonString.DECLINE_BILL, `${CommonString.ATTACHMENT_REMOVED}`, this.props.app);
      this.fileAttach = val;
    };

    onSubmit = async (form) => {
      analyticsLogs(CommonString.DECLINE_BILL, `${CommonString.OPEN_DECLINE_CONFIRMATION}`, this.props.app);
      this.setState({ isVisible: true });
      return false;
    };

    onCancel = () => {
      // this.setState({ isVisible: false, selectedOpt: null });
      // this.form.elmts.comments.setValue("");
      const { billData } = this.props;
      analyticsLogs(CommonString.DECLINE_BILL, `${CommonString.CLICKED_ON_CANCEL_BUTTON}`, this.props.app);
      this.props.app.nav.to("billDetailsRoute", { threadId: billData.threadId });
    };

    onConfirmationCancel = () => {
      analyticsLogs(CommonString.DECLINE_BILL, `${CommonString.CLOSED_DECLINE_CONFIRMATION}`, this.props.app);
      this.setState({
        isDisabled: false,
        isVisible: false,
      });
    };
  
    onOkPress = async () => {
      this.setState({
        isDisabled: true,
        isVisible: false
      });
      const { selectedOpt } = this.state;
      const { billData } = this.props;
      const { comments } = this.form.values;
      let newThreadId = billData.threadId; //.replaceAll("#", ">>");
      let leaf = {
        ...billData,
        additionalInformation: !!comments ? comments : selectedOpt,
        messageType: MSG_TYPE.DECLINE,
        // note: `Declined: ${!!comments ? comments : selectedOpt}`,
        attachments: this.fileAttach ? [this.fileAttach] : null,
      };
      let payload = await this.props.app.dm.billDetails.getDeclinedPayload(leaf);
      try {
        let res = await this.props.app.dm.billDetails.postMessageForPayment(payload);
        if (!!res && res.threadId) {
          analyticsLogs(CommonString.DECLINE_BILL, `${CommonString.BILL_DECLINED_SUCCESSFULLY}`, this.props.app);
          this.props.app.dm.commonDomain.showToastr("error", CommonString.DeclinedBill);
          let decPayload = {
            uid: billData.uid,
            billStatus: "Declined",
            bookmark: false,
            fullPaidAndDeclined: new Date().toJSON(),
            isFailedPayment: false,
            failDate: null,
            rtpMessageType: MSG_TYPE.DECLINE,
            paymentStatus:"Declined"
          };
          let decBill = await this.props.app.dm.dashboard.updateBill(decPayload);
          if (decBill) {
            const { compiledMsgList, filterBills } = this.props.app.dm.dashboard.state;
            if (compiledMsgList) {
              const ind = compiledMsgList.findIndex((el) => el.uid === billData.uid);
              if (compiledMsgList[ind]) {
                compiledMsgList[ind].billStatus = decPayload.billStatus;
                compiledMsgList[ind].paymentStatus = decPayload.paymentStatus;
                compiledMsgList[ind].isFailedPayment = decPayload.isFailedPayment;
                compiledMsgList[ind].bookmark = decPayload.bookmark;
                compiledMsgList[ind].fullPaidAndDeclined = decPayload.fullPaidAndDeclined;
                compiledMsgList[ind].rtpMessageType = decPayload.rtpMessageType;
              }
              const flIndx = filterBills.findIndex((el) => el.uid === billData.uid);
              if (filterBills[flIndx]) {
                filterBills[flIndx].billStatus = decPayload.billStatus;
                filterBills[flIndx].paymentStatus = decPayload.paymentStatus;
                filterBills[flIndx].isFailedPayment = decPayload.isFailedPayment;
                filterBills[flIndx].bookmark = decPayload.bookmark;
                filterBills[flIndx].fullPaidAndDeclined = decPayload.fullPaidAndDeclined;
                filterBills[flIndx].rtpMessageType = decPayload.rtpMessageType;
                }
              await this.props.app.dm.dashboard.updateBillCount(compiledMsgList, filterBills);
            }

            let newBill = {
              ...billData,
              ...decPayload,
            };
            this.props.app.dm.billDetails.setState({
              billDetails: newBill
            });
            
            if (!!res && res.threadId) {
              setTimeout(() => {
                this.props.app.nav.to("billReceiptRoute", { threadId: newThreadId });
              }, 100);
            }
          }
        } else {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        }
      } catch (error) {
        console.log("Declined Error: ", error);
      } finally {
        // this.setState({ isVisible: false });
      }
    };

    readText = (val) => {
      if ((!val && !!this.form) || val.length <= 140) {
        this.form.elmts.comments.setError(null);
      } else if (val.length > 140) {
        this.form.elmts.comments.setValue(val.substring(0, 140));
        this.form.elmts.comments.setError(CommonString.ContentExceed140);
      }
    };

    render() {
      const { selectedOpt, isLoading, isVisible } = this.state;
      const { billData, payType } = this.props;
      return (
            <View style={styles.declineScreenContainer}>
              <BillerDetails customStyles={commonStyles.padding0} billData={billData} customFlexDirection={styles.customFlexDirection} />
              <View style={[commonStyles.webView, customCommonStyles.customMarginTopAuto]}>
                <BottomPaySection
                  threadId={billData.threadId}
                  billerDetails={billData.biller}
                  activePage={payType}
                  isDisabled={BILL_STATUS[billData.billStatus]}
                  extraOpt={billData.rtpMessageType}
                  billDetails = {billData}
                />
              </View>
              <View>
                <View style={[styles.commentsAttachment]}>
                  <View style={[commonStyles.dFlex, commonStyles.flexDirectionColumn]}></View>
                </View>
              </View>
              <View style={[styles.BottomPaySection, commonStyles.mView]}>
                <BottomPaySection
                  threadId={billData.threadId}
                  billerDetails={billData.biller}
                  activePage={payType}
                  isDisabled={BILL_STATUS[billData.billStatus]}
                  extraOpt={billData.rtpMessageType}
                  billDetails = {billData}
                />
              </View>
                  <Form
                    name="declineBill"
                    style={[commonStyles.paddingLeft0, commonStyles.paddingRight0, commonStyles.paddingTop0, commonStyles.flexGrow0, styles.declineModalForm]}
                    onSubmit={this.onSubmit}
                    validateBeforeSubmit={true}
                    validators={CommonValidators.getFormValidator()}>
                      
                      <View style={[commonStyles.marginBottom5]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.darkGrayColor]}>Decline reason</Text>
                      </View>
                        {DECLINERADIO_OPT.map((el, key) => (
                          <RadioButtons
                            key={key}
                            options={el}
                            onValueChange={this.onValueChange}
                            isSelected={selectedOpt}
                          />
                        ))}
                  {selectedOpt === "Other" ? (
                      <View style={[customCommonStyles.declineModalWrapperMsgComp]}>
                        <ChatboxInputField
                          onValueChange={this.onAttachment}
                          onChangeText={this.readText}
                          commentsProps={{
                            name:"comments",
                            required: selectedOpt === "Other" ? "Enter the reason" : "Enter the comments",
                            label: selectedOpt === "Other" ? "Other Reason*" : "Enter Message*",
                          }}
                          textBoxStyle={[commonStyles.smallTextBoxStyle]}
                          attachmentBoxStyle={[commonStyles.smallAttachmentBoxStyle]}
                          sendBtnBoxStyle={[commonStyles.smallSendBtnBoxStyle]}
                        />
                      </View>
                    ) : null} 
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.declinedBtnWrap]}>
                      <Button
                        title={"Cancel"}
                        style={[commonStyles.blueBorderButton, commonStyles.marginRight8, styles.cancelBtn, Platform.OS === 'ios' ? styles.halfWidth : styles.declinedBtn]}
                        onPress={this.onCancel}
                        titleTextStyle={[
                          commonFontStyles.workSans,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonStyles.exSmallHeading,
                          commonStyles.transperentBtnColor,
                        ]}
                      />
                      <SubmitButton
                        title="Decline"
                        key="decline"
                        style={[commonStyles.blueButton, Platform.OS === 'ios' ? styles.fullWidth : styles.declinedBtn, customCommonStyles.noBoxShadow]}
                        titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonStyles.whiteColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}
                        disabled={!selectedOpt && !isLoading}
                      />
                    </View>
                  </Form>
                  <PopupModalComponent
                    visibility={!!isVisible}
                    bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.confirmDeclineModal]}
                    messageType={MESSAGE_TYPE.delete}
                    headingIcon={true}
                    heading={"Decline Bill"}
                    modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
                    bodyContent={`Do you want to decline Bill?`}
                    okLabel={"Confirm"}
                    modalPopupOkBtn={[commonStyles.widthAuto]}
                    handleOk={this.onOkPress}
                    disabledOk={this.state.isDisabled}
                    closeLabel={"Cancel"}
                    handleClose={this.onConfirmationCancel}
                  />
            </View> 
      );
    }
  }
);

const styles = StyleSheet.create({
  declinedContainer: {
    // backgroundColor: "#FFFFFF",
    position: "relative",
  },
  BottomPaySection: {
    // position: "fixed",
    bottom: 0,
    width: "100%",
    marginTop: "auto",
    backgroundColor: "#fff",
    padding: 0,
  },
  commentsAttachment: {},
  commentsIcon: {
    height: 44,
    width: 44,
    display: "block",
    backgroundSize: "contain",
    borderTopLeftRadius: 44,
    borderTopRightRadius: 44,
    borderBottomLeftRadius: 44,
    borderBottomRightRadius: 44,
  },
  modalWrapper: {
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  modalBodyWrapper: {
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#a7a7a7",
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    width: 300,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  declinedBtnWrap: {
    justifyContent: "flex-end",
    maxHeight: 38,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          justifyContent: "space-between",
          marginTop: 20,
        };
      }
    },
  },
  fullWidth: {
    marginLeft: 10,
    minWidth: "70%",
    height: 24,
  },
  halfWidth: {
    // minWidth: "100%",
    minWidth: "48%"
  },
  declinedBtn: {
    paddingRight: 16,
    paddingLeft: 16,
    // marginLeft: 15,
    width: 100,
    height: 24,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minWidth: "48%",
          paddingRight: 0,
          paddingLeft: 0,
        };
      }
    },
  },
  cancelBtn: {
    borderWidth: 2,
    borderColor: "#1277D9"
  },
  declineScreen: {
    paddingRight: 16,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingRight: 0,
          paddingLeft: 0,
        };
      }
    },
  },
  declineScreenContainer: {
    backgroundColor: "#fff",
    marginLeft: 16,
    marginRight: 16,
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    height: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingTop: 0,
        };
      }
    },
  },
  confirmBtn: {
    minWidth: 110,
  },
  exModalHelperTextStyle: {
    width: "150%",
  },
  otherComments: {
    minHeight: 56,
  },
  declineModalForm: {
    justifyContent: "flex-start"
  },
  confirmDeclineModal: {
    minHeight: 135,
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexDirection: "column",
        };
    },
  },
  pageTitle: {
    screen: ({ width }) => {
      if (width <= 991){
        return {
          marginLeft: 16,
          marginRight: 16
        };
      }
    },
  }
});
