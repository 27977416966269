import React from "react";
import R14, {
  StyleSheet,
  Button,
  Image,
  Text,
  View,
  Touchable,
  TextInputField,
  SelectMenuField,
  Form,
  SubmitButton,
  FlatList,
  Checkbox,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import NoRecordsFound from "../NoRecordsFound";
import CommonValidators from "../../utils/CommonValidators";
import CommonUtility from "../../utils/CommonUtility";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import PCHLoaderComponent from "./PCHLoaderComponent";
import { DEFAULT_COUNTRY, SEARCH_TYPE, MESSAGE_TYPE } from "../../constant/app.data.constant";
import PrimaryProviderAddress from "./PrimaryProviderAddress";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";
import PopupModalComponent from "../PopupModalComponent";
import { analyticsLogs } from "../../utils/AnalyticLogs";

const editImage = require("../../assets/images/edit.png");
const primaryIcon = require("../../assets/images/selete_icon_active.png");
const deleteImage = require("../../assets/images/delete.png");
const plusSolidIcon = require("../../assets/images/plus_solid.png");
const arrowLeft = require("../../assets/images/icPrevious.png");
const arrowRight = require("../../assets/images/icNext.png");
const arrowFirst = require("../../assets/images/icFirst.png");
const arrowLast = require("../../assets/images/icLast.png");

export default R14.connect(
  class AddDoctors extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        actionMode: this.props.defaultMode ? "Add" : "Listing",
        doctorUid: "",
        isConfirmationVisible: false,
        addedHeight: 0,
        provider: null,
        treatmentAddresses: [],
        isTreatmentAddressList: false,
        isEditTreatmentAddress: false,
        isPrimaryAddress: false,
        specialitiesList: [],
        selectedSpecialities: [],
        otherSpeciality: "",
        addressConfirmed: false,
        isAddressUpdation: "",
        addressConfirmationMessage: "",
        secondaryAddressConfirmed: false
      };
      this.statesList = [];
      this.citiesList = [];
      this.props.app.dm.addDoctorsDomain.setState(
        {
          isAddProviderBackConfirmation: false,
          hasTreatmentAddress: true
        });

    }

    componentDidMount = async () => {
      let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, "", DEFAULT_COUNTRY.value, "", 10);
      this.statesList = data.map((item) => ({
        label: item.state_name,
        value: item.uid,
      }));
      this.props.app.ui.form("addDoctor").elmts.primaryStateObject.setItems(this.statesList);
      if (!!this.props.app.ui.form("addDoctor")) this.props.app.ui.form("addDoctor").elmts.countryCode.setValue({ label: "+1", value: "+1" });
      await this.getSpecialitiesList();
    }

    onCancel = async () => {
      if (this.props.defaultMode === "new") {
        this.props.app.nav.back();
        this.props.app.dm.addDoctorsDomain.setState({ hasTreatmentAddress: true});
        return false;
      }
      this.clearAllValidationMessages();
      this.setState({
        isTreatmentAddressList: false,
        isEditTreatmentAddress: false,
        isConfirmationVisible: false,
        provider: null,
        treatmentAddresses: [],
      })
    };

    onBack = async () => {
      let docAddressForm = this.props.app.ui.form("doctorAddressForm");
      docAddressForm.elmts.addressLine1.setError("");
      docAddressForm.elmts.cityObject.setError("");
      docAddressForm.elmts.stateObject.setError("");
      docAddressForm.elmts.postCode.setError("");
      docAddressForm.elmts.licenseDetails.setError("");
      
      let docForm = this.props.app.ui.form("addDoctor");
      docForm.elmts.doctorName.setError("");
      docForm.elmts.doctorMiddleName.setError("");
      docForm.elmts.doctorLastName.setError("");
      docForm.elmts.NPI.setError("");
      docForm.elmts.phoneNo.setError("");
      docForm.elmts.taxIdNumber.setError("");
      docForm.elmts.specialities.setError("");
      docForm.elmts.primaryAddressLine1.setError("");
      docForm.elmts.primaryStateObject.setError("");
      docForm.elmts.primaryCityObject.setError("");
      docForm.elmts.primaryPostCode.setError("");
      docForm.elmts.primaryLicenseDetails.setError("");
      const {provider, otherSpeciality} = this.state;
      docForm.elmts.otherSpecialities.setValue(otherSpeciality);
      docForm.elmts.doctorName.setValue(provider.firstName);
      docForm.elmts.doctorMiddleName.setValue(provider.middleName);
      docForm.elmts.doctorLastName.setValue(provider.lastName || provider.DoctorName);
      docForm.elmts.NPI.setValue(provider.NPI);
      this.mobileNumberText(provider.phoneNo, docForm);
      docForm.elmts.taxIdNumber.setValue(provider.taxIdNumber);
      let specialities = provider.specialityObject && provider.specialityObject.length ? provider.specialityObject.map(speciality => {
        if(speciality.value === "other"){
          docForm.elmts.otherSpecialities.setValue(speciality.label);
          return {label: "Other", value: speciality.value}
        };
        return {label: speciality.label, value: speciality.value}
      }) : [];
      docForm.elmts.specialities.setValue(specialities);

      this.setState({
        actionMode: 'Update',
        isTreatmentAddressList: true,
        isEditTreatmentAddress: false,
        isConfirmationVisible: false,
        isPrimary: false,
        provider: {...provider, treatmentAddresses: this.state.treatmentAddresses},
        otherSpeciality: otherSpeciality === " " ? "" : otherSpeciality
      })
    };

    // Clear Validation Message
    clearAllValidationMessages = async () => {
      const { defaultMode } = this.props;
      let docForm = this.props.app.ui.form("addDoctor");
      let docAddressForm = this.props.app.ui.form("doctorAddressForm");
      if (docForm) {
        docForm.elmts.doctorName.setValue("");
        docForm.elmts.doctorMiddleName.setValue("");
        docForm.elmts.doctorLastName.setValue("");
        docForm.elmts.NPI.setValue("");
        docForm.elmts.phoneNo.setValue("");
        docForm.elmts.taxIdNumber.setValue("");
        docForm.elmts.specialities.setValue([]);
        docForm.elmts.otherSpecialities.setValue("");
        docForm.elmts.primaryAddressLine1.setValue("");
        docForm.elmts.primaryStateObject.setValue("");
        docForm.elmts.primaryCityObject.setValue("");
        docForm.elmts.primaryPostCode.setValue("");
        docForm.elmts.primaryLicenseDetails.setValue("");

        docForm.elmts.doctorName.setError("");
        docForm.elmts.doctorMiddleName.setError("");
        docForm.elmts.doctorLastName.setError("");
        docForm.elmts.NPI.setError("");
        docForm.elmts.phoneNo.setError("");
        docForm.elmts.taxIdNumber.setError("");
        docForm.elmts.specialities.setError("");
        docForm.elmts.otherSpecialities.setError("");
        docForm.elmts.primaryAddressLine1.setError("");
        docForm.elmts.primaryStateObject.setError("");
        docForm.elmts.primaryCityObject.setError("");
        docForm.elmts.primaryPostCode.setError("");
        docForm.elmts.primaryLicenseDetails.setError("");
        this.setState({otherSpeciality: ""})
      } else if (docAddressForm) {
        docAddressForm.elmts.addressLine1.setValue("");
        docAddressForm.elmts.cityObject.setValue("");
        docAddressForm.elmts.stateObject.setValue("");
        docAddressForm.elmts.postCode.setValue("");
        docAddressForm.elmts.licenseDetails.setValue("");
        docAddressForm.elmts.addressLine1.setError("");
        docAddressForm.elmts.cityObject.setError("");
        docAddressForm.elmts.stateObject.setError("");
        docAddressForm.elmts.postCode.setError("");
        docAddressForm.elmts.licenseDetails.setError("");
      }

      if (defaultMode === "add") {
        this.props.app.nav.back();
      }
      else {
        this.setState({ actionMode: "Listing", provider: null });
      }
    };

    handleAddressSubmit = async(form) => {
      try {
        let { treatmentAddresses, isPrimaryAddress, provider } = this.state;
        if(isPrimaryAddress){
          const primaryAddress = treatmentAddresses.find(address => address.isPrimary);
          if(primaryAddress && primaryAddress.uid && provider.treatmentAddresses.uid !== primaryAddress.uid) {
            this.setState({
              isPrimaryAddress: true,
              isConfirmationVisible: true
            })
          } else this.handleAddUpdateAddressSubmit(form);
        } else  this.handleAddUpdateAddressSubmit(form);
        return false;
      } catch (error) {
        console.log(error)
        return false;
      }
    }

    handleAddUpdateAddressSubmit = async (form) => {
      let apiBody = {
        doctorUid: this.state.doctorUid,
        updatedAt: new Date().toUTCString(),
        updatedBy: this.props.userLoginDetail.uid,
        addressLine1: form.elmts.addressLine1.value,
        town: form.elmts.cityObject.value.label,
        state: form.elmts.stateObject.value.label,
        country: 'US',
        isActive: 'true',
        isPrimary: this.state.isPrimaryAddress,
        postCode: form.elmts.postCode.value,
        stateObject: form.elmts.stateObject.value,
        cityObject: form.elmts.cityObject.value,
        countryObject: DEFAULT_COUNTRY,
        licenseDetails: form.elmts.licenseDetails.value,
      };

      let { provider } = this.state;
      let treatmentAddresses = this.state.treatmentAddresses;

      if(!this.state.addressConfirmed){
        let isValidAddress = await this.props.app.dm.queryDomain.ValidateAddressDetails({
          address2: form.elmts.addressLine1.value,
          city: form.elmts.cityObject ? form.elmts.cityObject.value.label : "",
          state: form.elmts.stateObject ? form.elmts.stateObject.value.label : "",
          country: DEFAULT_COUNTRY ? DEFAULT_COUNTRY.label : "",
          zipCode: form.elmts.postCode.value
        });

        if(isValidAddress && isValidAddress.success === false){
          this.props.app.ui.progressIndicator.hide({ timeout: 750 });
          this.props.app.dm.commonDomain.showToastr("error", isValidAddress.message);
          return {message: "Invalid Address"}
        };

        if(isValidAddress && isValidAddress.success && !!isValidAddress.isBusiness && isValidAddress.isBusiness === "N" ){
          const firstName = provider.firstName;
          if(!firstName){
            const form = this.props.app.ui.form("addDoctor");
            form.elmts.doctorName.setError(CommonString.EnterFirstNameRequired);
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            this.props.app.dm.commonDomain.showToastr("error", 'First Name is required for individual address');
            return {message: 'First Name is required for individual address'}
          }
        };

          /** Start of Handle address confirmation popup */
          const updateAddressKeys = !!isValidAddress ? Object.keys(isValidAddress.updatedAddress) : [];
          if(isValidAddress && updateAddressKeys.length){
            let message = "";
            updateAddressKeys.forEach(key => {
              if(key === "address1") message = `Address: ${isValidAddress.updatedAddress["address1"]}`;
              if(key === "address2") message = (!!message ? `${message}\n` : "") +  `Address2: ${isValidAddress.updatedAddress["address2"]}`;
              if(key === "city") message = (!!message ? `${message}\n` : "") + `City: ${isValidAddress.updatedAddress["city"]}`;
              if(key === "state") message = (!!message ? `${message}\n` : "") + `State: ${isValidAddress.updatedAddress["state"]}`;
              if(key === "zipCode") message = (!!message ? `${message}\n` : "") + `Zip Code: ${isValidAddress.updatedAddress["zipCode"]}`;
            });
    
            if(isValidAddress.alertMessage) message = (!!message ? `${message}\n\n` : "") + `Note* ${isValidAddress.alertMessage}`;
            this.setState({
              isAddressUpdation: "updateAddress",
              addressConfirmationMessage: message,
              addressConfirmed: true,
              secondaryAddressConfirmed: true
            });
            return false;
          } else if(isValidAddress && !!isValidAddress.alertMessage) {
            const message = `Note* ${isValidAddress.alertMessage}`;
            this.setState({
              isAddressUpdation: "alertMessage",
              addressConfirmationMessage: message,
              addressConfirmed: true,
              secondaryAddressConfirmed: true,
            });
            return false;
          };
          /** End of handle address confirmation popup */
        }
        this.setState({addressConfirmed: false});

      if(this.state.isPrimaryAddress){
        apiBody.isPrimary = true;
        const primaryAddress = treatmentAddresses.find(address => address.isPrimary);

        if(primaryAddress && primaryAddress.uid) {
          this.props.app.dm.addDoctorsDomain.updateTreatmentAddress({uid: primaryAddress.uid, isPrimary: false});
          treatmentAddresses = treatmentAddresses.map(address => {
            if(address.uid === primaryAddress.uid ){
              return {...address, isPrimary: false}
            } else {
              return address
            }
          })
        }
      }

      if (!!provider && !!provider.treatmentAddresses && !!provider.treatmentAddresses.stateObject && provider.treatmentAddresses.stateObject.value) {
        apiBody = { ...apiBody, uid: provider.treatmentAddresses.uid }
        await this.props.app.dm.addDoctorsDomain.updateTreatmentAddress(apiBody);
        // this.clearAllValidationMessages();
        this.props.app.dm.commonDomain.showToastr("success", CommonString.TreatmentUpdated);
        treatmentAddresses = treatmentAddresses.filter(address => address.uid !== provider.treatmentAddresses.uid);
        treatmentAddresses = [apiBody, ...treatmentAddresses];
        provider = {...provider, treatmentAddresses};
      } else {
        const res = await this.props.app.dm.addDoctorsDomain.insertTreatmentAddress(apiBody);
        apiBody.uid = res.uid;
        // this.clearAllValidationMessages();
        analyticsLogs(CommonString.MANAGE_PROVIDER, CommonString.ADDRESS_ADDED_ACTION, this.props.app);
        this.props.app.dm.commonDomain.showToastr("success", CommonString.TreatmentAdded);
        treatmentAddresses = [apiBody, ...treatmentAddresses];
        provider = {...provider, treatmentAddresses};
      }
      if (this.props.defaultMode === "new") {
        this.props.app.dm.addDoctorsDomain.setState({ hasTreatmentAddress: true});
        // this.props.app.nav.to("uploadBillsRoute");
        // this.props.app.nav.back();
        // return false;
      }
      this.setState({
        isTreatmentAddressList: true,
        isEditTreatmentAddress: false,
        isConfirmationVisible: false,
        treatmentAddresses,
        provider,
        actionMode: "Update"
      });
      return false;
    }

    handleSubmit = async (form) => {
      const { provider, actionMode, selectedSpecialities } = this.state;
      const { isUberDocs } = this.props.app.dm.userSession.state;
      const specialityObject = !!selectedSpecialities ? selectedSpecialities.map(speciality => {
        if(speciality.value === "other"){
          return {label: form.elmts.otherSpecialities.value, value: speciality.value}
        }
        return speciality;
      }) : [];

      try {
        if ((!!provider && provider.NPI !== form.elmts.NPI.value) || !!!provider) {
          let result = await this.props.app.dm.addDoctorsDomain.isValidateNPI(form.elmts.NPI.value.trim());
          if (!!result && !!!result.success) {
            form.elmts.NPI.setError(result.error);
            return false;
          } else {
            form.elmts.NPI.setError(null);
          }
        }

        if(!this.state.addressConfirmed){
          let isValidAddress = await this.props.app.dm.queryDomain.ValidateAddressDetails({
            address2: form.elmts.primaryAddressLine1.value,
            city: form.elmts.primaryCityObject ? form.elmts.primaryCityObject.value.label : "",
            state: form.elmts.primaryStateObject ? form.elmts.primaryStateObject.value.label : "",
            country: DEFAULT_COUNTRY ? DEFAULT_COUNTRY.label : "",
            zipCode: form.elmts.primaryPostCode.value
          });

          if(isValidAddress && isValidAddress.success === false){
            this.props.app.ui.progressIndicator.hide({ timeout: 750 });
            this.props.app.dm.commonDomain.showToastr("error", isValidAddress.message);
            return false;
          };

          if(isValidAddress && isValidAddress.success && !!isValidAddress.isBusiness && isValidAddress.isBusiness === "N" ){
            const firstName = form.elmts.doctorName.value;
            if(!firstName){
              form.elmts.doctorName.setError(CommonString.EnterFirstNameRequired)
              this.props.app.ui.progressIndicator.hide({ timeout: 750 });
              this.props.app.dm.commonDomain.showToastr("error", 'First Name is required for individual address');
              return false;
            }
          };

          /** Start of Handle address confirmation popup */
          const updateAddressKeys = !!isValidAddress ? Object.keys(isValidAddress.updatedAddress) : [];
          if(isValidAddress && updateAddressKeys.length){
            let message = "";
            updateAddressKeys.forEach(key => {
              if(key === "address1") message = `Address: ${isValidAddress.updatedAddress["address1"]}`;
              if(key === "address2") message = (!!message ? `${message}\n` : "") +  `Address2: ${isValidAddress.updatedAddress["address2"]}`;
              if(key === "city") message = (!!message ? `${message}\n` : "") + `City: ${isValidAddress.updatedAddress["city"]}`;
              if(key === "state") message = (!!message ? `${message}\n` : "") + `State: ${isValidAddress.updatedAddress["state"]}`;
              if(key === "zipCode") message = (!!message ? `${message}\n` : "") + `Zip Code: ${isValidAddress.updatedAddress["zipCode"]}`;
            });
    
            if(isValidAddress.alertMessage) message = (!!message ? `${message}\n\n` : "") + `Note* ${isValidAddress.alertMessage}`;
            this.setState({
              isAddressUpdation: "updateAddress",
              addressConfirmationMessage: message,
              addressConfirmed: true
            });
            return false;
          } else if(isValidAddress && !!isValidAddress.alertMessage) {
            const message = `Note* ${isValidAddress.alertMessage}`;
            this.setState({
              isAddressUpdation: "alertMessage",
              addressConfirmationMessage: message,
              addressConfirmed: true
            });
            return false;
          };
          /** End of handle address confirmation popup */
        }
        this.setState({addressConfirmed: false});

        if (actionMode === "Add") {
          const userLoginDetail = await this.props.app.dm.rest.getUser();
          let uid = !!userLoginDetail ? userLoginDetail.uid : null;
          let DoctorName = form.elmts.doctorName.value && form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value ? form.elmts.doctorName.value.trim() + " " + form.elmts.doctorMiddleName.value.trim() + " " + form.elmts.doctorLastName.value.trim() :
            form.elmts.doctorLastName.value && form.elmts.doctorMiddleName.value ? form.elmts.doctorMiddleName.value.trim() + " " + form.elmts.doctorLastName.value.trim() :
            form.elmts.doctorName.value && form.elmts.doctorLastName.value ? form.elmts.doctorName.value.trim() + " " + form.elmts.doctorLastName.value.trim() :
            form.elmts.doctorLastName.value ? form.elmts.doctorLastName.value.trim() : "";
          
          if(
            (form.elmts.doctorName.value && form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value && DoctorName.trim().length >=78) ||
            (form.elmts.doctorName.value && !form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value && DoctorName.trim().length >=77) ||
            (!form.elmts.doctorName.value && form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value && DoctorName.trim().length >=77) ||
            (!form.elmts.doctorName.value && !form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value && DoctorName.trim().length >=76)
          ){
            form.elmts.doctorName.setError(CommonString.ProviderFullNameError);
            form.elmts.doctorMiddleName.setError(CommonString.ProviderFullNameError);
            form.elmts.doctorLastName.setError(CommonString.ProviderFullNameError);
            return false;
          } else {
            form.elmts.doctorName.setError("");
            form.elmts.doctorMiddleName.setError("");
            form.elmts.doctorLastName.setError("");
          }
          // for specialities drop down
          // let specialities = form.elmts.specialities.value && form.elmts.specialities.value.length ? form.elmts.specialities.value.map(e => e.label)  : [];
          // specialities = specialities.join(", ");
          let apiBody = {
            DoctorName,
            firstName: form.elmts.doctorName.value,
            middleName: form.elmts.doctorMiddleName.value,
            lastName: form.elmts.doctorLastName.value,
            NPI: form.elmts.NPI.value,
            specialityObject: specialityObject,
            phoneNo: !!form.elmts.phoneNo.value ? (form.elmts.countryCode.value.value + " " + form.elmts.phoneNo.value) : "",
            taxIdNumber: form.elmts.taxIdNumber.value,
            userLoginDetailUid: uid,
            parentUid: !!userLoginDetail.parentUid ? userLoginDetail.parentUid : uid,
            parentName: !!userLoginDetail.parentUid ? `${userLoginDetail.parentUserDetail.firstName} (${userLoginDetail.firstName} ${userLoginDetail.lastName})` : userLoginDetail.firstName,
          };
          let doctorAPIRes = await this.props.app.dm.addDoctorsDomain.insertDoctorsDetail(apiBody);
          if (!!doctorAPIRes.uid) {
            let treatmentApiBody = {
              doctorUid: doctorAPIRes.uid,
              updatedAt: new Date().toUTCString(),
              updatedBy: this.props.userLoginDetail.uid,
              addressLine1: form.elmts.primaryAddressLine1.value,
              town: form.elmts.primaryCityObject.value.label,
              state: form.elmts.primaryStateObject.value.label,
              country: 'US',
              isActive: 'true',
              postCode: form.elmts.primaryPostCode.value,
              stateObject: form.elmts.primaryStateObject.value,
              cityObject: form.elmts.primaryCityObject.value,
              countryObject: DEFAULT_COUNTRY,
              isPrimary: true,
              licenseDetails: form.elmts.primaryLicenseDetails.value,
            };

            await this.props.app.dm.addDoctorsDomain.insertTreatmentAddress(treatmentApiBody);
            analyticsLogs(CommonString.MANAGE_PROVIDER, CommonString.PROVIDER_ADDED_ACTION, this.props.app);
            this.props.app.dm.commonDomain.showToastr("success", CommonString.ProviderAdded);
            this.setState({
              isTreatmentAddressList: false,
              isEditTreatmentAddress: false,
            })
            const { doctorDetails, totalBills, resultsPerPage } = this.props.app.dm.addDoctorsDomain.state;
            doctorDetails.unshift({ ...apiBody, ...doctorAPIRes });
            let totalIns = totalBills + 1;
            if (resultsPerPage < totalIns)
              doctorDetails.pop();

            this.props.app.dm.addDoctorsDomain.setState({
              doctorDetails: doctorDetails,
              totalBills: totalIns,
              totalPages: Math.ceil(totalIns / resultsPerPage),
              isFetched: true
            });

            if (this.props.defaultMode === "add") {
              // AsyncStorage.setItem("provider", JSON.stringify([{...apiBody, ...doctorAPIRes}]));
            //   handle
              this.props.app.nav.back();
            } else if (this.props.defaultMode === "new"){
                if(!!isUberDocs){
                    let filter = {
                      filter: {
                        doctorUid: { eq: doctorAPIRes.uid },
                        isActive: { eq: "true" },
                      },
                      sort: { field: "createdAt", order: "DESC" },
                    };
                    const treatmentAddresses = await this.props.app.dm.addDoctorsDomain.getTreatmentAddressList(filter);
                    
                    this.setState({
                      isTreatmentAddressList: true,
                      isEditTreatmentAddress: false,
                      doctorUid: doctorAPIRes.uid,
                      provider: {...doctorAPIRes, ...apiBody, treatmentAddresses: treatmentAddresses},
                      treatmentAddresses: treatmentAddresses
                    })
                    this.props.app.dm.addDoctorsDomain.setState({ hasTreatmentAddress: true});
                } else {
                    // this.props.app.nav.to("uploadBillsRoute");
                    this.props.app.dm.addDoctorsDomain.setState({ hasTreatmentAddress: false });
                    this.props.app.nav.back();
                }
            } else {
              this.clearAllValidationMessages();
            }
            return false;
          }
        } else {
          let DoctorName = form.elmts.doctorName.value && form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value ? form.elmts.doctorName.value.trim() + " " + form.elmts.doctorMiddleName.value.trim() + " " + form.elmts.doctorLastName.value.trim() :
            form.elmts.doctorLastName.value && form.elmts.doctorMiddleName.value ? form.elmts.doctorMiddleName.value.trim() + " " + form.elmts.doctorLastName.value.trim() :
            form.elmts.doctorName.value && form.elmts.doctorLastName.value ? form.elmts.doctorName.value.trim() + " " + form.elmts.doctorLastName.value.trim() :
            form.elmts.doctorLastName.value ? form.elmts.doctorLastName.value.trim() : "";

          if(
            (form.elmts.doctorName.value && form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value && DoctorName.trim().length >=78) ||
            (form.elmts.doctorName.value && !form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value && DoctorName.trim().length >=77) ||
            (!form.elmts.doctorName.value && form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value && DoctorName.trim().length >=77) ||
            (!form.elmts.doctorName.value && !form.elmts.doctorMiddleName.value && form.elmts.doctorLastName.value && DoctorName.trim().length >=76)
          ){
            form.elmts.doctorName.setError(CommonString.ProviderFullNameError);
            form.elmts.doctorMiddleName.setError(CommonString.ProviderFullNameError);
            form.elmts.doctorLastName.setError(CommonString.ProviderFullNameError);
            return false;
          } else {
            form.elmts.doctorName.setError("");
            form.elmts.doctorMiddleName.setError("");
            form.elmts.doctorLastName.setError("");
          }
          // for specialities drop down
          // let specialities = form.elmts.specialities.value && form.elmts.specialities.value.length ? form.elmts.specialities.value.map(e => e.label)  : [];
          // specialities = specialities.join(", ");
          let apiBody = {
            DoctorName,
            firstName: form.elmts.doctorName.value,
            middleName: form.elmts.doctorMiddleName.value,
            lastName: form.elmts.doctorLastName.value,
            NPI: form.elmts.NPI.value,
            specialityObject: specialityObject,
            uid: provider.uid,
            updatedAt: new Date(),
            updatedBy: this.props.userLoginDetail.uid,
            phoneNo: !!form.elmts.phoneNo.value ? (form.elmts.countryCode.value.value + " " + form.elmts.phoneNo.value) : "",
            taxIdNumber: form.elmts.taxIdNumber.value,
          };
          this.props.app.dm.addDoctorsDomain.updateDoctorsDetail(apiBody);
          const { doctorDetails } = this.props.app.dm.addDoctorsDomain.state;
          if (!!doctorDetails.length) {
            let ind = doctorDetails.findIndex((el) => el.uid === provider.uid);
            doctorDetails[ind] = { ...doctorDetails[ind], ...apiBody };
          };

          let treatmentApiBody = {
            doctorUid: provider.uid,
            updatedAt: new Date().toUTCString(),
            updatedBy: this.props.userLoginDetail.uid,
            addressLine1: form.elmts.primaryAddressLine1.value,
            town: form.elmts.primaryCityObject.value.label,
            state: form.elmts.primaryStateObject.value.label,
            country: 'US',
            isActive: 'true',
            isPrimary: true,
            postCode: form.elmts.primaryPostCode.value,
            stateObject: form.elmts.primaryStateObject.value,
            cityObject: form.elmts.primaryCityObject.value,
            countryObject: DEFAULT_COUNTRY,
            licenseDetails: form.elmts.primaryLicenseDetails.value,
          };

          if(provider.treatmentAddresses.uid){
            treatmentApiBody.uid = provider.treatmentAddresses.uid
            this.props.app.dm.addDoctorsDomain.updateTreatmentAddress(treatmentApiBody)
          } else {
            this.props.app.dm.addDoctorsDomain.insertTreatmentAddress(treatmentApiBody);
          };

          this.props.app.dm.addDoctorsDomain.setState({
            doctorDetails: doctorDetails
          });
          if (this.props.defaultMode === "new"){
            let filter = {
              filter: {
                doctorUid: { eq: provider.uid },
                isActive: { eq: "true" },
              },
              sort: { field: "createdAt", order: "DESC" },
            };
            const treatmentAddresses = await this.props.app.dm.addDoctorsDomain.getTreatmentAddressList(filter);
            
            this.setState({
              isTreatmentAddressList: true,
              isEditTreatmentAddress: false,
              doctorUid: provider.uid,
              provider: {...provider, ...apiBody, treatmentAddresses: treatmentAddresses},
              treatmentAddresses: treatmentAddresses
            })
            this.props.app.dm.addDoctorsDomain.setState({ hasTreatmentAddress: true});
          } else {
            this.clearAllValidationMessages();
            this.setState({
              isTreatmentAddressList: false,
              isEditTreatmentAddress: false,
            });
          }
          return false;
        }
      } catch (error) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
      }
    };


    onUpdateAddress = async (updateNewAddress) => {
      // update required fields
      const form = this.props.app.ui.form("addDoctor");
      const docForm = this.props.app.ui.form("doctorAddressForm");
      const {secondaryAddressConfirmed, addressConfirmationMessage} = this.state;
      if(!!updateNewAddress) {
        const splitMessage = addressConfirmationMessage.split("\n");
        splitMessage.forEach(msg => {
          if(!msg.startsWith("Note*")){
            const splitMsg = msg.split(": ");
            if(!!secondaryAddressConfirmed){
              if(splitMsg[0] === "State"){
                docForm.elmts.stateObject.setValue({label: splitMsg[1], value: splitMsg[1]});
                docForm.elmts.stateObject.setItems([{label: splitMsg[1], value: splitMsg[1]}]);
              } else if(splitMsg[0] === "City"){
                docForm.elmts.cityObject.setValue({label: splitMsg[1], value: splitMsg[1]});
                docForm.elmts.cityObject.setItems([{label: splitMsg[1], value: splitMsg[1]}]);
              } else if(splitMsg[0] === "Zip Code") docForm.elmts.postCode.setValue(splitMsg[1]);
              else if(splitMsg[0] === "Address") docForm.elmts.addressLine1.setValue(splitMsg[1]);
            } else {
              if(splitMsg[0] === "State"){
                form.elmts.primaryStateObject.setValue({label: splitMsg[1], value: splitMsg[1]});
                form.elmts.primaryStateObject.setItems([{label: splitMsg[1], value: splitMsg[1]}]);
              } else if(splitMsg[0] === "City"){
                form.elmts.primaryCityObject.setValue({label: splitMsg[1], value: splitMsg[1]});
                form.elmts.primaryCityObject.setItems([{label: splitMsg[1], value: splitMsg[1]}]);
              } else if(splitMsg[0] === "Zip Code") form.elmts.primaryPostCode.setValue(splitMsg[1]);
              else if(splitMsg[0] === "Address") form.elmts.primaryAddressLine1.setValue(splitMsg[1]);

            }
          }
        });
      }

      this.setState({ addressConfirmed: true, isAddressUpdation: "", addressConfirmationMessage: "", secondaryAddressConfirmed: false});
      if(!!secondaryAddressConfirmed) await this.handleAddUpdateAddressSubmit(docForm);
      else await this.handleSubmit(form);
      return false;
    }

    deleteDoctor = async () => {
      try {
        let bills = await this.props.app.dm.addInsuranceDomain.checkExistinUploadBill({
          doctorUid: { eq: this.state.doctorUid },
        });

        if (bills.length > 0) {
          this.setState({ isConfirmationVisible: false, doctorUid: "" });
          this.props.app.dm.commonDomain.showToastr("error", CommonString.ProviderCanDelete);
          return false;
        } else {

          let apiBody = {
            isActive: "false",
            uid: this.state.doctorUid,
            deletedAt: new Date().toUTCString(),
            deletedBy: this.props.userLoginDetail.uid,
          };
          const { doctorDetails, totalBills, resultsPerPage } = this.props.app.dm.addDoctorsDomain.state;
          if (totalBills <= resultsPerPage) {
            this.props.app.dm.addDoctorsDomain.updateDoctorsDetail(apiBody);
            if (!!doctorDetails.length) {
              let ind = doctorDetails.findIndex((el) => el.uid === this.state.doctorUid);
              doctorDetails.splice(ind, 1);
            }
            this.props.app.dm.addDoctorsDomain.setState({
              doctorDetails: doctorDetails,
              totalBills: totalBills - 1
            });
            this.setState({ isConfirmationVisible: false, doctorUid: "" });
            analyticsLogs(CommonString.MANAGE_PROVIDER, CommonString.PROVIDER_DELETED_ACTION, this.props.app);
            this.props.app.dm.commonDomain.showToastr("error", CommonString.ProviderDeleted);
            return false;
          }
          else {
            await this.props.app.dm.addDoctorsDomain.updateDoctorsDetail(apiBody);
            this.setState({ isConfirmationVisible: false, doctorUid: "" });
            analyticsLogs(CommonString.MANAGE_PROVIDER, CommonString.PROVIDER_DELETED_ACTION, this.props.app);
            this.props.app.dm.commonDomain.showToastr("error", CommonString.ProviderDeleted);
            await this.getFirst();
            return false;
          }
        }
      }
      catch (error) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        return false;
      }
    };

    getSpecialitiesList = async(search = "", limit = 10) => {
      const form = this.props.app.ui.form("addDoctor");
      try {
        // graphql API
        let specialitiesList = await this.props.app.dm.addDoctorsDomain.getMstSpecialitiesDetails({
          search: search,
          page: 1,
          resultsPerPage: limit,
          sort: {
            field: "createdAt",
            order: "DESC"
          },
        });

        if(form && form.elmts && form.elmts.specialities) form.elmts.specialities.setItems(specialitiesList);
        this.setState({specialitiesList: specialitiesList})
        return specialitiesList;
      } catch (error) {
        console.log('error while fetching specialities list', error);
        return []
      }
    };

    deleteAddress = async () => {
      try {
        const { doctorUid, addressUid, provider } = this.state;
        let apiBody = { isActive: 'false', uid: addressUid, doctorUid: doctorUid }
        await this.props.app.dm.addDoctorsDomain.updateTreatmentAddress(apiBody);
        const filteredAddress = provider.treatmentAddresses.filter(address => address.uid !== addressUid);
        this.setState({
          isConfirmationVisible: false,
          isPrimaryAddress: false,
          provider: { ...provider, treatmentAddresses: filteredAddress },
          treatmentAddresses: filteredAddress,
          addressUid: "",
          actionMode: "Update"
        });
        analyticsLogs(CommonString.MANAGE_PROVIDER, CommonString.ADDRESS_DELETED_ACTION, this.props.app);
        this.props.app.dm.commonDomain.showToastr("error", CommonString.TreatmentDeleted);
        return false;
      }
      // }
      catch (error) {
        this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
        return false;
      }
    }

    openPopScreen = async (uid, addressUid, item) => {
      if(!!item && !!item.treatmentAddresses && !!item.treatmentAddresses.isPrimary){
        this.props.app.dm.commonDomain.showToastr("error", CommonString.MinOneTreatment);
        return false;
      }
      this.setState({ doctorUid: uid, addressUid, isConfirmationVisible: true, isPrimaryAddress: false, });
    };

    closePopScreen = async () => {
      this.setState({ isConfirmationVisible: false});
      this.props.app.dm.addDoctorsDomain.setState({ isAddProviderBackConfirmation: false });
    };

    onEditProvider = async () => {
      const {provider} = this.state;

      let primaryAddress = provider.treatmentAddresses.filter(address => address.isPrimary);
      
      primaryAddress = primaryAddress && primaryAddress.length ? primaryAddress[0] : null;

      let docForm = this.props.app.ui.form("addDoctor");
      docForm.elmts.primaryStateObject.setValue(primaryAddress ? primaryAddress.stateObject : null);
      docForm.elmts.primaryCityObject.setValue(primaryAddress ? primaryAddress.cityObject : null);
      docForm.elmts.primaryAddressLine1.setValue(primaryAddress ? primaryAddress.addressLine1 : null);
      docForm.elmts.primaryPostCode.setValue(primaryAddress ? primaryAddress.postCode : null);
      docForm.elmts.primaryLicenseDetails.setValue(primaryAddress ? primaryAddress.licenseDetails : null);

      this.setState({
        actionMode: "Update",
        isTreatmentAddressList: false,
        isEditTreatmentAddress: false,
        isPrimaryAddress: primaryAddress && primaryAddress.isPrimary,
        provider: {...provider, treatmentAddresses: primaryAddress ? primaryAddress : {} }
      })
    }

    trimExceededCharZipCode = (val) => {
      CommonUtility.zipCodeFormater(this.props.app.ui.form("doctorAddressForm"),"postCode", val );
    };

    trimExceededCharPrimaryZipCode = (val) => {
      CommonUtility.zipCodeFormater(this.props.app.ui.form("addDoctor"), "primaryPostCode", val );
    };


    onStateChange = async (val) => {
      let { label } = val;
      const { oldState } = this.state;
      if (!!oldState && oldState.label !== val.label) {
        this.props.app.ui.form("doctorAddressForm").elmts.cityObject.setValue(null);
      };
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.citiesList = [];
        this.props.app.ui.form("doctorAddressForm").elmts.cityObject.setValue(null);
        this.props.app.ui.form("doctorAddressForm").elmts.stateObject.setValue(null);
        this.props.app.ui.form("doctorAddressForm").elmts.cityObject.setItems(this.citiesList);
        return false;
      }
      this.props.app.ui.form("doctorAddressForm").elmts.stateObject.setValue(val);
      this.setState({
        oldState: val
      });
      this.citiesList = await this.onSearchStateCity(SEARCH_TYPE.CITY, true);
    };

    onPrimaryStateChange = async (val) => {
      let { label } = val;
      const { oldState } = this.state;
      if (!!oldState && oldState.label !== val.label) {
        this.props.app.ui.form("addDoctor").elmts.primaryCityObject.setValue(null);
      };
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.citiesList = [];
        this.props.app.ui.form("addDoctor").elmts.primaryCityObject.setValue(null);
        this.props.app.ui.form("addDoctor").elmts.primaryStateObject.setValue(null);
        this.props.app.ui.form("addDoctor").elmts.primaryCityObject.setItems(this.citiesList);
        return false;
      }
      this.props.app.ui.form("addDoctor").elmts.primaryStateObject.setValue(val);
      this.setState({
        oldState: val
      });
      this.citiesList = await this.onSearchPrimaryStateCity(SEARCH_TYPE.CITY, true);
    };

    onCityName = (val) => {
      const { label } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.props.app.ui.form("doctorAddressForm").elmts.cityObject.setValue(null);
        return false;
      }
      this.props.app.ui.form("doctorAddressForm").elmts.cityObject.setValue(val);
    };

    onPrimaryCityName = (val) => {
      const { label } = val;
      if (label === "No Results Found" || label.includes("Type in a search value")) {
        this.props.app.ui.form("addDoctor").elmts.primaryCityObject.setValue(null);
        return false;
      }
      this.props.app.ui.form("addDoctor").elmts.primaryCityObject.setValue(val);
    };

    onSearchStateCity = async (type, isBlankAllow = false, search = "") => {
      let selectedCountry = DEFAULT_COUNTRY;
      if (type === SEARCH_TYPE.STATE) {
        let country_name = !!selectedCountry && !!selectedCountry.value ? selectedCountry.value : "";
        let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, search, country_name, "", 10);
        this.statesList = data.map((item) => ({
          label: item.state_name,
          value: item.uid,
        }));

        this.props.app.ui.form("doctorAddressForm").elmts.stateObject.setValue(null);
        this.props.app.ui.form("doctorAddressForm").elmts.stateObject.setItems(this.statesList);
      } else if (type === SEARCH_TYPE.CITY) {
        const { values, elmts } = this.props.app.ui.form("doctorAddressForm");
        let state_name = !!values && !!values.stateObject && !!values.stateObject.value ? values.stateObject.value  :"";
        if(!state_name) return [];
        let items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.CITY, search, "", state_name);
        items = !!items ? items.map((element) => ({
          label: element.city_name,
          value: element.uid,
        })) : [];
        items = [...new Map(items.map((item) => [item["label"], item])).values()]
          .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        elmts.cityObject.setItems(!!values.stateObject && 'label' in values.stateObject ? items : null);
        return !!values.stateObject && 'label' in values.stateObject ? items : [];
      }
    };

    onSearchPrimaryStateCity = async (type, isBlankAllow = false, search = "") => {
      let selectedCountry = DEFAULT_COUNTRY;
      if (type === SEARCH_TYPE.STATE) {
        let country_name = !!selectedCountry && !!selectedCountry.value ? selectedCountry.value : "";

        let data = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.STATE, search, country_name, "", 10);
        this.statesList = data.map((item) => ({
          label: item.state_name,
          value: item.uid,
        }));

        this.props.app.ui.form("addDoctor").elmts.primaryStateObject.setValue(null);
        this.props.app.ui.form("addDoctor").elmts.primaryStateObject.setItems(this.statesList);
      } else if (type === SEARCH_TYPE.CITY) {
        const { values, elmts } = this.props.app.ui.form("addDoctor");
        let state_name = !!values && !!values.primaryStateObject && !!values.primaryStateObject.value ? values.primaryStateObject.value  :"";
        let items = await this.props.app.dm.miscellaneousDomain.getCountryStateCity(SEARCH_TYPE.CITY, search, "", state_name);
        items = !!items ? items.map((element) => ({
          label: element.city_name,
          value: element.uid,
        })) : [];
        items = [...new Map(items.map((item) => [item["label"], item])).values()]
          .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        elmts.primaryCityObject.setItems(!!values.primaryStateObject && 'label' in values.primaryStateObject ? items : null);
        return !!values.primaryStateObject && 'label' in values.primaryStateObject ? items : [];
      }
    };

    onSpecialitiesChange = (item) => {
      let docForm = this.props.app.ui.form("addDoctor");
      if(!item){
        docForm.elmts.otherSpecialities.setValue(null);
        docForm.elmts.otherSpecialities.setError(null);
        this.setState({ otherSpeciality: "", selectedSpecialities: [] })
        return false;
      };

      const {label, value} = item;
      if (label === "No Results Found" || (!!label && label.includes("Type in a search value"))){
        docForm.elmts.specialities.setValue(null);
        return false;
      };

      let {selectedSpecialities, otherSpeciality} = this.state;
      const specialitesIdx = selectedSpecialities.findIndex(specialites => specialites.value === value);
      if(specialitesIdx > -1) selectedSpecialities.splice(specialitesIdx, 1);
      else {
        if(item && label && value) selectedSpecialities.push(item);
      };

      if(value === "other") docForm.elmts.otherSpecialities.setValue(null);
      /**
       * 1) if other is selected & otherSpeciality textbox is not already opened -- open
       * 2) if other is selected & otherSpeciality textbox is already opened ---- close
       * 3) if other is selected & otherSpeciality textbox is already opened & user click on cancel button ---- close
       * 4) if other is not selected ---- show what every be already state
       */
      const specs = (value === "other" && label === "Other" && !otherSpeciality) ? " " :  
                    (value === "other" && label === "Other" && !!otherSpeciality) ? "" :  
                    (value === "other" && !label) ? "" :  
                    (value !== "other") ?  otherSpeciality : "";

      this.setState({
        selectedSpecialities,
        otherSpeciality: specs
      });
      return false;
    }

    formatSpecialitiesText = async (txt) => {

    }

    // NOTE: Doctor Update.
    updateDoctor = async (item) => {
      let docForm = this.props.app.ui.form("addDoctor");
      this.getSpecialitiesList();
      // for specialities drop down
      let specialities = item.specialityObject && item.specialityObject.length ? item.specialityObject.map(speciality => {
        if(speciality.value === "other"){
          docForm.elmts.otherSpecialities.setValue(speciality.label);
          this.setState({otherSpeciality: speciality.label});
          return {label: "Other", value: speciality.value}
        };
        return {label: speciality.label, value: speciality.value}
      }) : [];
      this.setState({selectedSpecialities: specialities})

      if (!!item && item.showTreatmentAddressList) {
        docForm.elmts.doctorName.setValue(item.firstName);
        docForm.elmts.doctorMiddleName.setValue(item.middleName);
        docForm.elmts.doctorLastName.setValue(item.lastName|| item.DoctorName);
        docForm.elmts.NPI.setValue(item.NPI);
        docForm.elmts.specialities.setValue(specialities);
        this.mobileNumberText(item.phoneNo, docForm);
        docForm.elmts.taxIdNumber.setValue(item.taxIdNumber);
        // call treatment address list
        delete item.showTreatmentAddressList;
        let filter = {
          filter: {
            doctorUid: { eq: item.uid },
            isActive: { eq: "true" },
          },
          sort: { field: "createdAt", order: "DESC" },
        };
        const treatmentAddresses = await this.props.app.dm.addDoctorsDomain.getTreatmentAddressList(filter);
        item = { ...item, treatmentAddresses: treatmentAddresses }
        this.setState({
          actionMode: 'Update',
          isTreatmentAddressList: true,
          isEditTreatmentAddress: false,
          doctorUid: item.uid,
          provider: item,
          treatmentAddresses: treatmentAddresses
        })
      } else if (!!item && item.isUpdateTreatmentAddress) {
        docForm.elmts.doctorName.setValue(item.firstName);
        docForm.elmts.doctorMiddleName.setValue(item.middleName);
        docForm.elmts.doctorLastName.setValue(item.lastName || item.DoctorName);
        docForm.elmts.NPI.setValue(item.NPI);
        this.mobileNumberText(item.phoneNo, docForm);
        docForm.elmts.taxIdNumber.setValue(item.taxIdNumber);

        let docAddressForm = this.props.app.ui.form("doctorAddressForm");
        docAddressForm.elmts.stateObject.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.stateObject : null);
        docAddressForm.elmts.cityObject.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.cityObject : null);
        docAddressForm.elmts.addressLine1.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.addressLine1 : null);
        docAddressForm.elmts.postCode.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.postCode : null);
        docAddressForm.elmts.licenseDetails.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.licenseDetails : null);
        if(!(item && item.treatmentAddresses && item.treatmentAddresses.stateObject)) analyticsLogs(CommonString.MANAGE_PROVIDER, CommonString.ADD_ADDRESS_ACTION, this.props.app);
        this.setState({
          actionMode: 'Edit',
          isTreatmentAddressList: false,
          isEditTreatmentAddress: true,
          doctorUid: item.uid,
          isPrimaryAddress: item.treatmentAddresses.isPrimary,
          provider: item
        });
      } else {
        this.setState({ actionMode: "Edit", provider: item });
        let phoneNo = !!item.phoneNo ? item.phoneNo.split(" ")[1] : "";

        let docForm = this.props.app.ui.form("addDoctor");
        
        // for specialities drop down
        let specialities = item.specialityObject ? item.specialityObject.map(speciality => {
          if(speciality.value === "other"){
            docForm.elmts.otherSpecialities.setValue(speciality.label);
            return {label: "Other", value: speciality.value}
          }
          return speciality;
        }) : [];
        docForm.elmts.doctorName.setValue(item.firstName);
        docForm.elmts.doctorMiddleName.setValue(item.middleName);
        docForm.elmts.doctorLastName.setValue(item.lastName || item.DoctorName);
        docForm.elmts.NPI.setValue(item.NPI);
        // docForm.elmts.licenseDetails.setValue(!!item.licenseDetails ? item.licenseDetails : null);
        docForm.elmts.specialities.setValue(specialities);
        docForm.elmts.phoneNo.setValue(phoneNo || "");
        docForm.elmts.taxIdNumber.setValue(!!item.taxIdNumber ? item.taxIdNumber : null);

        docForm.elmts.primaryStateObject.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.stateObject : null);
        docForm.elmts.primaryCityObject.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.cityObject : null);
        docForm.elmts.primaryAddressLine1.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.addressLine1 : null);
        docForm.elmts.primaryPostCode.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.postCode : null);
        docForm.elmts.primaryLicenseDetails.setValue(item && item.treatmentAddresses ? item.treatmentAddresses.licenseDetails : null);
      }
    };
    getHeightOfElement = async (event) => {
      const { height } = event.nativeEvent.layout;
      this.setState({
        addedHeight: height,
      });
    };
    toggleProviderFormList = () => {
        analyticsLogs(CommonString.MANAGE_PROVIDER, CommonString.ADD_PROVIDER_ACTION, this.props.app);
        this.props.app.ui.form("addDoctor").elmts.primaryStateObject.setItems(this.statesList);
      this.setState({
        actionMode: "Add",
        isTreatmentAddressList: false,
        isEditTreatmentAddress: false,
      });
    };

    getNext = async () => {
      this.setState({
        loaderVisible: true
      })
      let { currentPage, totalPages } = this.props.app.dm.addDoctorsDomain.state;
      if (currentPage <= totalPages) {
        currentPage = currentPage + 1;
        if (currentPage <= totalPages) {

          this.props.app.dm.addDoctorsDomain.setState({
            currentPage: currentPage,
            isFetched: false
          });
          await this.props.app.dm.addDoctorsDomain.getDoctorsDetailsWithPage();
        }
      }
      this.setState({
        loaderVisible: false
      })
    }

    getPrevious = async () => {
      this.setState({
        loaderVisible: true
      })
      let { currentPage } = this.props.app.dm.addDoctorsDomain.state;
      if (currentPage >= 1) {
        currentPage = currentPage - 1;
        if (currentPage > 0) {

          this.props.app.dm.addDoctorsDomain.setState({
            currentPage: currentPage,
            isFetched: false
          })

          await this.props.app.dm.addDoctorsDomain.getDoctorsDetailsWithPage();
        }
      }
      this.setState({
        loaderVisible: false
      })
    }


    getFirst = async () => {
      this.setState({
        loaderVisible: true
      })
      this.props.app.dm.addDoctorsDomain.setState({
        currentPage: 1,
        isFetched: false
      })

      await this.props.app.dm.addDoctorsDomain.getDoctorsDetailsWithPage();
      this.setState({
        loaderVisible: false
      })
    }

    getLast = async () => {
      this.setState({
        loaderVisible: true
      })
      let { totalPages } = this.props.app.dm.addDoctorsDomain.state;
      this.props.app.dm.addDoctorsDomain.setState({
        currentPage: totalPages,
        isFetched: false
      })

      await this.props.app.dm.addDoctorsDomain.getDoctorsDetailsWithPage();
      this.setState({
        loaderVisible: false
      })
    }

    formatNPIText = async (val) => {
      let npi = val;
      if (!!val) {
        let cleaned = ("" + npi).replace(/\D/g, "").substring(0, 10);
        let docForm = this.props.app.ui.form("addDoctor");
        docForm.elmts.NPI.setValue(cleaned);
        if(cleaned && cleaned.length === 10 && cleaned !== 9999999999) {
          let result = await this.props.app.dm.addDoctorsDomain.isValidateNPI(cleaned);
          if(result && result.success){
             docForm.elmts.NPI.setError(null);
            if(result.addresses && result.addresses.length){
              if(result.addresses[0].address_1) docForm.elmts.primaryAddressLine1.setValue(result.addresses[0].address_1);
              if(result.addresses[0].state) docForm.elmts.primaryStateObject.setValue({value: result.addresses[0].state, label: result.addresses[0].state});
              if(result.addresses[0].state) docForm.elmts.primaryStateObject.setItems([{value: result.addresses[0].state, label: result.addresses[0].state}]);
              if(result.addresses[0].city) docForm.elmts.primaryCityObject.setValue({value: result.addresses[0].city, label: result.addresses[0].city});
              if(result.addresses[0].city) docForm.elmts.primaryCityObject.setItems([{value: result.addresses[0].city, label: result.addresses[0].city}]);
              if(result.addresses[0].postal_code) this.trimExceededCharPrimaryZipCode(result.addresses[0].postal_code);
              if(result.addresses[0].telephone_number) this.mobileNumberText(result.addresses[0].telephone_number, docForm);
              // if(result.addresses[0].fax_number) this.onTaxIdText(result.addresses[0].fax_number, docForm);
            }
            if(result.basic){
              docForm.elmts.doctorName.setValue(result.basic.firstName);
              docForm.elmts.doctorMiddleName.setValue(result.basic.middleName);
              docForm.elmts.doctorLastName.setValue(result.basic.lastName);
            }
          }
          else if (!!result && !!result.error) {
            docForm.elmts.NPI.setError(result.error);
          }

        }
      }
    }

    formatLicenseText = async (val, doctorAddressForm) => {
      let license = val;
      if (!!val && !!doctorAddressForm) {
        let cleaned = ("" + license).replace("\\s").substring(0, 20);
        let docForm = this.props.app.ui.form("doctorAddressForm");
        docForm.elmts.licenseDetails.setValue(cleaned);
      } else if (!!val) {
        let cleaned = ("" + license).replace("\\s").substring(0, 20);
        let docForm = this.props.app.ui.form("addDoctor");
        docForm.elmts.primaryLicenseDetails.setValue(cleaned);
      }
    }

    mobileNumberText(val, form) {
      let mobNumText = val;
      if (!!val) {
        let cleaned = ("" + mobNumText).replace("+1", "").replace(/[^\d]/g, "").substring(0, 10);
        form.elmts.phoneNo.setValue(cleaned);
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          let formattedString = "";
          if (match[1] !== undefined) {
            match[1] = form.elmts.countryCode.value.value;
          } else {
            match[1] = "+1 ";
          }
          formattedString = ["(", match[2], ") ", match[3], "-", match[4]].join("");
          form.elmts.phoneNo.setValue(formattedString);
        }
      };
    }

    onTaxIdText(val, form) {
      let taxId = val;
      if (!!val) {
        let cleaned = ("" + taxId).replace(/[^0-9]/g, "").substring(0, 9);
        if(!!cleaned && cleaned.length >= 4 && cleaned.length <= 5 ) {
          form.elmts.taxIdNumber.setValue(cleaned.substring(0,3) + "-" + cleaned.substring(3,5));
        } else if(!!cleaned && cleaned.length >= 6){
          form.elmts.taxIdNumber.setValue(cleaned.substring(0,3) + "-" + cleaned.substring(3,5) + "-" + cleaned.substring(5,9));
        } else{
          form.elmts.taxIdNumber.setValue(cleaned);
        }
      };
    }

    toggleIsPrimaryAddress = () => {
      this.setState({
        isPrimaryAddress: !this.state.isPrimaryAddress
      })
    }

    renderAddProviderForm() {
      const {
        actionMode,
        isTreatmentAddressList,
        isEditTreatmentAddress,
        otherSpeciality,
        provider
      } = this.state;
      !this.statesList.length && this.onSearchStateCity(SEARCH_TYPE.STATE);
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
      return (
        <View
          style={[
            styles.addDoctorsSection,
            clientStyle.secondaryBgColor,
            actionMode === "Listing" ? commonStyles.displayNone : null,
          ]}
        >
          <Form
            name="addDoctor"
            style={[commonStyles.dFlex, commonStyles.flexDirectionColumn]}
            onSubmit={this.handleSubmit}
            validators={CommonValidators.getFormValidator()}
            validateBeforeSubmit={true}
          >
            <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.provideName,
                  !isEditTreatmentAddress && !isTreatmentAddressList && !(provider && provider.NPI) ? null : customCommonStyles.disabledInput
                ]}
              >
                <TextInputField
                  name="NPI"
                  label={`${CommonString.NPILabel}*`}
                  required={CommonString.EnterNPIRequired}
                  validator="min10Max10DigitNPI"
                  onChangeText={this.formatNPIText}
                  readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList || !!(provider && provider.NPI)}
                  disabled={!isEditTreatmentAddress && !isTreatmentAddressList && !(provider && provider.NPI)? false : true}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.left0,
                    styles.customHelperTextCss,
                    commonStyles.helperTextFontStyle,
                    commonFontStyles.workSans,
                  ]}
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lineHeight16, customCommonStyles.inputHeight]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.enterNPI,
                  !isEditTreatmentAddress && !isTreatmentAddressList ? null : customCommonStyles.disabledInput
                ]}
              >
                <TextInputField
                  name="doctorName"
                  label={`${CommonString.ProviderFirstName} `}
                  // required={CommonString.EnterFirstNameRequired}
                  validator="min0Max50"
                  readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  disabled={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.left0,
                    styles.customHelperTextCss,
                    commonStyles.helperTextFontStyle,
                    commonFontStyles.workSans,
                  ]}
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lineHeight16, customCommonStyles.inputHeight]}
                />
              </View>
            </View>
            <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.provideName,
                  !isEditTreatmentAddress && !isTreatmentAddressList ? null : customCommonStyles.disabledInput
                ]}
              >
                <TextInputField
                  name="doctorMiddleName"
                  label={`${CommonString.ProviderMiddleName} `}
                  validator="min0Max50"
                  readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  disabled={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.left0,
                    styles.customHelperTextCss,
                    commonStyles.helperTextFontStyle,
                    commonFontStyles.workSans,
                  ]}
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lineHeight16, customCommonStyles.inputHeight]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.enterNPI,
                  !isEditTreatmentAddress && !isTreatmentAddressList ? null : customCommonStyles.disabledInput
                ]}
              >
                <TextInputField
                  name="doctorLastName"
                  label={`${CommonString.ProviderLastName}* `}
                  required={CommonString.EnterLastNameRequired}
                  validator="min2Max50"
                  readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  disabled={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.left0,
                    styles.customHelperTextCss,
                    commonStyles.helperTextFontStyle,
                    commonFontStyles.workSans,
                  ]}
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lineHeight16, customCommonStyles.inputHeight]}
                />
              </View>
            </View>
            {/* License Details & Specialities */}
            <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.provideName,
                  !isEditTreatmentAddress && !isTreatmentAddressList ? null : customCommonStyles.disabledInput,
                  commonStyles.flexDirectionRow,
                  styles.mobileNumberInput,
                ]}
              >
                <SelectMenuField
                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.countryCode, customCommonStyles.inputHeight]}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                  name="countryCode"
                  // required={CommonString.SelectCountryCodeRequired}
                  items={[{ label: "+1", value: "+1" }]}
                  // onValueChange={this.onDomainExtensionChange}
                  searchable
                  disabled={true}
                  itemLoader={async ({ search }) => {
                    let items = [{ label: "+1", value: "+1" }];
                    return items
                      .filter((item) => {
                        return item.label.toLowerCase().includes(search.toLowerCase());
                      })
                      .sort((a, b) => b.label - a.label);
                  }}
                />
                <TextInputField
                  name="phoneNo"
                  label={CommonString.MobilerNumberLabel}
                  validator="max10phoneNoValidator"
                  onChangeText={txt => this.mobileNumberText(txt, this.props.app.ui.form("addDoctor") )}
                  readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  disabled={!isEditTreatmentAddress && !isTreatmentAddressList ? false : true}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.left0,
                    styles.customHelperTextCss,
                    commonStyles.helperTextFontStyle,
                    commonFontStyles.workSans,
                  ]}
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lineHeight16, customCommonStyles.inputHeight]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.enterNPI,
                  !isEditTreatmentAddress && !isTreatmentAddressList ? null : customCommonStyles.disabledInput
                ]}
              >
                <TextInputField
                  name="taxIdNumber"
                  label={`${CommonString.TaxIdNumber} `}
                  validator="min0Max50"
                  onChangeText={txt => this.onTaxIdText(txt, this.props.app.ui.form("addDoctor") )}
                  readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  disabled={!isEditTreatmentAddress && !isTreatmentAddressList ? false : true}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[
                    commonStyles.left0,
                    styles.customHelperTextCss,
                    commonStyles.helperTextFontStyle,
                    commonFontStyles.workSans,
                  ]}
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lineHeight16, customCommonStyles.inputHeight]}
                />
              </View>
            </View>

            {/* License Details & Specialities */}
            <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.provideName,
                  !isEditTreatmentAddress && !isTreatmentAddressList ? null : customCommonStyles.disabledInput,
                ]}
              >
                <SelectMenuField
                  name="specialities"
                  label={CommonString.SpecialitiesLabel}
                  items={this.state.specialitiesList}
                  onValueChange={this.onSpecialitiesChange}
                  multiple={true}
                  searchable
                  itemLoader={async ({ search }) => {
                    return await this.getSpecialitiesList(search);
                  }}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                  readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  disabled={!isEditTreatmentAddress && !isTreatmentAddressList ? false : true}
                  // style={[commonFontStyles.workSans, commonStyles.lineHeight16, customCommonStyles.inputHeight]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.enterNPI,
                  !isEditTreatmentAddress && !isTreatmentAddressList ? null : customCommonStyles.disabledInput,
                  // !!otherSpeciality ? null : commonStyles.displayNone
                ]}
              >
                <TextInputField
                  name="otherSpecialities"
                  label={CommonString.OtherSpecialitiesLabel}
                  onChangeText={this.formatSpecialitiesText}
                  validator="max125AlphaNumeric"
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.lineHeight16, styles.commentsField, customCommonStyles.inputHeight, !!otherSpeciality ? null : commonStyles.displayNone]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,                    
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}

                  readOnly={!!isEditTreatmentAddress || !!isTreatmentAddressList}
                  disabled={!isEditTreatmentAddress && !isTreatmentAddressList ? false : true}
                  // style={[commonFontStyles.workSans, commonStyles.lineHeight16, customCommonStyles.inputHeight]}
                />
              </View>
            </View>

            <PrimaryProviderAddress
              onSearchStateCity={this.onSearchPrimaryStateCity}
              onStateChange={this.onPrimaryStateChange}
              statesList={this.statesList}
              trimExceededCharZipCode={this.trimExceededCharPrimaryZipCode}
              onCityName={this.onPrimaryCityName}
              citiesList={this.citiesList}
              actionMode={this.state.actionMode}
              isVisiable={!this.state.isEditTreatmentAddress && !this.state.isTreatmentAddressList ? true : false}
              isEditTreatmentAddress={this.state.isEditTreatmentAddress}
              isTreatmentAddressList={this.state.isTreatmentAddressList}
              formatLicenseText={this.formatLicenseText}
            />

            {!!isEditTreatmentAddress ? null : (
              <View
                style={[
                  commonStyles.marTAuto,
                  commonStyles.flex1,
                  styles.buttonWrapper,
                  commonStyles.flexDirectionRow,
                ]}
              >
                <View
                  style={[
                    commonStyles.marginRight8,
                    styles.addProviderBtnHolder,
                  ]}
                >
                  <Button
                    key="cancel"
                    title={this.state.isTreatmentAddressList || this.state.actionMode === "Add" ? "Cancel" : "Back"}
                    onPress={() =>this.state.isTreatmentAddressList || this.state.actionMode === "Add" ? this.onCancel() : this.onBack()}
                    style={[commonStyles.blueBorderButton, styles.addBtn, clientStyle.backAndCancelButton]}
                    titleTextStyle={[
                      commonStyles.blueBorderButtonColor,
                      commonStyles.fontWeight500,
                      commonFontStyles.workSans,
                      commonStyles.exSmallHeading,
                      clientStyle.backAndCancelButtonText
                    ]}
                  />
                </View>
                <View
                  style={[
                    styles.addProviderBtnHolder,
                    commonStyles.marginLeft8
                  ]}
                >
                  {!!isTreatmentAddressList ? (
                    <Button
                      key="editButton"
                      title="Edit"
                      onPress={this.onEditProvider}
                      // style={[commonStyles.blueBorderButton, styles.addBtn]}
                      // titleTextStyle={[
                      //   commonStyles.blueBorderButtonColor,
                      //   commonStyles.fontWeight500,
                      //   commonFontStyles.workSans,
                      //   commonStyles.heading6,
                      // ]}
                      style={[commonStyles.blueButton, styles.addBtn, customCommonStyles.noBoxShadow, clientStyle.submitButton]}
                      titleTextStyle={[
                        commonStyles.blueButtonColor,
                        commonStyles.fontWeight500,
                        commonFontStyles.workSans,
                        commonStyles.exSmallHeading,
                        commonStyles.whiteColor,
                        clientStyle.submitButtonText
                      ]}
                    />
                  ) : (
                      <SubmitButton
                        key="Add"
                        title={this.state.actionMode}
                        style={[commonStyles.blueButton, styles.addBtn, customCommonStyles.noBoxShadow, clientStyle.submitButton]}
                        titleTextStyle={[
                          commonStyles.blueButtonColor,
                          commonStyles.fontWeight500,
                          commonFontStyles.workSans,
                          commonStyles.exSmallHeading,
                          commonStyles.whiteColor,
                          clientStyle.submitButtonText
                        ]}
                      />
                    )}
                </View>
              </View>
            )}
          </Form>
          {!!isTreatmentAddressList ? this.renderTreatmentAddressList() : null}
          {this.renderEditTreatmentAddress()}
        </View>
      );
    }

    renderTreatmentAddressList() {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const { provider } = this.state;
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <View style={[commonStyles.marginTop15]}>
          {/* Treatment Address Title */}
          <View
            style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween, commonStyles.paddingLeftRight16]}
          >
            <View>
              <Text style={[
                commonStyles.blueButtonColor,
                commonStyles.fontWeight500,
                commonFontStyles.workSans,
                commonStyles.heading5,
                commonStyles.whiteColor,
                commonStyles.darkGrayColor
              ]}>{'Treatment Address'}</Text>
            </View>
            <View>
              <Touchable
                style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentFlexEnd, commonStyles.alignItemCenter]}
                onPress={() => this.updateDoctor({ ...provider, isUpdateTreatmentAddress: true })}
              >
                <Image source={plusSolidIcon} style={commonStyles.icon20} />
                <Text style={[
                  commonStyles.blueButtonColor,
                  commonStyles.fontWeight500,
                  commonFontStyles.workSans,
                  commonStyles.heading5,
                  commonStyles.darkGrayColor,
                  commonStyles.marginLeft8
                ]}>Add Address</Text>
              </Touchable>
            </View>
          </View>
          {/* Treatment Address List */}
          {/* {doctorDetails.length > 0 ? ( */}
          {provider && provider.treatmentAddresses && provider.treatmentAddresses.length > 0 ? (
            !!isMobileDevice ? null :
              (<View style={[styles.table, commonStyles.margin0, commonStyles.padding0, styles.borderWidth0, commonStyles.marginTop15, commonStyles.paddingLeftRight16, clientStyle.secondaryBgColor]}>
                <View
                  style={[
                    styles.thead,
                    commonStyles.flexGrow0,
                    commonStyles.justifyContentSpaceBetween,
                    commonStyles.flexDirectionRow,
                    // !!dashboarScroll ? styles.customPaddingRight16 : null
                  ]}>
                  <View style={styles.th}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.tableKey]}>Address</Text>
                  </View>
                  <View style={styles.th}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.tableKey]}>City</Text>
                  </View>
                  <View style={styles.th}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.tableKey]}>State</Text>
                  </View>
                  <View style={styles.th}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.tableKey]}>Zip Code</Text>
                  </View>
                  <View
                    style={[
                      styles.th,
                      styles.action,
                      // !!dashboarScroll ? styles.customMarginRight16 : null
                    ]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.tableKey]}>Action</Text>
                  </View>
                </View>
              </View>)
          ) : null}
          <View>
            <FlatList
              style={[styles.providerListComponent, provider && provider.treatmentAddresses && provider.treatmentAddresses.length > 0 ? customCommonStyles.customProviderListComponent : styles.noTreatmentItemsComponent, styles.customMarginTop, styles.borderBottomWidth0, clientStyle.secondaryBgColor]}
              data={provider && provider.treatmentAddresses ? provider.treatmentAddresses : []}
              keyExtractor={(el, i) => i}
              // onLayout={(event) => this.getHeightOfElement(event)}
              ListEmptyComponent={
                <View style={styles.padd10}>
                  <NoRecordsFound isUberDocs={isUberDocs} noRecords={[commonStyles.marginTop15, styles.noTreatmentAddressRecords, clientStyle.primaryBgColor]} message={"No treatment address to display."} />
                </View>
              }
              renderItem={({ item, index }) => (
                <View style={styles.body}>
                  <View style={[styles.tr, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.flex1, styles.customFlexDirection]}>
                      {/* Address */}
                      <View style={[styles.td, commonStyles.paddingRight16, item.isPrimary ?  commonStyles.flexDirectionRow : null]}>
                        <View
                          style={[
                            commonStyles.mView,
                            commonStyles.marginBottom5,
                            styles.mobileLabels,
                          ]}
                        >
                          <Text
                            style={[
                              commonFontStyles.workSans,
                              commonStyles.fontWeight700,
                              commonStyles.heading6,
                            ]}
                          >
                            Address:
                          </Text>
                        </View>
                        {/* Primary Address icon */}
                        <View style={[ item.isPrimary ? null : commonStyles.displayNone, item.isPrimary ? styles.paddingRight5 : null ]}>
                          <Image source={primaryIcon} style={commonStyles.icon18} />
                        </View>
                        <View style={[customCommonStyles.valueInMbileBreakWord]}>
                          <Text
                            style={[commonFontStyles.workSans, commonStyles.heading6]}
                          >
                            {item.addressLine1}
                          </Text>
                        </View>
                      </View>

                      {/* <View style={[styles.td]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.breakWord]}>{item.town}</Text>
                      </View> */}
                      {/* town */}
                      <View style={[styles.td, commonStyles.paddingRight16]}>
                        <View
                          style={[
                            commonStyles.mView,
                            commonStyles.marginBottom5,
                            styles.mobileLabels,
                          ]}
                        >
                          <Text
                            style={[
                              commonFontStyles.workSans,
                              commonStyles.fontWeight700,
                              commonStyles.heading6,
                            ]}
                          >
                            City:
                          </Text>
                        </View>
                        <View style={[customCommonStyles.valueInMbileBreakWord]}>
                          <Text
                            style={[commonFontStyles.workSans, commonStyles.heading6]}
                          >
                            {item.town}
                          </Text>
                        </View>
                      </View>
                      {/* state */}
                      {/* <View style={[styles.td]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.breakWord]}>{item.state}</Text>
                      </View> */}
                      <View style={[styles.td, commonStyles.paddingRight16]}>
                        <View
                          style={[
                            commonStyles.mView,
                            commonStyles.marginBottom5,
                            styles.mobileLabels,
                          ]}
                        >
                          <Text
                            style={[
                              commonFontStyles.workSans,
                              commonStyles.fontWeight700,
                              commonStyles.heading6,
                            ]}
                          >
                            State:
                          </Text>
                        </View>
                        <View style={[customCommonStyles.valueInMbileBreakWord]}>
                          <Text
                            style={[commonFontStyles.workSans, commonStyles.heading6]}
                          >
                            {!!item.state? item.state.split('-').pop() : ""}
                          </Text>
                        </View>
                      </View>
                      {/* post code */}
                      {/* <View style={[styles.td]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.breakWord]}>{item.postCode}</Text>
                      </View> */}
                      <View style={[styles.td, commonStyles.paddingRight16]}>
                        <View
                          style={[
                            commonStyles.mView,
                            commonStyles.marginBottom5,
                            styles.mobileLabels,
                          ]}
                        >
                          <Text
                            style={[
                              commonFontStyles.workSans,
                              commonStyles.fontWeight700,
                              commonStyles.heading6,
                            ]}
                          >
                            Zip Code:
                          </Text>
                        </View>
                        <View style={[customCommonStyles.valueInMbileBreakWord]}>
                          <Text
                            style={[commonFontStyles.workSans, commonStyles.heading6]}
                          >
                            {item.postCode}
                          </Text>
                        </View>
                      </View>
                      <View style={[commonStyles.webView]}>
                        {this.renderEditandDeleteSection({ ...provider, treatmentAddresses: item, isUpdateTreatmentAddress: true })}
                      </View>
                    </View>
                    <View style={[styles.customMView]}>
                      {this.renderEditandDeleteSection({ ...provider, treatmentAddresses: item, isUpdateTreatmentAddress: true })}
                    </View>
                  </View>
                </View>
              )}
            />
          </View>
        </View>
      )
    }

    renderEditTreatmentAddress() {
      const { provider } = this.state;
      const { isUberDocs } = this.props.app.dm.userSession.state;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <View style={[commonStyles.paddingLeftRight16, !!this.state.isEditTreatmentAddress ? null : commonStyles.displayNone]}>
          {/* Treatment Address Title */}
          <View>
            <Text style={[
              commonStyles.blueButtonColor,
              commonStyles.fontWeight500,
              commonFontStyles.workSans,
              commonStyles.heading6,
              commonStyles.darkGrayColor
            ]}>{!!provider && !!provider.treatmentAddresses && !!provider.treatmentAddresses.stateObject && !!provider.treatmentAddresses.stateObject.value ? 'Edit Treatment Address' : 'Add New Treatment Address'}</Text>
          </View>
          <Form
            name="doctorAddressForm"
            style={[commonStyles.dFlex, commonStyles.flexDirectionColumn, commonStyles.paddingLeft0, commonStyles.paddingRight0]}
            onSubmit={this.handleAddressSubmit}
            validators={CommonValidators.getFormValidator()}
            validateBeforeSubmit={true}
          >
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.marginBottom16, commonStyles.flexGrow0, !!this.state.isPrimaryAddress ? null : commonStyles.alignItemsCenter]}>
              <Checkbox
                name="isPrimaryAddress"
                label={CommonString.PrimaryAddressLabel}
                value={this.state.isPrimaryAddress}
                disabled={provider && provider.treatmentAddresses && provider.treatmentAddresses.isPrimary ? true : false}
                onPress={() => this.toggleIsPrimaryAddress()}
              />
              <View style={[commonStyles.marginTop5]}>
                <Text
                  style={[
                    commonStyles.fontWeight500,
                    commonStyles.heading6,
                    commonFontStyles.workSans,
                    commonStyles.marginBottom5,
                  ]}
                >Primary Address</Text>
              </View>
            </View>
            <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.provideName,
                ]}
              >
                <TextInputField
                  name="addressLine1"
                  label="Address*"
                  required={CommonString.EnterAddressRequired}
                  validator="min2Max70"
                  multiline
                  style={[
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    customCommonStyles.customSsidInputField,
                    styles.commentsField,
                    customCommonStyles.inputHeight,
                    commonStyles.paddingTop8
                ]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,                    
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.enterNPI,
                  styles.customMarginTop30
                ]}
              >
                <SelectMenuField
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, commonStyles.marB0, commonStyles.paddingTop8]}
                  name="stateObject"
                  label={`${CommonString.StatesLabel}*`}
                  required={CommonString.SelectStateRequired}
                  items={this.statesList}
                  onValueChange={this.onStateChange}
                  searchable
                  itemLoader={async ({ search }) => {
                    return await this.onSearchStateCity(SEARCH_TYPE.STATE, false, search);
                  }}
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                />
              </View>
            </View>

            <View style={[commonStyles.dFlex, styles.textFieldsHolder]}>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.provideName,
                  commonStyles.marTop30
                ]}
              >
                <SelectMenuField
                  style={[commonFontStyles.workSans, commonStyles.heading6, commonFontStyles.workSans, commonStyles.marB0]}
                  name="cityObject"
                  label={`${CommonString.CityLabel}*`}
                  required={CommonString.SelectCityRequired}
                  items={this.citiesList}
                  onValueChange={this.onCityName}
                  searchable
                  itemLoader={async ({ search }) => {
                    return await this.onSearchStateCity(SEARCH_TYPE.CITY, false, search);
                  }}
                  
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, customCommonStyles.selectMenuInputLabelStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.enterNPI,
                  commonStyles.marTop30
                ]}
              >
                <TextInputField
                  name="postCode"
                  label={`${CommonString.ZipCodeLabel}*`}
                  required={CommonString.EnterZipCodeRequired}
                  validator="min5Max10"
                  onChangeText={this.trimExceededCharZipCode}
                  style={[commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.customSsidInputField, customCommonStyles.inputHeight]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                />
              </View>
            </View>
            <View style={[commonStyles.dFlex, styles.textFieldsHolder, commonStyles.smallModalBtnWrapper, styles.marTop30]}>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.provideName
                ]}
              >
                <TextInputField
                  name="licenseDetails"
                  label={CommonString.StateLicenseLabel}
                  validator="max20AlphaNumeric"
                  onChangeText={(txt) => this.formatLicenseText(txt, "doctorAddressForm")}
                  style={[
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    customCommonStyles.customSsidInputField,
                    styles.commentsField,
                    customCommonStyles.inputHeight
                ]}
                  labelTextStyle={[
                    commonStyles.labelText,
                    commonFontStyles.workSans,                    
                    commonStyles.heading6,
                    clientStyle.secondaryBgColor
                  ]}
                  labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                  helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                />
              </View>
              <View
                style={[
                  commonStyles.flex1,
                  commonStyles.flexBasis48,
                  styles.enterNPI,
                  styles.customMarginTop30
                ]}
              >
              </View>
            </View>

            <View
              style={[
                commonStyles.marTAuto,
                commonStyles.flex1,
                styles.buttonWrapper,
                commonStyles.flexDirectionRow,
                commonStyles.marTop30
              ]}
            >
              <View
                style={[
                  commonStyles.marginRight8,
                  styles.addProviderBtnHolder,
                ]}
              >
                <Button
                  key="back"
                  title="back"
                  onPress={this.onBack}
                  style={[commonStyles.blueBorderButton, styles.addBtn, clientStyle.backAndCancelButton]}
                  titleTextStyle={[
                    commonStyles.blueBorderButtonColor,
                    commonStyles.fontWeight500,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    clientStyle.backAndCancelButtonText
                  ]}
                />
              </View>
              <View
                style={[
                  styles.addProviderBtnHolder,
                  commonStyles.marginLeft8
                ]}
              >
                <SubmitButton
                  key="Add"
                  title={!!provider && !!provider.treatmentAddresses && !!provider.treatmentAddresses.stateObject && provider.treatmentAddresses.stateObject.value ? 'Update' : 'Add'}
                  style={[commonStyles.blueButton, styles.addBtn, customCommonStyles.noBoxShadow, clientStyle.submitButton]}
                  titleTextStyle={[
                    commonStyles.blueButtonColor,
                    commonStyles.fontWeight500,
                    commonFontStyles.workSans,
                    commonStyles.exSmallHeading,
                    commonStyles.whiteColor,
                    clientStyle.submitButtonText
                  ]}
                />
              </View>
            </View>
          </Form>
        </View>
      )
    }

    renderEditandDeleteSection(item) {
      return (
        <View style={[styles.td, styles.action]}>
          <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
            <Touchable onPress={() => this.updateDoctor(item)}>
              <View style={styles.editAction}>
                <Image source={editImage} style={commonStyles.icon18} />
              </View>
            </Touchable>
            <Touchable onPress={() => this.openPopScreen(item.uid, item.treatmentAddresses && item.treatmentAddresses.uid, item)} style={styles.customMarginLeft15}>
              <View>
                <Image source={deleteImage} style={commonStyles.icon18} />
              </View>
            </Touchable>
          </View>
        </View>
      );
    }
    renderProviderList(clientStyle, commonFontStyles) {
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const { doctorDetails, currentPage, resultsPerPage, totalBills } = this.props.app.dm.addDoctorsDomain.state;

      let startIndex = ((currentPage - 1) * resultsPerPage) + 1;
      let endIndex = totalBills < currentPage * resultsPerPage ? totalBills : currentPage * resultsPerPage;
      let rowItems = endIndex - startIndex + 1;

      const { actionMode } = this.state;
      return (
        <View style={[actionMode === "Listing" ? styles.height85 : commonStyles.displayNone]}>
          {doctorDetails.length > 0 ? (
            !!isMobileDevice ? null :
              (<View style={styles.table}>
                <View
                  style={[
                    styles.thead,
                    commonStyles.flexGrow0,
                    commonStyles.justifyContentSpaceBetween,
                    commonStyles.flexDirectionRow,
                    // !!dashboarScroll ? styles.customPaddingRight16 : null
                  ]}>
                  <View style={styles.th}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.tableKey]}>Provider Name</Text>
                  </View>
                  <View style={styles.th}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.tableKey]}>NPI</Text>
                  </View>
                  <View
                    style={[
                      styles.th,
                      styles.action,
                      // !!dashboarScroll ? styles.customMarginRight16 : null
                    ]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6, commonStyles.tableKey]}>Action</Text>
                  </View>
                </View>
              </View>)
          ) : null}
          <View style={[styles.providerListComponent]}>
            <FlatList
              style={[doctorDetails.length > 0 ? customCommonStyles.customProviderListComponent : styles.noItemsComponent, styles.customProviderListHeight]}
              data={doctorDetails}
              keyExtractor={(el, i) => i}
              // onLayout={(event) => this.getHeightOfElement(event)}
              ListEmptyComponent={
                <View style={styles.padd10}>
                  <NoRecordsFound noRecords={[commonStyles.marginTop15, styles.noProviderRecords]} message={"No providers found."} />
                </View>
              }
              renderItem={({ item, index }) => (
                <View style={styles.body}>
                  <View style={[styles.tr, commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentSpaceBetween]}>
                    <View
                      style={[commonStyles.flex1, styles.customFlexDirection]}>
                      <View style={[styles.td]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.customFontWeight500, styles.breakWord, commonStyles.tableValue]}>
                          {item.DoctorName}
                        </Text>
                      </View>
                      <View style={[styles.td]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, styles.breakWord,  commonStyles.tableValue]}>{item.NPI}</Text>
                      </View>
                      <View style={[commonStyles.webView]}>
                        {this.renderEditandDeleteSection({ ...item, showTreatmentAddressList: true })}
                        {/* {alert('button pressed 2')} */}

                      </View>
                    </View>
                    <View style={[styles.customMView]}>
                      {this.renderEditandDeleteSection({ ...item, showTreatmentAddressList: true })}
                    </View>
                  </View>
                </View>
              )}
            />
            {this.props.app.dm.addDoctorsDomain.state.totalPages > 1 ?
            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.paginationWrap, commonStyles.flexGrow0, customCommonStyles.customPaginationWrap, styles.customMargin]}>
              <View style={[commonStyles.rowPerPage]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}>Rows per page: {rowItems}</Text>
              </View>
              <View style={[commonStyles.displayingItem]}>
                <Text style={[commonStyles.darkGrayColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}>Displaying item {startIndex}-{endIndex} of {totalBills}</Text>
              </View>
              <View style={[commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, commonStyles.paginationInnerWrap, commonStyles.flexGrow0]}>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.addDoctorsDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.addDoctorsDomain.state.currentPage === 1 ? null : this.getFirst()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.addDoctorsDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowFirst} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.addDoctorsDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.addDoctorsDomain.state.currentPage === 1 ? null : this.getPrevious()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.addDoctorsDomain.state.currentPage === 1 ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowLeft} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.addDoctorsDomain.state.currentPage === this.props.app.dm.addDoctorsDomain.state.totalPages ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.addDoctorsDomain.state.currentPage === this.props.app.dm.addDoctorsDomain.state.totalPages ? null : this.getNext()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.addDoctorsDomain.state.currentPage === this.props.app.dm.addDoctorsDomain.state.totalPages ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowRight} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
                <View style={[styles.paginationBtnWrap]}>
                  <Touchable
                    style={[this.props.app.dm.addDoctorsDomain.state.currentPage === this.props.app.dm.addDoctorsDomain.state.totalPages ? customCommonStyles.disabledInput : null]}
                    onPress={() => this.props.app.dm.addDoctorsDomain.state.currentPage === this.props.app.dm.addDoctorsDomain.state.totalPages ? null : this.getLast()}>
                    <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, this.props.app.dm.addDoctorsDomain.state.currentPage === this.props.app.dm.addDoctorsDomain.state.totalPages ? customCommonStyles.disabledInput : null]}>
                      <Image source={arrowLast} style={commonStyles.icon20} />
                    </View>
                  </Touchable>
                </View>
              </View>
            </View>
            : null}
          </View>
          <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} clientStyle={clientStyle} />
        </View>
      );
    }

    render() {
      const { actionMode, isTreatmentAddressList, isConfirmationVisible, isPrimaryAddress, isAddressUpdation, addressConfirmationMessage } = this.state;
      const { isAddProviderBackConfirmation } = this.props.app.dm.addDoctorsDomain.state;
      const { isUberDocs } = this.props.app.dm.userSession.state;

      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <>
          <View style={[commonStyles.marginTop15, commonStyles.paddingLeftRight16, commonStyles.flex1, commonStyles.justifyContentSpaceBetween, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.flexGrow0, commonStyles.alignItemsCenter, commonStyles.marginBottom10]}>
            <View>
              {
                actionMode === "Listing" ?
                  <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.titleTextColor]}>{this.props.r14.navigation.currRoute._config.label}</Text>
                  :
                  <Text style={[commonStyles.heading3, commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.titleTextColor]}>{actionMode === "Update" ? "Edit" : "Add"} Provider</Text>
              }
            </View>
            <View>
              {actionMode !== "Listing" ? null : (
                <Touchable style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.alignItemsCenter, commonStyles.primaryButton, commonStyles.btnHeight24 ]} onPress={this.toggleProviderFormList}>
                  <View style={[
                    commonStyles.flex1,
                    commonStyles.flexDirectionRow,
                    commonStyles.alignItemsCenter,
                    commonStyles.flexGrow0,
                    commonStyles.justifyContentFlexEnd,
                    commonStyles.marginBottom3,
                  ]}>
                    {/* <View>
                    <Image source={plusSolidIcon} style={[commonStyles.icon20]} />
                  </View> */}
                    <View>
                      <Text style={[commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.exSmallHeading, styles.custompaddingLeftRight, commonStyles.whiteColor]}>
                        Add Provider
                      </Text>
                    </View>
                  </View>
                </Touchable>
              )}
            </View>
          </View>
            {this.renderProviderList(clientStyle, commonFontStyles)}
            {this.renderAddProviderForm()}
          <PopupModalComponent
            visibility={isConfirmationVisible || isAddProviderBackConfirmation || !!isAddressUpdation}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.confirmationModal, clientStyle.secondaryBgColor]}
            messageType={!!isAddressUpdation ? MESSAGE_TYPE.info : !isAddProviderBackConfirmation && !isPrimaryAddress ? MESSAGE_TYPE.delete : MESSAGE_TYPE.info}
            headingIcon={true}
            heading={
              !!isTreatmentAddressList ? "Delete Address" : 
              !!isAddProviderBackConfirmation ? "Info" : 
              !!isPrimaryAddress ? "Update Primary Address" : 
              isAddressUpdation === "updateAddress" ? "Update Suggested Address" :
              !!isAddressUpdation ? "Update Address" :
              "Delete Provider" 
            }
            modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
            bodyContent={!!isAddressUpdation ? addressConfirmationMessage : isAddProviderBackConfirmation ? "Treatment address is required when submitting claim. Continue?" : !!isPrimaryAddress ? "This address will be considered as Primary Service Address. Do you want to proceed?" : "Are you sure you want to delete?"}
            okLabel={(isAddressUpdation === "alertMessage") ? "Ok" : "No"}
            handleOk={() => !!isAddressUpdation ? this.onUpdateAddress(false) : this.closePopScreen()}
            closeLabel={"Yes"}
            handleClose={isAddressUpdation === "alertMessage" ? null : () => 
              !!isAddressUpdation ? this.onUpdateAddress(true) :
              !!isTreatmentAddressList ? this.deleteAddress() : 
              !!isAddProviderBackConfirmation ? this.onBack() : 
              !!isPrimaryAddress ? this.handleAddUpdateAddressSubmit(this.props.app.ui.form("doctorAddressForm")) : 
              this.deleteDoctor() 
            }
          />  
          </>
      );
    }
  }

);

const styles = StyleSheet.create({
  table: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    backgroundColor: "#fff",
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    borderBottomWidth: 0,
    // screen: ({ width }) => {
    //   if (width <= 991)
    //     return {
    //       display: "none"
    //     };
    // },
  },  
  textFieldsHolder: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexDirection: "column",
        };
    },
  },
  aadBtn: { minWidth: 100 },
  thead: {
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    flex: 1,
    flexDirection: "row",
    zIndex: 999,
    backgroundColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    maxHeight: 40,
    minHeight: 25,
    alignItems: 'center',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  tr: {
    justifyContent: "space-between",
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTop: 0,
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    minHeight: 30,
    alignItems: 'center',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 0,
          marginLeft: 0,
          borderLeft: 0,
          borderRight: 0,
          flex: 1,
          flexDirection: "row",
        };
    },
  },
  th: {
    flex: 1,
    flexBasis: "33%",
    maxWidth: "33%",
  },
  td: {
    flex: 1,
    flexBasis: "33%",
    maxWidth: "33%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "100%",
          maxWidth: "100%",
          flexDirection: "row",
        };
    },
  },
  action: {
    flexBasis: "0%",
    minWidth: 63,
    maxWidth: 63,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          maxWidth: 80,
          paddingRight: 0,
          // position: "absolute",
          // top: 0,
          // right: 0,
          // zIndex: 99999999,
        };
    },
  },
  providerListComponent: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginLeft: 16,
    paddingTop: 0,
    paddingBottom: 16,
    display: "block",
    flexGrow: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    height: "100%",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginTop: 184,
        };
      }
      if (width >= 320 && width <= 991) {
        return {
          borderTopWidth: 1,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginRight: 0,
          marginLeft: 0,
        };
      }
    },
  },
  provideName: {
    marginRight: 8,
    marginBottom: 2,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 0,
        };
    },
  },
  enterNPI: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginLeft: 0,
        };
    },
  }, 
  rightInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
          flexBasis: "100%",
        };
      }
    },
  },
  leftInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "100%",
          marginLeft: 0,
        };
      }
    },
  },

  buttonWrapper: {
    justifyContent: "flex-end",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          justifyContent: "space-between",
        };
      }
    },
  },
  addBtn: {
    minWidth: 108,
    // width: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  addProviderBtnHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  addDoctorsSection: {
    backgroundColor: "#fff",
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dfdfdf",
    flexGrow: 1,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          minHeight: 350,
        };
      }
    },
  },

  customFontWeight600: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          fontWeight: "bold",
        };
    },
  },

  btnWrapper: {
    marginTop: 24,
    // marginBottom: 15,
  },
  bodyWrapper: {
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          fontWeight: 600,
        };
    },
  },
  noButton: {
    minWidth: 79,
  },
  customPaddingRight16: {
    paddingRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          paddingRight: 0,
        };
      }
    },
  },
  customMarginRight16: {
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  customHelperTextCss: {
    screen: ({ width }) => {
      if (width <= 359) {
        return {
          lineHeight: 12,
          bottom: -26,
          position: "static",
        };
      }
    },
  },
  breakWord: {
    wordBreak: "break-word",
  },
  customTopMargin: {
    marginTop: 50,
  },
  buttons: {
    height: 38,
    minWidth: 100,
    // width: "100%",
  },
  editAction: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 16,
        };
      }
    },
  },
  customMarginLeft15: {
    marginLeft: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customFlexDirection: {
    flexDirection: "row",
    justifyContent: "space-between",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
          maxWidth: "80%",
          flexBasis: "80%",
          flexGrow: 1,
        };
      }
    },
  },
  customMView: {
    display: "none",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 4,
          display: "flex",
          // flex: 1,
        };
      }
    },
  },
  custompaddingLeftRight: {
    ...StyleSheet.padding(0, 10, 0, 5),
  },
  noProviderRecords: {
    marginBottom: 250,
  },
  noTreatmentAddressRecords: {
    marginBottom: 50,
  },
  copyRightWrap: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  paginationWrap: {
    marginTop: "auto",
    marginRight: 50,
    marginBottom: 0,
    marginLeft: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 17,
          paddingTop: 20,
          marginBottom: 70,
        }
      }
    }
  },
  paginationBtn: {
    borderWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  paginationBtnTextSize: {
    fontSize: 14,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          fontSize: 12,
        }
      }
    },
  },
  noItemsComponent: {
    marginTop: 67,
    marginRight: 16,
    marginBottom: 50,
    marginLeft: 16,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 60,
          marginRight: 0,
          marginBottom: 100,
          marginLeft: 0,
        };
    },
  },
  noTreatmentItemsComponent: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 60,
          marginRight: 0,
          marginBottom: 100,
          marginLeft: 0,
        };
    },
  },
  closeBtn: {
    position: "absolute",
    right: 0,
    top: 4,
  },
  areYou: {
    // marginTop: -7,
  },
  inputLableStyle: {
    top: 16,
  },
  confirmationModal: {
    minHeight: 110,
  },
  borderWidth0: {
    borderWidth: 0
  },
  customMarginTop: {
    margin: 0,
    position: "relative",
    borderWidth: 0,
    top: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 16,
        };
    },
  },
  borderBottomWidth0: {
    borderBottomWidth: 0
  },
  disable: {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "auto",
  },
  minHeight150: {
    minHeight: 200
  },
  customMarginTop30: {
    screen: ({ width }) => {
      if (width <= 991)
      return {
        marginTop: 30,
      };
    },
  },
  mobileLabels: {
    marginRight: 10,
    minWidth: 100,
  },
  commentsField: {
    resize: "none",
    lineHeight: 15,
  },
  paddingRight5: {
    paddingRight: 5
  },
  marginBottom32: {
    screen: ({ width }) => {
      if (width <= 991)
      return {
        marginBottom: 32
      };
    },
  },
  customProviderListHeight: {
    position: 'relative',
    marginTop: 0,
    top: 0,
  },
  countryCode: {
    maxWidth: 75,
  },
  mobileNumberInput: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 15,
        };
      }
    },
  },
  marTop30: {
    marginTop: 30,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 36,
        };
      }
    },
  },
  height85:{
    height: "85%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: "90%",
        };
      }
    },
  },
  customMargin:{
    marginLeft:16,
    marginRight:16,
    marginTop:10,
    borderWidth: 0
  }
});
