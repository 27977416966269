import React from "react";
import R14 from "../../core";
import AddDoctors from "../../components/PCH/AddDoctors";

export default R14.connect(
  class ProviderListScreen extends React.Component {
    render() {
      const { providerItems, defaultMode } = this.props;
      return (
        <AddDoctors defaultMode={defaultMode} providerItems={providerItems} userLoginDetail={this.props.userLoginDetail} />
      );
    }
  }
); 
