import React from "react";
import R14, {
    View,
    StyleSheet,
    Button,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { MESSAGE_TYPE } from "../../constant/app.data.constant";
import PopupModalComponent from "../../components/PopupModalComponent";
import {analyticsLogs} from "../../utils/AnalyticLogs";

import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";
import ManagePaymentDetailsComponent from "../../components/PCH/ManagePaymentDetailsComponent";
import EditManagePaymentComponent from "../../components/PCH/EditManagePaymentComponent";
import TableListComponent from "../../components/TableListComponent";
import ModalPopUp from "../../components/ModalPopUp";
import PDFViewerComponent from "../../components/PCH/PDFViewerComponent";
import PdfListModalComponent from "../../components/PdfListModalComponent";
import HtmlDecode from '../../utils/HtmlDecode';
import DateUtils from "../../utils/DateUtils"; 
import ViewSubmittedClaimComponent from "../../components/PCH/ViewSubmittedClaimComponent";

const editIcon = require("../../assets/images/edit.png");

export default R14.connect(
    class ViewManagePaymentScreen extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                billUid: this.props.billUid,
                uploadedBill: this.props.uploadedBill,
                isConfirmationPopUp: false,
                isVisiable: false,
                selectedItem: {},  // for edit claim
                isEditPaymentVisible: false,
                isPaymentConfiramtion: false,
                claimPayments: [],
                totalCount: !!this.props.uploadedBill && !!this.props.uploadedBill.claimPayments ? this.props.uploadedBill.claimPayments.length : 0,
                pdfs: [],
                isDocVisible: false,
                fileContent: "",
                signedUrl: "",
                selectedBill: {}, // for view/download document
                fileContentUnsecure: "",
                isPdfConfirmation: false,
                selectedItemType: "",
                isErrorVisible: false,
                errorMessage: "",
                isEditAvailable: false,
                isViewClaim: false,
            };
        }

        componentDidMount = async () => {
            try {
                if(!!this.props.uploadedBill && !!this.props.uploadedBill.claimPayments) {
                    const payments = this.props.uploadedBill.claimPayments.map(bill => {
                        return {
                            ...bill,
                            paidDate: DateUtils.formatDateInMMDDYYYY(bill.paidDate)
                        }
                    });
                    this.setState({claimPayments: payments})
                }
            }
            catch (err) {
                this.setState({ loaderVisible: false })
                console.log(err)
            }
        };

        onActionClick = async (item, act) => {
            if (act === "action") {
                this.setState({
                    isEditPaymentVisible: true,
                    selectedItem: item,
                    isEditAvailable: true
                })
            } else if (act === "delete") {
                this.setState({
                    isConfirmationPopUp: true,
                    selectedItem: item
                })
            };
        }

        handleDelete = async (act) => {
            const { isPaymentConfiramtion, isConfirmationPopUp } = this.state;
            if (!!isConfirmationPopUp && act === "yes") {
                const bill = {
                    uid: this.state.selectedItem.uid,
                    isDeleted: "true",
                }
                const res = await this.props.app.dm.claimPaymentDomain.updateClaimPayment(bill);
                if (res && res.uid) {
                    this.props.app.nav.back();
                    this.setState({
                        isConfirmationPopUp: false,
                        isEditPaymentVisible: false,
                        isPaymentConfiramtion: false,
                        selectedItem: {}
                    });
                    analyticsLogs(CommonString.MANAGE_PAYMENT, CommonString.DELETE_PAYMENT, this.props.app);
                    this.props.app.dm.commonDomain.showToastr("error", "Payment deleted successfully.");
                } else {
                    this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
                }
            } else if (isPaymentConfiramtion && act === "no") {
                this.props.app.nav.back();
            } else if (act === "no") {
                this.setState({
                    isConfirmationPopUp: false,
                    isPaymentConfiramtion: false
                });
            }
            return false;
        }

        handleSubmitPayment = async (form) => {
            const {
                paidAmount = "0",
                deductible = "",
                copay = "",
                coinsurance = "",
                comments = ""
            } = form.values;
            this.onLoaderHanlde(true);
            const bill = {
                uid: this.state.selectedItem.uid,
                paidAmount: this.removeCommas(paidAmount),
                deductible: this.removeCommas(deductible),
                copay: this.removeCommas(copay),
                coinsurance: this.removeCommas(coinsurance),
                comments,
                updatedBy: this.props.userLoginDetail.uid,
                updatedAt: new Date()
            }
            const res = await this.props.app.dm.claimPaymentDomain.updateClaimPayment(bill);
            if (res && res.uid) {
                this.setState({
                    isEditPaymentVisible: true,
                    isPaymentConfiramtion: true,
                    selectedItem: {}
                });
            } else {
                this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            };
            this.onLoaderHanlde(false)
            return false;
        }

        onCancelPayment = async () => {
            this.props.app.nav.back();
        }

        onUploadEOBPayment = async () => {
            alert('on upload eob')
        }

        removeCommas = (formattedNumber = "") => {
            return !!formattedNumber ? formattedNumber.toString().replace(/,/g, "") : "";
        };

        numberWithCommas = (number = "") => {
            const num = this.removeCommas(number);
            return !!num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
        };

        onLoaderHanlde = async (status) => {
            this.setState({ isVisiable: !!status ? status : !this.state.isVisiable });
        }

        onViewEOB = async (item, type = "View") => {
            const { documentId } = item;
            if (!documentId) return false;
            this.setState({ isVisiable: true });
            const filter = {
                filter: {
                    documentId: {
                        eq: documentId
                    }
                },
                sort: {
                    field: "seqNo",
                    order: "ASC"
                }
            }
            let documentLogs = await this.props.app.dm.uploadBillDomain.documentLogs(filter);
            this.setState({ isPdfConfirmation: true, selectedItem: item, selectedItemType: type, pdfs: documentLogs, isVisiable: false })
        }

        onDownloadOrViewBillDocuments = async (selectedBill, docAction) => {
            try {
                this.setState({ isVisiable: true, selectedBill });
                if (!!true) {
                    let options = {
                        responseType: "arraybuffer",
                        headers: {
                            "content-type": "application/pdf",
                        }
                    };
                    let signedUrl = await this.props.app.dm.uploadBillDomain.getObjectSignedUrl(selectedBill.mergedFileName);
                    let response = await this.props.app.dm.rest.getS3(signedUrl, options);
                    let billDocuments = Buffer.from(response.data, 'binary').toString('base64');

                    if (!!billDocuments) {
                        if (docAction === "View") {
                            this.setState({ fileContent: billDocuments, signedUrl: signedUrl, isDocVisible: true, fileContentUnsecure: billDocuments, isVisiable: false });
                        } else {
                            const item = {
                                content: `data:application/octet-stream;base64,${billDocuments}`,
                                contentType: 'PDF',
                                filename: selectedBill.mergedFileName
                            };
                            HtmlDecode.downloadFile(item);
                            this.setState({ isVisiable: false })
                        }
                    } else this.props.app.dm.commonDomain.showToastr("error", CommonString.FileNotFound);
                } else this.props.app.dm.commonDomain.showToastr("error", CommonString.FileInProcess);

                this.setState({ isVisiable: false })
            } catch (error) {
                this.setState({ isVisiable: false })
                console.log(error)
            }

        };

        showError = (errorMessage) => {
            this.setState({
                errorMessage: errorMessage
            })
        }

        onEditAvailable = (status) => {
            this.setState({isEditAvailable: (status === false || status === true) ? status : !this.state.isEditAvailable})
        }

        renderPDFViewer(clientStyle, isMobileDevice, ) {
            const { isDocVisible, fileContent, signedUrl, selectedBill, fileContentUnsecure } = this.state;
            return (
                <ModalPopUp
                    visibility={isDocVisible}
                    isFooter={true}
                    bodyWrapper={[commonStyles.largModalBodyWrapper, clientStyle.secondaryBgColor]}
                    heading={"View Document"}
                    handleCancel={() => this.setState({ isDocVisible: false })}
                    animationType="fade"
                    closeButton={customCommonStyles.viewPDFCloseBtn}
                >
                    <View>
                        <PDFViewerComponent closePDF={() => this.setState({ isDocVisible: false })} isMobileDevice={isMobileDevice} bills={selectedBill} signedUrl={signedUrl} dataSource={fileContent} dataSourceUnsecured={fileContentUnsecure} dataSourceType={selectedBill.dataSourceType || "pdf"} disableFileName={true} />
                    </View>
                </ModalPopUp>
            );
        }

        render() {
            const { isUberDocs } = this.props.app.dm.userSession.state;
            const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
            let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
            let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
            const {
                isEditPaymentVisible,
                isConfirmationPopUp,
                isPaymentConfiramtion,
                selectedItem,
                isVisiable,
                uploadedBill,
                claimPayments,
                isPdfConfirmation,
                selectedItemType,
                pdfs,
                errorMessage,
                isEditAvailable,
                isViewClaim
            } = this.state;

            return (
                <>
                    {isViewClaim ? <ViewSubmittedClaimComponent uploadBill={uploadedBill} userLoginDetail={this.props.userLoginDetail} managePayment={true} onViewClaimBack={() => this.setState({ isViewClaim: false })} /> : null}
                    <View style={[styles.moopSection, clientStyle.secondaryBgColor]}>
                        {this.renderPDFViewer(clientStyle, isMobileDevice, isUberDocs)}
                        <PdfListModalComponent
                            isPdfConfirmation={isPdfConfirmation}
                            clientStyle={clientStyle}
                            commonFontStyles={commonFontStyles}
                            selectedItem={selectedItem}
                            selectedItemType={selectedItemType}
                            pdfs={pdfs}
                            onDownloadOrViewBillDocuments={this.onDownloadOrViewBillDocuments}
                            onHidePdfConfimation={() => this.setState({ isPdfConfirmation: false })}
                            headerText={"EOB Document(s)"}
                        />
                        <ManagePaymentDetailsComponent
                            icn={uploadedBill.icn || ""}
                            providerGroup={uploadedBill.providerGroup || ""}
                            provider={uploadedBill.provider || ""}
                            dateOfService={uploadedBill.dateOfService || ""}
                            totalClaimAmount={uploadedBill.totalClaimAmount || ""}
                            paid={uploadedBill.paid || ""}
                            patientResponsibility={uploadedBill.patientResponsibility || ""}
                            balance={uploadedBill.balance || ""}
                            viewClaim={() => this.setState({ isViewClaim: true })}
                            viewPayments={null}
                        />
                        <View style={[commonStyles.paddingTop15]}>
                            <TableListComponent
                                dataList={claimPayments}
                                pagination={false}
                                labelKeys={["Check Number", "Paid Date", "Paid Amount", "Deductible", "Copay", "Coinsurance", "Action", "View"]}
                                labelValues={["checkNo", "paidDate", "paidAmount", "deductible", "copay", "coinsurance", "action:delete", ""]}
                                visibility={false}
                                icon={editIcon}
                                onActionClick={(item, act) => this.onActionClick(item, act)}
                                onViewClick={(documentId) => this.onViewEOB(documentId)}
                                message={"No payment found"}
                                containerStyle={styles.containerStyle}
                                height={styles.containerHeight}
                                dateTypeFields={["paidDate"]}
                                amountTypeFields={["paidAmount", "deductible", "copay", "coinsurance"]}
                            />
                        </View>
                        {!!isEditPaymentVisible ?
                            <EditManagePaymentComponent
                                handleSubmitPayment={this.handleSubmitPayment}
                                onCancelPayment={this.onCancelPayment}
                                onUploadEOBPayment={this.onUploadEOBPayment}
                                item={selectedItem}
                                numberWithCommas={this.numberWithCommas}
                                onLoaderHanlde={this.onLoaderHanlde}
                                showError={this.showError}
                                onEditAvailable={this.onEditAvailable}
                                isEditAvailable={isEditAvailable}
                            />
                            : null}

                        <View style={[commonStyles.flex1, styles.customFlexProperties, commonStyles.flexDirectionRow, !!isEditPaymentVisible ? commonStyles.displayNone : null]}>
                            <View style={[styles.formBtnsHolder]}>
                                <Button
                                    title={"Close"}
                                    style={[commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.btnWidth108, styles.customRestMargin]}
                                    titleTextStyle={[commonStyles.secondayWhiteBtnText, commonStyles.exSmallHeading, commonFontStyles.workSans]}
                                    onPress={() => this.props.app.nav.back()}
                                />
                            </View>
                        </View>
                    </View>
                    <PCHLoaderComponent isVisiable={isVisiable} labelText={"Please wait"} clientStyle={clientStyle} />
                    <PopupModalComponent
                        visibility={isConfirmationPopUp || isPaymentConfiramtion || errorMessage}
                        bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
                        messageType={!!errorMessage ? MESSAGE_TYPE.info : !isPaymentConfiramtion ? MESSAGE_TYPE.delete : MESSAGE_TYPE.info}
                        headingIcon={true}
                        heading={!!errorMessage ? "Error" : !isPaymentConfiramtion ? "Delete Payment" : "Payment Updated"}
                        modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
                        bodyContent={!!errorMessage ? errorMessage : !isPaymentConfiramtion ? `Are you sure you want to delete this payment.` : `Your payment has been updated successfully.`}
                        okLabel={!!errorMessage ? "Ok" : !isPaymentConfiramtion ? "No" : "Ok"}
                        handleOk={!!errorMessage ? () => this.setState({ errorMessage: "" }) : () => this.handleDelete('no')}
                        closeLabel={!!errorMessage ? "" : !isPaymentConfiramtion ? "Yes" : ""}
                        handleClose={!!errorMessage ? null : !isPaymentConfiramtion ? () => this.handleDelete('yes') : null}
                        modalHeading={[commonStyles.nativeModalHeading]}
                    />
                </>
            );
        }
    }
);
const styles = StyleSheet.create({
    pageTitle: {
        marginTop: 15,
        display: "flex",
        justifyContent: "space-between",
        minHeight: 30,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 10,
                    marginBottom: 10,
                };
            }
        },
    },
    moopSection: {
        backgroundColor: "#fff",
        marginRight: 16,
        marginBottom: 16,
        marginLeft: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        // flex: 1,
        // alignItems: "flex-start",
        // flexDirection: "row",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    paddingTop: 16,
                };
            }
        },
    },
    customFlexProperties: {
        padding: 16,
        paddingTop: 0,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: "auto",
                    marginBottom: 30
                };
            }
            if (width >= 991) {
                return {
                    justifyContent: "flex-end",
                };
            }
        },
    },
    formBtnsHolder: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexBasis: "100%",
                    marginBottom: 16,
                    marginTop: 16
                };
            }
        },
    },
    customRestMargin: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginRight: 16,
                    flexBasis: "48%",
                };
            }
        }
    },
    btnWidth108: {
        width: 150
    },
    containerStyle: {
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    minHeight: "auto"
                };
            }
        }
    },
    containerHeight: {
        marginBottom: 0
    }
});