import React from "react";
import R14, { StyleSheet, Text, View, Touchable, Image, Form } from "../../core";
import ChartComponent from "../../components/charts/ChartComponent";
import DropDown from "../../components/DropDown";

import { CHART_TYPE } from "../../constant/app.data.constant";

import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import CommonUtility from "../../utils/CommonUtility";
let lineImg = require("../../assets/images/chart-line-solid.png")
let barImg = require("../../assets/images/icManageInsuranceCoB.png");

export default R14.connect(
    class MOOPScreen extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                chartType: CHART_TYPE.LINECHART,
                selectedInsurance: null,
                claimPayments: this.props.data
            }
            
            this.form = null;
            this.insuranceItems = [];
        }

        componentDidMount = async () => {
            try {
                this.form = this.props.app.ui.form("frmMOOP");
                const {insurances} = this.props;
                if (!!insurances && insurances.length > 0) {
                    insurances.forEach((insurance) => {
                        this.insuranceItems.push({
                            label: insurance.name,
                            value: insurance.uid,
                        });
                    });
                    this.form.elmts.insuranceName.setItems(this.insuranceItems);
                    this.form.elmts.insuranceName.setValue(this.insuranceItems[0]);
                    this.setState({
                        selectedInsurance: this.insuranceItems[0]
                    })
                };
            }
            catch (err) {
                console.log(err)
            }
        };

        onInvalidTypeText = (txt, key, form = this.props.app.ui.form("frmMOOP")) => {
            return form.elmts[key].setValue(txt.slice(0, -1))
        }

        
        filterItems = async (list) => {
            let filterArray = list.reduce((acc, current) => {
                const x = acc.find(item => item.value === current.value);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            return filterArray;
        }

        onSearchInsurance = async (val) => {
            const { insurances, userLoginDetail } = this.props;
            this.insuranceItems = [];
            if (!!insurances && insurances.length > 0) {
                insurances.forEach((insurance) => {
                    this.insuranceItems.push({
                        label: insurance.name,
                        value: insurance.uid,
                    });
                });
            };
            if (!!val) {
                this.insuranceItems = this.insuranceItems
                    .filter((item) => {
                        return item.label.toLowerCase().includes(val.toLowerCase());
                    }).sort((a, b) => b.label - a.label);

                let ins = await this.props.app.dm.addInsuranceDomain.getInsuranceOnSearch(userLoginDetail.uid, val);
                if (!!ins && ins.length > 0) {
                    ins.forEach((insurance) => {
                        this.insuranceItems.push({
                            label: insurance.name,
                            value: insurance.uid
                        });
                    });
                };
            };
            this.insuranceItems = await this.filterItems(this.insuranceItems);
            this.form.elmts.insuranceName.setItems(this.insuranceItems);
            return this.insuranceItems;
        }


        onInsuranceChange = async (val) => {
            const { label, value } = val;
            const form = this.props.app.ui.form("frmMOOP")
            if (label === "No Results Found" || label.includes("Type in a search value")) {
                form.elmts.insuranceName.setValue(null);
                return false;
            }
            form.elmts.insuranceName.setValue(val);
            form.elmts.insuranceName.setError(null);
            this.setState({
                selectedInsurance: val,
                claimPayments: await this.props.app.dm.moop.getMoopData(value)
            });
        };

        renderInsuranceSection() {
            return (
                <View style={[styles.filterContainer]}>
                    <Form
                        name="frmMOOP"
                        style={[commonStyles.form, styles.uploadBillsForm]}>
                        <View
                            style={[
                                commonStyles.flex1,
                                commonStyles.flexBasis48,
                                commonStyles.customFlexDirection,
                            ]}>
                            <DropDown
                                name="insuranceName"
                                label={"Insurance"}
                                onValueChange={this.onInsuranceChange}
                                searchable={true}
                                items={this.insuranceItems}
                                itemLoader={async ({ search }) => {
                                    return await this.onSearchInsurance(search);
                                }}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.secondaryBgColor]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, commonStyles.secondaryBgColor]}
                                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                style={[customCommonStyles.inputHeight, commonFontStyles.workSans, commonStyles.heading6, commonStyles.marB0]}
                                onInvalidTypeText={(txt) => this.onInvalidTypeText(txt, "insuranceName")}
                            />
                        </View>
                    </Form>
                </View>
            );
        }

        onToggle = () => {
            this.setState({
                chartType: this.state.chartType === CHART_TYPE.BARCHART ? CHART_TYPE.LINECHART : CHART_TYPE.BARCHART
            });
        }

        render() {
            const {chartType, selectedInsurance, claimPayments} = this.state

            // Initialize a new object with zeros for all months
            const result = [];
            let data = claimPayments

            let totalClaimAmount = data.length > 0 ? data[0].memberClaimsAmount : 0;
            let totalPaidAmount = CommonUtility.groupBySum(data, 'paidAmount');
            let totalDeductible = CommonUtility.groupBySum(data, 'deductible');
            let totalcoPay = CommonUtility.groupBySum(data, 'copay');
            let totalcoInsurance = CommonUtility.groupBySum(data, 'coinsurance');

            for (let i = 0; i < 12; i++) {
                let items = data.filter(x => new Date(x.paidDate).getMonth() === i);
                result.push(CommonUtility.groupBySum(items, 'paidAmount'));
            }

            const chartData = {
                labels: ["Jan", "Feb", "Mar", "Apt", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
                datasets: [
                    {
                        label: 'Total Payment',
                        data: result,
                        // you can set indiviual colors for each bar
                        backgroundColor: [
                            "rgb(27, 99, 181)",
                            "red", "orange", "blue"
                            // 'rgba(75, 192, 192, 1)',
                            // 'rgba(255, 255, 255, 0.6)',
                            // 'rgba(255, 255, 255, 0.6)'
                        ],
                        borderWidth: 1,
                    }
                ]
            };
            return (
                <View style={[styles.container]}>
                    {this.renderInsuranceSection()}
                    <View style={[commonStyles.marLT15]}>
                        <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                            <Text style={[commonFontStyles.workSans, commonStyles.fontWeight300, commonStyles.exSmallHeading, commonStyles.contentColor, styles.width90]}>Total Charges: {CommonUtility.formatAmount(totalClaimAmount)}     Total Payment: {CommonUtility.formatAmount(totalPaidAmount)}      Patient Responsibility: {CommonUtility.formatAmount(totalDeductible + totalcoPay + totalcoInsurance)}     Total Unpaid: {CommonUtility.formatAmount(totalClaimAmount - (totalPaidAmount + totalDeductible + totalcoInsurance + totalcoPay))} (Based on Check Date)</Text>
                            <Touchable style={[commonStyles.flex1, commonStyles.justifyContentFlexEnd, styles.customMarginTop]} onPress={this.onToggle}>
                                <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                                    <Text style={[commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.exSmallHeading, commonStyles.contentColor,]}>Chart Type:  </Text>
                                    <Image resizeMode="contain" style={[commonStyles.icon18]} source={chartType === CHART_TYPE.BARCHART ? lineImg : barImg} />
                                </View>
                            </Touchable>
                        </View>
                    </View>
                    <View style={[commonStyles.flex1, commonStyles.customFlexDirection]}>
                        <View style={[commonStyles.marLT15, styles.width70]}>
                            <ChartComponent chartData={chartData} chartText={"Payment By Month (Current Year)"} chartTypes={[chartType]} customContainer={styles.width90} />
                            {/* , CHART_TYPE.LINECHART, CHART_TYPE.PIECHART */}
                        </View>
                        <View style={[styles.marginLeft25, commonStyles.marginTop50]}>
                            <View style={[styles.padding10]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.contentColor]}>Current: {new Date().getFullYear()} (Based on Date of Service/ By Insurance)</Text>
                            </View>
                            <View style={[styles.padding10]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.contentColor]}>Insurance: {!!selectedInsurance && selectedInsurance.label}</Text>
                            </View>
                            <View style={[styles.padding10]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.contentColor]}>Member Out of Pocket: {CommonUtility.formatAmount(totalDeductible + totalcoInsurance + totalcoPay)}</Text>
                            </View>
                            <View style={[styles.padding10]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.contentColor]}>Deductible:  {CommonUtility.formatAmount(totalDeductible)}</Text>
                            </View>
                            <View style={[styles.padding10]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.contentColor]}>Copay: {CommonUtility.formatAmount(totalcoPay)}</Text>
                            </View>
                            <View style={[styles.padding10]}>
                                <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.contentColor]}>Coinsurance: {CommonUtility.formatAmount(totalcoInsurance)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            );
        }
    })

const styles = StyleSheet.create({
    profileViewScreen: {
        padding: 15,
        ...StyleSheet.margin(0, 0, 0, 0),
        height: "100%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    backgroundColor: "#f4f4f4",
                };
            }
            if (width <= 640) {
                return {
                    margin: 0,
                    paddingBottom: 20
                };
            }
        },
    },
    pageTitleHolder: {
        // backgroundColor: "#fff",
        zIndex: 999,
        paddingTop: 7,
        paddingRight: 16,
        paddingBottom: 10,
        paddingLeft: 16,
        background: "#f4f4f4",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 0,
        maxHeight: 45,
    },
    pageTitle: {
        color: "#151b26",
    },
    container: {
        marginLeft:16,
        marginRight:16,
        backgroundColor: "white",
        height:"100%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    backgroundColor: "white",
                };
            }
        },
    },
    marginLeft25: {
        marginRight: 20,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginRight: 0,
                    marginLeft: 25
                };
            }
        }
    },
    width70: {
        width: "70%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "97%",
                    marginLeft: 5,
                    marginRight: 5,
                };
            }
        }
    },
    width90: {
        width: "90%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "100%",
                };
            }
        }
    },
    customMarginTop:{
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    width: "25%",
                    marginTop: 10,
                };
            }
        }
    },
    padding10: {
        paddingTop: 10,
        paddingBottom: 5,
    },
    filterContainer: {
        flex: 1,
        flexDirection: 'row',
        position: 'relative',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        marginTop: 10,
        maxWidth: "40%",
        maxHeight: 52,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginBottom: 16,
                    maxWidth: "100%",
                };
            }
        }
    },    
    uploadBillsForm: {
        paddingTop: 10,
        paddingLeft: 5,
        paddingBottom: 0,
        paddingRight: 0,
        maxWidth: "100%",
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    paddingLeft: 0,
                    paddingRight: 5,
                };
            }
        },
    }
});
