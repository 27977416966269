import { RSA_PUBLIC_KEY } from "../constant/app.constant";
const NodeRSA = require("node-rsa");
const jwt = require("jsonwebtoken");

export default class RSAencryption {
  static EnctyptString(secretString) {
    if (!!!secretString)
      return "";
    let key_public = NodeRSA(RSA_PUBLIC_KEY);
    let encryptedString = key_public.encrypt(secretString, "base64");
    return encryptedString;
  }
  //NOTE:Apple Token Decode
  static AppleTokenDecode(token_id) {
    return jwt.decode(token_id);
  }
  
  static encryptDataByBTOA(data) {
    try {
      return btoa(JSON.stringify(data));
    } catch (error) {
      return data;
    }
  }

  static decryptDataByATOB(data) {
    try {
      return JSON.parse(atob(data));
    } catch (error) {
      return data;
    }
  }
}
