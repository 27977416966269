import React from "react";
import R14, { Image, StyleSheet, Text, Touchable, View } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import Linking from "../utils/Linking";
import SubmittedComponent from "./PCH/SubmittedComponent";
import PCHLoaderComponent from "./PCH/PCHLoaderComponent";
import { MESSAGE_TYPE } from "../constant/app.data.constant";
import PopupModalComponent from "./PopupModalComponent";

const accept = require("../assets/images/accept.png");

export default R14.connect(
    class SubscriptionComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isLoading: true,
                isVisible: false,
                currentPlan: null,
            }
        }

        componentDidMount = async () => {
            setTimeout(() => this.setState({ isLoading: false }), 1000);
        }

        onConfirmationCancel = () => {
            this.setState({ isVisible: false, currentPlan: null });
            return false;
        }

        onOkPress = async () => {
            this.setState({ isLoading: true });
            let plan = this.state.currentPlan;
            let res = await this.props.app.dm.achPaymentDomain.planCheckout(plan.uid);
            this.setState({ isLoading: false, currentPlan: null, isVisible: false });
            if (!!res && res.indexOf('trial_') > -1 && !!plan.isTrial) {
                this.props.app.nav.to("subscribedRoute", { act: "success", session_id: res });
            }
            else if (!!res) {
                Linking.LinkingURL(res, "_self");
            }
            return false;
        }

        onSelection = async (plan) => {
            this.setState({ isVisible: true, currentPlan: plan })
            return false;
        }

        render() {
            const { packages, status, userType, planId } = this.props;
            const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
            return (
                <View style={[styles.subscriptionScreen]}>
                    {!!status ?
                        <View style={[commonStyles.marginBottom15]}>
                            <SubmittedComponent message={status === "success" ? "Thank you for subscribe. Your plan has been activated." : "Thank you try to subscribe plan. Your payment has been failed/cancelled."} customMargin={styles.customMargin} userType={userType} goTo={"dashboard"} goToTitle={"Go To Dashboard"} currency={"usd"} hasError={status === "success" ? false : true} />
                        </View>
                        : null}
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, !!isMobileDevice ? commonStyles.flexDirectionColumn : null]}>
                        {packages && packages.map((item, index) => {
                            return <View style={[styles.boxModel, index > 0 && !!!isMobileDevice ? commonStyles.marginLeft12 : null, planId === item.uid ? styles.activePlan : null]} key={"parent_" + index}>
                                <View style={[styles.free_section]}>
                                    <Text style={[commonStyles.heading5, commonFontStyles.workSans, commonStyles.fontWeight500]}>{item.name}</Text>
                                </View>
                                <View style={[styles.amount_section, commonStyles.flex1, commonStyles.flexDirectionColumn, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter]}>
                                    <Text style={[commonStyles.heading5, commonFontStyles.workSans, commonStyles.fontWeight500]}>${item.amount}</Text>
                                    <Text style={[commonStyles.smallHeading, commonFontStyles.workSans, commonStyles.fontWeight400]}>{item.isTrial ? `${item.duration} ${item.planType}` : !!item.planType ? `Per ${item.planType}`: " "}</Text>
                                </View>
                                <View style={[styles.selection_section]}>
                                    <Touchable onPress={() => (planId === item.uid || (planId && !!item.isTrial)) ? null : this.onSelection(item)}>
                                        <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, commonStyles.textAlignCenter, commonStyles.primaryBlueButton, (planId === item.uid || (planId && !!item.isTrial)) ? styles.disabledBtn : null]}>
                                            <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.smallHeading, commonStyles.whiteColor, commonStyles.textAlignCenter]}>
                                                Get Started
                                            </Text>
                                        </View>
                                    </Touchable>
                                </View>
                                <View style={[styles.description_section]}>
                                    {JSON.parse(item.modules[0]).map((val, i) => {
                                        return <View key={"child_" + i} style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.justifyContentCenter, commonStyles.alignItemsStart, commonStyles.marginBottom10]}>
                                            <View style={[commonStyles.marginTop5, commonStyles.justifyContentFlexStart, commonStyles.marginRight16]}>
                                                <Image source={accept} style={commonStyles.icon18} />
                                            </View>
                                            <View style={[commonStyles.flex1, commonStyles.flexDirectionColumn, commonStyles.justifyContentCenter, commonStyles.alignItemsStart]}>
                                                <Text style={[commonStyles.heading5, commonFontStyles.workSans, commonStyles.fontWeight500]}>{val.name}</Text>
                                                {!!val.description || val.serialNo < 0 ?
                                                    <Text style={[commonStyles.smallHeading, commonFontStyles.workSans, commonStyles.fontWeight400]}>{val.serialNo < 0 ? `${item.duration} Days` : val.description}</Text> : null}
                                            </View>
                                        </View>
                                    })}
                                </View>
                            </View>
                        })}
                    </View>
                    <PCHLoaderComponent isVisiable={this.state.isLoading} labelText={"Please wait"} />
                    <PopupModalComponent
                        visibility={!!this.state.isVisible}
                        bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.confirmDeclineModal]}
                        messageType={MESSAGE_TYPE.info}
                        headingIcon={true}
                        heading={"Confirm Plan"}
                        modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
                        bodyContent={`Do you want to continue with Plan?`}
                        okLabel={"Confirm"}
                        handleOk={this.onOkPress}
                        closeLabel={"Cancel"}
                        modalPopupCancelBtn={styles.paddingLeftRight0}
                        modalPopupOkBtn={styles.paddingLeftRight0}
                        handleClose={this.onConfirmationCancel}
                        modalHeading={[commonStyles.nativeModalHeading]}
                    />
                </View>
            );
        }
    }
);

const styles = StyleSheet.create({
    subscriptionScreen: {
        backgroundColor: "#fff",
        paddingTop: 15,
        paddingRight: 15,
        paddingBottom: 15,
        paddingLeft: 15,
        // marginTop: 16,
        marginRight: 16,
        marginBottom: 16,
        marginLeft: 16,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    marginTop: 0,
                    marginRight: 0,
                    marginLeft: 0,
                    marginBottom: 70,
                    borderColor: "#fff",
                };
        },
    },
    boxModel: {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#e2e2e2",
        width: "33%",
        screen: ({ width }) => {
            if (width <= 991)
                return {
                    width: "100%",
                    marginBottom: 15,
                }
        }
    },
    free_section: {
        textAlign: "center",
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 0,
        paddingRight: 0
    },
    amount_section: {
        height: 70,
        textAlign: "center",
        background: "#e3f5fd"
    },
    selection_section: {
        paddingBottom: 15,
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15
    },
    description_section: {
        paddingLeft: 15,
        paddingRight: 15
    },
    disabledBtn: {
        opacity: 1,
        pointerEvents: "none",
        backgroundColor: "#888888",
        cursor: "auto",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    paddingLeftRight0: {
        paddingLeft: 0,
        paddingRight: 0
    },
    activePlan: {
        boxShadow: "0px 0px 6px #1b63b5",
        borderColor: "#1277d9",
    }
});
