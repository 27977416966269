import R14, { } from "../core";
import { GET_MSG_FROM_THREAD } from "../constant/app.constant";
import CommonUtility from "../utils/CommonUtility";
import DateUtils from "../utils/DateUtils";
import { MSG_TYPE, PAYMENT_MODE } from "../constant/app.data.constant";
import { CommonString } from "../constant/appCommonText";

export default class BillDetailsDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      msgPayload: {},
      bankTabActive: "Card",
      billDetails: null,
      isShowErrorMsg: false
    };
  }

  async createGetCardTokenPayload(sessionToken, bancHubPayload) {
    let payload = {
      header: {
        username: "EXELARTPPay",
        session_token: sessionToken,
      },

      carddetails: {
        card_number: bancHubPayload.cardNumber ? bancHubPayload.cardNumber : "",
        cardholder_name: bancHubPayload.cardHolderName ? bancHubPayload.cardHolderName : "",
        expire_month: bancHubPayload.monthExpDate ? parseInt(bancHubPayload.monthExpDate) : 0,
        expire_year: bancHubPayload.yearExpDate ? parseInt(bancHubPayload.yearExpDate) : 0,
        payment_cardtype: 0,
      },
    };
    return payload;
  }

  async postMessageForPayment(payload) {
    let pid = await this.dm.dashboard.findCorrectPId(payload.envelope.threadHeader.threadId);
    try {
      return await this.dm.rest.post(`${GET_MSG_FROM_THREAD}`, payload, { pid: pid });
    } catch (err) {
      console.log("Payment Message :::: ", err);
    }
  }

  async getCardToken(payload) {
    try {
      let res = await this.api.qry(
        `
      query AddNewCard($input: addNewCardInput!) {
        addNewCard(input: $input){
          GetCardTokenResult
          {
            display_number
            error_desc
            status
            token_id
          }
        }
      }`,
        {
          input: payload,
        }
      );
      return res.data.addNewCard;
    } catch (err) {
      return err;
    }
  }

  async cardTokenPayment(payload) {
    try {
      let res = await this.api.qry(
        `
        query CardPayment($input: CardPaymentInput!) {
          cardPayment(input: $input){
            CardTokenPaymentResult
            {
              transaction_id
              error_desc
              status 
            }
        }
      }`,
        {
          input: payload,
        }
      );
      return res.data.cardPayment;
    } catch (err) {
      return err;
    }
  }

  async savePayerCardDetails(input, fields = "uid payerID paymentType cardType token displayNumber status cardHolderName ") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
        mutation CreatePayerCardDetails($input: CreatePayerCardDetailsInput){
          createPayerCardDetails(input: $input){
            payerCardDetails{
              ${fields}
            }
            success
          }
        }`,
        { input }
      );
      return res.data.createPayerCardDetails.payerCardDetails;
    } catch (error) {
      return error;
    }
  }

  async updatePayerCardDetails(input, fields = "uid payerID paymentType cardType token displayNumber status isSetDefault cardHolderName") {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
        mutation UpdatePayerCardDetails($input: UpdatePayerCardDetailsInput){
          updatePayerCardDetails(input: $input){
            payerCardDetails{
              ${fields}
            }
            success
          }
        }`,
        { input }
      );
      return res.data.updatePayerCardDetails.payerCardDetails;
    } catch (error) {
      return error;
    }
  }

  async getPayerCardDetailsList(fields = "uid payerID paymentType cardType token displayNumber status isSetDefault cardHolderName") {
    let user = await this.dm.rest.getUser();
    try {
      let res = await this.api.qry(
        `
      query PayerCardDetailss($filter: PayerCardDetailsFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        payerCardDetailss(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
        {
          filter: {
            payerID: { eq: user.payerId },
            status: { eq: "Enabled" },
          },
        }
      );
      return res.data.payerCardDetailss.nodes;
    } catch (err) {
      return err;
    }
  }

  async deleteBookmark(uid, fields = "uid threadId") {
    try {
      let res = await this.api.mutate(
        `
      mutation DeleteBookmark($uid: ID!) {
        deleteBookmark(uid: $uid){
          bookmark {
            ${fields}
          }
          success
        }
      }`,
        { uid }
      );
      return res.data.bookmark;
    } catch (error) {
      return error;
    }
  }

  async getBookmark(data) {
    const { threadId } = data;

    try {
      let res = await this.api.qry(
        `
      query Bookmark($filter: BookmarkFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        bookmarks(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            uid threadId
          }
        }
      }`,
        {
          filter: {
            threadId: { eq: threadId },
          },
          sort: [
            {
              field: "pid",
              order: "ASC",
            },
          ],
        }
      );
      return res.data.bookmarks.nodes;
    } catch (err) {
      return err;
    }
  }

  async getSenderName(input) {
    let buDetail = await this.dm.rest.getuserBUDetails(input);
    let senderName = input.payerRef;
    if (!!buDetail)
      senderName = buDetail.firstName && buDetail.lastName ? `${buDetail.firstName} ${buDetail.lastName}` : buDetail.firstName;
    return senderName;
  }

  
  async getNotePayload(lastLeaf = null) {
    let { billData, attac, envelope } = lastLeaf;
    let attachment = [];
    let senderName = await this.getSenderName({
      buId: envelope.messageHeader.buId,
      billerRef: envelope.messageHeader.senderPIDRef,
      payerRef: envelope.messageHeader.recipientPIDRef,
      payerId: envelope.messageHeader.recipientPid
    });

    if (lastLeaf.attac) {
      attachment.push({
        filename: attac.filename,
        contentType: attac.contentType,
        content: attac.content,
      });
    }
    let payload = {
      envelope: {
        messageHeader: {
          ...lastLeaf.envelope.messageHeader,
          senderName: senderName,
          senderPid: billData.recipientPid,
          recipientPid: billData.senderPid,
          messageId: CommonUtility.generate20Character(),
          messageDateTime: DateUtils.isoFormateDate(),
          senderPIDRef: billData.messageHeader.recipientPIDRef,
          recipientPIDRef: billData.messageHeader.senderPIDRef
        },
        threadHeader: {
          ...lastLeaf.envelope.threadHeader,
          messageDateTime: DateUtils.isoFormateDate(),
        },
        messageBody: {
          endToEndIdentification: lastLeaf.envelope.messageBody.endToEndIdentification,
          messageType: billData.messageType,
          subMessageType: "NoteToBiller",
          note: billData.note,
        },
      },
      messageMeta: {
        ...lastLeaf.messageMeta,
        deliveryPath: {
          ...lastLeaf.messageMeta.deliveryPath,
          timestamp: DateUtils.isoFormateDate(),
        },
      },
      attachments: !!attachment.length ? attachment : null,
    };

    return !!lastLeaf ? payload : {};
  }

  async getPaymentPayload(lastLeaf = null, paymentMode, isBulkPay) {
    let { billData, envelope } = lastLeaf;
    let senderName = await this.getSenderName({
      buId: envelope.messageHeader.buId,
      billerRef: envelope.messageHeader.senderPIDRef,
      payerRef: envelope.messageHeader.recipientPIDRef,
      payerId: envelope.messageHeader.recipientPid
    });

    let cDate = DateUtils.isoFormateDate();
    cDate = `${cDate.substr(0, cDate.length - 5)}Z`;

    let payload = {
      envelope: {
        messageHeader: {
          ...lastLeaf.envelope.messageHeader,
          senderName: senderName,
          senderPid: billData.messageHeader.recipientPid,
          recipientPid: billData.messageHeader.senderPid,
          messageId: CommonUtility.generate20Character(),
          messageDateTime: cDate,
          senderPIDRef: billData.messageHeader.recipientPIDRef,
          recipientPIDRef: billData.messageHeader.senderPIDRef
        },
        threadHeader: {
          ...lastLeaf.envelope.threadHeader,
        },
        messageBody: {
          endToEndIdentification: billData.endToEndIdentification,
          amount: { instructedAmount: billData.amount, currency: billData.currency },
          transactionId: billData.transactionId,
          messageType: lastLeaf.messageType,
          subMessageType: "NoteToBiller",
          requestedExecutionDate: cDate,
          acceptanceDateTime: cDate,
          transactionStatus: "ACSC",
          additionalInformation: billData.additionalInformation || null,
        },
      },
      messageMeta: {
        ...lastLeaf.messageMeta,
        deliveryPath: {
          ...lastLeaf.messageMeta.deliveryPath,
          timestamp: cDate,
        },
      },
      attachments: lastLeaf.attachments,
    };

    if (paymentMode === PAYMENT_MODE.ACH_PAY) {
      payload.envelope.messageBody.messageType = "NoteToBiller";
      let paymentType = lastLeaf.messageType === MSG_TYPE.PAY_PART ? "partial" : "full";
      let comment = !!isBulkPay ? ' bulk ' : ' ';
      payload.envelope.messageBody.note = `Initiated${comment}${paymentType} payment of $${billData.amount} through ACH.\nTransaction Ref: ${billData.transactionId}`;
      if (!!billData.additionalInformation) {
        payload.envelope.messageBody.note = payload.envelope.messageBody.note + '\nAdditional Information: ' + billData.additionalInformation;
      }
    }
    else {
      payload.envelope.messageBody.amount = { instructedAmount: billData.amount, currency: billData.currency };
    }
    return !!lastLeaf ? payload : {};
  }

  async getDeclinedPayload(billData) {
    let senderName = await this.getSenderName({
      buId: billData.messageHeader.buId,
      billerRef: billData.messageHeader.senderPIDRef,
      payerRef: billData.messageHeader.recipientPIDRef,
      payerId: billData.messageHeader.recipientPid
    });

    return {
      envelope: {
        threadHeader: billData.threadHeader,
        messageHeader: {
          senderName: senderName,
          senderPid: billData.messageHeader.recipientPid,
          recipientPid: billData.messageHeader.senderPid,
          messageId: CommonUtility.generate20Character(),
          messageDateTime: DateUtils.isoFormateDate(),
          senderPIDRef: billData.messageHeader.recipientPIDRef,
          recipientPIDRef: billData.messageHeader.senderPIDRef,
          buId: billData.messageHeader.buId
        },
        messageBody: {
          messageType: billData.messageType,
          subMessageType: "NoteToBiller",
          endToEndIdentification: billData.endToEndIdentification,
          additionalInformation: billData.additionalInformation,
        },
      },
      messageMeta: billData.messageMeta,
      attachments: billData.attachments,
    };
  }


  async getRequestExtPayload(billData) {
    
    let senderName = await this.getSenderName({
      buId: billData.messageHeader.buId,
      billerRef: billData.messageHeader.senderPIDRef,
      payerRef: billData.messageHeader.recipientPIDRef,
      payerId: billData.messageHeader.recipientPid
    });

    return {
      envelope: {
        threadHeader: billData.threadHeader,
        messageHeader: {
          senderName: senderName,
          senderPid: billData.messageHeader.recipientPid,
          recipientPid: billData.messageHeader.senderPid,
          messageId: CommonUtility.generate20Character(),
          messageDateTime: DateUtils.isoFormateDate(),
          senderPIDRef: billData.messageHeader.recipientPIDRef,
          recipientPIDRef: billData.messageHeader.senderPIDRef,
          buId: billData.messageHeader.buId
        },
        messageBody: {
          messageType: billData.messageType,
          subMessageType: "NoteToBiller",
          endToEndIdentification: billData.endToEndIdentification,
          additionalInformation: billData.additionalInformation || null,
          requestedExtensionDate: billData.requestedExecutionDate || new Date(),
        },
      },
      messageMeta: billData.messageMeta,
      attachments: billData.attachments,
    };
  }

  async getPayerAccountDetailsList(
    fields = "uid payerId accountType accountHolderName bankName accountNumber displayAccountNumber rtnNumber bicCode displayBicCode isSetDefault status"
  ) {
    let user = await this.dm.rest.getUser();
    try {
      let res = await this.api.qry(
        `
      query PayerAccountDetailss($filter: PayerAccountDetailsFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        payerAccountDetailss(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
        {
          filter: {
            payerId: { eq: user.payerId },
            status: { eq: "Enabled" },
          },
        }
      );
      return res.data.payerAccountDetailss.nodes;
    } catch (err) {
      return err;
    }
  }
  async getAllPayerAccountDetailsList(
    fields = "uid payerId accountType accountHolderName bankName accountNumber displayAccountNumber rtnNumber bicCode displayBicCode isSetDefault status"
  ) {
    try {
      let res = await this.api.qry(
        `
      query PayerAccountDetailss($filter: PayerAccountDetailsFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        payerAccountDetailss(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`
      );
      return res.data.payerAccountDetailss.nodes;
    } catch (err) {
      return err;
    }
  }
  async savePayerAccountDetails(
    input,
    fields = "uid payerId accountType accountHolderName bankName accountNumber displayAccountNumber rtnNumber bicCode displayBicCode isSetDefault status "
  ) {

    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
        mutation CreatePayerAccountDetails($input: CreatePayerAccountDetailsInput){
          createPayerAccountDetails(input: $input){
            payerAccountDetails{
              ${fields}
            }
            success
          }
        }`,
        { input }
      );
      return res.data.createPayerAccountDetails.payerAccountDetails;
    } catch (error) {
      return error;
    }
  }
  async updatePayerAccountDetails(
    input,
    fields = "uid payerId accountType accountHolderName bankName accountNumber displayAccountNumber rtnNumber bicCode displayBicCode isSetDefault status"
  ) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
        mutation UpdatePayerAccountDetails($input: UpdatePayerAccountDetailsInput){
          updatePayerAccountDetails(input: $input){
            payerAccountDetails{
              ${fields}
            }
            success
          }
        }`,
        { input }
      );
      return res.data.updatePayerAccountDetails.payerAccountDetails;
    } catch (error) {
      return error;
    }
  }
  async fetchPayerAccountDetails(uid = null) {
    try {
      let res = uid
        ? await this.api.qry(
          `
      query FetchPayerAccountDetails($uid: ID!) {
        payerAccountDetails(uid: $uid){
        uid payerId accountType accountHolderName bankName accountNumber displayAccountNumber rtnNumber 
        bicCode displayBicCode isSetDefault status
        }
      }`,
          {
            uid: uid,
          }
        )
        : {};
      return {
        values: res.payerAccountDetails || {},
      };
    } catch (error) {
      return error;
    }
  }
  async checkBankAccountExist(accountNumber, payerId, uid) {
    let data = {
      accountNumber: accountNumber,
      payerId: payerId,
      uid: uid,
    };
    await CommonUtility.htmlDecode(data);
    try {
      let res = await this.dm.queryDomain.middleware(this.api.query(
        `
        query checkBankAccountExist($input: BankAccountInput!) {
          checkBankAccountExist(input: $input){       
                success msg              
            }            
        }`,
        {
          input: data,
        }
      ));
      return res.data.checkBankAccountExist;
    } catch (error) {
      return error;
    }
  }

  async stripeCheckout(token) {

    await CommonUtility.htmlDecode(token);
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(`
              mutation StripeCheckout($input: CheckoutInput!) {
              stripeCheckout(input: $input){                        
                                  success
                                  transactionId
                                  error                                  
                              }                               
              }`,
        {
          input: token
        }
      ));
      return res.data.stripeCheckout;
    }
    catch (error) {
      return error;
    }
  }

  async stripePaymentCheckout(token) {

    await CommonUtility.htmlDecode(token);
    try {
      let res = await this.dm.queryDomain.middleware(this.api.mutate(`
            mutation SafariStripePaymentCheckout($input: CheckoutInput!) {
              safariStripePaymentCheckout(input: $input){                        
                                success
                                transactionId
                                paymentIntentId
                                ephemeralKey
                                customer
                                error                                  
                            }                               
            }`,
        {
          input: token
        }
      ));
      if (!!res && !!res.errors && res.errors.length > 0) {
        return {
          success: false,
          error: !!res.errors[0].message ? res.errors[0].message : CommonString.SomethingWentWrong,
          transactionId: ``,
          paymentIntentId: ``,
          customer: ``,
          ephemeralKey: ``,
        };
      }
      else
        return res.data.safariStripePaymentCheckout;
    }
    catch (error) {
      return {
        success: false,
        error: !!error && error.message ? error.message : CommonString.SomethingWentWrong,
        transactionId: ``,
        paymentIntentId: ``,
        customer: ``,
        ephemeralKey: ``,
      };
    }
  }

  async getInvoiceData(refID) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query  GetInvoice($refID: String! ) {
          getInvoice(refID: $refID) {
            data error success
          }
          }`,
        { refID: refID }
      ));
      return res.data.getInvoice || "";
    } catch (error) {
      return error;
    }
  }

  async validateBillAccount(accountId) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(`query GetConnectedAccount($accountId: String! ) { getConnectedAccount(accountId: $accountId) }`,
        { accountId: accountId }
      ));
      return res.data.getConnectedAccount || false;
    } catch (error) {
      return false;
    }
  }
}
