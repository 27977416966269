import React from "react";
import R14, { View, StyleSheet, Platform } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import PopupModalComponent from "../components/PopupModalComponent";
import { MESSAGE_TYPE } from "../constant/app.data.constant";

export default R14.connect(
  class DeleteAccountScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        message: ''
      }
    }

    handleAlertPopUp = (goBack = false) => {
      const { message } = this.state;

      // when cancel and delete btn active & user clicked on cancel
      if(goBack === true) {
        this.props.app.nav.back();
      } else if (!message) {
        // when cancel and delete btn active & user clicked on delete
        this.setState({message: 'Account deletion request has been sent. Your account will be deleted within 90 days. On login again request will be cancelled.' });
      } else if (!!message) {
        // on confirmation, show success message and if necessary Add API to handle delete account action
        this.onCancel();
        this.setState({message: '' });
      }
      return false;
    }

    // Delete confirmation popup model with Yes/No buttons
    alertModelPopUp = () => {
      const { message } = this.state;
      return (
        <PopupModalComponent
          visibility={true}
          bodyWrapper={[styles.bodyWrapper]}
          messageType={message ? MESSAGE_TYPE.info : MESSAGE_TYPE.delete}
          headingIcon={true}
          heading={message ? 'Request Sent' : "Delete Confirmation"}
          modalContent={[commonStyles.marginTop10, commonStyles.marginBottom10]}
          bodyContent={message ? message : 'Deleting your account will remove all of your information from our database. This cannot be undone.'}
          okLabel={message ? 'Ok' : "Delete"}
          modalPopupOkBtn={message ? null : styles.width90}
          handleOk={this.handleAlertPopUp}
          closeLabel={message ? null :"Cancel"}
          modalPopupCancelBtn={message ? null : styles.width90}
          handleClose={message ? null :() => this.handleAlertPopUp(true)}
          modalHeading={[commonStyles.nativeModalHeading]}
        />
      )
    }

    onCancel = () => {
      this.props.app.nav.to("login");
    }

    render() {
      return (
        <>
          <View style={[Platform.OS !== "web" ? null : [commonStyles.flex1, commonStyles.flexDirectionRow]]}>
            {this.alertModelPopUp()}
          </View>
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  bodyWrapper: {
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          maxHeight: 200,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          fontWeight: 600,
        };
    },
  },
  width90: {
    width: 90
  }
});