const SEPA_EXELA_ISSUER = "Exela.global";
const SEPA_STRIPE_ISSUER = "Stripe";

const SEPA_PMT_METHOD = "TRF";
const SEPA_ADVICE_TYPE_CODE = ["ADND", "ADWD"];
const SEPA_EXTERNAL_IDENTITY_ORG_CODE = "BOID";
const SEPA_EXTERNAL_IDENTITY_PRN_CODE = "POID";
const SEPA_PRXY_CODE = ['BIID', 'CCPT', 'CINC', 'COID', 'COTX', 'CUST', 'DNAM', 'DRLC', 'EIDN', 'EMAL', 'EWAL', 'LEIC', 'MBNO', 'NIDN', 'PVTX', 'SHID', 'SOSE', 'TELE', 'TOKN', 'UBIL', 'VIPN'];
const SEPA_ACCEPT_TRANS_CODE = ['ACCP', 'ACWC'];
const SEPA_REJCT_RSN_CODE = ['AM03', 'AM05', 'AM09', 'IEDT', 'MS02', 'NOAR', 'UCRD'];
const SEPA_REJCT_TRANS_CODE = ['RJCT'];


const SEPA_ACCEPT_TRANS = [{ value: 'ACCP', label: "Accepted Customer Profile" }, { value: 'ACWC', label: "Accepted With Change" }];
const SEPA_REJCT_RSNS = [{ value: 'AM03', label: "Not Allowed Currency" }, { value: 'AM05', label: "Duplication" }, { value: 'AM09', label: "Wrong Amount" }, { value: 'IEDT', label: "Incorrect Expiry Date Time" }, { value: 'MS02', label: "Not Specified Reason Customer Generated" }, { value: 'NOAR', label: "Non Agreed RTP" }, { value: 'UCRD', label: "Unknown Creditor" }];
const SEPA_CANCEL_RSNS = [{ value: 'AC02', label: "Invalid Debtor Account Number" }, { value: 'AM09', label: "Wrong Amount" }, { value: 'BE16', label: "Invalid Debtor Identification Code" }, { value: 'DRTP', label: "Duplication Request To Pay" }, { value: 'DT01', label: "Invalid Date" }, { value: 'MODT', label: "Modified Transaction" }, { value: 'PAID', label: "Transaction Already Paid" }, { value: 'TECH', label: "Technical Problem" }];
// added space in label, so that iphone8 doesn't have two lines issue
const SEPA_REJCT_TRANS = [{ value: 'RJCT', label: "Rejected " }];
const FULL_PAYMENT = "OT";
const INSTALLMENT = "IN";

export {
    SEPA_EXELA_ISSUER, SEPA_STRIPE_ISSUER, SEPA_PMT_METHOD, SEPA_ADVICE_TYPE_CODE, SEPA_EXTERNAL_IDENTITY_ORG_CODE, SEPA_EXTERNAL_IDENTITY_PRN_CODE, SEPA_PRXY_CODE, SEPA_ACCEPT_TRANS_CODE, SEPA_REJCT_RSN_CODE, SEPA_REJCT_TRANS_CODE, FULL_PAYMENT, INSTALLMENT, SEPA_ACCEPT_TRANS, SEPA_REJCT_RSNS, SEPA_REJCT_TRANS, SEPA_CANCEL_RSNS
}
