import React from "react";
import R14, { View, Text, Switch } from "../core";
import { commonStyles } from "../assets/css/commonStyle";

const activeButton = commonStyles.blueButton.backgroundColor;
const inactiveButton = commonStyles.exLightGray.backgroundColor;

export default R14.connect(
    class SwitchToggleComponent extends React.Component {

        render() {
            return (
                <View key={this.props.key} style={this.props.containerStyle}>
                    <Switch
                        key={this.props.key}
                        trackColor={{ false: inactiveButton, true: `${activeButton}75` }}
                        thumbColor={this.props.paymentStatus ? activeButton : inactiveButton}
                        ios_backgroundColor={this.props.paymentStatus ? `${activeButton}10` : `${inactiveButton}10`}
                        activeThumbColor={activeButton}
                        value={this.props.paymentStatus ? true : false}
                        disabled={this.props.toggleSwitch ? false : true}
                        onValueChange={this.props.toggleSwitch}
                    />
                    <Text style={this.props.textStyle}>{this.props.paymentOption}</Text>
                </View>
            )
        }

    }
);