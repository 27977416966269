import React from "react";
import R14 from "../../core";
import AddInsurance from "../../components/PCH/AddInsurance";

export default R14.connect(
  class PatientInsuranceScreen extends React.Component {
    render() {
      const { defaultMode, insuranceItems } = this.props;
      return (
        <AddInsurance
          profileActionType={"Edit"}
          defaultMode={defaultMode}
          insuranceItems={insuranceItems}
          userLoginDetail={this.props.userLoginDetail}
        />
      );
    }
  }
); 
