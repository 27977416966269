import React from "react";
import R14, { StyleSheet, Text, View, Touchable } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import BankList from "../components/BankList";
import CardsList from "../components/CardsList";

export default R14.connect(
  class PaymentOptionsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isBank: false,
        isCard: true,
      };
    }
    onSelectBank = () => {
      this.setState({
        isBank: true,
        isCard: false,
      });
    };
    onSelectCard = () => {
      this.setState({
        isCard: true,
        isBank: false,
      });
    };
    componentDidMount = async () => {
      const { bankTabActive } = this.props.app.dm.billDetails.state;
      bankTabActive === "Card" ? this.onSelectCard() : this.onSelectBank();
    };
    render() {
      const { cardDisplayList, bankAccountList } = this.props;
      return (
        <View style={styles.paymentOptionsScreen}>
          <View
            style={[
              styles.cardHeading,
              commonStyles.dFlex,
              commonStyles.alignItemsCenter,
              commonStyles.borderTopBottom,
              commonStyles.marginLeftRightMinus15,
            ]}>
            <Touchable style={[styles.cardHeadingText]} onPress={this.onSelectCard}>
              <View>
                <Text
                  style={[
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight700,
                    this.state.isCard ? commonStyles.transperentBtnColor : null,
                  ]}>
                  CARDS
                </Text>
                <Text style={[this.state.isCard ? styles.cardBoxBorder : null]}></Text>
              </View>
            </Touchable>
            <Touchable onPress={this.onSelectBank} style={[styles.cardHeadingText]}>
              <View>
                <Text
                  style={[
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight700,
                    this.state.isBank ? commonStyles.transperentBtnColor : null,
                  ]}>
                  ACCOUNTS
                </Text>
                <Text style={[this.state.isBank ? styles.cardBoxBorder : null]}></Text>
              </View>
            </Touchable>
          </View>
          <View style={commonStyles.marginBottom20}></View>
          {this.state.isCard ? <CardsList cardDisplayList={cardDisplayList} /> : null}
          {this.state.isBank ? <BankList bankAccountList={bankAccountList} /> : null}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  paymentOptionsScreen: {
    flex: 0,
    height: "100%",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          alignItems: "center",
          margin: 0,
        };
    },
  },
  cardHeadingText: {
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    flexDirection: "column",
    position: "relative",
  },
  cardBoxBorder: {
    display: "block",
    width: "100%",
    bottom: 0,
    height: 2,
    backgroundColor: "#0C7DA6",
    position: "absolute",
  },
});
