import React from "react";
import R14, {
    View,
    Text,
    StyleSheet,
    Button,
    Platform,
    Dimensions,
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import RadioButtons from "../RadioButtons";

import ModalPopUp from "../ModalPopUp";
import TableListComponent from "../../components/TableListComponent";
import { FULL_PAYMENT } from "../../constant/sepa.constant";

export default R14.connect(
    class SepaPaymentOption extends React.Component {

        onPaymentOptionChange = (item) => {
            const updatePaymentOption = this.props.paymentOptions.map(e => {
                return { ...e, selectedChk: item.value }
            });
            this.props.onPaymentChange(updatePaymentOption);
        }

        render() {
            const {
                isVisiable = false,
                item,
                onCancel,
                onApply,
                paymentsList = [],
                paymentLabelKeys = [],
                paymentLabelValues = [],
                paymentOptions,
            } = this.props;
            let commonFontStyles = fontFamilyStyles;
            return <>
                <ModalPopUp
                    visibility={isVisiable}
                    isFooter={false}
                    bodyWrapper={[commonStyles.mediumModalBodyWrapper, commonStyles.flexWrap, styles.customWidth480]}
                    handleCancel={(item) => console.log(item)}
                    modalContent={commonStyles.marB0}>
                    <View style={[styles.pageTitle]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600]}>
                            Payment Option
                        </Text>
                    </View>
                    {/* Radio buttons */}
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.height25]}>
                        {!!paymentOptions && !!paymentOptions.length ? paymentOptions.map((el, key) => (
                            <View style={[commonStyles.flex1, commonStyles.flexDirectionRow]}>
                                <RadioButtons
                                    radioButtonsClass={styles.radioButtonsOuter}
                                    key={"paymentType_" + key}
                                    options={el}
                                    onValueChange={() => this.onPaymentOptionChange(el)}
                                    isSelected={el.selectedChk}
                                />
                            </View>
                        )) : null}
                    </View>
                    {/* Details */}
                    <View style={[ commonStyles.marginBottom10, styles.customMarginTop10, commonStyles.wordBreakWord]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight600]}>
                            {`${!!paymentOptions.length && paymentOptions[0].value === FULL_PAYMENT && paymentOptions[0].selectedChk === FULL_PAYMENT ? "OneTime" : "Instalment"} Payment details for : ${item.billerName}`}
                        </Text>
                    </View>
                    {/* Table list */}
                    <View style={[commonStyles.flex1]}>
                        <TableListComponent
                            dataList={paymentsList}
                            pagination={false}
                            containerStyle={[commonStyles.marginTop10, styles.tableHeight]}
                            height={styles.maxHeight250}
                            labelKeys={paymentLabelKeys}
                            labelValues={paymentLabelValues}
                            visibility={true}
                            dateTypeFields={["executionDate", "createdOn"]}
                            dateFormat={"DD/MM/YYYY"}
                            amountTypeFields={["billAmount"]}
                            textOnly={true}
                            message={"No bills found."}
                            onlyWebView={true}
                        />
                    </View>
                    {item.billStatus === "VOID" ?
                        <View style={[ commonStyles.marginTop10]}>
                            <Text style={[commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.wordBreakWord, commonStyles.helperTextFontStyle, commonStyles.fontWeight500]}>*Bill request expired. Please connect with Biller.</Text>
                        </View> : null
                    }
                    {/* Cancel & Apply Btn */}
                    <View style={[styles.buttonContainer, Platform.OS === "web" ? styles.mViewButtonContainer : null]} >
                        <View style={[commonStyles.marginRight15]}>
                            <Button
                                title={item.billStatus === "VOID" ? "Ok" : "Cancel"}
                                key="SepaCancelButton"
                                style={[styles.activeButton, customCommonStyles.noBoxShadow, item.billStatus === "VOID" ? [styles.activeButtonPadding] : [styles.border2, commonStyles.whiteBackgroundColor]]}
                                titleTextStyle={[commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6, item.billStatus === "VOID" ? null : commonStyles.blueBorderButtonColor]}
                                onPress={() => onCancel()}
                            />
                        </View>
                        {item.billStatus === "VOID" ? null :
                            <View>
                                <Button
                                    title={"Apply"}
                                    key="SepaApplyButton"
                                    style={[styles.activeButton, styles.activeButtonPadding, customCommonStyles.noBoxShadow]}
                                    titleTextStyle={[commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading6, commonStyles.whiteColor]}
                                    onPress={() => onApply()}
                                />
                            </View>
                        }
                    </View>
                </ModalPopUp>
            </>
        }
    });

const styles = StyleSheet.create({
    pageTitle: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: 30,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 10,
                    marginBottom: 0,
                    minHeight: 15,
                };
            }
        },
    },

    activeButton: {
        backgroundColor: "#d71996",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        boxShadow: "none",
        justifyContent: 'center',
        height: 24,
        width: 108,
        textAlign: 'center'
    },
    border2: {
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "rgb(18, 119, 217)"
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        width: "100%",
        maxHeight: 34,
        marginTop: 16,
    },
    mViewButtonContainer: {
        screen: ({ width, height }) => {
            if (width === 375 && height === 553)
                return {
                    marginBottom: 56,
                };
        },
    },
    activeButtonPadding: {
        paddingLeft: 15,
        paddingRight: 15,
        borderColor: "#d71996"
    },
    radioButtonsOuter: {
        marginRight: 5
    },
    customWidth480: {
        width: "95%",
        minHeight: Platform.OS !== "web" ? "60%" : "auto",
        screen: ({ width }) => {
            if (width >= 991)
                return {
                    width: 480,
                };
        },
    },
    maxHeight250: {
        maxHeight: 80,
        marginBottom: 0,
    },
    tableHeight: {
        minHeight: 80,
        screen: ({ width }) => {
            if (Platform.OS !== "web"  && width <= 991)
                return {
                    maxWidth: Dimensions.get("window").width - 52,
                };
        },
        // maxWidth: "100%",
    },
    customMarginTop10: {
        marginTop: 10,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginTop: 20,
                    marginBottom: 0
                };
            }
        },
    },
    height25: {
        maxHeight: 25,
        screen: ({ width }) => {
            if (width <= 420) {
                return {
                    flexDirection: 'column',
                    maxHeight: 46,
                    minHeight: 42,
                    justifyContent: 'space-between',
                }

            }
        }
    }
});