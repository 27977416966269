import React, { PureComponent } from "react";
import { StyleSheet, View, Text, Image, Button } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import { CURRENCY, USER_TYPE } from "../../constant/app.data.constant";

const verifiedDoneIcon = require("../../assets/images/verified_done.png");
const errorIcon = require("../../assets/images/error.png");

export default class SubmittedComponent extends PureComponent {
  render() {
    const { message, customMargin, userType, isUberDocs, goTo, goToTitle, hasError, currency, hideButton } = this.props;
    let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
    let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;
    return (
      <View style={[styles.paidSuccessfullyComponent, commonStyles.flex1, commonStyles.alignItemsCenter, customMargin]}>
        <View>
          <Image source={hasError ? errorIcon : verifiedDoneIcon} style={styles.verified_done} />
        </View>
        <View style={[styles.successTextHolder, commonStyles.textAlignCenter]}>
          <Text style={[commonFontStyles.workSans, !!hasError ? commonStyles.redColor : commonStyles.greenColor, styles.successText]}>{!!currency ? CURRENCY[currency] : ""}{message}</Text>
        </View> 
        {!!hideButton ? null :
          <View style={styles.buttonHolder}>
            <Button
              key="gotohome"
              title={userType === USER_TYPE.PCH_PATIENT ? "Go to Dashboard" : !!goToTitle ? goToTitle : "Go to Upload Bill"}
              style={[commonStyles.primaryBtn, commonStyles.flexBasis50, commonStyles.marginLeftRight15, styles.gtohButton, clientStyle.backAndCancelButton]}
              titleTextStyle={[commonStyles.fontWeight500, commonStyles.exSmallHeading, commonStyles.primaryBtnText, commonStyles.transformNone, clientStyle.backAndCancelButtonText]}
              to={userType === USER_TYPE.PCH_PATIENT ? "patientDashboard" : !!goTo ? goTo : "uploadBillsRoute"}
            />
          </View>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  paidSuccessfullyComponent: {
    position: "relative",
    paddingTop: 10,
    paddingRight: 15,
    paddingBottom: 10,
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          padding: 0, //"0px 15px",
        };
    },
  },
  verified_done: {
    height: 36,
    width: 36,
    display: "block",
    backgroundSize: "contain",
  },
  successImage: {
    marginTop: 20,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
  },
  successText: {
    lineHeight: 29,
    fontSize: 14,
  },
  successTextHolder: {
    marginBottom: 16,
  },
  buttonHolder: {
    width: "100%"    
  },
  gtohButton: {
    minHeight: 24,
    maxHeight: 24,
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow:"none",
    width: 180,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: "100%",
        };
    },
  },
  payMathodIcon: {
    height: 24,
    width: 54,
    display: "block",
    backgroundSize: "contain",
  },
});
