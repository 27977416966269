import { CommonString } from "../../constant/appCommonText";
import R14, { } from "../../core";
import CommonUtility from "../../utils/CommonUtility";
export default class AddDoctorsDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      doctorDetails: [],
      totalBills: 0,
      currentPage: 1,
      totalPages: 1,
      resultsPerPage: 9,
      isFetched: false,
      isAddProviderBackConfirmation: false,
      hasTreatmentAddress: true
    };
  }

  async getLists(filter, fields, resultsPerPage = 10) {
    let result = await this.api.qry(
      `query PchDoctorDetails($filter: PchDoctorDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        pchDoctorDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`
      ,
      filter
    );
    if (!!result.data.pchDoctorDetails && !!result.data.pchDoctorDetails.nodes) {
      let totalCount = result.data.pchDoctorDetails.totalCount;
      const { currentPage} = this.state;
      this.setState({
        totalBills: totalCount,
        totalPages: Math.ceil(totalCount / resultsPerPage),
        isFetched:  currentPage === 1 ? true: false,
        doctorDetails: result.data.pchDoctorDetails.nodes 
      })
      return this.state.doctorDetails;
    }
    else return [];
  }

  async getDoctorsDetailsWithPage(fieldsStr = "uid DoctorName NPI specialities userLoginDetailUid phoneNo taxIdNumber firstName middleName lastName specialityObject { label value }") {
    const { isFetched, currentPage, resultsPerPage, doctorDetails, totalBills } = this.state;
    if (!!isFetched && totalBills > 0) return doctorDetails;
    else {
      let user = await this.dm.rest.getUser();
      if (user) {
        const { uid, parentUid } = user;
        let filter = {};
        filter = {
          filter: {
            parentUid: { eq: !!parentUid ? parentUid : uid },
            isActive: { eq: "true" },
          },
          sort: { field: "createdAt", order: "DESC" },
          page: currentPage,
          resultsPerPage: resultsPerPage
        };
        return await this.getLists(filter, fieldsStr, resultsPerPage);
      }
    }
  }

  // NOTE: insert Doctors Detail.
  async insertDoctorsDetail(input) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
          mutation CreatePchDoctorDetail($input: CreatePchDoctorDetailInput){
            createPchDoctorDetail(input: $input){              
              success pchDoctorDetail {
                uid
              }
            }
          }`,
        { input }
      );
      return res.data.createPchDoctorDetail.pchDoctorDetail;
    } catch (error) {
      return error;
    }
  }

  async getProvidersOnSearch(filter, fields = "uid DoctorName NPI specialities userLoginDetailUid phoneNo taxIdNumber firstName middleName lastName specialityObject { label value }") {
    try {
        let res = await this.api.qry(
          ` query PchDoctorDetails($filter: PchDoctorDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
            pchDoctorDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
              totalCount @include(if: true)
              nodes {
                ${fields}
              }
            }
          }`,
          {
            filter: filter,
            sort: { field: "DoctorName", order: "ASC" },
            page: 1,
            resultsPerPage: 10
          }
        );
        return res.data.pchDoctorDetails.nodes;
    } catch (err) {
      return err;
    }
  }

  async getProviderOnSearch(search, fields = "uid DoctorName NPI specialities userLoginDetailUid phoneNo taxIdNumber firstName middleName lastName specialityObject { label value }") {
    try {
      let user = await this.dm.rest.getUser();
      if (user) {
        const { uid, parentUid } = user;
        let res = await this.api.qry(
          ` query PchDoctorDetails($filter: PchDoctorDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
            pchDoctorDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
              totalCount @include(if: true)
              nodes {
                ${fields}
              }
            }
          }`,
          {
            filter: {
              DoctorName :{like: `%${search}%` },
              parentUid: {eq: !!parentUid ? parentUid : uid},
              isActive: { eq: "true" },
            },
            sort: { field: "DoctorName", order: "ASC" },
            page: 1,
            resultsPerPage: 10
          }
        );
        return res.data.pchDoctorDetails.nodes;
      }
      return [];
    } catch (err) {
      return err;
    }
  }
  
  async isValidateNPI(npi, fields = "uid") {
    try {
      let user = await this.dm.rest.getUser();
      if (user) {
        const { uid, parentUid } = user;
        let res = await this.dm.queryDomain.middleware(this.api.qry(
        ` query ValidateNPI($parentUid: String!, $npi: String!) {
          validateNPI(parentUid: $parentUid, npi: $npi){
            success message error data
          }
          }`,
        {
            parentUid: !!parentUid ? parentUid : uid,
            npi: npi,
        }
        ));
        let result = res.data && res.data.validateNPI;

        // check if result.data (address & basic) 
        if(result && result.success && result.data){
          // parse result (address)
          result.data = JSON.parse(result.data);

          // for address
          if(result.data && result.data.length && result.data[0].addresses && result.data[0].addresses.length ) {
            // get address_purpose location and send in result.data
            const filteredNPIaddresses = result.data[0].addresses.filter(e => e.address_purpose === "LOCATION");
            if(filteredNPIaddresses && filteredNPIaddresses.length) result.addresses = filteredNPIaddresses;
          } else result.addresses = [];

          // for baisc details
          if(result.data && result.data.length && result.data[0].basic) {
            result.basic = {};
            result.basic.firstName = result.data[0].basic.authorized_official_first_name || result.data[0].basic.first_name || "";
            result.basic.middleName = result.data[0].basic.authorized_official_middle_name || result.data[0].basic.middle_name || "";
            result.basic.lastName = result.data[0].basic.authorized_official_last_name || result.data[0].basic.last_name || "";
          } else result.basic = {};
          delete result.data;
        } else {
          result.addresses = [];
          result.basic = {};
        }
        
        return result;
      }
    } catch (error) {
      return {success: false, error: CommonString.SomethingWentWrong};
    }
  }
  // NOTE: Delete Doctor.
  async deleteDoctor(uid, fields = "uid") {
    try {
      let res = await this.api.mutate(
        `
      mutation DeleteDoctor($uid: ID!) {
        deletePchDoctorDetail(uid: $uid){
          pchDoctorDetail {
            ${fields}
          }
          success
        }
      }`,
        { uid }
      );
      return res.data;
    } catch (error) {
      return error;
    }
  }

  // NOTE: Update Doctor.
  async updateDoctorsDetail(input) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
          mutation UpdatePchDoctorDetail($input: UpdatePchDoctorDetailInput){
            updatePchDoctorDetail(input: $input){              
              success pchDoctorDetail {
                uid
              }
            }
          }`,
        { input }
      );
      return res.data.updatePchDoctorDetail.pchDoctorDetail;
    } catch (error) {
      return error;
    }
  }

  async getTreatmentAddressList(filter, fields = "uid doctorUid isActive isPrimary addressLine1 town state country postCode countryObject{label value} stateObject{label value} cityObject{label value} licenseDetails", resultsPerPage = 10) {
    let result = await this.api.qry(
      `query treatmentAddresss($filter: TreatmentAddressFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        treatmentAddresss(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`
      ,
      filter
    );
    return result.data.treatmentAddresss.nodes
  }
  
  // NOTE: insert treatment address.
  async insertTreatmentAddress(input) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
          mutation createTreatmentAddress($input: CreateTreatmentAddressInput){
            createTreatmentAddress(input: $input){              
              success treatmentAddress {
                uid
              }
            }
          }`,
        { input }
      );
      return res.data.createTreatmentAddress.treatmentAddress;
    } catch (error) {
      return error;
    }
  }

  
  // NOTE: Update Treatment Address.
  async updateTreatmentAddress(input) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
          mutation updateTreatmentAddress($input: UpdateTreatmentAddressInput){
            updateTreatmentAddress(input: $input){              
              success treatmentAddress {
                uid addressLine1 town state country cityObject{label value} stateObject{label value} doctorUid
              }
            }
          }`,
        { input }
      );
      return res.data.updateTreatmentAddress.treatmentAddress;
    } catch (error) {
      return error;
    }
  }

  // get mst specialities list
  async getMstSpecialitiesDetails(filter, fields = "uid label value") {
    try {
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetMstSpecialitiesDetails( $search: String, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]) {
        getMstSpecialitiesDetails(search: $search, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
            ${fields}
          }
        }
      }`,
        filter
      ));
      if (!!result.data.getMstSpecialitiesDetails && !!result.data.getMstSpecialitiesDetails.nodes) return result.data.getMstSpecialitiesDetails.nodes || [];
      return []; 
    } catch (error) {
      return [];
    }
  }
}
