
import React from "react";
import R14, {
  StyleSheet,
  Text,
  View,
  Platform,
  FlatList,
  Touchable,
  Image,
} from "../core";
import NoRecordsFound from "./NoRecordsFound";
import { CommonString } from "../constant/appCommonText";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { responsiveTableStyle } from "../assets/css/responsiveTableStyle";
import DateUtils from "../utils/DateUtils";
import CommonUtility from "../utils/CommonUtility";
import Bookmarked from "./Bookmared";

const arrowLeft = require("../assets/images/icPrevious.png");
const arrowRight = require("../assets/images/icNext.png");
const arrowFirst = require("../assets/images/icFirst.png");
const arrowLast = require("../assets/images/icLast.png");
const editIcon = require("../assets/images/edit.png");
const deleteIcon = require("../assets/images/delete.png");
const eyeIcon = require("../assets/images/eye.png");
const addIcon = require("../assets/images/plus_solid.png");

export default R14.connect(
  class TableListComponent extends React.Component {

    shortenText = (txt = "", limit = 20) => {
      if (!!txt && !isNaN(txt) && !Number.isInteger(+txt)) {
        const num = +txt;
        return num.toFixed(2);
      } else if (!!txt && txt.length > limit && Platform.OS !== "web") {
        return txt.substring(0, limit) + '...';
      } else {
        return txt;
      }
    };

    dateFormatter = (value, format = "MM/DD/YYYY") => {
      let formatedDate = "";
      if (!!value && value.includes('/') && value.split(":") && value.split(":").length) {
        value.split(":").forEach(dat => {
          formatedDate = !!formatedDate && !!dat ? `${formatedDate}:${DateUtils.formatDate(dat, format)}` : DateUtils.formatDate(dat, format);
        });
        return formatedDate
      } else if (!!value) {
        return DateUtils.formatDate(value, format);
      } else return value;
    };

    render() {
      const {
        totalPages,
        startIndex,
        endIndex,
        rowItems,
        dataList,
        getFirst,
        getLast,
        getNext,
        getPrevious,
        currentPage,
        totalBills,
        labelKeys,
        labelValues,
        message,
        pagination,
        onActionClick,
        icon = editIcon,
        onViewClick,
        tableStyle,
        listingStyle,
        theadStyle,
        height,
        dateTypeFields,
        amountTypeFields,
        textOnly,
        containerStyle = null,
        onToggleBookmark = null,
        onProfileIcon = null,
        onlyWebView = false,
        dateFormat = "MM/DD/YYYY"
      } = this.props;

      return (
        <View style={[styles.container, commonStyles.dashboardScrollView, commonStyles.flex1, containerStyle]}>
          <View style={[styles.table, tableStyle]}>
            {!!dataList && !!dataList.length > 0 ? (
              <View style={[styles.table, commonStyles.margin0, commonStyles.padding0, styles.borderWidth0, !!onlyWebView ? styles.minHeight20 : null]}>
                <View style={[!!onlyWebView ? null : styles.theadOuter]}>
                  <View
                    style={[
                      styles.thead,
                      commonStyles.flex1,
                      commonStyles.justifyContentSpaceBetween,
                      commonStyles.flexDirectionRow,
                      !!onlyWebView ? styles.minHeight20 : null,
                      theadStyle
                    ]}
                  >
                    {labelKeys.map((label, idx) => (
                      <View key={"label_" + idx} style={[styles.th, commonStyles.paddingRight16,
                      label === "Action" ? commonStyles.alignItemsEnd : null,
                      label === "View" ? commonStyles.alignItemsEnd : null,
                      !!onlyWebView ? styles.webThStyle : null,
                      ]}>
                        <Text
                          style={[
                            commonStyles.heading6,
                            commonStyles.fontWeight500,
                            commonFontStyles.workSans,
                            commonStyles.darkGrayColor,
                            commonStyles.tableKey,
                            commonStyles.wordBreakWord,
                            !!onToggleBookmark && !!onProfileIcon && idx === 0 ? commonStyles.marginLeft30 : null,
                            !!onToggleBookmark && !onProfileIcon && idx === 0 ? commonStyles.marginLeft15 : null,
                            !onToggleBookmark && !!onProfileIcon && idx === 0 ? commonStyles.marginLeft10 : null,
                          ]}
                        >
                          {label}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            ) : null}
            <FlatList
              style={[
                styles.flatlistEle,
                dataList && dataList.length > 0
                  ? styles.billListComponent
                  : styles.noItemsComponent,
                styles.borderWidth0,
                commonStyles.marT0,
                commonStyles.marRT0,
                commonStyles.marLT0,
                commonStyles.positionRelative,
                !!pagination ? commonStyles.marginBottom30 : null,
                !!height ? height : null,
                listingStyle
              ]}
              data={dataList || []}
              ListEmptyComponent={
                <View style={[styles.padd10, commonStyles.flex1]}>
                  <NoRecordsFound
                    noRecords={[commonStyles.marRT0, commonStyles.marLT0, commonStyles.margin0]}
                    message={!!message ? message : CommonString.NoBillFound}
                  />
                </View>
              }
              keyExtractor={(el, i) => i}
              renderItem={({ item, index }) => (
                <View key={"tableRow_" + index} style={[styles.customFlex, commonStyles.wordBreakWord, !!onlyWebView ? styles.webCustomFlexStyle : null]}>
                  {/* On icons before first key */}
                  <View
                    style={[
                      customCommonStyles.valueInMbileBreakWord,
                      commonStyles.wordBreakWord,
                      commonStyles.flex1,
                      commonStyles.flexDirectionRow,
                      commonStyles.mView,
                      styles.mViewIconContainer,
                      !!onToggleBookmark & !!onProfileIcon ? styles.mViewBothIconMaxWidth : null,
                      !!onToggleBookmark || !!onProfileIcon ? null : commonStyles.displayNone,
                    ]}>
                    <View style={[commonStyles.flex1, commonStyles.flexDirectionColumn]}>
                      {!!onProfileIcon && !!item[labelValues[0]] ?
                        <Touchable onPress={() => onProfileIcon(item, "pro")} >
                          <View style={[responsiveTableStyle.userIconDiv, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, styles.marginLeft0, commonStyles.marginTop5, commonStyles.marginRight5]}>
                            <Text
                              style={[
                                // commonMetricsStyle.heading6,
                                commonStyles.fontWeight500,
                                commonFontStyles.workSans,
                                commonStyles.smallHeading,
                                commonStyles.userInitial,
                                commonStyles.contentColor,
                                responsiveTableStyle.userInitial,
                                commonStyles.dBlock
                              ]}>
                              {CommonUtility.shortName(`${item[labelValues[0]]}`)}
                            </Text>
                          </View>
                        </Touchable>
                        : null}
                      {!!onToggleBookmark && !!!["VOID", "CANCELLED", "REJECTED", "PAID"].includes(item.billStatus) ?
                        <View style={[commonStyles.marginRight15, commonStyles.marginTop5]}>
                          <Bookmarked
                            bookedMarkSpacing={responsiveTableStyle.bookedMarkSpacing}
                            onToggle={() => onToggleBookmark(item, 'toogle')}
                            isBookmarked={item.bookmark || false}
                            bookedMarkImgStyle={[styles.marginLeft0, commonStyles.marginTop5, commonStyles.icon15]}
                          />
                        </View>
                        : null}
                    </View>
                  </View>
                  <View
                    style={[
                      styles.tr,
                      commonStyles.flex1,
                      commonStyles.justifyContentSpaceBetween,
                      styles.customFlexDirection,
                      commonStyles.marLT0,
                      commonStyles.marRT0,
                      commonStyles.wordBreakWord,
                      !!onlyWebView ? styles.webTrStyle : null,
                    ]}
                  >
                    {labelValues.map((value, idx) => (
                      <View key={"value_" + idx} style={[styles.td, commonStyles.wordBreakWord,
                      labelKeys[idx] === "Action" && !!value && value.split(":") && value.split(":").length > 2 ? styles.paddingLeft16 : commonStyles.paddingRight16,
                      (labelKeys[idx] === "Action" || labelKeys[idx] === "View") ?
                        commonStyles.alignItemsEnd : null,
                      !!textOnly && labelKeys[idx] === "Action" ? styles.justifyContentCenter : null,
                      !!onlyWebView ? styles.webTdStyle : null,
                      ]}>
                        {/* Key lable on responsive mobile */}
                        <View
                          style={[
                            commonStyles.mView,
                            commonStyles.marginBottom5,
                            styles.mobileLabels,
                            commonStyles.wordBreakWord,
                            !!onlyWebView ? commonStyles.displayNone : null,
                          ]}
                        >
                          <Text
                            style={[
                              commonFontStyles.workSans,
                              commonStyles.fontWeight700,
                              commonStyles.heading6,
                              commonStyles.wordBreakWord,
                            ]}
                          >
                            {labelKeys[idx]}:
                          </Text>
                        </View>

                        {/* Value on web + responsive */}
                        <View style={[
                          customCommonStyles.valueInMbileBreakWord,
                          commonStyles.wordBreakWord,
                          labelKeys[idx] === "Action" ? commonStyles.dFlex : null,
                          labelKeys[idx] === "Action" ? commonStyles.flexDirectionRow : null,
                          labelKeys[idx] === "Action" ? commonStyles.flexWrap : null,
                          (!!onToggleBookmark || !!onProfileIcon) && idx === 0 ? commonStyles.displayNone : null
                        ]}>
                          {(labelKeys[idx] === "Action" || labelKeys[idx] === "View") ?
                            !!value ? value.split(":").map((act, id) => (
                              <Touchable key={"act_" + idx + id} onPress={() => onActionClick(item, act)} style={!!textOnly ? [responsiveTableStyle.paynowBtn] : null}>
                                <View key={"actView_" + idx + id} style={!!textOnly ? [responsiveTableStyle.paynowBtnInnerWrap, customCommonStyles.customPaynowBtnInnerWrap] : [customCommonStyles.customActionBtn, styles.customAlignItemsEnd, commonStyles.marginBottom5]}>
                                  {!!textOnly ?
                                    <Text style={[commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.whiteColor, commonStyles.textAlignCenter, commonStyles.fullWidth, commonStyles.exSmallHeading, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemCenter]}>{act}</Text>
                                    :
                                    <Image
                                      key={"actImg_" + idx + id}
                                      source={
                                        act === "view" ? eyeIcon
                                          : act === "delete" ? deleteIcon
                                            : act === "add" ? addIcon
                                              : icon
                                      }
                                      style={commonStyles.icon18}
                                    />}
                                </View>
                              </Touchable>
                            ))
                              :
                              <Touchable key={"view_" + idx} onPress={async () => await onViewClick(item)}>
                                <View key={"view_" + idx} style={[customCommonStyles.customActionBtn, styles.customAlignItemsEnd, commonStyles.marginBottom5]}>
                                  <Text
                                    style={[commonStyles.wordBreakWord, commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans, commonStyles.tableValue, commonStyles.dBlock, styles.blueColor]}
                                  >
                                    {"View EOB"}
                                  </Text>
                                </View>
                              </Touchable>
                            :
                            <Text
                              style={[commonStyles.wordBreakWord, commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans, commonStyles.tableValue, commonStyles.dBlock, labelKeys[idx] === "View" ? styles.blueColor : null]}
                            >
                              {!!dateTypeFields && dateTypeFields.includes(value) ? this.dateFormatter(item[value], dateFormat)
                                : labelKeys[idx] === "View" ? "View EOB"
                                  : !!amountTypeFields && amountTypeFields.includes(value) ? item[value] ? this.shortenText(CommonUtility.formatAmount(item[value])) : "0.00"
                                    : this.shortenText(item[value])}
                            </Text>
                          }
                        </View>

                        {/* On icons before first key */}
                        <View
                          style={[
                            customCommonStyles.valueInMbileBreakWord,
                            commonStyles.wordBreakWord,
                            commonStyles.flex1,
                            commonStyles.flexDirectionRow,
                            (!!onToggleBookmark || !!onProfileIcon) && idx === 0 ? null : commonStyles.displayNone
                          ]}
                        >
                          {!!onToggleBookmark && !!!["VOID", "CANCELLED", "REJECTED", "PAID"].includes(item.billStatus) && idx === 0 ?
                            <View style={[commonStyles.paddingTop5, commonStyles.wView]}>
                              <Bookmarked
                                bookedMarkSpacing={responsiveTableStyle.bookedMarkSpacing}
                                onToggle={() => onToggleBookmark(item, 'toogle')}
                                isBookmarked={item.bookmark || false}
                                bookedMarkImgStyle={[styles.marginLeft0, commonStyles.marginTop3, commonStyles.marginBottom3, commonStyles.icon15]}
                              />
                            </View>
                            : <View style={[commonStyles.paddingTop5, styles.bookedMark, commonStyles.wView]}>
                            </View>}
                          {!!onProfileIcon && !!item[value] && idx === 0 ?
                            <Touchable style={[commonStyles.wView]} onPress={() => onProfileIcon(item, "pro")} >
                              <View style={[responsiveTableStyle.userIconDiv, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, styles.marginLeft0, commonStyles.marginTop3, commonStyles.marginBottom3, commonStyles.marginRight5, commonStyles.wView]}>
                                <Text
                                  style={[
                                    commonStyles.fontWeight500,
                                    commonFontStyles.workSans,
                                    commonStyles.smallHeading,
                                    commonStyles.userInitial,
                                    commonStyles.contentColor,
                                    responsiveTableStyle.userInitial,
                                    commonStyles.dBlock
                                  ]}>
                                  {CommonUtility.shortName(`${item[value]}`)}
                                </Text>
                              </View>
                            </Touchable>
                            : null}

                          <Text style={[commonStyles.wordBreakWord, commonStyles.darkGrayColor, commonStyles.heading6, commonFontStyles.workSans, commonStyles.tableValue, commonStyles.dBlock,]}>
                            {this.shortenText(item[value])}
                          </Text>

                        </View>

                      </View>
                    ))}
                  </View>
                </View>
              )}
            />
            {dataList && dataList.length && !!pagination ?
              <View style={[commonStyles.flex1, commonStyles.customFlexDirection, commonStyles.paginationWrap, commonStyles.flexGrow0, customCommonStyles.customPaginationWrap, styles.paginationWrap]}>
                <View style={[commonStyles.rowPerPage, styles.marginLeft0]}>
                  <Text style={[commonStyles.darkGrayColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}>Rows per page: {rowItems}</Text>
                </View>
                <View style={[commonStyles.displayingItem]}>
                  <Text style={[commonStyles.darkGrayColor, commonStyles.exSmallHeading, commonFontStyles.workSans]}>Displaying item {startIndex}-{endIndex} of {totalBills}</Text>
                </View>
                <View style={[commonStyles.flex1, commonStyles.justifyContentFlexEnd, commonStyles.flexDirectionRow, commonStyles.paginationInnerWrap, commonStyles.flexGrow0]}>
                  <View>
                    <Touchable
                      style={[currentPage === 1 ? commonStyles.disabledInput : null]}
                      onPress={() => currentPage === 1 ? null : getFirst()}>
                      <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === 1 ? commonStyles.disabledInput : null]}>
                        <Image source={arrowFirst} style={commonStyles.icon18} />
                      </View>
                    </Touchable>
                  </View>
                  <View>
                    <Touchable
                      style={[currentPage === 1 ? commonStyles.disabledInput : null]}
                      onPress={() => currentPage === 1 ? null : getPrevious()}>
                      <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === 1 ? commonStyles.disabledInput : null]}>
                        <Image source={arrowLeft} style={commonStyles.icon18} />
                      </View>
                    </Touchable>
                  </View>
                  <View>
                    <Touchable
                      style={[currentPage === totalPages ? commonStyles.disabledInput : null]}
                      onPress={() => currentPage === totalPages ? null : getNext()}>
                      <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === totalPages ? commonStyles.disabledInput : null]}>
                        <Image source={arrowRight} style={commonStyles.icon18} />
                      </View>
                    </Touchable>
                  </View>
                  <View>
                    <Touchable
                      style={[currentPage === totalPages ? commonStyles.disabledInput : null]}
                      onPress={() => currentPage === totalPages ? null : getLast()}>
                      <View style={[styles.paginationBtn, commonStyles.flex1, commonStyles.flexGrow0, commonStyles.alignItemCenter, commonStyles.justifyContentCenter, customCommonStyles.paginationBtnWidth, currentPage === totalPages ? commonStyles.disabledInput : null]}>
                        <Image source={arrowLast} style={commonStyles.icon18} />
                      </View>
                    </Touchable>
                  </View>
                </View>
              </View>
              : null}
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  container: {
    marginBottom: 16
  },
  table: {
    marginRight: 16,
    marginLeft: 16,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderBottom: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          padding: 0,
          marginTop: 2,
          marginRight: 0,
          marginLeft: 0,
        };
    },
  },
  borderWidth0: {
    borderWidth: 0
  },
  theadOuter: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  thead: {
    paddingTop: 3,
    paddingRight: 16,
    paddingBottom: 3,
    paddingLeft: 16,
    position: "relative",
    zIndex: 999,
    backgroundColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    flexDirection: "row",
  },
  tr: {
    flexDirection: "row",
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 10,
          paddingRight: 16,
          paddingBottom: 10,
          paddingLeft: 16,
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          borderRightWidth: 0,
          // borderBottomWidth: 1,
          borderLeftWidth: 0,
          flexDirection: "column",
        };
    },
  },
  webTrStyle: {
    flexDirection: "row",
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 0,
    paddingLeft: 16,
    position: "relative",
    margin: 0,
  },
  th: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
  },
  webThStyle: {
    flex: 1,
    flexBasis: "33.5%",
    maxWidth: "33.5%",
  },
  td: {
    flex: 1,
    flexBasis: "22.5%",
    maxWidth: "22.5%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "75%",
          maxWidth: "100%",
          flexDirection: "row",
        };
    },
  },
  webTdStyle: {
    flex: 1,
    flexBasis: "33.5%",
    maxWidth: "33.5%",
    paddingLeft: 0,

  },
  flatlistEle: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "block",
        }
      }
    },
  },
  billListComponent: {
    marginTop: 0,
    marginRight: -16,
    marginBottom: 30,
    marginLeft: -16,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    position: 'relative',
    screen: ({ width }) => {
      if (width <= 991)
        return {
          // borderTopWidth: 1,
          marginTop: 150,
          marginRight: 0,
          marginBottom: 30,
          marginLeft: 0,
          paddingBottom: 0,
        };
    },
  },
  noItemsComponent: {
    marginTop: 88,
    marginBottom: 50,
    marginLeft: 16,
    marginRight: 16,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 145,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        };
    },
  },
  customFlexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  mobileLabels: {
    marginRight: 10,
    minWidth: 120,
  },
  customFlex: {
    flex: 1,
    flexDirection: "row",
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          border: "solid",
          borderTop: "none",
          borderWidth: 1,
          borderColor: "#dadada",
        };
      }
      if (width <= 992) {
        return {
          borderBottomWidth: 1,
          borderBottom: "solid",
          borderColor: "#dadada",
        };
      }
    },
  },
  webCustomFlexStyle: {
    borderStyle: "solid",
    borderTop: 0,
    borderWidth: 1,
    borderColor: "#dadada",
  },
  paginationWrap: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          marginTop: 16,
          marginBottom: 28,
          marginRight: 0,
        };
      } else if (Platform.OS !== "web" && width <= 991) {
        return {
          borderTopWidth: 0,
        }
      }
    },
  },
  marginLeft0: {
    marginLeft: 0,
  },
  customAlignItemsEnd: {
    alignItems: "flex-end",
    minHeight: 18,
    paddingTop: 5,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          alignItems: "flex-start"
        };
      }
    },
  },
  blueColor: {
    color: "#d71996"
  },
  paddingLeft16: {
    paddingLeft: 16,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingLeft: 0
        };
      }
    }
  },
  justifyContentCenter: {
    screen: ({ width }) => {
      if (width > 991) {
        return {
          paddingTop: 5,
          // justifyContent: "center"
        };
      }
    }
  },
  mViewIconContainer: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "5%",
          marginLeft: 16,
          marginTop: 5,
          flexDirection: "row",
          flex: 1,
        };
      }
    }
  },
  mViewBothIconMaxWidth: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          maxWidth: "5%",
        };
      }
    }
  },
  minHeight20: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          minHeight: 20,
        };
      }
    }
  },
  bookedMark: {
    height: 22,
    width: 15,
    minWidth: 15,
    display: "block",
  },
});
