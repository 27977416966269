
import React from "react";
import R14, {
  StyleSheet,
  Text,
  View,
  FlatList,
  Touchable,
  Image,
  Button
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import ModalPopUp from "./ModalPopUp";

const viewBlueIcon = require("../assets/images/viewBlue.png");

export default R14.connect(
  class PdfListModalComponent extends React.Component {

    render() {
      const {
        clientStyle,
        commonFontStyles,
        isPdfConfirmation,
        selectedItem,
        selectedItemType,
        pdfs,
        onDownloadOrViewBillDocuments,
        onHidePdfConfimation,
        headerText = "EOB Document(s)",
      } = this.props;

      return (
        <ModalPopUp
          visibility={isPdfConfirmation}
          isFooter={true}
          bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.confirmationModal, clientStyle.secondaryBgColor, pdfs.length ? null : styles.width300,]}
          modalContent={[commonStyles.marB0]}>
          <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter,]}>
            <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight500, commonStyles.textAlignCenter]}>{headerText}</Text>
          </View>

          <FlatList
            style={[
              styles.messageComponent,
              styles.dashboardMessageComponent,
              customCommonStyles.customDashboardMessageComponent,
              styles.tboday,
              styles.noBorder,
              pdfs.length ? null : commonStyles.displayNone,
              clientStyle.secondaryBgColor,
            ]}
            data={pdfs}
            keyExtractor={(el, i) => i}
            renderItem={({ item, index }) => (
              <View
                style={[
                  styles.tr,
                  commonStyles.flex1,
                  commonStyles.alignItemsStart,
                  commonStyles.flexDirectionRow,
                  styles.attachmentCard
                ]}>
                <View style={[commonStyles.marginRight16, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                  <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight500, customCommonStyles.wordBreakWord, commonStyles.tableValue]}>
                    {(index + 1) + ")  "}
                  </Text>
                  <View style={[commonStyles.marginRight16]}>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, customCommonStyles.wordBreakWord]}>
                      {item.filename}
                    </Text>
                    <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight300, customCommonStyles.wordBreakWord, commonStyles.tableValue]}>
                      {item.size}
                    </Text>
                  </View>

                </View>
                <Touchable
                  style={[styles.customMarLAuto,]}
                  onPress={() => {
                    selectedItem.mergedFileName = item.filename;
                    selectedItem.dataSourceType = item.contentType ? item.contentType.toLowerCase() : "";
                    onDownloadOrViewBillDocuments(selectedItem, selectedItemType)
                  }}>
                  <Image source={viewBlueIcon} style={[styles.viewPdfIconSize]} />
                </Touchable>
              </View>
            )}
          />

          <View style={[commonStyles.smallModalBtnWrapper, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow]}>
            <Button
              title={"OK"}
              style={[
                commonStyles.blueButton,
                styles.buttons,
                customCommonStyles.noBoxShadow,
                clientStyle.submitButton
              ]}
              onPress={() => onHidePdfConfimation()}
              titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonFontStyles.workSans, commonStyles.whiteColor, clientStyle.submitButtonText, commonStyles.exSmallHeading]}
            />
          </View>
        </ModalPopUp>
      );
    }
  }
);

const styles = StyleSheet.create({
  confirmationModal: {
    width: 420,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: 350,
          maxWidth: "calc(90vw)"
        };
      }
    }
  },
  width300: {
    width: 300
  },
  messageComponent: {
    backgroundColor: "#fff",
  },
  dashboardMessageComponent: {
    marginTop: 200, //164,
    marginRight: 33,
    marginLeft: 16,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 16,
    paddingLeft: 16,
    display: "block",
    flexGrow: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTop: 0,
    // position: "unset",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginTop: 200,
          marginRight: 16,
          marginLeft: 16,
        };
      }
      if (width >= 320 && width <= 991) {
        return {
          paddingTop: 15,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
        };
      }
    },
  },
  tboday: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          position: "relative",
          margin: 0,
          padding: 0,
          maxHeight: "calc(100vh - 360px)"
        };
    },
  },
  noBorder: {
    borderTopWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    marginTop: 30,
    padding: 16,
    paddingTop: 0
  },
  tr: {
    paddingTop: 2,
    paddingBottom: 2,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderWidth: 1,
    borderBottomWidth: 0,
    // flexWrap: "wrap",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 5,
          paddingBottom: 5,
          width: "100%",
          borderLeft: 0,
          borderRight: 0,
          borderWidth: 0,
          borderBottomWidth: 1,
        };
    },
  },
  attachmentCard: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#a1a4a8",
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
          minHeight: 65,
        };
      }
    },
  },
  customMarLAuto: {
    marginLeft: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  viewPdfIconSize: {
    width: 25,
    height: 20,
    minWidth: 30,
    backgroundSize: "contain",
    display: "block",
    backgroundPosition: "center center",
  },
  buttons: {
    height: 38,
    padding: 0,
    minHeight: 38,
    width: 100,
    maxWidth: 100,
    flexBasis: "48%",
  },
});
