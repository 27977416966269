import React, { Component } from "react";
import R14, { StyleSheet, View, Text, Platform } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { VERSION_NO } from "../constant/app.data.constant";
export default R14.connect(
  class CopyRight extends Component {
    render() {
      const { customClass } = this.props;
      return (
        <View style={[styles.copyRightWrapper, customClass, Platform.OS !== "web" ? commonStyles.marginLeft16 : null]}>
          <Text style={[commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.exSmallHeading, commonStyles.contentColor]}>
          {this.props.app.dm.userSession.state.isUberDocs ? " " :  `© ${new Date().getFullYear()} Exela Technologies, All Rights Reserved. ${VERSION_NO.label}`}
          </Text>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  copyRightWrapper: {
    marginTop: "auto",
    textAlign: "center",
    paddingBottom: 7,
    paddingTop: 7,
    paddingLeft: 0,
    paddingRight: 0,
    screen: ({ width, height }) => {
      if (width <= 991){
        return {
          // display: "none",
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
        };
      }
      if (width === 1366 && height === 657){
        return {
          marginBottom: 7,
        };
      }
    },
  },
});
