import React from "react";
import R14, { StyleSheet, Platform, Touchable, Image, View, Text } from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import { responsiveTableStyle } from "../../assets/css/responsiveTableStyle";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
import NoRecordsFound from "../../components/NoRecordsFound";
import { CommonString } from "../../constant/appCommonText";
import { analyticsLogs } from "../../utils/AnalyticLogs";


const profile = require("../../assets/images/icManageUserB.png");
export default R14.connect(
  class OrganizationScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    switchOrganization = async (item) => {
      const { userLoginDetail } = this.props;
      analyticsLogs(CommonString.ORGANIZATION, `${CommonString.ORGANIZATION_SWITCHED}`, this.props.app);
      await this.props.app.dm.userSession.updateSMBOrganization(userLoginDetail, item);
    };

    render() {
      const { organizations } = this.props;
      const { defaultOrg } = this.props.app.dm.userSession.state;
      return (
        <View style={[styles.orgnizationcreen, commonStyles.padding0]}>
          <PCHLoaderComponent isVisiable={!!this.stateisLoading} labelText={"Please wait"} />
            <View style={[styles.container, commonStyles.dashboardScrollView, commonStyles.flex1]}>
              <View style={[commonStyles.flex1, commonStyles.flexDirectionRow, commonStyles.flexWrap, styles.mainContainer]}>
                {!!organizations && organizations.length > 0 ?
                  organizations.map((item, i) => {
                    return (
                      <View key={`orgitem_${i}`} style={[responsiveTableStyle.dashboardHeader, customCommonStyles.customDashboardHeader, styles.customWidth]}>
                        <View style={[commonStyles.justifyContentSpaceBetween, responsiveTableStyle.countBoxHolder, commonStyles.flexDirectionRow, customCommonStyles.customCountBoxHolder, styles.customCountBoxHolder]}>
                          <View style={[responsiveTableStyle.countBoxWrapper, styles.countBoxWrapper, Platform.OS !== "web" ? commonStyles.paddingTop0 : null]}>
                            <View style={[responsiveTableStyle.countBox, styles.countBox]}>
                              <View style={[responsiveTableStyle.countBoxImg, styles.orgIcon]}>
                                <Image source={profile} style={commonStyles.icon20} />
                              </View>
                              <View style={[commonStyles.flexDirectionColumn, commonStyles.flex1, responsiveTableStyle.countBoxTextHolder, styles.countBoxTextHolder]}>
                                <View style={[commonStyles.paddingTop5, styles.height32]}>
                                  <Text style={[responsiveTableStyle.billCountText, commonStyles.fontWeight500, commonFontStyles.workSans, commonStyles.heading5]}>{item.orgName}</Text>
                                </View>
                                <View>
                                  <Text style={[responsiveTableStyle.billCountText, responsiveTableStyle.countBoxTitleText, commonStyles.fontWeight400, commonFontStyles.workSans, commonStyles.smallHeading]}>{item.pid}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View style={[styles.marginTopAuto, Platform.OS !== "web" ? commonStyles.marB10 : null]}>
                              <Touchable onPress={() => defaultOrg.pid === item.pid ? null : this.switchOrganization(item)}>
                                <View style={[commonStyles.blueButton, styles.switchBtn, defaultOrg.pid === item.pid ? customCommonStyles.disabledBtn : null]}>
                                  <Text style={[commonStyles.exSmallHeading, commonFontStyles.workSans, commonStyles.fontWeight400, commonStyles.whiteColor]}>Switch</Text>
                                </View>
                              </Touchable>
                            </View>
                          </View>
                        </View>
                      </View>
                    )
                  }) :
                  <NoRecordsFound
                    noRecords={[commonStyles.marginTop15, commonStyles.marT0, commonStyles.marRT0, commonStyles.marLT0, styles.customMargin16]}
                    message={CommonString.NoRecordFound}
                  />}
              </View>
            </View>
        </View>
      )

    }
  })
const styles = StyleSheet.create({
  orgnizationcreen: {
    padding: 15,
    ...StyleSheet.margin(0, 0, 0, 0),
    height: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          backgroundColor: "#fff",
        };
      }
      if (width <= 640) {
        return {
          margin: 0,
          paddingBottom: 20
        };
      }
    },
  },
  pageTitleHolder: {
    // backgroundColor: "#fff",
    zIndex: 999,
    paddingTop: 7,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    background: "#f4f4f4",
  },
  pageTitle: {
    color: "#151b26",
  },
  container: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "column"
  },
  zIndex9: {
    zIndex: 9
  },
  orgIcon: {
    height: 30,
    width: 30,
    backgroundSize: "cover",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5
  },
  customWidth: {
    width: "31.5%",
    marginBottom: 16,
    marginRight: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    height: 75,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "95%",
          marginLeft: 10,
          marginTop: 10,
          marginBottom: 5
        }
      }
    }
  },
  marginTopAuto: {
    marginTop: "auto",
    marginBottom: 0,
    width: "20%"
  },
  switchBtn: {
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  countBoxWrapper: {
    flexDirection: "row",
    display: "flex",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
          paddingBottom: 16,
          height: 75,
        }
      }
    }
  },
  countBoxTextHolder: {
    justifyContent: "flex-start",
    minWidth: "80%",
    maxWidth: "80%"
  },
  countBox: {
    flexDirection: "row",
    minWidth: "75%",
    height: "100%",
  },
  customCountBoxHolder: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  mainContainer: {
    flexGrow: 0,
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          margin: 16,
          marginTop: 0,
          paddingTop: 16,
          backgroundColor: "#fff"
        }
      }
    }
  },
  height32: {
    height: 32
  },
  customMargin16: {
    marginLeft: 16,
    height: 40,
    width: "100%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          margin: 16
        };
      }
    },
  },
});
