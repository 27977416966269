import React from "react";
import R14, { StyleSheet, Text, View, Image} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import ToggleRadioBtn from "../components/ToggleRadioBtn";
import { MY_PREFERENCES_LIST } from "../constant/app.data.constant";

const emailIcon = require("../assets/images/email.png");
const smsIcon = require("../assets/images/sms.png");
const fingerprintIcon = require("../assets/images/fingerprint.png");
const patternIcon = require("../assets/images/pattern.png");
const otpIcon = require("../assets/images/otp.png");
const faceRecognitionIcon = require("../assets/images/faceRecognition.png");
const notificatIcon = require("../assets/images/notifications.png");

export default R14.connect(
  class MyPreferencesScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: false,
        sms: false,
        fingerprint: false,
        pattern: false,
        oneTimePassword: false,
        pin: false,
        faceRecognition: false,
        notifications: true,
      };
      this.preferenceList = MY_PREFERENCES_LIST;
    }
    toggleSwitch = async (val) => {
      this.setState((prevState) => ({
        email: val === "email" ? !this.state.email : prevState.email,
        sms: val === "sms" ? !this.state.sms : prevState.sms,
        fingerprint: val === "fingerprint" ? !this.state.fingerprint : prevState.fingerprint,
        pattern: val === "pattern" ? !this.state.pattern : prevState.pattern,
        oneTimePassword: val === "oneTimePassword" ? !this.state.oneTimePassword : prevState.oneTimePassword,
        pin: val === "pin" ? !this.state.pin : prevState.pin,
        faceRecognition: val === "faceRecognition" ? !this.state.faceRecognition : prevState.faceRecognition,
        notifications: val === "notifications" ? !this.state.notifications : prevState.notifications,
      }));
      this.preferenceList = {
        email: val === "email" ? !this.state.email : this.state.email,
        sms: val === "sms" ? !this.state.sms : this.state.sms,
        fingerprint: val === "fingerprint" ? !this.state.fingerprint : this.state.fingerprint,
        pattern: val === "pattern" ? !this.state.pattern : this.state.pattern,
        oneTimePassword: val === "oneTimePassword" ? !this.state.oneTimePassword : this.state.oneTimePassword,
        pin: val === "pin" ? !this.state.pin : this.state.pin,
        faceRecognition: val === "faceRecognition" ? !this.state.faceRecognition : this.state.faceRecognition,
        notifications: val === "notifications" ? !this.state.notifications : this.state.notifications,
      };
      let list = await this.props.app.dm.myPreferenceDomain.getMyPreference();
      if (!!list.length > 0) {
        try {
          await this.props.app.dm.myPreferenceDomain.updatePreference(this.preferenceList, list[0].uid, list[0].payerId);
        } catch (error) {
          console.log("error in updating", error);
        }
      } else {
        try {
          await this.props.app.dm.myPreferenceDomain.savePreference(this.preferenceList);
        } catch (err) {
          console.log("error in saving", err);
        }
      }
    };
    componentDidMount() {
      const { myPreferenceList } = this.props;
      if (!!myPreferenceList.length > 0) {
        let preferences = JSON.parse(myPreferenceList[0].setPreferenceList);
        this.setState({
          email: preferences.email,
          sms: preferences.sms,
          fingerprint: preferences.fingerprint,
          pattern: preferences.pattern,
          oneTimePassword: preferences.oneTimePassword,
          pin: preferences.pin,
          faceRecognition: preferences.faceRecognition,
          notifications: preferences.notifications,
        });
      }
    }
    render() {
      return (
        
            <View style={[styles.container]}>
                <View style={[commonStyles.flex1, commonStyles.flexBasis48]}>
                  <View style={[commonStyles.paddingLeftRight16, commonStyles.marginTop10]}>
                    <Text style={[commonStyles.heading5, commonStyles.fontWeight500, commonStyles.userInitial, commonFontStyles.workSans]}>
                      Communication
                    </Text>
                  </View>
                  <View style={[styles.preferencesList, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <View style={commonStyles.marginRight15}>
                        <Image source={emailIcon} style={[commonStyles.icon35]} />
                      </View>
                      <View>
                        <Text style={[commonStyles.heading6, commonStyles.userInitial, commonFontStyles.workSans]}>Email</Text>
                      </View>
                    </View>
                    <View>
                      <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="email" on={this.state.email} />
                    </View>
                  </View>
                  <View style={[styles.preferencesList, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <View style={commonStyles.marginRight15}>
                        <Image source={smsIcon} style={[commonStyles.icon35]} />
                      </View>
                      <View>
                        <Text style={[commonStyles.heading6, commonStyles.userInitial, commonFontStyles.workSans]}>SMS</Text>
                      </View>
                    </View>
                    <View>
                      <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="sms" on={this.state.sms} />
                    </View>
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.flexBasis48]}>
                  <View style={[commonStyles.paddingLeftRight16, commonStyles.marginTop10]}>
                    <Text style={[commonStyles.heading5, commonStyles.fontWeight500, commonStyles.userInitial, commonFontStyles.workSans]}>
                      Secure login
                    </Text>
                  </View>
                  <View style={[styles.preferencesList, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <View style={commonStyles.marginRight15}>
                        <Image source={fingerprintIcon} style={[commonStyles.icon35]} />
                      </View>
                      <View>
                        <Text style={[commonStyles.heading6, commonStyles.userInitial, commonFontStyles.workSans]}>Fingerprint</Text>
                      </View>
                    </View>
                    <View>
                      {!this.state.faceRecognition ? (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="fingerprint" on={this.state.fingerprint} />
                      ) : (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="" on={false} disable={styles.lightGrayBg} />
                      )}
                    </View>
                  </View>
                  <View style={[styles.preferencesList, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <View style={commonStyles.marginRight15}>
                        <Image source={patternIcon} style={[commonStyles.icon35]} />
                      </View>
                      <View>
                        <Text style={[commonStyles.heading6, commonStyles.userInitial, commonFontStyles.workSans]}>Pattern</Text>
                      </View>
                    </View>
                    <View>
                      {!(this.state.oneTimePassword || this.state.pin) ? (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="pattern" on={this.state.pattern} />
                      ) : (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="" on={false} disable={styles.lightGrayBg} />
                      )}
                    </View>
                  </View>
                  <View style={[styles.preferencesList, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <View style={commonStyles.marginRight15}>
                        <Image source={otpIcon} style={[commonStyles.icon35]} />
                      </View>
                      <View>
                        <Text style={[commonStyles.heading6, commonStyles.userInitial, commonFontStyles.workSans]}>One Time Password</Text>
                      </View>
                    </View>
                    <View>
                      {!(this.state.pattern || this.state.pin) ? (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="oneTimePassword" on={this.state.oneTimePassword} />
                      ) : (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="" on={false} disable={styles.lightGrayBg} />
                      )}
                    </View>
                  </View>
                  <View style={[styles.preferencesList, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <View style={commonStyles.marginRight15}>
                        <Image source={otpIcon} style={[commonStyles.icon35]} />
                      </View>
                      <View>
                        <Text style={[commonStyles.heading6, commonStyles.userInitial, commonFontStyles.workSans]}>PIN</Text>
                      </View>
                    </View>
                    <View>
                      {!(this.state.pattern || this.state.oneTimePassword) ? (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="pin" on={this.state.pin} />
                      ) : (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="" on={false} disable={styles.lightGrayBg} />
                      )}
                    </View>
                  </View>
                  <View style={[styles.preferencesList, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <View style={commonStyles.marginRight15}>
                        <Image source={faceRecognitionIcon} style={[commonStyles.icon35]} />
                      </View>
                      <View>
                        <Text style={[commonStyles.heading6, commonStyles.userInitial, commonFontStyles.workSans]}>Face Recognition</Text>
                      </View>
                    </View>
                    <View>
                      {!this.state.fingerprint ? (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="faceRecognition" on={this.state.faceRecognition} />
                      ) : (
                        <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="" on={false} disable={styles.lightGrayBg} />
                      )}
                    </View>
                  </View>
                </View>
                <View style={[commonStyles.flex1, commonStyles.flexBasis48]}>
                  <View style={[commonStyles.paddingLeftRight16, commonStyles.marginTop10]}>
                    <Text style={[commonStyles.heading5, commonStyles.fontWeight500, commonStyles.userInitial, commonFontStyles.workSans]}>
                      Others
                    </Text>
                  </View>
                  <View style={[styles.preferencesList, commonStyles.dFlex, commonStyles.alignItemsCenter, commonStyles.justifyContentSpaceBetween]}>
                    <View style={[commonStyles.dFlex, commonStyles.alignItemsCenter]}>
                      <View style={commonStyles.marginRight15}>
                        <Image source={notificatIcon} style={[commonStyles.icon35]} />
                      </View>
                      <View>
                        <Text style={[commonStyles.heading6, commonStyles.userInitial, commonFontStyles.workSans]}>Notifications</Text>
                      </View>
                    </View>
                    <View>
                      <ToggleRadioBtn toggleSwitch={this.toggleSwitch} value="notifications" on={this.state.notifications} />
                    </View>
                  </View>
                </View>
            </View> 
      );
    }
  }
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    marginLeft: 16,
    marginRight: 16,
  },
  preferencesList: {
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    borderBottom: "1px solid #DADADA",
  },
  lightGrayBg: {
    backgroundColor: "#a9a9a9",
    borderColor: "#a9a9a9",
    cursor: "default",
  },
});
