import React from "react";
import R14, { View, Text, StyleSheet, ControlsView } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
// import DateUtils from "../utils/DateUtils";
import SwitchToggleComponent from "./SwitchToggleComponent";
import { customCommonStyles } from "../assets/css/customCommonStyle";

export default R14.connect(
    class PayerPerferenceBottomSection extends React.Component {

        render() {
            return (
                <ControlsView
                    type="buttons"
                    style={[styles.bottomControlsView, this.props.bottomControlsViewStyle]}
                    children={[
                        <View key="controlPayerPreferenceBottom" style={[styles.bottomPaySection, customCommonStyles.customBottomPaySection]}>
                            <View key="payerPreferenceBottomHeading">
                                <Text style={[commonFontStyles.workSans, commonStyles.socialLoginText, commonStyles.fontWeight500,commonStyles.contentColor]}>Text to Pay Preferences</Text>
                            </View>
                            <View key="payerPreferenceToggleBottom" style={[commonStyles.flex1, commonStyles.flexBasis50, commonStyles.flexDirectionRow, styles.switchToggleBtnHolder]}>
                                <View key="payerPreferenceEmailBottom" style={[this.props.source === "bottom" ? commonStyles.marginRight45 : commonStyles.flexBasis50]}>
                                    <SwitchToggleComponent
                                        containerStyle={[styles.switchToggleBtnContainer]}
                                        paymentStatus={this.props.emailStatus}
                                        textStyle={[styles.switchToggleText, commonFontStyles.workSans, commonStyles.smallHeading]}
                                        paymentOption={'Email'}
                                        key={'Email'}
                                        toggleSwitch={this.props.onEmailNotification}
                                    />
                                </View>
                                <View key="payerPreferenceSMSbottom" style={[this.props.source === "bottom" ? commonStyles.marginLeft30 : commonStyles.flexBasis50]}>
                                    <SwitchToggleComponent
                                        containerStyle={[styles.switchToggleBtnContainer]}
                                        paymentStatus={this.props.SMSstatus}
                                        textStyle={[styles.switchToggleText, commonFontStyles.workSans, commonStyles.smallHeading]}
                                        paymentOption={'SMS'}
                                        key={'SMS'}
                                        toggleSwitch={this.props.onSMSnotification}
                                    />
                                </View>
                            </View>
                        </View>
                    ]}
                />
            );
        }
    }
);

const styles = StyleSheet.create({
    bottomPaySection: {
        paddingTop: 10,
        paddingRight: 15,
        paddingBottom: 10,
        paddingLeft: 15,
        width: "100%",
    },
    payOptImages: {
        display: "block",
        width: 24,
        height: 24,
        backgroundSize: "contain",
        marginTop: 0,
        marginRight: "auto",
        marginBottom: 0,
        marginLeft: "auto",
    },
    active: {
        color: "#37c2ef",
    },
    disable: {
        opacity: 0.5,
        cursor: "auto",
    },
    bottomControlsView: {
        // display: "block",
        borderTopWidth: 1,
        borderColor: "#C8C8C8",
        borderStyle: "solid",
        height: "auto",
        flexBasis: "50%",
        flexGrow: 0,
        maxHeight: 65,
        minHeight: 65,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    flexBasis: "100%",
                    width: "100%"
                }
            }
        }
    },
    switchToggleText: {
        marginTop: -2.5,
        marginLeft: 10
    },
    switchToggleBtnContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 15,
        alignItems: "center",
    },
    switchToggleBtnHolder: {
        justifyContent: "space-evenly",
        flexGrow: 0,
        maxHeight: 30,
        minHeight: 30,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    justifyContent: "space-around",
                }
            }
        }
    }
});
