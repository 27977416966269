import React from "react";
import R14, {
  Modal,
  Form,
  View,
  Text,
  StyleSheet,
  Touchable,
  Image,
  TextInputField,
  Button,
  SubmitButton,
  FlatList
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { fontFamilyStyles } from "../../assets/css/commonFontStyles";
import CommonValidators from "../../utils/CommonValidators";
import DateUtils from "../../utils/DateUtils";
import { USER_TYPE } from "../../constant/app.data.constant";
import ModalPopUp from "../../components/ModalPopUp";
import PDFViewerComponent from "../../components/PCH/PDFViewerComponent";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import DatePicker from "../../components/PCH/DatePicker"
import HtmlDeCode from '../../utils/HtmlDecode';
import { uberDocThemeStyle } from "../../assets/css/clients/uberDocThemeStyle";
import { CommonString } from "../../constant/appCommonText";
import PCHLoaderComponent from "../../components/PCH/PCHLoaderComponent";
const viewBlueIcon = require("../../assets/images/viewBlue.png");

export default R14.connect(
  class ViewSubmittedClaimComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isVisible: false,
        isDocVisible: false,
        isPdfConfirmation: false,
        selectedItem: {},
        selectedItemType: "",
        isLoading: false,
        pdfs: [],
        dataSourceType: "pdf",
        signedUrl: "",
        fileContent: "",
        fileContentUnsecure: ""
      };
      this.ssid = "";
    }

    componentDidMount = async () => {
     
    };

    onCancelClick = async () => {
      this.props.app.nav.to("patientDashboard");
    };

    onDownloadFile = async (item, action = "View") => {
      this.ssid = "";
     this.setState({
        isVisible: true,
        docAction: action,
        password: "",
        isLoading: true,
        dataSourceType: item.dataSourceType
      });
    };

    onCancel = async () => {
      this.setState({
        isVisible: false,
        isLoading: false
      });
    };

    readText = async (val) => {
      this.ssid = val;
      let ssidText = val;
      if (!!val) {
        let cleaned = ("" + ssidText).replace(/\D/g, "").substring(0, 4);
        this.props.app.ui.form("uploadedBillPassword").elmts.filePassword.setValue(cleaned);
        this.ssid = cleaned;
      };
    };

    onDateChange = async (val, autoformat = true) => {
      if (!!autoformat) {
        let date = DateUtils.formatDateInMMDDYYYY(val);
        if (!!date && new Date(date) > new Date()) {
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(null);
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotFuture);;
          return false;
        } else if (!!date && DateUtils.isOlderThanMaxLimit(val)) {
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(null);
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotOlderThan125);
          return false;
        }
      }
      else {
        if (!!val && new Date(val) > new Date()) {
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setValue(null);
          this.props.app.ui.form("uploadedBillPassword").elmts.dOBPassword.setError(CommonString.DOBCanNotFuture);;
          return false;
        }
      }
    };

    onPDFClose = async () => {
      this.setState({ isDocVisible: false });
    };

    onViewClick = async (item, type) => {
        const {mergedFileName, documentId } = item;
        const filter = {
            filter: {
            documentId:{
                eq: documentId
            }
            },
            sort: {
            field: "seqNo",
            order: "ASC"
            }
        }
        let documentLogs =  await this.props.app.dm.uploadBillDomain.documentLogs(filter);
        // if(item && isSuperBill) {   // merged file show only when super bill exists 
        if(item) {      // merged file show (superBill or not)
          const isDocumentAlreadyExists = documentLogs.findIndex(documentFile => documentFile.filename === mergedFileName);
          if(isDocumentAlreadyExists === -1 && !!mergedFileName) documentLogs.unshift({uid: "", filename: mergedFileName, contentType: "pdf", seqNo: 1, isSuperBill: true});
          documentLogs = documentLogs.map((doc, idx) => {
            return {
                ...doc,
                seqNo: idx + 1
            }
          })
        }
        this.setState({isPdfConfirmation: true, selectedItem: item, selectedItemType: type, pdfs: documentLogs})
    }

    downloadOrViewBillDocuments = async () => {
      const { uploadBill } = this.props;
      if (!!uploadBill.isProcessed) {
        let options = {
          responseType:"arraybuffer",
          headers: {
            "content-type": "application/pdf",
          }
        };
        let signedUrl = await this.props.app.dm.uploadBillDomain.getObjectSignedUrl(uploadBill.mergedFileName);
        let response = await this.props.app.dm.rest.getS3(signedUrl, options);
        let billDocuments = Buffer.from(response.data, 'binary').toString('base64');
        let signedUnsecuredUrl = !!uploadBill.mergedUnsecuredFileName && !!uploadBill.isSuperBill ? await this.props.app.dm.uploadBillDomain.getObjectSignedUrl(uploadBill.mergedUnsecuredFileName) : signedUrl;
        let responseUnsecure = !!signedUnsecuredUrl && !!uploadBill.isSuperBill ? await this.props.app.dm.rest.getS3(signedUnsecuredUrl, options) : signedUrl;
        let billDocumentsUnsecure = !!responseUnsecure && !!uploadBill.isSuperBill ? Buffer.from(responseUnsecure.data, 'binary').toString('base64') : billDocuments;    
        if (!!billDocuments) {
          if (this.state.docAction === "View") {
            this.setState({ fileContent: billDocuments,signedUrl: signedUrl, isDocVisible: true, fileContentUnsecure: billDocumentsUnsecure, });
            this.onCancel();
          } else {
            const item = {
              content: `data:application/octet-stream;base64,${billDocuments}`,
              contentType:  'PDF',
              filename: uploadBill.mergedFileName
            };
            HtmlDeCode.downloadFile(item);
            this.onCancel();
          }
        }
        else
          this.props.app.dm.commonDomain.showToastr("error", CommonString.FileNotFound);
      } else
        this.props.app.dm.commonDomain.showToastr("error", CommonString.FileInProcess);
        this.setState({isLoading: false})
    };

    onOk = async (form) => {
      const { dOBPassword } = form.values;
      const filePassword = this.ssid //this.state.password;
      const { uploadBill } = this.props;
      if (!!uploadBill.ssId ? filePassword === uploadBill.ssId : new Date(dOBPassword).toString() === new Date(uploadBill.dOB).toString()) {
        await this.downloadOrViewBillDocuments();
      } else {
        this.props.app.dm.commonDomain.showToastr("error", !!uploadBill.ssId ? CommonString.IncorrectSSID : CommonString.IncorrectDOB);
        if (form.elmts.filePassword) {
          this.setState(
            {
              password: "",
            },
            () => {
              form.elmts.filePassword.setValue(null);
              this.ssid = "";
            }
          );
        }
      }
      return false;
    };

    onClearDobDate = () => {
        const form = this.props.app.ui.form("uploadedBillPassword");
        form.elmts.dOBPassword.setValue(null);
        form.elmts.dOBPassword.setError(null);
    }

    renderModelPopup(clientStyle, commonFontStyles, isUberDocs) {
      const { isVisible } = this.state;
      const { uploadBill } = this.props;
      return (

        <ModalPopUp
          visibility={isVisible}
          isFooter={true}
          heading={!!uploadBill.ssId ? CommonString.SSIDRequired : CommonString.DOBRequired}
          bodyWrapper={[commonStyles.smallModalBodyWrapper, clientStyle.secondaryBgColor]}
          modalContent = {[commonStyles.marT0, commonStyles.marB0]}
          modalHeading={styles.headingStyle}
          closeButton={customCommonStyles.customDisplayNone}>
            <Form
              name="uploadedBillPassword"
              style={[commonStyles.padding0]}
              onSubmit={this.onOk}
              validateBeforeSubmit={true}
              validators={CommonValidators.getFormValidator()}>
              <View>
                {!!uploadBill.ssId ? (
                  <View style={[commonStyles.marginTop10, styles.minHeight52, commonStyles.marginBottom20]}>
                    <TextInputField
                      name="filePassword"
                      label={`${CommonString.SSIDRequiredLabel}`}
                      required={CommonString.SSIDRequired}
                      secure={true}
                      onChangeText={this.readText}
                      style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.marB0, customCommonStyles.inputHeight]}
                      labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                      labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle, clientStyle.secondaryBgColor]}
                      helperTextStyle={[commonStyles.helperTextFontStyle, commonFontStyles.workSans, commonStyles.left0]}
                    />
                  </View>
                ) : (
                    <View style={[commonStyles.marginTop10, commonStyles.dFlex, commonStyles.flexDirectionRow, commonStyles.marginBottom20, styles.minHeight52]}>
                      <DatePicker
                        name={"dOBPassword"}
                        label={`${CommonString.DOBRequired}*`}
                        formName="uploadedBillPassword"
                        required={CommonString.DOBRequired}
                        props={this.props}
                        onValueChange={this.onDateChange}
                        style={[commonStyles.marB0, commonFontStyles.workSans, commonStyles.heading6, customCommonStyles.inputHeight]}
                        labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, clientStyle.secondaryBgColor]}
                        labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle,clientStyle.secondaryBgColor]}
                        helperTextStyle={[commonFontStyles.workSans, styles.dobLabelStyle]}
                        isUberDocs={isUberDocs}
                      />
                  </View>
                )}
              </View>

              <View style={[styles.btnHolder, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow, customCommonStyles.customBtnHolder,]}>
                <Button
                  title={"Cancel"}
                  style={[
                    commonStyles.blueBorderButton,
                    commonStyles.marginRight8,
                    commonStyles.flex1,
                    styles.cancelBtn,
                    styles.buttons,
                    clientStyle.backAndCancelButton
                  ]}
                  onPress={() => this.onCancel()}
                  key="billCancel"
                  titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonStyles.transperentBtnColor,clientStyle.backAndCancelButtonText]}
                />
                <SubmitButton
                  title="Ok"
                  key="billOk"
                  style={[
                    commonStyles.blueButton,
                    styles.declinedBtn,
                    commonStyles.marginLeft8,
                    commonStyles.flex1,
                    styles.buttons,
                    commonStyles.flexBasis48,
                    customCommonStyles.noBoxShadow,
                    clientStyle.submitButton
                  ]}
                  titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonStyles.blueButtonColor, commonFontStyles.workSans, commonStyles.whiteColor, clientStyle.submitButtonText]}
                />
              </View>
            </Form>
          
        </ModalPopUp>
      );
    }

    renderPDFconfirmationModelPopup(clientStyle, commonFontStyles) {
      const { isPdfConfirmation, selectedItem, selectedItemType, pdfs } = this.state;
      return (
        <ModalPopUp
            visibility={isPdfConfirmation}
            isFooter={true}
            bodyWrapper={[commonStyles.smallModalBodyWrapper, styles.confirmationModal, clientStyle.secondaryBgColor, pdfs.length ? null : styles.width300,]}
            modalContent = {[commonStyles.marB0]}>
            <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.alignItemsCenter, ]}>
              <Text style={[commonFontStyles.workSans, commonStyles.dBlock, commonStyles.fontWeight500, commonStyles.textAlignCenter]}>{`Document password is Patient's ${!selectedItem.dOB ? "4 digits SSID" : "DOB(MM/DD/YYYY)"}`}</Text>
            </View>
            <FlatList
                style={[
                  styles.dashboardMessageComponent,
                  customCommonStyles.customDashboardMessageComponent,
                  styles.noBorder,
                  styles.tboday,
                  pdfs.length ? null : commonStyles.displayNone,
                  clientStyle.secondaryBgColor,
                ]}
                data={pdfs}
                keyExtractor={(el, i) => i}
                renderItem={({ item, index }) => (
                  <View
                    style={[
                      styles.tr,
                      commonStyles.flex1,
                      commonStyles.alignItemsStart,
                      commonStyles.flexDirectionRow,
                      styles.attachmentCard,
                      styles.customPadding
                    ]}>
                      <View style={[commonStyles.marginRight16, commonStyles.flex1, commonStyles.flexDirectionRow]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight500, customCommonStyles.wordBreakWord]}>
                          {item.seqNo + ")  "}
                        </Text>
                        <View style={[commonStyles.marginRight16]}>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, customCommonStyles.wordBreakWord]}>
                            {item.filename}
                          </Text>
                          <Text style={[commonFontStyles.workSans, commonStyles.heading6, commonStyles.dBlock, commonStyles.fontWeight300, customCommonStyles.wordBreakWord]}>
                            {item.size}
                          </Text>
                        </View>

                      </View>
                      <Touchable
                          style={[styles.customMarLAuto, ]}
                          onPress={() => {              
                            selectedItem.mergedFileName = item.filename;  
                            selectedItem.dataSourceType = item.contentType ? item.contentType.toLowerCase() : ""
                            selectedItem.isSuperBill = item.isSuperBill;
                            this.onDownloadFile(selectedItem, selectedItemType)
                          }}>
                           <Image source={viewBlueIcon} style={[styles.viewPdfIconSize]} />
                        </Touchable>
                  </View>
                )}
              />
            <View style={[commonStyles.smallModalBtnWrapper, commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow]}>
              <Button
                title={"OK"}
                style={[
                    commonStyles.blueButton,
                    styles.buttons,
                    customCommonStyles.noBoxShadow,
                    clientStyle.submitButton,
                    ]}
                onPress={() =>  {
                    this.setState({isPdfConfirmation: false, selectedItem: {}, selectedItemType: ""})
                } }
                titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonStyles.blueButtonColor, commonFontStyles.workSans, commonStyles.whiteColor, clientStyle.submitButtonText]}
              />
            </View>
          </ModalPopUp>
      );
    }

    renderPDFViewer(clientStyle, commonFontStyles, isUberDocs) {
      const { isDocVisible, fileContent, signedUrl, dataSourceType, fileContentUnsecure} = this.state;
      return (
        <ModalPopUp
          visibility={isDocVisible}
          isFooter={false}
          bodyWrapper={[commonStyles.largModalBodyWrapper, clientStyle.secondaryBgColor]}
          heading={"View Document"}
          handleCancel={this.onPDFClose}
          closeButton={customCommonStyles.viewPDFCloseBtn}
          >
          <View>
            <PDFViewerComponent closePDF={this.onPDFClose} bills={this.props.uploadBill} signedUrl = {signedUrl} dataSource={fileContent} dataSourceUnsecured={fileContentUnsecure} dataSourceType={dataSourceType || "pdf"} disableFileName={true} isUberDocs={isUberDocs}/>
          </View>
        </ModalPopUp>
      );
    }

    alertOk = async () => {
      const { userLoginDetail } = this.props;
      let userType = !!userLoginDetail && !!userLoginDetail.userType ? userLoginDetail.userType : USER_TYPE.XBP;
      if (userType === USER_TYPE.PCH_PATIENT) {
        this.props.app.nav.to("addInsuranceRoute", { mode: "add" });
      } else if (userType === USER_TYPE.PCH_PROVIDER) {
        this.props.app.nav.to("addProviderRoute", { mode: "add" });
      }
      this.setState({
        isAlertVisible: false,
        alertMessage: ''
      })
    };

    renderAlertPopup(clientStyle, commonFontStyles) {
      const { isAlertVisible, alertMessage } = this.state;
      return (
        <Modal visible={!!isAlertVisible} animationType="fade" style={[styles.filePassowrdModalWrapper, styles.modalWrapper]}>
          <View style={[styles.filePassowrdModalBodyWrapper, commonStyles.flex1, commonStyles.flexGrow0, styles.addInsuranceAlert, clientStyle.secondaryBgColor]}>
            <View style={commonStyles.addInsuranceAlertText}>
              <Text style={[commonFontStyles.workSans, commonStyles.heading5]}>{alertMessage}</Text>
            </View>
            <Button
              title={"Ok"}
              style={[commonStyles.blueButton, commonStyles.marginRight8, commonStyles.addInsuranceAlertOkBtn, customCommonStyles.noBoxShadow, clientStyle.submitButton]}
              onPress={this.alertOk}
              key="alertOk"
              titleTextStyle={[
                commonFontStyles.workSans,
                commonStyles.fontWeight700,
                commonStyles.headinexSmallHeadingg6,
                commonStyles.transperentBtnColor,
                commonStyles.whiteColor,
                clientStyle.submitButtonText
              ]}
            />
          </View>
        </Modal>
      );
    }
    
    render() {
      const { isUberDocs } = this.props.app.dm.userSession.state;
      const { isMobileDevice } = this.props.app.dm.notificationDomain.state;
      const { uploadBill, managePayment, onViewClaimBack } = this.props;
      let clientStyle = !!isUberDocs ? uberDocThemeStyle : commonStyles;
      let commonFontStyles = !!isUberDocs ? uberDocThemeStyle : fontFamilyStyles;

      return (
        <>
            {this.renderModelPopup(clientStyle, commonFontStyles)}
            {this.renderAlertPopup(clientStyle, commonFontStyles)}
            {this.renderPDFViewer(clientStyle, commonFontStyles, isUberDocs)}
            {this.renderPDFconfirmationModelPopup(clientStyle, commonFontStyles)}
            <PCHLoaderComponent isVisiable={this.state.isLoading} labelText={"Please wait"} />
            <View style={[commonStyles.paddingLeftRight16, styles.pageTitle]}>
              <View>
                <Text style={[commonStyles.heading3, commonStyles.fontWeight500, commonFontStyles.workSans]}>
                  Claim Details
                  </Text>
              </View>
            </View>
            <View style={[commonStyles.dFlex, commonStyles.flexDirectionColumn, commonStyles.flexGrow1, styles.uploadBillSection,  clientStyle.secondaryBgColor]}>
              <Form
                name="uploadBills"
                style={[commonStyles.dFlex, commonStyles.flexDirectionColumn, commonStyles.flexGrow1]}
                validateBeforeSubmit={true}
                validators={CommonValidators.getFormValidator()}
                onSubmit={async () => {}}>
                <View style={[commonStyles.dFlex, styles.inputHolder, !!uploadBill.uid ? styles.uploadedBillAttachFile : styles.attachFileScetion]}>
                  <View style={[commonStyles.dFlex, commonStyles.flex1, commonStyles.flexBasis48, !!uploadBill.uid ? null : styles.rightInputHolder]}>
                    <View
                      style={[
                        commonStyles.flexGrow1,
                        styles.attachmentsHolder,
                      ]}>
                          <View key={`atta_${uploadBill.uid}`}>
                            <View style={[commonStyles.dFlex, styles.attachmentCard]}>
                              <View>
                                <Touchable style={styles.ViewPdfIcon} onPress={() => this.onViewClick(uploadBill, "View")}>
                                  <View>
                                    <Image source={viewBlueIcon} style={commonStyles.icon36x22} />
                                  </View>
                                </Touchable>
                              </View>
                            </View>
                          </View>
                    </View>
                  </View>
                </View>
                {
                  !!uploadBill && !!uploadBill.processFileName ?
                  <View style={[commonStyles.flex1, styles.customflexDirection, commonStyles.flexGrow0]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
                    <View style={[styles.customTextContainer]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.tableKey
                        ]}>
                        ICN
                      </Text>
                    </View>
                    <View style={[styles.customValueContainer]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        { uploadBill.processFileName }
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
                    <View style={[styles.customTextContainer]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.tableKey
                        ]}>
                        Date of Submission
                      </Text>
                    </View>
                    <View style={[styles.customValueContainer]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {!!uploadBill && !!uploadBill.submittedDateTime ? uploadBill.submittedDateTime : DateUtils.dateAndTimeWithAmPm(uploadBill.submittedDate, "PCH")}
                      </Text>
                    </View>
                  </View>
                </View>
                  : null
                }
                <View style={[commonStyles.flex1, styles.customflexDirection, commonStyles.flexGrow0]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
                    <View style={[styles.customTextContainer]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.tableKey
                        ]}>
                        Insurance
                      </Text>
                    </View>
                    <View style={[styles.customValueContainer]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {!!uploadBill && !!uploadBill.pchInsuranceDetail ? uploadBill.pchInsuranceDetail.name : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
                    <View style={[styles.customTextContainer]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.tableKey
                        ]}>
                        Group
                      </Text>
                    </View>
                    <View style={[styles.customValueContainer]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {!!uploadBill && !!uploadBill.pchInsuranceDetail ? uploadBill.pchInsuranceDetail.groupNumber : ""}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[commonStyles.flex1, styles.customflexDirection, commonStyles.flexGrow0]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
                    <View style={[styles.customTextContainer]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.tableKey
                        ]}>
                        Insurance Address
                      </Text>
                    </View>
                    <View style={[styles.customValueContainer]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {!!uploadBill && !!uploadBill.pchInsuranceDetail ? (
                            uploadBill.pchInsuranceDetail.address1 && uploadBill.pchInsuranceDetail.address2 && uploadBill.pchInsuranceDetail.city && uploadBill.pchInsuranceDetail.state ? `${uploadBill.pchInsuranceDetail.address1}, ${uploadBill.pchInsuranceDetail.address2}, ${uploadBill.pchInsuranceDetail.city}, ${uploadBill.pchInsuranceDetail.state}` :
                            uploadBill.pchInsuranceDetail.address1 && uploadBill.pchInsuranceDetail.city && uploadBill.pchInsuranceDetail.state ? `${uploadBill.pchInsuranceDetail.address1}, ${uploadBill.pchInsuranceDetail.city}, ${uploadBill.pchInsuranceDetail.state}` :
                            uploadBill.pchInsuranceDetail.city && uploadBill.pchInsuranceDetail.state ? `${uploadBill.pchInsuranceDetail.city}, ${uploadBill.pchInsuranceDetail.state}` :
                            uploadBill.pchInsuranceDetail.state ? `${uploadBill.pchInsuranceDetail.state}` : ""
                        ) : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
                    <View style={[styles.customTextContainer]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          commonStyles.tableKey
                        ]}>
                        Patient Name
                      </Text>
                    </View>
                    <View style={[styles.customValueContainer]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {!!uploadBill && 
                          !!uploadBill.insuranceMember && 
                          !!uploadBill.insuranceMember.firstName 
                          ? !!uploadBill.insuranceMember.lastName ? uploadBill.insuranceMember.firstName + " " + uploadBill.insuranceMember.lastName
                            : uploadBill.insuranceMember.firstName 
                          : ""
                        }
                      </Text>
                    </View>
                  </View>
                </View>

                {!!uploadBill.parentName ? (
                  <View style={[commonStyles.flex1, styles.customflexDirection, commonStyles.flexGrow0]}>
                    <View style={[commonStyles.flex1, styles.leftInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
                      <View style={[styles.customTextContainer]}>
                        <Text
                          style={[
                            commonStyles.dBlock,
                            commonStyles.fontWeight500,
                            commonStyles.heading6,
                            commonFontStyles.workSans,
                            commonStyles.marginBottom5,
                            commonStyles.tableKey
                          ]}>
                          Practice Group
                        </Text>
                      </View>
                      <View style={[styles.customValueContainer]}>
                        <Text
                          style={[
                            commonStyles.dBlock,
                            commonStyles.heading6,
                            commonFontStyles.workSans,
                            styles.userDetailsVla,
                            commonStyles.tableValue
                            // isMobileDevice ? styles.mViewFontStyle : null,
                          ]}>
                          {
                            uploadBill.parentName
                          }
                          </Text>
                      </View>
                    </View>
                    <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow, commonStyles.flexGrow0]}>
                      <View style={[styles.customTextContainer]}>
                        <Text
                          style={[
                            commonStyles.dBlock,
                            commonStyles.fontWeight500,
                            commonStyles.heading6,
                            commonFontStyles.workSans,
                            commonStyles.marginBottom5,
                            commonStyles.tableKey
                          ]}>
                          Provider
                        </Text>
                      </View>
                      <View style={[styles.customValueContainer]}>
                        <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                          {!!uploadBill.providerName ? uploadBill.providerName : ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                ) : null}
                <View style={[commonStyles.flex1, styles.customflexDirection, commonStyles.flexGrow0]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexGrow0, styles.detailRow, styles.customflexDirectionReverce]}>
                    <View style={[styles.customTextContainer]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          styles.mobileSpacificView,
                          commonStyles.tableKey
                        ]}>
                        Uploaded On<Text style={commonStyles.mView}>: </Text>
                      </Text>
                    </View>
                    <View style={[styles.customValueContainer]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {!!uploadBill.uploadedDateTime ? uploadBill.uploadedDateTime : DateUtils.dateAndTimeWithAmPm(uploadBill.createdAt, "PCH")}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow, styles.customflexDirectionReverce]}>
                    <View style={[styles.customTextContainer]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          styles.mobileSpacificView,
                          commonStyles.tableKey
                        ]}>
                        Status<Text style={commonStyles.mView}>: </Text>
                      </Text>
                    </View>
                    <View style={[styles.customValueContainer]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {uploadBill.pchSubmittedStatus}
                      </Text>
                    </View>
                  </View>
                </View>
                {!!uploadBill.participatingPayer && uploadBill.pchSubmittedStatus  && 1 === 2 ?
                <View style={[commonStyles.flex1, styles.customflexDirection, commonStyles.flexGrow0]}>
                  <View style={[commonStyles.flex1, styles.leftInputHolder, commonStyles.flexGrow0, styles.detailRow, styles.customflexDirectionReverce]}>
                    <View style={[isMobileDevice ? commonStyles.marginRight10 : null]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          styles.mobileSpacificView,
                          commonStyles.tableKey
                        ]}>
                        Payer Type<Text style={commonStyles.mView}>: </Text>
                      </Text>
                    </View>
                    <View>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {uploadBill.participatingPayer}
                      </Text>
                    </View>
                  </View>
                  <View style={[commonStyles.flex1, styles.rightInputHolder, styles.detailRow, styles.customflexDirectionReverce]}>
                    <View style={[isMobileDevice ? commonStyles.marginRight10 : null]}>
                      <Text
                        style={[
                          commonStyles.dBlock,
                          commonStyles.fontWeight500,
                          commonStyles.heading6,
                          commonFontStyles.workSans,
                          commonStyles.marginBottom5,
                          styles.mobileSpacificView,
                          commonStyles.tableKey
                        ]}>
                        Document Status<Text style={commonStyles.mView}>: </Text>
                      </Text>
                    </View>
                    <View style={[]}>
                      <Text style={[commonStyles.dBlock, commonStyles.heading6, commonFontStyles.workSans, styles.userDetailsVla, commonStyles.tableValue]}>
                        {uploadBill.pchSubmittedStatus}
                      </Text>
                    </View>
                  </View>
                </View> : null }
                <Button
                  title={"Back"}
                  style={[
                    commonStyles.blueBorderButton,
                    customCommonStyles.noBoxShadow,
                    commonStyles.btnHeight24,
                    styles.customBackButton,
                    !managePayment ? commonStyles.displayNone : null
                  ]}
                  onPress={() => onViewClaimBack()}
                  titleTextStyle={[
                    commonStyles.blueBorderButtonColor,
                    commonStyles.fontWeight500,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                  ]}
                />
              </Form>
            </View>
        </>
      );
    }
  }
);
const styles = StyleSheet.create({
  bodyWrapper: {
    width: 311,
    screen: ({ width }) => {
      if (width <= 359)
        return {
          width: 300,
        };
    },
  },

  filePassowrdModalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  modalWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },

  filePassowrdModalBodyWrapper: {
    backgroundColor: "#fff",
    border: "1px solid #a7a7a7",
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: 300,
          height: 190,
          minHeight: 190,
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
        };
      }
    },
  },
  addInsuranceAlert: {
    height: 150,
  },
  leftInputHolder: {
    marginRight: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginRight: 0,
        };
      }
    },
  },
  rightInputHolder: {
    marginLeft: 8,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customMarTAuto: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          // marginTop: -15,
        };
      }
    },
  },
  customFlexProperties: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: "auto",
        };
      }
      if (width >= 991) {
        return {
          justifyContent: "flex-end",
        };
      }
    },
  },
  submitBtn: {
    minWidth: 108,
    height: 24,
    maxHeight: 24,

  },
  inputHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  formBtnsHolder: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexBasis: "48%",
        };
      }
    },
  },
  formBtns: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100%",
        };
      }
    },
  },
  detailRow: {
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    paddingBottom: 0,
    marginBottom: 6,
    flexBasis: "48%",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          paddingBottom: 0,
          flexBasis: "100%",
          flexDirection: "row"
        };
      }
    },
  },
  customflexDirection: {
    flexDirection: "row",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "column",
        };
      }
    },
  },
  customflexDirectionReverce: {
    flexDirection: "column",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flexDirection: "row",
        };
      }
    },
  },
  countryCode: {
    maxWidth: 70,
  },
  
  btnHolder: {
    // paddingRight: 10,
    // paddingLeft: 10,
  },
  uploadBillSection: {
    backgroundColor: "#fff",
    marginTop: 16,
    marginRight: 16,
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          paddingTop: 6,
        };
      }
    },
  },
  attachFileScetion: {
    marginBottom: 32,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 6,
        };
      }
    },
  },
  pageTitle: {
    marginTop: 15,
    display: "flex",
    justifyContent: "space-between",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginTop: 10,
        };
      }
    },
  },
  attachmentsHolder: {
    maxHeight: 120,
    overflowY: "auto",
    flexBasis: null,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginBottom: 0,
        };
      }
    },
  },
  autoFillModelBodyWrapper: {
    width: 500,
    maxHeight: "80vh",
    overflowY: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "100vw",
          height: "100vh",
        };
      }
    },
  },
  autoFillModel: {
    height: "100%",
    overflowY: "auto",
    marginBottom: 15,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          height: "100vh",
        };
      }
    },
  },
  fillWidth: {
    width: "33%",
  },
  tr: {
    display: "flex",
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    cursor: "pointer",
    borderBottom: "1px solid rgb(161 164 168)",
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 5,
    flexDirection: "column",
  },
  mViewFontStyle: {
    fontSize: 16,
    fontWeight: 600,
  },
  attachmentCard: {
    // border: "1px solid #e2e2e2",
    justifyContent: "flex-start",
    marginBottom: 16,
    // marginLeft: -12,
  },
  updatedonForMView: {
    flexDirection: "row",
  },
  PDFViewBodyWrapper: {
    width: "80vw",
    screen: ({ width, height }) => {
      if (width <= 991) {
        return {
          // width: 300,
          minHeight: 400,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderTopLeftRadius: 6,
borderTopRightRadius: 6,
borderBottomLeftRadius: 6,
borderBottomRightRadius: 6,
        };
      }
    },
  },
  PDFModalContent: {
    paddingLeft: 0,
    paddingRight: 0,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingLeft: 26,
          paddingRight: 26,
        };
    },
  },
  closeBtn: {
    position: "absolute",
    right: 0,
    top: 0,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          top: -5,
          right: -15,
        };
      }
    },
  },
  buttons: {
    height: 24,
    maxHeight: 24,
    padding: 0,
    width: 100,
    maxWidth: 100,
    flexBasis: "48%",
  },
  mobileSpacificView: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          flex: 1,
          flexDirection: "row",
          minWidth: 50,
        };
      }
    },
  },
  minHeight52: {
    minHeight: 52,
  },
  enterPasswordBodyWrapper: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#a7a7a7",
    borderStyle: "solid",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          minHeight: 180,
        };
    },
  },
  viewPdfIconSize: {
    width: 25,
    height: 30,
    minWidth: 30,
    backgroundSize: "contain",
    display: "block",
    backgroundPosition: "center center",
  },
  dashboardMessageComponent: {
    marginTop: 200, //164,
    marginRight: 33,
    marginLeft: 16,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 16,
    paddingLeft: 16,
    display: "block",
    flexGrow: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    borderTop: 0,
    backgroundColor: "#fff",
    // position: "unset",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginTop: 200,
          marginRight: 16,
          marginLeft: 16,
        };
      }
      if (width >= 320 && width <= 991) {
        return {
          paddingTop: 15,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
          position: "relative",
          margin: 0,
          padding: 0,
          height: "calc(100vh - 360px)"
        };
      }
    },
  },
  noBorder: {
    borderTopWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    marginTop: 70,
    padding: 16,
    paddingTop: 0
  },
  confirmationModal: {
    width: 420,
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: 350,
          maxWidth: "calc(90vw)"
        };
      }
    }
  },
  width300: {
    width: 300
  },
  customMarLAuto: {
    marginLeft: "auto",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          marginLeft: 0,
        };
      }
    },
  },
  customPadding: {
    padding: 0,
    paddingTop: 8
  },
  tboday: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          position: "relative",
          margin: 0,
          padding: 0,
          maxHeight: "calc(100vh - 360px)",
          marginTop: 24,
        };
    }, 
  },
  customBackButton: {
    width: 108,
    maxWidth: 108,
    marginTop: 24,
    marginLeft: "auto"
  },
  customTextContainer: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "40%",
          marginTop: 4
        };
      }
    },
  },
  customValueContainer: {
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          width: "60%",
        };
      }
    },
  },
});
