import React from "react";
import R14, {
    StyleSheet,
    View,
    Text,
    Modal,
    Form,
    TextInputField,
    Button,
    SubmitButton,
    Platform
} from "../../core";
import { commonStyles } from "../../assets/css/commonStyle";
import { commonFontStyles } from "../../assets/css/commonFontStyles";
import CommonValidators from "../../utils/CommonValidators";
import { customCommonStyles } from "../../assets/css/customCommonStyle";
import { PAGE_PATH } from "../../constant/app.data.constant";
import DropDown from "../DropDown";
import { CommonString } from "../../constant/appCommonText";

export default R14.connect(class ReasonPopupComponent extends React.Component {

    onCancel = () => {
        const { handleClose } = this.props;
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    onOk = async (form) => {
        const { status, statusInfo } = form.values;
        let payload = {
            status: status.value,
            statusInfo: !!statusInfo && !!statusInfo.value ? statusInfo.value : statusInfo
        }
        if(Platform.OS !== "web"){
            payload.status = !!status && status.split("-") ? status.split(" - ")[0] : "";
            payload.statusInfo = !!statusInfo && statusInfo.split("-") ? statusInfo.split(" - ")[0] : "";
        }
        await this.props.onConfirm(payload);
        return false;
    };

    readText = (val) => {
        this.form = this.props.app.ui.form("frmAcptRjct");
        if ((!val && !!this.form) || val.length <= 100) {
            this.form.elmts.statusInfo.setError(null);
        } else if (val.length > 100) {
            this.form.elmts.statusInfo.setError(CommonString.ContentExceed100);
        }
    };

    render() {
        const { visibility, formName, action, transactionStatus, transactionStatusInfo, onRejectValueChange, onStatusInfoValueChange } = this.props;
        return (
            <Modal
                visible={visibility}
                transparent={true}
                animationType="fade"
                style={[
                    styles.modalWrapper,
                    commonStyles.flex1,
                    commonStyles.flexGrow0,
                    commonStyles.justifyContentCenter,
                    commonStyles.alignItemsCenter,
                    commonStyles.flexWrap,
                ]}>
                <View style={[styles.reasonModalBodyWrapper, commonStyles.flex1, commonStyles.flexGrow0, customCommonStyles.customFilePassowrdModalBodyWrapper]}>
                    <Form
                        name={formName}
                        style={[commonStyles.padding0]}
                        onSubmit={this.onOk}
                        validateBeforeSubmit={true}
                        validators={CommonValidators.getFormValidator()}>
                        <View style={[customCommonStyles.customAlignItemsCenter, commonStyles.paddingTopBottom15]}>
                            <Text style={[commonFontStyles.workSans, commonStyles.fontWeight600, commonStyles.heading6, commonStyles.textAlignCenter]}>
                                Choose {action === PAGE_PATH.REJECT ? "Reject" : "Accept"} Reason
                            </Text>
                        </View>
                        <View style={[commonStyles.flexDirectionRow, commonStyles.marginBottom20]}>
                            <DropDown
                                name="status"
                                label={"Reject Reason*"}
                                required={"Select Reject Reason"}
                                searchable={false}
                                value={transactionStatus[0]}
                                items={transactionStatus}
                                onValueChange={onRejectValueChange}
                                itemLoader={() => transactionStatus}
                                labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, commonStyles.margin0, customCommonStyles.inputHeight,]}

                                // for native
                                onItemSelect={onRejectValueChange}
                                // for native - onDataProvider must return array [{label, value}]
                                onDataProvider={() => transactionStatus}
                                setExistingValue={() => transactionStatus[0]}
                                defaultValue={()=> transactionStatus[0]}
                                searchableTextBoxStyle={[commonStyles.marB0]}
                                searchableTextBoxStyleHolder={[commonStyles.marginBottom16, commonStyles.fullFlexContainer, commonStyles.maxWidth100,]}
                                readOnly={false}
                                disabled={false}
                                onInvalidTypeText={(txt) => ""}
                            />
                        </View>
                        <View style={[commonStyles.flexDirectionRow, commonStyles.marginBottom25]}>
                            {action === PAGE_PATH.ACCEPTLATER || action === PAGE_PATH.ACCEPTNOW ?
                                <TextInputField
                                    name="statusInfo"
                                    label="Additional Information*"
                                    required={"Enter Information"}
                                    secure={false}
                                    validator="max100"
                                    onChangeText={this.readText}
                                    // multiline
                                    style={[commonStyles.marB0, commonStyles.resizeNone, commonFontStyles.workSans, commonStyles.heading6]}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                    helperTextStyle={[commonStyles.left0, commonFontStyles.workSans, commonStyles.helperTextFontStyle]}
                                />
                                : <DropDown
                                    name="statusInfo"
                                    label={"Reject Info*"}
                                    required={"Select Reject Info"}
                                    searchable={false}
                                    items={transactionStatusInfo}
                                    itemLoader={() => transactionStatusInfo}
                                    onValueChange={onStatusInfoValueChange}
                                    labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6]}
                                    labelStyle={[commonStyles.formLabelStyle, customCommonStyles.inputLableStyle]}
                                    helperTextStyle={[commonStyles.left0, commonStyles.helperTextFontStyle, commonFontStyles.workSans]}
                                    style={[commonStyles.flex0, commonFontStyles.workSans, commonStyles.heading6, commonStyles.margin0, customCommonStyles.inputHeight, ]}
                                    
                                    // for native
                                    onItemSelect={onStatusInfoValueChange}
                                    // for native - onDataProvider must return array [{label, value}]
                                    onDataProvider={() => transactionStatusInfo}
                                    setExistingValue={""}
                                    defaultValue={()=> ""}
                                    searchableTextBoxStyle={[commonStyles.marB0]}
                                    searchableTextBoxStyleHolder={[commonStyles.marginBottom16, commonStyles.fullFlexContainer, commonStyles.maxWidth100,]}
                                    readOnly={false}
                                    disabled={false}
                                    onInvalidTypeText={(txt) => ""}
                                />}
                            </View>
                        <View style={[commonStyles.flex1, commonStyles.justifyContentCenter, commonStyles.flexDirectionRow, customCommonStyles.customBtnHolder, commonStyles.flexGrow0]}>
                            <Button
                                title={"Cancel"}
                                style={[
                                    commonStyles.secondayWhiteBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, commonStyles.btnWidth108, commonStyles.marginRight8
                                ]}
                                onPress={this.onCancel}
                                key="billCancel"
                                titleTextStyle={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.exSmallHeading, commonStyles.transperentBtnColor]}
                            />
                            <SubmitButton
                                title="Ok"
                                key="billOk"
                                style={[
                                    commonStyles.primaryBtn, customCommonStyles.noBoxShadow, commonStyles.btnHeight24, styles.customMarginLeft16
                                ]}
                                titleTextStyle={[commonStyles.fontWeight500, commonStyles.black, commonStyles.blueButtonColor, commonFontStyles.workSans, commonStyles.exSmallHeading, commonStyles.whiteColor]}
                            />
                        </View>
                    </Form>
                </View>
            </Modal>
        );
    }
});

const styles = StyleSheet.create({
    modalWrapper: {
        width: "100%",
        height: "100%",
        minHeight: 150,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
    reasonModalBodyWrapper: {
        backgroundColor: "#fff",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#a7a7a7",
        paddingTop: 0,
        paddingRight: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        width: 350,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        minHeight: 150,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    minHeight: Platform.OS !== "web" ? 300 : 200,
                };
            }
        },
    },
    customMarginLeft16: {
        marginLeft: 16,
        screen: ({ width }) => {
            if (width <= 991) {
                return {
                    marginLeft: 8,
                };
            }
        }
    },
});
