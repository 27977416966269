export default class JsonToCSV {
  static convertJsonToCSV(jsonData) {
    const items = jsonData;
    if (items.length) {
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");
      return csv;
    } else {
      return ""
    }
  }
}
