import React from "react";
import R14, { StyleSheet, View } from "../core";
import MessageComponent from "../components/MessageComponent";
import ChatInputField from "../components/ChatInputField";
import { BILL_STATUS } from "../constant/app.data.constant"; 
import { customCommonStyles } from "../assets/css/customCommonStyle";
import { CommonString } from "../constant/appCommonText";

export default R14.connect(
  class MessageScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        messages: props.displayMessages,
        msgCount: 0,
        isChatAllow: true,
      };
    }
  
    onSent = (msgData) => {
      this.setState({ messages: this.state.messages.concat(msgData), msgCount: this.state.msgCount + 1 });
    };

    render() {
      const pid = !!this.props.userDetails && !!this.props.userDetails.pid ? this.props.userDetails.pid : null ;
      const { billDetails } = this.props;
      const { messages, isChatAllow } = this.state;
      let lastLeaf = messages[messages.length - 1];
      const messageType = lastLeaf.envelope.messageHeader.senderPid === pid ? "NotetoBiller" : "NotetoPayer";
      const { messageHeader } = lastLeaf.envelope;
      let newBillDetails = {
        ...billDetails,
        recipientPid: pid,
        senderPid: messageHeader.recipientPid === pid ? messageHeader.senderPid : messageHeader.recipientPid,
      }; 
      return (
        <>
          <View style={[styles.messageComponent, customCommonStyles.customMessageComponent]}>
            <MessageComponent displayMessages={messages} key={this.state.msgCount}
              customClass={styles.messageComponentPadding} billDetails={billDetails}
              userDetails={this.props.userDetails}
              billerList={this.props.billerList}
            />
          </View>
          {(BILL_STATUS[billDetails.billStatus] || BILL_STATUS[billDetails.billStatus] === undefined) && !billDetails.biller.isBlocked ? (
            <View style={[styles.chatInputFieldSection]}>
              <ChatInputField
                messageType={messageType}
                billData={newBillDetails}
                msgList={messages}
                onAfterSent={this.onSent}
                notAllowedChat={!isChatAllow}
                eventName={CommonString.SIMPLE_MESSAGE_DETAILS}
              />
            </View>
          ) : null}
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  messageComponent: {
    backgroundColor: "#fff",
    padding: 16,
    marginRight: 16, 
    marginLeft: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    height:"100%",
    screen: ({ width }) => {
      if (width <= 640)
      return {
        marginRight: 0,
        marginLeft: 0,
        padding: 0,
        };
    },
  },
  chatInputFieldSection: {
    backgroundColor: "#fff",
    position: "absolute",
    bottom: 25,
    width: "100%",
    left: 0,
    paddingTop: 0,
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
  },
  pageTitle: {
    paddingTop: 10,
    paddingLeft: 16,
  },
  messageDetailsScreen: {
    zIndex: 999,
  },
  messageComponentPadding: {
    paddingTop: 5,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    width: "100%",
  }
});
