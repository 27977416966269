
import ReactGA4 from "react-ga4";
import { GOOGLE_ANALYTICS_TOKEN } from "../constant/app.constant";

const debugMode = process.env.NODE_ENV === "development" ||  !process.env.NODE_ENV;
ReactGA4.initialize(GOOGLE_ANALYTICS_TOKEN,{ debug: !!debugMode });

/**
 * groupName = String
 * trackingEvent = {} // any
 */
const gaEvent = (groupName, trackingEvent = {}) => {
    // Send a custom event
    ReactGA4.event(groupName, trackingEvent);
    return true;
}


export {
  gaEvent
};