import React from "react";
import R14, { StyleSheet, Platform, Text, View, Touchable, ActivityIndicator } from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { NOTIFICATION_OPT } from "../constant/app.data.constant";
import DateUtils from "../utils/DateUtils";
import NoRecordsFound from "../components/NoRecordsFound";
import PCHLoaderComponent from "../components/PCH/PCHLoaderComponent";

export default R14.connect(
  class NotificationScreen extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loaderVisible: false
      };
    }

    onNavigate = async (type, items) => {
      this.setState({
        loaderVisible: true
      })
      try {
        let pids = await this.props.app.dm.rest.getLinkedPID();
        await this.props.app.dm.notificationDomain.updateNotification({ payerId: pids, subMessageType: type });
        let threadIds = items.map((data, i) => {
          return { threadId: data.threadId, payerId: data.payerId };
        })
        if (items.length > 1)
          this.props.app.dm.notificationDomain.setState({
            notificationThreadIds: threadIds
          });

        if (type === "simpleMessage") {
          //make API call
          // await this.props.app.dm.simpleMessage.displaySimpleMessage(true);
          await this.props.app.dm.simpleMessage.compiledMessagesNotification(threadIds);
          if (items.length > 1) {
            //listing
            this.props.app.nav.to("simpleMessageRoute");
          } else {
            //details
            let newThreadId = items[0].threadId; //.replaceAll("#", ">>");
            this.props.app.nav.to("simpleMessageDetailRoute", { threadId: newThreadId });
          }
        } else if (type === "PAM") {
          //make API call
          // await this.props.app.dm.billerDirectoryDomain.displayBillerDirectoryData();
          await this.props.app.dm.billerDirectoryDomain.pamDirectoryNotification(threadIds, pids.split(','));
          if (items.length > 1) {
            //listing
            this.props.app.nav.to("billersDirectoryRoute");
          } else {
            //details
            let newThreadId = items[0].threadId; //.replaceAll("#", ">>");
            this.props.app.nav.to("pamMessageRoute", { pamId: newThreadId, pid: items[0].payerId });
          }
        } else {
          //make API call
          const { compiledMsgList } = this.props.app.dm.dashboard.state;
          this.props.app.dm.dashboard.setState({
            filterBills: compiledMsgList,
            isBlockNavigation: false,
            selectedBlock: [],
          });
          this.props.app.dm.userSearchDomain.setState({
            isSearchOpen: false,
            textInSearchBox: "",
            currentOpenScreen: this.props.r14.navigation.activeRouteName,
          });
          this.props.app.dm.filterDomain.resetFilter();

          await this.props.app.dm.dashboard.setCompiledListNotification(threadIds);
          if (items.length > 1) {
            //listing
            this.props.app.nav.to("makePaymentRoutes");
          } else {
            //details
            let newThreadId = items[0].threadId; //.replaceAll("#", ">>");
            const { compiledMsgList } = this.props.app.dm.dashboard.state;
            if (!!compiledMsgList.length) {
              let item = compiledMsgList.filter((message) => message.threadId === items[0].threadId);
              if (!!item.length) {
                this.props.app.dm.billDetails.setState({
                  billDetails: item[0]
                });
                this.props.app.nav.to("billDetailsRoute", { threadId: newThreadId });
              }
            }
          }
        }
      } catch (err) {
        this.props.app.dm.commonDomain.showToastr("error", err.message);
      }
    };

    render() {
      const { notifications } = this.props.app.dm.notificationDomain.state;
      const notificationGrp = [...new Set(notifications.map(({ subMessageType }) => subMessageType))];
      return (
        <View style={styles.notificationScreen}>
          {notifications.length === 0 ? (
            <View style={styles.customSpacing}>
              <NoRecordsFound message="No notifications to display" />
            </View>
          ) : (
            notificationGrp.map((ng, i) => {
              let noti = NOTIFICATION_OPT.filter((x) => x.value === ng);
              if (noti.length > 0) {
                let gl = noti[0];
                let messages = notifications.filter((x) => x.subMessageType === gl.value);
                let count = messages.filter((x) => x.isRead === false).length;
                const countLen = (count && count > 0) ? count.toString().length : 0;
                return (
                  <View key={i} style={[commonStyles.flex1, commonStyles.flexDirectionRow, styles.notificationList, commonStyles.alignItemsStart]}>
                    {messages.length > 1 ? (
                      <>
                        <View style={[commonStyles.marginRight10, commonStyles.notificationHolder]}>
                          {/* <Image source={require("../assets/images/" + gl.img)} style={[commonStyles.icon35]} /> */}
                          {count === 0 ? null : (
                            <View style={[commonStyles.notificationCountPerMsg, countLen === 3 ? styles.threeDigiCount : null, countLen === 4 ? styles.fourDigiCount : null]}>
                              <Text style={[styles.notificationCount, commonStyles.whiteColor, commonFontStyles.workSans]}>{count}</Text>
                            </View>
                          )}
                        </View>
                        <View style={[styles.maxWidth80]}>
                          <Touchable onPress={() => this.onNavigate(gl.value, messages)}>
                            <View>
                              <View>
                                <Text style={[commonStyles.heading6, commonFontStyles.workSans, commonStyles.fontWeight500]}>{gl.label}</Text>
                              </View>
                              <View>
                                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans, commonStyles.fontWeight500]}>
                                  {gl.comment.replace("$", messages.length)}{" "}
                                </Text>
                              </View>

                              <View>
                                <Text
                                  style={[
                                    commonStyles.exSmallHeading,
                                    commonStyles.lightGray,
                                    commonFontStyles.workSans,
                                    commonStyles.fontWeight500,
                                  ]}>
                                  {DateUtils.dateTodayYesterday(
                                    messages.sort((a, b) => new Date(b.messageDateTime) - new Date(a.messageDateTime))[0].messageDateTime
                                  )}
                                </Text>
                              </View>
                            </View>
                          </Touchable>
                        </View>

                      </>
                    ) : (
                      <>
                        <View style={[commonStyles.marginRight10, commonStyles.notificationHolder]}>
                          {/* <Image source={require("../assets/images/" + gl.img)} style={[commonStyles.icon35]} /> */}
                          {count === 0 ? null : (
                            <View style={[commonStyles.notificationCountPerMsg, countLen === 3 ? styles.threeDigiCount : null, countLen === 4 ? styles.fourDigiCount : null]}>
                              <Text style={[styles.notificationCount, commonStyles.whiteColor, commonFontStyles.workSans]}>{count}</Text>
                            </View>
                          )}
                        </View>
                        <View style={[styles.maxWidth80]}>
                          <Touchable onPress={() => this.onNavigate(gl.value, messages)}>
                            <View>
                              <View>
                                <Text style={[commonStyles.heading6, commonFontStyles.workSans, commonStyles.fontWeight500]}>{gl.label}</Text>
                              </View>
                              <View>
                                <Text style={[commonStyles.smallHeading, commonFontStyles.workSans, commonStyles.fontWeight500]}>
                                  {messages[0].note}
                                </Text>
                              </View>
                              <View>
                                <Text
                                  style={[
                                    commonStyles.exSmallHeading,
                                    commonStyles.lightGray,
                                    commonFontStyles.workSans,
                                    commonStyles.fontWeight500,
                                  ]}>
                                  {DateUtils.dateTodayYesterday(messages[0].messageDateTime)}
                                </Text>
                              </View>
                            </View>
                          </Touchable>
                        </View>
                      </>
                    )}
                  </View>
                );
              } else return null;
            })
          )}

          {Platform.OS !== 'web' ?
            <View>
              {this.state.isLoading && <ActivityIndicator size="large" color="#337aff" />}
            </View>
            : <PCHLoaderComponent isVisiable={this.state.loaderVisible} labelText={"Please wait"} />
          }
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  notificationScreen: {
    backgroundColor: "#fff",
    marginLeft: 16,
    marginRight: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#d6d6d6",
    flexGrow: 1,
    marginBottom: 16,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          minHeight: 300,
          borderWidth: 0,
        };
    },
  },
  container: {
    backgroundColor: "#fff",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  notificationList: {
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    borderBottom: "1px solid #DADADA",
  },
  pageTitle: {
    color: "#151b26",
  },
  maxWidth80: {
    maxWidth: "80%"
  },
  notificationCount: {
    paddingTop: 2,
    fontSize: 10,
    lineHeight: 10,
  },
  threeDigiCount: {
    width: 30,
    height: 30,
  },
  fourDigiCount: {
    width: 40,
    height: 40,
  },
  customSpacing: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 16,
        };
    },
  }
});
