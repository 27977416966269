import R14, { AsyncStorage } from "../../core";
import { USER_TYPE } from "../../constant/app.data.constant";
import CommonUtility from "../../utils/CommonUtility";
import { CommonString } from "../../constant/appCommonText";

export default class UploadBillsDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      IsSubmitted: false,
      InSideNavigation: true,
      searchPatients: [],
      totalBills: 0,
      currentPage: 1,
      totalPages: 1,
      resultsPerPage : 9,
      selectedBlock:"New"
    };
  }

  async getBills(filter, fields, resultsPerPage = 10) {
    try {
    let result = await this.api.qry(
      `query UploadBills($filter: UploadBillFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          uploadBills(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            totalCount @include(if: true)
            nodes {
              ${fields}
            }
          }
        }`,
      filter
    );
    if (!!result.data.uploadBills && !!result.data.uploadBills.nodes) {
      let totalCount = result.data.uploadBills.totalCount;
      this.setState({
        totalBills: totalCount,
        totalPages: Math.ceil(totalCount / resultsPerPage)
      })
      return result.data.uploadBills.nodes;
    }
    else return [];
     
  } catch (error) {
      console.log(error)
      return [];
  }
  }

  async find(fields, filter) {
    try {
    let result = await this.api.qry(
      `query UploadBills($filter: UploadBillFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
          uploadBills(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
            totalCount @include(if: true)
            nodes {
              ${fields}
            }
          }
        }`,
      filter
    );
    return result.data.uploadBills;
     
  } catch (error) {
      console.log(error)
      return {
        nodes: [],
        totalCount: 0
      };
  }
  }

  async getUploadedBillsWithPage(selectedBlock = CommonString.AllLabel, fieldsStr = "uid email mobileNumber billStatus ssId dOB createdAt doctorUid isPatientRequest submittedDate userProfileUid poBoxNo uploadedDateTime insuranceMemberClaimsAmount") {
    const { currentPage, resultsPerPage } = this.state;
    let statuses = selectedBlock === CommonString.AllLabel ? [CommonString.NewLabel, CommonString.DraftLabel, CommonString.AcceptedLabel,CommonString.ReceivedLabel,CommonString.RejectedLabel,CommonString.SubmittedLabel,CommonString.MailedLabel ] : typeof(selectedBlock) === "string" ? [selectedBlock] : selectedBlock
    let user = await this.dm.rest.getUser();
    if (user) {
      const { userType, mobileNumber, email, uid, parentUid } = user;
      let filter = {};
      if (!!userType && userType === USER_TYPE.PCH_PATIENT) {
        fieldsStr = "uid email mobileNumber billStatus pchSubmittedStatus ssId dOB createdAt doctorUid isPatientRequest submittedDate userProfileUid poBoxNo parentName parentUid providerName isProcessed mergedFileName mergedUnsecuredFileName smsMessageId mailMessageId insuranceMember {uid memberId firstName lastName} pchInsuranceDetail {uid name address1 address2 city state zipCode} uploadedDateTime documentId isSuperBill processFileName insuranceMemberTreatedAt {label value} insuranceMemberClaimsAmount insuranceMemberTreatedOn";
        let appointment = await AsyncStorage.getItem("appointment");
        let isUberDocs = this.dm.userSession.state.isUberDocs;

        if (!!isUberDocs || !!appointment) {
          if(selectedBlock === "All")
              statuses = statuses.filter(x => x !== CommonString.NewLabel);

          filter = {
            filter: {
              mobileNumber: { eq: mobileNumber },
              email: { eq: email },
              source: { eq : "UberDoc"},
              pchSubmittedStatus: { in: statuses }
            },
            sort: { field: "createdAt", order: "DESC" },
            page: currentPage,
            resultsPerPage: resultsPerPage
          };
        }
        else {
          filter = {
            filter: {
              mobileNumber: { eq: mobileNumber },
              email: { eq: email },
              // billStatus: { in: statuses }
              pchSubmittedStatus: { in: statuses }
            },
            sort: { field: "createdAt", order: "DESC" },
            page: currentPage,
            resultsPerPage: resultsPerPage
          };
        }
      }
      else if (!!userType && userType === USER_TYPE.PCH_PROVIDER) {
        fieldsStr = "uid email mobileNumber billStatus pchSubmittedStatus ssId dOB createdAt doctorUid isPatientRequest submittedDate userProfileUid providerName isProcessed mergedFileName mergedUnsecuredFileName smsMessageId mailMessageId insuranceMember {uid memberId firstName lastName} pchInsuranceDetail {uid name} uploadedDateTime documentId isSuperBill processFileName insuranceMemberTreatedAt {label value} insuranceMemberTreatedOn";
        filter = {
          filter: {
            parentUid: { eq: !!parentUid ? parentUid : uid },
            pchSubmittedStatus: { in: statuses }
          },
          sort: { field: "createdAt", order: "DESC" },
          page: currentPage,
          resultsPerPage: resultsPerPage
        };
      }
      return await this.getBills(filter, fieldsStr, resultsPerPage);
    }
  }

  async uploadBill(input, fields = "uid") { 
    await CommonUtility.htmlDecode(input);
    try {
        let res = await this.api.mutate(
          `
          mutation CreateUploadBill($input: CreateUploadBillInput){
            createUploadBill(input: $input){              
              success 
              uploadBill {
                ${fields}
              }
            }
          }`,
          { input }
        );
        return res.data.createUploadBill.uploadBill;
      } catch (error) {
        return error;
      }
  }
  
  async fetchUploadedBill(
    uid,
    fields = "uid name email mobileNumber dOB ssId doctorUid billStatus createdAt isPatientRequest submittedDate pchInsuranceDetail {uid name groupNumber address1 address2 city state country } userProfileUid parentUid parentName providerName isProcessed mergedFileName mergedUnsecuredFileName pchSubmittedStatus participatingPayer processFileName submittedDateTime uploadedDateTime insuranceMemberTreatedOn insuranceMemberClaimsAmount insuranceMemberTreatedType notes source  insuranceMember {uid memberId firstName lastName} documentId isSuperBill",
  ) {
    let options = {
      uid: uid
    };
    try {
      let res = await this.api.qry(
        `query UploadedBill($uid: ID!){
            uploadBill(uid: $uid){
                ${fields}
            }
        }`,
        options
      );
      return res.data.uploadBill;
    } catch (error) {
      return error;
    }
  }

  async submitUploadedBill(
    input,
    fields = "uid"
  ) {
    await CommonUtility.htmlDecode(input);
    try {
      let res = await this.api.mutate(
        `
      mutation UpdateUploadedBillDetail($input: UpdateUploadBillInput) {
        updateUploadBill(input: $input){
          uploadBill {
            ${fields}
          }
          success
        }
      }`,
        { input }
      );
      return res.data.updateUploadBill.uploadBill;
    } catch (error) {
      return error;
    }
  }
  
   setStatePatients = (input) => {
    // 1. copy existing state & update it
    let filterItems = !!input ? input : [];
    const searchPatients = [...new Set([...this.state.searchPatients, ...filterItems])];
    // 2. set state
    this.setState({ searchPatients });
    return searchPatients;
  };
    

  async getUsersByMobileNumber(search, type, limit = 10, fields = "uid email mobileNumber firstName lastName") {
    try {
      const options = {
        searchFilter: search,
        propFilter: type,
        limit: limit,
      };
  
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query SearchUsersByParam($searchFilter: String!, $propFilter:String!, $limit: Int){
                    searchUsersByParam(searchFilter: $searchFilter, propFilter: $propFilter, limit: $limit) {
                      nodes {
                        ${fields}
                      }
                    }
                  }`,
        options
      ));
      return result.data.searchUsersByParam && result.data.searchUsersByParam.nodes ? result.data.searchUsersByParam.nodes : []
    } catch (error) {
      console.log(error);
      return []      
    }
  }

  async getUsersByEmail(search, type, limit = 10, fields = "uid email mobileNumber firstName lastName") {
    try {
      const options = {
        searchFilter: search,
        propFilter: type,
        limit: limit,
      };
  
      let result = await this.dm.queryDomain.middleware(this.api.qry(
        `query SearchUsersByParam($searchFilter: String!, $propFilter:String!, $limit: Int){
                searchUsersByParam(searchFilter: $searchFilter, propFilter: $propFilter, limit: $limit) {
                  nodes {
                    ${fields}
                  }
                }
              }`,
        options
      ));
      return result.data.searchUsersByParam && result.data.searchUsersByParam.nodes ? result.data.searchUsersByParam.nodes : [];
    } catch (error) {
      console.log(error)
      return []
    }
  }

  async readPdfServer(fileName) {
    try {
      if (!!fileName) {
        let res = await this.dm.queryDomain.middleware(this.api.mutate(
          `mutation ReadPDF($fileName: String! ) {
            readPdf(fileName: $fileName) 
          }`,
          { fileName: fileName }
        ));
        return res.data.readPdf;
      }
      else return "";
    } catch (error) {
      return error;
    }
  }

  async readDocument(uid) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query ReadDocument($uid: String! ) {
           readDocument(uid: $uid) 
          }`,
        { uid:uid}
      ));
      return res.data.readDocument;
    } catch (error) {
      return error;
    }
  }

  async uploadDocumentOnServer(input) {
      try {
        delete input.uid;
        let res = await this.dm.queryDomain.middleware(this.api.mutate(
          `mutation CreateDocumentLog($input: CreateDocumentLogInput!){
            writeDocument(input: $input){
              documentLog {
                uid 
                documentId
                content
              }            
            }
          }`,
          {
            input: input,
          }
        ));
        return res.data.writeDocument.documentLog;
      } catch (error) {
        return {
          uid: null,
          documentId: input.documentId
        };
      }
    }

    async deleteDocument(uid) {
      try {
        this.api.mutate(
          `mutation DeleteDocumentLog($uid: ID!){
            deleteDocumentLog(uid: $uid){
             success            
            }
          }`,
          {
            uid: uid,
          }
        );
        // return res.data.createDocumentLog.success;
      } catch (error) {
        return error;
      }
      }

    async uploadDocumentAtSFTP(uid, url, uniqueCode) {
    try {
       this.dm.queryDomain.middleware(this.api.mutate(
        `mutation UploadFiles($uid: String!, $url: String, $uniqueCode: String) {
            uploadFiles(uid: $uid, url: $url, uniqueCode:$uniqueCode) 
          }`,
        { uid: uid, url: url, uniqueCode: uniqueCode}
      ));
    } catch (error) {
      return error;
    }
  }

  async getObjectSignedUrl(fileName) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetObjectSignedUrl($fileName: String! ) {
          getObjectSignedUrl(fileName: $fileName) 
          }`,
        { fileName: fileName }
      ));
      return res.data.getObjectSignedUrl;
    } catch (error) {
      return error;
    }
  }
  
  async getPutObjectSignedUrl(file) {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query PutObjectSignedUrl($fileName: String!, $fileType: String!) {
            putObjectSignedUrl(fileName: $fileName, fileType: $fileType) 
          }`,
        {
          fileName: file.filename,
          fileType: "pdf",
        }
      ));
      return res.data.putObjectSignedUrl;
    } catch (error) {
      return error;
    }
  }

  async documentLogs(filter, fields = "uid filename contentType seqNo size"){
    try {
      let result = await this.api.qry(
        `query DocumentLogs($filter: DocumentLogFilter, $sort: [SortOption!]){
            documentLogs(filter: $filter, sort: $sort){
              nodes {
                ${fields}
              }
            }
          }`,
        filter
      );
      if (!!result.data.documentLogs && !!result.data.documentLogs.nodes) {
        return result.data.documentLogs.nodes;
      }
      else return [];
      
    } catch (error) {
        console.log(error)
        return [];
    }
  }

  }
