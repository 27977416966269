import React from "react";
import R14, { StyleSheet, View, Text, Button, Checkbox, Image, FlatList } from "../core";

import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import { customCommonStyles } from "../assets/css/customCommonStyle";
import PCHLoaderComponent from "./PCH/PCHLoaderComponent";

import { responsiveTableStyle } from "../assets/css/responsiveTableStyle";
import NoRecordsFound from "../components/NoRecordsFound";
import { CommonString } from "../constant/appCommonText";
import OTPConfirmComponent from "./OTPConfirmComponent";
import { analyticsLogs } from "../utils/AnalyticLogs";

const varifed = require("../assets/images/selete_icon_active.png");

export default R14.connect(
  class LinkPayerComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        selectedPIDs: [],
        suggestionPIDs: [],
        userLoginDetail: {},
        isDisable: true,
        visibleOptModalPopUp: false,
        timer: 45,
        totalResentOTP: 0,
      };
      this.timerInstance = null;

    }

    startTimer = () => {
      this.setState({
        timer: 45
      })
      this.timerInstance = setInterval(() => {
        if (this.state.timer > 0) {
          this.setState({
            timer: this.state.timer - 1
          });
        }
        else { if (this.timerInstance) { clearInterval(this.timerInstance); } }
      }, 1000);
    }

    componentWillUnmount() {
      if (this.timerInstance) {
        clearInterval(this.timerInstance);
      }
    }

    async componentDidMount() {
      try {
        let suggestionPIDs = await this.props.app.dm.simpleMessage.getLinkPIDSuggestions();
        const userLoginDetail = await this.props.app.dm.rest.getUser();
        let selectedPIDs = [];
        if (suggestionPIDs && suggestionPIDs.suggestions) {
          suggestionPIDs = suggestionPIDs.suggestions.map(suggestion => {
            return suggestion;
          });
          suggestionPIDs = suggestionPIDs.filter(suggestion => !!suggestion.buId && !!suggestion.billerRef)
          if (selectedPIDs.filter(x => x.payerPID !== userLoginDetail.pid).length > 0) {
            this.setState({
              isDisable: false
            })
          }
          this.setState({
            suggestionPIDs: suggestionPIDs,
            userLoginDetail,
            selectedPIDs,
            isLoading: false
          })
        } else this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    }

    handleSubmit = async (otp) => {
      const { selectedPIDs, userLoginDetail, suggestionPIDs } = this.state;
      if (selectedPIDs.filter(x => x.payerPID !== userLoginDetail.pid).length > 0) {
        let primaryPID = suggestionPIDs.find(x => x.payerPID === userLoginDetail.pid);
        primaryPID = primaryPID || userLoginDetail;
        selectedPIDs[0].otp = otp;
        const res = await this.props.app.dm.simpleMessage.postLinkPayerPID(selectedPIDs, primaryPID);
        if (!!res && !!res.success) {
          let pids = await this.props.app.dm.simpleMessage.getLinkPIDSuggestions();
          if (pids && pids.suggestions) {
            pids = pids.suggestions.map(suggestion => {
              return suggestion;
            });
            pids = pids.filter(suggestion => !!suggestion.buId && !!suggestion.billerRef)
          }
          this.setState({
            isDisable: false,
            suggestionPIDs: pids,
            visibleOptModalPopUp: false,
            selectedPIDs: [],
          })
          analyticsLogs(CommonString.LINK_PAYER, `${CommonString.CLICKED_SUBMIT_BUTTON}`, this.props.app);
          this.props.app.dm.commonDomain.showToastr("success", "Payer linked successfully");
          analyticsLogs(CommonString.LINK_PAYER, `${CommonString.Payer_Linked_Successfully}`, this.props.app);
        } else {
          this.props.app.dm.commonDomain.showToastr("error", !!res && !!res.message ? res.message : CommonString.SomethingWentWrong);
        }
      }
      else {
        this.setState({
          visibleOptModalPopUp: false
        })
      }
      return false;
    };

    onCancel = () => {
      analyticsLogs(CommonString.LINK_PAYER, `${CommonString.CLICKED_ON_CANCEL_BUTTON}`, this.props.app);
      this.props.app.nav.back();
    }

    sendOTP = async (resend) => {
      const { selectedPIDs, userLoginDetail, suggestionPIDs, totalResentOTP } = this.state;
      if (!!resend && resend === "resend" && totalResentOTP >= 3) return false;
      if (!!resend && resend === "resend") this.setState({ totalResentOTP: totalResentOTP + 1 });
      let primaryPID = suggestionPIDs.find(x => x.payerPID === userLoginDetail.pid);
      primaryPID = primaryPID || userLoginDetail;
      let res = await this.props.app.dm.simpleMessage.linkPayerOTP(selectedPIDs, primaryPID);
      if (!!res && !!!res.success)
        this.props.app.dm.commonDomain.showToastr("error", !!res && !!res.message ? res.message : CommonString.SomethingWentWrong);
      analyticsLogs(CommonString.LINK_PAYER, `${CommonString.LINK_PAYER_OTP_SENT}`, this.props.app);
      this.startTimer();
    }

    handleOtpPopUp = async () => {
      // if modal pop up is visible then close popup else open and call send otp API
      if (!!this.state.visibleOptModalPopUp) {

        analyticsLogs(CommonString.LINK_PAYER, `${CommonString.CLOSE_OTP_POPUP}`, this.props.app);
        this.setState({ visibleOptModalPopUp: false, totalResentOTP: 0 });

        if (this.timerInstance) clearInterval(this.timerInstance);
      } else {
        // api call and get mobile number
        await this.sendOTP();
        this.setState({ visibleOptModalPopUp: true });
      }
      return false;
    }

    masking = (value) => {
      let four = value.substring(value.length - 4, value.length);
      return `(xxx)-xxx-${four}`;
    }

    renderOtpModelPopUp = () => {
      const { visibleOptModalPopUp, selectedPIDs, timer, totalResentOTP } = this.state;
      return (
        <OTPConfirmComponent
          visibility={visibleOptModalPopUp}
          mobileNo={!!selectedPIDs && selectedPIDs.length > 0 ? this.masking(selectedPIDs[0].phone) : ""}
          sendOTP={this.sendOTP}
          totalResentOTP={totalResentOTP}
          timer={timer}
          handleOtpPopUp={this.handleOtpPopUp}
          handleSubmit={this.handleSubmit}
        />
      )
    }

    toggleCheckBox = async (item) => {
      const { selectedPIDs, userLoginDetail } = this.state;
      const isSelected = selectedPIDs.findIndex(arr => arr.payerPID === item.payerPID && arr.billerPID === item.billerPID && arr.buId === item.buId)
      if (isSelected > -1) {
        analyticsLogs(CommonString.LINK_PAYER, `${CommonString.PAYER_UNSELECTED}`, this.props.app);
        this.setState({
          selectedPIDs: []
        });
      } else {
        analyticsLogs(CommonString.LINK_PAYER, `${CommonString.PAYER_SELECTED}`, this.props.app);
        selectedPIDs.length = 0;
        selectedPIDs.push({ payerPID: item.payerPID, billerPID: item.billerPID, buId: item.buId, phone: item.phone });
        this.setState({
          selectedPIDs: selectedPIDs
        })
      }

      if (selectedPIDs.filter(x => x.payerPID !== userLoginDetail.pid).length > 0) {
        this.setState({
          isDisable: false
        })
      }
    };

    render() {
      const { selectedPIDs, suggestionPIDs, isLoading, isDisable } = this.state;
      return (
        <>
          {isLoading ? (
            <PCHLoaderComponent isVisiable={isLoading} labelText={"Please wait"} />
          ) : !suggestionPIDs || !suggestionPIDs.length ? (
            <View style={[styles.noItemsComponent]}>
              <NoRecordsFound message={"No link payer suggestion to display."} />
            </View>
          ) : (
            <View style={[responsiveTableStyle.myBankMainSection]}>
              {/* Web View Start */}
              <View style={[styles.table, !!this.state.suggestionPIDs.length <= 0 ? responsiveTableStyle.tablepadding : null, commonStyles.flex1]}>
                {!!suggestionPIDs.length > 0 ? (
                  <View style={[styles.table, commonStyles.margin0, commonStyles.padding0, styles.borderWidth0, commonStyles.marginTop15, commonStyles.paddingLeftRight16]}>
                    <View
                      style={[
                        styles.thead,
                        commonStyles.flexGrow0,
                        commonStyles.justifyContentSpaceBetween,
                        commonStyles.flexDirectionRow,
                      ]}>
                      {/* Checkbox */}
                      <View style={[styles.checkboxContainer]}>

                      </View>
                      <View style={styles.th}>
                        <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Biller</Text>
                      </View>
                      <View style={styles.th}>
                        <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Payer</Text>
                      </View>
                      <View style={[styles.th, styles.thStatus]}>
                        <Text style={[commonFontStyles.workSans, commonStyles.fontWeight500, commonStyles.heading6]}>Status</Text>
                      </View>
                    </View>
                  </View>
                ) : null}
                <View>
                  <FlatList
                    style={[styles.providerListComponent, suggestionPIDs && suggestionPIDs.length > 0 ? customCommonStyles.customProviderListComponent : styles.noPIDComponent, styles.customMarginTop, styles.borderBottomWidth0, styles.marginLeftRight16, styles.customListHeight]}
                    data={suggestionPIDs ? suggestionPIDs : []}
                    keyExtractor={(el, i) => i}
                    // onLayout={(event) => this.getHeightOfElement(event)}
                    ListEmptyComponent={
                      <View style={styles.padd10}>
                        <NoRecordsFound noRecords={[commonStyles.marginTop15, styles.noRecordFound]} message={"No PID suggestion to display."} />
                      </View>
                    }
                    renderItem={({ item, index }) => (
                      <View style={styles.body}>
                        <View style={[styles.tr, commonStyles.flex1, commonStyles.flexDirectionRow, styles.justifyContentSpaceBetween]}>
                          {/* Checkbox */}
                          <View style={[styles.checkboxContainer, item.isPrimary ? commonStyles.flexDirectionRow : null]}>
                            <Checkbox
                              name={item.buId + index}
                              label={item.buId}
                              value={selectedPIDs.findIndex(arr => arr.payerPID === item.payerPID && arr.billerPID === item.billerPID && arr.buId === item.buId) !== -1 ? true :
                                false}
                              onPress={() => this.toggleCheckBox(item)}
                              colorBlank="#585858"
                              colorMarked="#4dc5f2"
                              disabled={item.mobileStatus === "Linked" || item.mobileStatus === "Requested" || item.mobileStatus === "Accepted" ? true : false}
                            />
                          </View>
                          <View style={[commonStyles.flex1, styles.customFlexDirection]}>
                            {/* Address */}
                            <View style={[styles.td, commonStyles.paddingRight16, item.isPrimary ? commonStyles.flexDirectionRow : null]}>
                              <View
                                style={[
                                  commonStyles.mView,
                                  commonStyles.marginBottom5,
                                  styles.mobileLabels,
                                ]}
                              >
                                <Text
                                  style={[
                                    commonFontStyles.workSans,
                                    commonStyles.fontWeight700,
                                    commonStyles.heading6,
                                  ]}
                                >
                                  Biller:
                                </Text>
                              </View>
                              {/* Primary Address icon */}
                              <View style={[item.isPrimary ? null : commonStyles.displayNone, item.isPrimary ? styles.paddingRight5 : null]}>
                                <Image source={varifed} style={commonStyles.icon18} />
                              </View>
                              <View style={[customCommonStyles.valueInMbileBreakWord]}>
                                <Text
                                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.flexWrap]}
                                >
                                  {`${item.billerName}\n${item.buName}\n${item.billerRef} (${item.billerPID})`}
                                </Text>
                              </View>
                            </View>

                            {/* town */}
                            <View style={[styles.td, commonStyles.paddingRight16]}>
                              <View
                                style={[
                                  commonStyles.mView,
                                  commonStyles.marginBottom5,
                                  styles.mobileLabels,
                                ]}
                              >
                                <Text
                                  style={[
                                    commonFontStyles.workSans,
                                    commonStyles.fontWeight700,
                                    commonStyles.heading6,
                                  ]}
                                >
                                  Payer:
                                </Text>
                              </View>
                              <View style={[customCommonStyles.valueInMbileBreakWord]}>
                                <Text
                                  style={[commonFontStyles.workSans, commonStyles.heading6, styles.flexWrap]}
                                >
                                  {`${item.payerName}\n${item.email}\n${item.phone}\n${item.payerRef} (${item.payerPID})`}
                                </Text>
                              </View>
                            </View>
                            {/* post code */}
                            <View style={[styles.td, styles.tdStatus, commonStyles.paddingRight16]}>
                              <View
                                style={[
                                  commonStyles.mView,
                                  commonStyles.marginBottom5,
                                  styles.mobileLabels,
                                ]}
                              >
                                <Text
                                  style={[
                                    commonFontStyles.workSans,
                                    commonStyles.fontWeight700,
                                    commonStyles.heading6,
                                  ]}
                                >
                                  Status:
                                </Text>
                              </View>
                              <View style={[customCommonStyles.valueInMbileBreakWord]}>
                                <Text
                                  style={[commonFontStyles.workSans, commonStyles.heading6]}
                                >
                                  {item.mobileStatus ? item.mobileStatus : "Not Linked"}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    )}
                  />
                </View>
                <View style={[commonStyles.smallModalBtnWrapper, commonStyles.flex1, commonStyles.flexDirectionRow, styles.pidButtonContainer]}>
                  <Button
                    title={"Cancel"}
                    style={[commonStyles.blueBorderButton, commonStyles.addInsuranceAlertOkBtn, customCommonStyles.noBoxShadow, commonStyles.marginRight16, styles.customInactiveBtnStyle, commonStyles.btnHeight24]}
                    onPress={() => this.onCancel()}
                    key="cancel"
                    titleTextStyle={[
                      commonFontStyles.workSans,
                      commonStyles.fontWeight500,
                      commonStyles.heading6,
                      commonStyles.transperentBtnColor,
                      commonStyles.blueBorderButtonColor,
                    ]}
                  />
                  <Button
                    title={"Submit"}
                    style={[commonStyles.blueButton, commonStyles.marginRight8, commonStyles.addInsuranceAlertOkBtn, customCommonStyles.noBoxShadow, (!selectedPIDs.length || !!isDisable) && customCommonStyles.disabledBtn, styles.customActiveBtnStyle, commonStyles.btnHeight24]}
                    onPress={() => (!!selectedPIDs.length && !isDisable) ? this.handleOtpPopUp() : null}
                    key="handleSubmit"
                    titleTextStyle={[
                      commonFontStyles.workSans,
                      commonStyles.fontWeight500,
                      commonStyles.heading6,
                      commonStyles.transperentBtnColor,
                      commonStyles.whiteColor,
                    ]}
                  />
                </View>
              </View>
              {this.renderOtpModelPopUp()}
            </View>
          )
          }
        </>
      );
    }
  }
);

const styles = StyleSheet.create({
  table: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderWidth: 0,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    borderBottomWidth: 0,
    // screen: ({ width }) => {
    //   if (width <= 991)
    //     return {
    //       display: "none"
    //     };
    // },
  },
  block: { display: "block" },
  none: { display: "none" },
  noStyle: {},
  textFieldsHolder: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexDirection: "column",
        };
    },
  },
  thead: {
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    position: "relative",
    flex: 1,
    flexDirection: "row",
    zIndex: 999,
    backgroundColor: "#dadada",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    maxHeight: 40,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          display: "none",
        };
    },
  },
  tr: {
    justifyContent: "space-between",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dadada",
    borderTop: 0,
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          borderWidth: 0,
          borderBottomWidth: 1,
          marginRight: 0,
          marginLeft: 0,
          paddingTop: 10,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          borderLeft: 0,
          borderRight: 0,
          flex: 1,
          flexDirection: "row",
        };
    },
  },
  th: {
    flex: 1,
    flexBasis: "35%",
    maxWidth: "35%",
  },
  thStatus: {
    flex: 1,
    flexBasis: "15%",
    maxWidth: "15%",
  },
  td: {
    flex: 1,
    flexBasis: "35%",
    maxWidth: "35%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: "100%",
          maxWidth: "100%",
          flexDirection: "row",
        };
    },
  },
  tdStatus: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          flex: 1,
          flexBasis: "15%",
          maxWidth: "15%",
        };
    },
  },
  providerListComponent: {
    backgroundColor: "#fff",
    marginTop: 164,
    marginRight: 16,
    marginBottom: 70,
    marginLeft: 16,
    paddingTop: 0,
    // paddingRight: 16,
    paddingBottom: 16,
    // paddingLeft: 16,
    display: "block",
    flexGrow: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    // borderTopWidth: 0,
    // position: "unset",
    screen: ({ width }) => {
      if (width >= 992 && width <= 1024) {
        return {
          marginTop: 184,
        };
      }
      if (width >= 320 && width <= 991) {
        return {
          // paddingTop: 16,
          borderTopWidth: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginBottom: 100,
          marginRight: 0,
          marginLeft: 0,
        };
      }
    },
  },
  bodyWrapper: {
    width: 300,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          width: 300,
          minHeight: 150,
          maxHeight: 200,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          fontWeight: 600,
        };
    },
  },
  customFlexDirection: {
    flexDirection: "row",
    justifyContent: "space-between",
    screen: ({ width }) => {
      if (width <= 991) {
        return {
          display: "block",
          flexDirection: "column",
          maxWidth: "100%",
          flexBasis: "100%",
        };
      }
    },
  },
  noRecordFound: {
    marginBottom: 50,
  },
  noItemsComponent: {
    marginTop: 0,
    marginRight: 16,
    marginBottom: 0,
    marginLeft: 16,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    height: "100%",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 0,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          borderWidth: 0,
        };
    },
  },
  noPIDComponent: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    backgroundColor: "#fff",
    padding: 0,
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "#e2e2e2",
    position: "relative",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginTop: 60,
          marginRight: 0,
          marginBottom: 100,
          marginLeft: 0,
        };
    },
  },
  borderWidth0: {
    borderWidth: 0
  },
  customMarginTop: {
    margin: 0,
    position: "relative",
    borderWidth: 0,
    top: 0,
  },
  borderBottomWidth0: {
    borderBottomWidth: 0
  },
  mobileLabels: {
    marginRight: 10,
    minWidth: 100,
  },
  pidButtonContainer: {
    marginRight: 16,
    maxWidth: "50%",
    marginLeft: "auto",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginLeft: 16,
          maxWidth: "100%"
        };
    },
  },
  checkboxContainer: {
    flex: 1,
    flexBasis: "5%",
    maxWidth: "5%",
    marginTop: -8,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          marginBottom: 5,
          flexBasis: 38,
          maxWidth: 38,
          flexDirection: "row",
        };
    },
  },
  buttonWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 15,
  },
  buttons: {
    width: 90,
    minWidth: "40%",
  },
  btnTexClr: {
    color: "#333",
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          justifyContent: "flex-start",
        };
    },
  },
  customActiveBtnStyle: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          marginLeft: 0,
          marginRight: 0,
          maxWidth: 150
        };
    },
  },
  customInactiveBtnStyle: {
    screen: ({ width }) => {
      if (width >= 992)
        return {
          maxWidth: 150
        };
    },
  },
  customListHeight: {
    height: "calc(50vh)",
    screen: ({ width }) => {
      if (width <= 991)
        return {
          height: "calc(70vh)"
        };
    },
  },
  marginLeftRight16: {
    marginLeft: 16,
    marginRight: 16,
  },
  disabledBtn: {
    opacity: 0.5,
    cursor: "no-drop",
    pointerEvents: "none",
  },
  flexWrap: {
    screen: ({ width }) => {
      if (width <= 991)
        return {
          flexShrink: 1,
          flexWrap: "wrap",
          maxWidth: "95%",
          wordBreak: "break-word",
        };
    },
  },
})