import React from "react";
import R14, {
  StyleSheet, 
  View,
  Form,
  Button,
  TextInputField,
  SubmitButton,
  HiddenInputField,
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { commonFontStyles } from "../assets/css/commonFontStyles";
import CommonValidators from "../utils/CommonValidators";
import LightBlueRadioButtons from "../components/LightBlueRadioButtons";
import RSAencryption from "../utils/RSAencryption";
import { CommonString } from "../constant/appCommonText";

export default R14.connect(
  class AddNewAccountScreen extends React.Component {
    constructor(props) {
      super(props);
      this.accountOption = [
        { label: "Savings Account", value: "SA" },
        { label: "Checking Account", value: "CA" },
      ];
      this.state = {
        selectedOpt: null,
        accountNumber: null,
        displayAccountNumber: null,
        accountHolderName: null,
        bankName: null,
        rtnNumber: null,
        bicCode: null,
        displayBicCode: null,
      };
      this.form = null;
      this.user = this.props.userDetails;
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid ? true : false;
    }
    get formData() {
      return this.props.formData;
    }

    componentDidMount() {
      this.form = this.props.app.ui.form("addAccount");
      if (this.isEditMode === true) {
        if (this.props.formData.values.accountType !== "" && this.props.formData.values.accountType !== null) {
          this.setState({ selectedOpt: this.props.formData.values.accountType });
        }
        this.form.elmts.accountNumber.setValue(this.props.formData.values.displayAccountNumber);
        this.form.elmts.bicCode.setValue(this.props.formData.values.displayBicCode);
        this.setState({
          accountHolderName: this.props.formData.values.accountHolderName,
          bankName: this.props.formData.values.bankName,
          rtnNumber: this.props.formData.values.rtnNumber,
          bicCode: this.props.formData.values.bicCode,
          displayBicCode: this.props.formData.values.displayBicCode,
          accountNumber: this.props.formData.values.accountNumber,
          displayAccountNumber: this.props.formData.values.displayAccountNumber,
        });
      }
    }

    addAccountHolderName(val) {
      val = val.replace(/^\s+/, "");
      this.form.elmts.accountHolderName.setValue(val);
      this.setState({ accountHolderName: val });
      this.form.elmts.accountHolderName.setError(null);
    }
    onChangeAccountNumber(val) {
      this.setState({ accountNumber: val });
      if (val.length > 0) {
        this.setState({ displayAccountNumber: this.maskAccountNumber(val) });
      } else {
        this.form.elmts.accountNumber.setError(null);
      }
    }
    maskAccountNumber = (value) => {
      if (!value) return value;
      let currentValue = value;
      currentValue = currentValue.replace(/[^\dX]/g, "").substring(0, 17);
      let currentValueLength = currentValue.length;
      let specialChar = "X";
      if (currentValue.substr(-1).toLowerCase() === "x") {
        this.setState({ accountNumber: "" });
        return this.form.elmts.accountNumber.setValue("");
      } else {
        if (currentValueLength < 5) {
          this.setState({ accountNumber: currentValue.replace(/[^\d]/g, "") });
          this.form.elmts.accountNumber.setValue(currentValue);
          return currentValue;
        } else {
          this.setState((previousState) => ({
            accountNumber: `${previousState.accountNumber}${currentValue.substr(-1)}`.substring(0, currentValueLength),
          }));
          let value = currentValue.substring(0, 1) + specialChar.repeat(currentValueLength - 4) + currentValue.substr(-3);
          this.form.elmts.accountNumber.setValue(value);
          return value;
        }
      }
    };
    onChangeBankName(val) {
      val = val.replace(/^\s+/, "");
      this.form.elmts.bankName.setValue(val);
      this.setState({ bankName: val });
      this.form.elmts.bankName.setError(null);
    }
    onChangeRtnNumber(val) {
      if (val.length > 0) {
        val = val.replace(/[^\d]/g, "").substring(0, 9);
        this.form.elmts.rtnNumber.setValue(val);
        this.setState({ rtnNumber: val });
      } else {
        this.setState({ rtnNumber: val });
        this.form.elmts.rtnNumber.setError(null);
      }
    }
    onChangeBicCode(val) {
      val = val.replace(/^\s+/, "");
      this.form.elmts.bicCode.setValue(val);
      if (val.length > 0) {
        val = val.replace(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g, "");
        this.form.elmts.bicCode.setValue(val);
        this.setState({ bicCode: val });
        if (val.length > 7) {
          let specialChar = "X";
          let maskValue = "";
          let valueLength = val.length;
          if (valueLength < 9) {
            this.setState((previousState) => ({
              bicCode: val,
            }));
            maskValue = val.substring(0, 5) + specialChar.repeat(3);
          } else {
            this.setState((previousState) => ({
              bicCode: `${previousState.bicCode}${val.substr(-1)}`.substring(0, valueLength),
            }));
            maskValue = this.state.bicCode.substring(0, valueLength - 3) + specialChar.repeat(3);
          }
          this.setState({ displayBicCode: maskValue });
          this.form.elmts.bicCode.setValue(maskValue);
          this.form.elmts.bicCode.setError(null);
        }
      } else {
        this.setState({ bicCode: val });
        this.form.elmts.bicCode.setError(null);
      }
    }
    onValueChange = (val) => {
      this.setState({ selectedOpt: val });
    };
    navigateToBack = async () => {
      this.props.app.nav.back();
    };

    onSubmit = async (form) => {
      let payerAccountDetails = {
        payerId: this.user.payerId,
        accountType: this.state.selectedOpt,
        accountHolderName: form.values.accountHolderName,
        bankName: form.values.bankName,
        accountNumber: this.isEditMode ? this.state.accountNumber : RSAencryption.EnctyptString(this.state.accountNumber, true),
        displayAccountNumber: this.state.displayAccountNumber,
        rtnNumber: form.values.rtnNumber,
        bicCode: this.isEditMode ? this.state.bicCode : RSAencryption.EnctyptString(this.state.bicCode, true),
        displayBicCode: this.state.displayBicCode,
        isSetDefault: false,
        status: "Enabled",
      };
      if (this.isEditMode) {
        if (this.props.formData.values.accountNumber !== this.state.accountNumber) {
          payerAccountDetails.accountNumber = RSAencryption.EnctyptString(this.state.accountNumber, true);
        }
        if (this.props.formData.values.bicCode !== this.state.bicCode) {
          payerAccountDetails.bicCode = RSAencryption.EnctyptString(this.state.bicCode, true);
        }
      }
      let uid = this.isEditMode ? this.props.formData.values.uid : "";
      let accountExist = await this.props.app.dm.billDetails.checkBankAccountExist(
        payerAccountDetails.accountNumber,
        payerAccountDetails.payerId,
        uid
      );
      // await this.props.app.dm.billDetails.getPayerAccountDetailsList();
      if (this.isEditMode) {
        if (!!accountExist && accountExist.success) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.DuplicateAccount);
        } else {
          try {
            payerAccountDetails.uid = this.props.formData.values.uid;
            payerAccountDetails.isSetDefault = this.props.formData.values.isSetDefault;
            let update = await this.props.app.dm.billDetails.updatePayerAccountDetails(payerAccountDetails);
            if (update) {
              this.props.app.dm.commonDomain.showToastr("success", `Account ${this.state.displayAccountNumber} updated successfully.`);
            } else {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
          } catch (error) {
            // console.log("error", error);
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }
        }
      } else if (!this.isEditMode) {
        if (!!accountExist && accountExist.success) {
          this.props.app.dm.commonDomain.showToastr("error", CommonString.DuplicateAccount);
        } else {
          try {
            let create = await this.props.app.dm.billDetails.savePayerAccountDetails(payerAccountDetails);
            if (create) {
              this.props.app.dm.commonDomain.showToastr("success", CommonString.AddAccount);
            } else {
              this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
            }
          } catch (error) {
            this.props.app.dm.commonDomain.showToastr("error", CommonString.SomethingWentWrong);
          }
        }
      }
      setTimeout(() => {
        this.props.app.nav.back();
      }, 1000);
    };

    render() {
      const { selectedOpt, accountNumber, accountHolderName, bankName, rtnNumber, bicCode } = this.state;

      return (
        <View style={styles.addNewCardScreen}>
          <View style={[commonStyles.dFlex, commonStyles.marginTop15, commonStyles.alignItemsCenter]}>
            {this.accountOption.map((el, key) => (
              <LightBlueRadioButtons
                radioButtonsClass={[
                  commonStyles.margin0,
                  commonStyles.padding0,
                  commonStyles.flexBasis50,
                  el.label === "Checking Account" ? commonStyles.marginLeft12 : null,
                ]}
                imgClass={commonStyles.marginRight15}
                options={el}
                key={key}
                onValueChange={this.onValueChange}
                isSelected={selectedOpt}
              />
            ))}
          </View>
          <Form
            name="addAccount"
            validators={CommonValidators.getFormValidator()}
            validateBeforeSubmit={true}
            onSubmit={this.onSubmit}
            initialValues={this.formData.values}
            style={[commonStyles.padding0, commonStyles.marginTop25, commonStyles.flexGrow1]}>
            {this.isEditMode && <HiddenInputField name="uid" />}
            <View style={[commonStyles.dFlex, commonStyles.flexWrap]}>
              <View
                style={[commonStyles.flex1, commonStyles.flexBasis48, commonStyles.marginRight8, styles.inputFields, commonStyles.marginBottom20]}>
                <TextInputField
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}
                  labelStyle={[commonStyles.formLabelStyle]}
                  name="accountHolderName"
                  label="Account Holder Name*"
                  validator="min2Max26"
                  onChangeText={(val) => this.addAccountHolderName(val)}
                  style={[
                    commonStyles.margin0,
                    styles.messageTextField,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight500,
                  ]}
                />
              </View>
              <View style={[commonStyles.flex1, commonStyles.flexBasis48, commonStyles.marginLeft8, styles.inputFields, commonStyles.marginBottom20]}>
                <TextInputField
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}
                  labelStyle={[commonStyles.formLabelStyle]}
                  name="bankName"
                  validator="min10Max50"
                  label="Bank Name*"
                  onChangeText={(val) => this.onChangeBankName(val)}
                  style={[
                    commonStyles.margin0,
                    styles.messageTextField,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight500,
                  ]}
                />
              </View>
            </View>
            <View style={[commonStyles.dFlex, commonStyles.flexWrap]}>
              <View
                style={[commonStyles.flex1, commonStyles.flexBasis48, commonStyles.marginRight8, styles.inputFields, commonStyles.marginBottom20]}>
                <TextInputField
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}
                  labelStyle={[commonStyles.formLabelStyle]}
                  name="accountNumber"
                  validator="bankAccountValidator"
                  label="Bank Account Number*"
                  onChangeText={(val) => this.onChangeAccountNumber(val)}
                  style={[
                    commonStyles.margin0,
                    styles.messageTextField,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight500,
                  ]}
                />
              </View>
              {/* <View style={styles.hideAccountNumber}>
              <Text>{this.state.accountNumber}</Text>
            </View> */}
              <View style={[commonStyles.flex1, commonStyles.flexBasis48, commonStyles.marginLeft8, styles.inputFields, commonStyles.marginBottom20]}>
                <TextInputField
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}
                  labelStyle={[commonStyles.formLabelStyle]}
                  name="rtnNumber"
                  validator="min9Max9Digit"
                  label="RTN / ABA Number*"
                  onChangeText={(val) => this.onChangeRtnNumber(val)}
                  style={[
                    commonStyles.margin0,
                    styles.messageTextField,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight500,
                  ]}
                />
              </View>
            </View>
            <View style={[commonStyles.dFlex, commonStyles.flexWrap]}>
              <View style={[commonStyles.flex1, commonStyles.flexBasis48, styles.inputFields, commonStyles.marginBottom20]}>
                <TextInputField
                  labelTextStyle={[commonStyles.labelText, commonFontStyles.workSans, commonStyles.heading6, commonStyles.fontWeight400]}
                  labelStyle={[commonStyles.formLabelStyle]}
                  name="bicCode"
                  label="BIC / SWIFT Code*"
                  validator="min8Max11"
                  onChangeText={(val) => this.onChangeBicCode(val)}
                  style={[
                    commonStyles.margin0,
                    styles.messageTextField,
                    commonFontStyles.workSans,
                    commonStyles.heading6,
                    commonStyles.fontWeight500,
                  ]}
                />
              </View>
            </View>

            <View style={[commonStyles.dFlex, commonStyles.marTAuto, commonStyles.marginBottom30, styles.buttonWrapper]}>
              <Button
                key="cancel"
                title="Cancel"
                style={[commonStyles.blueBorderButton, commonStyles.flexBasis50, commonStyles.marginRight15, styles.buttons]}
                titleTextStyle={[commonStyles.fontWeight700, commonStyles.blueBorderButtonColor, commonFontStyles.workSans]}
                onPress={this.navigateToBack}
              />
              <SubmitButton
                title={this.isEditMode ? "Update" : "ADD"}
                key="add"
                disabled={!selectedOpt || !accountHolderName || !accountNumber || !bankName || !rtnNumber || !bicCode}
                style={[commonStyles.flexBasis50, commonStyles.blueButton, styles.buttons]}
                titleTextStyle={[commonStyles.fontWeight700, commonStyles.black, commonStyles.blueButtonColor, commonFontStyles.workSans]}
              />
            </View>
          </Form>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  addNewCardScreen: {
    flex: 0,
    height: "100%",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    paddingRight: 15,
    paddingLeft: 15,
    marginLeft: 16,
    marginRight: 16,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          alignItems: "center",
          margin: 0,
        };
    },
  },
  month: {
    marginRight: 10,
    flexGrow: 1,
    flexBasis: "50%",
  },
  year: {
    marginLeft: 10,
    flexGrow: 1,
    flexBasis: "50%",
  },
  hideAccountNumber: {
    display: "none",
  },
  bodyWrapper: {
    screen: ({ width }) => {
      if (width <= 479) {
        return {
          width: "93%",
        };
      }
    },
  },
  buttonWrapper: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          justifyContent: "flex-end",
        };
      }
    },
  },
  buttons: {
    screen: ({ width }) => {
      if (width >= 992) {
        return {
          flexBasis: "auto",
          flexGrow: 0,
          minWidth: "108px",
        };
      }
    },
  },
  inputFields: {
    screen: ({ width }) => {
      if (width >= 991 && width <= 1083) {
        return {
          flexBasis: "48%",
        };
      }
      if (width <= 991) {
        return {
          flexBasis: "100%",
          margin: 0,
        };
      }
    },
  },
});
