import React from "react";
import R14, {
  View,
  IconButton,
  StyleSheet,
  Image,
  AsyncStorage,
} from "../core";
import { commonStyles } from "../assets/css/commonStyle";
import { uberDocThemeStyle } from "../assets/css/clients/uberDocThemeStyle";
import Linking from "../utils/Linking";
const xbpLogo = require("../assets/images/XBPlogo.png");
const clientLogo = require("../assets/images/uberdoc_logo.png");
const xbpSymbolLogo = require("../assets/images/XBPSymbol.png");

export default R14.connect(
  class HeaderControlsLeft extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        toggle: false,
        initialized: false,
      };
      this.userDetails = null;
    }
    async initialize() {
      this.userDetails = await this.props.app.dm.rest.getUser();
      this.setState({ initialized: true });
    }
    componentDidMount() {
      this.initialize();
    }
    onToggle = () => {
      this.props.app.dm.userSession.setState({
        leftMenuToggle: !this.props.app.dm.userSession.state.leftMenuToggle,
        leftMenuOverlay: !this.props.app.dm.userSession.state.leftMenuOverlay,
        isProfileDetailsOpen: false,
      });
    };
    onBack = async () => {
      let currentRoute = this.props.r14.navigation.activeRouteName;
      let currRoute = this.props.r14.navigation.currRoute;
      const { billDetails } = this.props.app.dm.billDetails.state;
      let threadId = billDetails ? billDetails.threadId : "";
      let billerId = billDetails ? billDetails.billerId : "";
      if (currentRoute === "billDetailsRoute") {
        this.props.app.nav.to(await AsyncStorage.getItem("homeScreen"));
      } else if (["makePaymentRoutes"].includes(currentRoute)) {
        this.props.app.nav.to("dashboard");
      } else if (!!billerId && currentRoute === "billHistoryDetailsRoute") {
        this.props.app.nav.to("billHistoryScreenRoute", { billerPid: billerId })
      } else if (!!threadId && ["billHistoryScreenRoute", "payTypeRoute", "payPartialRoute", "declinedRoute", "extensionRoute"].includes(currentRoute)) {
        this.props.app.nav.to("billDetailsRoute", { threadId: threadId });
      } else if (currentRoute === "addProviderRoute" && !!currRoute && !!currRoute.params && currRoute.params["mode"] === "new" && !this.props.app.dm.addDoctorsDomain.state.hasTreatmentAddress) {
        this.props.app.dm.addDoctorsDomain.setState({ isAddProviderBackConfirmation: true });
        return false;
      }else if (!!this.props.app.dm.userSession.state.isUberDocs && ["externalClientUploadBillRoute", "externalClientbillViewRoute", "externalClientbillHistoryRoute"].includes(currentRoute)) {
        let redirect_url = await AsyncStorage.getItem("REDIRECT_URI");
        let appointment = await AsyncStorage.getItem("appointment");
        let access_token =  await this.props.app.dm.userSession.getAccessToken();
        let homeScreen =  await AsyncStorage.getItem("homeScreen");
        if(!!homeScreen && currentRoute !== homeScreen && homeScreen === "externalClientbillHistoryRoute")
        {
          this.props.app.nav.back();
        }
        else
        {
          Linking.LinkingURL(`${redirect_url}&appointment=${appointment}&errorMessage=dismiss&access_token=${access_token}`, "_self")
          await this.props.app.dm.dashboard.resetDashboardDomain(); 
        }
        return false;
      }  else {
        this.props.app.nav.back();
      }
    };

    render() {
      if (!this.state.initialized) return null;
      let currentRoute = this.props.r14.navigation.activeRouteName;
      const isSearchopen = this.props.app.dm.userSearchDomain.state.isSearchOpen || false;
      let listRoutes = [
        "dashboard",
        "patientDashboard",
        "pracDashboard",
        "profileViewRoute",
        "simpleMessageRoute",
        "notificationScreenRoute",
        "billersDirectoryRoute",
        "paymentOptionsRoute",
        "myPreferencesViewRoute",
        "notificationScreenRoute",
        "pchProfileViewRoute",
        "insuranceListRoute",
        "providerListRoute",
        "uploadBillsRoute",
        "addUsersRoute",
        "editUsersRoute",
        "helpRoute",
        "billRequestScreenRoute",
        "myBanksRoute",
        "scanQRcodeRoute",
        "approveBillRoute",
        "makePaymentsRoute",
        "uploadBillRoute",
        "vendorStatementsRoute",
        "receivePaymentsRoute",
        "managePaymentsRoute",
        "moopRoute",
        "paymentReceiptRoute",
        "subscriptionRoute",
        "subscribedRoute",
        "serviceSubscriptionRoute",
        "serviceSubscribedRoute",
        "directSubmission",
        "nonRegisterReceipt",
        "clientListRoute"
      ];

      let clientStyle = !!this.props.app.dm.userSession.state.isUberDocs ? uberDocThemeStyle : commonStyles;

      return (
        <View
          style={[
            styles.headerControlsRight,
            !isSearchopen && styles.headerControlLeftWrapper,
            clientStyle.header
          ]}
        >
          <View style={[commonStyles.dFlex, styles.flex1]}>
            <View
              style={[
                styles.routeName,
                commonStyles.flex1,
                commonStyles.alignItemsCenter,
                commonStyles.flexDirectionRow,
              ]}
            >
              { ["nonRegisterReceipt", "directSubmission"].includes(currentRoute) ? 
                <IconButton icon="menu" style={[styles.menuIcon]} /> : 
                <IconButton
                  icon={
                    listRoutes.includes(currentRoute) && !!!this.props.app.dm.userSession.state.isExternalLogin ? "menu" : "arrowBack"
                  }
                  style={[styles.menuIcon]}
                  onPress={
                    listRoutes.includes(currentRoute) && !!!this.props.app.dm.userSession.state.isExternalLogin
                      ? this.onToggle
                      : this.onBack
                  }
                />
              }
              <View style={commonStyles.webView}>
                <Image source={!!this.props.app.dm.userSession.state.isUberDocs ? clientLogo : xbpLogo} style={[styles.logoImg, clientStyle.clientLogo]} />
              </View>
              <View style={commonStyles.mView}>
                <Image source={!!this.props.app.dm.userSession.state.isUberDocs ? clientLogo :xbpSymbolLogo} style={[styles.logoIconImg, clientStyle.clientLogo]} />
              </View>
            </View>
          </View>
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  headerControlsRight: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  headerControlLeftWrapper: {
    // minWidth: 350,
  },
  menuIcon: {
    padding: 12,
    height: 48,
    marginTop: 0,
    marginRight: 5,
    marginBottom: 0,
    marginLeft: 5,
    screen: ({ width }) => {
      if (width <= 991)
        return {
          paddingTop: 12,
          paddingRight: 8,
          paddingBottom: 12,
          paddingLeft: 12,
        };
    },
  },
  flex1: {
    flex: 1,
  },
  userDetail: {
    borderBottom: "1px solid #ccc",
    flex: 1,
    flexDirection: "row",
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 4,
    paddingLeft: 5,
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px 0px",
  },
  userImage: {
    height: 24,
    width: 21,
    backgroundSize: "contain",
  },
  userName: {
    color: "#182832",
  },
  userId: {
    color: "#727272",
  },
  routeName: {},
  headerTitle: {
    marginLeft: 6,
    screen: ({ width }) => {
      if (width <= 992) {
        return {
          marginLeft: 10,
        };
      }
    },
  },
  backAndHamburgIcon: {
    color: "red",
  },
  logoImg: {
    width: 96,
    height: 30,
    display: "block",
    backgroundSize: "contain",
  },
  logoIconImg: {
    width: 30,
    height: 30,
    display: "block",
    backgroundSize: "contain",
  },
});
