import R14, { AsyncStorage } from "../core";
import { USER_API } from "../constant/app.constant";
import CommonUtility from "../utils/CommonUtility";

export default class UserDomain extends R14.Domain {
  constructor(props) {
    super(props);
    this.state = {
      billers: [],
      subuserItems: [],
      totalBills: 0,
      currentPage: 1,
      totalPages: 1,
      resultsPerPage: 9
    };
  }

  async updateUserDetails(data) {
    try {
      return await this.dm.rest.put(USER_API, data);
    } catch (error) {
      console.log("UpdateUserDetails error ", error);
      return error;
    }
  }

  async getUniqueObject(items) {
    return Array.from(new Set(items.map((object) => JSON.stringify(object)))).map((string) => JSON.parse(string));
  }

  async getBillerListFromPam() {
    let reCalledBiller = false;
    const linkPids = await this.dm.rest.getLinkedPID();
    let billerList = await this.dm.rest.getBillers();
    if (!billerList || billerList.length === 0)
      billerList = await this.dm.queryDomain.getBiller({ filter: { payerId: { in: linkPids.split(',') } } });

    //get requested & accepted PAMs using biller api
    let resData = await this.dm.billerDirectoryDomain.getPamMessagesList(false);
    //get existing biller from payer DB.
    let res = !!Array.isArray(resData) ? resData : [];
    let billerPids = res.map(item => {
      return {
        billerId: item.billerId, payerId: item.payerId, buId: item.buid,
        billerRef: item.billerRef, payerRef: item.payerRef
      }
    });

    let dbBillerIds = billerList.map(item => {
      return {
        billerId: item.billerId, payerId: item.payerId, buId: item.buId,
        billerRef: item.billerRef, payerRef: item.payerRef
      }
    });

    billerPids = await this.getUniqueObject([...billerPids, ...dbBillerIds]);
    let activeBillers = [];

    let requestedBillers = res.filter(x => x.status === "requested").map(x => { return { pid: x.billerId, buId: x.buid } });
    requestedBillers = await this.getUniqueObject(requestedBillers);

    let nonRequestedBiller = [...res.filter(x => x.status !== "requested").map(x => { return { pid: x.billerId, buId: x.buid } })
      , ...dbBillerIds.map(x => { return { pid: x.billerId, buId: x.buId } })];
    nonRequestedBiller = await this.getUniqueObject(nonRequestedBiller);


    //Requested billers/PAMs only
    if (!!requestedBillers && requestedBillers.length > 0) {
      activeBillers = await this.dm.billRequest.getBillerCSEPaymentToggle(
        {
          isMessagingConfig: true,
          billerPIDList: requestedBillers,
          from: 1,
          to: parseInt((new Date().getTime() / 1000).toFixed())
        });
    }
    //other than requested billers/PAMs
    if (!!nonRequestedBiller && nonRequestedBiller.length > 0) {
      let billerOptions = await this.dm.billRequest.getActiveBillersWithAPI(nonRequestedBiller, true);
      if (!!billerOptions && billerOptions.length > 0) {
        activeBillers = activeBillers.concat(billerOptions)
      }
    }

    //if get accepted/Request data
    if (!!billerPids && billerPids.length > 0) {
      for await (let item of billerPids) {
        CommonUtility.htmlDecode(item)
        let billerDetail = billerList.find((x) => x.billerId === item.billerId && x.payerId === item.payerId && x.buId === item.buId && x.billerRef === item.billerRef && x.payerRef === item.payerRef);
        if (!!billerDetail) {
          // get messaging flag
          let filterBillers = !!activeBillers && activeBillers.length > 0 ? activeBillers.filter(x => x.billerId === item.billerId && x.buId === item.buId && x.billerRef === item.billerRef) : null;
          let activeBiller = !!filterBillers && filterBillers.length > 0 ? filterBillers.pop() : null;
          let ele = null;
          let newBiller = res.filter(x => x.billerId === item.billerId).sort((n1, n2) => {
            if (n1.status > n2.status) {
              return 1;
            }
            if (n1.status < n2.status) {
              return -1;
            }
            return 0;
          });

          if (newBiller.length > 0) {
            reCalledBiller = true;
            if (!!activeBiller) {
              billerDetail.messagingToggleValue = activeBiller.messagingToggleValue;
              billerDetail.isPaymentDecline = activeBiller.isPaymentDecline;
              billerDetail.isPaymentDueDateExtensions = activeBiller.isPaymentDueDateExtensions;
              billerDetail.isPaymentsFull = activeBiller.isPaymentsFull;
              billerDetail.isPaymentsPartial = activeBiller.isPaymentsPartial;
              billerDetail.billerName = activeBiller.billerName || billerDetail.billerName;
            }
            ele = newBiller[0];
            await this.dm.queryDomain.addUpdateBiller({ ...ele, biller: billerDetail });
          }
          else {
            if (!!activeBiller && (billerDetail.messagingToggleValue !== activeBiller.messagingToggleValue
              || billerDetail.isPaymentDecline !== activeBiller.isPaymentDecline
              || billerDetail.isPaymentDueDateExtensions !== activeBiller.isPaymentDueDateExtensions
              || billerDetail.isPaymentsFull !== activeBiller.isPaymentsFull
              || billerDetail.isPaymentsPartial !== activeBiller.isPaymentsPartial
              || billerDetail.billerName !== activeBiller.billerName)) {
              billerDetail.messagingToggleValue = activeBiller.messagingToggleValue;
              billerDetail.isPaymentDecline = activeBiller.isPaymentDecline;
              billerDetail.isPaymentDueDateExtensions = activeBiller.isPaymentDueDateExtensions;
              billerDetail.isPaymentsFull = activeBiller.isPaymentsFull;
              billerDetail.isPaymentsPartial = activeBiller.isPaymentsPartial;
              billerDetail.billerName = activeBiller.billerName || billerDetail.billerName;
              ele = billerDetail;
              reCalledBiller = true;
              await this.dm.queryDomain.addUpdateBiller({ ...ele, biller: billerDetail });
            }
          }
        }
      }
    }
    if (!!reCalledBiller)
      await this.dm.queryDomain.getBiller({ filter: { payerId: { in: linkPids.split(',') } } });
    return true;
  }

  async getLists(filter, fields, resultsPerPage = 10) {
    let result = await this.api.qry(
      `
      query FindUserLoginDetail($filter: UserLoginDetailFilter, $page: Int, $resultsPerPage: Int, $sort: [SortOption!]){
        userLoginDetails(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: true)
          nodes {
              ${fields}
            }
          }
        }`
      ,
      filter
    );
    if (!!result.data.userLoginDetails && !!result.data.userLoginDetails.nodes) {
      let totalCount = result.data.userLoginDetails.totalCount;
      this.setState({
        totalBills: totalCount,
        totalPages: Math.ceil(totalCount / resultsPerPage)
      })
      let users = result.data.userLoginDetails.nodes;
      for await (const user of users) {
        let res = await this.getUserBUName(user.uid);
        user.firstName = res.firstName;
        user.lastName = res.lastName;
      }
      this.setState({
        subuserItems: users
      })
      return this.state.subuserItems;
    }
    else return [];
  }

  async getUserBUName(uid, fields = "uid firstName lastName") {
    const filter = {"filter": {
        "userDetailUid": {
            "eq": uid
        }
    }};
    let result = await this.api.qry(
      `
      query UserBUDetail($filter: UserBUDetailFilter){
        userBUDetails(filter: $filter){
          nodes {
              ${fields}
            }
          }
        }`
      ,
      filter
    );
    if (!!result.data.userBUDetails && !!result.data.userBUDetails.nodes && !!result.data.userBUDetails.nodes.length) {
      return result.data.userBUDetails.nodes[0];
    }
    else return {uid: uid, firstName: "", lastName: ""};
  }


  async getUserDetailsListWithPage(userUID, fieldsStr = "uid pid email firstName mobileNumber parentUid isActive lastName") {

    const { currentPage, resultsPerPage } = this.state;

    let filter = {
      filter: {
        parentUid: { eq: userUID }
      },
      sort: { field: "createdAt", order: "DESC" },
      page: currentPage,
      resultsPerPage: resultsPerPage
    };

    return await this.getLists(filter, fieldsStr, resultsPerPage);
  }

  async getUserFromBiller(pid) {
    let options = {
      pid
    };
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query GetUserFromBiller($pid: String!){
          getUserFromBiller(pid: $pid ) 
          {
            userBUDetails { uid firstName lastName userDetailUid payerRef buId buName billerId billerRef billerName country addressLine1 addressLine2 state town postCode }
          }
          }`,
        options
      ));

      if (!!res.data.getUserFromBiller) {
        await AsyncStorage.setItem(`userBUDetails_${pid}`, JSON.stringify(res.data.getUserFromBiller.userBUDetails));
        return res.data.getUserFromBiller.userBUDetails;
      }
      return [];
    } catch (error) {
      return error;
    }
  }

  async hasSocialLogin() {
    try {
      let res = await this.dm.queryDomain.middleware(this.api.qry(
        `query HasSocialLogin{
            hasSocialLogin 
          }`
      ));

        return res.data.hasSocialLogin;
    } catch (error) {
      return false;
    }
  }
}
